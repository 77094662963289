// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import JobCommercialMaterialsAndEquipmentsBlock from 'modules/Job/V2/Move/components/JobCommercialMaterialsAndEquipmentsBlock';
import JobProjectBillingBlock from 'modules/Job/V2/Move/components/JobProjectBillingBlock';
import JobProjectCostsBlock from 'modules/Job/V2/Move/components/JobProjectCostsBlock';
import JobProjectInvoiceBlock from 'modules/Job/V2/Move/components/JobProjectInvoiceBlock';
import JobValuationCoverageBlock from 'modules/Job/V2/Move/components/JobValuationCoverageBlock';

const HeaderText = Styled.Text`
  ${Typography.Heading1}
`;

const JobProjectAccountingBlock = ({index, job, refetch}: any) => {
  const {isCostAndCompensationEnabled} = job.organization.settings;

  return (
    <React.Fragment>
      <HeaderText>{isCostAndCompensationEnabled ? 'Accounting' : 'Billing'}</HeaderText>
      <Space height={24} />
      <JobProjectInvoiceBlock sectionIndex={index + 3} job={job} refetch={refetch} />
      <Space height={32} />
      <JobValuationCoverageBlock sectionIndex={index + 4} job={job} />
      {isCostAndCompensationEnabled && (
        <React.Fragment>
          <Space height={32} />
          <JobProjectCostsBlock sectionIndex={index} job={job} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectAccountingBlock.fragment = gql`
  ${JobProjectCostsBlock.fragment}
  ${JobProjectBillingBlock.fragment}
  ${JobCommercialMaterialsAndEquipmentsBlock.fragment}
  ${JobValuationCoverageBlock.fragment}
  ${JobProjectInvoiceBlock.fragment}

  fragment JobProjectAccountingBlock on Job {
    id
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
    ...JobProjectCostsBlock
    ...JobProjectBillingBlock
    ...JobCommercialMaterialsAndEquipmentsBlock_Job
    ...JobValuationCoverageBlock
    ...JobProjectInvoiceBlock
  }
`;

export default JobProjectAccountingBlock;
