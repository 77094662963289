// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  setMutationErrors: (...args: any[]) => any;
  submitForm: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AssignTruckToJobV2Mutation.defaultProps;

// @ts-expect-error TS(7022): 'AssignTruckToJobV2Mutation' implicitly has type '... Remove this comment to see the full error message
const AssignTruckToJobV2Mutation = ({
  truckId,
  jobId,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => {
  return (
    <Mutation
      // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
      variables={{
        truckId,
        jobId,
      }}
      mutation={AssignTruckToJobV2Mutation.mutation}
      onCompleted={({response: {jobTruck, errors}}: any) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(jobTruck);
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={['PrepareJobPage']}
      awaitRefetchQueries
    >
      {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
    </Mutation>
  );
};

AssignTruckToJobV2Mutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignTruckToJobV2Mutation.mutation = gql`
  mutation AssignTruckToJobV2Mutation(
    $truckId: Int!,
    $jobId: Int!,
  ) {
    response: assignTruckToJobV2(
      truckId: $truckId,
      jobId: $jobId,
    ) {
      ${gql.errors}
      jobTruck {
        truckId
        jobId
      }
    }
  }
`;

export default AssignTruckToJobV2Mutation;
