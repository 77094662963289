// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/TableV2Deprecated';

const Container = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Heading4}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: 100;
`;

const SelectedText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const getVirtualizedHeights = ({itemCount, itemHeight, window}: any) => {
  const heightOfItems = itemCount * itemHeight;
  const maxHeight = window.innerHeight - 400;
  return {
    virtualizedItemHeight: itemHeight,
    virtualizedTableHeight: heightOfItems < maxHeight ? heightOfItems : maxHeight,
  };
};

const ViewHeader = ({title, handleEdit, csvActions, setSearchTerm}: any) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Space height={16} />
      <Row>
        <SearchBar
          placeholder={'Search'}
          onChangeText={(text) => {
            setSearchTerm(text);
          }}
          iconColor={colors.gray.tertiary}
          style={{width: '100%', borderColor: colors.gray.tertiary}}
          containerStyle={{flex: 1}}
        />
        <Space width={16} />
        <DropdownButton
          text={'CSV'}
          actions={csvActions}
          ButtonComponent={SecondaryButton}
          iconLeft={Icon.Upload}
          menuWidth={200}
        />
        <Space width={16} />
        <Button onPress={handleEdit} text={'Edit'} iconLeft={Icon.Pen} />
      </Row>
    </React.Fragment>
  );
};

const ViewTable = ({columnDefinitions, tableItems, emptyStateText, virtualizedListRef}: any) => {
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: tableItems.length,
    itemHeight: 64,
    window,
  });
  return (
    <Table.Virtualized
      columnDefinitions={columnDefinitions}
      emptyStateText={emptyStateText}
      items={tableItems}
      itemKey={'id'}
      // @ts-expect-error TS(2322): Type '{ columnDefinitions: any; emptyStateText: an... Remove this comment to see the full error message
      contentContainerStyle={{width: '100%'}}
      isDense
      virtualizedListRef={virtualizedListRef}
      virtualizedItemHeight={virtualizedItemHeight}
      virtualizedTableHeight={virtualizedTableHeight}
    />
  );
};

const EditHeader = ({
  title,
  form,
  handleClose,
  handleSubmit,
  submitting,
  field,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Space height={16} />
      <Row style={{justifyContent: 'space-between'}}>
        <Row>
          <SelectedText>{`${_.size(bulkSelectedFormIndices)} selected`}</SelectedText>
          <Space width={16} />
          <SecondaryButton
            onPress={() => {
              _.map(bulkSelectedFormIndices, (bulkSelectedFormIndex) => {
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDeleted`, false);
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDirty`, true);
              });
            }}
            text={'Restore'}
            iconLeft={Icon.ArrowRotateRight}
            iconSize={12}
          />
          <Space width={16} />
          <SecondaryButton
            onPress={() => {
              _.map(bulkSelectedFormIndices, (bulkSelectedFormIndex) => {
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDeleted`, true);
                form.setFieldValue(`${field}.${bulkSelectedFormIndex}.isDirty`, true);
              });
            }}
            text={'Remove'}
            iconLeft={Icon.Trash}
            iconSize={12}
          />
        </Row>
        <Row>
          <QuaternaryButton
            isDisabled={submitting}
            onPress={() => {
              setBulkSelectedFormIndices([]);
              form.handleReset();
              handleClose();
            }}
            text={'Cancel'}
            textColor={colors.blue.interactive}
          />
          <Space width={16} />
          <Button
            isSubmitting={submitting}
            onPress={handleSubmit}
            text={'Save Changes'}
            iconLeft={Icon.Check}
          />
        </Row>
      </Row>
    </React.Fragment>
  );
};

const EditTable = ({form, field, itemKey, columnDefinitions, virtualizedListRef}: any) => {
  const items = _.get(form.values, field);
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: items.length,
    itemHeight: 64,
    window,
  });
  return (
    <Table.Virtualized
      columnDefinitions={columnDefinitions}
      items={items}
      itemKey={itemKey}
      // @ts-expect-error TS(2322): Type '{ columnDefinitions: any; items: any; itemKe... Remove this comment to see the full error message
      contentContainerStyle={{width: '100%'}}
      isDense
      hasFooter
      virtualizedListRef={virtualizedListRef}
      virtualizedItemHeight={virtualizedItemHeight}
      virtualizedTableHeight={virtualizedTableHeight}
    />
  );
};

// //////////////////////////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL: SURVEYS_TIME_ADDITIVES
const TableContainer = Styled.View`
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width || '800px'};
`;

const ViewHeaderDeprecated = ({title, handleEdit, csvActions, setSearchTerm}: any) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <Space height={16} />
      <Row style={{justifyContent: 'space-between'}}>
        <SearchBar
          placeholder={'Search'}
          onChangeText={(text) => {
            setSearchTerm(text);
          }}
          iconColor={colors.gray.tertiary}
          // @ts-expect-error TS(2322): Type '{ placeholder: string; onChangeText: (text: ... Remove this comment to see the full error message
          tyle={{width: '296px', borderColor: colors.gray.tertiary}}
        />
        <Row>
          <DropdownButton
            text={'CSV'}
            actions={csvActions}
            ButtonComponent={SecondaryButton}
            iconLeft={Icon.Upload}
            menuWidth={200}
          />
          <Space width={16} />
          <Button onPress={handleEdit} text={'Edit'} iconLeft={Icon.Pen} />
        </Row>
      </Row>
    </React.Fragment>
  );
};

const ViewTableDeprecated = ({
  columnDefinitions,
  tableItems,
  emptyStateText,
  tableWidth,
  virtualizedListRef,
}: any) => {
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: tableItems.length,
    itemHeight: 64,
    window,
  });
  return (
    <TableContainer width={tableWidth}>
      <Table.Virtualized
        columnDefinitions={columnDefinitions}
        emptyStateText={emptyStateText}
        items={tableItems}
        itemKey={'id'}
        isDense
        virtualizedListRef={virtualizedListRef}
        virtualizedItemHeight={virtualizedItemHeight}
        virtualizedTableHeight={virtualizedTableHeight}
      />
    </TableContainer>
  );
};

const EditTableDeprecated = ({
  form,
  field,
  itemKey,
  columnDefinitions,
  tableWidth,
  virtualizedListRef,
}: any) => {
  const items = _.get(form.values, field);
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: items.length,
    itemHeight: 64,
    window,
  });
  return (
    <TableContainer width={tableWidth}>
      <Table.Virtualized
        columnDefinitions={columnDefinitions}
        items={items}
        itemKey={itemKey}
        isDense
        hasFooter
        virtualizedListRef={virtualizedListRef}
        virtualizedItemHeight={virtualizedItemHeight}
        virtualizedTableHeight={virtualizedTableHeight}
      />
    </TableContainer>
  );
};

type OwnInventoryLibraryBulkUpdateContentsProps = {
  ViewComponent: (...args: any[]) => any;
  viewComponentProps?: any;
  EditComponent: (...args: any[]) => any;
  editHeaderProps?: any;
  editComponentProps?: any;
  isEdit: boolean;
  setIsEdit: (...args: any[]) => any;
  title: string;
  csvActions: any[];
  setSearchTerm: (...args: any[]) => any;
  form: any;
  handleSubmit: (...args: any[]) => any;
  submitting?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'InventoryLibraryBulkUpdateContentsProp... Remove this comment to see the full error message
type InventoryLibraryBulkUpdateContentsProps = OwnInventoryLibraryBulkUpdateContentsProps &
  typeof InventoryLibraryBulkUpdateContents.defaultProps;

// FEATURE FLAG REMOVAL BLOCK END
// //////////////////////////////////////////////////////////////////////////////////////////

// FEATURE FLAG REMOVAL: SURVEYS_TIME_ADDITIVES
// - delete isEnabledSurveysTimeAdditives prop and the else statement
// @ts-expect-error TS(7022): 'InventoryLibraryBulkUpdateContents' implicitly ha... Remove this comment to see the full error message
const InventoryLibraryBulkUpdateContents = ({
  ViewComponent,
  viewComponentProps,
  EditComponent,
  editHeaderProps,
  editComponentProps,
  isEdit,
  setIsEdit,
  title,
  csvActions,
  setSearchTerm,
  form,
  handleSubmit,
  submitting,
  isEnabledSurveysTimeAdditives,
}: InventoryLibraryBulkUpdateContentsProps) => {
  if (isEnabledSurveysTimeAdditives) {
    return (
      <ScrollView horizontal contentContainerStyle={{width: '100%'}}>
        {/* viewComponentProps.tableWidth and editComponentProps.tableWidth are expected to have the same value. */}
        <Container
          style={{
            width: '100%',
            minWidth: viewComponentProps.tableWidth || '800px',
            maxWidth: '1200px',
          }}
        >
          {isEdit ? (
            <React.Fragment>
              <EditHeader
                title={title}
                form={form}
                handleClose={() => {
                  form.resetForm();
                  setIsEdit(false);
                }}
                handleSubmit={handleSubmit}
                submitting={submitting}
                {...editHeaderProps}
              />
              <Space height={24} />
              <EditComponent {...editComponentProps} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ViewHeader
                title={title}
                handleEdit={() => {
                  setIsEdit(true);
                }}
                csvActions={csvActions}
                setSearchTerm={setSearchTerm}
              />
              <Space height={24} />
              <ViewComponent {...viewComponentProps} />
              {/* This space is necessary for the ViewHeader csv dropdown.
               * When the table is empty, the dropdown list is cut off by the bottom of the virtualized table otherwise.
               */}
              <Space height={50} />
            </React.Fragment>
          )}
        </Container>
      </ScrollView>
    );
  } else {
    return (
      <ScrollView horizontal>
        <Container>
          {isEdit ? (
            <React.Fragment>
              <EditHeader
                title={title}
                form={form}
                handleClose={() => {
                  form.resetForm();
                  setIsEdit(false);
                }}
                handleSubmit={handleSubmit}
                submitting={submitting}
                {...editHeaderProps}
              />
              <Space height={24} />
              <EditTableDeprecated {...editComponentProps} />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ViewHeaderDeprecated
                title={title}
                handleEdit={() => {
                  setIsEdit(true);
                }}
                csvActions={csvActions}
                setSearchTerm={setSearchTerm}
              />
              <Space height={24} />
              <ViewTableDeprecated {...viewComponentProps} />
              {/* This space is necessary for the ViewHeader csv dropdown.
               * When the table is empty, the dropdown list is cut off by the bottom of the virtualized table otherwise.
               */}
              <Space height={50} />
            </React.Fragment>
          )}
        </Container>
      </ScrollView>
    );
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryLibraryBulkUpdateContents.fragment = gql`
  fragment InventoryLibraryBulkUpdateContents on Organization {
    id
    features {
      isEnabledSurveysTimeAdditives: isEnabled(feature: "SURVEYS_TIME_ADDITIVES")
    }
  }
`;

InventoryLibraryBulkUpdateContents.defaultProps = {
  viewComponentProps: {},
  editHeaderProps: {},
  editComponentProps: {},
  submitting: false,
};

InventoryLibraryBulkUpdateContents.EditTable = EditTable;
InventoryLibraryBulkUpdateContents.ViewTable = ViewTable;
export default InventoryLibraryBulkUpdateContents;
