// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ClaimTypeForm from '@shared/modules/Claim/forms/ClaimTypeForm';
import useDeleteClaimTypeMutation from '@shared/modules/Claim/hooks/useDeleteClaimTypeMutation';

const DeleteClaimTypeModal = ({claimType, isOpen, handleClose, refetch}: any) => {
  const claimTypeForm = ClaimTypeForm.edit(claimType);
  const {handleSubmit} = useDeleteClaimTypeMutation({
    claimTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log(errors),
  });
  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Remove claim type?`}
      subtitle={
        'This claim type will no longer exist in settings but can still be viewed in the claim. You can’t undo this action.'
      }
      deleteButtonText={'Remove'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteClaimTypeModal.fragment = gql`
  ${ClaimTypeForm.edit.fragment}

  fragment DeleteClaimTypeModal on ClaimType {
    id
    ...ClaimTypeForm_edit
  }
`;
export default DeleteClaimTypeModal;
