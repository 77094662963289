// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Loading, Space, Icon, Popover, MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useDrawer, useState, usePopover, useForm} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowRunStatus from '@shared/modules/Workflow/enums/WorkflowRunStatus';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import StatusBadge from 'modules/App/components/StatusBadge';
import WorkflowRunDetailsDrawer from 'modules/Organization/Settings/Workflow/components/WorkflowRunDetailsDrawer';
import ManuallyRunWorkflowDrawer from 'modules/Project/V2/Show/components/widgets/ManuallyRunWorkflowDrawer';

const WorkflowDetailsButton = Styled.Touchable`
`;

const PopoverContainer = Styled.View`
  padding-horizontal: 24px;
`;

const Container = Styled.View`
  flex: 1;
  padding: 16px;
  background-color: ${colors.gray.background};
`;

const Row = Styled.View`
  flex-direction: row;
  align-item: center;
  justify-content: space-between;
`;

const WorkflowRunItemContainer = Styled.View`
  padding: 12px;
  border-top-right-radius: ${({
    // @ts-expect-error TS(2339): Property 'isFirst' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isFirst,
  }) => isFirst && '4px'};
  border-top-left-radius: ${({
    // @ts-expect-error TS(2339): Property 'isFirst' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isFirst,
  }) => isFirst && '4px'};
  border-bottom-right-radius: ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => isLast && '4px'};
  border-bottom-left-radius: ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => isLast && '4px'};
  border-top-width: 1px;
  border-bottom-width: ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => isLast && '1px'};
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const PopoverHeader = Styled.Text`
  ${Typography.Heading2}
`;

const WorkflowRunTitle = Styled.Text`
  ${Typography.Label}
`;

const WorkflowRunDetail = Styled.Text`
  ${Typography.MicroLabel}
`;

const getActiveFilters = ({form, field}: any) => {
  const filters = _.cloneDeep(_.get(form.values, field));
  return Object.keys(filters).reduce((result, key) => {
    if (filters[key].length > 0) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[key] = filters[key];
    }
    return result;
  }, {});
};

const getSortedWorkflowRuns = ({workflowRuns}: any) => {
  return _.orderBy(
    workflowRuns,
    [
      (workflowRun) => {
        return workflowRun.status === WorkflowRunStatus.COMPLETED
          ? workflowRun.completedAt
          : workflowRun.createdAt;
      },
    ],
    ['desc'],
  );
};

const getFilteredWorkflowRuns = ({workflowRuns, appliedFilters}: any) => {
  return _.filter(workflowRuns, (workflowRun) => {
    if (appliedFilters.statuses && appliedFilters.statuses.length > 0) {
      if (!_.includes(appliedFilters.statuses, workflowRun.status)) {
        return false;
      }
    }
    if (appliedFilters.jobIds && appliedFilters.jobIds.length > 0) {
      if (!_.includes(appliedFilters.jobIds, workflowRun.jobId)) {
        return false;
      }
    }
    return true;
  });
};

const WorkflowFiltersPopover = ({project, appliedFilters, setAppliedFilters, handleClose}: any) => {
  const field = 'workflowFiltersForm';
  const form = useForm({
    initialValues: {
      workflowFiltersForm: {
        statuses: appliedFilters.statuses || [],
        jobIds: appliedFilters.jobIds || [],
      },
    },
  });
  const activeFiltersCount = Object.keys(getActiveFilters({form, field})).length;
  return (
    <ResponsivePopover.StaticContainer width={400} maxHeight={352}>
      <PopoverContainer>
        <Space height={24} />
        <Row style={{justifyContent: 'space-between'}}>
          <PopoverHeader>{`Filters (${activeFiltersCount})`}</PopoverHeader>
          <TertiaryButton
            text={'Clear Filters'}
            iconLeft={Icon.Trash}
            onPress={() => {
              form.setFieldValue('workflowFiltersForm', {
                statuses: [],
                jobIds: [],
              });
            }}
          />
        </Row>
        <Space height={16} />
        <FieldInput
          {...form}
          component={MultiDropdownInput}
          name={`${field}.statuses`}
          label={'Status'}
          index={0}
          input={{
            options: WorkflowRunStatus.DROPDOWN_OPTIONS,
            placeholder: 'Select status',
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
        />
        <Space height={16} />
        <FieldInput
          {...form}
          component={MultiDropdownInput}
          name={`${field}.jobIds`}
          label={'Jobs'}
          index={1}
          input={{
            options: _.map(project?.activeJobs, (job) => ({
              label: job.fullName,
              value: _.toNumber(job.id),
            })),
            placeholder: 'Select jobs',
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
          }}
        />
        <Space height={16} />
        <Row style={{justifyContent: 'flex-end'}}>
          <QuaternaryButton text={'Cancel'} onPress={handleClose} />
          <Space width={16} />
          <Button
            iconLeft={Icon.Check}
            text={'Apply'}
            onPress={() => {
              setAppliedFilters(getActiveFilters({form, field}));
              handleClose();
            }}
          />
        </Row>
        <Space height={24} />
      </PopoverContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const WorkflowFilters = ({
  project,
  appliedFilters,
  setAppliedFilters,
  appliedFiltersCount,
}: any) => {
  const workflowFiltersPopover = usePopover({name: 'Workflow Filters Popover'});
  return (
    <React.Fragment>
      <Popover.Content innerRef={workflowFiltersPopover.ref}>
        <SecondaryButton
          text={`(${appliedFiltersCount})`}
          iconLeft={Icon.Filter}
          iconSize={14}
          onPress={workflowFiltersPopover.handleToggle}
          isResponsive
        />
      </Popover.Content>
      <Popover
        key={workflowFiltersPopover.key}
        placement={Popover.Positions.BottomEnd}
        isOpen={workflowFiltersPopover.isOpen}
        handleOpen={workflowFiltersPopover.handleOpen}
        handleClose={workflowFiltersPopover.handleClose}
        reference={workflowFiltersPopover.ref}
        offset={[0, 4]}
      >
        <WorkflowFiltersPopover
          project={project}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          handleClose={workflowFiltersPopover.handleClose}
        />
      </Popover>
    </React.Fragment>
  );
};

const WorkflowRunItem = ({workflowRun, isFirst, isLast, refetch, urlFilters}: any) => {
  const statusOption = WorkflowRunStatus.getStatusOption(workflowRun.status);
  const workflowRunDetailsDrawer = useDrawer({
    name: 'Project Page - Workflow Run Details Drawer',
    enableTracking: true,
  });
  const handleCloseDetailsDrawer = () => {
    urlFilters.handleUpdate({workflowRunUuid: null});
  };
  const isWorkflowRunDetailsDrawerOpen = urlFilters.get('workflowRunUuid') === workflowRun.uuid;

  const job = workflowRun?.job;

  return (
    <React.Fragment>
      <WorkflowDetailsButton
        onPress={() => {
          urlFilters.handleUpdate({workflowRunUuid: workflowRun.uuid});
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <WorkflowRunItemContainer isFirst={isFirst} isLast={isLast}>
          <Row>
            <WorkflowRunTitle>{workflowRun.workflow.name}</WorkflowRunTitle>
            <StatusBadge {...statusOption} />
          </Row>
          <Space height={4} />
          <Row>
            <WorkflowRunDetail>
              {workflowRun.status === WorkflowRunStatus.COMPLETED
                ? `Completed: ${Datetime.convertToDisplayDatetime(workflowRun.completedAt)}`
                : `Started: ${Datetime.convertToDisplayDatetime(workflowRun.createdAt)}`}
            </WorkflowRunDetail>
          </Row>
          {workflowRun.jobId && (
            <React.Fragment>
              <Space height={4} />
              <Row>
                <WorkflowRunDetail>{`Job: ${job.jobType.name} ${job.identifier}`}</WorkflowRunDetail>
              </Row>
            </React.Fragment>
          )}
        </WorkflowRunItemContainer>
      </WorkflowDetailsButton>
      {isWorkflowRunDetailsDrawerOpen && (
        <WorkflowRunDetailsDrawer
          key={workflowRunDetailsDrawer.key}
          isOpen={isWorkflowRunDetailsDrawerOpen}
          handleClose={handleCloseDetailsDrawer}
          workflowRun={workflowRun}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

const ProjectWorkflowsWidget = ({projectUuid, urlFilters}: any) => {
  const [appliedFilters, setAppliedFilters] = useState({});
  const appliedFiltersCount = Object.keys(appliedFilters).length;

  const runWorkflowManuallyDrawer = useDrawer({
    name: 'Project Page - Run Workflow',
    enableTracking: true,
  });
  const {data, loading, refetch} = useQuery(ProjectWorkflowsWidget.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
  });

  return (
    <React.Fragment>
      <Row style={{borderBottom: `1px solid ${colors.gray.border}`, padding: 16}}>
        <Button
          text={'Run Automation'}
          iconLeft={Icon.Bolt}
          onPress={() => {
            runWorkflowManuallyDrawer.handleOpen();
          }}
          isWidthOfContainer
          style={{flex: 1, height: '100%'}}
        />
        <Space width={16} />
        <WorkflowFilters
          project={data?.project}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          appliedFiltersCount={appliedFiltersCount}
        />
      </Row>
      <Container>
        <Loading loading={loading}>
          {() => {
            const workflowRuns = getSortedWorkflowRuns({workflowRuns: data.project.workflowRuns});
            const filteredWorkflowRuns = getFilteredWorkflowRuns({
              workflowRuns,
              appliedFilters,
            });
            return (
              <React.Fragment>
                {filteredWorkflowRuns.map((workflowRun, index) => (
                  <WorkflowRunItem
                    key={workflowRun.id}
                    workflowRun={workflowRun}
                    isFirst={index === 0}
                    isLast={index === filteredWorkflowRuns.length - 1}
                    refetch={refetch}
                    urlFilters={urlFilters}
                  />
                ))}
                <Space height={16} />
                <ManuallyRunWorkflowDrawer
                  key={runWorkflowManuallyDrawer.key}
                  runWorkflowManuallyDrawer={runWorkflowManuallyDrawer}
                  project={data.project}
                  refetch={refetch}
                />
              </React.Fragment>
            );
          }}
        </Loading>
      </Container>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWorkflowsWidget.query = gql`
${ManuallyRunWorkflowDrawer.fragment}
${WorkflowRunDetailsDrawer.fragment}
  query ProjectWorkflowsWidget($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      workflowRuns {
        id
        status
        completedAt
        createdAt
        jobId
        uuid
        job {
          id
          identifier
          jobType {
            id
            name
          }
        }
        workflow {
            id
            name
        }
        ...WorkflowRunDetailsDrawer
      }
      activeJobs {
        id
        fullName
      }
      ...ManuallyRunWorkflowDrawer
    }
  }
`;

export default ProjectWorkflowsWidget;
