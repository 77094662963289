// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Tabs from '@shared/design/components/Tabs';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const PageContainer = Styled.View`
`;

const TabsContainer = Styled.View`
  padding-top: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? '0px' : '16px')};
  border-bottom-width: 1px;
  margin-horizontal: 24px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
`;

const getTabIndex = ({navigator, tabs}: any) => {
  const {location} = navigator;

  const splitLocationPath = location.pathname.split('/');

  return _.findIndex(tabs, {key: _.last(splitLocationPath)});
};

const getTabDefinitions = () => {
  return [
    {
      key: 'list',
      label: 'Projects',
      link: '/storage/list',
    },
    {
      key: 'containers',
      label: 'Containers',
      link: '/storage/containers',
    },
  ];
};

const TabHeader = ({navigator}: any) => {
  const responsive = useResponsive();
  const tabDefinitions = getTabDefinitions();
  const activeTabIndex = getTabIndex({navigator, tabs: tabDefinitions});

  return (
    <TabsContainer responsive={responsive}>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({link}) => navigator.replace(link)}
        activeTabIndex={activeTabIndex}
      />
    </TabsContainer>
  );
};

const StoragePageHeader = () => {
  const {navigator} = useNavigationDOM();

  return (
    <PageContainer>
      <StandardOfficeHeader title={'Storage'} showLine={false} />
      <TabHeader navigator={navigator} />
    </PageContainer>
  );
};

export default StoragePageHeader;
