// Libararies
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import WorkflowRunStatus from '@shared/modules/Workflow/enums/WorkflowRunStatus';
import StatusBadge from 'modules/App/components/StatusBadge';
import WorkflowRunDetailsDrawer from 'modules/Organization/Settings/Workflow/components/WorkflowRunDetailsDrawer';

const Container = Styled.View`
  flex: 1;
`;

const LinkText = Styled.Text`
  ${Typography.Link};
  cursor: pointer;
`;

const WorkflowRunDetailsButton = ({workflowRun, refetch}: any) => {
  const workflowRunDetailsDrawer = useDrawer({name: 'Workflow Run Details Drawer'});
  return (
    <React.Fragment>
      <LinkText onPress={workflowRunDetailsDrawer.handleOpen}>View Details</LinkText>
      <WorkflowRunDetailsDrawer
        key={workflowRunDetailsDrawer.key}
        isOpen={workflowRunDetailsDrawer.isOpen}
        handleClose={workflowRunDetailsDrawer.handleClose}
        workflowRun={workflowRun}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const getWorkflowRunsTableColumnDefinitions = ({navigator, refetch}: any) => [
  // Status
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Status</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellContent: ({item: workflowRun}) => {
      const statusOption = WorkflowRunStatus.getStatusOption(workflowRun.status);
      return <StatusBadge {...statusOption} />;
    },
  },
  // Started at
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Started at</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellContent: ({item: workflowRun}) => {
      return (
        <Table.CellText>{Datetime.convertToDisplayDatetime(workflowRun.createdAt)}</Table.CellText>
      );
    },
  },
  // Completed at
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Completed at</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellContent: ({item: workflowRun}) => {
      return (
        <Table.CellText>
          {workflowRun.completedAt
            ? Datetime.convertToDisplayDatetime(workflowRun.completedAt)
            : '-'}
        </Table.CellText>
      );
    },
  },
  // Project
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Project</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellContent: ({item: workflowRun}) => {
      const {project} = workflowRun;
      return (
        <React.Fragment>
          {project ? (
            <LinkText
              onPress={() => navigator.push(`/projects/${project.uuid}`)}
              numberOfLines={1}
            >{`Project ${project.identifier}`}</LinkText>
          ) : (
            <Table.CellText>-</Table.CellText>
          )}
        </React.Fragment>
      );
    },
  },
  // View Details
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Details</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflowRun' implicitly has an 'a... Remove this comment to see the full error message
    cellContent: ({item: workflowRun}) => {
      return <WorkflowRunDetailsButton workflowRun={workflowRun} refetch={refetch} />;
    },
  },
];

const WorkflowRunsTable = ({workflowRuns, refetch}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <Container>
      <Table.FixedHeaderScroll
        columnDefinitions={getWorkflowRunsTableColumnDefinitions({
          navigator,
          refetch,
        })}
        emptyStateText='No automation runs'
        items={workflowRuns}
      />
      <Space height={32} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowRunsTable.fragment = gql`
  ${WorkflowRunDetailsDrawer.fragment}
  fragment WorkflowRunsTable on WorkflowRun {
    id
    status
    completedAt
    createdAt
    uuid
    project {
      id
      uuid
      identifier
    }
    ...WorkflowRunDetailsDrawer
  }
`;

export default WorkflowRunsTable;
