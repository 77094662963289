// Libraries
import React from 'react';

// App
import {DropdownInput, Form, Hover, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Truck} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {Field} from 'modules/App/components';

import AssignTruckToJobV2Mutation from './AssignTruckToJobV2Mutation';

const Container = Styled.View`
  align-self: flex-start;
`;

const Buttons = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  padding-top: 10px;
`;

const Button = Styled.Button`
  height: 30px;
  padding-horizontal: 10px;
  margin-top: ${(props) => (props as any).marginTop || 0}px;
  margin-right: ${(props) => (props as any).marginRight || 0}px;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const ButtonSpace = Styled.View`
  margin-bottom: ${(props) => ((props as any).mobile ? 5 : 0)};
  margin-right: ${(props) => ((props as any).mobile ? 0 : 5)};
`;

const Indicator = Styled.Loading`
`;

const Touchable = Styled.Touchable`
`;

const AddItem = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const AddText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(700)}
`;

const getTruckOptions = ({job}: any) => {
  const selectedTruckIds = job.jobTrucks.map((jobTruck: any) => String(jobTruck.truckId));
  // Return all trucks that are not already signed to this job.
  return Truck.sort(
    job.organization.trucks.filter(
      (truck: any) => !selectedTruckIds.includes(String(truck.id)),
      ['truck.name', 'truck.size'],
    ),
  ).map((truck) => ({
    label: Truck.getFullName(truck),
    value: truck.id,
  }));
};

type OwnAddJobTruckItemProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'AddJobTruckItemProps' circularly refer... Remove this comment to see the full error message
type AddJobTruckItemProps = OwnAddJobTruckItemProps & typeof AddJobTruckItem.defaultProps;

// @ts-expect-error TS(7022): 'AddJobTruckItem' implicitly has type 'any' becaus... Remove this comment to see the full error message
const AddJobTruckItem = ({job, responsive, refetch}: AddJobTruckItemProps) => (
  <Form
    initialValues={{
      isOpen: false,
      truckId: null,
    }}
    onSubmit={() => {}}
  >
    {({initialValues, setMutationErrors, submitForm, ...form}: any) => (
      <AssignTruckToJobV2Mutation
        truckId={form.values.truckId}
        jobId={job.id}
        onSuccess={() => {
          form.setFieldValue('truckId', null);
          refetch();
        }}
        setMutationErrors={setMutationErrors}
        submitForm={submitForm}
      >
        {({loading, handleSubmit}: any) =>
          form.values.isOpen ? (
            <Container>
              <Field
                {...form}
                index={0}
                component={DropdownInput}
                name={'truckId'}
                input={{
                  options: getTruckOptions({job}),
                  placeholder: 'Select truck',
                  setFieldValue: form.setFieldValue,
                  style: {
                    width: 250,
                  },
                }}
              />
              <Buttons {...responsive}>
                <Button
                  color={colors.gray.tertiary}
                  disabled={loading}
                  onPress={() => {
                    form.setFieldValue('isOpen', false);
                    form.setFieldValue('truckId', null);
                  }}
                  {...responsive}
                >
                  <ButtonText>Cancel</ButtonText>
                </Button>
                <ButtonSpace {...responsive} />
                <Button
                  disabled={loading}
                  onPress={() => {
                    if (form.values.truckId) {
                      handleSubmit();
                    } else {
                      form.setFieldValue('isOpen', false);
                    }
                  }}
                  {...responsive}
                >
                  {loading ? <Indicator /> : <ButtonText>Save</ButtonText>}
                </Button>
              </Buttons>
            </Container>
          ) : (
            <Container>
              <Touchable
                onPress={() => form.setFieldValue('isOpen', true)}
                style={{
                  alignSelf: 'flex-start',
                }}
              >
                <Hover>
                  {({isHovered}: any) => (
                    <AddItem
                      style={{
                        backgroundColor: isHovered ? colors.blue.accent : 'none',
                      }}
                    >
                      <Icon
                        color={colors.gray.tertiary}
                        size={Icon.Sizes.Small}
                        source={Icon.Plus}
                        style={{marginRight: 5}}
                      />
                      <AddText>Add truck</AddText>
                    </AddItem>
                  )}
                </Hover>
              </Touchable>
            </Container>
          )
        }
      </AssignTruckToJobV2Mutation>
    )}
  </Form>
);

AddJobTruckItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddJobTruckItem.fragment = gql`
  fragment AddJobTruckItem on Job {
    id
    jobTrucks {
      id
      truckId
    }
    organization {
      id
      trucks: activeTrucks {
        id
        name
        size
      }
    }
  }
`;

export default AddJobTruckItem;
