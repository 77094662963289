// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import JobEventForm from '@shared/modules/Job/forms/JobEventForm';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import AddTimesheetBillableBlockModal from '@shared/modules/Timesheet/components/AddTimesheetBillableBlockModal';
import useCreateJobEventJobTimesheetUpdatedMutation from '@shared/modules/Timesheet/hooks/useCreateJobEventJobTimesheetUpdatedMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import JobTimetable from 'modules/App/components/JobTimetable';
import useAppContext from 'modules/App/context/useAppContext';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobMissingDateModal from 'modules/Project/V2/Show/Blocks/Job/components/JobMissingDateModal';
import JobTimesheetBillableEntries from 'modules/Project/V2/Show/Blocks/Job/components/JobTimesheetBillableEntries';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const Row = Styled.View`
flex-direction: row;
align-items: center;
`;

const ContentContainer = Styled.View`
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 24 : 16)}px;
`;

const TimesheetHeader = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const getHasMovers = ({job}: any) => {
  return _.some(job.timesheetBillableEntries);
};

const DesktopHeaderButtons = ({job, addTimesheetBillableBlockModal, jobMissingDateModal}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <Row>
      <SecondaryButton
        isSmall
        iconLeft={Icon.MapMarkedAlt}
        text={'View GPS Tracking'}
        onPress={() => navigator.push(`/jobs/${job.uuid}/timesheet/audit`)}
      />
      <Space width={12} />
      <JobActionDisabledTooltip
        job={job}
        customMessage={getHasMovers({job}) ? null : 'Must add movers before adding time.'}
      >
        <Button
          isSmall
          iconLeft={Icon.Plus}
          text={'Add Time'}
          onPress={
            job.startDate
              ? addTimesheetBillableBlockModal.handleOpen
              : jobMissingDateModal.handleOpen
          }
          isDisabled={!getHasMovers({job}) || job.isFinal}
        />
      </JobActionDisabledTooltip>
    </Row>
  );
};

const MobileActionsButton = ({job, addTimesheetBillableBlockModal, jobMissingDateModal}: any) => {
  const {navigator} = useNavigationDOM();
  const {viewer} = useAppContext();

  return (
    <DropdownButton
      text={'Actions'}
      isSmall
      menuWidth={180}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      actions={[
        {
          text: 'Add Time',
          onPress: job.startDate
            ? addTimesheetBillableBlockModal.handleOpen
            : jobMissingDateModal.handleOpen,
          tooltip: !getHasMovers({job})
            ? 'Must add movers before adding time.'
            : job.isFinal
              ? UserRole.getJobActionDisabledTooltip(viewer?.role)
              : '',
          isDisabled: !getHasMovers({job}) || job.isFinal,
        },
        {
          text: 'View GPS Tracking',
          onPress: () => navigator.push(`/jobs/${job.uuid}/timesheet/audit`),
        },
      ]}
      ButtonComponent={MobileProjectBlockHeader.EllipsisButton}
    />
  );
};

const TimesheetsContent = ({job, timesheetUpdatedMutation, responsive, refetch}: any) => {
  if (!getHasMovers({job})) {
    return (
      <EmptyState title={'No timesheets'} message={'Add movers to view and edit timesheets.'} />
    );
  }

  return (
    <React.Fragment>
      <JobTimesheetBillableEntries
        job={job}
        refetch={refetch}
        responsive={responsive}
        onUpdate={timesheetUpdatedMutation.handleSubmit}
        contentContainerStyle={{paddingHorizontal: 24}}
      />
      <Space height={24} />
      <ContentContainer responsive={responsive}>
        <JobTimetable
          job={job}
          hideWorkTimesheet
          HeaderComponent={() => {
            return (
              <React.Fragment>
                <TimesheetHeader responsive={responsive}>Tablet GPS Tracking</TimesheetHeader>
                <Space height={8} />
              </React.Fragment>
            );
          }}
        />
      </ContentContainer>
    </React.Fragment>
  );
};

const JobJobTimesheetBlockContent = ({job, project, refetch, responsive}: any) => {
  const timesheetUpdatedMutation = useCreateJobEventJobTimesheetUpdatedMutation({
    jobEventForm: JobEventForm.new({jobId: job.id}),
    onSuccess: () => {},
    onError: (errors: any) => console.log({errors}),
  });
  const addTimesheetBillableBlockModal = useModal({name: 'Add Timesheet Billable Block Modal'});
  const jobMissingDateModal = useModal({name: 'Job Missing Date Modal'});
  const actionsProps = {job, addTimesheetBillableBlockModal, jobMissingDateModal};
  const bodyProps = {job, timesheetUpdatedMutation, refetch, responsive};
  const headerText = 'Job Timesheet';

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={TimesheetsContent}
          bodyComponentProps={bodyProps}
          ActionButtonComponent={DesktopHeaderButtons}
          actionButtonComponentProps={actionsProps}
          title={headerText}
          style={{flex: 1, width: '100%'}}
          bodyStyle={{paddingHorizontal: 0}}
        />
      ) : (
        <React.Fragment>
          <MobileProjectBlockHeader
            title={headerText}
            ActionsComponent={MobileActionsButton}
            actionsComponentProps={actionsProps}
            style={{paddingHorizontal: 16}}
          />
          <TimesheetsContent {...bodyProps} />
        </React.Fragment>
      )}
      <AddTimesheetBillableBlockModal
        key={addTimesheetBillableBlockModal.key}
        isOpen={addTimesheetBillableBlockModal.isOpen}
        handleClose={addTimesheetBillableBlockModal.handleClose}
        job={job}
        refetch={refetch}
        onUpdate={timesheetUpdatedMutation.handleSubmit}
      />
      <JobMissingDateModal
        key={jobMissingDateModal.key}
        job={job}
        projectTypeId={project.projectTypeId}
        isOpen={jobMissingDateModal.isOpen}
        handleClose={jobMissingDateModal.handleClose}
        onSuccess={() => {
          addTimesheetBillableBlockModal.handleOpen();
          refetch();
        }}
        messageExtension={' before adding time'}
      />
    </React.Fragment>
  );
};

const JobJobTimesheetBlockQuery = ({
  jobUuid,
  project,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  pageRefetch,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={JobJobTimesheetBlock.query}
      layoutKey={blockToPositionY[ProjectBlockKind.Job.JOB_TIMESHEET] || layoutKey}
      queryVariables={{jobUuid}}
      handleSetPositionY={handleSetPositionY}
      style={responsive.desktop ? null : {paddingVertical: 24}}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: any) => {
        return (
          <JobJobTimesheetBlockContent
            job={data.job}
            project={project}
            refetch={() => {
              refetch();
              pageRefetch();
            }}
            responsive={responsive}
          />
        );
      }}
    </ProjectBlockWrapper>
  );
};

const JobJobTimesheetBlock = ({
  project,
  jobUuid,
  blockToPositionY,
  handleSetPositionY,
  layoutKey,
  index,
  pageRefetch,
  projectBlockKind,
}: any) => {
  return (
    <JobJobTimesheetBlockQuery
      key={project.jobUsersHash}
      jobUuid={jobUuid}
      project={project}
      blockToPositionY={blockToPositionY}
      handleSetPositionY={handleSetPositionY}
      layoutKey={layoutKey}
      index={index}
      pageRefetch={pageRefetch}
      projectBlockKind={projectBlockKind}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobJobTimesheetBlock.listener = gql`
  fragment JobJobTimesheetBlock_listener on Project {
    id
    jobUsersHash
  }
`;

JobJobTimesheetBlock.fragment = gql`
  fragment JobJobTimesheetBlock on Project {
    id
    projectTypeId
  }
`;

JobJobTimesheetBlock.query = gql`
  ${AddTimesheetBillableBlockModal.fragment}
  ${JobTimesheetBillableEntries.fragment}
  ${JobTimetable.fragment}
  ${JobActionDisabledTooltip.fragment}
  ${JobMissingDateModal.fragment}

  query JobJobTimesheetBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      uuid
      startDate
      isFinal
      timesheetBillableEntries {
        id
      }
      ...AddTimesheetBillableBlockModal
      ...JobTimesheetBillableEntries
      ...JobTimetable
      ...JobActionDisabledTooltip
      ...JobMissingDateModal
    }
  }
`;

export default JobJobTimesheetBlock;
