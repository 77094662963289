// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TreeNavigation from '@shared/design/components/TreeNavigation';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const getTreeNavigationItems = ({jobType}: any) => {
  const variablesBlocks = jobType
    ? jobType.jobTypeVariableSections.map((variableSection: any) => ({
        label: variableSection.name,
        value: _.snakeCase(variableSection.name),
        valueKey: 'block',
      }))
    : [];

  return ProjectBlockKind.EditJobBlocks.map((block) =>
    block === ProjectBlockKind.VARIABLES
      ? variablesBlocks
      : {
          label: ProjectBlockKind.getDisplay(block),
          value: block,
          valueKey: 'block',
        },
  ).flat();
};

const ProjectJobNavigationContainer = Styled.View`
  width: 200px;
  background-color: ${colors.white};
`;

const NavigationSectionButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
  padding-horizontal: 24px;
`;

const LoadingComponent = () => {
  return (
    <ProjectJobNavigationContainer>
      <Space height={24} />
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
      <NavigationSectionButton disabled>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} style={{flex: 1}} />
      </NavigationSectionButton>
    </ProjectJobNavigationContainer>
  );
};

const ProjectJobNavigation = ({urlFilters, form, field}: any) => {
  const {params} = useNavigationDOM();

  const jobTypeId = _.get(form.values, `${field}.jobTypeId`);
  const {data, loading} = useQuery(ProjectJobNavigation.query, {
    fetchPolicy: 'cache-first',
    skip: !jobTypeId,
    variables: {jobTypeId},
  });

  return (
    <Loading loading={loading} as={LoadingComponent}>
      {() => {
        return (
          <ProjectJobNavigationContainer>
            <Space height={24} />
            <TreeNavigation
              navigationItems={getTreeNavigationItems({jobType: data?.jobType})}
              handleSetValues={({block, variableSection}: any) =>
                urlFilters.handleUpdate({block, variableSection})
              }
              values={params}
              width={200}
            />
          </ProjectJobNavigationContainer>
        );
      }}
    </Loading>
  );
};

ProjectJobNavigation.SkeletonLoader = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobNavigation.query = gql`
  query ProjectJobNavigation($jobTypeId: Int!) {
    ${gql.query}
    jobType: jobTypeById(jobTypeId: $jobTypeId) {
      id
      jobTypeVariableSections {
        id
        name
      }
    }
  }
`;

export default ProjectJobNavigation;
