// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import AddProjectJobsButton from 'modules/Project/components/AddProjectJobsButton';

const Container = Styled.View`
  border-bottom-color: ${colors.gray.border};
  border-bottom-width: 1px;
  z-index: 800;
`;

const Row = Styled.View`
  flex-direction: row;
  height: 60px;
  z-index: 100;
`;

const Side = Styled.View`
  justify-content: center;
  width: ${(props) => (props as any).width}px;
`;

const BackLink = Styled.Touchable`
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const BackLinkText = Styled.Text`
  ${Typography.Body1}
  color: ${colors.gray.primary};
`;

const Links = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const JobLink = Styled.Touchable`
`;

const JobLinkTextContainer = Styled.View<{color: string}>`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({color}) => color};
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const JobLinkText = Styled.Text<{color: string}>`
  ${Typography.Label4}
  color: ${({color}) => color};
`;

const JobLine = Styled.View<{isSelected: boolean}>`
  margin-top: 15px;
  height: 3px;
  background-color: ${(props) => ((props as any).isSelected ? colors.blue.accentDark : colors.transparent)};
`;

const Actions = Styled.View`
  flex-direction: row;
  align-self: flex-end;
  margin-horizontal: 10px;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary};
  letter-spacing: 0.5;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const RangeJobConnector = Styled.View<{mobile: boolean}>`
  width: ${({mobile}) => (mobile ? '8px' : '16px')};
  height: 4px;
  background-color: ${colors.gray.border};
  align-self: center;
`;

const ActionsButton = ({onPress}: any) => {
  const responsive = useResponsive();

  return (
    <Button color={colors.white} onPress={onPress}>
      {!responsive.mobile && <ButtonText style={{marginRight: 5}}>Actions</ButtonText>}
      <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.AngleDown} />
    </Button>
  );
};

const getJobUrl = ({jobUuid, params}: any) => {
  const isOverrideV1 = _.includes(Object.keys(params), 'overrideV1');
  const urlParamString = isOverrideV1 ? '?overrideV1' : '';
  return `/jobs/${jobUuid}${urlParamString}`;
};

const JobProjectNavigation = ({viewer, job: selectedJob, handleOpenSidebar, refetch}: any) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const width = responsive.mobile ? null : 270;
  const jobs = selectedJob.project
    ? selectedJob.project.activeJobsExcludingChildJobs
    : [selectedJob];
  const sortedJobs = Job.sortJobsBySequence(jobs);

  return (
    <Container data-test-id='job-project-navigation'>
      <Row responsive={responsive}>
        <Side width={width}>
          <BackLink onPress={() => navigator.goBack()}>
            <BackLinkText>{responsive.mobile ? '←' : '← Back'}</BackLinkText>
          </BackLink>
        </Side>
        <ScrollView horizontal contentContainerStyle={{minWidth: '100%', justifyContent: 'center'}}>
          <Links>
            {sortedJobs.map((job, index) => {
              const isFirstJob = index === 0;
              const isSelectedJob = selectedJob.id === (job as any).id;
              const isEstimatedRange = Job.getIsEstimatedRange(job);
              if (isEstimatedRange) {
                return (
                  <React.Fragment key={(job as any).id}>
                    {(job as any).jobsForEstimatedRange.map((rangeJob: any, rangeJobIndex: any) => {
                      const isSelectedRangeJob = selectedJob.id === rangeJob.id;
                      const isParentJob = rangeJobIndex === 0;
                      return (
                        <React.Fragment key={rangeJob.id}>
                          {!isFirstJob && isParentJob && (
                            <Space width={responsive.mobile ? 16 : 32} />
                          )}
                          {!isParentJob && <RangeJobConnector {...responsive} />}
                          <JobLink
                            onPress={() =>
                              navigator.replace(getJobUrl({jobUuid: rangeJob.uuid, params}))
                            }
                            responsive={responsive}
                          >
                            <JobLinkTextContainer
                              color={
                                isSelectedRangeJob ? colors.blue.accentDark : colors.gray.border
                              }
                            >
                              <JobLinkText
                                color={
                                  isSelectedRangeJob
                                    ? colors.blue.accentDark
                                    : colors.gray.secondary
                                }
                              >
                                {rangeJob.shortName}
                              </JobLinkText>
                            </JobLinkTextContainer>
                            <JobLine isSelected={isSelectedRangeJob} />
                          </JobLink>
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              }
              return (
                <React.Fragment key={(job as any).id}>
                  {!isFirstJob && <Space width={responsive.mobile ? 16 : 32} />}
                  <JobLink
                    onPress={() =>
                      navigator.replace(getJobUrl({jobUuid: (job as any).uuid, params}))
                    }
                    responsive={responsive}
                  >
                    <JobLinkTextContainer
                      color={isSelectedJob ? colors.blue.accentDark : colors.gray.border}
                    >
                      <JobLinkText
                        color={isSelectedJob ? colors.blue.accentDark : colors.gray.secondary}
                      >
                        {(job as any).shortName}
                      </JobLinkText>
                    </JobLinkTextContainer>
                    <JobLine isSelected={isSelectedJob} />
                  </JobLink>
                </React.Fragment>
              );
            })}
          </Links>
        </ScrollView>
        <Side width={width}>
          <Actions>
            <AddProjectJobsButton
              jobId={selectedJob.id}
              project={selectedJob.project}
              refetch={refetch}
            />
            {viewer.viewingOrganization.features.isEnabledActionPanel && (
              <React.Fragment>
                <ButtonSpace />
                <ActionsButton onPress={handleOpenSidebar} />
              </React.Fragment>
            )}
          </Actions>
        </Side>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectNavigation.fragment = gql`
  ${Job.getIsEstimatedRange.fragment}
  ${Job.sortJobsBySequence.fragment}
  ${AddProjectJobsButton.fragment}

  fragment JobProjectNavigation_Viewer on User {
    id
    role
    viewingOrganization {
      id
      features {
        isEnabledActionPanel: isEnabled(feature: "ACTION_PANEL")
      }
      managerJobActionsPanelBlock: block(kind: "MANAGER_JOB_ACTIONS_PANEL") {
        kind
      }
    }
  }

  fragment JobProjectNavigation_Job on Job {
    id
    uuid
    kind
    project {
      id
      uuid
      activeJobsExcludingChildJobs {
        id
        uuid
        isCancelled
        kind
        shortName
        startDate
        startTime1
        day {
          id
          value
        }
        jobsForEstimatedRange {
          id
          uuid
          isCancelled
          kind
          shortName
        }
        ...Job_getIsEstimatedRange
        ...Job_sortJobsBySequence
      }
      ...AddProjectJobsButton
    }
  }
`;

export default JobProjectNavigation;
