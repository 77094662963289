// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PercentInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useResponsive} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Percent} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import Line from '@shared/design/components/Line';
import RadioButton from '@shared/design/components/RadioButton';
import PaymentMethodKind, {
  PaymentMethodKindType,
  PaymentMethodOptionType,
} from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import {PaymentMethodFormToFormType} from '@shared/modules/PaymentMethod/forms/PaymentMethodForm';
import {ProjectTypePaymentMethodFormType} from '@shared/modules/PaymentMethod/forms/ProjectTypePaymentMethodForm';

const Subheading = Styled.Text`
    ${Typography.Responsive.Subheading};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
`;

const PaymentFeeFields = ({
  form,
  field,
}: {
  form: Form<{paymentMethodForm: PaymentMethodFormToFormType}>;
  field: string;
}) => {
  const isDiscount = _.get(form.values, `${field}.isDiscount`);
  return (
    <React.Fragment>
      <Row>
        <FieldInput
          {...form}
          name={`${field}.feePercentage`}
          label={'Payment Fee'}
          component={PercentInput}
          isResponsive
          input={{
            component: FieldInput.TextInput,
            placeholder: 'Enter a percentage',
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            onBlur: (event: any) => {
              const value = _.get(event, 'target.value');
              if (value) {
                setImmediate(() =>
                  form.setFieldValue(
                    `${field}.feePercentage`,
                    Percent.toForm(Math.abs(Percent.toFloat(value))),
                  ),
                );
              }
            },
            style: {
              width: '100%',
            },
          }}
          style={{flex: 1}}
        />
        <Space width={16} />
        <Column style={{justifyContent: 'flex-end'}}>
          <RadioButton
            isOn={!isDiscount}
            onPress={() => form.setFieldValue(`${field}.isDiscount`, false)}
            label={'Fee'}
          />
          <Space height={4} />
          <RadioButton
            isOn={isDiscount}
            onPress={() => form.setFieldValue(`${field}.isDiscount`, true)}
            label={'Discount'}
          />
        </Column>
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const PaymentMethodFields = ({
  form,
  field,
  organization,
  isUpdate,
}: {
  form: Form<{paymentMethodForm: PaymentMethodFormToFormType}>;
  field: string;
  organization: OrganizationModel;
  isUpdate?: boolean;
}) => {
  const responsive = useResponsive();
  const projectTypePaymentMethodsField = `${field}.projectTypePaymentMethodForms`;
  const projectTypePaymentMethodForms = _.get(form.values, projectTypePaymentMethodsField, []);

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.kind`}
        label={'Payment Method'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          isDisabled: isUpdate,
          isPortaled: true,
          placeholder: 'Select payment method',
          options: PaymentMethodKind.getDropdownOptions({organization}),
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          onChangeValue: (
            value: PaymentMethodKindType,
            option: PaymentMethodOptionType,
            prevValue: PaymentMethodKindType,
          ) => {
            // Set name to default value if it is empty or same as previous value (a non-custom name)
            const name = _.get(form.values, `${field}.name`);
            const prevName = PaymentMethodKind.getName(prevValue);
            if (!name || name === prevName) {
              form.setFieldValue(`${field}.name`, PaymentMethodKind.getName(value));
            }
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter name',
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <PaymentFeeFields form={form} field={field} />
      <Line />
      <Space height={16} />
      <Subheading responsive={responsive}>Office App</Subheading>
      <Space height={16} />
      <SwitchField
        form={form}
        field={`${field}.isEnabledForOffice`}
        labelRight={'Enable for Office App'}
        hint={'When on, this payment method will be enabled for all payments on Office App.'}
        isResponsive
      />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <Subheading responsive={responsive}>Crew App</Subheading>
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Enabled for These Project Types'}
        isResponsive
        component={MultiDropdownCheckboxInput}
        input={{
          isResponsive: true,
          isSearchable: true,
          isEnabledSelectAll: true,
          usePills: true,
          isPortaled: true,
          placeholder: 'Select project types',
          options: organization.moveProjectTypes.map((projectType) => ({
            label: projectType.name,
            value: projectType.id,
          })),
          value: projectTypePaymentMethodForms
            .filter(
              (projectTypePaymentMethod: ProjectTypePaymentMethodFormType) =>
                projectTypePaymentMethod.isEnabled,
            )
            .map((projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) =>
              _.toString(projectTypePaymentMethodForm.projectTypeId),
            ),
          onChangeValue: (newValue: unknown[]) => {
            const newProjectTypePaymentMethodForms = projectTypePaymentMethodForms.map(
              (projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) => ({
                ...projectTypePaymentMethodForm,
                isEnabled: newValue.includes(
                  _.toString(projectTypePaymentMethodForm.projectTypeId),
                ),
              }),
            );
            form.setFieldValue(projectTypePaymentMethodsField, newProjectTypePaymentMethodForms);
          },
          setFieldValue: () => {},
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
      <SwitchField
        form={form}
        field={`${field}.isEnabledForNewProjectTypes`}
        labelRight={'Enable for all new project types'}
        hint={
          'When on, this payment method will be enabled for all new project types on the Crew App.'
        }
        isResponsive
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentMethodFields.fragment = gql`
  ${PaymentMethodKind.getDropdownOptions.fragment}
  fragment PaymentMethodFields on Organization {
    id
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      name
    }
    ...PaymentMethodKind_getDropdownOptions
  }
`;

export default PaymentMethodFields;
