// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CommentForm from '@shared/modules/Comment/forms/CommentForm';

const useCreateCommentMutation = ({commentForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      commentForm: CommentForm.toForm(commentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateCommentMutation.mutation,
    variables: {
      commentForm: CommentForm.toMutation(form.values.commentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// NOTE(cooper): Fragments imported here ensure that data needed on these components
// gets refreshed without needing to manually refetch other queries
// NOTE(dan): We've removed the fragments to not import from the app level to
// the shared level. The mutation response is utilized by manager app files:
// JobCommentsBlock, MoveProjectsTable, and ProjectsList. These three files all
// use the ProjectCommentsSidebar, which uses this mutation. When ProjectCommentsSidebar
// saves a new comment, this mutation response updates the most recent comment and comment
// counts for these parent components.
// --------------------------------------------------
useCreateCommentMutation.mutation = gql`
  mutation useCreateCommentMutation($commentForm: CommentForm!) {
    response: createComment(commentForm: $commentForm) {
      ${gql.errors}
      comment {
        id
        thread {
          id
          commentsCount
          mostRecentComment {
            id
            subject
            body
            createdAt
            user {
              id
              fullName
            }
          }
        }
      }
    }
  }
`;

export default useCreateCommentMutation;
