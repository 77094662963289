/* eslint-disable no-unused-vars */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Styled, Space, Icon} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import InputIsRequiredCheckBoxV1 from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBoxV1';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 8px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const HelperText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const RadioButtonContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const getOptions = (maxValue: any) => {
  const options = [];
  for (let i = 1; i <= maxValue; i += 1) {
    options.push({label: i, value: `${i}`});
  }
  return options;
};

const addFieldInput = ({setDraftDocumentContentJson, documentItemIndex}: any) => {
  setDraftDocumentContentJson((prevState: any) => {
    prevState.documentItems[documentItemIndex].input.value.values.push(['', '', '']);
    prevState.documentItems[documentItemIndex].input.metadata.items.push({
      options: [],
      inputs: {},
    });
    return {
      ...prevState,
    };
  });
};

const removeFieldInput = ({setDraftDocumentContentJson, documentItemIndex, fieldIndex}: any) => {
  setDraftDocumentContentJson((prevState: any) => {
    prevState.documentItems[documentItemIndex].input.value.values.splice(fieldIndex, 1);
    prevState.documentItems[documentItemIndex].input.metadata.items.splice(fieldIndex, 1);
    return {
      ...prevState,
    };
  });
};

const InputRadioButtonsWithTextInputsEditorV1 = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
}: any) => {
  const {values} = documentItem.input.value;
  const {items} = documentItem.input.metadata;
  const initialOptionChoicesAndInput = items.map((currentItem: any) => {
    const lowerCaseOptions = currentItem.options.map((option: any) =>
      _.camelCase(option.toLowerCase()),
    );
    return {
      optionNum: currentItem.options.length ? currentItem.options.length : '',
      optionNumInputIndex: lowerCaseOptions.indexOf(Object.keys(currentItem.inputs)[0]),
    };
  });

  return (
    <React.Fragment>
      <InputIsRequiredCheckBoxV1
        documentItem={documentItem}
        documentItemIndex={documentItemIndex}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {values.map((value: any, valueIndex: any) => (
        // @ts-expect-error TS(2769): No overload matches this call.
        <RadioButtonContainer key={valueIndex} index={valueIndex}>
          <Text>{`Radio Button ${valueIndex + 1}`}</Text>
          <Space height={8} />
          <Row>
            <TextInput
              key={valueIndex}
              placeholder={'Enter radio button title'}
              value={value[0]}
              multiline
              onChangeText={(text: any) => {
                setDraftDocumentContentJson((prevState: any) => {
                  prevState.documentItems[documentItemIndex].input.value.values[valueIndex][0] =
                    text;
                  return {
                    ...prevState,
                  };
                });
              }}
            />
            {values.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemIndex,
                      fieldIndex: valueIndex,
                    })
                  }
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={24} />
          <FieldInput
            value={`${initialOptionChoicesAndInput[valueIndex].optionNum}`}
            label={'Choices'}
            component={DropdownInput}
            input={{
              style: {width: 250},
              options: getOptions(10),
              setFieldValue: (name: any, text: any) => {
                const number = Number(text);
                setDraftDocumentContentJson((prevState: any) => {
                  const {metadata} = prevState.documentItems[documentItemIndex].input;
                  const options = [];

                  for (let optionsLength = 0; optionsLength <= number - 1; optionsLength += 1) {
                    const currentOption = metadata.items[valueIndex].options[optionsLength];
                    options.push(currentOption || '');
                  }

                  metadata.items[valueIndex].options = options;
                  metadata.items[valueIndex].inputs = metadata.items[valueIndex].inputs || {};

                  return {
                    ...prevState,
                  };
                });
              },
            }}
          />
          <Space height={8} />
          <HelperText>{`If 'add input' is selected, an input field will appear below the choice.`}</HelperText>
          <Space height={8} />
          {items[valueIndex].options.map((option: any, optionIndex: any) => {
            const isChecked =
              initialOptionChoicesAndInput[valueIndex].optionNumInputIndex === optionIndex;
            return (
              <React.Fragment key={optionIndex}>
                <Row>
                  <FieldInput
                    key={`${optionIndex} option name`}
                    style={{width: '60%'}}
                    input={{
                      placeholder: `Option ${optionIndex + 1}`,
                      onChangeText: (text: any) => {
                        setDraftDocumentContentJson((prevState: any) => {
                          const currentMetadataItem =
                            prevState.documentItems[documentItemIndex].input.metadata.items[
                              valueIndex
                            ];
                          currentMetadataItem.options[optionIndex] = text;
                          return {
                            ...prevState,
                          };
                        });
                      },
                    }}
                    value={option}
                  />
                  <Space width={12} />
                  <Checkbox
                    key={`${optionIndex} add input`}
                    isChecked={isChecked}
                    isDisabled={option === ''}
                    handleToggle={() => {
                      setDraftDocumentContentJson((prevState: any) => {
                        const prevItemState =
                          prevState.documentItems[documentItemIndex].input.metadata.items[
                            valueIndex
                          ];
                        const getInputKey = items[valueIndex].options[optionIndex];

                        if (isChecked) {
                          prevItemState.inputs = {};
                        } else {
                          prevItemState.inputs = {[_.camelCase(getInputKey.toLowerCase())]: true};
                        }

                        return {
                          ...prevState,
                        };
                      });
                    }}
                    childrenRight
                  >
                    <Space width={8} />
                    <Text>{'Add input'}</Text>
                  </Checkbox>
                </Row>
                <Space height={8} />
              </React.Fragment>
            );
          })}
          <Space height={32} />
        </RadioButtonContainer>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemIndex})}
      />
    </React.Fragment>
  );
};

export default InputRadioButtonsWithTextInputsEditorV1;
