// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Config} from '@supermove/sdk';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// Components
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-self: stretch;
  margin-bottom: 40px;
  padding-horizontal: ${(props) => ((props as any).mobile ? 12 : 24)}px;
`;

const Title = Styled.H2`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Subtitle = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const SmallSection = Styled.View`
`;

const SmallSectionSpace = Styled.View`
  margin-top: 10px;
`;

const BoldText = Styled.H7`
  margin-top: 10px;
  ${fontWeight(700)}
`;

const Text = Styled.H7`
  margin-top: 10px;
  color: ${colors.gray.primary};
`;

const ConnectButton = Styled.Touchable`
  margin-top: 10px;
`;

const ConnectImage = Styled.Image`
  width: 200px;
  height: 35px;
`;

const StripeMessageText = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
`;

const StripeMessageError = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
  color: ${colors.Pink600};
`;

const Actions = Styled.View`
  margin-top: 10px;
  flex-direction: row;
`;

const Button = Styled.Button`
  width: 200px;
  height: 35px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const getQuickBooksURL = ({slug}: any) => {
  const clientId = 'ABAVjG5PLSVrNKkILXmJTm1xWH31BaIjGROcKKqYxTCRUGuwES';
  const responseType = 'code';
  const scope = 'com.intuit.quickbooks.accounting';
  const redirectUri = `https%3A%2F%2Fapi.supermove.co%2Fqb%2Fv1%2Foauth&state=${slug}`;

  return (
    `https://appcenter.intuit.com/connect/oauth2?` +
    `client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`
  );
};

const QuickbooksSection = ({sectionIndex, organization}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section sectionIndex={sectionIndex}>
      <Subtitle>QuickBooks</Subtitle>
      <Actions>
        <Button
          onPress={() => {
            const url = getQuickBooksURL({slug: organization.slug});
            window.location.href = url;
          }}
        >
          <ButtonText>Connect to QuickBooks</ButtonText>
        </Button>
      </Actions>
    </Section>
  );
};

const navigateToStripeConnect = () => {
  const clientId = Config.getStripeClientId();
  window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write`;
};

const getSearchParams = ({location}: any) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const saveStripeCredentials = ({code, error, errorDescription, organization, scope}: any) => {
  if (scope !== 'read_write') {
    return;
  }

  const query = `mutation AddOrganizationStripeAccountCredential(
    $organizationId: Int!,
    $authorizationCode: String!,
  ) {
    addOrganizationStripeAccountCredential(
      organizationId: $organizationId,
      authorizationCode: $authorizationCode,
    ) {
      organization {
        id
      }
      account {
        id
      }
      error
    }
  }`;

  fetch(`${Config.getAPIHost()}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: Json.stringify({
      query,
      variables: {
        organizationId: organization.id,
        authorizationCode: code,
      },
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (data.error) {
        window.location.href = '/settings/external?error=supermove';
      } else {
        window.location.href = '/settings/external?code=supermove';
      }
    });
};

const StripeAccount = ({organization}: any) => {
  return (
    <SmallSection>
      <BoldText>Existing Stripe Account</BoldText>
      {Organization.hasStripeAccountId(organization) ? (
        <React.Fragment>
          <Text>
            {`You have a Stripe account connected with Supermove (Stripe account id: ${Organization.getStripeAccountId(
              organization,
            )}).`}
          </Text>
          <a
            target={'_blank'}
            href={'https://dashboard.stripe.com/dashboard'}
            style={{
              marginTop: 10,
            }}
          >
            <Button>
              <ButtonText>Go to Stripe</ButtonText>
            </Button>
          </a>
        </React.Fragment>
      ) : (
        <Text>You do NOT have a Stripe account connected with Supermove.</Text>
      )}
    </SmallSection>
  );
};

/**
 * On a successful Stripe Connect authentication, Stripe will
 * send us the query parameters 'code' and 'scope'.
 *
 * On an unsuccessful Stripe Connect authentication, Stripe will
 * send us the query parameters 'error' and 'error_description'.
 */
const StripeAuthenticationMessage = ({code, error, errorDescription, organization, scope}: any) => {
  let message;
  if (code) {
    if (code === 'supermove') {
      message = 'Successfully connected your account with Supermove.';
    } else if (scope === 'read_write') {
      message = 'Verifying the connection to your account...';
    } else {
      message = 'Please grant Supermove both Read & Write access to your Stripe account.';
    }
  } else if (error) {
    if (error === 'access_denied') {
      message = 'Please grant Supermove access to your Stripe account.';
    } else if (error === 'supermove') {
      message = 'Something went wrong.';
    } else {
      message = `${error}: ${errorDescription}`;
    }
  }

  if (message) {
    if (message.indexOf('Please') === 0) {
      return <StripeMessageError>{message}</StripeMessageError>;
    } else {
      return <StripeMessageText>{message}</StripeMessageText>;
    }
  } else {
    return null;
  }
};

const StripeConnect = ({organization, searchParams}: any) => {
  return (
    <Lifecycle onMount={() => saveStripeCredentials({organization, ...searchParams})}>
      <SmallSection>
        <BoldText>Connect Stripe Account</BoldText>
        <Text>
          Connect a Stripe Connect account (this will replace an already connected account).
        </Text>
        <ConnectButton onPress={() => navigateToStripeConnect()}>
          <ConnectImage
            resizeMode={'contain'}
            source={require('./assets/stripe-connect-button.png')}
          />
        </ConnectButton>
        <StripeAuthenticationMessage organization={organization} {...searchParams} />
      </SmallSection>
    </Lifecycle>
  );
};

const StripeSection = ({sectionIndex, organization, searchParams}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section sectionIndex={sectionIndex}>
      <Subtitle>Stripe</Subtitle>
      <StripeAccount organization={organization} />
      <SmallSectionSpace />
      <StripeConnect organization={organization} searchParams={searchParams} />
    </Section>
  );
};

const OrganizationSettingsExternalPage = () => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <SidebarPage selected={'settings'} query={OrganizationSettingsExternalPage.query}>
      {({data}: any) => (
        <Wrapper>
          <StandardOfficeHeader title={'Settings'} />
          <ScrollView style={{flex: 1}}>
            <Container {...responsive}>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Section sectionIndex={0}>
                <Title>3rd Party Settings</Title>
              </Section>
              <SectionSpace />
              <StripeSection
                sectionIndex={1}
                organization={data.viewer.viewingOrganization}
                searchParams={getSearchParams({location: navigator.location})}
              />
              <SectionSpace />
              <QuickbooksSection sectionIndex={2} organization={data.viewer.viewingOrganization} />
            </Container>
          </ScrollView>
        </Wrapper>
      )}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsExternalPage.query = gql`
  ${Organization.getStripeAccountId.fragment}
  ${Organization.hasStripeAccountId.fragment}
  query OrganizationSettingsBusinessInfoPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        ...Organization_getStripeAccountId
        ...Organization_hasStripeAccountId
      }
    }
  }
`;

export default OrganizationSettingsExternalPage;
