// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {FileDragInput, Styled, Space, ScrollView} from '@supermove/components';
import {useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import BulkStorageCustomersForm from '@shared/modules/Storage/forms/BulkStorageCustomersForm';
import useUpsertBulkStorageProjectsAndCustomersMutation from '@shared/modules/Storage/hooks/useUpsertBulkStorageProjectsAndCustomersMutation';

const FileDragInputContainer = Styled.View`
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-style: dotted;
  border-width: 2px;
  cursor: pointer;
  padding: 12px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isHighlighted' does not exist on type 'T... Remove this comment to see the full error message
    isHighlighted,
  }) => (isHighlighted ? colors.blue.hover : colors.gray.tertiary)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isHighlighted' does not exist on type 'T... Remove this comment to see the full error message
    isHighlighted,
  }) => (isHighlighted ? colors.gray.background : colors.white)};
`;

const FileDragInputText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
`;

const FileNameText = Styled.Text`
  ${Typography.Body}
  color: ${colors.blue.interactive};
`;

const ValidationError = Styled.Text`
  ${Typography.Body}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const ErrorContainer = Styled.View`
  max-height: 200px;
`;

const UploadInput = ({form, field, setValidationErrors}: any) => {
  return (
    <FileDragInput
      onFilesChange={(files) => {
        setValidationErrors([]);
        form.setFieldValue(`${field}.data`, files[0]);
      }}
    >
      {({isDragActive}) => (
        // @ts-expect-error TS(2769): No overload matches this call.
        <FileDragInputContainer isHighlighted={isDragActive}>
          {!_.get(form.values, `${field}.data`) ? (
            <FileDragInputText>
              {isDragActive ? 'Drop the file here' : 'Drag a file or click to upload'}
            </FileDragInputText>
          ) : (
            <FileNameText>{_.get(form.values, `${field}.data`).name}</FileNameText>
          )}
        </FileDragInputContainer>
      )}
    </FileDragInput>
  );
};

const BulkUploadStorageProjectAndCustomerModal = ({isOpen, handleClose}: any) => {
  const bulkStorageCustomersForm = BulkStorageCustomersForm.new();
  const field = 'bulkStorageCustomersForm';

  const successToast = useToast({
    ToastComponent: SuccessToast,
    message:
      'Uploading .csv in the background. You can leave this page and view projects in storage.',
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const [validationErrors, setValidationErrors] = useState([]);

  const {form, handleSubmit, submitting} = useUpsertBulkStorageProjectsAndCustomersMutation({
    bulkStorageCustomersForm,
    onSuccess: () => {
      successToast.handleToast();
      handleClose();
    },
    onError: (errors: any) => {
      setValidationErrors(errors);
    },
  });

  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen}>
      <SmallModal.BoldText>Import Customers from CSV</SmallModal.BoldText>
      <Space height={16} />
      <React.Fragment>
        <Space height={8} />
        <FieldInput
          {...form}
          name={`${field}.data`}
          component={UploadInput}
          input={{
            form,
            field,
            setValidationErrors,
          }}
          showErrorMessage={false}
        />
        <Space height={4} />
        <ErrorContainer>
          <ScrollView>
            {!_.isEmpty(validationErrors) && (
              <React.Fragment>
                <ValidationError>{`Error: This file could not be uploaded. Please review the following ${pluralize(
                  'error',
                  validationErrors.length,
                  true,
                )}.`}</ValidationError>
                {validationErrors.map((error, index) => {
                  return (
                    <ValidationError key={index}>{`• ${(error as any).message}`}</ValidationError>
                  );
                })}
              </React.Fragment>
            )}
          </ScrollView>
        </ErrorContainer>
        <Space height={8} />
      </React.Fragment>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Upload
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default BulkUploadStorageProjectAndCustomerModal;
