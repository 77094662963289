// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateConfirmationCardMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateConfirmationCardMutation' implicitly has ty... Remove this comment to see the full error message
const UpdateConfirmationCardMutation = ({
  confirmationId,
  paymentMethodId,
  payengineCreditCardId,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
    variables={{
      confirmationId,
      paymentMethodId,
      payengineCreditCardId,
    }}
    mutation={UpdateConfirmationCardMutation.mutation}
    onCompleted={({response: {confirmation, errors}}: any) => {
      if (!errors) {
        onSuccess(confirmation);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateConfirmationCardMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateConfirmationCardMutation.mutation = gql`
  mutation UpdateConfirmationCardMutation(
    $confirmationId: Int!,
    $paymentMethodId: String,
    $payengineCreditCardId: Int,
  ) {
    response: updateConfirmationCard(
      confirmationId: $confirmationId,
      paymentMethodId: $paymentMethodId,
      payengineCreditCardId: $payengineCreditCardId,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default UpdateConfirmationCardMutation;
