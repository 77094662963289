// DEPRECATED

// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  user?: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateSuperAdminImpersonateMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateSuperAdminImpersonateMutation' implicitly h... Remove this comment to see the full error message
const UpdateSuperAdminImpersonateMutation = ({user, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      userId: user ? user.id : null,
    }}
    mutation={UpdateSuperAdminImpersonateMutation.mutation}
    onCompleted={({response: {viewer, errors}}: any) => {
      if (viewer) {
        onSuccess(viewer);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

UpdateSuperAdminImpersonateMutation.defaultProps = {
  user: null,
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuperAdminImpersonateMutation.mutation = gql`
  mutation UpdateSuperAdminImpersonateMutation(
    $userId: Int,
  ) {
    response: updateSuperAdminImpersonate(
      userId: $userId,
    ) {
      ${gql.errors}
      viewer {
        id
        role
      }
    }
  }
`;

export default UpdateSuperAdminImpersonateMutation;
