// Libraries
import React from 'react';

// Supermove
import {Icon, Styled, ZoomView} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import {ViewDocumentModal} from 'modules/Document/components';

const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '796px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 65px;
  padding-horizontal: 10px;
  z-index: 1;
`;

const Touchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding-vertical: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Text = Styled.H6`
  margin-right: 6px;
`;

const Content = Styled.View`
  width: 792px;
  padding-vertical: 36px;
  padding-horizontal: 36px;
  background-color: ${colors.white};
`;

const PrintButton = ({printLink}: any) => (
  <a target={'_blank'} href={printLink}>
    <Touchable>
      <Text>Print</Text>
      <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Print} />
    </Touchable>
  </a>
);

type OwnPreviewDocumentProps = {
  canFullscreen?: boolean;
  canZoom?: boolean;
  printLink?: string;
  title?: string;
};

// @ts-expect-error TS(2456): Type alias 'PreviewDocumentProps' circularly refer... Remove this comment to see the full error message
type PreviewDocumentProps = OwnPreviewDocumentProps & typeof PreviewDocument.defaultProps;

/*
 * The `<Content>` wrapper around `children` simulates the
 * margin of a printed PDF. We cannot simply put padding
 * within the documents (`children` in this case) themselves
 * because such padding will only apply to the whole document,
 * not each page of the document if it spans multiple pages.
 */
// @ts-expect-error TS(7022): 'PreviewDocument' implicitly has type 'any' becaus... Remove this comment to see the full error message
const PreviewDocument = ({
  canFullscreen,
  canZoom,
  printLink,
  title,
  style,
  children,
}: PreviewDocumentProps) => {
  const responsive = useResponsive();
  const scale = responsive.mobile ? 0.5 : 1;

  return (
    <Container style={style} {...responsive}>
      <Header>
        {!responsive.mobile && printLink && <PrintButton printLink={printLink} />}
        {canFullscreen && (
          <ViewDocumentModal
            title={title}
            trigger={({handleOpen}: any) => (
              <Touchable onPress={handleOpen} style={{marginLeft: 10}}>
                <Text>Fullscreen</Text>
                <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Expand} />
              </Touchable>
            )}
          >
            {children}
          </ViewDocumentModal>
        )}
      </Header>
      {canZoom ? (
        <ZoomView
          defaultScale={scale}
          defaultPositionX={0}
          defaultPositionY={0}
          minScale={scale}
          centerContent={false}
          wrapperClass={responsive.mobile ? 'react-zoom-mobile-document' : ''}
        >
          <Content>{children}</Content>
        </ZoomView>
      ) : (
        <Content>{children}</Content>
      )}
    </Container>
  );
};

PreviewDocument.defaultProps = {
  canFullscreen: false,
  canZoom: true,
  printLink: null,
  title: 'View Document',
};

export default PreviewDocument;
