// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

import Tabs from '@shared/design/components/Tabs';

const getJobTypesTab = (jobType: any) => {
  const {
    uuid: jobTypeUuid,
    projectType: {uuid: projectTypeUuid},
  } = jobType;

  return [
    {
      label: 'Configuration',
      url: `/settings/projects/project-types/${projectTypeUuid}/job-types/${jobTypeUuid}/general`,
    },
    {
      label: 'Crew Steps',
      url: `/settings/projects/project-types/${projectTypeUuid}/job-types/${jobTypeUuid}/steps`,
    },
  ];
};

const JobTypeSettingsPageNavigationTabs = ({jobType}: any) => {
  const {navigator} = useNavigationDOM();
  const tabs = getJobTypesTab(jobType);
  const currentTab = _.findIndex(tabs, {url: navigator.location.pathname});

  return (
    <Tabs
      tabs={tabs}
      handlePressTab={(tab) => navigator.push(tab.url)}
      activeTabIndex={currentTab}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeSettingsPageNavigationTabs.fragment = gql`
  fragment JobTypeSettingsPageNavigationTabs on JobType {
    id
    uuid
    projectType {
      id
      uuid
    }
  }
`;

export default JobTypeSettingsPageNavigationTabs;
