// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, PercentInput, Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';

const Row = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const TextInput = Styled.TextInput`
  ${Typography.Body4}
  height: 24px;
  min-height: 24px;
  padding-right: 6px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 0px;
`;

const CostItemRateInputs = ({
  form,
  field,
  handleChangeRate,
  handleChangeUnit,
  costItemForm,
  billItemUnit,
}: any) => {
  const isPercent = costItemForm.unit === CostItemUnit.PERCENT;
  const customProps = isPercent ? {} : {shouldHideCentsIfZero: true};
  const costItemUnitOptions = costItemForm.isCustomCostItem
    ? CostItemUnit.VALUES_NO_BILL_ITEM
    : CostItemKind.getCostItemUnits(costItemForm.kind, {billItemUnit});
  const rate = _.get(form.values, `${field}.rate`);
  return (
    <Row>
      <FieldInput
        {...form}
        name={`${field}.rate`}
        component={isPercent ? PercentInput : CurrencyInput}
        handleBlur={() => handleChangeRate(rate)}
        style={{flex: 3}}
        showErrorMessage={false}
        input={{
          ...customProps,
          component: TextInput,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          style: {
            fontSize: 12,
            height: 24,
            minHeight: 24,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            textAlign: 'right',
            paddingRight: 6,
            paddingLeft: 6,
            paddingTop: 1,
          },
        }}
      />
      <FieldInput
        {...form}
        name={`${field}.unit`}
        component={DropdownInput}
        style={{flex: 2}}
        showErrorMessage={false}
        input={{
          options: _.map(costItemUnitOptions, (unit) => ({
            label: CostItemUnit.getDisplayValue(unit),
            value: unit,
          })),
          onChangeValue: handleChangeUnit,
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          placeholder: '',
          fontSize: 12,
          style: {
            height: 24,
            minHeight: 24,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            paddingRight: 0,
            paddingLeft: 0,
            flex: 1,
          },
          components: {DropdownIndicator: () => null, IndicatorSeparator: () => null},
        }}
      />
    </Row>
  );
};

export default CostItemRateInputs;
