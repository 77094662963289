// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {useDrawer} from '@supermove/hooks';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import GlobalHeader from 'modules/App/Global/GlobalHeader';
import MobileHeader from 'modules/App/Global/components/MobileHeader';
import MobileNavigationDrawer from 'modules/App/Global/components/MobileNavigationDrawer';
import MobileNotificationsDrawer from 'modules/App/Global/components/MobileNotificationsDrawer';
import MobileSearchDrawer from 'modules/App/Global/components/MobileSearchDrawer';
import useAppContext from 'modules/App/context/useAppContext';

const MenuButton = ({handleOpenNavigation}: any) => {
  return <GlobalHeader.IconButton icon={Icon.Bars} onPress={handleOpenNavigation} />;
};

const GlobalHeaderMobileContent = ({
  handleOpenNavigation,
  mobileSearchDrawer,
  mobileNotificationsDrawer,
}: any) => {
  const {viewer} = useAppContext();
  return (
    <MobileHeader>
      <Space width={12} />
      <MenuButton handleOpenNavigation={handleOpenNavigation} />
      <Space width={8} />
      <GlobalHeader.OrganizationName />
      <GlobalHeader.EnvironmentBadge />
      <Space style={{flex: 1}} />
      <GlobalHeader.SearchButton handleOpenSearch={mobileSearchDrawer.handleOpen} />
      <Space width={8} />
      <GlobalHeader.NotificationsButton
        handleOpenNotifications={mobileNotificationsDrawer.handleOpen}
      />
      <Space width={8} />
      {viewer?.role === UserRole.SUPER_ADMIN && <GlobalHeader.SuperAdminOrganizationSwitcher />}
    </MobileHeader>
  );
};

const GlobalHeaderMobile = () => {
  const mobileNavigationDrawer = useDrawer({name: 'Mobile Navigation Drawer'});
  const mobileSearchDrawer = useDrawer({name: 'Mobile Search Drawer'});
  const mobileNotificationsDrawer = useDrawer({name: 'Mobile Notifications Drawer'});

  return (
    <React.Fragment>
      <GlobalHeaderMobileContent
        handleOpenNavigation={mobileNavigationDrawer.handleOpen}
        mobileSearchDrawer={mobileSearchDrawer}
        mobileNotificationsDrawer={mobileNotificationsDrawer}
      />
      <MobileNavigationDrawer
        isOpen={mobileNavigationDrawer.isOpen}
        handleClose={mobileNavigationDrawer.handleClose}
      />
      <MobileSearchDrawer
        isOpen={mobileSearchDrawer.isOpen}
        handleClose={mobileSearchDrawer.handleClose}
      />
      <MobileNotificationsDrawer
        isOpen={mobileNotificationsDrawer.isOpen}
        handleClose={mobileNotificationsDrawer.handleClose}
      />
    </React.Fragment>
  );
};

export default GlobalHeaderMobile;
