// Supermove
import {Currency, Percent} from '@supermove/utils';

// App
import PaymentFeeKind, {
  PaymentFeeKindType,
} from '@shared/modules/Organization/enums/PaymentFeeKind';

export interface PaymentFeeFormType {
  name: string;
  kind: PaymentFeeKindType;
  amount?: number;
  percentage?: number;
}

const _new = (): PaymentFeeFormType => ({
  name: '',
  kind: PaymentFeeKind.PERCENT,
  amount: undefined,
  percentage: undefined,
});

export interface PaymentFeeFormEditFromJobRateDataProps {
  name: string;
  amount: number;
  percentage: number;
}

const editFromJobRateData = ({
  name,
  amount,
  percentage,
}: PaymentFeeFormEditFromJobRateDataProps): PaymentFeeFormType => ({
  name,
  kind: amount
    ? PaymentFeeKind.RATE
    : percentage === 1
      ? PaymentFeeKind.NO_FEE
      : PaymentFeeKind.PERCENT,
  amount: amount || undefined,
  percentage: percentage || undefined,
});

const toForm = ({
  name,
  kind,
  amount,
  percentage,
}: ReturnType<typeof _new | typeof editFromJobRateData>) => ({
  name,
  kind,
  amount: amount ? Currency.toForm(amount) : '',
  // percentage is stored as a multiplier but the input is relative (ie. 1.03 vs 3%)
  percentage: percentage ? Percent.toForm(percentage - 1) : '',
});

export type PaymentFeeFormToFormType = ReturnType<typeof toForm>;

const toMutation = ({name, kind, amount, percentage}: PaymentFeeFormToFormType) => ({
  name,
  kind,
  amount: amount ? Currency.toMutation(amount) : null,
  percentage: percentage ? Percent.toMutation(percentage) : null,
});

const PaymentFeeForm = {
  new: _new,
  editFromJobRateData,
  toForm,
  toMutation,
};

export default PaymentFeeForm;
