// Libraries
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {Currency, Json, Percent} from '@supermove/utils';

// Components
import EmployeeFormFields from './EmployeeFormFields';
import UpdateEmployeeMutation from './UpdateEmployeeMutation';

const getInitialMoverPositionIds = ({user}: any) => {
  return (User as any)
    .getSortedUserMoverPositions(user)
    .map((userMoverPosition: any) => userMoverPosition.moverPositionId);
};

type OwnUpdateEmployeeFormProps = {
  user: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'UpdateEmployeeFormProps' circularly re... Remove this comment to see the full error message
type UpdateEmployeeFormProps = OwnUpdateEmployeeFormProps & typeof UpdateEmployeeForm.defaultProps;

// @ts-expect-error TS(7022): 'UpdateEmployeeForm' implicitly has type 'any' bec... Remove this comment to see the full error message
const UpdateEmployeeForm = ({user, onSuccess, onError}: UpdateEmployeeFormProps) => {
  const initialValues = {
    userId: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phoneNumber: user.phoneNumber || '',
    position: user.position,
    branchCode: user.branchCode || '',
    payrollIdentifier: user.payrollIdentifier,
    tabletPin: user.tabletPin,
    billingHourlyRate: Currency.toForm(user.billingHourlyRate),
    compensationPercentage: Percent.toForm(user.compensationPercentage),
    isDriver: user.isDriver,
    isActive: user.isActive,
    features: Json.parse(user.features),
    moverPositionIds: getInitialMoverPositionIds({user}),
    primaryMoverPositionId: user.userMoverPositions.find(
      (userMoverPosition: any) => userMoverPosition.isPrimary,
    )?.moverPositionId,
  };

  return (
    <Form initialValues={initialValues} onSubmit={(values: any) => {}}>
      {({submitForm, setMutationErrors, ...form}: any) => (
        <UpdateEmployeeMutation
          {...form}
          onSuccess={onSuccess}
          submitForm={submitForm}
          setMutationErrors={setMutationErrors}
        >
          {({loading, handleSubmit}: any) => (
            <EmployeeFormFields
              isUpdate
              form={form}
              loading={loading}
              organization={user.viewingOrganization}
              handleSubmit={handleSubmit}
            />
          )}
        </UpdateEmployeeMutation>
      )}
    </Form>
  );
};

UpdateEmployeeForm.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateEmployeeForm.fragment = gql`
  ${EmployeeFormFields.fragment}
  ${User.getSortedUserMoverPositions.fragment}

  fragment UpdateEmployeeForm on User {
    id
    firstName
    lastName
    phoneNumber
    payrollIdentifier
    tabletPin
    position
    branchCode
    billingHourlyRate
    compensationPercentage
    isDriver
    isActive
    features
    viewingOrganization {
      id
      ...EmployeeFormFields
    }
    userMoverPositions {
      id
      moverPositionId
      isPrimary
    }
    ...User_getSortedUserMoverPositions
  }
`;

export default UpdateEmployeeForm;
