// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useEffect, useResponsive} from '@supermove/hooks';

// App
import useAppContext from 'modules/App/context/useAppContext';

const PageContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
`;

const SidebarPageV2 = ({selected, children}: any) => {
  const responsive = useResponsive();
  const {setGlobalNavigationTab} = useAppContext();

  useEffect(() => {
    setGlobalNavigationTab(selected);
  }, [selected, setGlobalNavigationTab]);

  if (responsive.mobile) {
    return (
      <Container {...responsive}>
        <React.Fragment>{children}</React.Fragment>
      </Container>
    );
  }

  return (
    <PageContainer>
      <Container {...responsive}>{children}</Container>
    </PageContainer>
  );
};

export default SidebarPageV2;
