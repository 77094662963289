// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
  ScrollViewType,
  UrlFiltersType,
} from '@supermove/hooks';
import {
  CapacityCalendarDayModel,
  CapacityCalendarModel,
  OrganizationModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, URL} from '@supermove/utils';

// App
import Line from '@shared/design/components/Line';
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import CapacityCalendarSettingHelpers from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSettingHelpers';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';
import DaySlotMetric from 'modules/Calendar/Capacity/components/DaySlotMetric';

const Column = Styled.View`
  flex-direction: column;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LoadingComponentContainer = Styled.View`
  padding: 16px;
`;

const StatusBar = Styled.View<{color: string}>`
  width: 4px;
  height: 100%;
  background-color: ${({color}) => color};
`;

const CalendarDayContainer = Styled.View`
`;

const MobileCapacityCalendarCard = Styled.ButtonV2<{isToday: boolean}>`
  flex-direction: row;
  background-color: ${({isToday}) => (isToday ? colors.blue.accent : colors.white)};
`;

const DateColumnContainer = Styled.View`
  width: 64px;
`;

const MetricsColumnContainer = Styled.View`
  flex: 1;
`;

const DateText = Styled.Text<{vars: {isToday: boolean}}>`
  ${({vars}) => (vars.isToday ? Typography.Responsive.Label : Typography.Responsive.Body)}
  color: ${({vars}) => (vars.isToday ? colors.blue.interactive : colors.gray.secondary)};
`;

const DayText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const LoadingComponent = () => {
  return (
    <LoadingComponentContainer>
      <SkeletonLoader height={120} />
      <Space height={16} />
      <SkeletonLoader height={120} />
      <Space height={16} />
      <SkeletonLoader height={120} />
      <Space height={16} />
      <SkeletonLoader height={120} />
      <Space height={16} />
      <SkeletonLoader height={120} />
    </LoadingComponentContainer>
  );
};

const StatusIndicator = ({
  capacityCalendar,
  capacityCalendarDay,
}: {
  capacityCalendar: CapacityCalendarModel;
  capacityCalendarDay: CapacityCalendarDayModel;
}) => {
  const color = CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor({
    capacityCalendar,
    capacityCalendarDay,
  }).statusBar;
  return <StatusBar color={color} />;
};

const DateColumn = ({
  capacityCalendarDay,
  isToday,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  isToday: boolean;
}) => {
  const responsive = useResponsive();

  return (
    <DateColumnContainer>
      <DateText responsive={responsive} vars={{isToday}}>
        {Datetime.convertToDisplayDate(capacityCalendarDay.date, 'MMM DD')}
      </DateText>
      <Space height={2} />
      <DayText responsive={responsive}>
        {Datetime.convertToDisplayDate(capacityCalendarDay.date, 'ddd')}
      </DayText>
    </DateColumnContainer>
  );
};

const MetricsColumn = ({
  capacityCalendarDay,
  capacityCalendar,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
}) => {
  const primarySlotMetric = _.find(capacityCalendar.capacityCalendarSlotMetrics, {
    slotPriorityIndex: 1,
  });
  const secondarySlotMetric = _.find(capacityCalendar.capacityCalendarSlotMetrics, {
    slotPriorityIndex: 2,
  });

  return (
    <MetricsColumnContainer>
      {primarySlotMetric && (
        <DaySlotMetric
          metric={primarySlotMetric.metric}
          capacityCalendarDay={capacityCalendarDay}
          capacityCalendar={capacityCalendar}
          isSummary
        />
      )}
      {secondarySlotMetric && (
        <React.Fragment>
          <Space height={4} />
          <DaySlotMetric
            metric={secondarySlotMetric.metric}
            capacityCalendarDay={capacityCalendarDay}
            capacityCalendar={capacityCalendar}
            isSecondaryMetric
            isSummary
          />
        </React.Fragment>
      )}
    </MetricsColumnContainer>
  );
};

const MobileCapacityCalendarDay = ({
  capacityCalendarDay,
  capacityCalendar,
  isToday,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
  isToday: boolean;
}) => {
  const {navigator, params} = useNavigationDOM();

  return (
    <MobileCapacityCalendarCard
      onPress={() => {
        navigator.push(
          URL.getUrlFromVariables('/moves/capacity/calendar/day', {
            date: capacityCalendarDay.date,
            slugs: params.slugs,
          }),
        );
      }}
      isToday={isToday}
    >
      <StatusIndicator
        capacityCalendar={capacityCalendar}
        capacityCalendarDay={capacityCalendarDay}
      />
      <Space width={12} />
      <Column>
        <Space height={8} />
        <Row>
          <DateColumn capacityCalendarDay={capacityCalendarDay} isToday={isToday} />
          <Space width={16} />
          <MetricsColumn
            capacityCalendarDay={capacityCalendarDay}
            capacityCalendar={capacityCalendar}
          />
        </Row>
        {!!capacityCalendarDay.dayNotes && (
          <React.Fragment>
            <Space height={4} />
            <DaySlotMetric
              metric={CapacityCalendarSlotMetricKind.DAY_NOTES}
              capacityCalendarDay={capacityCalendarDay}
              capacityCalendar={capacityCalendar}
              isSummary
            />
          </React.Fragment>
        )}
        <Space height={8} />
      </Column>
    </MobileCapacityCalendarCard>
  );
};

const MonthDays = ({
  capacityCalendar,
  todayPositionY,
  setTodayPositionY,
  urlFilters,
  scrollView,
}: {
  capacityCalendar: CapacityCalendarModel;
  todayPositionY: number;
  setTodayPositionY: (position: number) => void;
  urlFilters: UrlFiltersType;
  scrollView: ScrollViewType;
}) => {
  const {params} = useNavigationDOM();

  // If not on the current month, the "Today" button sets the date to 'today'.
  // We'll use this solely to know to scroll to the current day position and
  // then reset the date to undefined.
  useEffect(() => {
    if (params.date === 'today') {
      scrollView.handleScrollTo({y: todayPositionY, animated: true});
      urlFilters.handleUpdate({date: undefined});
    }
  }, [params.date]);

  return (
    <React.Fragment>
      {_.sortBy(capacityCalendar.capacityCalendarDays, 'date').map(
        (capacityCalendarDay: CapacityCalendarDayModel, index: number) => {
          const isToday = capacityCalendarDay.date === Datetime.toDate(Datetime.today);

          return (
            <CalendarDayContainer
              key={capacityCalendarDay.date}
              onLayout={(e) => (isToday ? setTodayPositionY(e.nativeEvent.layout.y) : null)}
            >
              {index > 0 && <Line />}
              <MobileCapacityCalendarDay
                capacityCalendarDay={capacityCalendarDay}
                capacityCalendar={capacityCalendar}
                isToday={isToday}
              />
            </CalendarDayContainer>
          );
        },
      )}
    </React.Fragment>
  );
};

const MobileCapacityCalendarMonthDays = ({
  organization,
  todayPositionY,
  setTodayPositionY,
  urlFilters,
  scrollView,
}: {
  organization: OrganizationModel;
  todayPositionY: number;
  setTodayPositionY: (position: number) => void;
  urlFilters: UrlFiltersType;
  scrollView: ScrollViewType;
}) => {
  const {params} = useNavigationDOM();
  const queryDate = !params.date || params.date === 'today' ? Datetime.startOfMonth : params.date;
  const {data, loading} = useQuery(MobileCapacityCalendarMonthDays.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: Datetime.toMutationMonth(queryDate),
      // Only the primary organization should default to all branches and franchises
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
    },
  });

  return (
    <React.Fragment>
      {loading ? (
        // Instead of using our Loading component, which shows the load state only on the first
        // load, we do want to show the loading state every time the data is being fetched to
        // create a better exerience when refetching filtered data.
        <LoadingComponent />
      ) : (
        <MonthDays
          capacityCalendar={data.capacityCalendar}
          todayPositionY={todayPositionY}
          setTodayPositionY={setTodayPositionY}
          urlFilters={urlFilters}
          scrollView={scrollView}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileCapacityCalendarMonthDays.query = gql`
  ${CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor.fragment}
  ${DaySlotMetric.fragment}

  query CapacityCalendar(
    $month: String!
    $slugs: [String!]!
  ) {
    ${gql.query}
    capacityCalendar(month: $month, slugs: $slugs, shouldFetchAdditionalDaysBeforeAndAfterMonth: false) {
      capacityCalendarDays {
        date
        dayNotes
      }
      capacityCalendarSlotMetrics {
        id
        metric
        slotPriorityIndex
      }
      ...CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor
      ...DaySlotMetric
	  }
  }
`;

MobileCapacityCalendarMonthDays.fragment = gql`
  fragment MobileCapacityCalendarMonthDays on Organization {
    id
    slug
    isPrimary
  }
`;
export default MobileCapacityCalendarMonthDays;
