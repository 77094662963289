// Libraries
import React from 'react';

// App
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

import DeleteMoverPositionForm from '@shared/modules/User/forms/DeleteMoverPositionForm';
import useDeleteMoverPositionMutation from '@shared/modules/User/hooks/useDeleteMoverPositionMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding-horizontal: 16px;
`;

const IconContainer = Styled.View`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: ${colors.alpha(colors.red.warning, 0.1)};
  align-items: center;
  justify-content: center;
`;

const MainText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const DescriptionText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color}
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const ActivityIndicator = Styled.Loading`
`;

const DeleteMoverPositionModalContent = ({moverPositionId, handleClose, refetch}: any) => {
  const deleteMoverPositionForm = DeleteMoverPositionForm.new({moverPositionId});
  const {submitting, handleSubmit} = useDeleteMoverPositionMutation({
    deleteMoverPositionForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    <Container pointerEvents={'box-none'}>
      <Wrapper width={392}>
        <Space height={24} />
        <IconContainer>
          <Icon color={colors.red.warning} size={22} source={Icon.Trash} />
        </IconContainer>
        <Space height={24} />
        <MainText>Are you sure you want to delete this position?</MainText>
        <Space height={12} />
        <DescriptionText>{`Deleting this position will remove it from movers. It will remain on previous jobs. You cannot undo this action.`}</DescriptionText>
        <Space height={24} />
        <Actions>
          <Button
            // @ts-expect-error TS(2769): No overload matches this call.
            color={colors.white}
            onPress={handleClose}
            activeOpacity={0.8}
            disabled={submitting}
          >
            <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
          </Button>
          <Space width={8} />
          <Button
            color={submitting ? colors.gray.border : colors.red.warning}
            activeOpacity={0.8}
            // @ts-expect-error TS(2769): No overload matches this call.
            onPress={handleSubmit}
            disabled={submitting}
          >
            {submitting ? (
              <ActivityIndicator color={colors.white} size={'small'} />
            ) : (
              <ButtonText color={colors.white}>Delete</ButtonText>
            )}
          </Button>
        </Actions>
        <Space height={24} />
      </Wrapper>
    </Container>
  );
};

const DeleteMoverPositionModalV1 = ({isOpen, moverPositionId, handleClose, refetch}: any) => {
  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <DeleteMoverPositionModalContent
        moverPositionId={moverPositionId}
        handleClose={handleClose}
        refetch={refetch}
      />
    </Modal.Content>
  );
};

export default DeleteMoverPositionModalV1;
