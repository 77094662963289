// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {JobUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useState, useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {pluralize, Datetime} from '@supermove/utils';

// App

import AssignUsersToCrewWithCrewSlotsForm from '@shared/modules/Dispatch/forms/AssignUsersToCrewWithCrewSlotsForm';
import useAssignUsersToCrewWithCrewSlotsMutation from '@shared/modules/Dispatch/hooks/useAssignUsersToCrewWithCrewSlotsMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SystemMessage from 'modules/App/components/SystemMessage';
import CrewUsersAndDriversContent from 'modules/Dispatch/Crew/components/CrewUsersAndDriversContent';

const LoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${colors.white};
`;

const LoadingIndicator = Styled.Loading`
`;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
`;

const Touchable = Styled.Touchable`
  justify-content: center;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const getDatetimeToQuery = ({date}: any) => {
  return Datetime.previousMonday(Datetime.fromDate(date));
};

const getHeaderTitle = (crew: any, selectedUsers: any) => {
  return `Assign Movers ${selectedUsers.length}/${crew.numberOfRequiredMovers}`;
};

const getHeaderCaption = (crew: any) => {
  return (
    `${pluralize('Truck', crew.numberOfRequiredTrucks, true)}, ` +
    `${pluralize('Mover', crew.numberOfRequiredMovers, true)}`
  );
};

const handleAddJobUserForm = ({
  field,
  form,
  usersById,
  userId,
  jobUserForms,
  jobId,
  jobUser,
}: any) => {
  const addedUser = usersById[userId];
  // If the jobUser is removed and then readded, restore the jobUser's previous state,
  // including any updates to the jobUser's primary mover position.
  const jobUserForm = jobUser
    ? JobUserForm.edit(jobUser)
    : JobUserForm.new({
        jobId,
        userId,
        firstName: addedUser.firstName,
        position: addedUser.position,
        branchCode: addedUser.branchCode,
        moverPositionIds: addedUser.moverPositions.map((moverPosition: any) =>
          Number(moverPosition.id),
        ),
      });
  form.setFieldValue(field, [...jobUserForms, JobUserForm.toForm(jobUserForm)]);
};

const handleRemoveJobUserForm = ({field, form, userId, jobUserForms}: any) => {
  const updatedJobUserForms = jobUserForms.filter(
    (jobUserForm: any) => String(jobUserForm.userId) !== userId,
  );
  form.setFieldValue(field, updatedJobUserForms);
};

const LoadingPlaceholder = () => {
  return (
    <ResponsivePopover.Container width={400} height={524}>
      <LoadingContainer>
        <LoadingIndicator color={colors.gray.secondary} />
      </LoadingContainer>
    </ResponsivePopover.Container>
  );
};

const HeaderRight = ({handleClose, jobJobUsersScheduleModal}: any) => {
  const responsive = useResponsive();
  const onOpenJobUsersScheduleModal = () => {
    jobJobUsersScheduleModal.handleOpen();
    // TODO(dan) Remove the handleClose once popovers are able to hide behind modals
    handleClose();
  };
  return (
    <React.Fragment>
      <Touchable onPress={onOpenJobUsersScheduleModal} activeOpacity={0.8}>
        <Icon source={Icon.UserClock} size={16} color={colors.gray.primary} />
      </Touchable>
      {!responsive.mobile && (
        <React.Fragment>
          <Space width={16} />
          <Touchable onPress={handleClose} activeOpacity={0.8}>
            <Icon source={Icon.Times} size={16} color={colors.gray.primary} />
          </Touchable>
          <Space width={2} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CrewUsersAndDriversPopoverContent = ({
  crew,
  employeeSchedules,
  handleClose,
  refetch,
  refetchQueries,
  isClosable,
  setIsClosable,
  jobJobUsersScheduleModal,
  shouldRunUpsertCostsAndBillingEngineAsync,
}: any) => {
  const responsive = useResponsive();
  const assignUsersToCrewWithCrewSlotsForm = AssignUsersToCrewWithCrewSlotsForm.edit(crew, {
    shouldRunUpsertCostsAndBillingEngineAsync,
  });
  const {form, submitting, handleSubmit} = useAssignUsersToCrewWithCrewSlotsMutation({
    assignUsersToCrewWithCrewSlotsForm,
    refetchQueries,
    onSuccess: () => {
      handleClose();
      setIsClosable(true);
      refetch();
    },
    onError: (errors: any) => {
      setIsClosable(true);
      console.log({errors});
    },
  });
  const usersById = _.reduce(
    crew.organization.allEmployees,
    (acc, user) => ({...acc, [user.id]: user}),
    {},
  );
  const field = 'assignUsersToCrewWithCrewSlotsForm.assignUsersToCrewForm.jobUserForms';
  const jobUserForms = _.get(form.values, field);
  const hasSelectedTooManyMovers = jobUserForms.length > crew.numberOfRequiredMovers;
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const selectedUsers = jobUserForms.map((jobUserForm: any) => usersById[jobUserForm.userId]);

  const handleAddUserId = (userId: any) => {
    handleAddJobUserForm({
      field,
      form,
      usersById,
      userId,
      jobUserForms,
      jobId: crew.jobId,
      jobUser: _.find(crew.job.jobUsers, ['userId', _.toNumber(userId)]),
    });
  };
  const handleRemoveUserId = (userId: any) => {
    handleRemoveJobUserForm({field, form, userId, jobUserForms});
  };

  return (
    <ResponsivePopover.Container width={400} height={524}>
      <HeaderContainer>
        <Space height={responsive.mobile ? 12 : 16} />
        <ResponsivePopover.Header
          title={getHeaderTitle(crew, selectedUsers)}
          caption={getHeaderCaption(crew)}
          handleClose={handleClose}
          headerRight={
            <HeaderRight
              handleClose={handleClose}
              jobJobUsersScheduleModal={jobJobUsersScheduleModal}
            />
          }
        />
        <Space height={responsive.mobile ? 10 : 8} />
        {responsive.mobile && <Line />}
      </HeaderContainer>
      {responsive.mobile && <Space height={6} />}
      <CrewUsersAndDriversContent
        form={form}
        crew={crew}
        employeeSchedules={employeeSchedules}
        selectedUsers={selectedUsers}
        setIsClosable={setIsClosable}
        handleAddUserId={handleAddUserId}
        handleRemoveUserId={handleRemoveUserId}
      />
      <Space height={20} />
      {hasSelectedTooManyMovers && (
        <React.Fragment>
          <SystemMessage isWarning>
            There are more selected movers than the requested number of movers.
          </SystemMessage>
          <Space height={16} />
        </React.Fragment>
      )}
      <ResponsivePopover.Footer
        handleClose={handleClose}
        handleSubmit={() => {
          setIsClosable(false);
          handleSubmit();
        }}
        handleSubmitText={'Assign'}
        disabled={submitting || !isClosable}
        loading={submitting || !isClosable}
        submitButtonWidth={80}
      />
      <Space height={12} />
    </ResponsivePopover.Container>
  );
};

type OwnCrewUsersAndDriversPopoverProps = {
  isDispatchCloseable?: boolean;
  setIsDispatchCloseable?: (...args: any[]) => any;
  shouldRunUpsertCostsAndBillingEngineAsync?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'CrewUsersAndDriversPopoverProps' circu... Remove this comment to see the full error message
type CrewUsersAndDriversPopoverProps = OwnCrewUsersAndDriversPopoverProps &
  typeof CrewUsersAndDriversPopover.defaultProps;

// @ts-expect-error TS(7022): 'CrewUsersAndDriversPopover' implicitly has type '... Remove this comment to see the full error message
const CrewUsersAndDriversPopover = ({
  crew,
  isOpen,
  handleOpen,
  handleClose,
  popoverRef,
  placement,
  refetch,
  refetchQueries,
  offset,
  jobJobUsersScheduleModal,
  isDispatchCloseable,
  setIsDispatchCloseable,
  shouldRunUpsertCostsAndBillingEngineAsync,
}: CrewUsersAndDriversPopoverProps) => {
  const [isClosable, setIsClosable] = useState(isDispatchCloseable);
  const date = _.get(crew, 'job.day.value') || crew.job.startDate;
  const hasDate = !!date;
  const {loading, data} = useQuery(CrewUsersAndDriversPopover.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen || !hasDate,
    variables: {
      crewId: crew.id,
      date: hasDate ? Datetime.toDate(getDatetimeToQuery({date})) : null,
      organizationId: crew.organizationId,
    },
  });

  return (
    <ResponsivePopover
      placement={placement}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      offset={offset}
      isClosableOutside={isClosable && isDispatchCloseable}
    >
      <Loading loading={loading || !data} as={LoadingPlaceholder}>
        {() => {
          return (
            <CrewUsersAndDriversPopoverContent
              crew={data.crew}
              employeeSchedules={data.employeeSchedules}
              handleClose={handleClose}
              refetch={refetch}
              refetchQueries={refetchQueries}
              isClosable={isClosable}
              setIsClosable={() => {
                setIsClosable(!isClosable);
                setIsDispatchCloseable(!isDispatchCloseable);
              }}
              jobJobUsersScheduleModal={jobJobUsersScheduleModal}
              shouldRunUpsertCostsAndBillingEngineAsync={shouldRunUpsertCostsAndBillingEngineAsync}
            />
          );
        }}
      </Loading>
    </ResponsivePopover>
  );
};

CrewUsersAndDriversPopover.defaultProps = {
  isDispatchCloseable: true,
  setIsDispatchCloseable: () => {},
  shouldRunUpsertCostsAndBillingEngineAsync: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversPopover.query = gql`
  ${AssignUsersToCrewWithCrewSlotsForm.edit.fragment}
  ${CrewUsersAndDriversContent.fragment}
  ${JobUserForm.edit.fragment}

  query CrewUsersAndDriversPopover($crewId: Int!, $date: String!, $organizationId: Int!) {
    crew(crewId: $crewId) {
      id
      numberOfRequiredTrucks
      numberOfRequiredMovers
      jobId
      organization {
        id
        allEmployees {
          id
          firstName
          position
          branchCode
          moverPositions {
            id
          }
        }
      }
      job {
        id
        jobUsers {
          id
          userId
          ...JobUserForm_edit
        }
      }
      ...AssignUsersToCrewWithCrewSlotsForm_edit
      ...CrewUsersAndDriversContent
    }
    employeeSchedules: employeeSchedulesByOrganizationId(
      date: $date
      organizationId: $organizationId
    ) {
      schedules: employeeSchedules {
        ...CrewUsersAndDriversContent_EmployeeSchedule
      }
    }
  }
`;

CrewUsersAndDriversPopover.fragment = gql`
  fragment CrewUsersAndDriversPopover on Crew {
    id
    organizationId
    job {
      id
      startDate
      day {
        id
        value
      }
    }
  }
`;

export default CrewUsersAndDriversPopover;
