// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, Icon, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import ButtonComponent from '@shared/design/components/Button';
import IconButton from '@shared/design/components/IconButton';
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';
import Line from 'modules/App/components/Line';

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Heading2};
`;

const Row = Styled.View`
  flex-direction: row;
`;

type DocumentFlowPageHeaderProps = {
  form: Form<UserFlowFormToFormType>;
  field: string;
  handleSave: () => void;
};

const DocumentFlowPageHeader = ({form, field, handleSave}: DocumentFlowPageHeaderProps) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const userFlowForm = _.get(form.values, field) as UserFlowFormToFormType;
  const [flowName] = useState(userFlowForm.name);

  return (
    <React.Fragment>
      <Space height={16} />
      <HeaderContainer>
        <Row style={{alignItems: 'center'}}>
          <Space width={responsive.desktop ? 24 : 16} />
          <IconButton
            source={Icon.Xmark}
            isSecondary
            onPress={() => navigator.goBack()}
            size={20}
          />
          <Space width={8} />
          <Title responsive={responsive}>
            {userFlowForm.userFlowId ? flowName : 'New Document Flow'}
          </Title>
        </Row>
        <Space flex={1} />
        <Row>
          <ButtonComponent text={'Save'} iconLeft={Icon.Check} onPress={handleSave} />
          <Space width={responsive.desktop ? 24 : 16} />
        </Row>
      </HeaderContainer>
      <React.Fragment>
        <Space height={16} />
        <Line />
      </React.Fragment>
    </React.Fragment>
  );
};

export default DocumentFlowPageHeader;
