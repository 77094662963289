// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useEffect, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

// This is a hack for getting the right height for the input.
// For some reason onContentSizeChange gives a slow height
// reduction when a line gets deleted. Eg, Instead of going
// from 55 to 36 (which would be expected) it goes 53, 51, 49,
// etc... slowly decreasing the size with each character change.
const getInputHeight = ({contentSizeHeight, paddingVertical, isResponsive, responsive}: any) => {
  const isLarge = isResponsive && !responsive.desktop;
  const textLineHeight = isLarge ? 24 : 20; // This is the lineHeight for the Typography
  const paddingHeight = paddingVertical * 2;
  const numberOfTextLines = Math.floor((contentSizeHeight - paddingHeight) / textLineHeight);
  const totalTextHeight = numberOfTextLines * textLineHeight;
  return totalTextHeight + paddingHeight;
};

const TextInput = Styled.TextInput`
  ${Typography.Responsive.Body}
  max-height: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'MaybeRespo... Remove this comment to see the full error message
    vars,
  }) => (vars.maxHeight ? vars.maxHeight : null)};
  height: ${(props) => (props as any).height}px;
  color: ${colors.gray.primary};
  background-color: ${(props) => ((props as any).disabled ? colors.gray.disabled : colors.white)};
  border-color: ${colors.gray.tertiary};
  border-width: 1px;
  border-radius: 4px;
  padding-vertical: 8px;
  padding-horizontal: 12px;
`;

type OwnResponsiveTextInputProps = {
  minHeight?: number;
  input?: any;
  paddingVertical?: number;
  handleCommandEnter?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'ResponsiveTextInputProps' circularly r... Remove this comment to see the full error message
type ResponsiveTextInputProps = OwnResponsiveTextInputProps &
  typeof ResponsiveTextInput.defaultProps;

// @ts-expect-error TS(7022): 'ResponsiveTextInput' implicitly has type 'any' be... Remove this comment to see the full error message
const ResponsiveTextInput = ({
  form,
  field,
  label,
  minHeight,
  maxHeight,
  input,
  paddingVertical,
  handleCommandEnter,
  disabled,
  autoFocus,
  isResponsive,
}: ResponsiveTextInputProps) => {
  const responsive = useResponsive();
  const [inputHeight, setInputHeight] = useState(minHeight || (responsive.desktop ? 36 : 48));
  const value = _.get(form.values, field);
  useEffect(() => {
    if (!value && inputHeight !== minHeight) {
      setInputHeight(minHeight);
    }
  }, [value, inputHeight, minHeight]);

  const handleSizeChange = (contentSizeHeight: any) => {
    setInputHeight(
      Math.max(
        minHeight,
        getInputHeight({contentSizeHeight, paddingVertical, isResponsive, responsive}),
      ),
    );
  };

  return (
    <React.Fragment>
      {label && (
        <React.Fragment>
          <FieldInput.LabelText isResponsive={isResponsive}>{label}</FieldInput.LabelText>
          <Space height={4} />
        </React.Fragment>
      )}
      <TextInput
        value={value}
        onChangeText={(text: any) => {
          if (form.customSetFieldValue) {
            return form.customSetFieldValue(field, text);
          }
          return form.setFieldValue(field, text);
        }}
        onContentSizeChange={(event: any) => handleSizeChange(event.nativeEvent.contentSize.height)}
        height={inputHeight}
        multiline
        disabled={disabled}
        autoFocus={autoFocus}
        vars={{maxHeight}}
        responsive={isResponsive ? responsive : null}
        {...input}
        placeholder={input.disabled || disabled ? null : input.placeholder}
        onKeyPress={(event: any) => {
          if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
            handleCommandEnter();
          }
        }}
      />
    </React.Fragment>
  );
};

ResponsiveTextInput.defaultProps = {
  minHeight: 36,
  input: {},
  paddingVertical: 8,
  handleCommandEnter: () => {},
};

const areEqual = (prevProps: any, nextProps: any) => {
  const prevValue = _.get(prevProps.form.values, prevProps.field);
  const nextValue = _.get(nextProps.form.values, nextProps.field);
  const prevError = _.get(prevProps.form.errors, prevProps.field);
  const nextError = _.get(nextProps.form.errors, nextProps.field);
  const prevDisabled = prevProps.disabled;
  const nextDisabled = nextProps.disabled;
  return prevValue === nextValue && prevError === nextError && prevDisabled === nextDisabled;
};

ResponsiveTextInput.Memoized = React.memo(ResponsiveTextInput, areEqual);

export default ResponsiveTextInput;
