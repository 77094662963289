// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {Organization, ReferralSource} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import OrganizationStaffDropdown from 'modules/Organization/components/OrganizationStaffDropdown';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View<{responsive: ResponsiveType; index: number}>`
  padding-horizontal:${({responsive}) => (responsive.desktop ? 0 : 16)}px;
  z-index: ${({index}) => 100 - index}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View<{index: number}>`
  flex-direction: row;
  z-index: ${({index}) => 100 - index}
`;

const ProjectTagFilter = ({organization, form, index}: any) => {
  const filterByArchivedTag = organization.companySettings.tags.filter(
    (tag: any) => !tag.isArchived,
  );
  const projectTags = filterByArchivedTag.filter(
    (tag: any) => tag.category === TagCategory.PROJECT,
  );

  const tagOptions = projectTags
    ? projectTags.map((tag: any) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  return (
    <TagDropdownInputField
      options={tagOptions}
      placeholder={'Select project tags'}
      label={'Project Tags'}
      index={index}
      value={form.values?.filteredProjectsForm?.projectTagIds}
      onChangeValue={(projectTagIds: any) => {
        form.setFieldValue('filteredProjectsForm.projectTagIds', projectTagIds);
      }}
    />
  );
};

const FollowUpStartDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.followUpStartDate`}
      label={'Follow Up Start'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const FollowUpEndDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.followUpEndDate`}
      label={'Follow Up End'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        position: 'bottom-end',
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const FromDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.fromDate`}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ToDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      isResponsive
      component={DateInput}
      name={`${name}.toDate`}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
        flex: 1,
      }}
      input={{
        position: 'bottom-end',
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {width: '100%'},
      }}
    />
  );
};

const ProjectTypeFilter = ({index, name, form, projectTypes}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={`${name}.projectTypeIds`}
      isResponsive
      label={'Project Types'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: projectTypes.map((projectType: any) => ({
          label: projectType.name,
          value: projectType.id,
        })),
        placeholder: 'Select project types',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

const SalespeopleMultiselect = ({index, name, form, organization}: any) => {
  return (
    <OrganizationStaffDropdown
      organization={organization}
      form={form}
      field={`${name}.salespersonIds`}
      label={'Salesperson'}
      noneOptionLabel={'No salesperson'}
      placeholder={'Select salespeople'}
      isMultiSelect
      index={index}
      inputStyle={{flex: 1}}
      isSalesperson
      isResponsive
    />
  );
};

const CoordinatorMultiselect = ({index, name, form, organization}: any) => {
  return (
    <OrganizationStaffDropdown
      organization={organization}
      form={form}
      field={`${name}.coordinatorIds`}
      label={'Coordinators'}
      noneOptionLabel={'No coordinator'}
      placeholder={'Select coordinators'}
      isMultiSelect
      index={index}
      inputStyle={{flex: 1}}
      isCoordinator
      isResponsive
    />
  );
};

const ReferralSourceMultiselect = ({index, name, form, organization}: any) => {
  const {referralSources} = organization.settings;
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={`${name}.referralSources`}
      isResponsive
      label={'Referral Sources'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: ReferralSource.getDropdownOptions({referralSources}),
        placeholder: 'Select referral sources',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

const LaborSourceMultiselect = ({index, name, form, organizations}: any) => {
  const getLaborSourceOptions = ({organizations}: any) => {
    const sortedOrganizationsByName = _.sortBy(organizations, ['name']);
    const laborSourceOptions = sortedOrganizationsByName.map((organization) => ({
      label: organization.name,
      value: organization.id,
    }));
    return [
      {
        label: 'No Labor Source',
        value: 'NO_CREW',
      },
      ...laborSourceOptions,
    ];
  };
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={`${name}.crewOrganizationIds`}
      label={'Labor Sources'}
      isResponsive
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: getLaborSourceOptions({organizations}),
        placeholder: 'Select labor sources',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

const ProjectFilters = ({organization, form, isRestricted, field}: any) => {
  const {
    features: {isEnabledProjectDetailsFollowUp, isEnabledMovesListMultiBranchSupport},
    isPrimary,
    projectTypesForFilters,
  } = organization;

  const isLaborSourceFilterVisible = isEnabledMovesListMultiBranchSupport && isPrimary;

  return (
    <React.Fragment>
      {isEnabledProjectDetailsFollowUp && (
        <FilterContainer index={0}>
          <FollowUpStartDateFilter name={field} form={form} index={0} />
          <Space width={8} />
          <BodyText>-</BodyText>
          <Space width={8} />
          <FollowUpEndDateFilter name={field} form={form} index={1} />
        </FilterContainer>
      )}
      <Space height={8} />
      <FilterContainer index={1}>
        <FromDateFilter name={field} form={form} index={2} />
        <Space width={8} />
        <BodyText>-</BodyText>
        <Space width={8} />
        <ToDateFilter name={field} form={form} index={3} />
      </FilterContainer>
      <Space height={8} />
      <ProjectTypeFilter projectTypes={projectTypesForFilters} form={form} name={field} index={4} />
      <Space height={8} />
      {organization.features.isEnabledProjectTag && (
        <React.Fragment>
          <ProjectTagFilter organization={organization} form={form} name={field} index={5} />
          <Space height={8} />
        </React.Fragment>
      )}
      {!isRestricted && (
        <React.Fragment>
          <SalespeopleMultiselect organization={organization} form={form} name={field} index={6} />
          <Space height={8} />
        </React.Fragment>
      )}
      <CoordinatorMultiselect organization={organization} form={form} name={field} index={7} />
      {isLaborSourceFilterVisible && (
        <React.Fragment>
          <Space height={8} />
          <LaborSourceMultiselect
            organizations={organization.company.organizations}
            form={form}
            name={field}
            index={8}
          />
        </React.Fragment>
      )}
      <Space height={8} />
      <ReferralSourceMultiselect organization={organization} form={form} name={field} index={9} />
      <Space height={16} />
    </React.Fragment>
  );
};

const ListProjectsPageFiltersPopover = ({
  activeFilterCount,
  popover,
  organization,
  form,
  isRestricted,
  viewerId,
}: any) => {
  const responsive = useResponsive();
  const field = 'filteredProjectsForm';
  return (
    <PopoverFilter
      activeFiltersCount={activeFilterCount}
      hideActionButtons
      popover={popover}
      isScrolling
      responsive={responsive}
      handleApply={popover.handleClose}
      handleClear={() => {
        form.setFieldValue('filteredProjectsForm.salespersonIds', isRestricted ? [viewerId] : []);
        form.setFieldValue(`${field}.followUpStartDate`, null);
        form.setFieldValue(`${field}.followUpEndDate`, null);
        form.setFieldValue(`${field}.fromDate`, null);
        form.setFieldValue(`${field}.toDate`, null);
        form.setFieldValue(`${field}.coordinatorIds`, []);
        form.setFieldValue(`${field}.projectTypeIds`, null);
        form.setFieldValue(`${field}.crewOrganizationIds`, null);
        form.setFieldValue(`${field}.projectTagIds`, null);
        form.setFieldValue(`${field}.referralSources`, null);
      }}
    >
      <React.Fragment>
        <ContentContainer responsive={responsive} index={0}>
          <ProjectFilters
            organization={organization}
            form={form}
            field={field}
            isRestricted={isRestricted}
            viewerId={viewerId}
          />
        </ContentContainer>
        <ContentContainer responsive={responsive} index={1}>
          <Button text={'Done'} onPress={popover.handleClose} style={{flex: 1}} />
        </ContentContainer>
        <Space height={16} />
      </React.Fragment>
    </PopoverFilter>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListProjectsPageFiltersPopover.fragment = gql`
  ${Organization.getCoordinatorOptions.fragment}
  ${OrganizationStaffDropdown.fragment}
  ${ReferralSource.getDropdownOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment ListProjectsPageFiltersPopover on Organization {
    id
    isPrimary
    features {
      isEnabledProjectDetailsFollowUp: isEnabled(feature: "PROJECT_DETAILS_FOLLOW_UP")
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledMovesListMultiBranchSupport: isEnabled(feature: "MOVES_LIST_MULTI_BRANCH_SUPPORT")
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
    }
    company {
      id
      organizations {
        id
        name
      }
    }
    projectTypesForFilters(category: "MOVE") {
      id
      name
    }
    companySettings {
      tags {
        id
        name
        emoji
        isArchived
        category
        ...TagDropdownInputField
      }
    }
    settings {
      id
      referralSources {
        ...ReferralSource_getDropdownOptions
      }
    }
    ...Organization_getCoordinatorOptions
    ...OrganizationStaffDropdown
  }
`;

export default ListProjectsPageFiltersPopover;
