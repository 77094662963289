// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationPaymentFeesForm, {
  OrganizationPaymentFeesFormToFormType,
} from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';
import BillingAdjustmentsModal from 'modules/Organization/Settings/Company/components/BillingAdjustmentsModal';

const AddBillingAdjustmentModal = ({
  isOpen,
  handleClose,
  form,
  field,
  organization,
}: {
  isOpen: boolean;
  handleClose: () => void;
  form: Form<{organizationPaymentFeesForm: OrganizationPaymentFeesFormToFormType}>;
  field: string;
  organization: OrganizationModel;
}) => {
  const organizationPaymentFeesForm =
    OrganizationPaymentFeesForm.editWithNewPaymentFee(organization);
  const paymentForms = _.get(form.values, field);
  const index = paymentForms.length;
  return (
    <BillingAdjustmentsModal
      title={'Add Billing Adjustment'}
      isOpen={isOpen}
      handleClose={handleClose}
      organizationPaymentFeesForm={organizationPaymentFeesForm}
      form={form}
      field={field}
      index={index}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillingAdjustmentModal.fragment = gql`
  ${OrganizationPaymentFeesForm.editWithNewPaymentFee.fragment}
  fragment AddBillingAdjustmentModal on Organization {
    id
    ...OrganizationPaymentFeesForm_editWithNewPaymentFee
  }
`;

export default AddBillingAdjustmentModal;
