// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Container = Styled.View`
  align-items: center;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const License = Styled.H8`
  text-align: center;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  text-align: center;
`;

const Text = Styled.H8`
`;

type OwnProps = {
  shouldShowBusinessName?: boolean;
  shouldShowBusinessLicense?: boolean;
  shouldShowBusinessAddressAndPhoneNumber?: boolean;
  title?: string;
  organization: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentHeaderOrganization.defaultProps;

// @ts-expect-error TS(7022): 'DocumentHeaderOrganization' implicitly has type '... Remove this comment to see the full error message
const DocumentHeaderOrganization = ({
  shouldShowBusinessName,
  shouldShowBusinessLicense,
  shouldShowBusinessAddressAndPhoneNumber,
  title,
  organization,
}: Props) => {
  return (
    <Container>
      {!!title && <Title>{title}</Title>}
      <OrganizationLogo
        organization={organization}
        style={{
          width: 195,
          height: 80,
          marginBottom: '5px',
        }}
      />
      {shouldShowBusinessName && <Name>{organization.businessName}</Name>}
      {shouldShowBusinessLicense && <License>{organization.businessLicense}</License>}
      {shouldShowBusinessAddressAndPhoneNumber && (
        <React.Fragment>
          <Text>{organization.businessAddress}</Text>
          <Text>{Phone.display(organization.phoneNumber)}</Text>
        </React.Fragment>
      )}
    </Container>
  );
};

DocumentHeaderOrganization.defaultProps = {
  shouldShowBusinessName: true,
  shouldShowBusinessLicense: true,
  shouldShowBusinessAddressAndPhoneNumber: true,
  title: '',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentHeaderOrganization.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment DocumentHeaderOrganization on Organization {
    id
    slug
    phoneNumber
    businessAddress
    businessLicense
    businessName
    ...OrganizationLogo
  }
`;

export default DocumentHeaderOrganization;
