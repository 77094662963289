// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Field, Navigation} from 'modules/App/components';
import {useUpdateJobRequestInternalNotesForm} from 'modules/JobRequest/Show/logic';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
  padding: 0px 15px;
`;

const Container = Styled.View`
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => ((props as any).mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  padding: 10px;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Button = Styled.LoadingButton`
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(600)}
`;

const UpdateJobRequestInternalNotesModal = ({jobRequest, trigger}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useUpdateJobRequestInternalNotesForm({
    jobRequest,
    onSuccess: () => handleClose(),
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      {trigger({handleOpen})}
      <Modal.Content isOpen={isOpen} onClose={handleClose}>
        <Overlay pointerEvents={'box-none'}>
          <Wrapper {...responsive}>
            <Container>
              <Header>
                <HeaderBorderSection {...responsive}>
                  <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                </HeaderBorderSection>
                <HeaderMainSection>
                  <Title>Update Internal Notes</Title>
                </HeaderMainSection>
                <HeaderBorderSection />
              </Header>
              <Content>
                <Field
                  {...form}
                  name={'internalNotes'}
                  label={'Internal notes'}
                  input={{
                    autoFocus: true,
                    multiline: true,
                    placeholder: `Enter internal notes`,
                    style: {
                      paddingVertical: 9,
                      minHeight: 150,
                    },
                  }}
                />
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <RowSpace {...responsive} />
                <Button loading={submitting} onPress={handleSubmit}>
                  <Text>Save</Text>
                </Button>
              </Content>
            </Container>
          </Wrapper>
        </Overlay>
      </Modal.Content>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateJobRequestInternalNotesModal.fragment = gql`
  ${useUpdateJobRequestInternalNotesForm.fragment}

  fragment UpdateJobRequestInternalNotesModal on JobRequest {
    id
    ...useUpdateJobRequestInternalNotesForm
  }
`;

export default UpdateJobRequestInternalNotesModal;
