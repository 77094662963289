// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import OrganizationPaymentFeesForm, {
  OrganizationPaymentFeesFormType,
} from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';

const useUpdateOrganizationPaymentFeesMutation = ({
  organizationPaymentFeesForm,
  onSuccess,
  onError,
}: {
  organizationPaymentFeesForm: OrganizationPaymentFeesFormType;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      organizationPaymentFeesForm: OrganizationPaymentFeesForm.toForm(organizationPaymentFeesForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationPaymentFeesMutation.mutation,
    variables: {
      organizationPaymentFeesForm: OrganizationPaymentFeesForm.toMutation(
        form.values.organizationPaymentFeesForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------

useUpdateOrganizationPaymentFeesMutation.mutation = gql`
  mutation useUpdateOrganizationPaymentFeesMutation($organizationPaymentFeesForm: OrganizationPaymentFeesForm!) {
    response: updateOrganizationPaymentFees(organizationPaymentFeesForm: $organizationPaymentFeesForm) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          jobRatesData
        }
      }
    }
  }
`;

export default useUpdateOrganizationPaymentFeesMutation;
