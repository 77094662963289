// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import WorkflowForm from '@shared/modules/Workflow/forms/WorkflowForm';
import useCreateWorkflowMutation from '@shared/modules/Workflow/hooks/useCreateWorkflowMutation';
import WorkflowDrawerFields from 'modules/Organization/Settings/Workflow/components/WorkflowDrawerFields';

const CreateWorkflowDrawer = ({handleClose, isOpen, onSuccess, organizationId}: any) => {
  const workflowForm = WorkflowForm.new({
    organizationId,
  });
  const {form, submitting, handleSubmit} = useCreateWorkflowMutation({
    workflowForm,
    onSuccess: ({workflow}: any) => {
      onSuccess(workflow);
    },
    onError: (error: any) => console.log(error),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Create Automation'}
      primaryActionText={'Create Automation'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <WorkflowDrawerFields form={form} />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateWorkflowDrawer.fragment = gql`
  fragment CreateWorkflowDrawer on Organization {
    id
  }
`;

export default CreateWorkflowDrawer;
