// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

import RecordPaymentForm, {
  RecordPaymentFormType,
} from '@shared/modules/Storage/forms/RecordPaymentForm';

const useRecordPaymentMutation = ({
  paymentForm,
  onSuccess,
  onError,
}: {
  paymentForm: RecordPaymentFormType;
  onSuccess: () => void;
  onError: (errors: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      paymentForm: RecordPaymentForm.toForm(paymentForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useRecordPaymentMutation.mutation,
    variables: {
      recordPaymentForm: RecordPaymentForm.toMutation(form.values.paymentForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useRecordPaymentMutation.mutation = gql`
  mutation useRecordPaymentMutation($recordPaymentForm: RecordPaymentForm!) {
    response: recordPayment(recordPaymentForm: $recordPaymentForm) {
      ${gql.errors}
      payment {
        id
      }
    }
  }
`;

export default useRecordPaymentMutation;
