/* eslint-disable react-hooks/exhaustive-deps */
// TODO(jholston): This should be used for recording any non-transacting payments.
// We should consolidate this with RecordPaymentDrawer.
// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useEffect, useQuery, useResponsive, useState} from '@supermove/hooks';
import {
  InvoiceModel,
  OrganizationModel,
  PaymentMethod,
  PaymentMethodModel,
} from '@supermove/models';
import {Currency} from '@supermove/utils';

// Components
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import useRecordPaymentMutation from '@shared/modules/Payment/hooks/useRecordPaymentMutation';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import RecordPaymentForm, {
  RecordPaymentFormToFormType,
} from '@shared/modules/Storage/forms/RecordPaymentForm';
import PaymentTotals from 'modules/Payment/components/PaymentTotals';

const RecordInvoicePaymentFields = ({
  form,
  field,
  organization,
  paymentMethod,
}: {
  form: Form<{paymentForm: RecordPaymentFormToFormType}>;
  field: string;
  organization: OrganizationModel;
  paymentMethod?: PaymentMethodModel;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Payment Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter a payment name',
          autoFocus: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.subtotal`}
        label={'Payment Amount'}
        component={CurrencyInput}
        isResponsive
        isRequired
        handleBlur={(e) => {
          const amount = Currency.convertToCents(e.currentTarget.value);
          form.setFieldValue(`${field}.subtotal`, Currency.toForm(amount));
          const paymentFeeAmount = paymentMethod
            ? PaymentMethod.computePaymentFeeAmount(paymentMethod, {amount})
            : 0;
          form.setFieldValue(`${field}.paymentFeeAmount`, Currency.toForm(paymentFeeAmount));
          form.setFieldValue(`${field}.amount`, Currency.toForm(paymentFeeAmount + amount));
        }}
        input={{
          component: FieldInput.TextInput,
          placeholder: 'Enter a payment amount',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        component={DropdownInput}
        name={`${field}.paymentMethodId`}
        label={'Payment Method'}
        isResponsive
        isRequired
        input={{
          showDescriptionInOption: true,
          options: PaymentMethod.getOfficePaymentMethodOptions({organization}),
          placeholder: 'Choose payment method',
          setFieldValue: form.setFieldValue,
          onChangeValue: (value: string, option: {kind: string}) => {
            form.setFieldValue(`${field}.method`, option.kind);
            form.setFieldValue(`${field}.paymentMethodId`, value);
          },
          style: {width: '100%'},
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        isResponsive
        name={`${field}.description`}
        label={'Payment Description'}
        isRequired={
          form.values.paymentForm.method === PaymentMethodKind.CHECK &&
          !form.values.paymentForm.description
        }
        input={{
          style: {height: 80, paddingTop: 10},
          multiline: true,
          placeholder: 'Enter a payment description',
        }}
      />
    </React.Fragment>
  );
};

const RecordInvoicePaymentDrawerContent = ({
  invoice,
  refetch,
  handleClose,
}: {
  invoice: InvoiceModel;
  refetch: () => void;
  handleClose: () => void;
}) => {
  const responsive = useResponsive();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodModel | undefined>(undefined);
  const paymentForm = RecordPaymentForm.new({
    name: `Payment for Invoice ${invoice.identifier}`,
    invoice,
  });
  const {form, submitting, handleSubmit} = useRecordPaymentMutation({
    paymentForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: unknown) => {
      console.log(errors);
    },
  });

  useEffect(() => {
    const newPaymentMethod = _.find(
      invoice.organization.paymentMethodsForOffice,
      (paymentMethod) =>
        _.toString(paymentMethod.id) === _.toString(form.values.paymentForm.paymentMethodId),
    );
    setPaymentMethod(newPaymentMethod);
    if (newPaymentMethod) {
      const subtotal = Currency.convertToCents(form.values.paymentForm.subtotal);
      const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(newPaymentMethod, {
        amount: subtotal,
      });
      form.setFieldValue('paymentForm.paymentFeeAmount', Currency.toForm(paymentFeeAmount));
      form.setFieldValue('paymentForm.amount', Currency.toForm(paymentFeeAmount + subtotal));
    }
  }, [form.values.paymentForm.paymentMethodId]);

  return (
    <React.Fragment>
      <Drawer.Body>
        <RecordInvoicePaymentFields
          form={form}
          field={'paymentForm'}
          organization={invoice.organization}
          paymentMethod={paymentMethod}
        />
      </Drawer.Body>
      <PaymentTotals
        paymentFeeAmount={form.values.paymentForm.paymentFeeAmount}
        total={form.values.paymentForm.amount}
        subtotal={form.values.paymentForm.subtotal}
        paymentMethod={paymentMethod}
      />
      {responsive.desktop ? (
        <Drawer.Footer
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          secondaryAction={handleClose}
          primaryActionText={'Submit'}
        />
      ) : (
        <Drawer.FooterMobile
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          primaryActionText={'Submit'}
        />
      )}
    </React.Fragment>
  );
};

const RecordInvoicePaymentDrawer = ({
  handleClose,
  isOpen,
  refetch,
  invoiceUuid,
}: {
  handleClose: () => void;
  isOpen: boolean;
  refetch: () => void;
  invoiceUuid: string;
}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(RecordInvoicePaymentDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      invoiceUuid,
    },
  });
  return (
    <Drawer isOpen={isOpen} width={Drawer.WIDTH.DEFAULT}>
      <Drawer.Header
        isResponsive
        headerText={`Record Payment`}
        description={
          'Add a payment name and enter an amount. This payment should have happened already.'
        }
        handleClose={handleClose}
        isClosable={!responsive.desktop}
      />
      <Loading loading={loading || !data}>
        {() => (
          <RecordInvoicePaymentDrawerContent
            handleClose={handleClose}
            refetch={refetch}
            invoice={data.invoiceByUuid}
          />
        )}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RecordInvoicePaymentDrawer.query = gql`
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  ${PaymentMethod.getOfficePaymentMethodOptions.fragment}
  ${PaymentTotals.fragment}
  ${RecordPaymentForm.new.fragment}
  query RecordInvoicePaymentDrawer ($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      identifier
      grandTotal
      organization {
        id
        paymentMethodsForOffice {
          id
          ...PaymentTotals
          ...PaymentMethod_computePaymentFeeAmount
        }
        ...PaymentMethod_getOfficePaymentMethodOptions
      }
      ...RecordPaymentForm_new
    }
  }
`;

export default RecordInvoicePaymentDrawer;
