// Libraries
import _ from 'lodash';
import React from 'react';

// App
import FileInputFieldV2 from '@shared/modules/File/components/FileInputFieldV2';
import ImportInventoryLibraryItemTagsForm from '@shared/modules/File/forms/ImportInventoryLibraryItemTagsForm';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useImportInventoryLibraryItemTagsMutation from '@shared/modules/File/hooks/useImportInventoryLibraryItemTagsMutation';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import ImportInventoryLibraryContentsModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryContentsModal';

const ImportInventoryLibraryItemTagsModal = ({
  viewerId,
  organizationId,
  inventoryLibraryId,
  kind,
  title,
  isOpen,
  handleClose,
  refetch,
}: any) => {
  // @ts-expect-error TS(7022): 'useUploadFileMutation' implicitly has type 'any' ... Remove this comment to see the full error message
  const useUploadFileMutation = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId,
      creatorId: viewerId,
    }),
    onSuccess: ({file}) => {
      importInventoryLibraryItemTagsMutation.form.setFieldValue(
        'importInventoryLibraryItemTagsForm.fileId',
        file.id,
      );
    },
    onError: (errors) =>
      FileInputFieldV2.resetUploadFileForm({
        field: 'uploadFileForm',
        form: useUploadFileMutation.form,
      }),
  });

  const importInventoryLibraryItemTagsMutation = useImportInventoryLibraryItemTagsMutation({
    importInventoryLibraryItemTagsForm: ImportInventoryLibraryItemTagsForm.new({
      inventoryLibraryId,
      kind,
    }),
    onSuccess: () => {
      FileInputFieldV2.resetUploadFileForm({
        name: 'uploadFileForm',
        form: useUploadFileMutation.form,
      });
      importInventoryLibraryItemTagsMutation.form.setFieldValue(
        'importInventoryLibraryItemTagsForm.fileId',
        null,
      );
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const validationErrors = _.reverse(
    _.values(importInventoryLibraryItemTagsMutation.form.errors.importInventoryLibraryItemTagsForm),
  );

  return (
    <ImportInventoryLibraryContentsModal
      useUploadFileMutation={useUploadFileMutation}
      isSubmitting={importInventoryLibraryItemTagsMutation.submitting}
      handleSubmit={importInventoryLibraryItemTagsMutation.handleSubmit}
      title={title}
      isOpen={isOpen}
      handleClose={handleClose}
      validationErrors={validationErrors}
    />
  );
};

export default ImportInventoryLibraryItemTagsModal;
