// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {List} from '@supermove/utils';

// App
import Tabs from '@shared/design/components/Tabs';

const getTabDefinitions = ({billingLibrary}: any) => {
  const {
    uuid,
    organization: {
      settings: {isCostAndCompensationEnabled},
    },
  } = billingLibrary;
  return [
    {
      key: 'BILL_ITEMS',
      label: 'Bill Items',
      link: `/settings/billing/billing-libraries/${uuid}/items/fees`,
    },
    {
      key: 'BILL_RULES',
      label: 'Bill Rules',
      link: `/settings/billing/billing-libraries/${uuid}/rules`,
    },
    {
      key: 'BILL_TEMPLATES',
      label: 'Bill Templates',
      link: `/settings/billing/billing-libraries/${uuid}/bill-templates`,
    },
    ...List.insertIf(isCostAndCompensationEnabled, {
      key: 'COST_ITEMS',
      label: 'Cost Items',
      link: `/settings/billing/billing-libraries/${uuid}/cost-items`,
    }),
    ...List.insertIf(isCostAndCompensationEnabled, {
      key: 'COMPENSATION_ITEMS',
      label: 'Compensation Items',
      link: `/settings/billing/billing-libraries/${uuid}/compensation-items`,
    }),
  ];
};

const BillingLibraryPageNavigationTabs = ({billingLibrary}: any) => {
  const {navigator} = useNavigationDOM();
  const tabDefinitions = getTabDefinitions({billingLibrary});
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (tab) =>
      navigator.location.pathname.startsWith(tab.link) ||
      navigator.location.pathname.includes(tab.link),
  );

  return (
    <Tabs
      tabs={tabDefinitions}
      handlePressTab={({link}) => navigator.replace(link)}
      activeTabIndex={activeTabIndex}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryPageNavigationTabs.fragment = gql`
  fragment BillingLibraryPageNavigationTabs on BillingLibrary {
    id
    uuid
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

export default BillingLibraryPageNavigationTabs;
