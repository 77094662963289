const CARTON = 'CARTON' as const;
const ITEM = 'ITEM' as const;

const ITEM_TYPE_KINDS = [CARTON, ITEM] as const;

export type ItemTypeKindType = (typeof ITEM_TYPE_KINDS)[number];

const getLabel = (kind: any) => {
  switch (kind) {
    case CARTON:
      return 'Carton';
    case ITEM:
      return 'Item';
    default:
      return '';
  }
};

const ItemTypeKind = {
  CARTON,
  ITEM,
  getLabel,
};

export default ItemTypeKind;
