// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Document} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const PrintButtonContainer = Styled.ButtonV2`
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
  width: 16px;
  height: 16px;
`;

const getDocumentPdf = async ({isEnabledShowCompletedDocuments, document, filename, url}: any) => {
  if (isEnabledShowCompletedDocuments && document.isCompleted) {
    // For completed documents, we fetch the PDF from S3 rather than generating from the live document
    const pdf = await Document.fetchPDFFromS3({filename, documentUuid: document.uuid});
    return pdf;
  } else {
    const pdf = await Document.generatePDFFromUrl({filename, url});
    return pdf;
  }
};

const openPrintDialog = ({url, setIsLoading, document, isEnabledShowCompletedDocuments}: any) => {
  const filename = 'document.pdf';
  url = `${window.location.origin}${url}`;
  setIsLoading(true);
  getDocumentPdf({isEnabledShowCompletedDocuments, document, filename, url}).then((pdf) => {
    // @ts-expect-error TS(2739): Type 'Blob' is missing the following properties fr... Remove this comment to see the full error message
    Document.printFile({file: pdf});
    setIsLoading(false);
  });
};

const PrintDocumentIcon = ({isDisabled}: any) => {
  return (
    <IconContainer>
      <Icon
        color={isDisabled ? colors.gray.tertiary : colors.blue.interactive}
        size={16}
        source={Icon.Print}
      />
    </IconContainer>
  );
};

const PrintDocumentButton = ({
  document,
  isEnabledShowCompletedDocuments,
  documentUrl,
  tooltipPlacement,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const isPrintingDisabled =
    isEnabledShowCompletedDocuments && document.isCompleted && !document.isUploaded;

  if (isLoading) {
    return (
      <IconContainer style={{justifyContent: 'flex-start'}}>
        <Indicator size={'small'} color={colors.gray.secondary} />
      </IconContainer>
    );
  }
  if (isPrintingDisabled) {
    return (
      <TextTooltip
        isEnabledMobileToast={false}
        text={'Document failed to upload'}
        placement={tooltipPlacement}
      >
        <IconContainer>
          <PrintDocumentIcon isDisabled />
        </IconContainer>
      </TextTooltip>
    );
  }
  return (
    <PrintButtonContainer
      onPress={() => {
        openPrintDialog({
          url: documentUrl,
          setIsLoading,
          document,
          isEnabledShowCompletedDocuments,
        });
      }}
    >
      <PrintDocumentIcon />
    </PrintButtonContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrintDocumentButton.fragment = gql`
  fragment PrintDocumentButton on Document {
    id
    uuid
    isUploaded
    isCompleted
  }
`;

export default PrintDocumentButton;
