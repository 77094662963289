// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

type OwnProps = {
  onClose: (...args: any[]) => any;
  side?: (...args: any[]) => any;
  title?: (...args: any[]) => any;
  subtitle?: (...args: any[]) => any;
  right?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobRequestPage.defaultProps;

// @ts-expect-error TS(7022): 'JobRequestPage' implicitly has type 'any' because... Remove this comment to see the full error message
const JobRequestPage = ({
  side,
  title,
  subtitle,
  variables,
  query,
  onClose,
  right,
  children,
}: Props) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({loading, data, navigator}: any) => (
      <Navigation
        side={side}
        title={title({loading, data})}
        subtitle={subtitle({loading, data})}
        left={() => <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />}
        right={() => right({loading, data, navigator})}
      />
    )}
    children={children}
  />
);

JobRequestPage.defaultProps = {
  // If we just have the close button, we can default to this width.
  side: () => 60,
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestPage.fragment = gql`
  fragment JobRequestPage on JobRequest {
    id
  }
`;

export default JobRequestPage;
