// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, PhoneInput, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const PreviewContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.gray.border};
`;

const PreviewText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.primary};
`;

const LoadingComponent = () => {
  return (
    <Space height={300}>
      <PageLoadingIndicator />
    </Space>
  );
};

const HEADER_TEXT = 'Send Virtual Walkthrough Link';

const UpdateInventoryKindVirtualWalkthroughModalContent = ({
  inventory,
  handleClose,
  onSuccess,
  isOpen,
}: any) => {
  const responsive = useResponsive();
  const {submitting, form, handleSubmit} = useInlineFormMutation({
    name: 'updateInventoryToVirtualWalkthroughForm',
    form: {
      inventoryId: inventory.id,
      userId: inventory.project.client.primaryContact.id,
      phoneNumber: inventory.project.client.primaryContact.phoneNumber,
    },
    mutation: gql`
      mutation useUpdateInventoryToVirtualWalkthroughMutation($updateInventoryToVirtualWalkthroughForm: UpdateInventoryToVirtualWalkthroughForm!) {
        response: updateInventoryToVirtualWalkthrough(updateInventoryToVirtualWalkthroughForm: $updateInventoryToVirtualWalkthroughForm) {
          ${gql.errors}
          inventory {
            id
          }
        }
      }
    `,
    onSuccess: () => {
      handleClose();
      onSuccess();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      handlePressOutside={handleClose}
      isMobileSheet
      shouldCloseOnClickOutside={false}
      sheetLabel={HEADER_TEXT}
      sheetStyle={{maxHeight: '100%'}}
      sheetBodyStyle={{flex: 1, paddingHorizontal: null}}
    >
      <ScrollView style={{flex: 1, paddingHorizontal: 16}}>
        {responsive.desktop && (
          <React.Fragment>
            <SmallModal.HeaderText>{HEADER_TEXT}</SmallModal.HeaderText>
            <Space height={12} />
          </React.Fragment>
        )}
        <SmallModal.Text responsive={responsive}>
          You will receive videos from your customers when they submit them through this link.
        </SmallModal.Text>
        <Space height={16} />
        <WarningCallout text={'Changing the phone number will also update it for this client.'} />
        <Space height={16} />
        <FieldInput
          {...form}
          isResponsive
          component={PhoneInput}
          name={'updateInventoryToVirtualWalkthroughForm.phoneNumber'}
          label={'Phone Number'}
          input={{
            autoFocus: true,
            setFieldValue: form.setFieldValue,
            placeholder: "Enter customer's phone number",
          }}
          style={{flex: 1}}
        />
        <Space height={16} />
        <SmallModal.Text responsive={responsive}>SMS Preview:</SmallModal.Text>
        <Space height={8} />
        <PreviewContainer>
          <PreviewText responsive={responsive}>
            {_.get(inventory, 'project.organization.textMessageTemplateBySystemTemplateKind.body')}
          </PreviewText>
        </PreviewContainer>
        <Space height={16} />
        {responsive.desktop ? (
          <SmallModal.Footer>
            <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
              Cancel
            </SmallModal.Button>
            <Space width={8} />
            <SmallModal.Button
              onPress={handleSubmit}
              isSubmitting={submitting}
              color={colors.blue.interactive}
            >
              Send
            </SmallModal.Button>
          </SmallModal.Footer>
        ) : (
          <Button
            text={'Send'}
            onPress={handleSubmit}
            isSubmitting={submitting}
            isWidthOfContainer
            isResponsive
          />
        )}
      </ScrollView>
    </SmallModal>
  );
};

const UpdateInventoryKindVirtualWalkthroughModal = ({
  isOpen,
  inventoryUuid,
  handleClose,
  onSuccess,
}: any) => {
  const {loading, data} = useQuery(UpdateInventoryKindVirtualWalkthroughModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      uuid: inventoryUuid,
    },
  });

  return (
    <Loading
      loading={loading || !data}
      as={() => {
        if (isOpen) {
          return (
            <SmallModal
              isOpen
              handleClose={handleClose}
              handlePressOutside={handleClose}
              isMobileSheet
            >
              <LoadingComponent />
            </SmallModal>
          );
        }
        return null;
      }}
    >
      {() => (
        <UpdateInventoryKindVirtualWalkthroughModalContent
          inventory={data.inventory}
          handleClose={handleClose}
          isOpen={isOpen}
          onSuccess={onSuccess}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateInventoryKindVirtualWalkthroughModal.query = gql`
  query UpdateInventoryKindVirtualWalkthroughModal($uuid: String!) {
    inventory(uuid: $uuid) {
      id
      project {
        id
        organization {
          id
          textMessageTemplateBySystemTemplateKind(systemTemplateKind: "START_VIRTUAL_WALKTHROUGH") {
            id
            body
          }
        }
        client {
          id
          primaryContact {
            id
            phoneNumber
          }
        }
      }
    }
  }
`;

export default UpdateInventoryKindVirtualWalkthroughModal;
