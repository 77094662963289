// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import DocumentTemplateV2Form from '@shared/modules/Document/forms/DocumentTemplateV2Form';
import useCreateDocumentTemplateMutation from '@shared/modules/Document/hooks/useCreateDocumentTemplateMutation';
import DefaultQuoteConfirmationDocumentTemplateFields from 'modules/Organization/Settings/Document/components/DefaultQuoteConfirmationDocumentTemplateFields';

const CreateDocumentTemplateDrawer = ({
  createDocumentTemplateDrawer,
  organization,
  userId,
}: any) => {
  const {navigator} = useNavigationDOM();
  const documentTemplateV2Form = DocumentTemplateV2Form.new({
    organizationId: organization.id,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateDocumentTemplateMutation({
    documentTemplateV2Form,
    onSuccess: (response: any) => {
      navigator.push(
        `/settings/documents/document-templates/${response.documentTemplate.uuid}` +
          `/version/${response.documentTemplate.draftTemplateVersion.uuid}` +
          `${organization.features.isEnabledDocumentSections ? '/edit' : ''}`,
      );
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={createDocumentTemplateDrawer.isOpen}
      handleClose={createDocumentTemplateDrawer.handleClose}
      headerText={'Create Document Template'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Create'}
    >
      <FieldInput
        {...form}
        index={0}
        label={'Name'}
        name={'documentTemplateV2Form.name'}
        input={{
          required: !_.get(form.values, 'documentTemplateV2Form.name'),
          placeholder: 'Enter a name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Description'}
        name={'documentTemplateV2Form.description'}
        input={{placeholder: 'Enter a description'}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        label={'Document Category'}
        name={`documentTemplateV2Form.category`}
        component={DropdownInput}
        input={{
          required: !_.get(form.values, `documentTemplateV2Form.category`),
          options: DocumentTemplateCategory.DOCUMENT_TEMPLATE_CATEGORY_OPTIONS,
          placeholder: 'Select a category',
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(name, value);

            // Default the send email document to false for invoice doc
            if (value === DocumentTemplateCategory.INVOICE) {
              form.setFieldValue('documentTemplateV2Form.shouldEmailDocumentToCustomer', false);
            } else {
              form.setFieldValue('documentTemplateV2Form.shouldEmailDocumentToCustomer', true);
            }
          },
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
      {form.values.documentTemplateV2Form.category !== DocumentTemplateCategory.INVOICE && (
        <SwitchField
          index={3}
          form={form}
          field={'documentTemplateV2Form.shouldEmailDocumentToCustomer'}
          labelRight={'Email this document to customers at the end of a move'}
        />
      )}
      <Space height={16} />
      <DefaultQuoteConfirmationDocumentTemplateFields
        form={form}
        field={'documentTemplateV2Form'}
        isCreate
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateDocumentTemplateDrawer.fragment = gql`
  fragment CreateDocumentTemplateDrawer on Organization {
    id
    features {
      isEnabledDocumentSections: isEnabled(feature: "DOCUMENT_SECTIONS")
    }
  }
`;

export default CreateDocumentTemplateDrawer;
