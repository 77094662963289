// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

const BlockContainer = Styled.View`
  padding-horizontal: 16px;
  padding-vertical: 24px;
`;

const BlockHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const MobileEditJobBlock = ({headerText, responsive, children}: any) => {
  return (
    <BlockContainer>
      <BlockHeader responsive={responsive}>{headerText}</BlockHeader>
      <Space height={16} />
      {children}
    </BlockContainer>
  );
};

export default MobileEditJobBlock;
