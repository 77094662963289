// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, useResponsive, useSheet} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {List, Currency, Datetime} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import Line from 'components/Line';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import PaymentSheet from 'modules/Payment/components/PaymentSheet';
import BillingProjectPaymentsTable from 'modules/Project/Billing/components/BillingProjectPaymentsTable';
import RecordPaymentDrawer from 'modules/Project/Billing/components/RecordPaymentDrawer';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';

const Container = Styled.View`
`;

const TouchableContainer = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PaymentsSectionContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  padding: 12px;
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label}
  ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color && `color: ${color};`}
`;

const MobileMicroLabelText = Styled.Text`
  ${Typography.Mobile.MicroLabel}
  ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color && `color: ${color};`}
`;

const MobileMicroText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const PaymentsActions = ({
  project,
  recordPaymentModal,
  chargeCreditCardDrawer,
  manageCreditsCardDrawer,
}: any) => {
  const isEnabledPayengine = Project.getIsEnabledCreditCards(project);
  const responsive = useResponsive();

  return (
    <DropdownButton
      isSmall={responsive.desktop}
      text={responsive.desktop ? 'Record Payment' : 'Record'}
      menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      menuWidth={248}
      iconLeft={responsive.desktop ? Icon.Plus : undefined}
      ButtonComponent={responsive.desktop ? undefined : TertiaryButton}
      isResponsive
      actions={[
        {
          text: 'Record payment',
          onPress: recordPaymentModal.handleOpen,
        },
        ...List.insertIf(isEnabledPayengine, {
          text: 'Charge credit card',
          onPress: chargeCreditCardDrawer.handleOpen,
        }),
        ...List.insertIf(isEnabledPayengine, {
          text: 'Manage credit cards',
          onPress: manageCreditsCardDrawer.handleOpen,
        }),
      ]}
    />
  );
};

const PaymentInfo = ({payment, isMobile}: any) => {
  return (
    <Container style={{paddingVertical: 8}}>
      <Row style={{alignItems: 'center'}}>
        <MobileLabelText>{payment.name}</MobileLabelText>
        <Space style={{flex: 1}} />
        {isMobile && <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={16} />}
      </Row>
      <Space height={8} />
      <MobileMicroText>
        {Datetime.convertToDisplayDatetime(payment.createdAt, Datetime.DISPLAY_DATETIME)}
      </MobileMicroText>
      <Space height={8} />
      <MobileMicroText>{Currency.display(payment.totalAmount)}</MobileMicroText>
    </Container>
  );
};

const PaymentCard = ({payment, responsive, isEnabledStorageInvoicePayment, refetch}: any) => {
  const deletePaymentSheet = useSheet({
    name: 'Delete Payment Sheet',
    enableTracking: true,
  });

  const isCreditCardPayment = PaymentMethodKind.getIsCreditCardKind(payment.method);
  const isMobile = isEnabledStorageInvoicePayment && !responsive.desktop && !isCreditCardPayment;

  return (
    <React.Fragment>
      {isMobile ? (
        <TouchableContainer onPress={deletePaymentSheet.handleOpen}>
          <PaymentInfo payment={payment} isMobile={isMobile} />
        </TouchableContainer>
      ) : (
        <PaymentInfo payment={payment} isMobile={isMobile} />
      )}
      <PaymentSheet
        key={`DeletePaymentSheet_${payment.id}`}
        payment={payment}
        isOpen={deletePaymentSheet.isOpen}
        handleClose={deletePaymentSheet.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const PaymentCardList = ({payments, responsive, isEnabledStorageInvoicePayment, refetch}: any) => {
  return payments.map((payment: any) => {
    return (
      <React.Fragment key={payment.id}>
        <PaymentCard
          payment={payment}
          responsive={responsive}
          isEnabledStorageInvoicePayment={isEnabledStorageInvoicePayment}
          refetch={refetch}
        />
        <Line />
      </React.Fragment>
    );
  });
};

const RemainingBalance = ({project}: any) => {
  const {isTotalAvailable, minBalance, maxBalance} = project.activeJobsAggregateBill;
  const balance = isTotalAvailable
    ? // @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message
      Currency.formatRange({min: minBalance, max: maxBalance})
    : 'TBD';
  const balanceColor = Project.getProjectBillBalanceColor({
    aggregateBill: project.activeJobsAggregateBill,
  });

  return (
    <Row style={{justifyContent: 'space-between', paddingVertical: 8}}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <MobileLabelText color={balanceColor}>Remaining Balance</MobileLabelText>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <MobileMicroLabelText color={balanceColor}>{balance}</MobileMicroLabelText>
    </Row>
  );
};

const PaymentsBody = ({project, refetch, isEnabledStorageInvoicePayment}: any) => {
  const {payments} = project.activeJobsAggregateBill;
  const responsive = useResponsive();

  return responsive.mobile ? (
    payments.length === 0 ? (
      <MobileMicroText>No Payments</MobileMicroText>
    ) : (
      <React.Fragment>
        <PaymentCardList
          payments={payments}
          responsive={responsive}
          isEnabledStorageInvoicePayment={isEnabledStorageInvoicePayment}
          refetch={refetch}
        />
        <RemainingBalance project={project} />
      </React.Fragment>
    )
  ) : (
    <Container style={{paddingHorizontal: 12}}>
      <BillingProjectPaymentsTable project={project} refetch={refetch} />
    </Container>
  );
};

const ProjectBillingPaymentsSection = ({project, refetch, isEnabledPaymentActions}: any) => {
  const title = 'Payments';
  const responsive = useResponsive();
  const recordPaymentModal = useModal({name: 'Record Payment Modal'});
  const chargeCreditCardDrawer = useDrawer({
    name: 'Charge Credit Card Drawer',
    enableTracking: true,
  });
  const manageCreditsCardDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });

  const {isEnabledStorageInvoicePayment} = project.organization.features;

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Panel>
          <Panel.Header style={{padding: 12}}>
            <Panel.HeaderText>{title}</Panel.HeaderText>
            <Space style={{flex: 1}} />
            {isEnabledPaymentActions && (
              <PaymentsActions
                project={project}
                recordPaymentModal={recordPaymentModal}
                chargeCreditCardDrawer={chargeCreditCardDrawer}
                manageCreditsCardDrawer={manageCreditsCardDrawer}
              />
            )}
          </Panel.Header>
          <Panel.Body style={{paddingVertical: 0}}>
            <Space height={12} />
            <PaymentsBody
              project={project}
              refetch={refetch}
              isEnabledStorageInvoicePayment={isEnabledStorageInvoicePayment}
            />
            <Space height={12} />
          </Panel.Body>
        </Panel>
      ) : (
        <React.Fragment>
          <PaymentsSectionContainer>
            <MobileProjectBlockHeader
              title={title}
              // @ts-expect-error TS(2322): Type '(({ project, recordPaymentModal, chargeCredi... Remove this comment to see the full error message
              ActionsComponent={isEnabledPaymentActions ? PaymentsActions : null}
              actionsComponentProps={{
                project,
                recordPaymentModal,
                chargeCreditCardDrawer,
                manageCreditsCardDrawer,
              }}
            />
            <PaymentsBody
              project={project}
              refetch={refetch}
              isEnabledStorageInvoicePayment={isEnabledStorageInvoicePayment}
            />
          </PaymentsSectionContainer>
        </React.Fragment>
      )}
      <RecordPaymentDrawer
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        billUuid={project.currentPrimaryBill.uuid}
      />
      <InvoiceChargeCreditCardDrawer
        key={chargeCreditCardDrawer.key}
        isOpen={chargeCreditCardDrawer.isOpen}
        handleClose={chargeCreditCardDrawer.handleClose}
        invoiceUuid={project.mainMoveInvoice.uuid}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditsCardDrawer.key}
        isOpen={manageCreditsCardDrawer.isOpen}
        handleClose={manageCreditsCardDrawer.handleClose}
        clientId={project.billingClientId}
        projectId={project.id}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectBillingPaymentsSection.fragment = gql`
  ${Project.getIsEnabledCreditCards.fragment}
  ${Project.getProjectBillBalanceColor.fragment}
  ${BillingProjectPaymentsTable.fragment}
  ${PaymentSheet.fragment}

  fragment ProjectBillingPaymentsSection on Project {
    id
    billingClientId
    currentPrimaryBill {
      id
      uuid
    }
    mainMoveInvoice {
      id
      uuid
    }
    activeJobsAggregateBill {
      payments {
        id
        ...PaymentSheet
      }
      isTotalAvailable
      minBalance
      maxBalance
      ...Project_getProjectBillBalanceColor
    }
    organization {
      id
      features {
        isEnabledStorageInvoicePayment: isEnabled(feature: "STORAGE_INVOICE_PAYMENT")
      }
    }
    ...Project_getIsEnabledCreditCards
    ...BillingProjectPaymentsTable
  }
`;

export default ProjectBillingPaymentsSection;
