// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectTypeValuationCoverageDataTable from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeValuationCoverageDataTable';
import UploadValuationCoverageTableModal from 'modules/Organization/Settings/ProjectTypes/components/UploadValuationCoverageTableModal';

const Text = Styled.Text`
  ${Typography.Body3}
`;

const ProjectTypeValuationCoverageContent = ({
  projectTypeUuid,
  uploadValuationCoverageTableModal,
}: any) => {
  const {loading, data, refetch} = useQuery(ProjectTypeValuationCoverageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <React.Fragment>
      <UploadValuationCoverageTableModal
        key={uploadValuationCoverageTableModal.key}
        isOpen={uploadValuationCoverageTableModal.isOpen}
        handleClose={uploadValuationCoverageTableModal.handleClose}
        dataTables={data.viewer.viewingOrganization.dataTables}
        projectTypeId={data.projectTypeByUuid.id}
        organizationId={data.viewer.viewingOrganization.id}
        userId={data.viewer.id}
        refetch={refetch}
        currentValuationCoverageDataTableId={
          data.projectTypeByUuid.valuationCoverageDataTable &&
          data.projectTypeByUuid.valuationCoverageDataTable.id
        }
      />
      <Space height={32} />
      {data.projectTypeByUuid.valuationCoverageDataTable ? (
        <ProjectTypeValuationCoverageDataTable
          valuationCoverageDataTableUuid={data.projectTypeByUuid.valuationCoverageDataTable.uuid}
        />
      ) : (
        <Text>
          This project type uses the default valuation coverage. Upload a new table above.
        </Text>
      )}
      <Space height={48} />
    </React.Fragment>
  );
};

ProjectTypeValuationCoverageContent.query = gql`
  ${UploadValuationCoverageTableModal.fragment}

  query ProjectTypeValuationCoverageContent(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      valuationCoverageDataTable {
        id
        uuid
      }
    }
    viewer {
      id
      viewingOrganization {
        id
        dataTables {
          id
          ...UploadValuationCoverageTableModal
        }
      }
    }
  }
`;

export default ProjectTypeValuationCoverageContent;
