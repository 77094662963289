// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const ItalicizedText = Styled.Text`
  ${Typography.Responsive.Body}
  font-style: italic;
  color: ${colors.gray.tertiary};
`;

const FilenameField = ({index, label, form, field, isEdit, attachment, responsive}: any) => {
  return isEdit ? (
    <FieldInput
      {...form}
      index={index}
      name={field}
      label={label}
      input={{
        placeholder: 'Enter file name',
      }}
      isRequired
      isResponsive
    />
  ) : (
    <React.Fragment>
      <LabelText responsive={responsive}>{label}</LabelText>
      <Space height={4} />
      <BodyText responsive={responsive}>{attachment.file.name}</BodyText>
    </React.Fragment>
  );
};

const NotesField = ({index, label, form, field, isEdit, attachment, responsive}: any) => {
  return isEdit ? (
    <FieldInput
      {...form}
      index={index}
      name={field}
      label={label}
      input={{
        style: {height: 180, paddingTop: 8},
        multiline: true,
        placeholder: 'Enter notes',
      }}
      isResponsive
    />
  ) : (
    <React.Fragment>
      <LabelText responsive={responsive}>{label}</LabelText>
      <Space height={4} />
      {attachment.description ? (
        <BodyText responsive={responsive}>{attachment.description}</BodyText>
      ) : (
        <ItalicizedText responsive={responsive}>None</ItalicizedText>
      )}
    </React.Fragment>
  );
};

const VisibleToCrewField = ({label, form, field, isEdit, attachment, responsive}: any) => {
  return isEdit ? (
    <Checkbox
      isChecked={_.get(form.values, field)}
      handleToggle={(isChecked) => {
        form.setFieldValue(field, isChecked);
      }}
      childrenRight
    >
      <Space width={8} />
      <BodyText responsive={responsive}>{label}</BodyText>
    </Checkbox>
  ) : (
    <React.Fragment>
      <LabelText responsive={responsive}>{label}</LabelText>
      <Space height={4} />
      <BodyText responsive={responsive}>{attachment.isVisibleToCrew ? 'Yes' : 'No'}</BodyText>
    </React.Fragment>
  );
};

const AttachmentFieldsV2 = ({form, field, attachment, isEdit}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <LabelText responsive={responsive}>Uploaded By</LabelText>
      <Space height={4} />
      <BodyText responsive={responsive}>{attachment.file.creator.fullName}</BodyText>
      <Space height={16} />
      <LabelText responsive={responsive}>Uploaded At</LabelText>
      <Space height={4} />
      <BodyText responsive={responsive}>
        {Datetime.convertToDisplayDatetime(attachment.file.uploadedAt, Datetime.DISPLAY_DATETIME)}
      </BodyText>
      <Space height={16} />
      <FilenameField
        index={0}
        form={form}
        field={`${field}.filename`}
        label={'File Name'}
        isEdit={isEdit}
        attachment={attachment}
        responsive={responsive}
      />
      <Space height={16} />
      <NotesField
        index={1}
        form={form}
        field={`${field}.description`}
        label={'Notes'}
        isEdit={isEdit}
        attachment={attachment}
        responsive={responsive}
      />
      <Space height={16} />
      <VisibleToCrewField
        form={form}
        field={`${field}.isVisibleToCrew`}
        label={'Visible To Crew'}
        isEdit={isEdit}
        attachment={attachment}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentFieldsV2.fragment = gql`
  fragment AttachmentFieldsV2 on Attachment {
    id
    description
    isVisibleToCrew
    file {
      id
      name
      uploadedAt
      creator {
        id
        fullName
      }
    }
  }
`;

export default AttachmentFieldsV2;
