// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, typography} from '@supermove/styles';

// App
import {Navigation} from 'modules/App/components';

import useAppContext from '../context/useAppContext';

import UpdateSuperAdminOrganizationForm from './UpdateSuperAdminOrganizationForm';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
  ${(props) => ((props as any).mobile ? 'height: 100%;' : '')}
`;

const Container = Styled.View`
  ${(props) => ((props as any).mobile ? 'flex: 1;' : '')}
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => ((props as any).mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? typography.h6() : '')}
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.primary};
  text-align: center;
`;

type OwnProps = {
  trigger: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateSuperAdminOrganizationModal.defaultProps;

// @ts-expect-error TS(7022): 'UpdateSuperAdminOrganizationModal' implicitly has... Remove this comment to see the full error message
const UpdateSuperAdminOrganizationModal = ({trigger}: Props) => {
  const responsive = useResponsive();
  const {viewer} = useAppContext();

  return (
    <Modal trigger={trigger}>
      {({handleClose}: any) => (
        <Overlay pointerEvents={'box-none'}>
          <Wrapper {...responsive}>
            <Container {...responsive}>
              <Header>
                <HeaderBorderSection {...responsive}>
                  <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                </HeaderBorderSection>
                <HeaderMainSection>
                  {/* @ts-expect-error TS(2769): No overload matches this call. */}
                  <Title vars={responsive}>Change Organization</Title>
                  <Subtitle>{`Current: ${viewer?.viewingOrganization.slug}`}</Subtitle>
                </HeaderMainSection>
                <HeaderBorderSection />
              </Header>
              <UpdateSuperAdminOrganizationForm />
            </Container>
          </Wrapper>
        </Overlay>
      )}
    </Modal>
  );
};

UpdateSuperAdminOrganizationModal.defaultProps = {};

export default UpdateSuperAdminOrganizationModal;
