// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Phone} from '@supermove/utils';

// App
import SendCustomerReportMoveInvoiceMutation from './SendCustomerReportMoveInvoiceMutation';

const getBody = ({job}: any) => {
  const {organization} = job.project;

  return [
    `Hi ${job.customer.fullName},`,
    ``,
    `Thank you for choosing ${organization.name} for your move. It was a pleasure to serve you.`,
    `Attached below is a copy of an invoice with an outstanding balance.`,
    ``,
    `Please call or email us anytime if you have questions, concerns, or comments.`,
    ``,
    `Thanks,`,
  ]
    .concat(getSignature({job}))
    .join('<br>');
};

const getSignature = ({job}: any) => {
  const {organization} = job.project;

  if (job.bookedBy) {
    return [
      `${job.bookedBy.fullName}, ${_.startCase(_.toLower(job.bookedBy.position))}`,
      `${organization.name}${
        organization.phoneNumber ? ` | ${Phone.display(organization.phoneNumber)}` : ''
      }`,
    ];
  } else {
    return [
      organization.name,
      organization.phoneNumber ? Phone.display(organization.phoneNumber) : '',
    ];
  }
};

type OwnSendInvoiceReportMoveFormProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'SendInvoiceReportMoveFormProps' circul... Remove this comment to see the full error message
type SendInvoiceReportMoveFormProps = OwnSendInvoiceReportMoveFormProps &
  typeof SendInvoiceReportMoveForm.defaultProps;

// @ts-expect-error TS(7022): 'SendInvoiceReportMoveForm' implicitly has type 'a... Remove this comment to see the full error message
const SendInvoiceReportMoveForm = ({
  job,
  onSuccess,
  onError,
  children,
}: SendInvoiceReportMoveFormProps) => {
  return (
    <Form
      initialValues={{
        jobId: job.id,
        body: getBody({job}),
        subject: `Invoice from ${job.project.organization.name}`,
        attachmentIds: [],
      }}
      onSubmit={() => {}}
    >
      {({values, submitForm, setMutationErrors, ...form}: any) => (
        <SendCustomerReportMoveInvoiceMutation
          values={values}
          onSuccess={onSuccess}
          onError={onError}
          setMutationErrors={setMutationErrors}
          submitForm={submitForm}
        >
          {({loading, handleSubmit}: any) =>
            children({
              ...form,
              loading,
              values,
              handleSubmit,
            })
          }
        </SendCustomerReportMoveInvoiceMutation>
      )}
    </Form>
  );
};

SendInvoiceReportMoveForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendInvoiceReportMoveForm.fragment = gql`
  fragment SendInvoiceReportMoveForm on Job {
    id
    startTime1
    startTime2
    project {
      id
      organization {
        id
        slug
        name
        phoneNumber
      }
    }
    day {
      id
      value
    }
    customer {
      id
      fullName
    }
    bookedBy {
      id
      fullName
      position
    }
  }
`;

export default SendInvoiceReportMoveForm;
