// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import useUncancelJobMutation from '@shared/modules/Job/hooks/useUncancelJobMutation';

const RestoreJobModal = ({job, isOpen, handleClose, refetch}: any) => {
  const {submitting, handleSubmit} = useUncancelJobMutation({
    jobId: job.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <ConfirmationModal
      isOpen={isOpen}
      icon={Icon.ArrowRotateRight}
      title={`Restore ${job.fullName}?`}
      subtitle={`Restoring this job will add it back to the project.`}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      // @ts-expect-error TS(2322): Type '{ isOpen: any; icon: readonly ["fas", "arrow... Remove this comment to see the full error message
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RestoreJobModal.fragment = gql`
  fragment RestoreJobModal on Job {
    id
    fullName
  }
`;

export default RestoreJobModal;
