// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import Drawer from '@shared/design/components/Drawer';

const ContainerContent = Styled.View`
  padding-left: 16px;
  padding-right: 8px;
  padding-vertical: 8px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
`;

const Text = Styled.Text`
  ${Typography.Label}
`;

const PrimaryBadgeContainer = Styled.View`
  background-color: ${colors.orange.status};
  padding-horizontal: 8px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-radius: 20px;
`;

const PrimaryBadgeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.white};
  ${fontWeight(700)}
`;

const EmptyText = Styled.Text`
  ${Typography.Body}
`;

type OwnViewLaborSourcesPopoverContentProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'ViewLaborSourcesPopoverContentProps' c... Remove this comment to see the full error message
type ViewLaborSourcesPopoverContentProps = OwnViewLaborSourcesPopoverContentProps &
  typeof ViewLaborSourcesPopoverContent.defaultProps;

// @ts-expect-error TS(7022): 'ViewLaborSourcesPopoverContent' implicitly has ty... Remove this comment to see the full error message
const ViewLaborSourcesPopoverContent = ({
  job,
  viewer,
  handleClose,
}: ViewLaborSourcesPopoverContentProps) => {
  const responsive = useResponsive();
  const sortedCrews = _.sortBy(
    job.crews,
    (crew) => !crew.isPrimary,
    (crew) => crew.organization.name,
  );
  const otherLaborSources = sortedCrews.filter(
    (crew) => crew.organization.id !== viewer.viewingOrganization.id,
  );
  return (
    <React.Fragment>
      {responsive.mobile && (
        <Drawer.HeaderMobile headerText={'Labor Sources'} handleClose={handleClose} />
      )}
      <ContainerContent>
        {_.isEmpty(otherLaborSources) ? (
          <EmptyText style={{alignSelf: 'center'}}>No other labor sources</EmptyText>
        ) : (
          otherLaborSources.map((crew) => <CrewRow crew={crew} key={crew.id} />)
        )}
      </ContainerContent>
    </React.Fragment>
  );
};

const CrewRow = ({crew}: any) => {
  return (
    <Row>
      <Text>{crew.organization.name}</Text>
      <Space width={8} />
      <Icon source={Icon.Truck} size={13} color={colors.gray.primary} />
      <Space width={4} />
      <Text>{crew.numberOfRequiredTrucks}</Text>
      <Space width={6} />
      <Icon source={Icon.User} size={13} color={colors.gray.primary} />
      <Space width={4} />
      <Text>{crew.numberOfRequiredMovers}</Text>
      <Space width={8} />
      {crew.isPrimary && (
        <PrimaryBadgeContainer>
          <PrimaryBadgeText>Primary</PrimaryBadgeText>
        </PrimaryBadgeContainer>
      )}
    </Row>
  );
};

ViewLaborSourcesPopoverContent.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewLaborSourcesPopoverContent.fragment = gql`
  fragment ViewLaborSourcesPopoverContent_Job on Job {
    id
    crews {
      id
      numberOfRequiredTrucks
      numberOfRequiredMovers
      isPrimary
      organization {
        id
        name
      }
    }
  }

  fragment ViewLaborSourcesPopoverContent_Viewer on User {
    id
    viewingOrganization {
      id
    }
  }
`;

export default ViewLaborSourcesPopoverContent;
