// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';

import {ModalPage, Navigation} from 'modules/App/components';

const Container = Styled.View`
  flex: 1;
`;

const CustomerPage = ({
  fetchPolicy,
  pollInterval,
  query,
  right,
  subtitle,
  title,
  variables,
  children,
}: any) => {
  const navigation =
    title || right
      ? ({loading, data, navigator, responsive, refetch}: any) => (
          <Navigation
            right={right && (() => right({loading, data, navigator, responsive, refetch}))}
            subtitle={subtitle({loading, data})}
            title={title({loading, data})}
          />
        )
      : () => null;
  return (
    <ModalPage
      fetchPolicy={fetchPolicy}
      navigation={navigation}
      pollInterval={pollInterval}
      query={query}
      variables={variables}
    >
      {(props: any) => <Container>{children(props)}</Container>}
    </ModalPage>
  );
};

export default CustomerPage;
