// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Trip} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/TableV2Deprecated';
import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import StatusBadge from 'modules/App/components/StatusBadge';

const Title = Styled.Text`
  ${Typography.Heading2}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const Container = Styled.View`
  flex: 1;
`;

const getColumnDefinitions = ({job, navigator}: any) => [
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Trip Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: shipment}) => {
      if (!shipment.trip) {
        return <Text>{'-'}</Text>;
      }
      return (
        <Container>
          <TertiaryButton
            style={{flex: 1, justifyContent: 'flex-start'}}
            onPress={() =>
              navigator.push(`/dispatch/long-distance/trips/${shipment.trip.uuid}/shipments`)
            }
          >
            <LinkText>{shipment.trip.name}</LinkText>
          </TertiaryButton>
        </Container>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Trip Status</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: shipment}) => {
      if (!shipment.trip) {
        return <Text>{'-'}</Text>;
      }
      const statusOption = TripStatus.getStatusOption(shipment.trip.status);
      return <StatusBadge {...statusOption} />;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Trip Dates</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: shipment}) => {
      const {trip} = shipment;
      return <Text>{trip ? Trip.getDisplayDates(trip) : '-'}</Text>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Shipment Weight</TableHeaderText>;
    },
    cellContent: () => {
      const {weight} = job.project;
      return <Text>{weight ? pluralize('lb', weight, true) : '-'}</Text>;
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Shipment Status</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: shipment}) => {
      const statusOption = ShipmentStatus.getStatusOption(shipment.status);
      return <StatusBadge {...statusOption} />;
    },
  },
];

const JobDispatchBlockShipments = ({job}: any) => {
  const {navigator} = useNavigationDOM();
  const shipmentWithTrip = _.find(job.project.shipments, (shipment) => !!shipment.trip);
  return (
    <React.Fragment>
      <Title>Dispatch</Title>
      <Space height={8} />
      <Table
        columnDefinitions={getColumnDefinitions({job, navigator})}
        emptyStateText='No trip assigned'
        items={shipmentWithTrip ? job.project.shipments : []}
        isDense
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchBlockShipments.fragment = gql`
  fragment JobDispatchBlockShipments on Job {
    id
    project {
      id
      weight
      shipments {
        id
        status
        weight
        trip {
          id
          uuid
          name
          status
          startDate
          endDate
        }
      }
    }
  }
`;

export default JobDispatchBlockShipments;
