// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';

// App
import DropdownInputComponent from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowAssignOfficeUserRole from '@shared/modules/Workflow/enums/WorkflowAssignOfficeUserRole';
import WorkflowProjectAssignmentType from '@shared/modules/Workflow/enums/WorkflowProjectAssignmentType';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const WorkflowStepAssignOfficeStaffSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
}: any) => {
  const {label, description} =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.ASSIGN_OFFICE_STAFF];
  const field = `${workflowStepsField}.${workflowStepIndex}.assignOfficeStaffActionForm`;
  const workflowProjectAssignmentType = _.get(
    form.values,
    `${field}.workflowProjectAssignmentType`,
  );
  const {organization} = workflow;
  const isDoneDisabled =
    !_.get(form.values, `${field}.userRoleKind`) ||
    !_.get(form.values, `${field}.workflowProjectAssignmentType`) ||
    (workflowProjectAssignmentType === WorkflowProjectAssignmentType.SPECIFIC_USER &&
      !_.get(form.values, `${field}.specificUserId`)) ||
    (workflowProjectAssignmentType === WorkflowProjectAssignmentType.ROUND_ROBIN &&
      _.isEmpty(_.get(form.values, `${field}.roundRobinUserIds`, [])));

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.userRoleKind`}
        label={'Role'}
        isRequired
        input={{
          options: WorkflowAssignOfficeUserRole.DROPDOWN_OPTIONS,
          placeholder: 'Select user role',
          isSearchable: false,
          isPortaled: true,
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(`${field}.userRoleKind`, value);
            form.setFieldValue(`${field}.specificUserId`, null);
            form.setFieldValue(`${field}.roundRobinUserIds`, []);
          },
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.workflowProjectAssignmentType`}
        label={'Assignment Method'}
        isRequired
        input={{
          options: WorkflowProjectAssignmentType.DROPDOWN_OPTIONS,
          placeholder: 'Select assignment method',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(`${field}.workflowProjectAssignmentType`, value);
            form.setFieldValue(`${field}.specificUserId`, null);
            form.setFieldValue(`${field}.roundRobinUserIds`, []);
          },
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      {workflowProjectAssignmentType === WorkflowProjectAssignmentType.SPECIFIC_USER && (
        <React.Fragment>
          <FieldInput.LabelText isRequired>User</FieldInput.LabelText>
          <Space height={4} />
          <DropdownInputComponent
            {...form}
            options={Organization.getOfficeStaffDropdownOptions(organization)}
            name={'Specific User'}
            value={_.get(form.values, `${field}.specificUserId`)}
            setFieldValue={(name, value) => {
              form.setFieldValue(`${field}.specificUserId`, value);
            }}
            placeholder={'Select a user'}
            style={{flex: 1}}
            isPortaled
          />
        </React.Fragment>
      )}
      {workflowProjectAssignmentType === WorkflowProjectAssignmentType.ROUND_ROBIN && (
        <React.Fragment>
          <FieldInput.LabelText isRequired>Rotation</FieldInput.LabelText>
          <Space height={4} />
          {/* @ts-expect-error TS(2740): Type '{ options: any; placeholder: string; style: ... Remove this comment to see the full error message */}
          <MultiDropdownInput
            options={Organization.getOfficeStaffDropdownOptions(organization)}
            placeholder={'Select users'}
            style={{width: '100%'}}
            value={_.get(form.values, `${field}.roundRobinUserIds`, [])}
            onChangeValue={(roundRobinUserIds) => {
              form.setFieldValue(`${field}.roundRobinUserIds`, roundRobinUserIds);
            }}
            isSearchable
            isPortaled
          />
        </React.Fragment>
      )}
    </WorkflowStepSlide>
  );
};

const WorkflowStepAssignOfficeStaffDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}: any) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={`Assign office staff to project`}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepAssignOfficeStaff = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => [
    <WorkflowStepAssignOfficeStaffSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <WorkflowStepAssignOfficeStaffDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    ${Organization.getOfficeStaffDropdownOptions.fragment}
    fragment WorkflowStepAssignOfficeStaff on Workflow {
      id
      organization {
        id
        allTeams {
          id
          name
        }
        ...Organization_getOfficeStaffDropdownOptions
      }
    }
  `,
};

export default WorkflowStepAssignOfficeStaff;
