// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';

const maybeAddNoneSalesperson = ({options, noneOptionLabel, organization}: any) => {
  if (noneOptionLabel && organization.features.isEnabledAllowEmptyBookedBy) {
    options.unshift({label: noneOptionLabel, value: null, description: ''});
  }
  return options;
};

const getSalespersonOptions = ({
  organization,
  isEnabledShowIntegrationUser,
  noneOptionLabel,
}: any) => {
  if (isEnabledShowIntegrationUser) {
    const options = Organization.getSalespersonOptionsV2(organization);
    return maybeAddNoneSalesperson({options, noneOptionLabel, organization});
  }
  const officeOptions = Organization.getOfficeStaffDropdownOptions(organization);
  return maybeAddNoneSalesperson({options: officeOptions, noneOptionLabel, organization});
};

type OwnSingleOrganizationStaffDropdownProps = {
  isEnabledShowIntegrationUser?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'SingleOrganizationStaffDropdownProps' ... Remove this comment to see the full error message
type SingleOrganizationStaffDropdownProps = OwnSingleOrganizationStaffDropdownProps &
  typeof SingleOrganizationStaffDropdown.defaultProps;

// @ts-expect-error TS(7022): 'SingleOrganizationStaffDropdown' implicitly has t... Remove this comment to see the full error message
const SingleOrganizationStaffDropdown = ({
  organizationSlug,
  isEnabledShowIntegrationUser,
  form,
  field,
  label,
  placeholder,
  isMultiSelect,
  isDisabled,
  isRequired,
  noneOptionLabel,
  required,
  style,
  inputStyle,
  index,
  isResponsive,
  isPortaled,
}: SingleOrganizationStaffDropdownProps) => {
  const {data, loading} = useQuery(SingleOrganizationStaffDropdown.query, {
    variables: {
      organizationSlug,
      isEnabledShowIntegrationUser,
    },
  });

  return (
    <FieldInput
      {...form}
      component={isMultiSelect ? MultiDropdownCheckboxInput : DropdownInput}
      name={field}
      label={label}
      index={index}
      style={style}
      isRequired={isRequired}
      isResponsive={isResponsive}
      input={{
        isPortaled,
        isClearable: true,
        disabled: isDisabled,
        required,
        setFieldValue: form.setFieldValue,
        isLoading: loading,
        placeholder,
        options: loading
          ? []
          : getSalespersonOptions({
              organization: data.organization,
              isEnabledShowIntegrationUser,
              noneOptionLabel,
            }),
        style: inputStyle || {flex: 1},
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

SingleOrganizationStaffDropdown.defaultProps = {
  isEnabledShowIntegrationUser: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SingleOrganizationStaffDropdown.query = gql`
  ${Organization.getOfficeStaffDropdownOptions.fragment}
  ${Organization.getSalespersonOptionsV2.fragment}
  query SingleOrganizationStaffDropdown(
    $organizationSlug: String!
    $isEnabledShowIntegrationUser: Boolean!
  ) {
    organization(slug: $organizationSlug) {
      id
      features {
        isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
      }
      ...Organization_getOfficeStaffDropdownOptions @skip(if: $isEnabledShowIntegrationUser)
      ...Organization_getSalespersonOptionsV2 @include(if: $isEnabledShowIntegrationUser)
    }
  }
`;

export default SingleOrganizationStaffDropdown;
