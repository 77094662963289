// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SidebarPage from 'modules/App/components/SidebarPage';
import EstimatesCalendar from 'modules/Job/Estimate/Calendar/components/EstimatesCalendar';

const Container = Styled.View`
  flex: 1;
`;

const getSearchParams = ({location}: any) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const JobEstimatesCalendar = () => (
  <SidebarPage
    fetchPolicy={'cache-and-network'}
    selected={'estimates'}
    query={JobEstimatesCalendar.query}
  >
    {({responsive, navigator, data}: any) => (
      <Container {...responsive} data-test-id='estimates-calendar-page'>
        {responsive.mobile ? (
          <ScrollView>
            <EstimatesCalendar
              searchParams={getSearchParams({location: navigator.location})}
              viewer={data.viewer}
            />
          </ScrollView>
        ) : (
          <EstimatesCalendar
            searchParams={getSearchParams({location: navigator.location})}
            viewer={data.viewer}
          />
        )}
      </Container>
    )}
  </SidebarPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEstimatesCalendar.query = gql`
  ${EstimatesCalendar.fragment}

  query JobEstimatesCalendar {
    ${gql.query}
    viewer {
      id
      ...EstimatesCalendar
    }
  }
`;

export default JobEstimatesCalendar;
