// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobInfoSection from 'modules/Job/components/JobInfoSection';

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobInfoBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobInfoBlock' implicitly has type 'any' because i... Remove this comment to see the full error message
const JobInfoBlock = ({job}: Props) => {
  return <JobInfoSection sectionIndex={0} job={job} />;
};

JobInfoBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInfoBlock.fragment = gql`
  ${JobInfoSection.fragment}

  fragment JobInfoBlock on Job {
    id
    ...JobInfoSection
  }
`;

export default JobInfoBlock;
