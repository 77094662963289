// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteSlotMutation from '@shared/modules/Dispatch/hooks/useDeleteSlotMutation';

const DeleteSlotModal = ({isOpen, slotId, handleClose, refetch, refetchQueries}: any) => {
  const {submitting, handleSubmit} = useDeleteSlotMutation({
    slotId,
    refetchQueries,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      title={'Delete slot?'}
      subtitle={
        "This will unassign all the currently assigned jobs on this slot. You can't undo this action."
      }
      isOpen={isOpen}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

export default DeleteSlotModal;
