// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';
import TagPill from 'modules/Tag/components/TagPill';

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const HandleTagSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
}: any) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {label, description, kind} = WorkflowStepActionKind.ATTRIBUTES[actionKind];
  const isRemoveTagAssignment = [
    WorkflowStepActionKind.JOB_REMOVE_TAG,
    WorkflowStepActionKind.PROJECT_REMOVE_TAG,
  ].includes(actionKind);
  const isJobActionKind = kind === WorkflowStepActionKind.CATEGORIES.JOB;
  const field = `${workflowStepsField}.${workflowStepIndex}.${
    isRemoveTagAssignment ? `removeTagAssignmentActionForm` : `updateTagAssignmentActionForm`
  }`;
  const isDoneDisabled = _.isEmpty(_.get(form.values, `${field}.tagIds`));
  const tags = workflow.organization.companySettings.tags.filter(
    (tag: any) => tag.category === kind && !tag.isArchived,
  );

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <TagDropdownInputField
        options={
          tags
            ? tags.map((tag: any) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
            : []
        }
        placeholder={`Select tag`}
        label={`Select ${isJobActionKind ? 'Job' : 'Project'} Tag(s)`}
        isPortaled
        isRequired
        value={_.get(form.values, `${field}.tagIds`, [])}
        onChangeValue={(tagIds: any) => {
          form.setFieldValue(`${field}.tagIds`, tagIds);
        }}
      />
    </WorkflowStepSlide>
  );
};

const TagTitleDisplay = ({workflow, tagIdList, isJobActionKind, isRemoveTagAssignment}: any) => {
  const responsive = useResponsive();
  return (
    <Row style={{flexWrap: 'wrap', width: responsive.mobile ? 300 : 370}}>
      <LabelText style={{marginBottom: 4}}>{isRemoveTagAssignment ? 'Remove' : 'Add'}</LabelText>
      <Space width={4} />
      {tagIdList.map((tagId: any, index: any) => {
        const fetchedTag = workflow.organization.companySettings.tags.find(
          (tag: any) => tag.id === tagId,
        );
        return (
          <React.Fragment key={index}>
            <TagPill tag={fetchedTag} isSmall hasDeleteButton={false} />
            <Space width={4} />
          </React.Fragment>
        );
      })}
      <LabelText style={{marginBottom: 4}}>{`${
        isJobActionKind ? 'Job Tags' : 'Project Tags'
      }`}</LabelText>
    </Row>
  );
};

const HandleTagDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}: any) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {kind} = WorkflowStepActionKind.ATTRIBUTES[actionKind];
  const isJobActionKind = kind === WorkflowStepActionKind.CATEGORIES.JOB;
  const isRemoveTagAssignment = [
    WorkflowStepActionKind.JOB_REMOVE_TAG,
    WorkflowStepActionKind.PROJECT_REMOVE_TAG,
  ].includes(actionKind);
  const field = `${workflowStepsField}.${workflowStepIndex}.${
    isRemoveTagAssignment ? `removeTagAssignmentActionForm` : `updateTagAssignmentActionForm`
  }`;
  const tagIdList = _.get(form.values, `${field}.tagIds`, '') || [];

  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={
        <TagTitleDisplay
          form={form}
          isRemoveTagAssignment={isRemoveTagAssignment}
          workflow={workflow}
          tagIdList={tagIdList}
          isJobActionKind={isJobActionKind}
        />
      }
      isPreview={isPreview}
    />
  );
};

const WorkflowStepHandleTag = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => [
    <HandleTagSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <HandleTagDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    ${TagDropdownInputField.fragment}
    ${TagPill.fragment}

    fragment WorkflowStepHandleTag on Workflow {
      id
      organization {
        id
        companySettings {
          tags {
            id
            name
            emoji
            isArchived
            category
            ...TagPill
            ...TagDropdownInputField
          }
        }
      }
    }
  `,
};

export default WorkflowStepHandleTag;
