// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {ExternalLink, Icon, Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime, Location, Phone} from '@supermove/utils';

// Components
import {JobPage} from 'modules/Customer/Job/components';

import CustomerJobMap from '../components/CustomerJobMap';

const Container = Styled.View`
  flex: 1;
`;

const Wrapper = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  height: 250px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Text = Styled.H7`
  margin-top: 5px;
  color: ${colors.gray.primary};
`;

const StatusBar = Styled.View`
  align-items: center;
  background-color: ${(props) => (props as any).color};
  padding-vertical: 10px;
`;

const StatusText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const HelpSection = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HelpText = Styled.H6`
  color: ${colors.gray.primary};
`;

const PhoneText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  cursor: pointer;
`;

const InfoSection = Styled.View`
  align-items: flex-start;
  padding-horizontal: 10px;
  padding-vertical: 10px;
`;

const getRouteType = ({job}: any) => {
  switch (job.secondaryStatus) {
    case 'IN_PROGRESS_CREW_CONFIRMED':
    case 'IN_PROGRESS_ON_THE_WAY':
      return 'ROUTE_TO_PICK_UP';
    case 'IN_PROGRESS_ARRIVED':
    case 'IN_PROGRESS_SIGNED_DOCUMENTS':
      return 'ROUTE_TO_DROP_OFF';
    default:
      return 'ROUTE_NONE';
  }
};

const getStatusColor = ({job}: any) => {
  switch (job.secondaryStatus) {
    case 'IN_PROGRESS_CREW_CONFIRMED':
    case 'IN_PROGRESS_ON_THE_WAY':
      return colors.red.warning;
    case 'IN_PROGRESS_ARRIVED':
    case 'IN_PROGRESS_SIGNED_DOCUMENTS':
    case 'IN_PROGRESS_BILL_PAID':
    case 'IN_PROGRESS_CONFIRMED_TIMES':
      return colors.blue.interactive;
    case 'NOT_FINAL_REPORT_INCOMPLETE':
    case 'COMPLETE':
    case 'FINAL':
      return colors.green.status;
    default:
      // Job not started yet.
      return colors.gray.secondary;
  }
};

/**
 * Move has not started yet
 * Movers are on the way
 * Movers are working hard
 * Movers are finishing up
 * Move is complete
 */
const getStatusText = ({job}: any) => {
  switch (job.secondaryStatus) {
    case 'IN_PROGRESS_CREW_CONFIRMED':
    case 'IN_PROGRESS_ON_THE_WAY':
      return 'Movers are on the way';
    case 'IN_PROGRESS_ARRIVED':
    case 'IN_PROGRESS_SIGNED_DOCUMENTS':
      return 'Movers are working hard';
    case 'IN_PROGRESS_BILL_PAID':
    case 'IN_PROGRESS_CONFIRMED_TIMES':
      return 'Movers are finishing up';
    case 'NOT_FINAL_REPORT_INCOMPLETE':
    case 'COMPLETE':
    case 'FINAL':
      return 'Move is complete';
    default:
      // Job not started yet.
      return 'Move has not started yet';
  }
};

const isJobStarted = ({job}: any) => {
  return job.isInProgress;
};

const isJobFinished = ({job}: any) => {
  return job.isComplete;
};

const Map = ({job}: any) => {
  const {latestPosition} = job;
  const hasLatestPosition = !isJobFinished({job}) && !!latestPosition;
  const latestLocation = hasLatestPosition
    ? Location.create({
        latitude: latestPosition.latitude,
        longitude: latestPosition.longitude,
      })
    : null;

  return (
    <Wrapper>
      <CustomerJobMap
        locations={job.locations}
        pickUpLocation={job.pickUpLocation}
        routeType={getRouteType({job})}
        truckLastLocation={latestLocation}
      />
    </Wrapper>
  );
};

const JobInfo = ({job, responsive}: any) => {
  const {isEnabledShowCustomerPrimaryCrewOrganizationPhoneNumber} = job.organization.features;
  const jobOrganizationPhoneNumber = _.get(job, 'organization.phoneNumber');
  const crewOrganizationPhoneNumber = _.get(job, 'primaryCrew.organization.phoneNumber');
  const displayPhoneNumber =
    (isEnabledShowCustomerPrimaryCrewOrganizationPhoneNumber && crewOrganizationPhoneNumber) ||
    jobOrganizationPhoneNumber;
  return (
    <Content>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <StatusBar color={getStatusColor({job})}>
        <StatusText>{getStatusText({job})}</StatusText>
      </StatusBar>
      {displayPhoneNumber && (
        <HelpSection>
          <HelpText>Questions?</HelpText>
          <ExternalLink url={Phone.createUrl(displayPhoneNumber)}>
            <PhoneText>
              <Icon
                color={colors.gray.primary}
                size={Icon.Sizes.Small}
                source={Icon.Phone}
                style={{marginLeft: 5, marginRight: 5}}
              />
              {Phone.display(displayPhoneNumber)}
            </PhoneText>
          </ExternalLink>
        </HelpSection>
      )}
      <ScrollView
        style={{
          alignSelf: 'center',
          width: responsive.mobile ? '100%' : 400,
        }}
      >
        <InfoSection {...responsive}>
          <Title>{`Move Details`}</Title>
          <Text>{`Date: ${Datetime.convertToDisplayDate(job.startDate)}`}</Text>
          {isJobStarted({job}) && !!_.get(job, 'move.startTime') && (
            <Text>{`Start time: ${Datetime.convertToDisplayTime(job.move.startTime)}`}</Text>
          )}
          {isJobFinished({job}) && !!_.get(job, 'move.endTime') && (
            <Text>{`End time: ${Datetime.convertToDisplayTime(job.move.endTime)}`}</Text>
          )}
          <Text>{`Pick up: ${job.pickUpLocation.address}`}</Text>
          <Text>{`Drop off: ${job.dropOffLocation.address}`}</Text>
          <Text>{`Move size: ${job.moveSize} - ${job.crewSize} movers`}</Text>
        </InfoSection>
      </ScrollView>
    </Content>
  );
};

const CustomerTrackJobPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => (
  <JobPage
    query={CustomerTrackJobPage.query}
    variables={{uuid}}
    title={({loading, data}: any) =>
      loading
        ? 'Loading...'
        : `Job #${data.job.identifier} - ${Datetime.convertToDisplayDate(
            data.job.startDate,
            'dddd, MMMM Do',
          )}`
    }
    subtitle={({loading, data}: any) => 'Track your move'}
  >
    {({responsive, navigator, data, startPolling, stopPolling}: any) => (
      <Container>
        <Lifecycle
          onMount={() => {
            startPolling(1000 * 30); // Poll once every 30 seconds.
          }}
          onUpdate={() => {
            if (isJobFinished({job: data.job})) {
              stopPolling();
            }
          }}
          children={null}
        />
        <Map job={data.job} />
        <JobInfo job={data.job} responsive={responsive} />
      </Container>
    )}
  </JobPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerTrackJobPage.query = gql`
  ${JobPage.fragment}

  query CustomerTrackJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      identifier
      crewSize
      moveSize
      secondaryStatus
      isInProgress
      isComplete
      startDate
      customer {
        id
        firstName
      }
      pickUpLocation {
        id
        address
        city
        latitude
        longitude
      }
      dropOffLocation {
        id
        address
        city
        latitude
        longitude
      }
      day {
        id
        value
      }
      organization {
        id
        phoneNumber
        features {
          isEnabledShowCustomerPrimaryCrewOrganizationPhoneNumber: isEnabled(feature: "SHOW_CUSTOMER_PRIMARY_CREW_ORGANIZATION_PHONE_NUMBER")
        }
      }
      move {
        id
        startTime
        endTime
      }
      latestPosition {
        id
        latitude
        longitude
        timestamp
      }
      locations {
        id
        address
        city
        latitude
        longitude
        zipCode
      }
      primaryCrew {
        id
        organization {
          id
          phoneNumber
        }
      }
      ...JobPage
    }
  }
`;

export default CustomerTrackJobPage;
