/**
 * Component - v2.0.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  ${(props) => ((props as any).mobile ? '' : 'padding-vertical: 40px;')}
`;

const Wrapper = Styled.View`
  flex: 1;
  width: ${(props) => ((props as any).mobile ? '100%' : `${(props as any).width}px`)};
  background-color: ${colors.white};
  ${(props) => ((props as any).mobile ? '' : 'box-shadow: 0 2px 5px rgba(194,194,194,0.5);')}
  ${(props) => ((props as any).mobile ? '' : 'border-radius: 5px;')}
`;

const HeaderSection = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
  flex-direction: row;
  align-items: center;
  height: 60px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const CloseButton = Styled.ButtonV2`
  padding: 6px;
`;

type OwnProps = {
  isOpen: boolean;
  width?: number;
  handleClose: (...args: any[]) => any;
  disableCloseOnPressOutside?: boolean;
  children: React.ReactElement | React.ReactElement[];
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof LargeModal.defaultProps;

/**
 * Renders a large modal on top of an opaque overlay.
 * In terms of width:
 * - On desktop web, modal accepts width as a prop with a default.
 * - On mobile web, modal fills page.
 * In terms of height:
 * - On desktop web, modal fills page except for some vertical margin.
 * - On mobile web, modal fills page.
 */
// @ts-expect-error TS(7022): 'LargeModal' implicitly has type 'any' because it ... Remove this comment to see the full error message
const LargeModal = ({
  isOpen,
  width,
  handleClose,
  disableCloseOnPressOutside,
  title,
  children,
}: Props) => {
  const responsive = useResponsive();

  return (
    <Modal.Content isOpen={isOpen} onClose={disableCloseOnPressOutside ? () => {} : handleClose}>
      <Overlay pointerEvents={'box-none'} {...responsive}>
        <Wrapper width={width} {...responsive}>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <HeaderSection sectionIndex={0}>
            <Space width={28} />
            <HeaderText>{title}</HeaderText>
            <Space style={{flex: 1}} />
            <CloseButton onPress={handleClose}>
              <Icon source={Icon.Times} size={24} color={colors.gray.primary} />
            </CloseButton>
            <Space width={28} />
          </HeaderSection>
          <Section
            // @ts-expect-error TS(2769): No overload matches this call.
            sectionIndex={1}
            style={{
              flex: 1,
            }}
          >
            {children}
          </Section>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

LargeModal.defaultProps = {
  width: 600,
  disableCloseOnPressOutside: false,
};

export default LargeModal;
