// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useQuery, useResponsive, useState} from '@supermove/hooks';
import {OrganizationModel, ProjectTypeModel} from '@supermove/models';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownInputComponent from '@shared/design/components/MultiDropdownInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import {OrganizationSettingsDocumentFlowsUrlFilters} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersTypes';

const LoadingIndicator = Styled.Loading`
`;

type OrganizationSettingsDocumentFlowsFilterQueryType = {
  viewer: {
    id: string;
    viewingOrganization: OrganizationModel & {
      projectTypesForCategory: ProjectTypeModel[];
    };
  };
};

interface OrganizationSettingsDocumentFlowsFilterProps {
  urlFilters: OrganizationSettingsDocumentFlowsUrlFilters;
  handleApply: (props: {projectTypeIds: string[]}) => void;
}

const toArray = (value: string[] | string | undefined) => {
  return value ? _.flatten([value]) : [];
};

const OrganizationSettingsDocumentFlowsFilter = ({
  urlFilters,
  handleApply,
}: OrganizationSettingsDocumentFlowsFilterProps) => {
  const filterPopover = usePopover({
    name: 'Global dashboards library page global dashboards filter modal popover',
  });

  const filtersCount = urlFilters.getFilterCount({
    filterKeys: ['projectTypeIds'],
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={filterPopover.ref}>
        <SecondaryButton
          text={`Filters${filtersCount ? ` (${filtersCount})` : ''}`}
          onPress={filterPopover.handleOpen}
          iconLeft={Icon.Filter}
          isWidthOfContainer
          isResponsive
        />
      </Popover.Content>
      <OrganizationSettingsDocumentFlowsFilterPopover
        selectedProjectTypeIds={urlFilters.get('projectTypeIds')}
        popover={filterPopover}
        handleApply={handleApply}
      />
    </React.Fragment>
  );
};

interface OrganizationSettingsDocumentFlowsFilterPopoverProps {
  popover: ReturnType<typeof usePopover>;
  selectedProjectTypeIds: string[] | undefined;
  handleApply: OrganizationSettingsDocumentFlowsFilterProps['handleApply'];
}

const OrganizationSettingsDocumentFlowsFilterPopover = ({
  popover,
  selectedProjectTypeIds,
  handleApply,
}: OrganizationSettingsDocumentFlowsFilterPopoverProps) => {
  const responsive = useResponsive();

  const [newSelectedProjectTypeIds, setNewSelectedProjectTypeIds] = useState(
    toArray(selectedProjectTypeIds),
  );

  const {data, loading} = useQuery<OrganizationSettingsDocumentFlowsFilterQueryType>(
    OrganizationSettingsDocumentFlowsFilter.query,
    {
      variables: {
        projectTypeCategory: ProjectTypeCategory.MOVE,
      },
      fetchPolicy: 'cache-and-network',
      skip: !popover.isOpen,
    },
  );

  const filtersCount = newSelectedProjectTypeIds.length ? 1 : 0;

  return (
    <PopoverFilter
      activeFiltersCount={filtersCount}
      popover={popover}
      responsive={responsive}
      handleApply={() => {
        handleApply({
          projectTypeIds: newSelectedProjectTypeIds,
        });

        popover.handleClose();
      }}
      handleClear={() => {
        setNewSelectedProjectTypeIds([]);
      }}
    >
      <Loading loading={loading || !data} as={LoadingIndicator}>
        {() => (
          <OrganizationSettingsDocumentFlowsFilterBody
            projectTypes={data!.viewer.viewingOrganization.projectTypesForCategory}
            newSelectedProjectTypeIds={newSelectedProjectTypeIds}
            setNewSelectedProjectTypeIds={setNewSelectedProjectTypeIds}
          />
        )}
      </Loading>
    </PopoverFilter>
  );
};

interface OrganizationSettingsDocumentFlowsFilterBodyProps {
  projectTypes: ProjectTypeModel[];
  newSelectedProjectTypeIds: string[];
  setNewSelectedProjectTypeIds: (value: string[]) => void;
}

const OrganizationSettingsDocumentFlowsFilterBody = ({
  projectTypes,
  newSelectedProjectTypeIds,
  setNewSelectedProjectTypeIds,
}: OrganizationSettingsDocumentFlowsFilterBodyProps) => {
  const sortedProjectTypeOptions = projectTypes
    .map((projectType) => ({
      label: projectType.name,
      value: projectType.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return (
    <React.Fragment>
      <FieldInput
        index={0}
        component={MultiDropdownInputComponent}
        label={'Project Types'}
        input={{
          options: sortedProjectTypeOptions,
          name: 'Project type',
          value: newSelectedProjectTypeIds,
          setFieldValue: (name: string, value: string[]) => setNewSelectedProjectTypeIds(value),
          placeholder: 'Select project types',
          style: {flex: 1},
          isResponsive: true,
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsDocumentFlowsFilter.query = gql`
  query OrganizationSettingsDocumentFlowsFilter($projectTypeCategory: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        projectTypesForCategory(category: $projectTypeCategory) {
          id
          name
        }
      }
    }
  }
`;

export default OrganizationSettingsDocumentFlowsFilter;
