// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {CustomerPage} from 'modules/Customer/components';

import JobRequestNotFoundPage from './JobRequestNotFoundPage';

const JobRequestPage = ({query, right, subtitle, title, variables, children}: any) => (
  <CustomerPage query={query} right={right} subtitle={subtitle} title={title} variables={variables}>
    {(props: any) => (props.data.jobRequest ? children(props) : <JobRequestNotFoundPage />)}
  </CustomerPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestPage.fragment = gql`
  fragment JobRequestPage on JobRequest {
    id
  }
`;

export default JobRequestPage;
