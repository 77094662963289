// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import {PageLoadingIndicator} from 'modules/App/components';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import OrganizationSettingsStaffMoversContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffMoversContent';
import OrganizationSettingsStaffOfficeStaffContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContent';
import OrganizationSettingsStaffPermissionsContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffPermissionsContent';
import OrganizationSettingsStaffPositionsContent from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffPositionsContent';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

const getTabDefinitions = () => {
  const tabs = [
    {
      label: 'Office Staff & Admins',
      url: '/settings/staff/office-staff',
      component: <OrganizationSettingsStaffOfficeStaffContent />,
      pageTitle: '',
      pageDescription: '',
    },
    {
      label: 'Crew Members',
      url: '/settings/staff/movers',
      component: <OrganizationSettingsStaffMoversContent />,
      pageTitle: '',
      pageDescription: '',
    },
    {
      label: 'Positions',
      url: '/settings/staff/positions',
      component: <OrganizationSettingsStaffPositionsContent />,
      pageTitle: 'Positions',
      pageDescription: '',
    },
    {
      label: 'Permissions',
      url: '/settings/staff/permissions',
      component: <OrganizationSettingsStaffPermissionsContent />,
      pageTitle: 'Permissions',
      pageDescription: 'Determine how you communicate with your Movers.',
    },
  ].filter((tab) => tab !== null);
  return tabs;
};

const StaffSettingsPageTabContent = ({tabs, currentTabIndex}: any) => {
  return tabs[currentTabIndex].component;
};

const StaffSettingsPage = () => {
  const {navigator} = useNavigationDOM();
  const {data} = useQuery(StaffSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (!data) {
    return (
      <SidebarPageV2 selected={'settings'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const tabs = getTabDefinitions();
  const currentTabIndex = _.findIndex(tabs, (tab) => navigator.location.pathname.includes(tab.url));

  return (
    <SettingsPage
      title={tabs[currentTabIndex].pageTitle}
      description={tabs[currentTabIndex].pageDescription}
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {name: 'Staff'},
      ]}
      tabs={tabs}
      scrollViewStyle={{paddingHorizontal: '24px'}}
      scrollViewContentContainerStyle={{flex: 1}}
    >
      <StaffSettingsPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffSettingsPage.query = gql`
  query StaffSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
      }
    }
  }
`;

export default StaffSettingsPage;
