// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TaskTemplateForm from '@shared/modules/TaskManagement/forms/TaskTemplateForm';
import useCreateTaskTemplateMutation from '@shared/modules/TaskManagement/hooks/useCreateTaskTemplateMutation';
import TaskTemplateDrawerFields from 'modules/TaskManagement/TaskTemplates/components/TaskTemplateDrawerFields';

const NewTaskTemplateDrawer = ({isOpen, handleClose, organization, refetch}: any) => {
  const taskTemplateForm = TaskTemplateForm.new({organizationId: organization.id});
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Successfully created task template!',
  });

  const {form, handleSubmit, submitting} = useCreateTaskTemplateMutation({
    taskTemplateForm,
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
      form.resetForm();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'New Task Template'}
      primaryActionText={'Create'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <TaskTemplateDrawerFields
        form={form}
        field={'taskTemplateForm'}
        organization={organization}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewTaskTemplateDrawer.fragment = gql`
  ${TaskTemplateDrawerFields.fragment}
  fragment NewTaskTemplateDrawer on Organization {
    id
    ...TaskTemplateDrawerFields
  }
`;

export default NewTaskTemplateDrawer;
