// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import IconButton from '@shared/design/components/IconButton';
import ProjectCommentsSidebar from 'modules/Project/components/ProjectCommentsSidebar';

const Button = Styled.ButtonV2`
  flex-direction: row;
  max-width: 100%;
  align-items: center;
`;

const CommentText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const ProjectCommentsButton = ({project, style, sidebar}: any) => {
  const {mostRecentComment} = project.officeThread;
  const {isEnabledMostRecentCommentPreview} = project.organization.features;
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  const textColor = isHovered ? colors.blue.hover : colors.gray.secondary;

  return (
    <React.Fragment>
      <Button style={style} onPress={sidebar.handleOpen} ref={ref}>
        <IconButton
          source={Icon.CommentDots}
          isDisplayOnly
          isSecondary
          isHoverDistinct
          isHovered={isHovered}
        />
        <Space width={6} />
        <CommentText color={textColor} responsive={responsive}>
          {project.officeThread ? project.officeThread.commentsCount : 0}
        </CommentText>
        {isEnabledMostRecentCommentPreview && mostRecentComment && (
          <React.Fragment>
            <Space width={4} />
            <CommentText numberOfLines={1} color={textColor} responsive={responsive}>
              {`- "${mostRecentComment.subject}"`}
            </CommentText>
          </React.Fragment>
        )}
      </Button>
      <ProjectCommentsSidebar
        key={sidebar.key}
        isOpen={sidebar.isOpen}
        handleClose={sidebar.handleClose}
        projectUuid={project.uuid}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectCommentsButton.fragment = gql`
  fragment ProjectCommentsButton on Project {
    id
    uuid
    officeThread {
      id
      commentsCount
      mostRecentComment {
        id
        subject
      }
    }
    organization {
      id
      features {
        isEnabledMostRecentCommentPreview: isEnabled(feature: "MOST_RECENT_COMMENT_PREVIEW")
      }
    }
  }
`;

export default ProjectCommentsButton;
