// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import {ScheduleCell} from 'modules/App/Schedule/components';

import AssignUserToJobButton from './AssignUserToJobButton';
import UnassignUserFromJobButton from './UnassignUserFromJobButton';

const Container = Styled.View`
`;

const NameText = Styled.H7`
  ${fontWeight(500)}
`;

const PositionText = Styled.H7`
  padding-top: 5px;
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LeftHeaderCell = Styled.View`
  ${(props) => (props as any).mobile && 'flex: 1'};
  justify-content: center;
  ${(props) => !(props as any).mobile && 'width: 130px'};
  height: 50px;
  padding: 5px;
  border-width: 1px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const HeaderCell = Styled.View`
  justify-content: center;
  width: 130px;
  height: 50px;
  padding: 5px;
  border-width: 1px;
  border-bottom-width: 2px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const LeftCell = Styled.View`
  ${(props) => (props as any).mobile && 'flex: 1'};
  justify-content: center;
  ${(props) => !(props as any).mobile && 'width: 130px'};
  height: 75px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.H7`
  ${fontWeight(700)}
`;

const Touchable = Styled.Touchable`
  height: 100%;
`;

const filterScheduleDays = ({formDate, responsive, scheduleDays}: any) => {
  return responsive.mobile
    ? scheduleDays.filter(
        (scheduleDay: any) =>
          scheduleDay.day && Datetime.isSameDay(formDate, scheduleDay.day.value),
      )
    : scheduleDays;
};

const getDatetimeToQuery = ({date}: any) => {
  return Datetime.previousMonday(Datetime.fromDate(date));
};

const getDateRange = ({date, responsive}: any) => {
  return responsive.mobile
    ? [date]
    : Datetime.getDateRange(getDatetimeToQuery({date}), 7).map((datetime: any) =>
        Datetime.toDate(datetime),
      );
};

const getCellColor = ({scheduleDay, schedulingType}: any) => {
  if (schedulingType === 'DEFAULT_AVAILABLE') {
    if (scheduleDay.userDay && scheduleDay.userDay.scheduleStatus === 'NOT_AVAILABLE') {
      return colors.Pink600;
    } else {
      return colors.green.status;
    }
  } else if (!scheduleDay.userDay) {
    return colors.gray.border;
  } else {
    switch (scheduleDay.userDay.scheduleStatus) {
      case 'AVAILABLE':
        return colors.green.status;
      case 'NOT_AVAILABLE':
        return colors.Pink600;
      default:
        return colors.gray.border;
    }
  }
};

const getCellOpacity = ({formDate, scheduleDay}: any) => {
  return isSameDay({formDate, scheduleDay}) ? 1.0 : 0.5;
};

const isSameDay = ({formDate, scheduleDay}: any) => {
  if (!scheduleDay.day) {
    return false;
  } else {
    return Datetime.isSameDay(formDate, scheduleDay.day.value);
  }
};

const isUserAssignable = ({formDate, schedulingType, job, scheduleDay, user}: any) => {
  if (!isSameDay({formDate, scheduleDay})) {
    return false;
  }
  const assignedUserIds = job.jobUsers.map((jobUser: any) => String(jobUser.userId));
  if (assignedUserIds.indexOf(user.id) >= 0) {
    return false;
  }
  // Logic below to handle organization scheduling type.
  if (schedulingType === 'DEFAULT_AVAILABLE') {
    if (scheduleDay.userDay && scheduleDay.userDay.scheduleStatus === 'NOT_AVAILABLE') {
      return false;
    } else {
      return true;
    }
  } else {
    return scheduleDay.userDay && scheduleDay.userDay.scheduleStatus === 'AVAILABLE';
  }
};

const isUserUnassignable = ({formDate, job, scheduleDay, user}: any) => {
  const assignedUserIds = job.jobUsers.map((jobUser: any) => String(jobUser.userId));
  return isSameDay({formDate, scheduleDay}) && assignedUserIds.indexOf(user.id) >= 0;
};

const TableHeader = ({date}: any) => {
  const responsive = useResponsive();

  return (
    <Row>
      <LeftHeaderCell {...responsive}>
        <HeaderText>Employee</HeaderText>
      </LeftHeaderCell>
      {getDateRange({date, responsive}).map((date: any) => (
        <HeaderCell key={date} {...responsive}>
          <HeaderText>{Datetime.convertToDisplayDate(date, 'ddd (M/D)')}</HeaderText>
        </HeaderCell>
      ))}
    </Row>
  );
};

const renderJobCell = ({
  loading,
  formDate,
  schedulingType,
  index,
  job,
  schedule,
  scheduleDay,
  onSuccess,
}: any) => {
  if (
    isUserAssignable({
      formDate,
      schedulingType,
      job,
      scheduleDay,
      user: schedule.user,
    })
  ) {
    return (
      <AssignUserToJobButton key={index} job={job} user={schedule.user} onSuccess={onSuccess}>
        {({loading: submitting, handleSubmit}: any) => (
          <Touchable activeOpacity={1} disabled={loading || submitting} onPress={handleSubmit}>
            <ScheduleCell
              boldJobId={job.id}
              jobs={scheduleDay.jobs}
              key={index}
              loading={submitting}
              styleColor={getCellColor({job, scheduleDay, schedulingType})}
              styleCursor={'pointer'}
              styleOpacity={getCellOpacity({formDate, scheduleDay})}
            />
          </Touchable>
        )}
      </AssignUserToJobButton>
    );
  } else if (
    isUserUnassignable({
      formDate,
      job,
      scheduleDay,
      user: schedule.user,
    })
  ) {
    return (
      <UnassignUserFromJobButton
        key={schedule.user.id}
        job={job}
        user={schedule.user}
        onSuccess={onSuccess}
      >
        {({loading: submitting, handleSubmit}: any) => (
          <Touchable activeOpacity={1} disabled={loading || submitting} onPress={handleSubmit}>
            <ScheduleCell
              boldJobId={job.id}
              jobs={scheduleDay.jobs}
              key={index}
              loading={submitting}
              styleColor={getCellColor({job, scheduleDay, schedulingType})}
              styleCursor={'pointer'}
              styleOpacity={getCellOpacity({formDate, scheduleDay})}
            />
          </Touchable>
        )}
      </UnassignUserFromJobButton>
    );
  } else {
    return (
      <ScheduleCell
        boldJobId={job.id}
        jobs={scheduleDay.jobs}
        key={index}
        loading={false}
        styleColor={getCellColor({job, scheduleDay, schedulingType})}
        styleCursor={'not-allowed'}
        styleOpacity={getCellOpacity({formDate, scheduleDay})}
      />
    );
  }
};

const TableRows = ({loading, date, job, schedules, schedulingType, onSuccess}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {schedules.map((schedule: any) => (
        <Row key={schedule.user.id}>
          <LeftCell {...responsive}>
            <NameText numberOfLines={1}>{User.getFullName(schedule.user)}</NameText>
            <PositionText>{User.getPosition(schedule.user)}</PositionText>
          </LeftCell>
          {filterScheduleDays({
            formDate: date,
            responsive,
            scheduleDays: schedule.scheduleDays,
          }).map((scheduleDay: any, index: any) =>
            renderJobCell({
              loading,
              formDate: date,
              schedulingType,
              index,
              job,
              schedule,
              scheduleDay,
              onSuccess,
            }),
          )}
        </Row>
      ))}
    </React.Fragment>
  );
};

type OwnEditJobUsersTableProps = {
  date: string;
  job: any;
  schedules: any[];
  schedulingType: string;
  onEditSuccess: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'EditJobUsersTableProps' circularly ref... Remove this comment to see the full error message
type EditJobUsersTableProps = OwnEditJobUsersTableProps & typeof EditJobUsersTable.defaultProps;

// @ts-expect-error TS(7022): 'EditJobUsersTable' implicitly has type 'any' beca... Remove this comment to see the full error message
const EditJobUsersTable = ({
  loading,
  date,
  job,
  schedules,
  schedulingType,
  onEditSuccess,
}: EditJobUsersTableProps) => (
  <ScrollView horizontal style={{flex: 1}}>
    <Container>
      <TableHeader date={date} />
      <ScrollView style={{flex: 1}}>
        <TableRows
          loading={loading}
          date={date}
          job={job}
          schedules={schedules}
          schedulingType={schedulingType}
          onSuccess={onEditSuccess}
        />
      </ScrollView>
    </Container>
  </ScrollView>
);

EditJobUsersTable.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobUsersTable.fragment = gql`
  ${ScheduleCell.fragment}

  fragment EditJobUsersTable on Job {
    id
    ...ScheduleCell
  }
`;

export default EditJobUsersTable;
