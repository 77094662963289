// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteMoverPositionForm from '@shared/modules/User/forms/DeleteMoverPositionForm';
import useDeleteMoverPositionMutation from '@shared/modules/User/hooks/useDeleteMoverPositionMutation';

const DeleteMoverPositionModal = ({isOpen, moverPositionId, handleClose, refetch}: any) => {
  const deleteMoverPositionForm = DeleteMoverPositionForm.new({moverPositionId});
  const {handleSubmit} = useDeleteMoverPositionMutation({
    deleteMoverPositionForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={handleClose}
      isOpen={isOpen}
      title={`Are you sure you want to delete this position?`}
      subtitle={
        'Deleting this position will remove it from movers. It will remain on previous jobs. You cannot undo this action.'
      }
    />
  );
};

export default DeleteMoverPositionModal;
