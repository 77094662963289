// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight, Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '4px' : '8px')};
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '2px' : '4px')};
  border-radius: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '4px' : '4px')};
  background-color: ${(props) => colors.alpha((props as any).color, 0.15)};
`;

const Text = Styled.H7`
  color: ${(props) => (props as any).color};
  ${fontWeight(500)}
`;

const SmallText = Styled.H8`
  color: ${(props) => (props as any).color};
  ${fontWeight(500)}
`;

const LabelText = Styled.Text`
  ${Typography.Label3}
  color: ${(props) => (props as any).color};
`;

type OwnProps = {
  text: string;
  color: string;
  isSmall?: boolean;
  isLabel?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TextBadge.defaultProps;

// @ts-expect-error TS(7022): 'TextBadge' implicitly has type 'any' because it d... Remove this comment to see the full error message
const TextBadge = ({text, color, isSmall, isLabel}: Props) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container color={color} isSmall={isSmall}>
      {isLabel ? (
        <LabelText color={color}>{text}</LabelText>
      ) : isSmall ? (
        <SmallText color={color}>{text}</SmallText>
      ) : (
        <Text color={color}>{text}</Text>
      )}
    </Container>
  );
};

TextBadge.defaultProps = {
  isSmall: false,
  isLabel: false,
};

export default TextBadge;
