// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import TableV1 from '@shared/design/components/TableV1';
import TextBadge from 'modules/App/components/TextBadge';
import CreateMoverPositionModalV1 from 'modules/Organization/Settings/Users/List/components/CreateMoverPositionModalV1';
import DeleteMoverPositionModalV1 from 'modules/Organization/Settings/Users/List/components/DeleteMoverPositionModalV1';
import UpdateMoverPositionModalV1 from 'modules/Organization/Settings/Users/List/components/UpdateMoverPositionModalV1';

const Section = Styled.View`
  margin-bottom: 20px;
`;

const Title = Styled.H5`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Cell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) => (props as any).width}px;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  width: ${(props) => (props as any).width}px;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.H7`
  ${fontWeight(700)}
`;

const EditButton = Styled.ButtonV2`
  padding: 10px 10px 10px 14px;
  border-radius: 4px;
  height: 40px;
  background-color: ${colors.blue.interactive};
`;

const DeactivateButton = Styled.ButtonV2`
  padding: 10px 14px 10px 14px;
  border-radius: 4px;
  height: 40px;
  background-color: ${colors.Pink600};
`;

const Button = Styled.LoadingButton`
  align-self: flex-start;
  height: 40px;
  padding-horizontal: 10px;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  margin-bottom: 20px;
`;

const TableHeader = () => (
  <Row>
    <Cell width={400}>
      <HeaderText>Position Name</HeaderText>
    </Cell>
    <ActionsCell width={200}>
      <HeaderText>Actions</HeaderText>
    </ActionsCell>
  </Row>
);

const MoverPositionRow = ({moverPosition, refetch}: any) => {
  const updateMoverPositionModalV1 = useModal();
  const deleteMoverPositionModalV1 = useModal();

  return (
    <Row>
      <Cell width={400}>
        <TextBadge text={moverPosition.name} color={moverPosition.color} />
      </Cell>
      <ActionsCell width={200}>
        <EditButton onPress={updateMoverPositionModalV1.handleOpen}>
          <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Edit} />
        </EditButton>
        <Space width={5} />
        <DeactivateButton onPress={deleteMoverPositionModalV1.handleOpen}>
          <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Trash} />
        </DeactivateButton>
      </ActionsCell>
      <UpdateMoverPositionModalV1
        key={`${updateMoverPositionModalV1.isOpen}_UPDATE_MOVER_POSITION`}
        isOpen={updateMoverPositionModalV1.isOpen}
        handleClose={updateMoverPositionModalV1.handleClose}
        moverPosition={moverPosition}
        refetch={refetch}
      />
      <DeleteMoverPositionModalV1
        key={`${deleteMoverPositionModalV1.isOpen}_DELETE_MOVER_POSITION`}
        isOpen={deleteMoverPositionModalV1.isOpen}
        handleClose={deleteMoverPositionModalV1.handleClose}
        moverPositionId={moverPosition.id}
        refetch={refetch}
      />
    </Row>
  );
};

type OwnStaffMoverPositionsSectionV1Props = {
  organization: any;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'StaffMoverPositionsSectionV1Props' cir... Remove this comment to see the full error message
type StaffMoverPositionsSectionV1Props = OwnStaffMoverPositionsSectionV1Props &
  typeof StaffMoverPositionsSectionV1.defaultProps;

// @ts-expect-error TS(7022): 'StaffMoverPositionsSectionV1' implicitly has type... Remove this comment to see the full error message
const StaffMoverPositionsSectionV1 = ({
  organization,
  refetch,
}: StaffMoverPositionsSectionV1Props) => {
  const responsive = useResponsive();
  const createMoverPositionModalV1 = useModal();

  return (
    <Section>
      <Title>Mover Positions</Title>
      <Actions {...responsive}>
        <Button onPress={createMoverPositionModalV1.handleOpen} {...responsive}>
          <ButtonText>Create Position</ButtonText>
        </Button>
      </Actions>
      <ScrollView horizontal>
        <TableV1>
          <TableHeader />
          {organization.moverPositions.map((moverPosition: any) => (
            <MoverPositionRow
              moverPosition={moverPosition}
              key={moverPosition.id}
              refetch={refetch}
            />
          ))}
        </TableV1>
      </ScrollView>
      <CreateMoverPositionModalV1
        key={`${createMoverPositionModalV1.isOpen}_CREATE_MOVER_POSITION`}
        isOpen={createMoverPositionModalV1.isOpen}
        handleClose={createMoverPositionModalV1.handleClose}
        organizationId={organization.id}
        refetch={refetch}
      />
    </Section>
  );
};

StaffMoverPositionsSectionV1.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffMoverPositionsSectionV1.fragment = gql`
  ${UpdateMoverPositionModalV1.fragment}

  fragment StaffMoverPositionsSectionV1 on Organization {
    id
    moverPositions {
      id
      name
      color
      ...UpdateMoverPositionModalV1
    }
  }
`;

export default StaffMoverPositionsSectionV1;
