// Libraries
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import ScheduleUserDayItem from './ScheduleUserDayItem';

const UserDay = Styled.View`
`;

const ScheduleUserDaysList = ({
  refetch,
  scheduleDays,
  viewer,
  moverSetScheduleMinDaysAhead,
}: any) => (
  <FlatList
    data={scheduleDays}
    keyExtractor={(scheduleDay: any) => scheduleDay.date}
    renderItem={({item: scheduleDay, index}: any) => (
      <UserDay>
        <ScheduleUserDayItem
          isFirst={index === 0}
          isLast={index === scheduleDays.length - 1}
          refetch={refetch}
          scheduleDay={scheduleDay}
          viewer={viewer}
          moverSetScheduleMinDaysAhead={moverSetScheduleMinDaysAhead}
        />
      </UserDay>
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ScheduleUserDaysList.fragment = gql`
  ${ScheduleUserDayItem.fragment}

  fragment ScheduleUserDaysList on EmployeeScheduleV2 {
    scheduleDays: employeeScheduleDays {
      ...ScheduleUserDayItem
    }
  }
`;

export default ScheduleUserDaysList;
