// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Checkbox, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// Components
const Container = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row-reverse')};
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'flex-end')};
`;

const LabelWrapper = Styled.Touchable`
  margin-left: ${(props) => ((props as any).mobile ? 0 : 5)}px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary}
`;

const CheckboxField = ({name, label, values, input = {}, setFieldValue, style}: any) => {
  const responsive = useResponsive();
  return (
    <Container {...responsive} style={style}>
      <LabelWrapper
        {...responsive}
        accessible={false} // Disables the tabIndex and focus properties.
        disabled={input.disabled}
        activeOpacity={1}
        onPress={() => setFieldValue(name, !_.get(values, name))}
      >
        <Label>{label}</Label>
      </LabelWrapper>
      <Checkbox
        {...input}
        checked={_.get(values, name, false)}
        color={colors.gray.secondary}
        borderColor={colors.gray.secondary}
        size={16}
        iconSize={12}
        onChange={(value) => setFieldValue(name, value)}
      />
    </Container>
  );
};

export default CheckboxField;
