// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import Tabs from '@shared/design/components/Tabs';

const TabsContainer = Styled.View<{responsive: ResponsiveType}>`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
`;

const getTabDefinitions = ({
  isEnabledBillingHourlyRates,
}: {
  isEnabledBillingHourlyRates: boolean;
}) => {
  return [
    {
      label: 'Libraries',
      value: `/settings/billing/billing-libraries`,
    },
    ...List.insertIf(isEnabledBillingHourlyRates, {
      label: 'Hourly Rates',
      value: `/settings/billing/hourly-rates`,
    }),
    {
      label: 'Formulas',
      value: `/settings/billing/formulas`,
    },
    {
      label: 'Variables',
      value: `/settings/billing/variables`,
    },
    {
      label: 'Tables',
      value: `/settings/billing/tables`,
    },
  ];
};

const BillingSettingsPageNavigationTabs = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {data} = useQuery(BillingSettingsPageNavigationTabs.query, {
    fetchPolicy: 'cache-and-network',
  });
  const tabs = getTabDefinitions({
    isEnabledBillingHourlyRates:
      data?.viewer.viewingOrganization.features.isEnabledBillingHourlyRates,
  });
  const activeTabIndex = _.findIndex(tabs, {value: navigator.location.pathname});
  return (
    <TabsContainer responsive={responsive}>
      <Tabs
        tabs={tabs}
        handlePressTab={({value}) => navigator.push(value)}
        activeTabIndex={activeTabIndex}
        scrollViewStyle={{paddingLeft: responsive.desktop ? 24 : 12}}
      />
    </TabsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingSettingsPageNavigationTabs.query = gql`
  query OrganizationSettingsBillingHourlyRatesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledBillingHourlyRates: isEnabled(feature: "BILLING_HOURLY_RATES")
        }
      }
    }
  }
`;

export default BillingSettingsPageNavigationTabs;
