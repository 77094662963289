// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteCommercialMaterialMutation from '@shared/modules/CommercialCatalog/hooks/useDeleteCommercialMaterialMutation';

const DeleteCommercialMaterialModal = ({isOpen, commercialMaterial, handleClose, refetch}: any) => {
  const {submitting, handleSubmit} = useDeleteCommercialMaterialMutation({
    commercialMaterialId: commercialMaterial.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${commercialMaterial.name}`}
      subtitle={`Are you sure you would like to delete ${commercialMaterial.name}?`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteCommercialMaterialModal.fragment = gql`
  fragment DeleteCommercialMaterialModal on CommercialMaterial {
    id
    name
  }
`;

export default DeleteCommercialMaterialModal;
