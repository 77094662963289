// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {BillItem, Cost, CostItem, Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import Line from 'modules/App/components/Line';

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
`;

const HeaderText = Styled.Text`
  ${Typography.Label4}
  font-size: 10px;
  line-height: 14px;
  color: ${colors.gray.secondary}
`;

const CellWrapper = Styled.View`
  flex: ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex};
`;

const Cell = Styled.View`
  padding-horizontal: 4px;
`;

const HeaderCell = Styled.View`
  padding: 4px;
`;

const HeaderCellText = Styled.Text`
  ${Typography.Label4}
  font-size: 10px;
  line-height: 14px;
`;

const CellText = Styled.Text`
  ${Typography.Body5}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 4px;
  background-color: ${colors.alpha(colors.blue.accent, 0.5)};
`;

const TotalCompensationText = Styled.Text`
  ${Typography.Label4}
`;

const getCostColumns = ({isEnabledTbdBillItems}: any) => [
  {
    flex: 2,
    style: {borderRightWidth: 1, borderColor: colors.gray.border},
    headerContent: () => {
      return (
        <HeaderCell>
          <HeaderCellText>Item Name</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      return (
        <Cell>
          <CellText>{costItem.name}</CellText>
        </Cell>
      );
    },
  },
  {
    flex: 1,
    style: {borderRightWidth: 1, borderColor: colors.gray.border},
    headerContent: () => {
      return (
        <HeaderCell>
          <HeaderCellText>Quantity</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      const {billItem} = costItem;
      return (
        <Cell>
          <CellText>{billItem ? billItem.minQuantity : ''}</CellText>
        </Cell>
      );
    },
  },
  {
    flex: 2,
    style: {borderRightWidth: 1, borderColor: colors.gray.border},
    headerContent: () => {
      return (
        <HeaderCell>
          <HeaderCellText>Price</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      const {billItem} = costItem;
      return (
        <Cell>
          <CellText>
            {billItem ? BillItem.getDisplayPrice(billItem, {isEnabledTbdBillItems}) : ''}
          </CellText>
        </Cell>
      );
    },
  },
  {
    flex: 2,
    style: {borderRightWidth: 1, borderColor: colors.gray.border},
    headerContent: () => {
      return (
        <HeaderCell>
          <HeaderCellText>Total Price</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      const {billItem} = costItem;
      return (
        <Cell>
          <CellText>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            {billItem
              ? Currency.display(billItem.total, {shouldHideCentsIfZero: true})
              : CostItem.getDisplayRelatedTotal(costItem)}
          </CellText>
        </Cell>
      );
    },
  },
  {
    flex: 1,
    style: {},
    headerContent: () => {
      return (
        <HeaderCell>
          <HeaderCellText>Rate</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      return (
        <Cell>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <CellText>{CostItem.getDisplayRate(costItem)}</CellText>
        </Cell>
      );
    },
  },
  {
    flex: 2,
    style: {},
    headerContent: () => {
      return (
        <HeaderCell style={{alignItems: 'flex-end'}}>
          <HeaderCellText>Compensation Value</HeaderCellText>
        </HeaderCell>
      );
    },
    cellContent: ({costItem}: any) => {
      return (
        <Cell style={{alignItems: 'flex-end'}}>
          <CellText>{Currency.display(costItem.total, {shouldHideCentsIfZero: true})}</CellText>
        </Cell>
      );
    },
  },
];

const CostHeader = ({cost}: any) => {
  return (
    <Row>
      <HeaderText>
        {cost.job
          ? Job.getDisplayDate(cost.job, Datetime.DISPLAY_SHORT_DATE)
          : Project.getDate(cost.project)}
      </HeaderText>
      <Space width={12} />
      <HeaderText>Project {cost.project.identifier}</HeaderText>
      {!!cost.project.name && (
        <React.Fragment>
          <Space width={12} />
          <HeaderText>{cost.project.name}</HeaderText>
        </React.Fragment>
      )}
      {!!cost.job && (
        <React.Fragment>
          <Space width={12} />
          <HeaderText>{Cost.getDisplayIdentifier(cost)}</HeaderText>
        </React.Fragment>
      )}
      <Space style={{flex: 1}} />
      <HeaderText>{cost.project.client.name}</HeaderText>
    </Row>
  );
};

const CostItemRow = ({costItem, isEven, costColumns}: any) => {
  return (
    <Row style={{backgroundColor: isEven ? colors.white : colors.gray.background}}>
      {costColumns.map(({flex, cellContent, style}: any, index: any) => {
        return (
          // @ts-expect-error TS(2769): No overload matches this call.
          <CellWrapper key={index} flex={flex} style={style}>
            <Space height={4} />
            {cellContent({costItem})}
            <Space height={4} />
          </CellWrapper>
        );
      })}
    </Row>
  );
};

const CostFooter = ({cost}: any) => {
  return (
    <FooterRow>
      <CellText>{Currency.display(cost.total, {shouldHideCentsIfZero: true})}</CellText>
    </FooterRow>
  );
};

const CompensationReportCosts = ({compensationReport, isEnabledTbdBillItems}: any) => {
  const costColumns = getCostColumns({isEnabledTbdBillItems});
  return (
    <Container>
      <Space height={12} />
      {compensationReport.costs.map((cost: any) => {
        return (
          <React.Fragment key={cost.id}>
            <Space height={12} />
            <CostHeader cost={cost} />
            <Space height={4} />
            <Line color={colors.blue.interactive} />
            <Row style={{backgroundColor: colors.gray.background}}>
              {costColumns.map(({flex, headerContent, style}, index) => {
                return (
                  // @ts-expect-error TS(2769): No overload matches this call.
                  <CellWrapper key={index} flex={flex} style={style}>
                    <Space height={4} />
                    {headerContent()}
                    <Space height={6} />
                  </CellWrapper>
                );
              })}
            </Row>
            {cost.costItems.map((costItem: any, index: any) => {
              return (
                <React.Fragment key={costItem.id}>
                  <CostItemRow
                    costItem={costItem}
                    isEven={index % 2 === 0}
                    costColumns={costColumns}
                  />
                </React.Fragment>
              );
            })}
            <Line />
            <CostFooter cost={cost} />
          </React.Fragment>
        );
      })}
      <Space height={12} />
      <FooterRow>
        <TotalCompensationText>Total Compensation</TotalCompensationText>
        <Space style={{flex: 1}} />
        <TotalCompensationText>
          {Currency.display(compensationReport.total, {shouldHideCentsIfZero: true})}
        </TotalCompensationText>
      </FooterRow>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompensationReportCosts.fragment = gql`
  ${BillItem.getDisplayPrice.fragment}
  ${Cost.getDisplayIdentifier.fragment}
  ${CostItem.getDisplayRate.fragment}
  ${CostItem.getDisplayRelatedTotal.fragment}
  ${Job.getDisplayDate.fragment}
  ${Project.getDate.fragment}

  fragment CompensationReportCosts on CompensationReport {
    id
    total
    costs {
      id
      total
      job {
        id
        ...Job_getDisplayDate
      }
      compensationUser {
        id
        fullName
      }
      costItems {
        id
        name
        unit
        rate
        total
        billItem {
          id
          minQuantity
          total
          ...BillItem_getDisplayPrice
        }
        ...CostItem_getDisplayRate
        ...CostItem_getDisplayRelatedTotal
      }
      project {
        id
        identifier
        name
        client {
          id
          name
        }
        ...Project_getDate
      }
      ...Cost_getDisplayIdentifier
    }
  }
`;

export default CompensationReportCosts;
