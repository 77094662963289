// Libraries
import React from 'react';

// Supermove
import {Styled, Loading, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useDrawer, useSearch} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import Line from 'modules/App/components/Line';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import CreateWorkflowDrawer from 'modules/Organization/Settings/Workflow/components/CreateWorkflowDrawer';
import WorkflowPreviewPanel from 'modules/Organization/Settings/Workflow/components/WorkflowPreviewPanel';
import WorkflowsTable from 'modules/Organization/Settings/Workflow/components/WorkflowsTable';

const Container = Styled.View`
  flex-direction: column;
`;

const VerticalLine = Styled.View`
  width: 1px;
  background-color: ${colors.gray.border};
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.Heading2}
`;

const ContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
  min-width: 1200px;
  flex-direction: row;
`;

const WorkflowsTablePanelContainer = Styled.View`
  flex: 1;
`;

const PanelHeader = Styled.View`
  height: 60px;
  flex-direction: row;
  align-items: center;
  padding-horizontal: 24px;
  padding-vertical: 12px;
  background-color: ${colors.white}
`;

const WorkflowsTableContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24px;
`;

const OrganizationSettingsWorkflowBreadcrumb = () => {
  const {navigator} = useNavigationDOM();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Automations', isBeta: true},
  ];

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
};

const OrganizationSettingsWorkflowHeader = () => {
  return (
    <Container>
      <StandardOfficeHeader title={''}>
        <OrganizationSettingsWorkflowBreadcrumb />
      </StandardOfficeHeader>
    </Container>
  );
};

const WorkflowsTablePanel = ({workflows, refetch, createWorkflowDrawer}: any) => {
  // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  const {results: filteredWorkflows, setQuery} = useSearch({
    initialQuery: '',
    items: workflows,
    options: {keys: ['name', 'description', 'updatedBy.fullName']},
  });
  return (
    <WorkflowsTablePanelContainer>
      <PanelHeader>
        <Title>Automations</Title>
        <Space style={{flex: 1}} />
        <Button
          text={'Create Automation'}
          iconLeft={Icon.Plus}
          onPress={createWorkflowDrawer.handleOpen}
        />
      </PanelHeader>
      <Line />
      <WorkflowsTableContainer>
        <Space height={24} />
        <SearchBar
          onChangeText={(text) => setQuery(text)}
          placeholder='Search by automation name, description, updated by'
          style={{width: 400}}
        />
        <Space height={24} />
        <WorkflowsTable workflows={filteredWorkflows} refetch={refetch} />
      </WorkflowsTableContainer>
    </WorkflowsTablePanelContainer>
  );
};

const OrganizationSettingsWorkflowSettingsContent = ({workflows, refetch, organizationId}: any) => {
  const {navigator} = useNavigationDOM();
  const createWorkflowDrawer = useDrawer({name: 'Create Workflow Drawer'});

  return (
    <PageContainer>
      <OrganizationSettingsWorkflowHeader />
      <ContentContainer>
        <WorkflowsTablePanel
          workflows={workflows}
          refetch={refetch}
          createWorkflowDrawer={createWorkflowDrawer}
        />
        <VerticalLine />
        <WorkflowPreviewPanel workflows={workflows} />
      </ContentContainer>
      <CreateWorkflowDrawer
        key={createWorkflowDrawer.key}
        isOpen={createWorkflowDrawer.isOpen}
        handleClose={createWorkflowDrawer.handleClose}
        organizationId={organizationId}
        onSuccess={(workflow: any) => {
          refetch();
          createWorkflowDrawer.handleClose();
          navigator.push(`/settings/automations/${workflow.uuid}/build`);
        }}
      />
    </PageContainer>
  );
};

const OrganizationSettingsWorkflowSettingsPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsWorkflowSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading loading={loading}>
        {() => (
          <OrganizationSettingsWorkflowSettingsContent
            workflows={data.viewer.viewingOrganization.workflows}
            organizationId={data.viewer.viewingOrganization.id}
            refetch={refetch}
          />
        )}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsWorkflowSettingsPage.query = gql`
  ${WorkflowsTable.fragment}
  ${CreateWorkflowDrawer.fragment}
  query OrganizationSettingsWorkflowSettingsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...CreateWorkflowDrawer
        workflows {
          id
          uuid
          name
          ...WorkflowsTable
        }
      }
    }
  }
`;

export default OrganizationSettingsWorkflowSettingsPage;
