// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Styled} from '@supermove/components';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepForm from '@shared/modules/Workflow/forms/WorkflowStepForm';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StepNumberContainer = Styled.View`
  width: 56px;
`;

const getWorkflowStepDropdownOptions = ({
  workflowStepForms,
  accessPropertySuffix,
  isFormula,
}: any) => {
  return _.map(workflowStepForms, (workflowStepForm) => {
    const {label, kind} =
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      WorkflowStepActionKind.ATTRIBUTES[workflowStepForm.workflowStepActionKind];
    return {
      value: WorkflowStepForm.generateWorkflowStepDropdownValue({
        workflowStepForm,
        accessPropertySuffix,
        isFormula,
      }),
      kind,
      label,
      stepNumber: workflowStepForm.order + 1,
    };
  });
};

const WorkflowStepOption = ({data, ...props}: any) => {
  return (
    <DropdownInput.Option {...props}>
      <Row>
        <StepNumberContainer>
          <DropdownInput.OptionSecondaryLabel {...props}>
            {`Step ${data.stepNumber}`}
          </DropdownInput.OptionSecondaryLabel>
        </StepNumberContainer>
        <DropdownInput.OptionLabel {...props}>{data.label}</DropdownInput.OptionLabel>
      </Row>
    </DropdownInput.Option>
  );
};

const WorkflowStepDropdown = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  accessPropertySuffix,
  isFormula,
}: any) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  const {actionForm, actionFormField, workflowStepDropdown} =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowStepActionKind.ATTRIBUTES[actionKind];

  const workflowStepForms = _.get(form.values, workflowStepsField);
  const priorWorkflowStepForms = _.slice(workflowStepForms, 0, workflowStepIndex);
  const filteredWorkflowStepForms = _.filter(priorWorkflowStepForms, (workflowStepForm) =>
    workflowStepDropdown.optionFilter((workflowStepForm as any).workflowStepActionKind),
  );

  const referencePathField = `${workflowStepsField}.${workflowStepIndex}.${actionForm}.${actionFormField}`;
  const referenceKindField = `${workflowStepsField}.${workflowStepIndex}.${actionForm}.stepReferenceKind`;

  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={referencePathField}
      label={workflowStepDropdown.label}
      isRequired
      input={{
        options: getWorkflowStepDropdownOptions({
          workflowStepForms: filteredWorkflowStepForms,
          accessPropertySuffix,
          isFormula,
        }),
        placeholder: workflowStepDropdown.placeholder,
        isSearchable: true,
        isPortaled: true,
        onChangeValue: (_: any, {kind}: any) => {
          form.setFieldValue(referenceKindField, kind);
        },
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        menuPlacement: 'auto',
        components: {
          Option: WorkflowStepOption,
        },
      }}
    />
  );
};

export default WorkflowStepDropdown;
