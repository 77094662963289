// Supermove
import {gql} from '@supermove/graphql';
import {AttachmentModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export interface ItemAttachmentFormType {
  attachmentId: string | null;
  uuid: string;
  fileId: number;
  itemId: number | null;
  filename: string;
  description: string | undefined;
  downloadUrl?: string;
  isDeleted: boolean;
}

type ItemAttachmentFormToFormType = ItemAttachmentFormType;

interface ItemAttachmentFormToMutationType {
  attachmentId: string | null;
  uuid: string;
  fileId: number;
  itemId: number | null;
  filename: string;
  description: string | undefined;
  isDeleted: boolean;
  itemUuid: string;
}

const _new = ({
  fileId,
  filename,
  uuid,
  description,
  downloadUrl,
  isDeleted,
}: {
  fileId: number;
  filename: string;
  uuid: string;
  description?: string;
  downloadUrl?: string;
  isDeleted: boolean;
}): ItemAttachmentFormType => ({
  attachmentId: null,
  fileId,
  uuid,
  itemId: null, // NOTE(cooper): We don't have itemIds at the time of creation
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const newWithAttachmentId = ({
  id,
  itemId,
  fileId,
  filename,
  uuid,
  description,
  downloadUrl,
  isDeleted,
}: {
  id: string;
  itemId: number;
  fileId: number;
  filename: string;
  uuid: string;
  description: string;
  downloadUrl: string;
  isDeleted: boolean;
}): ItemAttachmentFormType => ({
  attachmentId: id,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const edit = withFragment(
  (attachment: AttachmentModel): ItemAttachmentFormType => ({
    attachmentId: attachment.id,
    fileId: attachment.fileId,
    uuid: attachment.uuid,
    itemId: attachment.itemId,
    filename: attachment.file.name,
    description: attachment.description,
    downloadUrl: attachment.file.downloadUrl,
    isDeleted: attachment.isDeleted,
  }),
  gql`
    fragment ItemAttachmentForm_edit on Attachment {
      id
      fileId
      uuid
      itemId
      description
      isDeleted
      file {
        id
        name
        downloadUrl
      }
    }
  `,
);

const toForm = ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
}: ItemAttachmentFormType): ItemAttachmentFormToFormType => ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  downloadUrl,
  isDeleted,
});

const toMutation = ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  filename,
  description,
  isDeleted,

  // NOTE(cooper): Item uuid gets injected when the mutation fires,
  // we don't have it available when the ItemAttachmentForm gets created
  itemUuid,
}: ItemAttachmentFormType & {itemUuid: string}): ItemAttachmentFormToMutationType => ({
  attachmentId,
  fileId,
  uuid,
  itemId,
  itemUuid,
  filename,
  description,
  isDeleted,
});

const ItemAttachmentForm = {
  new: _new,
  newWithAttachmentId,
  edit,
  toForm,
  toMutation,
};

export default ItemAttachmentForm;
