// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  refetchQueries?: any[];
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UnassignUserFromJobMutation.defaultProps;

// @ts-expect-error TS(7022): 'UnassignUserFromJobMutation' implicitly has type ... Remove this comment to see the full error message
const UnassignUserFromJobMutation = ({
  jobId,
  userId,
  refetchQueries,
  onSuccess,
  onError,
  children,
}: Props) => {
  return (
    <Mutation
      // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
      variables={{
        jobId,
        userId,
      }}
      mutation={UnassignUserFromJobMutation.mutation}
      onCompleted={({response: {errors}}: any) => {
        if (!errors) {
          onSuccess();
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={refetchQueries}
      awaitRefetchQueries
    >
      {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
    </Mutation>
  );
};

UnassignUserFromJobMutation.defaultProps = {
  refetchQueries: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnassignUserFromJobMutation.mutation = gql`
  mutation UnassignUserFromJobMutation(
    $jobId: Int!,
    $userId: Int!,
  ) {
    response: unassignUserFromJob(
      jobId: $jobId,
      userId: $userId,
    ) {
      ${gql.errors}
    }
  }
`;

export default UnassignUserFromJobMutation;
