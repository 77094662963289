// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import Table from '@shared/design/components/TableV2Deprecated';
import EditReferralTypeModal from 'modules/Organization/Settings/Company/components/EditReferralTypeModal';
import RemoveReferralTypeModal from 'modules/Organization/Settings/Company/components/RemoveReferralTypeModal';

const TableContainer = Styled.View`
  width: 400px;
`;

const TableHeaderText = Styled.Text`
    ${Typography.Label3}
`;

const Text = Styled.Text`
    ${Typography.Body3}
`;

const EditReferralButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const ReferralActions = ({organization, referralSources, referral, refetch}: any) => {
  const editReferralTypeModal = useModal({name: 'Edit Referral Type Modal'});
  const removeReferralTypeModal = useModal({name: 'Remove Referral Type Modal'});
  const referralActionPopover = usePopover();

  return (
    <React.Fragment>
      <EditReferralButton onPress={editReferralTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditReferralButton>
      <Space width={12} />
      <Popover.Content innerRef={referralActionPopover.ref}>
        <MoreActionsButton onPress={referralActionPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </MoreActionsButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={referralActionPopover.isOpen}
        handleOpen={referralActionPopover.handleOpen}
        handleClose={referralActionPopover.handleClose}
        reference={referralActionPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={referralActionPopover.handleClose}
          actions={[{text: 'Remove', onPress: removeReferralTypeModal.handleOpen}]}
        />
      </Popover>
      <EditReferralTypeModal
        key={editReferralTypeModal.key}
        editReferralTypeModal={editReferralTypeModal}
        refetch={refetch}
        referral={referral}
        referralSources={referralSources}
        organizationId={organization.id}
      />
      <RemoveReferralTypeModal
        key={removeReferralTypeModal.key}
        removeReferralTypeModal={removeReferralTypeModal}
        refetch={refetch}
        referral={referral}
        referralSources={referralSources}
        organizationId={organization.id}
      />
    </React.Fragment>
  );
};

const getReferralTableColumnDefinitions = ({organization, referralSources, refetch}: any) => [
  {
    width: 300,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'referral' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: referral}) => {
      return <Text>{referral.name}</Text>;
    },
  },
  {
    width: 100,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'referral' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: referral}) => {
      return (
        <ReferralActions
          organization={organization}
          referralSources={referralSources}
          referral={referral}
          refetch={refetch}
        />
      );
    },
  },
];

const ReferralSourcesTable = ({organization, referralSources, refetch}: any) => {
  return (
    <TableContainer>
      <Table
        columnDefinitions={getReferralTableColumnDefinitions({
          organization,
          referralSources,
          refetch,
        })}
        emptyStateText='No referrals to display'
        items={referralSources}
        isDense
        itemKey={'name'}
      />
    </TableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReferralSourcesTable.fragment = gql`
  ${EditReferralTypeModal.fragment}
  ${RemoveReferralTypeModal.fragment}
  fragment ReferralSourcesTable on Organization {
    id
    settings {
      id
      referralSources {
        name
      }
    }
    ...EditReferralTypeModal
    ...RemoveReferralTypeModal
  }
`;

export default ReferralSourcesTable;
