// Libraries
import React from 'react';

// Supermove
import {Emoji, Styled, Tooltip} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  cursor: default;
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(300)}
`;

const EmojiText = Styled.H4`
  margin-top: 5px;
`;

type OwnProps = {
  emoji: string;
  tooltip: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EmojiTooltip.defaultProps;

// @ts-expect-error TS(7022): 'EmojiTooltip' implicitly has type 'any' because i... Remove this comment to see the full error message
const EmojiTooltip = ({emoji, tooltip, style}: Props) => (
  <Tooltip placement={'bottom'} overlay={() => <Text>{tooltip}</Text>}>
    <Container style={style}>
      <Emoji component={EmojiText} name={emoji} />
    </Container>
  </Tooltip>
);

EmojiTooltip.defaultProps = {};

export default EmojiTooltip;
