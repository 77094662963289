// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobProjectSection from 'modules/Project/components/JobProjectSection';

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobProjectBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobProjectBlock' implicitly has type 'any' becaus... Remove this comment to see the full error message
const JobProjectBlock = ({job}: Props) => {
  return <JobProjectSection sectionIndex={0} job={job} />;
};

JobProjectBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectBlock.fragment = gql`
  ${JobProjectSection.fragment}

  fragment JobProjectBlock on Job {
    id
    ...JobProjectSection
  }
`;

export default JobProjectBlock;
