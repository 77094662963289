// Libraries
import React from 'react';

// Supermove
import {Notification, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Day} from '@supermove/models';

const Container = Styled.View`
  width: 100%;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

type OwnProps = {
  status: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DayJobsStatusBanner.defaultProps;

// @ts-expect-error TS(7022): 'DayJobsStatusBanner' implicitly has type 'any' be... Remove this comment to see the full error message
const DayJobsStatusBanner = ({status}: Props) => (
  <Container>
    {!!Day.getCalendarStatusColor({status}) && (
      // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'string'.
      <Notification color={Day.getCalendarStatusColor({status})} text={status.text} />
    )}
  </Container>
);

DayJobsStatusBanner.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayJobsStatusBanner.fragment = gql`
  fragment DayJobsStatusBanner on CalendarDay {
    status {
      kind
      text
    }
  }
`;

export default DayJobsStatusBanner;
