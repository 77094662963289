// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useMountEffect,
  useQuery,
  useNavigationDOM,
  useRef,
  useEffect,
  usePagination,
} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ContainerCapacityKind from '@shared/modules/Storage/enums/ContainerCapacityKind';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import useFilteredContainersReducer from '@shared/modules/Storage/hooks/useFilteredContainersReducer';
import ContainersList from 'modules/Storage/Containers/ContainersList';
import StorageContainerFilters from 'modules/Storage/components/StorageContainerFilters';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const BASE_ROUTE = '/storage/containers';

const getVariablesFromParams = (params: any, firstWarehouseId: any, organization: any) => {
  const {locationKind, ...rest} = params;
  return {
    locationKind: locationKind || ContainerLocationKind.WAREHOUSE,
    warehouseIds: !locationKind ? (firstWarehouseId ? [firstWarehouseId] : null) : null,
    capacityKinds: ContainerCapacityKind.ALL,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    slugs: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
    ...rest,
  };
};

const StorageContainersPageContent = ({organization}: any) => {
  const {params, navigator} = useNavigationDOM();
  const ref = useRef();

  // If no warehouse is specified, we default to the first warehouse
  const firstWarehouseId = !_.isEmpty(organization.companySettings.warehouses)
    ? organization.companySettings.warehouses[0].id
    : null;
  const {filters, dispatch, actions} = useFilteredContainersReducer(
    getVariablesFromParams(params, firstWarehouseId, organization),
  );

  useMountEffect(() => {
    // If required params are not set in the route on mount,
    // reload the page with the correct variables
    if (!params.pagination || !params.locationKind || !params.slugs) {
      navigator.replace(URL.getUrlFromVariables(BASE_ROUTE, filters));
    }
  });

  useEffect(() => {
    if (!ref.current) {
      // Set the initial ref to avoid unnecessary URL updates
      ref.current = filters;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, filters)) {
      ref.current = filters;
      const url = URL.getUrlFromVariables(BASE_ROUTE, filters);
      navigator.replace(url);
    }
  }, [navigator, ref, filters]);

  const {loading, data, refetch, error} = useQuery(StorageContainersPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...filters,
      locationKind: filters.locationKind === 'ALL' ? null : filters.locationKind,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(filters.pagination.page),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => dispatch({type: actions.SET_PAGINATION, payload: page}),
  });

  return (
    <Container>
      <Space height={16} />
      <StorageContainerFilters
        refetch={refetch}
        filteredContainerCountsByLocation={data ? data.filteredContainerCountsByLocation : {}}
        filters={filters}
        dispatch={dispatch}
        actions={actions}
        organization={organization}
      />
      <ScrollView style={{flex: 1}}>
        <ContainersList
          loading={loading}
          refetch={refetch}
          containers={data ? data.paginatedList.containers : []}
          hasError={!!error}
          onContainerSelect={() => {}}
          showActions
          isWarehouseView={filters.locationKind === ContainerLocationKind.WAREHOUSE}
        />
        <Space height={40} />
        <PaginationBar pagination={pagination} />
        <Space height={70} />
      </ScrollView>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageContainersPageContent.query = gql`
  ${ContainersList.fragment}
  ${usePagination.fragment}

  query StorageContainersPageContent(
    $locationKind: String
    $pagination: PaginationInput!
    $warehouseIds: [Int]
    $containerTypeIds: [Int]
    $capacityKinds: [String]
    $warehouseCoordinateX: String
    $warehouseCoordinateY: String
    $warehouseCoordinateZ: String
    $searchQuery: String
    $customLocationSearchQuery: String
    $slugs: [String]
  ) {
    ${gql.query}
    paginatedList: filteredContainersPaginatedList(
      locationKind: $locationKind
      pagination: $pagination
      warehouseIds: $warehouseIds
      containerTypeIds: $containerTypeIds
      capacityKinds: $capacityKinds
      warehouseCoordinateX: $warehouseCoordinateX
      warehouseCoordinateY: $warehouseCoordinateY
      warehouseCoordinateZ: $warehouseCoordinateZ
      searchQuery: $searchQuery
      customLocationSearchQuery: $customLocationSearchQuery
      slugs: $slugs
    ) {
      containers: results {
        id
        ...ContainersList
      }
      paginationMetadata {
        ...usePagination
      }
    }
    filteredContainerCountsByLocation(
      containerTypeIds: $containerTypeIds
      capacityKinds: $capacityKinds
      warehouseCoordinateX: $warehouseCoordinateX
      warehouseCoordinateY: $warehouseCoordinateY
      warehouseCoordinateZ: $warehouseCoordinateZ
      searchQuery: $searchQuery
      customLocationSearchQuery: $customLocationSearchQuery
      slugs: $slugs
    ) {
      warehouseId
      count
    }
  }
`;

StorageContainersPageContent.fragment = gql`
  ${StorageContainerFilters.fragment}

  fragment StorageContainersPageContent on Organization {
    id
    isPrimary
    slug
    companySettings {
      warehouses {
        id
      }
    }
    ...StorageContainerFilters
  }
`;

export default StorageContainersPageContent;
