// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import CustomerTaskProjectQuoteBody from 'modules/Project/Confirmation/components/CustomerTaskProjectQuoteBody';

const Container = Styled.View`
`;

const Content = Styled.View`
  width: 700px;
  padding-vertical: 15px;
  padding-horizontal: 15px;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  align-self: stretch;
  padding-horizontal: 20px;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Title = Styled.Text`
  ${Typography.Heading5}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border}
  margin-horizontal: 20px;
`;

const SectionSpace = () => {
  return <Space height={16} />;
};

const CustomerTaskProjectQuoteUnacceptedContent = ({project}: any) => {
  const {isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber} = project.organization.features;
  return (
    <Container>
      <Content>
        {!!project.organization.logoUri && (
          // @ts-expect-error TS(2769): No overload matches this call.
          <Section sectionIndex={0}>
            <DocumentHeaderOrganization
              shouldShowBusinessName={false}
              shouldShowBusinessAddressAndPhoneNumber={
                isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber
              }
              organization={project.organization}
            />
          </Section>
        )}
        <SectionSpace />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section sectionIndex={1}>
          <Title>Review Your Quote</Title>
          <Space height={4} />
          <Subtitle>{Project.getQuoteMessage(project)}</Subtitle>
        </Section>
        <SectionSpace />
        <Line />
        <SectionSpace />
        <CustomerTaskProjectQuoteBody project={project} />
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerTaskProjectQuoteUnacceptedContent.fragment = gql`
  ${DocumentHeaderOrganization.fragment}
  ${CustomerTaskProjectQuoteBody.fragment}
  ${Project.getQuoteMessage.fragment}

  fragment CustomerTaskProjectQuoteUnacceptedContent on Project {
    id
    customer {
      id
      fullName
    }
    organization {
      id
      name
      logoUri
      features {
        isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber: isEnabled(
          feature: "QUOTE_AND_CONFIRMATION_SHOW_ADDRESS_AND_PHONE_NUMBER"
        )
      }
      ...DocumentHeaderOrganization
    }
    ...CustomerTaskProjectQuoteBody
    ...Project_getQuoteMessage
  }
`;

export default CustomerTaskProjectQuoteUnacceptedContent;
