// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, usePopover, useModal, useDebouncedCallback} from '@supermove/hooks';
import {titleize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ButtonGroup from '@shared/design/components/Button/ButtonGroup';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ContainerCapacityKind from '@shared/modules/Storage/enums/ContainerCapacityKind';
import ContainerLocationKind from '@shared/modules/Storage/enums/ContainerLocationKind';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';
import BulkUploadContainersModal from 'modules/Storage/components/BulkUploadContainersModal';
import CreateContainerModal from 'modules/Storage/components/CreateContainerModal';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TabsContainer = Styled.View`
`;

const getTabs = ({
  warehouses,
  filteredContainerCountsByLocation,
  allTabCount,
  isMultiBranch,
  params,
}: any) => {
  // Iterate through organization warehouses and sort them by name
  const warehouseTabs = _.sortBy(
    _.reduce(
      warehouses,
      // @ts-expect-error TS(2769): No overload matches this call.
      (tabs, warehouse) => {
        const countForWarehouse = _.find(
          filteredContainerCountsByLocation,
          (containerCountsByLocation) =>
            containerCountsByLocation.warehouseId === _.toNumber(warehouse.id),
        );

        // If multibranch is on and org is primary, we filter out
        // warehouses that are not in the params.slugs to show
        // subtabs correctly
        if (
          isMultiBranch &&
          params.slugs &&
          !params.slugs.includes('ALL_ORGANIZATIONS') &&
          !params.slugs.includes(warehouse.organization.slug)
        ) {
          return [...tabs];
        }

        return [
          ...tabs,
          {
            key: warehouse.id,
            label: warehouse.name,
            count: countForWarehouse ? countForWarehouse.count : 0,
            isPrimary: warehouse.organization.isPrimary,
          },
        ];
      },
      [],
    ),
    [
      ['isPrimary', 'label'],
      ['desc', 'asc'],
    ],
  );

  const customLocationWithCount = _.find(
    filteredContainerCountsByLocation,
    (containerCountByLocation) => containerCountByLocation.warehouseId === null,
  );

  return [
    ...warehouseTabs,
    {
      key: ContainerLocationKind.CUSTOM,
      label: 'Other Locations',
      count: customLocationWithCount ? customLocationWithCount.count : 0,
    },
    {
      key: 'ALL',
      label: 'All',
      count: allTabCount,
    },
  ];
};

const getActiveTabKey = ({locationKind, warehouseIds}: any) => {
  if (locationKind === ContainerLocationKind.CUSTOM) {
    return ContainerLocationKind.CUSTOM;
  } else if (locationKind === ContainerLocationKind.WAREHOUSE) {
    // If there is no warehouse, default to ALL tab
    if (_.isEmpty(warehouseIds)) {
      return 'ALL';
    }
    return warehouseIds[0];
  } else {
    return 'ALL';
  }
};

const TabHeader = ({
  filteredContainerCountsByLocation,
  filters,
  dispatch,
  actions,
  warehouses,
  isMultiBranch,
  params,
}: any) => {
  const allTabCount = _.reduce(
    filteredContainerCountsByLocation,
    (sum, location) => {
      return sum + location.count;
    },
    0,
  );

  const activeTabKey = getActiveTabKey(filters);

  const tabDefinitions = getTabs({
    warehouses,
    filteredContainerCountsByLocation,
    allTabCount,
    params,
    isMultiBranch,
  });
  const activeTabIndex = _.findIndex(
    tabDefinitions,
    (definition) => definition.key === activeTabKey,
  );

  const handleTabPress = ({key}: any) => {
    if (key === ContainerLocationKind.CUSTOM) {
      dispatch({type: actions.SET_WAREHOUSE, payload: null});
      dispatch({type: actions.SET_LOCATION_KIND, payload: ContainerLocationKind.CUSTOM});
    } else if (key === 'ALL') {
      dispatch({type: actions.SET_WAREHOUSE, payload: null});
      dispatch({type: actions.SET_LOCATION_KIND, payload: 'ALL'});
    } else {
      dispatch({type: actions.SET_WAREHOUSE, payload: key});
      dispatch({type: actions.SET_LOCATION_KIND, payload: ContainerLocationKind.WAREHOUSE});
    }
  };

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={handleTabPress}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const ContainersTabActionMenu = ({
  popover,
  createContainerModal,
  bulkUploadContainersModal,
}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.RightEnd}
      width={200}
      offset={[4, 48]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          createContainerModal.handleOpen();
          popover.handleClose();
        }}
      >
        Add container
      </ActionMenuPopover.MenuItem>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          bulkUploadContainersModal.handleOpen();
          popover.handleClose();
        }}
      >
        Import via CSV
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const StorageContainerFilters = ({
  refetch,
  filteredContainerCountsByLocation,
  organization,
  filters,
  dispatch,
  actions,
}: any) => {
  const {params} = useNavigationDOM();

  const actionsPopover = usePopover();
  const createContainerModal = useModal({name: 'Create Container Modal', enableTracking: true});
  const bulkUploadContainersModal = useModal({
    name: 'Bulk Upload Containers Modal',
    enableTracking: true,
  });

  const handleChangeSearch = useDebouncedCallback((text) => {
    dispatch({type: actions.SET_SEARCH_QUERY, payload: text});
  }, 200);

  const handleCapacityFilterPress = (capacityKind: any) => {
    const newCapacityKinds = _.xor(filters.capacityKinds, [capacityKind]);
    dispatch({type: actions.SET_CAPACITY_KINDS, payload: newCapacityKinds});
  };

  const isMultiBranch =
    organization.canViewOtherBranchesData && organization.features.isEnabledMultibranchStorage;

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by container details'
            style={{width: '348px', height: '38px'}}
            defaultValue={params.searchQuery}
            isClearable
          />
          <Space width={12} />
          <ButtonGroup
            options={ContainerCapacityKind.ALL.map((capacityKind) => ({
              value: capacityKind,
              label: titleize(capacityKind),
              disabled:
                filters.capacityKinds.includes(capacityKind) && filters.capacityKinds.length === 1,
            }))}
            selectedOptionValues={filters.capacityKinds}
            handleOptionPress={handleCapacityFilterPress}
            containerStyle={{width: 188}}
          />
          <Space width={12} />
          {isMultiBranch && (
            <React.Fragment>
              <MultiBranchOrganizationField
                isPortaled
                value={params.slugs || []}
                organizations={organization.company.organizations}
                onChangeValue={(newSlugs: any) => {
                  dispatch({type: actions.SET_SLUGS, payload: newSlugs});
                  refetch();
                }}
              />
              <Space height={8} />
            </React.Fragment>
          )}
        </LeftContainer>
        <RightContainer>
          <Space width={8} />
          <Popover.Content innerRef={actionsPopover.ref}>
            <Button
              onPress={actionsPopover.handleToggle}
              iconLeft={Icon.Plus}
              text={'Add Containers'}
            />
          </Popover.Content>
          <ContainersTabActionMenu
            popover={actionsPopover}
            createContainerModal={createContainerModal}
            bulkUploadContainersModal={bulkUploadContainersModal}
          />
        </RightContainer>
      </FiltersContainers>
      <Space height={16} />
      <TabHeader
        params={params}
        isMultiBranch={isMultiBranch}
        filteredContainerCountsByLocation={filteredContainerCountsByLocation}
        filters={filters}
        dispatch={dispatch}
        actions={actions}
        warehouses={organization.companySettings.warehouses}
      />
      <CreateContainerModal
        key={`CREATE_CONTAINER_MODAL-${createContainerModal.isOpen}`}
        isOpen={createContainerModal.isOpen}
        handleClose={createContainerModal.handleClose}
        refetch={refetch}
      />
      <BulkUploadContainersModal
        key={`BULK_UPLOAD_CONTAINERS_MODAL-${bulkUploadContainersModal.isOpen}`}
        isOpen={bulkUploadContainersModal.isOpen}
        handleClose={bulkUploadContainersModal.handleClose}
        organization={organization}
        handleOpen={bulkUploadContainersModal.handleOpen}
        refetch={refetch}
      />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageContainerFilters.fragment = gql`
  ${BulkUploadContainersModal.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment StorageContainerFilters on Organization {
    id
    canViewOtherBranchesData
    companySettings {
      warehouses {
        id
        uuid
        name
        organization {
          id
          slug
          isPrimary
        }
      }
    }
    features {
      isEnabledMultibranchStorage: isEnabled(feature: "MULTIBRANCH_STORAGE")
    }
    ...BulkUploadContainersModal
    ...MultiBranchOrganizationField
  }
`;

export default StorageContainerFilters;
