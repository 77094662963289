// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {UserFlowModel} from '@supermove/models';

// App
import DrawerWithClose from '@shared/design/components/Drawer/DrawerWithClose';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import DocumentFlowDetailsDrawerSettings from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowDetailsDrawerSettings';
import DocumentFlowDetailsDrawerStepsListItem from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowDetailsDrawerStepsListItem';

const DocumentFlowDetailsDrawerContent = ({
  userFlow,
  isOpen,
  handleClose,
}: {
  userFlow: UserFlowModel;
  isOpen: boolean;
  handleClose: () => void;
}) => {
  return (
    <DrawerWithClose
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={userFlow.name}
      description={userFlow.description}
      width={480}
    >
      <ActionPanel
        index={0}
        title={'Settings'}
        BodyComponent={DocumentFlowDetailsDrawerSettings}
        bodyComponentProps={{userFlow}}
        bodyStyle={{maxWidth: '440px'}}
      />
      {userFlow.userFlowSteps.map((userFlowStep, index) => (
        <React.Fragment key={userFlowStep.id}>
          <Space height={16} />
          <DocumentFlowDetailsDrawerStepsListItem index={index} userFlowStep={userFlowStep} />
        </React.Fragment>
      ))}
    </DrawerWithClose>
  );
};

const DocumentFlowDetailsDrawer = ({
  isOpen,
  userFlowUuid,
  handleClose,
}: {
  isOpen: boolean;
  userFlowUuid: string;
  handleClose: () => void;
}) => {
  const {loading, data} = useQuery<{userFlow: UserFlowModel}>(DocumentFlowDetailsDrawer.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: userFlowUuid,
    },
    skip: !isOpen,
  });

  if (loading || !data) {
    return null;
  }

  return (
    <DocumentFlowDetailsDrawerContent
      userFlow={data!.userFlow}
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentFlowDetailsDrawer.query = gql`
  ${DocumentFlowDetailsDrawerSettings.fragment}
  ${DocumentFlowDetailsDrawerStepsListItem.fragment}
  query DocumentFlowDetailsDrawer($uuid: String!) {
    ${gql.query}
    userFlow(uuid: $uuid) {
      id
      ...DocumentFlowDetailsDrawerSettings
      userFlowSteps {
        id
        ...DocumentFlowDetailsDrawerStepsListItem
      }
    }
  }
`;

export default DocumentFlowDetailsDrawer;
