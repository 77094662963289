// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import useSyncCommercialCatalogBillMutation from '@shared/modules/CommercialCatalog/hooks/useSyncCommercialCatalogBillMutation';

const SyncCommercialCatalogBillModal = ({
  isOpen,
  onClose,
  commercialCatalogId,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  commercialCatalogId: string;
  refetch: () => void;
}) => {
  const {submitting, handleSubmit} = useSyncCommercialCatalogBillMutation({
    commercialCatalogId,
    onSuccess: () => {
      onClose();
      refetch();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <CautionModal
      title={'Regenerate bill?'}
      message={
        `This will regenerate the equipment and materials bill ` +
        `and remove any changes you made to it. This action ` +
        `cannot be undone.`
      }
      isOpen={isOpen}
      handlePressOutside={onClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={onClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      isMobileSheet
    />
  );
};

export default SyncCommercialCatalogBillModal;
