// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ClaimTypeForm from '@shared/modules/Claim/forms/ClaimTypeForm';
import useCreateClaimTypeMutation from '@shared/modules/Claim/hooks/useCreateClaimTypeMutation';
import ClaimTypeFields from 'modules/Organization/Settings/Company/components/ClaimTypeFields';

const CreateClaimTypeHeader = () => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Create Claim Type</SmallModal.HeaderText>
      <Space height={16} />
    </React.Fragment>
  );
};

const CreateClaimTypeFooter = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        // @ts-expect-error TS(2322): Type '{ children: string; textColor: "#616161"; on... Remove this comment to see the full error message
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const CreateClaimTypeModal = ({organizationId, refetch, isOpen, handleClose}: any) => {
  const claimTypeForm = ClaimTypeForm.new({organizationId});
  const {form, submitting, handleSubmit} = useCreateClaimTypeMutation({
    claimTypeForm,
    onSuccess: ({claimType}: any) => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <CreateClaimTypeHeader />
      <Space height={4} />
      <ClaimTypeFields form={form} field={'claimTypeForm'} />
      <Space height={16} />
      <CreateClaimTypeFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

export default CreateClaimTypeModal;
