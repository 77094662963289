// Libraries
import React from 'react';

// Supermove
import {useToast} from '@supermove/hooks';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useFinalizeBulkInvoicesMutation from '@shared/modules/Billing/hooks/useFinalizeBulkInvoicesMutation';

const FinalizeBulkInvoicesModal = ({
  invoiceIds,
  isOpen,
  handleClose,
  refetch,
  resetSelectedInvoices,
}: any) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice(s) finalized`,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const {submitting, handleSubmit} = useFinalizeBulkInvoicesMutation({
    invoiceIds,
    onSuccess: () => {
      refetch();
      resetSelectedInvoices();
      handleClose();
      successToast.handleToast();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <SuccessModal
      title={'Finalize invoice(s)?'}
      subtitle={'These invoices will be locked and no further changes can be made.'}
      isOpen={isOpen}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Finalize'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

export default FinalizeBulkInvoicesModal;
