// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import JobTimetable from 'modules/App/components/JobTimetable';
import CommercialJobReportDropdown from 'modules/Job/Commercial/Complete/components/CommercialJobReportDropdown';
import CommercialReportMoveTimesheetTable from 'modules/Job/Commercial/Timesheet/components/CommercialReportMoveTimesheetTable';

const Container = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const TitleRow = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  align-items: flex-start;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionHeader = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const TitleAndBadge = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const JobTimetableContainer = Styled.View`
  align-items: stretch;
  min-width: 100%;
`;

type OwnProps = {
  job: any;
  refetch: (...args: any[]) => any;
  sectionIndex: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CommercialJobReport.defaultProps;

// @ts-expect-error TS(7022): 'CommercialJobReport' implicitly has type 'any' be... Remove this comment to see the full error message
const CommercialJobReport = ({job, viewer, refetch, sectionIndex, showHeader}: Props) => {
  const shouldShowEditReportButton = ({job, viewer}: any) => {
    return !job.isComplete || viewer.viewingOrganization.features.isEnabledCanEditCompleteJob;
  };

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container sectionIndex={sectionIndex}>
      {showHeader && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={0}>
          <TitleRow>
            <TitleAndBadge>
              <Title>Report Information</Title>
            </TitleAndBadge>
            {shouldShowEditReportButton({job, viewer}) && (
              <CommercialJobReportDropdown job={job} refetch={refetch} />
            )}
          </TitleRow>
        </Section>
      )}
      <Section
        // @ts-expect-error TS(2769): No overload matches this call.
        sectionIndex={1}
        style={{
          paddingHorizontal: 0,
        }}
      >
        <SectionHeader>
          <Label>Work Timesheet</Label>
        </SectionHeader>
        <ScrollView
          horizontal
          contentContainerStyle={{
            minWidth: '100%',
          }}
          style={{
            alignSelf: 'stretch',
          }}
        >
          <CommercialReportMoveTimesheetTable reportMove={job.reportMove} />
        </ScrollView>
      </Section>
      <SectionSpace />
      {job.jobTimetable.timesheets.length > 0 && (
        <React.Fragment>
          <Section
            // @ts-expect-error TS(2769): No overload matches this call.
            sectionIndex={3}
            style={{
              paddingHorizontal: 0,
            }}
          >
            <ScrollView
              horizontal
              contentContainerStyle={{
                minWidth: '100%',
              }}
              style={{
                alignSelf: 'stretch',
              }}
            >
              <JobTimetableContainer>
                <JobTimetable job={job} hideWorkTimesheet />
              </JobTimetableContainer>
            </ScrollView>
          </Section>
        </React.Fragment>
      )}
    </Container>
  );
};

CommercialJobReport.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialJobReport.fragment = gql`
  ${CommercialJobReportDropdown.fragment}
  ${CommercialReportMoveTimesheetTable.fragment}
  ${JobTimetable.fragment}

  fragment CommercialJobReport_Viewer on User {
    viewingOrganization {
      id
      features {
        isEnabledCanEditCompleteJob: isEnabled(feature: "CAN_EDIT_COMPLETE_JOB")
      }
    }
  }

  fragment CommercialJobReport_Job on Job {
    id
    uuid
    isComplete
    jobTimetable {
      timesheets {
        name
      }
    }
    reportMove {
      id
      isFinal
      ...CommercialReportMoveTimesheetTable
    }
    ...CommercialJobReportDropdown
    ...JobTimetable
  }
`;

export default CommercialJobReport;
