// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback, useResponsive, useSheet} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {URL, pluralize} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import SearchBar from '@shared/design/components/SearchBar';
import Sheet from '@shared/design/components/Sheet';
import Tabs from '@shared/design/components/Tabs';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityCalendarDayPickerBlock from 'modules/Calendar/Capacity/components/CapacityCalendarDayPickerBlock';
import CapacityProjectListFilters from 'modules/Calendar/Capacity/components/CapacityProjectListFilters';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const Subheading = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const SelectedDateButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ToolbarHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-bottom: 16px;
  padding-top: ${({
    // @ts-expect-error TS(2339): Property 'isDayView' does not exist on type 'Theme... Remove this comment to see the full error message
    isDayView,
  }) => (isDayView ? '20px' : '0px')};
`;

const ExpandedDispatchViewButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.gray.border)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.white)};
  padding-horizontal: 12px;
  height: ${({
    // @ts-expect-error TS(2339): Property 'height' does not exist on type 'ThemePro... Remove this comment to see the full error message
    height,
  }) => height}px;
  width: 50px;
`;

const TABS = [
  {
    label: 'Month',
    url: '/moves/capacity/calendar/month',
  },
  {
    label: 'Day',
    url: '/moves/capacity/calendar/day',
  },
];

const getSheetOptions = ({tabs}: any) => {
  // @ts-expect-error TS(7006): Parameter 'tab' implicitly has an 'any' type.
  return tabs.map((tab, index) => ({
    label: tab.label,
    value: index,
  }));
};

const getFilteredUrlFromParams = (params: any, baseUrl: any) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}: any) => {
  navigator.push(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const handleClearProjectsListFilters = ({
  baseUrl,
  navigator,
  params,
  isRestricted,
  viewerId,
}: any) => {
  navigator.replace(
    getFilteredUrlFromParams(
      {
        ...params,
        projectTypes: null,
        salespersons: isRestricted ? [viewerId] : null,
        projectTagIds: null,
        jobTagIds: null,
      },
      baseUrl,
    ),
  );
};

const SearchInput = () => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const handleUpdateQuery = useDebouncedCallback((text) => {
    handleUpdateParam({
      baseUrl: location.pathname,
      navigator,
      params,
      paramKey: 'searchQuery',
      paramValue: text,
    });
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder={responsive.desktop ? 'Search by customer, locations, or job type' : 'Search'}
      style={responsive.desktop ? {width: '400px'} : {width: '100%'}}
      defaultValue={params.query}
      isResponsive
      containerStyle={responsive.desktop ? {} : {flex: 1}}
    />
  );
};

const MonthViewCalendarControls = ({responsive, onNavigate, label}: any) => {
  return (
    <React.Fragment>
      <Space width={8} />
      <SecondaryButton onPress={() => onNavigate('TODAY')} text={'Today'} />
      <Space width={16} />
      <SecondaryButton onPress={() => onNavigate('PREV')}>
        <Icon source={Icon.ChevronLeft} color={colors.blue.interactive} size={16} />
      </SecondaryButton>
      <Space width={8} />
      <SecondaryButton onPress={() => onNavigate('NEXT')}>
        <Icon source={Icon.ChevronRight} color={colors.blue.interactive} size={16} />
      </SecondaryButton>
      <Space width={16} />
      <Subheading responsive={responsive}>{label}</Subheading>
    </React.Fragment>
  );
};

const DayViewControls = ({
  responsive,
  totalJobsForDay,
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
}: any) => {
  return (
    <React.Fragment>
      {totalJobsForDay === null ? (
        <SkeletonLoader width={50} height={SkeletonLoader.HEIGHT.SubheadingText} />
      ) : (
        <Subheading responsive={responsive}>{pluralize('Job', totalJobsForDay, true)}</Subheading>
      )}
      <Space width={16} />
      <SearchInput />
      <Space width={16} />
      <CapacityProjectListFilters
        handleUpdateParam={handleUpdateParam}
        organization={organization}
        handleClearProjectsListFilters={handleClearProjectsListFilters}
      />
    </React.Fragment>
  );
};

const DispatchButton = ({isJobsListTableExpanded, setIsJobsListTableExpanded, responsive}: any) => {
  return (
    <React.Fragment>
      <ExpandedDispatchViewButton
        height={responsive.desktop ? 36 : 48}
        isSelected={isJobsListTableExpanded}
        onPress={() => setIsJobsListTableExpanded(!isJobsListTableExpanded)}
      >
        <Icon
          source={Icon.Truck}
          size={16}
          color={isJobsListTableExpanded ? colors.white : colors.gray.tertiary}
        />
      </ExpandedDispatchViewButton>
      <Space width={8} />
    </React.Fragment>
  );
};

const CalendarViewTabs = ({tabs, currentTabIndex, navigator, params}: any) => {
  return (
    <Tabs<{url: string}>
      tabs={tabs}
      handlePressTab={(tab) => navigator.push(URL.getUrlFromVariables(tab.url, params))}
      activeTabIndex={currentTabIndex}
      isEnclosed
    />
  );
};

const CalendarViewPicker = ({tabs, currentTabIndex, navigator, params}: any) => {
  const selectedCapacityCalendarViewSheet = useSheet({
    name: 'Selected Capacity Calendar View Sheet',
  });
  return (
    <React.Fragment>
      <TertiaryButton
        onPress={selectedCapacityCalendarViewSheet.handleOpen}
        text={`View: ${tabs[currentTabIndex].label}`}
        iconRight={Icon.AngleDown}
        iconSize={16}
        isResponsive
      />
      <Sheet.PreventPropagationContainer>
        <DropdownSheet
          key={selectedCapacityCalendarViewSheet.key}
          isOpen={selectedCapacityCalendarViewSheet.isOpen}
          handleClose={selectedCapacityCalendarViewSheet.handleClose}
          headerText={'View'}
          options={getSheetOptions({tabs})}
          value={currentTabIndex}
          onChangeValue={(index: any) => {
            navigator.push(URL.getUrlFromVariables(tabs[index].url, params));
          }}
          isSearchable={false}
        />
      </Sheet.PreventPropagationContainer>
    </React.Fragment>
  );
};

const MobileDayViewHeader = ({
  label,
  selectedDate,
  setSelectedDate,
  organization,
  tabs,
  currentTabIndex,
  dayNotesSheet,
}: any) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const dateSelectorSheet = useSheet({name: 'Selected Date Sheet'});
  return (
    <React.Fragment>
      <Space height={12} />
      <Row>
        <SelectedDateButton onPress={dateSelectorSheet.handleOpen}>
          <Subheading responsive={responsive}>{label}</Subheading>
          <Space width={8} />
          <Icon source={Icon.AngleDown} size={16} color={colors.gray.secondary} />
        </SelectedDateButton>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Space flex={1} minWidth={16} />
        <CalendarViewPicker
          tabs={tabs}
          currentTabIndex={currentTabIndex}
          navigator={navigator}
          params={params}
        />
      </Row>
      <Space height={12} />
      <Row>
        <SearchInput />
        <Space width={16} />
        <CapacityProjectListFilters
          handleUpdateParam={handleUpdateParam}
          organization={organization}
          handleClearProjectsListFilters={handleClearProjectsListFilters}
        />
        <Space width={16} />
        <SecondaryButton
          onPress={dayNotesSheet.handleOpen}
          iconLeft={Icon.StickyNote}
          iconSize={16}
          isResponsive
        />
      </Row>
      <Space height={12} />
      <Sheet.PreventPropagationContainer>
        <Sheet
          isOpen={dateSelectorSheet.isOpen}
          handleClose={dateSelectorSheet.handleClose}
          headerText={''}
        >
          <Row
            style={{
              backgroundColor: colors.gray.background,
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CapacityCalendarDayPickerBlock
              selectedDate={selectedDate}
              setSelectedDate={(date: any) => {
                setSelectedDate(date);
                dateSelectorSheet.handleClose();
              }}
              organization={organization}
            />
          </Row>
        </Sheet>
      </Sheet.PreventPropagationContainer>
    </React.Fragment>
  );
};

const DesktopHeader = ({
  isDayView,
  totalJobsForDay,
  handleUpdateParam,
  organization,
  handleClearProjectsListFilters,
  isJobsListTableExpanded,
  setIsJobsListTableExpanded,
  onNavigate,
  label,
  organizations,
  currentTabIndex,
  canViewOtherBranchesData,
}: any) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ToolbarHeader isDayView={isDayView}>
      <Row>
        {isDayView ? (
          <DayViewControls
            responsive={responsive}
            totalJobsForDay={totalJobsForDay}
            handleUpdateParam={handleUpdateParam}
            organization={organization}
            handleClearProjectsListFilters={handleClearProjectsListFilters}
          />
        ) : (
          <MonthViewCalendarControls
            responsive={responsive}
            onNavigate={onNavigate}
            label={label}
          />
        )}
      </Row>
      <Space flex={1} style={{minWidth: 16}} />
      <Row>
        {isDayView && responsive.desktop && (
          <DispatchButton
            responsive={responsive}
            isJobsListTableExpanded={isJobsListTableExpanded}
            setIsJobsListTableExpanded={setIsJobsListTableExpanded}
          />
        )}
        <CalendarViewTabs
          tabs={TABS}
          currentTabIndex={currentTabIndex}
          navigator={navigator}
          params={params}
        />
        <Space width={8} />
        {canViewOtherBranchesData && (
          <MultiBranchOrganizationField
            value={params.slugs || []}
            organizations={organizations}
            onChangeValue={(newSlugs: any) => {
              handleUpdateParam({
                baseUrl: location.pathname,
                navigator,
                params,
                paramKey: 'slugs',
                paramValue: newSlugs,
              });
            }}
          />
        )}
      </Row>
    </ToolbarHeader>
  );
};

const CapacityCalendarToolbarHeader = ({
  onNavigate,
  label,
  isDayView,
  organization,
  canViewOtherBranchesData,
  isJobsListTableExpanded,
  totalJobsForDay,
  setIsJobsListTableExpanded,
  selectedDate,
  setSelectedDate,
  dayNotesSheet,
}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const organizations = _.get(organization, 'company.organizations', []);
  const currentTabIndex = _.findIndex(TABS, (tab) => navigator.location.pathname.includes(tab.url));

  if (isDayView && !responsive.desktop) {
    return (
      <MobileDayViewHeader
        label={label}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        organization={organization}
        tabs={TABS}
        currentTabIndex={currentTabIndex}
        dayNotesSheet={dayNotesSheet}
      />
    );
  }

  return (
    <DesktopHeader
      isDayView={isDayView}
      totalJobsForDay={totalJobsForDay}
      handleUpdateParam={handleUpdateParam}
      organization={organization}
      handleClearProjectsListFilters={handleClearProjectsListFilters}
      isJobsListTableExpanded={isJobsListTableExpanded}
      setIsJobsListTableExpanded={setIsJobsListTableExpanded}
      onNavigate={onNavigate}
      label={label}
      organizations={organizations}
      currentTabIndex={currentTabIndex}
      canViewOtherBranchesData={canViewOtherBranchesData}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarToolbarHeader.fragment = gql`
  ${CapacityProjectListFilters.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment CapacityCalendarToolbarHeader on Organization {
    id
    isPrimary
    hasMultipleOrganizations
    ...CapacityProjectListFilters
    ...MultiBranchOrganizationField
  }
`;

export default CapacityCalendarToolbarHeader;
