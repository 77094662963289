// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive, ResponsiveType, UrlFiltersType} from '@supermove/hooks';
import {DayModel, Organization, OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import Sheet from '@shared/design/components/Sheet';
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpsertDayMutation from '@shared/modules/Scheduling/hooks/useUpsertDayMutation';
import AddDayNotesPopover from 'modules/App/Day/components/AddDayNotesPopover';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import CapacityCalendarDaySlotMetrics from 'modules/Calendar/Capacity/components/CapacityCalendarDaySlotMetrics';
import MobileCapacityCalendarDaySlotMetrics from 'modules/Calendar/Capacity/components/MobileCapacityCalendarDaySlotMetrics';

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const DayNotesRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const DayDetailsMetricsContainer = Styled.View`
  height: 150px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const MobileDayDetailsMetricsContainer = Styled.View`
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const NotesContainer = Styled.View`
  flex: 1;
`;

const DayNotesSectionContainer = Styled.ButtonV2`
  flex: 1;
`;

const NotesContentContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding: 12px;
  border-radius: 4px;
  flex-basis: auto;
  border: 1px solid ${colors.gray.border};
  background-color: ${({responsive}) =>
    responsive.desktop ? colors.gray.background : colors.white};
`;

const DayNotesText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const DayNotesHeading = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.secondary};
`;

const LastUpdatedText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const SheetNotesInputContainer = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const DayNotesSection = ({
  index,
  day,
  organization,
  viewingOrganization,
  selectedDate,
  refetchDayView,
  urlFilters,
}: any) => {
  const responsive = useResponsive();
  const organizationId = organization.id;
  const addDayNotesPopover = usePopover();
  const dayForm = DayForm.upsert(day, {organizationId, value: selectedDate});
  const {form, handleSubmit, submitting} = useUpsertDayMutation({
    dayForm,
    onSuccess: () => {
      addDayNotesPopover.handleClose();
      refetchDayView();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const isBlockEditForMainBranch = !viewingOrganization.isPrimary && organization.isPrimary;

  return (
    <Popover.Content innerRef={addDayNotesPopover.ref}>
      <DayNotesSectionContainer
        disabled={isBlockEditForMainBranch}
        onPress={() => {
          if (urlFilters) {
            urlFilters.handleUpdate({dayNotesOrganizationSlug: organization.slug});
          } else {
            addDayNotesPopover.handleOpen();
          }
        }}
      >
        <DayNotesRow>
          {responsive.desktop && (
            <React.Fragment>
              <Icon source={Icon.StickyNote} color={colors.gray.secondary} size={12} />
              <Space width={4} />
            </React.Fragment>
          )}
          <DayNotesHeading responsive={responsive}>{organization.name}</DayNotesHeading>
          <Space style={{flex: 1}} />
          {!isBlockEditForMainBranch && <IconButton source={Icon.Pen} isDisplayOnly />}
        </DayNotesRow>
        <Space height={4} />
        {day && day.notes ? (
          <DayNotesText responsive={responsive}>{day.notes}</DayNotesText>
        ) : (
          <DayNotesText responsive={responsive} style={{color: colors.gray.tertiary}}>
            Click to add day notes.
          </DayNotesText>
        )}
        <Space height={4} />
        {day && (
          <LastUpdatedText>{`Last updated: ${Datetime.convertToDisplayDatetime(day.updatedAt)}${
            day.notesUpdatedBy ? ` by ${day.notesUpdatedBy.fullName}` : ''
          }`}</LastUpdatedText>
        )}
        <AddDayNotesPopover
          key={`${addDayNotesPopover.isOpen}_${index}`}
          popover={addDayNotesPopover}
          width={400}
          form={form}
          handleSubmit={handleSubmit}
          submitting={submitting}
          isClosableOutside
          offset={[270, 8]}
          organization={organization}
          isMultibranchDayView
        />
      </DayNotesSectionContainer>
    </Popover.Content>
  );
};

const EditDayNotes = ({
  day,
  organization,
  selectedDate,
  urlFilters,
  refetch,
}: {
  day: DayModel;
  organization: OrganizationModel;
  selectedDate: Date;
  urlFilters: UrlFiltersType;
  refetch: () => void;
}) => {
  const dayForm = DayForm.upsert(day, {organizationId: organization.id, value: selectedDate});
  const {form, handleSubmit, submitting} = useUpsertDayMutation({
    dayForm,
    onSuccess: () => {
      refetch();
      urlFilters.handleUpdate({dayNotesOrganizationSlug: undefined});
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <SheetNotesInputContainer>
        <FieldInput
          {...form}
          label={organization.name}
          name={'dayForm.notes'}
          input={{
            placeholder: 'Enter day notes.',
            style: {flex: 1, paddingTop: 8},
            multiline: true,
          }}
          style={{flex: 1}}
          isResponsive
        />
      </SheetNotesInputContainer>
      <Space height={16} />
      <Line />
      <Space height={16} />
      <Button
        text={'Save'}
        onPress={handleSubmit}
        isWidthOfContainer
        isResponsive
        style={{marginHorizontal: 16}}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const DayMetricsAndNotesSheet = ({
  dayNotesSheet,
  selectedDateText,
  params,
  isLoading,
  viewingOrganization,
  capacityCalendarSingleDay,
  days,
  selectedDate,
  refetchDayView,
  urlFilters,
}: any) => {
  const dayNotesOrganizationSlug = urlFilters?.get('dayNotesOrganizationSlug');
  const dayNotesOrganization =
    dayNotesOrganizationSlug &&
    _.find(viewingOrganization?.company.organizations, {slug: dayNotesOrganizationSlug});
  const dayNotesOrganizationDay =
    dayNotesOrganization && _.find(days, {organization: {id: dayNotesOrganization.id}});

  return (
    <Sheet.PreventPropagationContainer>
      <Sheet
        isOpen={dayNotesSheet.isOpen}
        handleClose={() => {
          urlFilters?.handleUpdate({dayNotesOrganizationSlug: undefined});
          dayNotesSheet.handleClose();
        }}
        headerText={dayNotesOrganization ? 'Edit Day Notes' : selectedDateText}
        description={
          dayNotesOrganization
            ? `Visible to the main branch${dayNotesOrganization.isPrimary ? '.' : ` and ${dayNotesOrganization.name}.`}`
            : undefined
        }
        isFixedHeight
        handleGoBack={
          dayNotesOrganizationSlug
            ? () => {
                urlFilters?.handleUpdate({dayNotesOrganizationSlug: undefined});
              }
            : undefined
        }
      >
        {dayNotesOrganization && dayNotesOrganizationDay ? (
          <EditDayNotes
            refetch={refetchDayView}
            day={dayNotesOrganizationDay}
            organization={dayNotesOrganization}
            selectedDate={selectedDate}
            urlFilters={urlFilters}
          />
        ) : (
          <ScrollView
            contentContainerStyle={{
              backgroundColor: colors.gray.background,
              paddingHorizontal: 16,
              paddingVertical: 8,
            }}
          >
            <DayMetricsAndNotes
              isLoading={isLoading}
              viewingOrganization={viewingOrganization}
              capacityCalendarSingleDay={capacityCalendarSingleDay}
              days={days}
              selectedDate={selectedDate}
              params={params}
              refetchDayView={refetchDayView}
              urlFilters={urlFilters}
            />
          </ScrollView>
        )}
      </Sheet>
    </Sheet.PreventPropagationContainer>
  );
};

const DayMetricsAndNotes = ({
  isLoading,
  viewingOrganization,
  capacityCalendarSingleDay,
  days,
  selectedDate,
  params,
  refetchDayView,
  urlFilters,
}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {responsive.desktop ? (
        <DayDetailsMetricsContainer responsive={responsive}>
          <CapacityCalendarDaySlotMetrics
            capacityCalendar={capacityCalendarSingleDay}
            cellDate={selectedDate}
            shouldHideDayNotes
            isLoading={isLoading}
          />
        </DayDetailsMetricsContainer>
      ) : (
        <MobileDayDetailsMetricsContainer>
          <MobileCapacityCalendarDaySlotMetrics
            capacityCalendar={capacityCalendarSingleDay}
            cellDate={selectedDate}
            shouldHideDayNotes
            isLoading={isLoading}
          />
        </MobileDayDetailsMetricsContainer>
      )}
      <Space height={16} />
      <Row>
        {isLoading || !params.slugs || !viewingOrganization ? (
          <NotesContentContainer responsive={responsive}>
            <SkeletonLoader height={72} isFullWidth />
          </NotesContentContainer>
        ) : (
          <NotesContainer>
            {
              // We need to loop through each org instead of days because a day object is not always created
              // For Franchise/Branch orgs, we will filter it out
              (Organization as any)
                .getOrganizationBySlugsWithParent({
                  slugs: params.slugs,
                  organization: viewingOrganization,
                })
                .map((organization: any, index: any) => {
                  const organizationDay = days.find(
                    (day: any) => day.organization.id === organization.id,
                  );
                  return (
                    <React.Fragment key={index}>
                      <NotesContentContainer responsive={responsive}>
                        <DayNotesSection
                          index={index}
                          refetchDayView={refetchDayView}
                          day={organizationDay}
                          organization={organization}
                          viewingOrganization={viewingOrganization}
                          selectedDate={selectedDate}
                          urlFilters={urlFilters}
                        />
                        <Space width={8} />
                      </NotesContentContainer>
                      <Space height={12} />
                    </React.Fragment>
                  );
                })
            }
          </NotesContainer>
        )}
      </Row>
      <Space height={48} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayMetricsAndNotes.fragment = gql`
  ${AddDayNotesPopover.fragment}
  ${CapacityCalendarDaySlotMetrics.fragment}
  ${DayForm.edit.fragment}
  ${Organization.getOrganizationBySlugsWithParent.fragment}

  fragment DayMetricsAndNotes_Organization on Organization {
    id
    isPrimary
    company {
      id
      organizations {
        id
        isPrimary
        name
        ...AddDayNotesPopover
      }
    }
    ...Organization_getOrganizationBySlugsWithParent
  }

  fragment DayMetricsAndNotes_CapacityCalendar on CapacityCalendar {
    ...CapacityCalendarDaySlotMetrics
  }

  fragment DayMetricsAndNotes_Day on Day {
    id
    organization {
      id
    }
    updatedAt
    notesUpdatedBy {
      id
      fullName
    }
    ...DayForm_edit
  }
`;

DayMetricsAndNotes.Sheet = DayMetricsAndNotesSheet;

export default DayMetricsAndNotes;
