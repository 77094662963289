// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useRef, useResponsive, useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import EmptyState from '@shared/design/components/EmptyState';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import JobActionDisabledTooltip from 'modules/Project/V2/Show/Blocks/Job/components/JobActionDisabledTooltip';
import JobLocationsList from 'modules/Project/V2/Show/Blocks/Job/components/JobLocationsList';
import LocationsMap from 'modules/Project/V2/Show/Blocks/components/LocationsMap';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const MAP_MIN_HEIGHT = 320;
const ROUTE_VIEW = 'ROUTE_VIEW';
const MAP_VIEW = 'MAP_VIEW';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const handleEditStops = ({navigator, params, job}: any) =>
  Job.goToEditProjectJobs(job, navigator, {
    projectUuid: params.projectUuid,
    block: ProjectBlockKind.Job.STOPS,
  });

const SkeletonBody = () => {
  return (
    <Row>
      <Column style={{flex: 2}}>
        <JobLocationsList.SkeletonComponent />
      </Column>
      <Space width={24} />
      <Column style={{flex: 1}}>
        <LocationsMap.SkeletonComponent height={MAP_MIN_HEIGHT} />
      </Column>
    </Row>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={136} />
      )}
      title={'Stops'}
      style={{width: '100%'}}
    />
  );
};

const EmptyBody = ({job}: any) => {
  const {navigator, params} = useNavigationDOM();

  return (
    <EmptyState
      icon={Icon.MapMarkedAlt}
      title={'No stops.'}
      message={'Add a stop and it will appear here.'}
      primaryActionIcon={Icon.Plus}
      primaryActionText={'Add Stop'}
      handlePrimaryAction={() => handleEditStops({navigator, params, job})}
      paddingVertical={64}
    />
  );
};

const EditButton = ({job}: any) => {
  const {navigator, params} = useNavigationDOM();
  return (
    <JobActionDisabledTooltip job={job}>
      <Button
        iconLeft={Icon.Pen}
        text={'Edit Stops'}
        isSmall
        onPress={() => handleEditStops({navigator, params, job})}
        isDisabled={job.isFinal}
      />
    </JobActionDisabledTooltip>
  );
};

const JobLocationsRoute = ({job, mapRef, locationIdsWithStreetView, refetch}: any) => {
  return (
    <Column style={{flex: 2}}>
      <JobLocationsList
        job={job}
        mapRef={mapRef}
        locationIdsWithStreetView={locationIdsWithStreetView}
        refetch={refetch}
      />
    </Column>
  );
};

const JobLocationsMap = ({job, isReadyForMap, mapRef, setLocationIdsWithStreetView}: any) => {
  return (
    <Column style={{flex: 1, minHeight: MAP_MIN_HEIGHT, borderRadius: 4, overflow: 'hidden'}}>
      {isReadyForMap ? (
        <LocationsMap
          job={job}
          locations={job.locations}
          organization={job.project.owningOrganization}
          mapRef={mapRef}
          setLocationIdsWithStreetView={setLocationIdsWithStreetView}
          showRoute
        />
      ) : (
        <SkeletonLoader height={MAP_MIN_HEIGHT} isFullWidth />
      )}
    </Column>
  );
};

const DesktopJobLocationsContent = ({
  job,
  isReadyForMap,
  locationIdsWithStreetView,
  setLocationIdsWithStreetView,
  mapRef,
  refetch,
}: any) => {
  return (
    <Row>
      <JobLocationsRoute
        job={job}
        mapRef={mapRef}
        locationIdsWithStreetView={locationIdsWithStreetView}
        refetch={refetch}
      />
      <Space width={24} />
      <JobLocationsMap
        job={job}
        isReadyForMap={isReadyForMap}
        mapRef={mapRef}
        setLocationIdsWithStreetView={setLocationIdsWithStreetView}
      />
    </Row>
  );
};

const MobileJobLocationsBlockContent = ({
  job,
  project,
  mapRef,
  isReadyForMap,
  removeLocationId,
  setRemoveLocationId,
  selectedView,
  setSelectedView,
  locationIdsWithStreetView,
  setLocationIdsWithStreetView,
  refetch,
  responsive,
  description,
}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Stops (${job.locations.length})`}
        ActionsComponent={() => (
          <JobActionDisabledTooltip job={job}>
            <MobileProjectBlockHeader.TextButton
              text={'Edit'}
              isDisabled={job.isFinal}
              onPress={() =>
                navigator.push(
                  `/projects/${project.uuid}/edit/jobs?jobUuid=${job.uuid}&block=${ProjectBlockKind.Job.STOPS}`,
                )
              }
            />
          </JobActionDisabledTooltip>
        )}
      >
        <Space height={4} />
        <DescriptionText responsive={responsive}>{description}</DescriptionText>
      </MobileProjectBlockHeader>
      <Row>
        <ContextSwitcher
          isFullWidth
          contextDefinitions={[
            {
              label: `Route`,
              isSelected: selectedView === ROUTE_VIEW,
              onPress: () => {
                setSelectedView(ROUTE_VIEW);
              },
            },
            {
              label: `Map`,
              isSelected: selectedView === MAP_VIEW,
              onPress: () => {
                setSelectedView(MAP_VIEW);
              },
            },
          ]}
        />
      </Row>
      <Space height={16} />
      {selectedView === MAP_VIEW ? (
        <JobLocationsMap
          key={removeLocationId}
          job={job}
          isReadyForMap={isReadyForMap}
          mapRef={mapRef}
          setLocationIdsWithStreetView={setLocationIdsWithStreetView}
          removeLocationId={removeLocationId}
          setRemoveLocationId={setRemoveLocationId}
          refetch={refetch}
        />
      ) : (
        <JobLocationsRoute
          job={job}
          mapRef={mapRef}
          locationIdsWithStreetView={locationIdsWithStreetView}
          removeLocationId={removeLocationId}
          setRemoveLocationId={setRemoveLocationId}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

const JobLocationsBlock = ({
  project,
  jobUuid,
  handleSetPositionY,
  layoutKey,
  isReadyForMap,
  index,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();
  const mapRef = useRef();
  const [removeLocationId, setRemoveLocationId] = useState();
  const [selectedView, setSelectedView] = useState(ROUTE_VIEW);
  const [locationIdsWithStreetView, setLocationIdsWithStreetView] = useState([]);

  const contentProps = {
    project,
    isReadyForMap,
    mapRef,
    removeLocationId,
    setRemoveLocationId,
    selectedView,
    setSelectedView,
    locationIdsWithStreetView,
    setLocationIdsWithStreetView,
    responsive,
  };

  return (
    <ProjectBlockWrapper
      key={project.owningOrganization.id}
      index={index}
      query={JobLocationsBlock.query}
      queryVariables={{jobUuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data: {job}, refetch}: any) => {
        const milesString = `${pluralize('mile', _.round(job.totalDistance, 2), true)} total`;
        const stopsAndMilesString = `${pluralize(
          'stop',
          job.locations.length,
          true,
        )} (${milesString})`;

        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ActionPanel
                BodyComponent={DesktopJobLocationsContent}
                bodyComponentProps={{job, refetch, ...contentProps}}
                EmptyBodyComponent={EmptyBody}
                emptyBodyComponentProps={{job}}
                ActionButtonComponent={EditButton}
                actionButtonComponentProps={{job}}
                title={'Stops'}
                description={stopsAndMilesString}
                style={{flex: 1, width: undefined}}
                isEmpty={_.isEmpty(job.locations)}
              />
            ) : (
              <MobileJobLocationsBlockContent
                job={job}
                refetch={refetch}
                description={milesString}
                {...contentProps}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsBlock.listener = gql`
  fragment JobLocationsBlock_listener on Project {
    id
    owningOrganization {
      id
    }
  }
`;

JobLocationsBlock.fragment = gql`
  ${JobLocationsBlock.listener}
  fragment JobLocationsBlock on Project {
    id
    uuid
    ...JobLocationsBlock_listener
  }
`;

JobLocationsBlock.query = gql`
  ${JobLocationsList.fragment}
  ${Job.goToEditProjectJobs.fragment}
  ${LocationsMap.fragment}
  ${JobLocationsBlock.listener}
  ${JobActionDisabledTooltip.fragment}

  query JobLocationsBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      isFinal
      totalDistance
      locationDistances
      locations {
        id
        ...LocationsMap_Location
      }
      project {
        id
        owningOrganization {
          id
          ...LocationsMap_Organization
        }
        ...JobLocationsBlock_listener
      }
      warehouseLocation {
        id
        ...LocationsMap_Location
      }
      ...JobLocationsList
      ...Job_goToEditProjectJobs
      ...JobActionDisabledTooltip
    }
  }
`;

export default JobLocationsBlock;
