// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import ProjectNotFoundPage from 'modules/Customer/Project/components/ProjectNotFoundPage';
import CustomerPage from 'modules/Customer/components/CustomerPage';

const ProjectPage = ({
  fetchPolicy,
  pollInterval,
  query,
  right,
  subtitle,
  title,
  variables,
  children,
}: any) => (
  <CustomerPage
    fetchPolicy={fetchPolicy}
    pollInterval={pollInterval}
    query={query}
    right={right}
    subtitle={subtitle}
    title={title}
    variables={variables}
  >
    {(props: any) => (props.data.project ? children(props) : <ProjectNotFoundPage />)}
  </CustomerPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectPage.fragment = gql`
  fragment ProjectPage on Project {
    id
  }
`;

export default ProjectPage;
