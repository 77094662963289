// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import useBookingCalendarContext from 'modules/Calendar/Booking/context/useBookingCalendarContext';

import BookingCalendarJobItem from './BookingCalendarJobItem';

const Container = Styled.View`
  flex: 1;
  padding-horizontal: 7px;
  min-width: 180px;
`;

const Content = Styled.View`
`;

const sortJobs = ({jobs}: any) => {
  return _.sortBy(jobs, ['startTime1', 'startTime2']);
};

const JobItems = ({jobs, date, refetch}: any) => (
  <Content>
    {sortJobs({jobs}).map((job) => (
      <BookingCalendarJobItem key={job.id} job={job} date={date} refetch={refetch} />
    ))}
  </Content>
);

const BookingCalendarWeekDay = ({calendarDay, refetch}: any) => {
  const {columnMinWidth} = useBookingCalendarContext();
  return (
    <Container style={{minWidth: columnMinWidth}}>
      <Space height={10} />
      <JobItems jobs={calendarDay.jobs} date={calendarDay.date} refetch={refetch} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeekDay.fragment = gql`
  ${BookingCalendarJobItem.fragment}

  fragment BookingCalendarWeekDay on CalendarDayV4 {
    date
    jobs {
      id
      ...BookingCalendarJobItem
    }
  }
`;

export default BookingCalendarWeekDay;
