// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useRef, useResponsive} from '@supermove/hooks';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import InventoryConfirmationForm from 'modules/Customer/Project/Confirmation/Inventory/components/InventoryConfirmationForm';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import DocumentPdfView from 'modules/Document/components/DocumentPdfView';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => ((props as any).mobile ? '100%' : '796px')};
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const Section = Styled.View`
  padding-horizontal: 20px;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const getSubtitleText = () => {
  return (
    'Please read through the inventory items below.\n' +
    'Afterwards, sign at the bottom and press "Continue".'
  );
};

const isCorrectPage = ({project}: any) => {
  return (
    _.get(project, 'confirmation.nextStep.kind') ===
    ConfirmationStepKind.CONFIRMATION_STEP_SIGN_INVENTORY
  );
};

const onMountOrUpdate = ({navigator, project}: any) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}: any) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const InventoryConfirmationProjectPageContent = ({project, refetch}: any) => {
  const responsive = useResponsive();
  const scrollView = useRef();

  return (
    <Wrapper>
      <ScrollView
        ref={scrollView}
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center',
        }}
      >
        <Container {...responsive}>
          <Content>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Section sectionIndex={0}>
              <ConfirmationStepHeader
                title={'Inventory Review'}
                subtitle={getSubtitleText()}
                confirmation={project.confirmation}
              />
            </Section>
            <SectionSpace />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Section sectionIndex={1}>
              <InventoryConfirmationForm confirmation={project.confirmation} />
            </Section>
          </Content>
        </Container>
      </ScrollView>
    </Wrapper>
  );
};

const InventoryConfirmationProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => {
  return (
    <ProjectPage variables={{uuid}} query={InventoryConfirmationProjectPage.query}>
      {({navigator, data, refetch}: any) => (
        <Redirect navigator={navigator} project={data.project}>
          <InventoryConfirmationProjectPageContent project={data.project} refetch={refetch} />
        </Redirect>
      )}
    </ProjectPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryConfirmationProjectPage.query = gql`
  ${InventoryConfirmationForm.fragment}
  ${ConfirmationStepHeader.fragment}
  ${DocumentPdfView.fragment}
  ${ProjectPage.fragment}

  query InventoryConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      confirmation {
        id
        nextStep {
          kind
          value
        }
        ...InventoryConfirmationForm
        ...ConfirmationStepHeader
      }
      organization {
        id
        slug
        name
      }
      ...ProjectPage
    }
  }
`;

export default InventoryConfirmationProjectPage;
