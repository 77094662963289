// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Project} from '@supermove/models';

// App
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import CreateProjectQuoteSuccessModal from 'modules/Project/Quote/components/CreateProjectQuoteSuccessModal';
import ProjectPageWithNavigation from 'modules/Project/components/ProjectPageWithNavigation';
import ProjectQuoteAndConfirmationPageContent from 'modules/Project/components/ProjectQuoteAndConfirmationPageContent';

const QuoteProjectPage = ({
  match: {
    params: {uuid},
  },
}: any) => {
  const createQuoteSuccessModal = useModal({name: 'Create Quote Success Modal'});
  return (
    <ProjectPageWithNavigation
      fetchPolicy={'network-only'}
      query={QuoteProjectPage.query}
      variables={{uuid}}
      title={({loading, data}: any) => (loading ? 'Loading...' : Project.getName(data.project))}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({data}: any) => (
        <React.Fragment>
          <ProjectQuoteAndConfirmationPageContent
            viewerId={data.viewer.id}
            project={data.project}
            onSuccess={createQuoteSuccessModal.handleOpen}
            kind={ConfirmationStepsKind.QUOTE}
            pageTitle={'Prepare & Send Quote'}
            pageSubtitle={
              'Please configure the steps required in the quote process and draft an email to send.'
            }
            emailTemplate={data.project.projectType.quoteConfirmationSteps.emailTemplate}
          />
          <CreateProjectQuoteSuccessModal
            isOpen={createQuoteSuccessModal.isOpen}
            project={data.project}
            handleClose={createQuoteSuccessModal.handleClose}
          />
        </React.Fragment>
      )}
    </ProjectPageWithNavigation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
QuoteProjectPage.query = gql`
  ${ProjectPageWithNavigation.fragment}
  ${ProjectQuoteAndConfirmationPageContent.fragment}
  ${CreateProjectQuoteSuccessModal.fragment}
  ${Project.getName.fragment}

  query QuoteProjectPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    project(uuid: $uuid) {
      id
      projectType {
        id
        quoteConfirmationSteps: confirmationStepsByKind(kind: "QUOTE"){
          id
          emailTemplate {
            id
            ...ProjectQuoteAndConfirmationPageContent_EmailTemplate
          }
        }
      }
      ...CreateProjectQuoteSuccessModal
      ...ProjectPageWithNavigation
      ...Project_getName
      ...ProjectQuoteAndConfirmationPageContent_Project
    }
  }
`;

export default QuoteProjectPage;
