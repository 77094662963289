// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// App
import UpdateSuppliesMutation from './UpdateSuppliesMutation';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const Indicator = Styled.Loading`
  margin-left: 5px;
`;

/**
 * Functional method to remove a supply from an array of supplies.
 * TODO(peter): find a way to functionally do this without using cloneDeep
 *
 * @param {string} object.index The supply to remove
 * @param {string[]} object.organizationSuppliesForm The array of supplies to remove from
 */
const removeSupplyFromSupplies = ({index, organizationSuppliesForm}: any) => {
  const copy = _.cloneDeep(organizationSuppliesForm);
  const newArray = copy.suppliesForm.supplyForms.filter(
    (v: any, currIndex: any) => index !== currIndex,
  );
  copy.suppliesForm.supplyForms = newArray;
  return copy;
};

type OwnDeleteSupplyModalProps = {};

// @ts-expect-error TS(2456): Type alias 'DeleteSupplyModalProps' circularly ref... Remove this comment to see the full error message
type DeleteSupplyModalProps = OwnDeleteSupplyModalProps & typeof DeleteSupplyModal.defaultProps;

// @ts-expect-error TS(7022): 'DeleteSupplyModal' implicitly has type 'any' beca... Remove this comment to see the full error message
const DeleteSupplyModal = ({
  organizationSuppliesForm,
  index,
  refetch,
  trigger,
}: DeleteSupplyModalProps) => (
  <Modal onClose={refetch} trigger={trigger}>
    {({handleClose}: any) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'wastebasket'} />
          </Circle>
          <Content>
            <Title>Delete Supply</Title>
            <Subtitle>
              {`Are you sure you want to delete "${_.get(
                organizationSuppliesForm,
                `suppliesForm.supplyForms.${index}.name`,
              )}"?`}
            </Subtitle>
            <Actions>
              <Button color={colors.gray.tertiary} onPress={handleClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <UpdateSuppliesMutation
                organizationSuppliesForm={removeSupplyFromSupplies({
                  index,
                  organizationSuppliesForm,
                })}
                onSuccess={handleClose}
              >
                {({loading, handleSubmit}: any) => (
                  <Button color={colors.red.warning} onPress={handleSubmit}>
                    {loading ? (
                      // @ts-expect-error TS(2769): No overload matches this call.
                      <Indicator size={'small'} colors={colors.gray.border} />
                    ) : (
                      <Text>Confirm</Text>
                    )}
                  </Button>
                )}
              </UpdateSuppliesMutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

DeleteSupplyModal.defaultProps = {};

export default DeleteSupplyModal;
