// Libraries
import React from 'react';

import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// Components

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SplitPage.defaultProps;

// @ts-expect-error TS(7022): 'SplitPage' implicitly has type 'any' because it d... Remove this comment to see the full error message
const SplitPage = ({split, children, style}: Props) => {
  const {mobile} = useResponsive();

  return (
    <Container style={style}>
      {children}
      {!mobile && split}
    </Container>
  );
};

SplitPage.defaultProps = {};

export default SplitPage;
