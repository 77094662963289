// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PayrollReportKind from '@shared/modules/Accounting/enums/PayrollReportKind';
import AccountingPageHeader from 'modules/Accounting/components/AccountingPageHeader';
import ListPayrollReportsPageContent from 'modules/Accounting/components/ListPayrollReportsPageContent';
import ListPayrollReportsPageContentV2 from 'modules/Accounting/components/ListPayrollReportsPageContentV2';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const Container = Styled.View`
  flex: 1;
`;

const BASE_ROUTE = `/accounting/employee-payroll`;

const getVariablesFromParams = (params: any) => {
  return {
    kind: PayrollReportKind.ALL_EMPLOYEE,
    pagination: {
      page: 1,
      resultsPerPage: 10,
    },
    ...params,
  };
};

const ListPayrollReportsPage = () => {
  const {navigator, params} = useNavigationDOM();
  const variables = getVariablesFromParams(params);
  const {loading, data} = useQuery(ListPayrollReportsPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  // If required params are not set in the route on mount,
  // reload the page with the correct variables
  if (!params.kind || !params.pagination) {
    navigator.replace(URL.getUrlFromVariables(BASE_ROUTE, variables));
  }

  if (loading) {
    return (
      <SidebarPageV2 selected={'accounting'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  return (
    <SidebarPageV2 selected={'accounting'}>
      <Container>
        <AccountingPageHeader organization={data.viewer.viewingOrganization} />
        {data.viewer.viewingOrganization.features.isEnabledJobLevelCosts ? (
          <ListPayrollReportsPageContentV2
            organization={data.viewer.viewingOrganization}
            baseRoute={`/accounting/employee-payroll`}
            payrollReportKind={PayrollReportKind.ALL_EMPLOYEE}
          />
        ) : (
          <ListPayrollReportsPageContent
            organization={data.viewer.viewingOrganization}
            baseRoute={`/accounting/employee-payroll`}
            payrollReportKind={PayrollReportKind.ALL_EMPLOYEE}
          />
        )}
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListPayrollReportsPage.query = gql`
  ${AccountingPageHeader.fragment}

  query ListPayrollReportsPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledJobLevelCosts: isEnabled(feature: "JOB_LEVEL_COSTS")
        }
        ...AccountingPageHeader
      }
    }
  }
`;

export default ListPayrollReportsPage;
