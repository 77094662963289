// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Checkbox, CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import {BillRuleForm} from '@supermove/forms';
import {BillRule} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';
import Switch from 'modules/App/components/Switch';

const Row = Styled.View`
  flex-direction: row;
`;

const ActionsColumn = Styled.View`
`;

const IconButton = Styled.ButtonV2`
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border-width: 1px;
  border-color: ${(props) => (props as any).color};
  background-color: ${colors.white};
`;

const FieldsColumn = Styled.View`
  flex: 1;
`;

const SwitchContainer = Styled.View`
  height: 40px;
  width: 36px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.border};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextInput = Styled.TextInput.H6`
  border-color: ${colors.gray.border};
`;

const ShowCustomerButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ShowCustomerText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.blue.interactive};
`;

const ActionButtons = ({isReordering, handleRemove, handleChangeOrder, index, isLastItem}: any) => {
  return (
    <ActionsColumn>
      {isReordering ? (
        <React.Fragment>
          <IconButton
            color={index === 0 ? colors.gray.border : colors.blue.interactive}
            onPress={() => handleChangeOrder(index - 1)}
            disabled={index === 0}
          >
            <Icon
              source={Icon.ArrowUp}
              color={index === 0 ? colors.gray.border : colors.blue.interactive}
              size={16}
            />
          </IconButton>
          <Space height={8} />
          <IconButton
            color={isLastItem ? colors.gray.border : colors.blue.interactive}
            onPress={() => handleChangeOrder(index + 1)}
            disabled={isLastItem}
          >
            <Icon
              source={Icon.ArrowDown}
              color={isLastItem ? colors.gray.border : colors.blue.interactive}
              size={16}
            />
          </IconButton>
        </React.Fragment>
      ) : (
        <IconButton color={colors.gray.border} onPress={handleRemove}>
          <Icon source={Icon.Trash} color={colors.gray.secondary} size={12} />
        </IconButton>
      )}
    </ActionsColumn>
  );
};

const NameField = ({billRuleForm, form, field}: any) => {
  return (
    <Field
      {...form}
      name={field}
      component={TextInput}
      input={{
        placeholder: 'Name',
        required: !_.get(billRuleForm, 'name'),
      }}
    />
  );
};

const AmountField = ({billRuleForm, form, field}: any) => {
  switch (BillRuleForm.getValueType(billRuleForm)) {
    case BillRule.VALUE_TYPES.CURRENCY:
      return (
        <Field
          {...form}
          name={field}
          component={CurrencyInput}
          input={{
            component: TextInput,
            setFieldValue: form.setFieldValue,
            setFieldTouched: form.setFieldTouched,
            placeholder: 'Amount',
            options: {
              enableEmptyValues: true,
            },
          }}
        />
      );
    case BillRule.VALUE_TYPES.HOURS:
      return (
        <Field
          {...form}
          name={field}
          component={TextInput}
          input={{
            placeholder: 'Amount',
            keyboardType: 'number-pad',
          }}
        />
      );
    case BillRule.VALUE_TYPES.CUSTOM:
      return <TextInput placeholder={'N/A'} placeholderTextColor={colors.gray.tertiary} disabled />;
    default:
      return null;
  }
};

const ShowCustomerField = ({billRuleForm, form, field}: any) => {
  const checked = billRuleForm.isVisibleToCustomer;
  return (
    <ShowCustomerButton onPress={() => form.setFieldValue(field, !checked)}>
      <Checkbox
        checked={checked}
        color={colors.blue.interactive}
        borderColor={checked ? colors.blue.interactive : colors.gray.border}
        size={20}
        iconSize={14}
        disabled
        onChange={() => {}}
      />
      <Space width={8} />
      <ShowCustomerText>Show Customer?</ShowCustomerText>
    </ShowCustomerButton>
  );
};

const EditBillBillRule = ({
  billRuleForm,
  form,
  index,
  handleRemoveBillRuleForm,
  handleChangeOrder,
  isReordering,
  isLastItem,
}: any) => {
  const formField = `billForm.billRuleForms.${index}`;
  return (
    <React.Fragment>
      <Space height={12} />
      <Row>
        <Space width={20} />
        <ActionButtons
          isReordering={isReordering}
          handleRemove={handleRemoveBillRuleForm}
          handleChangeOrder={handleChangeOrder}
          index={index}
          isLastItem={isLastItem}
        />
        <Space width={6} />
        <FieldsColumn>
          <NameField billRuleForm={billRuleForm} form={form} field={`${formField}.name`} />
          <Space height={4} />
          <Row>
            <SwitchContainer>
              <Switch isOn color={colors.green.status} onChange={() => {}} />
            </SwitchContainer>
            <Space width={4} />
            <FieldsColumn>
              <AmountField
                billRuleForm={billRuleForm}
                form={form}
                field={`${formField}.values.0`}
              />
            </FieldsColumn>
          </Row>
          <Space height={6} />
          <ShowCustomerField
            billRuleForm={billRuleForm}
            form={form}
            field={`${formField}.isVisibleToCustomer`}
          />
        </FieldsColumn>
        <Space width={12} />
      </Row>
    </React.Fragment>
  );
};

export default EditBillBillRule;
