// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import TreeNavigation from '@shared/design/components/TreeNavigation';
import ErrorCatcher from '@shared/modules/Error/components/ErrorCatcher';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'modules/App/components/Line';
import ProjectWidgetContent from 'modules/Project/V2/Show/components/ProjectWidgetContent';
import QuitEditingWidgetModal from 'modules/Project/V2/Show/components/widgets/QuitEditingWidgetModal';

const VerticalDivider = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border}
`;

const ProjectWidgetsContainer = Styled.View`
  flex-direction: row;
`;

const WidgetButtonsContainer = Styled.View`
`;

const WidgetContentContainer = Styled.View`
  width: 344px;
`;

const ContentHeaderContainer = Styled.View`
  flex-direction: row;
  padding-vertical: 16px;
  align-items: center;
`;

const ContentHeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const LoadingComponent = () => {
  return <WidgetButtons isDisabled />;
};

const WidgetContentHeader = ({handleClose}: any) => {
  const {params} = useNavigationDOM();
  return (
    <ContentHeaderContainer>
      <Space width={16} />
      <ContentHeaderText>{ProjectWidgetKind.getDisplay(params.widget)}</ContentHeaderText>
      <Space style={{flex: 1}} />
      <TertiaryButton onPress={handleClose}>
        <Icon source={Icon.XmarkLarge} size={12} color={colors.gray.primary} />
      </TertiaryButton>
      <Space width={18} />
    </ContentHeaderContainer>
  );
};

const WidgetButtons = ({urlFilters, isDisabled, organization}: any) => {
  const {params} = useNavigationDOM();
  return (
    <WidgetButtonsContainer>
      <Space height={24} />
      <TreeNavigation
        navigationItems={
          isDisabled
            ? []
            : (ProjectWidgetKind as any).getOrganizationWidgets({organization}).map((kind: any) => {
                return {
                  icon: ProjectWidgetKind.getIcon(kind),
                  tooltip: ProjectWidgetKind.getDisplay(kind),
                  value: kind,
                  valueKey: 'widget',
                };
              })
        }
        handleSetValues={(values: any) => urlFilters.handleUpdate(values)}
        values={params}
        width={56}
        spacing={24}
        isCentered
        isDisabled={isDisabled}
        tooltipPlacement={'left'}
      />
    </WidgetButtonsContainer>
  );
};

const ProjectWidgets = ({project, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const [isClosable, setIsClosable] = useState(true);
  const quitEditingModal = useModal({name: 'Quit Editing Modal'});
  return (
    <ProjectWidgetsContainer>
      <WidgetButtons urlFilters={urlFilters} organization={project.organization} />
      {params.widget && (
        <React.Fragment>
          <VerticalDivider />
          <WidgetContentContainer>
            <WidgetContentHeader
              handleClose={() =>
                isClosable
                  ? ProjectWidgetContent.handleCloseWidget({urlFilters})
                  : quitEditingModal.handleOpen()
              }
            />
            <Line />
            <ErrorCatcher
              ErrorComponent={() => (
                <ErrorState.Centered title={ProjectWidgetKind.getErrorTitle(params.widget)} />
              )}
            >
              <ScrollView contentContainerStyle={{flex: 1}}>
                <ProjectWidgetContent
                  project={project}
                  setIsClosable={setIsClosable}
                  urlFilters={urlFilters}
                />
              </ScrollView>
            </ErrorCatcher>
          </WidgetContentContainer>
        </React.Fragment>
      )}
      <QuitEditingWidgetModal
        quitEditingModal={quitEditingModal}
        handleCloseParent={() => ProjectWidgetContent.handleCloseWidget({urlFilters})}
      />
    </ProjectWidgetsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWidgets.fragment = gql`
  ${ProjectWidgetContent.fragment}
  ${ProjectWidgetKind.getOrganizationWidgets.fragment}
  fragment ProjectWidgets on Project {
    id
    uuid
    organization {
      id
      ...ProjectWidgetKind_getOrganizationWidgets
    }
    ...ProjectWidgetContent
  }
`;

ProjectWidgets.Placeholder = LoadingComponent;

export default ProjectWidgets;
