// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Icon, Popover, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SurveyMethodKind from '@shared/modules/Inventory/enums/SurveyMethodKind';
import useSetDefaultOrganizationSurveyMethodMutation from '@shared/modules/Inventory/hooks/useSetDefaultOrganizationSurveyMethodMutation';
import DeleteDefaultSurveyMethodModal from 'modules/Organization/Settings/Inventory/components/DeleteDefaultSurveyMethodModal';
import DeleteSurveyMethodModal from 'modules/Organization/Settings/Inventory/components/DeleteSurveyMethodModal';

const ItemContainer = Styled.View`
  background-color: ${colors.white};
  border-radius: 8px;
  padding: 12px 16px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  z-index: ${(props) => 100 - (props as any).index};
  flex: 1;
`;

const SurveyMethodText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.black};
`;

const ActionButton = Styled.ButtonV2`
  min-width: 16px;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const Container = Styled.View`
  flex-direction: row;
  flex: 1;
  /* Must use padding here to get spacing between components in drag and drop list */
  /* Drag and drop does some weird stuff with the Space component so it does not show up properly */
  padding-vertical: 4px;
`;

const TextContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ActionContainer = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const DefaultBadge = Styled.View`
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${colors.gray.border};
  width: fit-content;
`;

const DefaultBadgeText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const getActions = ({
  isDeletable,
  isDefaultSurveyMethod,
  deleteSurveyMethodModal,
  handleSubmit,
  deleteDefaultSurveyMethodModal,
}: any) => {
  // Case 1: CUSTOM kind and current default - show replace all modal
  if (isDeletable && isDefaultSurveyMethod) {
    return [
      {
        text: 'Remove',
        onPress: deleteDefaultSurveyMethodModal.handleOpen,
      },
    ];
    // Case 2: CUSTOM kind and not default - show replace by project type modal
  } else if (isDeletable) {
    return [
      {
        text: 'Set as default',
        onPress: handleSubmit,
      },
      {
        text: 'Remove',
        onPress: deleteSurveyMethodModal.handleOpen,
      },
    ];
    // Case 3: not CUSTOM (not deletable) and not default - only allow setting as default
  } else {
    return [
      {
        text: 'Set as default',
        onPress: handleSubmit,
      },
    ];
  }
};

const EllipseButton = ({
  isDisabled,
  isDeletable,
  isDefaultSurveyMethod,
  surveyMethodForm,
  refetch,
  organization,
}: any) => {
  const actionsPopover = usePopover();
  const deleteSurveyMethodModal = useModal({name: 'Delete Survey Method Modal'});
  const deleteDefaultSurveyMethodModal = useModal({name: 'Delete Default Survey Method Modal'});
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error setting default method',
  });
  const {handleSubmit} = useSetDefaultOrganizationSurveyMethodMutation({
    surveyMethodForm,
    onSuccess: refetch,
    onError: () => errorToast.handleToast(),
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <ActionButton disabled={isDisabled} onPress={actionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
            size={16}
          />
        </ActionButton>
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={actionsPopover.isOpen}
        handleOpen={actionsPopover.handleOpen}
        handleClose={actionsPopover.handleClose}
        reference={actionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={actionsPopover.handleClose}
          actions={getActions({
            isDeletable,
            isDefaultSurveyMethod,
            deleteSurveyMethodModal,
            handleSubmit,
            deleteDefaultSurveyMethodModal,
          })}
        />
      </Popover>
      <DeleteSurveyMethodModal
        deleteSurveyMethodModal={deleteSurveyMethodModal}
        surveyMethodForm={surveyMethodForm}
        refetch={refetch}
        organization={organization}
      />
      <DeleteDefaultSurveyMethodModal
        deleteDefaultSurveyMethodModal={deleteDefaultSurveyMethodModal}
        surveyMethodForm={surveyMethodForm}
        refetch={refetch}
        organization={organization}
      />
    </React.Fragment>
  );
};

const SurveyMethodItem = ({
  surveyMethodForm,
  index,
  organization,
  refetch,
  setIndexOfEdit,
  isEditingAnotherItem,
}: any) => {
  const isDefaultSurveyMethod =
    _.toString(organization.defaultSurveyMethodId) === _.toString(surveyMethodForm.surveyMethodId);
  const isDeletable = !_.includes(
    [SurveyMethodKind.NO_SURVEY, SurveyMethodKind.VIRTUAL_WALKTHROUGH],
    surveyMethodForm.kind,
  );
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ItemContainer index={index}>
        <TextContainer>
          <SurveyMethodText>{surveyMethodForm.name}</SurveyMethodText>
          <Space width={8} />
          {isDefaultSurveyMethod && (
            <DefaultBadge>
              <DefaultBadgeText>Default</DefaultBadgeText>
            </DefaultBadge>
          )}
        </TextContainer>
        <ActionContainer>
          {surveyMethodForm.kind === SurveyMethodKind.CUSTOM && (
            <IconButton
              onPress={() => {
                setIndexOfEdit(index);
              }}
              disabled={isEditingAnotherItem}
            >
              <Icon
                source={Icon.Pen}
                color={isEditingAnotherItem ? colors.gray.disabled : colors.blue.interactive}
                size={16}
              />
            </IconButton>
          )}
          <EllipseButton
            isDefaultSurveyMethod={isDefaultSurveyMethod}
            isDeletable={isDeletable}
            surveyMethodForm={surveyMethodForm}
            refetch={refetch}
            organization={organization}
            isDisabled={isEditingAnotherItem || (isDefaultSurveyMethod && !isDeletable)}
          />
        </ActionContainer>
      </ItemContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SurveyMethodItem.fragment = gql`
  ${DeleteDefaultSurveyMethodModal.fragment}
  ${DeleteSurveyMethodModal.fragment}
  fragment SurveyMethodItem on Organization {
    id
    defaultSurveyMethodId
    ...DeleteDefaultSurveyMethodModal
    ...DeleteSurveyMethodModal
  }
`;

export default SurveyMethodItem;
