// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePagination, useResponsive, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import CompensationReportsList from 'modules/Accounting/CompensationReport/components/CompensationReportsList';
import Line from 'modules/App/components/Line';
import EmployeePageV1 from 'modules/Employee/components/EmployeePageV1';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ContentContainer = Styled.View`
  max-width: 1230px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '12px' : '20px')};
`;

const Title = Styled.Text`
  ${Typography.Heading5}
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
`;

const PayrollReportsText = Styled.Text`
  ${Typography.Label1};
`;

const ContentHeader = ({viewer}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Space height={responsive.mobile ? 16 : 32} />
      <Title>{viewer.viewingOrganization.name}</Title>
      <Space height={12} />
      <Subtitle>{`Welcome back, ${viewer.fullName}! See your payroll reports below.`}</Subtitle>
      <Space height={16} />
      <Line />
      <Space height={16} />
      <Row>
        <PayrollReportsText>
          {pluralize('Payroll Report', viewer.compensationReportsCount, true)}
        </PayrollReportsText>
      </Row>
      <Space height={16} />
    </React.Fragment>
  );
};

const ListCompensationReportAccountingEmployeePageContent = ({
  viewer,
  setCurrentPage,
  currentPage,
}: any) => {
  const responsive = useResponsive();
  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      viewer,
      'filteredCompensationReportsPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  return (
    <ContentContainer {...responsive}>
      <ContentHeader viewer={viewer} />
      <ScrollView>
        <CompensationReportsList
          compensationReports={viewer.filteredCompensationReportsPaginatedList.compensationReports}
          organizationSlug={viewer.viewingOrganization.slug}
          viewerUserRole={viewer.role}
          isCostAndCompensationEnabled={
            viewer.viewingOrganization.settings.isCostAndCompensationEnabled
          }
          isEnabledCompensationCsvDownload={
            viewer.viewingOrganization.features.isEnabledCompensationCsvDownload
          }
        />
        <Space height={16} />
      </ScrollView>
      <Space height={32} />
      <Container>
        <PaginationBar pagination={pagination} />
      </Container>
      <Space height={32} />
    </ContentContainer>
  );
};

const ListCompensationReportAccountingEmployeePage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <EmployeePageV1
      selected={'compensation'}
      query={ListCompensationReportAccountingEmployeePage.query}
      variables={{
        pagination: {
          page: currentPage,
          resultsPerPage: 20,
        },
      }}
    >
      {({data}: any) => (
        <Container style={{flex: 1}}>
          <ListCompensationReportAccountingEmployeePageContent
            viewer={data.viewer}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </Container>
      )}
    </EmployeePageV1>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListCompensationReportAccountingEmployeePage.query = gql`
  ${usePagination.fragment}
  ${CompensationReportsList.fragment}

  query ListCompensationReportAccountingEmployeePage(
    $pagination: PaginationInput!,
  ) {
    ${gql.query}
    viewer {
      id
      role
      compensationReportsCount
      fullName
      viewingOrganization {
        id
        slug
        name
        settings {
          id
          isCostAndCompensationEnabled
        }
        features {
          isEnabledCompensationCsvDownload: isEnabled(feature: "COMPENSATION_CSV_DOWNLOAD")
        }
      }
      filteredCompensationReportsPaginatedList(
        pagination: $pagination,
      ) {
        compensationReports: results {
          id
          ...CompensationReportsList
        }
        paginationMetadata {
          ...usePagination
        }
      }
    }
  }
`;

export default ListCompensationReportAccountingEmployeePage;
