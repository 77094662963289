// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';

const handleChangeProjectType = ({
  form,
  field,
  organization,
  shouldResetJobDateOnProjectTypeChange,
  jobFormField,
}: any) => {
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  const previousProjectTypeId = _.get(form, `values.${field}.projectTypeId`);

  if (
    isEnabledCreateProjectDispatchLocks &&
    shouldResetJobDateOnProjectTypeChange &&
    jobFormField
  ) {
    form.setFieldValue(`${jobFormField}.date`, '');
  }

  form.setFieldValue(`${field}.valueForms`, []);

  if (previousProjectTypeId) {
    form.setFieldValue(`${jobFormField}.jobTypeId`, null);
    form.setFieldValue(`${jobFormField}.valueForms`, []);
  }
};

type OwnProjectTypeDropdownProps = {
  organization: any;
  form: any;
  field: string;
  shouldResetJobDateOnProjectTypeChange?: boolean;
  jobFormField: string;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'ProjectTypeDropdownProps' circularly r... Remove this comment to see the full error message
type ProjectTypeDropdownProps = OwnProjectTypeDropdownProps &
  typeof ProjectTypeDropdown.defaultProps;

// @ts-expect-error TS(7022): 'ProjectTypeDropdown' implicitly has type 'any' be... Remove this comment to see the full error message
const ProjectTypeDropdown = ({
  organization,
  form,
  field,
  shouldResetJobDateOnProjectTypeChange,
  jobFormField,
  style,
}: ProjectTypeDropdownProps) => {
  const responsive = useResponsive();

  return (
    <FieldInput
      {...form}
      index={0}
      component={DropdownInput}
      name={`${field}.projectTypeId`}
      label={'Project Type'}
      input={{
        options: organization.moveProjectTypes.map((projectType: any) => ({
          label: projectType.name,
          value: Number(projectType.id),
        })),
        required: !_.get(form, `values.${field}.projectTypeId`),
        placeholder: 'Select Project Type',
        isSearchable: true,
        setFieldValue: form.setFieldValue,
        onChangeValue: (value: any) =>
          handleChangeProjectType({
            form,
            field,
            organization,
            shouldResetJobDateOnProjectTypeChange,
            jobFormField,
          }),
        style: style || {
          width: responsive.mobile ? '100%' : 250,
        },
      }}
      style={style}
    />
  );
};

ProjectTypeDropdown.defaultProps = {
  shouldResetJobDateOnProjectTypeChange: false,
  style: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDropdown.fragment = gql`
  fragment ProjectTypeDropdown on Organization {
    id
    features {
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      name
    }
  }
`;
export default ProjectTypeDropdown;
