// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/TableV2Deprecated';
import TeamForm from '@shared/modules/TaskManagement/forms/TeamForm';
import useDeleteTeamMutation from '@shared/modules/TaskManagement/hooks/useDeleteTeamMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import SendTeamSMSModal from 'modules/Organization/Settings/Communication/components/SendTeamSMSModal';
import EditTeamDrawer from 'modules/Organization/Settings/Staff/components/EditTeamDrawer';
import UserProfilePreviewDrawer from 'modules/Organization/Settings/Users/components/UserProfilePreviewDrawer';

const TRUNCATE_MEMBERS_COUNT = 10;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TeamMemberChipsContainer = Styled.View`
  flex-direction: row;
  flex: 1;
  margin-top: -8px;
  margin-left: -8px;
  flex-wrap: wrap;
`;

const ChipContainer = Styled.ButtonV2`
  margin-top: 8px;
  margin-left: 8px;
  height: 24px;
  justify-content: center;
  padding-horizontal: 8px;
  background-color: ${colors.blue.accent}
  border-radius: 16px;
  margin-left: 4px;
  opacity: ${({
    // @ts-expect-error TS(2339): Property 'isActive' does not exist on type 'ThemeP... Remove this comment to see the full error message
    isActive,
  }) => (isActive ? 1 : 0.5)};
`;

const ChipText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.primary};
`;

const ExpandMembersText = Styled.Text`
  ${Typography.Link}
  margin-top: 10px;
`;

const TeamMemberChip = ({user}: any) => {
  const userProfilePreviewDrawer = useDrawer({name: 'User Profile Preview Drawer'});
  return (
    <React.Fragment>
      <ChipContainer isActive={user.isActive} onPress={userProfilePreviewDrawer.handleOpen}>
        <ChipText>{user.fullName}</ChipText>
      </ChipContainer>
      <UserProfilePreviewDrawer
        isOpen={userProfilePreviewDrawer.isOpen}
        handleClose={userProfilePreviewDrawer.handleClose}
        userUuid={user.uuid}
      />
    </React.Fragment>
  );
};

const TeamMembersList = ({team}: any) => {
  const [showAll, setShowAll] = useState(false);
  const totalMembers = team.members.length;
  const isExpandable = totalMembers > TRUNCATE_MEMBERS_COUNT;
  const excessMembersCount = totalMembers - TRUNCATE_MEMBERS_COUNT;
  const sortedMembers = _.sortBy(team.members, (member) => member.fullName);
  const displayMembers = sortedMembers.slice(0, showAll ? totalMembers : TRUNCATE_MEMBERS_COUNT);
  return (
    <TeamMemberChipsContainer>
      {displayMembers.map((member) => (
        <TeamMemberChip key={member.id} user={member} />
      ))}
      {isExpandable && (
        <React.Fragment>
          <Space width={12} />
          <ExpandMembersText onPress={() => setShowAll(!showAll)}>
            {showAll ? `Hide` : `+${excessMembersCount} more`}
          </ExpandMembersText>
        </React.Fragment>
      )}
    </TeamMemberChipsContainer>
  );
};

const SendSMSToTeamButton = ({sendTeamSMSModal}: any) => {
  return (
    <TertiaryButton onPress={sendTeamSMSModal.handleOpen}>
      <Icon source={Icon.CommentDots} size={16} color={colors.blue.interactive} />
    </TertiaryButton>
  );
};

const TeamActionsPopover = ({popover, deleteTeamModal, editTeamDrawer, team}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.LeftEnd}
      width={120}
      offset={[4, 101]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          editTeamDrawer.handleOpen();
          popover.handleClose();
        }}
      >
        Edit Team
      </ActionMenuPopover.MenuItem>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          deleteTeamModal.handleOpen();
          popover.handleClose();
        }}
      >
        Delete Team
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const ActionMenuButton = ({teamActionsPopover}: any) => {
  return (
    <Popover.Content innerRef={teamActionsPopover.ref}>
      <TertiaryButton style={{paddingHorizontal: 8}} onPress={teamActionsPopover.handleToggle}>
        <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
      </TertiaryButton>
    </Popover.Content>
  );
};

const TeamActions = ({team, refetch, viewer, isCrewTeam}: any) => {
  const sendTeamSMSModal = useModal({name: 'Send Team SMS Modal'});
  const editTeamDrawer = useDrawer({name: 'Edit Team Drawer'});
  const teamActionsPopover = usePopover({name: 'Team Actions Popover'});
  const deleteTeamModal = useModal({name: 'Delete Team Modal'});
  const teamForm = TeamForm.delete({teamId: team.id});
  const {handleSubmit, submitting} = useDeleteTeamMutation({
    teamForm,
    onSuccess: () => {
      deleteTeamModal.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <React.Fragment>
      <Row>
        <SendSMSToTeamButton sendTeamSMSModal={sendTeamSMSModal} team={team} />
        <Space width={4} />
        <ActionMenuButton
          teamActionsPopover={teamActionsPopover}
          deleteTeamModal={deleteTeamModal}
        />
      </Row>
      <EditTeamDrawer
        key={editTeamDrawer.key}
        isOpen={editTeamDrawer.isOpen}
        handleClose={editTeamDrawer.handleClose}
        team={team}
        refetch={refetch}
        isCrewTeam={isCrewTeam}
      />
      <TeamActionsPopover
        key={teamActionsPopover.key}
        popover={teamActionsPopover}
        team={team}
        deleteTeamModal={deleteTeamModal}
        editTeamDrawer={editTeamDrawer}
      />
      <SendTeamSMSModal
        key={sendTeamSMSModal.key}
        sendTeamSMSModal={sendTeamSMSModal}
        team={team}
        viewer={viewer}
      />
      <DeleteModal
        title={`Are you sure you want to delete ${team.name}?`}
        subtitle={"You can't undo this action."}
        isOpen={deleteTeamModal.isOpen}
        handleClose={deleteTeamModal.handleClose}
        handleDelete={handleSubmit}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

const getTeamsTableColumnDefinitions = ({refetch, viewer, isCrewTeam}: any) => {
  return [
    {
      width: 0, // Resolves to smallest width possible.
      headerContent: () => null,
      cellContent: ({rowIndex}: any) => {
        return <Table.CellText>{rowIndex + 1}</Table.CellText>;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <Table.HeaderText>Name</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'team' implicitly has an 'any' typ... Remove this comment to see the full error message
      cellContent: ({item: team}) => {
        return <Table.CellText>{team.name}</Table.CellText>;
      },
    },
    {
      flex: 9,
      headerContent: () => {
        return <Table.HeaderText>Members</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'team' implicitly has an 'any' typ... Remove this comment to see the full error message
      cellContent: ({item: team}) => {
        return <TeamMembersList team={team} />;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Actions</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'team' implicitly has an 'any' typ... Remove this comment to see the full error message
      cellContent: ({item: team}) => {
        return (
          <TeamActions team={team} refetch={refetch} viewer={viewer} isCrewTeam={isCrewTeam} />
        );
      },
    },
  ];
};

const TeamsTable = ({teams, refetch, loading, viewer, isCrewTeam}: any) => {
  return (
    <Table.FixedHeaderScroll
      columnDefinitions={getTeamsTableColumnDefinitions({
        refetch,
        viewer,
        isCrewTeam,
      })}
      emptyStateText='No teams'
      items={teams}
      itemKey={'id'}
      loading={loading}
      isDense
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TeamsTable.fragment = gql`
  ${EditTeamDrawer.fragment}
  ${SendTeamSMSModal.fragment}

  fragment TeamsTable on Team {
    id
    name

    members {
      id
      uuid
      fullName
      isActive
    }
    ...EditTeamDrawer
    ...SendTeamSMSModal
  }

  fragment TeamsTable_Viewer on User {
    id
    ...SendTeamSMSModal_Viewer
  }
`;

export default TeamsTable;
