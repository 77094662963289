// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Styled, Space} from '@supermove/components';
import {DateInputProps} from '@supermove/components/src/DateInput/dateInputTypes';
import {Form, ResponsiveType, useResponsive} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

// App
import FieldInput, {FieldInputProps} from '@shared/design/components/Field/FieldInput';

const Row = Styled.View<{index?: number; responsive: ResponsiveType}>`
  flex-direction: row;
  z-index: ${({index}) => (index ? 100 - index : undefined)};
`;

const Container = Styled.View`
`;

interface DateRangeInput {
  form: Form<any>;
  field: string;
  index?: number;
  startDateField: string;
  endDateField: string;

  startDateProps?: FieldInputProps;
  startDateInputProps?: DateInputProps;
  endDateProps?: FieldInputProps;
  endDateInputProps?: DateInputProps;
  toggleRangeOnSameDate?: (startDate?: unknown) => void;
}

const DateRangeInput = ({
  form,
  field,
  index,
  startDateField,
  endDateField,
  startDateProps,
  startDateInputProps,
  endDateProps,
  endDateInputProps,
  toggleRangeOnSameDate,
}: DateRangeInput) => {
  const responsive = useResponsive();
  const startDate = _.get(form.values, startDateField);
  const endDate = _.get(form.values, endDateField);

  const isStartDateInPast = Datetime.isPast(startDate);
  const isEndDateInPast = Datetime.isPast(endDate);
  const minEndDate = startDate || undefined;
  const hasTouchedStartDate = form.touched[startDateField] || form.touched[`${field}.date`];
  const hasTouchedEndDate = form.touched[endDateField];

  const hasStartDateWarning = hasTouchedStartDate && isStartDateInPast;
  const hasEndDateWarning = hasTouchedEndDate && isEndDateInPast;
  const hasWarning = hasStartDateWarning || hasEndDateWarning;

  const onChangeStartDate = (date: string) => {
    form.setTouched({...form.touched, [startDateField]: true});
    const numberDaysDiff = Datetime.getNumberOfDays(date, endDate) - 1; // This util adds 1, but makes more sense here to not

    if (toggleRangeOnSameDate && date && endDate && numberDaysDiff === 0) {
      toggleRangeOnSameDate(date);
    } else if (numberDaysDiff <= 0) {
      // Clear the end date if it's before or on the start date
      form.setFieldValue(endDateField, '');
    }
  };

  const onChangeEndDate = (date: string) => {
    form.setTouched({...form.touched, [endDateField]: true});
    const numberDaysDiff = Datetime.getNumberOfDays(startDate, date) - 1;

    if (toggleRangeOnSameDate && numberDaysDiff === 0) {
      toggleRangeOnSameDate(date);
    } else if (numberDaysDiff < 0) {
      // Clear the start date if it's after or on the end date
      form.setFieldValue(startDateField, '');
    }
  };

  return (
    <Container>
      <Row index={index} responsive={responsive}>
        <FieldInput
          {...form}
          component={DateInput}
          name={startDateField}
          style={{flex: 1}}
          {...startDateProps}
          input={{
            setFieldValue: form.setFieldValue,
            isPortaled: true,
            style: {width: '100%'},
            onChangeDate: onChangeStartDate,
            ...startDateInputProps,
          }}
        />
        <Space height={12} width={8} />
        <FieldInput
          {...form}
          component={DateInput}
          name={endDateField}
          style={{flex: 1}}
          {...endDateProps}
          input={{
            onChangeDate: onChangeEndDate,
            minDate: minEndDate,
            setFieldValue: form.setFieldValue,
            isPortaled: true,
            style: {width: '100%'},
            position: DateInput.Positions.BOTTOM_END,
            ...endDateInputProps,
          }}
        />
      </Row>
      {hasWarning ? (
        <FieldInput.Footer warningMessage='Selected date is in the past.' hasWarning />
      ) : null}
    </Container>
  );
};

export default DateRangeInput;
