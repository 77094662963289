// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {KeyboardView, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useState, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import CompleteDocumentV2Form from '@shared/modules/Document/forms/CompleteDocumentV2Form';
import useCompleteDocumentV2Mutation from '@shared/modules/Document/hooks/useCompleteDocumentV2Mutation';
import useUpdateDocumentContentJsonForDocumentMutation from '@shared/modules/Document/hooks/useUpdateDocumentContentJsonForDocumentMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CustomerDocumentV2 from 'modules/Document/DocumentV2/CustomerDocumentV2';

const Container = Styled.View`
  flex: 1;
`;

const DocumentContainer = Styled.View`
  border-width: 1;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  padding: ${(props) => ((props as any).mobile ? 10 : 20)}px;
`;

const ContentContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 20 : 0)}px;
`;

const Footer = Styled.View`
  align-items: center;
  border-top-width: 1;
  border-color: ${colors.gray.border};
`;

const FooterContent = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '796px')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 20 : 0)}px;
`;

const ErrorsText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.red.warning};
`;

const ErrorsMessage = ({form}: any) => {
  return (
    <React.Fragment>
      <ErrorsText>{`${pluralize(
        'error',
        _.get(form.values, 'completeDocumentV2Form.numberOfErrors'),
        true,
      )} found`}</ErrorsText>
      <Space width={20} />
    </React.Fragment>
  );
};

const CustomerDocumentV2SignContent = ({
  document,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: any) => {
  const responsive = useResponsive();
  const completeDocumentV2Form = CompleteDocumentV2Form.new({document, step: document.step});
  const {form, handleSubmit, submitting} = useCompleteDocumentV2Mutation({
    completeDocumentV2Form,
    onSuccess: onSubmit,
    onError: (errors: any) => {
      console.log({errors});
      form.setFieldValue('completeDocumentV2Form.numberOfErrors', errors.length);
    },
  });

  const hasErrors = !_.isEmpty(
    _.filter(
      _.get(form.errors, 'completeDocumentV2Form.documentForm.documentItemForms'),
      (item) => !_.isNil(item),
    ),
  );

  return (
    <Container>
      <KeyboardView>
        <ScrollView
          alwaysBounceVertical={false}
          style={{flex: 1}}
          contentContainerStyle={{alignItems: 'center'}}
        >
          <ContentContainer {...responsive}>
            {HeaderComponent && <HeaderComponent {...headerComponentProps} />}
            <Space height={10} />
            <DocumentContainer {...responsive}>
              <CustomerDocumentV2
                document={document}
                form={form}
                field={'completeDocumentV2Form'}
                isEditable
              />
            </DocumentContainer>
          </ContentContainer>
          <Space height={24} />
        </ScrollView>
      </KeyboardView>
      <Footer>
        <FooterContent {...responsive}>
          <Space height={16} />
          {hasErrors && (
            <React.Fragment>
              <ErrorsMessage form={form} />
              <Space height={8} />
            </React.Fragment>
          )}
          <Button
            isWidthOfContainer
            text={submitButtonText}
            isSubmitting={submitting}
            onPress={() => beforeSubmit(handleSubmit)}
          />
          <Space height={16} />
        </FooterContent>
      </Footer>
    </Container>
  );
};

const CustomerDocumentV2SignQuery = ({
  documentUuid,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: any) => {
  const {loading, data} = useQuery(CustomerDocumentV2Sign.query, {
    fetchPolicy: 'network-only',
    variables: {
      documentUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <CustomerDocumentV2SignContent
            document={data.document}
            beforeSubmit={beforeSubmit}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
            HeaderComponent={HeaderComponent}
            headerComponentProps={headerComponentProps}
          />
        );
      }}
    </Loading>
  );
};

type OwnCustomerDocumentV2SignProps = {
  documentUuid: string;
  onSubmit?: (...args: any[]) => any;
  submitButtonText?: string;
  HeaderComponent?: (...args: any[]) => any;
  headerComponentProps?: any;
  beforeSubmit?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'CustomerDocumentV2SignProps' circularl... Remove this comment to see the full error message
type CustomerDocumentV2SignProps = OwnCustomerDocumentV2SignProps &
  typeof CustomerDocumentV2Sign.defaultProps;

// @ts-expect-error TS(7022): 'CustomerDocumentV2Sign' implicitly has type 'any'... Remove this comment to see the full error message
const CustomerDocumentV2Sign = ({
  documentUuid,
  beforeSubmit,
  onSubmit,
  submitButtonText,
  HeaderComponent,
  headerComponentProps,
}: CustomerDocumentV2SignProps) => {
  const [isMutationComplete, setIsMutationComplete] = useState(false);
  const {submitting, handleSubmit} = useUpdateDocumentContentJsonForDocumentMutation({
    documentUuid,
    onSuccess: () => {
      setIsMutationComplete(true);
    },
    onError: (errors: any) => {
      console.log({errors});
      setIsMutationComplete(true);
    },
  });

  // Updating the document content JSON before displaying ensures that all variables are accurate.
  // This is necessary for custom/dynamic variables that can change by user input after being generated.
  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <Loading loading={submitting || !isMutationComplete} as={PageLoadingIndicator}>
      {() => {
        return (
          <CustomerDocumentV2SignQuery
            documentUuid={documentUuid}
            beforeSubmit={beforeSubmit}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
            HeaderComponent={HeaderComponent}
            headerComponentProps={headerComponentProps}
          />
        );
      }}
    </Loading>
  );
};

CustomerDocumentV2Sign.defaultProps = {
  onSubmit: () => {},
  submitButtonText: 'Continue',
  HeaderComponent: null,
  headerComponentProps: {},
  beforeSubmit: (handleSubmit: any) => handleSubmit(),
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentV2Sign.query = gql`
  ${CompleteDocumentV2Form.new.fragment}
  ${CustomerDocumentV2.fragment}

  query CustomerDocumentV2Sign($documentUuid: String!) {
    ${gql.query}
    document(uuid: $documentUuid) {
      id
      step
      ...CompleteDocumentV2Form_new
      ...CustomerDocumentV2
    }
  }
`;

export default CustomerDocumentV2Sign;
