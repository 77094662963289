// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, ScrollView, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import HardcodedSystemVariables from '@shared/modules/Variable/enums/HardcodedSystemVariables';

const Text = Styled.Text`
  ${Typography.Body}
`;

const VariableListContainer = Styled.View`
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  width: 100%;
  height: 600px;
`;

const Column = Styled.View`
`;

const TooltipContents = Styled.View`
`;

const Variable = ({label, identifier}: any) => {
  const textCopiedToast = useToast({
    ToastComponent: Toast,
    message: 'Copied to clipboard',
  });

  return (
    <React.Fragment>
      <Text>{label}</Text>
      <TertiaryButton
        onPress={() => {
          /* global navigator */
          navigator.clipboard.writeText(identifier);
          textCopiedToast.handleToast();
        }}
      >
        {/* @ts-expect-error TS(2322): Type '"bottomLeft"' is not assignable to type 'Pla... Remove this comment to see the full error message */}
        <TextTooltip text={'Click to copy'} placement={'bottomLeft'} isEnabledMobileToast={false}>
          <TooltipContents>
            <TertiaryButton.Text>{identifier}</TertiaryButton.Text>
          </TooltipContents>
        </TextTooltip>
      </TertiaryButton>
    </React.Fragment>
  );
};

const getOrderedFilteredSearchResults = ({variables, searchTerm, variableKindsToExclude}: any) => {
  return _.filter(
    _.orderBy(
      _.filter(
        variables,
        (variable) =>
          variable.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
          variable.identifier.toLowerCase().includes(searchTerm.toLowerCase()) ||
          variable.identifier.replace(/_+/g, ' ').toLowerCase().includes(searchTerm.toLowerCase()),
      ),
      [(variable) => variable.label.toLowerCase()],
      ['asc'],
    ),
    (variable) => {
      return !variableKindsToExclude.includes(variable.kind);
    },
  );
};

// TODO(Kevin): Remove when deleting isEnabledUrlVariableFormatting feature flag
const conditionForVariablesForUrlFormattingFeatureFlag = ({
  isEnabledUrlVariableFormatting,
  variable,
}: any) => {
  return isEnabledUrlVariableFormatting
    ? ![
        HardcodedSystemVariables.ALL_VARIABLES.URL_CONFIRMATION.value,
        HardcodedSystemVariables.ALL_VARIABLES.URL_PROJECT.value,
        HardcodedSystemVariables.ALL_VARIABLES.URL_QUOTE.value,
        HardcodedSystemVariables.ALL_VARIABLES.URL_INVOICE.value,
        HardcodedSystemVariables.ALL_VARIABLES.URL_INVOICE_RECEIPT.value,
      ].includes(variable.value)
    : ![
        HardcodedSystemVariables.ALL_VARIABLES.CONFIRMATION_LINK.value,
        HardcodedSystemVariables.ALL_VARIABLES.CONFIRMATION_URL.value,
        HardcodedSystemVariables.ALL_VARIABLES.PROJECT_LINK.value,
        HardcodedSystemVariables.ALL_VARIABLES.PROJECT_URL.value,
        HardcodedSystemVariables.ALL_VARIABLES.QUOTE_LINK.value,
        HardcodedSystemVariables.ALL_VARIABLES.QUOTE_URL.value,
        HardcodedSystemVariables.ALL_VARIABLES.INVOICE_LINK.value,
        HardcodedSystemVariables.ALL_VARIABLES.INVOICE_RECEIPT_LINK.value,
        HardcodedSystemVariables.ALL_VARIABLES.INVOICE_RECEIPT_URL.value,
        HardcodedSystemVariables.ALL_VARIABLES.INVOICE_URL.value,
      ].includes(variable.value);
};

type OwnVariableListProps = {
  organization: any;
  containerStyle?: any;
  isCollapsible?: boolean;
  hardcodedSystemVariables?: any[];
  variableKindsToExclude?: any[];
  isShowingVariableListByDefault?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'VariableListProps' circularly referenc... Remove this comment to see the full error message
type VariableListProps = OwnVariableListProps & typeof VariableList.defaultProps;

// @ts-expect-error TS(7022): 'VariableList' implicitly has type 'any' because i... Remove this comment to see the full error message
const VariableList = ({
  organization,
  hardcodedSystemVariables,
  isCollapsible,
  containerStyle,
  variableKindsToExclude,
  isShowingVariableListByDefault,
}: VariableListProps) => {
  const [isShowingVariableList, setIsShowingVariableList] = useState(
    isShowingVariableListByDefault,
  );
  const [searchTerm, setSearchTerm] = useState('');
  const dynamicVariables = [...organization.systemVariables, ...organization.customVariables]
    .filter(
      (variable) =>
        variable.identifier && !_.has(VariableFormat.DYNAMIC_VARIABLE_FORMATS, variable.format),
    )
    .map((variable) => ({
      identifier: `{${variable.identifier}}`,
      label: variable.name,
      kind: variable.kind,
    }));
  const systemVariables = HardcodedSystemVariables.getDisplayValues(hardcodedSystemVariables)
    .filter((variable) =>
      conditionForVariablesForUrlFormattingFeatureFlag({
        isEnabledUrlVariableFormatting: organization.features.isEnabledUrlVariableFormatting,
        variable,
      }),
    )
    .filter((variable) => !_.find(dynamicVariables, {identifier: variable.identifier}))
    .map((variable) => ({
      identifier: variable.identifier,
      label: variable.displayDescription,
      kind: variable.kind,
    }));
  const variables = getOrderedFilteredSearchResults({
    variables: [...dynamicVariables, ...systemVariables],
    searchTerm,
    variableKindsToExclude,
  });

  return (
    <React.Fragment>
      {isCollapsible && (
        <React.Fragment>
          <TertiaryButton
            onPress={() => {
              setIsShowingVariableList(!isShowingVariableList);
              setSearchTerm('');
            }}
            iconRight={isShowingVariableList ? Icon.ChevronUp : Icon.ChevronDown}
            iconSize={12}
            text={`${isShowingVariableList ? 'Hide' : 'Show'} Variable List`}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {isShowingVariableList && (
        <React.Fragment>
          <SearchBar
            placeholder={'Search variables...'}
            onChangeText={(text) => {
              setSearchTerm(text);
            }}
            style={{flex: 1}}
          />
          <Space height={16} />
          <VariableListContainer style={{...containerStyle}}>
            <ScrollView>
              <ScrollView style={{flex: 1, padding: 16}} horizontal>
                <Column>
                  {variables.map((variable) => {
                    return (
                      <React.Fragment key={`${variable.identifier}-${variable.label}`}>
                        <Variable label={variable.label} identifier={variable.identifier} />
                        <Space height={20} />
                      </React.Fragment>
                    );
                  })}
                </Column>
              </ScrollView>
            </ScrollView>
          </VariableListContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

VariableList.defaultProps = {
  containerStyle: {},
  isCollapsible: true,
  hardcodedSystemVariables: [],
  variableKindsToExclude: [],
  isShowingVariableListByDefault: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
VariableList.fragment = gql`
  fragment VariableList on Organization {
    id
    customVariables {
      id
      name
      identifier
      format
      kind
    }
    systemVariables {
      id
      name
      identifier
      format
      kind
    }
    features {
      isEnabledUrlVariableFormatting: isEnabled(feature: "URL_VARIABLE_FORMATTING")
    }
  }
`;

export default VariableList;
