// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import useCompleteJobManuallyMutation from '@shared/modules/Job/hooks/useCompleteJobManuallyMutation';

type OwnProps = {
  job: any;
  handleClose?: (...args: any[]) => any;
  isOpen?: boolean;
  onSuccess?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobCompleteManuallyModal.defaultProps;

// @ts-expect-error TS(7022): 'JobCompleteManuallyModal' implicitly has type 'an... Remove this comment to see the full error message
const JobCompleteManuallyModal = ({job, handleClose, isOpen, onSuccess}: Props) => {
  const {handleSubmit, submitting} = useCompleteJobManuallyMutation({
    job,
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <ActionModal
      title={'Manually Finish Move'}
      message={
        `Are you sure you want to manually finish ${Job.getFullName(job)}? ` +
        `You should only do this if you know the crew finished the move but ` +
        `forgot to finish it on the tablet.`
      }
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      icon={Icon.FlagCheckered}
      isSubmitting={submitting}
      isDisabled={submitting}
    />
  );
};

JobCompleteManuallyModal.defaultProps = {
  onSuccess: () => {},
  isOpen: false,
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCompleteManuallyModal.fragment = gql`
  ${Job.getFullName.fragment}
  fragment JobCompleteManuallyModal on Job {
    id
    ...Job_getFullName
  }
`;

export default JobCompleteManuallyModal;
