// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const CheckButton = Styled.ButtonV2`
`;

const CheckContainer = Styled.View`
  height: ${({
    // @ts-expect-error TS(2339): Property 'desktop' does not exist on type 'ThemePr... Remove this comment to see the full error message
    desktop,
  }) => (desktop ? '22' : '24')}px;
  width: ${({
    // @ts-expect-error TS(2339): Property 'desktop' does not exist on type 'ThemePr... Remove this comment to see the full error message
    desktop,
  }) => (desktop ? '28' : '32')}px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 2px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isCompleted' does not exist on type 'The... Remove this comment to see the full error message
    isCompleted,
  }) => (isCompleted ? colors.green.status : colors.gray.border)}
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isCompleted' does not exist on type 'The... Remove this comment to see the full error message
    isCompleted,
  }) => (isCompleted ? colors.green.status : colors.white)}
`;

type OwnProps = {
  task: any;
  onPress?: (...args: any[]) => any;
  isDisabled?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TaskIsCompletedCheckbox.defaultProps;

// @ts-expect-error TS(7022): 'TaskIsCompletedCheckbox' implicitly has type 'any... Remove this comment to see the full error message
const TaskIsCompletedCheckbox = ({task, onPress, isDisabled, style}: Props) => {
  const responsive = useResponsive();
  return (
    <CheckButton key={task.isCompleted} disabled={isDisabled} onPress={onPress} style={style}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <CheckContainer isCompleted={task.isCompleted} {...responsive}>
        {task.isCompleted && <Icon source={Icon.Check} color={colors.white} size={11} />}
      </CheckContainer>
    </CheckButton>
  );
};

TaskIsCompletedCheckbox.defaultProps = {
  onPress: null,
  isDisabled: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskIsCompletedCheckbox.fragment = gql`
  fragment TaskIsCompletedCheckbox on Task {
    id
    isCompleted
  }
`;

export default TaskIsCompletedCheckbox;
