// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import EmailThreadName from 'modules/Email/components/EmailThreadName';

const EmailDateContainer = Styled.View`
  margin-left: auto;
`;

const DateTimeText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const EmailThreadButton = Styled.ButtonV2`
  background-color: ${colors.white};
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const CustomerEmailThreadItem = ({emailThread, setSelectedThread}: any) => {
  const responsive = useResponsive();
  return (
    <EmailThreadButton onPress={() => setSelectedThread(emailThread)}>
      <EmailThreadName emailThread={emailThread} />
      <Space width={12} />
      <EmailDateContainer>
        <DateTimeText responsive={responsive} numberOfLines={1}>
          {emailThread.mostRecentCommunicationDate &&
            Datetime.convertToDisplayDatetime(emailThread.mostRecentCommunicationDate)}
        </DateTimeText>
      </EmailDateContainer>
    </EmailThreadButton>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadItem.fragment = gql`
  ${EmailThreadName.fragment}
  fragment CustomerEmailThreadItem on Thread {
    id
    mostRecentCommunicationDate
    ...EmailThreadName
  }
`;

export default CustomerEmailThreadItem;
