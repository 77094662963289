// Libraries
import React from 'react';

// Supermove
import {ExternalLink, ScrollView, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App

import useLoginForm from '@shared/modules/Authentication/hooks/useLoginForm';
import Field from 'modules/App/components/Field';

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
`;

const Logo = Styled.H1`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H2`
  margin-top: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  width: 120px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Links = Styled.View`
  margin-vertical: 60px;
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const NewVisitor = Styled.H6`
  ${fontWeight(700)}
`;

const Website = Styled.H6`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const LoginPage = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const {form, submitting, handleSubmit} = useLoginForm({
    onSuccess: () => {
      window.location.href = '/';
    },
  });

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
      <Content {...responsive}>
        <Logo>Supermove</Logo>
        <Title>Sign in to your account</Title>
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'email'}
          label={'Email'}
          input={{
            placeholder: 'Enter your email',
          }}
        />
        <Space height={20} />
        <Field
          {...form}
          component={TextInput}
          name={'password'}
          label={'Password'}
          actionText={'Forgot Password?'}
          handleAction={() => navigator.push('/password/reset')}
          input={{
            secureTextEntry: true,
            placeholder: 'Enter your password',
            onSubmitEditing: handleSubmit,
          }}
        />
        <Actions>
          <SubmitButton loading={submitting} onPress={handleSubmit}>
            <SubmitText>Continue</SubmitText>
          </SubmitButton>
        </Actions>
        <Links>
          <Row>
            <NewVisitor>New to Supermove?</NewVisitor>
          </Row>
          <Row>
            <ExternalLink url={'https://www.supermove.co/'}>
              <Website>View our website</Website>
            </ExternalLink>
            <ExternalLink url={'https://www.supermove.co/legal'}>
              <Website>View privacy policy</Website>
            </ExternalLink>
          </Row>
        </Links>
      </Content>
    </ScrollView>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
LoginPage.propTypes = {};

LoginPage.defaultProps = {};

export default LoginPage;
