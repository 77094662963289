// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency, Json, Percent} from '@supermove/utils';

const prepareFeatures = ({features}: any) => {
  return Json.stringify(Json.toUpperCaseKeys(Json.toSnakeCaseKeys(features)));
};

type OwnUpdateEmployeeMutationProps = {
  values: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  setMutationErrors: (...args: any[]) => any;
  submitForm: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'UpdateEmployeeMutationProps' circularl... Remove this comment to see the full error message
type UpdateEmployeeMutationProps = OwnUpdateEmployeeMutationProps &
  typeof UpdateEmployeeMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateEmployeeMutation' implicitly has type 'any'... Remove this comment to see the full error message
const UpdateEmployeeMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: UpdateEmployeeMutationProps) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      userId: values.userId,
      moverPositionIds: values.moverPositionIds,
      primaryMoverPositionId: values.primaryMoverPositionId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      payrollIdentifier: values.payrollIdentifier,
      tabletPin: values.tabletPin,
      position: values.position,
      branchCode: values.branchCode,
      billingHourlyRate: Currency.toMutation(values.billingHourlyRate),
      compensationPercentage: Percent.toMutation(values.compensationPercentage),
      isDriver: values.isDriver,
      isActive: values.isActive,
      features: prepareFeatures({features: values.features}),
    }}
    mutation={UpdateEmployeeMutation.mutation}
    onCompleted={({updateEmployee: {user, errors}}: any) => {
      submitForm();
      setMutationErrors(errors);

      if (user) {
        onSuccess(user);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

UpdateEmployeeMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateEmployeeMutation.mutation = gql`
  mutation UpdateEmployeeMutation(
    $userId: Int!,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String!,
    $payrollIdentifier: String!,
    $tabletPin: String!,
    $position: String!,
    $branchCode: String!,
    $billingHourlyRate: Int!,
    $compensationPercentage: Float!,
    $isDriver: Boolean!,
    $isActive: Boolean!,
    $features: JSONString!,
    $moverPositionIds: [Int],
    $primaryMoverPositionId: Int,
  ) {
    updateEmployee(
      userId: $userId,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      payrollIdentifier: $payrollIdentifier,
      tabletPin: $tabletPin,
      position: $position,
      branchCode: $branchCode,
      billingHourlyRate: $billingHourlyRate,
      compensationPercentage: $compensationPercentage,
      isDriver: $isDriver,
      isActive: $isActive,
      features: $features,
      moverPositionIds: $moverPositionIds,
      primaryMoverPositionId: $primaryMoverPositionId,
    ) {
      ${gql.errors}
      user {
        id
        firstName
        lastName
        phoneNumber
        payrollIdentifier
        tabletPin
        position
        isDriver
        isActive
        features
      }
    }
  }
`;

export default UpdateEmployeeMutation;
