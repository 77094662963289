// Libraries
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import LargeModal from 'modules/App/Modal/components/LargeModal';

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Header = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
`;

const Cell = Styled.View`
  width: 140px;
`;

const CellText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  background-color: ${(props) => ((props as any).index % 2 === 0 ? colors.white : colors.blue.accent)};
`;

const TableHeader = () => (
  <Header>
    <Cell style={{flex: 1}}>
      <CellText style={{textAlign: 'left'}}>Contractor</CellText>
    </Cell>
    <Cell>
      <CellText># Jobs</CellText>
    </Cell>
  </Header>
);

const CalendarDayOrganizationSummaryItem = ({index, calendarDayOrganizationSummary}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={index}>
      <Cell style={{flex: 1}}>
        <CellText style={{textAlign: 'left'}}>
          {calendarDayOrganizationSummary.organization.name}
        </CellText>
      </Cell>
      <Cell>
        <CellText>{calendarDayOrganizationSummary.jobsCount}</CellText>
      </Cell>
    </Row>
  );
};

const CalendarDaySummaryModal = ({isOpen, calendarDay, onClose}: any) => {
  return (
    <LargeModal isOpen={isOpen} handleClose={onClose}>
      <Title>{Datetime.convertToDisplayDate(calendarDay.day.value)}</Title>
      <Space height={20} />
      <FlatList
        data={calendarDay.calendarDayOrganizationSummaries}
        keyExtractor={(calendarDayOrganizationSummary: any, index: any) => String(index)}
        renderItem={({item: calendarDayOrganizationSummary, index}: any) => (
          <CalendarDayOrganizationSummaryItem
            index={index}
            calendarDayOrganizationSummary={calendarDayOrganizationSummary}
          />
        )}
        ListHeaderComponent={TableHeader}
      />
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarDaySummaryModal.fragment = gql`
  fragment CalendarDaySummaryModal on CalendarDay {
    day {
      id
      value
    }
    calendarDayOrganizationSummaries {
      jobsCount
      organization {
        id
        name
      }
    }
  }
`;

export default CalendarDaySummaryModal;
