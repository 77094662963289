// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {BillRule, BillRuleType, Formula} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const handleChangeKind = ({kind, prevKind, form, field}: any) => {
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const isValueTypeChanged =
    BillRuleType.getValueType(kind) !== BillRuleType.getValueType(prevKind);
  if (isValueDisabled || isValueTypeChanged) {
    form.setFieldValue(`${field}.value`, '');
    form.setFieldValue(`${field}.stringValue`, '');
    form.setFieldValue(`${field}.valueFormulaId`, null);
  }
};

const BillRuleTypeFieldsV1 = ({form, field}: any) => {
  const {data} = useQuery(BillRuleTypeFieldsV1.query, {
    fetchPolicy: 'cache-and-network',
  });

  const billRuleTypeForm = _.get(form.values, field);
  const {kind} = billRuleTypeForm;
  const isValueDisabled = kind === BillRuleKind.WAIVE_OVERTIME;
  const formulas = _.get(data, 'viewer.viewingOrganization.formulas', []);
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Kind'}
        name={`${field}.kind`}
        component={DropdownInput}
        isRequired
        isResponsive
        input={{
          isPortaled: true,
          options: BillRule.KINDS_DROPDOWN_OPTIONS_V1,
          placeholder: 'Select a kind',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          onChangeValue: (value: any, option: any, prevValue: any) =>
            handleChangeKind({kind: value, prevKind: prevValue, form, field}),
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
      <TextInputWithDropdown
        isResponsive
        isPortaled
        form={form}
        index={1}
        textValueField={`${field}.name`}
        dropdownValueField={`${field}.nameFormulaId`}
        clearTextValue={''}
        label={'Name'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={'Formula or custom name'}
        noOptionsMessage={'No formula options'}
        isRequired={() =>
          !_.get(form.values, `${field}.name`) || !_.get(form.values, `${field}.nameFormulaId`)
        }
      />
      <Space height={16} />
      <TextInputWithDropdown
        isResponsive
        isPortaled
        form={form}
        index={2}
        textValueField={`${field}.value`}
        dropdownValueField={`${field}.valueFormulaId`}
        clearTextValue={''}
        label={'Value'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={isValueDisabled ? 'N/A' : 'Formula or custom value'}
        noOptionsMessage={'No formula options'}
        disabled={isValueDisabled}
        onBlur={() => {
          const billRuleTypeForm = _.get(form.values, `${field}`);
          const value = BillRuleTypeForm.getFormValueFromInput(billRuleTypeForm);
          form.setFieldValue(`${field}.value`, value);
        }}
      />
      <Space height={16} />
      <TextInputWithDropdown
        isResponsive
        isPortaled
        form={form}
        index={3}
        textValueField={`${field}.description`}
        dropdownValueField={`${field}.descriptionFormulaId`}
        clearTextValue={''}
        label={'Description'}
        dropdownOptions={Formula.getFormulaDropdownOptions(formulas)}
        placeholder={'Formula or custom description'}
        noOptionsMessage={'No formula options'}
      />
      <Space height={16} />
      <SwitchField
        isResponsive
        form={form}
        field={`${field}.isVisibleToCustomer`}
        labelRight={'Show Customers?'}
        hint={'When enabled, this rule will be displayed to the customer when used on a bill.'}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillRuleTypeFieldsV1.query = gql`
  ${Formula.getFormulaDropdownOptions.fragment}
  query BillRuleTypeFieldsV1 {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        formulas {
          id
          ...Formula_getFormulaDropdownOptions
        }
      }
    }
  }
`;

export default BillRuleTypeFieldsV1;
