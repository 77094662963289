/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import CreateAttachmentForm from '@shared/modules/File/forms/CreateAttachmentForm';
import {PageLoadingIndicator} from 'modules/App/components';
import AddAttachmentModal from 'modules/Job/V2/Move/components/AddAttachmentModal';

const Container = Styled.View`
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Text = Styled.H7`
  color: ${colors.gray.secondary};
`;

const UnattachedText = Styled.H8`
  color: ${colors.gray.secondary};
`;

const AttachmentRow = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const DeleteButton = Styled.Button`
  height: 25px;
  width: 25px;
  margin: 5px;
  padding-horizontal: 12px;
  background-color: ${colors.Pink600};
`;

const AddButton = Styled.Button`
  height: 25px;
  width: 25px;
  margin: 5px;
  padding-horizontal: 12px;
`;

const Button = Styled.Button`
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => ((props as any).mobile ? '100%' : 'fit-content')};
  height: 40px;
  padding-horizontal: 10px;
  margin-top: ${(props) => ((props as any).mobile && !(props as any).isFirst ? '10px' : '0px')};
  margin-right: ${(props) => ((props as any).mobile ? '0px' : '10px')};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ATTACHMENT_CATEGORY_KINDS_DROPDOWN_OPTIONS = [
  {
    label: 'Email Template',
    value: 'EMAIL_TEMPLATE_ATTACHMENT',
  },
];

const EmailTemplateAttachmentsSelectorContent = ({form, name, data, refetch}: any) => {
  const attachments = data.viewer.viewingOrganization.emailTemplateAttachments;
  const fieldName = name ? `${name}.attachmentIds` : 'attachmentIds';

  const {
    isOpen: isOpenAddAttachmentModal,
    handleOpen: handleOpenAddAttachmentModal,
    handleClose: handleCloseAddAttachmentModal,
  } = useModal();

  const createAttachmentForm = CreateAttachmentForm.new({
    // @ts-expect-error TS(2322): Type '"EMAIL_TEMPLATE_ATTACHMENT"' is not assignab... Remove this comment to see the full error message
    attachmentCategoryKinds: ['EMAIL_TEMPLATE_ATTACHMENT'],
    description: '',
  });

  const removeAttachmentId = ({attachmentIds, attachmentId}: any) => {
    return attachmentIds.filter((id: any) => {
      return id !== attachmentId;
    });
  };

  const getAttachmentNameForId = ({attachmentId, attachments}: any) => {
    const attachment = _.find(attachments, (currAttachment) => currAttachment.id === attachmentId);
    return attachment ? attachment.file.name : 'Unknown';
  };

  const getAvailableAttachments = ({attachments, form, fieldName}: any) => {
    const isAttached = ({attachment, form, fieldName}: any) =>
      getFieldValue({form, name: fieldName}).includes(attachment.id);

    return attachments.filter((attachment: any) => !isAttached({attachment, form, fieldName}));
  };

  const getFieldValue = ({form, name}: any) => {
    return _.get(form.values, name);
  };

  return (
    <Container>
      <RowSpace />
      <Text>{`Default Attachments (${getFieldValue({form, name: fieldName}).length})`}</Text>
      {getFieldValue({form, name: fieldName}).map((attachmentId: any) => {
        return (
          <AttachmentRow key={attachmentId}>
            <DeleteButton
              onPress={() => {
                form.setFieldValue(
                  fieldName,
                  removeAttachmentId({
                    attachmentIds: getFieldValue({form, name: fieldName}),
                    attachmentId,
                  }),
                );
              }}
            >
              <Icon color={colors.white} size={Icon.Sizes.Small} source={Icon.Trash} />
            </DeleteButton>
            <ButtonSpace />
            <Text>{getAttachmentNameForId({attachmentId, attachments})}</Text>
          </AttachmentRow>
        );
      })}
      <RowSpace />
      <Text>{`Available Attachments (${
        getAvailableAttachments({attachments, form, fieldName}).length
      })`}</Text>
      {getAvailableAttachments({attachments, form, fieldName}).map((attachment: any) => {
        return (
          <AttachmentRow key={attachment.id}>
            <AddButton
              onPress={() => {
                form.setFieldValue(fieldName, [
                  ...getFieldValue({form, name: fieldName}),
                  attachment.id,
                ]);
              }}
            >
              <Icon color={colors.white} size={Icon.Sizes.Small} source={Icon.Plus} />
            </AddButton>
            <ButtonSpace />
            <UnattachedText>{attachment.file.name}</UnattachedText>
          </AttachmentRow>
        );
      })}
      <Space height={10} />
      <Button onPress={handleOpenAddAttachmentModal}>
        <ButtonText>Upload a New Attachment</ButtonText>
      </Button>
      <AddAttachmentModal
        isOpen={isOpenAddAttachmentModal}
        handleClose={handleCloseAddAttachmentModal}
        viewer={data.viewer}
        refetch={refetch}
        initialCreateAttachmentForm={createAttachmentForm}
        attachmentCategoryKindsDropdownOptions={ATTACHMENT_CATEGORY_KINDS_DROPDOWN_OPTIONS}
      />
    </Container>
  );
};

type OwnEmailTemplateAttachmentsSelectorProps = {
  form: any;
  name?: string;
};

// @ts-expect-error TS(2456): Type alias 'EmailTemplateAttachmentsSelectorProps'... Remove this comment to see the full error message
type EmailTemplateAttachmentsSelectorProps = OwnEmailTemplateAttachmentsSelectorProps &
  typeof EmailTemplateAttachmentsSelector.defaultProps;

// @ts-expect-error TS(7022): 'EmailTemplateAttachmentsSelector' implicitly has ... Remove this comment to see the full error message
const EmailTemplateAttachmentsSelector = ({form, name}: EmailTemplateAttachmentsSelectorProps) => {
  const {loading, data, refetch} = useQuery(EmailTemplateAttachmentsSelector.query, {
    fetchPolicy: 'network-only',
  });

  return (
    <Loading alwaysUpdate loading={loading} as={PageLoadingIndicator}>
      {() => (
        <EmailTemplateAttachmentsSelectorContent
          form={form}
          name={name}
          data={data}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

EmailTemplateAttachmentsSelector.defaultProps = {
  name: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailTemplateAttachmentsSelector.query = gql`
  ${AddAttachmentModal.fragment}

  query EmailTemplateAttachmentsSelector {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        emailTemplateAttachments {
          id
          file {
            id
            name
          }
        }
      }
      ...AddAttachmentModal
    }
  }
`;

export default EmailTemplateAttachmentsSelector;
