// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {BillLineItemsList} from 'modules/Bill/components';

const ProvisionalJobBillView = ({job}: any) => {
  return <BillLineItemsList hidePrices bill={job.mainBill} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProvisionalJobBillView.fragment = gql`
  ${BillLineItemsList.fragment}

  fragment ProvisionalJobBillView on Job {
    id
    mainBill {
      id
      ...BillLineItemsList
    }
  }
`;

export default ProvisionalJobBillView;
