// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover, useResponsive, useModal} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useCreateBillAndBillItemsMutation from '@shared/modules/Billing/hooks/useCreateBillAndBillItemsMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import Switch from 'modules/App/components/Switch';
import SelectBillTemplateModal from 'modules/Bill/components/SelectBillTemplateModal';

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderButtonsContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => ((props as any).mobile ? 'flex-end' : 'flex-start')};
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 27px;
  min-width: 34px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accentDark : colors.gray.border)}
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.blue.accentDark};
`;

const MobileButton = Styled.ButtonV2`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36;
  padding-horizontal: 12px;
  background-color: ${(props) => ((props as any).isSelected ? colors.blue.accent : colors.white)};
  border-color: ${(props) => ((props as any).isSelected ? colors.blue.interactive : colors.gray.border)};
  border-width: 1px;
  border-radius: 4px;
`;

const MobileButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const OptionsContainer = Styled.View`
  background-color: ${colors.white};
  width: 180px;
  border-radius: 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

const ActionContainer = Styled.ButtonV2`
  height: 28px;
  justify-content: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isHighlighted ? colors.hover : colors.white)};
  padding-horizontal: 12px;
  padding-vertical: 4px;
`;

const ActionText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary}
`;

const SwitchContainer = Styled.View`
  align-self: flex-end;
  margin-right: 4px;
`;

const Option = ({label, handlePress, index}: any) => {
  const {ref, isHovered} = useHover();
  return (
    <React.Fragment>
      {index > 0 && <Space height={4} />}
      <ActionContainer ref={ref} vars={{isHighlighted: isHovered}} onPress={handlePress}>
        <ActionText>{label}</ActionText>
      </ActionContainer>
    </React.Fragment>
  );
};

const BillingOptionsPopover = ({popover, updateProjectValuesModal}: any) => {
  // If the feature flags are off, the options will be removed from the array.
  const options = [
    {
      label: 'Edit Billing Values',
      modal: updateProjectValuesModal,
    },
  ].filter(Boolean);

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      key={popover.isOpen}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <OptionsContainer>
        <Space height={10} />
        {options.map((action, index) => {
          return (
            <Option
              key={action.label}
              index={index}
              label={action.label}
              handlePress={() => {
                action.modal.handleOpen();
                popover.handleClose();
              }}
            />
          );
        })}
        <Space height={10} />
      </OptionsContainer>
    </Popover>
  );
};

const PaymentOptionsPopover = ({
  project,
  popover,
  recordPaymentModal,
  chargeCreditCardModal,
}: any) => {
  const allPaymentActions = [
    {label: 'Record Payment', modal: recordPaymentModal},
    ...List.insertIf(Project.getIsEnabledCreditCards(project), {
      label: 'Charge Credit Card',
      modal: chargeCreditCardModal,
    }),
  ];

  // Removes conditional payment actions that don't apply to this organization.
  const paymentActions = _.filter(allPaymentActions, Boolean);

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      key={popover.isOpen}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <OptionsContainer>
        <Space height={10} />
        {paymentActions.map((action, index) => {
          return (
            <Option
              key={action.label}
              index={index}
              label={action.label}
              handlePress={() => {
                action.modal.handleOpen();
                popover.handleClose();
              }}
            />
          );
        })}
        <Space height={10} />
      </OptionsContainer>
    </Popover>
  );
};

const ReorderSwitch = ({isReordering, setIsReordering}: any) => {
  return (
    <SwitchContainer>
      <Switch isOn={isReordering} onChange={setIsReordering} label={'Reorder'} />
    </SwitchContainer>
  );
};

const AddBillPopover = ({popover, selectBillTemplateModal, addBillModal, organization}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.BottomStart}
      width={200}
      offset={[0, 4]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          addBillModal.handleOpen();
          popover.handleClose();
        }}
      >
        Add new bill
      </ActionMenuPopover.MenuItem>
      {organization.features.isEnabledAddBillTemplateToMoveProject && (
        <React.Fragment>
          <Space height={4} />
          <ActionMenuPopover.MenuItem
            onPress={() => {
              selectBillTemplateModal.handleOpen();
              popover.handleClose();
            }}
          >
            Select bill template
          </ActionMenuPopover.MenuItem>
        </React.Fragment>
      )}
    </ActionMenuPopover>
  );
};

const HeaderButtons = ({addBillPopover, billingOptionsPopover, paymentActionsPopover}: any) => {
  return (
    <HeaderButtonsContainer>
      <Popover.Content innerRef={addBillPopover.ref}>
        <Button onPress={addBillPopover.handleToggle}>
          <Icon
            source={Icon.Plus}
            size={12}
            color={colors.blue.accentDark}
            style={{paddingBottom: 1}}
          />
          <Space width={5} />
          <ButtonText>Add Bill</ButtonText>
        </Button>
      </Popover.Content>
      <Space width={8} />
      <Popover.Content innerRef={paymentActionsPopover.ref}>
        <Button
          onPress={paymentActionsPopover.handleToggle}
          isSelected={paymentActionsPopover.isOpen}
        >
          <ButtonText>Payment</ButtonText>
          <Space width={11} />
          <Icon
            source={Icon.CaretDown}
            size={14}
            color={colors.blue.accentDark}
            style={{paddingBottom: 1}}
          />
        </Button>
      </Popover.Content>
      <Space width={8} />
      <Popover.Content innerRef={billingOptionsPopover.ref}>
        <Button
          onPress={billingOptionsPopover.handleToggle}
          isSelected={billingOptionsPopover.isOpen}
        >
          <Icon source={Icon.EllipsisV} size={14} color={colors.blue.accentDark} />
        </Button>
      </Popover.Content>
    </HeaderButtonsContainer>
  );
};

const HeaderButtonsMobile = ({
  paymentActionsPopover,
  billingOptionsPopover,
  addBillPopover,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <HeaderButtonsContainer mobile>
      <React.Fragment>
        <Popover.Content innerRef={addBillPopover.ref}>
          <MobileButton onPress={addBillPopover.handleToggle}>
            <Icon source={Icon.Plus} size={14} color={colors.blue.accentDark} />
            <Space width={7} />
            <MobileButtonText color={colors.blue.accentDark}>Add Bill</MobileButtonText>
          </MobileButton>
        </Popover.Content>
        <Space width={4} />
      </React.Fragment>
      <Popover.Content innerRef={paymentActionsPopover.ref}>
        <MobileButton
          onPress={paymentActionsPopover.handleToggle}
          isSelected={paymentActionsPopover.isOpen}
        >
          <MobileButtonText
            color={paymentActionsPopover.isOpen ? colors.blue.interactive : colors.blue.accentDark}
          >
            Payment
          </MobileButtonText>
          <Space width={7} />
          <Icon
            source={Icon.CaretDown}
            size={14}
            color={paymentActionsPopover.isOpen ? colors.blue.interactive : colors.blue.accentDark}
          />
        </MobileButton>
      </Popover.Content>
      <Space width={4} />
      <Popover.Content innerRef={billingOptionsPopover.ref}>
        <MobileButton
          onPress={billingOptionsPopover.handleToggle}
          isSelected={billingOptionsPopover.isOpen}
        >
          <Icon
            source={Icon.EllipsisV}
            size={14}
            color={billingOptionsPopover.isOpen ? colors.blue.interactive : colors.blue.accentDark}
            style={{paddingHorizontal: 4}}
          />
        </MobileButton>
      </Popover.Content>
    </HeaderButtonsContainer>
  );
};

const JobProjectBillingBlockHeader = ({
  job,
  addBillModal,
  updateProjectValuesModal,
  recordPaymentModal,
  chargeCreditCardModal,
  isReordering,
  setIsReordering,
  refetch,
}: any) => {
  const responsive = useResponsive();
  const billingOptionsPopover = usePopover();
  const paymentActionsPopover = usePopover();
  const addBillPopover = usePopover();
  const selectBillTemplateModal = useModal({
    name: 'Select Bill Template Modal',
    enableTracking: true,
  });
  const {isEnabledProjectBillingEditMobileView} = job.project.organization.features;

  const billForm = BillForm.new(job.project);
  const {form, handleSubmit, submitting} = useCreateBillAndBillItemsMutation({
    billForm,
    onSuccess: () => {
      refetch();
      selectBillTemplateModal.handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  const handleAddBill = (billTypeId = null, jobId = null) => {
    if (billTypeId) {
      const billType = _.find(
        job.project.projectType.billingLibrary.billTypes,
        ({id}) => id === billTypeId,
      );
      form.setFieldValue(`billForm`, BillForm.newFromBillType(job.project, {billType}));
      form.setFieldValue(`billForm.title`, `${billType.name}`);
    } else {
      form.setFieldValue(`billForm`, BillForm.new(job.project));
      form.setFieldValue(`billForm.title`, `New bill`);
    }
    form.setFieldValue(`billForm.jobId`, jobId);

    setTimeout(handleSubmit, 0);
  };

  return (
    <Header>
      <Title>Billing</Title>
      <Space width={8} />
      {responsive.mobile && isEnabledProjectBillingEditMobileView ? (
        <HeaderButtonsMobile
          billingOptionsPopover={billingOptionsPopover}
          paymentActionsPopover={paymentActionsPopover}
          addBillPopover={addBillPopover}
        />
      ) : (
        <React.Fragment>
          <HeaderButtons
            billingOptionsPopover={billingOptionsPopover}
            paymentActionsPopover={paymentActionsPopover}
            addBillPopover={addBillPopover}
          />
          <ReorderSwitch isReordering={isReordering} setIsReordering={setIsReordering} />
        </React.Fragment>
      )}
      <AddBillPopover
        popover={addBillPopover}
        selectBillTemplateModal={selectBillTemplateModal}
        addBillModal={addBillModal}
        organization={job.project.organization}
      />
      <PaymentOptionsPopover
        project={job.project}
        popover={paymentActionsPopover}
        recordPaymentModal={recordPaymentModal}
        chargeCreditCardModal={chargeCreditCardModal}
      />
      <BillingOptionsPopover
        popover={billingOptionsPopover}
        addBillModal={addBillModal}
        updateProjectValuesModal={updateProjectValuesModal}
      />
      <SelectBillTemplateModal
        key={selectBillTemplateModal.key}
        isOpen={selectBillTemplateModal.isOpen}
        handleClose={selectBillTemplateModal.handleClose}
        project={job.project}
        handleAddBill={handleAddBill}
        isSubmitting={submitting}
      />
    </Header>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectBillingBlockHeader.fragment = gql`
  ${SelectBillTemplateModal.fragment}
  ${BillForm.new.fragment}
  ${BillForm.newFromBillType.fragment}
  ${Project.getIsEnabledCreditCards.fragment}

  fragment JobProjectBillingBlockHeader on Job {
    id
    project {
      id
      organization {
        id
        features {
          isEnabledProjectBillingEditMobileView: isEnabled(
            feature: "PROJECT_BILLING_EDIT_MOBILE_VIEW"
          )
          isEnabledAddBillTemplateToMoveProject: isEnabled(
            feature: "ADD_BILL_TEMPLATE_TO_MOVE_PROJECT"
          )
        }
      }
      ...SelectBillTemplateModal
      ...BillForm_new
      ...BillForm_newFromBillType
      ...Project_getIsEnabledCreditCards
    }
  }
`;

export default JobProjectBillingBlockHeader;
