/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSearch, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SearchCrewsList from 'modules/Dispatch/Calendar/Day/RightPanel/components/SearchCrewsList';
import JobInfoContent from 'modules/Dispatch/Calendar/Day/components/JobInfoContent';

const BorderedContainer = Styled.View`
  flex: 1;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const ItemContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
  padding-vertical: 2px;
  padding-horizontal: 8px;
`;

const SearchBarInput = Styled.TextInput`
  margin-vertical: 2px;
  margin-horizontal: 8px;
  height: 40px;
  flex: 1;
  border-width: 0;
  color: ${colors.gray.secondary};
  background-color: ${colors.gray.background};
`;

const SearchContainer = Styled.View`
  margin-vertical: 8px;
  margin-horizontal: 8px;
  flex-direction: row;
  align-items: center;
  padding-left: 12px;
  border-radius: 4px;
  background-color: ${colors.gray.background};
`;

const Indicator = Styled.Loading`
  padding: 20px;
`;

const SearchJobsList = ({jobs, refetchCalendar, canAssignOrganization}: any) => {
  const {
    query,
    results: filteredJobs,
    setQuery,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: jobs,
    options: {keys: ['identifier', 'customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.Search} />
        <SearchBarInput
          placeholder={'Customer name, job name'}
          onChangeText={(text: any) => setQuery(text)}
          value={query}
        />
      </SearchContainer>
      <ScrollView>
        {filteredJobs.map((job, index) => {
          return (
            <ItemContainer key={(job as any).id} index={index}>
              <JobInfoContent
                job={job}
                crewUsersCount={Job.getDispatchJobUsersCount(job)}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchJobsList.fragment = gql`
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDispatchJobUsersNames.fragment}
  ${JobInfoContent.fragment}

  fragment SearchJobsList on Job {
    id
    identifier
    customer {
      id
      fullName
    }
    ...Job_getDispatchJobUsersCount
    ...Job_getDispatchJobUsersNames
    ...JobInfoContent
  }
`;

const OrganizationAllCrewsList = ({
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}: any) => {
  const {loading, data, refetch} = useQuery(OrganizationAllCrewsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds, organizationId},
  });

  if (loading) {
    return <Indicator />;
  }

  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <SearchCrewsList
        crews={data.dispatchCalendarDay.allCrews}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

OrganizationAllCrewsList.query = gql`
  ${SearchCrewsList.fragment}

  query OrganizationAllCrewsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      allCrews(organizationId: $organizationId) {
        id
        ...SearchCrewsList
      }
    }
  }
`;

const OrganizationUnassignedCrewsList = ({
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}: any) => {
  const {loading, data, refetch} = useQuery(OrganizationUnassignedCrewsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds, organizationId},
  });

  if (loading) {
    return <Indicator />;
  }
  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <SearchCrewsList
        crews={data.dispatchCalendarDay.unassignedCrews}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

OrganizationUnassignedCrewsList.query = gql`
  ${SearchCrewsList.fragment}

  query OrganizationUnassignedCrewsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
    $organizationId: Int!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      unassignedCrews(organizationId: $organizationId) {
        id
        ...SearchCrewsList
      }
    }
  }
`;

const OrganizationCrewsLists = ({
  dispatchJobsSummaryKind,
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}: any) => {
  switch (dispatchJobsSummaryKind) {
    case 'ALL':
      return (
        <OrganizationAllCrewsList
          viewerDayId={viewerDayId}
          dayIds={dayIds}
          organizationId={organizationId}
          refetchCalendar={refetchCalendar}
          canAssignOrganization={canAssignOrganization}
        />
      );
    case 'UNASSIGNED':
      return (
        <OrganizationUnassignedCrewsList
          viewerDayId={viewerDayId}
          dayIds={dayIds}
          organizationId={organizationId}
          refetchCalendar={refetchCalendar}
          canAssignOrganization={canAssignOrganization}
        />
      );
    default:
      return null;
  }
};

export default OrganizationCrewsLists;
