// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput, Form, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {OrganizationModel} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import {ProjectTypeFormToFormType} from '@shared/modules/Project/forms/ProjectTypeForm';
import ProjectTypeColorDropdownV2 from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeColorDropdownV2';
import ProjectTypeDocumentFlows from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeDocumentFlows';

const FieldContainer = Styled.View<{index: number}>`
  zIndex: ${(props) => 100 - props.index}
`;

const getBillingLibraryOptions = ({organization}: {organization: OrganizationModel}) => {
  return organization.billingLibraries.map((billingLibrary) => {
    return {
      label: billingLibrary.name,
      value: billingLibrary.id,
    };
  });
};

const getBillTypeOptions = ({
  form,
  organization,
}: {
  form: Form<ProjectTypeFormToFormType>;
  organization: OrganizationModel;
}) => {
  const selectedBillingLibraryId = form.values.projectTypeForm.billingLibraryId;
  const {billTypes} = _.find(
    organization.billingLibraries,
    (billingLibrary) => billingLibrary.id === selectedBillingLibraryId,
  )!;

  return billTypes.map((billType) => ({
    label: billType.name,
    value: _.toNumber(billType.id),
  }));
};

const ProjectTypeFields = ({
  form,
  organization,
  isStorageSettingsPage,
}: {
  form: Form<ProjectTypeFormToFormType>;
  organization: OrganizationModel;
  isStorageSettingsPage: boolean;
}) => {
  const fieldName = 'projectTypeForm';
  const billTypesField = `${fieldName}.billTypeIds`;
  const isLongDistanceField = `${fieldName}.isLongDistance`;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Name'}
        index={0}
        name={`${fieldName}.name`}
        isResponsive
        isRequired
        input={{
          autoFocus: true,
          placeholder: 'Enter name',
          disabled: !organization.isOwnerOfSettings,
        }}
      />
      <Space height={20} />
      <ProjectTypeColorDropdownV2
        form={form}
        field={`${fieldName}.color`}
        index={1}
        label={'Color'}
        placeholder={'Select project type color'}
        isDisabled={!organization.isOwnerOfSettings}
      />
      <Space height={20} />
      <FieldInput
        {...form}
        label={'Billing Library'}
        name={`${fieldName}.billingLibraryId`}
        component={DropdownInput}
        isResponsive
        isRequired
        index={2}
        input={{
          options: getBillingLibraryOptions({organization}),
          placeholder: 'Select billing library',
          setFieldValue: form.setFieldValue,
          isPortaled: true,
          onChangeValue: () => {
            form.setFieldValue(billTypesField, undefined);
          },
          style: {flex: 1},
        }}
      />
      {!!form.values.projectTypeForm.billingLibraryId && !isStorageSettingsPage && (
        <React.Fragment>
          <Space height={20} />
          <FieldInput
            {...form}
            label={'Default Bill Template(s)'}
            name={billTypesField}
            component={MultiDropdownCheckboxInput}
            index={3}
            input={{
              options: getBillTypeOptions({form, organization}),
              placeholder: `Select billing template(s)`,
              setFieldValue: form.setFieldValue,
              isPortaled: true,
              style: {flex: 1},
            }}
          />
        </React.Fragment>
      )}
      <Space height={20} />
      <FieldContainer index={4}>
        <ProjectTypeDocumentFlows
          projectTypeId={form.values.projectTypeForm.id}
          userFlows={organization.userFlows}
          form={form}
          field={`${fieldName}.projectTypeUserFlowForms`}
        />
      </FieldContainer>
      {!isStorageSettingsPage && (
        <FieldContainer index={5}>
          <Space height={20} />
          <SwitchField
            form={form}
            field={isLongDistanceField}
            index={4}
            labelRight={'This is a long distance project type.'}
          />
        </FieldContainer>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeFields.fragment = gql`
  ${ProjectTypeDocumentFlows.fragment}
  fragment ProjectTypeFields on Organization {
    id
    isOwnerOfSettings
    billingLibraries {
      id
      name
      billTypes {
        id
        name
      }
    }
    userFlows {
      id
      ...ProjectTypeDocumentFlows
    }
  }
`;
export default ProjectTypeFields;
