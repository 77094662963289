// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {useForm} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';

const EditInventoryNotesSheetContent = ({form}: any) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      setFieldValue={form.setFieldValue}
      name={'inventoryRoomsForm.notes'}
      input={{
        autoFocus: true,
        style: {height: 128, paddingTop: 8},
        multiline: true,
        placeholder: 'Enter survey notes',
      }}
    />
  );
};

const NotesModal = ({isOpen, handleClose, handleSubmit, label, children}: any) => {
  return (
    <SmallModal isOpen={isOpen} shouldCloseOnClickOutside={false}>
      <SmallModal.HeaderText>{label}</SmallModal.HeaderText>
      <Space height={16} />
      {children}
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          iconLeft={Icon.Check}
          color={colors.blue.interactive}
          onPress={handleSubmit}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const EditInventoryNotesSheet = ({
  isOpen,
  handleClose,
  inventoryRoomsForm,
  isDesktopNonTouchDevice,
}: any) => {
  // We clone the form and make edits there so that all the changes are made at once
  const form = useForm({initialValues: _.cloneDeep(inventoryRoomsForm.values)});
  const label = 'Survey Notes';
  const handleSubmit = () => {
    inventoryRoomsForm.setFieldValue(
      'inventoryRoomsForm.notes',
      form.values.inventoryRoomsForm.notes,
    );
    inventoryRoomsForm.setFieldValue('inventoryRoomsForm.isDirty', true);
    handleClose();
  };

  return isDesktopNonTouchDevice ? (
    <NotesModal isOpen={isOpen} handleClose={handleClose} handleSubmit={handleSubmit} label={label}>
      <EditInventoryNotesSheetContent form={form} />
    </NotesModal>
  ) : (
    <SheetWithFooter
      headerText={label}
      isOpen={isOpen}
      handleClose={handleClose}
      primaryActionText={'Save'}
      handlePrimaryAction={handleSubmit}
    >
      <EditInventoryNotesSheetContent form={form} />
    </SheetWithFooter>
  );
};

export default EditInventoryNotesSheet;
