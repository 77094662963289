// DEPRECATED(dan) in favor of SelectBillTemplateDrawer

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';

const SelectBillTemplateModal = ({
  isOpen,
  handleClose,
  project,
  handleAddBill,
  isSubmitting,
}: any) => {
  const [selectedBillType, setSelectedBillType] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [error, setError] = useState(null);

  const billTypes = _.get(project.projectType, 'billingLibrary.billTypes');
  const isStorageProject = project.projectType.category === ProjectTypeCategory.STORAGE;

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <SmallModal.BoldText>Select Bill Template</SmallModal.BoldText>
      {!isStorageProject && (
        <React.Fragment>
          <Space height={20} />
          <FieldInput
            component={DropdownInput}
            index={0}
            label={'Project or Job'}
            input={{
              isSearchable: true,
              placeholder: `Select a job to add to`,
              value: selectedJob,
              options: [
                {
                  label: `Project-${project.identifier}`,
                  value: null,
                },
                ..._.map(project.activeMoveJobs, (job) => ({
                  label: Job.getFullName(job),
                  value: _.toNumber(job.id),
                })),
              ],
              onChangeValue: (jobId: any) => {
                setSelectedJob(jobId);
                setError(null);
              },
              setFieldValue: () => {},
              style: {flex: 1},
            }}
          />
        </React.Fragment>
      )}

      <Space height={20} />
      <FieldInput
        name={'billTypeId'}
        component={DropdownInput}
        index={1}
        errors={{billTypeId: error}}
        touched={{billTypeId: !!error}}
        label={'Bill Template'}
        isRequired
        input={{
          isSearchable: true,
          placeholder: `Select a bill template to add`,
          value: selectedBillType,
          options: _.map(billTypes, ({id, name}) => ({
            label: name,
            value: id,
          })),
          onChangeValue: (billTypeId: any) => {
            setSelectedBillType(billTypeId);
            setError(null);
          },
          setFieldValue: () => {},
          style: {flex: 1},
        }}
      />
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          color={colors.blue.interactive}
          isSubmitting={isSubmitting}
          onPress={() => {
            if (selectedBillType) {
              handleAddBill(selectedBillType, selectedJob);
            } else {
              // @ts-expect-error TS(2345): Argument of type '"Select a bill template"' is not... Remove this comment to see the full error message
              setError('Select a bill template');
            }
          }}
        >
          Add
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SelectBillTemplateModal.fragment = gql`
  ${Job.getFullName.fragment}

  fragment SelectBillTemplateModal on Project {
    id
    identifier
    activeMoveJobs {
      id
      ...Job_getFullName
    }
    projectType {
      id
      category
      billingLibrary {
        id
        billTypes {
          id
          name
        }
      }
    }
  }
`;

export default SelectBillTemplateModal;
