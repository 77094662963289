// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {generated, gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App

import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DashboardLinkedToGlobalDashboardForm, {
  DashboardLinkedToGlobalDashboardFormEditFragment,
} from '@shared/modules/Reports/forms/DashboardLinkedToGlobalDashboardForm';
import useUpdateDashboardLinkedToGlobalDashboardMutation from '@shared/modules/Reports/hooks/useUpdateDashboardLinkedToGlobalDashboardMutation';

interface DashboardsPageUpdateDashboardVariablesModalProps {
  dashboard: generated.DashboardsPageUpdateDashboardVariablesModalFragment;
  newExploVariables: string;
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
}

const DashboardsPageUpdateDashboardVariablesModal = ({
  dashboard,
  newExploVariables,
  isOpen,
  handleClose,
  refetch,
}: DashboardsPageUpdateDashboardVariablesModalProps) => {
  const updateDashboardVariablesSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Dashboard filters have been updated!',
  });

  const dashboardLinkedToGlobalDashboardForm = DashboardLinkedToGlobalDashboardForm.edit({
    ...dashboard,
    exploVariables: newExploVariables,
  });
  const {form, submitting, handleSubmit} = useUpdateDashboardLinkedToGlobalDashboardMutation({
    dashboardLinkedToGlobalDashboardForm,
    onSuccess: () => {
      updateDashboardVariablesSuccessToast.handleToast();
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <CautionModal
      isOpen={isOpen}
      icon={Icon.ChartLineUp}
      title={`Update report view?`}
      message={'This will save the applied filters as the default view for the entire company.'}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DashboardsPageUpdateDashboardVariablesModal.fragment = gql`
  ${DashboardLinkedToGlobalDashboardFormEditFragment}
  fragment DashboardsPageUpdateDashboardVariablesModal on Dashboard {
    id
    ...DashboardLinkedToGlobalDashboardFormEdit
  }
`;

export default DashboardsPageUpdateDashboardVariablesModal;
