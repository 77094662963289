// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import CreateJobButton from 'modules/App/components/CreateJobButton';
import ImportOrganizationProjectsModal from 'modules/Organization/Settings/Jobs/components/ImportOrganizationProjectsModal';

const Actions = Styled.View`
  flex-direction: row;
  z-index: 100;
`;

const ButtonSpace = Styled.View`
  flex: 1;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const ButtonWrapper = Styled.View`
  height: 40px;
`;

const SecondaryButton = Styled.SecondaryButton`
  flex-direction: row;
  padding-horizontal: 10px;
  justify-content: center;
  flex: 1;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const excelTextObject = {
  title: 'Import Excel File: Upcoming Jobs',
  titleText: 'Upload an excel file of upcoming jobs into Supermove.',
  textValidationNames: [
    'Date',
    'Shipper Name',
    'Order Number',
    'Service',
    'Estimated Weight',
    'Hauled Weight',
    'Order Containers',
    'Job Start',
    'Job End',
    'Crew',
    'Hours',
    'Crew Names',
    'Equipment',
    'Origin Address',
    'Origin City',
    'Origin State',
    'Destination Address',
    'Destination City',
    'Destination State',
    'Note',
    'Service Instructions',
    'Van',
    'Driver',
    'Coordinator',
    'National Account Name',
    'Cleared On',
    'Cleared By',
    'Move Type',
    'Last Edited On',
    'Last Edited By',
    'Work Ticket',
    'Is Placeholder',
  ],
  textHeaders:
    'Date,Shipper Name,Order Number,Service,Estimated Weight,Hauled Weight,Order Containers,Job Start,Job End,Crew,Hours,Crew Names,Equipment,Origin Address,Origin City,Origin State,Destination Address,Destination City,Destination State,Note,Service Instructions,Van,Driver,Coordinator,National Account Name,Cleared On,Cleared By,Move Type,Last Edited On,Last Edited By,Work Ticket,Is Placeholder',
};

const SalesCalendarActions = ({viewer}: any) => {
  const responsive = useResponsive();
  const importOrganizationProjectsModal = useModal();

  return (
    <Actions {...responsive}>
      {viewer.viewingOrganization.canCreateJobs && (
        <ButtonWrapper>
          <CreateJobButton style={{zIndex: 2, alignSelf: 'stretch'}} />
        </ButtonWrapper>
      )}
      <Space width={10} />
      {viewer.viewingOrganization.features.isEnabledImportProjectsCalendarButton ? (
        <ButtonWrapper>
          <SecondaryButton onPress={importOrganizationProjectsModal.handleOpen}>
            <Icon color={colors.gray.primary} size={Icon.Sizes.Medium} source={Icon.FileDownload} />
            <ButtonText style={{marginLeft: 5}}>Import</ButtonText>
          </SecondaryButton>
          <ImportOrganizationProjectsModal
            key={`importOrganizationProjectsModal${importOrganizationProjectsModal.isOpen}`}
            isOpen={importOrganizationProjectsModal.isOpen}
            handleClose={importOrganizationProjectsModal.handleClose}
            textObject={excelTextObject}
          />
        </ButtonWrapper>
      ) : (
        <ButtonSpace />
      )}
    </Actions>
  );
};

SalesCalendarActions.fragment = gql`
  fragment SalesCalendarActions on User {
    id
    viewingOrganization {
      id
      canCreateJobs
      features {
        isEnabledImportProjectsCalendarButton: isEnabled(feature: "IMPORT_PROJECTS_CALENDAR_BUTTON")
      }
    }
  }
`;

export default SalesCalendarActions;
