// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput, Space, DropdownInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextMessageRecipientKind from '@shared/modules/Sms/enums/TextMessageRecipientKind';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';

const Description = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
  padding-horizontal: 4;
`;

const NameField = ({form, field, index, isSystemTemplate}: any) => {
  const systemTemplateKind = _.get(form.values, 'textMessageTemplateForm.systemTemplateKind');
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        index={index}
        label={'Template Name'}
        name={`${field}.name`}
        isRequired
        input={{
          disabled: isSystemTemplate,
          placeholder: 'Name of template',
        }}
      />
      {isSystemTemplate && (
        <React.Fragment>
          <Space height={4} />
          <Description>
            {/* @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {TextMessageSystemKind.ATTRIBUTES[systemTemplateKind].description}
          </Description>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const updateAndDisablesFieldsIfCustomerCategory = ({category, form, field}: any) => {
  // If value is customer we set user recipient to CUSTOMER and set all the other fields
  // to empty
  if (category === TextMessageTemplateCategory.CUSTOMER) {
    form.setFieldValue(`${field}.category`, category);
    form.setFieldValue(`${field}.teamIdRecipients`, []);
    form.setFieldValue(`${field}.projectUserRecipients`, [TextMessageRecipientKind.CUSTOMER]);
  } else {
    form.setFieldValue(`${field}.category`, category);
    form.setFieldValue(`${field}.projectUserRecipients`, []);
  }
};

const isCustomerCategory = ({form}: any) => {
  return (
    _.get(form.values, 'textMessageTemplateForm.category') === TextMessageTemplateCategory.CUSTOMER
  );
};

const TemplateTypeField = ({form, field, index, isSystemTemplate}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={DropdownInput}
      label={'Template Type'}
      name={`${field}.category`}
      isRequired
      input={{
        disabled: isSystemTemplate,
        options: TextMessageTemplateCategory.DROPDOWN_OPTIONS,
        style: {flex: 1},
        onChangeValue: (category: any) => {
          updateAndDisablesFieldsIfCustomerCategory({category, form, field});
        },
        setFieldValue: () => {},
        components: {
          IndicatorSeparator: () => null,
        },
      }}
    />
  );
};

const TeamIdRecipientsField = ({form, field, index, organization}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      label={'Team Recipients'}
      name={`${field}.teamIdRecipients`}
      index={index}
      input={{
        disabled: isCustomerCategory({form}),
        options: _.isEmpty(organization.allTeams)
          ? []
          : organization.allTeams.map((team: any) => {
              return {
                value: team.id,
                label: team.name,
              };
            }),
        placeholder: 'Enter team recipients',
        setFieldValue: form.setFieldValue,
        isSearchable: true,
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const ProjectUserRecipientsField = ({form, field, index}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      label={'Project User Recipients'}
      name={`${field}.projectUserRecipients`}
      index={index}
      input={{
        disabled: isCustomerCategory({form}),
        options: TextMessageRecipientKind.DROPDOWN_OPTIONS,
        placeholder: 'Enter user recipients',
        setFieldValue: form.setFieldValue,
        isSearchable: true,
        isOptionDisabled: (option: any) => option.disabled,
        style: {
          width: '100%',
        },
      }}
    />
  );
};

const BodyField = ({form, field, index, isSystemTemplate}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Body'}
      name={`${field}.body`}
      isRequired
      input={{
        disabled: isSystemTemplate,
        placeholder: 'Enter body',
        multiline: true,
        style: {
          paddingVertical: 9,
          minHeight: 200,
        },
      }}
    />
  );
};

const SmsTemplatesField = ({form, field, organization}: any) => {
  const isSystemTemplate =
    _.get(form.values, 'textMessageTemplateForm.systemTemplateKind') !==
    TextMessageSystemKind.CUSTOM;

  return (
    <React.Fragment>
      <NameField form={form} field={field} index={0} isSystemTemplate={isSystemTemplate} />
      <Space height={16} />
      <TemplateTypeField form={form} field={field} index={1} isSystemTemplate={isSystemTemplate} />
      <Space height={16} />
      {!isCustomerCategory({form}) &&
        !isSystemTemplate &&
        organization.features.isEnabledTextTemplateRecipients && (
          <React.Fragment>
            <ProjectUserRecipientsField form={form} field={field} index={3} />
            <Space height={16} />
            <TeamIdRecipientsField
              form={form}
              field={field}
              organization={organization}
              index={4}
            />
            <Space height={16} />
          </React.Fragment>
        )}
      <BodyField form={form} field={field} index={5} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SmsTemplatesField.fragment = gql`
  fragment SmsTemplatesField on Organization {
    id
    features {
      isEnabledTextTemplateRecipients: isEnabled(feature: "TEXT_TEMPLATE_RECIPIENTS")
    }
    allTeams {
      id
      name
    }
  }
`;

export default SmsTemplatesField;
