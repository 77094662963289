// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import ReportMoveTimeRangesChart from './ReportMoveTimeRangesChart';
import ReportMoveTimeRangesList from './ReportMoveTimeRangesList';

const Container = Styled.View`
`;

const Row = Styled.View`
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

type Props = {};

// @ts-expect-error TS(2339): Property 'reportMove' does not exist on type 'Prop... Remove this comment to see the full error message
const ReportMoveTimeRanges = ({reportMove}: Props) => (
  <Container>
    <Row>
      <ReportMoveTimeRangesChart timeRanges={reportMove.timeRanges} />
    </Row>
    <RowSpace />
    <Row>
      <ReportMoveTimeRangesList timeRanges={reportMove.timeRanges} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveTimeRanges.fragment = gql`
  fragment ReportMoveTimeRanges on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

export default ReportMoveTimeRanges;
