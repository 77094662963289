// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

const Column = Styled.View`
`;

const RowContainer = Styled.View`
  flex-direction: row;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 8 : 24)}px;
  padding-vertical: 12px;
  justify-content: space-between;
  background-color: ${colors.white}
`;

const ValueContainer = Styled.View`
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const ValueText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Value = ({label, value}: any) => {
  const responsive = useResponsive();
  return (
    <ValueContainer>
      <LabelText>{label}</LabelText>
      <Space width={responsive.mobile ? 8 : 16} />
      <ValueText>{value}</ValueText>
    </ValueContainer>
  );
};

const RevenueTotal = ({project}: any) => {
  return (
    <Value
      label={'Revenue Total'}
      value={Currency.display(project.totalRevenue, {shouldHideCentsIfZero: true})}
    />
  );
};

const CostTotal = ({project}: any) => {
  return (
    <Value
      label={'Cost Total'}
      value={Currency.display(project.totalCost, {shouldHideCentsIfZero: true})}
    />
  );
};

const NetProfit = ({project}: any) => {
  return (
    <Value
      label={'Net Profit'}
      value={Currency.display(project.totalProfit, {shouldHideCentsIfZero: true})}
    />
  );
};

const PercentProfit = ({project}: any) => {
  const percentProfit = project.totalProfit / project.totalRevenue;
  return <Value label={'% Profit'} value={Percent.display(_.round(percentProfit, 2))} />;
};

const ProjectCostsBlockRevenueSummaryV1 = ({project}: any) => {
  const responsive = useResponsive();
  return (
    <RowContainer {...responsive}>
      {responsive.mobile ? (
        <React.Fragment>
          <Column>
            <RevenueTotal project={project} />
            <CostTotal project={project} />
          </Column>
          <Column style={{alignItems: 'flex-end'}}>
            <NetProfit project={project} />
            <PercentProfit project={project} />
          </Column>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <RevenueTotal project={project} />
          <CostTotal project={project} />
          <NetProfit project={project} />
          <PercentProfit project={project} />
        </React.Fragment>
      )}
    </RowContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectCostsBlockRevenueSummaryV1.fragment = gql`
  fragment ProjectCostsBlockRevenueSummaryV1 on Project {
    id
    totalRevenue
    totalCost
    totalProfit
  }
`;

export default ProjectCostsBlockRevenueSummaryV1;
