// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';

const Container = Styled.View`
`;

const TableContainer = Styled.View`
  min-width: 600px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link2}
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const getDisplayDate = (date: any) => {
  return Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE);
};

const getCollectionsTableColumnDefinitions = ({navigator}: any) => {
  return [
    // Customer
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Customer</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collectionVersion' implicitly has... Remove this comment to see the full error message
      cellContent: ({item: collectionVersion}) => {
        return (
          <Container style={{width: 'fit-content'}}>
            {_.get(collectionVersion, 'collection.project.client') ? (
              <LinkButton
                style={{width: 'fit-content'}}
                onPress={() => {
                  navigator.push(
                    `/clients/${_.get(collectionVersion, 'collection.project.client.uuid')}`,
                  );
                }}
              >
                <LinkButtonText numberOfLines={1}>
                  {_.get(collectionVersion, 'collection.project.client.primaryContact.fullName')}
                </LinkButtonText>
              </LinkButton>
            ) : (
              <Text>--</Text>
            )}
          </Container>
        );
      },
    },
    // Project
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Project Name</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collectionVersion' implicitly has... Remove this comment to see the full error message
      cellContent: ({item: collectionVersion}) => {
        return (
          <Container style={{width: 'fit-content'}}>
            <LinkButton
              style={{width: 'fit-content'}}
              onPress={() => {
                navigator.push(
                  `/storage/projects/${_.get(collectionVersion, 'collection.project.uuid')}`,
                );
              }}
            >
              <LinkButtonText numberOfLines={2}>
                {`Project ${_.get(collectionVersion, 'collection.project.identifier')}${
                  _.get(collectionVersion, 'collection.project.name')
                    ? `: ${_.get(collectionVersion, 'collection.project.name')}`
                    : ''
                }`}
              </LinkButtonText>
            </LinkButton>
          </Container>
        );
      },
    },
    // Date In
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Date In</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collectionVersion' implicitly has... Remove this comment to see the full error message
      cellContent: ({item: collectionVersion}) => {
        return (
          <Text>
            {collectionVersion.assignedStartDate
              ? getDisplayDate(collectionVersion.assignedStartDate)
              : '--'}
          </Text>
        );
      },
    },
    // Date Out
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Date Out</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collectionVersion' implicitly has... Remove this comment to see the full error message
      cellContent: ({item: collectionVersion}) => {
        return (
          <Text>
            {collectionVersion.assignedEndDate
              ? getDisplayDate(collectionVersion.assignedEndDate)
              : '--'}
          </Text>
        );
      },
    },
  ];
};

const StorageContainerSidebarCollectionTable = ({container}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <ScrollView>
      <Container>
        <ScrollView horizontal contentContainerStyle={{flex: 1}}>
          <TableContainer>
            <Table
              columnDefinitions={getCollectionsTableColumnDefinitions({
                navigator,
              })}
              emptyStateText='No collections to display'
              items={container.collectionVersions}
              itemKey={'id'}
            />
          </TableContainer>
        </ScrollView>
      </Container>
      <Space height={70} />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageContainerSidebarCollectionTable.fragment = gql`
  fragment StorageContainerSidebarCollectionTable on Container {
    id
    collectionVersions {
      id
      collection {
        id
        project {
          id
          name
          identifier
          uuid
          client {
            id
            uuid
            primaryContact {
              id
              fullName
            }
          }
        }
      }
      assignedStartDate
      assignedEndDate
    }
  }
`;

export default StorageContainerSidebarCollectionTable;
