// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// Components
import UpdateTimeRangeItem from './UpdateTimeRangeItem';

const Container = Styled.View`
`;

const Cell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Button = Styled.Button`
  align-self: flex-end;
  padding-horizontal: 20px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const UpdateTimeRangesList = ({form}: any) => (
  <Container>
    {form.values.timeRangeInputs.map((timeRangeInput: any, index: any) => (
      // @ts-expect-error TS(2769): No overload matches this call.
      <Cell key={index} index={index}>
        <UpdateTimeRangeItem isFirst={index === 0} index={index} form={form} />
      </Cell>
    ))}
    <Button
      onPress={() => {
        form.setFieldValue('timeRangeInputs', [
          ...form.values.timeRangeInputs,
          TimeRange.create({
            start: _.get(
              form.values,
              `timeRangeInputs.${form.values.timeRangeInputs.length - 1}.end`,
              '',
            ),
          }),
        ]);
      }}
    >
      <Text>Add Time</Text>
    </Button>
  </Container>
);

export default UpdateTimeRangesList;
