// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState, useSidebar} from '@supermove/hooks';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SearchBar from '@shared/design/components/SearchBar';
import AssignShipmentsToTripSidebar from 'modules/Dispatch/LongDistance/components/AssignShipmentsToTripSidebar';
import CreateTripWithShipmentsModal from 'modules/Dispatch/LongDistance/components/CreateTripWithShipmentsModal';
import RemoveShipmentsFromTripModal from 'modules/Dispatch/LongDistance/components/RemoveShipmentsFromTripModal';
import ShipmentsTable from 'modules/Dispatch/Shipment/components/ShipmentsTable';

const TripShipmentsContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  z-index: 1000;
  flex-direction: row;
`;

const ShipmentsInteractions = ({
  isShipmentSelected,
  createTripWithShipmentsModal,
  removeShipmentsFromTripModal,
  assignShipmentsToTripSidebar,
}: any) => {
  const shipmentActions = [
    {
      text: 'Create New Trip',
      onPress: () => createTripWithShipmentsModal.handleOpen(),
    },
    {
      text: 'Move Shipments',
      isDisabled: !isShipmentSelected,
      onPress: () => assignShipmentsToTripSidebar.handleOpen(),
    },
    {
      text: 'Remove From Trip',
      isDisabled: !isShipmentSelected,
      onPress: () => removeShipmentsFromTripModal.handleOpen(),
    },
  ];
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0}>
      {/* TODO(dan) Show when ready to implement search */}
      {false && <SearchBar style={{width: 348}} placeholder={'Search by shipment details'} />}
      <Space style={{flex: 1, minWidth: 12}} />
      <DropdownButton
        text={'Actions'}
        actions={shipmentActions}
        menuWidth={200}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
      />
    </Row>
  );
};

const TripShipmentsContent = ({trip, refetch}: any) => {
  const createTripWithShipmentsModal = useModal({
    name: 'Create Trip Modal',
    enableTracking: true,
  });
  const removeShipmentsFromTripModal = useModal({
    name: 'Remove Shipments From Trip Modal',
    enableTracking: true,
  });
  const assignShipmentsToTripSidebar = useSidebar({
    name: 'Assign Shipments To Trip Sidebar',
    enableTracking: true,
  });

  const [selectedShipments, setSelectedShipments] = useState({});
  const isShipmentSelected = !_.isEmpty(selectedShipments);
  return (
    <TripShipmentsContainer>
      <ShipmentsInteractions
        isShipmentSelected={isShipmentSelected}
        createTripWithShipmentsModal={createTripWithShipmentsModal}
        removeShipmentsFromTripModal={removeShipmentsFromTripModal}
        assignShipmentsToTripSidebar={assignShipmentsToTripSidebar}
      />
      <Space height={16} />
      <ScrollView horizontal contentContainerStyle={{flex: 1, minWidth: 1080}}>
        <ShipmentsTable
          refetchTabData={refetch}
          shipments={trip.shipments}
          createTripWithShipmentsModal={createTripWithShipmentsModal}
          selectedShipments={selectedShipments}
          setSelectedShipments={setSelectedShipments}
          isTripNameEnabled={false}
          style={{paddingBottom: 16}}
        />
      </ScrollView>
      <CreateTripWithShipmentsModal
        key={createTripWithShipmentsModal.key} // Ensures resetting the modal content when reopening
        isOpen={createTripWithShipmentsModal.isOpen}
        handleClose={createTripWithShipmentsModal.handleClose}
        shipments={_.values(selectedShipments)}
        onSuccess={() => {
          setSelectedShipments({});
          refetch();
        }}
      />
      <RemoveShipmentsFromTripModal
        key={removeShipmentsFromTripModal.key} // Ensures re-creating the mutation form when reopening
        refetch={refetch}
        isOpen={removeShipmentsFromTripModal.isOpen}
        handleClose={removeShipmentsFromTripModal.handleClose}
        shipmentIds={_.keys(selectedShipments)}
        tripId={trip.id}
      />
      <AssignShipmentsToTripSidebar
        assignShipmentsToTripSidebar={assignShipmentsToTripSidebar}
        shipmentIds={_.keys(selectedShipments)}
        // Setting key this way ensures previously selected trip does not linger
        key={assignShipmentsToTripSidebar.key}
        onSuccess={() => {
          assignShipmentsToTripSidebar.handleClose();
          refetch();
          setSelectedShipments({});
        }}
      />
    </TripShipmentsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripShipmentsContent.fragment = gql`
  ${CreateTripWithShipmentsModal.fragment}
  ${ShipmentsTable.fragment}
  fragment TripShipmentsContent on Trip {
    id
    shipments {
      id
      ...CreateTripWithShipmentsModal
      ...ShipmentsTable
    }
  }
`;

export default TripShipmentsContent;
