// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  setMutationErrors: (...args: any[]) => any;
  submitForm: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SignConfirmationDocumentMutation.defaultProps;

// @ts-expect-error TS(7022): 'SignConfirmationDocumentMutation' implicitly has ... Remove this comment to see the full error message
const SignConfirmationDocumentMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => {
  return (
    <Mutation
      // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
      variables={{
        confirmationId: values.confirmationId,
        date: values.date,
        image: values.image,
      }}
      mutation={SignConfirmationDocumentMutation.mutation}
      onCompleted={({response: {confirmation, errors}}: any) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(confirmation);
        } else if (onError) {
          onError(errors);
        }
      }}
    >
      {(handleSubmit: any, {loading, error}: any) =>
        children({
          loading,
          handleSubmit,
        })
      }
    </Mutation>
  );
};

SignConfirmationDocumentMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignConfirmationDocumentMutation.mutation = gql`
  mutation SignConfirmationDocumentMutation(
    $confirmationId: Int!,
    $date: String!,
    $image: String!,
  ) {
    response: signConfirmationDocument(
      confirmationId: $confirmationId,
      date: $date,
      image: $image,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default SignConfirmationDocumentMutation;
