/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import AddProjectJobsModal from 'modules/Project/Update/components/AddProjectJobsModal';

const ButtonText = Styled.H7`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 4px;
`;

const AddProjectJobsButton = ({jobId, project, refetch}: any) => {
  const responsive = useResponsive();
  const {isHovered, ref} = useHover();
  const {isOpen, handleToggle, handleClose} = useModal();

  return (
    <React.Fragment>
      <Button ref={ref} color={isHovered ? colors.hover : colors.white} onPress={handleToggle}>
        <Icon color={colors.gray.primary} size={Icon.Sizes.Medium} source={Icon.Plus} />
        {!responsive.mobile && (
          <ButtonText color={colors.gray.primary} style={{marginLeft: 5}}>
            Add Jobs
          </ButtonText>
        )}
      </Button>
      <AddProjectJobsModal
        isOpen={isOpen}
        handleClose={handleClose}
        projectUuid={project.uuid}
        jobId={jobId}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddProjectJobsButton.fragment = gql`
  fragment AddProjectJobsButton on Project {
    id
    uuid
  }
`;

export default AddProjectJobsButton;
