/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Text = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isLarge ? Typography.Body : Typography.Micro)}
  color: ${colors.gray.secondary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 12px;
  align-items: center;
`;

const getDisplayArrivalTime = ({job}: any) => {
  if (job.kind === 'HOLD') {
    return 'All Day';
  }
  return `${job.startTime1 ? Datetime.toDisplayTime(Datetime.fromTime(job.startTime1)) : 'TBD'} - ${job.startTime2 ? Datetime.toDisplayTime(Datetime.fromTime(job.startTime2)) : 'TBD'}`;
};

const JobArrivalTime = ({job, showIcon, isLarge}: any) => {
  return (
    <Row>
      {showIcon && (
        <React.Fragment>
          <TextTooltip isEnabledMobileToast={false} text={'Arrival Time'}>
            <IconContainer>
              <Icon
                source={Icon.Clock}
                size={12}
                color={colors.gray.secondary}
                style={{marginTop: 1}}
              />
            </IconContainer>
          </TextTooltip>
          <Space width={6} />
        </React.Fragment>
      )}
      {/* TODO(dan) Backend should handle saving the time for holds as 12am to 12pm. Then we can
      look at the time without depending on job.kind */}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Text numberOfLines={1} vars={{isLarge}}>
        {getDisplayArrivalTime({job})}
      </Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobArrivalTime.fragment = gql`
  fragment JobArrivalTime on Job {
    id
    kind
    startTime1
    startTime2
  }
`;

JobArrivalTime.getDisplayArrivalTime = getDisplayArrivalTime;

export default JobArrivalTime;
