// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SearchBar from '@shared/design/components/SearchBar';
import TripDayViewDateNavigation from 'modules/Dispatch/LongDistance/Trip/components/TripDayViewDateNavigation';
import ShipmentsTable from 'modules/Dispatch/Shipment/components/ShipmentsTable';

const TripShipmentsContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ShipmentsInteractions = () => {
  return (
    <Row style={{zIndex: 1}}>
      <TripDayViewDateNavigation />
      <Space width={16} />
      {/* TODO(dan) Show when ready to implement search */}
      {false && <SearchBar style={{width: 348}} placeholder={'Search by shipment details'} />}
    </Row>
  );
};

const TripDayViewContent = ({trip, refetch}: any) => {
  return (
    <TripShipmentsContainer>
      <ShipmentsInteractions />
      <Space height={16} />
      <ShipmentsTable
        refetchTabData={refetch}
        shipments={trip.shipments}
        isCheckboxEnabled={false}
        isTripNameEnabled={false}
        hasFooter={!_.isEmpty(trip.shipments)}
        style={{paddingBottom: 16}}
      />
    </TripShipmentsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripDayViewContent.fragment = gql`
  ${ShipmentsTable.fragment}
  fragment TripDayViewContent on Trip {
    id
    shipments {
      id
      ...ShipmentsTable
    }
  }
`;

export default TripDayViewContent;
