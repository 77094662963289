// Libraries
import React from 'react';

// Supermove
import {useState} from '@supermove/hooks';

// App
import CELL_STYLE from 'modules/Dispatch/Calendar/Day/constants/CELL_STYLE';

export const DayCalendarDispatchViewContext = React.createContext(null);

const DayCalendarDispatchViewContextProvider = ({children, isViewingAsPrimaryOrg}: any) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [leftPanelWidth, setLeftPanelWidth] = useState(24 * CELL_STYLE.WIDTH);

  const store = {
    isViewingAsPrimaryOrg,
    isExpanded,
    setIsExpanded,
    leftPanelWidth,
    setLeftPanelWidth,
  };

  return (
    // @ts-expect-error TS(2322): Type '{ isViewingAsPrimaryOrg: any; isExpanded: bo... Remove this comment to see the full error message
    <DayCalendarDispatchViewContext.Provider value={store}>
      {children}
    </DayCalendarDispatchViewContext.Provider>
  );
};

export default DayCalendarDispatchViewContextProvider;
