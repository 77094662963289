// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const NewJobSuccessModalContent = ({isOpen, job, onClose}: any) => {
  const {navigator} = useNavigationDOM();

  if (!job) {
    return null;
  }

  return (
    <Modal.Content
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
    >
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'thumbsup'} />
          </Circle>
          <Content>
            <Title>{job ? `${job.jobType.name} Created!` : 'Job Created!'}</Title>
            <Subtitle>{job ? `You've successfully created a ${job.jobType.name}.` : ''}</Subtitle>
            <Actions>
              <Button
                color={colors.gray.secondary}
                onPress={() => {
                  navigator.replace('/');
                }}
              >
                <Text>Go Home</Text>
              </Button>
              <ButtonSpace />
              <Button
                onPress={() => {
                  navigator.replace('/');
                  navigator.push(`/jobs/${job.uuid}`);
                }}
              >
                <Text>View Job</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

type OwnNewJobSuccessModalProps = {
  job?: any;
};

// @ts-expect-error TS(2456): Type alias 'NewJobSuccessModalProps' circularly re... Remove this comment to see the full error message
type NewJobSuccessModalProps = OwnNewJobSuccessModalProps & typeof NewJobSuccessModal.defaultProps;

// @ts-expect-error TS(7022): 'NewJobSuccessModal' implicitly has type 'any' bec... Remove this comment to see the full error message
const NewJobSuccessModal = ({job, trigger}: NewJobSuccessModalProps) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      {trigger({handleOpen})}
      <NewJobSuccessModalContent isOpen={isOpen} job={job} onClose={handleClose} />
    </React.Fragment>
  );
};

NewJobSuccessModal.Content = NewJobSuccessModalContent;

NewJobSuccessModal.defaultProps = {
  job: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewJobSuccessModal.fragment = gql`
  fragment NewJobSuccessModal on Job {
    id
    jobType {
      id
      name
    }
  }
`;

export default NewJobSuccessModal;
