// Libraries
import React from 'react';

// App
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useCreateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useCreateBillRuleTypeMutation';
import BillRuleTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeDrawer';
import BillRuleTypeFieldsV1 from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFieldsV1';

const AddBillRuleTypeDrawerV1 = ({
  billingLibraryId,
  organizationId,
  refetch,
  userId,
  isOpen,
  handleClose,
}: any) => {
  const billRuleTypeForm = BillRuleTypeForm.new({
    billingLibraryId,
    organizationId,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <BillRuleTypeDrawer
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Add Bill Rule'}
    >
      <BillRuleTypeFieldsV1 form={form} field={'billRuleTypeForm'} />
    </BillRuleTypeDrawer>
  );
};

export default AddBillRuleTypeDrawerV1;
