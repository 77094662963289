// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import CrewPayableItemsList from './CrewPayableItemsList';

const CrewPayableUserDayFields = ({payableUserDay, payableUserDayForm, form}: any) => {
  return (
    <CrewPayableItemsList
      userId={payableUserDay.user.id}
      payableItems={payableUserDay.payableItems}
      payableItemForms={payableUserDayForm.payableItemForms}
      form={form}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPayableUserDayFields.fragment = gql`
  ${CrewPayableItemsList.fragment}

  fragment CrewPayableUserDayFields on PayableUserDay {
    id
    user {
      id
    }
    payableItems {
      ...CrewPayableItemsList
    }
  }
`;

export default CrewPayableUserDayFields;
