// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobProjectDocumentsCollapsibleTables from 'modules/Job/V2/Move/components/JobProjectDocumentsCollapsibleTables';

type OwnProps = {
  job: any;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobProjectDocumentsBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobProjectDocumentsBlock' implicitly has type 'an... Remove this comment to see the full error message
const JobProjectDocumentsBlock = ({job, refetch}: Props) => {
  return <JobProjectDocumentsCollapsibleTables job={job} refetch={refetch} />;
};

JobProjectDocumentsBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectDocumentsBlock.fragment = gql`
  ${JobProjectDocumentsCollapsibleTables.fragment}
  fragment JobProjectDocumentsBlock on Job {
    id
    ...JobProjectDocumentsCollapsibleTables
  }
`;

export default JobProjectDocumentsBlock;
