// Libraries
import jwt from 'jwt-decode';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';

// Components
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ExpiredInvalidTokenView from 'modules/Authentication/Signup/components/ExpiredInvalidTokenView';
import InviteOrganizationUserSignUpPageContent from 'modules/Authentication/Signup/components/InviteOrganizationUserSignUpPageContent';

const InviteOrganizationUserSignUpPage = () => {
  const responsive = useResponsive();
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(InviteOrganizationUserSignUpPage.query, {
    fetchPolicy: 'network-only',
    variables: {
      inviteToken: params.token,
    },
  });
  // Validate token
  let decodedToken;
  try {
    // Decoded token has email and expiration date
    decodedToken = jwt(params.token);
    // Check for token expiration date
    if (Date.now() >= (decodedToken as any).exp * 1000) {
      return <ExpiredInvalidTokenView responsive={responsive} />;
    }
  } catch (error) {
    return <ExpiredInvalidTokenView responsive={responsive} />;
  }

  if (loading) {
    return <PageLoadingIndicator />;
  }

  if (data.user) {
    return (
      <InviteOrganizationUserSignUpPageContent token={params.token} decodedToken={decodedToken} />
    );
  }

  return <ExpiredInvalidTokenView responsive={responsive} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InviteOrganizationUserSignUpPage.query = gql`
  query InviteOrganizationUserSignUpPage($inviteToken: String!) {
    ${gql.query}
    user(inviteToken: $inviteToken) {
      id
    }
  }
`;

export default InviteOrganizationUserSignUpPage;
