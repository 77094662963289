// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import ProjectStatusBadge from '@shared/modules/Project/components/ProjectStatusBadge';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ProjectHeaderActions from 'modules/Project/V2/Show/components/ProjectHeaderActions';
import ProjectHeaderClientInformation from 'modules/Project/V2/Show/components/ProjectHeaderClientInformation';
import ProjectTags from 'modules/Project/V2/Show/components/ProjectTags';
import ProjectType from 'modules/Project/V2/Show/components/ProjectType';

const ProjectHeaderContainer = Styled.View`
  padding-horizontal: 24px;
  padding-vertical: 12px;
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ProjectName = Styled.Text`
  ${Typography.Heading1}
`;

const IconContainer = Styled.View`
`;

const MicroText = Styled.Text`
  ${Typography.Micro}
`;

const ExpandCollapseButton = Styled.ButtonV2`
  flex-shrink: 1;
`;

const VerticalLine = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const LoadingComponent = () => {
  return (
    <ProjectHeaderContainer>
      <Row>
        <Column style={{flex: 1}}>
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Heading1Text} width={360} />
          <Space height={12} />
          <Row>
            <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
            <Space width={32} />
            <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
            <Space width={32} />
            <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
          </Row>
        </Column>
        <Column>
          <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={120} />
        </Column>
      </Row>
    </ProjectHeaderContainer>
  );
};

const ProjectDetail = ({iconSource, text, tooltipText}: any) => {
  return (
    <TooltipWrapper tooltipText={tooltipText}>
      <Row style={{alignItems: 'center'}}>
        <IconContainer>
          <Icon source={iconSource} size={Icon.Sizes.Small} color={colors.gray.secondary} />
        </IconContainer>
        <Space width={8} />
        <MicroText>{text}</MicroText>
      </Row>
    </TooltipWrapper>
  );
};

const TooltipWrapper = ({tooltipText, children}: any) => {
  if (tooltipText) {
    return (
      <TextTooltip isEnabledMobileToast={false} text={tooltipText}>
        {children}
      </TextTooltip>
    );
  }
  return children;
};

const CollapsibleDescription = ({text}: any) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  return (
    <Row style={{flex: 1}}>
      <ExpandCollapseButton
        onPress={() => {
          setIsDescriptionExpanded(!isDescriptionExpanded);
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <MicroText numberOfLines={isDescriptionExpanded ? null : 1}>{text}</MicroText>
      </ExpandCollapseButton>
      <Space width={24} />
    </Row>
  );
};

const SpaceWithLine = ({width = 12}) => (
  <Row>
    <Space width={width} />
    <VerticalLine />
    <Space width={width} />
  </Row>
);

const ProjectHeader = ({project, urlFilters, refetchAndReset}: any) => {
  return (
    <ProjectHeaderContainer>
      <Row>
        <Column style={{flex: 1}}>
          <Row style={{alignItems: 'center'}}>
            {project.status && (
              <Row>
                <ProjectStatusBadge project={project} />
                <Space width={8} />
              </Row>
            )}
            <ProjectName>{Project.getName(project)}</ProjectName>
            <Space width={4} />
            <ProjectHeaderActions project={project} refetch={refetchAndReset} />
          </Row>
          <Space height={12} />
          <Row style={{flexWrap: 'wrap'}}>
            <ProjectDetail text={project.organization.name} iconSource={Icon.Building} />
            <SpaceWithLine />
            <ProjectType projectType={project.projectType} />
            <SpaceWithLine />
            <ProjectDetail
              text={Project.getDate(project)}
              iconSource={Icon.Calendar}
              tooltipText={'Start & End Date'}
            />
            <SpaceWithLine />
            {!!project.size && (
              <React.Fragment>
                <ProjectDetail
                  text={project.size}
                  iconSource={Icon.HouseBuilding}
                  tooltipText={'Project Size'}
                />
                <SpaceWithLine />
              </React.Fragment>
            )}
            <ProjectDetail text={`${project.weight} lbs`} iconSource={Icon.WeightHanging} />
            <SpaceWithLine />
            <ProjectDetail text={`${project.volume} ft³`} iconSource={Icon.RulerTriangle} />
            <SpaceWithLine />
            <ProjectDetail
              text={Project.getLocation(project)}
              iconSource={Icon.MapPin}
              tooltipText={'Origin & Destination'}
            />
          </Row>
          {project.description !== '' && (
            <React.Fragment>
              <Space height={12} />
              <CollapsibleDescription text={project.description} />
            </React.Fragment>
          )}
          {project.organization.features.isEnabledProjectTag && (
            <React.Fragment>
              <Space height={12} />
              <ProjectTags project={project} refetchAndReset={refetchAndReset} />
            </React.Fragment>
          )}
        </Column>
        <ProjectHeaderClientInformation
          project={project}
          client={project.client}
          urlFilters={urlFilters}
        />
      </Row>
    </ProjectHeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectHeader.listener = gql`
  ${Project.getLocation.fragment}
  fragment ProjectHeader_listener on Project {
    id
    weight
    ...Project_getLocation
  }
`;

ProjectHeader.fragment = gql`
  ${Project.getDate.fragment}
  ${Project.getLocation.fragment}
  ${Project.getName.fragment}
  ${ProjectHeaderActions.fragment}
  ${ProjectHeaderClientInformation.fragment}
  ${ProjectHeader.listener}
  ${ProjectStatusBadge.fragment}
  ${ProjectType.fragment}
  ${ProjectTags.fragment}

  fragment ProjectHeader on Project {
    id
    uuid
    description
    weight
    volume
    size
    client {
      id
    }
    organization {
      id
      name
      features {
        isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
      }
    }
    ...Project_getDate
    ...Project_getLocation
    ...Project_getName
    ...ProjectHeaderActions
    ...ProjectHeaderClientInformation
    ...ProjectHeader_listener
    ...ProjectStatusBadge
    ...ProjectType
    ...ProjectTags
  }
`;

ProjectHeader.SkeletonLoader = LoadingComponent;

export default ProjectHeader;
