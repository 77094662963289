// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
`;

const OptionText = Styled.Text`
  ${Typography.Body};
`;

const GroupedListContainer = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
`;

const GroupedCardContainer = Styled.View`
  flex-direction: row;
  width: ${({
    // @ts-expect-error TS(2339): Property 'numberInRow' does not exist on type 'The... Remove this comment to see the full error message
    numberInRow,
  }) => `${100 / numberInRow}%`};
`;

const OptionCardButton = Styled.ButtonV2`
  flex-direction: row;
  flex: 1;
  height: 52px;
  align-items: center;
  justify-content: ${({
    // @ts-expect-error TS(2339): Property 'isTextCentered' does not exist on type '... Remove this comment to see the full error message
    isTextCentered,
  }) => (isTextCentered ? 'center' : 'flex-start')};
  padding-horizontal: 24px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : colors.gray.background)};
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.interactive : colors.gray.border)};
`;

const OptionIconContainer = Styled.View`
  width: 16px;
  align-items: center;
`;

const RowSeparator = () => {
  return <Space height={12} style={{width: '100%'}} />;
};

const OptionCard = ({text, icon, onPress, isSelected, isTextCentered}: any) => {
  return (
    <OptionCardButton onPress={onPress} isSelected={isSelected} isTextCentered={isTextCentered}>
      {!!icon && (
        <React.Fragment>
          <OptionIconContainer>
            <Icon source={icon} size={14} color={colors.gray.secondary} />
          </OptionIconContainer>
          <Space width={16} />
        </React.Fragment>
      )}
      <OptionText>{text}</OptionText>
    </OptionCardButton>
  );
};

const WorkflowBuilderOptionCardTripled = ({
  text,
  icon,
  onPress,
  index,
  isSelected,
  isTextCentered,
}: any) => {
  const isFirstInRow = index % 3 === 0;
  const isSecondInRow = index % 3 === 1;
  const isThirdInRow = index % 3 === 2;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <GroupedCardContainer numberInRow={3}>
      {isSecondInRow && <Space width={4} />}
      {isThirdInRow && <Space width={8} />}
      <OptionCard
        text={text}
        icon={icon}
        onPress={onPress}
        isSelected={isSelected}
        isTextCentered={isTextCentered}
      />
      {isFirstInRow && <Space width={8} />}
      {isSecondInRow && <Space width={4} />}
    </GroupedCardContainer>
  );
};

const WorkflowBuilderOptionCardPaired = ({
  text,
  icon,
  onPress,
  index,
  isSelected,
  isTextCentered,
}: any) => {
  const isFirstInRow = index % 2 === 0;
  const isSecondInRow = index % 2 === 1;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <GroupedCardContainer numberInRow={2}>
      {isSecondInRow && <Space width={6} />}
      <OptionCard
        text={text}
        icon={icon}
        onPress={onPress}
        isSelected={isSelected}
        isTextCentered={isTextCentered}
      />
      {isFirstInRow && <Space width={6} />}
    </GroupedCardContainer>
  );
};

const WorkflowBuilderOptionCard = ({text, icon, onPress, isSelected}: any) => {
  return (
    <Row>
      <OptionCard
        text={text}
        icon={icon}
        onPress={() => {
          onPress();
          return text;
        }}
        isSelected={isSelected}
      />
    </Row>
  );
};

WorkflowBuilderOptionCard.Card = OptionCard;
WorkflowBuilderOptionCard.Paired = WorkflowBuilderOptionCardPaired;
WorkflowBuilderOptionCard.Tripled = WorkflowBuilderOptionCardTripled;
WorkflowBuilderOptionCard.GroupedListContainer = GroupedListContainer;
WorkflowBuilderOptionCard.RowSeparator = RowSeparator;

export default WorkflowBuilderOptionCard;
