// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Project} from '@supermove/models';

// App

import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';

const StorageProjectPageHeader = ({project}: any) => {
  const {navigator} = useNavigationDOM();

  const breadcrumbs = [
    {name: 'Storage', onPress: () => navigator.push('/storage/list')},
    {name: Project.getName(project)},
  ];

  return (
    <StandardOfficeHeader
      title={''}
      // @ts-expect-error TS(2322): Type '{ marginVertical: string; marginHorizontal: ... Remove this comment to see the full error message
      officeHeaderStyle={{marginVertical: '10px', marginHorizontal: '16px'}}
    >
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </StandardOfficeHeader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectPageHeader.fragment = gql`
  ${Project.getName.fragment}
  fragment StorageProjectPageHeader on Project {
    id
    ...Project_getName
  }
`;

export default StorageProjectPageHeader;
