// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Organization, Project, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Modal from 'components/Modal';
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import SignatureCapture from 'modules/Document/components/SignatureCapture';
import ConfirmationDocumentProjectInfo from 'modules/Project/Confirmation/components/ConfirmationDocumentProjectInfo';

// `display: block` is required for page breaks to
// work correctly when printing or creating PDFs.
const Container = Styled.View`
  display: block;
  width: 100%;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  width: 100%;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Title = Styled.H4`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Line = Styled.View`
  border-bottom-width: ${(props) => ((props as any).size ? `${(props as any).size}px` : '1px')};
  border-color: ${colors.gray.border};
`;

const Wrapper = Styled.View`
  width: 100%;
  margin-bottom: 10px;
`;

const StipulationTitle = Styled.H7`
  margin-bottom: 3px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const StipulationBody = Styled.H7`
  color: ${colors.gray.primary};
`;

const StipulationSection = ({title, body, style}: any) => (
  <Wrapper style={style}>
    {!!title && <StipulationTitle>{title}</StipulationTitle>}
    <StipulationBody>{body}</StipulationBody>
  </Wrapper>
);

const EntryWrapper = Styled.View`
  justify-content: flex-end;
  height: 100%;
`;

const EntryTitle = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EntryValue = Styled.H5`
  ${fontWeight(700)}
  line-height: 18px;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const EntryLine = Styled.View`
  width: 100%;
  height: 2px;
  margin-vertical: 5px;
  background-color: ${colors.gray.primary};
`;

const Image = Styled.Image`
`;

const SignatureTouchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 300px;
  height: 150px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

const SignatureText = Styled.H6`
  color: ${colors.gray.primary};
`;

const ModalContent = Styled.View`
  align-items: center;
  margin-horizontal: 20px;
`;

const Button = Styled.Button`
  width: 100%;
`;

const ButtonText = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const SignatureModalField = ({signatureRef, form}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const {image} = form.values;

  return (
    <React.Fragment>
      <SignatureTouchable onPress={handleOpen}>
        {image ? (
          <Image
            source={{uri: image}}
            style={{
              width: 300,
              height: 150,
            }}
          />
        ) : (
          <SignatureText>Tap here to sign</SignatureText>
        )}
      </SignatureTouchable>
      <Modal
        isOpen={isOpen}
        title={'Draw Signature'}
        subtitle={'Please draw your signature to sign and accept the document.'}
        onClose={handleClose}
      >
        <ModalContent>
          <Space height={20} />
          <SignatureCapture
            innerRef={signatureRef}
            onEnd={() => {}}
            onSave={(image: any) => {
              form.setFieldValue('image', image);
              handleClose();
            }}
          />
          <Space height={20} />
          <Button
            onPress={() => {
              signatureRef.current.save();
            }}
          >
            <ButtonText>Save</ButtonText>
          </Button>
          <Space height={20} />
        </ModalContent>
      </Modal>
    </React.Fragment>
  );
};

const Entry = ({isSignable, title, type, value, form, signatureRef, style}: any) => (
  <EntryWrapper style={style}>
    {isSignable ? (
      <SignatureModalField signatureRef={signatureRef} form={form} />
    ) : type === 'image' ? (
      <Image
        source={{uri: value}}
        style={{
          width: 350,
          height: 175,
        }}
      />
    ) : (
      <EntryValue>{value || ' '}</EntryValue>
    )}
    <EntryLine />
    <EntryTitle>{title}</EntryTitle>
  </EntryWrapper>
);

type OwnProjectConfirmationDocumentProps = {
  values?: any;
  isSignable?: boolean;
  form?: any;
  signatureRef?: any;
};

// @ts-expect-error TS(2456): Type alias 'ProjectConfirmationDocumentProps' circ... Remove this comment to see the full error message
type ProjectConfirmationDocumentProps = OwnProjectConfirmationDocumentProps &
  typeof ProjectConfirmationDocument.defaultProps;

// @ts-expect-error TS(7022): 'ProjectConfirmationDocument' implicitly has type ... Remove this comment to see the full error message
const ProjectConfirmationDocument = ({
  project,
  values,
  // Signable props.
  isSignable,
  form,
  signatureRef,
}: ProjectConfirmationDocumentProps) => {
  const {isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber} = project.organization.features;
  return (
    <Container>
      {!!project.organization.logoUri && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={0}>
          <DocumentHeaderOrganization
            shouldShowBusinessName={false}
            shouldShowBusinessAddressAndPhoneNumber={
              isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber
            }
            organization={project.organization}
          />
        </Section>
      )}
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <Title>Confirmation Letter</Title>
        <Space height={4} />
        <Subtitle>{`${project.name}`}</Subtitle>
        <Subtitle>{Project.getMovingServicesText(project)}</Subtitle>
      </Section>
      <Space height={16} />
      <Line />
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={2}>
        <ConfirmationDocumentProjectInfo project={project} />
      </Section>
      <Space height={40} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Line size={3} />
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={3}>
        {Organization.getConfirmationLetterSections(project.organization).map(
          ({title, body, style}: any, index: any) => (
            <StipulationSection key={index} title={title} body={body} style={style} />
          ),
        )}
      </Section>
      <Section
        // @ts-expect-error TS(2769): No overload matches this call.
        sectionIndex={4}
        style={{
          flexDirection: 'row',
          height: 250,
        }}
        data-avoid-page-break
      >
        {isSignable || values.image ? (
          <Entry
            isSignable={isSignable}
            form={form}
            signatureRef={signatureRef}
            title={`Signature: ${User.getCustomerDisplayName(project.customer)}`}
            type={'image'}
            value={values.image}
            style={{
              flex: 1,
              marginRight: 20,
            }}
          />
        ) : (
          <Entry
            title={`Signature: ${User.getCustomerDisplayName(project.customer)}`}
            value={values.text}
            style={{
              flex: 1,
              marginRight: 20,
            }}
          />
        )}
        <Entry
          title={'Date'}
          value={values.date && Datetime.convertToDisplayDate(values.date, 'M/D/YYYY')}
          style={{width: 150}}
        />
      </Section>
    </Container>
  );
};

ProjectConfirmationDocument.defaultProps = {
  values: {},
  // Signable props.
  isSignable: false,
  form: null,
  signatureRef: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectConfirmationDocument.fragment = gql`
  ${ConfirmationDocumentProjectInfo.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${Project.getMovingServicesText.fragment}

  fragment ProjectConfirmationDocument on Project {
    id
    name
    isCancelled
    organization {
      id
      slug
      name
      businessName
      logoUri
      features {
        isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber: isEnabled(
          feature: "QUOTE_AND_CONFIRMATION_SHOW_ADDRESS_AND_PHONE_NUMBER"
        )
      }
      ...DocumentHeaderOrganization
    }
    customer {
      id
      aliasName
      firstName
      lastName
      phoneNumber
      email
    }
    activeJobs {
      id
      name
      day {
        id
        value
      }
      startLocation {
        id
        address
      }
      endLocation {
        id
        address
      }
      additionalLocations {
        id
        address
      }
    }
    ...ConfirmationDocumentProjectInfo
    ...Project_getMovingServicesText
  }
`;

export default ProjectConfirmationDocument;
