// Libararies
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ContainerForm from '@shared/modules/Storage/forms/ContainerForm';
import useUpdateContainerMutation from '@shared/modules/Storage/hooks/useUpdateContainerMutation';
import ContainerModalContent from 'modules/Storage/components/ContainerModalContent';

const UpdateContainerModalContent = ({
  organization,
  handleClose,
  refetch,
  container,
  viewer,
}: any) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Changes saved!',
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });
  const containerForm = ContainerForm.edit({container});
  const field = 'containerForm';
  const {form, handleSubmit, submitting} = useUpdateContainerMutation({
    containerForm,
    onSuccess: () => {
      successToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{'Edit Container'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body>
        <ContainerModalContent
          form={form}
          field={field}
          organization={organization}
          viewer={viewer}
          refetch={refetch}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const UpdateContainerModal = ({isOpen, handleClose, refetch, container, viewer}: any) => {
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.SMALL}>
      <UpdateContainerModalContent
        organization={container.organization}
        handleClose={handleClose}
        refetch={refetch}
        container={container}
        viewer={viewer}
      />
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateContainerModal.fragment = gql`
  ${ContainerModalContent.fragment}
  ${ContainerForm.edit.fragment}

  fragment UpdateContainerModal on Container {
    id
    organization {
      id
      ...ContainerModalContent
    }
    ...ContainerForm_edit
  }
`;

export default UpdateContainerModal;
