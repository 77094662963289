/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Notification, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import DocumentAdditionalInfoGridActions from 'modules/Customer/Document/components/DocumentAdditionalInfoGridActions';
import DocumentAdditionalInfoGridInput from 'modules/Customer/Document/components/DocumentAdditionalInfoGridInput';
import DocumentAdditionalInfoGridSearch from 'modules/Customer/Document/components/DocumentAdditionalInfoGridSearch';

const GridHeader = Styled.View`
  flex-direction: row;
  margin-left: 40px;
  margin-bottom: 5px;
`;

const GridColumn = Styled.H7`
  margin-left: 10px;
  width: ${(props) => (props as any).width}px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const getValue = ({form, documentAdditionalInfo, defaultValue}: any) => {
  return _.get(form, `values.additionalInfo.${documentAdditionalInfo.name}`, defaultValue);
};

const DocumentAdditionalInfoGridFields = ({documentAdditionalInfo, form}: any) => {
  const fieldName = `additionalInfo.${documentAdditionalInfo.name}`;
  const data = getValue({form, documentAdditionalInfo, defaultValue: []});
  const metadata = Json.toObject(documentAdditionalInfo.metadata);

  if (!data) {
    return null;
  }

  const columns = _.get(metadata, 'columns', []);
  const emptyRow = _.map(columns, (column) => '');
  const items = _.get(metadata, 'items', []);

  return (
    <React.Fragment>
      <GridHeader>
        {columns.map((column: any, index: any) => {
          const isHidden = _.get(column, 'isHidden', false);

          if (isHidden) {
            return null;
          }

          return (
            <GridColumn key={index} width={150}>
              {column.name}
            </GridColumn>
          );
        })}
      </GridHeader>
      {data.map((data: any, index: any) => {
        return (
          <DocumentAdditionalInfoGridInput
            key={index}
            isFirst={index === 0}
            isLast={index === data.length - 1}
            name={`${fieldName}.${index}`}
            index={index}
            columns={columns}
            values={form.values}
            setFieldValue={form.setFieldValue}
            setFieldTouched={form.setFieldTouched}
          />
        );
      })}
      {/* @ts-expect-error TS(2741): Property 'color' is missing in type '{ text: strin... Remove this comment to see the full error message */}
      {data.length === 0 && <Notification.Warning text={'No Items'} />}
      {items.length > 0 && (
        <React.Fragment>
          <Space height={10} />
          <DocumentAdditionalInfoGridSearch
            items={items}
            extraData={form}
            onSelect={(item: any) => {
              const newData = _.concat(data, [item.data]);
              form.setFieldValue(fieldName, newData);
            }}
          />
        </React.Fragment>
      )}
      <Space height={10} />
      <DocumentAdditionalInfoGridActions name={fieldName} form={form} emptyRow={emptyRow} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoGridFields.fragment = gql`
  fragment DocumentAdditionalInfoGridFields on DocumentAdditionalInfo {
    name
    metadata
  }
`;

export default DocumentAdditionalInfoGridFields;
