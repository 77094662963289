/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useRef, useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import EndDateFilter from 'modules/Report/Metabase/components/EndDateFilter';
import SalespersonDropdown from 'modules/Report/Metabase/components/SalespersonDropdown';
import StartDateFilter from 'modules/Report/Metabase/components/StartDateFilter';
import ReportViewFiltersForm from 'modules/Report/Metabase/forms/ReportViewFiltersForm';
import useReportViewFiltersForm from 'modules/Report/Metabase/logic/useReportViewFiltersForm';
import ReportsNavigation from 'modules/Report/components/ReportsNavigation';

const Container = Styled.View`
  flex: 1;
`;

const FilterRow = Styled.View`
  padding-top: 20px;
  padding-horizontal: 20px;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const ClearFilterButton = Styled.Button`
  padding-horizontal: 10px;
  align-self: flex-end;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const MetabaseReportContent = ({data, form}: any) => {
  const {reportView} = data.viewer.viewingOrganization;

  return (
    <Container>
      <FilterRow>
        {reportView.filters.map((filter: any, index: any) => {
          switch (filter) {
            case 'START_DATE':
              return (
                <React.Fragment key={index}>
                  <StartDateFilter index={index} form={form} />
                  <Space width={20} />
                </React.Fragment>
              );
            case 'END_DATE':
              return (
                <React.Fragment key={index}>
                  <EndDateFilter index={index} form={form} />
                  <Space width={20} />
                </React.Fragment>
              );
            case 'SALESPERSON_ID':
              return (
                <React.Fragment key={index}>
                  <SalespersonDropdown
                    organization={data.viewer.viewingOrganization}
                    index={index}
                    form={form}
                  />
                  <Space width={20} />
                </React.Fragment>
              );
            default:
              console.log('Unsupported filter type!');
              return null;
          }
        })}
        <ClearFilterButton
          onPress={() => {
            const reportViewFiltersForm = ReportViewFiltersForm.new();
            form.setFieldValue('reportViewFiltersForm', reportViewFiltersForm);
          }}
        >
          <ButtonText>Clear Filters</ButtonText>
        </ClearFilterButton>
      </FilterRow>
      <iframe
        title={'Report'}
        src={reportView.url}
        style={{
          flex: 1,
          borderWidth: 0,
        }}
      />
    </Container>
  );
};

const pushUrlWithParameters = ({parameters, navigator}: any) => {
  navigator.push(`${navigator.location.pathname}?${parameters}`);
};

// TODO(peter): Add filters and include the slug in the query
const MetabaseReport = ({
  match: {
    params: {slug},
  },
}: any) => {
  const {navigator, params} = useNavigationDOM();
  const {form} = useReportViewFiltersForm({
    reportViewFiltersForm: ReportViewFiltersForm.fromUrlParameters(params),
  });

  const ref = useRef();

  useEffect(() => {
    const parameters = ReportViewFiltersForm.toUrlParameters(form.values.reportViewFiltersForm);
    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, parameters)) {
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'undefined... Remove this comment to see the full error message
      ref.current = parameters;
      pushUrlWithParameters({parameters, navigator});
    }
  }, [form, navigator, ref]);

  return (
    <SidebarPage
      selected={'reports'}
      variables={{
        filters: ReportViewFiltersForm.toFilterObject(form.values.reportViewFiltersForm),
        slug,
      }}
      query={MetabaseReport.query}
      alwaysUpdate
    >
      {({responsive, data}: any) => (
        <Container>
          <StandardOfficeHeader title={'Reports'} />
          <ReportsNavigation />
          <MetabaseReportContent responsive={responsive} data={data} form={form} />
        </Container>
      )}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MetabaseReport.query = gql`
  ${SalespersonDropdown.fragment}

  query MetabaseReport($filters: JSONString!, $slug: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        reportView(filters: $filters, slug: $slug) {
          url
          filters
          title
        }
        ...SalespersonDropdown
      }
    }
  }
`;

export default MetabaseReport;
