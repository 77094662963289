// Libraries

// Supremove
import {gql} from '@supermove/graphql';
import {ProjectTypeUserFlowModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type ProjectTypeUserFlowFormToFormType = {
  projectTypeUserFlowId: string | undefined;
  projectTypeId: string | undefined;
  userFlowId: number;
  index: number;
};

const _new = ({
  projectTypeId,
  userFlowId,
}: {
  projectTypeId: string | undefined;
  userFlowId: number;
}): ProjectTypeUserFlowFormToFormType => ({
  projectTypeUserFlowId: undefined,
  projectTypeId,
  userFlowId,
  index: 0,
});

const edit = withFragment(
  (projectTypeUserFlow: ProjectTypeUserFlowModel) => {
    return {
      projectTypeUserFlowId: projectTypeUserFlow.id,
      userFlowId: projectTypeUserFlow.userFlowId,
      projectTypeId: projectTypeUserFlow.projectTypeId,
      index: projectTypeUserFlow.index,
    };
  },
  gql`
    fragment ProjectTypeUserFlowForm_edit on ProjectTypeUserFlow {
      id
      userFlowId
      projectTypeId
      index
    }
  `,
);

const toForm = (projectTypeUserFlow: ProjectTypeUserFlowFormToFormType) => {
  return {
    projectTypeUserFlowId: projectTypeUserFlow.projectTypeUserFlowId,
    userFlowId: projectTypeUserFlow.userFlowId,
    projectTypeId: projectTypeUserFlow.projectTypeId,
    index: projectTypeUserFlow.index,
  };
};

const toMutation = (projectTypeUserFlowForm: ReturnType<typeof toForm>) => {
  return {
    projectTypeUserFlowId: projectTypeUserFlowForm.projectTypeUserFlowId,
    userFlowId: projectTypeUserFlowForm.userFlowId,
    projectTypeId: projectTypeUserFlowForm.projectTypeId,
    index: projectTypeUserFlowForm.index,
  };
};

const ProjectTypeUserFlowForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeUserFlowForm;
