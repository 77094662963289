/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const IconContainer = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => colors.alpha(color, 0.15)};
  height: 18px;
  width: 18px;
  border-radius: 9px;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  align-items: center;
  justify-content: center;
`;

const JobDispatchStatus = ({job}: any) => {
  const color = Job.getCalendarPrimaryStatusColor(job);

  return (
    <TextTooltip isEnabledMobileToast={false} text={Job.getCalendarPrimaryStatusText(job)}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <IconContainer color={color}>
        {/* @ts-expect-error TS(2322): Type '"" | "calendar-exclamation" | "calendar-xmar... Remove this comment to see the full error message */}
        <Icon source={Job.getCalendarPrimaryStatusIcon(job)} size={10} color={color} />
      </IconContainer>
    </TextTooltip>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchStatus.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusIcon.fragment}
  ${Job.getCalendarPrimaryStatusText.fragment}

  fragment JobDispatchStatus on Job {
    id
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusIcon
    ...Job_getCalendarPrimaryStatusText
  }
`;

export default JobDispatchStatus;
