// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Icon, SanitizedHTML} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

import EmailStatus from '@shared/modules/Email/enums/EmailStatus';
import Line from 'modules/App/components/Line';
import SystemMessage from 'modules/App/components/SystemMessage';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CCRow = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const EmailDateContainer = Styled.View`
  margin-left: auto;
`;

const Container = Styled.View`
  padding-horizontal: 16px;
  background-color: ${colors.white};
`;

const EmailText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const NameText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DateNameText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ThreadEmail = ({email}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Container>
        <Space height={16} />
        {email.status === EmailStatus.ERROR && (
          <React.Fragment>
            <SystemMessage isResponsive containerStyle={{margin: 0}}>
              <Icon
                color={colors.orange.status}
                size={responsive.desktop ? 11 : 13}
                source={Icon.ExclamationTriangle}
              />
              {'  Email Not Delivered, Please Try Sending Again'}
            </SystemMessage>
            <Space height={8} />
          </React.Fragment>
        )}
        <Row>
          <NameText responsive={responsive}>{email.fromName}</NameText>
          <Space width={8} />
          <EmailDateContainer>
            <DateNameText responsive={responsive} numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(email.sentAt)}
            </DateNameText>
          </EmailDateContainer>
        </Row>
        <Space height={8} />
        {email.ccEmailRecipientsJson && JSON.parse(email.ccEmailRecipientsJson).length > 0 && (
          <React.Fragment>
            <CCRow>
              <EmailText responsive={responsive}>cc: </EmailText>
              {JSON.parse(email.ccEmailRecipientsJson).map((ccRecipients: any, index: any) => (
                <EmailText key={index} responsive={responsive}>
                  &lt;{ccRecipients.email}&gt;{' '}
                </EmailText>
              ))}
            </CCRow>
            <Space height={8} />
          </React.Fragment>
        )}
        {email.bccEmailRecipientsJson && JSON.parse(email.bccEmailRecipientsJson).length > 0 && (
          <React.Fragment>
            <CCRow>
              <EmailText responsive={responsive}>bcc: </EmailText>
              {JSON.parse(email.bccEmailRecipientsJson).map((bccRecipients: any, index: any) => (
                <EmailText key={index} responsive={responsive}>
                  &lt;{bccRecipients.email}&gt;{' '}
                </EmailText>
              ))}
            </CCRow>
            <Space height={8} />
          </React.Fragment>
        )}
        <Row>
          <SanitizedHTML rawHTML={email.html} />
        </Row>
        <Space height={16} />
      </Container>
      <Line />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ThreadEmail.fragment = gql`
  fragment ThreadEmail on Email {
    id
    html
    toEmail
    fromName
    sentAt
    fromEmail
    bccEmailRecipientsJson
    ccEmailRecipientsJson
    subject
    status
  }
`;

export default ThreadEmail;
