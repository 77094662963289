/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobMoveTimesheetView from 'modules/Customer/Document/components/JobMoveTimesheetView';
import ValuationDeclarationTable from 'modules/Customer/Document/components/ValuationDeclarationTable';
import DocumentBillingInformation from 'modules/Document/components/DocumentBillingInformation';

const Wrapper = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
  margin-bottom: 10px;
`;

const SectionTitle = Styled.Text`
  font-size: 14px;
  line-height: 19px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const SectionBody = Styled.Text`
  margin-top: 5px;
  font-size: 14px;
  line-height: 19px;
  color: ${colors.gray.primary};
`;

const DocumentItemSection = ({index, document, documentItem, section}: any) => {
  switch (documentItem.kind) {
    case 'TITLE':
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={index}>
          <SectionTitle>{documentItem.text}</SectionTitle>
        </Wrapper>
      );
    case 'PARAGRAPH':
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={index}>
          <SectionBody>{documentItem.text}</SectionBody>
        </Wrapper>
      );

    // Custom components rendering different sections of different documents.
    case 'BILLING_INFORMATION':
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={index}>
          <DocumentBillingInformation showBillRules showPayments job={document.job} />
        </Wrapper>
      );
    case 'TIMESHEET_INFORMATION':
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={index} style={{marginBottom: 20}}>
          <JobMoveTimesheetView job={document.job} />
        </Wrapper>
      );
    case 'VALUATION_DECLARATION_TABLE':
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={index} style={{marginBottom: 20}}>
          <ValuationDeclarationTable job={document.job} />
        </Wrapper>
      );
    default:
      if (section) {
        // @ts-expect-error TS(2769): No overload matches this call.
        return <Wrapper index={index}>{section(documentItem.kind) || null}</Wrapper>;
      }

      // Otherwise return nothing (no section render function).
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentItemSection.fragment = gql`
  ${DocumentBillingInformation.fragment}
  ${JobMoveTimesheetView.fragment}
  ${ValuationDeclarationTable.fragment}

  fragment DocumentItemSection_Document on Document {
    id
    job {
      id
      ...DocumentBillingInformation
      ...JobMoveTimesheetView
      ...ValuationDeclarationTable
    }
  }

  fragment DocumentItemSection on DocumentItem {
    kind
    text
  }
`;

export default DocumentItemSection;
