// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ProjectClientFields from 'modules/Project/components/ProjectClientFields';
import ProjectInfoFields from 'modules/Project/components/ProjectInfoFields';

const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : 'fit-content')};
  padding-horizontal: 30px;
  z-index: 100;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Space = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 30 : 40)}px;
`;

type OwnProps = {
  isUpdate?: boolean;
  canEditProjectOrganization?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProjectFieldsForJob.defaultProps;

// @ts-expect-error TS(7022): 'ProjectFieldsForJob' implicitly has type 'any' be... Remove this comment to see the full error message
const ProjectFieldsForJob = ({
  isUpdate,
  field,
  organization,
  form,
  shouldResetJobDateOnProjectTypeChange,
  jobFormField,
  canEditProjectOrganization,
}: Props) => {
  const responsive = useResponsive();
  const isClientSearchEnabled = _.get(form.values, `${field}.isClientSearchEnabled`);
  return (
    <Container {...responsive}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Space {...responsive} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={1}>
        <ProjectClientFields
          organization={organization}
          index={0}
          field={`${field}.clientForm`}
          form={form}
          isSearchEnabled={isClientSearchEnabled}
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Space {...responsive} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={2}>
        <ProjectClientFields
          organization={organization}
          index={1}
          field={`${field}.billingClientForm`}
          form={form}
          isSearchEnabled
          isShowingBillingClient
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Space {...responsive} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={3}>
        <ProjectInfoFields
          isUpdate={isUpdate}
          index={2}
          field={field}
          form={form}
          organization={organization}
          shouldResetJobDateOnProjectTypeChange={shouldResetJobDateOnProjectTypeChange}
          jobFormField={jobFormField}
          canEditProjectOrganization={canEditProjectOrganization}
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Space {...responsive} />
    </Container>
  );
};

ProjectFieldsForJob.defaultProps = {
  isUpdate: false,
  canEditProjectOrganization: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectFieldsForJob.fragment = gql`
  ${ProjectClientFields.fragment}
  ${ProjectInfoFields.fragment}

  fragment ProjectFieldsForJob on Organization {
    id
    ...ProjectClientFields
    ...ProjectInfoFields
  }
`;

export default ProjectFieldsForJob;
