// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import StorageProjectStatus from '@shared/modules/Project/enums/StorageProjectStatus';
import ProjectStatusForm from '@shared/modules/Project/forms/ProjectStatusForm';
import useUpdateProjectStatusMutation from '@shared/modules/Project/hooks/useUpdateProjectStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

const Row = Styled.View`
  flex-direction: row;
`;

type OwnProps = {
  project: any;
  onSuccess?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditStorageProjectStatusDropdown.defaultProps;

// @ts-expect-error TS(7022): 'EditStorageProjectStatusDropdown' implicitly has ... Remove this comment to see the full error message
const EditStorageProjectStatusDropdown = ({project, onSuccess}: Props) => {
  const projectStatusForm = ProjectStatusForm.edit(project);
  const {form, handleSubmit} = useUpdateProjectStatusMutation({
    projectStatusForm,
    onSuccess: ({project}: any) => {
      onSuccess(project);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <Row>
      <StatusDropdown
        options={StorageProjectStatus.OPTIONS}
        initialValue={form.values.projectStatusForm.status}
        onSelect={(option: any) => {
          form.setFieldValue('projectStatusForm.status', option.value);
          setTimeout(() => handleSubmit(), 0);
        }}
        size={StatusDropdown.SIZE.LARGE}
      />
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditStorageProjectStatusDropdown.fragment = gql`
  ${ProjectStatusForm.edit.fragment}

  fragment EditStorageProjectStatusDropdown on Project {
    id
    ...ProjectStatusForm_edit
  }
`;

EditStorageProjectStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditStorageProjectStatusDropdown;
