// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const shouldResetJobDate = ({
  prevJobTypeId,
  nextJobTypeId,
  jobTypes,
  shouldResetJobDateOnJobTypeChange,
}: any) => {
  // Only returns true if flag is set and switch from an unblocked to a blocked job type
  // i.e. from ESTIMATE to MOVE
  const unblockedJobTypes = ['ESTIMATE', 'REQUEST'];
  const jobType = jobTypes.find((jobType: any) => jobType.id === _.toString(nextJobTypeId));
  const prevJobType = jobTypes.find((jobType: any) => jobType.id === _.toString(prevJobTypeId));
  const isPrevUnblocked = prevJobType && unblockedJobTypes.includes(prevJobType.kind);
  const isNextBlocked = jobType && !unblockedJobTypes.includes(jobType.kind);
  return shouldResetJobDateOnJobTypeChange && isPrevUnblocked && isNextBlocked;
};

const handleChangeJobType = ({
  form,
  field,
  value,
  prevValue,
  organization,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  jobTypes,
}: any) => {
  const {isEnabledCreateProjectDispatchLocks} = organization.features;
  if (
    isEnabledCreateProjectDispatchLocks &&
    shouldResetJobDate({
      prevJobTypeId: prevValue,
      nextJobTypeId: value,
      jobTypes,
      shouldResetJobDateOnJobTypeChange,
    })
  ) {
    form.setFieldValue(`${jobFormField}.date`, '');
  }

  form.setFieldValue(`${field}.valueForms`, []);
};

type OwnJobTypeDropdownProps = {
  index?: number;
  organization: any;
  projectTypeId?: number;
  form: any;
  field: string;
  shouldResetJobDateOnJobTypeChange?: boolean;
  jobFormField: string;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'JobTypeDropdownProps' circularly refer... Remove this comment to see the full error message
type JobTypeDropdownProps = OwnJobTypeDropdownProps & typeof JobTypeDropdown.defaultProps;

// @ts-expect-error TS(7022): 'JobTypeDropdown' implicitly has type 'any' becaus... Remove this comment to see the full error message
const JobTypeDropdown = ({
  index,
  organization,
  projectTypeId,
  form,
  field,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  isRequired,
  isResponsive,
  isDisabled,
  style,
}: JobTypeDropdownProps) => {
  const responsive = useResponsive();
  const projectType = _.find(organization.moveProjectTypes, (projectType) => {
    return String(projectType.id) === String(projectTypeId);
  });
  const jobTypes = projectType ? projectType.jobTypes : [];

  return (
    <FieldInput
      {...form}
      index={index}
      component={DropdownInput}
      name={`${field}.jobTypeId`}
      label={'Job Type'}
      isRequired={isRequired}
      isResponsive={isResponsive}
      isDisabled
      input={{
        options: jobTypes.map((jobType: any) => ({
          label: jobType.name,
          value: Number(jobType.id),
        })),
        // If the isRequired prop is passed in, we show the
        // updated styling for marking as required
        required: !isRequired && !_.get(form.values, `${field}.jobTypeId`),
        placeholder: 'Select Job Type',
        isSearchable: true,
        isDisabled,
        setFieldValue: form.customSetFieldValue || form.setFieldValue,
        onChangeValue: (value: any, option: any, prevValue: any) => {
          handleChangeJobType({
            form,
            field,
            value,
            prevValue,
            organization,
            shouldResetJobDateOnJobTypeChange,
            jobFormField,
            jobTypes,
          });
        },
        style: style || {
          width: responsive.mobile ? '100%' : 250,
        },
      }}
      style={
        style || {
          width: responsive.mobile ? '100%' : 250,
        }
      }
    />
  );
};

JobTypeDropdown.defaultProps = {
  index: 0,
  projectTypeId: null,
  shouldResetJobDateOnJobTypeChange: false,
  style: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTypeDropdown.fragment = gql`
  fragment JobTypeDropdown on Organization {
    id
    features {
      isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      jobTypes {
        id
        kind
        name
      }
    }
  }
`;

export default JobTypeDropdown;
