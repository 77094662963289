// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useMountEffect, useState} from '@supermove/hooks';
import {PrintLetterStyle, PrintPageStyle, colors} from '@supermove/styles';
import {Document} from '@supermove/utils';

// App
import {ModalPage} from 'modules/App/components';

const Background = Styled.View`
  flex: 1;
  align-items: center;
  width: 100%;
  padding-bottom: 180px;
  background-color: ${colors.gray.background};
  overflow: scroll;
`;

/**
 * We set min-height so the preview looks realistic when
 * there is not enough content to fill a single page.
 * min-height should technically be 1024px to match the 8.5" x 11"
 * paper dimensions. But, this results in two pages when there
 * should only be one; as such, we set a min-height of 960px.
 */
const Wrapper = Styled.View`
  width: 796px;
  min-height: 960px;
  padding-vertical: 36px;
  padding-horizontal: 36px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  width: 720px;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 796px;
  padding-vertical: 20px;
`;

const Touchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  padding-vertical: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Text = Styled.H6`
  margin-right: 6px;
`;

const Indicator = Styled.Loading`
`;

const openPrintDialog = async ({setIsLoading}: any) => {
  const filename = 'document.pdf';
  const url = window.location.href;
  setIsLoading(true);
  const pdf = await Document.generatePDFFromUrl({filename, url});
  // @ts-expect-error TS(2739): Type 'Blob' is missing the following properties fr... Remove this comment to see the full error message
  Document.printFile({file: pdf});
  setIsLoading(false);
};

const ServerPrintButton = () => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Touchable
      disabled={isLoading}
      onPress={() => openPrintDialog({setIsLoading})}
      style={{marginRight: 10}}
    >
      {isLoading ? (
        <Indicator size={'small'} color={colors.gray.secondary} />
      ) : (
        <React.Fragment>
          <Text>Print</Text>
          <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.Print} />
        </React.Fragment>
      )}
    </Touchable>
  );
};

/* NOTE: `window.isReadyForPDF` is used by the PDF service. */
const Printable = ({children, data}: any) => {
  // After component did mount and fonts did load, set `isReadyForPDF`.
  useMountEffect(() => {
    if (document.fonts) {
      document.fonts.ready.then(() => {
        (window as any).isReadyForPDF = true;
      });
    } else {
      (window as any).isReadyForPDF = true;
    }
  });
  return (
    <Wrapper data-print-wrapper>
      <Container data-print-container>{children({data})}</Container>
    </Wrapper>
  );
};

type OwnPrintDocumentPageProps = {
  query: any;
  variables: any;
};

// @ts-expect-error TS(2456): Type alias 'PrintDocumentPageProps' circularly ref... Remove this comment to see the full error message
type PrintDocumentPageProps = OwnPrintDocumentPageProps & typeof PrintDocumentPage.defaultProps;

// @ts-expect-error TS(7022): 'PrintDocumentPage' implicitly has type 'any' beca... Remove this comment to see the full error message
const PrintDocumentPage = ({query, variables, children}: PrintDocumentPageProps) => (
  <ModalPage query={query} variables={variables}>
    {(props: any) => (
      <Background data-print-background>
        <PrintLetterStyle />
        <PrintPageStyle />
        <Header data-no-print>
          <ServerPrintButton />
        </Header>
        {_.get(props, 'data', null) && <Printable children={children} data={props.data} />}
      </Background>
    )}
  </ModalPage>
);

PrintDocumentPage.defaultProps = {};

export default PrintDocumentPage;
