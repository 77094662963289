// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// Components
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import {JobStatusBadge} from 'modules/Job/components';
import JobDispatchStatus from 'modules/Project/V2/Show/Blocks/Job/components/JobDispatchStatus';

const Container = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isFocused' does not exist on type 'Theme... Remove this comment to see the full error message
    isFocused,
  }) => (isFocused ? colors.gray.background : colors.white)};
  padding: 12px;
  cursor: pointer;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ResponsiveRow = Styled.View`
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 'column' : 'row')};
`;

const ProjectTypeDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color}
`;

const HeaderText = Styled.Text`
  ${Typography.Body}
`;

const HeaderBoldText = Styled.Text`
  ${Typography.Label}
`;

const DetailText = Styled.Text`
  ${Typography.Micro}
`;

const Training = Styled.Text`
  ${Typography.Body}
  margin-left: 8px;
  color: ${colors.purple.hover};
`;

const Divider = Styled.View`
  height: 18px;
  width: 1px;
  margin-horizontal: 8px;
  background-color: ${colors.gray.border};
`;

const ResponsiveDivider = () => {
  const responsive = useResponsive();
  if (responsive.mobile) {
    return <Space height={8} />;
  }

  return <Divider />;
};

const LoadingComponent = () => {
  const responsive = useResponsive();

  return (
    <Container>
      <Row>
        <SkeletonLoader height={22} width={80} />
        <Space width={8} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={160} />
      </Row>
      <Space height={8} />
      <Row>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={64} />
        <Divider />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={80} />
      </Row>
      <Space height={8} />
      <ResponsiveRow {...responsive}>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
        <ResponsiveDivider />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={100} />
      </ResponsiveRow>
    </Container>
  );
};

const JobHeader = ({job, isFocused}: any) => {
  const jobName = `${job.fullName}: ${job.name}`;

  return (
    <Row>
      <JobDispatchStatus job={job} showLabel />
      <Space width={8} />
      {isFocused ? (
        <HeaderBoldText numberOfLines={1}>{jobName}</HeaderBoldText>
      ) : (
        <HeaderText numberOfLines={1}>{jobName}</HeaderText>
      )}
      {job.isTest && <Training>(Training)</Training>}
    </Row>
  );
};

const ProjectInformation = ({job}: any) => {
  return (
    <Row>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ProjectTypeDot color={job.project.projectType.color} />
      <Space width={8} />
      <DetailText>{`${job.project.projectType.name}`}</DetailText>
      <Divider />
      <DetailText>{`${Project.getDisplayText(job.project, job.name)}`}</DetailText>
    </Row>
  );
};

const JobDetail = ({icon, style, children}: any) => {
  return (
    <Row style={style}>
      <Icon source={icon} size={10} color={colors.gray.tertiary} />
      <Space width={8} />
      <DetailText numberOfLines={1}>{children || '--'}</DetailText>
    </Row>
  );
};

const JobDetails = ({job}: any) => {
  const responsive = useResponsive();

  return (
    <ResponsiveRow style={responsive.mobile ? {flex: 1} : null} {...responsive}>
      <JobDetail icon={Icon.Calendar}>
        {Job.getDisplayDate(job, Datetime.DISPLAY_SHORT_DATE)}
      </JobDetail>
      <ResponsiveDivider />
      <JobDetail icon={Icon.User}>{job.project.client.name}</JobDetail>
      <ResponsiveDivider />
      <JobDetail icon={Icon.Phone}>{Phone.display(job.customer.phoneNumber)}</JobDetail>
      <ResponsiveDivider />
      <JobDetail icon={Icon.Envelope} style={{flex: 1}}>
        {job.customer.email}
      </JobDetail>
    </ResponsiveRow>
  );
};

type OwnGlobalSearchItemProps = {
  job: any;
  isFocused?: boolean;
  innerProps?: any;
};

// @ts-expect-error TS(2456): Type alias 'GlobalSearchItemProps' circularly refe... Remove this comment to see the full error message
type GlobalSearchItemProps = OwnGlobalSearchItemProps & typeof GlobalSearchItem.defaultProps;

// @ts-expect-error TS(7022): 'GlobalSearchItem' implicitly has type 'any' becau... Remove this comment to see the full error message
const GlobalSearchItem = ({job, isFocused, innerProps}: GlobalSearchItemProps) => {
  return (
    <Container {...innerProps} isFocused={isFocused}>
      <JobHeader job={job} isFocused={isFocused} />
      <Space height={8} />
      <ProjectInformation job={job} />
      <Space height={8} />
      <JobDetails job={job} />
    </Container>
  );
};

GlobalSearchItem.LoadingComponent = LoadingComponent;

GlobalSearchItem.defaultProps = {
  isFocused: false,
  innerProps: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalSearchItem.fragment = gql`
  ${Job.getDisplayDate.fragment}
  ${Job.getFullName.fragment}
  ${Project.getDisplayText.fragment}
  ${JobStatusBadge.fragment}
  ${JobDispatchStatus.fragment}

  fragment GlobalSearchItem on Job {
    id
    uuid
    isTest
    name
    moveSize
    totalDistance
    startTime1
    startTime2
    fullName
    name
    day {
      id
      value
    }
    customer {
      id
      fullName
      phoneNumber
      email
    }
    jobType {
      id
      name
    }
    project {
      id
      projectType {
        id
        color
        name
      }
      client {
        id
        name
      }
      ...Project_getDisplayText
    }
    ...Job_getDisplayDate
    ...Job_getFullName
    ...JobStatusBadge
    ...JobDispatchStatus
  }
`;

export default GlobalSearchItem;
