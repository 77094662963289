// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  userId: string;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeactivateEmployeeMutation.defaultProps;

// @ts-expect-error TS(7022): 'DeactivateEmployeeMutation' implicitly has type '... Remove this comment to see the full error message
const DeactivateEmployeeMutation = ({userId, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      userId,
    }}
    mutation={DeactivateEmployeeMutation.mutation}
    onCompleted={({response: {user, errors}}: any) => {
      if (user) {
        onSuccess(user);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

DeactivateEmployeeMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeactivateEmployeeMutation.mutation = gql`
  mutation DeactivateEmployeeMutation(
    $userId: Int!,
  ) {
    response: deactivateEmployee(
      userId: $userId,
    ) {
      ${gql.errors}
      user {
        id
        isActive
      }
    }
  }
`;

export default DeactivateEmployeeMutation;
