// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job, Project, Truck} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// Components
import {JobUsersList} from 'modules/App/Job/components';
import JobEstimatedRangeBadge from 'modules/Job/JobCard/components/JobEstimatedRangeBadge';
import {JobStatusBadge} from 'modules/Job/components';

import JobOfficeNotesModal from './JobOfficeNotesModal';

const Container = Styled.View`
  padding-vertical: 5px;
  background-color: ${(props) => colors.alpha((props as any).color, (props as any).isHovered ? 0.05 : 0.02)};
  border-left-width: 5px;
  border-left-color: ${(props) => (props as any).color};
`;

const JobTouchable = Styled.Touchable`
  padding-horizontal: 10px;
`;

const JobUsers = Styled.View`
  padding-horizontal: 10px;
`;

const NotesTouchable = Styled.Touchable`
  padding-horizontal: 10px;
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  align-items: ${(props) => ((props as any).mobile ? 'flex-start' : 'center')};
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'space-between')};
`;

const Line = Styled.View`
  height: 1px;
  margin-vertical: 5px;
  background-color: ${colors.blue.accent};
`;

const LeftInfo = Styled.View`
  ${(props) => ((props as any).mobile ? 'width: 100%' : 'flex: 1')};
  overflow: hidden;
`;

const RightInfo = Styled.View`
  align-items: flex-end;
  overflow: hidden;
`;

const NumberRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const ProjectName = Styled.H8`
  color: ${colors.gray.secondary};
`;

const JobName = Styled.H6`
  ${fontWeight(700)}
`;

const Training = Styled.H6`
  margin-left: 5px;
  ${fontWeight(700)}
  color: ${colors.purple.hover};
`;

const ProjectTypeName = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const InfoRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const InfoText = Styled.H8`
`;

const Status = Styled.View`
  margin-vertical: 5px;
`;

const OfficeNotes = Styled.H8`
  font-style: italic;
`;

const TruckText = Styled.H8`
  margin-bottom: 2px;
`;

const UsersText = Styled.H8`
  margin-bottom: 2px;
`;

const CrewInfoRowContainer = Styled.View`
  flex-direction: row;
`;

const GrayLabel = Styled.H8`
  background-color: ${colors.gray.tertiary};
  padding-vertical: 4px;
  padding-horizontal: 6px;
  border-radius: 4px;
  color: ${colors.white}
  ${fontWeight(600)}
`;

const PrimaryCrewLabel = Styled.H8`
  background-color: ${colors.orange.status};
  padding-vertical: 4px;
  padding-horizontal: 6px;
  border-radius: 4px;
  color: ${colors.white}
  ${fontWeight(600)}
`;

const getTruckName = ({job}: any) => {
  if (job.jobTrucks && job.jobTrucks.length) {
    return job.jobTrucks.map((jobTruck: any) => Truck.getFullName(jobTruck.truck)).join(', ');
  } else {
    return 'Not assigned yet';
  }
};

const CrewInfoRow = ({job}: any) => {
  const {crews} = job;
  const primaryCrew = _.find(crews, {isPrimary: true});
  const totalCrewsCount = crews.length;
  const additionalCrewsCount = totalCrewsCount - 1;

  if (!primaryCrew) {
    return (
      <React.Fragment>
        <Space height={8} />
        <CrewInfoRowContainer>
          <GrayLabel>No primary contractor assigned</GrayLabel>
          <Space width={4} />
          {totalCrewsCount > 0 && <GrayLabel>{`+${totalCrewsCount}`}</GrayLabel>}
        </CrewInfoRowContainer>
        <Space height={8} />
      </React.Fragment>
    );
  }

  if (!job.organization.hasMultipleOrganizations) {
    return null;
  }

  return (
    <React.Fragment>
      <Space height={8} />
      <CrewInfoRowContainer>
        <PrimaryCrewLabel>{primaryCrew.organization.name}</PrimaryCrewLabel>
        <Space width={4} />
        {additionalCrewsCount > 0 && <GrayLabel>{`+${additionalCrewsCount}`}</GrayLabel>}
      </CrewInfoRowContainer>
      <Space height={8} />
    </React.Fragment>
  );
};

const DayJobItemV1 = ({job}: any) => {
  const {navigator, params} = useNavigationDOM();
  const responsive = useResponsive();
  const {isHovered, ref} = useHover();
  const {project} = job;
  const inventoryWeight = job.inventory.collection.totalWeight;
  const date = params.date ? params.date : Datetime.toDisplayDate(Datetime.today, 'YYYY/MM/DD');

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container ref={ref} isHovered={isHovered} color={project.projectType.color}>
      <JobTouchable onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
        <ProjectTypeName color={project.projectType.color}>
          {project.projectType.name}
        </ProjectTypeName>
        <NumberRow {...responsive}>
          {job.hasPacking && <Emoji component={EmojiText} name={'package'} />}
          <ProjectName>{Project.getDisplayText(project, job.name)}</ProjectName>
        </NumberRow>
        <InfoRow>
          {Job.hasEstimatedRangeJobCounter(job) && (
            <React.Fragment>
              <JobEstimatedRangeBadge job={job} date={date} />
              <Space width={4} />
            </React.Fragment>
          )}
          <JobName>
            {Job.getFullName(job)}
            {job.isTest && <Training>(Training)</Training>}
          </JobName>
        </InfoRow>
        <CrewInfoRow job={job} />
        <Row
          {...responsive}
          style={{
            alignItems: 'flex-start',
          }}
        >
          <LeftInfo {...responsive}>
            <InfoText numberOfLines={1}>
              {!!job.startTime1 && Datetime.convertToDisplayTime(job.startTime1)}
              {!!job.startTime1 && !!project.size && `, `}
              {!!project.size && `${project.size}`}
            </InfoText>
            <InfoText numberOfLines={1}>
              {`${job.pickUpLocation.city} to ${job.dropOffLocation.city} ` +
                `(${Job.getTotalDistanceText(job)})`}
            </InfoText>
            <InfoRow>
              <Icon source={Icon.BoxOpen} color={colors.gray.primary} size={Icon.Sizes.Small} />
              <Space width={4} />
              <InfoText numberOfLines={1}>
                {inventoryWeight > 0 ? pluralize('lb', inventoryWeight.toFixed(2), true) : 'N/A'}
              </InfoText>
            </InfoRow>
          </LeftInfo>
          {!responsive.mobile && (
            <RightInfo {...responsive}>
              <Status>
                <JobStatusBadge job={job} />
              </Status>
            </RightInfo>
          )}
        </Row>
        {responsive.mobile && (
          <React.Fragment>
            <Line />
            <Row {...responsive}>
              <JobStatusBadge job={job} />
            </Row>
          </React.Fragment>
        )}
      </JobTouchable>
      {job.organization.features.isEnabledShowAssignedMoversOnMovesCalendar && (
        <React.Fragment>
          {job.primaryStatus !== 'CANCELLED' && (
            <JobUsers>
              <Line />
              <Row {...responsive}>
                <LeftInfo {...responsive}>
                  {job.kind === 'ESTIMATE' && (
                    <UsersText numberOfLines={1}>
                      {[
                        `Salesperson: ${project.bookedBy ? project.bookedBy.fullName : 'N/A'}`,
                        `Coordinator: ${
                          project.coordinatedBy ? project.coordinatedBy.fullName : 'N/A'
                        }`,
                      ].join(', ')}
                    </UsersText>
                  )}
                  <TruckText numberOfLines={1}>{`Trucks: ${getTruckName({job})}`}</TruckText>
                  <JobUsersList isCompact={job.primaryStatus === 'COMPLETE'} job={job} />
                </LeftInfo>
              </Row>
            </JobUsers>
          )}
        </React.Fragment>
      )}
      {!!job.officeNotes && (
        <JobOfficeNotesModal
          job={job}
          trigger={({handleOpen}: any) => (
            <NotesTouchable onPress={handleOpen}>
              <React.Fragment>
                <Line />
                <OfficeNotes numberOfLines={1}>{`Notes: ${job.officeNotes}`}</OfficeNotes>
              </React.Fragment>
            </NotesTouchable>
          )}
        />
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DayJobItemV1.fragment = gql`
  ${Job.getFullName.fragment}
  ${Job.hasEstimatedRangeJobCounter.fragment}
  ${Project.getDisplayText.fragment}
  ${JobOfficeNotesModal.fragment}
  ${JobStatusBadge.fragment}
  ${JobUsersList.fragment}
  ${JobEstimatedRangeBadge.fragment}

  fragment DayJobItemV1 on Job {
    id
    uuid
    kind
    isTest
    primaryStatus: calendarPrimaryStatus
    totalDistance
    startTime1
    startTime2
    hasPacking
    officeNotes
    name
    organization {
      id
      hasMultipleOrganizations
      features {
        isEnabledShowAssignedMoversOnMovesCalendar: isEnabled(
          feature: "SHOW_ASSIGNED_MOVERS_ON_MOVES_CALENDAR"
        )
      }
    }
    project {
      id
      size
      projectType {
        id
        color
        name
      }
      bookedBy {
        id
        fullName
      }
      coordinatedBy {
        id
        fullName
      }
      ...Project_getDisplayText
    }
    crews {
      id
      isPrimary
      organization {
        id
        name
      }
    }
    customer {
      id
      aliasName
      firstName
      lastName
    }
    inventory {
      id
      collection {
        id
        totalWeight
      }
    }
    jobTrucks {
      id
      truckId
      jobId
      truck {
        id
        name
        size
      }
    }
    pickUpLocation {
      id
      city
    }
    dropOffLocation {
      id
      city
    }
    ...Job_getFullName
    ...Job_hasEstimatedRangeJobCounter
    ...JobOfficeNotesModal
    ...JobStatusBadge
    ...JobUsersList
    ...JobEstimatedRangeBadge
  }
`;

export default DayJobItemV1;
