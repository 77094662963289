// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {MoveUser} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {EmployeeSearchField} from 'modules/User/components';

import UpdateReportMoveMoveUsersList from './UpdateReportMoveMoveUsersList';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.secondary};
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getTimeDisplay = ({time}: any) => {
  const hours = Math.floor(time / 60);
  const minutes = Math.floor(time % 60);
  return `${hours === 0 ? '' : `${hours} hr`}${minutes === 0 ? '' : ` ${Math.abs(minutes)} min`}`;
};

const handleSelectEmployee = ({employee, job, form, refetch}: any) => {
  const moveUsers = _.get(form.values, `moveUserInputs`);

  // userId and jobId are always required. moveId is optional (not included on manually completed jobs).
  const newMoveUsers = moveUsers.concat([
    {
      userId: employee.id,
      jobId: job.id,
      moveId: _.get(job, 'move.id', null),
      timePayableAdjustment: 0,
    },
  ]);

  const variables = {
    moveUserInputs: newMoveUsers.map((moveUser: any) => MoveUser.toMutation(moveUser)),
  };

  form.handleSubmit({variables}).then(
    ({
      data: {
        response: {reportMove, errors},
      },
    }: any) => {
      refetch();
    },
  );
};

const UpdateReportMoveMoveUsersFields = ({form, reportMove, refetch}: any) => (
  <Container>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={0}>
      <Title>Crew Hours</Title>
      <Subtitle>
        {`Hours payable calculated from work timesheet: ${getTimeDisplay({
          time: reportMove.timePayable,
        })}.`}
      </Subtitle>
    </Row>
    <RowSpace />
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={1}>
      <UpdateReportMoveMoveUsersList form={form} timePayable={reportMove.timePayable} />
    </Row>
    <RowSpace />
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={2}>
      <Label>Add Mover</Label>
      <EmployeeSearchField
        companyId={reportMove.job.companyId}
        selectedEmployeeIds={form.values.moveUserInputs.map((moveUser: any) => moveUser.userId)}
        onSelectEmployee={(employee: any) => {
          handleSelectEmployee({employee, job: reportMove.job, form, refetch});
        }}
      />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveMoveUsersFields.fragment = gql`
  fragment UpdateReportMoveMoveUsersFields on ReportMove {
    id
    timePayable
    job {
      id
      companyId
      move {
        id
      }
    }
  }
`;

export default UpdateReportMoveMoveUsersFields;
