// DEPRECATED
// TODO(jholston) Remove with isEnabledConditionalBillItems

// Libraries
import React from 'react';

// Supermove
import {Drawer, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '100%' : '424px')};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const CloseButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const Header = ({headerText, handleClose}: any) => {
  return (
    <HeaderContainer>
      <HeaderText>{headerText}</HeaderText>
      <CloseButton onPress={handleClose}>
        <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
      </CloseButton>
    </HeaderContainer>
  );
};

const Footer = ({submitting, handleSubmit, handleClose}: any) => {
  return (
    <FooterContainer>
      <Button
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
        text={'Cancel'}
      />
      <Space width={8} />
      <Button
        onPress={handleSubmit}
        isDisabled={submitting}
        isSubmitting={submitting}
        text={'Save'}
      />
    </FooterContainer>
  );
};

const DrawerContent = ({headerText, handleClose, isSubmitting, handleSubmit, children}: any) => {
  const responsive = useResponsive();
  return (
    <Container responsive={responsive}>
      <Header headerText={headerText} handleClose={handleClose} />
      <BodyContainer>
        <ScrollView contentContainerStyle={{padding: 16}}>{children}</ScrollView>
      </BodyContainer>
      <Footer submitting={isSubmitting} handleSubmit={handleSubmit} handleClose={handleClose} />
    </Container>
  );
};

type OwnBillRuleTypeDrawerProps = {
  isOpen?: boolean;
  isSubmitting?: boolean;
  handleSubmit?: (...args: any[]) => any;
  handleClose?: (...args: any[]) => any;
  headerText?: string;
};

// @ts-expect-error TS(2456): Type alias 'BillRuleTypeDrawerProps' circularly re... Remove this comment to see the full error message
type BillRuleTypeDrawerProps = OwnBillRuleTypeDrawerProps & typeof BillRuleTypeDrawer.defaultProps;

// @ts-expect-error TS(7022): 'BillRuleTypeDrawer' implicitly has type 'any' bec... Remove this comment to see the full error message
const BillRuleTypeDrawer = ({
  isOpen,
  handleClose,
  headerText,
  isSubmitting,
  handleSubmit,
  children,
}: BillRuleTypeDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerContent
        handleClose={handleClose}
        headerText={headerText}
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};

BillRuleTypeDrawer.defaultProps = {
  isOpen: false,
  isSubmitting: false,
  handleSubmit: () => {},
  handleClose: () => {},
  headerText: '',
};

export default BillRuleTypeDrawer;
