// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import BookingFiltersForm from '@shared/modules/Booking/forms/BookingFiltersForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BookingCalendarWeekDayHeader from 'modules/Calendar/Booking/components/BookingCalendarWeekDayHeader';
import BookingCalendarWeekDayV1 from 'modules/Calendar/Booking/components/BookingCalendarWeekDayV1';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  background-color: ${colors.white};
  box-shadow: 0 2px 2px rgba(194, 194, 194, 0.5);
  z-index: 1;
`;

const getDateToQuery = ({date}: any) => {
  return Datetime.toMutationDate(Datetime.previousSunday(Datetime.fromDate(date)));
};

const getDateRange = ({date, responsive}: any) => {
  return responsive.mobile
    ? [date]
    : Datetime.getDateRange(getDateToQuery({date}), 7).map((datetime: any) =>
        Datetime.toDate(datetime),
      );
};

const DayHeader = ({
  data,
  date,
  refetch,
  form,
  isEnabledBookingCalendarTruckAndMoverAvailability,
}: any) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return (
    <BookingCalendarWeekDayHeader
      calendarDay={calendarDay}
      refetch={refetch}
      form={form}
      isEnabledBookingCalendarTruckAndMoverAvailability={
        isEnabledBookingCalendarTruckAndMoverAvailability
      }
    />
  );
};

const DayColumn = ({
  data,
  date,
  refetch,
  isEnabledBookingCalendarTruckAndMoverAvailability,
}: any) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return (
    <BookingCalendarWeekDayV1
      calendarDay={calendarDay}
      isEnabledBookingCalendarTruckAndMoverAvailability={
        isEnabledBookingCalendarTruckAndMoverAvailability
      }
    />
  );
};

const BookingCalendarWeekV1 = ({date, form}: any) => {
  const responsive = useResponsive();
  const salespersonIds = BookingFiltersForm.getSelectedSalespersonIds(form.values);
  const projectTypeIds = BookingFiltersForm.getSelectedProjectTypeIds(form.values);

  const {loading, data, refetch} = useQuery(BookingCalendarWeekV1.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: getDateToQuery({date}),
      salespersonIds,
      projectTypeIds,
      showOnlyPackingJobs: form.values.showOnlyPackingJobs,
      showOnlyNonPackingJobs: form.values.showOnlyNonPackingJobs,
      organizationSlug: form.values.selectedOrganizationSlug,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        const {isEnabledBookingCalendarTruckAndMoverAvailability} =
          data.viewer.viewingOrganization.features;
        return (
          <Container>
            <Header>
              {getDateRange({date, responsive}).map((date: any) => (
                <DayHeader
                  key={date}
                  data={data}
                  date={date}
                  form={form}
                  refetch={refetch}
                  isEnabledBookingCalendarTruckAndMoverAvailability={
                    isEnabledBookingCalendarTruckAndMoverAvailability
                  }
                />
              ))}
            </Header>
            <ScrollView
              contentContainerStyle={{
                flexDirection: 'row',
                paddingBottom: 180,
              }}
              style={{flex: 1}}
            >
              {getDateRange({date, responsive}).map((date: any) => (
                <DayColumn
                  key={date}
                  data={data}
                  date={date}
                  refetch={refetch}
                  isEnabledBookingCalendarTruckAndMoverAvailability={
                    isEnabledBookingCalendarTruckAndMoverAvailability
                  }
                />
              ))}
            </ScrollView>
          </Container>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeekV1.query = gql`
  ${BookingCalendarWeekDayV1.fragment}
  ${BookingCalendarWeekDayHeader.fragment}

  query BookingCalendarWeek(
    $date: String!,
    $salespersonIds: [Int]!,
    $projectTypeIds: [Int]!,
    $showOnlyPackingJobs: Boolean!,
    $showOnlyNonPackingJobs: Boolean!,
    $organizationSlug: String!,
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        features {
          isEnabledBookingCalendarTruckAndMoverAvailability: isEnabled(
            feature: "BOOKING_CALENDAR_TRUCK_AND_MOVER_AVAILABILITY",
          )
        }
      }
    }
    calendar: calendarV4(
      date: $date,
      salespersonIds: $salespersonIds,
      projectTypeIds: $projectTypeIds,
      showOnlyPackingJobs: $showOnlyPackingJobs,
      showOnlyNonPackingJobs: $showOnlyNonPackingJobs,
      organizationSlug: $organizationSlug,
    ) {
      calendarDays {
        ...BookingCalendarWeekDayV1
        ...BookingCalendarWeekDayHeader
      }
    }
  }
`;

export default BookingCalendarWeekV1;
