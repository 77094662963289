// Libararies
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import FieldInputClickToCopy from 'modules/App/components/FieldInputClickToCopy';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import CreateOrganizationWebhookModal from 'modules/Organization/Settings/Integrations/components/CreateOrganizationWebhookModal';
import IntegrationsPageHeader from 'modules/Organization/Settings/Integrations/components/IntegrationsPageHeader';
import IntegrationsPageNavigationTabs from 'modules/Organization/Settings/Integrations/components/IntegrationsPageNavigationTabs';
import WebhooksTable from 'modules/Organization/Settings/Integrations/components/WebhooksTable';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 12 : 24)}px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
;`;

const CreateWebhookButton = Styled.ButtonV2`
  width: 159px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const ButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const OrganizationSettingsIntegrationsWebhooksContent = ({organization, refetch, loading}: any) => {
  const createOrganizationWebhookModal = useModal({name: 'Create Organization Webhook Modal'});

  return (
    <React.Fragment>
      <Space height={16} />
      <HeaderText>Information</HeaderText>
      <Space height={16} />
      <FieldInputClickToCopy
        label='Organization ID'
        style={{width: 232}}
        value={organization?.slug}
      />
      <Space height={16} />
      <FieldInputClickToCopy
        label='API Key'
        style={{width: 232}}
        value={organization?.apiToken}
        isSecure
      />
      <Space height={16} />
      <HeaderText>Webhooks</HeaderText>
      <Space height={12} />
      <CreateWebhookButton
        color={colors.blue.interactive}
        onPress={createOrganizationWebhookModal.handleOpen}
      >
        <ButtonText color={colors.white}>Create Webhook</ButtonText>
      </CreateWebhookButton>
      <Space height={12} />
      <WebhooksTable webhooks={organization?.webhooks} refetch={refetch} loading={loading} />
      <CreateOrganizationWebhookModal
        key={createOrganizationWebhookModal.key}
        organizationId={organization?.id}
        isOpen={createOrganizationWebhookModal.isOpen}
        handleClose={createOrganizationWebhookModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsIntegrationsWebhooksPage = () => {
  const {data, refetch, loading} = useQuery(OrganizationSettingsIntegrationsWebhooksPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <Container>
        <IntegrationsPageHeader />
        <ContentContainer>
          <IntegrationsPageNavigationTabs />
          <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
            <ScrollView>
              <OrganizationSettingsIntegrationsWebhooksContent
                organization={data?.viewer?.viewingOrganization}
                refetch={refetch}
                loading={loading}
              />
              <Space height={64} />
            </ScrollView>
          </ScrollView>
        </ContentContainer>
      </Container>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsIntegrationsWebhooksPage.query = gql`
  ${WebhooksTable.fragment}

  query OrganizationSettingsIntegrationsWebhooksPage {
    ${gql.query}
    viewer {
      id
      role
      viewingOrganization {
        id
        apiToken
        slug
        webhooks {
          id
          ...WebhooksTable
        }
      }
    }
  }
`;

export default OrganizationSettingsIntegrationsWebhooksPage;
