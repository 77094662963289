// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components
const Container = Styled.View`
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => ((props as any).isLast ? 0 : 5)}px;
`;

const Avatar = Styled.View`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${colors.gray.tertiary};
`;

type OwnProps = {
  isLast: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PlaceholderJobUserAvatar.defaultProps;

// @ts-expect-error TS(7022): 'PlaceholderJobUserAvatar' implicitly has type 'an... Remove this comment to see the full error message
const PlaceholderJobUserAvatar = ({isLast}: Props) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container isLast={isLast}>
    <Avatar>
      <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.Plus} />
    </Avatar>
  </Container>
);

PlaceholderJobUserAvatar.defaultProps = {};

export default PlaceholderJobUserAvatar;
