// Libraries
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';
import ProjectTypeValuationCoverageContent from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeValuationCoverageContent';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const CreateButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${colors.blue.interactive};
  border-radius: 4px;
  width: fit-content;
`;

const CreateButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const ProjectTypeValuationCoverageSettingsPageContent = ({projectTypeUuid}: any) => {
  const uploadValuationCoverageTableModal = useModal({
    name: 'Upload Valuation Coverage Table Modal',
  });
  const {loading, data} = useQuery(ProjectTypeValuationCoverageSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <PageContentContainer>
        <ScrollView>
          <Space height={24} />
          <HeaderText>Configure Valuation Coverage</HeaderText>
          <Space height={12} />
          <CreateButton onPress={uploadValuationCoverageTableModal.handleOpen}>
            <CreateButtonText>Update Valuation Coverage</CreateButtonText>
          </CreateButton>
          <ProjectTypeValuationCoverageContent
            projectTypeUuid={projectTypeUuid}
            uploadValuationCoverageTableModal={uploadValuationCoverageTableModal}
          />
        </ScrollView>
      </PageContentContainer>
    </PageContainer>
  );
};

const ProjectTypeValuationCoverageSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypeValuationCoverageSettingsPageContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypeValuationCoverageSettingsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}

  query ProjectTypeValuationCoverageSettingsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      ...ProjectTypeSettingsPageHeader
    }
  }
`;

export default ProjectTypeValuationCoverageSettingsPage;
