// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

const DeleteConditionalBillItemModal = ({
  isOpen,
  handleClose,
  handleConfirm,
  billRuleName,
  billItemName,
}: any) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={`Remove '${billItemName}'?`}
      message={`This is a conditional bill item and will also remove '${billRuleName}' upon saving.`}
      handlePrimaryAction={handleConfirm}
      handleSecondaryAction={handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

export default DeleteConditionalBillItemModal;
