// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

const Item = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 10px;
`;

const Cell = Styled.View`
`;

const CellSpace = Styled.View`
  width: 10px;
`;

const Timestamp = Styled.H7`
  color: ${colors.gray.primary};
`;

const JobIdentifier = Styled.H7`
  color: ${colors.gray.primary};
`;

const Name = Styled.H7`
  color: ${colors.gray.primary};
`;

const Creator = Styled.H7`
  color: ${colors.gray.primary};
`;

const Description = Styled.H7`
  color: ${colors.gray.primary};
`;

const getCreator = ({jobEvent}: any) => {
  if (jobEvent.isAutomated) {
    return 'Supermove';
  } else if (jobEvent.creator) {
    return _.get(jobEvent, 'creator.firstName');
  } else {
    return '---';
  }
};

type OwnJobEventTableItemProps = {};

// @ts-expect-error TS(2456): Type alias 'JobEventTableItemProps' circularly ref... Remove this comment to see the full error message
type JobEventTableItemProps = OwnJobEventTableItemProps & typeof JobEventTableItem.defaultProps;

// @ts-expect-error TS(7022): 'JobEventTableItem' implicitly has type 'any' beca... Remove this comment to see the full error message
const JobEventTableItem = ({jobEvent}: JobEventTableItemProps) => {
  return (
    <Item>
      <Cell style={{width: 160}}>
        <Timestamp>
          {Datetime.convertToDisplayDatetime(jobEvent.timestamp, 'ddd, MMM D, h:mm A')}
        </Timestamp>
      </Cell>
      <CellSpace />
      <Cell style={{width: 150}}>
        <JobIdentifier>{jobEvent.job ? `Job ${jobEvent.job.identifier}` : ''}</JobIdentifier>
      </Cell>
      <CellSpace />
      <Cell style={{width: 240}}>
        <Name>{jobEvent.name}</Name>
      </Cell>
      <CellSpace />
      <Cell style={{width: 150}}>
        <Creator>{getCreator({jobEvent})}</Creator>
      </Cell>
      <CellSpace />
      <Cell style={{width: 400}}>
        <Description numberOfLines={6}>{jobEvent.description}</Description>
      </Cell>
    </Item>
  );
};

JobEventTableItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEventTableItem.fragment = gql`
  fragment JobEventTableItem on JobEvent {
    id
    timestamp
    name
    description
    isAutomated
    creator {
      id
      firstName
    }
    job {
      id
      identifier
    }
  }
`;

export default JobEventTableItem;
