// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepForm from '@shared/modules/Proposal/forms/ConfirmationStepForm';
import useAddConfirmationStepMutation from '@shared/modules/Proposal/hooks/useAddConfirmationStepMutation';
import ConfirmationStepModalFields from 'modules/Organization/Settings/ProjectTypes/components/ConfirmationStepModalFields';

const CreateConfirmationStepModal = ({
  isOpen,
  handleClose,
  projectType,
  confirmationStepsId,
  refetch,
}: any) => {
  const hasPaymentProcessing = projectType.organization?.payengineMerchant?.isProcessingEnabled;
  const confirmationStepForm = ConfirmationStepForm.new({
    confirmationStepsId,
    kind: hasPaymentProcessing ? null : ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT,
    name: hasPaymentProcessing
      ? ''
      : ConfirmationStepKind.getLabel(ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT),
  });
  const {form, handleSubmit, submitting} = useAddConfirmationStepMutation({
    confirmationStepForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Create Confirmation Step'}</SmallModal.HeaderText>
      <Space height={16} />
      <ConfirmationStepModalFields projectType={projectType} form={form} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={false} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateConfirmationStepModal.fragment = gql`
  ${ConfirmationStepModalFields.fragment}

  fragment CreateConfirmationStepModal on ProjectType {
    id
    ...ConfirmationStepModalFields
    organization {
      id
      payengineMerchant {
        id
        isProcessingEnabled
      }
    }
  }
`;
export default CreateConfirmationStepModal;
