// Libraries
import React from 'react';

// App
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {MoveUser} from '@supermove/models';

// Components
import UpdateReportMoveMoveUsersMutation from './UpdateReportMoveMoveUsersMutation';

const getInitialMoveUserInputs = ({reportMove}: any) => {
  return reportMove.moveUsers.map((moveUser: any) => {
    return MoveUser.toForm(moveUser);
  });
};

type OwnUpdateReportMoveMoveUsersFormProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'UpdateReportMoveMoveUsersFormProps' ci... Remove this comment to see the full error message
type UpdateReportMoveMoveUsersFormProps = OwnUpdateReportMoveMoveUsersFormProps &
  typeof UpdateReportMoveMoveUsersForm.defaultProps;

// @ts-expect-error TS(7022): 'UpdateReportMoveMoveUsersForm' implicitly has typ... Remove this comment to see the full error message
const UpdateReportMoveMoveUsersForm = ({
  reportMove,
  onSuccess,
  onError,
  children,
}: UpdateReportMoveMoveUsersFormProps) => {
  return (
    <Form
      enableReinitialize
      initialValues={{
        reportMoveId: reportMove.id,
        moveUserInputs: getInitialMoveUserInputs({reportMove}),
      }}
      onSubmit={(values: any) => {}}
    >
      {({submitForm, setMutationErrors, ...form}: any) => (
        <UpdateReportMoveMoveUsersMutation
          reportMoveId={reportMove.id}
          moveUserInputs={form.values.moveUserInputs.map((moveUserInput: any) => {
            return MoveUser.toMutation(moveUserInput);
          })}
          onSuccess={onSuccess}
          setMutationErrors={setMutationErrors}
          submitForm={submitForm}
        >
          {({loading, handleSubmit}: any) =>
            children({
              ...form,
              loading,
              handleSubmit,
            })
          }
        </UpdateReportMoveMoveUsersMutation>
      )}
    </Form>
  );
};

UpdateReportMoveMoveUsersForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveMoveUsersForm.fragment = gql`
  fragment UpdateReportMoveMoveUsersForm on ReportMove {
    id
    uuid
    moveUsers {
      jobId
      moveId
      userId
      timePayableAdjustment
      tip
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
`;

export default UpdateReportMoveMoveUsersForm;
