// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, PhoneInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useQuery, useResponsive, useState} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import ClientSearchDropdownInput from 'modules/Client/components/ClientSearchDropdownInput';

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const HeaderText = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CheckboxText = Styled.Text`
  ${Typography.Body}
`;

const getInitialSearchQuery = ({form, field}: any) => {
  const contactPhoneNumber = _.get(form.values, `${field}.primaryContactForm.phoneNumber`);
  const contactEmail = _.get(form.values, `${field}.primaryContactForm.email`);
  const clientName = _.get(form.values, `${field}.name`);
  return contactPhoneNumber || contactEmail || clientName || '';
};

const getDerivedClientFromForm = ({form, field}: any) => {
  const clientForm = _.get(form.values, `${field}`);
  // Copies the shape of the data that we expect to see from the query
  return {
    id: '',
    name: clientForm.name,
    primaryContact: {
      fullName: clientForm.primaryContactForm.names.join(' '),
      firstNameV2: clientForm.primaryContactForm.names[0],
      lastNameV2: clientForm.primaryContactForm.names[1],
      phoneNumber: clientForm.primaryContactForm.phoneNumber,
      email: clientForm.primaryContactForm.email,
    },
  };
};

const getClientOptions = ({form, field, clients, getOptionLabel}: any) => {
  const newClient = getDerivedClientFromForm({form, field});
  const clientOptions = [...clients, newClient].map((client) => {
    return {
      client,
      label: getOptionLabel(client),
      value: client.id,
    };
  });
  return clientOptions;
};

const handleCheckDuplicatePhoneNumber = ({form, field, clients, isFocusedField}: any) => {
  const phoneNumber = _.get(form.values, `${field}.primaryContactForm.phoneNumber`);
  const isDuplicatePhoneNumber = _.get(form.values, `${field}.isDuplicatePhoneNumber`);
  const isMatchingPhoneNumberFound = !!_.find(clients, (client) => {
    return client.primaryContact.phoneNumber === phoneNumber;
  });
  // We block unnecessarily setting the form value in order to prevent rerenders.
  if (isMatchingPhoneNumberFound !== isDuplicatePhoneNumber) {
    // The duplicate phone number flag can be set to true even if the phone number
    // input is not focused. This handles the case for when new client information
    // is pre-loaded onto the create project page, eg. when converting a job request
    // into a project.
    if (isFocusedField || isMatchingPhoneNumberFound) {
      form.setFieldValue(`${field}.isDuplicatePhoneNumber`, isMatchingPhoneNumberFound);
    }
  }
};

const FieldSpace = () => {
  return <Space height={10} width={10} />;
};

const RowSpace = () => {
  const responsive = useResponsive();
  return <Space height={responsive.mobile ? 10 : 15} />;
};

const ResetClientButton = ({form, field, organizationId, resetClientLabel}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon source={Icon.Times} size={16} color={colors.blue.interactive} />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(
            field,
            ClientForm.toForm(ClientForm.new({organizationId, isOkayDuplicatePhoneNumber: true})),
          );
        }}
      >
        <ButtonText>{resetClientLabel || 'Reset client information'}</ButtonText>
      </Button>
    </Row>
  );
};

const CompanyNameToggle = ({form, field}: any) => {
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isShowingName ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isShowingName`, !isShowingName);
          form.setFieldValue(`${field}.name`, '');
        }}
      >
        <ButtonText>{isShowingName ? 'Remove company name' : 'Add company name'}</ButtonText>
      </Button>
    </Row>
  );
};

const ClientActionButton = ({
  form,
  field,
  organization,
  canSearchClients,
  hasSelectedClient,
  isShowingBillingClient,
}: any) => {
  return (
    <React.Fragment>
      {canSearchClients && hasSelectedClient ? (
        <React.Fragment>
          {isShowingBillingClient && <Space width={8} />}
          <ResetClientButton form={form} field={field} organizationId={organization.id} />
        </React.Fragment>
      ) : (
        <CompanyNameToggle form={form} field={field} />
      )}
    </React.Fragment>
  );
};

const CompanyNameRow = ({
  form,
  field,
  clients,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
  style,
  isRequired,
}: any) => {
  return (
    <ClientSearchDropdownInput
      index={0}
      options={getClientOptions({
        form,
        field,
        clients,
        getOptionLabel: (client: any) => client.name,
      })}
      form={form}
      field={field}
      name={'name'}
      label={'Company Name'}
      placeholder={'Enter company name'}
      loading={loading}
      disabled={disabled}
      required={!_.get(form.values, `${field}.name`)}
      canSearchClients={canSearchClients}
      searchQuery={searchQuery}
      handleChangeSearch={(clientName: any) => {
        handleChangeSearch(clientName);
        form.setFieldValue(`${field}.name`, clientName);
      }}
      style={style}
      isRequired={isRequired}
    />
  );
};

const ContactFirstNameField = ({
  form,
  field,
  clients,
  shouldShowSingleNameCustomerField,
  loading,
  disabled,
  contactFirstName,
  canSearchClients,
  searchQuery,
  handleChangeSearch,
  label,
  style,
  isRequired,
}: any) => {
  return (
    <ClientSearchDropdownInput
      index={0}
      options={getClientOptions({
        form,
        field,
        clients,
        getOptionLabel: (client: any) => client.primaryContact.firstNameV2,
      })}
      form={form}
      field={field}
      name={`primaryContactForm.names.0`}
      label={label || (shouldShowSingleNameCustomerField ? 'Name' : 'First Name')}
      placeholder={shouldShowSingleNameCustomerField ? 'Enter name' : 'Enter first name'}
      loading={loading}
      disabled={disabled}
      required={!contactFirstName}
      canSearchClients={canSearchClients}
      searchQuery={searchQuery}
      handleChangeSearch={(firstName: any) => {
        handleChangeSearch(firstName);
        form.setFieldValue(`${field}.primaryContactForm.names.0`, firstName);
      }}
      style={style}
      isRequired={isRequired}
    />
  );
};

const ContactLastNameField = ({
  form,
  field,
  clients,
  loading,
  disabled,
  isLastNameRequired,
  contactLastName,
  canSearchClients,
  searchQuery,
  handleChangeSearch,
  label,
  style,
}: any) => {
  return (
    <ClientSearchDropdownInput
      index={1}
      options={getClientOptions({
        form,
        field,
        clients,
        getOptionLabel: (client: any) => client.primaryContact.lastNameV2,
      })}
      form={form}
      field={field}
      name={`primaryContactForm.names.1`}
      loading={loading}
      disabled={disabled}
      required={isLastNameRequired && !contactLastName}
      label={label || 'Last Name'}
      placeholder={'Enter last name'}
      canSearchClients={canSearchClients}
      searchQuery={searchQuery}
      handleChangeSearch={(lastName: any) => {
        handleChangeSearch(lastName);
        form.setFieldValue(`${field}.primaryContactForm.names.1`, lastName);
      }}
      style={style}
    />
  );
};

const ContactNameLogic = ({form, field, organization, children}: any) => {
  const {shouldShowSingleNameCustomerField} = form.values;
  const contactField = `${field}.primaryContactForm`;
  const contactFirstName = _.get(form.values, `${contactField}.names.0`);
  const contactLastName = _.get(form.values, `${contactField}.names.1`);
  const isLastNameRequired = organization.features.isEnabledProjectCustomerLastNameRequired;
  return children({
    shouldShowSingleNameCustomerField,
    contactFirstName,
    contactLastName,
    isLastNameRequired,
  });
};

const ContactNameRow = ({
  index,
  form,
  field,
  clients,
  organization,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
  isRequired,
}: any) => {
  const responsive = useResponsive();
  return (
    <ContactNameLogic form={form} field={field} organization={organization}>
      {({
        shouldShowSingleNameCustomerField,
        contactFirstName,
        contactLastName,
        isLastNameRequired,
      }: any) => (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Row {...responsive} index={index} data-test-id='form-client-name-row'>
          <ContactFirstNameField
            form={form}
            field={field}
            clients={clients}
            shouldShowSingleNameCustomerField={shouldShowSingleNameCustomerField}
            loading={loading}
            disabled={disabled}
            contactFirstName={contactFirstName}
            canSearchClients={canSearchClients}
            searchQuery={searchQuery}
            handleChangeSearch={handleChangeSearch}
            style={{width: responsive.mobile ? '100%' : 250}}
            isRequired={isRequired}
          />
          {!shouldShowSingleNameCustomerField && (
            <React.Fragment>
              {/* @ts-expect-error TS(2559): Type '{ desktop: boolean; tablet: boolean; mobile:... Remove this comment to see the full error message */}
              <FieldSpace {...responsive} />
              <ContactLastNameField
                form={form}
                field={field}
                clients={clients}
                loading={loading}
                disabled={disabled}
                isLastNameRequired={isLastNameRequired}
                contactLastName={contactLastName}
                canSearchClients={canSearchClients}
                searchQuery={searchQuery}
                handleChangeSearch={handleChangeSearch}
                style={{width: responsive.mobile ? '100%' : 250}}
              />
            </React.Fragment>
          )}
        </Row>
      )}
    </ContactNameLogic>
  );
};

const ContactPhoneNumberField = ({
  form,
  field,
  clients,
  loading,
  disabled,
  isPhoneNumberRequired,
  hasPhoneNumber,
  canSearchClients,
  searchQuery,
  handleChangeSearch,
  isDuplicatePhoneNumber,
  style,
}: any) => {
  return (
    <ClientSearchDropdownInput
      options={getClientOptions({
        form,
        field,
        clients,
        getOptionLabel: (client: any) => Phone.display(client.primaryContact.phoneNumber),
      })}
      form={form}
      field={field}
      component={PhoneInput}
      name={`primaryContactForm.phoneNumber`}
      label={'Phone Number'}
      placeholder={'Enter phone number'}
      loading={loading}
      disabled={disabled}
      required={isPhoneNumberRequired && !hasPhoneNumber}
      canSearchClients={canSearchClients}
      searchQuery={searchQuery}
      handleChangeSearch={(phoneNumber: any) => {
        const numbersOnly = Phone.strip(phoneNumber);
        handleChangeSearch(numbersOnly);
        form.setFieldValue(`${field}.primaryContactForm.phoneNumber`, numbersOnly);
      }}
      handleValidation={(isFocusedField: any) =>
        handleCheckDuplicatePhoneNumber({form, field, clients, isFocusedField})
      }
      hasWarning={!disabled && hasPhoneNumber && isDuplicatePhoneNumber}
      warningMessage={'Another client is using this phone number'}
      style={style}
    />
  );
};

const ContactEmailField = ({
  form,
  field,
  clients,
  loading,
  disabled,
  canSearchClients,
  searchQuery,
  handleChangeSearch,
  style,
}: any) => {
  return (
    <ClientSearchDropdownInput
      index={1}
      options={getClientOptions({
        form,
        field,
        clients,
        getOptionLabel: (client: any) => client.primaryContact.email,
      })}
      form={form}
      field={field}
      name={`primaryContactForm.email`}
      loading={loading}
      disabled={disabled}
      label={'Email Address'}
      placeholder={'Enter email address'}
      canSearchClients={canSearchClients}
      searchQuery={searchQuery}
      handleChangeSearch={(email: any) => {
        const trimmedEmail = email.trim();
        handleChangeSearch(trimmedEmail);
        form.setFieldValue(`${field}.primaryContactForm.email`, trimmedEmail);
      }}
      style={style}
    />
  );
};

const ContactInfoLogic = ({form, field, organization, children}: any) => {
  const contactField = `${field}.primaryContactForm`;
  const isPhoneNumberRequired = organization.features.isEnabledProjectCustomerPhoneNumberRequired;
  const hasPhoneNumber = !!_.get(form.values, `${contactField}.phoneNumber`);
  const isDuplicatePhoneNumber = _.get(form.values, `${field}.isDuplicatePhoneNumber`);
  return children({isPhoneNumberRequired, hasPhoneNumber, isDuplicatePhoneNumber});
};

const ContactInfoRow = ({
  index,
  form,
  field,
  clients,
  organization,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
}: any) => {
  const responsive = useResponsive();
  return (
    <ContactInfoLogic form={form} field={field} organization={organization}>
      {({isPhoneNumberRequired, hasPhoneNumber, isDuplicatePhoneNumber}: any) => (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Row {...responsive} index={index}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Section index={0}>
              <ContactPhoneNumberField
                form={form}
                field={field}
                clients={clients}
                loading={loading}
                disabled={disabled}
                isPhoneNumberRequired={isPhoneNumberRequired}
                hasPhoneNumber={hasPhoneNumber}
                canSearchClients={canSearchClients}
                searchQuery={searchQuery}
                handleChangeSearch={handleChangeSearch}
                isDuplicatePhoneNumber={isDuplicatePhoneNumber}
                style={{width: responsive.mobile ? '100%' : 250}}
              />
            </Section>
            {/* @ts-expect-error TS(2559): Type '{ desktop: boolean; tablet: boolean; mobile:... Remove this comment to see the full error message */}
            <FieldSpace {...responsive} />
            <ContactEmailField
              form={form}
              field={field}
              clients={clients}
              loading={loading}
              disabled={disabled}
              canSearchClients={canSearchClients}
              searchQuery={searchQuery}
              handleChangeSearch={handleChangeSearch}
              style={{width: responsive.mobile ? '100%' : 250}}
            />
          </Row>
        </React.Fragment>
      )}
    </ContactInfoLogic>
  );
};

const ClientNotesInput = ({disabled, ...props}: any) => {
  if (disabled) {
    return <FieldInput.Memoized {...props} />;
  }
  return <FieldInput {...props} />;
};

const SameAsClientCheckbox = ({
  form,
  field,
  sameAsClient,
  parentField,
  organization,
  label,
  ...props
}: any) => {
  return (
    <Checkbox
      isChecked={sameAsClient}
      handleToggle={(checked) => {
        form.setFieldValue(`${parentField}.isSameBillingClient`, checked);
        if (!checked) {
          // Clear value
          form.setFieldValue(
            `${field}`,
            ClientForm.toForm(
              ClientForm.new({
                organizationId: organization.id,
                isOkayDuplicatePhoneNumber: true,
              }),
            ),
          );
        }
      }}
      {...props}
    >
      {props.childrenRight && <Space width={8} />}
      <CheckboxText>{label || 'Same as Client'}</CheckboxText>
      {props.childrenLeft && <Space width={8} />}
    </Checkbox>
  );
};

const ProjectClientFieldsContent = ({
  clients,
  organization,
  form,
  field,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  isSearchEnabled,
  isShowingBillingClient,
}: any) => {
  const responsive = useResponsive();
  return (
    <ProjectClientFieldsLogic
      organization={organization}
      form={form}
      field={field}
      disabled={disabled}
      isSearchEnabled={isSearchEnabled}
      isShowingBillingClient={isShowingBillingClient}
    >
      {({
        hasSelectedClient,
        hasClientNotes,
        isShowingName,
        canSearchClients,
        disableClientInputs,
        parentField,
        sameAsClient,
        isShowingClientActionButton,
        displayClientFields,
      }: any) => (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Row index={0} {...responsive}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <HeaderText vars={responsive}>
              {isShowingBillingClient ? 'Billing Information' : 'Client Information'}
            </HeaderText>
            {responsive.mobile ? <FieldSpace /> : <Space style={{flex: 1}} />}
            {isShowingClientActionButton && (
              <ClientActionButton
                form={form}
                field={field}
                organization={organization}
                canSearchClients={canSearchClients}
                hasSelectedClient={hasSelectedClient}
                isShowingBillingClient={isShowingBillingClient}
              />
            )}
          </Row>
          {isShowingBillingClient && (
            <React.Fragment>
              <RowSpace />
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Row index={1} {...responsive}>
                <SameAsClientCheckbox
                  form={form}
                  field={field}
                  sameAsClient={sameAsClient}
                  parentField={parentField}
                  organization={organization}
                  childrenLeft
                />
              </Row>
            </React.Fragment>
          )}
          {displayClientFields && (
            <React.Fragment>
              {isShowingName && (
                <React.Fragment>
                  <RowSpace />
                  {/* @ts-expect-error TS(2769): No overload matches this call. */}
                  <Row {...responsive} index={0}>
                    <CompanyNameRow
                      form={form}
                      field={field}
                      clients={clients}
                      loading={loading}
                      disabled={disableClientInputs}
                      canSearchClients={canSearchClients}
                      searchQuery={searchQuery}
                      handleChangeSearch={handleChangeSearch}
                      style={{width: responsive.mobile ? '100%' : 510}}
                    />
                  </Row>
                </React.Fragment>
              )}
              <RowSpace />
              <ContactNameRow
                index={1}
                form={form}
                field={field}
                clients={clients}
                organization={organization}
                loading={loading}
                disabled={disableClientInputs}
                canSearchClients={canSearchClients}
                searchQuery={searchQuery}
                handleChangeSearch={handleChangeSearch}
              />
              <RowSpace />
              <ContactInfoRow
                index={2}
                form={form}
                field={field}
                clients={clients}
                organization={organization}
                loading={loading}
                disabled={disableClientInputs}
                canSearchClients={canSearchClients}
                searchQuery={searchQuery}
                handleChangeSearch={handleChangeSearch}
              />
            </React.Fragment>
          )}
          {(!disableClientInputs || hasClientNotes) && displayClientFields && (
            <React.Fragment>
              <RowSpace />
              <ClientNotesInput
                {...form}
                index={3}
                name={`${field}.notes`}
                label={'Client Notes (Internal Only)'}
                disabled={disableClientInputs}
                input={{
                  disabled: disableClientInputs,
                  multiline: true,
                  placeholder: 'Enter any additional notes about your client.',
                  style: {
                    paddingVertical: 9,
                    width: responsive.mobile ? '100%' : 510,
                    minHeight: 100,
                  },
                }}
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </ProjectClientFieldsLogic>
  );
};

const ProjectClientFieldsLogic = ({
  organization,
  form,
  field,
  disabled,
  isSearchEnabled,
  isShowingBillingClient,
  children,
}: any) => {
  const hasSelectedClient = !!_.get(form.values, `${field}.clientId`);
  const hasClientNotes = !!_.get(form.values, `${field}.notes`);
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  const canSearchClients =
    isSearchEnabled && organization.features.isEnabledClientSearchOnCreateProject;
  const disableClientInputs = disabled || (canSearchClients && hasSelectedClient);
  const parentField = field.split('.')[0];
  const sameAsClient = _.get(form.values, `${parentField}.isSameBillingClient`);
  const isShowingClientActionButton =
    !_.get(form.values, `${parentField}.isSameBillingClient`) || !isShowingBillingClient;
  const displayClientFields = !isShowingBillingClient || !sameAsClient;
  return children({
    hasSelectedClient,
    hasClientNotes,
    isShowingName,
    canSearchClients,
    disableClientInputs,
    parentField,
    sameAsClient,
    isShowingClientActionButton,
    displayClientFields,
  });
};

const ProjectClientFieldsQuery = ({form, field, children}: any) => {
  const [searchQuery, setSearchQuery] = useState(getInitialSearchQuery({form, field}));
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  const {data, loading} = useQuery(ProjectClientFields.query, {
    fetchPolicy: 'cache-and-network',
    skip: !searchQuery,
    variables: {
      searchQuery,
      slugs: ['ALL_ORGANIZATIONS'],
      pagination: {page: 1, resultsPerPage: 50},
    },
  });

  const clients = _.get(
    data,
    'viewer.organizationForCreateProject.filteredClientsPaginatedList.results',
    [],
  );

  return children({searchQuery, handleChangeSearch, loading, clients});
};

const ProjectClientFields = ({
  organization,
  disabled,
  index,
  field,
  form,
  isSearchEnabled,
  isShowingBillingClient,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      <ProjectClientFieldsQuery form={form} field={field}>
        {({searchQuery, handleChangeSearch, loading, clients}: any) => (
          <ProjectClientFieldsContent
            clients={clients}
            organization={organization}
            loading={loading}
            disabled={disabled}
            field={field}
            form={form}
            searchQuery={searchQuery}
            handleChangeSearch={handleChangeSearch}
            isSearchEnabled={isSearchEnabled}
            isShowingBillingClient={isShowingBillingClient}
          />
        )}
      </ProjectClientFieldsQuery>
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientFields.query = gql`
  ${ClientForm.edit.fragment}

  query ProjectClientFields($pagination: PaginationInput!, $searchQuery: String,  $slugs: [String],) {
    ${gql.query}
    viewer {
      id
      organizationForCreateProject {
        id
        filteredClientsPaginatedList(
          pagination: $pagination,
          searchQuery: $searchQuery,
          slugs: $slugs,
        ) {
          results {
            id
            name
            primaryContact {
              id
              names
              fullName
              firstNameV2
              lastNameV2
              phoneNumber
              email
            }
            ...ClientForm_edit
          }
        }
      }
    }
  }
`;

ProjectClientFields.fragment = gql`
  fragment ProjectClientFields on Organization {
    id
    features {
      isEnabledClientSearchOnCreateProject: isEnabled(feature: "CLIENT_SEARCH_ON_CREATE_PROJECT")
      isEnabledProjectCustomerLastNameRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_LAST_NAME_REQUIRED"
      )
      isEnabledProjectCustomerPhoneNumberRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_PHONE_NUMBER_REQUIRED"
      )
    }
  }
`;

ProjectClientFields.Query = ProjectClientFieldsQuery;
ProjectClientFields.Logic = ProjectClientFieldsLogic;
ProjectClientFields.ResetClientButton = ResetClientButton;
ProjectClientFields.CompanyNameRow = CompanyNameRow;
ProjectClientFields.SameAsClientCheckbox = SameAsClientCheckbox;
ProjectClientFields.ContactNameLogic = ContactNameLogic;
ProjectClientFields.ContactFirstNameField = ContactFirstNameField;
ProjectClientFields.ContactLastNameField = ContactLastNameField;
ProjectClientFields.ContactInfoLogic = ContactInfoLogic;
ProjectClientFields.ContactPhoneNumberField = ContactPhoneNumberField;
ProjectClientFields.ContactEmailField = ContactEmailField;
ProjectClientFields.ClientNotesInput = ClientNotesInput;

export default ProjectClientFields;
