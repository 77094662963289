// Libraries
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import {SplitPage} from 'modules/App/components';
import CalendarDayDetails from 'modules/Calendar/components/CalendarDayDetails';
import CalendarDayDetailsV1 from 'modules/Calendar/components/CalendarDayDetailsV1';
import {JobRequestActionsDropdown, JobRequestFieldValues} from 'modules/JobRequest/Show/components';
import {JobRequestPage} from 'modules/JobRequest/components';

const Container = Styled.View`
  flex: 1;
`;

const Panel = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Title = Styled.H3`
  margin-bottom: 20px;
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const Content = Styled.View`
  margin-vertical: 30px;
  margin-horizontal: 16px;
`;

const Actions = Styled.View`
  padding: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

// If the request already has a move (it was converted before),
// redirect the manager to the show move page.
const Redirect = ({children, jobRequest, navigator}: any) => (
  <Lifecycle
    onMount={() => {
      if (jobRequest.job) {
        navigator.replace(`/jobs/${jobRequest.job.uuid}`);
      }
    }}
  >
    {!jobRequest.job ? children : null}
  </Lifecycle>
);

const ShowJobRequestPage = () => {
  const {params} = useNavigationDOM();
  const {uuid} = params;

  return (
    <JobRequestPage
      variables={{uuid}}
      query={ShowJobRequestPage.query}
      title={() => 'Move Request'}
      subtitle={() => 'View move request information'}
      side={({responsive}: any) => (responsive.mobile ? 70 : 120)}
      onClose={({navigator}: any) => navigator.goBack()}
      right={({loading, data}: any) =>
        !loading && data.jobRequest ? (
          <JobRequestActionsDropdown jobRequest={data.jobRequest} />
        ) : null
      }
    >
      {({navigator, data}: any) => (
        <React.Fragment>
          <Redirect navigator={navigator} jobRequest={data.jobRequest}>
            <SplitPage
              split={
                <Panel>
                  {data.jobRequest.organization.features.isEnabledMovesCalendarJobCardV2 ? (
                    <CalendarDayDetails date={data.jobRequest.preferredDate} />
                  ) : (
                    <CalendarDayDetailsV1 date={data.jobRequest.preferredDate} />
                  )}
                </Panel>
              }
            >
              <Container>
                <ScrollView style={{flex: 1}}>
                  <Content>
                    <Title>Review move request</Title>
                    <JobRequestFieldValues jobRequest={data.jobRequest} />
                  </Content>
                </ScrollView>
                {data.jobRequest.status !== 'CANCELLED' && (
                  <Actions>
                    <Button
                      onPress={() => {
                        navigator.push(`/jobs/requests/${data.jobRequest.uuid}/convert`);
                      }}
                    >
                      <Text>Create Job for Request</Text>
                    </Button>
                  </Actions>
                )}
              </Container>
            </SplitPage>
          </Redirect>
        </React.Fragment>
      )}
    </JobRequestPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowJobRequestPage.query = gql`
  ${JobRequestPage.fragment}
  ${JobRequestActionsDropdown.fragment}
  ${JobRequestFieldValues.fragment}

  query ShowJobRequestPage($uuid: String!) {
    ${gql.query}
    jobRequest(uuid: $uuid) {
      id
      uuid
      status
      job {
        id
        uuid
      }
      organization {
        id
        features {
          isEnabledMovesCalendarJobCardV2: isEnabled(feature: "MOVES_CALENDAR_JOB_CARD_V2")
        }
      }
      ...JobRequestPage
      ...JobRequestActionsDropdown
      ...JobRequestFieldValues
    }
  }
`;

export default ShowJobRequestPage;
