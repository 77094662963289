// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {useEffect} from '@supermove/hooks';

// App
import Page from 'modules/App/components/Page';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import useAppContext from 'modules/App/context/useAppContext';

const PageContainer = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
`;

type OwnProps = {
  alwaysUpdate?: boolean;
  skip?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SidebarPage.defaultProps;

// @ts-expect-error TS(7022): 'SidebarPage' implicitly has type 'any' because it... Remove this comment to see the full error message
const SidebarPage = ({
  fetchPolicy,
  pollInterval,
  selected,
  variables,
  query,
  children,
  alwaysUpdate,
  skip,
}: Props) => {
  const {setGlobalNavigationTab} = useAppContext();

  useEffect(() => {
    setGlobalNavigationTab(selected);
  }, [selected, setGlobalNavigationTab]);

  return (
    <Page
      fetchPolicy={fetchPolicy}
      pollInterval={pollInterval}
      variables={variables}
      query={query}
      skip={skip}
    >
      {({responsive, loading, ...props}: any) =>
        responsive.mobile ? (
          <Container {...responsive}>
            <Loading alwaysUpdate={alwaysUpdate} loading={loading} as={PageLoadingIndicator}>
              {() => children({responsive, ...props})}
            </Loading>
          </Container>
        ) : (
          <PageContainer>
            <Container {...responsive}>
              <Loading
                alwaysUpdate={alwaysUpdate}
                loading={loading || skip}
                as={PageLoadingIndicator}
              >
                {() => children({responsive, ...props})}
              </Loading>
            </Container>
          </PageContainer>
        )
      }
    </Page>
  );
};

SidebarPage.defaultProps = {
  alwaysUpdate: false,
  skip: false,
};

export default SidebarPage;
