// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const RowSpace = () => {
  const responsive = useResponsive();
  return <Space height={responsive.mobile ? 8 : 16} />;
};

const DateFieldValue = ({job}: any) => {
  return (
    <FieldValue
      label={'Date'}
      value={Job.getDisplayDate(job, 'M/D/YY', '')}
      empty={'Not Scheduled'}
      style={{
        width: '100%',
      }}
    />
  );
};

const EstimateSection = ({index, job}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Title vars={responsive}>Survey Information</Title>
      <Space height={responsive.mobile ? 0 : 16} />
      <FieldValue label={'Project Size'} value={job.project.size} empty={'None'} />
      <RowSpace />
      <FieldValue
        label={'Weight'}
        value={pluralize('lb', job.project.weight, true)}
        empty={'None'}
      />
      <RowSpace />
      <DateFieldValue job={job} />
      <RowSpace />
      <FieldValue
        label={job.organization.jobStartTimeField}
        value={Job.getDisplayArrivalWindow(job)}
        empty={'None'}
      />
      <Space height={16} />
    </Section>
  );
};

const MoveSection = ({index, job}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Title vars={responsive}>Move Information</Title>
      <Space height={responsive.mobile ? 0 : 16} />
      <FieldValue label={'Project Size'} value={job.project.size} empty={'None'} />
      <RowSpace />
      <FieldValue
        label={'Weight'}
        value={pluralize('lb', job.project.weight, true)}
        empty={'None'}
      />
      <RowSpace />
      <DateFieldValue job={job} />
      <RowSpace />
      <FieldValue
        label={job.organization.jobStartTimeField}
        value={Job.getDisplayArrivalWindow(job)}
        empty={'None'}
      />
      <RowSpace />
      <FieldValue label={'Number of Movers'} value={Job.getCrewSizeText(job)} empty={'TBD'} />
      <Space height={16} />
    </Section>
  );
};

const CommercialMoveSection = ({index, job}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Title vars={responsive}>Move Information</Title>
      <Space height={responsive.mobile ? 0 : 16} />
      <DateFieldValue job={job} />
      <RowSpace />
      <FieldValue
        label={job.organization.jobStartTimeField}
        value={Job.getDisplayArrivalWindow(job)}
        empty={'None'}
      />
      <RowSpace />
      <FieldValue label={'Number of Movers'} value={Job.getCrewSizeText(job)} empty={'TBD'} />
      <RowSpace />
      <FieldValue
        label={'Number of Trucks'}
        value={`${Job.getTruckCountText(job)}`}
        empty={'TBD'}
      />
      <Space height={16} />
    </Section>
  );
};

const JobMoveBlock = ({sectionIndex, job}: any) => {
  switch (job.kind) {
    case Job.KIND.COMMERCIAL:
      return <CommercialMoveSection index={sectionIndex} job={job} />;
    case Job.KIND.ESTIMATE:
      return <EstimateSection index={sectionIndex} job={job} />;
    default:
      return <MoveSection index={sectionIndex} job={job} />;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobMoveBlock.fragment = gql`
  ${Job.getDisplayArrivalWindow.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.getCrewSizeText.fragment}
  ${Job.getTruckCountText.fragment}

  fragment JobMoveBlock on Job {
    id
    kind
    project {
      id
      size
      weight
    }
    organization {
      id
      jobStartTimeField
    }
    ...Job_getDisplayArrivalWindow
    ...Job_getDisplayDate
    ...Job_getCrewSizeText
    ...Job_getTruckCountText
  }
`;

export default JobMoveBlock;
