// Libraries
import React from 'react';

// Supermove
import {Form} from '@supermove/components';

// Components
import SupplyFormFields from './SupplyFormFields';
import UpdateSuppliesMutation from './UpdateSuppliesMutation';

type OwnProps = {
  organizationSuppliesForm: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateSupplyForm.defaultProps;

// @ts-expect-error TS(7022): 'CreateSupplyForm' implicitly has type 'any' becau... Remove this comment to see the full error message
const CreateSupplyForm = ({organizationSuppliesForm, onSuccess, onError}: Props) => (
  <Form
    initialValues={{
      organizationSuppliesForm,
    }}
    onSubmit={(values: any) => {}}
  >
    {({submitForm, setMutationErrors, ...form}: any) => (
      <UpdateSuppliesMutation
        organizationSuppliesForm={form.values.organizationSuppliesForm}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}: any) => {
          // The supply we create is always the last row (we create an empty row in index.js)
          const rowIndex = organizationSuppliesForm.suppliesForm.supplyForms.length - 1;
          return (
            <SupplyFormFields
              field={`organizationSuppliesForm.suppliesForm.supplyForms.${rowIndex}`}
              form={form}
              loading={loading}
              handleSubmit={handleSubmit}
            />
          );
        }}
      </UpdateSuppliesMutation>
    )}
  </Form>
);

CreateSupplyForm.defaultProps = {
  onError: () => {},
};

export default CreateSupplyForm;
