// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  usePagination,
  useNavigationDOM,
  useRef,
  useEffect,
  useReducer,
} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import ShipmentKind from '@shared/modules/Storage/enums/ShipmentKind';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import StorageShipmentFilters from 'modules/Storage/components/StorageShipmentFilters';
import StorageShipmentsTable from 'modules/Storage/components/StorageShipmentsTable';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const BASE_ROUTE = '/storage/shipments';

// Setup kind variables to be sent to mutation based on param kind
const getKindFilters = (kind: any) => {
  if (kind === 'all') {
    return [ShipmentKind.INCOMING, ShipmentKind.OUTGOING];
  } else {
    return [ShipmentKind.getKindOption(kind.toUpperCase()).value];
  }
};

const getVariablesFromParams = (params: any) => {
  const {kind, ...rest} = params;
  return {
    kind: kind || ShipmentKind.getKindOption(ShipmentKind.INCOMING).key,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    ...rest,
  };
};

const storageShipmentVariableReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'updateKind':
      return {
        ...state,
        kind: action.payload,
        pagination: PaginationBar.DEFAULT_PAGINATION,
      };
    case 'updateSearchQuery':
      return {
        ...state,
        searchQuery: action.payload,
      };
    case 'updatePagination':
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      throw new Error();
  }
};

const StorageShipmentsPageContent = () => {
  const {params, navigator} = useNavigationDOM();
  const ref = useRef();

  const [urlVariables, dispatchURLVariables] = useReducer(storageShipmentVariableReducer, {
    ...getVariablesFromParams(params),
  });

  useEffect(() => {
    // If there are no pagination or kind, append them
    if (!params.pagination || !params.kind) {
      navigator.replace(URL.getUrlFromVariables(BASE_ROUTE, urlVariables));
    }

    if (!ref.current) {
      // Set the initial ref to avoid unnecessary URL updates
      ref.current = urlVariables;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, urlVariables)) {
      ref.current = urlVariables;
      const url = URL.getUrlFromVariables(BASE_ROUTE, urlVariables);
      navigator.replace(url);
    }
  }, [navigator, ref, params, urlVariables]);

  const {data, loading, refetch} = useQuery(StorageShipmentsPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...urlVariables,
      kinds: getKindFilters(urlVariables.kind),
      isStorage: true,
    },
  });

  const pagination = usePagination({
    currentPage: _.toNumber(urlVariables.pagination.page),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      dispatchURLVariables({
        type: 'updatePagination',
        payload: {
          page,
          resultsPerPage: urlVariables.pagination.resultsPerPage,
        },
      });
    },
  });

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  return (
    <Container>
      <Space height={16} />
      <StorageShipmentFilters
        shipmentCountsByKind={data.filteredShipmentCountsByKind}
        dispatchURLVariables={dispatchURLVariables}
      />
      <StorageShipmentsTable
        loading={loading}
        shipments={data.paginatedList.shipments}
        pagination={pagination}
        refetch={refetch}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageShipmentsPageContent.query = gql`
  ${usePagination.fragment}
  ${StorageShipmentFilters.fragment}
  ${StorageShipmentsTable.fragment}

  query StorageShipmentsPageContent(
    $kinds: [String]!
    $pagination: PaginationInput!
    $isStorage: Boolean!
    $searchQuery: String
  ) {
    filteredShipmentCountsByKind(isStorage: $isStorage, searchQuery: $searchQuery) {
      ...StorageShipmentFilters
    }
    paginatedList: filteredShipmentsPaginatedList(
      kinds: $kinds
      pagination: $pagination
      isStorage: $isStorage
      searchQuery: $searchQuery
    ) {
      shipments: results {
        id
        ...StorageShipmentsTable
      }
      paginationMetadata {
        ...usePagination
      }
    }
  }
`;

export default StorageShipmentsPageContent;
