// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: ${(props) => ((props as any).reversed ? 'row-reverse' : 'row')};
  align-items: center;
  padding-vertical: 3px;
  padding-horizontal: 3px;
  border-width: 1px;
  background-color: ${(props) => ((props as any).backgroundColor ? (props as any).backgroundColor : 'transparent')};
  border-color: ${(props) => (props as any).color};
  border-radius: 3px;
`;

const Circle = Styled.View`
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: ${(props) => (props as any).color};
`;

const Count = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.white};
`;

const Text = Styled.H8`
  ${fontWeight(500)}
  color: ${(props) => (props as any).color};
`;

type OwnProps = {
  color: string;
  count: number;
  text: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Pill.defaultProps;

// @ts-expect-error TS(7022): 'Pill' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Pill = ({icon, color, count, text, reversed, backgroundColor}: Props) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container color={color} reversed={reversed} backgroundColor={backgroundColor}>
      {icon}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Circle color={color}>
        <Count>{count}</Count>
      </Circle>
      <Space width={3} />
      <Text color={color}>{text}</Text>
      <Space width={3} />
    </Container>
  );
};

Pill.defaultProps = {};

export default Pill;
