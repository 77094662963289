// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App

// Components
import {SidebarPage} from 'modules/App/components';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import {
  CreateSupplyModal,
  DeleteSupplyModal,
  UpdateSupplyModal,
} from 'modules/Organization/Settings/Supplies/components';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

import OrganizationSuppliesForm from './forms/OrganizationSuppliesForm';
import SuppliesForm from './forms/SuppliesForm';
import SupplyForm from './forms/SupplyForm';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Buttons = Styled.View`
  margin-bottom: 20px;
`;

const Button = Styled.Button`
  align-self: flex-start;
  width: ${(props) => ((props as any).mobile ? '100%' : 'fit-content')};
  height: 40px;
  padding-horizontal: 10px;
  margin-top: ${(props) => ((props as any).mobile && !(props as any).isFirst ? '10px' : '0px')};
  margin-right: ${(props) => ((props as any).mobile ? '0px' : '10px')};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
`;

const SuppliesTable = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
`;

const Cell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const ActionsCell = Styled.View`
  width: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Name = Styled.H6`
`;

const Text = Styled.H6`
`;

const HeaderText = Styled.H6`
  ${fontWeight(700)}
`;

const EditButton = Styled.Button`
  padding-left: 14px;
  padding-right: 10px;
`;

const DeactivateButton = Styled.Button`
  padding-horizontal: 12px;
  background-color: ${colors.Pink600};
`;

const Header = ({responsive}: any) => (
  <Row {...responsive}>
    <Cell
      style={{
        width: 40,
      }}
    />
    <Cell>
      <HeaderText>Name</HeaderText>
    </Cell>
    <Cell>
      <HeaderText>Price</HeaderText>
    </Cell>
    <ActionsCell>
      <HeaderText>Actions</HeaderText>
    </ActionsCell>
  </Row>
);

const SupplyRow = ({index, refetch, responsive, supply, organizationId, supplies}: any) => {
  return (
    <Row {...responsive}>
      <Cell
        style={{
          width: 40,
        }}
      >
        <Name>{index + 1}</Name>
      </Cell>
      <Cell>
        <Name>{supply.name}</Name>
      </Cell>
      <Cell>
        <Text>{Currency.display(supply.value)}</Text>
      </Cell>
      <ActionsCell>
        <UpdateSupplyModal
          index={index}
          organizationSuppliesForm={OrganizationSuppliesForm.toForm({
            organizationId,
            suppliesForm: {
              supplyForms: supplies,
            },
          })}
          refetch={refetch}
          trigger={({handleOpen}: any) => (
            <EditButton onPress={handleOpen}>
              <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Edit} />
            </EditButton>
          )}
        />
        <DeleteSupplyModal
          organizationSuppliesForm={OrganizationSuppliesForm.toForm({
            organizationId,
            suppliesForm: {
              supplyForms: supplies,
            },
          })}
          index={index}
          refetch={refetch}
          trigger={({handleOpen}: any) => (
            <DeactivateButton onPress={handleOpen}>
              <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Trash} />
            </DeactivateButton>
          )}
        />
      </ActionsCell>
    </Row>
  );
};

const OrganizationSettingsSuppliesPage = () => (
  <SidebarPage selected={'settings'} query={OrganizationSettingsSuppliesPage.query}>
    {({responsive, data, refetch}: any) => {
      const supplies = Organization.getSupplies(data.viewer.viewingOrganization) || [];

      return (
        <Container>
          <StandardOfficeHeader title={'Settings'} />
          <ScrollView style={{flex: 1}}>
            <SettingsContent {...responsive}>
              <Title>Supplies Settings</Title>
              <Buttons>
                <CreateSupplyModal
                  organizationSuppliesForm={{
                    organizationId: data.viewer.viewingOrganization.id,
                    suppliesForm: SuppliesForm.toForm({
                      supplyForms: [
                        ...(supplies as any).map((supply: any) => SupplyForm.edit(supply)),
                        SupplyForm.new(),
                      ],
                    }),
                  }}
                  refetch={refetch}
                  trigger={({handleOpen}: any) => (
                    <Button onPress={handleOpen} isFirst {...responsive}>
                      <ButtonText>Create New Supply</ButtonText>
                    </Button>
                  )}
                />
              </Buttons>
              <ScrollView horizontal>
                <SuppliesTable>
                  <Header responsive={responsive} />
                  {(supplies as any).map((supply: any, index: any) => (
                    <SupplyRow
                      key={supply.name}
                      index={index}
                      responsive={responsive}
                      organizationId={data.viewer.viewingOrganization.id}
                      supplies={supplies}
                      supply={supply}
                      refetch={refetch}
                    />
                  ))}
                </SuppliesTable>
              </ScrollView>
            </SettingsContent>
          </ScrollView>
        </Container>
      );
    }}
  </SidebarPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsSuppliesPage.query = gql`
  ${Organization.getSupplies.fragment}
  query OrganizationSettingsSuppliesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...Organization_getSupplies
      }
    }
  }
`;

export default OrganizationSettingsSuppliesPage;
