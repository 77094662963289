/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const SecondaryButton = Styled.SecondaryButton`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

type OwnProps = {
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateJobButton.defaultProps;

// @ts-expect-error TS(7022): 'CreateJobButton' implicitly has type 'any' becaus... Remove this comment to see the full error message
const CreateJobButton = ({style}: Props) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <SecondaryButton
      data-test-id='create-job-button'
      onPress={() => navigator.push('/projects/new')}
    >
      <Icon color={colors.gray.primary} size={Icon.Sizes.Medium} source={Icon.Plus} />
      {!responsive.mobile && <ButtonText style={{marginLeft: 5}}>New Project</ButtonText>}
    </SecondaryButton>
  );
};

CreateJobButton.defaultProps = {
  style: {},
};

export default CreateJobButton;
