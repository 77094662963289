// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import DocumentPdfView from 'modules/Document/components/DocumentPdfView';

const InventoryConfirmationDocument = ({confirmation, shouldPromptSignature}: any) => {
  return (
    <DocumentPdfView
      shouldPromptSignature={shouldPromptSignature}
      document={confirmation.inventoryWithSignature}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryConfirmationDocument.fragment = gql`
  ${DocumentPdfView.fragment}

  fragment InventoryConfirmationDocument on Confirmation {
    id
    inventoryWithSignature {
      id
      ...DocumentPdfView
    }
  }
`;

export default InventoryConfirmationDocument;
