// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import {FieldValue} from 'modules/App/components';

import UpdateJobRequestInternalNotesModal from './UpdateJobRequestInternalNotesModal';

const Container = Styled.View`
  flex-direction: row;
`;

const Button = Styled.Touchable`
  align-self: flex-start;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(700)}
`;

const JobRequestInternalNotes = ({jobRequest}: any) => {
  return (
    <Container>
      <FieldValue
        label={'Internal notes about this request'}
        value={jobRequest.internalNotes}
        empty={'None'}
        style={{flex: 1}}
      />
      <UpdateJobRequestInternalNotesModal
        jobRequest={jobRequest}
        trigger={({handleOpen}: any) => (
          <Button onPress={handleOpen}>
            <ButtonText>Edit</ButtonText>
          </Button>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestInternalNotes.fragment = gql`
  fragment JobRequestInternalNotes on JobRequest {
    id
    internalNotes
  }
`;

export default JobRequestInternalNotes;
