// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import QuickbooksLogo from 'modules/Organization/Settings/Company/assets/QuickbooksLogo';

const Image = Styled.Image`
  width: 128px;
  height: 38px;
`;

const TextContainer = Styled.View`
  width: 452px;
`;

const ButtonContainer = Styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

const getQuickBooksURL = ({slug}: any) => {
  const clientId = 'ABAVjG5PLSVrNKkILXmJTm1xWH31BaIjGROcKKqYxTCRUGuwES';
  const responseType = 'code';
  const scope = 'com.intuit.quickbooks.accounting';
  const redirectUri = `https%3A%2F%2Fapi.supermove.co%2Fqb%2Fv1%2Foauth&state=${slug}`;

  return (
    `https://appcenter.intuit.com/connect/oauth2?` +
    `client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${redirectUri}`
  );
};

const handleNavigateToQuickbooks = (organization: any) => {
  const url = getQuickBooksURL({slug: organization.slug});
  window.location.href = url;
};

const ConnectToQuickbooks = ({organization}: any) => {
  return (
    <React.Fragment>
      <TextContainer>
        <Panel.LabelText>Connect to QuickBooks</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          Simplify your accounting by connecting your QuickBooks account with Supermove.
        </Panel.Text>
      </TextContainer>
      <ButtonContainer>
        <Button
          onPress={() => handleNavigateToQuickbooks(organization)}
          text={'Connect to QuickBooks'}
        />
      </ButtonContainer>
    </React.Fragment>
  );
};

const ExistingQuickbooksAccount = ({organization}: any) => {
  return (
    <React.Fragment>
      <TextContainer>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <TertiaryButton onPress={() => handleNavigateToQuickbooks(organization)}>
          <Panel.Text style={{color: colors.blue.interactive}}>
            {organization.latestQuickbooksCredentialId}
          </Panel.Text>
        </TertiaryButton>
      </TextContainer>
      <ButtonContainer>
        <DropdownButton
          text={'Update Account'}
          actions={[
            {
              text: 'Change Account',
              onPress: () => handleNavigateToQuickbooks(organization),
            },
          ]}
        />
      </ButtonContainer>
    </React.Fragment>
  );
};

const QuickbooksPanel = ({organization}: any) => {
  return (
    <React.Fragment>
      <Panel width={Panel.WIDTH.DEFAULT}>
        <Panel.Header>
          <Panel.HeaderText>QuickBooks Online (Legacy)</Panel.HeaderText>
        </Panel.Header>
        <Panel.Body>
          <Image resizeMode={'contain'} source={{uri: QuickbooksLogo}} />
          <Space height={16} />
          {organization.hasQuickbooksCredential ? (
            <ExistingQuickbooksAccount organization={organization} />
          ) : (
            <ConnectToQuickbooks organization={organization} />
          )}
        </Panel.Body>
      </Panel>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
QuickbooksPanel.fragment = gql`
  fragment QuickbooksPanel on Organization {
    id
    slug
    hasQuickbooksCredential
    latestQuickbooksCredentialId
  }
`;

export default QuickbooksPanel;
