// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import UpdateJobUserMutation from './UpdateJobUserMutation';

const Container = Styled.View`
`;

const Banner = Styled.View`
  align-items: center;
  padding-vertical: 10px;
  background-color: ${(props) => (props as any).backgroundColor};
`;

const BannerText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Buttons = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const SpaceBetween = Styled.View`
  width: 10px;
`;

const AcceptButton = Styled.LoadingButton`
  flex: 1;
  align-content: center;
  height: 40px;
  padding-horizontal: 10px;
`;

const DeclineButton = Styled.LoadingButton`
  flex: 1;
  align-content: center;
  height: 40px;
  padding-horizontal: 10px;
  background-color: ${(props) => ((props as any).disabled ? colors.gray.secondary : colors.Pink600)};
`;

const ButtonText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
  text-align: center;
  letter-spacing: 1;
  text-transform: uppercase;
`;

const getBannerColor = ({job, viewer}: any) => {
  if (!isViewerAvailable({job, viewer})) {
    return colors.Pink600;
  }

  switch (getViewerStatus({job, viewer})) {
    case 'CONFIRMED':
      return colors.green.status;
    case 'REMOVED':
      return colors.Pink600;
    default:
      return colors.red.warning;
  }
};

const getBannerText = ({job, viewer}: any) => {
  if (!isViewerAvailable({job, viewer})) {
    return `You're not available for this move`;
  }

  switch (getViewerStatus({job, viewer})) {
    case 'CONFIRMED':
      return `You've accepted this move`;
    case 'REMOVED':
      return `You've declined this move`;
    default:
      return 'Please select an option';
  }
};

const getViewerStatus = ({job, viewer}: any) => {
  const jobUser = job.jobUsers.find((jobUser: any) => String(jobUser.userId) === String(viewer.id));
  return jobUser.status;
};

const isViewerAvailable = ({job, viewer}: any) => {
  const {userDay} = viewer;
  if (!userDay) {
    return false;
  } else {
    return userDay.scheduleStatus === 'AVAILABLE';
  }
};

const ButtonGroup = ({job, viewer}: any) => {
  return (
    <Buttons>
      <UpdateJobUserMutation jobId={job.id} userId={viewer.id} status={'CONFIRMED'}>
        {({loading, handleSubmit}: any) => (
          <AcceptButton
            disabled={getViewerStatus({job, viewer}) === 'CONFIRMED'}
            loading={loading}
            onPress={handleSubmit}
          >
            {!loading && (
              <ButtonText>
                {getViewerStatus({job, viewer}) === 'CONFIRMED' ? 'Accepted' : 'Accept'}
              </ButtonText>
            )}
          </AcceptButton>
        )}
      </UpdateJobUserMutation>
      {job.organization.features.isEnabledJobUserDecline && (
        <React.Fragment>
          <SpaceBetween />
          <UpdateJobUserMutation jobId={job.id} userId={viewer.id} status={'REMOVED'}>
            {({loading, handleSubmit}: any) => (
              <DeclineButton
                disabled={getViewerStatus({job, viewer}) === 'REMOVED'}
                loading={loading}
                onPress={handleSubmit}
              >
                {!loading && (
                  <ButtonText>
                    {getViewerStatus({job, viewer}) === 'REMOVED' ? 'Declined' : 'Decline'}
                  </ButtonText>
                )}
              </DeclineButton>
            )}
          </UpdateJobUserMutation>
        </React.Fragment>
      )}
    </Buttons>
  );
};

// Setting Date correctly if its a date range job
const getDateRangeDate = ({job}: any) => {
  const currentDate = Datetime.convertToDate(Datetime.today);

  // Use the start_date if current time is before the start date
  // To get the status, and the same if current time is after end time
  if (job.startDate >= currentDate) {
    return job.startDate;
  } else if (job.endDate <= currentDate) {
    return job.endDate;
  }
  return currentDate;
};

const CrewJobUserFooter = ({job}: any) => {
  return (
    <Query
      fetchPolicy={'cache-and-network'}
      variables={{
        date: _.get(job, 'day.value') || getDateRangeDate({job}),
      }}
      query={CrewJobUserFooter.query}
    >
      {({loading, data}: any) => (
        <Loading loading={loading}>
          {() => (
            <Container>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Banner backgroundColor={getBannerColor({job, viewer: data.viewer})}>
                <BannerText>{getBannerText({job, viewer: data.viewer})}</BannerText>
              </Banner>
              {isViewerAvailable({job, viewer: data.viewer}) && (
                <ButtonGroup job={job} viewer={data.viewer} />
              )}
            </Container>
          )}
        </Loading>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobUserFooter.fragment = gql`
  fragment CrewJobUserFooter on Job {
    id
    day {
      id
      value
    }
    startDate
    endDate
    organization {
      id
      features {
        isEnabledJobUserDecline: isEnabled(feature: "JOB_USER_DECLINE")
      }
    }
    jobUsers: activeJobUsers {
      id
      userId
      status
    }
  }
`;

CrewJobUserFooter.query = gql`
  query CrewJobUserFooter($date: String!) {
    ${gql.query}
    viewer {
      id
      userDay(date: $date) {
        id
        scheduleStatus
      }
    }
  }
`;

export default CrewJobUserFooter;
