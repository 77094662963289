// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import Modal from '@shared/design/components/Modal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CreateStorageProjectModalContent from 'modules/Storage/components/CreateStorageProjectModalContent';

const CreateStorageProjectModal = ({
  isOpen,
  handleClose,
  refetch,
  organization,
  isDisabledClientFields,
  createProjectSuccessModal,
  setNewUuid,
}: any) => {
  const {data, loading} = useQuery(CreateStorageProjectModal.query, {
    variables: {
      slug: organization.slug,
      skip: !isOpen,
    },
  });

  return (
    <Modal.Responsive isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <CreateStorageProjectModalContent
              isOpen={isOpen}
              handleClose={handleClose}
              refetch={refetch}
              organization={data.organization}
              isDisabledClientFields={isDisabledClientFields}
              createProjectSuccessModal={createProjectSuccessModal}
              setNewUuid={setNewUuid}
              isViewerOrgPrimaryMultibranch={data.viewer.viewingOrganization.isPrimaryMultibranch}
            />
          );
        }}
      </Loading>
    </Modal.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateStorageProjectModal.query = gql`
  ${CreateStorageProjectModalContent.fragment}

  query CreateStorageProjectModal($slug: String!) {
    ${gql.query}
    organization(slug: $slug) {
      id
      ...CreateStorageProjectModalContent
    }
    viewer {
      id
      viewingOrganization {
        id
        isPrimaryMultibranch
      }
    }
  }
`;

CreateStorageProjectModal.fragment = gql`
  fragment CreateStorageProjectModal on Organization {
    id
    slug
  }
`;

export default CreateStorageProjectModal;
