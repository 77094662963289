/**
 * Generic component that renders a PDF-able view of a document.
 */

/**
 * WARNING (warren)
 * This is a web component that renders HTML used to generate a PDF.
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import CustomerDocumentSignaturesList from './CustomerDocumentSignaturesList';
import DocumentHeaderOrganization from './DocumentHeaderOrganization';

const Container = Styled.View`
  width: 100%;
  background-color: ${colors.white};
`;

type OwnProps = {
  shouldPromptSignature?: boolean;
  document: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentPdfView.defaultProps;

// @ts-expect-error TS(7022): 'DocumentPdfView' implicitly has type 'any' becaus... Remove this comment to see the full error message
const DocumentPdfView = ({shouldPromptSignature, document}: Props) => {
  return (
    <Container>
      <DocumentHeaderOrganization
        title={document.displayName}
        organization={document.job.project.organization}
      />
      <CustomerDocumentSignaturesList
        shouldPromptSignature={shouldPromptSignature}
        document={document}
      />
    </Container>
  );
};

DocumentPdfView.defaultProps = {
  shouldPromptSignature: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentPdfView.fragment = gql`
  ${DocumentHeaderOrganization.fragment}
  ${CustomerDocumentSignaturesList.fragment}

  fragment DocumentPdfView on Document {
    id
    displayName
    job {
      id
      project {
        id
        organization {
          id
          ...DocumentHeaderOrganization
        }
      }
    }
    ...CustomerDocumentSignaturesList
  }
`;

export default DocumentPdfView;
