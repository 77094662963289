// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useScrollView, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import BillRuleKind from '@shared/modules/Billing/enums/BillRuleKind';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import EditBillBillItem from 'modules/Project/Billing/Bill/Edit/components/EditBillBillItem';
import EditBillBillRule from 'modules/Project/Billing/Bill/Edit/components/EditBillBillRule';
import EditBillSlideHeader from 'modules/Project/Billing/Bill/Edit/components/EditBillSlideHeader';
import DeleteConditionalBillItemModal from 'modules/Project/Billing/components/DeleteConditionalBillItemModal';
import DeleteConditionalBillRuleModal from 'modules/Project/Billing/components/DeleteConditionalBillRuleModal';

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border}
`;

const Container = Styled.View`
  flex: 1;
`;

const ListHeaderContainer = Styled.View`
  align-items: flex-end;
  padding-right: 12px;
`;

const ReorderButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 4px;
  padding-horizontal: 12px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isOn' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    isOn,
  }) => (isOn ? colors.blue.interactive : colors.gray.border)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isOn' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    isOn,
  }) => (isOn ? colors.blue.interactive : colors.white)};
`;

const ReorderText = Styled.Text`
  ${Typography.Label1}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const SwitchOuterOval = Styled.View`
  height: 16px;
  width: 24px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isOn' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    isOn,
  }) => (isOn ? colors.white : colors.gray.secondary)};
  border-radius: 20px;
  justify-content: center;
  align-items: ${({
    // @ts-expect-error TS(2339): Property 'isOn' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    isOn,
  }) => (isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
`;

const SwitchInnerCircle = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isOn' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    isOn,
  }) => (isOn ? colors.blue.interactive : colors.white)};
`;

const ListSectionTitle = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
  padding-left: 16px;
`;

const handleChangeOrder = ({form, field, fromIndex, toIndex, isIndexed}: any) => {
  const forms = _.get(form.values, field);
  const reorderedForms = List.move({list: forms, fromIndex, toIndex});
  if (isIndexed) {
    form.setFieldValue(
      field,
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      reorderedForms.map((form, updatedIndex) => ({...form, index: updatedIndex})),
    );
  } else {
    form.setFieldValue(field, reorderedForms);
  }
};

const Switch = ({isOn}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SwitchOuterOval isOn={isOn}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SwitchInnerCircle isOn={isOn} />
    </SwitchOuterOval>
  );
};

const ListHeader = ({isReordering, setIsReordering}: any) => {
  return (
    <ListHeaderContainer>
      <Space height={12} />
      <ReorderButton onPress={() => setIsReordering(!isReordering)} isOn={isReordering}>
        <Switch isOn={isReordering} />
        <Space width={8} />
        <ReorderText color={isReordering ? colors.white : colors.gray.secondary}>
          Reorder
        </ReorderText>
      </ReorderButton>
    </ListHeaderContainer>
  );
};

const BillItemRow = ({
  billItemForm,
  form,
  isBillItemPostSubtotalForm,
  isReordering,
  numberOfBillItemFormsPreSubtotal,
  numberOfBillItemFormsPostSubtotal,
  conditionalBillRuleForm,
}: any) => {
  const deleteConditionalBillItemModal = useModal({name: 'Delete Conditional Bill Item Modal'});
  const handleDelete = () =>
    BillForm.handleRemoveBillItemForm({
      form,
      billFormField: 'billForm',
      billItemFormsField: isBillItemPostSubtotalForm
        ? 'billItemFormsPostSubtotal'
        : 'billItemFormsPreSubtotal',
      billItemForm,
    });
  return (
    <React.Fragment>
      <EditBillBillItem
        billItemForm={billItemForm}
        form={form}
        index={billItemForm.index}
        handleRemoveBillItemForm={
          conditionalBillRuleForm ? deleteConditionalBillItemModal.handleOpen : handleDelete
        }
        handleChangeOrder={(toIndex: any) => {
          handleChangeOrder({
            form,
            field: isBillItemPostSubtotalForm
              ? 'billForm.billItemFormsPostSubtotal'
              : 'billForm.billItemFormsPreSubtotal',
            fromIndex: billItemForm.index,
            toIndex,
            isIndexed: true,
          });
        }}
        isReordering={isReordering}
        isLastItem={
          isBillItemPostSubtotalForm
            ? billItemForm.index === numberOfBillItemFormsPostSubtotal - 1
            : billItemForm.index === numberOfBillItemFormsPreSubtotal - 1
        }
      />
      <DeleteConditionalBillItemModal
        key={deleteConditionalBillItemModal.key}
        isOpen={deleteConditionalBillItemModal.isOpen}
        handleClose={deleteConditionalBillItemModal.handleClose}
        handleConfirm={handleDelete}
        billRuleName={billItemForm.name}
        billItemName={conditionalBillRuleForm?.name || ''}
      />
    </React.Fragment>
  );
};

const BillRuleRow = ({
  billRuleForm,
  form,
  index,
  isReordering,
  isLastItem,
  conditionalBillItemName,
}: any) => {
  const deleteConditionalBillRuleModal = useModal({name: 'Delete Conditional Bill Rule Modal'});
  const isConditionalBillRule = billRuleForm.kind === BillRuleKind.CONDITIONAL_BILL_ITEM;
  const handleDelete = () =>
    BillForm.handleRemoveBillRuleForm({
      form,
      field: 'billForm',
      indexToRemove: index,
      billRuleForm,
    });
  return (
    <React.Fragment>
      <EditBillBillRule
        billRuleForm={billRuleForm}
        form={form}
        index={index}
        handleRemoveBillRuleForm={
          isConditionalBillRule ? deleteConditionalBillRuleModal.handleOpen : handleDelete
        }
        handleChangeOrder={(toIndex: any) => {
          handleChangeOrder({
            form,
            field: 'billForm.billRuleForms',
            fromIndex: index,
            toIndex,
          });
        }}
        isReordering={isReordering}
        isLastItem={isLastItem}
      />
      <DeleteConditionalBillRuleModal
        key={deleteConditionalBillRuleModal.key}
        isOpen={deleteConditionalBillRuleModal.isOpen}
        handleClose={deleteConditionalBillRuleModal.handleClose}
        handleConfirm={handleDelete}
        billRuleName={billRuleForm.name}
        billItemName={conditionalBillItemName}
      />
    </React.Fragment>
  );
};

const EditBillSlide = ({
  form,
  type,
  showSearch,
  searchPopover,
  handleOpenSearch,
  handleCloseSearch,
  bill,
}: any) => {
  const {ref, handleScrollToEnd} = useScrollView();
  const [isReordering, setIsReordering] = useState(false);
  const numberOfBillItemFormsPreSubtotal = form.values.billForm.billItemFormsPreSubtotal.length;
  const numberOfBillItemFormsPostSubtotal = form.values.billForm.billItemFormsPostSubtotal.length;
  const numberOfBillRuleForms = form.values.billForm.billRuleForms.length;
  const billItemForms = [
    ...form.values.billForm.billItemFormsPreSubtotal,
    ...form.values.billForm.billItemFormsPostSubtotal,
  ];
  return (
    <Container>
      <EditBillSlideHeader
        billId={bill.id}
        form={form}
        type={type}
        showSearch={showSearch}
        handleOpenSearch={handleOpenSearch}
        handleCloseSearch={handleCloseSearch}
        searchPopover={searchPopover}
        handleScrollToEnd={handleScrollToEnd}
        isEnabledTbdBillItems={bill.organization.features.isEnabledTbdBillItems}
      />
      <Line />
      {type === 'ITEMS' && (
        <React.Fragment>
          <FlatList
            ref={ref}
            listKey={'ITEMS'}
            data={billItemForms}
            keyExtractor={(form: any, index: any) => `ITEMS-${index}`}
            renderItem={({item: billItemForm, index}: any) => {
              return (
                <React.Fragment>
                  <Space height={12} />
                  {index === 0 && numberOfBillItemFormsPreSubtotal > 0 && (
                    <React.Fragment>
                      <ListSectionTitle>Items before subtotal</ListSectionTitle>
                      <Space height={12} />
                    </React.Fragment>
                  )}
                  {index === numberOfBillItemFormsPreSubtotal && (
                    <React.Fragment>
                      <ListSectionTitle>Items after subtotal</ListSectionTitle>
                      <Space height={12} />
                    </React.Fragment>
                  )}
                  <BillItemRow
                    form={form}
                    billItemForm={billItemForm}
                    isBillItemPostSubtotalForm={index >= numberOfBillItemFormsPreSubtotal}
                    isReordering={isReordering}
                    numberOfBillItemFormsPreSubtotal={numberOfBillItemFormsPreSubtotal}
                    numberOfBillItemFormsPostSubtotal={numberOfBillItemFormsPostSubtotal}
                    conditionalBillRuleForm={
                      billItemForm.billItemId &&
                      _.find(
                        form.values.billForm.billRuleForms,
                        (billRuleForm) =>
                          _.toString(billItemForm.billItemId) ===
                          _.toString(billRuleForm.billItemId),
                      )
                    }
                  />
                </React.Fragment>
              );
            }}
            ListHeaderComponent={
              <ListHeader isReordering={isReordering} setIsReordering={setIsReordering} />
            }
            style={{paddingBottom: 16, backgroundColor: colors.gray.background}}
          />
        </React.Fragment>
      )}
      {type === 'RULES' && (
        <React.Fragment>
          <FlatList
            ref={ref}
            listKey={'RULES'}
            data={form.values.billForm.billRuleForms}
            keyExtractor={(form: any, index: any) => `RULES-${index}`}
            renderItem={({item: billRuleForm, index}: any) => {
              return (
                <BillRuleRow
                  billRuleForm={billRuleForm}
                  form={form}
                  index={index}
                  isLastItem={index === numberOfBillRuleForms - 1}
                  isReordering={isReordering}
                  conditionalBillItemName={
                    _.find(
                      billItemForms,
                      (billItemForm) =>
                        _.toString(billItemForm.billItemId) === _.toString(billRuleForm.billItemId),
                    )?.name || ''
                  }
                />
              );
            }}
            ListHeaderComponent={
              <ListHeader isReordering={isReordering} setIsReordering={setIsReordering} />
            }
            style={{paddingBottom: 16}}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillSlide.fragment = gql`
  fragment EditBillSlide on Bill {
    id
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

export default EditBillSlide;
