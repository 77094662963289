// Libraries
import React from 'react';

// Supermove
import {Styled, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import ApplyJobTypeConfigDrawer from 'modules/Organization/Settings/JobTypes/components/ApplyJobTypeConfigDrawer';

const IconButton = Styled.ButtonV2`
`;

const ConfigPopover = ({popover, applyJobTypeConfigDrawer}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.BottomEnd}
      width={150}
      key={popover.key}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          applyJobTypeConfigDrawer.handleOpen();
          popover.handleClose();
        }}
      >
        Copy Config
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

type OwnApplyJobTypeConfigPopoverButtonProps = {
  jobType: any;
  refetch?: (...args: any[]) => any;
  defaultJobTypeConfigs?: any[];
};

// @ts-expect-error TS(2456): Type alias 'ApplyJobTypeConfigPopoverButtonProps' ... Remove this comment to see the full error message
type ApplyJobTypeConfigPopoverButtonProps = OwnApplyJobTypeConfigPopoverButtonProps &
  typeof ApplyJobTypeConfigPopoverButton.defaultProps;

// @ts-expect-error TS(7022): 'ApplyJobTypeConfigPopoverButton' implicitly has t... Remove this comment to see the full error message
const ApplyJobTypeConfigPopoverButton = ({
  jobType,
  refetch,
  defaultJobTypeConfigs,
}: ApplyJobTypeConfigPopoverButtonProps) => {
  const configPopover = usePopover({name: 'Config Popover'});
  const applyJobTypeConfigDrawer = useDrawer({name: 'Apply Job Type Config Drawer'});
  return (
    <React.Fragment>
      <Popover.Content innerRef={configPopover.ref}>
        <IconButton onPress={configPopover.handleToggle}>
          <Icon source={Icon.Cog} color={colors.gray.secondary} size={16} />
        </IconButton>
      </Popover.Content>
      <ConfigPopover popover={configPopover} applyJobTypeConfigDrawer={applyJobTypeConfigDrawer} />
      <ApplyJobTypeConfigDrawer
        key={applyJobTypeConfigDrawer.key}
        isOpen={applyJobTypeConfigDrawer.isOpen}
        handleClose={applyJobTypeConfigDrawer.handleClose}
        jobType={jobType}
        refetch={refetch}
        defaultJobTypeConfigs={defaultJobTypeConfigs}
      />
    </React.Fragment>
  );
};

ApplyJobTypeConfigPopoverButton.defaultProps = {
  refetch: () => {},
  defaultJobTypeConfigs: [],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ApplyJobTypeConfigPopoverButton.fragment = gql`
  ${ApplyJobTypeConfigDrawer.fragment}
  fragment ApplyJobTypeConfigPopoverButton on JobType {
    id
    ...ApplyJobTypeConfigDrawer
  }
`;

export default ApplyJobTypeConfigPopoverButton;
