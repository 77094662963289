// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import AssignContainersToProjectForm from '@shared/modules/Storage/forms/AssignContainersToProjectForm';
import useAssignContainersToProjectMutation from '@shared/modules/Storage/hooks/useAssignContainersToProjectMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import AssignContainersPage from 'modules/Storage/Containers/AssignContainersPage';

const Container = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  flex: 1;
`;

const BackButton = Styled.ButtonV2`
`;

const HeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1};
`;

const BodyContainer = Styled.View`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 24px;
`;

const BodyText = Styled.Text`
  ${Typography.Subheading}
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link}
`;

const StorageAssignContainersToProjectPageContent = ({project, navigator}: any) => {
  const assignContainersToProjectForm = AssignContainersToProjectForm.new(project.id);
  const {form, handleSubmit} = useAssignContainersToProjectMutation({
    assignContainersToProjectForm,
    onSuccess: () => navigator.goBack(),
    onError: (errors: any) => console.log({errors}),
  });

  const {containerIds} = form.values.assignContainersToProjectForm;

  const organization = project.assignedOrganization;

  const handleContainerSelect = (containerId: any) => {
    const newSelectedContainers = _.xor(containerIds, [containerId]);
    form.setFieldValue(`assignContainersToProjectForm.containerIds`, newSelectedContainers);
  };

  return (
    <Container>
      <HeaderContainer>
        <BackButton onPress={() => navigator.goBack()}>
          <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
        </BackButton>
        <Space width={16} />
        <HeaderText>Assign Container(s) to Project</HeaderText>
        <Space style={{flex: 1}} />
        <SelectedItemsText
          color={containerIds.length ? colors.blue.interactive : colors.gray.tertiary}
        >{`${_.size(containerIds)} ${pluralize(
          'containers',
          _.size(containerIds),
        )} selected`}</SelectedItemsText>
        <Space width={16} />
        <Button
          text={'Assign'}
          isDisabled={!containerIds.length}
          iconLeft={Icon.Check}
          onPress={handleSubmit}
        />
      </HeaderContainer>
      {_.isEmpty(organization.warehouses) ? (
        <BodyContainer>
          <BodyText>Please add a warehouse to the organization. </BodyText>
          <LinkButton onPress={() => navigator.push(`/settings/storage/warehouses`)}>
            <LinkButtonText>Go to warehouse settings</LinkButtonText>
          </LinkButton>
        </BodyContainer>
      ) : (
        <ContentContainer>
          <AssignContainersPage
            organization={organization}
            allowMultiselect
            selectedContainers={containerIds}
            onContainerSelect={handleContainerSelect}
          />
        </ContentContainer>
      )}
    </Container>
  );
};

const StorageAssignContainersToProjectPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {loading, data} = useQuery(StorageAssignContainersToProjectPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid: params.projectUuid},
  });

  return (
    <SidebarPageV2 selected={'storage'}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <StorageAssignContainersToProjectPageContent
              project={data.project}
              navigator={navigator}
            />
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageAssignContainersToProjectPage.query = gql`
  ${AssignContainersPage.fragment}

  query StorageAssignContainersToProjectPage($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      assignedOrganization {
        id
        warehouses {
          id
        }
        features {
          isEnabledStorageMultibranchAssignProject: isEnabled(feature: "STORAGE_MULTIBRANCH_ASSIGN_PROJECT")
        }
        ...AssignContainersPage
      }
    }
  }
`;

export default StorageAssignContainersToProjectPage;
