// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  CurrencyInput,
  DropdownInput,
  PercentInput,
  Sidebar,
  ScrollView,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';
import {colors, Typography, fontWeight} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostItemKind from '@shared/modules/Billing/enums/CostItemKind';
import CostItemUnit from '@shared/modules/Billing/enums/CostItemUnit';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '100%' : '500px')};
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 20px;
`;

const TitleText = Styled.Text`
  ${Typography.Label1}
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const FooterContainer = Styled.View`
`;

const FooterContent = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  padding-vertical: 8px;
  padding-horizontal: 24px;
  border-radius: 4px;
  background-color: ${colors.blue.interactive};
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const FormContainer = Styled.View`
  flex: 1;
`;

const FormSection = Styled.View`
  padding-horizontal: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const Separator = Styled.View`
  border-bottom-width: 4px;
  border-color: ${colors.gray.border};
`;

const FormHeaderText = Styled.Text`
  ${Typography.Label2}
`;

const TextInput = Styled.TextInput`
  ${Typography.Label2};
  ${fontWeight(300)}
`;

const Header = ({header}: any) => {
  return (
    <HeaderContainer>
      <Space height={16} />
      <TitleText>{header}</TitleText>
      <Space height={16} />
    </HeaderContainer>
  );
};

const Footer = ({handleSubmit, handleClose}: any) => {
  return (
    <FooterContainer>
      <Space height={16} />
      <FooterContent>
        <Button style={{backgroundColor: colors.white}} onPress={handleClose}>
          <ButtonText style={{color: colors.gray.secondary}}>Cancel</ButtonText>
        </Button>
        <Space width={8} />
        <Button onPress={handleSubmit}>
          <ButtonText>Save</ButtonText>
        </Button>
        <Space width={20} />
      </FooterContent>
      <Space height={16} />
    </FooterContainer>
  );
};

const getValidUnitsForKind = (form: any) => {
  const {kind} = form.values.costItemTypeForm;
  if (kind === CostItemKind.PER_WEIGHT) {
    return [CostItemUnit.CENTS_PER_POUND, CostItemUnit.CENTS_PER_ONE_HUNDRED_POUNDS];
  } else if (kind === CostItemKind.PER_MILE) {
    return [CostItemUnit.CENTS_PER_MILE];
  } else {
    return [CostItemUnit.CENTS, CostItemUnit.CENTS_PER_HOUR, CostItemUnit.PERCENT];
  }
};

const handleChangeKind = ({kind, form}: any) => {
  const {unit} = form.values.costItemTypeForm;

  // Clear billItemTypeId for all non PER_BILL_ITEMs
  if (kind !== CostItemKind.PER_BILL_ITEM) {
    form.setFieldValue('costItemTypeForm.billItemTypeId', null);
  }

  // Clear billTypeId for all non PER_BILL_TOTALs
  if (kind !== CostItemKind.PER_BILL_TOTAL) {
    form.setFieldValue('costItemTypeForm.billTypeId', null);
  }

  // When PER_BILL_ITEM is selected, clear the name
  if (kind === CostItemKind.PER_BILL_ITEM) {
    form.setFieldValue('costItemTypeForm.name', '');
  }

  // Update unit based on kind change
  if (kind === CostItemKind.PER_WEIGHT) {
    handleChangeUnit({unit: CostItemUnit.CENTS_PER_POUND, form});
    form.setFieldValue('costItemTypeForm.unit', CostItemUnit.CENTS_PER_POUND);
  } else if (kind === CostItemKind.PER_MILE) {
    handleChangeUnit({unit: CostItemUnit.CENTS_PER_MILE, form});
    form.setFieldValue('costItemTypeForm.unit', CostItemUnit.CENTS_PER_MILE);
  } else if (unit === CostItemUnit.CENTS_PER_MILE || unit === CostItemUnit.CENTS_PER_POUND) {
    handleChangeUnit({unit: CostItemUnit.CENTS, form});
    form.setFieldValue('costItemTypeForm.unit', CostItemUnit.CENTS);
  }
};

const handleChangeUnit = ({unit, form}: any) => {
  const previousUnit = form.values.costItemTypeForm.unit;
  if (unit === CostItemUnit.PERCENT || previousUnit === CostItemUnit.PERCENT) {
    clearCostItemTypeRates(form);
  }
};

const clearCostItemTypeRates = (form: any) => {
  _.forEach(form.values.costItemTypeForm.costItemTypeRateForms, (_rateForm, index) => {
    form.setFieldValue(`costItemTypeForm.costItemTypeRateForms.${index}.rate`, '');
  });
};

const CostItemTypeFormContent = ({form}: any) => {
  return (
    <React.Fragment>
      <FormSection style={{zIndex: 100}}>
        <Space height={20} />
        {_.get(form, 'values.costItemTypeForm.role') && (
          <React.Fragment>
            <FieldInput
              {...form}
              label={'Employee Role'}
              name={'costItemTypeForm.role'}
              component={DropdownInput}
              input={{
                options: _.map(UserRole.ROLES, (role) => ({
                  label: UserRole.getDisplayRole(role),
                  value: role,
                })),
                disabled: true,
                style: {flex: 1},
              }}
            />
            <Space height={20} />
          </React.Fragment>
        )}
        {form.values.costItemTypeForm.kind !== CostItemKind.PER_BILL_ITEM && (
          <React.Fragment>
            <FieldInput
              {...form}
              label={'Name'}
              name={'costItemTypeForm.name'}
              input={{
                placeholder: 'Enter a name',
                autoFocus: true,
              }}
            />
            <Space height={20} />
          </React.Fragment>
        )}
        <Row>
          <FieldInput
            {...form}
            label={'Type'}
            name={'costItemTypeForm.kind'}
            component={DropdownInput}
            style={{flex: 1}}
            input={{
              options: _.map(CostItemKind.VALUES, (kind) => ({
                label: CostItemKind.getDisplayValue(kind),
                value: kind,
              })),
              onChangeValue: (kind: any) => handleChangeKind({kind, form}),
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
          <Space width={10} />
          <FieldInput
            {...form}
            label={'Rate Unit'}
            name={'costItemTypeForm.unit'}
            component={DropdownInput}
            style={{flex: 1}}
            input={{
              options: _.map(getValidUnitsForKind(form), (unit) => ({
                label: CostItemUnit.getDisplayValue(unit),
                value: unit,
              })),
              onChangeValue: (unit: any) => handleChangeUnit({unit, form}),
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </Row>
      </FormSection>
      {form.values.costItemTypeForm.kind === CostItemKind.PER_BILL_ITEM && (
        <FormSection style={{zIndex: 50}}>
          <Space height={20} />
          <FieldInput
            {...form}
            label={'Bill Item'}
            name={'costItemTypeForm.billItemTypeId'}
            component={DropdownInput}
            style={{flex: 1}}
            input={{
              isSearchable: true,
              options: _.map(form.values.costItemTypeForm.billItemTypeOptions, (billItemType) => ({
                label: BillItemType.getDisplayName(billItemType),
                value: _.toNumber(billItemType.id),
              })),
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </FormSection>
      )}
      {form.values.costItemTypeForm.kind === CostItemKind.PER_BILL_TOTAL && (
        <FormSection style={{zIndex: 50}}>
          <Space height={20} />
          <FieldInput
            {...form}
            label={'Bill Template'}
            name={'costItemTypeForm.billTypeId'}
            component={DropdownInput}
            style={{flex: 1}}
            input={{
              isSearchable: true,
              options: _.map(form.values.costItemTypeForm.billTypeOptions, ({id, name}) => ({
                label: name,
                value: _.toNumber(id),
              })),
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </FormSection>
      )}
    </React.Fragment>
  );
};

const CostItemTypeRatesFormContent = ({form, moverPositions}: any) => {
  const isPercent = form.values.costItemTypeForm.unit === CostItemUnit.PERCENT;
  return (
    <FormSection>
      <Space height={10} />
      <FormHeaderText>Compensation Rate</FormHeaderText>
      <Space height={20} />
      {form.values.costItemTypeForm.costItemTypeRateForms.map(
        (costItemTypeRateForm: any, index: any) => {
          const {moverPositionId} = costItemTypeRateForm;
          return (
            <React.Fragment key={index}>
              <Row>
                <FieldInput
                  label={'Position'}
                  style={{flex: 1}}
                  input={{
                    disabled: true,
                    defaultValue: _.find(
                      moverPositions,
                      (moverPosition) => moverPosition.id === _.toString(moverPositionId),
                    ).name,
                  }}
                />
                <Space width={10} />
                <FieldInput
                  {...form}
                  label={'Rate'}
                  name={`costItemTypeForm.costItemTypeRateForms.${index}.rate`}
                  component={isPercent ? PercentInput : CurrencyInput}
                  style={{flex: 1}}
                  input={{
                    component: TextInput,
                    placeholder: 'Enter rate',
                    setFieldValue: form.setFieldValue,
                    setFieldTouched: form.setFieldTouched,
                  }}
                />
              </Row>
              <Space height={20} />
            </React.Fragment>
          );
        },
      )}
    </FormSection>
  );
};

const SingleCostItemTypeRateForm = ({form}: any) => {
  const isPercent = form.values.costItemTypeForm.unit === CostItemUnit.PERCENT;
  return (
    <FormSection>
      <Space height={20} />
      <FieldInput
        {...form}
        label={'Rate'}
        name={`costItemTypeForm.costItemTypeRateForms.0.rate`}
        component={isPercent ? PercentInput : CurrencyInput}
        style={{flex: 1}}
        input={{
          component: TextInput,
          placeholder: 'Enter rate',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        }}
      />
    </FormSection>
  );
};

const CostItemTypeSidebarContent = ({
  form,
  category,
  header,
  moverPositions,
  isOpen,
  handleClose,
  handleSubmit,
}: any) => {
  const responsive = useResponsive();
  return (
    <Sidebar.Content isOpen={isOpen} onClose={handleClose} position={'right'}>
      <Container responsive={responsive}>
        <Header header={header} />
        <Line />
        <ScrollView>
          <BodyContainer style={{justifyContent: 'center'}}>
            <FormContainer>
              <CostItemTypeFormContent form={form} />
              {category === CostCategoryKind.COMPENSATION ? (
                <React.Fragment>
                  <Space height={30} />
                  <Separator />
                  <Space height={20} />
                  <CostItemTypeRatesFormContent form={form} moverPositions={moverPositions} />
                </React.Fragment>
              ) : (
                <SingleCostItemTypeRateForm form={form} />
              )}
            </FormContainer>
          </BodyContainer>
        </ScrollView>
        <Line />
        <Footer handleClose={handleClose} handleSubmit={handleSubmit} />
      </Container>
    </Sidebar.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CostItemTypeSidebarContent.fragment = gql`
  ${BillItemType.getDisplayName.fragment}
  fragment CostItemTypeSidebarContent on BillItemType {
    id
    ...BillItemType_getDisplayName
  }
`;

export default CostItemTypeSidebarContent;
