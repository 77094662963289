// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import AttachmentsPanel from 'modules/Project/Attachments/components/AttachmentsPanel';

const Container = Styled.View`
  flex: 1;
  padding: 16px;
  background-color: ${colors.gray.background};
`;

const ProjectAttachmentsWidget = ({project}: any) => {
  return (
    <Container>
      <AttachmentsPanel project={project} containerStyle={{flex: 1}} isWidget />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAttachmentsWidget.fragment = gql`
  ${AttachmentsPanel.fragment}
  fragment ProjectAttachmentsWidget on Project {
    id
    ...AttachmentsPanel
  }
`;

export default ProjectAttachmentsWidget;
