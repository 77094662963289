// Libraries
import React from 'react';

// Components
import UnassignUserFromJobMutation from './UnassignUserFromJobMutation';

type OwnProps = {
  job: any;
  user: any;
  onSuccess: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UnassignUserFromJobButton.defaultProps;

// @ts-expect-error TS(7022): 'UnassignUserFromJobButton' implicitly has type 'a... Remove this comment to see the full error message
const UnassignUserFromJobButton = ({job, user, onSuccess, children}: Props) => (
  <UnassignUserFromJobMutation
    jobId={job.id}
    userId={user.id}
    refetchQueries={['EditJobUsersForm']}
    onSuccess={onSuccess}
  >
    {({loading, handleSubmit, ...props}: any) => children({loading, handleSubmit, ...props})}
  </UnassignUserFromJobMutation>
);

UnassignUserFromJobButton.defaultProps = {};

export default UnassignUserFromJobButton;
