// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Sheet from '@shared/design/components/Sheet';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const PopoverContainer = Styled.Touchable`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TabContainerButton = Styled.ButtonV2`
  flex: 1;
  padding-vertical: 8px;
  align-items: center;
  border-bottom-width: 2px;
  border-color: ${(props) => ((props as any).isSelected ? colors.blue.interactive : 'transparent')}
`;

const TabText = Styled.Text`
  ${Typography.Label2};
  color: ${colors.blue.interactive};
`;

const InformationText = Styled.Text`
  ${Typography.Body5};
  padding-top: 1px;
  color: ${colors.orange.status};
`;

const NotesContainer = Styled.View`
  max-height: 480px;
  padding-horizontal: 16px;
`;

const NotesSectionTitle = Styled.Text`
  ${Typography.Body5};
  color: ${colors.gray.primary};
`;

const NotesText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const SheetText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SheetPlaceholderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

// Notes tab options
// TODO(sam): Add support for dispatch notes when applicable
const SALES = 'SALES';
const TAB_OPTIONS = [{value: SALES, displayText: 'Sales Notes'}];

const NotesTabs = ({selectedTab, setSelectedTab}: any) => {
  return (
    <Row>
      {TAB_OPTIONS.map((option, index) => {
        return (
          <TabContainerButton
            key={index}
            onPress={() => setSelectedTab(option.value)}
            isSelected={selectedTab === option.value}
          >
            <TabText>{option.displayText}</TabText>
          </TabContainerButton>
        );
      })}
    </Row>
  );
};

const Information = ({children}: any) => {
  return (
    <Row>
      <Icon source={Icon.ExclamationCircle} color={colors.orange.status} size={12} />
      <Space width={4} />
      <InformationText>{children}</InformationText>
    </Row>
  );
};

const SalesNotesSection = ({title, notes}: any) => {
  return (
    <React.Fragment>
      <NotesSectionTitle>{title}</NotesSectionTitle>
      <Space height={4} />
      <NotesText>{notes || 'None'}</NotesText>
    </React.Fragment>
  );
};

const SalesNotes = ({project}: any) => {
  // TODO(sam): Add support for project level sales notes (including edit functionality)
  return (
    <NotesContainer>
      <ScrollView style={{maxHeight: 380}}>
        <Space height={10} />
        <Information>View only</Information>
        <Space height={10} />
        <SalesNotesSection title={'Project Description'} notes={project.description} />
      </ScrollView>
    </NotesContainer>
  );
};

const ProjectSalesNotesPopoverContent = ({project}: any) => {
  const [selectedTab, setSelectedTab] = useState(SALES);
  return (
    <ResponsivePopover.StaticContainer width={320} maxHeight={480}>
      <NotesTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === SALES && <SalesNotes project={project} />}
      <Space height={16} />
    </ResponsivePopover.StaticContainer>
  );
};

const ProjectSalesNotesPopover = ({popover, project, placement}: any) => {
  const responsive = useResponsive();

  if (responsive.desktop) {
    return (
      <PopoverContainer>
        <Popover
          placement={placement}
          isOpen={popover.isOpen}
          handleOpen={popover.handleOpen}
          handleClose={popover.handleClose}
          reference={popover.ref}
          offset={[0, 4]}
        >
          <ProjectSalesNotesPopoverContent project={project} />
        </Popover>
      </PopoverContainer>
    );
  }

  return (
    <Sheet isOpen={popover.isOpen} handleClose={popover.handleClose} headerText={'Sales Notes'}>
      <ScrollView style={{paddingHorizontal: 16}}>
        {project.description ? (
          <SheetText responsive={responsive}>{project.description}</SheetText>
        ) : (
          <SheetPlaceholderText responsive={responsive}>
            No sales notes available
          </SheetPlaceholderText>
        )}
        <Space height={32} />
      </ScrollView>
    </Sheet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSalesNotesPopover.fragment = gql`
  fragment ProjectSalesNotesPopover on Project {
    id
    description
  }
`;

export default ProjectSalesNotesPopover;
