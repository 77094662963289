// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import {FieldValue} from 'modules/App/components';

const Container = Styled.View`
  z-index: 1;
`;

const Row = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  align-items: flex-start;
`;

type OwnProps = {
  job: any;
  sectionIndex: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobInfoSection.defaultProps;

// @ts-expect-error TS(7022): 'JobInfoSection' implicitly has type 'any' because... Remove this comment to see the full error message
const JobInfoSection = ({job, sectionIndex}: Props) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container sectionIndex={sectionIndex}>
      <Section {...responsive}>
        <Title>Job Information</Title>
        <Space height={8} />
        <Row {...responsive}>
          <FieldValue
            label={'Job Description (shown to customer)'}
            value={job.description}
            empty={'None'}
            style={{flex: 1}}
          />
        </Row>
      </Section>
    </Container>
  );
};

JobInfoSection.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInfoSection.fragment = gql`
  fragment JobInfoSection on Job {
    id
    description
  }
`;

export default JobInfoSection;
