// Libraries
import React from 'react';

// Components
import {MapView} from '@supermove/components';

const getIcon = ({kind, props}: any) => {
  switch (kind) {
    case MapMarker.DOT_BLUE:
      return {
        anchor: new props.google.maps.Point(6, 6),
        origin: new props.google.maps.Point(0, 0),
        scaledSize: new props.google.maps.Size(12, 12),
        url: require('../assets/dot-blue.png'),
      };
    case MapMarker.DOT_RED:
      return {
        anchor: new props.google.maps.Point(6, 6),
        origin: new props.google.maps.Point(0, 0),
        scaledSize: new props.google.maps.Size(12, 12),
        url: require('../assets/dot-red.png'),
      };
    case MapMarker.TRUCK:
      return {
        anchor: new props.google.maps.Point(15, 30),
        origin: new props.google.maps.Point(0, 0),
        scaledSize: new props.google.maps.Size(30, 60),
        url: require('../assets/truck.png'),
      };
    case MapMarker.WAREHOUSE:
      return {
        anchor: new props.google.maps.Point(18, 32),
        origin: new props.google.maps.Point(0, 0),
        scaledSize: new props.google.maps.Size(36, 36),
        url: require('../assets/warehouse.svg'),
      };
    case MapMarker.DEFAULT:
    default:
      return null;
  }
};

const getName = ({kind, name}: any) => {
  if (name) {
    return name;
  }
  switch (kind) {
    case MapMarker.DOT_BLUE:
      return 'Position';
    case MapMarker.DOT_RED:
      return 'Position';
    case MapMarker.TRUCK:
      return 'Truck';
    case MapMarker.WAREHOUSE:
      return 'Warehouse';
    case MapMarker.DEFAULT:
    default:
      return 'Stop';
  }
};

/**
 * We override `MapView.Marker` because the existing implementation
 * contains an issue where custom icon markers flash on state changes
 * that should not impact them.
 */
class CustomMarker extends MapView.Marker {
  marker: any;

  props: any;

  renderMarker: any;

  componentDidUpdate(prevProps: any) {
    if (
      this.props.map !== prevProps.map ||
      (this.props.icon && this.props.icon.url !== prevProps.icon.url) ||
      this.props.position.lat !== prevProps.position.lat ||
      this.props.position.lng !== prevProps.position.lng
    ) {
      if (this.marker) {
        this.marker.setMap(null);
      }
      this.renderMarker();
    }
  }
}

type OwnMapMarkerProps = {
  kind: string;
  name?: string;
  label?: any;
  payload?: any;
  position: any;
  onClick?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'MapMarkerProps' circularly references ... Remove this comment to see the full error message
type MapMarkerProps = OwnMapMarkerProps & typeof MapMarker.defaultProps;

// @ts-expect-error TS(7022): 'MapMarker' implicitly has type 'any' because it d... Remove this comment to see the full error message
const MapMarker = ({kind, label, name, payload, position, onClick, ...props}: MapMarkerProps) => {
  return (
    // @ts-expect-error TS(2786): 'CustomMarker' cannot be used as a JSX component.
    <CustomMarker
      icon={getIcon({kind, props})}
      name={getName({kind, name})}
      label={label}
      payload={payload}
      position={position}
      onClick={onClick}
      {...props}
    />
  );
};

MapMarker.DEFAULT = 'DEFAULT';
MapMarker.DOT_BLUE = 'DOT_BLUE';
MapMarker.DOT_RED = 'DOT_RED';
MapMarker.TRUCK = 'TRUCK';
MapMarker.WAREHOUSE = 'WAREHOUSE';

const createMarkerLabel = (label: any) => {
  return {
    color: '#771F18', // Dark red color for marker label.
    fontFamily: 'Avenir',
    fontSize: '16px',
    fontWeight: '900',
    text: String(label),
  };
};

MapMarker.createMarkerLabel = createMarkerLabel;

MapMarker.defaultProps = {
  name: null,
  label: null,
  payload: null,
  onClick: null,
};

export default MapMarker;
