/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover} from '@supermove/hooks';
import {colors, fontWeight, Typography} from '@supermove/styles';

// App

import SlotForm from '@shared/modules/Dispatch/forms/SlotForm';
import SortDaySlotsForm from '@shared/modules/Dispatch/forms/SortDaySlotsForm';
import useCreateSlotMutation from '@shared/modules/Dispatch/hooks/useCreateSlotMutation';
import useSortDaySlotsMutation from '@shared/modules/Dispatch/hooks/useSortDaySlotsMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import useDayCalendarDispatchOrganizationsContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchOrganizationsContext';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';

const INDEX_CONTAINER_WIDTH = 40;

const Row = Styled.View`
  flex-direction: row;
`;

const OrganizationTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const ActionButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  height: 24px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  padding-horizontal: 8px;
`;

const CountContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  height: 24px;
  border-radius: 20px;
  background-color: ${colors.gray.border};
  padding-horizontal: 8px;
`;

const IconContainer = Styled.View`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  align-items: center;
  justify-content: center;
`;

const ButtonText = Styled.H8`
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  ${fontWeight(700)}
`;

const OpenButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const SortOptionRowButton = Styled.ButtonV2`
  flex-direction: row;
  height: 24px;
  align-items: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const SortOptionText = Styled.Text`
  ${Typography.Body4}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const ActivityIndicator = Styled.Loading`
`;

const SortOption = ({dayId, sortOption, handleClose, refetch, children}: any) => {
  const {ref, isHovered} = useHover();
  const sortDaySlotsForm = SortDaySlotsForm.new({dayId, sortOption});
  const {submitting, handleSubmit} = useSortDaySlotsMutation({
    sortDaySlotsForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.warn({errors});
    },
  });

  return (
    <SortOptionRowButton
      ref={ref}
      color={isHovered ? colors.hover : colors.white}
      onPress={handleSubmit}
    >
      <Space width={12} />
      <SortOptionText color={colors.gray.primary}>{children}</SortOptionText>
      <Space width={8} />
      {submitting && <ActivityIndicator color={colors.gray.secondary} />}
    </SortOptionRowButton>
  );
};

const SortOptionsPopoverContent = ({dayId, handleClose, refetch}: any) => {
  return (
    <ResponsivePopover.StaticContainer width={136}>
      <Space height={8} />
      <SortOption dayId={dayId} sortOption={'JOBS'} handleClose={handleClose} refetch={refetch}>
        Group By Job
      </SortOption>
      <SortOption dayId={dayId} sortOption={'DEFAULT'} handleClose={handleClose} refetch={refetch}>
        Reset To Default
      </SortOption>
      <Space height={8} />
    </ResponsivePopover.StaticContainer>
  );
};

const SortOptionsPopover = ({dayId, popover, refetch}: any) => {
  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <SortOptionsPopoverContent
        dayId={dayId}
        handleClose={popover.handleClose}
        refetch={refetch}
      />
    </ResponsivePopover>
  );
};

const OrganizationSlotsHeader = ({slotsSummary, refetch, index}: any) => {
  const slotForm = SlotForm.new({dayId: slotsSummary.dayId});
  const {submitting, handleSubmit} = useCreateSlotMutation({
    slotForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {dispatchAllCount, dispatchDoneCount, dispatchNotDoneCount} =
    slotsSummary.dispatchStatusHeader;
  const {organizations, toggleOrganizationIsOpen} = useDayCalendarDispatchOrganizationsContext();
  const {leftPanelWidth} = useDayCalendarDispatchViewContext();
  const {organizationId, isOpen} = _.get(organizations, `${index}`);
  const sortOptionsPopover = usePopover();

  return (
    <Row style={{alignItems: 'center', minWidth: leftPanelWidth - 32}}>
      <Space width={INDEX_CONTAINER_WIDTH} />
      <OrganizationTitle numberOfLines={1}>{slotsSummary.organization.name}</OrganizationTitle>
      <Space width={12} />
      <CountContainer>
        <ButtonText color={colors.gray.secondary}>All {dispatchAllCount}</ButtonText>
      </CountContainer>
      <Space width={4} />
      <CountContainer>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IconContainer color={colors.green.status}>
          <Icon source={Icon.Check} size={8} color={colors.white} />
        </IconContainer>
        <Space width={4} />
        <ButtonText color={colors.gray.secondary}>Dispatch Done {dispatchDoneCount}</ButtonText>
      </CountContainer>
      <Space width={4} />
      <CountContainer>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IconContainer color={colors.gray.tertiary}>
          <Icon source={Icon.Check} size={8} color={colors.white} />
        </IconContainer>
        <Space width={4} />
        <ButtonText color={colors.gray.secondary}>
          Dispatch Not Done {dispatchNotDoneCount}
        </ButtonText>
      </CountContainer>
      <Space width={8} />
      <ActionButton onPress={handleSubmit} disabled={submitting} color={colors.gray.tertiary}>
        <Icon source={Icon.Plus} size={10} color={colors.gray.tertiary} />
        <Space width={4} />
        <ButtonText color={colors.gray.tertiary}>Add Slot</ButtonText>
      </ActionButton>
      <Space width={8} />
      <Popover.Content innerRef={sortOptionsPopover.ref}>
        <ActionButton
          onPress={() => sortOptionsPopover.handleToggle()}
          color={sortOptionsPopover.isOpen ? colors.blue.interactive : colors.gray.tertiary}
        >
          <ButtonText
            color={sortOptionsPopover.isOpen ? colors.blue.interactive : colors.gray.tertiary}
          >
            Sort
          </ButtonText>
          <Space width={8} />
          <Icon
            source={Icon.CaretDown}
            size={12}
            color={sortOptionsPopover.isOpen ? colors.blue.interactive : colors.gray.tertiary}
          />
        </ActionButton>
      </Popover.Content>
      <Space width={8} />
      <Space style={{flex: 1}} />
      {/* @ts-expect-error TS(2349): This expression is not callable. */}
      <OpenButton onPress={() => toggleOrganizationIsOpen(organizationId)}>
        <ButtonText color={colors.blue.interactive}>{isOpen ? 'Close' : 'Open'}</ButtonText>
        <Space width={8} />
        <Icon
          source={isOpen ? Icon.ChevronUp : Icon.ChevronDown}
          size={Icon.Sizes.ExtraSmall}
          color={colors.blue.interactive}
        />
      </OpenButton>
      <SortOptionsPopover
        key={sortOptionsPopover.isOpen}
        popover={sortOptionsPopover}
        dayId={slotsSummary.dayId}
        refetch={refetch}
      />
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSlotsHeader.fragment = gql`
  fragment OrganizationSlotsHeader on DispatchCalendarDay {
    organizationSlotsSummaries {
      dayId
      dispatchStatusHeader {
        dispatchAllCount
        dispatchDoneCount
        dispatchNotDoneCount
      }
      organization {
        id
        name
      }
    }
  }
`;

export default OrganizationSlotsHeader;
