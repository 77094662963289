// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import JobForm from '@shared/modules/Job/forms/JobForm';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ValueFields from 'modules/Project/Billing/components/ValueFields';
import EditProjectBlockWrapper from 'modules/Project/V2/Edit/components/EditProjectBlockWrapper';
import MobileEditJobBlock from 'modules/Project/V2/Edit/components/MobileEditJobBlock';

const EmptyStateText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const NoJobTypePlaceholder = ({responsive}: any) => {
  return <EmptyStateText responsive={responsive}>Select a job type to see values.</EmptyStateText>;
};

const NoVariablesPlaceholder = ({responsive}: any) => {
  return <EmptyStateText responsive={responsive}>No values to display.</EmptyStateText>;
};

const EditJobVariablesPanelBody = ({form, field, filteredSectionId, isDisabled}: any) => {
  return (
    <ValueFields
      form={form}
      field={`${field}.valueForms`}
      isOnCreate
      filteredSectionId={filteredSectionId}
      isDisabled={isDisabled}
    />
  );
};

const EditJobVariablesBlockPanels = ({
  form,
  field,
  index,
  handleSetPositionY,
  layoutKey,
  jobType,
  isDisabled,
}: any) => {
  const responsive = useResponsive();

  if (!jobType) {
    return (
      <React.Fragment>
        {responsive.desktop ? (
          <ActionPanel
            index={index}
            title={'Values'}
            BodyComponent={NoJobTypePlaceholder}
            bodyComponentProps={{responsive}}
            style={{width: '100%'}}
          />
        ) : (
          <MobileEditJobBlock headerText={'Values'} responsive={responsive}>
            <NoJobTypePlaceholder responsive={responsive} />
          </MobileEditJobBlock>
        )}
      </React.Fragment>
    );
  }

  const jobForm = _.get(form.values, field);
  const sectionedValueForms = JobForm.getSectionedValueFormsForJobType(jobForm, {jobType});

  return (
    <React.Fragment>
      {sectionedValueForms.map(({id, name, valueFormsOnCreate}: any, sectionIndex: any) => {
        const isEmpty = _.isEmpty(valueFormsOnCreate);

        return (
          <React.Fragment key={id}>
            {responsive.desktop && sectionIndex > 0 && <Space height={24} />}
            {!responsive.desktop && sectionIndex > 0 && <Line style={{height: 4}} />}
            <EditProjectBlockWrapper
              index={sectionIndex}
              layoutKey={layoutKey}
              handleSetPositionY={({nativeEvent}: any) =>
                handleSetPositionY({nativeEvent, block: _.snakeCase(name)})
              }
            >
              {responsive.desktop ? (
                <ActionPanel
                  index={sectionIndex}
                  isEmpty={isEmpty}
                  EmptyBodyComponent={NoVariablesPlaceholder}
                  BodyComponent={EditJobVariablesPanelBody}
                  bodyComponentProps={{form, field, filteredSectionId: id, isDisabled}}
                  title={name}
                  style={{width: '100%'}}
                />
              ) : (
                <MobileEditJobBlock headerText={name} responsive={responsive}>
                  {isEmpty ? (
                    <NoVariablesPlaceholder responsive={responsive} />
                  ) : (
                    <EditJobVariablesPanelBody
                      form={form}
                      field={field}
                      filteredSectionId={id}
                      isDisabled={isDisabled}
                    />
                  )}
                </MobileEditJobBlock>
              )}
            </EditProjectBlockWrapper>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditJobVariablesBlock = ({
  form,
  field,
  index,
  handleSetPositionY,
  layoutKey,
  isDisabled,
}: any) => {
  const jobTypeId = _.get(form.values, `${field}.jobTypeId`, null);
  const {params} = useNavigationDOM();
  const jobId = _.get(form.values, `${field}.jobId`, null);
  const {loading, data} = useQuery(EditJobVariablesBlock.query, {
    variables: {
      jobTypeId,
      jobUuid: params.jobUuid,
      // We do not query for the job by uuid if we are creating/duplicating.
      // On redirect, the query for the same job utilizes cache-and-network, so the null object in the cache
      // causes errors when attempting to render the job blocks.
      isExistingJob: !!jobId,
    },
    skip: !jobTypeId,
    onCompleted: ({jobTypeById: jobType, job}) => {
      const updatedValueForms = Organization.makeJobValueFormsFromJobTypeVariableSections({
        jobType,
        existingValueForms: _.get(form.values, `${field}.valueForms`),

        // Under the hood, value forms get fully reset if there are no existing value forms
        // or if isEdit is false (i.e. a new job is being added). On the edit project jobs
        // page however we can rely solely on watching the value forms for a job to determine
        // if we need to reset the value forms. This way we can handle the difference between
        // a brand new job vs a new job that is a duplicate of an existing job. The duplicate
        // will have value forms that are already populated.
        isEdit: true,
      });

      if (job) {
        // For existing jobs run an additional check to make sure that we only
        // generate value forms for values that actually exist on the job.
        const variableIdentifiersForJobValues = job.values.map(
          ({variableIdentifier}: any) => variableIdentifier,
        );
        const applicableJobValueForms = (updatedValueForms as any).filter(
          ({variableIdentifier}: any) =>
            variableIdentifiersForJobValues.includes(variableIdentifier),
        );
        form.setFieldValue(`${field}.valueForms`, applicableJobValueForms);
      } else {
        form.setFieldValue(`${field}.valueForms`, updatedValueForms);
      }
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          <EditJobVariablesBlockPanels
            form={form}
            field={field}
            index={index}
            handleSetPositionY={handleSetPositionY}
            layoutKey={layoutKey}
            jobType={data?.jobTypeById}
            isDisabled={isDisabled}
          />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobVariablesBlock.fragment = gql`
  fragment EditJobVariablesBlock_Job on Query {
    job(uuid: $jobUuid) {
      id
      values {
        id
        variableIdentifier
      }
    }
  }
`;

EditJobVariablesBlock.query = gql`
  ${JobForm.getSectionedValueFormsForJobType.fragment}
  ${Organization.makeJobValueFormsFromJobTypeVariableSections.fragment}
  ${EditJobVariablesBlock.fragment}
  query EditJobVariablesBlock(
    $jobTypeId: Int!,
    $jobUuid: String!,
    $isExistingJob: Boolean!,
  ) {
    ${gql.query}
    jobTypeById(jobTypeId: $jobTypeId) {
      id
      ...JobForm_getSectionedValueFormsForJobType
      ...Organization_makeJobValueFormsFromJobTypeVariableSections
    }
    ...EditJobVariablesBlock_Job @include(if: $isExistingJob)
  }
`;

export default EditJobVariablesBlock;
