// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import Breadcrumbs from '@shared/design/components/Breadcrumbs';
import Line from 'modules/App/components/Line';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import JobTypeSettingsPageNavigationTabs from 'modules/Organization/Settings/JobTypes/components/JobTypeSettingsPageNavigationTabs';

const PageContainer = Styled.View`
`;

const ContentContainer = Styled.View`
  padding-left: 24px;
`;

const JobTypeSettingsPageBreadcrumb = ({jobType, projectType}: any) => {
  const {navigator} = useNavigationDOM();
  const breadcrumbs = [
    {name: 'Settings', onPress: () => navigator.push('/settings')},
    {name: 'Project Types', onPress: () => navigator.push('/settings/projects/project-types')},
    {
      name: projectType.name,
      onPress: () =>
        navigator.push(`/settings/projects/project-types/${projectType.uuid}/job-types`),
    },
    {name: jobType.name},
  ];

  return (
    <StandardOfficeHeader title={''} showLine={false}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </StandardOfficeHeader>
  );
};

const JobTypeSettingsPageHeader = ({jobType}: any) => {
  return (
    <PageContainer>
      <JobTypeSettingsPageBreadcrumb jobType={jobType} projectType={jobType.projectType} />
      <Space height={12} />
      <ContentContainer>
        <JobTypeSettingsPageNavigationTabs jobType={jobType} />
      </ContentContainer>
      <Line />
    </PageContainer>
  );
};

JobTypeSettingsPageHeader.fragment = gql`
  ${JobTypeSettingsPageNavigationTabs.fragment}

  fragment JobTypeSettingsPageHeader on JobType {
    id
    name
    projectType {
      id
      uuid
      name
    }
    ...JobTypeSettingsPageNavigationTabs
  }
`;

export default JobTypeSettingsPageHeader;
