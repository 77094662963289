// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {ReportMoveTimeRangesChart} from 'modules/ReportMove/components';

import UpdateTimeRangesList from './UpdateTimeRangesList';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

type Props = {
  form: any;
};

const UpdateReportMoveTimeRangesFields = ({form}: Props) => (
  <Container>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={0}>
      <ReportMoveTimeRangesChart timeRanges={form.values.timeRangeInputs} />
    </Row>
    <RowSpace />
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={1}>
      <UpdateTimeRangesList form={form} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesFields.fragment = gql`
  fragment UpdateReportMoveTimeRangesFields on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

export default UpdateReportMoveTimeRangesFields;
