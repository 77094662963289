// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import DocumentInventorySummaryV2 from 'modules/Document/components/DocumentInventorySummaryV2';

const Container = Styled.View`
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const Caption = Styled.H6`
  color: ${colors.gray.primary};
`;

const InventoryDocument = ({project}: any) => {
  const job = _.get(project, 'firstJob');
  const jobName = _.get(project, 'firstJob.name');
  const {isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber} = project.organization.features;

  return (
    <Container>
      <DocumentHeaderOrganization
        title={'Inventory Summary'}
        organization={project.organization}
        shouldShowBusinessAddressAndPhoneNumber={
          isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber
        }
      />
      <Space height={16} />
      <Title>Itemized Inventory</Title>
      <Caption>{`${project.organization.name} providing services to ${jobName}`}</Caption>
      <Space height={48} />
      <DocumentInventorySummaryV2 job={job} />
      <Space height={32} />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryDocument.fragment = gql`
  ${DocumentInventorySummaryV2.fragment}
  ${DocumentHeaderOrganization.fragment}

  fragment InventoryDocument on Project {
    id
    organization {
      id
      name
      features {
        isEnabledQuoteAndConfirmationShowAddressAndPhoneNumber: isEnabled(
          feature: "QUOTE_AND_CONFIRMATION_SHOW_ADDRESS_AND_PHONE_NUMBER"
        )
      }
      ...DocumentHeaderOrganization
    }
    firstJob {
      id
      name
      ...DocumentInventorySummaryV2
    }
  }
`;

export default InventoryDocument;
