// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ProjectTypeDayEstimateItem from 'modules/App/Day/components/ProjectTypeDayEstimateItem';
import ProjectTypeDayItem from 'modules/App/Day/components/ProjectTypeDayItem';

const Item = Styled.View`
  flex-direction: row;
`;

const Indicator = Styled.Loading`
  flex: 1;
`;

type OwnProps = {
  date: string;
  showEstimates?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CalendarProjectTypeDaysSection.defaultProps;

// @ts-expect-error TS(7022): 'CalendarProjectTypeDaysSection' implicitly has ty... Remove this comment to see the full error message
const CalendarProjectTypeDaysSection = ({date, showEstimates}: Props) => {
  const {loading, data} = useQuery(CalendarProjectTypeDaysSection.query, {
    variables: {
      date,
      showEstimates, // Fetch jobs OR estimates, but there's no need to fetch both
    },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <Indicator />;
  }

  return (
    <FlatList
      horizontal
      data={_.sortBy(data.day.projectTypeDays, 'projectType.index')}
      keyExtractor={(projectTypeDay: any) => projectTypeDay.id}
      renderItem={({item: projectTypeDay, index}: any) =>
        projectTypeDay.projectType.category === ProjectTypeCategory.MOVE && (
          <Item key={index}>
            {showEstimates ? (
              <ProjectTypeDayEstimateItem projectTypeDay={projectTypeDay} />
            ) : (
              <ProjectTypeDayItem projectTypeDay={projectTypeDay} />
            )}
            <Space width={5} />
          </Item>
        )
      }
      contentContainerStyle={{
        paddingVertical: 10,
      }}
      style={{
        alignSelf: 'stretch',
      }}
    />
  );
};

CalendarProjectTypeDaysSection.defaultProps = {
  showEstimates: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarProjectTypeDaysSection.query = gql`
  ${ProjectTypeDayItem.fragment}
  ${ProjectTypeDayEstimateItem.fragment}

  query CalendarProjectTypeDaysSection($date: String!, $showEstimates: Boolean!) {
    ${gql.query}
    day(date: $date) {
      id
      projectTypeDays {
        id
        projectType {
          id
          index
          category
        }
        ...ProjectTypeDayItem @skip(if: $showEstimates)
        ...ProjectTypeDayEstimateItem @include(if: $showEstimates)
      }
    }
  }
`;

export default CalendarProjectTypeDaysSection;
