// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const IconCell = Styled.View`
  width: 20px;
  height: ${(props) => ((props as any).height ? (props as any).height : '48px')};
  justify-content: ${(props) => ((props as any).justify ? (props as any).justify : 'flex-start')};
`;

const LeftDataCell = Styled.View`
  min-height: ${(props) => ((props as any).height ? (props as any).height : 48)}px;
  width: ${(props) => ((props as any).isLarge ? 421 : 303)}px;
  padding-horizontal: ${(props) => ((props as any).hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => ((props as any).hasPad ? '6px' : '0px')};
  background-color: ${(props) => ((props as any).disabled ? colors.gray.border : colors.white)};
  border-width: ${(props) => ((props as any).hasBorder || (props as any).hasError ? '1px' : '0px')};
  border-top-width: ${(props) => (((props as any).hasBorder && (props as any).isFirstItem) || (props as any).hasError ? '1px' : '0px')};
  border-color: ${(props) => ((props as any).hasError ? colors.red.warning : colors.gray.border)};
`;

const DataCell = Styled.View`
  width: 118px;
  align-items: flex-end;
  justify-content: flex-end;
  min-height: ${(props) => ((props as any).height ? (props as any).height : 48)}px;
  padding-horizontal: ${(props) => ((props as any).hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => ((props as any).hasPad ? '6px' : '0px')};
  background-color: ${(props) => {
    return (props as any).disabled
      ? colors.gray.border
      : (props as any).color
        ? (props as any).color
        : colors.white;
  }};
  border-top-width: ${(props) => ((props as any).hasBorder && (props as any).isFirstItem ? '1px' : '0px')};
  border-right-width: ${(props) => ((props as any).hasBorder || (props as any).hasBorderRight ? '1px' : '0px')};
  border-bottom-width: ${(props) => ((props as any).hasBorder ? '1px' : '0px')};
  border-color: ${colors.gray.border};
`;

const RightDataCell = Styled.View<{
  height?: number;
  isCentered?: boolean;
  isFirstItem?: boolean;
  hasPad?: boolean;
  disabled?: boolean;
  hasBorder?: boolean;
  color?: string;
}>`
  width: 177px;
  align-items: flex-end;
  min-height: ${(props) => ((props as any).height ? (props as any).height : 48)}px;
  justify-content: ${(props) => ((props as any).isCentered ? 'center' : 'flex-end')};
  padding-horizontal: ${(props) => ((props as any).hasPad ? '8px' : '0px')};
  padding-vertical: ${(props) => ((props as any).hasPad ? '6px' : '0px')};
  background-color: ${(props) => {
    return (props as any).disabled
      ? colors.gray.border
      : (props as any).color
        ? (props as any).color
        : colors.white;
  }};
  border-top-width: ${(props) => ((props as any).hasBorder && (props as any).isFirstItem ? '1px' : '0px')};
  border-right-width: ${(props) => ((props as any).hasBorder || (props as any).hasBorderRight ? '1px' : '0px')};
  border-bottom-width: ${(props) => ((props as any).hasBorder ? '1px' : '0px')};
  border-color: ${colors.gray.border};
`;

const FullWidthCell = Styled.View`
  width: 716px;
  border-width: 1px;
  border-top-width: 0px;
  border-color: ${colors.gray.border};
`;

const IconCircle = Styled.Touchable`
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${(props) => ((props as any).color ? (props as any).color : (props as any).disabled ? colors.gray.border : colors.blue.interactive)};
  justify-content: center;
  align-items: center;
`;

const CellCaption = Styled.H8`
  ${fontWeight(300)}
  color: ${colors.gray.secondary};
  text-align: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.textAlign ? vars.textAlign : 'left')};
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.hasPad ? '8px' : '0px')};
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.hasPad ? '6px' : '0px')};
`;

const CellButtonText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.blue.interactive};
`;

const CellText = Styled.H7`
  color: ${(props) => ((props as any).color ? (props as any).color : colors.black)};
  ${fontWeight(500)}
  text-align: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.textAlign ? vars.textAlign : 'left')};
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.hasPad ? '8px' : '0px')};
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.hasPad ? '6px' : '0px')};
`;

const CellInput = Styled.TextInput.H7`
  width: ${(props) => (props as any).width || '100%'};
  min-height: 47px;
  border-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.hasError ? 1 : 0)}px;
  border-color: ${colors.red.warning};
  border-radius: 0px;
  text-align: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.textAlign ? vars.textAlign : 'right')};
  padding-top: 16px;
  ${fontWeight(500)}
  position: absolute;
  background-color: transparent;
  padding-right: 8px;
  padding-left: 8px;
  left: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.alignLeft ? '0px' : null)};
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isFocused ? colors.black : 'transparent')};
`;

const DescriptionInputLabel = Styled.H8`
  color: ${colors.gray.secondary};
  position: absolute;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 2px;
`;

const DescriptionInput = Styled.TextInput.H7`
  height: ${(props) => (props as any).height}px;
  border-radius: 0px;
  border-width: 0px;
  padding-top: 24px;
  padding-left: 8px;
  padding-bottom: 6px;
  ${fontWeight(500)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isUpdating ? colors.black : colors.gray.tertiary)};
  text-align-vertical: bottom;
  background-color: ${(props) => ((props as any).disabled ? colors.gray.border : colors.white)};
`;

const NameInput = Styled.TextInput.H7`
  height: ${(props) => (props as any).height}px;
  border-radius: 0px;
  border-width: 0px;
  padding-left: 8px;
  padding-bottom: 6px;
  ${fontWeight(500)}
  color: ${colors.black};
  text-align-vertical: bottom;
  background-color: ${(props) => ((props as any).disabled ? colors.gray.border : colors.white)};
`;

const ResponsiveNameInput = ({value, handleChangeText, disabled}: any) => {
  const [inputHeight, setInputHeight] = useState(0);
  return (
    <NameInput
      defaultValue={value}
      onBlur={({nativeEvent: {text}}: any) => {
        handleChangeText(text);
      }}
      disabled={disabled}
      onContentSizeChange={(event: any) => setInputHeight(event.nativeEvent.contentSize.height)}
      multiline
      height={inputHeight}
    />
  );
};

CellText.propTypes = {
  // @ts-expect-error TS(2322): Type '{ vars: PropTypes.Requireable<object>; }' is... Remove this comment to see the full error message
  vars: PropTypes.object,
};
CellText.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ vars: {}; }' is not assignable to type 'Pa... Remove this comment to see the full error message
  vars: {},
};
CellCaption.propTypes = {
  // @ts-expect-error TS(2322): Type '{ vars: PropTypes.Requireable<object>; }' is... Remove this comment to see the full error message
  vars: PropTypes.object,
};
CellCaption.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ vars: {}; }' is not assignable to type 'Pa... Remove this comment to see the full error message
  vars: {},
};
CellInput.propTypes = {
  vars: PropTypes.object,
};
CellInput.defaultProps = {
  vars: {},
};
DescriptionInput.propTypes = {
  vars: PropTypes.object,
};
DescriptionInput.defaultProps = {
  vars: {},
};

const EditBillLineItemCells = {
  FullWidthCell,
  LeftDataCell,
  DataCell,
  RightDataCell,
  IconCell,
  IconCircle,
  CellCaption,
  CellButtonText,
  CellText,
  CellInput,
  DescriptionInputLabel,
  DescriptionInput,
  NameInput: ResponsiveNameInput,
};

export default EditBillLineItemCells;
