// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const BillTipInput = ({form, field, project}: any) => {
  return (
    <FieldInput
      {...form}
      isRequired
      name={`${field}.jobId`}
      component={DropdownInput}
      label={'Job'}
      isResponsive
      input={{
        isSearchable: true,
        placeholder: `Select job`,
        isPortaled: true,
        options: Project.getActiveJobsDropdownOptions(project),
        setFieldValue: form.setFieldValue,
        onChangeValue: (_value: any, option: any) => {
          form.setFieldValue(`${field}.name`, option.tipName);
        },
        style: {flex: 1},
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillTipInput.fragment = gql`
  ${Project.getActiveJobsDropdownOptions.fragment}

  fragment BillTipInput on Project {
    id
    organization {
      id
      features {
        isEnabledJobLevelTips: isEnabled(feature: "JOB_LEVEL_TIPS")
      }
    }
    ...Project_getActiveJobsDropdownOptions
  }
`;

export default BillTipInput;
