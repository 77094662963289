// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Dropdown, DropdownInput, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useHover, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

// Components
import {CalendarHeader} from 'modules/Calendar/components';

import ExportReportMovesReportModal from './ExportReportMovesReportModal';
import ReportsCalendarMonth from './ReportsCalendarMonth';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  flex: 1;
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Items = Styled.View`
  margin-top: 5px;
  padding-bottom: 5px;
  width: 200px;
  background-color: ${colors.white};
  border: 1px solid ${colors.blue.accent};
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const Item = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  padding-left: 10px;
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : colors.white)};
  z-index: 100;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Text = Styled.H7`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-horizontal: 10px;
  color: ${colors.gray.primary};
`;

const Space = Styled.View`
  width: 10px;
`;

const DropdownItem = ({text, onPress}: any) => {
  const {isHovered, ref} = useHover();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Item ref={ref} isHovered={isHovered}>
      <Touchable onPress={onPress}>
        <Text>{text}</Text>
      </Touchable>
    </Item>
  );
};

const SelectOrganizationDropdown = ({organization, date}: any) => {
  const responsive = useResponsive();
  const {params, navigator} = useNavigationDOM();
  const organizations = _.get(organization, 'primaryCompany.organizations', []);

  return (
    <DropdownInput
      name={'slug'}
      placeholder={'Select an organization'}
      value={params.organization}
      options={organizations.map((organization: any) => ({
        label: organization.name,
        value: organization.slug,
      }))}
      setFieldValue={(name, value) => {
        navigator.replace(`/reports/calendar?date=${date}&organization=${value}`);
      }}
      style={{
        width: responsive.mobile ? 200 : 250,
      }}
    />
  );
};

const ReportsActionsDropdown = ({responsive, organization}: any) => {
  const {isHovered, ref} = useHover();
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <Dropdown
        trigger={({handleToggle}: any) => (
          <Button ref={ref} color={isHovered ? colors.hover : colors.white} onPress={handleToggle}>
            {!responsive.mobile && <ButtonText style={{marginRight: 5}}>Actions</ButtonText>}
            <Icon color={colors.gray.primary} size={Icon.Sizes.Medium} source={Icon.CaretDown} />
          </Button>
        )}
      >
        {({isOpen, handleClose}: any) => (
          <Dropdown.Content isVisible={isOpen}>
            <Items>
              <DropdownItem text={'Export Data'} onPress={handleOpen} />
            </Items>
          </Dropdown.Content>
        )}
      </Dropdown>
      <ExportReportMovesReportModal
        isOpen={isOpen}
        organization={organization}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

const getOrganizationId = ({slug, organization}: any) => {
  if (!organization.isPrimary) {
    return organization.id;
  }

  const organizations = _.get(organization, 'primaryCompany.organizations', []);
  const selectedOrganization = _.find(organizations, (organization) => organization.slug === slug);
  return _.get(selectedOrganization, 'id');
};

const ReportsCalendar = ({searchParams, viewer}: any) => {
  const {params, navigator} = useNavigationDOM();
  const {organization: slug} = params;
  const date = Datetime.toMutationDate(
    searchParams.date || Datetime.toMutationDate(Datetime.today),
  );
  const type = searchParams.type || 'MONTH';
  const organizationId = getOrganizationId({slug, organization: viewer.viewingOrganization});
  const defaultSlug = viewer.viewingOrganization.slug;
  const isDeprecated = viewer.viewingOrganization.features.isEnabledDeprecateReportsCalendar;
  const responsive = useResponsive();

  useEffect(() => {
    if (isDeprecated) {
      navigator.replace(`/reports`);
      return;
    }
    // Default set the organization slug if one is not provided.
    if (!organizationId) {
      navigator.replace(`/reports/calendar?date=${date}&organization=${defaultSlug}`);
    }
  }, [organizationId, date, defaultSlug, navigator, isDeprecated]);

  return (
    <Wrapper {...responsive}>
      <CalendarHeader
        mode={'REPORTS'}
        queryDate={date}
        queryType={type}
        organization={viewer.viewingOrganization}
        right={
          <Actions>
            {viewer.viewingOrganization.isPrimary && (
              <SelectOrganizationDropdown
                organization={viewer.viewingOrganization}
                date={_.get(searchParams, 'date')}
              />
            )}
            {viewer.viewingOrganization.features.isEnabledExportReports && !responsive.mobile && (
              <React.Fragment>
                <Space />
                <ReportsActionsDropdown
                  responsive={responsive}
                  organization={viewer.viewingOrganization}
                />
              </React.Fragment>
            )}
          </Actions>
        }
      />
      <Container {...responsive} data-test-id='reports-calendar-container'>
        {!!organizationId && <ReportsCalendarMonth date={date} organizationId={organizationId} />}
      </Container>
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportsCalendar.fragment = gql`
  ${ExportReportMovesReportModal.fragment}
  ${CalendarHeader.fragment}

  fragment ReportsCalendar on User {
    id
    viewingOrganization {
      id
      slug
      isPrimary
      primaryCompany {
        id
        organizations {
          id
          name
          slug
        }
      }
      features {
        isEnabledExportReports: isEnabled(feature: "EXPORT_REPORTS")
        isEnabledDeprecateReportsCalendar: isEnabled(feature: "DEPRECATE_REPORTS_CALENDAR")
      }
      ...ExportReportMovesReportModal
      ...CalendarHeader
    }
  }
`;

export default ReportsCalendar;
