// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM, useResponsive, useToast} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Drawer from '@shared/design/components/Drawer';
import Switch from '@shared/design/components/Switch';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useCopyProjectMutation from '@shared/modules/Project/hooks/useCopyProjectMutation';
import ProjectClientFields from 'modules/Project/components/ProjectClientFields';

const Container = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
  flex-direction: row;
`;

const ClientFieldRow = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'row' : 'column')};
`;

const SectionHeading = Styled.Text`
  ${Typography.Subheading}
`;

const CompanyNameSwitch = ({form, field, disabled}: any) => {
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  const handleChange = () => {
    form.setFieldValue(`${field}.isShowingName`, !isShowingName);
    form.setFieldValue(`${field}.name`, '');
  };

  return (
    <Switch
      labelRight={'Add Company Name'}
      disabled={disabled}
      isOn={isShowingName}
      onChange={handleChange}
    />
  );
};

const ClientFields = ({
  form,
  field,
  organization,
  isShowingBillingClient,
  index,
  responsive,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index}>
      <ProjectClientFields.Query form={form} field={field}>
        {({searchQuery, handleChangeSearch, loading, clients}: any) => (
          <ProjectClientFields.Logic
            organization={organization}
            form={form}
            field={field}
            isSearchEnabled
            isShowingBillingClient={isShowingBillingClient}
          >
            {({
              hasSelectedClient,
              hasClientNotes,
              isShowingName,
              canSearchClients,
              disableClientInputs,
              parentField,
              sameAsClient,
              isShowingClientActionButton,
              displayClientFields,
            }: any) => (
              <React.Fragment>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Row index={0}>
                  <SectionHeading>{`${
                    isShowingBillingClient ? 'Billing' : 'Client'
                  } Information`}</SectionHeading>
                  <Space style={{flex: 1, minWidth: 16}} />
                  {isShowingClientActionButton && hasSelectedClient && (
                    <ProjectClientFields.ResetClientButton
                      form={form}
                      field={field}
                      organizationId={organization.id}
                      resetClientLabel={'Reset Client'}
                    />
                  )}
                </Row>
                {isShowingBillingClient && (
                  <React.Fragment>
                    <Space height={16} />
                    <ProjectClientFields.SameAsClientCheckbox
                      label={'Same as Client Information'}
                      form={form}
                      field={field}
                      sameAsClient={sameAsClient}
                      parentField={parentField}
                      organization={organization}
                      childrenRight
                    />
                  </React.Fragment>
                )}
                <Space height={16} />
                {displayClientFields && (
                  <React.Fragment>
                    <CompanyNameSwitch form={form} field={field} disabled={disableClientInputs} />
                    {isShowingName && (
                      <React.Fragment>
                        <Space height={16} />
                        {/* @ts-expect-error TS(2769): No overload matches this call. */}
                        <ClientFieldRow index={1} responsive={responsive}>
                          <ProjectClientFields.CompanyNameRow
                            isRequired
                            form={form}
                            field={field}
                            clients={clients}
                            loading={loading}
                            disabled={disableClientInputs}
                            searchQuery={searchQuery}
                            handleChangeSearch={handleChangeSearch}
                            canSearchClients={canSearchClients}
                            style={{flex: 1}}
                          />
                        </ClientFieldRow>
                      </React.Fragment>
                    )}
                    <ProjectClientFields.ContactNameLogic
                      form={form}
                      field={field}
                      organization={organization}
                    >
                      {({contactFirstName, contactLastName, isLastNameRequired}: any) => (
                        <React.Fragment>
                          <Space height={16} />
                          {/* @ts-expect-error TS(2769): No overload matches this call. */}
                          <ClientFieldRow index={2} responsive={responsive}>
                            <ProjectClientFields.ContactFirstNameField
                              isRequired
                              label={'Contact First Name'}
                              form={form}
                              field={field}
                              clients={clients}
                              loading={loading}
                              disabled={disableClientInputs}
                              canSearchClients={canSearchClients}
                              contactFirstName={contactFirstName}
                              searchQuery={searchQuery}
                              handleChangeSearch={handleChangeSearch}
                              style={{flex: 1}}
                            />
                          </ClientFieldRow>
                          <Space height={16} />
                          {/* @ts-expect-error TS(2769): No overload matches this call. */}
                          <ClientFieldRow index={3} responsive={responsive}>
                            <ProjectClientFields.ContactLastNameField
                              label={'Contact Last Name'}
                              form={form}
                              field={field}
                              clients={clients}
                              loading={loading}
                              disabled={disableClientInputs}
                              isLastNameRequired={isLastNameRequired}
                              contactLastName={contactLastName}
                              canSearchClients={canSearchClients}
                              searchQuery={searchQuery}
                              handleChangeSearch={handleChangeSearch}
                              style={{flex: 1}}
                            />
                          </ClientFieldRow>
                        </React.Fragment>
                      )}
                    </ProjectClientFields.ContactNameLogic>
                    <ProjectClientFields.ContactInfoLogic
                      form={form}
                      field={field}
                      organization={organization}
                    >
                      {({isPhoneNumberRequired, hasPhoneNumber, isDuplicatePhoneNumber}: any) => (
                        <React.Fragment>
                          <Space height={16} />
                          {/* @ts-expect-error TS(2769): No overload matches this call. */}
                          <ClientFieldRow index={4} responsive={responsive}>
                            <ProjectClientFields.ContactPhoneNumberField
                              form={form}
                              field={field}
                              clients={clients}
                              loading={loading}
                              disabled={disableClientInputs}
                              isPhoneNumberRequired={isPhoneNumberRequired}
                              hasPhoneNumber={hasPhoneNumber}
                              canSearchClients={canSearchClients}
                              searchQuery={searchQuery}
                              handleChangeSearch={handleChangeSearch}
                              isDuplicatePhoneNumber={isDuplicatePhoneNumber}
                              style={{flex: 1}}
                            />
                          </ClientFieldRow>
                          <Space height={16} />
                          {/* @ts-expect-error TS(2769): No overload matches this call. */}
                          <ClientFieldRow index={5} responsive={responsive}>
                            <ProjectClientFields.ContactEmailField
                              form={form}
                              field={field}
                              clients={clients}
                              loading={loading}
                              disabled={disableClientInputs}
                              canSearchClients={canSearchClients}
                              searchQuery={searchQuery}
                              handleChangeSearch={handleChangeSearch}
                              style={{flex: 1}}
                            />
                          </ClientFieldRow>
                        </React.Fragment>
                      )}
                    </ProjectClientFields.ContactInfoLogic>
                  </React.Fragment>
                )}
                {(!disableClientInputs || hasClientNotes) && displayClientFields && (
                  <React.Fragment>
                    <Space height={16} />
                    {/* @ts-expect-error TS(2769): No overload matches this call. */}
                    <ClientFieldRow index={6} responsive={responsive}>
                      <ProjectClientFields.ClientNotesInput
                        {...form}
                        index={0}
                        name={`${field}.notes`}
                        label={'Client Notes'}
                        disabled={disableClientInputs}
                        input={{
                          disabled: disableClientInputs,
                          multiline: true,
                          placeholder: 'Enter any notes',
                          style: {
                            paddingVertical: 9,
                            width: '100%',
                            minHeight: 100,
                          },
                        }}
                        style={{flex: 1}}
                      />
                    </ClientFieldRow>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </ProjectClientFields.Logic>
        )}
      </ProjectClientFields.Query>
    </Container>
  );
};

const DuplicateProjectDrawerContent = ({project, handleClose}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const copyProjectSuccessToast = useToast({
    ToastComponent: SuccessToast,
    // Default message, will get overriden by dynamicMessage
    message: 'Project created!',
    actionText: 'View Project',
    handleAction: ({projectUuid}) => navigator.push(`/projects/${projectUuid}/view`),
  });
  const {form, submitting, handleSubmit} = useCopyProjectMutation({
    projectForm: ProjectForm.copy(project),
    onSuccess: ({project}: any) => {
      const projectUuid = _.get(project, 'uuid');
      const projectIdentifier = _.get(project, 'identifier');
      copyProjectSuccessToast.handleToast({
        actionPayload: {projectUuid},
        message: `Project ${projectIdentifier} created!`,
      });
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const field = 'projectForm';

  return (
    <React.Fragment>
      <Drawer.Body>
        <ClientFields
          index={0}
          form={form}
          field={`${field}.clientForm`}
          organization={project.organization}
          responsive={responsive}
        />
        <Space height={24} />
        <ClientFields
          index={1}
          isShowingBillingClient
          form={form}
          field={`${field}.billingClientForm`}
          organization={project.organization}
          responsive={responsive}
        />
      </Drawer.Body>
      {responsive.desktop ? (
        <Drawer.Footer
          isDisabled={submitting}
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          secondaryAction={handleClose}
          primaryActionText={'Duplicate'}
          secondaryActionText={'Cancel'}
        />
      ) : (
        <Drawer.FooterMobile
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          primaryActionText={'Duplicate'}
        />
      )}
    </React.Fragment>
  );
};

const DuplicateProjectDrawer = ({projectUuid, isOpen, handleClose}: any) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(DuplicateProjectDrawer.query, {
    variables: {projectUuid},
    skip: !isOpen,
  });

  return (
    <Drawer.PreventPropagationContainer style={{position: 'absolute'}}>
      <Drawer
        isOpen={isOpen}
        handleClose={handleClose}
        width={Drawer.WIDTH.DEFAULT}
        shouldCloseOnClickOutside={false}
      >
        {responsive.desktop ? (
          // @ts-expect-error TS(2741): Property 'isResponsive' is missing in type '{ head... Remove this comment to see the full error message
          <Drawer.Header
            headerText={'Duplicate Project'}
            handleClose={handleClose}
            isClosable={false}
          />
        ) : (
          <Drawer.HeaderMobile headerText={'Duplicate Project'} handleClose={handleClose} />
        )}
        <Loading loading={loading || !data}>
          {() => <DuplicateProjectDrawerContent project={data.project} handleClose={handleClose} />}
        </Loading>
      </Drawer>
    </Drawer.PreventPropagationContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DuplicateProjectDrawer.query = gql`
  ${ProjectClientFields.fragment}
  ${ProjectForm.copy.fragment}
  query DuplicateProjectDrawer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        ...ProjectClientFields
      }
      ...ProjectForm_copy
    }
  }
`;

export default DuplicateProjectDrawer;
