// Libraries
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CrewPayableUserItem from './CrewPayableUserItem';
import CrewPayableUserListHeader from './CrewPayableUserListHeader';

const Item = Styled.View`
`;

const EmptyContainer = Styled.View`
  padding: 40px;
  align-items: center;
`;

const EmptyMessage = Styled.H6`
  color: ${colors.gray.secondary};
`;

const CrewPayableUsersList = ({payableUsers}: any) => {
  const responsive = useResponsive();

  return (
    <ScrollView
      horizontal
      // @ts-expect-error TS(2322): Type '{ width: string | null; }' is not assignable... Remove this comment to see the full error message
      contentContainerStyle={{
        width: responsive.desktop ? '100%' : null,
      }}
    >
      <FlatList
        initialNumToRender={20}
        data={payableUsers}
        keyExtractor={(payableUser: any, index: any) => payableUser.user.id}
        renderItem={({item: payableUser, index}: any) => (
          <Item>
            <CrewPayableUserItem
              isFirst={index === 0}
              isLast={index === payableUsers.length - 1}
              index={index}
              payableUser={payableUser}
            />
          </Item>
        )}
        ListHeaderComponent={() => <CrewPayableUserListHeader />}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>There are no crew members for these dates.</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPayableUsersList.fragment = gql`
  ${CrewPayableUserItem.fragment}

  fragment CrewPayableUsersList on PayableUser {
    user {
      id
    }
    ...CrewPayableUserItem
  }
`;

export default CrewPayableUsersList;
