// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import SendJobDetailsToSpecificUsersModal from './SendJobDetailsToSpecificUsersModal';

const Button = Styled.Touchable`
  padding-vertical: 4px;
  padding-horizontal: 8px;
  background-color: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const Text = Styled.H7`
  color: ${colors.gray.tertiary};
  ${fontWeight(700)}
`;

const SendJobDetailsButton = ({job, refetch}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();

  return (
    <React.Fragment>
      <SendJobDetailsToSpecificUsersModal
        isOpen={isOpen}
        job={job}
        onClose={() => {
          handleClose();
          refetch();
        }}
      />
      <Button onPress={handleOpen}>
        <Text>Send Text</Text>
      </Button>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendJobDetailsButton.fragment = gql`
  ${SendJobDetailsToSpecificUsersModal.fragment}

  fragment SendJobDetailsButton on Job {
    id
    ...SendJobDetailsToSpecificUsersModal
  }
`;

export default SendJobDetailsButton;
