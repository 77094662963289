/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App

import useDeleteRoomMutation from '@shared/modules/Inventory/hooks/useDeleteRoomMutation';
import Modal from 'components/Modal';

const Container = Styled.View`
  padding: 20px;
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const DeleteRoomModal = ({isOpen, room, handleClose, handleCancel}: any) => {
  const {navigator, params} = useNavigationDOM();
  const {submitting, handleSubmit} = useDeleteRoomMutation({
    roomId: room.id,
    onSuccess: () => {
      handleClose();
      navigator.replace(`/jobs/${params.jobUuid}/inventories/${params.uuid}/summary`);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      title={'Delete room'}
      subtitle={`Are you sure you would like to delete ${room.name}?`}
      onClose={handleClose}
    >
      <Container>
        <Actions>
          <Button color={colors.gray.tertiary} disabled={submitting} onPress={handleCancel}>
            <Text color={colors.white}>Cancel</Text>
          </Button>
          <Space width={15} />
          <Button color={colors.red.accent} loading={submitting} onPress={handleSubmit}>
            <Text color={colors.red.warning}>Delete</Text>
          </Button>
        </Actions>
      </Container>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteRoomModal.fragment = gql`
  fragment DeleteRoomModal on Room {
    id
    name
  }
`;

export default DeleteRoomModal;
