// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import UploadDataTableFields from 'modules/Organization/Settings/Billing/Tables/components/UploadDataTableFields';

const DataTableModalContent = ({
  titleText,
  form,
  handleSubmit,
  submitting,
  isOpen,
  handleClose,
  showTableKindDropdown,
}: any) => {
  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen}>
      <SmallModal.BoldText>{titleText}</SmallModal.BoldText>
      <Space height={16} />
      <UploadDataTableFields
        form={form}
        field={'dataTableForm'}
        showTableKindDropdown={showTableKindDropdown}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default DataTableModalContent;
