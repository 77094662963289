// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {Job, Location} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime, Location as LocationUtils, Weight} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import PhoneNumber from '@shared/design/components/PhoneNumber';
import Line from 'modules/App/components/Line';
import LongDistanceDispatchTripCard from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripCard';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TripJobCardContainer = Styled.View`
`;

const JobCardHeaderButton = Styled.ButtonV2`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${({
    // @ts-expect-error TS(2339): Property 'isExpanded' does not exist on type 'Them... Remove this comment to see the full error message
    isExpanded,
  }) => (isExpanded ? '0px' : '4px')};
  border-bottom-right-radius: ${({
    // @ts-expect-error TS(2339): Property 'isExpanded' does not exist on type 'Them... Remove this comment to see the full error message
    isExpanded,
  }) => (isExpanded ? '0px' : '4px')};
`;

const JobCardHeaderTitle = Styled.Text`
  ${Typography.Label1}
`;

const JobCardHeaderSubtitle = Styled.Text`
  ${Typography.Body}
`;

const JobCardInformationContainer = Styled.View`
  padding: 16px;
  border-width: 1px;
  border-top-width: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-color: ${colors.gray.secondary};
  background-color: ${colors.white};
`;

const JobLocationsContainer = Styled.View`
  padding-left: 6px;
`;

const LocationContainer = Styled.View`
  padding-left: 16px;
`;

const LocationDot = Styled.View`
  position: absolute;
  top: 8px;
  left: -5px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background-color: ${colors.gray.secondary};
`;

const LocationBar = Styled.View`
  position: absolute;
  top: 10px;
  left: -3px;
  width: 2px;
  height: 100%;
  background-color: ${colors.gray.secondary};
`;

const LocationCaptionContainer = Styled.View`
  align-items: flex-end;
  width: 80px;
`;

const LocationCaption = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const InformationLabel = Styled.Text`
  ${Typography.Label}
  flex: 1;
`;

const InformationText = Styled.Text`
  ${Typography.Body}
  flex: 1;
`;

const getJobTitle = ({job}: any) => {
  const scheduledDate = job.scheduledDate
    ? Datetime.convertToDisplayDate(job.scheduledDate, Datetime.DISPLAY_SHORT_DATE)
    : 'No Scheduled Date';
  if (job.isPackJob) {
    return `Pack: ${scheduledDate}`;
  }
  if (job.isLoadJob) {
    return `Load: ${scheduledDate}`;
  }
  if (job.isDeliveryJob) {
    return `Deliver: ${scheduledDate}`;
  }
  return `Job: ${scheduledDate}`;
};

const getJobCardHeaderStyle = ({isPast, isUnscheduled}: any) => {
  if (isPast) {
    return {
      backgroundColor: colors.gray.border,
      borderColor: colors.gray.secondary,
    };
  }
  if (isUnscheduled) {
    return {
      backgroundColor: colors.orange.accent,
      borderColor: colors.orange.status,
    };
  }
  return {
    backgroundColor: colors.blue.accent,
    borderColor: colors.blue.interactive,
  };
};

const getArrivalTimeText = ({job}: any) => {
  const startTime1 = job.startTime1
    ? Datetime.toDisplayTime(Datetime.fromTime(job.startTime1))
    : 'TBD';
  if (job.startTime2) {
    return `${startTime1} - ${Datetime.toDisplayTime(Datetime.fromTime(job.startTime2))}`;
  }
  if (job.startTime1) {
    return `${startTime1}`;
  }
  return '-';
};

const JobCardHeader = ({job, isExpanded, setIsExpanded, isPast}: any) => {
  const isUnscheduled = !job.scheduledDate;
  return (
    <JobCardHeaderButton
      isExpanded={isExpanded}
      onPress={() => setIsExpanded(!isExpanded)}
      style={getJobCardHeaderStyle({isPast, isUnscheduled})}
    >
      <Row>
        <Column>
          <Row>
            <JobCardHeaderTitle>{getJobTitle({job})}</JobCardHeaderTitle>
            {isUnscheduled && (
              <React.Fragment>
                <Space width={8} />
                <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={15} />
              </React.Fragment>
            )}
          </Row>
          <JobCardHeaderSubtitle>{job.project.client.name}</JobCardHeaderSubtitle>
        </Column>
        <Space style={{flex: 1}} />
        <Icon
          source={isExpanded ? Icon.ChevronUp : Icon.ChevronDown}
          size={15}
          color={colors.gray.primary}
        />
      </Row>
    </JobCardHeaderButton>
  );
};

const JobLocation = ({location, isFirst, isLast}: any) => {
  const responsive = useResponsive();
  return (
    <LocationContainer>
      <LocationDot />
      {!isLast && <LocationBar />}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} {...responsive}>
        <FieldValue
          newTab
          link={LocationUtils.createGoogleMapsUrl(location.address)}
          value={Location.getDisplayLocation(location)}
          style={{flex: 1}}
        />
        <LocationCaptionContainer>
          <LocationCaption>{isFirst ? 'Origin' : isLast ? 'Destination' : ''}</LocationCaption>
        </LocationCaptionContainer>
      </Row>
      {!isLast && <Space height={16} />}
    </LocationContainer>
  );
};

const JobLocations = ({job}: any) => {
  return (
    <JobLocationsContainer>
      {job.locations.map((location: any, index: any) => (
        <JobLocation
          key={location.id}
          location={location}
          isFirst={index === 0}
          isLast={index === job.locations.length - 1}
        />
      ))}
    </JobLocationsContainer>
  );
};

const InformationRow = ({label, text}: any) => {
  return (
    <React.Fragment>
      <Space height={8} />
      <Row>
        <InformationLabel>{`${label}:`}</InformationLabel>
        <InformationText>{text}</InformationText>
      </Row>
    </React.Fragment>
  );
};

const JobCustomer = ({job}: any) => {
  const customerName = job.project.client.name;
  return <InformationRow label={'Customer'} text={customerName} />;
};

const JobPhoneNumber = ({job}: any) => {
  const phoneNumber = _.get(job, 'project.client.primaryContact.phoneNumber');
  return (
    <React.Fragment>
      <Space height={8} />
      <Row>
        <InformationLabel>{`Phone Number:`}</InformationLabel>
        {!phoneNumber ? (
          <InformationText>-</InformationText>
        ) : (
          <Column style={{flex: 1}}>
            <PhoneNumber isClickable phoneNumber={phoneNumber} />
          </Column>
        )}
      </Row>
    </React.Fragment>
  );
};

const JobScheduledDate = ({job}: any) => {
  const scheduledDate = job.scheduledDate
    ? Datetime.convertToDisplayDate(job.scheduledDate, Datetime.DISPLAY_SHORT_DATE)
    : '-';
  return <InformationRow label={'Scheduled Date'} text={scheduledDate} />;
};

const JobEstimatedDate = ({job}: any) => {
  const estimatedDate = Job.getDisplayDate(job, Datetime.DISPLAY_SHORT_DATE, '-');
  return <InformationRow label={'Estimated Date(s)'} text={estimatedDate} />;
};

const JobArrivalTime = ({job}: any) => {
  return <InformationRow label={'Arrival Time'} text={getArrivalTimeText({job})} />;
};

const JobWeight = ({job}: any) => {
  const weight = Weight.display(job.project.weight);
  return <InformationRow label={'Weight'} text={weight} />;
};

const JobVolume = ({job}: any) => {
  return <InformationRow label={'Volume'} text={`${job.project.volume} ft³`} />;
};

const JobDriverCompensation = ({job}: any) => {
  const compensation = Currency.display(job.project.totalCompensationForTripDriver);
  return <InformationRow label={'Compensation'} text={compensation} />;
};

const JobDriverNotes = ({job}: any) => {
  return (
    <React.Fragment>
      <InformationRow label={'Driver Notes'} text={''} />
      <Space height={4} />
      <InformationText>{job.additionalNotes}</InformationText>
    </React.Fragment>
  );
};

const JobCardInformation = ({job}: any) => {
  return (
    <JobCardInformationContainer>
      <JobLocations job={job} />
      <Space height={4} />
      <JobCustomer job={job} />
      <JobPhoneNumber job={job} />
      <JobScheduledDate job={job} />
      <JobEstimatedDate job={job} />
      <JobArrivalTime job={job} />
      <JobWeight job={job} />
      <JobVolume job={job} />
      <Space height={12} />
      <Line />
      <Space height={4} />
      <JobDriverCompensation job={job} />
      <JobDriverNotes job={job} />
    </JobCardInformationContainer>
  );
};

const TripJobCard = ({job, isPast}: any) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <TripJobCardContainer>
      <JobCardHeader
        job={job}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        isPast={isPast}
      />
      {isExpanded && (
        <JobCardInformation job={job} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      )}
    </TripJobCardContainer>
  );
};

TripJobCard.fragment = gql`
  ${Job.getDisplayDate.fragment}
  ${LongDistanceDispatchTripCard.fragment}
  ${Location.getDisplayLocation.fragment}

  fragment TripJobCard on Job {
    id
    scheduledDate
    isPackJob
    isLoadJob
    isDeliveryJob
    additionalNotes
    startTime1
    startTime2
    project {
      id
      weight
      volume
      totalCompensationForTripDriver
      client {
        id
        name
        primaryContact {
          id
          phoneNumber
        }
      }
    }
    locations {
      id
      address
      ...Location_getDisplayLocation
    }
    ...Job_getDisplayDate
  }
`;

export default TripJobCard;
