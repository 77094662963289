// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useState, useToast} from '@supermove/hooks';
import {Proposal} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Float} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import Toast from '@shared/design/components/Toast';
import ExpirationTimeframeUnitKind from '@shared/modules/Proposal/enums/ExpirationTimeframeUnitKind';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import ProjectConfirmationStep from 'modules/Project/Confirmation/components/ProjectConfirmationStep';
import ProjectConfirmationStepAdd from 'modules/Project/Confirmation/components/ProjectConfirmationStepAdd';

const Wrapper = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Container = Styled.View`
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const ErrorText = Styled.Text`
  ${Typography.Body}
  color: ${colors.red.warning};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const IconButton = Styled.ButtonV2`
`;

// TODO(dan) Can remove this hack once fully migrated to EmailTemplateV2
const getFieldName = (name: any, field: any) => {
  return field ? `${field}.${name}` : name;
};

const ConfirmationStepsContent = ({
  isEditingSteps,
  steps,
  project,
  isQuote,
  form,
  field,
  stepsField,
  isShowingOptions,
  setIsShowingOptions,
}: any) => {
  const stepInputsError = _.get(form, `errors.${field}.stepInputs`);
  return (
    <Column>
      {!isShowingOptions && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Wrapper index={0}>
          {steps.map((step: any, index: any) => (
            <ProjectConfirmationStep
              key={index}
              showActions
              isFirst={index === 0}
              isLast={index === steps.length - 1}
              isEditable={isEditingSteps}
              index={index}
              form={form}
              field={field}
              project={project}
              step={step}
              style={{marginTop: 8}}
            />
          ))}
        </Wrapper>
      )}
      {isEditingSteps && (
        <ProjectConfirmationStepAdd
          index={1}
          steps={steps}
          project={project}
          isQuote={isQuote}
          setIsShowingOptions={setIsShowingOptions}
          isShowingOptions={isShowingOptions}
          handleAddStep={(step: any) => {
            setIsShowingOptions(false);
            form.setFieldValue(stepsField, [...steps, Proposal.stepToForm(step)]);
          }}
        />
      )}
      {stepInputsError && (
        <React.Fragment>
          <Space height={8} />
          <ErrorText>{stepInputsError}</ErrorText>
        </React.Fragment>
      )}
    </Column>
  );
};

const ExpirationMessage = ({form, field, isQuote}: any) => {
  const [time, setTime] = useState(Date.now());
  const textCopiedToast = useToast({
    ToastComponent: Toast,
    message: 'Copied to clipboard',
  });

  // This is used to update the UI every second to display an accurate expiration time
  // useEffect will run onMount and the setInterval will run every second to call setTime
  // returning clearInterval is the cleanup before unmount and will ensure setInterval stops running
  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const expirationTimeframe = _.get(form.values, `${field}.expirationTimeframe`);
  const expirationTimeframeUnitKind = _.get(form.values, `${field}.expirationTimeframeUnitKind`);
  const expirationDatetime = Datetime.now.add(
    Float.toFloat(expirationTimeframe),
    ExpirationTimeframeUnitKind.getMomentUnits({kind: expirationTimeframeUnitKind}),
  );

  const calloutText = `This ${
    isQuote ? 'quote' : 'confirmation'
  } will expire on ${expirationDatetime.format('dddd, MM/DD/YY')} at ${expirationDatetime.format(
    'h:mm A',
  )}.`;

  return (
    // @ts-expect-error TS(2322): Type '{ children: Element[]; key: number; }' is no... Remove this comment to see the full error message
    <Callout key={time}>
      <Callout.Text>{calloutText}</Callout.Text>
      <Space width={8} />
      <IconButton
        onPress={() => {
          /* global navigator */
          navigator.clipboard.writeText(calloutText);
          textCopiedToast.handleToast();
        }}
      >
        <Icon source={Icon.Copy} size={Icon.Sizes.Medium} color={colors.blue.interactive} />
      </IconButton>
    </Callout>
  );
};

const ExpirationFields = ({form, field, isQuote}: any) => {
  const isEnabledExpirationField = `${field}.isEnabledExpiration`;
  const isEnabledExpiration = _.get(form.values, isEnabledExpirationField);
  return (
    <React.Fragment>
      <Space height={40} />
      <Row style={{alignItems: 'center'}}>
        <Title>Expiration</Title>
        <Space width={16} />
        <SwitchField
          index={0}
          form={form}
          field={isEnabledExpirationField}
          labelRight={isEnabledExpiration ? 'Enabled' : 'Disabled'}
        />
      </Row>
      <Space height={8} />
      <DescriptionText>{`When enabled, this ${
        isQuote ? 'quote' : 'confirmation'
      } will automatically expire after the specified timeframe.`}</DescriptionText>
      <Space height={16} />
      {isEnabledExpiration && (
        <React.Fragment>
          <Row>
            <FieldInput
              {...form}
              isRequired
              index={1}
              name={`${field}.expirationTimeframe`}
              style={{flex: 1, maxWidth: 240}}
              label={'Expire In'}
              input={{
                placeholder: 'Enter #',
                style: {flex: 1},
              }}
            />
            <Space width={16} />
            <FieldInput
              {...form}
              index={1}
              name={`${field}.expirationTimeframeUnitKind`}
              component={DropdownInput}
              // an empty label is provided so inputs are inline even when errors are displayed
              label={' '}
              style={{width: 120}}
              input={{
                isPortaled: true,
                options: ExpirationTimeframeUnitKind.getDropdownOptions({
                  kinds: ExpirationTimeframeUnitKind.ALL_UNIT_KINDS,
                }),
                style: {flex: 1},
                setFieldValue: form.setFieldValue,
              }}
            />
          </Row>
          <Space height={16} />
          <ExpirationMessage form={form} field={field} isQuote={isQuote} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ConfirmationProjectFields = ({
  form,
  field,
  viewerId,
  project,
  isQuote,
  defaultEmailTemplateForm,
  setIsGeneratingPreview,
}: any) => {
  const isEditingStepsField = getFieldName('isEditingSteps', field);
  const stepsField = getFieldName('steps', field);
  const isEditingSteps = _.get(form.values, isEditingStepsField);
  const steps = _.get(form.values, stepsField);
  const [isShowingOptions, setIsShowingOptions] = useState(false);

  return (
    <Container>
      {!_.isEmpty(form.errors) && (
        <React.Fragment>
          <ErrorCallout text={'Please fix the errors below to continue.'} />
          <Space height={40} />
        </React.Fragment>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={0}>
        <Row
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title>{isQuote ? `Quote Steps` : `Confirmation Steps`}</Title>
          {isEditingSteps ? (
            <TertiaryButton
              text={'Done'}
              onPress={() => {
                form.setFieldValue(isEditingStepsField, false);
                setIsShowingOptions(false);
              }}
            />
          ) : (
            <SecondaryButton
              isSmall
              text={'Edit'}
              iconLeft={Icon.Pen}
              onPress={() => form.setFieldValue(isEditingStepsField, true)}
            />
          )}
        </Row>
        <Space height={8} />
        <ConfirmationStepsContent
          isEditingSteps={isEditingSteps}
          steps={steps}
          project={project}
          isQuote={isQuote}
          form={form}
          field={field}
          stepsField={stepsField}
          isShowingOptions={isShowingOptions}
          setIsShowingOptions={setIsShowingOptions}
        />
      </Section>
      {!isEditingSteps && <ExpirationFields form={form} field={field} isQuote={isQuote} />}
      <Space height={40} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={1} style={{opacity: isEditingSteps ? 0 : 100}}>
        <Title>Email Draft</Title>
        <Space height={16} />
        <ProjectEmailFields
          form={form}
          field={`${field}.emailForm`}
          viewerId={viewerId}
          project={project}
          defaultEmailTemplateForm={defaultEmailTemplateForm}
          setIsGeneratingPreview={setIsGeneratingPreview}
        />
        <Space height={40} />
        <Column>
          <EmailAttachmentsSelector
            form={form}
            name={field ? `${field}.emailForm` : null}
            project={project}
          />
        </Column>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationProjectFields.fragment = gql`
  ${ProjectEmailFields.fragment}
  ${EmailAttachmentsSelector.fragment}
  ${ProjectConfirmationStep.fragment}
  ${ProjectConfirmationStepAdd.fragment}

  fragment ConfirmationProjectFields on Project {
    id
    ...EmailAttachmentsSelector
    ...ProjectConfirmationStep
    ...ProjectConfirmationStepAdd
    ...ProjectEmailFields
  }
`;

export default ConfirmationProjectFields;
