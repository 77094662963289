// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Datetime, Duration, pluralize} from '@supermove/utils';

// App

// Components
import CrewUpdatePayableUserDayModal from './CrewUpdatePayableUserDayModal';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 20px;
  padding-horizontal: 20px;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Link = Styled.H6`
  width: 150px;
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Text = Styled.H6`
  width: 150px;
  color: ${colors.gray.primary};
  text-align: right;
`;

const CrewPayableUserDayItem = ({isFirst, isLast, index, payableUserDay, refetch}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal({onClose: refetch});

  return (
    <React.Fragment>
      <Container>
        <Line />
        <Row>
          <Touchable onPress={handleOpen}>
            <Link>{Datetime.convertToDisplayDate(payableUserDay.day.value, 'dddd (M/D)')}</Link>
          </Touchable>
          <Text>{pluralize('hr', Duration.toHours(payableUserDay.regularTime), true)}</Text>
          <Text>{pluralize('hr', Duration.toHours(payableUserDay.overtimeTime), true)}</Text>
          <Text>{pluralize('hr', Duration.toHours(payableUserDay.totalTime), true)}</Text>
          <Text>{Currency.display(payableUserDay.totalAmount)}</Text>
        </Row>
        {isLast && <Line />}
      </Container>
      <CrewUpdatePayableUserDayModal
        isOpen={isOpen}
        payableUserDay={payableUserDay}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewPayableUserDayItem.fragment = gql`
  ${CrewUpdatePayableUserDayModal.fragment}

  fragment CrewPayableUserDayItem on PayableUserDay {
    regularTime
    overtimeTime
    totalTime
    totalAmount
    day {
      id
      value
    }
    ...CrewUpdatePayableUserDayModal
  }
`;

export default CrewPayableUserDayItem;
