// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectTypeDot = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const ProjectType = ({projectType, style}: any) => {
  const responsive = useResponsive();

  return (
    <Container style={{...style}}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ProjectTypeDot color={projectType.color} />
      <Space width={8} />
      <MicroText responsive={responsive}>{projectType.name}</MicroText>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectType.fragment = gql`
  fragment ProjectType on Project {
    id
    projectType {
      id
      name
      color
    }
  }
`;

export default ProjectType;
