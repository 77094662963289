// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ClaimStatusForm from '@shared/modules/Claim/forms/ClaimStatusForm';
import useCreateClaimStatusMutation from '@shared/modules/Claim/hooks/useCreateClaimStatusMutation';
import ClaimStatusFields from 'modules/Organization/Settings/Company/components/ClaimStatusFields';

const CreateClaimStatusHeader = () => {
  return (
    <React.Fragment>
      <SmallModal.HeaderText>Create Claim Status</SmallModal.HeaderText>
      <Space height={16} />
    </React.Fragment>
  );
};

const CreateClaimStatusFooter = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <SmallModal.Footer>
      <SmallModal.Button
        // @ts-expect-error TS(2322): Type '{ children: string; textColor: "#616161"; on... Remove this comment to see the full error message
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      >
        Cancel
      </SmallModal.Button>
      <SmallModal.Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        isSubmitting={submitting}
        onPress={handleSubmit}
        isDisabled={submitting}
      >
        <Icon source={Icon.Check} color={colors.white} size={12} />
        <Space width={4} />
        Save
      </SmallModal.Button>
    </SmallModal.Footer>
  );
};

const CreateClaimStatusModal = ({organizationId, refetch, isOpen, handleClose}: any) => {
  const claimStatusForm = ClaimStatusForm.new({organizationId});
  const {form, submitting, handleSubmit} = useCreateClaimStatusMutation({
    claimStatusForm,
    onSuccess: ({claimStatus}: any) => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose} style={{overflow: 'visible'}}>
      <CreateClaimStatusHeader />
      <Space height={4} />
      <ClaimStatusFields form={form} field={'claimStatusForm'} />
      <Space height={16} />
      <CreateClaimStatusFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </SmallModal>
  );
};

export default CreateClaimStatusModal;
