/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import CrewSlotForm from '@shared/modules/Dispatch/forms/CrewSlotForm';

const Row = Styled.View`
  flex-direction: row;
`;

const LoadingContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ListItemRow = Styled.ButtonV2`
  flex-direction: row;
  background-color: ${(props) => (props as any).color};
  padding-vertical: ${(props) => ((props as any).responsive.mobile ? '12px' : '8px')};
  box-shadow: none;
  height: auto;
  border-radius: 0px;
  justify-content: flex-start;
  align-items: center;
`;

const CheckBox = Styled.View`
  height: 14px;
  width: 14px;
  align-items: center;
  background-color: ${(props) =>
    (props as any).disabled
      ? colors.gray.tertiary
      : (props as any).isSelected
        ? colors.blue.interactive
        : colors.white};
  border-color: ${(props) =>
    (props as any).disabled
      ? colors.gray.tertiary
      : (props as any).isSelected
        ? colors.blue.interactive
        : colors.gray.tertiary};
  border-width: 1px;
  border-radius: 2px;
`;

const PrimaryText = Styled.Text`
  ${Typography.Body3}
  color: ${(props) => ((props as any).disabled ? colors.gray.tertiary : colors.gray.primary)};
`;

const SecondaryText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const BusyText = Styled.Text`
  ${Typography.Body4}
  color: ${(props) => ((props as any).disabled ? colors.gray.tertiary : colors.gray.primary)};
`;

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

const updateCrewSlotFormsIsPrimary = (crewSlotForms: any) => {
  return crewSlotForms.map((crewSlotForm: any, index: any) => {
    return {...crewSlotForm, isPrimary: index === 0};
  });
};

const handleAddCrewSlotForm = ({field, form, crewId, truckId, slotId, driverId}: any) => {
  const crewSlotForms = _.get(form.values, field);
  const crewSlotFormToAdd = CrewSlotForm.toForm(
    CrewSlotForm.new({crewId, truckId, slotId, driverId}),
  );
  const crewSlotFormsAfterAdd = [...crewSlotForms, crewSlotFormToAdd];
  const crewSlotFormsUpdated = updateCrewSlotFormsIsPrimary(crewSlotFormsAfterAdd);
  form.setFieldValue(field, crewSlotFormsUpdated);
};

const handleRemoveCrewSlotForm = ({field, form, truckId, slotId}: any) => {
  const crewSlotForms = _.get(form.values, field);
  const crewSlotFormsAfterRemove = crewSlotForms.filter(
    (crewSlotForm: any) =>
      String(crewSlotForm.truckId) !== truckId && String(crewSlotForm.slotId) !== slotId,
  );

  const crewSlotFormsUpdated = updateCrewSlotFormsIsPrimary(crewSlotFormsAfterRemove);
  form.setFieldValue(field, crewSlotFormsUpdated);
};

const getBackgroundColor = ({isHighlighted, isDisabled}: any) => {
  if (isDisabled) {
    return colors.gray.disabled;
  }
  if (isHighlighted) {
    return colors.hover;
  }
  return colors.white;
};

/**
 * All TruckItems only have a truckId, SlotItem will handle both the (slot, no truck) and (slot, with truck)
 * cases.
 */
const TruckItem = ({truck, isSelected, onAddTruckOrSlotId, onRemoveTruckOrSlotId}: any) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const onPress = isSelected ? onRemoveTruckOrSlotId : onAddTruckOrSlotId;

  return (
    <ListItemRow
      ref={ref}
      color={getBackgroundColor({isHighlighted: isHovered || isSelected})}
      onPress={() => onPress({truckId: truck.id})}
      responsive={responsive}
    >
      <Space width={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <CheckBox isSelected={isSelected}>
        <Icon source={Icon.Check} size={12} color={colors.white} />
      </CheckBox>
      <Space width={8} />
      <PrimaryText>{truck.name}</PrimaryText>
      {!!truck.size && <PrimaryText>{`, ${truck.size}`}</PrimaryText>}
      <Space style={{flex: 1}} />
      <BusyText>AVAIL</BusyText>
      <Space width={12} />
    </ListItemRow>
  );
};

const SlotItem = ({slot, job, isSelected, onAddTruckOrSlotId, onRemoveTruckOrSlotId}: any) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const onPress = isSelected ? onRemoveTruckOrSlotId : onAddTruckOrSlotId;
  const isBusy = slot.activeCrewSlots.length > 0;
  const isTripTruck = job.tripTruckIds.includes(slot.truckId);

  return (
    <ListItemRow
      ref={ref}
      color={getBackgroundColor({isHighlighted: isHovered || isSelected, isDisabled: isTripTruck})}
      onPress={() => {
        onPress({slotId: slot.id, driverId: slot.driverId, truckId: _.get(slot, 'truck.id')});
      }}
      responsive={responsive}
      disabled={isTripTruck}
    >
      <Space width={16} />
      {isTripTruck ? (
        <TextTooltip
          isEnabledMobileToast={false}
          text={'A trip truck can only be removed by editing the trip.'}
        >
          <Row>
            <Space width={1} />
            <Icon source={Icon.Lock} size={13} color={colors.gray.secondary} />
          </Row>
        </TextTooltip>
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <CheckBox isSelected={isSelected}>
          <Icon source={Icon.Check} size={12} color={colors.white} />
        </CheckBox>
      )}
      <Space width={8} />
      {!!slot.truck && (
        <React.Fragment>
          <PrimaryText>{slot.truck.name}</PrimaryText>
          {!!slot.truck.size && <PrimaryText>{`, ${slot.truck.size}`}</PrimaryText>}
          <Space width={5} />
        </React.Fragment>
      )}
      <SecondaryText>{`Slot ${slot.index}`}</SecondaryText>
      <Space style={{flex: 1}} />
      <BusyText>{isBusy ? 'BUSY' : 'AVAIL'}</BusyText>
      <Space width={12} />
    </ListItemRow>
  );
};

type OwnTrucksAndSlotsListProps = {
  crewId?: string;
};

// @ts-expect-error TS(2456): Type alias 'TrucksAndSlotsListProps' circularly re... Remove this comment to see the full error message
type TrucksAndSlotsListProps = OwnTrucksAndSlotsListProps & typeof TrucksAndSlotsList.defaultProps;

// @ts-expect-error TS(7022): 'TrucksAndSlotsList' implicitly has type 'any' bec... Remove this comment to see the full error message
const TrucksAndSlotsList = ({
  organizationId,
  job,
  crewId,
  form,
  field,
  responsive,
}: TrucksAndSlotsListProps) => {
  const onAddTruckOrSlotId = ({truckId, slotId, driverId}: any) => {
    handleAddCrewSlotForm({field, form, crewId, truckId, slotId, driverId});
  };

  const onRemoveTruckOrSlotId = ({truckId, slotId}: any) => {
    handleRemoveCrewSlotForm({field, form, truckId, slotId});
  };

  const crewSlotForms = _.get(form.values, field);
  const selectedIds = {
    truckIds: crewSlotForms.map((crewSlotForm: any) => String(crewSlotForm.truckId)),
    slotIds: crewSlotForms.map((crewSlotForm: any) => String(crewSlotForm.slotId)),
  };

  const {loading, data} = useQuery(TrucksAndSlotsList.query, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId,
      date: job.day ? job.day.value : job.startDate,
      jobUuid: job.uuid,
    },
  });

  if (loading) {
    return (
      <LoadingContainer>
        <Indicator size={'large'} color={colors.gray.secondary} />
      </LoadingContainer>
    );
  }

  // Put all the slots with trucks first, sorted by index, and then remaining trucks are sorted alphabetically (case insensitive)
  const slots = _.sortBy(data.slots, (slot) => !slot.truck, ['index']);
  const trucks = [...data.organization.trucks, ...data.organization.overflowTrucks];
  const trucksWithoutSlot = trucks.filter((truck) => !_.find(slots, {truck: {id: truck.id}}));
  const trucksWithoutSlotSorted = _.sortBy(trucksWithoutSlot, [
    (truck) => truck.name.toLowerCase(),
  ]);

  return (
    <ScrollView>
      {slots.map((slot, index) => {
        return (
          <React.Fragment key={`${slot.id}-SLOT`}>
            {index === 0 && responsive.mobile && <Space height={8} />}
            {index > 0 && <Space height={2} />}
            <SlotItem
              slot={slot}
              job={data.job}
              isSelected={selectedIds.slotIds.includes(slot.id)}
              onAddTruckOrSlotId={onAddTruckOrSlotId}
              onRemoveTruckOrSlotId={onRemoveTruckOrSlotId}
            />
          </React.Fragment>
        );
      })}
      {trucksWithoutSlotSorted.map((truck, index) => {
        return (
          <React.Fragment key={`${truck.id}-TRUCK`}>
            {index === 0 && slots.length === 0 && responsive.mobile && <Space height={8} />}
            {(slots.length > 0 || index > 0) && <Space height={2} />}
            <TruckItem
              truck={truck}
              isSelected={selectedIds.truckIds.includes(truck.id)}
              onAddTruckOrSlotId={onAddTruckOrSlotId}
              onRemoveTruckOrSlotId={onRemoveTruckOrSlotId}
            />
          </React.Fragment>
        );
      })}
    </ScrollView>
  );
};

TrucksAndSlotsList.defaultProps = {
  crewId: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TrucksAndSlotsList.fragment = gql`
  fragment TrucksAndSlotsList on Job {
    id
    uuid
    startDate
    day {
      id
      value
    }
  }
`;

TrucksAndSlotsList.query = gql`
  query TrucksAndSlotsList($organizationId: Int!, $date: String!, $jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      tripTruckIds
    }
    slots: slotsForOrganizationAndDate(organizationId: $organizationId, date: $date) {
      id
      index
      driverId
      truckId
      truck {
        id
        name
        size
      }
      activeCrewSlots {
        id
      }
    }
    organization(organizationId: $organizationId) {
      id
      trucks: activeTrucks {
        id
        name
        size
      }
      overflowTrucks {
        id
        name
        size
      }
    }
  }
`;

export default TrucksAndSlotsList;
