// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useTextInput} from '@supermove/hooks';
import {CostItem} from '@supermove/models';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostItemForm from '@shared/modules/Billing/forms/CostItemForm';

const getOptionLabel = ({form, field, costItemType, rate}: any) => {
  const isSelected = _.get(form.values, `${field}.costItemTypeId`) === costItemType.id;
  const {name, unit} = costItemType;
  if (isSelected || !rate) {
    return name;
  }
  return `${name}, ${CostItem.getDisplayRate({unit, rate})}`;
};

const getCostItemTypeRateForMoverPositionId = ({form, costItemType, moverPositionId}: any) => {
  // NOTE(cooper): Cost item types of category COMPENSATION can have many rates,
  // whereas category OTHER only have one rate
  if (form.values.costForm.category === CostCategoryKind.COMPENSATION) {
    return _.find(
      costItemType.costItemTypeRates,
      (costItemTypeRate) => costItemTypeRate.moverPositionId === Number(moverPositionId),
    );
  }

  return costItemType.costItemTypeRates[0];
};

const getCostItemTypeOptions = ({form, field, costItemTypes, moverPositionId}: any) => {
  // 1. Filter out cost item type rates that do not match moverPositionId
  const costItemTypeOptions = costItemTypes.map((costItemType: any) => {
    const costItemTypeRate = getCostItemTypeRateForMoverPositionId({
      form,
      costItemType,
      moverPositionId,
    });
    const rate = costItemTypeRate ? costItemTypeRate.rate : null;
    return {
      label: getOptionLabel({form, field, costItemType, rate}),
      value: costItemType.id,
      rate,
      costItemType,
    };
  });
  // 2. Only keep the cost item types that have a rate for the moverPositionId
  const costItemTypesWithRateForPosition = _.filter(
    costItemTypeOptions,
    (option) => !_.isNil(option.rate),
  );
  // 3. Add a custom cost item option using the search input text
  const isCustomCostItem = _.get(form.values, `${field}.isCustomCostItem`);
  const costItemName = _.get(form.values, `${field}.name`);
  if (isCustomCostItem && costItemName) {
    const customCostItemOption = {label: costItemName, value: 0, rate: '', costItemType: {}};
    return [customCostItemOption, ...costItemTypesWithRateForPosition];
  }
  return costItemTypesWithRateForPosition;
};

const handleAddCostItemForm = ({form, field, costSectionField, costItemForm}: any) => {
  form.setFieldValue(field, costItemForm);
  const currentTotalCost = Currency.convertToCents(
    _.get(form.values, `${costSectionField}.totalCost`),
  );
  const addedCostTotal = Currency.convertToCents(costItemForm.total);
  form.setFieldValue(
    `${costSectionField}.totalCost`,
    Currency.toForm(currentTotalCost + addedCostTotal, {shouldHideCentsIfZero: true}),
  );
};

const CostItemTypeSearchInput = ({
  form,
  field,
  costSectionField,
  costItemTypes,
  costSectionForm,
  weight,
  totalDistance,
  moverPositionId,
}: any) => {
  const searchInput = useTextInput();
  const [isFocused, setIsFocused] = useState();
  const costItemTypeOptions = getCostItemTypeOptions({
    form,
    field,
    costSectionForm,
    costItemTypes,
    moverPositionId,
  });
  const selectedCostItemTypeId = _.get(form.values, `${field}.costItemTypeId`);
  const selectedCostItemTypeName = _.get(
    _.find(costItemTypeOptions, (option) => option.value === selectedCostItemTypeId),
    'label',
    '',
  );
  const {costId, billId, billSubtotal, projectRevenue} = costSectionForm;
  return (
    <FieldInput
      {...form}
      name={`${field}.costItemTypeId`}
      component={DropdownInput}
      style={{flex: 1}}
      showErrorMessage={false}
      input={{
        innerRef: searchInput.ref,
        options: costItemTypeOptions,
        isSearchable: true,
        inputValue: isFocused ? selectedCostItemTypeName : undefined,
        value: selectedCostItemTypeId,
        setFieldValue: form.setFieldValue,
        fontSize: 12,
        onInputChange: (value: any, {action}: any) => {
          if (action === 'input-change') {
            const rate = _.get(form.values, `${field}.rate`);
            const unit = _.get(form.values, `${field}.unit`);
            const total = _.get(form.values, `${field}.total`);
            const baseValue = _.get(form.values, `${field}.baseValue`);
            const costItemForm = CostItemForm.newCustomCostItem({
              costId,
              billId,
              name: value,
              rate,
              unit,
              total: Currency.convertToCents(total),
              baseValue,
            });
            form.setFieldValue(field, CostItemForm.toForm(costItemForm));
          }
          if (action === 'menu-close') {
            searchInput.handleBlur();
          }
        },
        onChangeValue: (id: any, {costItemType, rate}: any) => {
          if (costItemType.id) {
            const costItemForm = CostItemForm.newFromCostItemType(costItemType, {
              billId,
              costId,
              rate,
              weight,
              totalDistance,
              billSubtotal,
              projectRevenue,
            });
            handleAddCostItemForm({form, field, costSectionField, costItemForm});
          }
        },
        // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        onFocus: () => setIsFocused(true),
        // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        onBlur: () => setIsFocused(false),
        style: {
          width: '100%',
          fontSize: 12,
          height: 24,
          minHeight: 24,
        },
        components: {
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CostItemTypeSearchInput.fragment = gql`
  ${CostItemForm.newFromCostItemType.fragment}

  fragment CostItemTypeSearchInput on CostItemType {
    id
    unit
    costItemTypeRates {
      id
      rate
      moverPositionId
    }
    ...CostItemForm_newFromCostItemType
  }
`;

export default CostItemTypeSearchInput;
