// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {UserFlowStepModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';

const Container = Styled.View`
  padding-vertical: 16px;
  padding-horizontal: 24px;
  background-color: ${colors.white};
  border-radius: 8px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const DescriptionNone = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.tertiary};
`;

const NumberBox = Styled.View`
  width: 32px;
  height: 32px;
  border-radius: 4px;
  justify-content: center;
  background-color: ${colors.blue.accent};
`;

const NumberText = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.blue.accentDark};
  text-align: center;
`;

const DocumentFlowDetailsDrawerStepsListItem = ({
  index,
  userFlowStep,
}: {
  index: number;
  userFlowStep: UserFlowStepModel;
}) => {
  const responsive = useResponsive();
  const {documentTemplate, isDefault} = userFlowStep;

  return (
    <Container>
      <Row>
        <NumberBox>
          <NumberText>{index + 1}</NumberText>
        </NumberBox>
        <Space width={16} />
        <Column style={{flex: 1}}>
          <Row>
            <Title responsive={responsive}>{documentTemplate?.name}</Title>
            <Space width={8} />
            {isDefault && <ResponsiveBadge label={'Default'} />}
          </Row>
          <Space height={4} />
          {documentTemplate?.description ? (
            <Description responsive={responsive}>{documentTemplate?.description}</Description>
          ) : (
            <DescriptionNone responsive={responsive}>None</DescriptionNone>
          )}
        </Column>
      </Row>
    </Container>
  );
};

DocumentFlowDetailsDrawerStepsListItem.fragment = gql`
  fragment DocumentFlowDetailsDrawerStepsListItem on UserFlowStep {
    id
    isDefault
    documentTemplate {
      id
      name
    }
  }
`;

export default DocumentFlowDetailsDrawerStepsListItem;
