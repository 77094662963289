// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive} from '@supermove/hooks';
import {fontWeight, colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

import TruckAndMoverAvailabilityStatusColors from '@shared/modules/Dispatch/enums/TruckAndMoverAvailabilityStatusColors';
import Line from 'modules/App/components/Line';
import BookingCalendarWeekDayNotes from 'modules/Calendar/Booking/components/BookingCalendarWeekDayNotes';
import useBookingCalendarContext from 'modules/Calendar/Booking/context/useBookingCalendarContext';

const Section = Styled.View`
  width: 100%;
`;

const Container = Styled.View`
  flex: 1;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 7px;
`;

const Content = Styled.View`
  align-self: stretch;
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
  align-items: center;
`;

const DayText = Styled.H8`
  font-size: 10px;
  ${fontWeight(700)}
  line-height: 12px;
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const DateText = Styled.H5`
  margin-bottom: 1px;
  ${fontWeight(900)}
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Touchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const TouchableText = Styled.H7`
  margin-top: 1px;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CountHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const CountText = Styled.Text`
  ${Typography.Label4}
  color: ${(props) => (props as any).color};
`;

const TextOverflowColumn = Styled.View`
  width: 100%;
  max-width: 85px;
`;

const getFontColor = ({calendarDay}: any) => {
  return Datetime.isSameDay(Datetime.today, calendarDay.date)
    ? colors.blue.accentDark
    : colors.gray.secondary;
};

const navigateDate = ({date, selectedOrganizationSlug, navigator}: any) => {
  const newDate = Datetime.toMutationDate(Datetime.fromDate(date));
  navigator.replace(`/booking/calendar?date=${newDate}&organization=${selectedOrganizationSlug}`);
};

const BookingCalendarWeekDayHeader = ({calendarDay, refetch, form}: any) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const {columnMinWidth} = useBookingCalendarContext();
  const {data} = useQuery(BookingCalendarWeekDayHeader.query, {
    fetchPolicy: 'cache-and-network',
  });
  const isEnabledBookingCalendarDayNotes = _.get(
    data,
    'viewer.viewingOrganization.features.isEnabledBookingCalendarDayNotes',
  );

  return (
    <Container
      style={{
        justifyContent: responsive.mobile ? 'space-between' : 'center',
        minWidth: columnMinWidth,
      }}
    >
      <Content>
        {responsive.mobile && (
          <Touchable
            onPress={() =>
              navigateDate({
                date: Datetime.previousDay(calendarDay.date),
                selectedOrganizationSlug: form.values.selectedOrganizationSlug,
                navigator,
              })
            }
          >
            <Icon
              color={colors.gray.primary}
              size={Icon.Sizes.Large}
              source={Icon.ChevronLeft}
              style={{marginRight: 3}}
            />
            <TouchableText>
              {Datetime.convertToDisplayDate(Datetime.previousDay(calendarDay.date), 'M/D')}
            </TouchableText>
          </Touchable>
        )}
        <Column>
          <DayText style={{color: getFontColor({calendarDay})}}>
            {Datetime.convertToDisplayDate(calendarDay.date, 'ddd')}
          </DayText>
          <DateText style={{color: getFontColor({calendarDay})}}>
            {Datetime.convertToDisplayDate(calendarDay.date, 'M/D')}
          </DateText>
        </Column>
        {responsive.mobile && (
          <Touchable
            onPress={() =>
              navigateDate({
                date: Datetime.nextDay(calendarDay.date),
                selectedOrganizationSlug: form.values.selectedOrganizationSlug,
                navigator,
              })
            }
          >
            <TouchableText>
              {Datetime.convertToDisplayDate(Datetime.nextDay(calendarDay.date), 'M/D')}
            </TouchableText>
            <Icon
              color={colors.gray.primary}
              size={Icon.Sizes.Large}
              source={Icon.ChevronRight}
              style={{marginLeft: 3}}
            />
          </Touchable>
        )}
      </Content>
      <Space height={4} />
      <Content>
        <Column>
          <Row>
            <Icon color={colors.gray.primary} size={10} source={Icon.Truck} />
            <Space width={8} />
            <CountHeaderText>Trucks</CountHeaderText>
          </Row>
          <Space height={8} />
          <Row>
            <CountText color={colors.gray.secondary}>{calendarDay.trucksBooked} booked</CountText>
          </Row>
          <Space height={8} />
          <Row>
            <TextOverflowColumn>
              <CountText
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                color={TruckAndMoverAvailabilityStatusColors[calendarDay.trucksAvailabilityStatus]}
                numberOfLines={1}
              >
                {calendarDay.trucksAvailable}/{calendarDay.totalActiveTrucks} remaining
              </CountText>
            </TextOverflowColumn>
          </Row>
        </Column>
        <Column>
          <Row>
            <Icon color={colors.gray.primary} size={10} source={Icon.User} />
            <Space width={8} />
            <CountHeaderText>Movers</CountHeaderText>
          </Row>
          <Space height={8} />
          <Row>
            <CountText color={colors.gray.secondary}>{calendarDay.moversBooked} booked</CountText>
          </Row>
          <Space height={8} />
          <Row>
            <TextOverflowColumn>
              <CountText
                // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                color={TruckAndMoverAvailabilityStatusColors[calendarDay.moversAvailabilityStatus]}
                numberOfLines={1}
              >
                {calendarDay.moversAvailable}/{calendarDay.totalActiveMovers} remaining
              </CountText>
            </TextOverflowColumn>
          </Row>
        </Column>
      </Content>
      {isEnabledBookingCalendarDayNotes && (
        <Section>
          <Space height={8} />
          <Line />
          <Space height={8} />
          <BookingCalendarWeekDayNotes calendarDay={calendarDay} refetch={refetch} />
        </Section>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeekDayHeader.fragment = gql`
  ${BookingCalendarWeekDayNotes.fragment}
  fragment BookingCalendarWeekDayHeader on CalendarDayV4 {
    date
    moversAvailable
    moversAvailabilityStatus
    moversBooked
    totalActiveMovers
    trucksAvailable
    trucksAvailabilityStatus
    trucksBooked
    totalActiveTrucks
    ...BookingCalendarWeekDayNotes
  }
`;

BookingCalendarWeekDayHeader.query = gql`
  query BookingCalendarWeekDayHeader {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledBookingCalendarDayNotes: isEnabled(feature: "BOOKING_CALENDAR_DAY_NOTES")
        }
      }
    }
  }
`;

export default BookingCalendarWeekDayHeader;
