// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import DocumentTemplateCategory from '@shared/modules/Document/enums/DocumentTemplateCategory';
import DocumentTemplateV2Form from '@shared/modules/Document/forms/DocumentTemplateV2Form';
import useUpdateDocumentTemplateV2Mutation from '@shared/modules/Document/hooks/useUpdateDocumentTemplateV2Mutation';
import DefaultQuoteConfirmationDocumentTemplateFields from 'modules/Organization/Settings/Document/components/DefaultQuoteConfirmationDocumentTemplateFields';

const UpdateDocumentTemplateDrawer = ({
  updateDocumentTemplateDrawer,
  userId,
  documentTemplate,
  refetch,
  setIsModalOpen,
}: any) => {
  const handleClose = () => {
    setIsModalOpen(false);
    updateDocumentTemplateDrawer.handleClose();
  };
  const documentTemplateV2Form = DocumentTemplateV2Form.edit(documentTemplate, {userId});
  const {form, handleSubmit, submitting} = useUpdateDocumentTemplateV2Mutation({
    documentTemplateV2Form,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={updateDocumentTemplateDrawer.isOpen}
      handleClose={handleClose}
      headerText={'Update Document Template'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Update'}
    >
      <FieldInput
        {...form}
        index={0}
        label={'Name'}
        name={'documentTemplateV2Form.name'}
        input={{
          required: !_.get(form.values, 'documentTemplateV2Form.name'),
          placeholder: 'Enter a name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        label={'Description'}
        name={'documentTemplateV2Form.description'}
        input={{placeholder: 'Enter a description'}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        label={'Document Category'}
        name={`documentTemplateV2Form.category`}
        component={DropdownInput}
        input={{
          disabled: true,
          required: !_.get(form.values, `documentTemplateV2Form.category`),
          options: DocumentTemplateCategory.DOCUMENT_TEMPLATE_CATEGORY_OPTIONS,
          placeholder: 'Select a category',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
      {form.values.documentTemplateV2Form.category !== DocumentTemplateCategory.INVOICE && (
        <SwitchField
          index={3}
          form={form}
          field={'documentTemplateV2Form.shouldEmailDocumentToCustomer'}
          labelRight={'Email this document to customers at the end of a move'}
        />
      )}
      <Space height={16} />
      <DefaultQuoteConfirmationDocumentTemplateFields
        form={form}
        field={'documentTemplateV2Form'}
        isDefaultConfirmationDocumentTemplate={
          documentTemplate.isDefaultConfirmationDocumentTemplate
        }
        isDefaultQuoteDocumentTemplate={documentTemplate.isDefaultQuoteDocumentTemplate}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateDocumentTemplateDrawer.fragment = gql`
  ${DocumentTemplateV2Form.edit.fragment}
  fragment UpdateDocumentTemplateDrawer on DocumentTemplateV2 {
    id
    isDefaultConfirmationDocumentTemplate
    isDefaultQuoteDocumentTemplate
    organization {
      id
    }
    ...DocumentTemplateV2Form_edit
  }
`;

export default UpdateDocumentTemplateDrawer;
