// Libraries
import React from 'react';

// Supermove
import {Styled, SupermoveLogo} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const AvatarContainer = Styled.View`
  height: ${({
    // @ts-expect-error TS(2339): Property 'desktop' does not exist on type 'ThemePr... Remove this comment to see the full error message
    desktop,
  }) => (desktop ? '28' : '32')}px;
  min-width: ${({
    // @ts-expect-error TS(2339): Property 'desktop' does not exist on type 'ThemePr... Remove this comment to see the full error message
    desktop,
  }) => (desktop ? '28' : '32')}px;
  padding-horizontal: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isLogo' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLogo,
  }) => (isLogo ? colors.black : colors.gray.border)}
  border-radius: 4px;
`;

const AvatarText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.primary}
`;

type OwnProps = {
  user?: any;
  tooltipPlacement?: string;
  TextComponent?: any;
  logoSize?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UserAvatar.defaultProps;

// @ts-expect-error TS(7022): 'UserAvatar' implicitly has type 'any' because it ... Remove this comment to see the full error message
const UserAvatar = ({user, tooltipPlacement, style, TextComponent, logoSize}: Props) => {
  const responsive = useResponsive();
  return (
    <TextTooltip
      text={user ? user.fullName : 'Supermove'}
      placement={tooltipPlacement}
      isEnabledMobileToast={false}
    >
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <AvatarContainer style={style} isLogo={!user} {...responsive}>
        {user ? (
          <TextComponent responsive={responsive}>{user.initials}</TextComponent>
        ) : (
          <SupermoveLogo size={logoSize || (responsive.desktop ? 10 : 12)} />
        )}
      </AvatarContainer>
    </TextTooltip>
  );
};

UserAvatar.defaultProps = {
  user: null,
  tooltipPlacement: 'bottom',
  TextComponent: AvatarText,
  logoSize: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserAvatar.fragment = gql`
  fragment UserAvatar on User {
    id
    initials
    fullName
  }
`;

export default UserAvatar;
