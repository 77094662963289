// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Form, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {JobUsersList} from 'modules/App/Job/components';

import EditJobUsersTable from './EditJobUsersTable';

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray.tertiary};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.tertiary};
`;

const HeaderInfo = Styled.View`
  align-items: flex-end;
  justify-content: center;
  height: 48px;
`;

const SearchWrapper = Styled.View`
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const SearchInput = Styled.TextInput.H7`
  padding: 10px;
`;

const Text = Styled.H7`
  color: ${colors.gray.secondary};
`;

const ErrorMessage = Styled.H7`
  margin-bottom: 5px;
  color: ${colors.red.warning};
`;

const Indicator = Styled.Loading`
`;

const filterSchedules = ({searchQuery, schedules}: any) => {
  return _.sortBy(schedules, ['user.firstName', 'user.lastName']).filter((schedule) => {
    return User.getFullName(schedule.user).toLowerCase().indexOf(searchQuery.toLowerCase()) >= 0;
  });
};

const getDatetimeToQuery = ({date}: any) => {
  return Datetime.previousMonday(Datetime.fromDate(date));
};

const getDateToQuery = ({date}: any) => {
  return Datetime.toDate(getDatetimeToQuery({date}));
};

const Elements = ({loading, job, responsive, schedules, setFieldValue, values}: any) => (
  <React.Fragment>
    <Header>
      <JobUsersList
        job={job}
        onPressJobUser={(jobUser: any) => {
          const fullName = jobUser.user.firstName;
          if (values.searchQuery === fullName) {
            setFieldValue('searchQuery', '');
          } else {
            setFieldValue('searchQuery', fullName);
          }
        }}
      />
      {!responsive.mobile && (
        <HeaderInfo>
          <Text style={{marginBottom: 5}}>{job.crewStatus.status}</Text>
          {!!job.crewStatus.error && <ErrorMessage>{`${job.crewStatus.error}`}</ErrorMessage>}
        </HeaderInfo>
      )}
    </Header>
    <SearchWrapper>
      <SearchInput
        autoComplete={'OFF'}
        autoFocus
        name={'searchQuery'}
        placeholder={'Search for a member by name...'}
        value={values.searchQuery}
        onChangeText={(text: any) => setFieldValue('searchQuery', text)}
      />
    </SearchWrapper>
    <EditJobUsersTable
      loading={loading}
      date={job.startDate}
      job={job}
      schedules={filterSchedules({
        searchQuery: values.searchQuery,
        schedules,
      })}
      schedulingType={job.organization.settings.schedulingType}
      onEditSuccess={() => setFieldValue('searchQuery', '')}
    />
  </React.Fragment>
);

type OwnEditJobUsersFormProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'EditJobUsersFormProps' circularly refe... Remove this comment to see the full error message
type EditJobUsersFormProps = OwnEditJobUsersFormProps & typeof EditJobUsersForm.defaultProps;

// @ts-expect-error TS(7022): 'EditJobUsersForm' implicitly has type 'any' becau... Remove this comment to see the full error message
const EditJobUsersForm = ({job}: EditJobUsersFormProps) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Query
        fetchPolicy={'cache-and-network'}
        variables={{
          date: getDateToQuery({date: job.startDate}),
          jobUuid: job.uuid,
          organizationId: job.organization.id,
        }}
        query={EditJobUsersForm.query}
      >
        {({loading, data}: any) =>
          data ? (
            <Form initialValues={{searchQuery: ''}} onSubmit={() => {}}>
              {({values, setFieldValue}: any) => (
                <Elements
                  loading={loading}
                  job={data.job}
                  responsive={responsive}
                  schedules={data.employeeSchedules.schedules}
                  setFieldValue={setFieldValue}
                  values={values}
                />
              )}
            </Form>
          ) : (
            <Indicator size={'large'} color={colors.gray.secondary} />
          )
        }
      </Query>
    </Container>
  );
};

EditJobUsersForm.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobUsersForm.fragment = gql`
  fragment EditJobUsersForm on Job {
    id
    uuid
    startDate
    organization {
      id
    }
  }
`;

EditJobUsersForm.query = gql`
  ${EditJobUsersTable.fragment}
  ${JobUsersList.fragment}

  query EditJobUsersForm(
    $date: String!,
    $organizationId: Int!,
    $jobUuid: String!,
  ) {
    ${gql.query}
    employeeSchedules: employeeSchedulesByOrganizationId(
      date: $date,
      organizationId: $organizationId,
    ) {
      schedules: employeeSchedules {
        user {
          id
          firstName
          lastName
          position
        }
        scheduleDays: employeeScheduleDays {
          day {
            id
            value
          }
          userDay {
            id
            userId
            dayId
            scheduleStatus
          }
          jobs {
            id
            ...EditJobUsersTable
          }
        }
      }
    }
    job(uuid: $jobUuid) {
      id
      startDate
      crewSize
      crewStatus: employeeApprovalStatus {
        error
        status
      }
      jobUsers: activeJobUsers {
        jobId
        userId
      }
      organization {
        id
        settings {
          id
          schedulingType
        }
      }
      ...JobUsersList
    }
  }
`;

export default EditJobUsersForm;
