// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TaskForm from '@shared/modules/TaskManagement/forms/TaskForm';
import useCreateTaskMutation from '@shared/modules/TaskManagement/hooks/useCreateTaskMutation';
import Line from 'modules/App/components/Line';
import TaskDrawerFields from 'modules/TaskManagement/Tasks/components/TaskDrawerFields';

const IndexContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const TaskFieldsContainer = Styled.View`
  background-color: ${colors.gray.background};
`;

const FooterContainer = Styled.View`
  padding-top: 20px;
  padding-bottom: 24px;
  padding-horizontal: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Footer = ({
  handleClose,
  handleSubmit,
  submitting,
  isEnabledSave,
  isSubmitting,
  setIsSubmitting,
}: any) => {
  return (
    <FooterContainer>
      {/* @ts-expect-error TS(2322): Type '{ text: string; width: number; onPress: any;... Remove this comment to see the full error message */}
      <QuaternaryButton text={'Cancel'} width={96} onPress={handleClose} disabled={submitting} />
      <Space width={16} />
      <Button
        text={'Save'}
        width={96}
        onPress={() => {
          handleSubmit();
          setIsSubmitting(true);
        }}
        isSubmitting={submitting || isSubmitting}
        isDisabled={!isEnabledSave}
      />
    </FooterContainer>
  );
};

const NewTaskFields = ({
  organization,
  project,
  handleClose,
  refetch,
  hasChanges,
  setHasChanges,
  style,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const responsive = useResponsive();

  // A project is required for all tasks, but initially when opening these fields
  // there may or may not be a project that it comes with.
  const taskForm = TaskForm.new({
    organizationId: organization.id,
    projectId: _.get(project, 'id'),
    projectUuid: _.get(project, 'uuid'),
    projectBookedById: _.get(project, 'bookedById'),
    projectCoordinatedById: _.get(project, 'coordinatedById'),
  });
  const {form, handleSubmit, submitting} = useCreateTaskMutation({
    taskForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      setIsSubmitting(false);
      console.log({errors});
    },
  });

  useEffect(() => {
    if (form.dirty !== hasChanges) {
      setHasChanges(form.dirty);
    }
  }, [form.dirty, hasChanges, setHasChanges]);

  return (
    <React.Fragment>
      <TaskFieldsContainer style={style}>
        <ScrollView contentContainerStyle={{paddingHorizontal: 24}}>
          <Space height={16} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IndexContainer index={0}>
            <TaskDrawerFields form={form} field={'taskForm'} organization={organization} />
          </IndexContainer>
          <Space height={16} />
          {!responsive.desktop && (
            // @ts-expect-error TS(2769): No overload matches this call.
            <IndexContainer index={1}>
              <Button
                text={'Save'}
                isWidthOfContainer
                onPress={() => {
                  handleSubmit();
                  setIsSubmitting(true);
                }}
                isSubmitting={submitting || isSubmitting}
                isDisabled={!hasChanges}
                isResponsive
              />
              <Space height={16} />
            </IndexContainer>
          )}
        </ScrollView>
      </TaskFieldsContainer>
      {responsive.desktop && (
        <React.Fragment>
          <Line />
          <Footer
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
            isEnabledSave={hasChanges}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewTaskFields.fragment = gql`
  ${TaskDrawerFields.fragment}
  fragment NewTaskFields_Organization on Organization {
    id
    ...TaskDrawerFields
  }
  fragment NewTaskFields_Project on Project {
    id
    uuid
    bookedById
    coordinatedById
  }
`;

export default NewTaskFields;
