// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

const GetPositionWrapper = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const EditProjectBlockWrapper = ({index, layoutKey, handleSetPositionY, children}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <GetPositionWrapper index={index} key={layoutKey} onLayout={handleSetPositionY}>
      {children}
    </GetPositionWrapper>
  );
};

export default EditProjectBlockWrapper;
