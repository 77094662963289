// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useNavigationDOM, useMountEffect} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BillingTablesTable from 'modules/Organization/Settings/Billing/Tables/components/BillingTablesTable';
import UploadDataTableModal from 'modules/Organization/Settings/Billing/Tables/components/UploadDataTableModal';
import ViewDataTableModal from 'modules/Organization/Settings/Billing/Tables/components/ViewDataTableModal';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const ContentContainer = Styled.View`
  width: 1000;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const CreateButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${colors.blue.interactive};
  border-radius: 4px;
  width: fit-content;
`;

const CreateButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const OrganizationSettingsBillingSettingsPageContent = ({user, refetch}: any) => {
  const uploadDataTableModal = useModal({name: 'Upload Billing Table Modal'});
  const viewDataTableModal = useModal({name: 'View Data Table Modal'});
  const {navigator, params} = useNavigationDOM();
  useMountEffect(() => {
    if (params.dataTableUuid) {
      viewDataTableModal.handleOpen();
    }
  });

  const handleViewDataTableModalClose = () => {
    navigator.push('/settings/billing/tables');
    viewDataTableModal.handleClose();
  };

  return (
    <ContentContainer>
      <HeaderText>Billing Tables</HeaderText>
      <Space height={12} />
      <CreateButton onPress={uploadDataTableModal.handleOpen}>
        <Icon source={Icon.Plus} color={colors.white} size={12} />
        <Space width={6} />
        <CreateButtonText>Upload Billing Table</CreateButtonText>
      </CreateButton>
      <Space height={16} />
      <BillingTablesTable
        tables={user.viewingOrganization.dataTables}
        refetch={refetch}
        userId={user.id}
        handleOpenDataTableModal={viewDataTableModal.handleOpen}
      />
      <UploadDataTableModal
        key={uploadDataTableModal.key}
        isOpen={uploadDataTableModal.isOpen}
        handleClose={uploadDataTableModal.handleClose}
        organizationId={user.viewingOrganization.id}
        userId={user.id}
        refetch={refetch}
      />
      <ViewDataTableModal
        key={viewDataTableModal.key}
        isOpen={viewDataTableModal.isOpen}
        handleClose={handleViewDataTableModalClose}
        dataTableUuid={params.dataTableUuid}
      />
    </ContentContainer>
  );
};

const OrganizationSettingsBillingTablesPage = () => {
  const {data, loading, refetch} = useQuery(OrganizationSettingsBillingTablesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <OrganizationSettingsBillingSettingsPage>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <OrganizationSettingsBillingSettingsPageContent user={data.viewer} refetch={refetch} />
        )}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingTablesPage.query = gql`
  ${BillingTablesTable.fragment}

  query OrganizationSettingsBillingTablesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        dataTables{
          ...BillingTablesTable
        }
      }
    }
  }
`;

export default OrganizationSettingsBillingTablesPage;
