// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import TextTooltip from '@shared/design/components/TextTooltip';
import ClaimStatusColorDropdown from 'modules/Organization/Settings/Company/components/ClaimStatusColorDropdown';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const View = Styled.View``;

const ClaimStatusFields = ({claimStatus, form, field}: any) => {
  const disableIsDefaultSwitchField = claimStatus && claimStatus.isDefault;
  return (
    <FieldsContainer>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Name'}
        isRequired
        input={{
          autoFocus: true,
          placeholder: 'Enter a name',
        }}
      />
      <Space height={8} />
      <ClaimStatusColorDropdown
        form={form}
        field={`${field}.color`}
        index={1}
        label={'Color'}
        placeholder={'Select color'}
      />
      <Space height={8} />
      {disableIsDefaultSwitchField ? (
        <TextTooltip
          isEnabledMobileToast={false}
          placement={'top'}
          text={'There must be at least one default claim status.'}
        >
          <View>
            <SwitchField
              form={form}
              field={`${field}.isDefault`}
              disabled
              index={2}
              labelRight={'Set as default claim status when creating a new claim.'}
            />
          </View>
        </TextTooltip>
      ) : (
        <SwitchField
          form={form}
          field={`${field}.isDefault`}
          index={2}
          labelRight={'Set as default claim status when creating a new claim.'}
        />
      )}
    </FieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimStatusFields.fragment = gql`
  fragment ClaimStatusFields on ClaimStatus {
    id
    isDefault
  }
`;

export default ClaimStatusFields;
