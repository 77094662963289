// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import ErrorCatcher from '@shared/modules/Error/components/ErrorCatcher';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import Line from 'modules/App/components/Line';
import ProjectWidgetContent from 'modules/Project/V2/Show/components/ProjectWidgetContent';
import QuitEditingWidgetModal from 'modules/Project/V2/Show/components/widgets/QuitEditingWidgetModal';

const WidgetContentContainer = Styled.View`
  flex: 1;
`;

const ContentHeaderContainer = Styled.View`
  flex-direction: row;
  padding-horizontal: 16px;
  align-items: center;
  height: 60px;
`;

const SideContainer = Styled.View`
  width: 24px;
  align-items: center;
  justify-content: center;
`;

const ContentHeaderText = Styled.Text`
  ${Typography.Mobile.PageHeading}
`;

const IconButton = ({icon, onPress}: any) => {
  return (
    <TertiaryButton onPress={onPress} isHitSlop>
      <Icon source={icon} size={16} color={colors.gray.primary} />
    </TertiaryButton>
  );
};

const WidgetContentHeader = ({handleClose}: any) => {
  const {params} = useNavigationDOM();

  return (
    <ContentHeaderContainer>
      <SideContainer />
      <Space style={{flex: 1}} />
      <ContentHeaderText>{ProjectWidgetKind.getDisplay(params.widget) || ''}</ContentHeaderText>
      <Space style={{flex: 1}} />
      <SideContainer>
        <IconButton onPress={handleClose} icon={Icon.XmarkLarge} size={16} />
      </SideContainer>
    </ContentHeaderContainer>
  );
};

const ProjectWidgetsMobile = ({project, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const [isClosable, setIsClosable] = useState(true);
  const quitEditingModal = useModal({name: 'Quit Editing Modal'});

  return (
    <WidgetContentContainer>
      <WidgetContentHeader
        handleClose={() =>
          isClosable ? urlFilters.handleUpdate({widget: null}) : quitEditingModal.handleOpen()
        }
      />
      <Line />
      <ErrorCatcher
        ErrorComponent={() => (
          <ErrorState.Centered title={ProjectWidgetKind.getErrorTitle(params.widget)} />
        )}
      >
        <ScrollView contentContainerStyle={{flex: 1}}>
          <ProjectWidgetContent
            project={project}
            setIsClosable={setIsClosable}
            urlFilters={urlFilters}
          />
        </ScrollView>
      </ErrorCatcher>
      {/* TODO(dan) Will need to refactor this modal */}
      <QuitEditingWidgetModal
        quitEditingModal={quitEditingModal}
        handleCloseParent={() => ProjectWidgetContent.handleCloseWidget({urlFilters})}
      />
    </WidgetContentContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectWidgetsMobile.fragment = gql`
  ${ProjectWidgetContent.fragment}
  ${ProjectWidgetKind.getOrganizationWidgets.fragment}
  fragment ProjectWidgetsMobile on Project {
    id
    uuid
    organization {
      id
      ...ProjectWidgetKind_getOrganizationWidgets
    }
    ...ProjectWidgetContent
  }
`;

export default ProjectWidgetsMobile;
