// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {PhoneInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToast} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import ProjectClientsForm from '@shared/modules/Project/forms/ProjectClientsForm';
import useUpdateProjectClientsMutation from '@shared/modules/Project/hooks/useUpdateProjectClientsMutation';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';

const SectionLabel = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const FOCUS_FIELD = {
  PRIMARY_CLIENT_PHONE: 'primary-client-phone',
  BILLING_CLIENT_PHONE: 'billing-client-phone',
};

const FOCUS_INPUT = {
  PHONE: 'phone',
};

const handleToggleIsSameAsClient = ({form, field, project, isSame}: any) => {
  form.setFieldValue(`${field}.isSameBillingClient`, isSame);
  if (!isSame) {
    const hasDistinctBillingClient = project.billingClientId !== project.clientId;
    if (hasDistinctBillingClient) {
      form.setFieldValue(
        `${field}.billingClientForm`,
        ClientForm.toForm(ClientForm.edit(project.billingClient)),
      );
    } else {
      form.setFieldValue(
        `${field}.billingClientForm`,
        ClientForm.toForm(
          ClientForm.new({
            organizationId: project.organizationId,
            isOkayDuplicatePhoneNumber: true,
            isShowingName: true,
          }),
        ),
      );
    }
  }
};

const ClientNotes = ({form, field, responsive}: any) => {
  return (
    <React.Fragment>
      <FieldInput.LabelText isResponsive>Client Notes (internal)</FieldInput.LabelText>
      <Space height={responsive.desktop ? 4 : 8} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={`${field}.notes`}
        minHeight={76}
        isResponsive
        input={{
          style: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          placeholder: 'Enter client notes',
        }}
      />
    </React.Fragment>
  );
};

const ClientFields = ({form, field, project, responsive, focusInput}: any) => {
  const contactField = `${field}.primaryContactForm`;
  const {features} = project.organization;
  const isLastNameRequired = features.isEnabledProjectCustomerLastNameRequired;
  const isPhoneNumberRequired = features.isEnabledProjectCustomerPhoneNumberRequired;
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  return (
    <React.Fragment>
      <Checkbox
        label={'Add company name'}
        isChecked={isShowingName}
        handleToggle={() => {
          if (!isShowingName) {
            form.setFieldValue(`${field}.name`, '');
          }
          form.setFieldValue(`${field}.isShowingName`, !isShowingName);
        }}
        isMobile={!responsive.desktop}
      />
      <Space height={16} />
      {isShowingName && (
        <React.Fragment>
          <FieldInput.Memoized
            {...form}
            name={`${field}.name`}
            label={'Company Name'}
            isRequired
            input={{placeholder: 'Enter company name'}}
            isResponsive
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <FieldInput.Memoized
        {...form}
        name={`${contactField}.names.0`}
        label={'Contact First Name'}
        isRequired
        input={{placeholder: 'Enter first name'}}
        isResponsive
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        name={`${contactField}.names.1`}
        label={'Contact Last Name'}
        isRequired={isLastNameRequired}
        input={{placeholder: 'Enter last name'}}
        isResponsive
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        component={PhoneInput}
        name={`${contactField}.phoneNumber`}
        label={'Phone Number'}
        required={isPhoneNumberRequired}
        input={{
          placeholder: 'Enter phone number',
          setFieldValue: form.setFieldValue,
          autoFocus: focusInput === FOCUS_INPUT.PHONE,
        }}
        style={{flex: 1, width: '100%'}}
        isResponsive
      />
      <Space height={16} />
      <FieldInput.Memoized
        {...form}
        name={`${contactField}.email`}
        label={'Email'}
        input={{
          placeholder: 'Enter email',
        }}
        style={{flex: 1, width: '100%'}}
        isResponsive
      />
      <Space height={16} />
      <ClientNotes form={form} field={field} responsive={responsive} />
    </React.Fragment>
  );
};

const EditProjectClientsDrawerFields = ({form, field, project, focusField}: any) => {
  const responsive = useResponsive();
  const isSameAsClient = _.get(form.values, `${field}.isSameBillingClient`);

  return (
    <React.Fragment>
      <SectionLabel responsive={responsive}>Client Information</SectionLabel>
      <Space height={16} />
      <ClientFields
        form={form}
        field={`${field}.clientForm`}
        project={project}
        responsive={responsive}
        focusInput={focusField === FOCUS_FIELD.PRIMARY_CLIENT_PHONE ? FOCUS_INPUT.PHONE : null}
      />
      <Space height={32} />
      <SectionLabel responsive={responsive}>Billing Information</SectionLabel>
      <Space height={16} />
      <Checkbox
        label={'Same as client'}
        isChecked={isSameAsClient}
        handleToggle={(isSame) => handleToggleIsSameAsClient({form, field, project, isSame})}
        isMobile={!responsive.desktop}
      />
      <Space height={8} />
      {!isSameAsClient && (
        <ClientFields
          form={form}
          field={`${field}.billingClientForm`}
          project={project}
          responsive={responsive}
          focusInput={focusField === FOCUS_FIELD.BILLING_CLIENT_PHONE ? FOCUS_INPUT.PHONE : null}
        />
      )}
    </React.Fragment>
  );
};

const EditProjectClientsDrawer = ({isOpen, handleClose, project, focusField}: any) => {
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Client & Billing Information updated!',
  });

  const projectClientsForm = ProjectClientsForm.edit(project);
  const {form, handleSubmit, submitting} = useUpdateProjectClientsMutation({
    projectClientsForm,
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
    },
    onError: (error: any) => console.log({error}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Client & Billing Information'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
    >
      <EditProjectClientsDrawerFields
        form={form}
        field={'projectClientsForm'}
        project={project}
        focusField={focusField}
      />
    </DrawerWithAction>
  );
};

EditProjectClientsDrawer.FOCUS_FIELD = FOCUS_FIELD;

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectClientsDrawer.fragment = gql`
  ${ClientForm.edit.fragment}
  ${ProjectClientsForm.edit.fragment}
  fragment EditProjectClientsDrawer on Project {
    id
    clientId
    billingClientId
    organizationId
    organization {
      id
      features {
        isEnabledProjectCustomerLastNameRequired: isEnabled(
          feature: "PROJECT_CUSTOMER_LAST_NAME_REQUIRED"
        )
        isEnabledProjectCustomerPhoneNumberRequired: isEnabled(
          feature: "PROJECT_CUSTOMER_PHONE_NUMBER_REQUIRED"
        )
      }
    }
    billingClient {
      id
      ...ClientForm_edit
    }
    ...ProjectClientsForm_edit
  }
`;

export default EditProjectClientsDrawer;
