// Libraries
import React from 'react';

// Supermove
import {DateInput, MultiDropdownInput, Space, Styled, Popover} from '@supermove/components';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import CodatOperation from '@shared/modules/Integration/enums/CodatOperation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const PopoverContainer = Styled.Touchable`
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-top: 32px;
`;

const FilterContainer = Styled.View`
  flex-direction: row;
  zIndex: ${(props) => 1000 - (props as any).index}
`;

const FromDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={DateInput}
      name={`${name}.fromDate`}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {
          width: 125,
        },
      }}
    />
  );
};

const ToDateFilter = ({name, form, index}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      component={DateInput}
      name={`${name}.toDate`}
      label={'End Date'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        placeholder: 'MM/DD/YYYY',
        setFieldValue: form.setFieldValue,
        style: {
          width: 125,
        },
      }}
    />
  );
};

const ActionsMultiselect = ({index, name, form}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.actions`}
      label={'Actions'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: CodatOperation.getCodatOperationDropdownOptions(),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {
          minWidth: 200,
          maxWidth: 480,
        },
      }}
    />
  );
};

const TypeMultiselect = ({index, name, form}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.types`}
      label={'Types'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: CodatOperation.getCodatOperationTypeDropdownOptions(),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {
          minWidth: 200,
          maxWidth: 590,
        },
      }}
    />
  );
};

const StatusesMultiselect = ({index, name, form}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${name}.statuses`}
      label={'Statuses'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: CodatOperation.getCodatOperationStatusDropdownOptions(),
        placeholder: 'Select...',
        setFieldValue: form.setFieldValue,
        style: {
          minWidth: 200,
          maxWidth: 590,
        },
      }}
    />
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopoverContent = ({
  form,
}: any) => {
  const formName = 'filteredCodatPushOperationsForm';

  return (
    <ResponsivePopover.StaticContainer width={320} maxHeight={480}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filter</Header>
        <Space height={8} />
        <Space height={8} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <FilterContainer index={1}>
          <FromDateFilter name={formName} form={form} index={2} />
          <Space width={8} />
          <BodyText>-</BodyText>
          <Space width={8} />
          <ToDateFilter name={formName} form={form} index={3} />
        </FilterContainer>
        <Space height={8} />
        <ActionsMultiselect form={form} name={formName} index={4} />
        <Space height={8} />
        <TypeMultiselect form={form} name={formName} index={5} />
        <Space height={16} />
        <StatusesMultiselect form={form} name={formName} index={6} />
        <Space height={16} />
        <SecondaryButton
          text={'Clear Filters'}
          onPress={() => {
            form.setFieldValue(`${formName}.fromDate`, null);
            form.setFieldValue(`${formName}.toDate`, null);
            form.setFieldValue(`${formName}.actions`, null);
            form.setFieldValue(`${formName}.types`, null);
            form.setFieldValue(`${formName}.statuses`, null);
          }}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopover = ({
  popover,
  form,
}: any) => {
  return (
    <PopoverContainer>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={popover.isOpen}
        handleOpen={popover.handleOpen}
        handleClose={popover.handleClose}
        reference={popover.ref}
        offset={[0, 4]}
      >
        <OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopoverContent
          form={form}
        />
      </Popover>
    </PopoverContainer>
  );
};

export default OrganizationSettingsCompanyIntegrationsAccountingHistoryPageFiltersPopover;
