// Libraries
import React from 'react';

// Components
import {Loading, Mutation, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';

const SendRemindersToSetScheduleMutation = ({
  employeeId,
  organizationId,
  onSuccess,
  onError,
  children,
}: any) => (
  <Query query={SendRemindersToSetScheduleMutation.query}>
    {({loading, data}: any) => (
      <Loading loading={loading}>
        {() => (
          <Mutation
            // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
            variables={{
              employeeId,
              organizationId,
              creatorId: data.viewer.id,
            }}
            mutation={SendRemindersToSetScheduleMutation.mutation}
            onCompleted={({sendRemindersToSetSchedule: {textMessages, errors}}: any) => {
              if (textMessages) {
                onSuccess(textMessages);
              } else {
                onError(errors);
              }
            }}
          >
            {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
          </Mutation>
        )}
      </Loading>
    )}
  </Query>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SendRemindersToSetScheduleMutation.query = gql`
  query SendRemindersToSetScheduleMutation {
    ${gql.query}
    viewer {
      id
    }
  }
`;

SendRemindersToSetScheduleMutation.mutation = gql`
  mutation SendRemindersToSetScheduleMutation(
    $employeeId: Int!,
    $organizationId: Int!,
    $creatorId: Int!,
  ) {
    sendRemindersToSetSchedule(
      employeeId: $employeeId,
      organizationId: $organizationId,
      creatorId: $creatorId,
    ) {
      ${gql.errors}
      textMessages {
        id
      }
    }
  }
`;

export default SendRemindersToSetScheduleMutation;
