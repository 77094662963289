/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Json} from '@supermove/utils';

const ListItem = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 5px;
`;

const Index = Styled.H6`
  margin-right: 10px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const ItemLabel = Styled.H6`
  margin-right: 10px;
  width: 200px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Item = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Empty = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const SectionItem = Styled.View`
  padding-vertical: 10px;
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Value = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => fontWeight(vars.isSelected ? 700 : 300)}
  color: ${colors.gray.primary};
`;

const CellRow = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Text = Styled.H7`
`;

const DocumentAdditionalInfoFieldValues = ({
  additionalInfo,
  documentAdditionalInfo,
  document,
}: any) => {
  const metadata = Json.toObject(_.get(documentAdditionalInfo, 'metadata', '{}'));
  const columns = _.get(metadata, 'columns', []);

  switch (documentAdditionalInfo.kind) {
    case 'GRID':
      return (
        <React.Fragment>
          <CellRow>
            {columns.map((column: any, index: any) => {
              const isHidden = _.get(column, 'isHidden', false);

              if (isHidden) {
                return null;
              }

              return (
                <Cell key={index} style={{width: 225}}>
                  <Text>{column.name}</Text>
                </Cell>
              );
            })}
          </CellRow>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((row: any, rowIndex: any) => {
            return (
              <CellRow key={rowIndex}>
                {row.map((column: any, columnIndex: any) => {
                  const isHidden = _.get(columns, `${columnIndex}.isHidden`, false);

                  if (isHidden) {
                    return null;
                  }

                  return (
                    <Cell key={columnIndex} style={{width: 225}}>
                      <Text>{column}</Text>
                    </Cell>
                  );
                })}
              </CellRow>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((item: any, index: any) => (
            <ListItem key={index}>
              <Index>{`${index + 1}.`}</Index>
              <Item>{item}</Item>
            </ListItem>
          ))}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'LIST_V2':
      return (
        <React.Fragment>
          {_.zip(
            _.get(additionalInfo, documentAdditionalInfo.name, []),
            _.get(metadata, 'fields', []),
          ).map((combinedItem, index) => {
            const [item, field] = combinedItem;
            if (_.get(field, 'isHidden') === true) {
              return null;
            }
            return (
              <ListItem key={index}>
                <ItemLabel>{_.get(field, 'label')}</ItemLabel>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Item>{item}</Item>
              </ListItem>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS':
      return (
        <React.Fragment>
          {(Json.toObject(documentAdditionalInfo.values) as any).map((value: any, index: any) => {
            const isSelected = value === _.get(additionalInfo, documentAdditionalInfo.name);
            return (
              <ListItem key={index}>
                <Value
                  // @ts-expect-error
                  vars={{isSelected}}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    case 'RADIO_BUTTONS_WITH_TEXT_INPUTS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map((value: any, index: any) => {
            const [label, radioValue, textValue] = value;

            return (
              <React.Fragment key={index}>
                <SectionItem>
                  <Label>{label}</Label>
                  {radioValue ? <Item>{radioValue}</Item> : <Empty>None</Empty>}
                  {textValue ? <Text>{textValue}</Text> : null}
                </SectionItem>
              </React.Fragment>
            );
          })}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Responses</Item>
          )}
        </React.Fragment>
      );
    case 'SECTION_TEXT_AREAS':
      return (
        <React.Fragment>
          {_.get(additionalInfo, documentAdditionalInfo.name, []).map(
            // @ts-expect-error
            ([label, item], index: any) => (
              <React.Fragment key={index}>
                <SectionItem>
                  <Label>{label}</Label>
                  {item ? <Item>{item}</Item> : <Empty>None</Empty>}
                </SectionItem>
              </React.Fragment>
            ),
          )}
          {_.get(additionalInfo, documentAdditionalInfo.name, []).length === 0 && (
            <Item>No Items</Item>
          )}
        </React.Fragment>
      );
    case 'SELECT_MULTIPLE':
      return (
        <React.Fragment>
          {(Json.toObject(documentAdditionalInfo.values) as any).map((value: any, index: any) => {
            const selectedValues = _.get(additionalInfo, documentAdditionalInfo.name) || [];
            const isSelected = selectedValues.includes(value);
            return (
              <ListItem key={index}>
                <Value
                  // @ts-expect-error
                  vars={{isSelected}}
                >
                  {value}
                </Value>
              </ListItem>
            );
          })}
        </React.Fragment>
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentAdditionalInfoFieldValues.fragment = gql`
  fragment DocumentAdditionalInfoFieldValues_Document on Document {
    id
  }

  fragment DocumentAdditionalInfoFieldValues on DocumentAdditionalInfo {
    name
    kind
    values
    metadata
  }
`;

export default DocumentAdditionalInfoFieldValues;
