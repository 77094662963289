// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {Panel, SidebarPage, SplitPage} from 'modules/App/components';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import {DispatchJobsMap, DispatchJobsView} from 'modules/Dispatch/components';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const DispatchLivePage = () => {
  const date = Datetime.toMutationDate(Datetime.today);

  return (
    <SidebarPage
      fetchPolicy={'cache-and-network'}
      pollInterval={1000 * 60} // Refresh every 60 seconds.
      selected={'live'}
      variables={{
        date,
        kinds: ['MOVE'],
      }}
      query={DispatchLivePage.query}
    >
      {({responsive, navigator, data}: any) => (
        <Container style={{backgroundColor: 'transparent'}}>
          <StandardOfficeHeader title={'Live'} />
          <SplitPage
            split={
              <Panel>
                <DispatchJobsMap
                  jobs={data.calendarDay ? data.calendarDay.jobs : []}
                  organization={data.organization}
                />
              </Panel>
            }
          >
            <Container>
              <DispatchJobsView date={date} />
            </Container>
          </SplitPage>
        </Container>
      )}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchLivePage.query = gql`
  ${DispatchJobsMap.fragment}

  query DispatchLivePage($date: String!, $kinds: [String]!) {
    ${gql.query}
    calendarDay(date: $date, kinds: $kinds) {
      jobs {
        id
        ...DispatchJobsMap_Job
      }
    }
    organization {
      id
      ...DispatchJobsMap_Organization
    }
  }
`;

export default DispatchLivePage;
