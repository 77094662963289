// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import {Panel, SplitPage} from 'modules/App/components';
import {
  ScheduleUserDaysList,
  ViewScheduleMutation,
  WeekHeader,
} from 'modules/Employee/User/Schedule/components';
import EmployeePageV1 from 'modules/Employee/components/EmployeePageV1';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-vertical: ${(props) => ((props as any).mobile ? 16 : 32)}px;
  margin-horizontal: ${(props) => ((props as any).mobile ? 12 : 20)}px;
`;

const Title = Styled.Text`
  ${Typography.Heading5}
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
`;

const Weeks = Styled.View`
  margin-top: 20px;
`;

const Week = Styled.View`
`;

const getWeeks = ({schedule}: any) => {
  const numberOfWeeks = 9;
  const firstMonday = Datetime.previousMonday(Datetime.today);

  return _.range(numberOfWeeks).map((index) => {
    const date = Datetime.clone(firstMonday).add(index, 'weeks');
    const previousDate = Datetime.clone(date).subtract(1, 'day');
    const nextDate = Datetime.clone(date).add(1, 'week');

    const scheduleDays = schedule.scheduleDays.filter((scheduleDay: any) => {
      return Datetime.fromDate(scheduleDay.date).isBetween(previousDate, nextDate);
    });
    return {
      date,
      scheduleDays: _.orderBy(scheduleDays, ['date']),
    };
  });
};

const getDateToQuery = () => {
  return Datetime.toDate(Datetime.previousMonday(Datetime.today));
};

const EmployeeScheduleUserPage = () => (
  <EmployeePageV1
    variables={{
      date: getDateToQuery(),
    }}
    query={EmployeeScheduleUserPage.query}
    selected={'schedule'}
  >
    {({responsive, data, refetch}: any) => (
      <ViewScheduleMutation date={getDateToQuery()} userId={data.viewer.id}>
        <Container>
          <SplitPage split={<Panel />}>
            <ScrollView style={{flex: 1}}>
              <Content {...responsive}>
                <Title>{data.viewer.viewingOrganization.name}</Title>
                <Space height={12} />
                <Subtitle>
                  {`Welcome back, ${data.viewer.firstName}! Please view your schedule and set your availabilities.`}
                </Subtitle>
                <Weeks>
                  {getWeeks(data).map(({date, scheduleDays}, index) => (
                    <Week key={index}>
                      <WeekHeader date={date} user={data.viewer} refetch={refetch} />
                      <ScheduleUserDaysList
                        refetch={refetch}
                        scheduleDays={scheduleDays}
                        viewer={data.viewer}
                        moverSetScheduleMinDaysAhead={
                          data.viewer.viewingOrganization.moverSetScheduleMinDaysAhead
                        }
                      />
                    </Week>
                  ))}
                </Weeks>
              </Content>
            </ScrollView>
          </SplitPage>
        </Container>
      </ViewScheduleMutation>
    )}
  </EmployeePageV1>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeScheduleUserPage.query = gql`
  ${ScheduleUserDaysList.fragment}

  query EmployeeScheduleUserPage(
    $date: String!,
  ) {
    ${gql.query}
    viewer {
      id
      firstName
      viewingOrganization {
        id
        name
        moverSetScheduleMinDaysAhead
      }
    }
    schedule: employeeScheduleV2(
      date: $date,
    ) {
      scheduleDays: employeeScheduleDays {
        date
      }
      ...ScheduleUserDaysList
    }
  }
`;

export default EmployeeScheduleUserPage;
