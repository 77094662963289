// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {PaymentMethod} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const BillingProjectPaymentMethodsDropdown = ({form, bill}: any) => {
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={'beginPaymentForm.paymentMethodId'}
      label={'Payment Method'}
      isResponsive
      isRequired
      input={{
        showDescriptionInOption: true,
        options: PaymentMethod.getOfficePaymentMethodOptions({organization: bill.organization}),
        placeholder: 'Choose payment method',
        setFieldValue: form.setFieldValue,
        onChangeValue: (value: any, option: any) => {
          form.setFieldValue('beginPaymentForm.method', option.kind);
          form.setFieldValue('beginPaymentForm.paymentMethodId', value);
        },
        style: {width: '100%'},
        isPortaled: true,
      }}
    />
  );
};

BillingProjectPaymentMethodsDropdown.fragment = gql`
  ${PaymentMethod.getOfficePaymentMethodOptions.fragment}
  fragment BillingProjectPaymentMethodsDropdown on Bill {
    id
    organization {
      id
      ...PaymentMethod_getOfficePaymentMethodOptions
    }
  }
`;

export default BillingProjectPaymentMethodsDropdown;
