// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useModal, useQuery} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App

import useSendInvoiceForm from '@shared/modules/Email/hooks/useSendInvoiceForm';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import SendInvoiceReportMoveFields from 'modules/Job/ReportMove/Invoice/components/SendInvoiceReportMoveFields';
import SendInvoiceReportMoveForm from 'modules/Job/ReportMove/Invoice/components/SendInvoiceReportMoveForm';
import SendReportMoveSuccessModal from 'modules/Job/ReportMove/Send/components/SendReportMoveSuccessModal';
import JobPageWithNavigation from 'modules/Job/components/JobPageWithNavigation';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-top: ${(props) => ((props as any).mobile ? 20 : 60)}px;
  padding-bottom: 100px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H7`
  margin-top: 10px;
`;

const Row = Styled.View`
`;

const Footer = Styled.View`
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-vertical: ${(props) => ((props as any).mobile ? 10 : 15)};
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SendInvoiceReportMovePageContent = ({job, emailTemplate}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const {form, submitting, handleSubmit} = useSendInvoiceForm({
    job,
    subject: emailTemplate.subject,
    body: emailTemplate.html,
    attachmentIds: emailTemplate.emailTemplateAttachments.map(
      (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
    ),
    onSuccess: () => {
      handleOpen();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  const responsive = useResponsive();
  const fullName = job.project.billingClient.primaryContact.email
    ? job.project.billingClient.primaryContact.fullName
    : job.project.client.primaryContact.fullName;

  return (
    <Wrapper>
      <ScrollView style={{flex: 1}}>
        <Container>
          <Content {...responsive}>
            <Title>Prepare & send move invoice</Title>
            <Row>
              <Subtitle>
                {`Please draft an email to send to ${fullName}. The move invoice will be sent as an attachment.`}
              </Subtitle>
            </Row>
            <SendInvoiceReportMoveFields form={form} job={job} />
            <Space height={40} />
            <EmailAttachmentsSelector
              form={form}
              name={'sendEmailForJobForm'}
              project={job.project}
            />
          </Content>
        </Container>
      </ScrollView>
      <Footer {...responsive}>
        <Button
          loading={submitting}
          onPress={() => {
            handleSubmit();
          }}
        >
          <Text>Send Move Invoice</Text>
        </Button>
      </Footer>
      <SendReportMoveSuccessModal job={job} isOpen={isOpen} onClose={handleClose} />
    </Wrapper>
  );
};

const EmailTemplateLoader = ({job}: any) => {
  const {data} = useQuery(EmailTemplateLoader.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: job.project.id,
      emailTemplateKind: 'INVOICE_CREATE',
    },
  });

  if (data) {
    return (
      <SendInvoiceReportMovePageContent job={job} emailTemplate={data.hydratedEmailTemplate} />
    );
  }
  return null;
};

const SendInvoiceReportMovePage = ({
  match: {
    params: {uuid},
  },
}: any) => {
  return (
    <JobPageWithNavigation
      query={SendInvoiceReportMovePage.query}
      variables={{uuid}}
      title={({loading, data}: any) => (loading ? 'Loading...' : data.job.fullName)}
      subtitle={() => 'Send move invoice'}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({data}: any) => <EmailTemplateLoader job={data.job} />}
    </JobPageWithNavigation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendInvoiceReportMovePage.query = gql`
  ${EmailAttachmentsSelector.fragment}
  ${JobPageWithNavigation.fragment}
  ${SendInvoiceReportMoveForm.fragment}
  ${SendInvoiceReportMoveFields.fragment}
  ${SendReportMoveSuccessModal.fragment}

  query SendInvoiceReportMovePage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      fullName
      project {
        id
        billingClient {
          id
          primaryContact {
            id
            fullName
            email
          }
        }
        client {
          id
          primaryContact {
            id
            fullName
          }
        }
        ...EmailAttachmentsSelector
      }
      ...JobPageWithNavigation
      ...SendInvoiceReportMoveForm
      ...SendInvoiceReportMoveFields
      ...SendReportMoveSuccessModal
    }
  }
`;

EmailTemplateLoader.query = gql`
  query SendInvoiceReportMovePage($projectId: Int!, $emailTemplateKind: String!) {
    ${gql.query}
    hydratedEmailTemplate(projectId: $projectId, emailTemplateKind: $emailTemplateKind) {
      subject
      html
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
    }
  }
`;

export default SendInvoiceReportMovePage;
