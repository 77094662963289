/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const PreviousScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.secondary};
`;

const CurrentScreenText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.blue.accentDark};
`;

const PreviousScreen = ({children, onPress}: any) => {
  return (
    <React.Fragment>
      <Button onPress={onPress}>
        <PreviousScreenText numberOfLines={1}>{children}</PreviousScreenText>
      </Button>
      <Space width={12} />
      <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={14} />
      <Space width={12} />
    </React.Fragment>
  );
};

const CurrentScreen = ({children}: any) => {
  return <CurrentScreenText numberOfLines={1}>{children}</CurrentScreenText>;
};

const SettingsHeaderNavigation = ({children}: any) => {
  const responsive = useResponsive();
  return (
    <Row style={{flex: 1}}>
      {responsive.mobile && <Space width={12} />}
      {children}
    </Row>
  );
};

SettingsHeaderNavigation.PreviousScreen = PreviousScreen;
SettingsHeaderNavigation.CurrentScreen = CurrentScreen;

export default SettingsHeaderNavigation;
