// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import useUpdateBillItemTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillItemTypeMutation';
import SystemMessage from 'modules/App/components/SystemMessage';
import EditBillItemTypeModalFields from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeModalFields';

const getHeaderText = (category: any) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Edit Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Edit Fee';
  }
  return 'Edit Discount';
};

const BillTemplateEditBillItemTypeModal = ({form, field, isOpen, handleClose}: any) => {
  const billItemTypeForm = _.get(form.values, field);
  const {category} = billItemTypeForm;
  const {
    form: updateBillItemTypeForm,
    submitting,
    handleSubmit,
  } = useUpdateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      const updatedBillItemTypeForm = updateBillItemTypeForm.values.billItemTypeForm;
      form.setFieldValue(field, {
        ...updatedBillItemTypeForm,
        amount: Currency.convertToCents(updatedBillItemTypeForm.amount),
      });
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>{getHeaderText(category)}</SmallModal.HeaderText>
      <Space height={20} />
      <SystemMessage isWarning containerStyle={{margin: 0}}>
        {`WARNING: Updating this bill item will update it everywhere it is used in this ` +
          `billing library. You may want to create a new bill item to use instead.`}
      </SystemMessage>
      <Space height={20} />
      <EditBillItemTypeModalFields form={updateBillItemTypeForm} field={'billItemTypeForm'} />
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose} isDisabled={submitting}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default BillTemplateEditBillItemTypeModal;
