// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import BookingFiltersForm from '@shared/modules/Booking/forms/BookingFiltersForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import BookingCalendarWeekDay from 'modules/Calendar/Booking/components/BookingCalendarWeekDay';
import BookingCalendarWeekDayHeader from 'modules/Calendar/Booking/components/BookingCalendarWeekDayHeader';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Content = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  flex-direction: row;
  background-color: ${colors.white};
  box-shadow: 0 2px 2px rgba(194, 194, 194, 0.5);
  z-index: 1;
`;

const getDateToQuery = ({date}: any) => {
  return Datetime.toMutationDate(Datetime.fromDate(date));
};

const getDateRange = ({date, responsive}: any) => {
  return responsive.mobile
    ? [date]
    : Datetime.getDateRange(getDateToQuery({date}), 7).map((datetime: any) =>
        Datetime.toDate(datetime),
      );
};

const DayHeader = ({data, date, isEnabledBookingCalendarTruckAndMoverAvailability, form}: any) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return (
    <BookingCalendarWeekDayHeader
      calendarDay={calendarDay}
      isEnabledBookingCalendarTruckAndMoverAvailability={
        isEnabledBookingCalendarTruckAndMoverAvailability
      }
      form={form}
    />
  );
};

const DayColumn = ({
  data,
  date,
  isEnabledBookingCalendarTruckAndMoverAvailability,
  refetch,
}: any) => {
  const calendarDays = _.get(data, 'calendar.calendarDays');
  const calendarDay = _.find(calendarDays, (calendarDay) => {
    return _.get(calendarDay, 'date') === date;
  });

  return (
    <BookingCalendarWeekDay
      refetch={refetch}
      calendarDay={calendarDay}
      isEnabledBookingCalendarTruckAndMoverAvailability={
        isEnabledBookingCalendarTruckAndMoverAvailability
      }
    />
  );
};

const BookingCalendarDay = ({form}: any) => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();

  const salespersonIds = BookingFiltersForm.getSelectedSalespersonIds(form.values);
  const date = Datetime.toMutationDate(params.date || Datetime.toMutationDate(Datetime.today));
  const projectTypeIds = BookingFiltersForm.getSelectedProjectTypeIds(form.values);

  const {loading, data, refetch} = useQuery(BookingCalendarDay.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      date: getDateToQuery({date}),
      salespersonIds,
      projectTypeIds,
      organizationSlug: form.values.selectedOrganizationSlug,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  const {isEnabledBookingCalendarTruckAndMoverAvailability} =
    data.viewer.viewingOrganization.features;

  return (
    <Container>
      <Content>
        <Header>
          {getDateRange({date, responsive}).map((date: any) => (
            <DayHeader
              key={date}
              data={data}
              date={date}
              form={form}
              isEnabledBookingCalendarTruckAndMoverAvailability={
                isEnabledBookingCalendarTruckAndMoverAvailability
              }
            />
          ))}
        </Header>
        <ScrollView
          contentContainerStyle={{
            flexDirection: 'row',
            paddingBottom: 180,
          }}
          style={{flex: 1}}
        >
          {getDateRange({date, responsive}).map((date: any) => (
            <DayColumn
              refetch={refetch}
              key={date}
              data={data}
              date={date}
              isEnabledBookingCalendarTruckAndMoverAvailability={
                isEnabledBookingCalendarTruckAndMoverAvailability
              }
            />
          ))}
        </ScrollView>
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarDay.query = gql`
  ${BookingCalendarWeekDay.fragment}
  ${BookingCalendarWeekDayHeader.fragment}

  query BookingCalendarDay($date: String!, $salespersonIds: [Int]!, $projectTypeIds: [Int]!, $organizationSlug: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        features {
          isEnabledBookingCalendarTruckAndMoverAvailability: isEnabled(feature: "BOOKING_CALENDAR_TRUCK_AND_MOVER_AVAILABILITY")
        }
      }
    }
    calendar: calendarBookingDay(
      date: $date,
      salespersonIds: $salespersonIds,
      projectTypeIds: $projectTypeIds,
      organizationSlug: $organizationSlug,
    ) {
      calendarDays {
        ...BookingCalendarWeekDay
        ...BookingCalendarWeekDayHeader
      }
    }
  }
`;

export default BookingCalendarDay;
