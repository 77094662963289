// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {MoveUser} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Duration} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
`;

const Container = Styled.View`
  ${(props) => ((props as any).mobile ? 'flex: 1;' : '')}
  padding: 40px;
  background-color: ${colors.white};
`;

const Name = Styled.H4`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  text-align: center;
`;

const Content = Styled.View`
  margin-vertical: 20px;
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Actions = Styled.View`
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
`;

const DeleteButton = Styled.LoadingButton`
  height: 36px;
  width: 100px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Label = Styled.H6`
`;

const Buttons = Styled.View`
`;

const QuantityButton = Styled.Button`
  width: 100px;
  height: 30px;
  margin-top: 5px;
`;

const QuantityText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const TimePayable = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Adjustment = Styled.H7`
  color: ${colors.gray.secondary};
`;

const deleteMoveUser = ({
  parentForm,
  index: deleteIndex,
  handleClose,
  setIsDeleteMoveUserSubmitting,
}: any) => {
  setIsDeleteMoveUserSubmitting(true);
  const moveUsers = _.get(parentForm.values, `moveUserInputs`);
  const newMoveUsers = moveUsers.filter((moveUser: any, index: any) => index !== deleteIndex);

  const variables = {
    moveUserInputs: newMoveUsers.map((moveUser: any) => MoveUser.toMutation(moveUser)),
  };

  parentForm.handleSubmit({variables}).then(
    ({
      data: {
        response: {reportMove, errors},
      },
    }: any) => {
      setIsDeleteMoveUserSubmitting(false);
      if (reportMove) {
        handleClose();
      }
    },
  );
};

const updateMoveUser = ({parentForm, handleClose, setIsUpdateMoveUserSubmitting}: any) => {
  setIsUpdateMoveUserSubmitting(true);

  const moveUsers = _.get(parentForm.values, `moveUserInputs`);

  const variables = {
    moveUserInputs: moveUsers.map((moveUser: any) => MoveUser.toMutation(moveUser)),
  };

  parentForm.handleSubmit({variables}).then(
    ({
      data: {
        response: {reportMove, errors},
      },
    }: any) => {
      setIsUpdateMoveUserSubmitting(false);
      if (reportMove) {
        handleClose();
      }
    },
  );
};

const Fields = ({index, timePayable, parentForm}: any) => {
  const timePayableAdjustmentKey = `moveUserInputs.${index}.timePayableAdjustment`;
  const timePayableAdjustment = _.get(parentForm.values, timePayableAdjustmentKey, 0);

  return (
    <Content>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={0}>
        <Label>Work Timesheet Hours</Label>
        <TimePayable>{Duration.toDisplayTime(timePayable)}</TimePayable>
      </Section>
      <SectionSpace />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <Label>Adjusted crew member hours</Label>
        <TimePayable>{Duration.toDisplayTime(timePayable + timePayableAdjustment)}</TimePayable>
        <Adjustment>{`(${Duration.toDisplayTime(timePayableAdjustment)} adjustment)`}</Adjustment>
        <Buttons>
          <QuantityButton
            onPress={() =>
              parentForm.setFieldValue(
                `moveUserInputs.${index}.timePayableAdjustment`,
                timePayableAdjustment - 15,
              )
            }
          >
            <QuantityText>{'- 15 min'}</QuantityText>
          </QuantityButton>
          <QuantityButton
            onPress={() =>
              parentForm.setFieldValue(
                `moveUserInputs.${index}.timePayableAdjustment`,
                timePayableAdjustment + 15,
              )
            }
          >
            <QuantityText>{'+ 15 min'}</QuantityText>
          </QuantityButton>
          <QuantityButton
            onPress={() =>
              parentForm.setFieldValue(
                `moveUserInputs.${index}.timePayableAdjustment`,
                timePayable * -1,
              )
            }
          >
            <QuantityText>{'0 min'}</QuantityText>
          </QuantityButton>
        </Buttons>
      </Section>
    </Content>
  );
};

type OwnUpdateMoveUserModalProps = {
  index: number;
  timePayable: number;
  parentForm: any;
  trigger: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'UpdateMoveUserModalProps' circularly r... Remove this comment to see the full error message
type UpdateMoveUserModalProps = OwnUpdateMoveUserModalProps &
  typeof UpdateMoveUserModal.defaultProps;

// @ts-expect-error TS(7022): 'UpdateMoveUserModal' implicitly has type 'any' be... Remove this comment to see the full error message
const UpdateMoveUserModal = ({
  index,
  timePayable,
  parentForm,
  trigger,
}: UpdateMoveUserModalProps) => {
  const responsive = useResponsive();
  const [isUpdateMoveUserSubmitting, setIsUpdateMoveUserSubmitting] = useState(false);
  const [isDeleteMoveUserSubmitting, setIsDeleteMoveUserSubmitting] = useState(false);
  return (
    <Modal trigger={trigger}>
      {({handleClose}: any) => (
        <Overlay pointerEvents={'box-none'}>
          <Wrapper {...responsive}>
            <Container>
              <Name>Edit Crew Member Hours</Name>
              <Fields index={index} timePayable={timePayable} parentForm={parentForm} />
              <Actions>
                <DeleteButton
                  loading={isDeleteMoveUserSubmitting}
                  color={colors.red.warning}
                  onPress={() =>
                    deleteMoveUser({
                      parentForm,
                      index,
                      handleClose,
                      setIsDeleteMoveUserSubmitting,
                    })
                  }
                >
                  <Text>Delete</Text>
                </DeleteButton>
                <Button
                  text={'Save'}
                  width={100}
                  isSubmitting={isUpdateMoveUserSubmitting}
                  onPress={() =>
                    updateMoveUser({
                      parentForm,
                      handleClose,
                      setIsUpdateMoveUserSubmitting,
                    })
                  }
                />
              </Actions>
            </Container>
          </Wrapper>
        </Overlay>
      )}
    </Modal>
  );
};

UpdateMoveUserModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateMoveUserModal.fragment = gql`
  fragment UpdateMoveUserModal on LineItem {
    name
    price
    quantity
    kind
    unit
    total
  }
`;

export default UpdateMoveUserModal;
