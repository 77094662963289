// Libraries
import React from 'react';

// Supermove
import {MapView as SupermoveMapView} from '@supermove/components';
import {Location as LocationUtils} from '@supermove/utils';

type OwnProps = {
  hasExtraControls?: boolean;
  initialCenter?: any;
  locations?: any[];
  directionLocations?: any[];
  onRouteUpdate?: (...args: any[]) => any;
  renderMapAnnotations?: (...args: any[]) => any;
  shouldResetBounds?: boolean;
  streetViewPoint?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MapView.defaultProps;

// Components

/**
 * The map view component that should be used for all maps in Manager.
 */
// @ts-expect-error TS(7022): 'MapView' implicitly has type 'any' because it doe... Remove this comment to see the full error message
const MapView = ({
  hasExtraControls,
  initialCenter,
  locations,
  directionLocations,
  onRouteUpdate,
  renderMapAnnotations,
  shouldResetBounds,
  streetViewPoint,
  onReady,
}: Props) => {
  // We need to convert locations into memoized points using LocationUtils.create
  // to prevent re-rendering of the map.
  const points = locations.map((location: any) => LocationUtils.create(location)).filter(Boolean);

  const directionPoints = directionLocations
    .map((location: any) => LocationUtils.create(location))
    .filter(Boolean);

  return (
    // @ts-expect-error TS(2741): Property 'google' is missing in type '{ children: ... Remove this comment to see the full error message
    <SupermoveMapView
      isScrollEnabled={false}
      mapTypeControl={hasExtraControls}
      streetViewControl={hasExtraControls}
      bounds={points}
      shouldResetBounds={shouldResetBounds}
      directions={directionPoints}
      initialCenter={initialCenter}
      streetView={streetViewPoint}
      onRouteUpdate={onRouteUpdate}
      onReady={onReady}
      style={{
        flex: 1,
      }}
    >
      {(props) => <React.Fragment>{renderMapAnnotations(props)}</React.Fragment>}
    </SupermoveMapView>
  );
};

MapView.defaultProps = {
  hasExtraControls: false,
  initialCenter: {
    // Supermove's office ;)
    lat: 37.7749,
    lng: -122.4194,
  },
  locations: [],
  directionLocations: [],
  onRouteUpdate: () => {},
  renderMapAnnotations: () => null,
  shouldResetBounds: true,
  streetViewPoint: null,
};

export default MapView;
