// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import Page from 'modules/App/components/Page';
import ExpiredProposal from 'modules/Customer/Project/Confirmation/components/ExpiredProposal';

const ExpiredQuoteProjectPage = ({
  match: {
    params: {uuid},
  },
}: any) => (
  <Page fetchPolicy={'network-only'} query={ExpiredQuoteProjectPage.query} variables={{uuid}}>
    {({loading, data}: any) => (
      <Loading loading={loading}>
        {() => <ExpiredProposal kind={ConfirmationStepsKind.QUOTE} project={data.project} />}
      </Loading>
    )}
  </Page>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ExpiredQuoteProjectPage.query = gql`
  ${ExpiredProposal.fragment}
  query ExpiredQuoteProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      ...ExpiredProposal
    }
  }
`;

export default ExpiredQuoteProjectPage;
