// Libraries
import React from 'react';

// Supermove
import {Styled, DropdownInput} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

import FieldInput from '@shared/design/components/Field/FieldInput';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusBadge = Styled.View`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusBadgeContainer = Styled.View`
  align-items: flex-start;
`;

const StatusBadgeText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const ColorOption = ({value, label}: any) => {
  return (
    <Row>
      <StatusNameBadge value={value} label={label} />
    </Row>
  );
};

const StatusNameBadge = ({value, label}: any) => {
  const backgroundColor = colors.getBackgroundColor(value);
  const textColor = colors.getTextColor(value);
  return (
    <StatusBadgeContainer>
      <StatusBadge style={{backgroundColor}}>
        <StatusBadgeText numberOfLines={1} style={{color: textColor}}>
          {label}
        </StatusBadgeText>
      </StatusBadge>
    </StatusBadgeContainer>
  );
};

const ClaimStatusColorDropdown = ({form, field, label, index, placeholder, disabled}: any) => {
  return (
    <FieldInput
      {...form}
      label={label}
      name={field}
      component={DropdownInput}
      index={index}
      isRequired
      input={{
        options: colors.getDisplayColorOptions(),
        placeholder,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        disabled,
        components: {
          SingleValue: (props: any) => {
            return (
              <DropdownInput.SingleValueContainer {...props}>
                <ColorOption value={props.data.value} label={props.data.label} />
              </DropdownInput.SingleValueContainer>
            );
          },
          Option: (props: any) => {
            return (
              <DropdownInput.OptionContainer {...props}>
                <ColorOption
                  isSelected={props.isSelected}
                  value={props.data.value}
                  label={props.data.label}
                />
              </DropdownInput.OptionContainer>
            );
          },
        },
      }}
    />
  );
};

export default ClaimStatusColorDropdown;
