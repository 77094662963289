// Libraries
import React from 'react';

// App

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
`;

const Block = Styled.View`
`;

const Divider = Styled.View`
  width: 100%;
  margin-vertical: 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const Label = Styled.H7`
`;

const Value = Styled.View`
  justify-content: flex-end;
  height: 50px;
`;

const SignatureText = Styled.H2`
  font-family: 'Rouge Script';
  line-height: 32px;
`;

const DateText = Styled.H6`
`;

type OwnProps = {
  organization: any;
  signature?: any;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentOrganizationSignature.defaultProps;

// @ts-expect-error TS(7022): 'DocumentOrganizationSignature' implicitly has typ... Remove this comment to see the full error message
const DocumentOrganizationSignature = ({organization, signature, style}: Props) => (
  <Container style={style} data-avoid-page-break>
    <Block
      style={{
        flex: 1,
        paddingRight: 40,
      }}
    >
      <Value>
        <SignatureText>{organization.businessSignature}</SignatureText>
      </Value>
      <Divider />
      <Label>{organization.businessSignature}</Label>
    </Block>
    <Block style={{width: 100}}>
      <Value>
        <DateText>
          {signature
            ? Datetime.convertToDisplayDate(signature.date, 'M/D/YY')
            : Datetime.toDisplayDate(Datetime.today, 'M/D/YY')}
        </DateText>
      </Value>
      <Divider />
      <Label>Date</Label>
    </Block>
  </Container>
);

DocumentOrganizationSignature.defaultProps = {
  signature: null,
  style: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentOrganizationSignature.fragment = gql`
  fragment DocumentOrganizationSignature_Organization on Organization {
    id
    businessSignature
  }

  fragment DocumentOrganizationSignature_Signature on Signature {
    id
    date
  }
`;

export default DocumentOrganizationSignature;
