// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import SalesPayableItemsList from './SalesPayableItemsList';

const SalesPayableUserDayFields = ({payableUserDay, payableUserDayForm, form}: any) => {
  return (
    <SalesPayableItemsList
      userId={payableUserDay.user.id}
      payableItems={payableUserDay.payableItems}
      payableItemForms={payableUserDayForm.payableItemForms}
      form={form}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableUserDayFields.fragment = gql`
  ${SalesPayableItemsList.fragment}

  fragment SalesPayableUserDayFields on PayableUserDay {
    id
    user {
      id
    }
    payableItems {
      ...SalesPayableItemsList
    }
  }
`;

export default SalesPayableUserDayFields;
