// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import CostForm from '@shared/modules/Billing/forms/CostForm';
import Line from 'modules/App/components/Line';
import Switch from 'modules/App/components/Switch';
import CostSectionBillItems from 'modules/Cost/components/CostSectionBillItems';
import CostSectionCostItems from 'modules/Cost/components/CostSectionCostItems';

const Container = Styled.View`
`;

const IndexedContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const HeaderSubText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const CostSectionWrapper = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
  padding-bottom: 12px;
`;

const CostSectionContainer = Styled.View`
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const CostSectionRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
`;

const HeaderBadge = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
  background-color: ${colors.gray.background};
`;

const CostSectionHeaderText = Styled.Text`
  ${Typography.Label4}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 8px;
`;

const Cell = Styled.View`
  flex: ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: ${({
    // @ts-expect-error TS(2339): Property 'justify' does not exist on type 'ThemePr... Remove this comment to see the full error message
    justify,
  }) => justify};
  padding-horizontal: 8px;
  border-color: ${colors.gray.border};
`;

const CellLabel = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const COST_SECTION_FOOTER_COLUMNS = [
  {
    flex: 5,
    justify: 'flex-end',
    cellContent: () => {
      return <CellLabel>Revenue</CellLabel>;
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    style: {borderRightWidth: 1, marginRight: -2, height: 36},
    cellContent: ({form, field}: any) => {
      const totalRevenue = _.get(form.values, `${field}.totalRevenue`);
      return <CellText style={{paddingVertical: 10}}>{totalRevenue}</CellText>;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    cellContent: () => {
      return <CellLabel style={{paddingRight: 26}}>Cost</CellLabel>;
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    cellContent: ({form, field}: any) => {
      const totalCost = _.get(form.values, `${field}.totalCost`);
      return <CellText>{totalCost}</CellText>;
    },
  },
];

const CostSectionHeader = ({form, field}: any) => {
  const isCompensation = _.get(form.values, `${field}.isCompensation`);
  const name = _.get(form.values, `${field}.name`);
  const billDayValue = _.get(form.values, `${field}.billDayValue`);
  const isViewingAllBillItems = _.get(form.values, `${field}.isViewingAllBillItems`);
  return (
    <Container>
      <Space height={8} />
      <CostSectionRow>
        {billDayValue ? (
          <React.Fragment>
            <HeaderBadge>
              <CostSectionHeaderText>
                {Datetime.convertToDisplayDate(billDayValue, 'M/D')}
              </CostSectionHeaderText>
            </HeaderBadge>
            <Space width={4} />
            <HeaderBadge>
              <CostSectionHeaderText>{name}</CostSectionHeaderText>
            </HeaderBadge>
          </React.Fragment>
        ) : (
          <CostSectionHeaderText style={{paddingVertical: 2}}>{name}</CostSectionHeaderText>
        )}
        <Space style={{flex: 1}} />
        {isCompensation && (
          <Button
            onPress={() =>
              form.setFieldValue(`${field}.isViewingAllBillItems`, !isViewingAllBillItems)
            }
          >
            <Switch.Button
              isOn={isViewingAllBillItems}
              color={colors.blue.interactive}
              size={Switch.SIZE.SMALL}
            />
            <Space width={8} />
            <CostSectionHeaderText style={{color: colors.gray.secondary}}>
              View all billing items
            </CostSectionHeaderText>
          </Button>
        )}
      </CostSectionRow>
      <Space height={8} />
    </Container>
  );
};

const CostSectionFooter = ({form, field}: any) => {
  return (
    <FooterRow>
      {COST_SECTION_FOOTER_COLUMNS.map(({flex, justify, style, cellContent}, index) => (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Cell key={index} flex={flex} justify={justify} style={style}>
          {cellContent({form, field})}
        </Cell>
      ))}
    </FooterRow>
  );
};

const CostSectionsList = ({form, field, cost, costItemTypes}: any) => {
  const costSectionForms = _.get(form.values, `${field}.costSectionForms`);
  return (
    <React.Fragment>
      {costSectionForms.map((costSectionForm: any, index: any) => {
        return (
          // @ts-expect-error TS(2769): No overload matches this call.
          <CostSectionWrapper key={index} index={index}>
            <CostSectionContainer>
              <CostSectionHeader form={form} field={`${field}.costSectionForms.${index}`} />
              <Line />
              {!_.isEmpty(costSectionForm.billItemForms) && (
                // @ts-expect-error TS(2769): No overload matches this call.
                <IndexedContainer index={0}>
                  <CostSectionBillItems form={form} field={`${field}.costSectionForms.${index}`} />
                  <Line />
                </IndexedContainer>
              )}
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <IndexedContainer index={1}>
                <CostSectionCostItems
                  form={form}
                  field={`${field}.costSectionForms.${index}`}
                  costItemTypes={costItemTypes}
                  weight={cost.project.weight}
                  totalDistance={cost.project.totalDistance}
                  projectRevenue={cost.project.totalRevenue}
                  moverPositionId={_.get(cost, 'moverPosition.id')}
                />
                <Line />
              </IndexedContainer>
              <CostSectionFooter form={form} field={`${field}.costSectionForms.${index}`} />
            </CostSectionContainer>
          </CostSectionWrapper>
        );
      })}
    </React.Fragment>
  );
};

const EditCostCostItemsModalCostSectionsContent = ({form, field, cost, costItemTypes}: any) => {
  const name = _.get(form.values, `${field}.name`);
  const total = CostForm.computeTotal(_.get(form.values, field));

  return (
    <Container>
      <Space height={12} />
      <Row>
        <HeaderText>{name}</HeaderText>
        <Space style={{flex: 1}} />
        <HeaderSubText>Cost Total</HeaderSubText>
        <Space width={24} />
        <HeaderText>{Currency.display(total)}</HeaderText>
      </Row>
      <Space height={18} />
      <CostSectionsList form={form} field={field} cost={cost} costItemTypes={costItemTypes} />
    </Container>
  );
};

const EditCostCostItemsModalCostSections = ({form, field, cost}: any) => {
  const billingLibraryUuid = cost.project.projectType.billingLibrary.uuid;
  const {loading, data} = useQuery(EditCostCostItemsModalCostSections.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      billingLibraryUuid,
      category: cost.category,
    },
  });
  return (
    <Loading loading={loading}>
      {() => {
        return data ? (
          <EditCostCostItemsModalCostSectionsContent
            form={form}
            field={field}
            cost={cost}
            costItemTypes={data.billingLibrary.costItemTypes}
          />
        ) : null;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCostCostItemsModalCostSections.query = gql`
  ${CostSectionCostItems.fragment}

  query EditCostCostItemsModalCostSections($billingLibraryUuid: String!, $category: String!) {
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      costItemTypes(category: $category) {
        id
        ...CostSectionCostItems
      }
    }
  }
`;

EditCostCostItemsModalCostSections.fragment = gql`
  fragment EditCostCostItemsModalCostSections on Cost {
    id
    category
    moverPosition {
      id
    }
    project {
      id
      weight
      totalDistance
      totalRevenue
      projectType {
        id
        billingLibrary {
          id
          uuid
        }
      }
    }
  }
`;

export default EditCostCostItemsModalCostSections;
