// Libraries
import React from 'react';

// Supermove
import {Hover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {JobRequest, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
  flex-direction: row;
  width: 100%;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  padding: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Left = Styled.View`
  align-items: center;
  justify-content: center;
  width: 60px;
  margin-right: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const DayText = Styled.H8`
  color: ${colors.gray.secondary};
  ${fontWeight(700)}
  line-height: 12px;
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const DateText = Styled.H5`
  margin-bottom: 1px;
  ${fontWeight(700)}
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Info = Styled.View`
  flex: 1;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const StartTime = Styled.H7`
`;

const Location = Styled.H7`
`;

const CreatedAt = Styled.H7`
  color: ${colors.gray.secondary};
`;

const JobRequestItem = ({jobRequest}: any) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  return (
    <Hover>
      {({isHovered}: any) => (
        <Container style={{backgroundColor: isHovered ? colors.gray.background : colors.white}}>
          <Touchable onPress={() => navigator.push(`/jobs/requests/${jobRequest.uuid}`)}>
            <Row {...responsive}>
              <Left {...responsive}>
                <DayText>
                  {jobRequest.preferredDate
                    ? Datetime.convertToDisplayDate(jobRequest.preferredDate, 'ddd')
                    : ''}
                </DayText>
                <DateText>
                  {jobRequest.preferredDate
                    ? Datetime.convertToDisplayDate(jobRequest.preferredDate, 'M/D')
                    : '?'}
                </DateText>
              </Left>
              <Info>
                <Name>{User.getFullName(jobRequest.customer)}</Name>
                <StartTime numberOfLines={1}>
                  {jobRequest.preferredStartTime
                    ? `${Datetime.convertToDisplayTime(jobRequest.preferredStartTime)}, ${
                        jobRequest.moveSize
                      }`
                    : jobRequest.moveSize}
                </StartTime>
                <Location numberOfLines={1}>
                  {`${jobRequest.startLocation.city} to ${jobRequest.endLocation.city} ` +
                    `(${JobRequest.getTotalDistanceText(jobRequest)})`}
                </Location>
                <CreatedAt>
                  {`Created at ${Datetime.convertToDisplayDatetime(
                    jobRequest.createdAt,
                    'MM/D h:mm A',
                  )}`}
                </CreatedAt>
              </Info>
            </Row>
          </Touchable>
        </Container>
      )}
    </Hover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestItem.fragment = gql`
  fragment JobRequestItem on JobRequest {
    id
    uuid
    createdAt
    moveSize
    totalDistance
    preferredDate
    preferredStartTime
    customer {
      id
      firstName
      lastName
    }
    startLocation {
      id
      city
    }
    endLocation {
      id
      city
    }
  }
`;

export default JobRequestItem;
