// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Animation, colors} from '@supermove/styles';

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';
import ValueField from 'modules/Project/Billing/components/ValueField';

const ValuesGrid = Styled.View`
  display: grid;
  grid-template-columns: repeat(${(props) => (props as any).numColumns || ((props as any).mobile ? 1 : 3)}, minmax(0, 1fr));
  margin-right: -12px;
  margin-bottom: -8px;
`;

const ValueItem = Styled.View`
  margin-right: 12px;
  margin-bottom: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 8 : 16)}px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const Skeleton = Styled.View`
  ${Animation.pulse}
  height: 40px;
  flex: 1;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 8px;
  background-color: ${colors.gray.disabled};
`;

const SkeletonLoader = () => {
  const responsive = useResponsive();
  return (
    <ValuesGrid {...responsive}>
      {_.map(_.range(15), (i) => (
        <Skeleton key={i} />
      ))}
    </ValuesGrid>
  );
};

type OwnValueFieldsProps = {
  field: string;
  form: any;
  handleBlur?: (...args: any[]) => any;
  isOnCreate?: boolean;
  numColumns?: number;
};

// @ts-expect-error TS(2456): Type alias 'ValueFieldsProps' circularly reference... Remove this comment to see the full error message
type ValueFieldsProps = OwnValueFieldsProps & typeof ValueFields.defaultProps;

// @ts-expect-error TS(7022): 'ValueFields' implicitly has type 'any' because it... Remove this comment to see the full error message
const ValueFields = ({
  field,
  form,
  handleBlur,
  isOnCreate,
  filteredSectionId,
  isDisabled,
  isDisabledValuesHidden,
  numColumns,
}: ValueFieldsProps) => {
  const responsive = useResponsive();
  const valueForms = _.get(form.values, field);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ValuesGrid {...responsive} numColumns={numColumns}>
      {valueForms.reduce((filteredValueForms: any, valueForm: any, currentIndex: any) => {
        // Do not show dynamic variable format values since the display name would all show as the same thing
        const isDynamicVariableFormat = _.has(
          VariableFormat.DYNAMIC_VARIABLE_FORMATS,
          valueForm.variableFormat,
        );

        // CITY_VALUE_CURRENCY values typically have longer text
        // With the virtualized dropdown input we need to know the height ahead of time so must manually set it
        const optionHeight =
          valueForm.variableFormat === VariableFormat.CITY_VALUE_CURRENCY ? 50 : 35;

        const isValueFormDisabled = isDisabled || valueForm.isDisabled;
        const isDisabledValueHidden = isDisabledValuesHidden && isValueFormDisabled;

        if (
          valueForm.isEnabled &&
          !isDynamicVariableFormat &&
          (!isOnCreate || valueForm.isVisibleForCreateProject) &&
          // If we are filtering by section, only show the value forms that match the section
          (!filteredSectionId ||
            _.toString(valueForm.variableSectionId) === _.toString(filteredSectionId)) &&
          !isDisabledValueHidden
        ) {
          return [
            ...filteredValueForms,
            // @ts-expect-error TS(2769): No overload matches this call.
            <ValueItem key={currentIndex} index={currentIndex} responsive={responsive}>
              <ValueField
                optionHeight={responsive.desktop ? optionHeight : optionHeight + 12}
                isTruncatedLabel
                field={`${field}.${currentIndex}`}
                form={form}
                handleBlur={handleBlur}
                isDisabled={isDisabled}
                required={
                  isOnCreate &&
                  _.get(form.values, `${field}.${currentIndex}.isRequiredForCreateProject`)
                }
              />
            </ValueItem>,
          ];
        }
        return filteredValueForms;
      }, [])}
    </ValuesGrid>
  );
};

ValueFields.SkeletonLoader = SkeletonLoader;

ValueFields.defaultProps = {
  handleBlur: () => {},
  isOnCreate: false,
  numColumns: null,
};

export default ValueFields;
