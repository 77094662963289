// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Cost, CostCategory} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import CollapsibleSection from 'modules/App/components/CollapsibleSection';
import Line from 'modules/App/components/Line';
import CostChangesFoundModal from 'modules/Cost/components/CostChangesFoundModal';

const Container = Styled.View`
`;

const CostSectionButton = Styled.ButtonV2<{isSelected: boolean}>`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 8px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : 'transparent')}
`;

const CompensationUserButton = Styled.ButtonV2<{isSelected: boolean}>`
  flex-direction: row;
  padding-vertical: 8px;
  background-color: ${({isSelected}) => (isSelected ? colors.blue.accent : 'transparent')}
`;

const LabelText = Styled.Text<{color?: string}>`
  ${Typography.Label}
  color: ${({color}) => color || colors.gray.primary}
`;

const CompensationUserText = Styled.Text<{color: string}>`
  ${Typography.Body}
  color: ${({color}) => color || colors.gray.primary};
`;

const StatusBadge = Styled.View`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusBadgeContainer = Styled.View`
  align-items: flex-start;
`;

const StatusBadgeText = Styled.Text`
  ${Typography.MicroLabel}
`;

const Cell = Styled.View<{flex?: number; justify?: string; maxWidth?: number}>`
  flex: ${({flex}) => flex};
  justify-content: ${({justify}) => justify};
  width: ${({maxWidth}) => maxWidth || 'none'};
`;

const getCostsByDate = ({costs}: any) => {
  // Sort by date, then by employee name in alphabetical order
  return _.orderBy(
    costs,
    [
      (cost) => new Date(cost.date),
      (cost) => (cost.jobId ? 0 : 1), // Job goes first
      (cost) => Cost.getDisplayIdentifier(cost), // Cost's project/job identifier in alphabetical order
      (cost) => cost.displayName,
    ],
    ['asc', 'asc', 'asc', 'asc'],
  );
};

const EMPLOYEE_COMPENSATION_COLUMNS = [
  {
    maxWidth: 68,
    justify: 'center',
    cellContent: ({cost}: any) => {
      const isPaidOutOption = Cost.getIsPaidOutOption(cost);
      return (
        <StatusBadgeContainer>
          <StatusBadge style={{backgroundColor: isPaidOutOption.backgroundColor}}>
            <StatusBadgeText numberOfLines={1} style={{color: isPaidOutOption.textColor}}>
              {isPaidOutOption.label}
            </StatusBadgeText>
          </StatusBadge>
        </StatusBadgeContainer>
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-start',
    cellContent: ({cost, isSelected}: any) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <React.Fragment>
          {isSelected ? (
            <LabelText
              color={hasBalance ? colors.red.warning : colors.gray.primary}
              numberOfLines={2}
            >
              {cost.displayName}
            </LabelText>
          ) : (
            <CompensationUserText
              color={hasBalance ? colors.red.warning : colors.gray.primary}
              numberOfLines={2}
            >
              {cost.displayName}
            </CompensationUserText>
          )}
          <CompensationUserText numberOfLines={1} color={colors.gray.secondary}>
            {cost.moverPosition.name}
          </CompensationUserText>
        </React.Fragment>
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-start',
    cellContent: ({cost, isSelected}: any) => {
      const hasBalance = Cost.getHasBalanceDue(cost);
      return (
        <React.Fragment>
          {isSelected ? (
            <LabelText
              color={hasBalance ? colors.red.warning : colors.gray.primary}
              numberOfLines={2}
            >
              {Cost.getDisplayIdentifier(cost)}
            </LabelText>
          ) : (
            <CompensationUserText
              color={hasBalance ? colors.red.warning : colors.gray.primary}
              numberOfLines={2}
            >
              {Cost.getDisplayIdentifier(cost)}
            </CompensationUserText>
          )}
          <CompensationUserText numberOfLines={1} color={colors.gray.secondary}>
            {cost.date
              ? Datetime.convertToDisplayDate(cost.date, Datetime.DISPLAY_SHORT_DATE)
              : 'TBD'}
          </CompensationUserText>
        </React.Fragment>
      );
    },
  },
];

const CompensationUser = ({
  hasChange,
  handleSubmit,
  cost,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const costChangesFoundModal = useModal({name: 'Cost Changes Found Modal'});
  const isSelected = cost.id === currentCostId;
  return (
    <CompensationUserButton
      isSelected={isSelected}
      onPress={() => {
        if (hasChange) {
          return costChangesFoundModal.handleOpen();
        }
        return setCurrentCostId(cost.id);
      }}
    >
      {EMPLOYEE_COMPENSATION_COLUMNS.map((column, index) => {
        const isLastItem = index === EMPLOYEE_COMPENSATION_COLUMNS.length - 1;
        return (
          <React.Fragment>
            <Space width={index === 0 ? 16 : 8} />
            <Cell
              key={index}
              width={(column as any).width}
              maxWidth={column.maxWidth}
              flex={column.flex}
              justify={column.justify}
            >
              {column.cellContent({cost, isSelected})}
            </Cell>
            <Space width={isLastItem ? 16 : 8} />
          </React.Fragment>
        );
      })}
      <CostChangesFoundModal
        isOpen={costChangesFoundModal.isOpen}
        handleClose={costChangesFoundModal.handleClose}
        name={currentCostDisplayName}
        handleDecline={() => {
          setCurrentCostId(cost.id);
          costChangesFoundModal.handleClose();
        }}
        handleAccept={async () => {
          const {data} = await handleSubmit();
          if (!data.response.errors) {
            setCurrentCostId(cost.id);
          }
          costChangesFoundModal.handleClose();
        }}
      />
    </CompensationUserButton>
  );
};

const CompensationCostsByRole = ({
  hasChange,
  handleSubmit,
  costs,
  role,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const sortedCosts = getCostsByDate({costs});
  return (
    <React.Fragment>
      <CollapsibleSection headerColor={colors.gray.secondary} title={UserRole.getDisplayRole(role)}>
        <React.Fragment>
          {sortedCosts.map((cost, index) => {
            return (
              <CompensationUser
                key={cost.id}
                hasChange={hasChange}
                handleSubmit={handleSubmit}
                cost={cost}
                currentCostDisplayName={currentCostDisplayName}
                currentCostId={currentCostId}
                setCurrentCostId={setCurrentCostId}
              />
            );
          })}
        </React.Fragment>
      </CollapsibleSection>
    </React.Fragment>
  );
};

const CompensationCostsList = ({
  hasChange,
  handleSubmit,
  costs,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const costsByRole = _.groupBy(costs, (cost) => _.get(cost, 'moverPosition.role'));
  const displayUserRoles = [UserRole.EMPLOYEE, UserRole.CONTRACTOR, UserRole.SALESPERSON];
  return (
    <React.Fragment>
      {displayUserRoles.map((role, index) => {
        const roleCosts = _.get(costsByRole, role);
        if (roleCosts) {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Line />}
              <CompensationCostsByRole
                hasChange={hasChange}
                handleSubmit={handleSubmit}
                costs={roleCosts}
                role={role}
                currentCostDisplayName={currentCostDisplayName}
                currentCostId={currentCostId}
                setCurrentCostId={setCurrentCostId}
              />
            </React.Fragment>
          );
        }
        return null;
      })}
    </React.Fragment>
  );
};

const CostCategoryRow = ({
  hasChange,
  handleSubmit,
  costCategory,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const isCompensation = costCategory.kind === CostCategoryKind.COMPENSATION;
  const firstCostId = _.get(costCategory, 'costs.0.id');
  const isSelectedCategory = !isCompensation && firstCostId === currentCostId;
  const costChangesFoundModal = useModal({name: 'Confirm Save Cost Changes Modal'});
  const showFirstCostOfCostCategory = () => setCurrentCostId(firstCostId);
  return (
    <React.Fragment>
      {isCompensation ? (
        <CollapsibleSection title={CostCategory.getDisplayKind(costCategory)}>
          <CompensationCostsList
            hasChange={hasChange}
            handleSubmit={handleSubmit}
            costs={costCategory.costs}
            currentCostDisplayName={currentCostDisplayName}
            currentCostId={currentCostId}
            setCurrentCostId={setCurrentCostId}
          />
        </CollapsibleSection>
      ) : (
        <CostSectionButton
          isSelected={isSelectedCategory}
          onPress={() => {
            if (hasChange) {
              return costChangesFoundModal.handleOpen();
            }
            return showFirstCostOfCostCategory();
          }}
        >
          <LabelText>{CostCategory.getDisplayKind(costCategory)}</LabelText>
          <Space style={{flex: 1}} />
          <Icon source={Icon.ChevronRight} color={colors.gray.primary} size={12} />
        </CostSectionButton>
      )}
      <Line />
      <CostChangesFoundModal
        isOpen={costChangesFoundModal.isOpen}
        handleClose={costChangesFoundModal.handleClose}
        name={currentCostDisplayName}
        handleDecline={() => {
          showFirstCostOfCostCategory();
          costChangesFoundModal.handleClose();
        }}
        handleAccept={async () => {
          const {data} = await handleSubmit();
          if (!data.response.errors) {
            showFirstCostOfCostCategory();
          }
          costChangesFoundModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const EditCostCostItemsModalCostsV2 = ({
  hasChange,
  handleSubmit,
  aggregateCost,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  return (
    <Container>
      {aggregateCost.costCategories.map((costCategory: any, index: any) => {
        return (
          <CostCategoryRow
            key={index}
            hasChange={hasChange}
            handleSubmit={handleSubmit}
            costCategory={costCategory}
            currentCostDisplayName={currentCostDisplayName}
            currentCostId={currentCostId}
            setCurrentCostId={setCurrentCostId}
          />
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCostCostItemsModalCostsV2.fragment = gql`
  ${CostCategory.getDisplayKind.fragment}
  ${Cost.getHasBalanceDue.fragment}
  ${Cost.getIsPaidOutOption.fragment}
  ${Cost.getDisplayIdentifier.fragment}

  fragment EditCostCostItemsModalCostsV2 on AggregateCost {
    totalCost
    costCategories {
      costs {
        id
        displayName
        jobId
        moverPosition {
          id
          role
          name
        }
        ...Cost_getHasBalanceDue
        ...Cost_getRole
        ...Cost_getIsPaidOutOption
        ...Cost_getDisplayIdentifier
      }
      ...CostCategory_getDisplayKind
    }
  }
`;

export default EditCostCostItemsModalCostsV2;
