// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import RadioButton from '@shared/design/components/RadioButton';
import TextTooltip from '@shared/design/components/TextTooltip';
import ProjectStatus, {ProjectStatusType} from '@shared/modules/Project/enums/ProjectStatus';
import CrewVisibleJobsForMover, {
  CrewVisibleJobsForMoverType,
} from '@shared/modules/Settings/enums/CrewVisibleJobsForMover';

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
  width: 16px;
  justify-content: center;
`;

const CrewVisibleJobsPanelEdit = ({form}: {form: any}) => {
  const projectStatusesField = 'settingsForm.crewVisibleJobsByProjectStatus';
  const moverVisibleJobsField = 'settingsForm.crewVisibleJobsForMover';
  const projectStatuses = _.get(form.values, projectStatusesField);
  const moverVisibleJobs = _.get(form.values, moverVisibleJobsField);
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <Row>
        <EditPanel.LabelText responsive={responsive}>Assigned Jobs</EditPanel.LabelText>
        <Space width={4} />
        <TextTooltip
          placement={'top'}
          text={'Note: If tablet pins are set per mover, they will only see their own jobs.'}
          toastButtonStyle={{justifyContent: 'center'}}
        >
          <IconContainer>
            <Icon color={colors.gray.secondary} size={Icon.Sizes.Small} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </Row>
      <Space height={8} />
      <RadioButton
        isOn={moverVisibleJobs === CrewVisibleJobsForMover.INDIVIDUAL_MOVER_ONLY}
        label={CrewVisibleJobsForMover.getLabel(
          CrewVisibleJobsForMover.INDIVIDUAL_MOVER_ONLY as CrewVisibleJobsForMoverType,
        )}
        onPress={() => {
          form.setFieldValue(moverVisibleJobsField, CrewVisibleJobsForMover.INDIVIDUAL_MOVER_ONLY);
        }}
      />
      <Space height={8} />
      <RadioButton
        isOn={moverVisibleJobs === CrewVisibleJobsForMover.ALL_ORGANIZATION_JOBS}
        label={CrewVisibleJobsForMover.getLabel(
          CrewVisibleJobsForMover.ALL_ORGANIZATION_JOBS as CrewVisibleJobsForMoverType,
        )}
        onPress={() => {
          form.setFieldValue(moverVisibleJobsField, CrewVisibleJobsForMover.ALL_ORGANIZATION_JOBS);
        }}
      />
      <Space height={16} />
      <EditPanel.LabelText responsive={responsive}>Project Statuses</EditPanel.LabelText>
      <Space height={8} />
      {([ProjectStatus.LEAD, ProjectStatus.HOLD, ProjectStatus.BOOKED] as ProjectStatusType[]).map(
        (status: ProjectStatusType, index: number) => {
          return (
            <React.Fragment key={index}>
              {index > 0 && <Space height={8} />}
              <Checkbox
                isChecked={_.includes(projectStatuses, status)}
                handleToggle={() => {
                  const existingStatuses = [...projectStatuses];
                  const updatedStatuses = _.xor(existingStatuses, [status]);
                  form.setFieldValue(projectStatusesField, updatedStatuses);
                }}
                label={ProjectStatus.getStatusLabel(status)}
                isResponsive
              />
            </React.Fragment>
          );
        },
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewVisibleJobsPanelEdit.fragment = gql`
  fragment CrewVisibleJobsPanelEdit on Organization {
    id
    settings {
      id
      crewVisibleJobsByProjectStatus
      crewVisibleJobsForMover
    }
  }
`;

export default CrewVisibleJobsPanelEdit;
