// Libraries
import React, {useState} from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';

const ShowPasscodeButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  max-width: 100%
`;

const PasscodeCell = ({passcode}: any) => {
  const [isTextHidden, setIsTextHidden] = useState(true);

  return (
    <ShowPasscodeButton onPress={() => setIsTextHidden(!isTextHidden)}>
      <Table.CellText style={{wordBreak: 'break-all'}}>
        {isTextHidden ? '••••' : passcode}
      </Table.CellText>
      <Space width={8} />
      <Icon
        source={isTextHidden ? Icon.Eye : Icon.EyeSlash}
        color={colors.blue.interactive}
        size={16}
      />
    </ShowPasscodeButton>
  );
};

export default PasscodeCell;
