// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useModal, useNavigationDOM, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import ProjectTypeTab from '@shared/modules/Project/enums/ProjectTypeTab';
import JobTypeVariableSectionForm from '@shared/modules/Variable/forms/JobTypeVariableSectionForm';
import JobTypeVariableSectionsForm from '@shared/modules/Variable/forms/JobTypeVariableSectionsForm';
import useUpsertJobTypeVariableSectionsMutation from '@shared/modules/Variable/hooks/useUpsertJobTypeVariableSectionsMutation';
import DeleteDefaultVariableSectionWarningModal from 'modules/Organization/Settings/ProjectTypes/components/DeleteDefaultVariableSectionWarningModal';
import DeleteVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/DeleteVariableSectionModal';
import EditVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/EditVariableSectionModal';
import MoveToVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/MoveToVariableSectionModal';
import NewVariableSectionModal from 'modules/Organization/Settings/ProjectTypes/components/NewVariableSectionModal';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';
import VariableProjectAndJobTypeList from 'modules/Organization/Settings/ProjectTypes/components/VariableProjectAndJobTypeList';
import VariableSectionsTable from 'modules/Organization/Settings/ProjectTypes/components/VariableSectionsTable';

const PageContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const LeftHeaderColumn = Styled.View`
`;

const RightHeaderColumn = Styled.View`
  align-items: flex-end;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const LeftColumn = Styled.View`
  align-items: center;
`;

const RightColumn = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const LastSavedText = Styled.Text`
  ${Typography.Micro}
`;

const ManageVariablesButton = Styled.ButtonV2`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
`;

const ManageVariablesButtonText = Styled.Text`
  ${Typography.Link}
`;

const Header = ({jobType, isEdit, setIsEdit, handleSubmit, isSubmitting, handleResetForm}: any) => {
  const lastUpdatedAt = Datetime.fromDatetime(jobType.variableSectionsUpdatedAt).format(
    Datetime.DISPLAY_DATETIME,
  );

  return (
    <HeaderContainer>
      <LeftHeaderColumn>
        <HeaderText>Configure Variables</HeaderText>
        <Space height={12} />
        <DescriptionText>
          {`Configure variables for ${jobType.name}. Supermove will automatically apply these variables to your projects when they are created.`}
        </DescriptionText>
      </LeftHeaderColumn>
      <RightHeaderColumn>
        {isEdit ? (
          <ButtonContainer>
            <SecondaryButton
              onPress={() => {
                handleResetForm();
                setIsEdit(false);
              }}
              text={'Cancel'}
            />
            <Space width={16} />
            <Button onPress={handleSubmit} isSubmitting={isSubmitting} text={'Save Changes'} />
          </ButtonContainer>
        ) : (
          <Button onPress={() => setIsEdit(true)} text={'Edit Variables'} iconLeft={Icon.Pen} />
        )}
        <Space height={8} />
        <LastSavedText>{`Last Updated: ${lastUpdatedAt}`}</LastSavedText>
      </RightHeaderColumn>
    </HeaderContainer>
  );
};

const NewJobTypeVariableSectionModal = ({newVariableSectionModal, parentForm, jobTypeId}: any) => {
  const jobTypeVariableSectionForm = JobTypeVariableSectionForm.new(jobTypeId);
  const form = useForm({
    initialValues: {
      jobTypeVariableSectionForm: JobTypeVariableSectionForm.toForm(jobTypeVariableSectionForm),
    },
  });
  return (
    <NewVariableSectionModal
      key={newVariableSectionModal.key}
      isOpen={newVariableSectionModal.isOpen}
      handleClose={newVariableSectionModal.handleClose}
      parentForm={parentForm}
      form={form}
      field={'jobTypeVariableSectionForm'}
      sectionFormsField={'jobTypeVariableSectionsForm.jobTypeVariableSectionForms'}
    />
  );
};

const EditJobTypeVariableSectionModal = ({
  editVariableSectionModal,
  parentForm,
  variableSectionFormField,
}: any) => {
  // create a copy of the section form object
  // this allows us to make edits without alterting the parent form directly
  const jobTypeVariableSectionForm = {..._.get(parentForm.values, variableSectionFormField)};
  const form = useForm({
    initialValues: {
      jobTypeVariableSectionForm,
    },
  });
  return (
    <EditVariableSectionModal
      key={editVariableSectionModal.key}
      isOpen={editVariableSectionModal.isOpen}
      handleClose={editVariableSectionModal.handleClose}
      parentForm={parentForm}
      form={form}
      field={'jobTypeVariableSectionForm'}
      sectionFormsField={'jobTypeVariableSectionsForm.jobTypeVariableSectionForms'}
      variableSectionFormField={variableSectionFormField}
    />
  );
};

const DeleteJobTypeVariableSectionModal = ({
  deleteVariableSectionModal,
  form,
  variableSectionFormField,
}: any) => {
  const handleDelete = ({variableDestinationField}: any) => {
    JobTypeVariableSectionsForm.removeVariableSection({
      form,
      sectionFieldToRemove: variableSectionFormField,
      destinationField: variableDestinationField,
    });
    deleteVariableSectionModal.handleClose();
  };
  return (
    <DeleteVariableSectionModal
      key={deleteVariableSectionModal.key}
      isOpen={deleteVariableSectionModal.isOpen}
      handleClose={deleteVariableSectionModal.handleClose}
      parentForm={form}
      sectionFormsField={'jobTypeVariableSectionsForm.jobTypeVariableSectionForms'}
      variableSectionFormField={variableSectionFormField}
      handleDelete={handleDelete}
    />
  );
};

const MoveToJobTypeVariableSectionModal = ({moveToVariableSectionModal, form}: any) => {
  const handleMove = ({variableDestinationField}: any) => {
    const variableIdsToMove = _.get(form.values, 'jobTypeVariableSectionsForm.selectedVariableIds');
    JobTypeVariableSectionsForm.moveVariablesToSection({
      form,
      variableIdsToMove,
      destinationField: variableDestinationField,
    });
    moveToVariableSectionModal.handleClose();
    form.setFieldValue('jobTypeVariableSectionsForm.selectedVariableIds', []);
  };
  return (
    <MoveToVariableSectionModal
      key={moveToVariableSectionModal.key}
      isOpen={moveToVariableSectionModal.isOpen}
      handleClose={moveToVariableSectionModal.handleClose}
      form={form}
      sectionFormsField={'jobTypeVariableSectionsForm.jobTypeVariableSectionForms'}
      handleSubmit={handleMove}
    />
  );
};

const JobTypeVariableSettings = ({jobType, refetch}: any) => {
  const {navigator} = useNavigationDOM();
  const newVariableSectionModal = useModal({name: 'New Variable Section Modal'});
  const editVariableSectionModal = useModal({name: 'Edit Variable Section Modal'});
  const deleteVariableSectionModal = useModal({name: 'Delete Variable Section Modal'});
  const deleteDefaultVariableSectionWarningModal = useModal({
    name: 'Delete Default Variable Section Warning Modal',
  });
  const moveToVariableSectionModal = useModal({name: 'Move To Variable Section Modal'});
  const [variableSectionFormField, setVariableSectionFormField] = useState('');

  const jobTypeVariableSectionsForm = JobTypeVariableSectionsForm.edit(jobType);
  const {form, handleSubmit, submitting} = useUpsertJobTypeVariableSectionsMutation({
    jobTypeVariableSectionsForm,
    onSuccess: () => {
      setIsEdit(false);
      refetch();
    },
    onError: () => {},
  });

  const variableSectionsFormField = 'jobTypeVariableSectionsForm';
  const variableSectionsForm = _.get(form.values, variableSectionsFormField);
  const isEdit = _.get(variableSectionsForm, 'isEdit');
  const setIsEdit = (isEdit: any) =>
    form.setFieldValue(`${variableSectionsFormField}.isEdit`, isEdit);

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader
        projectType={jobType.projectType}
        selectedTab={ProjectTypeTab.VARIABLES}
      />
      <ContentContainer>
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
          <ScrollView contentContainerStyle={{padding: 24, flex: 1}}>
            <Header
              jobType={jobType}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              handleSubmit={handleSubmit}
              isSubmitting={submitting}
              handleResetForm={() => form.resetForm()}
            />
            <Space height={24} />
            <Container>
              <LeftColumn>
                <VariableProjectAndJobTypeList projectType={jobType.projectType} />
                <Space height={24} />
                <ManageVariablesButton
                  onPress={() => navigator.push('/settings/billing/variables')}
                >
                  <Icon size={12} source={Icon.ExternalLinkAlt} color={colors.blue.interactive} />
                  <Space width={8} />
                  <ManageVariablesButtonText>Manage Variables</ManageVariablesButtonText>
                </ManageVariablesButton>
                <Space height={24} />
              </LeftColumn>
              <Space width={24} />
              <RightColumn>
                <VariableSectionsTable
                  title={jobType.name}
                  refetch={refetch}
                  form={form}
                  variableSectionsForm={variableSectionsForm}
                  variableSectionsFormField={variableSectionsFormField}
                  variableSectionFormsField={'jobTypeVariableSectionForms'}
                  variableFormsField={'jobTypeVariableForms'}
                  handleNewSection={newVariableSectionModal.handleOpen}
                  handleEditSection={(variableSectionFormField: any) => {
                    setVariableSectionFormField(variableSectionFormField);
                    editVariableSectionModal.handleOpen();
                  }}
                  handleDeleteSection={(variableSectionFormField: any) => {
                    setVariableSectionFormField(variableSectionFormField);
                    const variableSectionToDelete = _.get(form.values, variableSectionFormField);
                    const isDefaultSection = _.get(variableSectionToDelete, 'isDefault');
                    if (isDefaultSection) {
                      deleteDefaultVariableSectionWarningModal.handleOpen();
                    } else {
                      deleteVariableSectionModal.handleOpen();
                    }
                  }}
                  handleMoveTo={moveToVariableSectionModal.handleOpen}
                  isEnabledTbdBillItems={jobType.organization.features.isEnabledTbdBillItems}
                />
                {newVariableSectionModal.isOpen && (
                  <NewJobTypeVariableSectionModal
                    newVariableSectionModal={newVariableSectionModal}
                    parentForm={form}
                    jobTypeId={jobType.id}
                  />
                )}
                {editVariableSectionModal.isOpen && (
                  <EditJobTypeVariableSectionModal
                    editVariableSectionModal={editVariableSectionModal}
                    parentForm={form}
                    variableSectionFormField={variableSectionFormField}
                  />
                )}
                <DeleteDefaultVariableSectionWarningModal
                  key={deleteDefaultVariableSectionWarningModal.key}
                  isOpen={deleteDefaultVariableSectionWarningModal.isOpen}
                  handleClose={deleteDefaultVariableSectionWarningModal.handleClose}
                />
                <DeleteJobTypeVariableSectionModal
                  deleteVariableSectionModal={deleteVariableSectionModal}
                  form={form}
                  variableSectionFormField={variableSectionFormField}
                />
                <MoveToJobTypeVariableSectionModal
                  moveToVariableSectionModal={moveToVariableSectionModal}
                  form={form}
                />
              </RightColumn>
            </Container>
          </ScrollView>
        </ScrollView>
        <Space height={48} />
      </ContentContainer>
    </PageContainer>
  );
};

JobTypeVariableSettings.fragment = gql`
  ${JobTypeVariableSectionsForm.edit.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  ${VariableProjectAndJobTypeList.fragment}

  fragment JobTypeVariableSettings on JobType {
    id
    name
    variableSectionsUpdatedAt
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
    projectType {
      id
      ...ProjectTypeSettingsPageHeader
      ...VariableProjectAndJobTypeList
    }
    ...JobTypeVariableSectionsForm_edit
  }
`;

export default JobTypeVariableSettings;
