// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Text = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? Typography.Responsive.Body : Typography.Responsive.Micro)}
  ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? `color: ${colors.gray.secondary};` : '')}
`;

const InfoText = ({icon, value}: any) => {
  const responsive = useResponsive();
  return (
    <Row style={{marginBottom: 8}}>
      <Icon source={icon} size={12} color={colors.gray.tertiary} />
      <Space width={8} />
      <Text responsive={responsive}>{value}</Text>
    </Row>
  );
};

const InventoryHeaderProjectInfo = ({project, style}: any) => {
  return (
    <Row style={{flexWrap: 'wrap', maxWidth: '100%', ...style}}>
      <InfoText icon={Icon.User} value={project.customer.fullName} />
      <Space width={16} />
      <InfoText icon={Icon.Calendar} value={Project.getDate(project)} />
      <Space width={16} />
      <InfoText icon={Icon.MapPin} value={Project.getLocation(project)} />
    </Row>
  );
};
// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryHeaderProjectInfo.fragment = gql`
  ${Project.getDate.fragment}
  ${Project.getLocation.fragment}
  fragment InventoryHeaderProjectInfo on Project {
    id
    customer {
      id
      fullName
    }
    ...Project_getDate
    ...Project_getLocation
  }
`;

export default InventoryHeaderProjectInfo;
