export enum UserFlowExpirationUnitType {
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
}

export const convertUserFlowExpirationUnitTypeToLabel = (unitType: UserFlowExpirationUnitType) => {
  switch (unitType) {
    case UserFlowExpirationUnitType.DAYS:
      return 'Days';
    case UserFlowExpirationUnitType.HOURS:
      return 'Hours';
    case UserFlowExpirationUnitType.MINUTES:
      return 'Minutes';
  }
};
