// Supermove
import {EmailTemplateForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useDeleteEmailTemplateForm = ({emailTemplate, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      emailTemplateForm: EmailTemplateForm.edit(emailTemplate),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeleteEmailTemplateForm.mutation,
    variables: {
      emailTemplateForm: EmailTemplateForm.toMutation(form.values.emailTemplateForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeleteEmailTemplateForm.mutation = gql`
  mutation DeleteEmailTemplateMutation(
    $emailTemplateForm: EmailTemplateForm!,
  ) {
    response: deleteEmailTemplate(
      emailTemplateForm: $emailTemplateForm,
    ) {
      ${gql.errors}
      emailTemplate {
        id
        kind
        subject
        body
      }
    }
  }
`;

useDeleteEmailTemplateForm.fragment = gql`
  ${EmailTemplateForm.edit.fragment}

  fragment useDeleteEmailTemplateForm on EmailTemplate {
    id
    ...EmailTemplateForm_edit
  }
`;

export default useDeleteEmailTemplateForm;
