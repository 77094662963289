// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Row = Styled.View`
  flex-direction: row;
`;

type OwnSkeletonComponentProps = {
  width?: number;
};

// @ts-expect-error TS(2456): Type alias 'SkeletonComponentProps' circularly ref... Remove this comment to see the full error message
type SkeletonComponentProps = OwnSkeletonComponentProps & typeof SkeletonComponent.defaultProps;

// @ts-expect-error TS(7022): 'SkeletonComponent' implicitly has type 'any' beca... Remove this comment to see the full error message
const SkeletonComponent = ({width}: SkeletonComponentProps) => {
  return <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={width} />;
};

type OwnActionBadgeProps = {
  icon?: string;
  text?: string;
  isActive?: boolean;
  isWidthOfContainer?: boolean;
  onPress: (...args: any[]) => any;
  style?: any;
  containerStyle?: any;
};

// @ts-expect-error TS(2456): Type alias 'ActionBadgeProps' circularly reference... Remove this comment to see the full error message
type ActionBadgeProps = OwnActionBadgeProps & typeof ActionBadge.defaultProps;

// @ts-expect-error TS(7022): 'ActionBadge' implicitly has type 'any' because it... Remove this comment to see the full error message
const ActionBadge = ({
  icon,
  text,
  isActive,
  isDisabled,
  onPress,
  style,
  containerStyle,
  isWidthOfContainer,
  children,
}: ActionBadgeProps) => {
  const {isHovered, ref} = useHover();
  return (
    <Row ref={ref} style={containerStyle}>
      <SecondaryButton
        iconLeft={icon}
        iconSize={12}
        text={text}
        isSmall
        textColor={isActive ? colors.blue.interactive : colors.gray.secondary}
        isDisabled={isDisabled}
        onPress={onPress}
        isWidthOfContainer={isWidthOfContainer}
        isResponsive
        style={{
          paddingHorizontal: 8,
          borderColor: isDisabled
            ? colors.gray.border
            : isActive
              ? colors.blue.interactive
              : isHovered
                ? colors.blue.hover
                : colors.gray.border,
          ...style,
        }}
      >
        {children}
      </SecondaryButton>
    </Row>
  );
};

ActionBadge.SkeletonComponent = SkeletonComponent;

ActionBadge.defaultProps = {
  icon: null,
  text: null,
  isActive: false,
  isWidthOfContainer: false,
  style: {},
  containerStyle: {},
};

SkeletonComponent.defaultProps = {
  width: 40,
};

export default ActionBadge;
