// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Field} from 'modules/App/components';

const Container = Styled.View`
  padding: 10px;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Button = Styled.LoadingButton`
`;

const Text = Styled.H7`
  color: ${colors.white};
  ${fontWeight(600)}
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

type OwnProps = {
  form: any;
  isUpdate?: boolean;
  loading: boolean;
  handleSubmit: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SupplyFormFields.defaultProps;

// @ts-expect-error TS(7022): 'SupplyFormFields' implicitly has type 'any' becau... Remove this comment to see the full error message
const SupplyFormFields = ({field, form, isUpdate, loading, handleSubmit}: Props) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Field
        {...form}
        name={`${field}.name`}
        label={'Name'}
        value={_.get(form.values, `${field}.name`)}
        input={{
          autoFocus: true,
          placeholder: 'Enter a name (Supply #1)',
        }}
      />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <RowSpace {...responsive} />
      <Field
        {...form}
        component={CurrencyInput}
        input={{
          component: TextInput,
          placeholder: '$100',
          setFieldValue: (name: any, value: any) => form.setFieldValue(name, value),
          setFieldTouched: form.setFieldTouched,
          style: {
            width: 100,
          },
        }}
        name={`${field}.value`}
        label={'Price'}
        value={_.get(form.values, `${field}.value`)}
      />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <RowSpace {...responsive} />

      <Button loading={loading} onPress={handleSubmit}>
        <Text>{`${isUpdate ? 'Update' : 'Create'} Supply`}</Text>
      </Button>
    </Container>
  );
};

SupplyFormFields.defaultProps = {
  isUpdate: false,
};

export default SupplyFormFields;
