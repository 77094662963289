// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  padding-bottom: 10px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const Touchable = Styled.Touchable`
  margin-top: 10px;
`;

const Item = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-radius: 3px;
`;

const Left = Styled.View`
  align-self: stretch;
  justify-content: center;
`;

const Number = Styled.H6`
  color: ${colors.gray.secondary};
  ${fontWeight(700)}
`;

const Right = Styled.View`
  align-items: flex-end;
  height: 100%;
`;

const Timestamp = Styled.H7`
  ${fontWeight(700)}
`;

const Text = Styled.H7`
`;

const PositionItem = ({isActive, index, position, onDeselectPosition, onSelectPosition}: any) => (
  <Touchable
    onPress={() => {
      if (isActive) {
        onDeselectPosition(position);
      } else {
        onSelectPosition(position);
      }
    }}
  >
    <Item style={{backgroundColor: isActive ? colors.orange.accent : colors.white}}>
      <Left>
        <Number>{index + 1}</Number>
      </Left>
      <Right>
        <Timestamp>
          {Datetime.convertToDisplayDatetime(position.timestamp, 'M/D/YY h:mm:ss A')}
        </Timestamp>
        <Text>{`(${position.latitude}, ${position.longitude})`}</Text>
        <Text>{`Id: ${position.id}`}</Text>
        <Text>{`Accuracy: ${position.accuracy}`}</Text>
        <Text>
          {`Nearest location distance: ${
            Math.ceil(position.distanceToNearestLocation * 100) / 100
          } mi`}
        </Text>
        {position.isDrifted && <Text>{`GPS DRIFT DETECTED`}</Text>}
        {!!position.jobEvent && <Text>{`Job Event: ${position.jobEvent.kind}`}</Text>}
      </Right>
    </Item>
  </Touchable>
);

type OwnPositionsListProps = {
  activePosition?: any;
  positions: any[];
  onDeselectPosition: (...args: any[]) => any;
  onSelectPosition: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'PositionsListProps' circularly referen... Remove this comment to see the full error message
type PositionsListProps = OwnPositionsListProps & typeof PositionsList.defaultProps;

// @ts-expect-error TS(7022): 'PositionsList' implicitly has type 'any' because ... Remove this comment to see the full error message
const PositionsList = ({
  activePosition,
  positions,
  onDeselectPosition,
  onSelectPosition,
}: PositionsListProps) => (
  <Container>
    <FlatList
      data={positions}
      keyExtractor={(position: any) => position.id}
      renderItem={({item: position, index}: any) => (
        <PositionItem
          isActive={position.id === _.get(activePosition, 'id', false)}
          index={index}
          position={position}
          onDeselectPosition={onDeselectPosition}
          onSelectPosition={onSelectPosition}
        />
      )}
      ListEmptyComponent={() => (
        <EmptyContainer>
          <EmptyMessage>No positions</EmptyMessage>
        </EmptyContainer>
      )}
    />
  </Container>
);

PositionsList.defaultProps = {
  activePosition: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PositionsList.fragment = gql`
  fragment PositionsList on Position {
    id
    accuracy
    distanceToNearestLocation
    isDrifted
    latitude
    longitude
    timestamp
    jobEvent {
      id
      kind
    }
  }
`;

export default PositionsList;
