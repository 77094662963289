// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useUpdateFormulaMutation from '@shared/modules/Billing/hooks/useUpdateFormulaMutation';
import FormulaEditorModal from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditorModal';

const EditFormulaModal = ({formula, userId, isOpen, handleClose, refetch}: any) => {
  const formulaForm = FormulaForm.edit(formula, {userId});
  const {form, handleSubmit, submitting} = useUpdateFormulaMutation({
    formulaForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <FormulaEditorModal
      organizationId={formula.organizationId}
      title={'Edit Billing Formula'}
      form={form}
      field={'formulaForm'}
      handleSubmit={handleSubmit}
      submitting={submitting}
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditFormulaModal.fragment = gql`
  ${FormulaForm.edit.fragment}
  fragment EditFormulaModal on Formula {
    id
    organizationId
    ...FormulaForm_edit
  }
`;

export default EditFormulaModal;
