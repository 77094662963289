// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useRef} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import useSignInventoryConfirmationForm from 'modules/Customer/Project/Confirmation/Inventory/logic/useSignInventoryConfirmationForm';
import DocumentPdfView from 'modules/Document/components/DocumentPdfView';
import PreviewDocument from 'modules/Document/components/PreviewDocument';
import SignatureField from 'modules/Document/components/SignatureField';

const Container = Styled.View`
  padding-bottom: 90px;
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Description = Styled.H6`
  margin-bottom: 10px;
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonContent = ({form}: any) => {
  if (form.loading) {
    return null;
  }
  return form.values.isFinished ? (
    <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />
  ) : (
    <Text>Continue</Text>
  );
};

const InventoryConfirmationForm = ({confirmation}: any) => {
  const {form, submitting, handleSubmit} = useSignInventoryConfirmationForm({
    confirmation,
    onSuccess: () => form.setFieldValue('isFinished', true),
    onError: (errors: any) => console.log({errors}),
  });
  const {navigator} = useNavigationDOM();
  const signatureRef = useRef();

  return (
    <Container>
      <Section>
        <PreviewDocument canFullscreen={false} title={'Itemized Inventory'} style={{marginTop: 10}}>
          <DocumentPdfView shouldPromptSignature document={confirmation.inventoryWithSignature} />
        </PreviewDocument>
      </Section>
      <SectionSpace />
      <Section>
        <Description>By signing here, you are agreeing to all items above.</Description>
        <SignatureField
          signatureRef={signatureRef}
          onEnd={() => form.setFieldValue('isSignatureValid', true)}
          // @ts-expect-error TS(2739): Type '{ image: any; }' is missing the following pr... Remove this comment to see the full error message
          onSave={(image: any) => handleSubmit({variables: {image}})}
        />
      </Section>
      <SectionSpace />
      <Section>
        <Button
          disabled={!form.values.isSignatureValid}
          loading={submitting}
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          onPress={() => signatureRef.current.save()}
          onSuccess={() =>
            navigator.push(
              `/0/${confirmation.project.organization.slug}/projects/${confirmation.project.uuid}/confirmation`,
            )
          }
          success={form.values.isFinished}
        >
          <ButtonContent form={form} />
        </Button>
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryConfirmationForm.fragment = gql`
  ${DocumentPdfView.fragment}

  fragment InventoryConfirmationForm on Confirmation {
    id
    inventoryWithSignature {
      id
      ...DocumentPdfView
    }
    project {
      id
      uuid
      organization {
        id
        slug
      }
    }
  }
`;

export default InventoryConfirmationForm;
