// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateReportMoveTimeRangesMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateReportMoveTimeRangesMutation' implicitly ha... Remove this comment to see the full error message
const UpdateReportMoveTimeRangesMutation = ({
  reportMoveId,
  timeRangeInputs,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
    variables={{
      reportMoveId,
      timeRangeInputs,
    }}
    mutation={UpdateReportMoveTimeRangesMutation.mutation}
    onCompleted={({response: {reportMove, errors}}: any) => {
      submitForm();
      setMutationErrors(errors);

      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateReportMoveTimeRangesMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesMutation.mutation = gql`
  mutation UpdateReportMoveTimeRangesMutation(
    $reportMoveId: Int!,
    $timeRangeInputs: [TimeRangeInput]!,
  ) {
    response: updateReportMoveTimeRanges(
      reportMoveId: $reportMoveId,
      timeRangeInputs: $timeRangeInputs,
    ) {
      ${gql.errors}
      reportMove {
        id
        uuid
        timeRanges {
          kind
          name
          start
          end
        }
      }
    }
  }
`;

export default UpdateReportMoveTimeRangesMutation;
