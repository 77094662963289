// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, Space, Styled, Icon, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useEffect} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding: 24px;
`;

const LoadingContainer = Styled.View`
  min-height: 500px;
`;

const NotesContainer = Styled.View`
  flex: 1;
`;

const Header = Styled.Text`
  ${Typography.Heading2}
`;

const GrayBody = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const MicroGrayBody = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.tertiary};
`;

const DayNotesRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const DayNotesHeading = Styled.Text`
    ${Typography.MicroLabel}
    color: ${colors.gray.secondary};
`;

const DayNotesText = Styled.Text`
    ${Typography.Body}
`;

const NotesContentContainer = Styled.View`
  flex: 1;
  padding: 12px;
  border-radius: 4px;
  flex-basis: auto;
  border: 1px solid ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const DayNotesSection = ({day, organization}: any) => {
  return (
    // @ts-expect-error TS(2741): Property 'innerRef' is missing in type '{ children... Remove this comment to see the full error message
    <Popover.Content>
      <NotesContainer>
        <DayNotesRow>
          <Icon source={Icon.StickyNote} color={colors.gray.secondary} size={12} />
          <Space width={4} />
          <DayNotesHeading>{organization.name}</DayNotesHeading>
        </DayNotesRow>
        <Space height={4} />
        {day && day.notes ? (
          <DayNotesText style={{color: colors.gray.primary}}>{day.notes}</DayNotesText>
        ) : (
          <DayNotesText style={{color: colors.gray.tertiary}}>No notes.</DayNotesText>
        )}
        <Space height={4} />
        {day && !_.isEmpty(day.notes) ? (
          <MicroGrayBody>{`Last updated: ${Datetime.convertToDisplayDatetime(day.updatedAt)}${
            day.notesUpdatedBy ? ` by ${day.notesUpdatedBy.fullName}` : ''
          }`}</MicroGrayBody>
        ) : null}
      </NotesContainer>
    </Popover.Content>
  );
};

const ViewDayNotesPopoverContent = ({slugs, date, isPopoverOpen}: any) => {
  useEffect(() => {}, [isPopoverOpen]);

  const {data, loading} = useQuery(ViewDayNotesPopoverContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      day: date,
      slugs: slugs || ['ALL_ORGANIZATIONS'],
    },
    skip: !isPopoverOpen,
  });

  // We do an a data check here to prevent an issue where calendar opens up
  // an empty modal
  if (!data) {
    return <LoadingContainer />;
  }

  return (
    <ResponsivePopover.StaticContainer style={{minHeight: 500}} width={324} maxHeight={500}>
      <ScrollView style={{maxWidth: 321, backgroundColor: colors.white}}>
        <ContentContainer>
          <Header>Day Notes</Header>
          <Space height={12} />
          <GrayBody>{`Go to the day view to edit notes`}</GrayBody>
          <Space height={12} />
          <Space height={8} />
          {!_.isEmpty(data) && loading ? (
            <LoadingContainer>
              <PageLoadingIndicator />
            </LoadingContainer>
          ) : (
            <React.Fragment>
              {
                // We need to loop through each org instead of days because a day object is not always created
                // For Franchise/Branch orgs, we will filter it out
                (Organization as any)
                  .getOrganizationBySlugsWithParent({
                    slugs,
                    organization: data?.viewer?.viewingOrganization,
                  })
                  .map((organization: any, index: any) => {
                    const organizationDay = data?.days.find(
                      (day: any) => day.organization.id === organization.id,
                    );
                    return (
                      <React.Fragment key={index}>
                        <NotesContentContainer>
                          <DayNotesSection
                            index={index}
                            day={organizationDay}
                            organization={organization}
                            selectedDate={date}
                          />
                          <Space width={8} />
                        </NotesContentContainer>
                        <Space height={12} />
                      </React.Fragment>
                    );
                  })
              }
            </React.Fragment>
          )}
          <Space height={8} />
        </ContentContainer>
      </ScrollView>
    </ResponsivePopover.StaticContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewDayNotesPopoverContent.query = gql`
  ${Organization.getOrganizationBySlugsWithParent.fragment}

  query ViewDayNotesPopoverContent($day: String!, $slugs: [String!]!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        company {
          id
          organizations {
            id
            name
            slug
            isPrimary
          }
        }
        ...Organization_getOrganizationBySlugsWithParent
      }
    }
    days(date: $day, slugs: $slugs) {
      id
      notes
      organization {
        id
      }
      updatedAt
      notesUpdatedBy {
        id
        fullName
      }
    }
  }
`;

export default ViewDayNotesPopoverContent;
