// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useInternet,
  useDebouncedCallback,
  useEffect,
  useResponsive,
  useState,
  useSheet,
  useCallback,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Currency} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import Sheet from '@shared/design/components/Sheet';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import EditInventoryNotesSheet from 'modules/Inventory/Edit/components/EditInventoryNotesSheet';
import InventoryHeaderProjectInfo from 'modules/Inventory/Edit/components/InventoryHeaderProjectInfo';

const HeaderContainer = Styled.View`
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '8' : '12')}px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '16' : '24')}px;
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
  border-bottom-style: solid;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const HeaderButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
`;

const HeaderButtonText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const LastSavedText = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? Typography.Responsive.Body : Typography.Responsive.Micro)}
`;

const SummaryAndNotesButtonText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MetricsContainer = Styled.View`
  width: 100%;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const MetricContainer = Styled.View`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 72px;
  flex: 1;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  height: 100%;
`;

const MetricTitle = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const MetricValue = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.primary};
`;

const getNoteTypography = (responsive: any, isEmpty: any) => {
  if (responsive.mobile) {
    return isEmpty ? Typography.Responsive.Link : Typography.Responsive.Body;
  }
  return isEmpty ? Typography.Responsive.MicroLink : Typography.Responsive.Micro;
};

const Note = Styled.Text<{vars: {isEmpty: boolean}; responsive: any}>`
  ${({vars: {isEmpty}, responsive}) => getNoteTypography(responsive, isEmpty)}
  color: ${({vars: {isEmpty}}) => (isEmpty ? colors.gray.tertiary : colors.gray.secondary)};
  text-decoration-color: ${({vars: {isEmpty}}) => (isEmpty ? colors.gray.tertiary : colors.gray.secondary)};
`;

const InventoryMetric = ({title, value, hideBorder}: any) => {
  const responsive = useResponsive();
  return (
    <MetricContainer
      style={{
        borderRightWidth: hideBorder ? 0 : 1,
        borderRightColor: colors.gray.border,
      }}
    >
      <MetricTitle responsive={responsive}>{title}</MetricTitle>
      <Space height={4} />
      <MetricValue responsive={responsive}>{value}</MetricValue>
    </MetricContainer>
  );
};

const InventoryInfo = ({inventoryRoomsInfo, containerWidth, isStackedOverride, style}: any) => {
  const isStacked = isStackedOverride || containerWidth < 552;
  return (
    <MetricsContainer style={{flexDirection: isStacked ? 'column' : 'row', ...style}}>
      <Row
        style={{
          flex: 1,
          borderColor: colors.gray.border,
          borderBottomWidth: isStacked ? 1 : 0,
        }}
      >
        <InventoryMetric title={'Rooms'} value={inventoryRoomsInfo.roomCount} />
        <InventoryMetric title={'Take'} value={inventoryRoomsInfo.takeCount} />
        <InventoryMetric
          title={'Leave'}
          value={inventoryRoomsInfo.leaveCount}
          hideBorder={isStacked}
        />
      </Row>
      <Row style={{flex: 1}}>
        <InventoryMetric title={'Volume'} value={`${inventoryRoomsInfo.volume} cu ft`} />
        <InventoryMetric title={'Weight'} value={`${inventoryRoomsInfo.weight} lbs`} />
        <InventoryMetric
          title={'Est. Price'}
          value={Currency.display(inventoryRoomsInfo.price)}
          hideBorder
        />
      </Row>
    </MetricsContainer>
  );
};

const LastSavedInfo = ({inventory, isConnected, style}: any) => {
  const responsive = useResponsive();
  const {lastSyncedAt} = inventory;
  const [lastSyncedText, setLastSyncedText] = useState(Datetime.timeAgo(inventory.lastSyncedAt));

  useEffect(() => {
    if (lastSyncedAt) {
      setLastSyncedText(Datetime.timeAgo(lastSyncedAt));
    }
  }, [lastSyncedAt]);

  return (
    <Row style={style}>
      {!isConnected && (
        <React.Fragment>
          <Icon
            source={Icon.ExclamationTriangle}
            color={colors.red.warning}
            size={Icon.Sizes.ExtraSmall}
          />
          <Space width={8} />
          <LastSavedText responsive={responsive} style={{color: colors.red.warning}}>
            You are offline.{' '}
          </LastSavedText>
        </React.Fragment>
      )}

      <LastSavedText responsive={responsive} style={{color: colors.gray.tertiary}}>
        Last Saved: {lastSyncedText}
      </LastSavedText>
    </Row>
  );
};

const BackButton = ({handleSaveAndExit}: any) => {
  const responsive = useResponsive();
  return (
    <HeaderButton onPress={handleSaveAndExit}>
      <Icon source={Icon.ChevronLeft} color={colors.gray.secondary} size={12} />
      <Space width={8} />
      <HeaderButtonText responsive={responsive}>Back to Project</HeaderButtonText>
    </HeaderButton>
  );
};

const SummaryAndNotesButton = ({onPress, isSummaryAndNotesVisible}: any) => {
  const responsive = useResponsive();
  return (
    <HeaderButton onPress={onPress} style={{marginBottom: 8}}>
      <Icon
        source={isSummaryAndNotesVisible ? Icon.ChevronUp : Icon.ChevronDown}
        color={colors.gray.secondary}
        size={12}
      />
      <Space width={8} />
      <SummaryAndNotesButtonText responsive={responsive}>Summary & Notes</SummaryAndNotesButtonText>
    </HeaderButton>
  );
};

const InventoryNotes = ({onPress, notes, responsive, label}: any) => {
  return (
    <HeaderButton onPress={onPress}>
      <Icon source={Icon.Pen} size={12} color={colors.blue.interactive} />
      <Space width={8} />
      <Note numberOfLines={2} vars={{isEmpty: !notes}} responsive={responsive}>
        {notes || label}
      </Note>
    </HeaderButton>
  );
};

const MobileHeader = ({
  responsive,
  handleSaveAndExit,
  surveyName,
  handleSubmit,
  isSaving,
  hasUnsavedChanges,
  inventory,
  isConnected,
  inventoryRoomsInfo,
  notes,
  editInventoryNotesSheet,
}: any) => {
  const inventorySummarySheet = useSheet({name: 'Inventory Summary Sheet'});
  return (
    <HeaderContainer responsive={responsive} style={{flexDirection: 'row'}}>
      <HeaderButton onPress={handleSaveAndExit}>
        <HeaderButtonText responsive={responsive}>Exit</HeaderButtonText>
      </HeaderButton>
      <Space style={{flex: 1, minWidth: 8}} />
      <HeaderButton onPress={inventorySummarySheet.handleOpen}>
        <HeaderButtonText responsive={responsive} style={{color: colors.gray.primary}}>
          {surveyName}
        </HeaderButtonText>
        <IconContainer style={{paddingBottom: 2}}>
          <Icon source={Icon.InfoCircle} color={colors.gray.secondary} size={16} />
        </IconContainer>
      </HeaderButton>
      <Space style={{flex: 1, minWidth: 8}} />
      <TertiaryButton
        isResponsive
        onPress={handleSubmit}
        isSubmitting={isSaving}
        isDisabled={!hasUnsavedChanges}
        textColor={colors.blue.interactive}
        text={'Save'}
      />
      <Sheet
        isOpen={inventorySummarySheet.isOpen}
        handleClose={inventorySummarySheet.handleClose}
        headerText={'Summary'}
        isFixedHeight
      >
        <Column style={{paddingVertical: 8, paddingHorizontal: 16}}>
          <HeaderText responsive={responsive}>{surveyName}</HeaderText>
          <Space height={8} />
          <LastSavedInfo inventory={inventory} isConnected={isConnected} />
          <Space height={16} />
          <InventoryHeaderProjectInfo
            project={inventory.project}
            style={{flexDirection: 'column', alignItems: 'flex-start', margin: 0}}
          />
          <Space height={12} />
          <InventoryInfo
            inventoryRoomsInfo={inventoryRoomsInfo}
            isStackedOverride
            style={{backgroundColor: colors.white}}
          />
          <Space height={16} />
          <InventoryNotes
            onPress={() => {
              editInventoryNotesSheet.handleOpen();
              inventorySummarySheet.handleClose();
            }}
            notes={notes}
            responsive={responsive}
            label={'Tap to add survey notes.'}
          />
        </Column>
      </Sheet>
    </HeaderContainer>
  );
};

const ResponsiveInventoryHeader = ({
  inventoryRoomsForm,
  inventory,
  handleSubmit: parentHandleSubmit,
  isSaving,
  setIsSaving,
  isDesktopNonTouchDevice,
  isResponsiveSurveysVisible,
  toggleIsResponsiveSurveysVisible,
}: any) => {
  const {navigator, params} = useNavigationDOM();
  const [containerWidth, setContainerWidth] = useState(0);
  const [isSummaryAndNotesVisible, setIsSummaryAndNotesVisible] = useState(false);
  const editInventoryNotesSheet = useSheet({name: 'Edit Inventory Notes Sheet'});
  const responsive = useResponsive();
  const {isConnected} = useInternet();
  const {project} = inventory;
  const {identifier, uuid: projectUuid} = project;
  const surveyName = `Survey ${identifier}`;
  const inventoryRoomsInfo = InventoryRoomsForm.getInfo(
    _.get(inventoryRoomsForm.values, 'inventoryRoomsForm'),
  );
  const {hasUnsavedChanges} = inventoryRoomsInfo;
  const {notes} = inventoryRoomsForm.values.inventoryRoomsForm;

  const handleSubmit = () => {
    setIsSaving(true);
    parentHandleSubmit();
  };
  const handleSubmitIfConnected = (isConnected: any) => {
    if (isConnected && hasUnsavedChanges) {
      handleSubmit();
    }
  };

  // Auto save every 10 seconds if changes are detected
  const handleDebouncedSave = useDebouncedCallback(handleSubmitIfConnected, 10 * 1000);
  useEffect(
    () => handleDebouncedSave(isConnected),
    [handleDebouncedSave, isConnected, inventoryRoomsForm],
  );

  const handleLayout = useCallback(
    ({nativeEvent}) => {
      setContainerWidth(nativeEvent.layout.width);
    },
    [setContainerWidth],
  );

  const handleSaveAndExit = () => {
    if (params.back) {
      navigator.goBack();
    } else {
      navigator.replace(`/projects/${projectUuid}`);
    }
    handleDebouncedSave.flush();
  };

  return (
    <React.Fragment>
      {responsive.mobile ? (
        <MobileHeader
          responsive={responsive}
          handleSaveAndExit={handleSaveAndExit}
          surveyName={surveyName}
          handleSubmit={handleSubmit}
          isSaving={isSaving}
          hasUnsavedChanges={hasUnsavedChanges}
          inventory={inventory}
          isConnected={isConnected}
          inventoryRoomsInfo={inventoryRoomsInfo}
          notes={notes}
          editInventoryNotesSheet={editInventoryNotesSheet}
        />
      ) : (
        <HeaderContainer responsive={responsive} onLayout={handleLayout}>
          <Row style={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <BackButton handleSaveAndExit={handleSaveAndExit} />
            <Space height={4} width={8} />
            <Row>
              {responsive.desktop && (
                <Row>
                  <ContextSwitcher
                    contextDefinitions={[
                      {
                        iconSource: Icon.Desktop,
                        isSelected: !isResponsiveSurveysVisible,
                        onPress: toggleIsResponsiveSurveysVisible,
                      },
                      {
                        iconSource: Icon.TabletScreenButton,
                        isSelected: isResponsiveSurveysVisible,
                        onPress: toggleIsResponsiveSurveysVisible,
                      },
                    ]}
                    containerStyle={{height: 32}}
                  />
                  <Space width={16} />
                </Row>
              )}
              <Button
                isSmall
                text={'Save'}
                isSubmitting={isSaving}
                isDisabled={!hasUnsavedChanges}
                iconLeft={Icon.Check}
                onPress={handleSubmit}
                // @ts-expect-error TS(2322): Type '{ height: number; } | null' is not assignabl... Remove this comment to see the full error message
                style={responsive.desktop ? {height: 32} : null}
              />
            </Row>
          </Row>
          <Space height={4} />
          <Row style={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
            <HeaderText responsive={responsive} style={{marginBottom: 8}}>
              {surveyName}
            </HeaderText>
            <LastSavedInfo
              inventory={inventory}
              isConnected={isConnected}
              style={{marginBottom: 8}}
            />
          </Row>
          {isDesktopNonTouchDevice ? (
            <Row>
              <Column style={{flex: 1}}>
                <InventoryHeaderProjectInfo project={project} />
                <InventoryNotes
                  onPress={editInventoryNotesSheet.handleOpen}
                  notes={notes}
                  responsive={responsive}
                  label={'Add survey notes.'}
                />
              </Column>
              <Space width={16} />
              <Column style={{flex: 1}}>
                <InventoryInfo
                  inventoryRoomsInfo={inventoryRoomsInfo}
                  containerWidth={containerWidth}
                />
              </Column>
            </Row>
          ) : (
            <React.Fragment>
              <Row style={{justifyContent: 'space-between', flexWrap: 'wrap'}}>
                <InventoryHeaderProjectInfo project={project} style={{marginBottom: 4}} />
                <SummaryAndNotesButton
                  onPress={() => setIsSummaryAndNotesVisible(!isSummaryAndNotesVisible)}
                  isSummaryAndNotesVisible={isSummaryAndNotesVisible}
                />
              </Row>
              {isSummaryAndNotesVisible && (
                <React.Fragment>
                  <Space height={4} />
                  <InventoryInfo
                    inventoryRoomsInfo={inventoryRoomsInfo}
                    containerWidth={containerWidth}
                  />
                  <Space height={8} />
                  <InventoryNotes
                    onPress={editInventoryNotesSheet.handleOpen}
                    notes={notes}
                    responsive={responsive}
                    label={'Tap to add survey notes.'}
                  />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </HeaderContainer>
      )}
      <EditInventoryNotesSheet
        key={editInventoryNotesSheet.key}
        isOpen={editInventoryNotesSheet.isOpen}
        handleClose={editInventoryNotesSheet.handleClose}
        inventoryRoomsForm={inventoryRoomsForm}
        isDesktopNonTouchDevice={isDesktopNonTouchDevice}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ResponsiveInventoryHeader.fragment = gql`
  ${InventoryHeaderProjectInfo.fragment}
  fragment ResponsiveInventoryHeader on Inventory {
    id
    lastSyncedAt
    project {
      id
      uuid
      identifier
      ...InventoryHeaderProjectInfo
    }
  }
`;

export default ResponsiveInventoryHeader;
