// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, MultiDropdownInput, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useNavigationDOM, usePopover, useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Label1}
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
  padding-bottom: 12px;
`;

const FiltersRow = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}
  flex-direction: row;
  align-items: flex-end;
`;

const FilterContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const getButtonText = ({urlFilters}: any) => {
  return urlFilters.getFilterCountLabel({
    filterKeys: ['fromDueDate', 'toDueDate', 'assignedToValues', 'isArchived', 'projectTagIds'],
  });
};

const getAssigneeDropdownOptions = ({organization}: any) => {
  const assigneeOptions = Organization.getTaskAssigneeDropdownOptions(organization);
  const unassignedOption = {label: 'Unassigned', value: '-1'};
  return [unassignedOption, ...assigneeOptions];
};

const ProjectTagFilter = ({urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {data} = useQuery(TaskFiltersButton.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <TagDropdownInputField
      options={
        data
          ? data.viewer.viewingOrganization.companySettings.tags
              .filter((tag: any) => !tag.isArchived && tag.category === TagCategory.PROJECT)
              .map((tag: any) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
          : []
      }
      placeholder={'Select project tag'}
      label={'Project Tags'}
      index={0}
      value={_.get(params, 'projectTagIds', [])}
      onChangeValue={(projectTagIds: any) => {
        urlFilters.handleUpdate({projectTagIds});
      }}
    />
  );
};

const DateFilter = ({index, dateKey, label, urlFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <DateInput
        placeholder={'MM/DD/YYYY'}
        setFieldValue={() => {}}
        onChangeDate={(date) => urlFilters.handleUpdateDate({dateKey, date})}
        value={urlFilters.getInputValueForDate({dateKey})}
        style={{
          width: 118,
        }}
      />
    </FilterContainer>
  );
};

const DueDateFilters = ({index, urlFilters}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FiltersRow index={index}>
      <DateFilter
        index={index}
        dateKey={'fromDueDate'}
        label={'Due Date'}
        urlFilters={urlFilters}
      />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <DateFilter index={index + 1} dateKey={'toDueDate'} urlFilters={urlFilters} />
    </FiltersRow>
  );
};

const AssignedToUsersFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {data, loading} = useQuery(TaskFiltersButton.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText>Assignee(s)</FieldInput.LabelText>
      <Space height={4} />
      {/* @ts-expect-error TS(2740): Type '{ options: any[]; isLoading: boolean; placeh... Remove this comment to see the full error message */}
      <MultiDropdownInput
        options={
          data ? getAssigneeDropdownOptions({organization: data.viewer.viewingOrganization}) : []
        }
        isLoading={loading}
        placeholder={'Select assignee(s)'}
        style={{width: '100%'}}
        value={_.get(params, 'assignedToValues', [])}
        onChangeValue={(assignedToValues) => {
          urlFilters.handleUpdate({assignedToValues});
        }}
        isSearchable
        components={{
          DropdownIndicator: () => null,
        }}
      />
    </FilterContainer>
  );
};

const IsArchivedFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const showIsArchived = params.isArchived === 'true';
  const handleUpdateIsArchived = useDebouncedCallback(
    () => urlFilters.handleUpdate({isArchived: !showIsArchived}),
    250,
  );

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <Checkbox isChecked={showIsArchived} handleToggle={handleUpdateIsArchived} childrenRight>
        <Space width={12} />
        <FieldInput.LabelText>Show archived tasks</FieldInput.LabelText>
      </Checkbox>
    </FilterContainer>
  );
};

const ClearFiltersButton = ({urlFilters}: any) => {
  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() =>
        urlFilters.handleUpdate({
          fromDueDate: null,
          toDueDate: null,
          assignedToValues: null,
          projectTagIds: null,
          isArchived: null,
        })
      }
    />
  );
};

const TaskFiltersPopoverContent = ({urlFilters, organization}: any) => {
  return (
    <ResponsivePopover.StaticContainer width={288}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filters</Header>
        {organization.features.isEnabledProjectTag && (
          <React.Fragment>
            <Space height={12} />
            <ProjectTagFilter index={0} organization={organization} urlFilters={urlFilters} />
          </React.Fragment>
        )}
        <Space height={12} />
        <DueDateFilters index={1} urlFilters={urlFilters} />
        <Space height={12} />
        <AssignedToUsersFilter index={2} urlFilters={urlFilters} />
        <Space height={12} />
        <IsArchivedFilter index={3} urlFilters={urlFilters} />
        <Space height={12} />
        <ClearFiltersButton urlFilters={urlFilters} />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const TaskFiltersPopover = ({popover, urlFilters, organization}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <TaskFiltersPopoverContent urlFilters={urlFilters} organization={organization} />
    </Popover>
  );
};

const TaskFiltersButton = ({urlFilters, organization}: any) => {
  const taskFilterPopover = usePopover({
    name: 'Task Filters Popover',
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={taskFilterPopover.ref}>
        <SecondaryButton
          text={getButtonText({urlFilters})}
          onPress={taskFilterPopover.handleOpen}
          iconLeft={Icon.Filter}
        />
      </Popover.Content>
      <TaskFiltersPopover
        popover={taskFilterPopover}
        urlFilters={urlFilters}
        organization={organization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskFiltersButton.query = gql`
  ${Organization.getTaskAssigneeDropdownOptions.fragment}
  ${TagDropdownInputField.fragment}

  query TaskFiltersButton {
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
        }
        companySettings {
          tags {
            id
            name
            emoji
            isArchived
            category
            ...TagDropdownInputField
          }
        }
        ...Organization_getTaskAssigneeDropdownOptions
      }
    }
  }
`;

TaskFiltersButton.fragment = gql`
  fragment TaskFiltersButton on Organization {
    id
    features {
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
    }
  }
`;

export default TaskFiltersButton;
