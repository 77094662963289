// Libraries
import _ from 'lodash';
import React from 'react';
import {Redirect} from 'react-router-dom';

// Supermove
import {Identify, SkipIdentify, useVitally} from '@supermove/analytics';
import {Lifecycle, Mutation, Query} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useState} from '@supermove/hooks';
import {Environment, Storage} from '@supermove/sdk';

// App
import UserRole from '@shared/modules/User/enums/UserRole';

const Viewer = ({children}: any) => (
  <Query fetchPolicy={'cache-first'} query={Viewer.query}>
    {({loading, data, error}: any) =>
      children({
        loading,
        isAuthenticated: _.get(data, 'isAuthenticated'),
        viewer: _.get(data, 'viewer'),
      })
    }
  </Query>
);

Viewer.query = gql`
  query Viewer {
    ${gql.query}
    isAuthenticated
    viewer {
      id
      firstName
      lastName
      fullName
      email
      phoneNumber
      role
      position
      userflowSecuritySignature
      createdAt
      viewingOrganization {
        id
        slug
        company {
          id
          name
          slug
          locationCity
          locationState
          locationCountry
          contractedTruckQuantity
          size
          salesforceId
        }
        features {
          isEnabledOfficeVitallyNps: isEnabled(feature: "OFFICE_VITALLY_NPS")
        }
      }
    }
  }
`;

const TrackViewer = ({skip, children}: any) => {
  const vitally = useVitally({
    token: Environment.get('VITALLY_TOKEN'),
    domain: 'supermove',
  });

  return (
    <Viewer>
      {({loading, isAuthenticated, viewer}: any) => {
        if (skip) {
          // Server rendering.
          return children();
        } else if (loading && !viewer) {
          // Loading state, first time loading the app.
          return <div />;
        } else if (isAuthenticated) {
          return (
            <Identify
              isEnabledOfficeVitallyNps={_.get(
                viewer,
                'viewingOrganization.features.isEnabledOfficeVitallyNps',
                false,
              )}
              viewer={{
                // These keys should be camelcase to signify specific keys on
                // analytics services.
                id: _.get(viewer, 'id'),
                firstName: _.get(viewer, 'firstName'),
                lastName: _.get(viewer, 'lastName'),
                fullName: _.get(viewer, 'fullName'),
                email: _.get(viewer, 'email'),
                phoneNumber: _.get(viewer, 'phoneNumber'),
                position: _.get(viewer, 'position'),
                role: _.get(viewer, 'role', ''),
                userflowSecuritySignature: _.get(viewer, 'userflowSecuritySignature'),
                createdAt: _.get(viewer, 'createdAt'),
                organizationSlug: _.get(viewer, 'viewingOrganization.slug'),
                companyName: _.get(viewer, 'viewingOrganization.company.name', ''),
                companyIdentifier: _.get(viewer, 'viewingOrganization.company.slug', ''),
                companyCity: _.get(viewer, 'viewingOrganization.company.locationCity', ''),
                companyState: _.get(viewer, 'viewingOrganization.company.locationState', ''),
                companyCountry: _.get(viewer, 'viewingOrganization.company.locationCountry', ''),
                companyContractedTruckQuantity: _.get(
                  viewer,
                  'viewingOrganization.company.contractedTruckQuantity',
                  '',
                ),
                companySize: _.get(viewer, 'viewingOrganization.company.size', ''),
                companySalesforceId: _.get(viewer, 'viewingOrganization.company.salesforceId', ''),

                // These key names are passed directly to the payload for analytics.
                Role: _.get(viewer, 'role', ''),
                Organization: _.get(viewer, 'viewingOrganization.slug'),
              }}
              vitally={vitally}
            >
              {children()}
            </Identify>
          );
        } else {
          // Public view.
          // @ts-expect-error TS(2741): Property 'viewer' is missing in type '{ children: ... Remove this comment to see the full error message
          return <SkipIdentify>{children()}</SkipIdentify>;
        }
      }}
    </Viewer>
  );
};

const RedirectIfAuthenticated = ({roles, redirect, children}: any) => (
  <Viewer>
    {({loading, isAuthenticated, viewer}: any) =>
      loading ? (
        <div />
      ) : isAuthenticated && UserRole.ADMIN_ROLES_PLUS_SUPER.concat(roles).includes(viewer.role) ? (
        <Redirect to={redirect} />
      ) : (
        children()
      )
    }
  </Viewer>
);

const RedirectIfNotAuthenticated = ({roles, redirect, children}: any) => (
  <Viewer>
    {({loading, isAuthenticated, viewer}: any) =>
      loading ? (
        <div />
      ) : isAuthenticated && UserRole.ADMIN_ROLES_PLUS_SUPER.concat(roles).includes(viewer.role) ? (
        children()
      ) : (
        <Redirect to={redirect} />
      )
    }
  </Viewer>
);

/**
 * 1. Fetch local storage user ID.
 * 2. If URL contains an auth query param, ping the server with it.
 * 3. If authenticated user matches the stored user, do not do anything.
 *    If authenticated user does not match the stored user, persist the
 *    new user into local storage and reload the page without query params.
 */
const EmployeeLogin = ({children}: any) => {
  const {params} = useNavigationDOM();
  const [isInitialized, setIsInitialized] = useState(false);
  const [storedUserId, setStoredUserId] = useState(null);

  return (
    <Lifecycle
      onMount={async () => {
        const storedUserId = await Storage.getItem('userId');
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        setStoredUserId(storedUserId);
        setIsInitialized(true);
      }}
      children={
        isInitialized && (
          <React.Fragment>
            {params.auth ? (
              <Mutation
                // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any) => Element; ... Remove this comment to see the full error message
                variables={{auth: params.auth}}
                mutation={EmployeeLogin.mutation}
                onCompleted={async ({logInEmployee: {token: newToken, user, errors}}: any) => {
                  if (errors) {
                    // If there is any error in the log in response,
                    // refresh the page without the auth query param.
                    window.location.href = `${window.location.origin}${window.location.pathname}`;
                  } else if (storedUserId !== user.id) {
                    // If authenticated user does not match the user in local storage,
                    // update local storage and refresh the page without the auth query param.
                    await Storage.setItem('token', newToken);
                    await Storage.setItem('userId', user.id);
                    // TODO(mark): This reloads the current page without any query params.
                    // This will break pages that have query params. We want to remove the `auth`
                    // param and that's it.
                    window.location.href = `${window.location.origin}${window.location.pathname}`;
                  }
                }}
              >
                {(handleSubmit: any) => (
                  <Lifecycle onMount={handleSubmit} children={storedUserId && children()} />
                )}
              </Mutation>
            ) : (
              children()
            )}
          </React.Fragment>
        )
      }
    />
  );
};

EmployeeLogin.mutation = gql`
  mutation EmployeeLoginMutation($auth: String!) {
    logInEmployee(auth: $auth) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export {EmployeeLogin, RedirectIfAuthenticated, RedirectIfNotAuthenticated, TrackViewer};
