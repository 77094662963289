// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useResponsive, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App

import useSendReceiptEmailMutation from '@shared/modules/Email/hooks/useSendReceiptEmailMutation';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import SendReportMoveFields from 'modules/Job/ReportMove/Send/components/SendReportMoveFields';
import SendReportMoveForm from 'modules/Job/ReportMove/Send/components/SendReportMoveForm';
import SendReportMoveSuccessModal from 'modules/Job/ReportMove/Send/components/SendReportMoveSuccessModal';
import JobPageWithNavigation from 'modules/Job/components/JobPageWithNavigation';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-top: ${(props) => ((props as any).mobile ? 20 : 60)}px;
  padding-bottom: 100px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H7`
  margin-top: 10px;
`;

const Row = Styled.View`
`;

const Footer = Styled.View`
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-vertical: ${(props) => ((props as any).mobile ? 10 : 15)};
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SendReportMovePageContent = ({job, emailTemplate}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useSendReceiptEmailMutation({
    job,
    subject: emailTemplate.subject,
    body: emailTemplate.html,
    attachmentIds: emailTemplate.emailTemplateAttachments.map(
      (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
    ),
    onSuccess: () => {
      handleOpen();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  return (
    <Wrapper>
      <ScrollView style={{flex: 1}}>
        <Container>
          <Content {...responsive}>
            <Title>Prepare & send move receipt</Title>
            <Row>
              <Subtitle>
                {`Please draft an email to send to ${job.project.client.primaryContact.fullName}. The move receipt will be sent as an attachment.`}
              </Subtitle>
            </Row>
            <SendReportMoveFields form={form} job={job} />
            <Space height={40} />
            <EmailAttachmentsSelector
              form={form}
              project={job.project}
              name={'sendEmailForJobForm'}
            />
          </Content>
        </Container>
      </ScrollView>
      <Footer {...responsive}>
        <Button
          disabled={(form.values as any).isEditingSteps}
          loading={submitting}
          onPress={handleSubmit}
        >
          <Text>Send Move Receipt</Text>
        </Button>
      </Footer>
      <SendReportMoveSuccessModal job={job} isOpen={isOpen} onClose={handleClose} />
    </Wrapper>
  );
};

const EmailTemplateLoader = ({job}: any) => {
  const {data} = useQuery(EmailTemplateLoader.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectId: job.project.id,
      emailTemplateKind: 'MOVE_RECEIPT_SEND',
    },
  });

  if (data) {
    return <SendReportMovePageContent job={job} emailTemplate={data.hydratedEmailTemplate} />;
  }
  return null;
};

const SendReportMovePage = ({
  match: {
    params: {uuid},
  },
}: any) => {
  return (
    <JobPageWithNavigation
      query={SendReportMovePage.query}
      variables={{uuid}}
      title={({loading, data}: any) => (loading ? 'Loading...' : Job.getFullName(data.job))}
      subtitle={() => 'Send move receipt'}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({data}: any) => <EmailTemplateLoader job={data.job} />}
    </JobPageWithNavigation>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendReportMovePage.query = gql`
  ${EmailAttachmentsSelector.fragment}
  ${JobPageWithNavigation.fragment}
  ${SendReportMoveFields.fragment}
  ${SendReportMoveForm.fragment}
  ${SendReportMoveSuccessModal.fragment}
  ${Job.getFullName.fragment}

  query SendReportMovePage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      number
      project {
        id
        client {
          id
          primaryContact {
            id
            fullName
          }
        }
        ...EmailAttachmentsSelector
      }
      ...JobPageWithNavigation
      ...SendReportMoveFields
      ...SendReportMoveForm
      ...SendReportMoveSuccessModal
      ...Job_getFullName
    }
  }
`;

EmailTemplateLoader.query = gql`
  query SendInvoiceReportMovePage($projectId: Int!, $emailTemplateKind: String!) {
    ${gql.query}
    hydratedEmailTemplate(projectId: $projectId, emailTemplateKind: $emailTemplateKind) {
      subject
      html
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
    }
  }
`;

export default SendReportMovePage;
