// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const Text = Styled.Text`
  ${Typography.Body2}
`;

const MoveToVariableSectionModal = ({
  isOpen,
  handleClose,
  form,
  sectionFormsField,
  handleSubmit,
}: any) => {
  const [variableDestinationField, setVariableDestinationField] = useState('');
  const existingSectionForms = _.get(form.values, sectionFormsField);
  const getVariableSectionOptions = ({existingSectionForms, sectionFormsField}: any) => {
    return existingSectionForms.map(
      (
        {name}: any,
        // @ts-expect-error TS(7006): Parameter 'index' implicitly has an 'any' type.
        index,
      ) => ({
        label: name,
        value: `${sectionFormsField}[${index}]`,
      }),
    );
  };
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Move Variables'}</SmallModal.HeaderText>
      <Space height={16} />
      <Text>{'Select which section you would like to move the current variables to:'}</Text>
      <Space height={16} />
      <FieldInput
        component={DropdownInput}
        input={{
          required: true,
          placeholder: 'Select section',
          value: variableDestinationField,
          options: getVariableSectionOptions({
            existingSectionForms,
            sectionFormsField,
          }),
          onChangeValue: (variableDestinationField: any) => {
            setVariableDestinationField(variableDestinationField);
          },
          setFieldValue: () => {},
          style: {flex: 1},
        }}
        style={{width: '100%'}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          isDisabled={!variableDestinationField}
          onPress={() => handleSubmit({variableDestinationField})}
          color={colors.blue.interactive}
        >
          Move
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default MoveToVariableSectionModal;
