/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App

import JobDispatchInfoForm from '@shared/modules/Job/forms/JobDispatchInfoForm';
import useUpdateJobDispatchInfoMutation from '@shared/modules/Job/hooks/useUpdateJobDispatchInfoMutation';
import Field from 'modules/App/components/Field';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SystemMessage from 'modules/App/components/SystemMessage';

const SplitRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const InputLabel = Styled.H8`
  color: ${colors.gray.secondary};
  ${fontWeight(700)}
`;

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const FieldContainer = Styled.View`
  width: ${(props) => (props as any).width}px;
`;

const FooterRow = Styled.View`
  flex-direction: row;
  flex; 1;
  justify-content: flex-end;
  align-items: center;
`;

const Button = Styled.ButtonV2`
`;

const LoadingButton = Styled.LoadingButton`
  height: 36px;
  width: 80px;
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const ArrivalWindow = ({form}: any) => {
  return (
    <React.Fragment>
      <InputLabel>Arrival Window</InputLabel>
      <SplitRow>
        <FieldContainer width={120}>
          <Field
            {...form}
            name={'jobDispatchInfoForm.startTime1'}
            component={TimeInput}
            input={{
              component: TextInput,
              placeholder: 'Start',
              setFieldValue: form.setFieldValue,
            }}
          />
        </FieldContainer>
        <FieldContainer width={120}>
          <Field
            {...form}
            name={'jobDispatchInfoForm.startTime2'}
            component={TimeInput}
            input={{
              component: TextInput,
              placeholder: 'End',
              setFieldValue: form.setFieldValue,
            }}
          />
        </FieldContainer>
      </SplitRow>
    </React.Fragment>
  );
};

const EstimateHours = ({form}: any) => {
  const handleBlur = (field: any, value: any) => {
    form.setFieldValue(field, Float.toString(Float.toFloat(value)));
  };
  const estimateHours1Field = 'jobDispatchInfoForm.estimateHours1';
  const estimateHours2Field = 'jobDispatchInfoForm.estimateHours2';
  return (
    <React.Fragment>
      <InputLabel>Estimate Hours</InputLabel>
      <SplitRow>
        <FieldContainer width={120}>
          <Field
            {...form}
            name={estimateHours1Field}
            input={{placeholder: 'Min', style: {paddingRight: 32, backgroundColor: 'transparent'}}}
            handleBlur={({nativeEvent}: any) => handleBlur(estimateHours1Field, nativeEvent.text)}
          />
        </FieldContainer>
        <FieldContainer width={120}>
          <Field
            {...form}
            name={estimateHours2Field}
            input={{placeholder: 'Max', style: {paddingRight: 32}}}
            handleBlur={({nativeEvent}: any) => handleBlur(estimateHours2Field, nativeEvent.text)}
          />
        </FieldContainer>
      </SplitRow>
    </React.Fragment>
  );
};

const NumberOfTrucks = ({form}: any) => {
  return (
    <React.Fragment>
      <InputLabel>Number of Trucks</InputLabel>
      <Field
        {...form}
        name={'jobDispatchInfoForm.numberOfTrucks'}
        input={{placeholder: 'Number of Trucks'}}
      />
    </React.Fragment>
  );
};

const NumberOfMovers = ({form}: any) => {
  return (
    <React.Fragment>
      <InputLabel>Number of Movers</InputLabel>
      <Field
        {...form}
        name={'jobDispatchInfoForm.crewSize'}
        input={{placeholder: 'Number of Movers'}}
      />
    </React.Fragment>
  );
};

const WarningMessage = ({children}: any) => {
  return (
    <React.Fragment>
      <Space height={8} />
      <SystemMessage isWarning containerStyle={{marginHorizontal: 0}}>
        {children}
      </SystemMessage>
      <Space height={8} />
    </React.Fragment>
  );
};

const ConditionalMessage = ({job, form}: any) => {
  const numberOfTrucksField = _.get(form.values, 'jobDispatchInfoForm.numberOfTrucks');
  const numberOfMoversField = _.get(form.values, 'jobDispatchInfoForm.crewSize');
  const excessTrucks =
    !!numberOfTrucksField && job.numberOfAssignedTrucks > Number(numberOfTrucksField);
  const excessMovers =
    !!numberOfMoversField && job.activeJobUsersCount > Number(numberOfMoversField);
  if (excessTrucks && excessMovers) {
    return (
      <WarningMessage>
        There are more assigned trucks and movers than the numbers entered above.
      </WarningMessage>
    );
  }
  if (excessTrucks) {
    return (
      <WarningMessage>There are more assigned trucks than the number entered above.</WarningMessage>
    );
  }
  if (excessMovers) {
    return (
      <WarningMessage>There are more assigned movers than the number entered above.</WarningMessage>
    );
  }
  return <Space height={20} />;
};

const Footer = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <FooterRow>
      <Button onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={26} />
      <LoadingButton onPress={handleSubmit} loading={submitting}>
        <ButtonText color={colors.white}>Save</ButtonText>
      </LoadingButton>
    </FooterRow>
  );
};

const EditJobDispatchInfoPopoverContent = ({job, crewId, handleClose, refetch}: any) => {
  const jobDispatchInfoForm = JobDispatchInfoForm.edit(job);
  const {form, handleSubmit, submitting} = useUpdateJobDispatchInfoMutation({
    jobDispatchInfoForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <ResponsivePopover.Container width={280}>
      <ContentContainer>
        <Space height={16} />
        <ArrivalWindow form={form} />
        <Space height={16} />
        <EstimateHours form={form} />
        {/* crewId is only passed in from the calendar side of the dispatch calendar.
        On the calendar side we currently want to hide editing the number of trucks
        and movers on a job to prevent confusion between assigning a number of
        trucks/movers to the job vs to the crew. */}
        {!crewId && (
          <React.Fragment>
            <Space height={16} />
            <NumberOfTrucks form={form} />
            <Space height={16} />
            <NumberOfMovers form={form} />
          </React.Fragment>
        )}
        <ConditionalMessage job={job} form={form} />
        <Footer
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
          refetch={refetch}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.Container>
  );
};

type OwnEditJobDispatchInfoPopoverProps = {
  setIsClosable?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'EditJobDispatchInfoPopoverProps' circu... Remove this comment to see the full error message
type EditJobDispatchInfoPopoverProps = OwnEditJobDispatchInfoPopoverProps &
  typeof EditJobDispatchInfoPopover.defaultProps;

// @ts-expect-error TS(7022): 'EditJobDispatchInfoPopover' implicitly has type '... Remove this comment to see the full error message
const EditJobDispatchInfoPopover = ({
  popover,
  job,
  crewId,
  refetch,
  setIsClosable,
}: EditJobDispatchInfoPopoverProps) => {
  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      onComponentDidHide={() => setIsClosable(true)}
      offset={[0, 4]}
    >
      <EditJobDispatchInfoPopoverContent
        job={job}
        crewId={crewId}
        handleClose={popover.handleClose}
        refetch={refetch}
      />
    </ResponsivePopover>
  );
};

EditJobDispatchInfoPopover.defaultProps = {
  setIsClosable: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobDispatchInfoPopover.fragment = gql`
  ${JobDispatchInfoForm.edit.fragment}

  fragment EditJobDispatchInfoPopover on Job {
    id
    activeJobUsersCount
    numberOfAssignedTrucks
    ...JobDispatchInfoForm_edit
  }
`;

export default EditJobDispatchInfoPopover;
