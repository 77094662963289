// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import UserFlowForm from '@shared/modules/UserFlows/forms/UserFlowForm';

type ResponseUserFlowType = {
  userFlow: UserFlowModel;
};

const useUpdateUserFlowMutation = ({
  userFlowForm,
  onSuccess,
  onError,
}: {
  userFlowForm: ReturnType<typeof UserFlowForm.new | typeof UserFlowForm.edit>;
  onSuccess: (response: ResponseUserFlowType) => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      userFlowForm: UserFlowForm.toForm(userFlowForm),
    },
    enableReinitialize: true,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateUserFlowMutation.mutation,
    variables: {
      userFlowForm: UserFlowForm.toMutation(form.values.userFlowForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateUserFlowMutation.mutation = gql`
  mutation useUpdateUserFlowMutation($userFlowForm: UserFlowForm!) {
    response: updateUserFlow(userFlowForm: $userFlowForm) {
      errors {
        field
        message
      }
      userFlow {
        id
        uuid
        name
      }
    }
  }
`;

export default useUpdateUserFlowMutation;
