// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import SettingsForm, {SettingsFormType} from '@shared/modules/Settings/forms/SettingsForm';

const useToggleSettingsIsCrewChargeSavedCardEnabledMutation = ({
  settingsForm,
  onSuccess,
  onError,
}: {
  settingsForm: SettingsFormType;
  onSuccess: () => void;
  onError: (error: unknown) => void;
}) => {
  const form = useForm({
    initialValues: {
      settingsForm: SettingsForm.toForm(settingsForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useToggleSettingsIsCrewChargeSavedCardEnabledMutation.mutation,
    variables: {
      settingsForm: SettingsForm.toMutation(form.values.settingsForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleSettingsIsCrewChargeSavedCardEnabledMutation.mutation = gql`
  mutation useToggleSettingsIsCrewChargeSavedCardEnabledMutation($settingsForm: SettingsForm!) {
    response: toggleSettingsIsCrewChargeSavedCardEnabled(settingsForm: $settingsForm) {
      ${gql.errors}
      settings {
        id
        isCrewChargeSavedCardEnabled
      }
    }
  }
`;

export default useToggleSettingsIsCrewChargeSavedCardEnabledMutation;
