// Libraries
import React from 'react';

// App
import BlueCheckboxField from 'modules/App/components/BlueCheckboxField';

const InputIsRequiredCheckBoxV1 = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
}: any) => {
  return (
    <React.Fragment>
      <BlueCheckboxField
        values={{isRequired: !!documentItem.isRequired}}
        name={'isRequired'}
        label={'Required input(s)'}
        setFieldValue={(name: any, value: any) => {
          setDraftDocumentContentJson((prevState: any) => {
            prevState.documentItems[documentItemIndex].isRequired = value;
            return {
              ...prevState,
            };
          });
        }}
      />
    </React.Fragment>
  );
};

export default InputIsRequiredCheckBoxV1;
