// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteContainerMutation from '@shared/modules/Storage/hooks/useDeleteContainerMutation';

const DeleteContainerModal = ({container, isOpen, handleClose, refetch}: any) => {
  const {handleSubmit} = useDeleteContainerMutation({
    containerForm: {containerId: container.id},
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Remove "${container.name}?"`}
      subtitle={'This container will be removed from the page'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Remove'}
    />
  );
};

export default DeleteContainerModal;
