// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useNavigationDOM, useQuery} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const getProjectOptions = ({selectedProject, filteredProjects}: any) => {
  const selectedProjectOption = selectedProject
    ? [Project.getProjectsSearchDropdownOption(selectedProject)]
    : [];

  const filteredProjectsOptions = filteredProjects.map((filteredProject: any) => {
    if (selectedProject && selectedProject.id === filteredProject.id) {
      return null;
    }
    return Project.getProjectsSearchDropdownOption(filteredProject);
  });

  return _.compact([...selectedProjectOption, ...filteredProjectsOptions]);
};

const ActionButton = ({project}: any) => {
  const {navigator} = useNavigationDOM();
  const openProject = () => navigator.pushNewTab(`/projects/${project.uuid}`);

  return (
    <TertiaryButton onPress={openProject}>
      <Icon source={Icon.ExternalLinkAlt} color={colors.blue.interactive} size={12} />
      <Space width={6} />
      <FieldInput.ActionText isResponsive>Open project</FieldInput.ActionText>
    </TertiaryButton>
  );
};

const TaskDrawerProjectsSearchInput = ({
  index,
  isDisabled,
  form,
  field,
  selectedProject,
  filteredProjects,
  handleChangeSearch,
  loading,
}: any) => {
  return (
    <FieldInput
      {...form}
      index={index}
      name={`${field}.projectId`}
      label={'Project'}
      component={DropdownInput}
      isRequired
      action={selectedProject && <ActionButton project={selectedProject} />}
      isResponsive
      input={{
        isDisabled,
        options: getProjectOptions({selectedProject, filteredProjects}),
        isSearchable: true,
        setFieldValue: form.setFieldValue,
        isLoading: loading,
        style: {flex: 1},
        onInputChange: (text: any, {action}: any) => {
          if (action === 'input-change') {
            handleChangeSearch(text);
          }
        },
        onChangeValue: (value: any, option: any) => {
          form.setFieldValue(`${field}.projectUuid`, option.project.uuid);
          form.setFieldValue(`${field}.projectBookedById`, option.project.bookedById);
          form.setFieldValue(`${field}.projectCoordinatedById`, option.project.coordinatedById);
        },
        components: {
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        },
        showDescriptionInOption: true,
        additionalSearchKeys: ['description', 'project.identifier'],
      }}
    />
  );
};

const TaskDrawerProjectsSearch = ({form, field, index, isDisabled}: any) => {
  const projectUuid = _.get(form.values, `${field}.projectUuid`);
  const {data, loading} = useQuery(TaskDrawerProjectsSearch.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      searchQuery: _.get(form.values, `${field}.projectsSearchQuery`),
      projectUuid: projectUuid || '',
    },
  });

  const handleChangeSearch = useDebouncedCallback(
    (input) => form.setFieldValue(`${field}.projectsSearchQuery`, input),
    500,
    {leading: true},
  );

  return (
    <TaskDrawerProjectsSearchInput
      index={index}
      isDisabled={isDisabled}
      form={form}
      field={field}
      selectedProject={_.get(data, 'project')}
      filteredProjects={_.get(data, 'filteredProjectsBySearch', [])}
      handleChangeSearch={handleChangeSearch}
      loading={loading}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskDrawerProjectsSearch.query = gql`
  ${Project.getProjectsSearchDropdownOption.fragment}

  query TaskDrawerProjectsSearch(
    $searchQuery: String,
    $projectUuid: String!,
  ) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      identifier
      bookedById
      coordinatedById
      ...Project_getProjectsSearchDropdownOption
    }
    filteredProjectsBySearch(
      searchQuery: $searchQuery,
    ) {
      id
      uuid
      identifier
      bookedById
      coordinatedById
      ...Project_getProjectsSearchDropdownOption
    }
  }
`;

export default TaskDrawerProjectsSearch;
