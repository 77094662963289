// Libraries
import _ from 'lodash';
import React from 'react';

// App

// Components
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

import UserDayJobUserItem from './UserDayJobUserItem';

const JobUser = Styled.View`
`;

const EmptyContainer = Styled.View`
`;

const EmptyMessage = Styled.H6`
  color: ${colors.gray.primary};
`;

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, 'job.startTime1');
};

const UserDayJobUsersList = ({jobUsers}: any) => (
  <FlatList
    data={sortJobUsers({jobUsers})}
    keyExtractor={(jobUser: any) => jobUser.id}
    renderItem={({item: jobUser, index}: any) => (
      <JobUser>
        <UserDayJobUserItem
          isFirst={index === 0}
          isLast={index === jobUsers.length - 1}
          jobUser={jobUser}
        />
      </JobUser>
    )}
    ListEmptyComponent={() => (
      <EmptyContainer>
        <EmptyMessage>You do not have any jobs on this day.</EmptyMessage>
      </EmptyContainer>
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
UserDayJobUsersList.fragment = gql`
  ${UserDayJobUserItem.fragment}

  fragment UserDayJobUsersList on JobUser {
    id
    job {
      id
      startTime1
    }
    ...UserDayJobUserItem
  }
`;

export default UserDayJobUsersList;
