// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Text = Styled.H8`
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
  line-height: 14;
  margin-top: 2px;
`;

const TextUnderlined = Styled.H8`
  color: ${colors.gray.secondary};
  ${fontWeight(500)}
  line-height: 14;
  margin-top: 1px;
  margin-bottom: 1px;
  text-decoration: underline;
  text-decoration-color: ${colors.gray.secondary};
`;

const BoldText = Styled.H8`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
  line-height: 14;
`;

const Container = Styled.View`
  background-color: ${colors.white};
`;

const JobContentWrapper = Styled.View`
  flex: 1;
  padding-vertical: 1px;
`;

const JobInfo = Styled.View`
  flex-direction: row;
  background-color: ${(props) => ((props as any).isPending ? colors.white : colors.alpha(colors.red.warning, 0.03))};
  border-top-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-right-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-bottom-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-color: ${colors.red.warning}
  border-style: dashed;
  border-radius: 4px;
  align-items: center;
`;

const JobLeftStrip = Styled.View`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${colors.red.warning};
  width: 4px;
  align-self: stretch;
`;

const JobInfoRow = Styled.View`
  margin-horizontal: 4px;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => colors.alpha(color, 0.15)};
  height: 18px;
  width: 18px;
  border-radius: 9px;
  align-items: center;
  justify-content: center;
`;

const JobNameContainer = Styled.View`
  padding-horizontal: 4px;
  padding-vertical: 2px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.tertiary};
  background-color: ${colors.white};
`;

const JobNameText = Styled.H8`
  font-size: 11px;
  line-height: 13px;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const JobStatusIcon = () => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <IconContainer color={colors.gray.primary}>
      <Icon source={Icon.CalendarCheck} size={10} color={colors.gray.primary} />
    </IconContainer>
  );
};

const JobNameBadge = ({jobType, jobIdentifier}: any) => {
  return (
    <JobNameContainer>
      <JobNameText>
        {jobType}: {jobIdentifier}
      </JobNameText>
    </JobNameContainer>
  );
};

const JobTitleRow = ({jobType, jobIdentifier, customerName, startTime, endTime}: any) => {
  return (
    <JobInfoRow>
      <JobStatusIcon />
      <Space width={4} />
      <JobNameBadge jobType={jobType} jobIdentifier={jobIdentifier} />
      <Space width={4} />
      <BoldText>{customerName}</BoldText>
      <Space width={4} />
      <Text>
        {startTime} - {endTime}
      </Text>
    </JobInfoRow>
  );
};

const JobDispatchNotesRow = ({dispatchNotes}: any) => {
  return (
    <JobInfoRow>
      <Space width={2} />
      <Icon source={Icon.StickyNote} color={colors.gray.primary} size={Icon.Sizes.Small} />
      <Space width={4} />
      <TextUnderlined numberOfLines={1}>{dispatchNotes}</TextUnderlined>
    </JobInfoRow>
  );
};

const JobLogisticsRow = ({weight, mileage, locations}: any) => {
  return (
    <JobInfoRow>
      <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.BoxOpen} />
      <Space width={4} />
      <Text color={colors.gray.primary}>{weight}</Text>
      <Space width={8} />
      <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.Road} />
      <Space width={4} />
      <Text color={colors.gray.primary}>{mileage}</Text>
      <Space width={8} />
      <Icon source={Icon.MapPin} color={colors.gray.primary} size={Icon.Sizes.Small} />
      <Space width={6} />
      <Text>{locations}</Text>
    </JobInfoRow>
  );
};

const JobCrewInformationRow = ({jobTrucksCount, jobUsersCount, jobUsersNames}: any) => {
  return (
    <JobInfoRow>
      <Space width={2} />
      <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.User} />
      <Space width={4} />
      <BoldText color={colors.gray.primary}>{jobTrucksCount}</BoldText>
      <Space width={8} />
      <Icon color={colors.gray.primary} size={Icon.Sizes.Small} source={Icon.Truck} />
      <Space width={4} />
      <BoldText color={colors.gray.primary}>{jobUsersCount}</BoldText>
      <Space width={4} />
      <Text>{jobUsersNames}</Text>
    </JobInfoRow>
  );
};

type OwnOrganizationJobCardSampleProps = {
  jobType?: string;
  jobIdentifier?: string;
  customerName?: string;
  startTime?: string;
  endTime?: string;
  dispatchNotes?: string;
  weight?: string;
  mileage?: string;
  locations?: string;
  jobTrucksCount?: string;
  jobUsersCount?: string;
  jobUsersNames?: string;
};

// @ts-expect-error TS(2456): Type alias 'OrganizationJobCardSampleProps' circul... Remove this comment to see the full error message
type OrganizationJobCardSampleProps = OwnOrganizationJobCardSampleProps &
  typeof OrganizationJobCardSample.defaultProps;

// @ts-expect-error TS(7022): 'OrganizationJobCardSample' implicitly has type 'a... Remove this comment to see the full error message
const OrganizationJobCardSample = ({
  isPending,
  jobType,
  jobIdentifier,
  customerName,
  startTime,
  endTime,
  dispatchNotes,
  weight,
  mileage,
  locations,
  jobTrucksCount,
  jobUsersCount,
  jobUsersNames,
}: OrganizationJobCardSampleProps) => {
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <JobInfo isPending={isPending}>
        <JobLeftStrip />
        <JobContentWrapper>
          <Space height={2} />
          <JobTitleRow
            jobType={jobType}
            jobIdentifier={jobIdentifier}
            customerName={customerName}
            startTime={startTime}
            endTime={endTime}
          />
          <Space height={2} />
          <JobDispatchNotesRow dispatchNotes={dispatchNotes} />
          <Space height={2} />
          <JobLogisticsRow weight={weight} mileage={mileage} locations={locations} />
          <Space height={2} />
          <JobCrewInformationRow
            jobTrucksCount={jobTrucksCount}
            jobUsersCount={jobUsersCount}
            jobUsersNames={jobUsersNames}
          />
          <Space height={2} />
        </JobContentWrapper>
      </JobInfo>
    </Container>
  );
};

OrganizationJobCardSample.defaultProps = {
  jobType: 'Job Type',
  jobIdentifier: 'Job Identifier',
  customerName: 'Customer Name',
  startTime: 'Start',
  endTime: 'End Time',
  dispatchNotes: 'Dispatch Notes',
  weight: 'Weight',
  mileage: 'Mileage',
  locations: 'Location 1 - Location 2 - Location 3',
  jobTrucksCount: '0/0',
  jobUsersCount: '0/0',
  jobUsersNames: 'Crew Names',
};

export default OrganizationJobCardSample;
