// Libraries
import React from 'react';

// App
import {Emoji, Modal, Mutation, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-vertical: 20px;
`;

const CancelButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  height: 30px;
  width: 100px;
  background-color: ${colors.gray.tertiary};
`;

const CancelText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SubmitButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100px;
`;

const SubmitText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobRequestConvertToPendingModal.defaultProps;

// @ts-expect-error TS(7022): 'JobRequestConvertToPendingModal' implicitly has t... Remove this comment to see the full error message
const JobRequestConvertToPendingModal = ({jobRequest, trigger, onSuccess}: Props) => (
  <Modal trigger={trigger}>
    {({handleClose}: any) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'alarm_clock'} />
          </Circle>
          <Content>
            <Title>Convert Request to Pending</Title>
            <Subtitle>Are you sure you want to change this request to pending?</Subtitle>
            <Actions>
              <CancelButton onPress={handleClose}>
                <CancelText>Cancel</CancelText>
              </CancelButton>
              <Mutation
                // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
                variables={{
                  jobRequestId: jobRequest.id,
                }}
                mutation={JobRequestConvertToPendingModal.mutation}
                onCompleted={({response: {jobRequest, errors}}: any) => {
                  if (jobRequest) {
                    onSuccess();
                  }
                }}
              >
                {(handleSubmit: any, {loading, error}: any) => (
                  <SubmitButton
                    loading={loading}
                    color={colors.blue.interactive}
                    onPress={handleSubmit}
                  >
                    <SubmitText>Confirm</SubmitText>
                  </SubmitButton>
                )}
              </Mutation>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

JobRequestConvertToPendingModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestConvertToPendingModal.fragment = gql`
  fragment JobRequestConvertToPendingModal on JobRequest {
    id
  }
`;

JobRequestConvertToPendingModal.mutation = gql`
  mutation JobRequestConvertToPendingModal(
    $jobRequestId: Int!,
  ) {
    response: convertJobRequestToPending(
      jobRequestId: $jobRequestId,
    ) {
      ${gql.errors}
      jobRequest {
        id
      }
    }
  }
`;

export default JobRequestConvertToPendingModal;
