// Libararies
import _ from 'lodash';
import React from 'react';

// App
import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const CreateProjectTypeSuccessModal = ({
  form,
  field,
  isOpen,
  handleClose,
  handlePrimaryAction,
  primaryActionText,
  secondaryActionText,
}: any) => {
  const projectTypeName = _.get(form.values, `${field}.name`);
  return (
    <SuccessModal
      title={`${projectTypeName} Created`}
      subtitle={`Your project type has been successfully created.`}
      isOpen={isOpen}
      handlePrimaryAction={handlePrimaryAction}
      handleSecondaryAction={handleClose}
      primaryActionText={primaryActionText}
      secondaryActionText={secondaryActionText}
    />
  );
};

export default CreateProjectTypeSuccessModal;
