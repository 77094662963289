// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {Styled, Icon, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useQuery,
  useNavigationDOM,
  usePopover,
  useEffect,
  useResponsive,
  useScrollView,
  ResponsiveType,
  ScrollViewType,
} from '@supermove/hooks';
import {OrganizationModel, UserModel} from '@supermove/models';
import {SupermoveNavigatorType} from '@supermove/navigation/src/NavigationTypes';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import IconButton from '@shared/design/components/IconButton';
import Line from '@shared/design/components/Line';
import Tabs from '@shared/design/components/Tabs';
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';
import CalendarKind from '@shared/modules/Settings/enums/CalendarKind';
import {PageLoadingIndicator} from 'modules/App/components';
import {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BookingCalendar from 'modules/Calendar/Booking/components/BookingCalendar';
import CapacityCalendarPage from 'modules/Calendar/Capacity/CapacityCalendarPage';
import JobsCalendar from 'modules/Calendar/components/JobsCalendar';
import MoveProjectsTab from 'modules/Project/List/MoveProjectsTab';

const Container = Styled.View`
  flex: 1;
`;

const MobileHeaderContainer = Styled.View`
  padding: 16px;
  flex-direction: row;
  align-items: center;
`;

const NotificationDot = Styled.View<{responsive: ResponsiveType}>`
  background-color: ${colors.red.warning};
  height: ${({responsive}) => (responsive.desktop ? 16 : 20)}px;
  width: ${({responsive}) => (responsive.desktop ? 16 : 20)}px;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
`;

const NotificationText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.white};
`;

const MobileHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const getSearchParams = (navigator: SupermoveNavigatorType) => {
  const search = _.replace(navigator.location.search, '?', '');
  return qs.parse(search);
};

const useScrollToTopOnPageChange = ({mobileMoveProjectsScrollView, params}: any) => {
  // mobileMoveProjectsScrollView is only passed in when on the mobile view
  // of MoveProjectsTab
  useEffect(() => {
    if (mobileMoveProjectsScrollView.ref.current) {
      mobileMoveProjectsScrollView.handleScrollTo({animated: false, y: 0});
    }
  }, [params.pagination?.page]);
};

const BookingCalendarPage = ({navigator, viewer}: any) => (
  <Container style={{zIndex: 100}}>
    <BookingCalendar searchParams={getSearchParams(navigator)} viewer={viewer} isHeaderHidden />
  </Container>
);

type MovesPageTabType = {
  label: string;
  url: string;
  component: React.ReactElement;
};

const getTabDefinitions = ({
  organization,
  viewer,
  navigator,
  responsive,
  isEnabledResponsiveProjectsList,
  mobileMoveProjectsScrollView,
}: {
  organization: OrganizationModel;
  viewer: UserModel;
  navigator: SupermoveNavigatorType;
  responsive: ResponsiveType;
  isEnabledResponsiveProjectsList: boolean;
  mobileMoveProjectsScrollView: ScrollViewType;
}): MovesPageTabType[] => {
  const capacityCalendarTab = [
    {
      label: 'Capacity',
      url: '/moves/capacity/calendar',
      component: (
        <CapacityCalendarPage
          organization={organization}
          mobileMoveProjectsScrollView={mobileMoveProjectsScrollView}
        />
      ),
    },
  ];

  const movesCalendarTab = List.insertIf(organization.settings.isMovesCalendarEnabled, {
    label: 'Calendar',
    url: '/calendar',
    component: responsive.mobile ? (
      <ScrollView>
        <JobsCalendar searchParams={getSearchParams(navigator)} viewer={viewer} isHeaderHidden />
      </ScrollView>
    ) : (
      <JobsCalendar searchParams={getSearchParams(navigator)} viewer={viewer} isHeaderHidden />
    ),
  });

  const primaryTab =
    organization.settings.primaryCalendar === CalendarKind.CAPACITY
      ? capacityCalendarTab
      : movesCalendarTab;
  const secondaryTab =
    organization.settings.primaryCalendar === CalendarKind.CAPACITY
      ? movesCalendarTab
      : capacityCalendarTab;

  return [
    ...primaryTab,
    ...secondaryTab,
    ...List.insertIf(organization.settings.isBookingCalendarEnabled, {
      label: 'Booking',
      url: '/booking/calendar',
      component: <BookingCalendarPage navigator={navigator} viewer={viewer} />,
    }),
    {
      label: 'Projects',
      url: isEnabledResponsiveProjectsList ? '/moves/projects' : '/moves/list',
      component: <MoveProjectsTab />,
    },
  ];
};

const ListProjectsPageNavigationTabs = ({
  tabs,
  currentTabIndex,
}: {
  tabs: MovesPageTabType[];
  currentTabIndex: number;
}) => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <Tabs<MovesPageTabType>
        tabs={tabs}
        handlePressTab={(tab) => navigator.push(tab.url)}
        activeTabIndex={currentTabIndex}
        style={{paddingLeft: 24, paddingRight: 24}}
      />
      <Line />
    </React.Fragment>
  );
};

const MobileHeader = ({jobRequestsCount}: {jobRequestsCount: number}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const actionMenuPopover = usePopover();

  return (
    <MobileHeaderContainer>
      <MobileHeaderText responsive={responsive}>Moves</MobileHeaderText>
      <Space flex={1} />
      <Button
        text={'Create'}
        iconLeft={Icon.Plus}
        onPress={() => navigator.push('/projects/new')}
      />
      <Space width={16} />
      <ActionMenuPopover
        popover={actionMenuPopover}
        actions={[
          {
            text: 'View Requests',
            onPress: () => navigator.push('/jobs/requests/new'),
            rightComponent: (
              <NotificationDot responsive={responsive}>
                <NotificationText responsive={responsive} style={{lineHeight: '0px'}}>
                  {jobRequestsCount}
                </NotificationText>
              </NotificationDot>
            ),
          },
        ]}
      >
        <IconButton source={'ellipsis-v'} onPress={actionMenuPopover.handleOpen} />
      </ActionMenuPopover>
    </MobileHeaderContainer>
  );
};

const MobilePageTabs = ({
  tabs,
  currentTabIndex,
  organization,
}: {
  tabs: MovesPageTabType[];
  currentTabIndex: number;
  organization: OrganizationModel;
}) => {
  const {navigator} = useNavigationDOM();
  const {isEnabledMobileCapacityCalendar, isEnabledResponsiveProjectsList} = organization.features;
  const {isBookingCalendarEnabled} = organization.settings;

  // If capacity calendar and projects list are updated for responsive, we can use FullWidthTabs.
  // Otherwise the tabs will bounce around between full width and non full width
  const isFullWidthTabs =
    isEnabledMobileCapacityCalendar && isEnabledResponsiveProjectsList && !isBookingCalendarEnabled;
  if (isFullWidthTabs) {
    return (
      <FullWidthTabs<MovesPageTabType>
        tabs={tabs}
        handlePressTab={(tab) => navigator.push(tab.url)}
        activeTabIndex={currentTabIndex}
      />
    );
  }
  return (
    <Tabs<MovesPageTabType>
      tabs={tabs}
      handlePressTab={(tab) => navigator.push(tab.url)}
      activeTabIndex={currentTabIndex}
      scrollViewStyle={{paddingLeft: 16, paddingRight: 16}}
    />
  );
};

const MovesPageTabContent = ({tabs, currentTabIndex}: {tabs: any; currentTabIndex: number}) => {
  return tabs[currentTabIndex].component;
};

const MovesPage = () => {
  const mobileMoveProjectsScrollView = useScrollView();
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const {data, refetch} = useQuery(MovesPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  // This is used to fix when we hit the back button on tabs for calendars
  // That causes pages to break since theres no slugs, we bypass this by force
  // Refetching the query which then the components below will mount the correct slugs param
  useEffect(() => {
    // slugs is not supported on move list
    if (!params.slugs && location.pathname.includes('capacity')) {
      refetch();
    }
  }, [params, refetch, location]);

  useScrollToTopOnPageChange({mobileMoveProjectsScrollView, params});

  if (!data) {
    return (
      <SidebarPageV2 selected={'moves'}>
        <PageLoadingIndicator />
      </SidebarPageV2>
    );
  }

  const {viewer} = data;
  const organization = viewer.viewingOrganization;
  const {isEnabledResponsiveProjectsList, isEnabledMobileCapacityCalendar} = organization.features;
  const tabs = getTabDefinitions({
    organization,
    viewer,
    navigator,
    responsive,
    isEnabledResponsiveProjectsList,
    mobileMoveProjectsScrollView,
  });
  const currentTabIndex = _.findIndex(tabs, (tab) =>
    navigator.location.pathname.startsWith(tab.url),
  );

  const isV1ProjectsList = _.get(tabs, `${currentTabIndex}.url`) === '/moves/list';
  const isCapacityCalendar = _.get(tabs, `${currentTabIndex}.url`) === '/moves/capacity/calendar';
  const isBookingCalendar = _.get(tabs, `${currentTabIndex}.url`) === '/booking/calendar';
  const isMobileFullPageScroll =
    !responsive.desktop &&
    !isBookingCalendar &&
    !isV1ProjectsList &&
    (!isCapacityCalendar || isEnabledMobileCapacityCalendar);
  const jobRequestsCount = data.jobRequestCounter.new;

  if (currentTabIndex === -1) {
    navigator.push('/');
  }

  return (
    <SidebarPageV2 selected={'moves'}>
      {isMobileFullPageScroll ? (
        <ScrollView style={{flex: 1}} ref={mobileMoveProjectsScrollView.ref}>
          <MobileHeader jobRequestsCount={jobRequestsCount} />
          <MobilePageTabs
            tabs={tabs}
            currentTabIndex={currentTabIndex}
            organization={organization}
          />
          <Line />
          <MovesPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
        </ScrollView>
      ) : (
        <Container>
          <StandardOfficeHeader title={'Moves'} showLine={false}>
            <OfficeHeaderBuilder.Content style={{flex: 1}}>
              {isEnabledResponsiveProjectsList && responsive.desktop ? (
                <React.Fragment>
                  <Space flex={1} />
                  <SecondaryButton
                    text={'View Requests'}
                    onPress={() => navigator.push('/jobs/requests/new')}
                  >
                    {jobRequestsCount > 0 && (
                      <React.Fragment>
                        <Space width={8} />
                        <NotificationDot responsive={responsive}>
                          <NotificationText responsive={responsive} style={{lineHeight: '0px'}}>
                            {jobRequestsCount}
                          </NotificationText>
                        </NotificationDot>
                      </React.Fragment>
                    )}
                  </SecondaryButton>
                  <Space width={16} />
                  <Button
                    iconLeft={Icon.Plus}
                    text={'Create Project'}
                    onPress={() => navigator.push('/projects/new')}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Space width={24} />
                  <SecondaryButton
                    text={'New Project'}
                    iconLeft={Icon.Plus}
                    onPress={() => navigator.push('/projects/new')}
                  />
                </React.Fragment>
              )}
            </OfficeHeaderBuilder.Content>
          </StandardOfficeHeader>
          {currentTabIndex !== -1 && (
            <React.Fragment>
              <ListProjectsPageNavigationTabs tabs={tabs} currentTabIndex={currentTabIndex} />
              <MovesPageTabContent tabs={tabs} currentTabIndex={currentTabIndex} />
            </React.Fragment>
          )}
        </Container>
      )}
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MovesPage.query = gql`
  ${BookingCalendar.fragment}
  ${CapacityCalendarPage.fragment}
  ${JobsCalendar.fragment}
  query MovesPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        slug
        settings {
          id
          isBookingCalendarEnabled
          isMovesCalendarEnabled
          isMovesTabPrimary
          primaryCalendar
        }
        features {
          isEnabledResponsiveProjectsList: isEnabled(feature: "RESPONSIVE_PROJECTS_LIST")
          isEnabledMobileCapacityCalendar: isEnabled(feature: "MOBILE_CAPACITY_CALENDAR")
        }
        ...CapacityCalendarPage
      }
      ...BookingCalendar
      ...JobsCalendar
    }
    jobRequestCounter {
      new
    }
  }
`;

export default MovesPage;
