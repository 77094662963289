// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';

const NoResults = ({onPress, searchQuery}: any) => {
  return (
    <React.Fragment>
      <Space height={2} />
      <TertiaryButton
        text={`Create '${searchQuery}'`}
        onPress={() => onPress(searchQuery)}
        isResponsive
      />
    </React.Fragment>
  );
};

const AddRoomSheet = ({
  sheet,
  inventoryRoomsForm,
  roomTypes,
  inventoryId,
  setFilteredCategoryIds,
}: any) => {
  const roomCreatedToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Room added!',
    duration: 3000,
  });
  return (
    <DropdownSheet
      isOpen={sheet.isOpen}
      handleClose={sheet.handleClose}
      headerText={'Add Room'}
      isSearchable
      isFixedHeight
      height={'85%'}
      options={[
        ...roomTypes.map((roomType: any) => ({
          label: roomType.name,

          handleAction: () => {
            const newRoomItemsForm = InventoryRoomsForm.addRoomToInventoryRoomsForm({
              inventoryId,
              inventoryRoomsForm,
              roomType,
              setFilteredCategoryIds,
            });
            roomCreatedToast.handleToast({message: `${(newRoomItemsForm as any).name} added!`});
            sheet.handleClose();
          },
        })),
      ]}
      NoResultsComponent={NoResults}
      noResultsComponentProps={{
        onPress: (searchQuery: any) => {
          const newRoomItemsForm = InventoryRoomsForm.addRoomToInventoryRoomsForm({
            inventoryId,
            inventoryRoomsForm,
            roomType: {
              id: null,
              name: searchQuery,
            },
            setFilteredCategoryIds,
          });
          roomCreatedToast.handleToast({message: `${(newRoomItemsForm as any).name} added!`});
          sheet.handleClose();
        },
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddRoomSheet.fragment = gql`
  ${InventoryRoomsForm.addRoomToInventoryRoomsForm.fragment}
  fragment AddRoomSheet on RoomType {
    id
    name
    ...InventoryRoomsForm_addRoomToInventoryRoomsForm
  }
`;

export default AddRoomSheet;
