// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSearch} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CrewInfoContent from 'modules/Dispatch/Calendar/Day/components/CrewInfoContent';

const ItemContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 8px;
`;

const SearchBarInput = Styled.TextInput.H7`
  border-width: 0;
  background-color: ${colors.alpha(colors.gray.border, 0.5)};
  color: ${colors.black}
  padding-left: 28px;
`;

const SearchContainer = Styled.View`
  margin-horizontal: 8px;
`;

const SearchIconContainer = Styled.View`
  position: absolute;
  top: 14px;
  left: 8px;
`;

const SearchCrewsList = ({crews, refetchCalendar, canAssignOrganization}: any) => {
  const {
    query,
    results: filteredCrews,
    setQuery,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: crews,
    options: {keys: ['job.identifier', 'job.customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <SearchBarInput
          placeholder={'Customer name, job name'}
          placeholderTextColor={colors.gray.secondary}
          onChangeText={(text: any) => setQuery(text)}
          value={query}
        />
        <SearchIconContainer>
          <Icon color={colors.gray.secondary} size={Icon.Sizes.Small} source={Icon.Search} />
        </SearchIconContainer>
      </SearchContainer>
      <Space height={8} />
      <ScrollView>
        {filteredCrews.map((crew) => {
          return (
            <ItemContainer key={(crew as any).id}>
              <CrewInfoContent
                crew={crew}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchCrewsList.fragment = gql`
  ${CrewInfoContent.fragment}

  fragment SearchCrewsList on Crew {
    id
    job {
      id
      identifier
      customer {
        id
        fullName
      }
    }
    ...CrewInfoContent
  }
`;

export default SearchCrewsList;
