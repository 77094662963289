// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useResponsive, useState} from '@supermove/hooks';
import {CostCategory} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';
import EditCostCostItemsModal from 'modules/Cost/components/EditCostCostItemsModal';

const Row = Styled.View`
  flex-direction: row;
`;

const Button = Styled.ButtonV2`
`;

const TableContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  background-color: ${colors.white}
`;

const TableRow = Styled.View`
  flex-direction: row;
  padding-left: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 8 : 16)}px;
  padding-right: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 0 : 24)}px;
  padding-vertical: 8px;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const Cell = Styled.View<{flex?: number; justify?: string}>`
  flex: ${({flex}) => flex};
  flex-direction: row;
  align-items: center;
  justify-content: ${({justify}) => justify};
  padding-horizontal: 8px;
`;

const BodyText = Styled.Text`
  ${Typography.Body}
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label}
`;

const MobileMicroText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const CompensationCostsTableContainer = Styled.View`
  padding-vertical: 4px;
  background-color: ${colors.gray.background};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const CardContainer = Styled.View`
  padding-vertical: 8px;
`;

const getRole = (cost: any) =>
  cost.moverPosition ? cost.moverPosition.role : cost.compensationUser.role;

const getCostsByRole = ({costs}: any) => {
  const roleToCosts = _.groupBy(costs, (cost) => getRole(cost));
  const userRolesWithCosts = _.filter(
    UserRole.getRolesForCompensation(),
    (userRole) => roleToCosts[userRole],
  );
  const sortedUserRoleWithCosts = userRolesWithCosts.map((userRole) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    return _.sortBy(_.get(roleToCosts, userRole), ['displayName']);
  });
  return _.flatten(sortedUserRoleWithCosts);
};

const CellText = ({text, isMobileLabel}: any) => {
  const responsive = useResponsive();
  const MobileText = isMobileLabel ? MobileLabelText : MobileMicroText;
  const Text = responsive.desktop ? BodyText : MobileText;
  return <Text>{text}</Text>;
};

const CostCategoryDisplayKind = ({costCategory}: any) => {
  return <CellText text={CostCategory.getDisplayKind(costCategory)} isMobileLabel />;
};

const CostCategoryTotal = ({costCategory}: any) => {
  return <CellText text={Currency.display(costCategory.totalCost)} />;
};

const CostCategoryPercentOfRevenue = ({costCategory, totalRevenue}: any) => {
  return <CellText text={Percent.display(_.round(costCategory.totalCost / totalRevenue, 2))} />;
};

const CostCategoryCard = ({costCategory, totalRevenue, isCostAndCompensationEnabled}: any) => {
  return (
    <CardContainer>
      <Row style={{justifyContent: 'space-between'}}>
        <CostCategoryDisplayKind costCategory={costCategory} />
        <Row>
          <Space width={8} />
          <CostCategoryTotal costCategory={costCategory} />
        </Row>
      </Row>
      {isCostAndCompensationEnabled && (
        <React.Fragment>
          <Space height={8} />
          <CostCategoryPercentOfRevenue costCategory={costCategory} totalRevenue={totalRevenue} />
        </React.Fragment>
      )}
    </CardContainer>
  );
};

const CostTotalText = () => {
  const responsive = useResponsive();
  return <LabelText responsive={responsive}>Cost Total</LabelText>;
};

const CostTotalAmount = ({aggregateCost}: any) => {
  const responsive = useResponsive();
  return <LabelText responsive={responsive}>{Currency.display(aggregateCost.totalCost)}</LabelText>;
};

const CostTotalCard = ({aggregateCost}: any) => {
  return (
    <CardContainer>
      <Row style={{justifyContent: 'space-between'}}>
        <CostTotalText />
        <Row>
          <Space width={8} />
          <CostTotalAmount aggregateCost={aggregateCost} />
        </Row>
      </Row>
    </CardContainer>
  );
};

const PROJECT_COSTS_TABLE_COLUMNS = [
  {
    flex: 4,
    costCategoryFlex: 6,
    justify: 'flex-start',
    headerContent: () => {
      return <TableHeaderText>Category / Employee</TableHeaderText>;
    },
    cellContent: ({costCategory}: any) => {
      return <CostCategoryDisplayKind costCategory={costCategory} />;
    },
    footerContent: () => {
      return <CostTotalText />;
    },
  },
  {
    flex: 3,
    costCategoryFlex: 1,
    justify: 'flex-start',
    headerContent: () => {
      return <TableHeaderText>Role</TableHeaderText>;
    },
    cellContent: () => {
      return null;
    },
    footerContent: () => {
      return null;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    headerContent: () => {
      return <TableHeaderText style={{backgroundColor: 'transparent'}}>Cost</TableHeaderText>;
    },
    cellContent: ({costCategory}: any) => {
      return <CostCategoryTotal costCategory={costCategory} />;
    },
    footerContent: ({aggregateCost}: any) => {
      return <CostTotalAmount aggregateCost={aggregateCost} />;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    headerContent: ({responsive}: any) => {
      return (
        <TableHeaderText style={{backgroundColor: 'transparent'}}>{`${
          responsive.mobile ? '%/Rev' : '% of Revenue'
        }`}</TableHeaderText>
      );
    },
    cellContent: ({costCategory, totalRevenue}: any) => {
      return (
        <CostCategoryPercentOfRevenue costCategory={costCategory} totalRevenue={totalRevenue} />
      );
    },
    footerContent: ({aggregateCost, totalRevenue}: any) => {
      return (
        <LabelText>{Percent.display(_.round(aggregateCost.totalCost / totalRevenue, 2))}</LabelText>
      );
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    headerContent: () => {
      return <TableHeaderText>Edit</TableHeaderText>;
    },
    cellContent: ({costCategory, handleEditCost}: any) => {
      const firstCostId = _.get(costCategory, 'costs.0.id');
      return (
        costCategory.kind === CostCategoryKind.OTHER && (
          <Button style={{paddingRight: 4}} onPress={() => handleEditCost(firstCostId)}>
            <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
          </Button>
        )
      );
    },
    footerContent: () => {
      return null;
    },
  },
];

const EmployeeName = ({cost}: any) => {
  return <CellText text={cost.displayName} isMobileLabel />;
};

const EmployeeRole = ({cost}: any) => {
  return <CellText text={UserRole.getDisplayRole(getRole(cost))} />;
};

const EmployeeCost = ({cost}: any) => {
  return <CellText text={Currency.display(cost.total)} />;
};

const EmployeePercentOfRevenue = ({cost, totalRevenue}: any) => {
  return <CellText text={Percent.display(_.round(cost.total / totalRevenue, 2))} />;
};

const EmployeeCompensationCard = ({cost, totalRevenue, isCostAndCompensationEnabled}: any) => {
  return (
    <CardContainer>
      <Row style={{justifyContent: 'space-between'}}>
        <EmployeeName cost={cost} />
        <Row>
          <Space width={8} />
          <EmployeeRole cost={cost} />
        </Row>
      </Row>
      <Space height={8} />
      <EmployeeCost cost={cost} />
      {isCostAndCompensationEnabled && (
        <React.Fragment>
          <Space height={8} />
          <EmployeePercentOfRevenue cost={cost} totalRevenue={totalRevenue} />
        </React.Fragment>
      )}
    </CardContainer>
  );
};

const COMPENSATION_TABLE_COLUMNS = [
  {
    flex: 4,
    justify: 'flex-start',
    cellContent: ({cost}: any) => {
      return <EmployeeName cost={cost} />;
    },
  },
  {
    flex: 3,
    justify: 'flex-start',
    cellContent: ({cost}: any) => {
      return <EmployeeRole cost={cost} />;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    cellContent: ({cost}: any) => {
      return <EmployeeCost cost={cost} />;
    },
  },
  {
    flex: 2,
    justify: 'flex-end',
    cellContent: ({cost, totalRevenue}: any) => {
      return <EmployeePercentOfRevenue cost={cost} totalRevenue={totalRevenue} />;
    },
  },
  {
    flex: 1,
    justify: 'flex-end',
    cellContent: ({cost, handleEditCost}: any) => {
      return (
        <Button style={{paddingRight: 4}} onPress={() => handleEditCost(cost.id)}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={14} />
        </Button>
      );
    },
  },
];

const CompensationCostRow = ({
  cost,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}: any) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TableRow ref={ref} isHovered={isHovered} {...responsive}>
      {COMPENSATION_TABLE_COLUMNS.map((column, index) => {
        const isRevenueFeature = index === 3;
        if (isRevenueFeature && !isCostAndCompensationEnabled) {
          return null;
        }
        return (
          <Cell
            key={index}
            flex={(column as any).costCategoryFlex || column.flex}
            justify={column.justify}
          >
            {column.cellContent({cost, totalRevenue, handleEditCost})}
          </Cell>
        );
      })}
    </TableRow>
  );
};

const CompensationCostsTable = ({
  costs,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}: any) => {
  const costsByRole = getCostsByRole({costs});
  return (
    <CompensationCostsTableContainer>
      {costsByRole.map((cost, index) => {
        return (
          <CompensationCostRow
            key={index}
            cost={cost}
            totalRevenue={totalRevenue}
            handleEditCost={handleEditCost}
            isCostAndCompensationEnabled={isCostAndCompensationEnabled}
          />
        );
      })}
    </CompensationCostsTableContainer>
  );
};

const CostCategoryRow = ({
  costCategory,
  totalRevenue,
  handleEditCost,
  isCostAndCompensationEnabled,
}: any) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const isCompensation = costCategory.kind === CostCategoryKind.COMPENSATION;
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <TableRow ref={ref} isHovered={isHovered} {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          const isRevenueFeature = index === 3;
          if (isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell
              key={index}
              flex={column.costCategoryFlex || column.flex}
              justify={column.justify}
            >
              {column.cellContent({costCategory, totalRevenue, handleEditCost})}
            </Cell>
          );
        })}
      </TableRow>
      {isCompensation && !_.isEmpty(costCategory.costs) && (
        <React.Fragment>
          <Line />
          <CompensationCostsTable
            costs={costCategory.costs}
            totalRevenue={totalRevenue}
            handleEditCost={handleEditCost}
            isCostAndCompensationEnabled={isCostAndCompensationEnabled}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ProjectCostsTable = ({project, refetch}: any) => {
  const [costId, setCostId] = useState();
  const editCostCostItemsModal = useModal();
  const responsive = useResponsive();
  const {aggregateCost, totalRevenue} = project;
  const {isCostAndCompensationEnabled} = project.organization.settings;
  return (
    <TableContainer>
      <TableRow {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          const isRevenueFeature = index === 3;
          if (isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell key={index} flex={column.flex} justify={column.justify}>
              {column.headerContent({responsive})}
            </Cell>
          );
        })}
      </TableRow>
      {aggregateCost.costCategories.map((costCategory: any, index: any) => {
        return (
          <React.Fragment key={index}>
            <Line />
            <CostCategoryRow
              costCategory={costCategory}
              totalRevenue={totalRevenue}
              handleEditCost={(costId: any) => {
                setCostId(costId);
                editCostCostItemsModal.handleOpen();
              }}
              isCostAndCompensationEnabled={isCostAndCompensationEnabled}
            />
          </React.Fragment>
        );
      })}
      <Line />
      <TableRow {...responsive}>
        {PROJECT_COSTS_TABLE_COLUMNS.map((column, index) => {
          const isRevenueFeature = index === 3;
          if (isRevenueFeature && !isCostAndCompensationEnabled) {
            return null;
          }
          return (
            <Cell key={index} flex={column.flex} justify={column.justify}>
              {column.footerContent({aggregateCost, totalRevenue})}
            </Cell>
          );
        })}
      </TableRow>
      <EditCostCostItemsModal
        key={editCostCostItemsModal.key}
        isOpen={editCostCostItemsModal.isOpen}
        handleClose={editCostCostItemsModal.handleClose}
        costId={costId}
        aggregateCost={aggregateCost}
        refetch={refetch}
      />
    </TableContainer>
  );
};

const CostAndCompensationCardList = ({project}: any) => {
  const {aggregateCost, totalRevenue, organization} = project;
  const {costCategories} = aggregateCost;
  const {isCostAndCompensationEnabled} = organization.settings;

  return (
    <React.Fragment>
      {costCategories.map((costCategory: any, costCategoryIndex: any) => {
        if (costCategory.kind === CostCategoryKind.COMPENSATION && !_.isEmpty(costCategory.costs)) {
          const costsByRole = getCostsByRole({costs: costCategory.costs});
          return costsByRole.map((cost) => (
            <React.Fragment key={cost.id}>
              <Line />
              <EmployeeCompensationCard
                cost={cost}
                totalRevenue={totalRevenue}
                isCostAndCompensationEnabled={isCostAndCompensationEnabled}
              />
            </React.Fragment>
          ));
        } else {
          return (
            <React.Fragment key={costCategoryIndex}>
              <Line />
              <CostCategoryCard
                costCategory={costCategory}
                totalRevenue={totalRevenue}
                isCostAndCompensationEnabled={isCostAndCompensationEnabled}
              />
            </React.Fragment>
          );
        }
      })}
      <Line />
      <CostTotalCard aggregateCost={aggregateCost} />
    </React.Fragment>
  );
};

ProjectCostsTable.Mobile = CostAndCompensationCardList;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectCostsTable.fragment = gql`
  ${CostCategory.getDisplayKind.fragment}
  ${EditCostCostItemsModal.fragment}
  fragment ProjectCostsTable on Project {
    id
    totalRevenue
    aggregateCost {
      totalCost
      costCategories {
        kind
        totalCost
        costs {
          id
          displayName
          total
          moverPosition {
            id
            role
          }
          compensationUser {
            id
            role
          }
        }
        ...CostCategory_getDisplayKind
      }
      ...EditCostCostItemsModal
    }
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

(ProjectCostsTable.Mobile as any).fragment = gql`
  fragment ProjectCostsTable_Mobile on Project {
    id
    totalRevenue
    aggregateCost {
      totalCost
      costCategories {
        kind
        totalCost
        costs {
          id
          displayName
          total
          moverPosition {
            id
            role
          }
          compensationUser {
            id
            role
          }
        }
        ...CostCategory_getDisplayKind
      }
    }
    organization {
      id
      settings {
        id
        isCostAndCompensationEnabled
      }
    }
  }
`;

export default ProjectCostsTable;
