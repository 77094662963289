// Libraries
import React from 'react';

// Supermove
import {Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useModal, useSidebar} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {pluralize, Datetime} from '@supermove/utils';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectCommentsSidebar from 'modules/Project/components/ProjectCommentsSidebar';

const Container = Styled.View`
`;

const Section = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Subject = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const Body = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Link = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const CommentMetaData = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const CommentLoading = () => {
  return (
    <Container>
      <Space height={24} />
      <PageLoadingIndicator />
      <Space height={32} />
    </Container>
  );
};

const getSummaryCommentText = (commentsCount: any) => {
  const numberOfAdditionalComments = commentsCount - 1;
  if (numberOfAdditionalComments <= 0) {
    return 'Add comment →';
  }
  return `${pluralize('more comment', numberOfAdditionalComments, true)} →`;
};

const CommentLink = ({job, commentsCount}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal();
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <Link onPress={handleOpen}>
        <LinkText>{getSummaryCommentText(commentsCount)}</LinkText>
      </Link>
      <ProjectCommentsSidebar
        key={projectCommentsSidebar.key}
        isOpen={isOpen}
        handleClose={handleClose}
        projectUuid={job.project.uuid}
      />
    </React.Fragment>
  );
};

type JobCommentsBlockProps = {
  job: any;
};

const JobCommentsBlock = ({job}: JobCommentsBlockProps) => {
  const responsive = useResponsive();
  const {mostRecentComment, commentsCount} = job.project.officeThread;

  return (
    <Container>
      <Section>
        <Space height={12} />
        <Title>Comments</Title>
        {commentsCount === 0 ? (
          <React.Fragment>
            <Space height={16} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Row index={0} {...responsive}>
              <Body>No comments added yet.</Body>
            </Row>
            <Space height={16} />
          </React.Fragment>
        ) : (
          <Loading as={CommentLoading} loading={!mostRecentComment}>
            {() => (
              <React.Fragment>
                <Space height={16} />
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Row index={0} {...responsive}>
                  <Subject>{mostRecentComment.subject}</Subject>
                  <Space height={4} />
                  <Body numberOfLines={6}>{mostRecentComment.body}</Body>
                </Row>
                <Space height={16} />
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Row index={1} {...responsive}>
                  <CommentMetaData>{mostRecentComment.user.fullName}</CommentMetaData>
                  <CommentMetaData>
                    {Datetime.convertToDisplayDatetime(
                      mostRecentComment.createdAt,
                      Datetime.DISPLAY_DATETIME,
                    )}
                  </CommentMetaData>
                </Row>
                <Space height={16} />
              </React.Fragment>
            )}
          </Loading>
        )}
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row index={2} {...responsive}>
          <CommentLink job={job} commentsCount={commentsCount} />
        </Row>
        <Space height={16} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobCommentsBlock.fragment = gql`
  fragment JobCommentsBlock on Job {
    id
    project {
      id
      uuid
      officeThread {
        id
        commentsCount
        mostRecentComment {
          id
          subject
          body
          createdAt
          user {
            id
            fullName
          }
        }
      }
    }
  }
`;

export default JobCommentsBlock;
