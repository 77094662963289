// Libraries
import React from 'react';

// App
import {Modal, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {Navigation} from 'modules/App/components';

import CreateEmployeeForm from './CreateEmployeeForm';

const Overlay = Styled.View`
  flex: 1;
`;

const Wrapper = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
  padding-horizontal: 20px;
`;

const Container = Styled.View`
  width: 100%;
  margin-vertical: 60px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => ((props as any).mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CreateEmployeeModal = ({organization, refetch, trigger}: any) => {
  const responsive = useResponsive();

  return (
    <Modal onClose={refetch} trigger={trigger}>
      {({handleClose}: any) => (
        <Overlay pointerEvents={'box-none'}>
          <ScrollView
            contentContainerStyle={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Wrapper {...responsive}>
              <Container>
                <Header>
                  <HeaderBorderSection {...responsive}>
                    <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                  </HeaderBorderSection>
                  <HeaderMainSection>
                    <Title>New Crew Member</Title>
                  </HeaderMainSection>
                  <HeaderBorderSection />
                </Header>
                <CreateEmployeeForm organization={organization} onSuccess={handleClose} />
              </Container>
            </Wrapper>
          </ScrollView>
        </Overlay>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmployeeModal.fragment = gql`
  ${CreateEmployeeForm.fragment}

  fragment CreateEmployeeModal on Organization {
    id
    ...CreateEmployeeForm
  }
`;

export default CreateEmployeeModal;
