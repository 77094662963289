// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Drawer, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, List} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import useUpdateInvoiceMutation from '@shared/modules/Billing/hooks/useUpdateInvoiceMutation';
import InvoiceSettingsFields from 'modules/Storage/components/InvoiceSettingsFields';

const View = Styled.View``;

const DrawerContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background}
  width: 650px;
`;

const DrawerHeaderFooterContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white}
  padding-horizontal: 24px;
  max-height: 80px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.border};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray.border};
`;

const DrawerTitle = Styled.Text`
  ${Typography.Heading1}
`;

const WarningCallout = Styled.View`
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  border-color: ${colors.red.accent};
  background-color: ${colors.red.accent};
`;

const WarningText = Styled.Text`
  ${Typography.Body}
  color: ${colors.red.warning};
`;

const UpdateInvoiceDrawerContent = ({invoice, isOpen, handleClose, refetch}: any) => {
  const invoiceForm = InvoiceForm.edit(invoice);
  const {form, submitting, handleSubmit} = useUpdateInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const isToday = Datetime.isToday(_.get(form.values, 'invoiceForm.date'));

  const submitWithStatus = (status: any) => {
    form.setFieldValue(`invoiceForm.status`, status);
    setTimeout(() => handleSubmit(), 0);
  };

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <DrawerContainer>
        <DrawerHeaderFooterContainer>
          <DrawerTitle>Invoice Settings</DrawerTitle>
        </DrawerHeaderFooterContainer>
        <View style={{flex: 1, paddingHorizontal: '24px'}}>
          <Space height={16} />
          {(invoice.status === InvoiceStatus.PAID ||
            invoice.status === InvoiceStatus.FINALIZED) && (
            <React.Fragment>
              <WarningCallout>
                <WarningText color={colors.red.warning}>
                  You are making changes to a "Paid" invoice.
                </WarningText>
              </WarningCallout>
              <Space height={16} />
            </React.Fragment>
          )}
          <InvoiceSettingsFields
            form={form}
            field={'invoiceForm'}
            project={invoice.project}
            allowRecurring={false}
          />
        </View>
        <DrawerHeaderFooterContainer style={{justifyContent: 'flex-end'}}>
          <QuaternaryButton onPress={handleClose} text={'Cancel'} isDisabled={submitting} />
          <Space width={16} />
          <DropdownButton
            actions={[
              {
                text: `Save and ${isToday ? 'send' : 'schedule'}`,
                onPress: () => submitWithStatus(InvoiceStatus.SCHEDULED),
              },
              ...List.insertIf(
                invoice.status === InvoiceStatus.DRAFT ||
                  invoice.status === InvoiceStatus.SCHEDULED,
                {
                  text: 'Save as draft',
                  onPress: () => submitWithStatus(InvoiceStatus.DRAFT),
                },
              ),
            ]}
            text={'Save'}
            isSubmitting={submitting}
            menuWidth={150}
            menuPosition={DropdownButton.MENU_POSITION.TOP}
          />
        </DrawerHeaderFooterContainer>
      </DrawerContainer>
    </Drawer>
  );
};

const UpdateInvoiceDrawer = ({invoiceUuid, isOpen, handleClose, refetch}: any) => {
  const {loading, data} = useQuery(UpdateInvoiceDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {invoiceUuid},
  });

  if (loading || !data) {
    return null;
  }

  return (
    <UpdateInvoiceDrawerContent
      invoice={data.invoiceByUuid}
      isOpen={isOpen}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateInvoiceDrawer.query = gql`
  ${InvoiceForm.edit.fragment}
  ${InvoiceSettingsFields.fragment}

  query UpdateInvoiceDrawer($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      project {
        id
        ...InvoiceSettingsFields
      }
      ...InvoiceForm_edit
    }
  }
`;

export default UpdateInvoiceDrawer;
