// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  job: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UncancelJobMutation.defaultProps;

// @ts-expect-error TS(7022): 'UncancelJobMutation' implicitly has type 'any' be... Remove this comment to see the full error message
const UncancelJobMutation = ({job, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      jobId: job.id,
    }}
    mutation={UncancelJobMutation.mutation}
    onCompleted={({response: {job, errors}}: any) => {
      if (job) {
        onSuccess(job);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

UncancelJobMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UncancelJobMutation.mutation = gql`
  mutation UncancelJobMutation($jobId: Int!) {
    response: uncancelJob(jobId: $jobId) {
      ${gql.errors}
      job {
        id
      }
    }
  }
`;

export default UncancelJobMutation;
