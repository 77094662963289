/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Crew} from '@supermove/models';

// App
import JobInfoContent from 'modules/Dispatch/Calendar/Day/components/JobInfoContent';

const CrewInfoContent = ({
  isSlot,
  isAssignable,
  isSecondary,
  isFirst,
  isLast,
  crew,
  numberOfHours,
  canAssignOrganization,
  refetch,
}: any) => {
  return (
    <JobInfoContent
      isAssignable={isAssignable}
      isFullyAssigned={Crew.getIsFullyAssigned(crew)}
      isSlot={isSlot}
      isFirst={isFirst}
      isLast={isLast}
      numberOfHours={numberOfHours}
      organizationId={crew.organizationId}
      crewId={crew.id}
      job={crew.job}
      crewUsersCount={Crew.getJobUsersCount(crew)}
      crewTrucksCount={Crew.getJobTrucksCount(crew)}
      refetch={refetch}
      isSecondary={isSecondary}
      canAssignOrganization={canAssignOrganization}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewInfoContent.fragment = gql`
  ${Crew.getJobTrucksCount.fragment}
  ${Crew.getJobUsersCount.fragment}
  ${Crew.getJobUsersNames.fragment}
  ${Crew.getIsFullyAssigned.fragment}
  ${JobInfoContent.fragment}

  fragment CrewInfoContent on Crew {
    id
    organizationId
    job {
      id
      ...JobInfoContent
    }
    ...Crew_getJobTrucksCount
    ...Crew_getJobUsersCount
    ...Crew_getJobUsersNames
    ...Crew_getIsFullyAssigned
  }
`;

export default CrewInfoContent;
