// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useState,
  useNavigationDOM,
  usePopover,
  useQuery,
} from '@supermove/hooks';
import {ClaimType, Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Currency, List} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const ContentContainer = Styled.View`
  padding-horizontal: 24px;
`;

const Header = Styled.Text`
  ${Typography.Heading2}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  padding-bottom: 12px;
`;

const FiltersRow = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const FilterContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const getButtonText = ({
  urlFilters,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  return urlFilters.getFilterCountLabel({
    filterKeys: [
      'claimTypeIds',
      'projectTypeIds',
      'projectTagIds',
      'projectStatuses',
      'minAmount',
      'maxAmount',
      ...List.insertIf(
        isEnabledClaimsProjectOrganizationMigration &&
          organization.isPrimary &&
          organization.hasMultipleOrganizations,
        'slugs',
      ),
    ],
  });
};

const getProjectTypeDropdownOptions = (projectTypes: any) => {
  return _.map(projectTypes, (projectType) => ({
    label: projectType.name,
    description: projectType.organizationName,
    value: projectType.id,
  }));
};

const MultiBranchDropdownFilter = ({index, urlFilters, organization}: any) => {
  const organizations = _.get(organization, 'company.organizations', []);
  const {params} = useNavigationDOM();

  return (
    <MultiBranchOrganizationField
      label={'Branch'}
      width={'100%'}
      index={index}
      isPortaled={false}
      value={params.slugs || []}
      organizations={organizations}
      onChangeValue={(newSlugs: any) => {
        urlFilters.handleUpdate({slugs: newSlugs});
      }}
    />
  );
};

const AmountFilter = ({index, paramKey, label, value, setAmountValue, urlFilters}: any) => {
  const updateAmountParam = useDebouncedCallback((amount) => {
    urlFilters.handleUpdate({
      [paramKey]: Currency.toMutation(amount),
    });
  }, 500);
  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <CurrencyInput
        // @ts-expect-error TS(2322): Type '{ label: any; component: FC<any>; placeholde... Remove this comment to see the full error message
        label={label}
        component={FieldInput.TextInput}
        placeholder={'$0.00'}
        name={label}
        value={value}
        setFieldValue={() => {}}
        setFieldTouched={() => {}}
        onChangeText={(amount) => {
          setAmountValue(amount);
          updateAmountParam(amount);
        }}
        style={{
          width: 139,
        }}
      />
    </FilterContainer>
  );
};

const MinMaxAmountFilters = ({
  index,
  minAmountValue,
  setMinAmountValue,
  maxAmountValue,
  setMaxAmountValue,
  urlFilters,
}: any) => {
  return (
    <FiltersRow>
      <AmountFilter
        index={index}
        paramKey={'minAmount'}
        label={'Minimum'}
        value={minAmountValue}
        setAmountValue={setMinAmountValue}
        urlFilters={urlFilters}
      />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <AmountFilter
        index={index + 1}
        paramKey={'maxAmount'}
        label={'Maximum'}
        value={maxAmountValue}
        setAmountValue={setMaxAmountValue}
        urlFilters={urlFilters}
      />
    </FiltersRow>
  );
};

const ProjectStatusFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Project Status</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={ProjectStatus.OPTIONS}
        placeholder={'Select project status'}
        style={{flex: 1}}
        value={_.get(params, 'projectStatuses', [])}
        onChangeValue={(projectStatuses: any) => {
          urlFilters.handleUpdate({projectStatuses});
        }}
      />
    </FilterContainer>
  );
};

const ProjectTypeFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {data, loading} = useQuery(ClaimFiltersButton.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Project Type</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={
          data
            ? getProjectTypeDropdownOptions(
                Organization.getProjectTypesForMultibranchSlugs(
                  data.viewer.viewingOrganization,
                  params.slugs,
                ),
              )
            : []
        }
        isLoading={loading}
        placeholder={'Select project type'}
        style={{flex: 1}}
        value={_.get(params, 'projectTypeIds', [])}
        onChangeValue={(projectTypeIds: any) => {
          urlFilters.handleUpdate({projectTypeIds});
        }}
        isDescriptionBelow
      />
    </FilterContainer>
  );
};

const ProjectTagFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {data} = useQuery(ClaimFiltersButton.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <TagDropdownInputField
      options={
        data
          ? data.viewer.viewingOrganization.companySettings.tags
              .filter((tag: any) => !tag.isArchived && tag.category === TagCategory.PROJECT)
              .map((tag: any) => ({
                value: tag.id,
                label: `${tag.emoji} ${tag.name}`,
              }))
          : []
      }
      placeholder={'Select project tag'}
      label={'Project Tags'}
      index={index}
      value={_.get(params, 'projectTagIds', [])}
      onChangeValue={(projectTagIds: any) => {
        urlFilters.handleUpdate({projectTagIds});
      }}
    />
  );
};

const ClaimTypeFilter = ({index, urlFilters}: any) => {
  const {params} = useNavigationDOM();
  const {data, loading} = useQuery(ClaimFiltersButton.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <FilterContainer index={index}>
      <FieldInput.LabelText>Claim Type</FieldInput.LabelText>
      <Space height={4} />
      <MultiDropdownCheckboxInput
        options={
          data
            ? ClaimType.sortClaimTypes(
                data.viewer.viewingOrganization.companySettings.claimTypes,
              ).map((claimType) => ({
                value: (claimType as any).id,
                label: (claimType as any).name,
              }))
            : []
        }
        isLoading={loading}
        placeholder={'Select claim type'}
        style={{flex: 1}}
        value={_.get(params, 'claimTypeIds', [])}
        onChangeValue={(claimTypeIds: any) => {
          urlFilters.handleUpdate({claimTypeIds});
        }}
      />
    </FilterContainer>
  );
};

const ClearFiltersButton = ({
  setMinAmountValue,
  setMaxAmountValue,
  urlFilters,
  organization,
}: any) => {
  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() => {
        // Clear useState for amount filter
        setMinAmountValue('');
        setMaxAmountValue('');
        // Clear param filters
        urlFilters.handleUpdate({
          claimTypeIds: null,
          projectTypeIds: null,
          projectTagIds: null,
          projectStatuses: null,
          minAmount: null,
          maxAmount: null,
          slugs: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
        });
      }}
    />
  );
};

const ClaimFiltersPopoverContent = ({
  urlFilters,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  const {params} = useNavigationDOM();
  // Used because CurrencyInput needs a value
  const {minAmount, maxAmount} = params;
  const initialMinAmount = minAmount ? Currency.toForm(minAmount) : '';
  const initialMaxAmount = maxAmount ? Currency.toForm(maxAmount) : '';
  const [minAmountValue, setMinAmountValue] = useState(initialMinAmount);
  const [maxAmountValue, setMaxAmountValue] = useState(initialMaxAmount);

  return (
    <ResponsivePopover.StaticContainer width={346}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filters</Header>
        <Space height={16} />
        {isEnabledClaimsProjectOrganizationMigration &&
          organization.isPrimary &&
          organization.hasMultipleOrganizations && (
            <React.Fragment>
              <MultiBranchDropdownFilter
                index={0}
                urlFilters={urlFilters}
                organization={organization}
              />
              <Space height={16} />
            </React.Fragment>
          )}
        <ProjectTypeFilter index={1} urlFilters={urlFilters} />
        <Space height={16} />
        <ClaimTypeFilter index={2} urlFilters={urlFilters} />
        <Space height={16} />
        {organization.features.isEnabledProjectTag && (
          <React.Fragment>
            <ProjectTagFilter index={3} urlFilters={urlFilters} />
            <Space height={16} />
          </React.Fragment>
        )}
        <ProjectStatusFilter index={4} urlFilters={urlFilters} />
        <Space height={16} />
        <MinMaxAmountFilters
          index={5}
          minAmountValue={minAmountValue}
          setMinAmountValue={setMinAmountValue}
          maxAmountValue={maxAmountValue}
          setMaxAmountValue={setMaxAmountValue}
          urlFilters={urlFilters}
        />
        <Space height={16} />
        <ClearFiltersButton
          setMinAmountValue={setMinAmountValue}
          setMaxAmountValue={setMaxAmountValue}
          urlFilters={urlFilters}
          organization={organization}
        />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const ClaimFiltersPopover = ({
  popover,
  urlFilters,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ClaimFiltersPopoverContent
        urlFilters={urlFilters}
        organization={organization}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
      />
    </Popover>
  );
};

const ClaimFiltersButton = ({urlFilters, organization}: any) => {
  const claimFiltersPopover = usePopover({name: 'Claims Filter Popover'});
  const {isEnabledClaimsProjectOrganizationMigration} = organization.features;

  return (
    <React.Fragment>
      <Popover.Content innerRef={claimFiltersPopover.ref}>
        <SecondaryButton
          text={getButtonText({
            urlFilters,
            organization,
            isEnabledClaimsProjectOrganizationMigration,
          })}
          onPress={claimFiltersPopover.handleOpen}
          iconLeft={Icon.Filter}
          isResponsive
        />
      </Popover.Content>
      <ClaimFiltersPopover
        popover={claimFiltersPopover}
        urlFilters={urlFilters}
        organization={organization}
        isEnabledClaimsProjectOrganizationMigration={isEnabledClaimsProjectOrganizationMigration}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimFiltersButton.query = gql`
  ${Organization.getProjectTypesForMultibranchSlugs.fragment}
  ${ClaimType.sortClaimTypes.fragment}
  ${TagDropdownInputField.fragment}

  query ClaimFiltersButton {
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
        }
        companySettings {
          claimTypes {
            id
            name
            ...ClaimType_sortClaimTypes
          }
          tags {
            id
            name
            emoji
            isArchived
            category
            ...TagDropdownInputField
          }
        }
        ...Organization_getProjectTypesForMultibranchSlugs
      }
    }
  }
`;

ClaimFiltersButton.fragment = gql`
  ${MultiBranchOrganizationField.fragment}

  fragment ClaimFiltersButton on Organization {
    id
    slug
    isPrimary
    hasMultipleOrganizations
    features {
      isEnabledProjectTag: isEnabled(feature: "PROJECT_TAG")
      isEnabledClaimsProjectOrganizationMigration: isEnabled(
        feature: "CLAIMS_PROJECT_ORGANIZATION_MIGRATION"
      )
    }
    ...MultiBranchOrganizationField
  }
`;

export default ClaimFiltersButton;
