// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useSheet, SheetProps, useQuery, UrlFiltersType} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import DayMetricsAndNotes from 'modules/Calendar/Capacity/components/DayMetricsAndNotes';

const DayNotesSheet = ({
  urlFilters,
  organization,
  dayNotesSheet,
  refetch,
}: {
  urlFilters: UrlFiltersType;
  organization: OrganizationModel;
  dayNotesSheet: SheetProps;
  refetch: () => void;
}) => {
  const {params} = useNavigationDOM();
  const currentDatetime = Datetime.today;
  const date = params.date || Datetime.toDisplayDate(currentDatetime, Datetime.SERVER_DATE);

  const {data, loading} = useQuery(MobileCapacityCalendarDayNotesButton.query, {
    fetchPolicy: 'cache-and-network',
    skip: !dayNotesSheet.isOpen,
    variables: {
      day: date,
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
    },
  });

  return (
    <DayMetricsAndNotes.Sheet
      dayNotesSheet={dayNotesSheet}
      selectedDateText={Datetime.convertToDisplayDate(date, 'ddd, MMMM Do')}
      viewingOrganization={data?.viewer?.viewingOrganization}
      capacityCalendarSingleDay={data?.capacityCalendarSingleDay}
      days={data?.days}
      isLoading={loading || !data}
      params={params}
      selectedDate={date}
      refetchDayView={refetch}
      urlFilters={urlFilters}
    />
  );
};

const MobileCapacityCalendarDayNotesButton = ({
  urlFilters,
  organization,
  refetch,
}: {
  urlFilters: UrlFiltersType;
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  const dayNotesSheet = useSheet({name: 'Day Notes Sheet'});

  return (
    <React.Fragment>
      <SecondaryButton
        iconLeft={Icon.NoteSticky}
        iconSize={14}
        isResponsive
        style={{padding: 14}}
        onPress={dayNotesSheet.handleOpen}
      />
      <DayNotesSheet
        urlFilters={urlFilters}
        organization={organization}
        dayNotesSheet={dayNotesSheet}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

MobileCapacityCalendarDayNotesButton.query = gql`
  ${DayMetricsAndNotes.fragment}

  query MobileCapacityCalendarDayNotesButton($day: String!, $slugs: [String!]!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...DayMetricsAndNotes_Organization
      }
    }
    capacityCalendarSingleDay(day: $day, slugs: $slugs) {
      ...DayMetricsAndNotes_CapacityCalendar
	  }
    days(date: $day, slugs: $slugs) {
      id
      ...DayMetricsAndNotes_Day
    }
  }
`;

MobileCapacityCalendarDayNotesButton.fragment = gql`
  fragment MobileCapacityCalendarDayNotesButton on Organization {
    id
    slug
    isPrimary
  }
`;

export default MobileCapacityCalendarDayNotesButton;
