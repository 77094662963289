// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeForm from '@shared/modules/Billing/forms/BillItemTypeForm';
import useUpdateBillItemTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillItemTypeMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import BillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeDrawer';
import EditBillItemTypeFields from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeFields';

const getHeaderText = (category: any) => {
  if (category === BillItemTypeCategory.SUPPLIES) {
    return 'Edit Supply';
  }
  if (category === BillItemTypeCategory.FEES) {
    return 'Edit Fee';
  }
  return 'Edit Discount';
};

const EditBillItemTypeDrawer = ({
  billItemType,
  refetch,
  userId,
  isOpen,
  handleClose,
  showGlobalEditWarning,
}: any) => {
  const {data, loading} = useQuery(EditBillItemTypeDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {organizationId: billItemType.organizationId},
  });
  const {category} = billItemType;
  const billItemTypeForm = BillItemTypeForm.edit(billItemType, {userId});
  const {form, submitting, handleSubmit} = useUpdateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <BillItemTypeDrawer
      isOpen={isOpen}
      isLoading={loading}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={getHeaderText(category)}
    >
      {!loading && data && (
        <EditBillItemTypeFields
          form={form}
          field={'billItemTypeForm'}
          category={category}
          formulas={data.organization.formulas}
          moverPositions={
            data.organization.features.isEnabledMoverPositionMultibranch
              ? Organization.getCompanySettingsMoverPositionsByRole({
                  organization: data.organization,
                  role: UserRole.EMPLOYEE,
                })
              : data.organization.moverPositions
          }
          organization={data.organization}
          showGlobalEditWarning={showGlobalEditWarning}
        />
      )}
    </BillItemTypeDrawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemTypeDrawer.query = gql`
  ${EditBillItemTypeFields.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  query EditBillItemTypeDrawer($organizationId: Int!) {
    organization(organizationId: $organizationId) {
      id
      moverPositions {
        id
        ...EditBillItemTypeFields_MoverPosition
      }
      formulas {
        id
        ...EditBillItemTypeFields_Formula
      }
      features {
        isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
      }
      ...Organization_getCompanySettingsMoverPositionsByRole
      ...EditBillItemTypeFields_Organization
    }
  }
`;

EditBillItemTypeDrawer.fragment = gql`
  ${BillItemTypeForm.edit.fragment}
  fragment EditBillItemTypeDrawer on BillItemType {
    id
    category
    organizationId
    ...BillItemTypeForm_edit
  }
`;

export default EditBillItemTypeDrawer;
