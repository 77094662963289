// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import LargeModal from 'modules/App/Modal/components/LargeModal';
import SendJobDetailsToSpecificUsersModalContent from 'modules/Day/Jobs/components/SendJobDetailsToSpecificUsersModalContent';

const SendJobDetailsToSpecificUsersModal = ({isOpen, job, onClose}: any) => {
  const {loading, data} = useQuery(SendJobDetailsToSpecificUsersModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  return (
    <LargeModal isOpen={isOpen} handleClose={onClose}>
      <Loading loading={loading || !data}>
        {() => (
          <SendJobDetailsToSpecificUsersModalContent
            isOpen={isOpen}
            job={job}
            onClose={onClose}
            viewerId={data.viewer.id}
          />
        )}
      </Loading>
    </LargeModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendJobDetailsToSpecificUsersModal.query = gql`
  query SendJobDetailsToSpecificUsersModal {
    ${gql.query}
    viewer {
      id
    }
  }
`;

SendJobDetailsToSpecificUsersModal.fragment = gql`
  ${SendJobDetailsToSpecificUsersModalContent.fragment}

  fragment SendJobDetailsToSpecificUsersModal on Job {
    id
    ...SendJobDetailsToSpecificUsersModalContent
  }
`;

export default SendJobDetailsToSpecificUsersModal;
