// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useResponsive, useToast} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency, pluralize} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryForm from '@shared/modules/Inventory/forms/InventoryForm';
import InventorySurveyMethodForm from '@shared/modules/Inventory/forms/InventorySurveyMethodForm';
import useSyncInventoryBillMutation from '@shared/modules/Inventory/hooks/useSyncInventoryBillMutation';
import useUpdateInventorySurveyMethodMutation from '@shared/modules/Inventory/hooks/useUpdateInventorySurveyMethodMutation';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import ProjectWidgetKind from '@shared/modules/Project/enums/ProjectWidgetKind';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ConfirmSyncInventoryBillModal from 'modules/Job/V2/Move/components/ConfirmSyncInventoryBillModal';
import UpdateInventoryKindVirtualWalkthroughModal from 'modules/Job/V2/Move/components/UpdateInventoryKindVirtualWalkthroughModal';
import ProjectBillingBlock from 'modules/Project/V2/Show/Blocks/ProjectBillingBlock';
import AccessSurveyV1ActionsMenuButton from 'modules/Project/V2/Show/Blocks/components/AccessSurveyV1ActionsMenuButton';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const BLOCK_TITLE = 'Survey';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const IndexedContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const IconButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
`;

const getGenerateBillLabel = ({hasSyncedBill}: any) => {
  return hasSyncedBill ? 'Regenerate Bill' : 'Generate Bill';
};

const handleGenerateBill = ({
  hasSyncedBill,
  confirmSyncInventoryBillModal,
  syncInventoryBillHandleSubmit,
}: any) => {
  if (hasSyncedBill) {
    confirmSyncInventoryBillModal.handleOpen();
  } else {
    syncInventoryBillHandleSubmit();
  }
};

const getVirtualWalkthroughLabel = ({isVirtualWalkthroughStarted}: any) => {
  return isVirtualWalkthroughStarted ? 'View Virtual Walkthrough' : 'Send Walkthrough Link';
};

const handleVirtualWalkthrough = ({
  inventory,
  navigator,
  isVirtualWalkthroughStarted,
  virtualWalkthroughModal,
}: any) => {
  if (isVirtualWalkthroughStarted) {
    navigator.push(`/inventories/${inventory.uuid}/edit`);
  } else {
    virtualWalkthroughModal.handleOpen();
  }
};

const SURVEY_ITEMS_LABEL = 'Survey Items';

const handleSurveyItems = ({project, navigator}: any) => {
  navigator.push(`/projects/${project.uuid}/inventory/${project.inventory.uuid}?back=1`);
};

const SkeletonBody = () => {
  return (
    <React.Fragment>
      <Row>
        <FieldValue.SkeletonLoader label={'Est. Volume'} width={144} style={{flex: 1}} />
        <FieldValue.SkeletonLoader label={'Est. Weight'} width={144} style={{flex: 1}} />
        <FieldValue.SkeletonLoader label={'Est. Price'} width={144} style={{flex: 1}} />
      </Row>
      <Space height={16} />
      <Row>
        <FieldValue.SkeletonLoader label={'Rooms'} width={144} style={{flex: 1}} />
        <FieldValue.SkeletonLoader label={'Take Cartons'} width={144} style={{flex: 1}} />
        <FieldValue.SkeletonLoader label={'Take Non-Cartons'} width={144} style={{flex: 1}} />
      </Row>
      <Space height={16} />
      <Row>
        <FieldValue.SkeletonLoader label={'Survey Notes'} isFullWidth />
      </Row>
    </React.Fragment>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      HeaderActionComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={118} />
      )}
      ActionButtonComponent={() => (
        <Row>
          <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={130} />
          <Space width={12} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={130} />
        </Row>
      )}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const SurveyKindDropdown = ({project, refetch}: any) => {
  const responsive = useResponsive();
  const inventorySurveyMethodForm = InventorySurveyMethodForm.edit(project.inventory);
  const {form, handleSubmit} = useUpdateInventorySurveyMethodMutation({
    inventorySurveyMethodForm,
    onSuccess: refetch,
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const methodLabel = project.inventory.surveyMethod?.name || 'Survey Method';

  return (
    <DropdownButton
      text={methodLabel}
      isSmall
      actions={project.organization.surveyMethods.map((surveyMethod: any) => ({
        text: surveyMethod.name,

        onPress: () => {
          form.setFieldValue('inventorySurveyMethodForm.surveyMethodId', surveyMethod.id);
          setTimeout(handleSubmit, 0);
        },
      }))}
      ButtonComponent={SecondaryButton}
      buttonStyle={{
        backgroundColor: colors.blue.accent,
        borderColor: colors.blue.accent,
        ...(responsive.desktop ? {} : {height: 32}),
      }}
      menuWidth={200}
      sheetLabel={'Change Survey Method'}
      value={methodLabel}
    />
  );
};

const NoSurveyTooltip = ({isNoSurvey, children}: any) => {
  if (isNoSurvey) {
    return (
      <TextTooltip
        isEnabledMobileToast={false}
        text={'Choose another survey method to survey items.'}
      >
        {children}
      </TextTooltip>
    );
  }
  return children;
};

const SurveyActions = ({project, refetch, urlFilters}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const {inventory, organization, activeMoveJobs} = project;
  const {isEnabledAccessSurveyV1FromProjectPageV2} = organization.features;
  const {hasSyncedBill, isVirtualWalkthroughStarted} = inventory;

  const inventoryJob = activeMoveJobs[0];
  const isVirtualWalkthrough = Inventory.getIsVirtualWalkthrough(inventory);
  const isNoSurvey = Inventory.getIsNoSurvey(inventory);

  const surveyActionsPopover = usePopover({name: 'Survey Actions Popover'});
  const confirmSyncInventoryBillModal = useModal({name: 'Confirm Sync Inventory Bill Modal'});
  const confirmSurveyItemsModal = useModal({
    name: 'Confirm Survey Items Modal',
    enableTracking: false,
  });
  const virtualWalkthroughModal = useModal({name: 'Virtual Walkthrough Modal'});
  const virtualWalkthroughLinkSentSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Virtual walkthrough link sent.',
  });
  const selectSurveyMethodToast = useToast({
    ToastComponent: Toast,
    message: 'Select a survey method to see actions.',
  });
  const billGeneratedToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Bill generated!',
    actionText: 'View',
    handleAction: () => urlFilters.handleUpdate({block: ProjectBlockKind.BILLING}),
  });

  const inventoryForm = InventoryForm.edit(inventory);
  const {submitting: syncInventoryBillSubmitting, handleSubmit: syncInventoryBillHandleSubmit} =
    useSyncInventoryBillMutation({
      inventoryForm,
      onSuccess: () => {
        confirmSyncInventoryBillModal.handleClose();
        surveyActionsPopover.handleClose();
        billGeneratedToast.handleToast();
        refetch();
      },
      onError: (errors: any) => console.log({errors}),
    });

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <NoSurveyTooltip isNoSurvey={isNoSurvey}>
          <Row>
            {/* First button for (Re)Generate Bill */}
            <SecondaryButton
              isSmall
              text={getGenerateBillLabel({hasSyncedBill})}
              iconLeft={Icon.DollarSign}
              isSubmitting={syncInventoryBillSubmitting}
              isDisabled={isNoSurvey}
              onPress={() =>
                handleGenerateBill({
                  hasSyncedBill,
                  confirmSyncInventoryBillModal,
                  syncInventoryBillHandleSubmit,
                })
              }
            />
            <Space width={12} />
            {/* Second button for Virtual Walkthrough or Survey Items */}
            {isVirtualWalkthrough ? (
              <Button
                isSmall
                iconLeft={isVirtualWalkthroughStarted ? null : Icon.CommentSms}
                onPress={() =>
                  handleVirtualWalkthrough({
                    inventory,
                    navigator,
                    isVirtualWalkthroughStarted,
                    virtualWalkthroughModal,
                  })
                }
                text={getVirtualWalkthroughLabel({isVirtualWalkthroughStarted})}
              />
            ) : (
              <Button
                isSmall
                text={SURVEY_ITEMS_LABEL}
                iconLeft={Icon.Pen}
                isDisabled={isNoSurvey}
                onPress={() => handleSurveyItems({project, navigator})}
              />
            )}
            {/* Desktop option to see v1 page for survey */}
            {isEnabledAccessSurveyV1FromProjectPageV2 && inventoryJob && (
              <React.Fragment>
                <Space width={12} />
                <AccessSurveyV1ActionsMenuButton
                  jobUuid={inventoryJob.uuid}
                  inventoryUuid={inventory.uuid}
                  isSmall
                  isDisabled={isNoSurvey}
                />
              </React.Fragment>
            )}
          </Row>
        </NoSurveyTooltip>
      ) : (
        <ActionMenuPopover
          popover={surveyActionsPopover}
          sheetLabel={'Actions'}
          actions={[
            // Virtual Walkthrough or Survey Items
            isVirtualWalkthrough
              ? {
                  text: getVirtualWalkthroughLabel({isVirtualWalkthroughStarted}),
                  onPress: () =>
                    handleVirtualWalkthrough({
                      inventory,
                      navigator,
                      isVirtualWalkthroughStarted,
                      virtualWalkthroughModal,
                    }),
                }
              : {
                  text: SURVEY_ITEMS_LABEL,
                  onPress: () => handleSurveyItems({project, navigator}),
                },
            // (Re)Generate Bill
            {
              text: getGenerateBillLabel({hasSyncedBill}),
              onPress: () =>
                handleGenerateBill({
                  hasSyncedBill,
                  confirmSyncInventoryBillModal,
                  syncInventoryBillHandleSubmit,
                }),
              isLoadedAction: !hasSyncedBill,
              isLoading: syncInventoryBillSubmitting,
            },
          ]}
        >
          <IconButton
            onPress={() =>
              isNoSurvey ? selectSurveyMethodToast.handleToast() : surveyActionsPopover.handleOpen()
            }
          >
            <Icon source={Icon.EllipsisV} color={colors.blue.interactive} size={16} />
          </IconButton>
        </ActionMenuPopover>
      )}
      <ConfirmSyncInventoryBillModal
        key={confirmSyncInventoryBillModal.key}
        isOpen={confirmSyncInventoryBillModal.isOpen}
        handleClose={confirmSyncInventoryBillModal.handleClose}
        handleSubmit={syncInventoryBillHandleSubmit}
        submitting={syncInventoryBillSubmitting}
      />
      <UpdateInventoryKindVirtualWalkthroughModal
        key={virtualWalkthroughModal.key}
        isOpen={virtualWalkthroughModal.isOpen}
        inventoryUuid={inventory.uuid}
        handleClose={virtualWalkthroughModal.handleClose}
        onSuccess={() => {
          refetch();
          virtualWalkthroughLinkSentSuccessToast.handleToast();
          if (responsive.desktop) {
            urlFilters.handleUpdate({widget: ProjectWidgetKind.SMS});
          }
        }}
      />
      <CautionModal
        key={confirmSurveyItemsModal.key}
        title={'Survey Items?'}
        message={
          'The survey items page has not been updated for mobile devices. Would you like to continue?'
        }
        isOpen={confirmSurveyItemsModal.isOpen}
        handleSecondaryAction={confirmSurveyItemsModal.handleClose}
        secondaryActionText={'Cancel'}
        handlePrimaryAction={() => handleSurveyItems({project, navigator})}
        primaryActionText={'Continue'}
      />
    </React.Fragment>
  );
};

const SurveyFields = ({project}: any) => {
  const {inventory, organization} = project;
  const {collection, notes, totalRooms} = inventory;
  const {
    totalPrice,
    totalTakeCartonItems,
    totalTakeNonCartonItems,
    totalWeight,
    totalVolume,
    totalPackTime,
    totalUnpackTime,
  } = collection;
  const {isEnabledSurveysTimeAdditives} = organization.features;

  const responsive = useResponsive();
  const desktopColumnCount = isEnabledSurveysTimeAdditives ? 4 : 3;
  const containerStyle = {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: `calc(100% * (1 / ${responsive.desktop ? desktopColumnCount : 2}))`,
  };

  return (
    <React.Fragment>
      <Row style={{flexWrap: 'wrap'}}>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Est. Volume'}
            value={`${_.round(totalVolume, 2)} cu ft`}
            isResponsive
          />
          <Space height={16} />
        </Container>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Est. Weight'}
            value={`${_.round(totalWeight, 2)} lbs`}
            isResponsive
          />
          <Space height={16} />
        </Container>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Est. Price'}
            value={Currency.display(totalPrice)}
            isResponsive
          />
          <Space height={16} />
        </Container>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Rooms'}
            value={pluralize('room', totalRooms, true)}
            isResponsive
          />
          <Space height={16} />
        </Container>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Carton Items'}
            value={pluralize('item', totalTakeCartonItems, true)}
            isResponsive
          />
          <Space height={16} />
        </Container>
        <Container style={containerStyle}>
          <FieldValue
            style={{flex: 1}}
            label={'Non-Carton Items'}
            value={pluralize('item', totalTakeNonCartonItems, true)}
            isResponsive
          />
          <Space height={16} />
        </Container>
        {isEnabledSurveysTimeAdditives && (
          <React.Fragment>
            <Container style={containerStyle}>
              <FieldValue
                style={{flex: 1}}
                label={'Pack Time'}
                value={`${_.round(totalPackTime, 2)} min`}
                isResponsive
              />
              <Space height={16} />
            </Container>
            <Container style={containerStyle}>
              <FieldValue
                style={{flex: 1}}
                label={'Unpack Time'}
                value={`${_.round(totalUnpackTime, 2)} min`}
                isResponsive
              />
              <Space height={16} />
            </Container>
          </React.Fragment>
        )}
      </Row>
      <Row>
        <FieldValue style={{flex: 1}} label={'Notes'} value={notes} empty={'None'} isResponsive />
      </Row>
    </React.Fragment>
  );
};

const ProjectSurveyBlockContent = ({project, refetch, urlFilters}: any) => {
  return (
    <ActionPanel
      BodyComponent={SurveyFields}
      bodyComponentProps={{project}}
      ActionButtonComponent={SurveyActions}
      actionButtonComponentProps={{project, refetch, urlFilters}}
      HeaderActionComponent={SurveyKindDropdown}
      headerActionComponentProps={{project, refetch}}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectSurveyBlockContentMobile = ({project, refetch, urlFilters}: any) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Survey`}
        ActionsComponent={SurveyActions}
        actionsComponentProps={{project, refetch, urlFilters}}
      />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <IndexedContainer index={0}>
        <SurveyKindDropdown project={project} refetch={refetch} />
      </IndexedContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <IndexedContainer index={1}>
        <Space height={16} />
        <SurveyFields project={project} />
      </IndexedContainer>
    </React.Fragment>
  );
};

const ProjectSurveyBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  urlFilters,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectSurveyBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: any) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectSurveyBlockContent
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
              />
            ) : (
              <ProjectSurveyBlockContentMobile
                project={project}
                refetch={refetch}
                urlFilters={urlFilters}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSurveyBlock.fragment = gql`
  fragment ProjectSurveyBlock on Project {
    id
    uuid
  }
`;

ProjectSurveyBlock.query = gql`
  ${Inventory.getIsNoSurvey.fragment}
  ${Inventory.getIsVirtualWalkthrough.fragment}
  ${InventoryForm.edit.fragment}
  ${InventorySurveyMethodForm.edit.fragment}
  ${ProjectBillingBlock.listener}
  query ProjectSurveyBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      activeMoveJobs {
        id
        uuid
      }
      inventory {
        id
        uuid
        notes
        totalRooms
        isVirtualWalkthroughStarted
        hasSyncedBill
        collection {
          id
          totalPrice
          totalVolume
          totalWeight
          totalTakeCartonItems
          totalTakeNonCartonItems
          totalPackTime
          totalUnpackTime
        }
        surveyMethod {
          id
          name
        }
        ...Inventory_getIsNoSurvey
        ...Inventory_getIsVirtualWalkthrough
        ...InventoryForm_edit
        ...InventorySurveyMethodForm_edit
      }
      organization {
        id
        surveyMethods {
          id
          name
        }
        features {
          isEnabledAccessSurveyV1FromProjectPageV2: isEnabled(feature: "ACCESS_SURVEY_V1_FROM_PROJECT_PAGE_V2")
          isEnabledSurveysTimeAdditives: isEnabled(feature: "SURVEYS_TIME_ADDITIVES")
        }
      }
      ...ProjectBillingBlock_listener
    }
  }
`;

export default ProjectSurveyBlock;
