// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {ExternalLink, Lifecycle, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {Config} from '@supermove/sdk';
import {colors, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import Panel from '@shared/design/components/Panel';
import StripeLogo from 'modules/Organization/Settings/Company/assets/StripeLogo';

const Image = Styled.Image`
  width: 80px;
  height: 40px;
`;

const TextContainer = Styled.View`
  width: 450px;
`;

const ButtonContainer = Styled.View`
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
`;

const StripeMessageContainer = Styled.View`
  align-items: flex-end;
  justify-content: flex-end;
`;

const StripeMessageText = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
`;

const StripeMessageError = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
  color: ${colors.Pink600};
`;

const navigateToStripeConnect = (redirectPath: any) => {
  const clientId = Config.getStripeClientId();
  const redirectUri = new URL(redirectPath, `${window.location.protocol}//${window.location.host}`);
  window.location.href = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${clientId}&scope=read_write&redirect_uri=${redirectUri}`;
};

const getSearchParams = ({location}: any) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const saveStripeCredentials = ({
  code,
  error,
  errorDescription,
  organization,
  scope,
  redirectPath,
}: any) => {
  if (scope !== 'read_write') {
    return;
  }

  const query = `mutation AddOrganizationStripeAccountCredential(
    $organizationId: Int!,
    $authorizationCode: String!,
  ) {
    addOrganizationStripeAccountCredential(
      organizationId: $organizationId,
      authorizationCode: $authorizationCode,
    ) {
      organization {
        id
      }
      account {
        id
      }
      error
    }
  }`;

  fetch(`${Config.getAPIHost()}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: Json.stringify({
      query,
      variables: {
        organizationId: organization.id,
        authorizationCode: code,
      },
    }),
  })
    .then((r) => r.json())
    .then((data) => {
      if (data.error) {
        window.location.href = `${redirectPath}?error=supermove`;
      } else {
        window.location.href = `${redirectPath}?code=supermove`;
      }
    });
};

/**
 * On a successful Stripe Connect authentication, Stripe will
 * send us the query parameters 'code' and 'scope'.
 *
 * On an unsuccessful Stripe Connect authentication, Stripe will
 * send us the query parameters 'error' and 'error_description'.
 */
const StripeAuthenticationMessage = ({code, error, errorDescription, scope}: any) => {
  let message;
  if (code) {
    if (code === 'supermove') {
      message = 'Successfully connected your account with Supermove.';
    } else if (scope === 'read_write') {
      message = 'Verifying the connection to your account...';
    } else {
      message = 'Please grant Supermove both Read & Write access to your Stripe account.';
    }
  } else if (error) {
    if (error === 'access_denied') {
      message = 'Please grant Supermove access to your Stripe account.';
    } else if (error === 'supermove') {
      message = 'Something went wrong.';
    } else {
      message = `${error}: ${errorDescription}`;
    }
  }

  if (message) {
    return (
      <StripeMessageContainer>
        {message.indexOf('Please') === 0 ? (
          <StripeMessageError>{message}</StripeMessageError>
        ) : (
          <StripeMessageText>{message}</StripeMessageText>
        )}
      </StripeMessageContainer>
    );
  } else {
    return null;
  }
};

const ConnectToStripe = ({redirectPath}: any) => {
  return (
    <React.Fragment>
      <TextContainer>
        <Panel.LabelText>Connect to Stripe</Panel.LabelText>
        <Space height={8} />
        <Panel.Text>
          Simplify payment processing by connecting your Stripe account with Supermove.
        </Panel.Text>
      </TextContainer>
      <ButtonContainer>
        <Button onPress={() => navigateToStripeConnect(redirectPath)} text={'Connect to Stripe'} />
      </ButtonContainer>
    </React.Fragment>
  );
};

const ExistingStripeAccount = ({organization, redirectPath}: any) => {
  const stripeAccountId = Organization.getStripeAccountId(organization);
  return (
    <React.Fragment>
      <TextContainer>
        <Panel.LabelText>Account ID</Panel.LabelText>
        <Space height={8} />
        <ExternalLink url={'https://dashboard.stripe.com/dashboard'}>
          <Panel.Text style={{color: colors.blue.interactive}}>{stripeAccountId}</Panel.Text>
        </ExternalLink>
      </TextContainer>
      <ButtonContainer>
        <DropdownButton
          text={'Update Account'}
          actions={[
            {
              text: 'Change Account',
              onPress: () => navigateToStripeConnect(redirectPath),
            },
          ]}
        />
      </ButtonContainer>
    </React.Fragment>
  );
};

type StripePanelProps = {
  organization: any;
  redirectPath: string;
};

const StripePanel = ({organization, redirectPath}: StripePanelProps) => {
  const {navigator} = useNavigationDOM();
  const searchParams = getSearchParams({location: navigator.location});
  return (
    <Lifecycle onMount={() => saveStripeCredentials({organization, redirectPath, ...searchParams})}>
      <Panel width={Panel.WIDTH.DEFAULT}>
        <Panel.Header>
          <Panel.HeaderText>Stripe</Panel.HeaderText>
        </Panel.Header>
        <Panel.Body>
          <Image resizeMode={'contain'} source={{uri: StripeLogo}} />
          <Space height={16} />
          {Organization.hasStripeAccountId(organization) ? (
            <ExistingStripeAccount organization={organization} redirectPath={redirectPath} />
          ) : (
            <ConnectToStripe redirectPath={redirectPath} />
          )}
          <StripeAuthenticationMessage organization={organization} {...searchParams} />
        </Panel.Body>
      </Panel>
    </Lifecycle>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StripePanel.fragment = gql`
  ${Organization.getStripeAccountId.fragment}
  ${Organization.hasStripeAccountId.fragment}
  fragment StripePanel on Organization {
    id
    ...Organization_getStripeAccountId
    ...Organization_hasStripeAccountId
  }
`;

export default StripePanel;
