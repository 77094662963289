// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateConfirmationDepositMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateConfirmationDepositMutation' implicitly has... Remove this comment to see the full error message
const UpdateConfirmationDepositMutation = ({
  confirmationId,
  paymentId,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
    variables={{
      confirmationId,
      paymentId,
    }}
    mutation={UpdateConfirmationDepositMutation.mutation}
    onCompleted={({response: {confirmation, errors}}: any) => {
      if (!errors) {
        onSuccess(confirmation);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateConfirmationDepositMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateConfirmationDepositMutation.mutation = gql`
  mutation UpdateConfirmationDepositMutation(
    $confirmationId: Int!,
    $paymentId: Int!,
  ) {
    response: updateConfirmationDeposit(
      confirmationId: $confirmationId,
      paymentId: $paymentId,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default UpdateConfirmationDepositMutation;
