// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover} from '@supermove/hooks';
import {Organization, Proposal} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import ProjectConfirmationStep from 'modules/Project/Confirmation/components/ProjectConfirmationStep';

const Wrapper = Styled.View`
  margin-top: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: dashed;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Title = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Name = Styled.H7`
  margin-left: 5px;
  color: ${colors.gray.tertiary};
`;

const Touchable = Styled.Touchable`
`;

const Footer = Styled.View`
`;

const Options = Styled.View`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  padding-bottom: 1000px;
  background-color: ${colors.white};
`;

const getAddableSteps = ({steps, projectType, isQuote}: any) => {
  const existingUuids = steps.map((step: any) => step.uuid);
  const possibleSteps = Proposal.getProjectTypeConfirmationSteps({
    projectType,
    kind: isQuote ? ConfirmationStepsKind.QUOTE : ConfirmationStepsKind.CONFIRMATION,
  });
  const documentV2Step = isQuote
    ? {kind: ConfirmationStepKind.QUOTE_STEP_DOCUMENT}
    : {kind: ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT};
  return [
    // We always allow adding a DocumentV2 step
    documentV2Step,
    ...(possibleSteps as any).filter((step: any) => {
      // We only allow one of each step to exist and we check by uuid
      return !existingUuids.includes(step.uuid);
    }),
  ];
};

const AddableStep = ({step, project, handleAddStep, setIsShowingOptions}: any) => {
  const {ref, isHovered} = useHover();

  return (
    <Touchable
      ref={ref}
      onPress={() => {
        // Add the new step to the bottom.
        handleAddStep(step);
        setIsShowingOptions(false);
      }}
      style={{marginTop: 10}}
    >
      <ProjectConfirmationStep isAddable isHovered={isHovered} project={project} step={step} />
    </Touchable>
  );
};

const Choices = ({steps, handleAddStep, project, setIsShowingOptions, isQuote}: any) => (
  <Options>
    {getAddableSteps({steps, projectType: project.projectType, isQuote}).map((step, index) => (
      <AddableStep
        key={index}
        step={step}
        handleAddStep={handleAddStep}
        project={project}
        setIsShowingOptions={setIsShowingOptions}
      />
    ))}
  </Options>
);

const ProjectConfirmationStepAdd = ({
  index,
  steps,
  handleAddStep,
  project,
  isQuote,
  isShowingOptions,
  setIsShowingOptions,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      <Touchable onPress={() => setIsShowingOptions(!isShowingOptions)}>
        <Container>
          <Title>
            {!isShowingOptions && (
              <Icon
                color={colors.blue.interactive}
                size={Icon.Sizes.Large}
                source={Icon.Plus}
                style={{width: 24}}
              />
            )}
            <Name>
              {isShowingOptions ? 'Select a step below to add' : 'Show available steps to add'}
            </Name>
          </Title>
        </Container>
      </Touchable>
      {isShowingOptions && (
        <Footer>
          <Choices
            steps={steps}
            handleAddStep={handleAddStep}
            project={project}
            setIsShowingOptions={setIsShowingOptions}
            isQuote={isQuote}
          />
        </Footer>
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectConfirmationStepAdd.fragment = gql`
  ${Organization.getConfirmationSteps.fragment}
  ${Organization.getQuoteSteps.fragment}
  ${Proposal.getProjectTypeConfirmationSteps.fragment}

  fragment ProjectConfirmationStepAdd on Project {
    id
    organization {
      id
      slug
      account {
        id
        stripeConnectAccountCredentials
      }
      ...Organization_getConfirmationSteps
      ...Organization_getQuoteSteps
    }
    projectType {
      id
      ...Proposal_getProjectTypeConfirmationSteps
    }
  }
`;

export default ProjectConfirmationStepAdd;
