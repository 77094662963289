// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import LargeModal from 'modules/App/Modal/components/LargeModal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectEmailFields from 'modules/Email/Project/components/ProjectEmailFields';
import EmailAttachmentsSelector from 'modules/File/Attachment/components/EmailAttachmentsSelector';
import SendEmailForJobContent from 'modules/Job/Email/components/SendEmailForJobContent';
import useSendEmailForJobForm from 'modules/Job/Email/logic/useSendEmailForJobForm';

type OwnProps = {
  isOpen: boolean;
  jobUuid: string;
  handleClose?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SendEmailForJobModal.defaultProps;

// @ts-expect-error TS(7022): 'SendEmailForJobModal' implicitly has type 'any' b... Remove this comment to see the full error message
const SendEmailForJobModal = ({isOpen, jobUuid, handleClose}: Props) => {
  const {loading, data} = useQuery(SendEmailForJobModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid,
    },
    skip: !isOpen,
  });

  return (
    <LargeModal
      isOpen={isOpen}
      handleClose={handleClose}
      disableCloseOnPressOutside
      title={'Send Email from Template'}
      width={800}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => (
          <SendEmailForJobContent
            job={data.job}
            viewerId={data.viewer.id}
            handleClose={handleClose}
          />
        )}
      </Loading>
    </LargeModal>
  );
};

SendEmailForJobModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendEmailForJobModal.query = gql`
  ${EmailAttachmentsSelector.fragment}
  ${ProjectEmailFields.fragment}
  ${useSendEmailForJobForm.fragment}
  query SendEmailForJobModal($jobUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    job(uuid: $jobUuid) {
      id
      uuid
      organization {
        id
        slug
      }
      project {
        id
        ...EmailAttachmentsSelector
        ...ProjectEmailFields
      }
      ...useSendEmailForJobForm
    }
  }
`;

export default SendEmailForJobModal;
