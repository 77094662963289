// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import TagKind from '@shared/modules/Tag/enums/TagKind';

const TagAssignmentFields = ({form, tags, label, isDropdownPortaled = true}: any) => {
  const tagIdsField = 'tagAssignmentForm.tagIds';

  const filterByArchivedTag = tags.filter((tag: any) => !tag.isArchived);

  const tagOptions = filterByArchivedTag
    ? filterByArchivedTag.map((tag: any) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
        name: tag.name,
        kind: tag.kind,
      }))
    : [];

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={tagIdsField}
        component={MultiDropdownCheckboxInput}
        input={{
          isPortaled: isDropdownPortaled,
          isSearchable: true,
          options: _.sortBy(tagOptions, ['name']).filter(
            (option) => option.kind !== TagKind.TRAINING_PROJECT,
          ),
          placeholder: 'Select tag',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          usePills: true,
          isResponsive: true,
          label,
        }}
        style={{flex: 1}}
        isResponsive
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

TagAssignmentFields.fragment = gql`
  fragment TagAssignmentFields on Organization {
    id
    companySettings {
      tags {
        id
        name
        emoji
        kind
        isArchived
      }
    }
  }
`;

export default TagAssignmentFields;
