// Libraries
import React from 'react';

// Supermove
import {DateInput, DropdownInput, Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

// Logic
import {Field} from 'modules/App/components';
import {useExportReportMovesReportForm} from 'modules/Calendar/Reports/logic';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Inputs = Styled.View`
  margin-vertical: 10px;
  z-index: 100;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
  margin-top: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const FieldSpace = Styled.View`
  width: 10px;
`;

const FILE_FORMATS = [{label: 'XML', value: 'XML'}];

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ExportReportMovesReportModal.defaultProps;

// @ts-expect-error TS(7022): 'ExportReportMovesReportModal' implicitly has type... Remove this comment to see the full error message
const ExportReportMovesReportModal = ({isOpen, organization, onClose}: Props) => {
  const {navigator} = useNavigationDOM();
  const {form, submitting, handleSubmit} = useExportReportMovesReportForm({
    organizationId: organization.id,
    startDate: Datetime.yesterday,
    endDate: Datetime.yesterday,
    fileFormat: 'XML',
    onSuccess: ({file, url}: any) => {
      navigator.pushNewTab(url);
      onClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <Modal.Content
      isOpen={isOpen}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onClose={onClose}
    >
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'arrow_down'} />
          </Circle>
          <Content>
            <Title>Export Data</Title>
            <Subtitle>Select a date range and type of export you would like to download.</Subtitle>
            <Inputs>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Row index={0}>
                <Field
                  {...form}
                  index={0}
                  component={DateInput}
                  name={'startDate'}
                  label={'Start Date'}
                  input={{
                    placeholder: 'Start date',
                    setFieldValue: form.setFieldValue,
                    style: {
                      width: 140,
                    },
                  }}
                />
                <FieldSpace />
                <Field
                  {...form}
                  index={1}
                  component={DateInput}
                  name={'endDate'}
                  label={'End Date'}
                  input={{
                    placeholder: 'End date',
                    setFieldValue: form.setFieldValue,
                    style: {
                      width: 140,
                    },
                  }}
                />
              </Row>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Row index={1}>
                <Field
                  {...form}
                  index={1}
                  component={DropdownInput}
                  name={'fileFormat'}
                  label={'File Format'}
                  input={{
                    placeholder: 'Select a file format',
                    options: FILE_FORMATS.map((option) => ({
                      label: option.label,
                      value: option.value,
                    })),
                    setFieldValue: form.setFieldValue,
                    style: {
                      width: 290,
                    },
                  }}
                />
              </Row>
            </Inputs>
            <Actions>
              <Button color={colors.gray.secondary} onPress={onClose}>
                <Text>Cancel</Text>
              </Button>
              <ButtonSpace />
              <Button color={colors.blue.interactive} loading={submitting} onPress={handleSubmit}>
                <Text>Download</Text>
              </Button>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    </Modal.Content>
  );
};

ExportReportMovesReportModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ExportReportMovesReportModal.fragment = gql`
  fragment ExportReportMovesReportModal on Organization {
    id
  }
`;

export default ExportReportMovesReportModal;
