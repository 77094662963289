// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useResponsive, useState, useScrollView, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import Switch from '@shared/design/components/Switch';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectLocationsForm from '@shared/modules/Project/forms/ProjectLocationsForm';
import useUpdateProjectLocationsMutation from '@shared/modules/Project/hooks/useUpdateProjectLocationsMutation';
import EditLocationsList from 'modules/Project/V2/Edit/components/EditLocationsList';

const EditProjectLocationsDrawer = ({
  isOpen,
  handleClose,
  project,
  scrollToIndex,
  refetch,
}: any) => {
  const responsive = useResponsive();
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Saved Locations updated!',
  });

  const [isReordering, setIsReordering] = useState(false);
  const [locationIndexToPositionY, setLocationIndexToPositionY] = useState({});
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollView = useScrollView();

  const projectLocationsForm = ProjectLocationsForm.edit(project);
  const {form, handleSubmit, submitting} = useUpdateProjectLocationsMutation({
    projectLocationsForm,
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});

      // Scroll to the error
      setIsReordering(false);
      const fieldErrorIndex = _.findIndex(errors, (error) => !!(error as any).field);
      if (!_.isNil(fieldErrorIndex)) {
        const error = errors[fieldErrorIndex];
        const locationIndex = error.field.split('.')[2];
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const y = locationIndexToPositionY[locationIndex] - 16;

        // In case the user was on the reorder view, we need to
        // wait for the list view to render before scrolling
        setTimeout(() => scrollView.handleScrollTo({y, animated: true}), 0);
      }
    },
  });

  const isFullyRendered = project.locations.length === Object.keys(locationIndexToPositionY).length;

  useEffect(() => {
    if (scrollToIndex && isFullyRendered && !isScrolled) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const y = locationIndexToPositionY[scrollToIndex] - 16;
      scrollView.handleScrollTo({y, animated: true});
      setIsScrolled(true);
    }
  }, [isFullyRendered, isScrolled, locationIndexToPositionY, scrollToIndex, scrollView]);

  const field = 'projectLocationsForm';

  return (
    <DrawerWithAction
      // @ts-expect-error TS(2322): Type '{ children: (false | Element)[]; isResponsiv... Remove this comment to see the full error message
      isResponsive={false}
      isOpen={isOpen}
      handleClose={handleClose}
      width={520}
      isDisabled={!form.dirty}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      shouldCloseOnClickOutside={!form.dirty}
      scrollViewRef={scrollView.ref}
      isClosable={!responsive.desktop}
      headerText={'Edit Saved Locations'}
      HeaderRightComponent={() =>
        responsive.desktop ? (
          <Switch
            isOn={isReordering}
            onChange={(value) => setIsReordering(value)}
            labelRight={'Reorder'}
          />
        ) : null
      }
    >
      {!responsive.desktop && (
        <React.Fragment>
          <Switch
            isOn={isReordering}
            onChange={(value) => setIsReordering(value)}
            labelRight={'Reorder'}
            isMobile
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <EditLocationsList
        form={form}
        field={field}
        project={project}
        isReordering={isReordering}
        setLocationIndexToPositionY={setLocationIndexToPositionY}
        scrollToIndex={scrollToIndex}
        showJobsCallout
        disableWarehouse
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectLocationsDrawer.fragment = gql`
  ${EditLocationsList.fragment}
  ${ProjectLocationsForm.edit.fragment}
  fragment EditProjectLocationsDrawer on Project {
    id
    ...EditLocationsList
    ...ProjectLocationsForm_edit
  }
`;

export default EditProjectLocationsDrawer;
