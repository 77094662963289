// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import BillForm from '@shared/modules/Billing/forms/BillForm';
import useDeleteBillMutation from '@shared/modules/Billing/hooks/useDeleteBillMutation';

const BillingProjectDeleteBillModalContent = ({bill, isOpen, handleClose, refetch}: any) => {
  const billForm = BillForm.edit(bill);
  const {handleSubmit, submitting} = useDeleteBillMutation({
    billForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${bill.title}?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const BillingProjectDeleteBillModal = ({isOpen, billUuid, handleClose, refetch}: any) => {
  const {loading, data} = useQuery(BillingProjectDeleteBillModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: billUuid,
    },
  });

  return (
    <Loading loading={loading || !data}>
      {() => (
        <BillingProjectDeleteBillModalContent
          bill={data.bill}
          isOpen={isOpen}
          handleClose={handleClose}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectDeleteBillModal.query = gql`
  ${BillForm.edit.fragment}

  query BillingProjectDeleteBillModal($uuid: String!) {
    bill(uuid: $uuid) {
      id
      title
      ...BillForm_edit
    }
  }
`;

export default BillingProjectDeleteBillModal;
