// Libraries
import React from 'react';

// Supermove
import {Space, Loading, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import SmallModal from '@shared/design/components/Modal/SmallModal';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import TagAssignmentForm from '@shared/modules/Tag/forms/TagAssignmentForm';
import useUpsertTagAssignmentMutation from '@shared/modules/Tag/hooks/useUpsertTagAssignmentMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TagAssignmentFields from 'modules/Tag/components/TagAssignmentFields';

const UpsertJobTagModal = ({job, tagIds, refetch, isOpen, handleClose}: any) => {
  const responsive = useResponsive();
  const isDisplayMobile = !responsive.desktop;
  // @ts-expect-error TS(2345): Argument of type '{ jobId: any; tagIds: any; }' is... Remove this comment to see the full error message
  const tagAssignmentForm = TagAssignmentForm.new({jobId: job.id, tagIds});
  const {form, submitting, handleSubmit} = useUpsertTagAssignmentMutation({
    tagAssignmentForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {loading, data} = useQuery(UpsertJobTagModal.query, {
    fetchPolicy: 'network-only',
    variables: {
      jobUuid: job.uuid,
    },
    skip: !isOpen,
  });

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      handlePressOutside={handleClose}
      style={{overflow: 'visible', maxHeight: 350}}
      isMobileSheet
      sheetLabel={'Edit Job Tags'}
      shouldCloseOnClickOutside={!form.dirty}
    >
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          const jobTags = data.job.organization.companySettings.tags.filter(
            (tag: any) => tag.category === TagCategory.JOB,
          );
          return (
            <React.Fragment>
              {responsive.desktop && (
                <React.Fragment>
                  <SmallModal.HeaderText>Edit Job Tags</SmallModal.HeaderText>
                  <Space height={12} />
                </React.Fragment>
              )}
              <SmallModal.Text responsive={responsive}>Select from existing tags.</SmallModal.Text>
              <Space height={12} />
              <ScrollView style={{maxHeight: 300}}>
                <TagAssignmentFields label={'Job Tags'} tags={jobTags} form={form} />
              </ScrollView>
              <Space height={16} />
              {isDisplayMobile && (
                <React.Fragment>
                  <Line />
                  <Space height={16} />
                </React.Fragment>
              )}
              <SmallModal.Footer isResponsive>
                {!isDisplayMobile && (
                  <React.Fragment>
                    <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
                      Cancel
                    </SmallModal.Button>
                    <Space width={8} />
                  </React.Fragment>
                )}
                <SmallModal.Button
                  isSubmitting={submitting}
                  color={colors.blue.interactive}
                  onPress={handleSubmit}
                  isResponsive
                  isWidthOfContainer={isDisplayMobile}
                >
                  Save
                </SmallModal.Button>
              </SmallModal.Footer>
            </React.Fragment>
          );
        }}
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpsertJobTagModal.query = gql`
  ${TagAssignmentFields.fragment}

  query UpsertJobTagModal($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      organization {
        id
        companySettings {
          tags {
            id
            category
          }
        }
        ...TagAssignmentFields
      }
    }
  }
`;

UpsertJobTagModal.fragment = gql`
  fragment UpsertJobTagModal on Job {
    id
    uuid
  }
`;

export default UpsertJobTagModal;
