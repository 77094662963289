// Libararies
import React from 'react';

// Supermove
import {pluralize} from '@supermove/utils';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';
import RemoveShipmentsFromTripForm from '@shared/modules/Dispatch/forms/RemoveShipmentsFromTripForm';
import useRemoveShipmentsFromTripMutation from '@shared/modules/Dispatch/hooks/useRemoveShipmentsFromTripMutation';

const RemoveShipmentsFromTripModal = ({refetch, isOpen, handleClose, shipmentIds, tripId}: any) => {
  const removeShipmentsFromTripForm = RemoveShipmentsFromTripForm.new({tripId, shipmentIds});
  const {handleSubmit, submitting} = useRemoveShipmentsFromTripMutation({
    removeShipmentsFromTripForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <MessageModal
      title={`Remove ${pluralize('shipment', shipmentIds.length, true)}?`}
      // @ts-expect-error TS(2322): Type '{ title: string; message: string; isOpen: an... Remove this comment to see the full error message
      message={`All removed shipments will be reset to unplanned.`}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      primaryActionText={'Remove'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
    />
  );
};

export default RemoveShipmentsFromTripModal;
