// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useToggle} from '@supermove/hooks';
import {Organization, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import FieldValue from '@shared/design/components/Field/FieldValue';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Switch from '@shared/design/components/Switch';
import SettingsForm, {SettingsFormToFormType} from '@shared/modules/Settings/forms/SettingsForm';
import useToggleSettingsIsCrewChargeSavedCardEnabledMutation from '@shared/modules/Settings/hooks/useToggleSettingsIsCrewChargeSavedCardEnabledMutation';

const Body = ({organization}: {organization: OrganizationModel}) => {
  const {isOwnerOfSettings, settings} = organization;
  const {isCrewChargeSavedCardEnabled} = settings;
  return (
    <React.Fragment>
      {!isOwnerOfSettings && (
        <React.Fragment>
          <Callout
            text={`Payment methods are set by the company. Please contact the admins at ${organization.company.primaryOrganization.name} to make changes.`}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <FieldValue.LabelText labelColor={colors.gray.primary}>Show Saved Cards</FieldValue.LabelText>
      <FieldValue.LabelSpace />
      <FieldValue.LabelText>{isCrewChargeSavedCardEnabled ? 'Yes' : 'No'}</FieldValue.LabelText>
    </React.Fragment>
  );
};

const EditBody = ({
  form,
  field,
  isDisabled,
}: {
  form: Form<{settingsForm: SettingsFormToFormType}>;
  field: string;
  isDisabled: boolean;
}) => {
  return (
    <React.Fragment>
      <Switch
        isOn={_.get(form.values, `${field}.isCrewChargeSavedCardEnabled`)}
        onChange={(newValue) =>
          form.setFieldValue(`${field}.isCrewChargeSavedCardEnabled`, newValue)
        }
        labelRight={'Show saved cards'}
        hint={
          isDisabled
            ? 'Only available for Supermove Payments.'
            : 'When enabled, customers will see any cards saved to their profile when making a payment on the Crew App.'
        }
        disabled={isDisabled}
      />
    </React.Fragment>
  );
};

const CrewShowSavedCardsPanel = ({
  organization,
  index,
}: {
  organization: OrganizationModel;
  index: number;
}) => {
  const editCrewShowSavedCardsToggle = useToggle();
  const settingsForm = SettingsForm.edit(organization.settings);
  const {form, handleSubmit, submitting} = useToggleSettingsIsCrewChargeSavedCardEnabledMutation({
    settingsForm,
    onSuccess: () => {
      editCrewShowSavedCardsToggle.handleToggleOff();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });
  const {isOwnerOfSettings} = organization;

  return (
    <EditPanel
      index={index}
      isDisabled={!isOwnerOfSettings}
      title={'Billing'}
      BodyComponent={Body}
      bodyComponentProps={{organization}}
      EditButtonComponent={() =>
        isOwnerOfSettings ? (
          <EditPanel.EditButton
            isEditing={editCrewShowSavedCardsToggle.isOn}
            handleEdit={editCrewShowSavedCardsToggle.handleToggleOn}
            isDisabled={false}
          />
        ) : null
      }
      EditBodyComponent={EditBody}
      editBodyComponentProps={{
        form,
        field: 'settingsForm',
        isDisabled: !Organization.getIsEnabledPayengine(organization),
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editCrewShowSavedCardsToggle.isOn}
      handleEdit={editCrewShowSavedCardsToggle.handleToggleOn}
      handleClose={editCrewShowSavedCardsToggle.handleToggleOff}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewShowSavedCardsPanel.fragment = gql`
  ${Organization.getIsEnabledPayengine.fragment}
  ${SettingsForm.edit.fragment}
  fragment CrewShowSavedCardsPanel on Organization {
    id
    isOwnerOfSettings
    settings {
      id
      isCrewChargeSavedCardEnabled
      ...SettingsForm_edit
    }
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
    ...Organization_getIsEnabledPayengine
  }
`;

export default CrewShowSavedCardsPanel;
