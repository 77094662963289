// Libraries
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useModal, useQuery} from '@supermove/hooks';
import {Location, Organization, Warehouse} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Callout from '@shared/design/components/Callout';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CreateWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/CreateWarehouseModal';
import WarehouseActionsButton from 'modules/Organization/Settings/Storage/Warehouses/components/WarehouseActionsButton';
import StorageSettingsPageHeader from 'modules/Organization/Settings/Storage/components/StorageSettingsPageHeader';
import SettingsContent from 'modules/Organization/Settings/components/SettingsContent';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const Section = Styled.View`
`;

const Cell = Styled.View`
  flex: 1;
`;

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Heading4}
`;

const Subtitle = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const TableCellItalicizedText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.tertiary};
  font-style: italic;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const SecondaryText = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary}
`;

const SecondaryBodyText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary}
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getWarehousesTableColumnDefinitions = ({refetch, userId, organization, canEdit}: any) => [
  {
    width: 300,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerContent: () => (
      <Cell>
        <HeaderText>{'Branch Name'}</HeaderText>
        <Space height={4} />
        <SecondaryText>{'Branch Type'}</SecondaryText>
      </Cell>
    ),
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <Cell>
        <TableCellText numberOfLines={1}>{warehouse.organization.name}</TableCellText>
        <Space height={4} />
        <SecondaryBodyText numberOfLines={1}>
          {Organization.getBranchTypeForOrganization(warehouse.organization)}
        </SecondaryBodyText>
      </Cell>
    ),
  },
  {
    width: 180,
    isHidden: organization.features.isEnabledMultipleWarehouse,
    headerContent: () => <HeaderText>Name</HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <TableCellText numberOfLines={1}>{warehouse.name}</TableCellText>
    ),
  },
  {
    width: 300,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerContent: () => (
      <Cell>
        <HeaderText>{'Warehouse Name'}</HeaderText>
        <Space height={4} />
        <SecondaryText>{'Address'}</SecondaryText>
      </Cell>
    ),
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <Cell>
        <TableCellText numberOfLines={1}>{warehouse.name}</TableCellText>
        <Space height={4} />
        <TextTooltip
          isEnabledMobileToast={false}
          placement={'top'}
          style={{textAlign: 'center'}}
          // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'TextTool... Remove this comment to see the full error message
          text={Location.getDisplayLocation(warehouse.location)}
        >
          <SecondaryBodyText numberOfLines={1}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            {Location.getDisplayLocation(warehouse.location)}
          </SecondaryBodyText>
        </TextTooltip>
      </Cell>
    ),
  },
  {
    width: 240,
    isHidden: organization.features.isEnabledMultipleWarehouse,
    headerContent: () => <HeaderText>Address</HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <TableCellText numberOfLines={1}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        {Location.getDisplayLocation(warehouse.location)}
      </TableCellText>
    ),
  },
  {
    width: 100,
    isHidden: !organization.features.isEnabledMultipleWarehouse,
    headerContent: () => (
      <Row>
        <HeaderText>Default</HeaderText>
        <Space width={2} />
        <TextTooltip
          isEnabledMobileToast={false}
          placement={'top'}
          style={{textAlign: 'center', width: 296}}
          text={`This warehouse will be automatically selected when creating a move project for its branch.`}
          numberOfLines={2}
        >
          <IconContainer>
            <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </Row>
    ),
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <CenteredCell>
        {warehouse.isDefault && <Icon source={Icon.Check} size={16} color={colors.gray.primary} />}
      </CenteredCell>
    ),
  },
  {
    width: 300,
    headerContent: () => <HeaderText>Notes</HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) =>
      warehouse.notes ? (
        <TableCellText numberOfLines={2}>{warehouse.notes}</TableCellText>
      ) : (
        <TableCellItalicizedText>None</TableCellItalicizedText>
      ),
  },
  {
    width: 100,
    isHidden: !canEdit,
    headerContent: () => <HeaderText style={{paddingLeft: 2}}>Actions</HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'warehouse' implicitly has an 'any... Remove this comment to see the full error message
    cellContent: ({item: warehouse}) => (
      <WarehouseActionsButton
        organization={organization}
        warehouse={warehouse}
        userId={userId}
        refetch={refetch}
      />
    ),
  },
];
const WarehouseTable = ({refetch, warehouses, userId, organization, canEdit}: any) => {
  const sortedWarehouses = useMemo(
    () => Warehouse.sortByOrganizationKind(warehouses),
    [warehouses],
  );

  return (
    <ScrollView horizontal>
      <Table.FixedHeaderScroll
        columnDefinitions={getWarehousesTableColumnDefinitions({
          refetch,
          userId,
          organization,
          canEdit,
        })}
        emptyStateText={'No warehouses to display'}
        items={sortedWarehouses}
        scrollViewContentContainerStyle={{height: '60vh'}}
      />
    </ScrollView>
  );
};

const WarehousesSection = ({refetch, userId, warehouses, organization, canEdit}: any) => {
  return (
    <Section>
      <WarehouseTable
        refetch={refetch}
        userId={userId}
        warehouses={warehouses}
        organization={organization}
        canEdit={canEdit}
      />
      <Space height={24} />
    </Section>
  );
};

const WarehouseSettingsPageContent = ({isCompanySetting}: any) => {
  const createWarehouseModal = useModal({name: 'Create Warehouse Modal'});
  const {loading, data, refetch} = useQuery(WarehouseSettingsPageContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }
  const {viewingOrganization} = data.viewer;
  const canEdit = viewingOrganization.features.isEnabledMultipleWarehouse
    ? viewingOrganization.isOwnerOfSettings
    : true;

  return (
    <PageContainer>
      <React.Fragment>
        {!isCompanySetting && <StorageSettingsPageHeader organization={viewingOrganization} />}
        <ScrollView>
          <ScrollView style={{flex: 1}}>
            <SettingsContent>
              <Section>
                <Title>Warehouses</Title>
                <Space height={12} />
                <Subtitle>Manage warehouses and the branch they belong to.</Subtitle>
                <Space height={24} />
              </Section>
              {canEdit ? (
                <Button
                  text={'Create Warehouse'}
                  iconLeft={Icon.Plus}
                  onPress={createWarehouseModal.handleOpen}
                />
              ) : (
                <Callout
                  text={`You are viewing a branch. To make changes, switch to the main branch.`}
                  width={510}
                />
              )}
              <Space height={24} />
              <WarehousesSection
                refetch={refetch}
                userId={data.viewer.id}
                organization={viewingOrganization}
                warehouses={viewingOrganization.companySettings.warehouses}
                canEdit={canEdit}
              />
            </SettingsContent>
          </ScrollView>
          <CreateWarehouseModal
            key={createWarehouseModal.key} // Ensures values are empty when modal is opened
            isOpen={createWarehouseModal.isOpen}
            handleClose={createWarehouseModal.handleClose}
            organization={viewingOrganization}
            userId={data.viewer.id}
            refetch={refetch}
          />
        </ScrollView>
      </React.Fragment>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WarehouseSettingsPageContent.query = gql`
  ${Location.getDisplayLocation.fragment}
  ${WarehouseActionsButton.fragment}
  ${StorageSettingsPageHeader.fragment}
  ${Organization.getBranchTypeForOrganization.fragment}
  ${CreateWarehouseModal.fragment}

  query WarehouseSettingsPageContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        name
        isOwnerOfSettings
        features {
          isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
        }
        companySettings {
          warehouses {
            id
            name
            notes
            isDefault
            location {
              id
              ...Location_getDisplayLocation
            }
            organization {
              id
              name
              ...Organization_getBranchTypeForOrganization
            }
            ...WarehouseActionsButton
          }
        }
        ...StorageSettingsPageHeader
        ...CreateWarehouseModal
        ...WarehouseActionsButton_Organization
      }
    }
  }
`;

export default WarehouseSettingsPageContent;
