// Libraries
import React from 'react';

// Supermove
import {Modal, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import UpdateCommercialReportMoveTimesheetTable from './UpdateCommercialReportMoveTimesheetTable';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  height: 90%;
  width: 800px;
  padding-vertical: 20px;
  padding-horizontal: 20px;
  background-color: ${colors.white};
`;

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Table = Styled.View`
  flex: 1;
  padding-top: 10px;
`;

const UpdateCommercialReportMoveTimesheetModal = ({job, refetch, trigger}: any) => {
  return (
    <Modal onClose={refetch} trigger={trigger}>
      {({handleClose}: any) => (
        <Overlay pointerEvents={'box-none'}>
          <Wrapper>
            <Query
              fetchPolicy={'cache-and-network'}
              variables={{
                uuid: job.uuid,
              }}
              query={UpdateCommercialReportMoveTimesheetModal.query}
            >
              {({loading, data}: any) =>
                loading ? null : (
                  <Container>
                    <Title>Work Timesheet</Title>
                    <Table>
                      <UpdateCommercialReportMoveTimesheetTable
                        reportMove={data.job.reportMove}
                        handleClose={handleClose}
                      />
                    </Table>
                  </Container>
                )
              }
            </Query>
          </Wrapper>
        </Overlay>
      )}
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialReportMoveTimesheetModal.query = gql`
  ${UpdateCommercialReportMoveTimesheetTable.fragment}

  query UpdateCommercialReportMoveTimesheetModal(
    $uuid: String!,
  ) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      uuid
      reportMove {
        id
        ...UpdateCommercialReportMoveTimesheetTable
      }
    }
  }
`;

export default UpdateCommercialReportMoveTimesheetModal;
