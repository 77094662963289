/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Left = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Name = Styled.H6`
  margin-left: 10px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Value = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const ReportMoveMoveUserTipItem = ({isFirst, moveUser}: any) => (
  <Container>
    {isFirst && <Line />}
    <Content>
      <Left>
        <Icon color={colors.gray.primary} size={Icon.Sizes.Large} source={Icon.User} />
        <Name>{moveUser.user.fullName}</Name>
      </Left>
      <Value>{Currency.display(moveUser.tip)}</Value>
    </Content>
    <Line />
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveMoveUserTipItem.fragment = gql`
  fragment ReportMoveMoveUserTipItem on ReportMoveUser {
    tip
    user {
      id
      fullName
    }
  }
`;

export default ReportMoveMoveUserTipItem;
