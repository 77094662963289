// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {CapacityCalendarDayModel, CapacityCalendarModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import CapacityCalendarSettingHelpers from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSettingHelpers';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';
import DaySlotMetric from 'modules/Calendar/Capacity/components/DaySlotMetric';

const TopSectionRow = Styled.View`
  flex-direction: row;
`;

const LeftColumnContainer = Styled.View`
`;

const RightColumnContainer = Styled.View`
  align-items: flex-end;
`;

const MetricsCard = Styled.View<{backgroundColor?: string}>`
  padding: 12px;
  background-color: ${({backgroundColor}) => backgroundColor || colors.white};
`;

const LoadingComponent = () => {
  return (
    <MetricsCard>
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Heading2} width={60} />
      <Space height={4} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Typography.Micro} width={120} />
    </MetricsCard>
  );
};

const LeftColumn = ({
  capacityCalendarDay,
  capacityCalendar,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
}) => {
  const slotMetric = _.find(capacityCalendar.capacityCalendarSlotMetrics, {slotPriorityIndex: 1});

  return (
    <LeftColumnContainer>
      {slotMetric && (
        <DaySlotMetric
          metric={slotMetric.metric}
          capacityCalendarDay={capacityCalendarDay}
          capacityCalendar={capacityCalendar}
        />
      )}
    </LeftColumnContainer>
  );
};

const RightColumn = ({
  capacityCalendar,
  capacityCalendarDay,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
}) => {
  const slotMetric = _.find(capacityCalendar.capacityCalendarSlotMetrics, {slotPriorityIndex: 2});

  return (
    <RightColumnContainer>
      {slotMetric && (
        <DaySlotMetric
          metric={slotMetric.metric}
          capacityCalendarDay={capacityCalendarDay}
          capacityCalendar={capacityCalendar}
          isSecondaryMetric
        />
      )}
    </RightColumnContainer>
  );
};

const MobileCapacityCalendarDaySlotMetrics = ({
  capacityCalendar,
  cellDate,
  shouldHideDayNotes,
  isLoading,
}: {
  capacityCalendar: CapacityCalendarModel;
  cellDate: string;
  shouldHideDayNotes?: boolean;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <LoadingComponent />;
  }

  const {capacityCalendarDays} = capacityCalendar;
  const capacityCalendarDay = capacityCalendarDays.find(({date}) => date === cellDate)!;

  return (
    <MetricsCard
      backgroundColor={
        CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor({
          capacityCalendar,
          capacityCalendarDay,
        }).backgroundColor
      }
    >
      <TopSectionRow>
        <LeftColumn capacityCalendarDay={capacityCalendarDay} capacityCalendar={capacityCalendar} />
        <Space flex={1} />
        <RightColumn
          capacityCalendarDay={capacityCalendarDay}
          capacityCalendar={capacityCalendar}
        />
      </TopSectionRow>
      <DaySlotMetric
        metric={CapacityCalendarSlotMetricKind.DAY_NOTES}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
        shouldHideDayNotes={shouldHideDayNotes}
      />
    </MetricsCard>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileCapacityCalendarDaySlotMetrics.fragment = gql`
  ${CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor.fragment}
  ${DaySlotMetric.fragment}

  fragment MobileCapacityCalendarDaySlotMetrics on CapacityCalendar {
    capacityCalendarSlotMetrics {
      id
      metric
      slotPriorityIndex
    }
    capacityCalendarDays {
      date
    }
    ...CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor
    ...DaySlotMetric
  }
`;

export default MobileCapacityCalendarDaySlotMetrics;
