// Libraries
import React from 'react';

// Supermove
import {Emoji, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

// Components
import {SignatureCapture} from 'modules/Document/components';

const Container = Styled.View`
  align-self: stretch;
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
`;

const Column = Styled.View`
  justify-content: flex-end;
`;

const SectionSpace = Styled.View`
  margin-left: ${(props) => ((props as any).mobile ? 0 : 20)}px;
  margin-top: ${(props) => ((props as any).mobile ? 20 : 0)}px;
`;

const Label = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Value = Styled.H5`
  ${fontWeight(700)}
  line-height: 18px;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Line = Styled.View`
  width: 100%;
  height: 2px;
  margin-vertical: 5px;
  background-color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H6`
`;

type OwnProps = {
  signatureRef?: any;
  onEnd: (...args: any[]) => any;
  onSave: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SignatureField.defaultProps;

// @ts-expect-error TS(7022): 'SignatureField' implicitly has type 'any' because... Remove this comment to see the full error message
const SignatureField = ({signatureRef, onEnd, onSave}: Props) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Column
        style={{
          flex: 1,
        }}
      >
        <SignatureCapture
          innerRef={signatureRef}
          onEnd={onEnd}
          onSave={onSave}
          style={{marginBottom: 5}}
        />
        <Line />
        <Row>
          <Emoji component={EmojiText} name={'pencil2'} />
          <Label>Draw signature</Label>
        </Row>
      </Column>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SectionSpace {...responsive} />
      <Column style={{width: 150}}>
        <Value>{Datetime.toDisplayDate(Datetime.today, 'M/D/YY')}</Value>
        <Line />
        <Label>Date</Label>
      </Column>
    </Container>
  );
};

SignatureField.defaultProps = {
  signatureRef: null,
};

export default SignatureField;
