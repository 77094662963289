// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon, Loading, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  ResponsiveType,
  useModal,
  useNavigationDOM,
  usePopover,
  useQuery,
  useResponsive,
} from '@supermove/hooks';
import {ProjectTypeModel, UserFlowModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import DocumentFlowDetailsDrawer from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowDetailsDrawer';
import ProjectTypeDocumentFlowDeleteModal from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeDocumentFlowDeleteModal';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const PageContainer = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  padding-horizontal: 24px;
  background-color: ${colors.gray.background};
`;

const PageHeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const PageSubHeaderText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Column = Styled.View``;

const PrimaryTableText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SecondaryTableText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const HeaderLabelSecondLine = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.gray.secondary};
`;

const UserFlowActions = ({
  projectType,
  userFlow,
  refetch,
}: {
  projectType: ProjectTypeModel;
  userFlow: UserFlowModel;
  refetch: () => void;
}) => {
  const userFlowActionsPopover = usePopover();
  const removeDocumentFlowFromProjectTypeModal = useModal({
    name: 'Remove Document Flow From Project Type Modal',
  });
  const documentFlowDetailsDrawer = useModal({name: 'Document Flow Details Drawer'});

  const actions = [
    {
      text: 'View details',
      onPress: documentFlowDetailsDrawer.handleOpen,
    },
    {
      text: 'Remove from project type',
      onPress: removeDocumentFlowFromProjectTypeModal.handleOpen,
    },
  ];

  return (
    <React.Fragment>
      <ActionMenuPopover
        key={userFlowActionsPopover.key}
        popover={userFlowActionsPopover}
        actions={actions}
        width={200}
      >
        <IconButton
          onPress={userFlowActionsPopover.handleToggle}
          isSecondary
          source={Icon.EllipsisV}
        />
      </ActionMenuPopover>
      <DocumentFlowDetailsDrawer
        key={documentFlowDetailsDrawer.key}
        userFlowUuid={userFlow.uuid}
        isOpen={documentFlowDetailsDrawer.isOpen}
        handleClose={documentFlowDetailsDrawer.handleClose}
      />
      <ProjectTypeDocumentFlowDeleteModal
        key={removeDocumentFlowFromProjectTypeModal.key}
        projectTypeId={projectType.id}
        userFlowId={userFlow.id}
        isOpen={removeDocumentFlowFromProjectTypeModal.isOpen}
        handleClose={removeDocumentFlowFromProjectTypeModal.handleClose}
        onDeleted={refetch}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  projectType,
  responsive,
  refetch,
}: {
  projectType: ProjectTypeModel;
  responsive: ResponsiveType;
  refetch: () => void;
}) => {
  return [
    {
      flex: 4,
      headerLabel: 'Name',
      cellText: (userFlow: UserFlowModel) => userFlow.name,
    },
    {
      flex: 4,
      headerLabel: 'Description',
      cellText: (userFlow: UserFlowModel) => userFlow.description,
      emptyText: '-',
    },
    {
      flex: 4,
      headerLabel: 'Project Types',
      cellText: (userFlow: UserFlowModel) =>
        userFlow.projectTypeUserFlows
          .map((projectTypeUserFlow) => projectTypeUserFlow.projectType.name)
          .join(', '),
      secondary: {
        headerLabel: 'Steps',
        cellText: (userFlow: UserFlowModel) =>
          userFlow.userFlowSteps
            .map((userFlowStep) => userFlowStep.documentTemplate.name)
            .join(', '),
      },
    },
    {
      flex: 4,
      headerLabel: 'Last Updated',
      cellText: (userFlow: UserFlowModel) => Datetime.convertToDisplayDatetime(userFlow.updatedAt),
      secondary: {
        cellText: (userFlow: UserFlowModel) => userFlow.updatedBy.fullName,
      },
    },
    {
      flex: 1,
      headerLabel: 'Actions',
      cellStyle: {
        alignItems: 'center',
      },
      cellComponent: (userFlow: UserFlowModel) => {
        return <UserFlowActions projectType={projectType} userFlow={userFlow} refetch={refetch} />;
      },
    },
  ];
};

const ProjectTypeDocumentFlowsPageContent = ({
  projectType,
  refetch,
}: {
  projectType: ProjectTypeModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={projectType} />
      <PageContentContainer>
        <ScrollView>
          <Space height={24} />
          <PageHeaderText responsive={responsive}>Document Flows</PageHeaderText>
          <Space height={12} />
          <PageSubHeaderText responsive={responsive}>
            Configure the Document Flow settings for the {projectType.name} project type.
          </PageSubHeaderText>
          <Space height={24} />
          <Table
            columnDefinitions={getColumnDefinitions({
              projectType,
              responsive,
              refetch,
            })}
            items={projectType.projectTypeUserFlows.map(
              (projectTypeUserFlow) => projectTypeUserFlow.userFlow,
            )}
            itemKey={'id'}
            emptyStateText={'No document flows'}
            hasBorder
          />
        </ScrollView>
      </PageContentContainer>
    </PageContainer>
  );
};

const ProjectTypeDocumentFlowsPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery<{projectType: ProjectTypeModel}>(
    ProjectTypeDocumentFlowsPage.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        projectTypeUuid: params.projectTypeUuid,
      },
    },
  );

  return (
    <SidebarPageV2 selected={'settings'}>
      <Loading as={PageLoadingIndicator} loading={loading}>
        {() => (
          <ProjectTypeDocumentFlowsPageContent projectType={data!.projectType} refetch={refetch} />
        )}
      </Loading>
    </SidebarPageV2>
  );
};

ProjectTypeDocumentFlowsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  query ProjectTypeDocumentFlowsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectType: projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      projectTypeUserFlows {
        id
        index
        userFlow {
          id
          uuid
          name
          description
          projectTypeUserFlows {
            projectType {
              id
              name
            }
          }
          userFlowSteps {
            id
            documentTemplate {
              id
              name
            }
          }
          updatedAt
          updatedBy {
            id
            fullName
          }
        }
      }
      ...ProjectTypeSettingsPageHeader
    }
  }
`;

export default ProjectTypeDocumentFlowsPage;
