// Libraries
import React from 'react';

// App

import SmallModalV1 from '@shared/design/components/SmallModalV1';

const Modal = ({isOpen, title, subtitle, onClose, children}: any) => {
  return (
    <SmallModalV1
      isResponsive={false}
      // @ts-expect-error TS(2322): Type '{ isResponsive: false; isOpen: any; title: a... Remove this comment to see the full error message
      isOpen={isOpen}
      title={title}
      subtitle={subtitle}
      onClose={onClose}
      children={children}
    />
  );
};

export default Modal;
