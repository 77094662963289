// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Location, Organization, User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Currency, Datetime, Phone} from '@supermove/utils';

// App
import {FieldValue} from 'modules/App/components';
import {LocationsFieldValues} from 'modules/Job/components';
import {JobTrucksList} from 'modules/JobTruck/components';
import BillingProjectBillsListV1Query from 'modules/Project/Billing/components/BillingProjectBillsListV1Query';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

const Container = Styled.View`
  padding-bottom: 120px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
  margin-horizontal: 20px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const Field = Styled.View`
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const Line = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 30 : 40)}px;
`;

const ProjectBillingColumn = Styled.View`
  flex: 1;
  padding-right: 20px;
`;

const getArrivalWindowValue = ({job}: any) => {
  const isAfternoon = Datetime.isBetweenTime(job.startTime1, '1200', '2400');
  const startTime1 = Datetime.convertToDisplayTime(job.startTime1);
  const startTime2 = Datetime.convertToDisplayTime(job.startTime2);

  if (job.organization.features.isEnabledAbleMovingMoverStartTime) {
    const customStartTime1 = Datetime.fromTime(job.startTime1).subtract(2, 'hour');
    return Datetime.toDisplayTime(customStartTime1);
  }
  if (job.organization.features.isEnabledShowEmployeeArrivalTime) {
    return isAfternoon ? `${startTime1} - ${startTime2}` : startTime1;
  } else {
    return `${startTime1} - ${startTime2}`;
  }
};

const PrimarySection = ({index, job, responsive}: any) => {
  const startTimeLabel = job.organization.features.isEnabledAbleMovingMoverStartTime
    ? 'Office / Warehouse Arrival Time'
    : `Arrival Time (at customer's origin location)`;

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      <Title>Important Information</Title>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row vars={{isFirst: true}}>
        <FieldValue label={'Date'} value={Job.getDisplayDate(job)} style={{flex: 1}} />
      </Row>
      <RowSpace {...responsive} />
      <Row>
        <FieldValue label={startTimeLabel} value={getArrivalWindowValue({job})} style={{flex: 1}} />
      </Row>
      <RowSpace {...responsive} />
      <Row>
        <Field>
          <Label>Trucks</Label>
          <JobTrucksList job={job} />
        </Field>
      </Row>
      <RowSpace {...responsive} />
      <Row>
        <FieldValue
          label={'Instructions for Crew'}
          value={job.additionalNotes}
          empty={'None'}
          style={{flex: 1}}
        />
      </Row>
    </Section>
  );
};

const ProjectSection = ({index, job}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    <Title>Project Information</Title>
    <Row>
      <FieldValue
        label={'Project Description'}
        value={job.project.description}
        empty={'None'}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const JobSection = ({index, job}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    <Title>Job Information</Title>
    <Row>
      <FieldValue
        label={'Job Description'}
        value={job.description}
        empty={'None'}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const CustomerSection = ({index, job, responsive}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    <Title>Customer Information</Title>
    <Row>
      <FieldValue label={'Contact Name'} value={User.getFullName(job.customer)} style={{flex: 1}} />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Contact Phone'}
        value={Phone.display(job.customer.phoneNumber)}
        link={Phone.createUrl(job.customer.phoneNumber)}
        style={{flex: 1}}
      />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue
        label={'Contact Email'}
        value={job.customer.email}
        link={`mailto:${job.customer.email}`}
        style={{flex: 1}}
      />
    </Row>
  </Section>
);

const MoveSection = ({index, job, responsive}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    <Title>Fees / Pricing Information</Title>
    <Row>
      <FieldValue label={'Project Size'} value={job.project.size} style={{flex: 1}} />
    </Row>
    <RowSpace {...responsive} />
    <Row>
      <FieldValue label={'Number of Movers'} value={Job.getCrewSizeText(job)} style={{flex: 1}} />
    </Row>
    {job.organization.features.isEnabledShowJobHourMinimum && (
      <React.Fragment>
        <RowSpace {...responsive} />
        <Row>
          <FieldValue
            label={'Hour Minimum'}
            value={Currency.display(job.travelFee * 100)}
            style={{flex: 1}}
          />
        </Row>
      </React.Fragment>
    )}
  </Section>
);

const BillingSection = ({index, job, responsive}: any) => (
  <Section
    // @ts-expect-error TS(2769): No overload matches this call.
    index={index}
    style={{
      marginRight: 0,
    }}
  >
    <Row>
      <ProjectBillingColumn>
        <Title>Billing Information</Title>
        <BillingProjectBillsListV1Query projectUuid={job.project.uuid} />
        <Space height={12} />
        <BillingProjectPaymentsList aggregateBill={job.project.activeJobsAggregateBill} />
      </ProjectBillingColumn>
    </Row>

    <RowSpace {...responsive} />
  </Section>
);

const AdditionalSection = ({index, job, responsive}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    <Title>Additional Information</Title>
    {_.map(Job.getAdditionalItems(job), (value, key) => (
      <React.Fragment key={key}>
        <Row {...responsive}>
          <FieldValue
            label={Organization.getJobFormAdditionalItemLabel(job.organization, {key})}
            value={Organization.getJobFormAdditionalItemValue(job.organization, {key, value})}
            empty={'None'}
            style={{
              flex: 1,
            }}
          />
        </Row>
        <RowSpace {...responsive} />
      </React.Fragment>
    ))}
    {job.organization.features.isEnabledShowJobHourMinimum && (
      <React.Fragment>
        <RowSpace {...responsive} />
        <Row>
          <FieldValue
            label={'Hour Minimum'}
            value={Currency.display(job.travelFee * 100)}
            style={{flex: 1}}
          />
        </Row>
      </React.Fragment>
    )}
  </Section>
);

const EmployeeJobFieldValues = ({canViewCustomerInfo, job}: any) => {
  const responsive = useResponsive();
  const {owningOrganization} = job.project;
  const {isEnabledCreateStorageMultipleWarehouse} = owningOrganization.features;
  const warehouseLocation = isEnabledCreateStorageMultipleWarehouse
    ? job.warehouseLocation
    : job.project.owningOrganization.warehouseLocation;

  const boundLocations = warehouseLocation ? [warehouseLocation, ...job.locations] : job.locations;

  return (
    <Container>
      <PrimarySection index={0} job={job} responsive={responsive} />
      {canViewCustomerInfo && (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <ProjectSection job={job} index={1} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <JobSection job={job} index={2} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <CustomerSection index={3} job={job} responsive={responsive} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <MoveSection index={4} job={job} responsive={responsive} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <BillingSection index={5} job={job} responsive={responsive} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Line {...responsive} />
          <AdditionalSection index={6} job={job} responsive={responsive} />
        </React.Fragment>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Line {...responsive} />
      <LocationsFieldValues
        index={6}
        totalDistance={job.totalDistance}
        locations={job.locations}
        warehouseLocation={warehouseLocation}
        boundLocations={boundLocations}
        showWarehouse={isEnabledCreateStorageMultipleWarehouse}
        style={{
          marginHorizontal: 20,
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmployeeJobFieldValues.fragment = gql`
  ${BillingProjectPaymentsList.fragment}
  ${Job.getDisplayDate.fragment}
  ${JobTrucksList.fragment}
  ${Location.getDisplayLocation.fragment}
  ${LocationsFieldValues.fragment}
  ${Organization.getJobFormAdditionalItemLabel.fragment}
  ${Organization.getJobFormAdditionalItemValue.fragment}

  fragment EmployeeJobFieldValues on Job {
    id
    description
    startTime1
    startTime2
    crewSize
    hourlyRate
    travelFee
    totalDistance
    additionalNotes
    additionalItems
    day {
      id
      value
    }
    project {
      id
      size
      uuid
      owningOrganization {
        id
        features {
          isEnabledCreateStorageMultipleWarehouse: isEnabled(
            feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
          )
        }
        warehouseLocation {
          latitude
          longitude
        }
      }
    }
    warehouseLocation {
      id
      latitude
      longitude
      ...LocationsFieldValues
    }
    organization {
      id
      slug
      features {
        isEnabledShowEmployeeArrivalTime: isEnabled(feature: "SHOW_EMPLOYEE_ARRIVAL_TIME")
        isEnabledShowJobHourMinimum: isEnabled(feature: "SHOW_JOB_HOUR_MINIMUM")
        isEnabledAbleMovingMoverStartTime: isEnabled(feature: "ABLEMOVING_MOVER_START_TIME")
      }
      ...Organization_getJobFormAdditionalItemLabel
      ...Organization_getJobFormAdditionalItemValue
    }
    project {
      id
      description
      activeJobsAggregateBill {
        ...BillingProjectPaymentsList_AggregateBill
      }
    }
    customer {
      id
      firstName
      lastName
      phoneNumber
      email
    }
    locations {
      id
      address
      floorNumber
      latitude
      longitude
      notes
      hasElevator
      hasLongWalk
      ...LocationsFieldValues
      ...Location_getDisplayLocation
    }
    ...Job_getDisplayDate
    ...JobTrucksList
  }
`;

export default EmployeeJobFieldValues;
