// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';

// App
import LocationsFieldValues from 'modules/Job/components/LocationsFieldValues';

const Container = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const getBoundLocations = ({job}: any) => {
  const {warehouseLocation} = job.project.owningOrganization;
  return warehouseLocation ? [warehouseLocation, ...job.locations] : job.locations;
};

const JobLocationsBlockContent = ({sectionIndex, job}: any) => {
  const {owningOrganization} = job.project;
  const {isEnabledCreateStorageMultipleWarehouse} = owningOrganization.features;

  const warehouseLocation = isEnabledCreateStorageMultipleWarehouse
    ? job.warehouseLocation
    : job.project.owningOrganization.warehouseLocation;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container sectionIndex={sectionIndex}>
      <LocationsFieldValues
        index={2}
        locationUpdateText={Job.getLocationUpdateText(job)}
        totalDistance={job.totalDistance}
        boundLocations={getBoundLocations({job})}
        warehouseLocation={warehouseLocation}
        locations={job.locations}
      />
    </Container>
  );
};

const JobLocationsBlockQuery = ({sectionIndex, job}: any) => {
  const {data, loading} = useQuery(JobLocationsBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {jobUuid: job.uuid},
  });

  return (
    <Loading loading={loading}>
      {() => {
        return <JobLocationsBlockContent sectionIndex={sectionIndex} job={data.job} />;
      }}
    </Loading>
  );
};

type OwnJobLocationsBlockProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'JobLocationsBlockProps' circularly ref... Remove this comment to see the full error message
type JobLocationsBlockProps = OwnJobLocationsBlockProps & typeof JobLocationsBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobLocationsBlock' implicitly has type 'any' beca... Remove this comment to see the full error message
const JobLocationsBlock = ({sectionIndex, job}: JobLocationsBlockProps) => {
  return <JobLocationsBlockQuery key={job.locationsHash} sectionIndex={sectionIndex} job={job} />;
};

JobLocationsBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobLocationsBlock.listener = gql`
  fragment JobLocationsBlock_listener on Job {
    id
    locationsHash
  }
`;

JobLocationsBlock.fragment = gql`
  ${JobLocationsBlock.listener}

  fragment JobLocationsBlock on Job {
    id
    uuid
    ...JobLocationsBlock_listener
  }
`;

JobLocationsBlock.query = gql`
  ${JobLocationsBlock.listener}
  ${Job.getLocationUpdateText.fragment}
  ${LocationsFieldValues.fragment}

  query JobLocationsBlock($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      totalDistance
      warehouseLocation {
        id
        latitude
        longitude
      }
      project {
        id
        owningOrganization {
          id
          features {
            isEnabledCreateStorageMultipleWarehouse: isEnabled(
              feature: "CREATE_STORAGE_MULTIPLE_WAREHOUSE"
            )
          }
          warehouseLocation {
            latitude
            longitude
          }
        }
      }
      locations {
        id
        ...LocationsFieldValues
      }
      ...Job_getLocationUpdateText
      ...JobLocationsBlock_listener
    }
  }
`;

export default JobLocationsBlock;
