// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectInformationForm from '@shared/modules/Project/forms/ProjectInformationForm';
import useUpdateProjectInformationMutation from '@shared/modules/Project/hooks/useUpdateProjectInformationMutation';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';

const EditProjectInformationDrawer = ({project, isOpen, handleClose}: any) => {
  const projectInformationForm = ProjectInformationForm.edit(project);
  const updateProjectInformationSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Project information updated!',
  });
  const {form, submitting, handleSubmit} = useUpdateProjectInformationMutation({
    projectInformationForm,
    onSuccess: () => {
      updateProjectInformationSuccessToast.handleToast();
      handleClose();
    },
    onError: (errors: any) => {
      console.log(errors);
    },
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText='Edit Project Information'
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
      // @ts-expect-error TS(2322): Type '{ children: Element[]; isOpen: any; handleCl... Remove this comment to see the full error message
      isResponsive
    >
      <FieldInput
        {...form}
        name={'projectInformationForm.name'}
        label={'Project Name'}
        isClearable
        isResponsive
        input={{
          autoFocus: true,
          placeholder: 'Enter project name',
        }}
      />
      <Space height={16} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={'projectInformationForm.description'}
        label={'Project Description (shown to customer)'}
        minHeight={160}
        input={{placeholder: 'Enter project description'}}
        isResponsive
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectInformationDrawer.fragment = gql`
  ${ProjectInformationForm.edit.fragment}
  fragment EditProjectInformationDrawer on Project {
    id
    ...ProjectInformationForm_edit
  }
`;

export default EditProjectInformationDrawer;
