// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useRef, useState} from '@supermove/hooks';
import {InventoryLibrary} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import InventoryLibraryObjectKind from '@shared/modules/Inventory/enums/InventoryLibraryObjectKind';
import RoomTypeForm from '@shared/modules/Inventory/forms/RoomTypeForm';
import RoomTypesForm from '@shared/modules/Inventory/forms/RoomTypesForm';
import useUpsertRoomTypesMutation from '@shared/modules/Inventory/hooks/useUpsertRoomTypesMutation';
import ImportInventoryLibraryRoomTypesModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryRoomTypesModal';
import InventoryLibraryBulkUpdateContents from 'modules/Organization/Settings/Inventory/components/InventoryLibraryBulkUpdateContents';
import InventoryLibrarySettingsPage from 'modules/Organization/Settings/Inventory/components/InventoryLibrarySettingsPage';
import getBulkActionCheckboxColumnDefinition from 'modules/Organization/Settings/Inventory/helpers/getBulkActionCheckboxColumnDefinition';

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const getCsvActions = ({importInventoryLibraryRoomsModal, inventoryLibrary}: any) => {
  return [
    {
      text: 'Import via CSV',
      onPress: importInventoryLibraryRoomsModal.handleOpen,
    },
    {
      text: 'Export via CSV',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.ROOMS,
        isTemplate: false,
      }),
    },
    {
      text: 'Download CSV template',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.ROOMS,
        isTemplate: true,
      }),
    },
  ];
};

const setFormIsDirty = ({form, field, rowIndex, isDirty = true}: any) => {
  form.setFieldValue(`${field}.${rowIndex}.isDirty`, isDirty);
};

const getColumnDefinitions = ({
  isEdit,
  inventoryLibrary,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return [
    getBulkActionCheckboxColumnDefinition({
      isEdit,
      form,
      field,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    {
      flex: 5,
      headerContent: () => {
        return <TableHeaderText>Room Name</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'roomType' implicitly has an 'any'... Remove this comment to see the full error message
      viewCellContent: ({item: roomType}) => {
        return <Text>{roomType.name}</Text>;
      },
      editCellContent: ({rowIndex}: any) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.name`}
            input={{
              autoFocus: rowIndex === _.size(_.get(form.values, field, [])) - 1,
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              placeholder: 'Enter room name',
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
            style={{flex: 1}}
            handleChange={() => setFormIsDirty({form, field, rowIndex})}
          />
        );
      },
      footerContent: () => {
        return (
          <TertiaryButton
            text={'Add Room'}
            iconLeft={Icon.Plus}
            onPress={() => {
              const existingRoomTypeForms = form.values.roomTypesForm.roomTypeForms;
              form.setFieldValue('roomTypesForm.roomTypeForms', [
                ...existingRoomTypeForms,
                RoomTypeForm.new({
                  organizationId: inventoryLibrary.organizationId,
                  inventoryLibraryId: inventoryLibrary.id,
                }),
              ]);
              setTimeout(() => scrollToBottom(), 0);
            }}
          />
        );
      },
    },
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderText>Primary Category</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'roomType' implicitly has an 'any'... Remove this comment to see the full error message
      viewCellContent: ({item: roomType}) => {
        return <Text>{_.get(roomType, 'primaryCategory.name', '')}</Text>;
      },
      editCellContent: ({rowIndex}: any) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.primaryCategoryId`}
            component={DropdownInput}
            input={{
              isClearable: true,
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              isPortaled: true,
              options: _.map(inventoryLibrary.categories, (category) => {
                return {
                  label: category.name,
                  value: category.id,
                };
              }),
              placeholder: 'Select primary category',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
              onChangeValue: () => setFormIsDirty({form, field, rowIndex}),
            }}
            style={{flex: 1}}
          />
        );
      },
      footerContent: () => {},
    },
    {
      flex: 1,
      headerContent: () => {},
      viewCellContent: () => {},
      editCellContent: ({rowIndex}: any) => {
        const isDeleted = _.get(form.values, `${field}.${rowIndex}.isDeleted`);
        return (
          <CenteredCell>
            <TertiaryButton
              text={isDeleted ? 'Restore' : 'Remove'}
              onPress={() => {
                form.setFieldValue(`${field}.${rowIndex}.isDeleted`, !isDeleted);
                setFormIsDirty({form, field, rowIndex});
              }}
              textColor={isDeleted ? colors.blue.interactive : colors.red.warning}
            />
          </CenteredCell>
        );
      },
      footerContent: () => {},
    },
  ];
};

const getViewColumnDefinitions = () => {
  return _.map(
    getColumnDefinitions({
      isEdit: false,
      inventoryLibrary: null,
      form: null,
      field: '',
      scrollToBottom: () => {},
      bulkSelectedFormIndices: [],
      setBulkSelectedFormIndices: () => {},
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.viewCellContent,
    }),
  );
};

const getEditColumnDefinitions = ({
  inventoryLibrary,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return _.map(
    getColumnDefinitions({
      isEdit: true,
      inventoryLibrary,
      form,
      field,
      scrollToBottom,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.editCellContent,
    }),
  );
};

const scrollToBottom = ({virtualizedListRef, itemCount}: any) => {
  virtualizedListRef.current.scrollToItem(itemCount);
};

const RoomsPageContent = ({inventoryLibrary, viewerId, title, refetch}: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [bulkSelectedFormIndices, setBulkSelectedFormIndices] = useState([]);
  const importInventoryLibraryRoomsModal = useModal({name: 'Import Inventory Library Rooms Modal'});
  const virtualizedListRef = useRef(null);

  // View
  const [searchTerm, setSearchTerm] = useState('');
  const viewColumnDefinitions = getViewColumnDefinitions();
  const csvActions = getCsvActions({importInventoryLibraryRoomsModal, inventoryLibrary});
  const filteredRoomTypes = _.filter(inventoryLibrary.roomTypes, (roomType) =>
    roomType.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Edit
  const roomTypesForm = RoomTypesForm.edit(inventoryLibrary);
  const {form, handleSubmit, submitting} = useUpsertRoomTypesMutation({
    roomTypesForm,
    onSuccess: () => {
      form.resetForm();
      refetch();
      setIsEdit(false);
    },
    onError: (errors: any) => console.log(errors),
  });
  const field = 'roomTypesForm.roomTypeForms';
  const editColumnDefinitions = getEditColumnDefinitions({
    inventoryLibrary,
    form,
    field,
    scrollToBottom: () =>
      scrollToBottom({virtualizedListRef, itemCount: _.get(form.values, field).length}),
    bulkSelectedFormIndices,
    setBulkSelectedFormIndices,
  });

  // FEATURE FLAG REMOVAL: SURVEYS_TIME_ADDITIVES
  const {isEnabledSurveysTimeAdditives} = inventoryLibrary.organization.features;

  return (
    <React.Fragment>
      <InventoryLibraryBulkUpdateContents
        ViewComponent={InventoryLibraryBulkUpdateContents.ViewTable}
        viewComponentProps={{
          columnDefinitions: viewColumnDefinitions,
          tableItems: filteredRoomTypes,
          emptyStateText: `Click “Edit” to create a room and it'll appear here.`,
        }}
        EditComponent={InventoryLibraryBulkUpdateContents.EditTable}
        editComponentProps={{
          form,
          field,
          itemKey: 'roomTypeId',
          columnDefinitions: editColumnDefinitions,
          virtualizedListRef,
        }}
        editHeaderProps={{
          field,
          bulkSelectedFormIndices,
          setBulkSelectedFormIndices,
        }}
        isEdit={isEdit}
        setIsEdit={(isEdit: any) => {
          setIsEdit(isEdit);
          setSearchTerm('');
        }}
        title={title}
        csvActions={csvActions}
        setSearchTerm={setSearchTerm}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
      />
      <ImportInventoryLibraryRoomTypesModal
        key={importInventoryLibraryRoomsModal.key}
        viewerId={viewerId}
        organizationId={inventoryLibrary.organizationId}
        inventoryLibraryId={inventoryLibrary.id}
        isOpen={importInventoryLibraryRoomsModal.isOpen}
        handleClose={importInventoryLibraryRoomsModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const InventoryLibraryRoomsPage = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(InventoryLibraryRoomsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid: params.libraryUuid,
    },
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <InventoryLibrarySettingsPage
      isLoading={loading}
      inventoryLibraryUuid={params.libraryUuid}
      inventoryLibraryName={data ? data.inventoryLibrary.name : ''}
      inventoryLibraryKind={data ? data.inventoryLibrary.kind : ''}
    >
      {data && (
        <RoomsPageContent
          key={refreshKey}
          inventoryLibrary={data.inventoryLibrary}
          viewerId={data.viewer.id}
          title={'Rooms'}
          refetch={refetchWithKeyUpdate}
        />
      )}
    </InventoryLibrarySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryLibraryRoomsPage.query = gql`
  ${InventoryLibrary.handleFileDownload.fragment}
  ${RoomTypesForm.edit.fragment}
  ${InventoryLibraryBulkUpdateContents.fragment}

  query InventoryLibraryRoomsPage($inventoryLibraryUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      name
      kind
      organizationId
      organization {
        id
        ...InventoryLibraryBulkUpdateContents
      }
      roomTypes {
        id
        name
        primaryCategory {
          id
          name
        }
      }
      categories {
        id
        name
      }
      ...InventoryLibrary_handleFileDownload
      ...RoomTypesForm_edit
    }
  }
`;

export default InventoryLibraryRoomsPage;
