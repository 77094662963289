// Supermove
import {gql} from '@supermove/graphql';
import {ItemTypeModel, ItemTypeCategoryModel} from '@supermove/models';
import {Currency, Float, withFragment} from '@supermove/utils';

// App
import {ItemTypeKindType} from '@shared/modules/Inventory/enums/ItemTypeKind';

export interface ItemTypeFormType {
  itemTypeId: string;
  organizationId: number;
  kind: ItemTypeKindType;
  name: string;
  weight: number;
  volume: number;
  price: number;
  packTime: number;
  unpackTime: number;
  isDerivedWeight: boolean;
  categoryIds: string[];
  inventoryLibraryId: number;
  isDeleted: boolean;
  // private
  isDirty: boolean;
}

export interface ItemTypeFormToFormType {
  itemTypeId: string;
  organizationId: number;
  kind: ItemTypeKindType;
  name: string;
  weight: string;
  volume: string;
  price: string;
  packTime: string;
  unpackTime: string;
  isDerivedWeight: boolean;
  categoryIds: string[];
  inventoryLibraryId: number;
  isDeleted: boolean;
  // private
  isDirty: boolean;
}

export interface ItemTypeFormNewType {
  itemTypeId: string | undefined;
  organizationId: number;
  kind: ItemTypeKindType;
  name: string;
  weight: string;
  volume: string;
  price: string;
  packTime: string;
  unpackTime: string;
  isDerivedWeight: boolean;
  categoryIds: string[];
  inventoryLibraryId: number | null;
  isDeleted: boolean;
  // private
  isDirty: boolean;
}

export interface ItemTypeFormToMutationType {
  itemTypeId: string | undefined;
  organizationId: number;
  kind: ItemTypeKindType;
  name: string;
  weight: number;
  volume: number;
  price: number;
  packTime: number;
  unpackTime: number;
  isDerivedWeight: boolean;
  categoryIds: string[];
  inventoryLibraryId: number | null;
  isDeleted: boolean;
}

const edit = withFragment(
  (itemType: ItemTypeModel): ItemTypeFormType => ({
    itemTypeId: itemType.id,
    organizationId: itemType.organizationId,
    kind: itemType.kind,
    name: itemType.name,
    weight: itemType.weight,
    volume: itemType.volume,
    price: itemType.price,
    packTime: itemType.packTime,
    unpackTime: itemType.unpackTime,
    isDerivedWeight: itemType.isDerivedWeight,
    categoryIds: itemType.itemTypeCategories.map((itemTypeCategory: ItemTypeCategoryModel) => {
      return String(itemTypeCategory.categoryId);
    }),
    inventoryLibraryId: itemType.inventoryLibraryId,
    isDeleted: itemType.isDeleted,
    // private
    isDirty: false,
  }),
  gql`
    fragment ItemTypeForm_edit on ItemType {
      id
      organizationId
      kind
      name
      weight
      volume
      price
      packTime
      unpackTime
      isDerivedWeight
      itemTypeCategories {
        id
        categoryId
      }
      inventoryLibraryId
      isDeleted
    }
  `,
);

const _new = ({
  organizationId,
  kind,
  inventoryLibraryId = null,
}: {
  organizationId: number;
  kind: ItemTypeKindType;
  inventoryLibraryId: number | null;
}): ItemTypeFormNewType => ({
  itemTypeId: undefined,
  organizationId,
  kind,
  name: '',
  weight: '',
  volume: '',
  price: '',
  packTime: '',
  unpackTime: '',
  isDerivedWeight: true,
  categoryIds: [],
  inventoryLibraryId,
  isDeleted: false,

  // private
  isDirty: true,
});

const toForm = ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
}: ItemTypeFormType): ItemTypeFormToFormType => ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight: Float.toForm(weight),
  volume: Float.toForm(volume),
  price: Currency.toForm(price),
  packTime: Float.toForm(packTime),
  unpackTime: Float.toForm(unpackTime),
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,

  // private
  isDirty,
});

const toMutation = ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight,
  volume,
  price,
  packTime,
  unpackTime,
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,
}: ItemTypeFormToFormType): ItemTypeFormToMutationType => ({
  itemTypeId,
  organizationId,
  kind,
  name,
  weight: Float.toMutation(weight) as number,
  volume: Float.toMutation(volume) as number,
  price: Currency.toMutation(price),
  packTime: Float.toMutation(packTime) as number,
  unpackTime: Float.toMutation(unpackTime) as number,
  isDerivedWeight,
  categoryIds,
  inventoryLibraryId,
  isDeleted,
});

const ItemTypeForm = {
  edit,
  new: _new,
  toForm,
  toMutation,
};

export default ItemTypeForm;
