// Libraries
import _ from 'lodash';
import React from 'react';
import {Redirect} from 'react-router-dom';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import Page from 'modules/App/components/Page';

const ProjectQuoteCustomerPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => {
  return (
    <Page fetchPolicy={'network-only'} query={ProjectQuoteCustomerPage.query} variables={{uuid}}>
      {({loading, data}: any) => (
        <Loading loading={loading}>
          {() => {
            const {project} = data;
            if (!project.quote || project.quote?.isExpired || project.isCancelled) {
              return <Redirect to={`/0/${slug}/projects/${uuid}/quote/expired`} />;
            }

            const nextStep = _.get(data, 'project.quote.nextStep');
            const documentUuid = _.get(nextStep, 'documentUuid', null);

            switch (_.get(data, 'project.quote.nextStep.kind', null)) {
              case ConfirmationStepKind.QUOTE_STEP_ACCEPT_QUOTE:
                return <Redirect to={`/0/${slug}/projects/${uuid}/quote/info`} />;
              case ConfirmationStepKind.QUOTE_STEP_ACCEPT_INVENTORY:
                return <Redirect to={`/0/${slug}/projects/${uuid}/quote/inventory`} />;
              case ConfirmationStepKind.QUOTE_STEP_DOCUMENT:
                return (
                  <Redirect to={`/0/${slug}/projects/${uuid}/quote/documents/v2/${documentUuid}`} />
                );
              default:
                return <Redirect to={`/0/${slug}/projects/${uuid}/quote/success`} />;
            }
          }}
        </Loading>
      )}
    </Page>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectQuoteCustomerPage.query = gql`
  query ProjectQuoteCustomerPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      quote {
        id
        kind
        isExpired
        isComplete
        nextStep {
          kind
          value
          documentUuid
        }
      }
    }
  }
`;

export default ProjectQuoteCustomerPage;
