/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
  padding-horizontal: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Index = Styled.H5`
  margin-right: 20px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const TextInput = Styled.TextInput.H7`
  width: 150px;
  margin-right: 10px;
`;

const DocumentAdditionalInfoGridInput = ({
  isFirst,
  isLast,
  name,
  index,
  columns,
  values,
  setFieldValue,
  setFieldTouched,
}: any) => {
  const data = _.get(values, name);

  return (
    <Container>
      {isFirst && <Line />}
      <Content>
        <Index>{`${index + 1}.`}</Index>
        {data.map((value: any, columnIndex: any) => {
          const isDisabled = _.get(columns, `${columnIndex}.isDisabled`, false);
          const isHidden = _.get(columns, `${columnIndex}.isHidden`, false);

          if (isHidden) {
            return null;
          }

          return (
            <TextInput
              key={columnIndex}
              disabled={isDisabled}
              name={`${name}.${columnIndex}`}
              placeholder={''}
              value={value}
              onChangeText={(text: any) => setFieldValue(`${name}.${columnIndex}`, text)}
              onBlur={() => setFieldTouched(`${name}.${columnIndex}`, true)}
            />
          );
        })}
      </Content>
      <Line />
    </Container>
  );
};

export default DocumentAdditionalInfoGridInput;
