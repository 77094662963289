// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useHover,
  usePopover,
  useResponsive,
  useState,
  useExportRowsToCsv,
} from '@supermove/hooks';
import {PayrollReport, CompensationReport} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import CompensationReportStatus from '@shared/modules/Accounting/enums/CompensationReportStatus';
import CompensationReportForm from '@shared/modules/Accounting/forms/CompensationReportForm';
import useUpdateCompensationReportMutation from '@shared/modules/Accounting/hooks/useUpdateCompensationReportMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import CompensationReportActions from 'modules/Accounting/CompensationReport/components/CompensationReportActions';
import CompensationReportsDrawer from 'modules/Accounting/CompensationReport/components/CompensationReportsDrawer';
import Line from 'modules/App/components/Line';

const Button = Styled.ButtonV2`
`;

const ButtonText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.blue.interactive};
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const ListContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
`;

const TableRow = Styled.View`
  flex-direction: row;
  padding-left: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 8 : 12)}px;
  padding-vertical: 8px;
  align-items: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Cell = Styled.View`
  flex: ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex};
  flex-direction: row;
  padding-horizontal: 8px;
`;

const CellText = Styled.Text`
  ${Typography.Body4}
`;

const StatusOptionButton = Styled.ButtonV2`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  margin-vertical: 1px;
`;

const StatusOptionText = Styled.Text`
  ${Typography.Body4}
  padding-vertical: 4px;
  padding-horizontal: 8px;
  color: ${colors.blue.interactive};
`;

const StatusButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 4px;
  padding-horizontal: 8px;
  width: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '76px' : '104px')};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isPaidOut' does not exist on type 'Theme... Remove this comment to see the full error message
    isPaidOut,
  }) => (isPaidOut ? colors.green.accent : colors.gray.background)};
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'borderColor' does not exist on type 'The... Remove this comment to see the full error message
    borderColor,
  }) => borderColor};
  border-width: 1px;
  border-radius: 4px;
`;

const StatusButtonText = Styled.Text`
  ${Typography.Label4};
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const StatusMenuPopoverContainer = Styled.View`
  width: 104px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
  min-height: 120px;
`;

const EmptyStateTitle = Styled.Text`
  ${Typography.Body3}
`;

const getStatusBorderColor = ({status, isActive}: any) => {
  if (status === CompensationReportStatus.PAID_OUT) {
    if (isActive) {
      return colors.green.status;
    }
    return colors.green.accent;
  }
  if (isActive) {
    return colors.gray.border;
  }
  return colors.gray.background;
};

const handleUpdateCompensationReportStatus = ({form, field, handleSubmit, status}: any) => {
  form.setFieldValue(`${field}.status`, status);
  setTimeout(() => handleSubmit(), 0);
};

const handleResetCompensationReportFormStatus = ({form, field, compensationReport}: any) => {
  form.setFieldValue(`${field}.status`, compensationReport.status);
};

const StatusOption = ({form, field, handleSubmit, status}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <StatusOptionButton
      onPress={() => handleUpdateCompensationReportStatus({form, field, handleSubmit, status})}
      isHovered={isHovered}
      ref={ref}
    >
      <StatusOptionText>{CompensationReportStatus.getDisplayStatus(status)}</StatusOptionText>
    </StatusOptionButton>
  );
};

const StatusMenuPopover = ({popover, form, field, handleSubmit}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <StatusMenuPopoverContainer>
        <Space height={7} />
        {CompensationReportStatus.VALUES.map((status) => {
          return (
            <StatusOption
              key={status}
              form={form}
              field={field}
              handleSubmit={handleSubmit}
              status={status}
            />
          );
        })}
        <Space height={7} />
      </StatusMenuPopoverContainer>
    </Popover>
  );
};

const StatusSelector = ({form, field, handleSubmit, viewerUserRole}: any) => {
  const responsive = useResponsive();
  const {ref, isHovered} = useHover();
  const statusPopover = usePopover();
  const disabled = !UserRole.getIsStaffAdmin(viewerUserRole);
  const isActive = (isHovered || statusPopover.isOpen) && !disabled;
  const status = _.get(form.values, `${field}.status`);
  const isPaidOut = status === CompensationReportStatus.PAID_OUT;

  return (
    <React.Fragment>
      <Popover.Content innerRef={statusPopover.ref}>
        <StatusButton
          onPress={statusPopover.handleToggle}
          ref={ref}
          isActive={isActive}
          isPaidOut={isPaidOut}
          borderColor={getStatusBorderColor({status, isActive})}
          disabled={disabled}
          {...responsive}
        >
          <StatusButtonText color={isPaidOut ? colors.green.status : colors.gray.tertiary}>
            {isPaidOut ? 'Paid Out' : 'Not Paid Out'}
          </StatusButtonText>
          <Space style={{flex: 1}} />
          {!disabled && (
            <Icon
              source={Icon.CaretDown}
              size={13}
              color={isPaidOut ? colors.green.status : colors.gray.tertiary}
            />
          )}
        </StatusButton>
      </Popover.Content>
      <StatusMenuPopover
        popover={statusPopover}
        form={form}
        field={field}
        handleSubmit={() => {
          handleSubmit();
          statusPopover.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const COMPENSATION_REPORT_COLUMNS = [
  {
    flex: 2,
    getIsVisible: ({viewerUserRole}: any) => !UserRole.getIsStaffAdmin(viewerUserRole),
    headerContent: () => {
      return <TableHeaderText>Pay Period</TableHeaderText>;
    },
    cellContent: ({compensationReport}: any) => {
      return (
        <CellText>
          {PayrollReport.getDateRange(compensationReport.payrollReport, {isShortened: true})}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    getIsVisible: ({viewerUserRole, mobile}: any) =>
      UserRole.getIsStaffAdmin(viewerUserRole) && !mobile,
    headerContent: () => {
      return <TableHeaderText>Report Number</TableHeaderText>;
    },
    cellContent: ({
      handleViewReport,
      compensationReport,
      isEnabledCompensationCsvDownload,
    }: any) => {
      return isEnabledCompensationCsvDownload ? (
        <Button onPress={handleViewReport}>
          <LinkText>{compensationReport.identifier}</LinkText>
        </Button>
      ) : (
        <CellText>{compensationReport.identifier}</CellText>
      );
    },
  },
  {
    flex: 2,
    getIsVisible: ({viewerUserRole}: any) => UserRole.getIsStaffAdmin(viewerUserRole),
    headerContent: () => {
      return <TableHeaderText>Employee</TableHeaderText>;
    },
    cellContent: ({compensationReport}: any) => {
      return <CellText>{compensationReport.user.fullName}</CellText>;
    },
  },
  {
    flex: 2,
    getIsVisible: ({mobile}: any) => !mobile,
    headerContent: () => {
      return <TableHeaderText>Position</TableHeaderText>;
    },
    cellContent: ({compensationReport}: any) => {
      return <CellText>{compensationReport.user.position}</CellText>;
    },
  },
  {
    flex: 2,
    getIsVisible: ({mobile}: any) => !mobile,
    headerContent: () => {
      return <TableHeaderText>Projects</TableHeaderText>;
    },
    cellContent: ({compensationReport}: any) => {
      return <CellText>{compensationReport.numberOfProjects}</CellText>;
    },
  },
  {
    flex: 2,
    getIsVisible: () => true,
    headerContent: () => {
      return <TableHeaderText numberOfLines={1}>Compensation</TableHeaderText>;
    },
    cellContent: ({compensationReport}: any) => {
      return (
        <CellText>
          {Currency.display(compensationReport.total, {shouldHideCentsIfZero: true})}
        </CellText>
      );
    },
  },
  {
    flex: 2,
    getIsVisible: ({viewerUserRole, mobile}: any) => UserRole.hasOfficePermissions(viewerUserRole),
    headerContent: () => {
      return <TableHeaderText>Status</TableHeaderText>;
    },
    cellContent: ({form, field, handleSubmit, viewerUserRole}: any) => {
      return (
        <StatusSelector
          form={form}
          field={field}
          handleSubmit={handleSubmit}
          viewerUserRole={viewerUserRole}
        />
      );
    },
  },
  {
    flex: 1,
    getIsVisible: ({isCostAndCompensationEnabled, isEnabledCompensationCsvDownload, mobile}: any) =>
      isCostAndCompensationEnabled && !mobile && !isEnabledCompensationCsvDownload,
    headerContent: () => {
      return <TableHeaderText>PDF</TableHeaderText>;
    },
    cellContent: ({form, field, compensationReport, organizationSlug}: any) => {
      const disabled =
        _.get(form.values, `${field}.status`) === CompensationReportStatus.NOT_PAID_OUT;
      return (
        <a
          href={`/0/${organizationSlug}/compensation-reports/${compensationReport.uuid}/print`}
          target={'_blank'}
          // @ts-expect-error TS(2322): Type '"none" | null' is not assignable to type 'Po... Remove this comment to see the full error message
          style={{padding: 4, pointerEvents: disabled ? 'none' : null}}
        >
          <Icon
            source={Icon.FileDownload}
            size={14}
            color={disabled ? colors.gray.tertiary : colors.blue.interactive}
          />
        </a>
      );
    },
  },
  {
    flex: 2,
    getIsVisible: () => true,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    cellContent: ({
      handleViewReport,
      handleExport,
      organizationSlug,
      compensationReport,
      isEnabledCompensationCsvDownload,
      compensationReportActionsPopover,
    }: any) => {
      return isEnabledCompensationCsvDownload ? (
        <CompensationReportActions
          organizationSlug={organizationSlug}
          compensationReport={compensationReport}
          handleExport={handleExport}
          compensationReportActionsPopover={compensationReportActionsPopover}
        />
      ) : (
        <Button onPress={handleViewReport}>
          <ButtonText>View Report</ButtonText>
        </Button>
      );
    },
  },
];

const CompensationReportRow = ({
  compensationReport,
  handleViewReport,
  organizationSlug,
  refetch,
  viewerUserRole,
  isCostAndCompensationEnabled,
  isEnabledCompensationCsvDownload,
}: any) => {
  const responsive = useResponsive();
  const compensationReportActionsPopover = usePopover();
  const compensationReportForm = CompensationReportForm.edit(compensationReport);
  const {form, handleSubmit} = useUpdateCompensationReportMutation({
    compensationReportForm,
    onSuccess: () => refetch(),
    onError: (errors: any) => {
      handleResetCompensationReportFormStatus({form, field, compensationReport});
      console.log({errors});
      refetch();
    },
  });
  const field = 'compensationReportForm';
  const {handleExport} = useExportRowsToCsv();

  return (
    <TableRow {...responsive}>
      {COMPENSATION_REPORT_COLUMNS.map((column, columnIndex) => {
        const isVisible = column.getIsVisible({
          viewerUserRole,
          isCostAndCompensationEnabled,
          isEnabledCompensationCsvDownload,
          ...responsive,
        });
        return (
          isVisible && (
            // @ts-expect-error TS(2769): No overload matches this call.
            <Cell key={columnIndex} flex={column.flex}>
              {column.cellContent({
                compensationReport,
                form,
                field,
                handleSubmit,
                handleViewReport,
                organizationSlug,
                viewerUserRole,
                handleExport,
                isEnabledCompensationCsvDownload,
                compensationReportActionsPopover,
              })}
            </Cell>
          )
        );
      })}
    </TableRow>
  );
};

const CompensationReportsList = ({
  compensationReports,
  organizationSlug,
  refetch,
  viewerUserRole,
  isCostAndCompensationEnabled,
  isEnabledCompensationCsvDownload,
}: any) => {
  const responsive = useResponsive();
  const compensationReportsDrawer = useDrawer({
    name: 'Compensation Reports Drawer',
    enableTracking: true,
  });
  const [viewingCompensationReportIndex, setViewingCompensationReportIndex] = useState(0);
  return (
    <ListContainer>
      <TableRow {...responsive} style={{backgroundColor: colors.gray.background}}>
        {COMPENSATION_REPORT_COLUMNS.map((column, columnIndex) => {
          const isVisible = column.getIsVisible({
            viewerUserRole,
            isCostAndCompensationEnabled,
            isEnabledCompensationCsvDownload,
            ...responsive,
          });
          return (
            isVisible && (
              // @ts-expect-error TS(2769): No overload matches this call.
              <Cell key={columnIndex} flex={column.flex}>
                {column.headerContent()}
              </Cell>
            )
          );
        })}
      </TableRow>
      {_.isEmpty(compensationReports) ? (
        <React.Fragment>
          <Line />
          <EmptyStateContainer>
            <Space height={45} />
            <EmptyStateTitle>There are no compensation reports for this pay period</EmptyStateTitle>
          </EmptyStateContainer>
        </React.Fragment>
      ) : (
        compensationReports.map((compensationReport: any, index: any) => (
          <React.Fragment key={index}>
            <Line />
            <CompensationReportRow
              key={compensationReport.status}
              compensationReport={compensationReport}
              organizationSlug={organizationSlug}
              handleViewReport={() => {
                setViewingCompensationReportIndex(index);
                compensationReportsDrawer.handleOpen();
              }}
              refetch={refetch}
              viewerUserRole={viewerUserRole}
              isCostAndCompensationEnabled={isCostAndCompensationEnabled}
              isEnabledCompensationCsvDownload={isEnabledCompensationCsvDownload}
            />
          </React.Fragment>
        ))
      )}
      <CompensationReportsDrawer
        isOpen={compensationReportsDrawer.isOpen}
        handleClose={compensationReportsDrawer.handleClose}
        viewingCompensationReportIndex={viewingCompensationReportIndex}
        setViewingCompensationReportIndex={setViewingCompensationReportIndex}
        compensationReports={compensationReports}
      />
    </ListContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompensationReportsList.fragment = gql`
  ${CompensationReportForm.edit.fragment}
  ${CompensationReportsDrawer.fragment}
  ${PayrollReport.getDateRange.fragment}
  ${CompensationReport.constructCSVRowsForCompensationReport.fragment}

  fragment CompensationReportsList on CompensationReport {
    id
    uuid
    identifier
    status
    total
    numberOfProjects
    user {
      id
      fullName
      position
    }
    payrollReport {
      id
      ...PayrollReport_getDateRange
    }
    ...CompensationReport_constructCSVRowsForCompensationReport
    ...CompensationReportForm_edit
    ...CompensationReportsDrawer
  }
`;

export default CompensationReportsList;
