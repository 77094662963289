// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Styled} from '@supermove/components';
import {useHover, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';

const Container = Styled.View`
`;

const SelectionText = Styled.Text`
  ${Typography.Body}
  paddingLeft: 2px;
  paddingTop: 2px;
`;

const Placeholder = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  paddingLeft: 2px;
  paddingTop: 2px;
`;

const DisabledText = ({form, field, options}: any) => {
  const currentSelectionValue = _.get(form.values, `${field}`);
  const currentSelectionOption = _.find(
    options,
    (option) => option.value === currentSelectionValue,
  );
  return (
    <Table.CellTextWithDefault style={{color: colors.gray.secondary}}>
      {_.get(currentSelectionOption, 'label')}
    </Table.CellTextWithDefault>
  );
};

const DropdownIcon = () => {
  return (
    <Icon
      source={Icon.ChevronDown}
      size={12}
      color={colors.gray.tertiary}
      style={{position: 'absolute', right: 4, top: 6}}
    />
  );
};

const InlineEditDropdownInput = ({
  options,
  form,
  field,
  handleSubmit,
  placeholder,
  isDisabled,
  showDescriptionInOption,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownHover = useHover();
  const isFocused = !isDisabled && (dropdownHover.isHovered || isOpen);

  if (isDisabled) {
    return <DisabledText form={form} field={field} options={options} />;
  }
  return (
    <Container
      ref={dropdownHover.ref}
      style={{flex: 1, marginLeft: -4, marginTop: -3, marginBottom: -1}}
    >
      <DropdownInput
        name={field}
        placeholder={placeholder}
        value={_.get(form.values, field)}
        disabled={isDisabled}
        options={options}
        setFieldValue={form.setFieldValue}
        onChangeValue={(value, option, prevValue) => {
          if (value !== prevValue) {
            // Use setTimeout to ensure handleSubmit is behind the form update
            // in the event loop.
            setTimeout(() => handleSubmit(value, option), 0);
          }
          dropdownHover.handleToggleOff();
        }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => {
          setIsOpen(false);
          dropdownHover.handleToggleOff();
        }}
        // Overrides default height and allows the option height to be dynamic
        // @ts-expect-error TS(2322): Type '{ name: any; placeholder: any; value: any; d... Remove this comment to see the full error message
        optionHeight={null}
        style={{
          flex: 1,
          height: null,
          minHeight: null,
          borderColor: isFocused ? colors.gray.tertiary : colors.white,
        }}
        valueContainerStyle={{
          paddingLeft: 1,
        }}
        inputStyle={{
          paddingTop: 4,
          paddingLeft: 2,
          paddingBottom: 1,
          minHeight: 22,
        }}
        showDescriptionInOption={showDescriptionInOption}
        components={{
          DropdownIndicator: () => isFocused && <DropdownIcon />,
          SingleValue: ({children}: any) => <SelectionText>{children}</SelectionText>,
          Placeholder: ({children}: any) => <Placeholder>{children}</Placeholder>,
        }}
      />
    </Container>
  );
};

export default InlineEditDropdownInput;
