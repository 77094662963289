// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import CardConfirmationProjectFormPayEngine from 'modules/Customer/Project/Confirmation/Card/components/CardConfirmationProjectFormPayEngine';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 20 : 0)}px;
`;

const isCorrectPage = ({project}: any) => {
  return (
    _.get(project, 'confirmation.nextStep.kind') ===
      ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD &&
    !project?.isCancelled &&
    !project?.confirmation?.isExpired
  );
};

const onMountOrUpdate = ({navigator, project}: any) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/confirmation`);
  }
};

// If the next step of the confirmation is not to save a credit card,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}: any) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const CardConfirmationProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => (
  <ProjectPage variables={{uuid}} query={CardConfirmationProjectPage.query}>
    {({responsive, navigator, data}: any) => {
      const {project} = data;
      const {confirmation} = project;
      const redirectRoute = `/0/${project.organization.slug}/projects/${project.uuid}/confirmation`;
      return (
        <Redirect navigator={navigator} project={project}>
          <Wrapper>
            <ScrollView
              contentContainerStyle={{
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Container {...responsive}>
                <CardConfirmationProjectFormPayEngine
                  project={project}
                  onSuccess={() => navigator.push(redirectRoute)}
                  beforeSubmit={(handleSubmit: any) => {
                    if (
                      confirmation.expirationDatetime &&
                      Datetime.fromDatetime(confirmation.expirationDatetime) <= Datetime.now
                    ) {
                      navigator.push(redirectRoute);
                    } else {
                      handleSubmit();
                    }
                  }}
                />
              </Container>
            </ScrollView>
          </Wrapper>
        </Redirect>
      );
    }}
  </ProjectPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CardConfirmationProjectPage.query = gql`
  ${ProjectPage.fragment}
  ${CardConfirmationProjectFormPayEngine.fragment}
  ${ConfirmationStepHeader.fragment}

  query CardConfirmationProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      confirmation {
        id
        isExpired
        expirationDatetime
        nextStep {
          kind
          value
          valueTwo
        }
        ...ConfirmationStepHeader
      }
      organization {
        id
        slug
      }
      ...ProjectPage
      ...CardConfirmationProjectFormPayEngine
    }
  }
`;

export default CardConfirmationProjectPage;
