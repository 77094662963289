// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';
import useUpdateJobTypeMutation from '@shared/modules/Job/hooks/useUpdateJobTypeMutation';
import JobTypeModalFields from 'modules/Organization/Settings/JobTypes/components/JobTypeModalFields';

const EditJobTypeModalContent = ({jobType, isOpen, handleClose, refetch}: any) => {
  const jobTypeForm = JobTypeForm.edit(jobType);
  const {form, handleSubmit, submitting} = useUpdateJobTypeMutation({
    jobTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Edit Job Type'}</SmallModal.HeaderText>
      <Space height={16} />
      <JobTypeModalFields form={form} projectType={jobType.projectType} isUpdate />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

const EditJobTypeModal = ({jobTypeUuid, isOpen, handleClose, refetch}: any) => {
  const {loading, data} = useQuery(EditJobTypeModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {jobTypeUuid},
  });

  if (loading || !data) {
    return null;
  }

  return (
    <EditJobTypeModalContent
      jobType={data.jobType}
      isOpen={isOpen}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobTypeModal.query = gql`
  ${JobTypeForm.edit.fragment}
  ${JobTypeModalFields.fragment}

  query EditJobTypeModal($jobTypeUuid: String!) {
    ${gql.query}
    jobType(uuid: $jobTypeUuid) {
      id
      projectType {
        id
        billingLibrary {
          id
          billTypes {
            id
            name
          }
        }
        ...JobTypeModalFields
      }
      ...JobTypeForm_edit
    }
  }
`;

export default EditJobTypeModal;
