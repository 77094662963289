// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ConfirmationStepForm from '@shared/modules/Proposal/forms/ConfirmationStepForm';
import useEditConfirmationStepMutation from '@shared/modules/Proposal/hooks/useEditConfirmationStepMutation';
import QuoteStepModalFields from 'modules/Organization/Settings/ProjectTypes/components/QuoteStepModalFields';

const EditQuoteStepModal = ({
  confirmationStep,
  confirmationStepsId,
  isOpen,
  handleClose,
  projectType,
  refetch,
}: any) => {
  const confirmationStepForm = ConfirmationStepForm.edit({
    ...confirmationStep,
    confirmationStepsId,
  });
  const {form, handleSubmit, submitting} = useEditConfirmationStepMutation({
    confirmationStepForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Edit Quote Step'}</SmallModal.HeaderText>
      <Space height={16} />
      <QuoteStepModalFields form={form} projectType={projectType} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={false} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditQuoteStepModal.fragment = gql`
  ${QuoteStepModalFields.fragment}
  fragment EditQuoteStepModal on ProjectType {
    id
    ...QuoteStepModalFields
  }
`;

export default EditQuoteStepModal;
