// Libararies
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  useUrlFilters,
  ResponsiveType,
  useResponsive,
  usePopover,
} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';
import {NavigationType} from '@supermove/navigation/src/NavigationTypes';
import {Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import IconButton from '@shared/design/components/IconButton';
import Table from '@shared/design/components/Table';
import DocumentFlowDetailsDrawer from 'modules/Organization/Settings/DocumentFlows/components/DocumentFlowDetailsDrawer';
import OrganizationSettingsDocumentFlowDeleteModal from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowDeleteModal';
import {OrganizationSettingsDocumentFlowsFiltersType} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersTypes';
import OrganizationSettingsDocumentFlowsPageHeader from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsPageHeader';

import OrganizationSettingsDocumentFlowDuplicateModal from './OrganizationSettingsDocumentFlowDuplicateModal';

type OrganizationSettingsDocumentFlowsPageContentQueryType = {
  filteredUserFlows: UserFlowModel[];
};

const LoadingIndicator = Styled.Loading``;

const UserFlowActions = ({
  navigator,
  userFlow,
  refetch,
}: {
  navigator: NavigationType;
  userFlow: UserFlowModel;
  refetch: () => void;
}) => {
  const userFlowActionsPopover = usePopover();
  const deleteUserFlowModal = useModal({name: 'Delete User Flow Modal'});
  const documentFlowDetailsDrawer = useModal({name: 'Document Flow Details Drawer'});
  const duplicateDocumentFlowModal = useModal({name: 'Duplicate Document Flow Modal'});

  const actions = [
    {
      text: 'View details',
      onPress: documentFlowDetailsDrawer.handleOpen,
    },
    {
      text: 'View runs',
      onPress: () => {
        navigator.push(`/settings/projects/document-flows/${userFlow.uuid}/runs`);
      },
    },
    {
      text: 'Duplicate flow',
      onPress: duplicateDocumentFlowModal.handleOpen,
    },
    {
      text: 'Delete flow',
      onPress: deleteUserFlowModal.handleOpen,
    },
  ];

  return (
    <React.Fragment>
      <IconButton
        onPress={() => {
          navigator.push(`/settings/projects/document-flows/${userFlow.uuid}/edit`);
        }}
        source={Icon.Pen}
      />
      <Space width={8} />
      <ActionMenuPopover
        key={userFlowActionsPopover.key}
        popover={userFlowActionsPopover}
        actions={actions}
        width={200}
      >
        <IconButton
          onPress={userFlowActionsPopover.handleToggle}
          isSecondary
          source={Icon.EllipsisV}
        />
      </ActionMenuPopover>
      <OrganizationSettingsDocumentFlowDeleteModal
        key={deleteUserFlowModal.key}
        userFlow={userFlow}
        isOpen={deleteUserFlowModal.isOpen}
        handleClose={deleteUserFlowModal.handleClose}
        onDeleted={refetch}
      />
      <DocumentFlowDetailsDrawer
        key={documentFlowDetailsDrawer.key}
        userFlowUuid={userFlow.uuid}
        isOpen={documentFlowDetailsDrawer.isOpen}
        handleClose={documentFlowDetailsDrawer.handleClose}
      />
      <OrganizationSettingsDocumentFlowDuplicateModal
        key={duplicateDocumentFlowModal.key}
        userFlow={userFlow}
        isOpen={duplicateDocumentFlowModal.isOpen}
        handleClose={duplicateDocumentFlowModal.handleClose}
        onDuplicated={refetch}
      />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  responsive,
  navigator,
  refetch,
}: {
  responsive: ResponsiveType;
  navigator: NavigationType;
  refetch: () => void;
}) => {
  return [
    {
      flex: 3,
      headerLabel: 'Name',
      cellText: (userFlow: UserFlowModel) => userFlow.name,
    },
    {
      flex: 3,
      headerLabel: 'Description',
      numberOfLines: 2,
      cellText: (userFlow: UserFlowModel) => userFlow.description,
      emptyText: '-',
    },
    {
      flex: 5,
      headerLabel: 'Project Types',
      cellText: (userFlow: UserFlowModel) => {
        return userFlow.projectTypeUserFlows
          .map((projectTypeUserFlow) => projectTypeUserFlow.projectType.name)
          .join(', ');
      },
      secondary: {
        headerLabel: 'Steps',
        cellText: (userFlow: UserFlowModel) => {
          return userFlow.userFlowSteps
            .map((userFlowStep) => userFlowStep.documentTemplate.name)
            .join(', ');
        },
      },
    },
    {
      flex: 2,
      headerLabel: 'Last Updated',
      cellText: (userFlow: UserFlowModel) => {
        return Datetime.convertToDisplayDatetime(userFlow.updatedAt);
      },
      secondary: {
        cellText: (userFlow: UserFlowModel) => {
          return userFlow.updatedBy.fullName;
        },
      },
    },
    {
      flex: 1,
      headerLabel: 'Actions',
      cellStyle: {
        alignItems: 'center',
      },
      cellComponent: (userFlow: UserFlowModel) => {
        return <UserFlowActions navigator={navigator} userFlow={userFlow} refetch={refetch} />;
      },
    },
  ];
};

const OrganizationSettingsDocumentFlowsPageContent = () => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const urlFilters = useUrlFilters<OrganizationSettingsDocumentFlowsFiltersType>({
    getRoute: () => '/settings/projects/document-flows',
    filterKeys: ['searchQuery', 'projectTypeIds'],
  });

  const {data, loading, refetch} = useQuery<OrganizationSettingsDocumentFlowsPageContentQueryType>(
    OrganizationSettingsDocumentFlowsPageContent.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        ...urlFilters.getFilters(),
      },
    },
  );

  return (
    <React.Fragment>
      <OrganizationSettingsDocumentFlowsPageHeader urlFilters={urlFilters} />
      <Space height={24} />
      <Loading loading={loading} as={LoadingIndicator}>
        {() => {
          return (
            <Table
              columnDefinitions={getColumnDefinitions({
                responsive,
                navigator,
                refetch,
              })}
              items={data!.filteredUserFlows as UserFlowModel[]}
              itemKey={'id'}
              emptyStateText={'No document flows to display'}
              hasBorder
            />
          );
        }}
      </Loading>
    </React.Fragment>
  );
};

OrganizationSettingsDocumentFlowsPageContent.query = gql`
  query OrganizationSettingsDocumentFlowsPageContentQuery(
    $projectTypeIds: [String!]
    $searchQuery: String
  ) {
    ${gql.query}
    filteredUserFlows(
      projectTypeIds: $projectTypeIds,
      searchQuery: $searchQuery
    ) {
      id
      uuid
      name
      description
      projectTypeUserFlows {
        id
        projectType {
          id
          name
        }
      }
      userFlowSteps {
        id
        documentTemplate {
          id
          name
        }
      }
      updatedAt
      updatedBy {
        id
        fullName
      }
    }
  }
`;

export default OrganizationSettingsDocumentFlowsPageContent;
