// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useResponsive} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import ResponsiveBadge from '@shared/design/components/Badge/ResponsiveBadge';
import IconButton from '@shared/design/components/IconButton';

const Container = Styled.View`
  flex: 1;
  flex-direction: row;
  padding: 12px 16px;
  background-color: ${colors.white};
  border-radius: 8px;
  border-color: ${colors.gray.border};
  border-width: 1px;
  align-items: center;
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ButtonGroup = Styled.View`
  flex-direction: row;
`;

const Title = Styled.Text`
  ${Typography.Responsive.Label}
`;

const ProjectTypeDocumentFlowsListItem = ({
  userFlow,
  handleDelete,
}: {
  userFlow: UserFlowModel;
  handleDelete: () => void;
}) => {
  const responsive = useResponsive();

  const actionsPopover = usePopover();
  const actions = [{text: 'Delete', onPress: handleDelete}];

  return (
    <Container>
      <Column>
        <Row>
          <Title responsive={responsive}>{userFlow.name}</Title>
          <Space width={8} />
          {userFlow.isDefault && <ResponsiveBadge label={'Default'} />}
        </Row>
      </Column>
      <Space flex={1} />
      <ButtonGroup>
        <ActionMenuPopover popover={actionsPopover} width={200} actions={actions}>
          <IconButton onPress={actionsPopover.handleToggle} isSecondary source={Icon.EllipsisV} />
        </ActionMenuPopover>
      </ButtonGroup>
    </Container>
  );
};

ProjectTypeDocumentFlowsListItem.fragment = gql`
  fragment ProjectTypeDocumentFlowsListItem on UserFlow {
    id
    name
    isDefault
  }
`;

export default ProjectTypeDocumentFlowsListItem;
