// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, EmojisPicker} from '@supermove/components';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import TagKind from '@shared/modules/Tag/enums/TagKind';

const Row = Styled.View`
  flex-direction: row;
`;

const TagCategoryField = ({form, isUpdate}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        label={'Tag Category'}
        name={`tagForm.category`}
        component={DropdownInput}
        input={{
          options: TagCategory.tagCategoryOptions,
          style: {width: '100%'},
          setFieldValue: form.setFieldValue,
          disabled: isUpdate,
        }}
      />
    </React.Fragment>
  );
};

const TagDrawerFields = ({form, isUpdate}: any) => {
  const nameField = 'tagForm.name';
  const descriptionField = 'tagForm.description';
  const emojiField = 'tagForm.emoji';

  return (
    <React.Fragment>
      <TagCategoryField form={form} isUpdate={isUpdate} />
      <Space height={12} />
      <Row>
        <FieldInput.Memoized
          {...form}
          index={1}
          label={'Tag Name'}
          name={nameField}
          isRequired
          input={{
            disabled: ![TagKind.CUSTOM, TagKind.DEFAULT].includes(form.values.tagForm.kind),
            placeholder: 'Name of tag',
          }}
          style={{width: 304}}
        />
        <Space width={4} />
        <EmojisPicker
          containerStyle={{paddingTop: 24}}
          defaultEmoji={form.values.tagForm?.emoji}
          onChangeValue={(emoji) => {
            form.setFieldValue(emojiField, emoji);
          }}
        />
      </Row>
      <Space height={12} />
      <FieldInput.Memoized
        {...form}
        index={2}
        label={'Description'}
        name={descriptionField}
        input={{
          multiline: true,
          placeholder: 'Enter Tag Description',
          style: {
            minHeight: 90,
            paddingTop: 8,
          },
        }}
      />
    </React.Fragment>
  );
};

export default TagDrawerFields;
