// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, DropdownInput, Form} from '@supermove/components';
import {ProjectType} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import {ProjectTypeFormToFormType} from '@shared/modules/Project/forms/ProjectTypeForm';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ColorDot = Styled.View<{color: string}>`
  height: 14px;
  width: 14px;
  border-radius: 14px;
  background-color: ${({color}) => color};
`;

const ColorOption = ({
  isSelected,
  value,
  label,
}: {
  isSelected: boolean;
  value: string;
  label: string;
}) => {
  return (
    <Row>
      <ColorDot color={value} />
      <Space width={8} />
      <DropdownInput.OptionLabel isSelected={isSelected} isDisabled={false}>
        {label}
      </DropdownInput.OptionLabel>
    </Row>
  );
};

const getColorOptions = ({
  isCurrentColorCustom,
  currentColor,
}: {
  isCurrentColorCustom: boolean;
  currentColor: string;
}) => {
  return isCurrentColorCustom
    ? [{label: 'Custom Color', value: currentColor}, ...ProjectType.getColorOptions()]
    : ProjectType.getColorOptions();
};

const ProjectTypeColorDropdownV2 = ({
  form,
  field,
  label,
  index,
  placeholder,
  isDisabled,
}: {
  form: Form<ProjectTypeFormToFormType>;
  field: string;
  label: string;
  index: number;
  placeholder: string;
  isDisabled: boolean;
}) => {
  const currentColor = _.get(form.values, field);
  const isColorAnOption = ProjectType.getColorOptions().some(
    (colorOption) => colorOption.value === currentColor,
  );
  const isCurrentColorCustom = !!currentColor && !isColorAnOption;

  return (
    <FieldInput
      {...form}
      label={label}
      name={field}
      component={DropdownInput}
      isResponsive
      isRequired
      index={index}
      input={{
        options: getColorOptions({isCurrentColorCustom, currentColor}),
        placeholder,
        style: {flex: 1},
        setFieldValue: form.setFieldValue,
        disabled: isDisabled,
        isPortaled: true,
        components: {
          SingleValue: (props: {data: {value: string; label: string}; isSelected: boolean}) => {
            return (
              <DropdownInput.SingleValueContainer {...props}>
                <ColorOption
                  isSelected={props.isSelected}
                  value={props.data.value}
                  label={props.data.label}
                />
              </DropdownInput.SingleValueContainer>
            );
          },
          Option: (props: {isSelected: boolean; data: {value: string; label: string}}) => {
            return (
              <DropdownInput.OptionContainer {...props}>
                <ColorOption
                  isSelected={props.isSelected}
                  value={props.data.value}
                  label={props.data.label}
                />
              </DropdownInput.OptionContainer>
            );
          },
        },
      }}
    />
  );
};

export default ProjectTypeColorDropdownV2;
