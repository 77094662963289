/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useNavigationDOM, useResponsive, useState} from '@supermove/hooks';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import EditJobDispatchBlock from 'modules/Project/V2/Edit/components/EditJobDispatchBlock';
import EditJobInformationBlock from 'modules/Project/V2/Edit/components/EditJobInformationBlock';
import EditJobNotesBlock from 'modules/Project/V2/Edit/components/EditJobNotesBlock';
import EditJobStopsBlock from 'modules/Project/V2/Edit/components/EditJobStopsBlock';
import EditJobVariablesBlock from 'modules/Project/V2/Edit/components/EditJobVariablesBlock';

// This is the number of pixels from the top where we consider
// a block to be the current block
const POSITION_Y_MARKER_OFFSET = 180;

const getAdjustedPositionY = (positionY: any) => positionY + POSITION_Y_MARKER_OFFSET;

const getCurrentPositionYBlockKind = ({positionY, blockToPositionY, isAtBottom}: any) => {
  const blockKindAndPositionPairs = Object.entries(blockToPositionY);

  if (blockKindAndPositionPairs.length > 0) {
    blockKindAndPositionPairs.sort((a, b) => (a as any)[1] - (b as any)[1]);

    // If at top, return first block
    if (positionY === 0) {
      const [firstBlockKind] = blockKindAndPositionPairs[0];
      return firstBlockKind;
    }

    // If at bottom, return last block
    if (isAtBottom) {
      const [lastBlockKind] = blockKindAndPositionPairs[blockKindAndPositionPairs.length - 1];
      return lastBlockKind;
    }

    // A possible block is any block that starts prior to the current positionY
    const possibleBlocks = blockKindAndPositionPairs.filter(
      ([blockKind, blockPosition]) => (blockPosition as any) <= getAdjustedPositionY(positionY),
    );
    const currentPositionYBlock = possibleBlocks[possibleBlocks.length - 1];
    const [blockKind] = currentPositionYBlock;
    return blockKind;
  }

  return null;
};

const getLastBlock = ({blockToPositionY}: any) => {
  return Object.keys(blockToPositionY).reduce((maxKey, currentKey) => {
    return blockToPositionY[currentKey] > blockToPositionY[maxKey] ? currentKey : maxKey;
  });
};

const EditJobBlock = ({
  kind,
  form,
  field,
  jobsField,
  project,
  handleSetPositionY,
  layoutKey,
  index,
  map,
}: any) => {
  // Every field should use this customized setter when setting form values
  form.customSetFieldValue = (editField: any, value: any) => {
    form.setFieldValue(`${field}.isUpdated`, true);
    form.setFieldValue(editField, value);
  };

  const isDisabled = _.get(form.values, `${field}.isFinal`);
  const {isMoversInputVisible, isTrucksInputVisible} = JobForm.getDispatchFieldVisibility(
    _.get(form.values, field),
  );

  switch (kind) {
    case ProjectBlockKind.Job.JOB_INFORMATION:
      return (
        <EditJobInformationBlock
          form={form}
          field={field}
          index={index}
          handleSetPositionY={handleSetPositionY}
          project={project}
          isDisabled={isDisabled}
        />
      );
    case ProjectBlockKind.Job.NOTES:
      return (
        <EditJobNotesBlock
          form={form}
          field={field}
          index={index}
          handleSetPositionY={handleSetPositionY}
          isDisabled={isDisabled}
        />
      );
    case ProjectBlockKind.Job.STOPS:
      return (
        <EditJobStopsBlock
          form={form}
          field={field}
          jobsField={jobsField}
          index={index}
          handleSetPositionY={handleSetPositionY}
          project={project}
          isDisabled={isDisabled}
          map={map}
        />
      );
    case ProjectBlockKind.Job.DISPATCH:
      return (
        isMoversInputVisible &&
        isTrucksInputVisible && (
          <EditJobDispatchBlock
            form={form}
            field={field}
            index={index}
            handleSetPositionY={handleSetPositionY}
            layoutKey={layoutKey}
            isDisabled={isDisabled}
            isTrucksInputVisible={isTrucksInputVisible}
            isMoversInputVisible={isMoversInputVisible}
          />
        )
      );
    case ProjectBlockKind.VARIABLES:
      return (
        <EditJobVariablesBlock
          form={form}
          field={field}
          index={index}
          handleSetPositionY={handleSetPositionY}
          layoutKey={layoutKey}
          isDisabled={isDisabled}
        />
      );
    default:
      return null;
  }
};

const EditProjectJobBlocks = ({
  form,
  field,
  jobsField,
  project,
  urlFilters,
  blockToPositionY,
  setBlockToPositionY,
  contentSize,
  positionY,
  isAtBottom,
  scrollView,
  map,
}: any) => {
  const responsive = useResponsive();
  const blocks = responsive.desktop
    ? ProjectBlockKind.EditJobBlocks
    : ProjectBlockKind.MobileEditJobBlocks;
  const {params} = useNavigationDOM();
  const blockPositionsSum = _.sum(Object.values(blockToPositionY));
  const [currentJobUuid, setCurrentJobUuid] = useState();
  const jobForm = _.get(form.values, field);
  const {valueForms} = jobForm;
  const variableSectionsCount = Object.keys(
    _.groupBy(valueForms, (valueForm) => valueForm.variableSectionId),
  ).length;

  // Beause we count each variable section as a block, we also
  // need to discount the overall section from the block count.
  const totalBlocks = blocks.length + variableSectionsCount - 1;

  // Watch to possibly scroll to a block when the block param or a block position changes
  useEffect(() => {
    const loadedBlocksCount = Object.keys(blockToPositionY).length;
    if (loadedBlocksCount === totalBlocks) {
      const currentPositionYBlock = getCurrentPositionYBlockKind({
        positionY,
        blockToPositionY,
        isAtBottom,
      });

      if (currentJobUuid && currentJobUuid !== params.jobUuid) {
        // When we navigate between jobs, we want to go straight to the top
        // of the content without animating. If there is no currentJobUuid,
        // then it means it's the initial navigation to the page, in which
        // case we do want to scroll to the block in the params.
        scrollView.handleScrollTo({y: 0, animated: false});
      } else if (currentPositionYBlock && currentPositionYBlock !== params.block) {
        const y = blockToPositionY[params.block];
        scrollView.handleScrollTo({y, animated: true});
      }

      setCurrentJobUuid(params.jobUuid);
    }
  }, [params.jobUuid, params.block, blockPositionsSum]);

  // Watch to possibly update the block param when the user is scrolling
  useEffect(() => {
    const currentPositionYBlock = getCurrentPositionYBlockKind({
      positionY,
      blockToPositionY,
      isAtBottom,
    });
    if (currentPositionYBlock && currentPositionYBlock !== params.block) {
      if (isAtBottom) {
        urlFilters.handleUpdate({block: getLastBlock({blockToPositionY})});
      } else {
        urlFilters.handleUpdate({block: currentPositionYBlock});
      }
    }
  }, [positionY]);

  return (
    <React.Fragment>
      {blocks.map((projectBlockKind, index) => {
        return (
          <React.Fragment key={projectBlockKind}>
            {index > 0 &&
              (responsive.desktop ? <Space height={24} /> : <Line style={{height: 4}} />)}
            <EditJobBlock
              layoutKey={contentSize}
              kind={projectBlockKind}
              form={form}
              field={field}
              jobsField={jobsField}
              project={project}
              handleSetPositionY={({nativeEvent, block}: any) => {
                setBlockToPositionY((previousBlockToPositionY: any) => ({
                  ...previousBlockToPositionY,
                  [block || projectBlockKind]: nativeEvent.layout.y,
                }));
              }}
              index={index}
              map={map}
            />
          </React.Fragment>
        );
      })}
      {responsive.desktop && <Space height={120} />}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectJobBlocks.fragment = gql`
  ${EditJobInformationBlock.fragment}
  ${EditJobStopsBlock.fragment}
  fragment EditProjectJobBlocks on Project {
    id
    ...EditJobInformationBlock
    ...EditJobStopsBlock
  }
`;

export default EditProjectJobBlocks;
