// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Workflow, Inventory} from '@supermove/models';
import {Datetime} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import TagCategory from '@shared/modules/Tag/enums/TagCategory';
import WorkflowTriggerIdentifiers from '@shared/modules/Workflow/enums/WorkflowTriggerIdentifier';
import TagDropdownInputField from 'modules/Tag/components/TagDropdownInputField';

const getWorkflowProjectTypeOptions = ({form, field, workflow, isStorage}: any) => {
  const {moveProjectTypes, storageProjectTypes} = workflow.organization;
  const projectTypes = isStorage ? storageProjectTypes : moveProjectTypes;
  const selectedProjectTypeIds = _.get(form.values, `${field}.projectTypeIdList`);
  const dropdownOptions = projectTypes
    .filter(
      (projectType: any) =>
        !projectType.isHidden || selectedProjectTypeIds.includes(projectType.id),
    )
    .map((projectType: any) => ({
      label: projectType.name,
      value: projectType.id,
    }));
  return dropdownOptions;
};

const getWorkflowProjectTypeCustomOptions = () => {
  // 'ALL Project Types' is distinguished from 'Select all' in that it is setup to
  // include all project types even if they are created later. Using select all would
  // exclusively select only the current project types and this workflow would not
  // apply to any project types that are created later.
  return [{label: 'ALL Project Types', value: -1}];
};

const getWorkflowJobTypeOptions = ({form, field, workflow}: any) => {
  // We only grab job types for selected project types
  const selectedProjectTypeIds = _.get(form.values, `${field}.projectTypeIdList`);
  const jobTypeOptions = workflow.organization.moveProjectTypes
    .filter((projectType: any) => {
      // '-1' is the value for 'ALL Project Types'
      if (selectedProjectTypeIds.includes(-1)) {
        return true;
      }
      return selectedProjectTypeIds.includes(projectType.id);
    })
    .reduce((combinedJobTypeOptions: any, projectType: any) => {
      const jobTypeOptionsFromProjectType = projectType.jobTypes.map((jobType: any) => ({
        label: jobType.name,
        value: jobType.id,
        description: projectType.name,
      }));
      return [...combinedJobTypeOptions, ...jobTypeOptionsFromProjectType];
    }, []);
  return jobTypeOptions;
};

const handleAllProjectTypesOption = ({selections, form, field}: any) => {
  // If adding 'ALL Project Types' to the selections, remove all other selections
  if (_.last(selections) === -1) {
    form.setFieldValue(
      `${field}.projectTypeIdList`,
      selections.filter((id: any) => id === -1),
    );
  } else if (_.includes(selections, -1)) {
    form.setFieldValue(
      `${field}.projectTypeIdList`,
      selections.filter((id: any) => id !== -1),
    );
  }
};

const JobTypesDropdown = ({form, field, workflow}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={`${field}.jobTypeIdList`}
      label={'Job Type'}
      isRequired
      input={{
        options: getWorkflowJobTypeOptions({form, field, workflow}),
        placeholder: 'Select job type(s)',
        isSearchable: true,
        isPortaled: true,
        isEnabledSelectAll: true,
        usePills: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

// For now create a V2, we can later use this once feature flag for scheduled job is turned to true for all
const ProjectTypesForJobTypesDropdown = ({form, field, workflow}: any) => {
  return (
    <FieldInput
      {...form}
      component={MultiDropdownCheckboxInput}
      name={`${field}.projectTypeIdList`}
      label={'Project Type'}
      isRequired
      input={{
        options: getWorkflowProjectTypeOptions({form, field, workflow}),
        customOptions: getWorkflowProjectTypeCustomOptions(),
        placeholder: 'Select project type(s)',
        isSearchable: true,
        isPortaled: true,
        usePills: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        onChangeValue: (selections: any) => {
          handleAllProjectTypesOption({selections, form, field});

          // Remove any job type that is not part of the project type
          const validJobTypeId = _.get(form.values, 'workflowForm.jobTypeIdList').filter(
            (id: any) => {
              return !!_.find(getWorkflowJobTypeOptions({form, field, workflow}), (option) => {
                return option.value === id;
              });
            },
          );
          form.setFieldValue(`${field}.jobTypeIdList`, validJobTypeId);
        },
      }}
    />
  );
};

const ProjectTypesDropdown = ({form, field, workflow, isStorage}: any) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);

  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={MultiDropdownCheckboxInput}
        name={`${field}.projectTypeIdList`}
        label={'Project Type'}
        isRequired
        input={{
          options: getWorkflowProjectTypeOptions({form, field, workflow, isStorage}),
          customOptions: getWorkflowProjectTypeCustomOptions(),
          placeholder: 'Select project type(s)',
          isSearchable: true,
          isPortaled: true,
          usePills: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          onChangeValue: (selections: any) =>
            handleAllProjectTypesOption({selections, form, field}),
        }}
      />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow &&
        triggerIdentifier !== WorkflowTriggerIdentifiers.PROJECT_CREATED && (
          <React.Fragment>
            <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
            <Space height={16} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const StorageProjectTypesDropdown = ({form, field, workflow}: any) => {
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={MultiDropdownCheckboxInput}
        name={`${field}.projectTypeIdList`}
        label={'Storage Project Type'}
        isRequired
        input={{
          options: getWorkflowProjectTypeOptions({form, field, workflow, isStorage: true}),
          customOptions: getWorkflowProjectTypeCustomOptions(),
          placeholder: 'Select storage project type(s)',
          isSearchable: true,
          isPortaled: true,
          usePills: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          onChangeValue: (selections: any) =>
            handleAllProjectTypesOption({selections, form, field}),
        }}
      />
      <Space height={16} />
      <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
      <Space height={16} />
    </React.Fragment>
  );
};

const ProjectValueDropdown = ({form, field, workflow}: any) => {
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.triggerVariableIdentifier`}
        label={'Project Value'}
        isRequired
        input={{
          options: Workflow.getProjectValueDropdownOptions(workflow),
          placeholder: 'Select a project value',
          isSearchable: true,
          isPortaled: true,
          isClearable: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const DaysBeforeField = ({form, field}: any) => {
  return (
    <FieldInput
      {...form}
      name={`${field}.triggerDaysBefore`}
      label={'Days Before'}
      isRequired
      input={{
        placeholder: 'Enter number of days',
        style: {flex: 1},
      }}
      handleBlur={(e) => {
        // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        const numberValue = parseInt(Inventory.getFloatValue(e.currentTarget.value), 10);
        form.setFieldValue(`${field}.triggerDaysBefore`, numberValue);
      }}
    />
  );
};

const WorkflowHoursField = ({form, field, workflow}: any) => {
  return (
    <FieldInput
      {...form}
      component={DropdownInput}
      name={`${field}.triggerHour`}
      label={'At'}
      isRequired
      input={{
        options: Datetime.getHourDropdownOptions(workflow.organization.timezone),
        placeholder: 'Select time',
        isSearchable: true,
        isPortaled: true,
        isClearable: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
      }}
    />
  );
};

const TagDropdownInputFieldByCategory = ({form, field, workflow, isTagField}: any) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);
  const isJobTypeRequiredWorkflow =
    WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(triggerIdentifier);
  const tags = workflow.organization.companySettings.tags.filter(
    (tag: any) =>
      (isJobTypeRequiredWorkflow
        ? [TagCategory.PROJECT, TagCategory.JOB].includes(tag.category)
        : tag.category === TagCategory.PROJECT) && !tag.isArchived,
  );

  const tagOptions = tags
    ? tags.map((tag: any) => ({
        value: tag.id,
        label: `${tag.emoji} ${tag.name}`,
      }))
    : [];

  return (
    <TagDropdownInputField
      options={tagOptions}
      customOptions={
        WorkflowTriggerIdentifiers.getCategory(triggerIdentifier) !== 'TAGS'
          ? [{label: 'ALL Tags', value: -1}]
          : undefined
      }
      placeholder={`Select tag`}
      label={`Select ${isJobTypeRequiredWorkflow ? '' : 'Project'} Tag(s)`}
      isPortaled
      isRequired={isTagField}
      value={_.get(form.values, `${field}.tagIdList`, [])}
      onChangeValue={(tagIds: any) => {
        const lastSelectionValue = _.last(tagIds);
        const isLastSelectionAllTags =
          tagIds.length === tagOptions.filter((option: any) => option.value !== -1).length;
        if (isLastSelectionAllTags || lastSelectionValue === -1) {
          form.setFieldValue(`${field}.tagIdList`, [-1]);
        } else if (_.includes(tagIds, -1)) {
          const filteredSelections = _.filter(tagIds, (value) => value !== -1);
          form.setFieldValue(`${field}.tagIdList`, filteredSelections);
        } else {
          form.setFieldValue(`${field}.tagIdList`, tagIds);
        }
      }}
    />
  );
};

const TagsField = ({form, field, workflow}: any) => {
  const isJobTypeRequiredWorkflow = WorkflowTriggerIdentifiers.isJobTypeRequiredWorkflow(
    _.get(form.values, `${field}.triggerIdentifier`),
  );

  return (
    <React.Fragment>
      <ProjectTypesForJobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {isJobTypeRequiredWorkflow && (
        <React.Fragment>
          <JobTypesDropdown form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
      <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} isTagField />
      <Space height={16} />
    </React.Fragment>
  );
};

const JobLevelWorkflowField = ({form, field, workflow}: any) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);
  return (
    <React.Fragment>
      <ProjectTypesForJobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <JobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow &&
        triggerIdentifier !== WorkflowTriggerIdentifiers.JOB_CREATED && (
          <React.Fragment>
            <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
            <Space height={16} />
          </React.Fragment>
        )}
    </React.Fragment>
  );
};

const ScheduledProjectField = ({form, field, workflow}: any) => {
  return (
    <React.Fragment>
      <ProjectTypesForJobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <DaysBeforeField form={form} field={field} />
      <Space height={16} />
      <WorkflowHoursField form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ScheduledJobField = ({form, field, workflow}: any) => {
  return (
    <React.Fragment>
      <ProjectTypesForJobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <JobTypesDropdown form={form} field={field} workflow={workflow} />
      <Space height={16} />
      <DaysBeforeField form={form} field={field} />
      <Space height={16} />
      <WorkflowHoursField form={form} field={field} workflow={workflow} />
      <Space height={16} />
      {workflow.organization.features.isEnabledProjectTagWorkflow && (
        <React.Fragment>
          <TagDropdownInputFieldByCategory form={form} field={field} workflow={workflow} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getWorkflowTriggerInput = ({triggerIdentifier, form, field, workflow}: any) => {
  switch (triggerIdentifier) {
    case WorkflowTriggerIdentifiers.JOB_CREATED:
    case WorkflowTriggerIdentifiers.JOB_COMPLETED:
    case WorkflowTriggerIdentifiers.JOB_CANCELLED:
    case WorkflowTriggerIdentifiers.PREPARE_STARTED:
    case WorkflowTriggerIdentifiers.TRACKING_STARTED:
    case WorkflowTriggerIdentifiers.CREW_LEFT_WAREHOUSE:
    case WorkflowTriggerIdentifiers.TRACKING_ARRIVED:
    case WorkflowTriggerIdentifiers.CREW_RETURNED_TO_WAREHOUSE:
      return <JobLevelWorkflowField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.SCHEDULED_DAYS_BEFORE_JOB:
      return <ScheduledJobField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.SCHEDULED_DAYS_BEFORE_PROJECT:
      return <ScheduledProjectField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.PROJECT_VALUE_UPDATED:
      return (
        <React.Fragment>
          <Space height={16} />
          <ProjectValueDropdown form={form} field={field} workflow={workflow} />
        </React.Fragment>
      );
    case WorkflowTriggerIdentifiers.PROJECT_TAG_ADDED:
    case WorkflowTriggerIdentifiers.PROJECT_TAG_REMOVED:
    case WorkflowTriggerIdentifiers.JOB_TAG_ADDED:
    case WorkflowTriggerIdentifiers.JOB_TAG_REMOVED:
      return <TagsField form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.CONFIRMATION_COMPLETED:
    case WorkflowTriggerIdentifiers.CONFIRMATION_SENT:
    case WorkflowTriggerIdentifiers.CONFIRMATION_SKIPPED:
    case WorkflowTriggerIdentifiers.QUOTE_SENT:
    case WorkflowTriggerIdentifiers.QUOTE_ACCEPTED:
    case WorkflowTriggerIdentifiers.PROJECT_CREATED:
    case WorkflowTriggerIdentifiers.PROJECT_CANCELLED:
    case WorkflowTriggerIdentifiers.PROJECT_COMPLETED:
    case WorkflowTriggerIdentifiers.INVENTORY_VIRTUAL_WALKTHROUGH_COMPLETED:
    case WorkflowTriggerIdentifiers.TEXT_MESSAGE_RECEIVED:
    case WorkflowTriggerIdentifiers.PROJECT_SALESPERSON_CHANGED:
    case WorkflowTriggerIdentifiers.PROJECT_COORDINATOR_CHANGED:
    case WorkflowTriggerIdentifiers.PROJECT_ADDITIONAL_SALESPERSON_CHANGED:
      return <ProjectTypesDropdown form={form} field={field} workflow={workflow} />;
    case WorkflowTriggerIdentifiers.STORAGE_PROJECT_CANCELLED:
    case WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_FAILED:
    case WorkflowTriggerIdentifiers.CUSTOMER_AUTOPAY_SUCCEEDED:
      return <StorageProjectTypesDropdown form={form} field={field} workflow={workflow} />;
    default:
      return null;
  }
};

const WorkflowTriggerInputsContent = ({form, field, workflow}: any) => {
  const triggerIdentifier = _.get(form.values, `${field}.triggerIdentifier`);

  return getWorkflowTriggerInput({triggerIdentifier, form, field, workflow});
};

const WorkflowTriggerInputs = ({form, field, workflow}: any) => {
  return <WorkflowTriggerInputsContent workflow={workflow} form={form} field={field} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowTriggerInputs.fragment = gql`
  ${Workflow.getProjectValueDropdownOptions.fragment}
  ${TagDropdownInputField.fragment}

  fragment WorkflowTriggerInputs on Workflow {
    id
    projectTypeIdList
    jobTypeIdList
    organization {
      id
      features {
        isEnabledProjectTagWorkflow: isEnabled(feature: "PROJECT_TAG_WORKFLOW")
      }
      companySettings {
        tags {
          id
          name
          emoji
          category
          isArchived
          ...TagDropdownInputField
        }
      }
      moveProjectTypes: projectTypesForCategory(category: "MOVE", showHidden: true) {
        id
        name
        isHidden
        jobTypes {
          id
          name
        }
      }
      storageProjectTypes: projectTypesForCategory(category: "STORAGE", showHidden: true) {
        id
        name
        isHidden
        jobTypes {
          id
          name
        }
      }
      timezone
    }
    ...Workflow_getProjectValueDropdownOptions
  }
`;

export default WorkflowTriggerInputs;
