// Libraries
import React from 'react';

// Components
import {Loading, Styled} from '@supermove/components';

import Page from './Page';

const Container = Styled.View`
  flex: 1;
`;

type OwnProps = {
  navigation?: (...args: any[]) => any;
  pollInterval?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ModalPage.defaultProps;

// @ts-expect-error TS(7022): 'ModalPage' implicitly has type 'any' because it d... Remove this comment to see the full error message
const ModalPage = ({
  fetchPolicy,
  navigation,
  pollInterval,
  query,
  variables,
  loadingAs,
  children,
}: Props) => (
  <Page fetchPolicy={fetchPolicy} pollInterval={pollInterval} query={query} variables={variables}>
    {({responsive, loading, ...props}: any) => (
      <Container {...responsive}>
        {navigation({responsive, loading, ...props})}
        <Loading as={loadingAs} loading={loading}>
          {() => children({responsive, ...props})}
        </Loading>
      </Container>
    )}
  </Page>
);

ModalPage.defaultProps = {
  navigation: () => null,
  pollInterval: 0,
};

export default ModalPage;
