// Libraries
import React from 'react';

// App
import {Hover, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Truck} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import UnassignTruckFromJobMutation from './UnassignTruckFromJobMutation';

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  align-self: flex-start;
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const Text = Styled.H7`
`;

const Content = ({isHovered, isUnassignable, shouldHideIcon, jobTruck}: any) => (
  <Container
    style={{
      backgroundColor: isUnassignable && isHovered ? colors.blue.accent : 'none',
    }}
  >
    {!shouldHideIcon && (
      <Icon
        color={colors.gray.primary}
        size={Icon.Sizes.Large}
        source={Icon.Truck}
        style={{marginRight: 5}}
      />
    )}
    <Text>{Truck.getFullName(jobTruck.truck)}</Text>
  </Container>
);

type OwnJobTruckItemProps = {
  isUnassignable?: boolean;
  jobTruck: any;
  refetch?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'JobTruckItemProps' circularly referenc... Remove this comment to see the full error message
type JobTruckItemProps = OwnJobTruckItemProps & typeof JobTruckItem.defaultProps;

// @ts-expect-error TS(7022): 'JobTruckItem' implicitly has type 'any' because i... Remove this comment to see the full error message
const JobTruckItem = ({isUnassignable, jobTruck, refetch}: JobTruckItemProps) =>
  isUnassignable ? (
    <UnassignTruckFromJobMutation jobTruckId={jobTruck.id} onSuccess={refetch}>
      {({loading, handleSubmit}: any) => (
        <Touchable
          disabled={!isUnassignable || loading}
          onPress={handleSubmit}
          style={{
            alignSelf: 'flex-start',
          }}
        >
          <Hover>
            {({isHovered}: any) => (
              <Content isHovered={isHovered} isUnassignable={isUnassignable} jobTruck={jobTruck} />
            )}
          </Hover>
        </Touchable>
      )}
    </UnassignTruckFromJobMutation>
  ) : (
    <Content shouldHideIcon jobTruck={jobTruck} />
  );

JobTruckItem.defaultProps = {
  isUnassignable: false,
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTruckItem.fragment = gql`
  fragment JobTruckItem on JobTruck {
    id
    truckId
    jobId
    truck {
      id
      name
      size
    }
  }
`;

export default JobTruckItem;
