// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {Animation, colors} from '@supermove/styles';

const Loader = Styled.View<{height: number; isFullWidth: boolean; width: number; color: string}>`
  height: ${({height}) => height}px;
  width: ${({isFullWidth, width}) => (isFullWidth ? '100%' : `${width}px`)};
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${Animation.pulse}
`;

type OwnProps = {
  height?: number;
  width?: number;
  color?: string;
  style?: any;
  isFullWidth?: boolean;
  children?: React.ReactNode;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SkeletonLoader.defaultProps;

// @ts-expect-error TS(7022): 'SkeletonLoader' implicitly has type 'any' because... Remove this comment to see the full error message
const SkeletonLoader = ({height, isFullWidth, width, color, style, children}: Props) => (
  <Loader isFullWidth={isFullWidth} height={height} width={width} color={color} style={style}>
    {children}
  </Loader>
);

SkeletonLoader.HEIGHT = {
  // Typography - matches line height
  Text: 20,
  Heading1Text: 32,
  Heading2Text: 28,
  SubheadingText: 24,
  MicroText: 18,

  ButtonMobile: 44,
  Button: 36,
  ButtonSmall: 28,
};

SkeletonLoader.defaultProps = {
  height: null,
  width: null,
  color: colors.gray.disabled,
  style: null,
  isFullWidth: false,
  children: null,
};

export default SkeletonLoader;
