// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const LabelText = Styled.Text`
  ${Typography.Label}
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const getSectionsWithValueForms = ({valueForms, jobTypeVariableSections}: any) => {
  const valueFormsBySectionId = _.groupBy(valueForms, (valueForm) => valueForm.variableSectionId);
  let sectionIndex = 0;
  return _.map(jobTypeVariableSections, (jobTypeVariableSection) => {
    const sectionValueForms = valueFormsBySectionId[jobTypeVariableSection.id] || [];
    const startingValueFormIndex = sectionIndex;
    sectionIndex += sectionValueForms.length;
    return {
      id: jobTypeVariableSection.id,
      name: jobTypeVariableSection.name,
      valueForms: sectionValueForms,
      // all of the ValueField logic relies on utilizing valueForms and fetching by index
      // we need to store the section index so that the ValueFields nested will have the correct index
      startingValueFormIndex,
      hasVisibleValueForms: !_.isEmpty(
        _.filter(
          sectionValueForms,
          (sectionValueForm) => !!sectionValueForm.isVisibleForCreateProject,
        ),
      ),
    };
    // we filter out sections without any value forms
    // this is different than a section with value forms but none visible on create project
  }).filter((sectionWithValueForms) => sectionWithValueForms.valueForms.length > 0);
};

const JobValueFields = ({index, jobTypeField, valueFormsField, form, isEdit, children}: any) => {
  const jobTypeId = _.get(form.values, jobTypeField, null);
  const existingValueForms = _.get(form.values, valueFormsField);
  const {loading, data} = useQuery(JobValueFields.query, {
    variables: {
      jobTypeId,
    },
    skip: !jobTypeId,
  });

  useEffect(() => {
    if (data && data.jobTypeById) {
      form.setFieldValue(
        valueFormsField,
        Organization.makeJobValueFormsFromJobTypeVariableSections({
          jobType: data.jobTypeById,
          existingValueForms,
          isEdit,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <Container index={index}>
        <Space height={24} />
        <PageLoadingIndicator />
        <Space height={24} />
      </Container>
    );
  }

  const valueForms = _.get(form.values, valueFormsField);
  const visibleValueForms = valueForms.filter(
    (valueForm: any) => !!valueForm.isVisibleForCreateProject,
  );

  if (visibleValueForms.length === 0 || !data?.jobTypeById) {
    return null;
  }

  const sectionsWithValueForms = getSectionsWithValueForms({
    valueForms,
    jobTypeVariableSections: data.jobTypeById.jobTypeVariableSections,
  });
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index}>
      {_.map(sectionsWithValueForms, (sectionWithValueForms, sectionIndex) => {
        return (
          <React.Fragment key={sectionWithValueForms.id}>
            <LabelText>{sectionWithValueForms.name}</LabelText>
            <Space height={4} />
            {sectionWithValueForms.hasVisibleValueForms ? (
              children({
                index: index + sectionIndex,
                form,
                valueFormsField,
                valueForms: sectionWithValueForms.valueForms,
                startingValueFormIndex: sectionWithValueForms.startingValueFormIndex,
              })
            ) : (
              <React.Fragment>
                <EmptyStateText>No variables to display</EmptyStateText>
                <Space height={16} />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobValueFields.query = gql`
  ${Organization.makeJobValueFormsFromJobTypeVariableSections.fragment}
  query JobValueFields(
    $jobTypeId: Int!,
  ) {
    ${gql.query}
    jobTypeById(jobTypeId: $jobTypeId) {
      id
      jobTypeVariableSections {
        id
        name
      }
      ...Organization_makeJobValueFormsFromJobTypeVariableSections
    }
  }
`;

export default JobValueFields;
