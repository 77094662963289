// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import UpdateSuperAdminImpersonateMutation from 'modules/App/components/UpdateSuperAdminImpersonateMutation';
import StaffCommunicationButton from 'modules/Communication/components/StaffCommunicationButton';
import StaffActionsButton from 'modules/Organization/Settings/Users/List/components/StaffActionsButton';
import CreateEmployeeModal from 'modules/User/components/CreateEmployeeModal';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const Section = Styled.View`
  margin-bottom: 20px;
`;

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 5px;
`;

const Text = Styled.Text`
  ${Typography.Body3}
  font-style: ${({
    // @ts-expect-error TS(2339): Property 'status' does not exist on type 'ThemePro... Remove this comment to see the full error message
    status,
  }) => status === UserStatus.PENDING && 'italic'};
  color: ${({
    // @ts-expect-error TS(2339): Property 'status' does not exist on type 'ThemePro... Remove this comment to see the full error message
    status,
  }) => status === UserStatus.PENDING && colors.gray.secondary};
`;

const Button = Styled.LoadingButton`
  align-self: flex-start;
  height: 40px;
  padding-horizontal: 10px;
`;

const ActiveText = Styled.Text`
  ${Typography.Label1}
`;

const MANAGER_POSITION_ORDER = ['ceo', 'general manager', 'manager', 'assistant manager'];

const sortOfficerUsers = (users: any) => {
  return _.sortBy(users, (user) => {
    if (!user.position) {
      return 100;
    } else {
      const index = MANAGER_POSITION_ORDER.indexOf(user.position.toLowerCase());
      if (index < 0) {
        return 99;
      } else {
        return index;
      }
    }
  });
};

const getStaffOfficeSectionColumnDefinitions = ({
  canImpersonate,
  viewerRole,
  refetch,
  isEnabledOperationsAdmins,
}: any) => [
  {
    width: 30,
    headerContent: () => {
      return null;
    },
    cellContent: ({rowIndex}: any) => {
      return <Text>{rowIndex + 1}</Text>;
    },
  },
  {
    width: 70,
    isHidden: !canImpersonate,
    headerContent: () => {
      return <Table.HeaderText>Super</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      return (
        <UpdateSuperAdminImpersonateMutation
          user={user}
          onSuccess={() => {
            // @ts-expect-error TS(2322): Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
            window.location = '/';
          }}
        >
          {({loading, handleSubmit}: any) => (
            <Button loading={loading} onPress={handleSubmit}>
              {!loading && <Icon color={colors.white} size={16} source={Icon.User} />}
            </Button>
          )}
        </UpdateSuperAdminImpersonateMutation>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Name</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Text numberOfLines={1} status={user.status}>
          {user.fullName} {user.status === UserStatus.PENDING && '(Pending)'}
        </Text>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Positions</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <Text status={user.status}>{User.getPosition(user)}</Text>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Supermove Role</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <Text status={user.status}>{User.getRole(user)}</Text>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Phone Number</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Text status={user.status}>
          {user.phoneNumber ? Phone.display(user.phoneNumber) : '---'}
        </Text>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Email</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      return (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Text numberOfLines={1} status={user.status}>
          {user.email}
        </Text>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'user' implicitly has an 'any' typ... Remove this comment to see the full error message
    cellContent: ({item: user}) => {
      return (
        <StaffOfficeSectionsActions
          user={user}
          viewerRole={viewerRole}
          refetch={refetch}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      );
    },
  },
];

const StaffOfficeSectionsActions = ({
  user,
  viewerRole,
  refetch,
  isEnabledOperationsAdmins,
}: any) => {
  const updateUserDrawer = useDrawer({name: 'Update User Drawer'});
  return (
    <React.Fragment>
      {UserRole.OFFICE_ROLES_PLUS_SUPER.includes(viewerRole) && (
        <ActionsCell>
          <React.Fragment>
            <StaffCommunicationButton
              user={user}
              viewerRole={viewerRole}
              refetch={refetch}
              isEnabledOperationsAdmins={isEnabledOperationsAdmins}
            />
            <Space width={8} />
            <UpdateUserDrawer
              key={updateUserDrawer.key}
              user={user}
              isOpen={updateUserDrawer.isOpen}
              handleClose={updateUserDrawer.handleClose}
              handleSuccess={() => {
                updateUserDrawer.handleClose();
                refetch();
              }}
              viewerRole={viewerRole}
              isEnabledOperationsAdmins={isEnabledOperationsAdmins}
            />
          </React.Fragment>
          {UserRole.ADMIN_ROLES_PLUS_SUPER.includes(viewerRole) && (
            <StaffActionsButton user={user} refetch={refetch} updateUserDrawer={updateUserDrawer} />
          )}
        </ActionsCell>
      )}
    </React.Fragment>
  );
};

const OfficeSectionTable = ({
  canImpersonate,
  viewerRole,
  refetch,
  users,
  isEnabledOperationsAdmins,
}: any) => (
  <Table
    columnDefinitions={getStaffOfficeSectionColumnDefinitions({
      canImpersonate,
      viewerRole,
      refetch,
      isEnabledOperationsAdmins,
    })}
    emptyStateText={'No users to display'}
    items={users}
    isDense
    rowStyle={{padding: '8', borderWidth: '0'}}
  />
);

const ActiveInactiveOfficeSection = ({
  canImpersonate,
  activeOfficeUsers,
  inactiveOfficeUsers,
  viewerRole,
  refetch,
  isEnabledOperationsAdmins,
}: any) => {
  return (
    <Section>
      <Section>
        <OfficeSectionTable
          canImpersonate={canImpersonate}
          viewerRole={viewerRole}
          refetch={refetch}
          users={activeOfficeUsers}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      </Section>
      <Section>
        <ActiveText>Inactive ({inactiveOfficeUsers.length})</ActiveText>
        <Space height={10} />
        <OfficeSectionTable
          canImpersonate={canImpersonate}
          viewerRole={viewerRole}
          refetch={refetch}
          users={inactiveOfficeUsers}
          isEnabledOperationsAdmins={isEnabledOperationsAdmins}
        />
      </Section>
    </Section>
  );
};

type OwnStaffOfficeSectionProps = {
  canImpersonate?: boolean;
  organization: any;
  viewerRole: string;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'StaffOfficeSectionProps' circularly re... Remove this comment to see the full error message
type StaffOfficeSectionProps = OwnStaffOfficeSectionProps & typeof StaffOfficeSection.defaultProps;

// @ts-expect-error TS(7022): 'StaffOfficeSection' implicitly has type 'any' bec... Remove this comment to see the full error message
const StaffOfficeSection = ({
  canImpersonate,
  organization,
  viewerRole,
  refetch,
}: StaffOfficeSectionProps) => {
  return (
    <ScrollView>
      <ActiveInactiveOfficeSection
        canImpersonate={canImpersonate}
        activeOfficeUsers={sortOfficerUsers(organization.activeOfficeUsers)}
        inactiveOfficeUsers={sortOfficerUsers(organization.inactiveOfficeUsers)}
        viewerRole={viewerRole}
        refetch={refetch}
        isEnabledOperationsAdmins={organization.features.isEnabledOperationsAdmins}
      />
    </ScrollView>
  );
};

StaffOfficeSection.defaultProps = {
  canImpersonate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StaffOfficeSection.fragment = gql`
  ${CreateEmployeeModal.fragment}
  ${UpdateUserDrawer.fragment}
  ${StaffActionsButton.fragment}
  ${StaffCommunicationButton.fragment}

  fragment StaffOfficeSection on Organization {
    id
    activeOfficeUsers: filteredActiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
      // @ts-expect-error TS(2345): Argument of type 'readonly ("manager" | "admin" | ... Remove this comment to see the full error message
      UserRole.NON_ADMIN_OFFICE_ROLES,
    )}]) {
      id
      fullName
      phoneNumber
      email
      position
      features
      role
      ...StaffActionsButton
      ...UpdateUserDrawer
      ...StaffCommunicationButton
    }
    inactiveOfficeUsers: filteredInactiveUsersByRole(roles: [${UserRole.getGraphqlArgs(
      // @ts-expect-error TS(2345): Argument of type 'readonly ("manager" | "admin" | ... Remove this comment to see the full error message
      UserRole.NON_ADMIN_OFFICE_ROLES,
    )}]) {
      id
      fullName
      phoneNumber
      email
      position
      features
      role
      ...StaffActionsButton
      ...UpdateUserDrawer
      ...StaffCommunicationButton
    }
    features {
      isEnabledOperationsAdmins: isEnabled(feature: "OPERATIONS_ADMINS")
    }
    ...CreateEmployeeModal
  }
`;

export default StaffOfficeSection;
