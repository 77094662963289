// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import TextTooltip from '@shared/design/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
`;

const IconContainer = Styled.View`
`;

const Text = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isMicro ? Typography.Micro : Typography.Body)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isMicro || !vars.hasData ? colors.gray.secondary : colors.gray.primary)};
  text-decoration-color: ${colors.gray.secondary};
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isDisabled ? '' : 'text-decoration-line: underline;')}
`;

const ProjectClientDataActions = ({
  data,
  addDataText,
  onAddDataPress,
  icon,
  iconTooltip,
  isBilling,
  isMicro,
  popover,
}: any) => {
  const hasData = !!data;
  const isDisabled = hasData && isBilling;

  return (
    <Row>
      <Popover.Content innerRef={popover.ref}>
        <TertiaryButton
          onPress={hasData ? popover.handleToggle : onAddDataPress}
          isDisabled={isDisabled}
          style={{userSelect: 'auto'}}
        >
          {isMicro && (
            <React.Fragment>
              <TextTooltip isEnabledMobileToast={false} text={iconTooltip}>
                <IconContainer>
                  <Icon source={icon} size={Icon.Sizes.Small} color={colors.gray.secondary} />
                </IconContainer>
              </TextTooltip>
              <Space width={8} />
            </React.Fragment>
          )}
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Text vars={{isMicro, isDisabled, hasData}}>{hasData ? data : addDataText}</Text>
          {hasData && !isBilling && (
            <Row>
              <Space width={8} />
              <Icon
                source={Icon.AngleDown}
                size={isMicro ? Icon.Sizes.Small : Icon.Sizes.Medium}
                color={colors.gray.secondary}
              />
            </Row>
          )}
        </TertiaryButton>
      </Popover.Content>
    </Row>
  );
};

export default ProjectClientDataActions;
