// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import CostItemTypeForm from '@shared/modules/Billing/forms/CostItemTypeForm';
import useUpdateCostItemTypeMutation from '@shared/modules/Billing/hooks/useUpdateCostItemTypeMutation';
import CostItemTypeSidebarContent from 'modules/Organization/Settings/BillingLibraries/components/CostItemTypeSidebarContent';

const UpdateCostItemTypeSidebar = ({
  category,
  header,
  moverPositions,
  costItemType,
  billItemTypeOptions,
  billTypeOptions,
  isOpen,
  handleClose,
  refetch,
}: any) => {
  const costItemTypeForm = CostItemTypeForm.edit(costItemType, {
    moverPositions,
    billItemTypeOptions,
    billTypeOptions,
  });
  const {form, handleSubmit} = useUpdateCostItemTypeMutation({
    costItemTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <CostItemTypeSidebarContent
      form={form}
      header={header}
      category={category}
      moverPositions={moverPositions}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCostItemTypeSidebar.fragment = gql`
  ${CostItemTypeForm.edit.fragment}
  ${CostItemTypeSidebarContent.fragment}

  fragment UpdateCostItemTypeSidebar on CostItemType {
    id
    billItemType {
      id
      ...CostItemTypeSidebarContent
    }
    ...CostItemTypeForm_edit
  }
`;

export default UpdateCostItemTypeSidebar;
