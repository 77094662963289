// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  width: 100%;
`;

const Banner = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CrewJobStatusBanner.defaultProps;

/**
 * The job status banner for crew members only shows whether
 * the job is a training move or whether it is cancelled.
 */
// @ts-expect-error TS(7022): 'CrewJobStatusBanner' implicitly has type 'any' be... Remove this comment to see the full error message
const CrewJobStatusBanner = ({job}: Props) => (
  <Container>
    {job.isTest && (
      // @ts-expect-error TS(2769): No overload matches this call.
      <Banner color={colors.purple.hover}>
        <Text>{`${job.fullName} is a training move`}</Text>
      </Banner>
    )}
    {job.calendarPrimaryStatus === 'CANCELLED' && (
      // @ts-expect-error TS(2769): No overload matches this call.
      <Banner color={Job.getCalendarPrimaryStatusColor(job)}>
        <Text>{Job.getCalendarPrimaryStatusTextWithName(job)}</Text>
      </Banner>
    )}
  </Container>
);

CrewJobStatusBanner.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobStatusBanner.fragment = gql`
  ${Job.getCalendarPrimaryStatusColor.fragment}
  ${Job.getCalendarPrimaryStatusTextWithName.fragment}

  fragment CrewJobStatusBanner on Job {
    id
    isTest
    calendarPrimaryStatus
    fullName
    ...Job_getCalendarPrimaryStatusColor
    ...Job_getCalendarPrimaryStatusTextWithName
  }
`;

export default CrewJobStatusBanner;
