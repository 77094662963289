// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToggle} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import RadioButton from '@shared/design/components/RadioButton';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useUpdateProjectTypeMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeMutation';

const Row = Styled.View`
  flex-direction: row;
`;

const EditBody = ({form, field}: any) => {
  const isJobLevelBilling = _.get(form.values, field);
  const toggleJobLevelBilling = ({form, field}: any) =>
    form.setFieldValue(field, !isJobLevelBilling);
  return (
    <React.Fragment>
      <RadioButton
        isOn={!isJobLevelBilling}
        onPress={() => toggleJobLevelBilling({form, field})}
        label={'Project-level billing'}
        hint={
          'When selected, the Crew App shows the entire bill for the whole project at all times. The customer can make a payment only on the final job of the project.'
        }
      />
      <Space height={12} />
      <RadioButton
        isOn={isJobLevelBilling}
        onPress={() => toggleJobLevelBilling({form, field})}
        label={'Job-level billing'}
        hint={
          'When selected, the Crew App shows the bills for each job up to and including the current day. The customer can make a payment on every job within the project.'
        }
      />
    </React.Fragment>
  );
};

const Body = ({projectType}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <FieldValue.LabelText isResponsive responsive={responsive} labelColor={colors.gray.primary}>
        Billing Method
      </FieldValue.LabelText>
      <FieldValue.LabelSpace isResponsive responsive={responsive} />
      <Row style={{alignItems: 'center'}}>
        <FieldValue.ValueText isResponsive responsive={responsive}>{`${
          projectType.isJobLevelBilling ? 'Job' : 'Project'
        }-level billing`}</FieldValue.ValueText>
        <Space width={8} />
      </Row>
    </React.Fragment>
  );
};

const AccountingBillingSettingsPanel = ({projectType, index, refetch}: any) => {
  const editBillingToggle = useToggle();
  const projectTypeForm = ProjectTypeForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      refetch();
      editBillingToggle.handleToggleOff();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      title={'Billing'}
      BodyComponent={Body}
      bodyComponentProps={{projectType}}
      EditBodyComponent={EditBody}
      editBodyComponentProps={{
        form,
        field: `projectTypeForm.isJobLevelBilling`,
      }}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editBillingToggle.isOn}
      handleEdit={editBillingToggle.handleToggleOn}
      handleClose={editBillingToggle.handleToggleOff}
    />
  );
};

AccountingBillingSettingsPanel.fragment = gql`
  ${ProjectTypeForm.edit.fragment}
  fragment AccountingBillingSettingsPanel on ProjectType {
    id
    isJobLevelBilling
    ...ProjectTypeForm_edit
  }
`;

export default AccountingBillingSettingsPanel;
