import React from 'react';

import {colors} from '@supermove/styles';

import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const InvalidInvoiceForExportModal = ({isOpen, onClose}: any) => {
  return (
    <ActionModal
      color={colors.red.warning}
      isOpen={isOpen}
      handlePrimaryAction={onClose}
      title='Invalid Invoice'
      message='Invoices must have at least one line item and a date in order to be exported.'
      primaryActionText={'Close'}
    />
  );
};

export default InvalidInvoiceForExportModal;
