/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const InstructionText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const ProjectTypeDayInfoModal = ({handleClose, isOpen}: any) => {
  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <InstructionText>
            {`Clicking the lock icon will prevent the sales team from booking ` +
              `additional jobs of this type. Use this to prevent overbooking.`}
          </InstructionText>
          <Space height={20} />
          <Actions>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Button activeOpacity={0.8} color={colors.blue.interactive} onPress={handleClose}>
              <ButtonText color={colors.white}>Got it</ButtonText>
            </Button>
          </Actions>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

export default ProjectTypeDayInfoModal;
