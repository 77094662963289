// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.mobile ? 'column' : 'row')};
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding-vertical: 10px;
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Banner = ({color, text, responsive, children}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={color} vars={responsive}>
    <Text>{text}</Text>
    {children}
  </Container>
);

export default Banner;
