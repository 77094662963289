// Libraries
import React from 'react';

// App
import {Emoji, Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {
  UpdateReportMoveTimeRangesFields,
  UpdateReportMoveTimeRangesForm,
} from 'modules/ReportMove/TimeRanges/Edit/components';
import {ReportMoveJobInfo, ReportMovePage} from 'modules/ReportMove/components';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  width: 100%;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '800px')};
  padding-top: 60px;
  padding-bottom: 60px;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Left = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H6`
  margin-top: 10px;
  line-height: 24px;
`;

const Row = Styled.View`
  margin-top: 40px;
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Line = Styled.View`
  margin-top: 40px;
  height: 10px;
  background-color: ${colors.gray.border};
`;

const Footer = Styled.View`
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '800px')};
  padding-vertical: ${(props) => ((props as any).mobile ? 10 : 15)};
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const MiniRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const isCorrectPage = ({reportMove}: any) => {
  return !reportMove.isFinal;
};

const onMountOrUpdate = ({navigator, reportMove}: any) => {
  if (!isCorrectPage({reportMove})) {
    navigator.replace(`/move-reports/${reportMove.uuid}/show`);
  }
};

const Redirect = ({children, navigator, reportMove}: any) => (
  <Lifecycle
    onMount={() => onMountOrUpdate({navigator, reportMove})}
    onUpdate={() => onMountOrUpdate({navigator, reportMove})}
  >
    {isCorrectPage({reportMove}) ? children : null}
  </Lifecycle>
);

const EditTimeRangesReportMovePage = ({
  match: {
    params: {uuid},
  },
}: any) => (
  <ReportMovePage
    variables={{uuid}}
    query={EditTimeRangesReportMovePage.query}
    title={() => 'Move Report'}
    subtitle={() => 'Edit work timesheet'}
  >
    {({responsive, navigator, data, refetch}: any) => (
      <Redirect navigator={navigator} reportMove={data.reportMove}>
        <UpdateReportMoveTimeRangesForm
          reportMove={data.reportMove}
          onSuccess={() => navigator.push(`/move-reports/${uuid}/hours/edit`)}
        >
          {({loading, handleSubmit, ...form}: any) => (
            <Wrapper>
              <ScrollView
                contentContainerStyle={{
                  flexGrow: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Container>
                  <Content {...responsive}>
                    <ReportMoveJobInfo job={data.reportMove.job} />
                    <Line />
                    {/* @ts-expect-error TS(2769): No overload matches this call. */}
                    <Row index={0}>
                      <MiniRow>
                        <Left>
                          <Emoji component={EmojiText} name={'clipboard'} />
                          <Title>Edit work timesheet</Title>
                        </Left>
                      </MiniRow>
                      <Subtitle>
                        Please enter in the work timesheet information for this move.
                      </Subtitle>
                    </Row>
                    {/* @ts-expect-error TS(2769): No overload matches this call. */}
                    <Row index={1}>
                      <UpdateReportMoveTimeRangesFields form={form} />
                    </Row>
                  </Content>
                </Container>
              </ScrollView>
              <Footer {...responsive}>
                <Button loading={loading} onPress={handleSubmit}>
                  <Text>Save & Continue</Text>
                </Button>
              </Footer>
            </Wrapper>
          )}
        </UpdateReportMoveTimeRangesForm>
      </Redirect>
    )}
  </ReportMovePage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTimeRangesReportMovePage.query = gql`
  ${ReportMoveJobInfo.fragment}
  ${ReportMovePage.fragment}
  ${UpdateReportMoveTimeRangesFields.fragment}
  ${UpdateReportMoveTimeRangesForm.fragment}

  query EditTimeRangesReportMovePage($uuid: String!) {
    ${gql.query}
    reportMove(uuid: $uuid) {
      id
      uuid
      isFinal
      job {
        id
        ...ReportMoveJobInfo
      }
      timeRanges {
        name
        kind
        start
        end
      }
      ...ReportMovePage
      ...UpdateReportMoveTimeRangesFields
      ...UpdateReportMoveTimeRangesForm
    }
  }
`;

export default EditTimeRangesReportMovePage;
