// Libraries
import React from 'react';

// Supermove
import {Calendar, Hover, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useState, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CompanyOrganizationsDropdown from 'modules/Company/components/CompanyOrganizationsDropdown';

const Container = Styled.View`
  height: ${(props) => (props as any).height}px;
  z-index: 100;
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  padding-horizontal: 10px;
  background-color: ${colors.white};
`;

const Section = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Toggles = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`;

const ToggleTouchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 20px;
`;

const HeaderText = Styled.H6`
  ${fontWeight(700)}
`;

const HeaderTouchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  margin-right: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Options = Styled.View`
  flex-direction: row;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 5px;
`;

const OptionsDivider = Styled.View`
  width: 1px;
  height: 100%;
  background-color: ${colors.gray.tertiary};
`;

const OptionTouchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-horizontal: 10px;
`;

const OptionText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Footer = Styled.View`
  height: ${(props) => (props as any).height};
  overflow: hidden;
  transition-property: height;
  transition-duration: 0.5s;
`;

const FooterContent = Styled.View`
  height: 240px;
`;

const MonthToggles = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-horizontal: 10px;
  margin-bottom: 10px;
`;

const MonthTouchable = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const TouchableText = Styled.H7`
  margin-top: 1px;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const DateHeader = Styled.H7`
  padding-top: 5px;
  padding-left: 5px;
  ${fontWeight(500)}
`;

const DateCell = Styled.View`
  flex: 1;
  background-color: ${(props) => (props as any).color};
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const getHeaderText = ({queryDate, queryType, abbreviateMonth}: any) => {
  if (['DAY', 'MONTH'].includes(queryType)) {
    const format = abbreviateMonth ? 'MMM YYYY' : 'MMMM YYYY';
    return Datetime.toDisplayDate(Datetime.fromDate(queryDate), format);
  } else {
    const startDate = Datetime.toDisplayDate(
      Datetime.previousSunday(Datetime.fromDate(queryDate)),
      'MMMM DD',
    );
    const endDate = Datetime.toDisplayDate(
      Datetime.nextSaturday(Datetime.fromDate(queryDate)),
      'MMMM DD',
    );
    return `${startDate} - ${endDate}`;
  }
};

const navigatePrevious = ({navigator, queryMode, queryDate, queryType, queryOrganization}: any) => {
  let previousDate;
  if (['DAY', 'MONTH'].includes(queryType)) {
    previousDate = Datetime.toMutationMonth(Datetime.previousMonth(Datetime.fromDate(queryDate)));
  } else {
    previousDate = Datetime.toMutationDate(Datetime.fromDate(queryDate).subtract(7, 'day'));
  }
  navigator.replace(
    `/${queryMode}/calendar?date=${previousDate}&organization=${queryOrganization}`,
  );
};

const navigateNext = ({navigator, queryMode, queryDate, queryType, queryOrganization}: any) => {
  let nextDate;
  if (['DAY', 'MONTH'].includes(queryType)) {
    nextDate = Datetime.toMutationMonth(Datetime.nextMonth(Datetime.fromDate(queryDate)));
  } else {
    nextDate = Datetime.toMutationDate(Datetime.nextSunday(Datetime.fromDate(queryDate)));
  }
  navigator.replace(`/${queryMode}/calendar?date=${nextDate}&organization=${queryOrganization}`);
};

const navigateDate = ({date, queryMode, queryOrganization, navigator}: any) => {
  const newDate = Datetime.toMutationDate(Datetime.fromDate(date));
  navigator.replace(`/${queryMode}/calendar?date=${newDate}&organization=${queryOrganization}`);
};

const navigateToday = ({navigator, queryMode, queryOrganization}: any) => {
  const todayDate = Datetime.toMutationDate(Datetime.today);
  navigator.replace(`/${queryMode}/calendar?date=${todayDate}&organization=${queryOrganization}`);
};

const navigateWeek = ({queryMode, navigator, queryDate}: any) => {
  navigator.replace(`/${queryMode}/calendar?date=${queryDate}`);
};

const navigateMonth = ({queryMode, navigator, queryDate}: any) => {
  navigator.replace(`/${queryMode}/calendar?date=${queryDate}`);
};

const getOptionStyle = ({isHovered, isSelected}: any) => {
  return {
    backgroundColor: isHovered || isSelected ? colors.blue.accent : 'transparent',
    boxShadow: isSelected ? 'inset 0 0 5px rgba(0, 0, 0, 0.1)' : 'none',
  };
};

const getShowBookingCalendarCompanyOrganizationsDropdown = ({organization}: any) => {
  return organization && organization.isPrimary && organization.company.organizations.length > 1;
};

const Left = ({
  isBottomOpen,
  queryMode,
  queryDate,
  queryType,
  selectedOrganizationSlug,
  setIsBottomOpen,
  organization,
  form,
}: any) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const isReportsView = queryMode === 'reports';

  return (
    <Section style={{flex: 1}}>
      {!responsive.mobile && (
        <Hover>
          {({isHovered}: any) => (
            <Button
              color={isHovered ? colors.blue.accent : colors.white}
              onPress={() =>
                navigateToday({navigator, queryMode, queryOrganization: selectedOrganizationSlug})
              }
            >
              <ButtonText>Today</ButtonText>
            </Button>
          )}
        </Hover>
      )}
      {/*
        Display rules for Previous/Next navigator:
        Booking: Mobile only
        Reporting: Mobile and desktop
      */}
      {(!responsive.mobile || isReportsView) && (
        <React.Fragment>
          <Toggles>
            <Hover>
              {({isHovered}: any) => (
                <ToggleTouchable
                  style={{backgroundColor: isHovered ? colors.blue.accent : 'transparent'}}
                  onPress={() =>
                    navigatePrevious({
                      navigator,
                      queryDate,
                      queryType,
                      queryMode,
                      queryOrganization: selectedOrganizationSlug,
                    })
                  }
                >
                  <Icon
                    color={colors.gray.primary}
                    size={Icon.Sizes.Large}
                    source={Icon.ChevronLeft}
                  />
                </ToggleTouchable>
              )}
            </Hover>
            <Hover>
              {({isHovered}: any) => (
                <ToggleTouchable
                  style={{backgroundColor: isHovered ? colors.blue.accent : 'transparent'}}
                  onPress={() =>
                    navigateNext({
                      navigator,
                      queryDate,
                      queryType,
                      queryMode,
                      queryOrganization: selectedOrganizationSlug,
                    })
                  }
                >
                  <Icon
                    color={colors.gray.primary}
                    size={Icon.Sizes.Large}
                    source={Icon.ChevronRight}
                  />
                </ToggleTouchable>
              )}
            </Hover>
          </Toggles>
        </React.Fragment>
      )}
      {/*
        Display rules for Bottom Calendar Dropdown:
        Booking: Mobile only
        Reporting: Always disabled
      */}
      <HeaderTouchable
        disabled={!responsive.mobile || isReportsView}
        activeOpacity={1}
        onPress={() => setIsBottomOpen(!isBottomOpen)}
        style={{flexDirection: 'row'}}
      >
        <HeaderText numberOfLines={1}>
          {getHeaderText({
            queryDate,
            queryType,
            abbreviateMonth: responsive.mobile && isReportsView,
          })}
        </HeaderText>
        {responsive.mobile && !isReportsView && (
          <Icon
            color={colors.gray.primary}
            size={Icon.Sizes.Medium}
            source={Icon.CaretDown}
            style={{
              marginLeft: 3,
              transform: isBottomOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              transitionProperty: 'transform',
              transitionDuration: '0.5s',
            }}
          />
        )}
      </HeaderTouchable>
      <Space width={16} />
      {!isReportsView &&
        !responsive.mobile &&
        getShowBookingCalendarCompanyOrganizationsDropdown({organization}) && (
          <CompanyOrganizationsDropdown
            company={organization.company}
            form={form}
            fieldName={'selectedOrganizationSlug'}
            handleChangeValue={(selectedOrganization: any) => {
              if (
                organization.features.isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs
              ) {
                form.setFieldValue(
                  'projectTypes',
                  Organization.getProjectTypes(selectedOrganization),
                );
                form.setFieldValue(
                  'salespersons',
                  Organization.getSalesPersons(selectedOrganization),
                );
              }
              navigator.replace(
                `/booking/calendar?date=${queryDate}&organization=${selectedOrganization.slug}`,
              );
            }}
          />
        )}
    </Section>
  );
};

const Top = ({
  isBottomOpen,
  queryMode,
  queryType,
  selectedOrganizationSlug,
  organization,
  right,
  setIsBottomOpen,
  form,
}: any) => {
  const {navigator, params} = useNavigationDOM();
  const queryDate = Datetime.toMutationDate(params.date || Datetime.toMutationDate(Datetime.today));

  return (
    <Header>
      <Left
        isBottomOpen={isBottomOpen}
        queryMode={queryMode}
        queryDate={queryDate}
        queryType={queryType}
        selectedOrganizationSlug={selectedOrganizationSlug}
        setIsBottomOpen={setIsBottomOpen}
        organization={organization}
        form={form}
      />
      <Section>
        {right}
        {false && (
          <Options>
            <Hover>
              {({isHovered}: any) => (
                <OptionTouchable
                  style={getOptionStyle({
                    isHovered,
                    isSelected: queryType === 'WEEK',
                  })}
                  onPress={() => navigateWeek({navigator, queryMode, queryDate})}
                >
                  <OptionText>Week</OptionText>
                </OptionTouchable>
              )}
            </Hover>
            <OptionsDivider />
            <Hover>
              {({isHovered}: any) => (
                <OptionTouchable
                  style={getOptionStyle({
                    isHovered,
                    isSelected: queryType === 'MONTH',
                  })}
                  onPress={() => navigateMonth({navigator, queryMode, queryDate})}
                >
                  <OptionText>Month</OptionText>
                </OptionTouchable>
              )}
            </Hover>
          </Options>
        )}
      </Section>
    </Header>
  );
};

const Bottom = ({isBottomOpen, queryMode, queryDate, selectedOrganizationSlug}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <Footer height={isBottomOpen ? 240 : 0}>
      <FooterContent>
        <MonthToggles>
          <MonthTouchable
            onPress={() =>
              navigateDate({
                date: Datetime.previousMonth(queryDate),
                queryMode,
                navigator,
                queryOrganization: selectedOrganizationSlug,
              })
            }
          >
            <Icon
              color={colors.gray.primary}
              size={Icon.Sizes.Large}
              source={Icon.ChevronLeft}
              style={{marginRight: 5}}
            />
            <TouchableText>
              {Datetime.convertToDisplayDate(Datetime.previousMonth(queryDate), 'MMM')}
            </TouchableText>
          </MonthTouchable>
          <TouchableText>{Datetime.convertToDisplayDate(queryDate, 'MMM')}</TouchableText>
          <MonthTouchable
            onPress={() =>
              navigateDate({
                date: Datetime.nextMonth(queryDate),
                queryMode,
                navigator,
                queryOrganization: selectedOrganizationSlug,
              })
            }
          >
            <TouchableText>
              {Datetime.convertToDisplayDate(Datetime.nextMonth(queryDate), 'MMM')}
            </TouchableText>
            <Icon
              color={colors.gray.primary}
              size={Icon.Sizes.Large}
              source={Icon.ChevronRight}
              style={{marginLeft: 5}}
            />
          </MonthTouchable>
        </MonthToggles>
        <Calendar
          hasToolbar={false}
          date={queryDate}
          dateCellWrapperComponent={({value}: any) => (
            <Touchable
              onPress={() =>
                navigateDate({
                  date: value,
                  queryMode,
                  navigator,
                  queryOrganization: selectedOrganizationSlug,
                })
              }
            >
              <DateCell
                // @ts-expect-error TS(2769): No overload matches this call.
                color={
                  queryDate === Datetime.toMutationDate(value)
                    ? colors.blue.interactive
                    : Datetime.isToday(value)
                      ? colors.orange.accent
                      : colors.white
                }
              />
            </Touchable>
          )}
          dateHeaderComponent={({date, label}: any) => (
            <Touchable
              onPress={() =>
                navigateDate({
                  date,
                  queryMode,
                  navigator,
                  queryOrganization: selectedOrganizationSlug,
                })
              }
            >
              <DateHeader>{label}</DateHeader>
            </Touchable>
          )}
        />
      </FooterContent>
    </Footer>
  );
};

type OwnCalendarHeaderProps = {
  mode: string;
  queryType?: string;
  right?: React.ReactElement;
};

// @ts-expect-error TS(2456): Type alias 'CalendarHeaderProps' circularly refere... Remove this comment to see the full error message
type CalendarHeaderProps = OwnCalendarHeaderProps & typeof CalendarHeader.defaultProps;

// @ts-expect-error TS(7022): 'CalendarHeader' implicitly has type 'any' because... Remove this comment to see the full error message
const CalendarHeader = ({
  mode,
  queryDate,
  queryType,
  selectedOrganizationSlug,
  organization,
  right,
  form,
}: CalendarHeaderProps) => {
  const [isBottomOpen, setIsBottomOpen] = useState(false);
  const queryMode = mode === 'BOOKING' ? 'booking' : 'reports';

  return (
    <Container>
      <Top
        isBottomOpen={isBottomOpen}
        queryMode={queryMode}
        queryDate={queryDate}
        queryType={queryType}
        organization={organization}
        selectedOrganizationSlug={selectedOrganizationSlug}
        right={right}
        setIsBottomOpen={setIsBottomOpen}
        form={form}
      />
      <Bottom
        isBottomOpen={isBottomOpen}
        queryMode={queryMode}
        queryDate={queryDate}
        selectedOrganizationSlug={selectedOrganizationSlug}
      />
    </Container>
  );
};

CalendarHeader.defaultProps = {
  queryType: 'MONTH',
  right: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarHeader.fragment = gql`
  ${CompanyOrganizationsDropdown.fragment}
  ${Organization.getProjectTypes.fragment}
  ${Organization.getSalesPersons.fragment}

  fragment CalendarHeader on Organization {
    id
    isPrimary
    features {
      isEnabledBookingCalendarSubContractorViewOwnOrganizationJobs: isEnabled(
        feature: "BOOKING_CALENDAR_SUB_CONTRACTOR_VIEW_OWN_ORGANIZATION_JOBS"
      )
    }
    company {
      id
      organizations {
        id
        name
        slug
        ...Organization_getProjectTypes
        ...Organization_getSalesPersons
      }
      ...CompanyOrganizationsDropdown
    }
  }
`;

export default CalendarHeader;
