// Libraries
import React from 'react';

// App
import {gql} from '@supermove/graphql';

import DataTableForm from '@shared/modules/Billing/forms/DataTableForm';
import useUpdateDataTableMutation from '@shared/modules/Billing/hooks/useUpdateDataTableMutation';
import DataTableModalContent from 'modules/Organization/Settings/Billing/Tables/components/DataTableModalContent';

const EditDataTableModal = ({isOpen, handleClose, refetch, dataTable, userId}: any) => {
  const dataTableForm = DataTableForm.edit(dataTable, {userId});
  const {form, handleSubmit, submitting} = useUpdateDataTableMutation({
    dataTableForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DataTableModalContent
      titleText='Edit Billing Table'
      form={form}
      handleSubmit={handleSubmit}
      submitting={submitting}
      isOpen={isOpen}
      handleClose={handleClose}
      showTableKindDropdown={false}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditDataTableModal.fragment = gql`
  ${DataTableForm.edit.fragment}

  fragment EditDataTableModal on DataTable {
    id
    ...DataTableForm_edit
  }
`;

export default EditDataTableModal;
