// Libraries
import React from 'react';
import Helmet from 'react-helmet';

import {Environment} from '@supermove/sdk';

import Meta from 'core/Meta';

type OwnProps = {
  title?: string;
  description?: string;
  keywords?: string[];
  imagePath?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Page.defaultProps;

// App

// @ts-expect-error TS(7022): 'Page' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Page = ({title, description, keywords, imagePath, children}: Props) => (
  <Page.Consumer>
    {({path}: any) => {
      // Override Supermove branding on *.move.new URLs. 127.0.0.1 is used during local development
      // to emulate the alternative office app domain.
      const isMoveNew = !!window.location.hostname.match(/(\.move\.new|127\.0\.0\.1)$/g);

      const domain = isMoveNew ? 'https://customer.move.new' : 'https://app.supermove.co';
      const url = `${domain}${path}`;
      const imageUrl = `${domain}${imagePath}`;
      return (
        <React.Fragment>
          <Helmet
            base={{
              target: '_self',
              href: isMoveNew ? 'https://customer.move.new' : Environment.getAppUrl(),
            }}
            title={isMoveNew ? 'Move' : title}
            meta={[
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1, maximum-scale=1',
              },
              {
                name: 'apple-mobile-web-app-capable',
                content: 'yes',
              },
              {
                name: 'apple-mobile-web-app-title',
                content: isMoveNew ? 'Move' : 'Supermove',
              },
              {
                name: 'apple-mobile-web-app-status-bar-style',
                content: 'black-translucent',
              },
              {
                name: 'mobile-web-app-capable',
                content: 'yes',
              },
              {
                name: 'google-site-verification',
                content: '',
              },
              {
                name: 'description',
                content: description,
              },
              {
                name: 'keywords',
                content: ['moving', 'supermove', ...keywords].join(', '),
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: description,
              },
              {
                property: 'og:image',
                content: imageUrl,
              },
              {
                property: 'og:url',
                content: url,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: description,
              },
              {
                name: 'twitter:image',
                content: imageUrl,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:site',
                content: '@supermoveco',
              },
            ]}
            link={[
              {rel: 'canonical', href: url},
              ...(isMoveNew
                ? [
                    {rel: 'icon', type: 'image/png', href: '/assets/favicon-movenew.png'},
                    {
                      rel: 'icon',
                      type: 'image/png',
                      sizes: '16x16',
                      href: '/assets/favicon-movenew@16x16.png',
                    },
                    {
                      rel: 'icon',
                      type: 'image/png',
                      sizes: '32x32',
                      href: '/assets/favicon-movenew@32x32.png',
                    },
                    {
                      rel: 'shortcut icon',
                      type: 'image/png',
                      href: '/assets/favicon-movenew@64x64.png',
                    },
                  ]
                : [
                    {rel: 'icon', type: 'image/png', href: '/assets/favicon.png'},
                    {
                      rel: 'icon',
                      type: 'image/png',
                      sizes: '16x16',
                      href: '/assets/favicon@16x16.png',
                    },
                    {
                      rel: 'icon',
                      type: 'image/png',
                      sizes: '32x32',
                      href: '/assets/favicon@32x32.png',
                    },
                    {rel: 'shortcut icon', type: 'image/png', href: '/assets/favicon@64x64.png'},
                  ]),

              // Apple icons
              {rel: 'apple-touch-icon', href: '/assets/apple-icon.png'},
              {rel: 'apple-touch-icon', sizes: '57x57', href: '/assets/apple-icon@57x57.png'},
              {rel: 'apple-touch-icon', sizes: '72x72', href: '/assets/apple-icon@72x72.png'},
              {rel: 'apple-touch-icon', sizes: '76x76', href: '/assets/apple-icon@76x76.png'},
              {rel: 'apple-touch-icon', sizes: '114x114', href: '/assets/apple-icon@114x114.png'},
              {rel: 'apple-touch-icon', sizes: '120x120', href: '/assets/apple-icon@120x120.png'},
              {rel: 'apple-touch-icon', sizes: '144x144', href: '/assets/apple-icon@144x144.png'},
              {rel: 'apple-touch-icon', sizes: '152x152', href: '/assets/apple-icon@152x152.png'},
              {rel: 'apple-touch-icon', sizes: '180x180', href: '/assets/apple-icon@180x180.png'},

              // Android icons
              {rel: 'icon', sizes: '36x36', href: '/assets/android-icon@36x36.png'},
              {rel: 'icon', sizes: '48x48', href: '/assets/android-icon@48x48.png'},
              {rel: 'icon', sizes: '72x72', href: '/assets/android-icon@72x72.png'},
              {rel: 'icon', sizes: '96x96', href: '/assets/android-icon@96x96.png'},
              {rel: 'icon', sizes: '144x144', href: '/assets/android-icon@144x144.png'},
              {rel: 'icon', sizes: '192x192', href: '/assets/android-icon@192x192.png'},
            ]}
          />
          {children}
        </React.Fragment>
      );
    }}
  </Page.Consumer>
);

// --------------------------------------------------
// Context
// --------------------------------------------------
const PageContext = React.createContext({});

Page.Provider = PageContext.Provider;
Page.Consumer = PageContext.Consumer;

Page.defaultProps = {
  title: Meta.DEFAULT.title,
  description: Meta.DEFAULT.description,
  keywords: [],
  imagePath: '',
};

export default Page;
