// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, withResponsive} from '@supermove/styles';

const Container = Styled.View`
  align-self: stretch;
  z-index: 800;
`;

const Row = Styled.View`
  flex-direction: row;
  height: 60px;
  padding-horizontal: 10px;
  z-index: 100;
`;

const Links = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  margin-left: 20px;
`;

const Link = Styled.Touchable`
  padding-right: ${withResponsive({
    default: 30,
    // @ts-expect-error TS(2559): Type 'number' has no properties in common with typ... Remove this comment to see the full error message
    mobile: 15,
  })}px;
`;

const LinkText = Styled.H6`
  font-weight: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isSelected ? 700 : 500)};
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isSelected ? colors.gray.primary : colors.gray.secondary)};
`;

const Line = Styled.View`
  margin-top: 15px;
  height: 3px;
  background-color: ${(props) => ((props as any).isSelected ? colors.gray.primary : colors.transparent)};
`;

const PayrollNavigation = ({selected, viewer}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Row responsive={responsive}>
        <ScrollView horizontal style={{flex: 1}}>
          <Links>
            {_.get(viewer, 'viewingOrganization.features.isEnabledPayrollForSales', false) && (
              <Link onPress={() => navigator.push('/payroll/sales')} responsive={responsive}>
                <LinkText
                  // @ts-expect-error TS(2769): No overload matches this call.
                  vars={{
                    isSelected: selected === 'SALES',
                  }}
                >
                  Sales
                </LinkText>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Line isSelected={selected === 'SALES'} />
              </Link>
            )}
            {_.get(viewer, 'viewingOrganization.features.isEnabledPayrollForCrew', false) && (
              <Link onPress={() => navigator.push('/payroll/crew')} responsive={responsive}>
                <LinkText
                  // @ts-expect-error TS(2769): No overload matches this call.
                  vars={{
                    isSelected: selected === 'CREW',
                  }}
                >
                  Crew
                </LinkText>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Line isSelected={selected === 'CREW'} />
              </Link>
            )}
          </Links>
        </ScrollView>
      </Row>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PayrollNavigation.fragment = gql`
  fragment PayrollNavigation on User {
    id
    viewingOrganization {
      id
      features {
        isEnabledPayrollForCrew: isEnabled(feature: "PAYROLL_FOR_CREW")
        isEnabledPayrollForSales: isEnabled(feature: "PAYROLL_FOR_SALES")
      }
    }
  }
`;

export default PayrollNavigation;
