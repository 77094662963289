// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useState,
  useNavigationDOM,
  useQuery,
  usePagination,
  useEffect,
  useResponsive,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Tabs from '@shared/design/components/Tabs';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ClaimsList from 'modules/Claim/components/ClaimsList';
import ClaimsPageHeader from 'modules/Claim/components/ClaimsPageHeader';
import ListClaimsFilters from 'modules/Claim/components/ListClaimsFilters';

const Container = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  background-color: ${colors.gray.background};
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? '24px' : 0)};
  flex: 1;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const getVariablesFromParams = (params: any, organization: any) => {
  return {
    slugs: organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
    ...params,
  };
};

const getPageUrl = ({params, page, organization}: any) => {
  const variables = getVariablesFromParams({...params, page}, organization);
  return URL.getUrlFromVariables('/claims', variables);
};

const getActiveTabIndex = ({tabDefinitions, claimStatusId}: any) => {
  const tabIndex = _.findIndex(
    tabDefinitions,
    (definition) => (definition as any).claimStatusId === claimStatusId,
  );
  return tabIndex === -1 ? 0 : tabIndex;
};

const getTabDefinitions = ({claimCounts, claimStatuses}: any) => {
  return _.orderBy(
    _.map(claimStatuses, (claimStatus) => {
      const countForClaimStatus = _.find(
        claimCounts,
        (containerCount) => containerCount.claimStatusId === _.toNumber(claimStatus.id),
      );

      return {
        claimStatusId: claimStatus.id,
        label: claimStatus.name,
        count: countForClaimStatus ? countForClaimStatus.count : 0,
        rank: claimStatus.rank,
      };
    }),
    ['rank'],
    ['asc'],
  );
};

const ListClaimsPageContent = ({
  loading,
  organization,
  claims,
  claimCounts,
  refetch,
  paginationMetadata,
  error,
  viewer,
}: any) => {
  const {params, navigator} = useNavigationDOM();
  const responsive = useResponsive();

  const [selectedClaims, setSelectedClaims] = useState([]);
  const {claimStatusId: currentClaimStatusId, ...filters} = params;

  const shouldRedirect = !params.slugs;

  useEffect(() => {
    if (shouldRedirect) {
      navigator.replace(getPageUrl({params, page: params.page || 1, organization}));
      return;
    }
  }, [params, shouldRedirect, navigator, organization]);

  const pagination = usePagination({
    currentPage: _.toNumber(params.page || 1),
    paginationMetadata,
    onChangePage: (page) => {
      navigator.replace(getPageUrl({params, page, organization}));
    },
  });
  const tabDefinitions = getTabDefinitions({
    claimCounts,
    claimStatuses: organization.companySettings.claimStatuses,
  });

  return (
    <PageContentContainer responsive={responsive}>
      <Space height={24} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={0}>
        <Row>
          <ListClaimsFilters
            organization={organization}
            selectedClaims={selectedClaims}
            handleResetSelections={() => setSelectedClaims([])}
            refetch={refetch}
          />
        </Row>
        <Space height={24} />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <Tabs
          tabs={tabDefinitions}
          tabStyle={{paddingLeft: 16, paddingRight: 16}}
          handlePressTab={({claimStatusId}) => {
            const variables = {...filters, claimStatusId, page: '1'};
            setSelectedClaims([]);
            navigator.push(URL.getUrlFromVariables(`/claims`, variables));
          }}
          activeTabIndex={getActiveTabIndex({tabDefinitions, claimStatusId: currentClaimStatusId})}
          isSpacedTabs={false}
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={2} style={{flex: 1}}>
        <ClaimsList
          organization={organization}
          loading={loading}
          refetch={refetch}
          claims={claims}
          hasError={!!error}
          selectedClaims={selectedClaims}
          setSelectedClaims={setSelectedClaims}
        />
      </Section>
      <Space height={24} />
      <PaginationBar pagination={pagination} />
      <Space height={24} />
    </PageContentContainer>
  );
};

const ListClaimsPage = () => {
  const {params} = useNavigationDOM();

  const {loading, data, refetch, error} = useQuery(ListClaimsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: params.page,
        resultsPerPage: 20,
      },
      searchQuery: params.query,
      claimStatusId: params.claimStatusId || -1,
      claimTypeIds: params.claimTypeIds,
      projectTypeIds: params.projectTypeIds,
      projectTagIds: params.projectTagIds,
      projectStatuses: params.projectStatuses,
      minAmount: params.minAmount,
      maxAmount: params.maxAmount,
      slugs: params.slugs || [],
    },
  });

  return (
    <SidebarPageV2 selected={'claims'}>
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <Container>
              <ClaimsPageHeader />
              <ListClaimsPageContent
                viewer={data.viewer}
                organization={data.viewer.viewingOrganization}
                claims={data.paginatedList.claims}
                claimCounts={data.claimCounts}
                paginationMetadata={data.paginatedList.paginationMetadata}
                loading={loading}
                error={error}
                refetch={refetch}
              />
            </Container>
          );
        }}
      </Loading>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListClaimsPage.query = gql`
  ${ListClaimsFilters.fragment}
  ${ClaimsList.fragment}
  ${usePagination.fragment}
  query ListClaimsPage(
    $pagination: PaginationInput!
    $claimStatusId: Int
    $claimTypeIds: [Int]
    $projectTypeIds: [Int]
    $projectTagIds: [Int]
    $projectStatuses: [String]
    $minAmount: Int
    $maxAmount: Int
    $searchQuery: String
    $slugs: [String]
  ) {
    viewer {
      id
      viewingOrganization {
        id
        slug
        isPrimary
        companySettings {
          claimStatuses {
            id
            name
            rank
          }
        }
        ...ListClaimsFilters
      }
      ...ClaimsList_viewer
    }
    paginatedList: filteredClaimsPaginatedList(
      pagination: $pagination
      claimStatusId: $claimStatusId
      claimTypeIds: $claimTypeIds
      projectTypeIds: $projectTypeIds
      projectTagIds: $projectTagIds
      projectStatuses: $projectStatuses
      minAmount: $minAmount
      maxAmount: $maxAmount
      searchQuery: $searchQuery
      slugs: $slugs
    ) {
      claims: results {
        id
        claimStatus {
          id
          isDefault
        }
        ...ClaimsList
      }
      paginationMetadata {
        ...usePagination
      }
    }
    claimCounts: filteredClaimCountsByClaimStatus(
      claimTypeIds: $claimTypeIds
      projectTypeIds: $projectTypeIds
      projectTagIds: $projectTagIds
      projectStatuses: $projectStatuses
      minAmount: $minAmount
      maxAmount: $maxAmount
      searchQuery: $searchQuery
      slugs: $slugs
    ) {
      claimStatusId
      count
    }
  }
`;

export default ListClaimsPage;
