// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {ReportMoveMoveUsersList} from 'modules/ReportMove/components';

const Container = Styled.View`
  width: 100%;
`;

type OwnProps = {
  moveUsers: any[];
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobReportMoveMoveUsers.defaultProps;

// @ts-expect-error TS(7022): 'JobReportMoveMoveUsers' implicitly has type 'any'... Remove this comment to see the full error message
const JobReportMoveMoveUsers = ({moveUsers}: Props) => (
  <Container>
    <ReportMoveMoveUsersList moveUsers={moveUsers} />
  </Container>
);

JobReportMoveMoveUsers.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobReportMoveMoveUsers.fragment = gql`
  ${ReportMoveMoveUsersList.fragment}

  fragment JobReportMoveMoveUsers on ReportMoveUser {
    ...ReportMoveMoveUsersList
  }
`;

export default JobReportMoveMoveUsers;
