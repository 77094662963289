/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {List} from '@supermove/utils';

// App

import ReorderSlotsForm from '@shared/modules/Dispatch/forms/ReorderSlotsForm';
import useReorderSlotsMutation from '@shared/modules/Dispatch/hooks/useReorderSlotsMutation';
import DriverSlotButton from 'modules/Dispatch/Calendar/Day/components/DriverSlotButton';
import OrganizationSlotsHeader from 'modules/Dispatch/Calendar/Day/components/OrganizationSlotsHeader';
import TruckSlotButton from 'modules/Dispatch/Calendar/Day/components/TruckSlotButton';
import CELL_STYLE from 'modules/Dispatch/Calendar/Day/constants/CELL_STYLE';
import RIGHT_PANEL_QUERIES from 'modules/Dispatch/Calendar/Day/constants/RIGHT_PANEL_QUERIES';
import useDayCalendarDispatchOrganizationsContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchOrganizationsContext';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';
import DeleteSlotModal from 'modules/Organization/Settings/Dispatch/Slots/components/DeleteSlotModal';

const INDEX_CONTAINER_WIDTH = 40;

const Row = Styled.View`
  flex-direction: row;
`;

const ColumnContainer = Styled.View`
  width: 128px;
`;

const OrganizationContainer = Styled.View`
`;

const SlotActionsContainer = Styled.View`
  width: ${INDEX_CONTAINER_WIDTH}px;
  align-items: flex-end;
  padding-right: 4px;
`;

const SlotIndexText = Styled.H8`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SlotsTimesHeaderPlaceholder = Styled.View`
  width: 88px;
  height: 25px;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const SlotTruckAndDriverColumn = Styled.View`
  width: 88px;
  align-items: center;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-top-width: 0px;
  border-bottom-width: 3px;
  border-color: ${colors.gray.border};
`;

const SlotIconButton = Styled.ButtonV2`
  height: 16px;
  width: 16px;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  border-radius: 8px;
`;

const ActivityIndicator = Styled.Loading`
`;

const getOrganizationSlotsKey = ({organizationSlotsSummary}: any) => {
  // We want reorderSlotsForm in OrganizationSlots to get re-created anytime
  // the slot order has changed. We do this by adding to the key a string of
  // the slot ids in sequential order.
  const {organization, slots} = organizationSlotsSummary;
  const slotIds: any = [];
  slots.forEach((slot: any) => slotIds.push(slot.id));
  return `${organization.id}_${slotIds.join()}`;
};

const getSlotHeight = ({slot, isExpanded}: any) => {
  const crewSlots = slot.activeCrewSlots;
  if (crewSlots.length < 1) {
    return CELL_STYLE.SHORT + 3;
  }
  if (isExpanded) {
    return _.sum(
      crewSlots.map((crewSlot: any, index: any) => {
        const isLast = index === crewSlots.length - 1;
        const offset = isLast ? 3 : 1;
        const cardHeight = CELL_STYLE.getCardHeight({
          isPrimary: crewSlot.isPrimary,
        });
        return cardHeight + offset;
      }),
    );
  }
  const hasPrimaryCrewSlot = _.includes(
    crewSlots.map((crewSlot: any) => crewSlot.isPrimary),
    true,
  );
  return CELL_STYLE.getCardHeight({isPrimary: hasPrimaryCrewSlot});
};

const handleChangeOrder = ({form, field, fromIndex, toIndex, handleSubmit}: any) => {
  const slotForms = _.get(form.values, field);
  const reorderedSlotForms = List.move({list: slotForms, fromIndex, toIndex});
  reorderedSlotForms.forEach((slotForm, index) => {
    if (index === fromIndex) {
      (slotForm as any).isReordering = true;
    }
    // TODO(dan) We need to add 1 to the index because slots and slotTypes are
    // currently indexed to start with 1. This should be changed so that slots
    // are indexed to start with 0.
    (slotForm as any).index = index + 1;
  });
  form.setFieldValue(field, reorderedSlotForms);

  // The setTimeout ensures that form.setFieldValue completes before running handleSubmit
  setTimeout(() => handleSubmit(), 0);
};

const resetIsReorderingSlotStates = ({form}: any) => {
  const {reorderSlotForms} = form.values.reorderSlotsForm;
  form.setFieldValue(
    'reorderSlotsForm.reorderSlotForms',
    reorderSlotForms.map((slotForm: any) => ({
      ...slotForm,
      isReordering: false,
    })),
  );
};

const getTripForSlotTruck = ({slot, trips}: any) => {
  if (!slot.truckId) {
    return null;
  }
  return _.find(trips, (trip) => _.includes(trip.truckIds, slot.truckId));
};

const getTripForSlotDriver = ({slot, trips}: any) => {
  if (!slot.driverId) {
    return null;
  }
  return _.find(trips, (trip) => trip.driverId === slot.driverId);
};

const SlotActionsColumn = ({
  slot,
  refetch,
  isFirstSlot,
  isLastSlot,
  index,
  submitting,
  handleChangeOrder,
  isSavingSlot,
}: any) => {
  const deleteSlotModal = useModal();
  return (
    <SlotActionsContainer>
      <SlotIndexText>{`${_.padStart(slot.index, 2, '0')}.`}</SlotIndexText>
      <Space height={2} />
      {isSavingSlot ? (
        <ActivityIndicator color={colors.gray.border} size={'small'} />
      ) : (
        <React.Fragment>
          <Row>
            <SlotIconButton
              onPress={() => {
                handleChangeOrder(index - 1);
              }}
              disabled={isFirstSlot || submitting}
              color={isFirstSlot ? colors.gray.border : colors.gray.border}
            >
              <Icon
                color={isFirstSlot ? colors.gray.border : colors.gray.secondary}
                size={Icon.Sizes.ExtraSmall}
                source={Icon.ArrowUp}
              />
            </SlotIconButton>
            <Space width={4} />
            <SlotIconButton
              onPress={() => {
                handleChangeOrder(index + 1);
              }}
              disabled={isLastSlot || submitting}
              color={isLastSlot ? colors.gray.border : colors.gray.border}
            >
              <Icon
                color={isLastSlot ? colors.gray.border : colors.gray.secondary}
                size={Icon.Sizes.ExtraSmall}
                source={Icon.ArrowDown}
              />
            </SlotIconButton>
          </Row>
          <Space height={2} />
          <SlotIconButton onPress={deleteSlotModal.handleOpen} color={colors.gray.border}>
            <Icon
              color={colors.gray.secondary}
              size={Icon.Sizes.ExtraSmall}
              source={Icon.EllipsisV}
            />
          </SlotIconButton>
        </React.Fragment>
      )}
      <DeleteSlotModal
        slotId={slot.id}
        isOpen={deleteSlotModal.isOpen}
        handleClose={deleteSlotModal.handleClose}
        refetch={refetch}
        refetchQueries={RIGHT_PANEL_QUERIES}
      />
    </SlotActionsContainer>
  );
};

const SlotInfo = ({
  slot,
  trips,
  refetch,
  isFirstSlot,
  isLastSlot,
  index,
  submitting,
  handleChangeOrder,
  isSavingSlot,
}: any) => {
  const {isExpanded} = useDayCalendarDispatchViewContext();
  const tripForTruck = getTripForSlotTruck({slot, trips});
  const tripForDriver = getTripForSlotDriver({slot, trips});
  const trip = tripForTruck || tripForDriver;
  const isTripSlot = !!trip;

  return (
    <Row style={{height: getSlotHeight({slot, isExpanded})}}>
      <SlotActionsColumn
        slot={slot}
        refetch={refetch}
        isFirstSlot={isFirstSlot}
        isLastSlot={isLastSlot}
        index={index}
        submitting={submitting}
        handleChangeOrder={handleChangeOrder}
        isSavingSlot={isSavingSlot}
      />
      <SlotTruckAndDriverColumn>
        <Space height={5} />
        <TruckSlotButton slot={slot} isTripSlot={isTripSlot} refetch={refetch} />
        <Space height={3} />
        <DriverSlotButton slot={slot} isTripSlot={isTripSlot} refetch={refetch} />
      </SlotTruckAndDriverColumn>
    </Row>
  );
};

const OrganizationSlots = ({organizationSlotsSummary, refetch, index}: any) => {
  const slots = _.sortBy(organizationSlotsSummary.slots, ['index']);

  const reorderSlotsForm = ReorderSlotsForm.edit({slots});
  const {form, handleSubmit, submitting} = useReorderSlotsMutation({
    reorderSlotsForm,
    onSuccess: async ({form}: any) => {
      await refetch();
      resetIsReorderingSlotStates({form});
    },
    onError: ({form}: any) => {
      resetIsReorderingSlotStates({form});
    },
  });

  const {organizations} = useDayCalendarDispatchOrganizationsContext();
  const isOpen = _.get(organizations, `${index}.isOpen`);

  return (
    <OrganizationContainer>
      <OrganizationSlotsHeader
        slotsSummary={organizationSlotsSummary}
        refetch={refetch}
        index={index}
      />
      <Space height={8} />
      {isOpen ? (
        <React.Fragment>
          <Row>
            <Space width={INDEX_CONTAINER_WIDTH} />
            <SlotsTimesHeaderPlaceholder />
          </Row>
          {slots.map((slot, index) => {
            const isFirstSlot = index === 0;
            const isLastSlot = index === slots.length - 1;
            return (
              <SlotInfo
                key={slot.id}
                slot={slot}
                trips={organizationSlotsSummary.trips}
                refetch={refetch}
                isFirstSlot={isFirstSlot}
                isLastSlot={isLastSlot}
                index={index}
                submitting={submitting}
                handleChangeOrder={(toIndex: any) => {
                  handleChangeOrder({
                    form,
                    field: 'reorderSlotsForm.reorderSlotForms',
                    fromIndex: index,
                    toIndex,
                    handleSubmit,
                  });
                }}
                isSavingSlot={_.get(
                  form.values,
                  `reorderSlotsForm.reorderSlotForms.${index}.isReordering`,
                )}
              />
            );
          })}
          <Space height={40} />
        </React.Fragment>
      ) : (
        <Space height={8} />
      )}
    </OrganizationContainer>
  );
};

const OrganizationSlotsInfo = ({organizationSlotsSummaries, refetch}: any) => {
  return (
    <ColumnContainer>
      {organizationSlotsSummaries.map((organizationSlotsSummary: any, index: any) => (
        <OrganizationSlots
          key={getOrganizationSlotsKey({organizationSlotsSummary})}
          organizationSlotsSummary={organizationSlotsSummary}
          refetch={refetch}
          index={index}
        />
      ))}
    </ColumnContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSlotsInfo.fragment = gql`
  ${DriverSlotButton.fragment}
  ${TruckSlotButton.fragment}
  ${OrganizationSlotsHeader.fragment}
  ${ReorderSlotsForm.edit.fragment}

  fragment OrganizationSlotsInfo on DispatchCalendarDay {
    organizationSlotsSummaries {
      organization {
        id
      }
      slots {
        id
        index
        driverId
        truckId
        activeCrewSlots {
          id
        }
        ...DriverSlotButton_Slot
        ...TruckSlotButton_Slot
        ...ReorderSlotsForm_edit
      }
      trips {
        id
        driverId
        truckIds
      }
    }
    ...OrganizationSlotsHeader
  }
`;

export default OrganizationSlotsInfo;
