// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';

const FilterContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

type OwnProps = {
  options: any[];
  label?: string;
  placeholder?: string;
  index?: number;
  value?: any[];
  onChangeValue?: (...args: any[]) => any;
  isPortaled?: boolean;
  isRequired?: boolean;
  showTrainingTag?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TagDropdownInputField.defaultProps;

// @ts-expect-error TS(7022): 'TagDropdownInputField' implicitly has type 'any' ... Remove this comment to see the full error message
const TagDropdownInputField = ({
  options,
  customOptions,
  label,
  placeholder,
  index,
  value,
  onChangeValue,
  isPortaled,
  isRequired,
  showTrainingTag,
}: Props) => {
  // Always filter out "Training" tags from dropdown
  const sortedOptions = _.sortBy(options, ['label']).filter((tag) => {
    return showTrainingTag || tag.label !== '🎓 Training';
  });

  return (
    <FilterContainer index={index}>
      {!!label && (
        <React.Fragment>
          <FieldInput.LabelText isResponsive isRequired={isRequired}>
            {label}
          </FieldInput.LabelText>
          <Space height={8} />
        </React.Fragment>
      )}
      <MultiDropdownCheckboxInput
        options={sortedOptions}
        customOptions={customOptions}
        placeholder={placeholder}
        style={{width: '100%'}}
        value={value}
        usePills
        isPortaled={isPortaled}
        onChangeValue={(tags: any) => {
          onChangeValue(tags);
        }}
        isSearchable
        isResponsive
      />
    </FilterContainer>
  );
};

TagDropdownInputField.defaultProps = {
  label: '',
  placeholder: '',
  index: 0,
  value: [],
  isPortaled: false,
  isRequired: false,
  showTrainingTag: false,
  onChangeValue: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TagDropdownInputField.fragment = gql`
  fragment TagDropdownInputField on Tag {
    id
    name
    emoji
    kind
  }
`;

export default TagDropdownInputField;
