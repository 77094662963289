/**
 * TODO(herrick): Replace this file with ProjectJobLocationsInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, LocationInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ViewLocationButtons from '@shared/modules/Location/components/ViewLocationButtons';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import CheckboxField from 'modules/App/components/CheckboxField';
import LocationKindDropdown from 'modules/Job/components/LocationKindDropdown';

const Container = Styled.View`
`;

const Section = Styled.View<{isFullWidth: boolean; mobile: boolean}>`
  ${({isFullWidth, mobile}) => (isFullWidth ? '' : `width: ${mobile ? '100%' : 'fit-content'}`)};
  z-index: ${(props) => 100 - (props as any).index}px;
`;

const TitleRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Left = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Action = Styled.Touchable`
`;

const ActionSpace = Styled.View`
  width: 10px;
`;

const ActionDivider = Styled.View`
  height: 24px;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  margin-left: ${(props) => ((props as any).mobile ? 0 : 10)}px;
`;

const Checkboxes = Styled.View`
  justify-content: flex-end;
`;

const AddLocation = Styled.View<{isFullWidth: boolean; mobile: boolean}>`
  justify-content: center;
  ${({isFullWidth, mobile}) => (isFullWidth ? '' : `width: ${mobile ? '100%' : 510}`)};
  height: 40px;
  margin-top: 20px;
`;

const AddLocationLine = Styled.View`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const InsertWrapper = Styled.View<{isFullWidth: boolean}>`
  align-items: ${({isFullWidth}) => (isFullWidth ? 'flex-start' : 'center')};
  z-index: 1;
`;

const STAIR_DESCRIPTION_OPTIONS = [
  {label: 'No Stairs', value: 'No Stairs'},
  {label: '1-10 Stairs', value: '1-10 Stairs'},
  {label: '11-20 Stairs', value: '11-20 Stairs'},
  {label: '21-30 Stairs', value: '21-30 Stairs'},
  {label: '31-40 Stairs', value: '31-40 Stairs'},
  {label: '41-50 Stairs', value: '41-50 Stairs'},
  {label: '51+ Stairs', value: '51+ Stairs'},
  {label: 'Unknown', value: 'Unknown'},
];

const getFieldName = ({field, name}: any) => {
  return field ? `${field}.${name}` : name;
};

const decrementIndexLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const beforeIndex = selectedIndex - 1;
  const beforeLocationForm = locationForms[beforeIndex];
  const selectedLocationForm = locationForms[selectedIndex];
  const newLocationForms = locationForms
    .slice(0, beforeIndex)
    .concat([selectedLocationForm, beforeLocationForm])
    .concat(locationForms.slice(selectedIndex + 1));
  form.setFieldValue(field, newLocationForms);
};

const incrementIndexLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const afterIndex = selectedIndex + 1;
  const afterLocationForm = locationForms[afterIndex];
  const selectedLocationForm = locationForms[selectedIndex];
  const newLocationForms = locationForms
    .slice(0, selectedIndex)
    .concat([afterLocationForm, selectedLocationForm])
    .concat(locationForms.slice(afterIndex + 1));
  form.setFieldValue(field, newLocationForms);
};

const deleteLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const newLocationForms = locationForms.filter((locationForm: any, index: any) => {
    return index !== selectedIndex;
  });

  form.setFieldValue(field, newLocationForms);
};

const insertLocationForm = ({isFirst, isLast, index, field, form, locationForms}: any) => {
  // Inserts a new LocationForm AFTER index.
  const kind = isLast ? LocationKind.DROP_OFF : LocationKind.PICK_UP;
  const newLocationForm = LocationForm.toForm(LocationForm.new({kind}));
  const firstLocationForms = _.slice(locationForms, 0, index + 1); // Include `index`.
  const lastLocationForms = _.slice(locationForms, index + 1, locationForms.length);
  const newLocationForms = [...firstLocationForms, newLocationForm, ...lastLocationForms];

  form.setFieldValue(field, newLocationForms);
};

const LocationActions = ({index, field, form, locationForms}: any) => {
  return (
    <Actions>
      <Action
        disabled={index === 0}
        onPress={() =>
          decrementIndexLocationForm({
            index,
            field,
            form,
            locationForms,
          })
        }
      >
        <Icon
          color={index === 0 ? colors.blue.accent : colors.gray.secondary}
          size={Icon.Sizes.Large}
          source={Icon.ArrowUp}
        />
      </Action>
      <ActionSpace />
      <Action
        disabled={index === locationForms.length - 1}
        onPress={() =>
          incrementIndexLocationForm({
            index,
            field,
            form,
            locationForms,
          })
        }
      >
        <Icon
          color={index === locationForms.length - 1 ? colors.blue.accent : colors.gray.secondary}
          size={Icon.Sizes.Large}
          source={Icon.ArrowDown}
        />
      </Action>
      <Space width={16} />
      <ActionDivider />
      <Space width={16} />
      <Action
        onPress={() =>
          deleteLocationForm({
            index,
            field,
            form,
            locationForms,
          })
        }
      >
        <Icon color={colors.red.warning} size={Icon.Sizes.Large} source={Icon.Trash} />
      </Action>
    </Actions>
  );
};

const LocationCoreFields = ({
  disabled,
  form,
  index,
  locationFormField,
  onLocation,
  organization,
  warehouseLocation,
  isBuildingTypeRequired,
  isFullWidth,
  responsive,
}: any) => {
  const fieldAddress = `${locationFormField}.address`;
  const fieldName = `${locationFormField}.name`;
  const fieldIsLocationLoading = `${locationFormField}.isLocationLoading`;
  const fieldZipCode = `${locationFormField}.zipCode`;
  const fieldIsNameViewable = `${locationFormField}.isNameViewable`;
  const nameValue = _.get(form, `values.${fieldName}`);
  const addressValue = _.get(form, `values.${fieldAddress}`);
  const isNameViewable = _.get(form, `values.${fieldIsNameViewable}`);
  return (
    <React.Fragment>
      {isNameViewable && (
        <React.Fragment>
          <Row index={index} {...responsive}>
            <FieldInput.Memoized
              {...form}
              actionText={nameValue ? null : 'Hide'}
              name={fieldName}
              label={'Name'}
              input={{
                disabled,
                placeholder: 'Enter custom name',
                style: {
                  paddingVertical: 9,
                },
              }}
              handleAction={() => form.setFieldValue(fieldIsNameViewable, false)}
              style={{flex: 1}}
            />
          </Row>
          <RowSpace {...responsive} />
        </React.Fragment>
      )}
      <Row data-test-id='form-location-row' index={index + 1} {...responsive}>
        <FieldInput
          {...form}
          index={0}
          component={LocationInput}
          actionText={isNameViewable ? null : '+ Custom name'}
          name={fieldAddress}
          label={'Address'}
          input={{
            disabled,
            required: !addressValue,
            placeholder: 'Enter address',
            setFieldValue: form.setFieldValue,
            setFieldError: form.setFieldError,
            setFieldTouched: form.setFieldTouched,
            onLocation,
            style: {
              paddingVertical: 9,
              ...(isFullWidth ? {} : {width: responsive.mobile ? '100%' : 380}),
            },
            isLocationLoadingField: fieldIsLocationLoading,
            initialLocation: warehouseLocation,
          }}
          handleAction={() => form.setFieldValue(fieldIsNameViewable, true)}
          style={{flex: 1}}
        />
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          index={1}
          name={fieldZipCode}
          label={'Zip Code'}
          input={{
            // Always disable the zipCode fields.
            disabled: true,
            placeholder: 'Enter zip code',
            style: {
              width: 120,
            },
          }}
        />
        {isFullWidth && (
          <React.Fragment>
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              index={3}
              component={DropdownInput}
              name={`${locationFormField}.buildingType`}
              label={'Location Type'}
              input={{
                disabled,
                isSearchable: true,
                required:
                  !_.get(form.values, `${locationFormField}.buildingType`) &&
                  isBuildingTypeRequired,
                options: Organization.getLocationTypeDropdownOptions(organization),
                placeholder: 'Select',
                setFieldValue: form.setFieldValue,
                style: {
                  width: '100%',
                },
              }}
              style={{flex: 1}}
            />
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const LocationSection = ({
  disabled,
  index,
  field,
  form,
  locationForms,
  responsive,
  organization,
  warehouseLocation,
  isBuildingTypeRequired,
  isFullWidth,
  mapRef,
}: any) => {
  const unitRef = React.createRef();
  const locationFormField = getFieldName({field, name: `${index}`});
  const locationForm = _.get(locationForms, index);
  const location = ViewLocationButtons.getLocationFromLocationForm(locationForm);

  return (
    <Section index={index} {...responsive} isFullWidth={isFullWidth}>
      <TitleRow>
        <Left>
          <Title>{`${index + 1} of ${locationForms.length}`}</Title>
          <Space width={8} />
          <LocationKindDropdown field={`${locationFormField}.kind`} form={form} />
        </Left>
        <Space style={{flex: 1}} />
        <ViewLocationButtons location={location} map={mapRef?.current} />
        {/** Only show actions if there is more than one location input. */}
        {locationForms.length > 1 && (
          <React.Fragment>
            <Space width={16} />
            <ActionDivider />
            <Space width={16} />
            <LocationActions
              index={index}
              field={field}
              form={form}
              locationForms={locationForms}
            />
          </React.Fragment>
        )}
      </TitleRow>
      <LocationCoreFields
        disabled={disabled}
        form={form}
        index={0}
        locationFormField={locationFormField}
        onLocation={({address, city, zipCode, latitude, longitude, state, country}: any) => {
          const locationForm = _.get(form.values, locationFormField);
          form.setFieldValue(locationFormField, {
            ...locationForm,
            address,
            city,
            zipCode,
            latitude,
            longitude,
            state,
            country,
          });
        }}
        organization={organization}
        warehouseLocation={warehouseLocation}
        isBuildingTypeRequired={isBuildingTypeRequired}
        isFullWidth={isFullWidth}
        responsive={responsive}
      />
      {!isFullWidth && (
        <React.Fragment>
          <RowSpace {...responsive} />
          <Row index={2} {...responsive}>
            <FieldInput.Memoized
              {...form}
              name={`${locationFormField}.unit`}
              label={'Unit / Suite #'}
              input={{
                disabled,
                innerRef: unitRef,
                placeholder: 'Enter unit #',
                style: {
                  width: 120,
                },
              }}
            />
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              index={3}
              component={DropdownInput}
              name={`${locationFormField}.buildingType`}
              label={'Location Type'}
              input={{
                disabled,
                isSearchable: true,
                required:
                  !_.get(form.values, `${locationFormField}.buildingType`) &&
                  isBuildingTypeRequired,
                options: Organization.getLocationTypeDropdownOptions(organization),
                placeholder: 'Select',
                setFieldValue: form.setFieldValue,
                style: {
                  width: 380,
                },
              }}
            />
          </Row>
        </React.Fragment>
      )}
      <RowSpace {...responsive} />
      <Row index={3} {...responsive}>
        {isFullWidth && (
          <React.Fragment>
            <FieldInput.Memoized
              {...form}
              name={`${locationFormField}.unit`}
              label={'Unit / Suite #'}
              input={{
                disabled,
                innerRef: unitRef,
                placeholder: 'Enter unit #',
                style: {
                  width: 120,
                },
              }}
            />
            <FieldSpace {...responsive} />
          </React.Fragment>
        )}
        <FieldInput.Memoized
          {...form}
          name={`${locationFormField}.floorNumber`}
          label={'Floor #'}
          input={{
            disabled,
            placeholder: 'Enter floor #',
            style: {
              width: 120,
            },
          }}
        />
        <FieldSpace {...responsive} />
        {organization.features.isEnabledProjectPageV2 ? (
          <FieldInput.Memoized
            {...form}
            name={`${locationFormField}.stairCount`}
            label={'Flights of Stairs'}
            input={{
              disabled,
              placeholder: 'Enter # of flights',
              style: {
                width: 160,
              },
            }}
          />
        ) : (
          <FieldInput.Memoized
            {...form}
            component={DropdownInput}
            name={`${locationFormField}.stairDescription`}
            label={'# of stairs'}
            input={{
              disabled,
              options: STAIR_DESCRIPTION_OPTIONS,
              placeholder: 'Select',
              isSearchable: true,
              setFieldValue: form.setFieldValue,
              style: {
                width: 120,
              },
            }}
          />
        )}
        <FieldSpace {...responsive} />
        <Checkboxes>
          <CheckboxField
            {...form}
            name={`${locationFormField}.hasElevator`}
            label={'Elevator?'}
            input={{
              disabled,
            }}
          />
          <CheckboxField
            {...form}
            name={`${locationFormField}.hasLongWalk`}
            label={'Long Walk?'}
            input={{
              disabled,
            }}
          />
        </Checkboxes>
      </Row>
      <RowSpace {...responsive} />
      <Row index={4} {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={`${locationFormField}.notes`}
          label={Job.FIELDS.LOCATION_NOTES}
          input={{
            disabled,
            multiline: true,
            placeholder: 'Enter notes such as parking details, access information, or gate codes',
            style: {
              paddingVertical: 9,
              minHeight: 60,
              ...(isFullWidth ? {flex: 1} : {width: responsive.mobile ? '100%' : 510}),
            },
          }}
          style={{flex: 1}}
        />
      </Row>
    </Section>
  );
};

const AddLocationSpace = ({locationsCount, onAddLocation, isFullWidth}: any) => {
  const responsive = useResponsive();

  return (
    <AddLocation
      {...responsive}
      style={{
        marginBottom: locationsCount === 1 ? 0 : 20,
      }}
      isFullWidth={isFullWidth}
    >
      <InsertWrapper isFullWidth={isFullWidth}>
        <SecondaryButton text={'Add Location'} iconLeft={Icon.Plus} onPress={onAddLocation} />
      </InsertWrapper>
      <AddLocationLine />
    </AddLocation>
  );
};

type OwnLocationFormsSectionProps = {
  disabled?: boolean;
  field: string;
  form: any;
  locationForms: any[];
  isBuildingTypeRequired: boolean;
};

// @ts-expect-error TS(2456): Type alias 'LocationFormsSectionProps' circularly ... Remove this comment to see the full error message
type LocationFormsSectionProps = OwnLocationFormsSectionProps &
  typeof LocationFormsSection.defaultProps;

// @ts-expect-error TS(7022): 'LocationFormsSection' implicitly has type 'any' b... Remove this comment to see the full error message
const LocationFormsSection = ({
  disabled,
  field,
  form,
  locationForms,
  organization,
  warehouseLocation,
  isBuildingTypeRequired,
  isFullWidth,
  mapRef,
}: LocationFormsSectionProps) => {
  const responsive = useResponsive();
  const locationsCount = locationForms.length;

  return (
    <Container>
      {locationForms.map((locationForm: any, index: any) => (
        <React.Fragment key={index}>
          <LocationSection
            disabled={disabled}
            index={index}
            field={field}
            form={form}
            locationForms={locationForms}
            responsive={responsive}
            organization={organization}
            warehouseLocation={warehouseLocation}
            isBuildingTypeRequired={isBuildingTypeRequired}
            isFullWidth={isFullWidth}
            mapRef={mapRef}
          />
          {(locationsCount === 1 || index < locationsCount - 1) && (
            <AddLocationSpace
              locationsCount={locationsCount}
              onAddLocation={() => {
                insertLocationForm({
                  isFirst: index === 0,
                  isLast: index === locationsCount - 1,
                  index,
                  field,
                  form,
                  locationForms,
                });
              }}
              isFullWidth={isFullWidth}
            />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

LocationFormsSection.defaultProps = {
  disabled: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationFormsSection.fragment = gql`
  ${Organization.getLocationTypeDropdownOptions.fragment}
  fragment LocationFormsSection on Organization {
    id
    features {
      isEnabledProjectPageV2: isEnabled(feature: "PROJECT_PAGE_V2")
    }
    ...Organization_getLocationTypeDropdownOptions
  }
`;

export default LocationFormsSection;
