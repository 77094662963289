// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import DocumentProcess from '@shared/modules/Document/enums/DocumentProcess';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import PrintDocumentButton from 'modules/Document/components/PrintDocumentButton';
import ShareDocumentButton from 'modules/Document/components/ShareDocumentButton';
import DownloadDocumentButton from 'modules/Job/V2/Move/components/DownloadDocumentButton';
import AddDocumentsPopover from 'modules/Project/V2/Show/components/AddDocumentsPopover';
import DocumentFilters from 'modules/Project/V2/Show/components/DocumentFilters';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ButtonContainer = Styled.View`
`;

const DocumentBodyContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const DocumentItemContainer = Styled.View`
  padding: 12px;
  border-bottom-width: ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => (isLast ? '0' : '1')}px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const MicroLinkText = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

const DocumentWidgetHeader = ({
  isViewIncomplete,
  setIsViewIncomplete,
  completedDocumentsCount,
  incompleteDocumentsCount,
  project,
  refetch,
  setSearchTerm,
  appliedFiltersCount,
  appliedFilters,
  setAppliedFilters,
}: any) => {
  return (
    <React.Fragment>
      <Row style={{paddingHorizontal: 16}}>
        <SearchBar
          onChangeText={(text) => setSearchTerm(text)}
          containerStyle={{flex: 1}}
          style={{flex: 1}}
          isClearable
          isResponsive
        />
        <Space width={12} />
        <DocumentFilters
          project={project}
          appliedFiltersCount={appliedFiltersCount}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
        />
      </Row>
      <Space height={16} />
      <Row style={{paddingHorizontal: 16}}>
        <ContextSwitcher
          isFullWidth
          contextDefinitions={[
            {
              label: `Completed (${completedDocumentsCount})`,
              isSelected: !isViewIncomplete,
              onPress: () => {
                setIsViewIncomplete(false);
              },
            },
            {
              label: `Incomplete (${incompleteDocumentsCount})`,
              isSelected: isViewIncomplete,
              onPress: () => {
                setIsViewIncomplete(true);
              },
            },
          ]}
        />
        <Space width={12} />
        <AddDocumentsPopover project={project} refetch={refetch}>
          {({handleToggle}: any) => (
            <Button
              width={48}
              // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
              text={null}
              iconLeft={Icon.Plus}
              iconSize={14}
              onPress={handleToggle}
              isResponsive
            />
          )}
        </AddDocumentsPopover>
      </Row>
    </React.Fragment>
  );
};

const getDocumentLink = ({document, project}: any) => {
  const {organization} = project;
  const {job} = document;
  const documentLinkMap = {
    BILL: () => `/0/${organization.slug}/jobs/${job.uuid}/bill`,
    CHANGE_ORDER: () => `/0/${organization.slug}/jobs/${job.uuid}/change-order`,
    CONFIRMATION_LETTER: () =>
      `/0/${organization.slug}/projects/${project.uuid}/confirmation/document/print`,
    REPORT_MOVE: () => `/0/${organization.slug}/jobs/${job.uuid}/move-report`,
    RISKY_MOVE: () => `/0/${organization.slug}/jobs/${job.uuid}/risky-move`,
    DEFAULT: () => {
      // If there's no document_content_json or if the v1 document has a signature that's been completed,
      // then we need to render the v1 document. Otherwise we can safetly render the v2 doc.
      if (!document.isDocumentV2 || document.isDocumentV1Completed) {
        return `/0/${organization.slug}/documents/${document.uuid}`;
      }
      if (organization.features.isEnabledShowCompletedDocuments && document.isCompleted) {
        return `/0/${organization.slug}/documents/v2/${document.uuid}/pdf`;
      }
      return `/0/${organization.slug}/documents/v2/${document.uuid}`;
    },
  };
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return (documentLinkMap[document.template] || documentLinkMap.DEFAULT)();
};

const LabelValue = ({label, value, onPress}: any) => {
  const responsive = useResponsive();
  return (
    <Row>
      <MicroLabelText responsive={responsive}>{`${label}: `}</MicroLabelText>
      {onPress ? (
        <TertiaryButton onPress={onPress}>
          <MicroLinkText responsive={responsive}>{value}</MicroLinkText>
        </TertiaryButton>
      ) : (
        <MicroText responsive={responsive}>{value}</MicroText>
      )}
    </Row>
  );
};

const DocumentItem = ({document, project, isLast, urlFilters}: any) => {
  const {isEnabledShowCompletedDocuments} = project.organization.features;
  const {job} = document;
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <DocumentItemContainer isLast={isLast}>
      <Row style={{alignItems: 'flexStart'}}>
        <a href={getDocumentLink({document, project})} target={'_blank'}>
          <LinkText responsive={responsive}>{document.displayName}</LinkText>
        </a>
        <Space style={{flex: 1, minWidth: 12}} />
        <ButtonContainer>
          <DownloadDocumentButton document={document} iconSize={16} tooltipPlacement={'left'} />
        </ButtonContainer>
        <Space width={12} />
        <ButtonContainer>
          <PrintDocumentButton
            document={document}
            isEnabledShowCompletedDocuments={isEnabledShowCompletedDocuments}
            documentUrl={getDocumentLink({document, project})}
            tooltipPlacement={'left'}
          />
        </ButtonContainer>
        <Space width={12} />
        <ShareDocumentButton document={document} />
      </Row>
      <Space height={4} />
      {document.isCompleted ? (
        <LabelValue
          label={'Completed'}
          value={Datetime.convertToDisplayDatetime(document.updatedAt)}
        />
      ) : (
        <LabelValue
          label={'Created'}
          value={Datetime.convertToDisplayDatetime(document.createdAt)}
        />
      )}
      <Space height={4} />
      <LabelValue label={'Process'} value={DocumentProcess.getDocumentProcessName(document)} />
      {!DocumentProcess.getIsProposalDocument(document) && (
        <React.Fragment>
          <Space height={4} />
          <LabelValue
            label={'Job'}
            value={job.fullName}
            onPress={() =>
              urlFilters.handleReset({
                jobUuid: job.uuid,
                block: ProjectBlockKind.JobBlocks[0],
                section: 'Jobs',
              })
            }
          />
        </React.Fragment>
      )}
    </DocumentItemContainer>
  );
};

const DocumentWidgetBody = ({
  documents,
  project,
  isViewIncomplete,
  urlFilters,
  refetch,
  appliedFiltersCount,
  setAppliedFilters,
}: any) => {
  return _.isEmpty(documents) ? (
    <EmptyState.ContentContainer>
      {appliedFiltersCount > 0 ? (
        <EmptyState
          title={'No results.'}
          message={'No results match your selected filters.\nClear all filters and try again.'}
          primaryActionIcon={Icon.Trash}
          primaryActionText={'Clear Filters'}
          handlePrimaryAction={() => setAppliedFilters({})}
        />
      ) : (
        <AddDocumentsPopover project={project} refetch={refetch}>
          {({handleToggle}: any) => (
            <EmptyState
              title={'No documents.'}
              message={
                isViewIncomplete
                  ? 'Add or generate documents\nand it will appear here.'
                  : 'Completed documents will appear here.'
              }
              primaryActionIcon={Icon.Plus}
              primaryActionIconRight={Icon.ChevronDown}
              primaryActionText={'Add Documents'}
              handlePrimaryAction={isViewIncomplete ? handleToggle : null}
            />
          )}
        </AddDocumentsPopover>
      )}
      <Space height={100} />
    </EmptyState.ContentContainer>
  ) : (
    <ScrollView style={{padding: 16, backgroundColor: colors.gray.background}}>
      <DocumentBodyContainer>
        {documents.map((document: any, index: any) => {
          return (
            <DocumentItem
              key={document.id}
              document={document}
              project={project}
              isLast={index + 1 === documents.length}
              urlFilters={urlFilters}
            />
          );
        })}
      </DocumentBodyContainer>
    </ScrollView>
  );
};

const getSortedDocuments = ({documents}: any) => {
  const sortedDocuments = documents.reduce(
    // @ts-expect-error TS(7031): Binding element 'completedDocuments' implicitly ha... Remove this comment to see the full error message
    ([completedDocuments, incompleteDocuments], document: any) => {
      return document.isCompleted || document.isDocumentV1Completed
        ? [[...completedDocuments, document], incompleteDocuments]
        : [completedDocuments, [...incompleteDocuments, document]];
    },
    [[], []],
  );
  return {completedDocuments: sortedDocuments[0], incompleteDocuments: sortedDocuments[1]};
};

const getFilteredDocuments = ({documents, searchTerm, appliedFilters}: any) => {
  let filteredDocuments = [...documents];
  if (appliedFilters.jobIds) {
    const selectedJobIds = appliedFilters.jobIds;
    filteredDocuments = filteredDocuments.filter((document) =>
      selectedJobIds.includes(_.toString(document.jobId)),
    );
  }
  if (appliedFilters.processes) {
    const selectedProcesses = appliedFilters.processes;
    filteredDocuments = filteredDocuments.filter((document) =>
      selectedProcesses.includes(DocumentProcess.getDocumentProcess(document)),
    );
  }
  if (searchTerm) {
    filteredDocuments = filteredDocuments.filter((document) =>
      document.displayName.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }
  return filteredDocuments;
};

const ProjectDocumentsWidget = ({projectUuid, urlFilters}: any) => {
  const [isViewIncomplete, setIsViewIncomplete] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [appliedFilters, setAppliedFilters] = useState({});
  const appliedFiltersCount = Object.keys(appliedFilters).length;
  const {loading, data, refetch} = useQuery(ProjectDocumentsWidget.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid},
  });

  return (
    <Container>
      <Loading loading={loading && !data} as={PageLoadingIndicator}>
        {() => {
          const {project} = data;
          const filteredDocuments = getFilteredDocuments({
            documents: project.activeDocuments,
            searchTerm,
            appliedFilters,
          });
          const {completedDocuments, incompleteDocuments} = getSortedDocuments({
            documents: filteredDocuments,
          });
          const documentsToDisplay = isViewIncomplete ? incompleteDocuments : completedDocuments;
          return (
            <React.Fragment>
              <Space height={16} />
              <DocumentWidgetHeader
                isViewIncomplete={isViewIncomplete}
                setIsViewIncomplete={setIsViewIncomplete}
                completedDocumentsCount={completedDocuments.length}
                incompleteDocumentsCount={incompleteDocuments.length}
                project={project}
                refetch={refetch}
                setSearchTerm={setSearchTerm}
                appliedFiltersCount={appliedFiltersCount}
                appliedFilters={appliedFilters}
                setAppliedFilters={setAppliedFilters}
              />
              <Space height={16} />
              <Line />
              <DocumentWidgetBody
                documents={documentsToDisplay}
                project={project}
                isViewIncomplete={isViewIncomplete}
                urlFilters={urlFilters}
                refetch={refetch}
                appliedFiltersCount={appliedFiltersCount}
                setAppliedFilters={setAppliedFilters}
              />
            </React.Fragment>
          );
        }}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectDocumentsWidget.query = gql`
  ${AddDocumentsPopover.fragment}
  ${DocumentFilters.fragment}
  ${DocumentProcess.getDocumentProcess.fragment}
  ${DocumentProcess.getDocumentProcessName.fragment}
  ${DocumentProcess.getIsProposalDocument.fragment}
  ${PrintDocumentButton.fragment}
  ${ShareDocumentButton.fragment}

  query ProjectDocumentsWidget($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      activeDocuments {
        id
        uuid
        displayName
        template
        isDocumentV2
        isDocumentV1Completed
        isCompleted
        createdAt
        updatedAt
        jobId
        job {
          id
          identifier
          uuid
          fullName
        }
        ...DocumentProcess_getDocumentProcess
        ...DocumentProcess_getDocumentProcessName
        ...DocumentProcess_getIsProposalDocument
        ...PrintDocumentButton
        ...ShareDocumentButton
      }
      organization {
        id
        slug
        features {
          isEnabledShowCompletedDocuments: isEnabled(feature: "SHOW_COMPLETED_DOCUMENTS")
        }
      }
      ...AddDocumentsPopover
      ...DocumentFilters
    }
  }
`;

export default ProjectDocumentsWidget;
