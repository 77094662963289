// Libraries
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation AcceptQuoteConfirmationMutation(
    $confirmationId: Int!,
  ) {
    response: acceptQuoteConfirmation(
      confirmationId: $confirmationId,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

const useAcceptQuoteConfirmationForm = ({confirmation, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      isFinished: false,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      confirmationId: confirmation.id,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useAcceptQuoteConfirmationForm.fragment = gql`
  fragment useAcceptQuoteConfirmationForm on Confirmation {
    id
  }
`;

export default useAcceptQuoteConfirmationForm;
