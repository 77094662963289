// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled, Loading, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useSheet,
  useState,
} from '@supermove/hooks';
import {Task} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import JobProjectTaskCard from 'modules/Job/components/JobProjectTaskCard';
import EditTaskDrawer from 'modules/TaskManagement/Tasks/components/EditTaskDrawer';
import NewTaskDrawer from 'modules/TaskManagement/Tasks/components/NewTaskDrawer';
import NewTaskSheet from 'modules/TaskManagement/Tasks/components/NewTaskSheet';

const Container = Styled.View`
`;

const EmptyContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const Row = Styled.View`
  flex-direction: row;

`;

const LoadingIndicator = Styled.Loading`
`;

const ViewAllTasksButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ViewAllTasksText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
`;

const HorizontalLine = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const getIsTaskVisible = ({task, isViewCompleted, isViewAll, index}: any) => {
  if (!isViewAll && index >= 5) {
    return false;
  }
  return task.isCompleted === isViewCompleted;
};

const TasksLoading = () => {
  return (
    <Container>
      <Space height={24} />
      <LoadingIndicator size={'small'} color={colors.gray.tertiary} />
      <Space height={32} />
    </Container>
  );
};

const ViewAllTasksToggle = ({isViewAll, setIsViewAll, tasksCount, responsive}: any) => {
  return (
    <Container style={{alignItems: 'center'}}>
      <ViewAllTasksButton onPress={() => setIsViewAll(!isViewAll)}>
        <ViewAllTasksText responsive={responsive}>
          {`${isViewAll ? 'Hide' : `${tasksCount - 5} more tasks`}`}
        </ViewAllTasksText>
        <Space width={8} />
        <Icon
          source={isViewAll ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.blue.interactive}
          size={14}
        />
      </ViewAllTasksButton>
    </Container>
  );
};

const UrlTaskDrawer = ({task, refetch, onCloseUrlTask}: any) => {
  const {navigator} = useNavigationDOM();
  const editTaskDrawer = useDrawer({
    name: 'Edit Task Drawer',
    enableTracking: true,
    initialIsOpen: true,
  });

  return (
    <EditTaskDrawer
      isOpen={editTaskDrawer.isOpen}
      handleClose={() => {
        editTaskDrawer.handleClose();
        if (onCloseUrlTask) {
          onCloseUrlTask();
        } else {
          navigator.replace(navigator.location.pathname);
        }
      }}
      task={task}
      refetch={refetch}
    />
  );
};

const TasksContent = ({
  project,
  refetch,
  urlTask,
  isWidget,
  onCloseUrlTask,
  newTaskHandler,
}: any) => {
  const responsive = useResponsive();
  const [isViewAll, setIsViewAll] = useState(isWidget);
  const {pendingTasks, completedTasks} = Task.groupByPendingOrCompleted(project.tasks);
  const pendingTasksCount = pendingTasks.length;
  const completedTasksCount = completedTasks.length;

  const [isViewPending, setIsViewPending] = useState(true);
  const isViewCompleted = !isViewPending;

  // NOTE(dan) This setup allows for each task's edit drawer to be rendered whether
  // the task is being filterered by the completion status or not. The task cards
  // will be hidden based on the completion filter, but the drawer will be functioning
  // whether the task card is visible or not. This allows the user to toggle the
  // completion state from the drawer while keeping the drawer rendered.
  const sortedTasks = isViewPending
    ? [...pendingTasks, ...completedTasks]
    : [...completedTasks, ...pendingTasks];
  const viewingTasksCount = isViewPending ? pendingTasksCount : completedTasksCount;

  return (
    <React.Fragment>
      <Row style={{padding: isWidget ? 16 : 0, justifyContent: 'space-between'}}>
        <ContextSwitcher
          isFullWidth
          contextDefinitions={[
            {
              label: `Pending (${pendingTasksCount})`,
              isSelected: isViewPending,
              onPress: () => {
                setIsViewPending(true);
              },
            },
            {
              label: `Completed (${completedTasksCount})`,
              isSelected: !isViewPending,
              onPress: () => {
                setIsViewPending(false);
              },
            },
          ]}
        />
        {isWidget && (
          <Row>
            <Space width={responsive.desktop ? 12 : 16} />
            <Button
              // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
              text={null}
              iconLeft={Icon.Plus}
              iconSize={14}
              onPress={newTaskHandler.handleOpen}
              isLarge={!responsive.desktop}
              // @ts-expect-error TS(2322): Type 'false | { paddingHorizontal: number; }' is n... Remove this comment to see the full error message
              style={!responsive.desktop && {paddingHorizontal: 20}}
            />
          </Row>
        )}
      </Row>
      {isWidget ? <HorizontalLine /> : <Space height={4} />}
      {isWidget && viewingTasksCount === 0 ? (
        <EmptyContainer>
          <EmptyText responsive={responsive}>{`No ${
            isViewPending ? 'Pending' : 'Completed'
          } Tasks`}</EmptyText>
          <Space height={16} />
          {isViewPending ? (
            <Button
              isResponsive
              text={'Create Task'}
              iconLeft={Icon.Plus}
              onPress={newTaskHandler.handleOpen}
            />
          ) : (
            <Button
              isResponsive
              text={'View Pending Tasks'}
              onPress={() => setIsViewPending(true)}
            />
          )}
        </EmptyContainer>
      ) : (
        <ScrollView style={{padding: isWidget ? 16 : 0}}>
          {sortedTasks.map((task, index) => {
            const isVisible = getIsTaskVisible({task, isViewCompleted, isViewAll, index});
            return (
              <React.Fragment key={(task as any).id}>
                {isVisible && <Space height={4} />}
                <JobProjectTaskCard task={task} refetch={refetch} isVisible={isVisible} />
              </React.Fragment>
            );
          })}
          {!isWidget && viewingTasksCount > 5 && (
            <React.Fragment>
              <Space height={16} />
              <ViewAllTasksToggle
                isViewAll={isViewAll}
                setIsViewAll={setIsViewAll}
                tasksCount={viewingTasksCount}
                responsive={responsive}
              />
            </React.Fragment>
          )}
        </ScrollView>
      )}
      {!!urlTask && (
        <UrlTaskDrawer task={urlTask} refetch={refetch} onCloseUrlTask={onCloseUrlTask} />
      )}
    </React.Fragment>
  );
};

type OwnProjectTasksProps = {
  projectUuid: string;
  newTaskDrawer?: any;
  isWidget?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'ProjectTasksProps' circularly referenc... Remove this comment to see the full error message
type ProjectTasksProps = OwnProjectTasksProps & typeof ProjectTasks.defaultProps;

// @ts-expect-error TS(7022): 'ProjectTasks' implicitly has type 'any' because i... Remove this comment to see the full error message
const ProjectTasks = ({
  projectUuid,
  newTaskDrawer,
  isWidget,
  onCloseUrlTask,
}: ProjectTasksProps) => {
  const responsive = useResponsive();
  const newTaskSheet = useSheet({name: 'New Task Sheet - Project Page'});
  const {params} = useNavigationDOM();
  const {data, loading, refetch} = useQuery(ProjectTasks.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
      taskUuid: params.taskUuid || '',
    },
  });

  return (
    <React.Fragment>
      <Loading loading={loading} as={TasksLoading}>
        {() => (
          <TasksContent
            project={data.project}
            urlTask={data.task}
            refetch={refetch}
            newTaskHandler={responsive.mobile ? newTaskSheet : newTaskDrawer}
            isWidget={isWidget}
            onCloseUrlTask={onCloseUrlTask}
          />
        )}
      </Loading>
      <NewTaskDrawer
        key={newTaskDrawer.key}
        isOpen={newTaskDrawer.isOpen}
        handleClose={newTaskDrawer.handleClose}
        projectUuid={projectUuid}
        refetch={refetch}
      />
      <NewTaskSheet
        isOpen={newTaskSheet.isOpen}
        handleClose={newTaskSheet.handleClose}
        projectUuid={projectUuid}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

ProjectTasks.defaultProps = {
  isWidget: false,
  newTaskDrawer: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTasks.query = gql`
  ${EditTaskDrawer.fragment}
  ${JobProjectTaskCard.fragment}
  ${Task.groupByPendingOrCompleted.fragment}
  query ProjectTasks($projectUuid: String!, $taskUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      tasks {
        id
        isCompleted
        ...JobProjectTaskCard
        ...Task_groupByPendingOrCompleted
      }
    }
    task(uuid: $taskUuid) {
      id
      ...EditTaskDrawer
    }
  }
`;

export default ProjectTasks;
