// Libraries
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components
import ReportMoveTimeRangeItem from './ReportMoveTimeRangeItem';

const EmptyMessage = Styled.H6`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

const ReportMoveTimeRangesList = ({timeRanges}: any) => (
  <FlatList
    data={timeRanges}
    keyExtractor={(timeRange: any, index: any) => String(index)}
    renderItem={({item: timeRange, index}: any) => (
      <ReportMoveTimeRangeItem isFirst={index === 0} index={index} timeRange={timeRange} />
    )}
    ListEmptyComponent={() => <EmptyMessage>No times yet</EmptyMessage>}
  />
);

export default ReportMoveTimeRangesList;
