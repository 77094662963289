// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import CreateJobButton from 'modules/App/components/CreateJobButton';

const Actions = Styled.View`
  flex-direction: row;
  z-index: 100;
`;

const ButtonSpace = Styled.View`
  flex: 1;
`;

const ButtonWrapper = Styled.View`
  align-items: center;
  height: 40px;
`;

const EstimatesCalendarActions = ({viewer}: any) => {
  const responsive = useResponsive();

  return (
    <Actions {...responsive}>
      {viewer.viewingOrganization.canCreateJobs && (
        <React.Fragment>
          <ButtonWrapper>
            <CreateJobButton style={{zIndex: 1, alignSelf: 'stretch'}} />
          </ButtonWrapper>
          <Space width={10} />
          <ButtonSpace />
        </React.Fragment>
      )}
    </Actions>
  );
};

EstimatesCalendarActions.fragment = gql`
  fragment EstimatesCalendarActionsButtons on User {
    id
    viewingOrganization {
      id
      canCreateJobs
    }
  }
`;

export default EstimatesCalendarActions;
