// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const EmailDateContainer = Styled.View`
  margin-left: auto;
`;

const DateTimeText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const EmailThreadItem = Styled.ButtonV2`
  background-color: ${colors.white};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectName = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.blue.accentDark};
`;

const EmailThreadName = Styled.Text`
  ${Typography.Responsive.Body}
`;

const CustomerEmailThreadItemV2 = ({emailThread, setSelectedThread}: any) => {
  const responsive = useResponsive();

  return (
    <EmailThreadItem onPress={() => setSelectedThread(emailThread)}>
      <Row>
        <ProjectName responsive={responsive} numberOfLines={1}>
          Project {emailThread.project.identifier}
        </ProjectName>
        <Space width={8} />
        <EmailDateContainer>
          <DateTimeText responsive={responsive} numberOfLines={1}>
            {emailThread.mostRecentCommunicationDate &&
              Datetime.convertToDisplayDatetime(emailThread.mostRecentCommunicationDate)}
          </DateTimeText>
        </EmailDateContainer>
      </Row>
      <Space height={8} />
      <Row>
        <EmailThreadName responsive={responsive} numberOfLines={2}>
          {emailThread.name}
        </EmailThreadName>
      </Row>
    </EmailThreadItem>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerEmailThreadItemV2.fragment = gql`
  fragment CustomerEmailThreadItemV2 on Thread {
    id
    name
    mostRecentCommunicationDate
    project {
      id
      identifier
    }
  }
`;

export default CustomerEmailThreadItemV2;
