// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal} from '@supermove/hooks';
import {OrganizationModel, PaymentMethod, PaymentMethodModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Table from '@shared/design/components/Table';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import CreatePaymentMethodDrawer from 'modules/Organization/Settings/Company/components/CreatePaymentMethodDrawer';
import DeletePaymentMethodModal from 'modules/Organization/Settings/Company/components/DeletePaymentMethodModal';
import EditPaymentMethodDrawer from 'modules/Organization/Settings/Company/components/EditPaymentMethodDrawer';

const getIsLastPayengineCreditCard = ({
  paymentMethod,
  organization,
}: {
  paymentMethod: PaymentMethodModel;
  organization: OrganizationModel;
}) =>
  PaymentMethodKind.PAYENGINE_CREDIT_CARD === paymentMethod.kind &&
  organization.payenginePaymentMethodsCount === 1;

const PaymentMethodsPanelContent = ({
  paymentMethods,
  organization,
  refetch,
}: {
  paymentMethods: PaymentMethodModel[];
  organization: OrganizationModel;
  refetch: () => void;
}) => {
  return (
    <Table
      columnDefinitions={[
        {
          flex: 2,
          headerLabel: 'Name',
          cellText: (item) => item.name,
          secondary: {
            headerLabel: 'Payment Method',
            cellText: (item) => PaymentMethodKind.getLabel(item.kind),
          },
        },
        {
          flex: 1,
          headerLabel: 'Payment Fee',
          cellText: (item) => PaymentMethod.getPaymentMethodDisplay(item),
        },
        {
          headerLabel: 'Actions',
          width: 84,
          cellStyle: {alignItems: 'center'},
          actions: (item) => [
            {
              text: 'Edit',
              onPress: ({handleOpen}) => handleOpen && handleOpen(),
              desktopIcon: Icon.Pen,
              actionHook: {
                hook: useDrawer,
                hookArgument: {name: 'Update Payment Method Drawer'},
                renderComponent: ({hookKey, isOpen, handleClose}) => {
                  return (
                    <EditPaymentMethodDrawer
                      key={hookKey}
                      isOpen={isOpen}
                      handleClose={handleClose}
                      // Model has it as string
                      paymentMethodId={item.id as unknown as number}
                      refetch={refetch}
                    />
                  );
                },
              },
            },
            {
              text: 'Remove payment method',
              // The last payengine credit card cannot be deleted
              isDisabled: getIsLastPayengineCreditCard({paymentMethod: item, organization}),
              tooltip: getIsLastPayengineCreditCard({paymentMethod: item, organization})
                ? `Cannot delete the last 'Credit Card (Supermove)'`
                : undefined,
              onPress: ({handleOpen}) => handleOpen && handleOpen(),
              color: colors.red.warning,
              actionHook: {
                hook: useModal,
                hookArgument: {name: 'Delete Payment Method Modal'},
                renderComponent: ({hookKey, isOpen, handleClose}) => {
                  const paymentMethod = item as unknown as PaymentMethodModel;
                  return (
                    <DeletePaymentMethodModal
                      key={hookKey}
                      isOpen={isOpen}
                      handleClose={handleClose}
                      paymentMethodId={paymentMethod.id}
                      paymentMethodName={paymentMethod.name}
                      refetch={refetch}
                    />
                  );
                },
              },
            },
          ],
        },
      ]}
      items={paymentMethods}
      itemKey={'id'}
      emptyStateText={'No payment methods'}
      hasBorder
    />
  );
};

const PaymentMethodsPanel = ({
  index,
  viewingOrganization,
  refetch,
}: {
  index: number;
  viewingOrganization: OrganizationModel;
  refetch: () => void;
}) => {
  const createPaymentMethodDrawer = useDrawer({name: 'Create Payment Method Drawer'});
  return (
    <React.Fragment>
      <ActionPanel
        index={index}
        title={'Payment Methods'}
        ActionButtonComponent={TertiaryButton}
        actionButtonComponentProps={{
          text: 'Create',
          iconLeft: Icon.Plus,
          onPress: createPaymentMethodDrawer.handleOpen,
          isResponsive: true,
        }}
        BodyComponent={PaymentMethodsPanelContent}
        bodyComponentProps={{
          paymentMethods: PaymentMethod.getSortedPaymentMethods(
            viewingOrganization.paymentMethods.filter(
              (paymentMethod) =>
                !_.includes(PaymentMethodKind.getHardcodedCrewMethods(), paymentMethod.kind),
            ),
          ),
          refetch,
          organization: viewingOrganization,
        }}
        width={Panel.WIDTH.DEFAULT}
      />
      <CreatePaymentMethodDrawer
        key={createPaymentMethodDrawer.key}
        isOpen={createPaymentMethodDrawer.isOpen}
        handleClose={createPaymentMethodDrawer.handleClose}
        organization={viewingOrganization}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentMethodsPanel.fragment = gql`
  ${CreatePaymentMethodDrawer.fragment}
  ${PaymentMethod.getPaymentMethodDisplay.fragment}
  ${PaymentMethod.getSortedPaymentMethods.fragment}
  fragment PaymentMethodsPanel on Organization {
    id
    paymentMethods {
      id
      name
      kind
      ...PaymentMethod_getPaymentMethodDisplay
      ...PaymentMethod_getSortedPaymentMethods
    }
    payenginePaymentMethodsCount
    ...CreatePaymentMethodDrawer
  }
`;

export default PaymentMethodsPanel;
