// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Task} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TaskTemplateDrawerFields from 'modules/TaskManagement/TaskTemplates/components/TaskTemplateDrawerFields';

const WorkflowStepEditTaskBlockContainer = Styled.View`
  padding: 16px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const handleResetTaskProperty = ({form, field, property}: any) => {
  switch (property) {
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.NAME:
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.DETAILS:
      return form.setFieldValue(`${field}.${property}`, '');
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.ASSIGNEE:
      form.setFieldValue(`${field}.assignedToUserId`, null);
      form.setFieldValue(`${field}.assignedToRole`, '');
      return;
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.DUE_AT:
      form.setFieldValue(`${field}.dueAtTime`, '');
      form.setFieldValue(`${field}.dueInUnits`, '');
      return;
    default:
      return;
  }
};

const TaskNameField = ({form, field, index}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Update To'}
      name={`${field}.name`}
      input={{
        placeholder: 'Enter value',
      }}
    />
  );
};

const TaskPropertyDropdown = ({form, field, index}: any) => {
  const propertiesToUpdate = _.get(form.values, `${field}.propertiesToUpdate`);
  return (
    <FieldInput
      {...form}
      label={'Select Field'}
      name={`${field}.propertiesToUpdate.${index}`}
      component={DropdownInput}
      input={{
        placeholder: 'Select field',
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        isPortaled: true,
        options: Task.WORKFLOW_BUILDER_TASK_PROPERTY_DROPDOWN_OPTIONS.map((option) => ({
          ...option,
          isDisabled: propertiesToUpdate.includes(option.value),
        })),
        onChangeValue: (newProperty: any, option: any, prevProperty: any) =>
          handleResetTaskProperty({form, field, property: prevProperty}),
      }}
      style={{width: '100%'}}
    />
  );
};

const TaskPropertyInput = ({property, form, field, organization}: any) => {
  switch (property) {
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.NAME:
      return <TaskNameField form={form} field={field} index={0} />;
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.DETAILS:
      return (
        <TaskTemplateDrawerFields.DetailsField
          form={form}
          field={field}
          index={0}
          label={'Update To'}
          placeholder={'Enter value'}
        />
      );
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.DUE_AT:
      return (
        <React.Fragment>
          <Space height={8} />
          <TaskTemplateDrawerFields.DueFields form={form} field={field} index={0} />
        </React.Fragment>
      );
    case Task.WORKFLOW_BUILDER_TASK_PROPERTY.ASSIGNEE:
      return (
        <TaskTemplateDrawerFields.AssigneeField
          form={form}
          field={field}
          organization={organization}
          index={0}
          label={'Update To'}
          placeholder={'Enter value'}
          isEnabledRoles={false}
        />
      );
    default:
      return <FieldInput label={'Update To'} input={{disabled: true}} />;
  }
};

const WorkflowStepEditTaskBlock = ({property, form, field, organization, index}: any) => {
  return (
    <WorkflowStepEditTaskBlockContainer>
      <TaskPropertyDropdown form={form} field={field} index={index} />
      <Space height={16} />
      <TaskPropertyInput
        property={property}
        form={form}
        field={field}
        organization={organization}
      />
    </WorkflowStepEditTaskBlockContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowStepEditTaskBlock.fragment = gql`
  ${TaskTemplateDrawerFields.fragment}
  fragment WorkflowStepEditTaskBlock on Organization {
    id
    ...TaskTemplateDrawerFields
  }
`;

export default WorkflowStepEditTaskBlock;
