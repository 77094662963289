// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import ViewInvoiceDrawerV1 from 'modules/Accounting/components/ViewInvoiceDrawerV1';
import InvoiceStatusBadge from 'modules/Storage/components/InvoiceStatusBadge';

const Row = Styled.View`
  flex-direction: row;
`;

const InvoiceCardButton = Styled.ButtonV2`
`;

const MobileLabelText = Styled.Text`
  ${Typography.Mobile.Label}
`;

const MobileMicroLabelText = Styled.Text`
  ${Typography.Mobile.MicroLabel}
`;

const MobileMicroText = Styled.Text`
  ${Typography.Mobile.Micro}
`;

const getDisplayDate = (date: any) =>
  date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : null;

const InvoiceCard = ({invoice}: any) => {
  const {dueDate, remainingBalance} = invoice;
  const viewInvoiceDrawer = useDrawer({name: 'View Invoice Drawer'});

  return (
    <React.Fragment>
      <InvoiceCardButton onPress={viewInvoiceDrawer.handleOpen}>
        <Row style={{justifyContent: 'space-between'}}>
          <MobileLabelText>{`Invoice ${invoice.identifier}`}</MobileLabelText>
          <Row style={{alignItems: 'center'}}>
            <InvoiceStatusBadge invoice={invoice} />
            <Space width={8} />
            <Icon source={Icon.AngleRight} size={14} color={colors.gray.secondary} />
          </Row>
        </Row>
        <Space height={8} />
        <Row>
          <MobileMicroLabelText>Due Date: </MobileMicroLabelText>
          <MobileMicroText>{getDisplayDate(dueDate) ?? 'None'}</MobileMicroText>
        </Row>
        <Space height={8} />
        <Row>
          <MobileMicroLabelText>Remaining Balance: </MobileMicroLabelText>
          <MobileMicroText>{Currency.display(remainingBalance)}</MobileMicroText>
        </Row>
      </InvoiceCardButton>
      <ViewInvoiceDrawerV1
        invoiceUuid={invoice.uuid}
        invoiceIdentifier={invoice.identifier}
        isOpen={viewInvoiceDrawer.isOpen}
        handleClose={viewInvoiceDrawer.handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

InvoiceCard.fragment = gql`
  ${InvoiceStatusBadge.fragment}

  fragment InvoiceCard on Invoice {
    id
    uuid
    identifier
    dueDate
    remainingBalance
    ...InvoiceStatusBadge
  }
`;

export default InvoiceCard;
