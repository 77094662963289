// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useDebouncedCallback, useModal} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import UpdateProjectValuesModal from 'modules/Project/Billing/components/UpdateProjectValuesModal';

const HeaderContainer = Styled.View`
`;

const LeftContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InvoiceFilters = ({setSearchQuery, isJobPage, project, refetch}: any) => {
  const {navigator} = useNavigationDOM();
  const editBillingValuesModal = useModal({
    name: 'Edit Billing Values Modal',
    enableTracking: true,
  });
  const updateProjectValuesModal = useModal({
    name: 'Update Project Values Modal',
    enableTracking: true,
  });
  const updateValuesForm = UpdateValuesForm.edit(project);
  const {form, handleSubmit} = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  // Reset state of searchQuery based on searchbar input
  const handleChangeSearch = useDebouncedCallback((text) => {
    setSearchQuery(text);
  }, 500);

  return (
    <HeaderContainer>
      <FiltersContainers>
        <LeftContainer>
          <SearchBar
            onChangeText={handleChangeSearch}
            placeholder='Search by invoice details'
            style={{width: '348px'}}
            defaultValue={''}
          />
        </LeftContainer>
        {!isJobPage && (
          <RightContainer>
            <SecondaryButton
              text={'Edit Billing Values'}
              iconLeft={Icon.Pen}
              onPress={editBillingValuesModal.handleOpen}
            />
            <Space width={12} />
            <Button
              text={'Create Invoice'}
              iconLeft={Icon.Plus}
              onPress={() => {
                navigator.push(
                  `/${isJobPage ? 'move' : 'storage'}/projects/${project.uuid}/invoices/new`,
                );
              }}
            />
            <UpdateProjectValuesModal
              key={updateProjectValuesModal.key}
              isOpen={updateProjectValuesModal.isOpen}
              projectUuid={project.uuid}
              refetch={() => {}}
              handleClose={updateProjectValuesModal.handleClose}
            />
            <EditBillingValuesModal
              key={editBillingValuesModal.key}
              isOpen={editBillingValuesModal.isOpen}
              handleClose={editBillingValuesModal.handleClose}
              projectUuid={project.uuid}
              updateValuesForm={form}
              handleSubmit={handleSubmit}
            />
          </RightContainer>
        )}
      </FiltersContainers>
      <Space height={16} />
    </HeaderContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

InvoiceFilters.fragment = gql`
  ${UpdateValuesForm.edit.fragment}

  fragment InvoiceFilters on Project {
    id
    uuid
    ...UpdateValuesForm_edit
  }
`;

export default InvoiceFilters;
