// Supermove
import {gql} from '@supermove/graphql';
import {useInlineFormMutation} from '@supermove/hooks';

const useSyncCommercialCatalogBillMutation = ({
  commercialCatalogId,
  onSuccess,
  onError,
}: {
  commercialCatalogId: string;
  onSuccess: () => void;
  onError: (errors: unknown) => void;
}) => {
  return useInlineFormMutation({
    name: 'syncCommercialCatalogBillForm',
    form: {
      commercialCatalogId,
    },
    mutation: useSyncCommercialCatalogBillMutation.mutation,
    onSuccess,
    onError,
  });
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSyncCommercialCatalogBillMutation.mutation = gql`
  mutation useSyncCommercialCatalogBillMutation($syncCommercialCatalogBillForm: SyncCommercialCatalogBillForm!) {
    response: syncCommercialCatalogBill(syncCommercialCatalogBillForm: $syncCommercialCatalogBillForm) {
      ${gql.errors}
      commercialCatalog {
        id
      }
    }
  }
`;

export default useSyncCommercialCatalogBillMutation;
