/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Loading, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useScrollView} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import useUpdateClientMutation from '@shared/modules/Client/hooks/useUpdateClientMutation';
import Line from 'modules/App/components/Line';
import EditClientModalFields from 'modules/Client/Show/components/EditClientModalFields';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 0 : 64)}px;
`;

const ModalContainer = Styled.View`
  flex: 1;
  width: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '100%' : '800px')};
  border-radius: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '0px' : '16px')};
  background-color: ${colors.white};
  overflow: hidden;
`;

const SectionContainer = Styled.View`
  padding-vertical: 16px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? 16 : 24)}px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const FooterButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 120px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${(props) => (props as any).color};
`;

const ActivityIndicator = Styled.Loading`
  flex: 1;
`;

const LoadingIndicator = () => {
  return (
    <ModalContainer>
      <ActivityIndicator size={'small'} color={colors.gray.secondary} />
    </ModalContainer>
  );
};

const EditClientModalHeader = () => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SectionContainer color={colors.white} {...responsive}>
      <HeaderText>Edit Client Profile</HeaderText>
    </SectionContainer>
  );
};

const EditClientModalFooter = ({handleClose, handleSubmit, submitting}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SectionContainer color={colors.white} {...responsive}>
      <Row style={{flex: 1, justifyContent: 'flex-end'}}>
        <FooterButton color={'transparent'} onPress={handleClose} disabled={submitting}>
          <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
        </FooterButton>
        <Space width={16} />
        <FooterButton
          color={submitting ? colors.gray.border : colors.blue.interactive}
          onPress={handleSubmit}
          disabled={submitting}
        >
          {submitting ? (
            <ActivityIndicator color={colors.white} size={'small'} />
          ) : (
            <FooterButtonText color={colors.white}>Save</FooterButtonText>
          )}
        </FooterButton>
      </Row>
    </SectionContainer>
  );
};

const EditClientModalContent = ({
  client,
  handleClose,
  refetch,
  resetParentForm,
  emailAddressRequired,
}: any) => {
  const responsive = useResponsive();
  const clientForm = ClientForm.edit(client);
  const scrollView = useScrollView();
  const {form, handleSubmit, submitting} = useUpdateClientMutation({
    clientForm,
    onSuccess: () => {
      refetch();
      if (resetParentForm) {
        resetParentForm(form);
      }
      handleClose();
    },
    onError: () => {
      scrollView.handleScrollToTop({animated: true});
    },
  });

  return (
    <React.Fragment>
      <EditClientModalHeader />
      <Line />
      <ScrollView
        ref={scrollView.ref}
        style={{
          flex: 1,
          backgroundColor: colors.gray.background,
          paddingVertical: 16,
          paddingHorizontal: responsive.mobile ? 8 : 24,
        }}
      >
        <EditClientModalFields
          form={form}
          field={'clientForm'}
          clientId={client.id}
          latestExternalIntegrationCustomerId={client.latestExternalIntegrationCustomer?.id}
          organization={client.organization}
          emailAddressRequired
        />
      </ScrollView>
      <Line />
      <EditClientModalFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
    </React.Fragment>
  );
};

const EditClientModal = ({
  isOpen,
  handleClose,
  clientUuid,
  refetch,
  resetParentForm,
  emailAddressRequired,
}: any) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(EditClientModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      clientUuid,
    },
  });

  return (
    <Modal.Content onClose={() => {}} isOpen={isOpen}>
      <PageContainer {...responsive}>
        <ModalContainer {...responsive}>
          <Loading loading={loading || !data} as={LoadingIndicator}>
            {() => {
              return (
                <EditClientModalContent
                  client={data.client}
                  handleClose={handleClose}
                  refetch={refetch}
                  resetParentForm={resetParentForm}
                  emailAddressRequired
                />
              );
            }}
          </Loading>
        </ModalContainer>
      </PageContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditClientModal.query = gql`
  ${ClientForm.edit.fragment}
  ${EditClientModalFields.fragment}

  query EditClientModal($clientUuid: String!) {
    client(clientUuid: $clientUuid) {
      id
      organization {
        id
        ...EditClientModalFields
      }
      latestExternalIntegrationCustomer {
        id
      }
      ...ClientForm_edit
    }
  }
`;

export default EditClientModal;
