// Supermove
import {useNavigationDOM} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const DateRangeHandler = ({getDefaultStartDate, getDefaultEndDate, children}: any) => {
  const {navigator, params} = useNavigationDOM();
  const {startDate: start, endDate: end} = params;

  if (start && end) {
    const startDate = Datetime.fromDate(start);
    const endDate = Datetime.fromDate(end);
    return children({
      startDate,
      endDate,
      setStartDate: (date: any) => {
        navigator.push(`?startDate=${Datetime.toDate(date)}&endDate=${end}`);
      },
      setEndDate: (date: any) => {
        navigator.push(`?startDate=${start}&endDate=${Datetime.toDate(date)}`);
      },
    });
  }

  if (start) {
    const startDate = Datetime.fromDate(start);
    const endDate = getDefaultEndDate({startDate});
    navigator.replace(`?startDate=${start}&endDate=${Datetime.toDate(endDate)}`);
    return null;
  }

  const startDate = getDefaultStartDate();
  const endDate = getDefaultEndDate({startDate});
  navigator.replace(`?startDate=${Datetime.toDate(startDate)}&endDate=${Datetime.toDate(endDate)}`);

  return null;
};

export default DateRangeHandler;
