// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState, useToast, useResponsive} from '@supermove/hooks';
import {DocumentItemImageType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import ContextSwitcher from '@shared/design/components/ContextSwitcher';
import FieldInput from '@shared/design/components/Field/FieldInput';
import FileDragInput from '@shared/design/components/FileDragInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import {
  DocumentContentJsonType,
  SetDocumentContentJsonType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';
import UploadFileForm from '@shared/modules/File/forms/UploadFileForm';
import useUploadFileForm from '@shared/modules/File/hooks/useUploadFileForm';
import useAppContext, {AppContextViewerType} from 'modules/App/context/useAppContext';

const MAX_FILE_BYTES = 10000000; // 10mb
const POSITION_TO_ICON = {
  [DocumentV2Image.POSITION.LEFT]: Icon.ObjectsAlignLeft,
  [DocumentV2Image.POSITION.CENTER]: Icon.ObjectsAlignCenter,
  [DocumentV2Image.POSITION.RIGHT]: Icon.ObjectsAlignRight,
};

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const SectionLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const getHandleImageOnLoad = ({
  img,
  resolve,
  setDraftDocumentContentJson,
  documentItemField,
}: {
  img: HTMLImageElement;
  resolve: (value: unknown) => void;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  return () => {
    setDraftDocumentContentJson((documentContentJson) => {
      _.set(documentContentJson, `${documentItemField}.input.metadata.width`, img.width);
      _.set(documentContentJson, `${documentItemField}.input.metadata.height`, img.height);
      return {...documentContentJson};
    });
    resolve(null);
  };
};

const getImageSizePromise = ({
  reader,
  setDraftDocumentContentJson,
  documentItemField,
}: {
  reader: FileReader;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  return new Promise((resolve) => {
    reader.onload = () => {
      const img = new Image();
      img.onload = getHandleImageOnLoad({
        img,
        resolve,
        setDraftDocumentContentJson,
        documentItemField,
      });
      img.src = reader.result as string;
    };
  });
};

const handleResetImage = ({
  setDraftDocumentContentJson,
  documentItemField,
}: {
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItemField: string;
}) => {
  setDraftDocumentContentJson((documentContentJson) => {
    _.set(documentContentJson, `${documentItemField}.input.metadata`, {
      fileId: null,
      size: 0,
      width: 0,
      height: 0,
    });
    return {...documentContentJson};
  });
};

const handleSizeValue = (value: string) => {
  const numeric = Math.abs(parseInt(value, 10));
  return numeric ? _.toString(numeric) : '';
};

const ImageInput = ({
  setErrorMessage,
  setDraftDocumentContentJson,
  documentItem,
  documentItemField,
  viewer,
}: {
  setErrorMessage: (message: string) => void;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
  documentItem: DocumentItemImageType;
  documentItemField: string;
  viewer: AppContextViewerType;
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const uploadSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Image uploaded successfully!',
  });

  const {data, loading} = useQuery(ImageInput.query, {
    fetchPolicy: 'cache-and-network',
    variables: {fileId: _.toNumber(documentItem.input.metadata.fileId)},
  });

  const {form, handleSubmit} = useUploadFileForm({
    uploadFileForm: UploadFileForm.new({
      organizationId: viewer.viewingOrganization.id,
      creatorId: viewer.id,
    }),
    onSuccess: ({file}) => {
      uploadSuccessToast.handleToast();
      setDraftDocumentContentJson((documentContentJson: DocumentContentJsonType) => {
        _.set(documentContentJson, `${documentItemField}.input.metadata.fileId`, file.id);
        return {...documentContentJson};
      });
      setIsUploading(false);
    },
    onError: (errors) => {
      console.log({errors});
      handleResetImage({setDraftDocumentContentJson, documentItemField});
      setIsUploading(false);
    },
  });

  return (
    <FileDragInput
      isImagesOnly
      isUploading={isUploading || loading}
      handleRemove={() => handleResetImage({setDraftDocumentContentJson, documentItemField})}
      handleRemoveText={data?.file?.name}
      handleUploadFile={async (file: Blob) => {
        const imageFile = file as Blob & {name: string};

        if (!imageFile.type.startsWith('image/')) {
          return setErrorMessage(`This is not a valid image file type.`);
        }

        // Check size of file
        if (imageFile.size > MAX_FILE_BYTES) {
          return setErrorMessage('This exceeds the maximum file size of 10mb.');
        }

        // Check dimensions of image
        const reader = new FileReader();
        const cacheImageSize = getImageSizePromise({
          reader,
          setDraftDocumentContentJson,
          documentItemField,
        });
        reader.readAsDataURL(imageFile);
        await cacheImageSize;

        setErrorMessage('');
        setIsUploading(true);
        form.setFieldValue(`uploadFileForm.requestUploadFileForm.mimetype`, imageFile.type);
        form.setFieldValue(
          `uploadFileForm.requestUploadFileForm.filename`,
          UploadFileForm.formatName(imageFile.name),
        );
        form.setFieldValue(`uploadFileForm.file`, imageFile);
        setDraftDocumentContentJson((documentContentJson: DocumentContentJsonType) => {
          _.set(documentContentJson, `${documentItemField}.input.metadata.size`, imageFile.size);
          return {...documentContentJson};
        });
        handleSubmit();
      }}
    />
  );
};

const CustomSizeInputs = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemImageType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const {width, height} = documentItem.input;

  return (
    <Row>
      <Column style={{flex: 1}}>
        <FieldInput.LabelText>Width</FieldInput.LabelText>
        <Space height={4} />
        <FieldInput.TextInput
          placeholder={'Auto'}
          value={width}
          onBlur={() => {
            setDraftDocumentContentJson((documentContentJson) => {
              _.set(
                documentContentJson,
                `${documentItemField}.input.width`,
                handleSizeValue(width),
              );
              return {...documentContentJson};
            });
          }}
          onChangeText={(text: string) => {
            setDraftDocumentContentJson((documentContentJson) => {
              _.set(documentContentJson, `${documentItemField}.input.width`, text);
              _.set(documentContentJson, `${documentItemField}.input.height`, '');
              return {...documentContentJson};
            });
          }}
        />
      </Column>
      <Space width={16} />
      <Column style={{flex: 1}}>
        <FieldInput.LabelText>Height</FieldInput.LabelText>
        <Space height={4} />
        <FieldInput.TextInput
          placeholder={'Auto'}
          value={height}
          onBlur={() => {
            setDraftDocumentContentJson((documentContentJson) => {
              _.set(
                documentContentJson,
                `${documentItemField}.input.height`,
                handleSizeValue(height),
              );
              return {...documentContentJson};
            });
          }}
          onChangeText={(text: string) => {
            setDraftDocumentContentJson((documentContentJson) => {
              _.set(documentContentJson, `${documentItemField}.input.height`, text);
              _.set(documentContentJson, `${documentItemField}.input.width`, '');
              return {...documentContentJson};
            });
          }}
        />
      </Column>
    </Row>
  );
};

const ImageAlignment = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemImageType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  return (
    <ContextSwitcher
      contextDefinitions={[
        DocumentV2Image.POSITION.LEFT,
        DocumentV2Image.POSITION.CENTER,
        DocumentV2Image.POSITION.RIGHT,
      ].map((position) => ({
        iconSource: POSITION_TO_ICON[position],
        isSelected: documentItem.input.position === position,
        onPress: () => {
          setDraftDocumentContentJson((documentContentJson) => {
            _.set(documentContentJson, `${documentItemField}.input.position`, position);
            return {...documentContentJson};
          });
        },
      }))}
    />
  );
};

const ImageEditor = ({
  documentItem,
  documentItemField,
  setDraftDocumentContentJson,
}: {
  documentItem: DocumentItemImageType;
  documentItemField: string;
  setDraftDocumentContentJson: SetDocumentContentJsonType;
}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const {viewer} = useAppContext();
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {errorMessage && (
        <React.Fragment>
          <ErrorCallout text={errorMessage} />
          <Space height={12} />
        </React.Fragment>
      )}
      {viewer && (
        <ImageInput
          setErrorMessage={setErrorMessage}
          setDraftDocumentContentJson={setDraftDocumentContentJson}
          documentItem={documentItem}
          documentItemField={documentItemField}
          viewer={viewer}
        />
      )}
      <Space height={16} />
      <Row>
        <SectionLabel responsive={responsive}>Size (px)</SectionLabel>
        <Space width={8} />
        <TextTooltip
          isEnabledMobileToast={false}
          text={
            'Setting a height or width will automatically resize the other to maintain aspect ratio.'
          }
          placement={'top'}
        >
          <Column>
            <Icon source={Icon.CircleInfo} color={colors.gray.secondary} size={14} />
          </Column>
        </TextTooltip>
      </Row>
      <Space height={8} />
      <CustomSizeInputs
        documentItem={documentItem}
        documentItemField={documentItemField}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={16} />
      <SectionLabel responsive={responsive}>Position</SectionLabel>
      <Space height={8} />
      <ImageAlignment
        documentItem={documentItem}
        documentItemField={documentItemField}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
    </React.Fragment>
  );
};

ImageInput.query = gql`
  query ImageInput($fileId: Int) {
    ${gql.query}
    file (fileId: $fileId) {
      id
      name
    }
  }
`;

export default ImageEditor;
