// Libraries
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import ErrorCatcher from '@shared/modules/Error/components/ErrorCatcher';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';

const Container = Styled.View`
`;

const LoadingComponent = () => {
  return <SkeletonLoader isFullWidth height={200} />;
};

const BillingProjectBillsListV1QueryContent = ({projectUuid, isCurrentAggregateBill}: any) => {
  const {data, loading} = useQuery(BillingProjectBillsListV1Query.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
      isCurrentAggregateBill: !!isCurrentAggregateBill,
    },
  });

  return (
    <Loading loading={loading} as={LoadingComponent}>
      {() => {
        return (
          <BillingProjectBillsListV1
            project={data.project}
            aggregateBill={
              isCurrentAggregateBill
                ? data.project.currentAggregateBill
                : data.project.activeJobsAggregateBill
            }
          />
        );
      }}
    </Loading>
  );
};

const BillingProjectBillsListV1Query = ({projectUuid, isCurrentAggregateBill}: any) => {
  return (
    <ErrorCatcher
      ErrorComponent={() => (
        <Container style={{height: 200}}>
          <ErrorState.Centered title={'Could not load bills'} />
        </Container>
      )}
    >
      <BillingProjectBillsListV1QueryContent
        projectUuid={projectUuid}
        isCurrentAggregateBill={isCurrentAggregateBill}
      />
    </ErrorCatcher>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectBillsListV1Query.query = gql`
  ${BillingProjectBillsListV1.fragment}

  query BillingProjectBillsListV1Query($projectUuid: String!, $isCurrentAggregateBill: Boolean!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      currentAggregateBill @include(if: $isCurrentAggregateBill) {
        ...BillingProjectBillsListV1_AggregateBill
      }
      activeJobsAggregateBill @skip(if: $isCurrentAggregateBill) {
        ...BillingProjectBillsListV1_AggregateBill
      }
      ...BillingProjectBillsListV1
    }
  }
`;

export default BillingProjectBillsListV1Query;
