// Libraries
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover, useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import RemoveClaimModal from 'modules/Project/Claim/components/RemoveClaimModal';

const ClaimActionsMenu = ({popover, removeClaimModal}: any) => {
  const handleAction = (onPress: any) => () => {
    onPress();
    popover.handleClose();
  };
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.Bottom}
      width={160}
      offset={[-144, 4]}
    >
      <ActionMenuPopover.MenuItem onPress={handleAction(removeClaimModal.handleOpen)}>
        Delete claim
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const ClaimActionsButton = ({claim, refetch}: any) => {
  const claimActionsPopover = usePopover();
  const removeClaimModal = useModal({name: 'Remove Claim Modal'});
  const removeClaimSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Claim ${claim.identifier} removed`,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });
  return (
    <React.Fragment>
      <Popover.Content innerRef={claimActionsPopover.ref}>
        <TertiaryButton style={{paddingHorizontal: 8}} onPress={claimActionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
        </TertiaryButton>
      </Popover.Content>
      <ClaimActionsMenu popover={claimActionsPopover} removeClaimModal={removeClaimModal} />
      <RemoveClaimModal
        key={claim.id}
        claimId={claim.id}
        isOpen={removeClaimModal.isOpen}
        handleClose={removeClaimModal.handleClose}
        onSuccess={() => {
          removeClaimSuccessToast.handleToast();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimActionsButton.fragment = gql`
  fragment ClaimActionsButton on Claim {
    id
    identifier
  }
`;

export default ClaimActionsButton;
