// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled, Space} from '@supermove/components';
import {BulkUpdateBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useModal,
  useSidebar,
  useQuery,
  useState,
  useDebouncedCallback,
} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import useBulkUpdateShipmentsIsArchivedMutation from '@shared/modules/Dispatch/hooks/useBulkUpdateShipmentsIsArchivedMutation';
import BulkArchiveModal from 'modules/App/components/BulkArchiveModal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import LongDistanceDispatchShipmentsTable from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchShipmentsTable';
import ShipmentFiltersButton from 'modules/Dispatch/Shipment/components/ShipmentFiltersButton';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 16px;
`;

const LeftContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'rowIndex' does not exist on type 'ThemeP... Remove this comment to see the full error message
    rowIndex,
  }) => 100 - rowIndex};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const VerticalLine = Styled.View`
  width: 1px;
  height: 36px;
  background-color: ${colors.gray.border};
`;

const TabsContainer = Styled.View`
`;

const handleTabClicked = ({tabValue, navigator}: any) => {
  navigator.replace(`/dispatch/long-distance/shipments/${tabValue.toLowerCase()}?page=1`);
};

const getActions = ({
  params,
  selectedShipments,
  createTripWithShipmentsModal,
  bulkArchiveShipmentsModal,
  handleOpenSidebar,
  form,
}: any) => {
  const isUnplannedTab = params.subTab === ShipmentStatus.UNPLANNED.toLowerCase();
  const isShipmentSelected = !_.isEmpty(selectedShipments);

  return [
    {
      text: 'Create Trip',
      onPress: () => createTripWithShipmentsModal.handleOpen(),
    },
    {
      text: 'Assign to Trip',
      isDisabled: !isShipmentSelected,
      onPress: () => handleOpenSidebar(),
    },
    ...(isUnplannedTab
      ? [
          {
            text: 'Archive Shipments',
            isDisabled: !isShipmentSelected,
            onPress: () => {
              form.setFieldValue('bulkUpdateBooleanPropertyForm.ids', _.keys(selectedShipments));
              bulkArchiveShipmentsModal.handleOpen();
            },
          },
        ]
      : []),
  ];
};

const TabHeader = ({organization, activeTabValue, navigator}: any) => {
  const {longDistanceShipmentCountsByStatus} = organization;
  const tabDefinitions = [
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.UNPLANNED).label,
      count: longDistanceShipmentCountsByStatus.unplannedCount,
      value: ShipmentStatus.UNPLANNED,
    },
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.PLANNED).label,
      count: longDistanceShipmentCountsByStatus.bookedCount,
      value: ShipmentStatus.PLANNED,
    },
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.IN_TRANSIT).label,
      count: longDistanceShipmentCountsByStatus.inTransitCount,
      value: ShipmentStatus.IN_TRANSIT,
    },
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.DELIVERED).label,
      count: longDistanceShipmentCountsByStatus.deliveredCount,
      value: ShipmentStatus.DELIVERED,
    },
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.ACTIVE).label,
      count: longDistanceShipmentCountsByStatus.activeCount,
      value: ShipmentStatus.ACTIVE,
    },
    {
      label: ShipmentStatus.getStatusOption(ShipmentStatus.CANCELLED).label,
      count: longDistanceShipmentCountsByStatus.cancelledCount,
      value: ShipmentStatus.CANCELLED,
    },
  ];
  const activeTabIndex = getTabIndex({tabDefinitions, tabValue: activeTabValue});

  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({value}) => handleTabClicked({tabValue: value, navigator})}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const ShipmentsTabActionMenu = ({
  selectedShipments,
  setSelectedShipments,
  handleOpenSidebar,
  createTripWithShipmentsModal,
  refetch,
}: any) => {
  const {params} = useNavigationDOM();
  const bulkArchiveShipmentsModal = useModal({name: 'Bulk Archive Shipments Modal'});
  const bulkUpdateBooleanPropertyForm = BulkUpdateBooleanPropertyForm.new({
    ids: [],
    booleanValue: true,
  });
  const {form, handleSubmit} = useBulkUpdateShipmentsIsArchivedMutation({
    bulkUpdateBooleanPropertyForm,
    onSuccess: () => {
      bulkArchiveShipmentsModal.handleClose();
      setSelectedShipments({});
      refetch();
    },
    onError: (error: any) => console.log({error}),
  });

  return (
    <React.Fragment>
      <DropdownButton
        text={'Actions'}
        menuWidth={200}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
        actions={getActions({
          params,
          form,
          selectedShipments,
          createTripWithShipmentsModal,
          bulkArchiveShipmentsModal,
          handleOpenSidebar,
        })}
      />
      <BulkArchiveModal
        title={'Archive shipments?'}
        subtitle={'Are you sure you want to archive all the selected shipments?'}
        submitText={'Archive shipments'}
        isOpen={bulkArchiveShipmentsModal.isOpen}
        handleClose={bulkArchiveShipmentsModal.handleClose}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
};

const getTabIndex = ({tabDefinitions, tabValue}: any) => {
  return _.findIndex(
    tabDefinitions,
    (definition) => (definition as any).value.toLowerCase() === tabValue.toLowerCase(),
  );
};

const LongDistanceDispatchShipmentsTabContent = () => {
  const {data, loading, refetch} = useQuery(LongDistanceDispatchShipmentsTabContent.query, {
    fetchPolicy: 'cache-and-network',
  });
  const {params, navigator} = useNavigationDOM();
  const createTripWithShipmentsModal = useModal({
    name: 'Create Trip Modal',
    enableTracking: true,
  });
  const assignShipmentsToTripSidebar = useSidebar({
    name: 'AssignShipmentsToTripSidebar',
    enableTracking: true,
  });
  const [selectedShipments, setSelectedShipments] = useState({});
  const handleChangeSearch = useDebouncedCallback((text) => {
    navigator.replace(`/dispatch/long-distance/shipments/${params.subTab}?page=1&query=${text}`);
  }, 500);

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <Container>
          <Space height={16} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <FiltersContainers rowIndex={0}>
            <LeftContainer>
              <SearchBar
                onChangeText={handleChangeSearch}
                placeholder='Search by shipment details'
                style={{width: '348px'}}
                defaultValue={params.query}
              />
              <Space width={8} />
              <ShipmentFiltersButton style={{height: 36}} />
            </LeftContainer>
            <RightContainer>
              <SelectedItemsText>{`${_.size(selectedShipments)} ${pluralize(
                'shipment',
                _.size(selectedShipments),
              )} selected`}</SelectedItemsText>
              <Space width={8} />
              <VerticalLine />
              <Space width={8} />
              <ShipmentsTabActionMenu
                selectedShipments={selectedShipments}
                setSelectedShipments={setSelectedShipments}
                handleOpenSidebar={assignShipmentsToTripSidebar.handleOpen}
                createTripWithShipmentsModal={createTripWithShipmentsModal}
                refetch={refetch}
              />
            </RightContainer>
          </FiltersContainers>
          <Space height={16} />
          <TabHeader
            organization={data.viewer.viewingOrganization}
            activeTabValue={params.subTab}
            navigator={navigator}
          />
          <LongDistanceDispatchShipmentsTable
            key={data.viewer.viewingOrganization.longDistanceShipmentCountsByStatus.activeCount}
            refetchTabData={refetch}
            createTripWithShipmentsModal={createTripWithShipmentsModal}
            setSelectedShipments={setSelectedShipments}
            selectedShipments={selectedShipments}
            assignShipmentsToTripSidebar={assignShipmentsToTripSidebar}
          />
        </Container>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LongDistanceDispatchShipmentsTabContent.query = gql`
  query LongDistanceDispatchShipmentsTabContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        longDistanceShipmentCountsByStatus{
          activeCount
          unplannedCount
          bookedCount
          inTransitCount
          deliveredCount
          cancelledCount
        }
      }
    }
  }
`;

export default LongDistanceDispatchShipmentsTabContent;
