/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';

const SaveCollectionChangesModal = ({
  isOpen,
  handleClose,
  handleSubmit,
  submitting,
  onSuccess,
  roomName,
}: any) => {
  return (
    <ActionModal
      isOpen={isOpen}
      icon={Icon.ExclamationTriangle}
      color={colors.orange.status}
      title={'Save changes?'}
      message={`Would you like to save your changes for the items in ${roomName}?`}
      primaryActionText={'Save changes'}
      handlePrimaryAction={() => {
        handleSubmit().then(
          ({
            data: {
              response: {errors},
            },
          }: any) => {
            if (!errors) {
              return onSuccess();
            }
            handleClose();
          },
        );
      }}
      secondaryActionText={'Discard changes'}
      handleSecondaryAction={onSuccess}
      handlePressOutside={handleClose}
      isSubmitting={submitting}
    />
  );
};

export default SaveCollectionChangesModal;
