// Libraries
import React from 'react';

// Supermove
import {Styled, Popover, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AssignDriverPopoverContent from 'modules/Dispatch/LongDistance/components/AssignDriverPopoverContent';

const PopoverContainer = Styled.View`
  width: 400px;
  height: 376px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
`;

const AssignDriverPopover = ({assignDriverAndTrucksPopover, form, handleSubmit}: any) => {
  const {data, loading} = useQuery(AssignDriverPopover.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      handleOpen={assignDriverAndTrucksPopover.handleOpen}
      // @ts-expect-error TS(2322): Type '{ children: Element; placement: string; hand... Remove this comment to see the full error message
      style={{padding: 0}}
      isOpen={assignDriverAndTrucksPopover.isOpen}
      handleClose={assignDriverAndTrucksPopover.handleClose}
      reference={assignDriverAndTrucksPopover.ref}
    >
      <PopoverContainer>
        <Loading loading={loading} as={PageLoadingIndicator}>
          {() => (
            <AssignDriverPopoverContent
              assignDriverAndTrucksPopover={assignDriverAndTrucksPopover}
              form={form}
              activeDrivers={data.viewer.viewingOrganization.activeDrivers}
              handleSubmit={handleSubmit}
            />
          )}
        </Loading>
      </PopoverContainer>
    </Popover>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignDriverPopover.query = gql`
  ${AssignDriverPopoverContent.fragment}
  query AssignDriverPopover {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...AssignDriverPopoverContent
      }
    }
  }
`;

export default AssignDriverPopover;
