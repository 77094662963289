// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, PreventPropagation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDrawer,
  useNavigationDOM,
  useHover,
  useResponsive,
  useToast,
  useModal,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime, pluralize} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import Table, {TableComponents} from '@shared/design/components/Table';
import TableV2Deprecated from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import ClaimActionsButton from 'modules/Project/Claim/components/ClaimActionsButton';
import EditProjectClaimDrawer from 'modules/Project/Claim/components/EditProjectClaimDrawer';
import RemoveClaimModal from 'modules/Project/Claim/components/RemoveClaimModal';
import ProjectClientCommunicationButtons from 'modules/Project/components/ProjectClientCommunicationButtons';

const Container = Styled.View`
`;

const Cell = Styled.View`
  flex: 1;
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const PrimaryText = Styled.Text`
  ${Typography.Body}
`;

const SecondaryText = Styled.Text`
  ${Typography.Body};
  color: ${colors.gray.secondary}
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ClaimTypeColor = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const handleCheckAllClaims = ({setSelectedClaims, isSelectedAllClaims, allClaimIds}: any) => {
  if (isSelectedAllClaims) {
    return setSelectedClaims([]);
  }
  return setSelectedClaims(allClaimIds);
};

const handleCheckClaim = ({selectedClaims, setSelectedClaims, isSelected, claimId}: any) => {
  if (isSelected) {
    const claimIdsToKeep = _.difference(selectedClaims, [claimId]);
    return setSelectedClaims(claimIdsToKeep);
  }
  return setSelectedClaims([...selectedClaims, claimId]);
};

const BulkEditCheckbox = ({isChecked, onPress, style}: any) => {
  return <Checkbox isChecked={isChecked} handleToggle={onPress} style={style} />;
};

const SelectAllCheckbox = ({claims, selectedClaims, setSelectedClaims}: any) => {
  const allClaimIds = claims.map((claim: any) => claim.id);
  const isSelectedAllClaims =
    allClaimIds.length === selectedClaims.length && allClaimIds.length !== 0;
  return (
    <BulkEditCheckbox
      isChecked={isSelectedAllClaims}
      onPress={() =>
        handleCheckAllClaims({
          setSelectedClaims,
          isSelectedAllClaims,
          allClaimIds,
        })
      }
      style={{paddingRight: 8}}
    />
  );
};

const SelectClaimCheckbox = ({claim, selectedClaims, setSelectedClaims}: any) => {
  const isSelected = _.includes(selectedClaims, claim.id);
  return (
    <BulkEditCheckbox
      isChecked={isSelected}
      onPress={() =>
        handleCheckClaim({
          selectedClaims,
          setSelectedClaims,
          isSelected,
          claimId: claim.id,
        })
      }
      style={{paddingVertical: 8, paddingRight: 8}}
    />
  );
};

// /////////////////////////////////////////////////////////////////////////////
// FEATURE FLAG REMOVAL: CLAIMS_ASSIGN_MOVERS
const ClaimIdentifierButtonDeprecated = ({claim, project, refetch}: any) => {
  const viewClaimDrawer = useDrawer({name: 'Edit Claim Drawer'});
  return (
    <TableV2Deprecated.PreventPropagationContainer>
      <TertiaryButton onPress={viewClaimDrawer.handleOpen}>
        <TableV2Deprecated.CellLink>{claim.identifier}</TableV2Deprecated.CellLink>
      </TertiaryButton>
      <EditProjectClaimDrawer
        key={viewClaimDrawer.key}
        claim={claim}
        projectUuid={project.uuid}
        isOpen={viewClaimDrawer.isOpen}
        handleClose={viewClaimDrawer.handleClose}
        onSuccess={() => {
          viewClaimDrawer.handleClose();
          refetch();
        }}
        isViewMode
      />
    </TableV2Deprecated.PreventPropagationContainer>
  );
};

const ClaimProjectButtonDeprecated = ({claim}: any) => {
  const {project} = claim;
  const {navigator} = useNavigationDOM();
  const {ref, isHovered} = useHover();
  const projectUrl =
    project.projectType.category === ProjectTypeCategory.STORAGE
      ? `/storage/projects/${project.uuid}`
      : `/projects/${project.uuid}`;
  const openProject = () => navigator.pushNewTab(projectUrl);

  return (
    <Cell>
      <Row>
        <TertiaryButton onPress={openProject}>
          {isHovered && (
            <Icon
              source={Icon.ExternalLinkAlt}
              size={11}
              color={colors.blue.interactive}
              style={{position: 'absolute', left: -16}}
            />
          )}
          <TableV2Deprecated.CellLink
            ref={ref}
          >{`Project ${project.identifier}`}</TableV2Deprecated.CellLink>
        </TertiaryButton>
      </Row>
      <SecondaryText numberOfLines={1}>{_.get(project, 'client.name')}</SecondaryText>
    </Cell>
  );
};

const getColumnDefinitionsDeprecated = ({
  claims,
  selectedClaims,
  setSelectedClaims,
  refetch,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  return [
    // Checkbox
    {
      width: 48,
      headerContent: () => {
        return (
          <SelectAllCheckbox
            claims={claims}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <SelectClaimCheckbox
            claim={claim}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
    },
    // Project Details
    {
      flex: 3,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText numberOfLines={1}>Project</TableV2Deprecated.HeaderText>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return <ClaimProjectButtonDeprecated claim={claim} />;
      },
    },
    // Claim ID
    {
      flex: 1,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText numberOfLines={1}>Claim ID</TableV2Deprecated.HeaderText>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <ClaimIdentifierButtonDeprecated
            claim={claim}
            project={claim.project}
            refetch={refetch}
          />
        );
      },
    },
    // Claim Type
    {
      flex: 2,
      headerContent: () => {
        return <TableV2Deprecated.HeaderText numberOfLines={1}>Type</TableV2Deprecated.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        const {color, name} = claim.claimType;
        return (
          <Row style={{flex: 1}}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <ClaimTypeColor color={color} />
            <Space width={8} />
            <TableV2Deprecated.CellText numberOfLines={1}>{name}</TableV2Deprecated.CellText>
          </Row>
        );
      },
    },
    // Claim Amount
    {
      flex: 1,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText numberOfLines={1}>Amount</TableV2Deprecated.HeaderText>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <TableV2Deprecated.CellText>
            {_.isNil(claim.amount) ? 'TBD' : Currency.display(claim.amount)}
          </TableV2Deprecated.CellText>
        );
      },
    },
    // Project Type
    {
      flex: 2,
      isHidden: !isEnabledClaimsProjectOrganizationMigration,
      headerContent: () => {
        return (
          <Cell>
            <TableV2Deprecated.HeaderText numberOfLines={1}>
              Project Type
            </TableV2Deprecated.HeaderText>
            {organization.isPrimary && organization.hasMultipleOrganizations && (
              <SecondaryText numberOfLines={1}>Branch</SecondaryText>
            )}
          </Cell>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        const {project} = claim;
        const {projectType} = project;
        return (
          <Cell>
            <PrimaryText numberOfLines={1}>
              <ProjectTypeColorIndicator color={projectType.color}>•</ProjectTypeColorIndicator>
              <Space width={7} />
              {projectType.name}
            </PrimaryText>
            {organization.isPrimary && organization.hasMultipleOrganizations && (
              <SecondaryText numberOfLines={1}>
                <Space width={16} />
                {project.owningOrganization.name}
              </SecondaryText>
            )}
          </Cell>
        );
      },
    },
    // Claim Notes
    {
      flex: 4,
      headerContent: () => {
        return <TableV2Deprecated.HeaderText numberOfLines={1}>Notes</TableV2Deprecated.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <TableV2Deprecated.CellText numberOfLines={2}>{claim.notes}</TableV2Deprecated.CellText>
        );
      },
    },
    // Last Action
    {
      flex: 4,
      headerContent: () => {
        return (
          <TableV2Deprecated.HeaderText numberOfLines={1}>Last Action</TableV2Deprecated.HeaderText>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <Container style={{flex: 1}}>
            <TableV2Deprecated.CellText numberOfLines={1}>
              {claim.isEdited ? 'Claim updated' : 'Claim created'}
            </TableV2Deprecated.CellText>
            <Space height={2} />
            <TableV2Deprecated.CellText style={{color: colors.gray.secondary}} numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(claim.updatedAt, 'MM/DD/YY, h:mm A')}
              {' by '}
              {claim.updatedBy.fullName}
            </TableV2Deprecated.CellText>
          </Container>
        );
      },
    },
    // Contact
    {
      width: 88,
      headerContent: () => {
        return <HeaderText>Contact</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <TableV2Deprecated.PreventPropagationContainer>
            <ProjectClientCommunicationButtons
              project={claim.project}
              refetch={refetch}
              iconSize={16}
              spaceWidth={16}
            />
          </TableV2Deprecated.PreventPropagationContainer>
        );
      },
    },
    // Actions
    {
      width: 80,
      headerContent: () => {
        return null;
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <TableV2Deprecated.PreventPropagationContainer style={{flexDirection: 'row'}}>
            <ClaimActionsButton claim={claim} refetch={refetch} />
          </TableV2Deprecated.PreventPropagationContainer>
        );
      },
    },
  ];
};
// FEATURE FLAG REMOVAL BLOCK END
// /////////////////////////////////////////////////////////////////////////////

const ClaimProjectButton = ({claim, responsive}: any) => {
  const {project} = claim;
  const {navigator} = useNavigationDOM();
  const {ref, isHovered} = useHover();
  const projectUrl =
    project.projectType.category === ProjectTypeCategory.STORAGE
      ? `/storage/projects/${project.uuid}`
      : `/projects/${project.uuid}`;
  const openProject = () => navigator.pushNewTab(projectUrl);

  return (
    <React.Fragment>
      {isHovered && (
        <Icon
          source={Icon.ExternalLinkAlt}
          size={11}
          color={colors.blue.interactive}
          style={{position: 'absolute', left: -16}}
        />
      )}
      <TableComponents.LinkText
        ref={ref}
        responsive={responsive}
        numberOfLines={1}
        onPress={openProject}
      >{`Project ${project.identifier}`}</TableComponents.LinkText>
    </React.Fragment>
  );
};

const DeleteClaim = ({claim, refetch, isOpen, handleClose, hookKey}: any) => {
  const removeClaimSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Claim ${claim.identifier} deleted`,
    isCloseable: true,
  });
  return (
    <RemoveClaimModal
      key={hookKey}
      claimId={claim.id}
      isOpen={isOpen}
      handleClose={handleClose}
      onSuccess={() => {
        removeClaimSuccessToast.handleToast();
        refetch();
      }}
    />
  );
};

const getColumnDefinitions = ({
  claims,
  selectedClaims,
  setSelectedClaims,
  refetch,
  organization,
  isEnabledClaimsProjectOrganizationMigration,
  responsive,
}: any) => {
  return [
    // Checkbox
    {
      width: 48,
      headerComponent: () => {
        return (
          <SelectAllCheckbox
            claims={claims}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
      cellComponent: (claim: any) => {
        return (
          <SelectClaimCheckbox
            claim={claim}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
          />
        );
      },
      mobileOptions: {
        isInHeader: true,
        rank: 0,
      },
    },
    // Project Details
    {
      flex: 1,
      headerLabel: 'Project',
      cellComponent: (claim: any) => {
        return <ClaimProjectButton claim={claim} responsive={responsive} />;
      },
      secondary: {
        cellText: (claim: any) => _.get(claim.project, 'client.name'),
      },
      mobileOptions: {
        isInHeader: true,
        rank: 1,
      },
    },
    // Claim ID and Type
    {
      flex: 1,
      headerLabel: 'Claim ID',
      cellText: (claim: any) => `Claim ${claim.identifier}`,
      secondary: {
        headerLabel: 'Type',
        // @ts-expect-error TS(2769): No overload matches this call.
        cellComponent: (claim: any) => <ClaimTypeColor color={claim.claimType.color} />,
        cellText: (claim: any) => claim.claimType.name,
      },
      mobileOptions: {
        isInHeader: true,
        rank: 2,
      },
    },
    // Claim Amounts
    // TODO(Hammad): Remove the extra code once we add support for labels in mobile card headers
    {
      flex: 1,
      headerLabel: 'Actual Amount',
      cellText: (claim: any) => {
        const amountText = _.isNil(claim.amount) ? null : Currency.display(claim.amount);
        if (responsive.desktop) {
          return amountText;
        } else {
          return _.isNil(amountText) ? null : `Actual ${amountText}`;
        }
      },
      emptyText: responsive.desktop ? 'TBD' : 'Actual TBD',
      secondary: {
        headerLabel: 'Estimated Amount',
        cellText: (claim: any) => {
          const estimatedAmountText = _.isNil(claim.estimatedAmount)
            ? null
            : Currency.display(claim.estimatedAmount);
          if (responsive.desktop) {
            return estimatedAmountText;
          } else {
            return _.isNil(estimatedAmountText) ? null : `Estimated ${estimatedAmountText}`;
          }
        },
        emptyText: responsive.desktop ? 'None' : 'Estimated None',
      },
      mobileOptions: {
        isInHeader: true,
        rank: 3,
      },
    },
    // Claim Job and Dovers
    {
      flex: 1,
      headerLabel: 'Job',
      cellText: (claim: any) => claim.job?.fullName,
      secondary: {
        headerLabel: 'Movers',
        cellComponent: (claim: any) => {
          const moversText = _.isEmpty(claim.activeUsers)
            ? null
            : pluralize('movers', claim.activeUsers.length, true);
          const moversTooltip = claim.activeUsers.map((user: any) => user.fullName).join(', ');

          return moversText ? (
            <TextTooltip
              text={moversTooltip}
              placement='top'
              numberOfLines={1}
              style={{maxWidth: 200}}
              isEnabledMobileToast={false}
            >
              <Container>
                <TableComponents.SecondaryText responsive={responsive} numberOfLines={1}>
                  {moversText}
                </TableComponents.SecondaryText>
              </Container>
            </TextTooltip>
          ) : null;
        },
      },
    },
    // Created and Closed at
    {
      flex: 1,
      headerLabel: 'Created At',
      cellText: (claim: any) =>
        claim.createdAt
          ? Datetime.convertToDisplayDate(claim.createdAt, Datetime.DISPLAY_SHORT_DATE)
          : null,
      secondary: {
        headerLabel: 'Closed At',
        cellText: (claim: any) =>
          claim.closedDate
            ? Datetime.convertToDisplayDate(claim.closedDate, Datetime.DISPLAY_SHORT_DATE)
            : null,
        emptyText: 'TBD',
      },
    },
    // Project Type
    {
      flex: 1,
      isHidden: !isEnabledClaimsProjectOrganizationMigration,
      headerLabel: 'Project Type',
      cellComponent: (claim: any) => {
        const {project} = claim;
        const {projectType} = project;
        return (
          <Cell>
            <TableComponents.PrimaryText responsive={responsive} numberOfLines={1}>
              <ProjectTypeColorIndicator color={projectType.color}>•</ProjectTypeColorIndicator>
              <Space width={8} />
              {projectType.name}
            </TableComponents.PrimaryText>
          </Cell>
        );
      },
      secondary: {
        isHidden: !(organization.isPrimary && organization.hasMultipleOrganizations),
        headerLabel: 'Branch',
        cellText: (claim: any) => claim.project.owningOrganization.name,
      },
    },
    // Claim Notes
    {
      flex: 1,
      headerLabel: 'Notes',
      numberOfLines: 2,
      cellText: (claim: any) => claim.notes,
    },
    // Last Action
    {
      flex: 1,
      headerLabel: 'Last Action',
      cellText: (claim: any) => (claim.isEdited ? 'Claim updated' : 'Claim created'),
      secondary: {
        cellText: (claim: any) =>
          `${Datetime.convertToDisplayDatetime(claim.updatedAt, 'MM/DD/YY, h:mm A')} by ${claim.updatedBy.fullName}`,
      },
    },
    // Contact
    {
      width: 88,
      headerLabel: 'Contact',
      cellComponent: (claim: any) => {
        return (
          <PreventPropagation>
            <ProjectClientCommunicationButtons
              project={claim.project}
              refetch={refetch}
              iconSize={16}
              spaceWidth={16}
            />
          </PreventPropagation>
        );
      },
    },
    // Actions
    {
      width: 88,
      headerContent: () => {
        return <HeaderText>Contact</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'claim' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: claim}) => {
        return (
          <PreventPropagation>
            <ProjectClientCommunicationButtons
              project={claim.project}
              refetch={refetch}
              iconSize={16}
              spaceWidth={16}
            />
          </PreventPropagation>
        );
      },
    },
    // Actions
    {
      headerLabel: 'Actions',
      minWidth: 80,
      actions: (claim: any) => [
        {
          text: 'Edit Claim',
          onPress: ({handleOpen}: any) => handleOpen(),
          desktopIcon: Icon.Pen,
          actionHook: {
            hook: useDrawer,
            hookArgument: {
              name: 'Edit Claim Drawer',
            },
            renderComponent: ({isOpen, handleClose, hookKey}: any) => {
              return (
                <EditProjectClaimDrawer
                  key={hookKey}
                  claim={claim}
                  projectUuid={claim.project.uuid}
                  isOpen={isOpen}
                  handleClose={handleClose}
                  onSuccess={() => {
                    handleClose();
                    refetch();
                  }}
                />
              );
            },
          },
        },
        {
          text: 'Delete Claim',
          onPress: ({handleOpen}: any) => handleOpen(),
          actionHook: {
            hook: useModal,
            hookArgument: {
              name: 'Delete Claim Modal',
            },
            renderComponent: ({isOpen, handleClose, hookKey}: any) => (
              <DeleteClaim
                claim={claim}
                refetch={refetch}
                isOpen={isOpen}
                handleClose={handleClose}
                hookKey={hookKey}
              />
            ),
          },
        },
      ],
    },
  ];
};

const RowHookComponent = ({isOpen, handleClose, item, refetch}: any) => {
  return (
    <EditProjectClaimDrawer
      key={isOpen}
      claim={item}
      projectUuid={item.project.uuid}
      isOpen={isOpen}
      handleClose={handleClose}
      onSuccess={() => {
        handleClose();
        refetch();
      }}
      isViewMode
    />
  );
};

const ClaimsList = ({
  claims,
  loading,
  refetch,
  hasError,
  selectedClaims,
  setSelectedClaims,
  organization,
}: any) => {
  const {isEnabledClaimsProjectOrganizationMigration, isEnabledClaimsAssignMovers} =
    organization.features;
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {isEnabledClaimsAssignMovers ? (
        <Table
          columnDefinitions={getColumnDefinitions({
            claims,
            selectedClaims,
            setSelectedClaims,
            refetch,
            organization,
            isEnabledClaimsProjectOrganizationMigration,
            responsive,
          })}
          items={claims}
          itemKey={'id'}
          // @ts-expect-error
          loading={loading}
          emptyStateText={'No claims to display'}
          isScrollable
          scrollViewStyle={{paddingBottom: 24}}
          containerStyle={{overflow: 'visible'}}
          hasError={hasError}
          // @ts-expect-error
          onRowPress={(item, {handleOpen}) => handleOpen()}
          rowHook={{
            hook: useDrawer,
            hookArgument: {name: 'Edit Claim Drawer'},
            renderComponent: ({isOpen, handleClose, hookKey, item: claim}) => (
              <EditProjectClaimDrawer
                key={hookKey}
                claim={claim}
                projectUuid={(claim as any).project.uuid}
                isOpen={isOpen}
                handleClose={handleClose}
                onSuccess={() => {
                  handleClose();
                  refetch();
                }}
                isViewMode
              />
            ),
          }}
        />
      ) : (
        <TableV2Deprecated.FixedHeaderScroll
          columnDefinitions={getColumnDefinitionsDeprecated({
            claims,
            selectedClaims,
            setSelectedClaims,
            refetch,
            organization,
            isEnabledClaimsProjectOrganizationMigration,
          })}
          items={claims}
          loading={loading}
          emptyStateText={'No claims to display'}
          isClickable
          scrollViewStyle={{paddingBottom: 24}}
          containerStyle={{overflow: 'visible'}}
          hasError={hasError}
          rowHook={{
            hook: useDrawer,
            hookArgument: {name: 'Edit Claim Drawer'},
            Component: RowHookComponent,
            componentProps: {refetch},
          }}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimsList.fragment = gql`
  ${ClaimActionsButton.fragment}
  ${EditProjectClaimDrawer.fragment}
  ${ProjectClientCommunicationButtons.fragment}
  fragment ClaimsList on Claim {
    id
    isEdited
    identifier
    amount
    estimatedAmount
    notes
    updatedAt
    createdAt
    closedDate
    claimType {
      id
      name
      color
    }
    updatedBy {
      id
      fullName
    }
    job {
      id
      fullName
    }
    activeUsers {
      id
      fullName
    }
    project {
      id
      uuid
      identifier
      owningOrganization {
        id
        name
      }
      projectType {
        id
        name
        category
      }
      client {
        id
        name
      }
      ...ProjectClientCommunicationButtons
    }
    ...ClaimActionsButton
    ...EditProjectClaimDrawer
  }

  fragment ClaimsList_viewer on User {
    id
    viewingOrganization {
      id
      slug
      isPrimary
      hasMultipleOrganizations
      features {
        isEnabledClaimsProjectOrganizationMigration: isEnabled(
          feature: "CLAIMS_PROJECT_ORGANIZATION_MIGRATION"
        )
        isEnabledClaimsAssignMovers: isEnabled(feature: "CLAIMS_ASSIGN_MOVERS")
      }
    }
  }
`;

export default ClaimsList;
