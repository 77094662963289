// Libraries
import _ from 'lodash';

// Supremove
import {gql} from '@supermove/graphql';
import {UserFlowStepModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type UserFlowStepFormToFormType = {
  userFlowStepId: string | undefined;
  userFlowId: string | undefined;
  buttonText: string;
  isDefault: boolean;
  documentTemplateId?: string;
};

const _new = ({userFlowId}: {userFlowId: string | undefined}): UserFlowStepFormToFormType => ({
  userFlowStepId: undefined,
  userFlowId,
  buttonText: '',
  isDefault: false,
  documentTemplateId: undefined,
});

const edit = withFragment(
  (userFlowStep: UserFlowStepModel) => {
    return {
      userFlowStepId: userFlowStep.id,
      userFlowId: userFlowStep.userFlowId,
      buttonText: userFlowStep.buttonText,
      isDefault: userFlowStep.isDefault,
      documentTemplateId: _.toString(userFlowStep.documentTemplateId),
    };
  },
  gql`
    fragment UserFlowStepForm_edit on UserFlowStep {
      id
      uuid
      userFlowId
      buttonText
      isDefault
      documentTemplateId
    }
  `,
);

const toForm = (userFlowStep: UserFlowStepFormToFormType) => {
  return {
    userFlowStepId: userFlowStep.userFlowStepId,
    userFlowId: userFlowStep.userFlowId,
    buttonText: userFlowStep.buttonText,
    isDefault: userFlowStep.isDefault,
    documentTemplateId: userFlowStep.documentTemplateId,
  };
};

const toMutation = (userFlowStepForm: ReturnType<typeof toForm>) => {
  return {
    userFlowId: userFlowStepForm.userFlowId,
    buttonText: userFlowStepForm.buttonText,
    isDefault: userFlowStepForm.isDefault,
    documentTemplateId: userFlowStepForm.documentTemplateId,
  };
};

const validate = ({
  userFlowStepForm,
  field,
}: {
  userFlowStepForm: UserFlowStepFormToFormType;
  field: string;
}) => {
  const errors = {};
  if (!userFlowStepForm.buttonText) {
    _.set(errors, `${field}.buttonText`, 'Please enter a button text');
  }
  if (!userFlowStepForm.documentTemplateId) {
    _.set(errors, `${field}.documentTemplateId`, 'Please select a document template');
  }
  return errors;
};

const UserFlowStepForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
  validate,
};

export default UserFlowStepForm;
