// Libraries
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useResponsive, useToggle} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import UpdateProjectTypePaymentMethodsForm from '@shared/modules/PaymentMethod/forms/UpdateProjectTypePaymentMethodsForm';
import useUpdateProjectTypePaymentMethodsMutation from '@shared/modules/PaymentMethod/hooks/useUpdateProjectTypePaymentMethodsMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import PaymentMethodBadges from 'modules/Organization/Settings/Company/components/PaymentMethodBadges';
import ProjectTypePaymentMethodFields from 'modules/Organization/Settings/Company/components/ProjectTypePaymentMethodFields';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const View = Styled.View`
  flex: 1;
`;

const PageContentContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading};
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const PaymentMethodSection = ({
  projectType,
  refetch,
}: {
  projectType: ProjectTypeModel;
  refetch: () => void;
}) => {
  const editPaymentMethodsToggle = useToggle();
  const updateProjectTypePaymentMethodsForm = UpdateProjectTypePaymentMethodsForm.edit(projectType);
  const {form, handleSubmit, submitting} = useUpdateProjectTypePaymentMethodsMutation({
    updateProjectTypePaymentMethodsForm,
    onSuccess: () => {
      editPaymentMethodsToggle.handleToggleOff();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });
  const field = 'updateProjectTypePaymentMethodsForm.projectTypePaymentMethodForms';

  return (
    <EditPanel
      index={0}
      BodyComponent={PaymentMethodBadges}
      bodyComponentProps={{paymentMethods: projectType.paymentMethods}}
      EditBodyComponent={ProjectTypePaymentMethodFields}
      editBodyComponentProps={{form, field, organization: projectType.organization}}
      title={'Payment Methods'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editPaymentMethodsToggle.isOn}
      handleEdit={editPaymentMethodsToggle.handleToggleOn}
      handleClose={editPaymentMethodsToggle.handleToggleOff}
    />
  );
};

const ProjectTypePaymentSettingsContent = ({projectTypeUuid}: {projectTypeUuid: string}) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(ProjectTypePaymentSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  return (
    <View>
      <ProjectTypeSettingsPageHeader projectType={data.projectTypeByUuid} />
      <PageContentContainer style={{paddingHorizontal: responsive.desktop ? 24 : 0}}>
        <ScrollView>
          <Space height={24} />
          <PageHeading responsive={responsive}>Payments</PageHeading>
          <Space height={16} />
          <PageDescription responsive={responsive}>
            Select the payment methods available on the Crew App for Local Move.
          </PageDescription>
          <Space height={16} />
          <PaymentMethodSection projectType={data.projectTypeByUuid} refetch={refetch} />
          <Space height={48} />
        </ScrollView>
      </PageContentContainer>
    </View>
  );
};

const ProjectTypePaymentSettingsPage = () => {
  const {params} = useNavigationDOM();

  return (
    <SidebarPageV2 selected={'settings'}>
      <ProjectTypePaymentSettingsContent projectTypeUuid={params.projectTypeUuid} />
    </SidebarPageV2>
  );
};

ProjectTypePaymentSettingsPage.query = gql`
  ${PaymentMethodBadges.fragment}
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypePaymentMethodFields.fragment}
  ${UpdateProjectTypePaymentMethodsForm.edit.fragment}

  query ProjectTypePaymentSettingsPage($projectTypeUuid: String!) {
    ${gql.query}

    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      organization {
        id
        ...ProjectTypePaymentMethodFields
      }
      paymentMethods {
        id
        ...PaymentMethodBadges
      }
      ...ProjectTypeSettingsPageHeader
      ...UpdateProjectTypePaymentMethodsForm_edit
    }
  }
`;

export default ProjectTypePaymentSettingsPage;
