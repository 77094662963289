// Libraries
import _ from 'lodash';

// Supremove
import {gql} from '@supermove/graphql';
import {UserFlowModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {UserFlowExpirationUnitType} from '@shared/modules/UserFlows/enums/UserFlowExpirationUnitType';
import UserFlowStepForm, {
  UserFlowStepFormToFormType,
} from '@shared/modules/UserFlows/forms/UserFlowStepForm';

export type UserFlowFormToFormType = {
  userFlowId: string | undefined;
  name: string;
  description: string | undefined;
  expirationUnitType: UserFlowExpirationUnitType | undefined;
  expirationValue: number | undefined;
  shouldIncludePdfs: boolean;
  organizationId: string;
  defaultEmailTemplateId: string | undefined;
  projectTypeIds: string[];
  isDefault: boolean;
  userFlowStepForms: UserFlowStepFormToFormType[];
};

// A step can be created for new user flow (doesn't have an id) or for an existing user flow (with an id)
const _new = ({organizationId}: {organizationId: string}) => ({
  userFlowId: undefined,
  name: '',
  description: undefined,
  expirationUnitType: undefined,
  expirationValue: undefined,
  shouldIncludePdfs: false,
  organizationId,
  defaultEmailTemplateId: undefined,
  projectTypeIds: [],
  isDefault: false,
  userFlowStepForms: [],
});

const edit = withFragment(
  (userFlow: UserFlowModel) => {
    return {
      userFlowId: userFlow.id,
      name: userFlow.name,
      description: userFlow.description,
      expirationUnitType: userFlow.expirationUnitType,
      expirationValue: userFlow.expirationValue,
      shouldIncludePdfs: userFlow.shouldIncludePdfs,
      organizationId: userFlow.organizationId,
      defaultEmailTemplateId: _.toString(userFlow.defaultEmailTemplateId),
      projectTypeIds: userFlow.projectTypeUserFlows.map((projectTypeUserFlow) =>
        _.toString(projectTypeUserFlow.projectTypeId),
      ),
      isDefault: userFlow.isDefault,
      userFlowStepForms: userFlow.userFlowSteps.map(UserFlowStepForm.edit),
    };
  },
  gql`
    ${UserFlowStepForm.edit.fragment}
    fragment UserFlowForm_edit on UserFlow {
      id
      uuid
      name
      description
      expirationUnitType
      expirationValue
      shouldIncludePdfs
      isDefault
      organizationId
      defaultEmailTemplateId
      projectTypeUserFlows {
        id
        projectTypeId
      }
      userFlowSteps {
        id
        ...UserFlowStepForm_edit
      }
    }
  `,
);

const toForm = (userFlow: ReturnType<typeof edit> | ReturnType<typeof _new>) => {
  return {
    userFlowId: userFlow.userFlowId ? _.toNumber(userFlow.userFlowId) : undefined,
    name: userFlow.name,
    description: userFlow.description,
    expirationUnitType: userFlow.expirationUnitType,
    expirationValue: userFlow.expirationValue,
    shouldIncludePdfs: userFlow.shouldIncludePdfs,
    organizationId: userFlow.organizationId,
    defaultEmailTemplateId: userFlow.defaultEmailTemplateId,
    projectTypeIds: userFlow.projectTypeIds,
    isDefault: userFlow.isDefault,
    userFlowStepForms: userFlow.userFlowStepForms,
  };
};

const toMutation = (userFlowForm: ReturnType<typeof toForm>) => {
  return {
    userFlowId: userFlowForm.userFlowId,
    name: userFlowForm.name,
    description: userFlowForm.description,
    expirationUnitType: userFlowForm.expirationUnitType,
    expirationValue: userFlowForm.expirationValue
      ? _.toNumber(userFlowForm.expirationValue)
      : undefined,
    shouldIncludePdfs: userFlowForm.shouldIncludePdfs,
    organizationId: userFlowForm.organizationId,
    defaultEmailTemplateId: userFlowForm.defaultEmailTemplateId,
    projectTypeIds: userFlowForm.projectTypeIds,
    isDefault: userFlowForm.isDefault,
    userFlowStepForms: userFlowForm.userFlowStepForms.map(UserFlowStepForm.toMutation),
  };
};

const UserFlowForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default UserFlowForm;
