// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import ProjectTasks from 'modules/Job/components/ProjectTasks';

const Container = Styled.View`
`;

const Section = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 12px;
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Title = Styled.Text`
  ${Typography.Subheading}
`;

const AddTaskButtonContainer = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  padding: 8px;
`;

const AddTaskButtonIconContainer = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${colors.blue.interactive}
  align-items: center;
  justify-content: center;
`;

const AddTaskButton = ({onPress}: any) => {
  return (
    <AddTaskButtonContainer onPress={onPress}>
      <AddTaskButtonIconContainer>
        <Icon source={Icon.Plus} color={colors.white} size={11} />
      </AddTaskButtonIconContainer>
    </AddTaskButtonContainer>
  );
};

type JobProjectTasksBlockProps = {
  job: any;
};

const JobProjectTasksBlock = ({job}: JobProjectTasksBlockProps) => {
  const newTaskDrawer = useDrawer({
    name: 'New Task Drawer - Job Page',
    enableTracking: true,
  });

  return (
    <Container>
      <Section>
        <Space height={12} />
        <TitleRow>
          <Title>Project Tasks</Title>
          <Space style={{flex: 1}} />
          <AddTaskButton onPress={newTaskDrawer.handleOpen} />
        </TitleRow>
        <Space height={12} />
        <ProjectTasks projectUuid={job.project.uuid} newTaskDrawer={newTaskDrawer} />
        <Space height={16} />
      </Section>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectTasksBlock.fragment = gql`
  fragment JobProjectTasksBlock on Job {
    id
    project {
      id
      uuid
    }
  }
`;

export default JobProjectTasksBlock;
