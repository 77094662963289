// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

type OwnProps = {
  onClose?: (...args: any[]) => any;
  side?: (...args: any[]) => any;
  title?: (...args: any[]) => any;
  subtitle?: (...args: any[]) => any;
  right?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ReportMovePage.defaultProps;

// @ts-expect-error TS(7022): 'ReportMovePage' implicitly has type 'any' because... Remove this comment to see the full error message
const ReportMovePage = ({
  side,
  title,
  subtitle,
  right,
  variables,
  query,
  onClose,
  children,
}: Props) => (
  <ModalPage
    variables={variables}
    query={query}
    fetchPolicy={'network-only'}
    navigation={({loading, data, navigator, refetch}: any) => (
      <Navigation
        side={side}
        title={title({loading, data})}
        subtitle={subtitle({loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />
          ) : null
        }
        right={() => right({loading, data, navigator, refetch})}
      />
    )}
  >
    {(props: any) => (props.data.reportMove ? children(props) : null)}
  </ModalPage>
);

ReportMovePage.defaultProps = {
  onClose: undefined,

  // If we just have the close button, we can default to this width.
  side: ({responsive}: any) => (responsive.mobile ? 40 : 60),
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMovePage.fragment = gql`
  fragment ReportMovePage on ReportMove {
    id
  }
`;

export default ReportMovePage;
