// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput, Space, Styled} from '@supermove/components';
import {FilteredClientsForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App

import FieldInput from '@shared/design/components/Field/FieldInput';
import PopoverFilter from '@shared/modules/App/components/PopoverFilter';
import MultiBranchOrganizationField from 'modules/Organization/components/MultibranchOrganizationField';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const getSalespersonDropdownOptions = ({options, organization, description}: any) => {
  if (organization.features.isEnabledAllowEmptyBookedBy) {
    options.unshift({label: 'No salesperson', value: null, description});
  }
  return options;
};

const MultiBranchDropdownFilter = ({index, field, form, organization}: any) => {
  const organizations = _.get(organization, 'company.organizations', []);
  const {params} = useNavigationDOM();

  return (
    <MultiBranchOrganizationField
      label={'Branches'}
      placeholder={'Select branches'}
      width={'100%'}
      index={index}
      isPortaled={false}
      isResponsive
      value={params.slugs || []}
      organizations={organizations}
      disableAllOption
      onChangeValue={(newSlugs: any) => {
        form.setFieldValue(`${field}.slugs`, newSlugs);
      }}
    />
  );
};

const SalespeopleMultiselect = ({field, form, organization, index}: any) => {
  const options = getSalespersonDropdownOptions({
    organization,
    description: 'All branches',
    options: Organization.getSalespersonOptions(organization).map((user: any) => ({
      label: user.label,

      // Keeps filter option selected when page is refreshed
      value: _.toString(user.value),

      description: user.description,
    })),
  });

  return (
    <FieldInput
      {...form}
      component={MultiDropdownInput}
      name={`${field}.salespersonIds`}
      label={'Salespeople'}
      style={{zIndex: index}}
      isResponsive
      input={{
        options,
        placeholder: 'Select salespeople',
        setFieldValue: form.setFieldValue,
        style: {
          width: '100%',
        },
        showDescriptionInOption: true,
      }}
    />
  );
};

const ClientFilters = ({organization, form, isRestricted}: any) => {
  const field = 'filteredClientsForm';
  const shouldSeeBranchFilter = organization.isPrimary;

  return (
    <React.Fragment>
      {shouldSeeBranchFilter && (
        <React.Fragment>
          <MultiBranchDropdownFilter
            organization={organization}
            form={form}
            field={field}
            index={0}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      {!isRestricted && (
        <React.Fragment>
          <SalespeopleMultiselect organization={organization} form={form} field={field} index={1} />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ClientFilterPopoverContent = ({organization, form, isRestricted, responsive}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ContentContainer style={{paddingHorizontal: responsive.desktop ? null : 16}}>
      <ClientFilters organization={organization} form={form} isRestricted={isRestricted} />
      {/* Without this, the mobile dropdown doesn't have enough space to display */}
      {responsive.desktop ? null : <Space height={72} />}
    </ContentContainer>
  );
};

const ClientsFilterPopover = ({popover, organization, form, isRestricted, viewerId}: any) => {
  const responsive = useResponsive();

  return (
    <PopoverFilter
      activeFiltersCount={FilteredClientsForm.getNumberOfFiltersApplied({
        form,
        organization,
        isRestricted,
      })}
      hideActionButtons
      popover={popover}
      responsive={responsive}
      handleApply={popover.handleClose}
      handleClear={() => {
        form.setFieldValue('filteredClientsForm.salespersonIds', isRestricted ? [viewerId] : null);
        form.setFieldValue(
          'filteredClientsForm.slugs',
          organization.isPrimary ? ['ALL_ORGANIZATIONS'] : [organization.slug],
        );
      }}
    >
      <ClientFilterPopoverContent
        organization={organization}
        form={form}
        isRestricted={isRestricted}
        responsive={responsive}
      />
    </PopoverFilter>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientsFilterPopover.fragment = gql`
  ${Organization.getSalespersonOptions.fragment}
  ${MultiBranchOrganizationField.fragment}

  fragment ClientsFilterPopover on Organization {
    id
    isPrimary
    features {
      isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
    }
    ...Organization_getSalespersonOptions
    ...MultiBranchOrganizationField
  }
`;

export default ClientsFilterPopover;
