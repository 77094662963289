// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useNavigationDOM} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import JobOfficeNotesModal from 'modules/App/Day/components/JobOfficeNotesModal';

const Container = Styled.View`
  background-color: ${colors.white};
  margin-bottom: 10px;
`;

const Content = Styled.View`
  flex-direction: row;
`;

const KindBorder = Styled.View`
  margin-right: 5px;
  width: 5px;
  background-color: ${(props) => (props as any).color};
`;

const Info = Styled.View`
  flex: 1;
  padding-vertical: 5px;
  padding-horizontal: 5px;
`;

const Touchable = Styled.Touchable`
`;

const Details = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-vertical: 2px;
`;

const Name = Styled.H8`
  color: ${colors.gray.secondary};
  font-size: 13px;
  ${fontWeight(700)}
`;

const Status = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusText = Styled.H8`
  margin-top: 1px;
  margin-right: 5px;
  color: ${(props) => (props as any).color};
  font-size: 11px;
  ${fontWeight(700)}
  letter-spacing: 0.5;
  text-transform: uppercase;
`;

const Customer = Styled.H7`
  font-size: 15px;
  ${fontWeight(700)}
`;

const StartTime = Styled.H8`
  margin-top: 3px;
  color: ${colors.gray.secondary};
  font-size: 13px;
`;

const Size = Styled.H8`
  margin-top: 3px;
  color: ${colors.gray.secondary};
  font-size: 13px;
`;

const Rate = Styled.H8`
  align-self: flex-start;
  padding-vertical: 4px;
  padding-horizontal: 5px;
  margin-top: 5px;
  margin-bottom: 2px;
  background-color: ${colors.blue.accent};
  border-radius: 3px;
  color: ${colors.gray.secondary};
  font-size: 13px;
  ${fontWeight(700)}
  letter-spacing: 0.5px;
`;

const NotesTouchable = Styled.Touchable`
`;

const Line = Styled.View`
  height: 1px;
  margin-vertical: 5px;
  background-color: ${colors.blue.accent};
`;

const OfficeNotes = Styled.H8`
  font-style: italic;
`;

const getStatusText = ({job}: any) => {
  if (job.bookingPrimaryStatus === 'READY') {
    return 'CONFIRMED';
  } else if (job.primaryStatus === 'HOLD') {
    return 'HOLD';
  } else {
    return 'PENDING';
  }
};

const BookingCalendarJobItemV1 = ({job}: any) => {
  const {navigator} = useNavigationDOM();
  const {isHovered, ref} = useHover();

  return (
    <Container>
      <Content
        ref={ref}
        style={{backgroundColor: isHovered ? colors.gray.background : colors.white}}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <KindBorder color={job.project.projectType.color} />
        <Info>
          <Touchable onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
            <Details>
              <Row>
                <Name numberOfLines={1}>{Project.getDisplayText(job.project, job.name)}</Name>
              </Row>
              <Row>
                <Name numberOfLines={1}>{Job.getFullName(job)}</Name>
                <Status>
                  <StatusText
                    color={
                      job.bookingPrimaryStatus === 'READY'
                        ? colors.green.status
                        : colors.orange.status
                    }
                  >
                    {getStatusText({job})}
                  </StatusText>
                </Status>
              </Row>
              <Customer numberOfLines={1}>{job.displayName}</Customer>
              <StartTime numberOfLines={1}>
                {job.startTime1 && job.startTime2
                  ? `${Datetime.convertToDisplayTime(job.startTime1)} - ` +
                    `${Datetime.convertToDisplayTime(job.startTime2)}`
                  : 'No start time'}
              </StartTime>
              <Size numberOfLines={1}>
                {`${job.crewSize} movers, ${job.moveSize || '? bedroom'}`}
              </Size>
              <Rate>{Job.getPrimaryJobRate(job)}</Rate>
            </Details>
          </Touchable>
          {!!job.officeNotes && (
            <JobOfficeNotesModal
              job={job}
              trigger={({handleOpen}: any) => (
                <NotesTouchable onPress={handleOpen}>
                  <React.Fragment>
                    <Line />
                    <OfficeNotes numberOfLines={2}>{job.officeNotes}</OfficeNotes>
                  </React.Fragment>
                </NotesTouchable>
              )}
            />
          )}
        </Info>
      </Content>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarJobItemV1.fragment = gql`
  ${Job.getFullName.fragment}
  ${Project.getDisplayText.fragment}

  fragment BookingCalendarJobItemV1 on Job {
    id
    uuid
    displayName

    bookingPrimaryStatus
    moveSize
    crewSize
    startTime1
    startTime2
    totalDistance
    hourlyRate
    travelFee
    officeNotes
    primaryStatus
    customer {
      id
      aliasName
      firstName
      lastName
    }
    project {
      id
      projectType {
        id
        color
      }
      ...Project_getDisplayText
    }
    ...Job_getFullName
  }
`;

export default BookingCalendarJobItemV1;
