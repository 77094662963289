// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.Touchable`
  flex-direction: row;
  align-items: center;
`;

const DisabledContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const SwitchOuterOval = Styled.View`
  height: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '12px' : '16px')};
  width: ${({
    // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isSmall,
  }) => (isSmall ? '18px' : '24px')};
  background-color: ${(props) => (props as any).color};
  border-radius: 20px;
  justify-content: center;
  align-items: ${(props) => ((props as any).isOn ? 'flex-end' : 'flex-start')};
  padding: 2px;
`;

const SwitchInnerCircle = Styled.View`
height: ${({
  // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
  isSmall,
}) => (isSmall ? '8px' : '12px')};
width: ${({
  // @ts-expect-error TS(2339): Property 'isSmall' does not exist on type 'ThemePr... Remove this comment to see the full error message
  isSmall,
}) => (isSmall ? '8px' : '12px')};
  border-radius: 6px;
  background-color: ${colors.white};
`;

const SwitchText = Styled.H8`
  ${fontWeight(700)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isOn ? colors.blue.interactive : colors.gray.tertiary)}
`;

const SIZES = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
};

const SwitchContainer = ({onPress, children, disabled}: any) => {
  if (disabled) {
    return (
      // @ts-expect-error TS(2769): No overload matches this call.
      <DisabledContainer onPress={onPress} activeOpacity={0.8}>
        {children}
      </DisabledContainer>
    );
  }

  return (
    <Container onPress={onPress} activeOpacity={0.8}>
      {children}
    </Container>
  );
};

const SwitchButton = ({isOn, color, size, disabled}: any) => {
  const isSmall = size === SIZES.SMALL;
  const baseColor = isOn ? color : colors.gray.tertiary;
  const switchColor = disabled ? colors.alpha(baseColor, 0.4) : baseColor;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SwitchOuterOval isOn={isOn} color={switchColor} activeOpacity={0.8} isSmall={isSmall}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SwitchInnerCircle isSmall={isSmall} />
    </SwitchOuterOval>
  );
};

type OwnSwitchProps = {
  color?: string;
  size?: string;
};

// @ts-expect-error TS(2456): Type alias 'SwitchProps' circularly references its... Remove this comment to see the full error message
type SwitchProps = OwnSwitchProps & typeof Switch.defaultProps;

// @ts-expect-error TS(7022): 'Switch' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Switch = ({isOn, color, onChange, label, size, disabled}: SwitchProps) => {
  return (
    <SwitchContainer onPress={() => !disabled && onChange(!isOn)} disabled={disabled}>
      {!!label && (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <SwitchText vars={{isOn}}>{label}</SwitchText>
          <Space width={6} />
        </React.Fragment>
      )}
      <SwitchButton isOn={isOn} color={color} size={size} disabled={disabled} />
    </SwitchContainer>
  );
};

Switch.Container = SwitchContainer;
Switch.Button = SwitchButton;
Switch.Text = SwitchText;
Switch.SIZE = SIZES;

Switch.defaultProps = {
  color: colors.blue.interactive,
  size: SIZES.MEDIUM,
};

export default Switch;
