// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Storage} from '@supermove/sdk';

type OwnProps = {
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateSuperAdminOrganizationMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateSuperAdminOrganizationMutation' implicitly ... Remove this comment to see the full error message
const UpdateSuperAdminOrganizationMutation = ({onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    mutation={UpdateSuperAdminOrganizationMutation.mutation}
    onCompleted={async ({response: {viewer, token, errors}}: any) => {
      if (viewer) {
        await Storage.setItem('token', token);
        onSuccess(viewer);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

UpdateSuperAdminOrganizationMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuperAdminOrganizationMutation.mutation = gql`
  mutation UpdateSuperAdminOrganizationMutation(
    $organizationId: Int!,
  ) {
    response: updateSuperAdminOrganization(
      organizationId: $organizationId,
    ) {
      ${gql.errors}
      token
      viewer {
        id
        role
      }
    }
  }
`;

export default UpdateSuperAdminOrganizationMutation;
