// Libraries
import React from 'react';

// Supermove
import {Project} from '@supermove/models';

// App
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';

const BlockDuplicateProjectModal = ({isOpen, handleClose}: any) => {
  return (
    <WarningModal
      isOpen={isOpen}
      // @ts-expect-error TS(2322): Type '{ isOpen: any; handleClose: any; title: stri... Remove this comment to see the full error message
      handleClose={handleClose}
      title={'Unable to duplicate project'}
      message={`You cannot duplicate a project that has more than ${Project.MAX_JOB_COUNT} jobs.`}
      primaryActionText={'Close'}
      handlePrimaryAction={handleClose}
    />
  );
};

export default BlockDuplicateProjectModal;
