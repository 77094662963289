// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useSpreadsheet} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const getValuationCoverageDataTableColumnDefinitions = ({columns}: any) => {
  return columns.map((column: any) => {
    return {
      flex: 1,
      minWidth: column.width,
      headerContent: () => {
        return <TableHeaderText>{column.accessor}</TableHeaderText>;
      },
      cellContent: ({item}: any) => {
        return <TableCellText numberOfLines={1}>{item[column.accessor]}</TableCellText>;
      },
    };
  });
};

const ValuationCoverageDataTable = ({data}: any) => {
  const parsedJson = JSON.parse(JSON.parse(data.dataTable.jsonTable));
  const {spreadsheetColumns, spreadsheetData} = useSpreadsheet({
    jsonData: parsedJson,
    columnWidth: 150,
  });

  const valuationCoverageDataTableColumnDefinitions =
    getValuationCoverageDataTableColumnDefinitions({
      columns: spreadsheetColumns,
    });

  return (
    <ScrollView horizontal style={{paddingRight: 24}}>
      <Table
        columnDefinitions={valuationCoverageDataTableColumnDefinitions}
        emptyStateText='No valuation coverage'
        items={spreadsheetData}
        headerStyle={{backgroundColor: colors.gray.background}}
        isDense
      />
    </ScrollView>
  );
};

const ProjectTypeValuationCoverageDataTable = ({valuationCoverageDataTableUuid}: any) => {
  const {data, loading} = useQuery(ProjectTypeValuationCoverageDataTable.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: valuationCoverageDataTableUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        return (
          data && (
            <React.Fragment>
              <HeaderText>{data.dataTable.name}</HeaderText>
              <Space height={12} />
              <ValuationCoverageDataTable data={data} />
            </React.Fragment>
          )
        );
      }}
    </Loading>
  );
};

ProjectTypeValuationCoverageDataTable.query = gql`
  query ProjectTypeValuationCoverageDataTable($uuid: String!) {
    ${gql.query}
    dataTable(uuid: $uuid){
      id
      jsonTable
      name
    }
  }
`;

export default ProjectTypeValuationCoverageDataTable;
