// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditProjectClientsDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectClientsDrawer';
import ProjectClientContactName from 'modules/Project/V2/Show/components/ProjectClientContactName';
import ProjectClientEmail from 'modules/Project/V2/Show/components/ProjectClientEmail';
import ProjectClientPhoneNumber from 'modules/Project/V2/Show/components/ProjectClientPhoneNumber';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FieldValueWrapper = Styled.View`
  flex: 1;
`;

const LabelText = Styled.Text`
  ${Typography.Label};
`;

const BodyText = Styled.Text`
  ${Typography.Body};
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color || colors.gray.primary};
`;

const SkeletonField = ({label}: any) => {
  return (
    <Container style={{flex: 1}}>
      <FieldValue.LabelText>{label}</FieldValue.LabelText>
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={144} />
    </Container>
  );
};

const SkeletonRow = () => {
  return (
    <Row style={{flex: 1}}>
      <SkeletonField label={'Company Name'} />
      <SkeletonField label={'Contact Name'} />
      <SkeletonField label={'Phone Number'} />
      <SkeletonField label={'Email'} />
    </Row>
  );
};

const SkeletonBody = () => {
  return (
    <Container>
      <InformationSection name={'Client Information'}>
        <SkeletonRow />
        <Space height={16} />
        <SkeletonField label={'Client Notes'} />
      </InformationSection>
      <Space height={24} />
      <InformationSection name={'Billing Information'}>
        <SkeletonRow />
        <Space height={16} />
        <SkeletonField label={'Client Notes'} />
      </InformationSection>
    </Container>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={72} />
      )}
      title={'Client & Billing Information'}
      style={{width: '100%'}}
    />
  );
};

const InformationSection = ({name, children}: any) => {
  return (
    <Container>
      <LabelText>{name}</LabelText>
      <Space height={12} />
      {children}
    </Container>
  );
};

const ClientInformationLabel = ({children}: any) => {
  return <FieldValue.LabelText style={{userSelect: 'none'}}>{children}</FieldValue.LabelText>;
};

const ClientField = ({value, label}: any) => {
  return <FieldValue value={value} label={label} empty={'None'} />;
};

const ClientCompanyName = ({value, label}: any) => {
  return (
    <FieldValueWrapper>
      <ClientField value={value} label={label} />
    </FieldValueWrapper>
  );
};

const ClientContactName = ({project, client, isBilling}: any) => {
  const isDummyClient = !client.id;
  return (
    <FieldValueWrapper>
      <ClientInformationLabel>Contact Name</ClientInformationLabel>
      <Space height={8} />
      {isDummyClient ? (
        <FieldValue.EmptyText>None</FieldValue.EmptyText>
      ) : (
        <ProjectClientContactName project={project} isBilling={isBilling} />
      )}
    </FieldValueWrapper>
  );
};

const ClientPhone = ({project, client, urlFilters, isBilling}: any) => {
  const isDummyClient = !client.id;
  return (
    <FieldValueWrapper>
      <ClientInformationLabel>Phone Number</ClientInformationLabel>
      <Space height={8} />
      {isDummyClient ? (
        <FieldValue.EmptyText>None</FieldValue.EmptyText>
      ) : (
        <ProjectClientPhoneNumber project={project} urlFilters={urlFilters} isBilling={isBilling} />
      )}
    </FieldValueWrapper>
  );
};

const ClientEmail = ({project, client, isBilling}: any) => {
  const isDummyClient = !client.id;
  return (
    <FieldValueWrapper>
      <ClientInformationLabel>Email</ClientInformationLabel>
      <Space height={8} />
      {isDummyClient ? (
        <FieldValue.EmptyText>None</FieldValue.EmptyText>
      ) : (
        <ProjectClientEmail project={project} isBilling={isBilling} />
      )}
    </FieldValueWrapper>
  );
};

const ClientInformation = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ title, project, client, urlFi... Remove this comment to see the full error message
  ({title, project, client, urlFilters, isBilling}) => {
    return (
      <InformationSection name={title}>
        {isBilling && !client ? (
          <BodyText color={colors.gray.secondary}>Same as client</BodyText>
        ) : (
          <React.Fragment>
            <Row>
              <ClientCompanyName value={client.name} label={'Company Name'} />
              <Space width={16} />
              <ClientContactName project={project} client={client} isBilling={isBilling} />
              <Space width={16} />
              <ClientPhone
                project={project}
                client={client}
                urlFilters={urlFilters}
                isBilling={isBilling}
              />
              <Space width={16} />
              <ClientEmail project={project} client={client} isBilling={isBilling} />
            </Row>
            <Space height={16} />
            <ClientField value={client.notes} label={'Client Notes (Internal)'} />
          </React.Fragment>
        )}
      </InformationSection>
    );
  },
  gql`
    fragment ProjectClientsBlockContentDesktop_ClientInformation on Client {
      id
      name
      notes
      primaryContact {
        id
        fullName
        phoneNumber
        email
      }
    }
  `,
);

const BodyComponent = ({project, isSameBillingClient, urlFilters}: any) => {
  return (
    <Container>
      {/* @ts-expect-error TS(2786): 'ClientInformation' cannot be used as a JSX compon... Remove this comment to see the full error message */}
      <ClientInformation
        // @ts-expect-error TS(2322): Type '{ title: string; project: any; client: any; ... Remove this comment to see the full error message
        title={'Client Information'}
        project={project}
        client={project.client}
        urlFilters={urlFilters}
      />
      <Space height={24} />
      {/* @ts-expect-error TS(2786): 'ClientInformation' cannot be used as a JSX compon... Remove this comment to see the full error message */}
      <ClientInformation
        // @ts-expect-error TS(2322): Type '{ title: string; project: any; client: any; ... Remove this comment to see the full error message
        title={'Billing Information'}
        project={project}
        client={isSameBillingClient ? null : project.billingClient}
        isBilling
      />
    </Container>
  );
};

const EditClientsButton = ({project}: any) => {
  const editProjectClientsDrawer = useDrawer({name: 'Edit Project Clients Drawer'});

  return (
    <React.Fragment>
      <Button
        text={'Edit'}
        iconLeft={Icon.Pen}
        onPress={editProjectClientsDrawer.handleOpen}
        isSmall
      />
      <EditProjectClientsDrawer
        key={editProjectClientsDrawer.key}
        isOpen={editProjectClientsDrawer.isOpen}
        handleClose={editProjectClientsDrawer.handleClose}
        project={project}
      />
    </React.Fragment>
  );
};

const ProjectClientsBlockContentDesktop = ({project, isSameBillingClient, urlFilters}: any) => {
  return (
    <ActionPanel
      BodyComponent={BodyComponent}
      bodyComponentProps={{project, isSameBillingClient, urlFilters}}
      ActionButtonComponent={EditClientsButton}
      actionButtonComponentProps={{project}}
      title={'Client & Billing Information'}
      style={{width: '100%'}}
    />
  );
};

ProjectClientsBlockContentDesktop.SkeletonComponent = SkeletonComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectClientsBlockContentDesktop.fragment = gql`
  ${ClientInformation.fragment}
  ${ProjectClientContactName.fragment}
  ${ProjectClientPhoneNumber.fragment}
  ${ProjectClientEmail.fragment}
  ${EditProjectClientsDrawer.fragment}

  fragment ProjectClientsBlockContentDesktop on Project {
    id
    client {
      id
      ...ProjectClientsBlockContentDesktop_ClientInformation
    }
    billingClient {
      id
      ...ProjectClientsBlockContentDesktop_ClientInformation
    }
    ...ProjectClientContactName
    ...ProjectClientPhoneNumber
    ...ProjectClientEmail
    ...EditProjectClientsDrawer
  }
`;

export default ProjectClientsBlockContentDesktop;
