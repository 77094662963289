// Libraries
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import Button from '@shared/design/components/Button';
import WarehouseForm from '@shared/modules/Storage/forms/WarehouseForm';
import useCreateWarehouseMutation from '@shared/modules/Storage/hooks/useCreateWarehouseMutation';
import WarehouseFields from 'modules/Organization/Settings/Storage/Warehouses/components/WarehouseFields';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : 400)}px;
  background-color: ${colors.white};
  border-radius: 4px;
  padding: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const CreateWarehouseHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Add Warehouse</TitleText>
      <Space height={16} />
    </React.Fragment>
  );
};

const CreateWarehouseFooter = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <Actions>
      <Button
        text={'Cancel'}
        color={colors.white}
        textColor={colors.gray.secondary}
        onPress={handleClose}
        isDisabled={submitting}
      />
      <Space width={8} />
      <Button
        text={'Save'}
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        isDisabled={submitting}
      />
    </Actions>
  );
};

const CreateWarehouseModal = ({organization, userId, refetch, isOpen, handleClose}: any) => {
  const {isEnabledMultipleWarehouse} = organization.features;
  const warehouseForm = WarehouseForm.new({
    organizationId:
      isEnabledMultipleWarehouse && organization.isPrimaryMultibranch ? null : organization.id,
    userId,
  });
  const {form, submitting, handleSubmit} = useCreateWarehouseMutation({
    warehouseForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log(errors);
    },
  });

  const responsive = useResponsive();

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <CreateWarehouseHeader />
          <Space height={4} />
          <WarehouseFields form={form} organization={organization} isCreate />
          <Space height={16} />
          <CreateWarehouseFooter
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            submitting={submitting}
          />
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateWarehouseModal.fragment = gql`
  ${WarehouseFields.fragment}

  fragment CreateWarehouseModal on Organization {
    id
    isPrimaryMultibranch
    features {
      isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
    }
    ...WarehouseFields
  }
`;

export default CreateWarehouseModal;
