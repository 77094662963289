// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ValuationCoverageForm from '@shared/modules/Insurance/forms/ValuationCoverageForm';
import useUpdateValuationCoverageForm from '@shared/modules/Insurance/hooks/useUpdateValuationCoverageForm';
import ProjectValuationCoverageFields from '@shared/modules/Project/components/ProjectValuationCoverageFields';

const EditValuationCoverageDrawerContent = ({project, isOpen, handleClose, onSuccess}: any) => {
  const editValuationCoverageSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Valuation coverage updated',
  });
  const valuationCoverageForm = ValuationCoverageForm.edit(project.valuationCoverage);
  const {form, submitting, handleSubmit} = useUpdateValuationCoverageForm({
    valuationCoverageForm,
    onSuccess: () => {
      handleClose();
      editValuationCoverageSuccessToast.handleToast();
      onSuccess && onSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Valuation Coverage'}
      handleSubmit={handleSubmit}
      primaryActionText={'Save'}
      isSubmitting={submitting}
      isUsingLoadingComponent
    >
      <ProjectValuationCoverageFields
        form={form}
        field={'valuationCoverageForm'}
        project={project}
      />
    </DrawerWithAction>
  );
};

const EditValuationCoverageDrawer = ({isOpen, projectUuid, handleClose, onSuccess}: any) => {
  const {loading, data} = useQuery(EditValuationCoverageDrawer.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      projectUuid,
    },
  });

  return (
    <Loading
      loading={loading || !data}
      as={() => (
        <DrawerWithAction.LoadingComponent
          isOpen={isOpen}
          handleClose={handleClose}
          headerText={'Edit Valuation Coverage'}
          primaryActionText={'Save'}
          // @ts-expect-error TS(2322): Type '{ isOpen: any; handleClose: any; headerText:... Remove this comment to see the full error message
          sheetStyle={{height: 532}}
        />
      )}
    >
      {() => (
        <EditValuationCoverageDrawerContent
          project={data.project}
          isOpen={isOpen}
          handleClose={handleClose}
          onSuccess={onSuccess}
        />
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditValuationCoverageDrawer.query = gql`
  ${ProjectValuationCoverageFields.fragment}
  ${ValuationCoverageForm.edit.fragment}

  query EditValuationCoverageDrawer($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      valuationCoverage {
        id
        ...ValuationCoverageForm_edit
      }
      ...ProjectValuationCoverageFields
    }
  }
`;

export default EditValuationCoverageDrawer;
