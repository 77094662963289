// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime, Json} from '@supermove/utils';

// Components
import {JobUserStatusBadge} from 'modules/App/Job/components';

const Container = Styled.View`
  justify-content: flex-start;
  width: ${(props) => (props as any).width}px;
  height: 75px;
  padding: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  background-color: ${(props) => (props as any).color};
  opacity: ${(props) => (props as any).opacity || 1.0};
  overflow: hidden;
  cursor: ${(props) => (props as any).cursor};
`;

const Job = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-horizontal: 3px;
  padding-vertical: ${(props) => ((props as any).isTarget ? '10px' : '5px')};
  margin-bottom: 3px;
  background-color: ${(props) => ((props as any).isTarget ? colors.gray.primary : colors.gray.tertiary)};
  border-radius: 3px;
`;

const JobText = Styled.H8`
  font-weight: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.isTarget ? 700 : 500)};
  color: ${colors.white};
`;

const IndicatorWrapper = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Indicator = Styled.Loading`
`;

const shouldShowBadge = ({jobUser}: any) => {
  const notificationSettings = _.get(jobUser, 'job.organization.settings.notifications', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(notificationSettings));
  return (json as any).allowEmployeeText;
};

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, ['job.startTime1', 'id']);
};

type OwnScheduleCellV2Props = {
  loading?: boolean;
  boldJobId?: string;
  width?: number;
  jobUsers: any[];
  styleColor: string;
  styleCursor: string;
  styleOpacity: number;
};

// @ts-expect-error TS(2456): Type alias 'ScheduleCellV2Props' circularly refere... Remove this comment to see the full error message
type ScheduleCellV2Props = OwnScheduleCellV2Props & typeof ScheduleCellV2.defaultProps;

/**
 * Render the jobs assigned to crew member for a day.
 * The difference between V2 and V1 is V2 renders
 * JobUsers, whereas V1 renders Jobs.
 */
// @ts-expect-error TS(7022): 'ScheduleCellV2' implicitly has type 'any' because... Remove this comment to see the full error message
const ScheduleCellV2 = ({
  loading,
  boldJobId,
  width,
  jobUsers,
  styleColor,
  styleCursor,
  styleOpacity,
}: ScheduleCellV2Props) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={styleColor} cursor={styleCursor} opacity={styleOpacity} width={width}>
    {loading ? (
      <IndicatorWrapper>
        <Indicator size={'small'} color={colors.gray.border} />
      </IndicatorWrapper>
    ) : (
      <React.Fragment>
        {sortJobUsers({jobUsers}).map((jobUser) => (
          // @ts-expect-error TS(2769): No overload matches this call.
          <Job key={jobUser.id} isTarget={jobUser.job.id === boldJobId}>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <JobText numberOfLines={1} vars={{isTarget: jobUser.job.id === boldJobId}}>
              {`#${jobUser.job.identifier} - ${Datetime.convertToDisplayTime(
                jobUser.job.startTime1,
              )}`}
            </JobText>
            {shouldShowBadge({jobUser}) && <JobUserStatusBadge jobUser={jobUser} />}
          </Job>
        ))}
      </React.Fragment>
    )}
  </Container>
);

ScheduleCellV2.defaultProps = {
  boldJobId: null,
  width: 130,
  loading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ScheduleCellV2.fragment = gql`
  ${JobUserStatusBadge.fragment}

  fragment ScheduleCellV2 on JobUser {
    id
    job {
      id
      identifier
      startTime1
      organization {
        id
        settings {
          id
          notifications
        }
      }
    }
    ...JobUserStatusBadge
  }
`;

export default ScheduleCellV2;
