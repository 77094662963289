// Libraries
import React from 'react';

// Supermove
import {Styled, ScrollView} from '@supermove/components';
import {useHover, useOnPressOutside, useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const DropdownList = Styled.View`
  top: 44px;
  position: absolute;
  background-color: ${colors.gray.background};
  overflow: auto;
  width: 100%;
  max-height: 200px;
  z-index: 1;
`;

const DropdownRowWrapper = Styled.Touchable`
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(props) => ((props as any).isHovered ? colors.gray.tertiary : colors.gray.background)};
`;

const DropdownRowContent = Styled.H6`
  align-self: stretch;
  padding-vertical: 5px;
  padding-horizontal: 10px;
`;

const SearchBarContent = Styled.View`
`;

const TextInput = Styled.TextInput.H7`
  align-self: stretch;
  width: 100%;
`;

const SublabelText = Styled.H7`
  padding-vertical: 5px;
  padding-right: 15px;
  font-style: italic;
  ${fontWeight(500)}
  color: ${colors.gray.primary}
`;

const DropdownRow = ({onPress, text, sublabel}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <DropdownRowWrapper ref={ref} isHovered={isHovered} onPress={onPress}>
        <DropdownRowContent numberOfLines={1}>{text}</DropdownRowContent>
        {sublabel ? <SublabelText>{sublabel}</SublabelText> : null}
      </DropdownRowWrapper>
    </React.Fragment>
  );
};

/**
 * A search bar used for displaying search suggestions as the user types. When a
 * search bar is clicked on, the onSelect handler will be called.
 *
 * @param {Object} style Any additional styling for the component
 * @param {Array} results A list of objects with the form {text, ...otherData}.
 * The text will be displayed in the search results.
 * @param {func} onSelect A handler for when the user click on a row. The row
 * data is passed into the handler.
 */
const SearchBar = ({style, results, onSelect, placeholder = ''}: any) => {
  const [searchText, setSearchText] = useState('');
  const [isResultsShown, setIsResultsShown] = useState(false);
  const clickOutsideRef = useOnPressOutside(() => setIsResultsShown(false));
  const filterResults = (searchText: any, results: any) => {
    return results.filter((result: any) =>
      result.text.toLowerCase().includes(searchText.toLowerCase()),
    );
  };

  return (
    <SearchBarContent style={style}>
      <div ref={clickOutsideRef}>
        <TextInput
          placeholder={placeholder}
          onChange={(event: any) => {
            setSearchText(event.target.value);
          }}
          value={searchText}
          onFocus={() => setIsResultsShown(true)}
        />
        <DropdownList style={{display: isResultsShown ? '' : 'none'}}>
          <ScrollView>
            {filterResults(searchText, results).map((result: any) => (
              <DropdownRow
                key={result.text}
                onPress={() => {
                  setSearchText('');
                  setIsResultsShown(false);
                  onSelect(result);
                }}
                text={result.text}
                sublabel={result.sublabel}
              />
            ))}
          </ScrollView>
        </DropdownList>
      </div>
    </SearchBarContent>
  );
};

export default SearchBar;
