// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import useDeleteDefaultSurveyMethodMutation from '@shared/modules/Inventory/hooks/useDeleteDefaultSurveyMethodMutation';

const DeleteDefaultSurveyMethodModal = ({
  deleteDefaultSurveyMethodModal,
  surveyMethodForm,
  refetch,
  organization,
}: any) => {
  const {form, submitting, handleSubmit} = useDeleteDefaultSurveyMethodMutation({
    surveyMethodForm,
    onSuccess: () => {
      refetch();
      deleteDefaultSurveyMethodModal.handleClose();
    },
    onError: (errors: any) => console.log(errors),
  });
  const surveyMethodOptions = organization.surveyMethods.reduce(
    (options: any, surveyMethod: any) => {
      if (surveyMethod.id !== surveyMethodForm.surveyMethodId) {
        options.push({
          label: surveyMethod.name,
          value: surveyMethod.id,
        });
      }
      return options;
    },
    [],
  );

  return (
    <SmallModal
      key={deleteDefaultSurveyMethodModal.key}
      isOpen={deleteDefaultSurveyMethodModal.isOpen}
      style={{overflow: 'visible'}}
    >
      <SmallModal.HeaderText>{`Remove ${_.get(
        surveyMethodForm,
        'name',
        'Survey Method',
      )}?`}</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>{`The survey method you want to remove is currently the default. Please choose a new default survey method. Any project types with this survey method will be replaced by the new default.`}</SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        isRequired
        name={'surveyMethodForm.newDefaultSurveyMethodId'}
        label={'Default Survey Method'}
        component={DropdownInput}
        input={{
          options: surveyMethodOptions,
          placeholder: 'Select default survey method',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={deleteDefaultSurveyMethodModal.handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          color={colors.red.warning}
          onPress={handleSubmit}
          isSubmitting={submitting}
        >
          Reassign and Remove
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteDefaultSurveyMethodModal.fragment = gql`
  fragment DeleteDefaultSurveyMethodModal on Organization {
    id
    surveyMethods {
      id
      name
    }
  }
`;

export default DeleteDefaultSurveyMethodModal;
