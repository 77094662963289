// Libraries
import React from 'react';

// Supermove
import {Dropdown, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import JobRequestCancelModal from './JobRequestCancelModal';
import JobRequestConvertToPendingModal from './JobRequestConvertToPendingModal';
import JobRequestUncancelModal from './JobRequestUncancelModal';

const Container = Styled.View`
  margin-horizontal: 10px;
`;

const Button = Styled.Button`
  height: 40px;
  width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? 50 : 100)}px;
  background-color: ${colors.blue.interactive};
`;

const MobileText = Styled.H3`
  margin-top: -10px;
  ${fontWeight(900)}
  color: ${colors.white};
`;

const DesktopText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Items = Styled.View`
  margin-top: 5px;
  padding-vertical: 8px;
  width: 200px;
  background-color: ${colors.white};
  border: 1px solid ${colors.blue.accent};
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const Item = Styled.View`
  z-index: 100;
`;

const Action = Styled.Touchable`
`;

const Text = Styled.H7`
  padding-top: 7px;
  padding-bottom: 7px;
  padding-horizontal: 20px;
`;

const JobRequestActionsDropdown = ({jobRequest}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Container>
      <Dropdown
        trigger={({handleToggle}: any) => (
          <Button vars={responsive} onPress={handleToggle}>
            {responsive.mobile ? <MobileText>...</MobileText> : <DesktopText>Actions</DesktopText>}
          </Button>
        )}
      >
        {({isOpen, handleClose}: any) => (
          <Dropdown.Content isVisible={isOpen}>
            <Items>
              {jobRequest.status === 'NEW' && (
                <Item>
                  <JobRequestConvertToPendingModal
                    jobRequest={jobRequest}
                    onSuccess={() => navigator.goBack()}
                    trigger={({handleOpen}: any) => (
                      <Action onPress={handleOpen}>
                        <Text style={{color: colors.blue.interactive}}>Convert to Pending</Text>
                      </Action>
                    )}
                  />
                </Item>
              )}
              {jobRequest.status !== 'CANCELLED' && (
                <Item>
                  <JobRequestCancelModal
                    jobRequest={jobRequest}
                    onSuccess={() => navigator.goBack()}
                    trigger={({handleOpen}: any) => (
                      <Action onPress={handleOpen}>
                        <Text style={{color: colors.red.warning}}>Delete Request</Text>
                      </Action>
                    )}
                  />
                </Item>
              )}
              {jobRequest.status === 'CANCELLED' && (
                <Item>
                  <JobRequestUncancelModal
                    jobRequest={jobRequest}
                    onSuccess={() => navigator.goBack()}
                    trigger={({handleOpen}: any) => (
                      <Action onPress={handleOpen}>
                        <Text style={{color: colors.blue.interactive}}>Recover Request</Text>
                      </Action>
                    )}
                  />
                </Item>
              )}
            </Items>
          </Dropdown.Content>
        )}
      </Dropdown>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestActionsDropdown.fragment = gql`
  ${JobRequestCancelModal.fragment}
  ${JobRequestConvertToPendingModal.fragment}
  ${JobRequestUncancelModal.fragment}

  fragment JobRequestActionsDropdown on JobRequest {
    id
    status
    ...JobRequestCancelModal
    ...JobRequestConvertToPendingModal
    ...JobRequestUncancelModal
  }
`;

export default JobRequestActionsDropdown;
