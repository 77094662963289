// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Link, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Percent} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import SalesTaxTemplateForm from '@shared/modules/Billing/forms/SalesTaxTemplateForm';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const ViewCell = ({label, placeholder, value}: any) => {
  const responsive = useResponsive();
  return (
    <Row>
      <Column>
        <EditPanel.LabelText responsive={responsive}>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text
          responsive={responsive}
          style={{color: value ? colors.black : colors.gray.tertiary}}
        >
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const HeaderAction = ({isEditing, handleEdit, handleCancel, handleClose}: any) => {
  const responsive = useResponsive();
  if (isEditing && !responsive.desktop) {
    return (
      <TertiaryButton
        isResponsive
        text={'Cancel'}
        onPress={() => {
          handleClose();
          handleCancel();
        }}
      />
    );
  }
  if (!isEditing) {
    // @ts-expect-error TS(2741): Property 'isDisabled' is missing in type '{ isEdit... Remove this comment to see the full error message
    return <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />;
  }
  return null;
};

const ProjectTypeSalesTaxTemplatePanelHeader = ({
  projectTypeName,
  isEditing,
  handleEdit,
  handleCancel,
  handleClose,
}: any) => {
  const responsive = useResponsive();
  return (
    <Panel.Header style={{alignItems: 'center'}}>
      <Column>
        <Row>
          <Panel.HeaderText responsive={responsive}>Sales Tax</Panel.HeaderText>
          <Space style={{flex: 1, minWidth: 12}} />
          <HeaderAction
            isEditing={isEditing}
            handleEdit={handleEdit}
            handleCancel={handleCancel}
            handleClose={handleClose}
          />
        </Row>
        <Space height={8} />
        <Panel.HeaderDescription responsive={responsive}>
          {`This default sales tax applies to all taxable bill items in new ${projectTypeName} projects, and will default to the `}
          <Link to={'/settings/company/accounting'}>
            <LinkText responsive={responsive}>{`organization's default sales tax rate`}</LinkText>
          </Link>
          {` when not overridden.`}
        </Panel.HeaderDescription>
      </Column>
    </Panel.Header>
  );
};

const ProjectTypeSalesTaxTemplatePanelEdit = ({
  form,
  field,
  variableOptions,
  lastUpdatedDisplay,
  projectTypeId,
  settingsSalesTaxTemplate,
}: any) => {
  const responsive = useResponsive();
  const isSettingsSalesTaxTemplate = _.get(form.values, `${field}.settingsId`);
  return (
    <React.Fragment>
      <Row>
        <Column>
          <TextInputWithDropdown
            isResponsive
            disabled={isSettingsSalesTaxTemplate}
            form={form}
            textValueField={`${field}.rate`}
            clearTextValue={''}
            dropdownValueField={`${field}.variableId`}
            label={'Default Sales Tax Rate'}
            dropdownOptions={variableOptions}
            placeholder={'Enter a percentage or select a variable'}
            onBlur={() => {
              const rateField = `${field}.rate`;
              if (_.get(form.values, `${field}.variableId`)) {
                form.setFieldValue(rateField, '');
              } else {
                form.setFieldValue(
                  rateField,
                  Percent.toForm(Percent.toFloat(_.get(form.values, rateField))),
                );
              }
            }}
            noOptionsMessage={'No variable options'}
            action={
              <React.Fragment>
                <Icon
                  source={isSettingsSalesTaxTemplate ? Icon.LinkSlash : Icon.ArrowRotateRight}
                  size={12}
                  color={colors.blue.interactive}
                />
                <Space width={8} />
              </React.Fragment>
            }
            actionText={isSettingsSalesTaxTemplate ? 'Override' : 'Restore to Default'}
            handleAction={() => {
              if (isSettingsSalesTaxTemplate) {
                form.setFieldValue(
                  field,
                  // @ts-expect-error TS(2345): Argument of type '{ projectTypeId: any; }' is not ... Remove this comment to see the full error message
                  SalesTaxTemplateForm.toForm(SalesTaxTemplateForm.new({projectTypeId})),
                );
              } else {
                // Restore to settings sales tax template
                // projectTypeId is set on the form so that existing project type sales tax templates can be queried and deleted
                form.setFieldValue(
                  field,
                  SalesTaxTemplateForm.toForm(
                    SalesTaxTemplateForm.edit(settingsSalesTaxTemplate, {projectTypeId}),
                  ),
                );
              }
            }}
          />
          <Space height={8} />
          <Text responsive={responsive}>{`Last Updated: ${lastUpdatedDisplay}`}</Text>
        </Column>
        {responsive.desktop && (
          <React.Fragment>
            <Space width={24} />
            <Column />
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

const ProjectTypeSalesTaxTemplatePanel = ({salesTaxTemplate}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Row>
        <Column>
          <ViewCell
            label={'Default Sales Tax Rate'}
            placeholder={'Add a sales tax rate'}
            value={`${salesTaxTemplate.variable?.name || Percent.display(salesTaxTemplate.rate)}${
              salesTaxTemplate.settingsId ? ' (rate set in organization settings)' : ''
            }`}
          />
        </Column>
        {responsive.desktop && (
          <React.Fragment>
            <Space width={24} />
            <Column />
          </React.Fragment>
        )}
      </Row>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeSalesTaxTemplatePanel.fragment = gql`
  fragment ProjectTypeSalesTaxTemplatePanel on SalesTaxTemplate {
    id
    rate
    variable {
      id
      name
    }
    settingsId
  }
  fragment ProjectTypeSalesTaxTemplatePanel_SettingsSalesTaxTemplate on SalesTaxTemplate {
    id
    rate
    variableId
    settingsId
  }
`;

ProjectTypeSalesTaxTemplatePanel.Edit = ProjectTypeSalesTaxTemplatePanelEdit;
ProjectTypeSalesTaxTemplatePanel.Header = ProjectTypeSalesTaxTemplatePanelHeader;

export default ProjectTypeSalesTaxTemplatePanel;
