// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const ConfirmReinviteUserModal = ({handleConfirm, handleClose, isOpen, title, subtitle}: any) => {
  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen} style={{borderRadius: 16}}>
      <SmallModal.HeaderIcon
        style={{alignItems: 'center'}}
        source={Icon.Envelope}
        color={colors.blue.interactive}
        label={title}
      />
      <Space height={8} />
      <SmallModal.Text style={{textAlign: 'center'}}>{subtitle}</SmallModal.Text>
      <Space height={12} />
      <SmallModal.Footer style={{justifyContent: 'center'}}>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.blue.interactive} onPress={handleConfirm}>
          Resend Invitation
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default ConfirmReinviteUserModal;
