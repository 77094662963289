// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, TimeInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TaskTemplateAssignedToRole from '@shared/modules/TaskManagement/enums/TaskTemplateAssignedToRole';
import Line from 'modules/App/components/Line';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';

const Container = Styled.View`
`;

const Subheading = Styled.Text`
  ${Typography.Subheading}
`;

const FieldContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const DueFieldsRow = Styled.View`
  flex-direction: row;
  align-items: flex-start;
`;

const DueFieldsText = Styled.Text`
  ${Typography.Body}
  margin-top: 8px;
`;

const getAssigneeOptions = ({organization, isEnabledRoles}: any) => {
  const roles = TaskTemplateAssignedToRole.DROPDOWN_OPTIONS;
  const assigneeOptions = Organization.getTaskTemplateAssigneeDropdownOptions(organization);
  return [...(isEnabledRoles ? roles : []), ...assigneeOptions];
};

const TemplateNameField = ({form, field, index}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Template Name'}
      name={`${field}.name`}
      isRequired
      input={{
        placeholder: 'Name of template',
      }}
    />
  );
};

const TaskNameField = ({form, field, index}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Task Name'}
      name={`${field}.taskName`}
      isRequired
      input={{
        placeholder: 'Name of task',
      }}
    />
  );
};

const DueFields = ({form, field, index}: any) => {
  const unitsField = `${field}.dueInUnits`;
  const timeField = `${field}.dueAtTime`;
  return (
    <DueFieldsRow style={{justifyContent: 'center'}}>
      <DueFieldsText>Due in</DueFieldsText>
      <Space width={8} />
      <FieldInput.Memoized
        {...form}
        index={index}
        name={unitsField}
        style={{width: 50}}
        input={{
          placeholder: '30',
          maxLength: 3,
        }}
      />
      <Space width={8} />
      <DueFieldsText>day(s)</DueFieldsText>
      <Space width={6} />
      <DueFieldsText>at</DueFieldsText>
      <Space width={8} />
      <FieldInput.Memoized
        {...form}
        index={index}
        component={TimeInput}
        name={timeField}
        input={{
          placeholder: '00:00 AM',
          setFieldValue: form.setFieldValue,
          component: FieldInput.TextInput,
        }}
        style={{width: 88}}
      />
    </DueFieldsRow>
  );
};

type OwnAssigneeFieldProps = {
  label?: string;
  placeholder?: string;
  isEnabledRoles?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'AssigneeFieldProps' circularly referen... Remove this comment to see the full error message
type AssigneeFieldProps = OwnAssigneeFieldProps & typeof AssigneeField.defaultProps;

// @ts-expect-error TS(7022): 'AssigneeField' implicitly has type 'any' because ... Remove this comment to see the full error message
const AssigneeField = ({
  form,
  field,
  index,
  organization,
  label,
  placeholder,
  isEnabledRoles,
}: AssigneeFieldProps) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      component={DropdownInput}
      label={label}
      name={`${field}.assignedToValue`}
      input={{
        isPortaled: true,
        placeholder,
        options: getAssigneeOptions({organization, isEnabledRoles}),
        setFieldValue: form.setFieldValue,
        onChangeValue: (value: any) => {
          // There is no value when the user clears the field
          if (!value) {
            form.setFieldValue(`${field}.assignedToUserId`, '');
            form.setFieldValue(`${field}.assignedToTeamId`, '');
            form.setFieldValue(`${field}.assignedToRole`, '');
          } else {
            const [kind, id] = value.split('-');

            const isUser = id && kind === 'USER';
            const isTeam = id && kind === 'TEAM';
            const isRole = !id;

            form.setFieldValue(`${field}.assignedToUserId`, isUser ? id : '');
            form.setFieldValue(`${field}.assignedToTeamId`, isTeam ? id : '');
            form.setFieldValue(`${field}.assignedToRole`, isRole ? kind : '');
          }
        },
        style: {
          flex: 1,
        },
        isClearable: true,
      }}
    />
  );
};

type OwnDetailsFieldProps = {
  label?: string;
  placeholder?: string;
};

// @ts-expect-error TS(2456): Type alias 'DetailsFieldProps' circularly referenc... Remove this comment to see the full error message
type DetailsFieldProps = OwnDetailsFieldProps & typeof DetailsField.defaultProps;

// @ts-expect-error TS(7022): 'DetailsField' implicitly has type 'any' because i... Remove this comment to see the full error message
const DetailsField = ({form, field, index, label, placeholder}: DetailsFieldProps) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FieldContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={`${field}.details`}
        minHeight={76}
        input={{
          style: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          placeholder,
        }}
      />
    </FieldContainer>
  );
};

const TaskTemplateDrawerFields = ({form, field, organization}: any) => {
  return (
    <Container>
      <TemplateNameField form={form} field={field} index={0} />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <Subheading>Task Information</Subheading>
      <Space height={12} />
      <TaskNameField form={form} field={field} index={1} />
      <Space height={24} />
      <DueFields form={form} field={field} index={2} />
      <Space height={24} />
      <AssigneeField form={form} field={field} index={3} organization={organization} />
      <Space height={12} />
      <DetailsField form={form} field={field} index={4} />
    </Container>
  );
};

TaskTemplateDrawerFields.DueFields = DueFields;
TaskTemplateDrawerFields.AssigneeField = AssigneeField;
TaskTemplateDrawerFields.DetailsField = DetailsField;

AssigneeField.defaultProps = {
  label: 'Assignee',
  placeholder: 'Select assignee',
  isEnabledRoles: true,
};

DetailsField.defaultProps = {
  label: 'Details',
  placeholder: 'Enter any details',
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskTemplateDrawerFields.fragment = gql`
  ${Organization.getTaskTemplateAssigneeDropdownOptions.fragment}

  fragment TaskTemplateDrawerFields on Organization {
    id
    ...Organization_getTaskTemplateAssigneeDropdownOptions
  }
`;

export default TaskTemplateDrawerFields;
