/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

const SectionContainer = Styled.View`
  background-color: ${colors.white};
  border: 1px solid ${colors.gray.border};
  border-radius: 8px;
  padding-horizontal: 12px;
  margin-horizontal: ${(props) => ((props as any).mobile ? '8px' : '16px')};
  z-index: ${(props) => 100 - (props as any).index};
`;

const SectionHeader = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Toggle = Styled.ButtonV2`
  border: 1px solid ${colors.gray.border};
  height: 28px;
  width: 28px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
`;

const ToggleButton = ({isExpanded, onPress}: any) => {
  const sourceIcon = isExpanded ? Icon.ChevronUp : Icon.ChevronDown;
  return (
    <Toggle isExpanded={isExpanded} onPress={onPress}>
      <Icon color={colors.blue.interactive} size={Icon.Sizes.Medium} source={sourceIcon} />
    </Toggle>
  );
};

const ProjectJobSection = ({index, title, children}: any) => {
  const responsive = useResponsive();
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SectionContainer index={index} {...responsive}>
      <Space height={16} />
      <SectionHeader>
        {title}
        <ToggleButton isExpanded={isExpanded} onPress={() => setIsExpanded(!isExpanded)} />
      </SectionHeader>
      {isExpanded && (
        <React.Fragment>
          <Space height={12} />
          {children}
        </React.Fragment>
      )}
      <Space height={16} />
    </SectionContainer>
  );
};

export default ProjectJobSection;
