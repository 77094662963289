// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {useState, useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components
import Field from 'modules/App/components/Field';

const FieldsContainer = Styled.View`
`;

const TextInput = Styled.TextInput`
  ${Typography.Body2}
  height: 44px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'ThemeP... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.secondary : colors.gray.primary)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'ThemeP... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.disabled : colors.white)};
  padding-right: 40px;
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Container = Styled.View`
  width: 100%;
`;

const IconButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  position: relative;
  right: 32px;
`;

const Input = ({innerRef, size, style, tooltipText, secureTextEntry, ...props}: any) => {
  const [textIsHidden, setTextIsHidden] = useState(secureTextEntry);
  const {ref, isHovered} = useHover();

  return (
    <Row>
      <Container ref={ref}>
        <TextInput
          ref={innerRef}
          style={{
            ...style,
            borderColor: isHovered ? colors.Blue201 : colors.gray.tertiary,
          }}
          {...props}
          secureTextEntry={textIsHidden}
        />
      </Container>
      {secureTextEntry && (
        <IconButton onPress={() => setTextIsHidden(!textIsHidden)}>
          <Icon
            source={textIsHidden ? Icon.Eye : Icon.EyeSlash}
            color={colors.gray.secondary}
            size={16}
          />
        </IconButton>
      )}
    </Row>
  );
};

const DisabledEmailInput = ({innerRef, size, style, tooltipText, ...props}: any) => {
  const {ref} = useHover();

  return (
    <Row>
      <Container ref={ref}>
        <TextInput
          ref={innerRef}
          style={{
            ...style,
          }}
          {...props}
          disabled
        />
      </Container>
    </Row>
  );
};

const InviteOrganizationUserSignUpFields = ({form}: any) => {
  return (
    <FieldsContainer>
      <Field
        {...form}
        index={0}
        name={'inviteUserForm.email'}
        label={'Email'}
        component={DisabledEmailInput}
        input={{
          required: !_.get(form.values, 'inviteUserForm.password'),
        }}
      />
      <Space height={16} />
      <Field
        {...form}
        index={1}
        name={'inviteUserForm.password'}
        label={'Password'}
        component={Input}
        input={{
          required: !_.get(form.values, 'inviteUserForm.password'),
          secureTextEntry: true,
          autoFocus: true,
          placeholder: 'Enter a password',
        }}
      />
      <Space height={16} />
      <Field
        {...form}
        index={2}
        name={'inviteUserForm.confirmPassword'}
        label={'Confirm Password'}
        component={Input}
        input={{
          required: !_.get(form.values, 'inviteUserForm.confirmPassword'),
          secureTextEntry: true,
          placeholder: 'Confirm your password',
        }}
      />
    </FieldsContainer>
  );
};

export default InviteOrganizationUserSignUpFields;
