// Libraries
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const HeaderContainer = Styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CalendarDayText = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CalendarButtonsButton = Styled.ButtonV2`
  width: 36px;
  height: 36px;
  border: 1px;
  border-color: ${colors.gray.border}
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => ((props as any).disabled ? 'not-allowed' : 'pointer')};
`;

const DateButton = Styled.ButtonV2`
  width: 36px;
  height: 36px;
  border: 1px solid;
  border-color: ${colors.gray.border}
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

// We use a forwardRef because react-datepicker passes a ref to the
// customInput component which throws an error unless we handle it
// this way.
// @ts-expect-error TS(2339): Property 'onClick' does not exist on type '{ child... Remove this comment to see the full error message
const CustomDateButton = React.forwardRef(({onClick}, ref) => {
  // The setTimeout is to fix a bug on mobile where the calendar
  // closes immediately after opening.
  return (
    <DateButton onPress={() => setTimeout(onClick, 0)}>
      <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.Calendar} />
    </DateButton>
  );
});

const CalendarDateButton = ({navigator, selectedDate, maxDatetime}: any) => {
  return (
    <DateInput
      customInput={<CustomDateButton />}
      setFieldValue={(name, date) => {
        const setDate = Datetime.toDate(Datetime.fromDate(date));
        return navigator.push(`/dispatch/calendar/day/${setDate}`);
      }}
      offset={'-34px, 0px'}
      value={Datetime.fromDate(selectedDate)}
      maxDate={maxDatetime}
    />
  );
};

type OwnDispatchCalendarActionsProps = {
  kind: string;
};

// @ts-expect-error TS(2456): Type alias 'DispatchCalendarActionsProps' circular... Remove this comment to see the full error message
type DispatchCalendarActionsProps = OwnDispatchCalendarActionsProps &
  typeof DispatchCalendarActions.defaultProps;

// @ts-expect-error TS(7022): 'DispatchCalendarActions' implicitly has type 'any... Remove this comment to see the full error message
const DispatchCalendarActions = ({
  kind,
  calendarDayString,
  maxDaysAhead,
}: DispatchCalendarActionsProps) => {
  const responsive = useResponsive();
  const {params, navigator} = useNavigationDOM();
  const selectedDate = params.date || Datetime.toMutationDate(Datetime.today);
  const selectedDatetime = Datetime.fromDate(selectedDate).endOf('day');
  const maxDatetime = maxDaysAhead ? Datetime.today.add(maxDaysAhead, 'days') : null;
  const isFutureHidden = !!maxDatetime && selectedDatetime >= maxDatetime;

  return (
    <HeaderContainer>
      <CalendarButtonsButton
        onPress={() => {
          if (kind === 'DAY') {
            const previousDay = Datetime.previousDay(Datetime.fromDate(selectedDate));
            return navigator.push(`/dispatch/calendar/day/${previousDay}`);
          } else if (kind === 'MONTH') {
            const previousMonth = Datetime.previousMonth(Datetime.fromDate(selectedDate));
            return navigator.push(`/calendar/?date=${previousMonth}`);
          }
        }}
      >
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.ChevronLeft} />
      </CalendarButtonsButton>
      {responsive.mobile ? (
        <Space width={4} />
      ) : (
        <React.Fragment>
          <Space width={12} />
          <CalendarDayText>{calendarDayString}</CalendarDayText>
          <Space width={12} />
        </React.Fragment>
      )}
      <CalendarButtonsButton
        disabled={isFutureHidden}
        onPress={() => {
          if (kind === 'DAY') {
            const nextDay = Datetime.nextDay(Datetime.fromDate(selectedDate));
            return navigator.push(`/dispatch/calendar/day/${nextDay}`);
          } else if (kind === 'MONTH') {
            const nextMonth = Datetime.nextMonth(Datetime.fromDate(selectedDate));
            return navigator.push(`/calendar/?date=${nextMonth}`);
          }
        }}
      >
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.ChevronRight} />
      </CalendarButtonsButton>
      <Space width={4} />
      <CalendarDateButton
        selectedDate={selectedDate}
        maxDatetime={maxDatetime}
        navigator={navigator}
      />
      <Space width={28} />
      <CalendarButtonsButton
        onPress={() => {
          return navigator.push(`/settings/staff/schedules?date=${selectedDate}`);
        }}
      >
        <Icon color={colors.gray.secondary} size={Icon.Sizes.Medium} source={Icon.UserClock} />
      </CalendarButtonsButton>
    </HeaderContainer>
  );
};

DispatchCalendarActions.defaultProps = {};

export default DispatchCalendarActions;
