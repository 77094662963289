// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useQuery, useState} from '@supermove/hooks';

const getQuery = ({useClientFieldsClientSearchFragment}: any) => {
  return gql`
      ${useClientFieldsClientSearchFragment}
      query useClientFieldsClientSearch($pagination: PaginationInput!, $searchQuery: String, $slugs: [String],) {
        ${gql.query}
        viewer {
          id
          organizationForCreateProject {
            id
            filteredClientsPaginatedList(
              pagination: $pagination,
              searchQuery: $searchQuery,
              slugs: $slugs,
            ) {
              results {
                id
                ...useClientFieldsClientSearchFragment
              }
            }
          }
        }
      }
    `;
};

// This hook is setup to separate some logic out of `ClientFields`. It is only used from
// that component and therefore can utilize a fragment passed through as an argument.
// This facilitates some separation of concerns and helps the readability of the component.
const useClientFieldsClientSearch = ({useClientFieldsClientSearchFragment, pagination}: any) => {
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  const {data, loading} = useQuery(getQuery({useClientFieldsClientSearchFragment}), {
    fetchPolicy: 'cache-and-network',
    skip: !searchQuery,
    variables: {
      searchQuery,
      slugs: ['ALL_ORGANIZATIONS'],
      pagination,
    },
  });

  const clients = _.get(
    data,
    'viewer.organizationForCreateProject.filteredClientsPaginatedList.results',
    [],
  );

  return {
    clients,
    isSearching: loading,
    searchQuery,
    handleChangeSearch,
  };
};

export default useClientFieldsClientSearch;
