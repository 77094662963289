// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import DeleteContainerTypeModal from 'modules/Organization/Settings/Storage/Containers/components/DeleteContainerTypeModal';
import EditContainerTypeModal from 'modules/Organization/Settings/Storage/Containers/components/EditContainerTypeModal';

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const ActionMenuItem = ({onPress, children, submitting}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuOptionButton
        onPress={onPress}
        isHovered={isHovered}
        ref={ref}
        activeOpacity={0.8}
        disabled={submitting}
      >
        <MenuItemText>{children}</MenuItemText>
      </MenuOptionButton>
      <Space height={2} />
    </React.Fragment>
  );
};

const ContainerTypeActionsPopover = ({
  containerTypeActionsPopover,
  deleteContainerTypeModal,
  containerType,
  userId,
  refetch,
}: any) => {
  const handleOpenDeleteContainerTypeModal = () => {
    deleteContainerTypeModal.handleOpen();
    containerTypeActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={containerTypeActionsPopover.isOpen}
      handleOpen={containerTypeActionsPopover.handleOpen}
      handleClose={containerTypeActionsPopover.handleClose}
      reference={containerTypeActionsPopover.ref}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <ActionMenuItem onPress={handleOpenDeleteContainerTypeModal}>Remove</ActionMenuItem>
        <DeleteContainerTypeModal
          deleteContainerTypeModal={deleteContainerTypeModal}
          handleClose={deleteContainerTypeModal.handleClose}
          containerType={containerType}
          userId={userId}
          refetch={refetch}
        />
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const ContainerTypeActionsButton = ({containerType, userId, refetch}: any) => {
  const editContainerTypeModal = useModal({name: 'EditContainerTypeModal'});
  const containerTypeActionsPopover = usePopover();
  const deleteContainerTypeModal = useModal({name: 'DeleteContainerTypeModal'});

  return (
    <React.Fragment>
      <IconButton onPress={editContainerTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <Popover.Content innerRef={containerTypeActionsPopover.ref}>
        <IconButton onPress={containerTypeActionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={
              containerTypeActionsPopover.isOpen ? colors.blue.interactive : colors.gray.secondary
            }
            size={16}
          />
        </IconButton>
      </Popover.Content>
      <ContainerTypeActionsPopover
        key={`CONTAINER_TYPE_ACTIONS_POPOVER-${containerTypeActionsPopover.isOpen}`}
        containerTypeActionsPopover={containerTypeActionsPopover}
        deleteContainerTypeModal={deleteContainerTypeModal}
        containerType={containerType}
        userId={userId}
        refetch={refetch}
      />
      <EditContainerTypeModal
        key={editContainerTypeModal.key}
        isOpen={editContainerTypeModal.isOpen}
        handleClose={editContainerTypeModal.handleClose}
        containerType={containerType}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainerTypeActionsButton.fragment = gql`
  ${DeleteContainerTypeModal.fragment}
  fragment ContainerTypeActionsButton on ContainerType {
    id
    name
    organizationId
    ...DeleteContainerTypeModal
  }
`;

export default ContainerTypeActionsButton;
