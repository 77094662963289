// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import CommentForm from '@shared/modules/Comment/forms/CommentForm';
import useDeleteCommentMutation from '@shared/modules/Comment/hooks/useDeleteCommentMutation';

const ProjectCommentDeleteModal = ({deleteModal, comment, refetch}: any) => {
  const commentForm = CommentForm.edit(comment);

  const {handleSubmit} = useDeleteCommentMutation({
    commentForm,
    onSuccess: () => {
      refetch();
      deleteModal.handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={deleteModal.isOpen}
      title={'Are you sure you want to delete this comment?'}
      subtitle={"You can't undo this action."}
      handleDelete={handleSubmit}
      handleClose={deleteModal.handleClose}
    />
  );
};

export default ProjectCommentDeleteModal;
