// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App

import Hint from '@shared/design/components/Hint';
import UserRole from '@shared/modules/User/enums/UserRole';
import useAppContext from 'modules/App/context/useAppContext';

const JobActionDisabledTooltip = ({job, customMessage, children}: any) => {
  const {viewer} = useAppContext();
  const tooltip = UserRole.getJobActionDisabledTooltip(viewer?.role);
  const message = customMessage || (job.isFinal ? tooltip : '');

  // @ts-expect-error TS(2786): 'Hint' cannot be used as a JSX component.
  return <Hint message={message}>{children}</Hint>;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobActionDisabledTooltip.fragment = gql`
  fragment JobActionDisabledTooltip on Job {
    id
    isFinal
  }
`;

export default JobActionDisabledTooltip;
