// Libraries
import React from 'react';

// App
import {Emoji, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const Warning = Styled.View`
`;

const EmojiText = Styled.H1`
`;

const Disclosures = Styled.View`
  flex: 1;
  margin-left: 10px;
`;

const Disclosure = Styled.H7`
  width: 100%;
`;

const DisclosureBold = Styled.H7`
  ${fontWeight(700)}
`;

const getDisclosureCopy = ({responsive}: any) => {
  if (responsive.mobile) {
    return `Please note that this is a non-binding move request form. `;
  } else {
    return (
      `Please note that this is a non-binding move request form. ` +
      `We always do our best to serve you, but sometimes we may be fully booked. `
    );
  }
};

const getDisclosureBoldCopy = ({responsive}: any) => {
  if (responsive.mobile) {
    return `Your move is not confirmed until you sign a confirmation via email.`;
  } else {
    return `Your move is not confirmed until you sign a confirmation via email.`;
  }
};

const JobRequestDisclosure = () => {
  const responsive = useResponsive();

  return (
    <Container>
      <Warning>
        <Emoji component={EmojiText} name={'warning'} />
      </Warning>
      <Disclosures>
        <Disclosure>
          {getDisclosureCopy({responsive})}
          <DisclosureBold>{getDisclosureBoldCopy({responsive})}</DisclosureBold>
        </Disclosure>
      </Disclosures>
    </Container>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
JobRequestDisclosure.propTypes = {};

JobRequestDisclosure.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestDisclosure.fragment = gql`
  fragment JobRequestDisclosure on Organization {
    id
    slug
  }
`;

export default JobRequestDisclosure;
