// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useNavigationDOM, useQuery, usePagination} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const ContentContainer = Styled.View`
`;

const TableContainer = Styled.View`
  min-width: 100%;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const CellContainer = Styled.View`
  flex: 1;
`;

const CellPrimaryText = Styled.Text`
  ${Typography.Body3}
`;

const CellSecondaryText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.tertiary}
`;

const getEventLogTableColumnDefinitions = () => {
  return [
    // Date & Time
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>{'Date & Time'}</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'event' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: event}) => {
        return (
          <CellContainer>
            <CellPrimaryText>
              {Datetime.convertToDisplayDatetime(event.createdAt, Datetime.DISPLAY_SHORT_DATE)}
            </CellPrimaryText>
            <CellSecondaryText numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(event.createdAt, Datetime.DISPLAY_TIME)}
            </CellSecondaryText>
          </CellContainer>
        );
      },
    },
    // User
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>User</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'event' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: event}) => {
        return (
          <CellContainer>
            <CellPrimaryText>
              {event.creator ? event.creator.fullName : 'Supermove'}
            </CellPrimaryText>
          </CellContainer>
        );
      },
    },
    // Event
    {
      flex: 3,
      headerContent: () => {
        return <HeaderText>Event</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'event' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: event}) => {
        return (
          <CellContainer>
            <CellPrimaryText>{event.name}</CellPrimaryText>
          </CellContainer>
        );
      },
    },
    // Details
    {
      flex: 5,
      headerContent: () => {
        return <HeaderText>Details</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'event' implicitly has an 'any' ty... Remove this comment to see the full error message
      cellContent: ({item: event}) => {
        return (
          <CellContainer>
            <CellPrimaryText numberOfLines={2}>{event.description}</CellPrimaryText>
          </CellContainer>
        );
      },
    },
  ];
};

const StorageProjectEventLogList = () => {
  const {params} = useNavigationDOM();
  const [currentPage, setCurrentPage] = useState(1);
  const {data, loading} = useQuery(StorageProjectEventLogList.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid: params.projectUuid,
      pagination: {
        page: currentPage,
        resultsPerPage: 10,
      },
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'project.paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  return (
    <ScrollView>
      <ContentContainer style={{minHeight: 50, flex: 1}}>
        <ScrollView horizontal contentContainerStyle={{flex: 1}}>
          <TableContainer>
            <Table
              columnDefinitions={getEventLogTableColumnDefinitions()}
              emptyStateText='No events to display'
              items={data ? data.project.paginatedList.projectEvents : []}
            />
          </TableContainer>
        </ScrollView>
      </ContentContainer>
      <Space height={16} />
      <PaginationBar pagination={pagination} />
      <Space height={16} />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
StorageProjectEventLogList.query = gql`
  ${usePagination.fragment}

  query StorageProjectEventLogList(
    $projectUuid: String!,
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      paginatedList: filteredJobEventsPaginatedList(
        pagination: $pagination
      ) {
        projectEvents: results {
          id
          createdAt
          name
          description
          creator {
            id
            fullName
          }
        }
        paginationMetadata {
          ...usePagination
        }
      }
    }
  }
`;

export default StorageProjectEventLogList;
