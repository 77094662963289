// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, usePopover, useResponsive, useState} from '@supermove/hooks';
import {JobUser, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import TextBadge from 'modules/App/components/TextBadge';
import useAppContext from 'modules/App/context/useAppContext';
import StaffCommunicationButton from 'modules/Communication/components/StaffCommunicationButton';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import CrewUsersAndDriversPopover from 'modules/Dispatch/Crew/components/CrewUsersAndDriversPopover';
import JobRequiresDateModal from 'modules/Job/Show/components/JobRequiresDateModal';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import JobUserMoverPosition from 'modules/JobUser/components/JobUserMoverPosition';

const Container = Styled.View`
`;

const EditButtonContainer = Styled.View`
  flex-direction: row;
`;

const EditButtonTouchable = Styled.Button`
  border-radius: 4px;
  box-shadow: none;
  align-items: center;
  padding-horizontal: 8px;
  height: 28px;
`;

const EditButtonText = Styled.Text`
  ${Typography.Label}
  color: ${colors.white};
`;

const ListItemRow = Styled.View<{isFirst: boolean}>`
  flex-direction: row;
  align-items: center;
  min-height: 28px;
  border-color: ${colors.gray.border};
  border-radius: 2px;
  border-width: 1px;
  border-top-width: ${(props) => ((props as any).isFirst ? '1px' : '0px')};
  padding-vertical: 2px;
`;

const ListItemContent = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const ListItemText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
`;

const ListItemSlotText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.tertiary};
  min-width: 140px;
  font-style: italic;
`;

const JobUserStatusDotContainer = Styled.View`
  width: 31px
  align-items: center;
  justify-content: center;
`;

const JobUserStatusDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const TruckIconContainer = Styled.View`
  flex-direction: row;
  width: 48px;
`;

const TruckText = Styled.Text`
  ${Typography.Body}
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const getTruckTrip = ({job, crewSlot}: any) => {
  return _.find(job.trips, (trip) => _.includes(trip.truckIds, crewSlot.truckId));
};

const getDriverTrip = ({job, crewSlot}: any) => {
  return _.find(job.trips, (trip) => trip.driverId && trip.driverId === crewSlot.driverId);
};

const EditButton = ({children, onPress, popoverRef, disabled}: any) => {
  return (
    <EditButtonContainer>
      <Popover.Content innerRef={popoverRef}>
        <EditButtonTouchable onPress={onPress} disabled={disabled}>
          <Icon source={Icon.Edit} color={colors.white} size={14} />
          <Space width={6} />
          <EditButtonText>{children}</EditButtonText>
        </EditButtonTouchable>
      </Popover.Content>
    </EditButtonContainer>
  );
};

const TruckIcon = () => {
  return (
    <React.Fragment>
      <Space width={6} />
      <Icon source={Icon.Truck} color={colors.gray.primary} size={12} />
    </React.Fragment>
  );
};

const TripTruckIcon = () => {
  return (
    <React.Fragment>
      <Space width={8} />
      <TextTooltip
        isEnabledMobileToast={false}
        text={'A trip truck can only be removed by editing the trip.'}
      >
        <Container>
          <Icon source={Icon.Lock} color={colors.gray.primary} size={12} />
        </Container>
      </TextTooltip>
    </React.Fragment>
  );
};

const CrewSlotItem = ({crewSlot, isFirst, crew}: any) => {
  const {navigator} = useNavigationDOM();
  const tripForTruck = getTruckTrip({job: crew.job, crewSlot});
  const tripForDriver = getDriverTrip({job: crew.job, crewSlot});
  const trip = tripForTruck || tripForDriver;
  const isCrewSlotForTrip = !!trip;
  return (
    <ListItemRow isFirst={isFirst}>
      <TruckIconContainer>
        {isCrewSlotForTrip ? <TripTruckIcon /> : <TruckIcon />}
      </TruckIconContainer>
      {crewSlot.truck ? (
        <Row>
          <TruckText>
            {`${crewSlot.truck.name} ${crewSlot.truck.size ? `(${crewSlot.truck.size})` : ''}`}
          </TruckText>
          {isCrewSlotForTrip && (
            <React.Fragment>
              <Space width={5} />
              <LinkButton
                onPress={() =>
                  navigator.push(`/dispatch/long-distance/trips/${trip.uuid}/shipments`)
                }
              >
                <LinkText>{`(trip truck)`}</LinkText>
              </LinkButton>
            </React.Fragment>
          )}
          <Space width={8} />
          {crewSlot.driver && (
            <TextBadge
              text={`Driver: ${crewSlot.driver.fullName}`}
              color={colors.orange.status}
              isSmall
            />
          )}
        </Row>
      ) : (
        <ListItemSlotText>{`Slot ${crewSlot.slot.index}`}</ListItemSlotText>
      )}
    </ListItemRow>
  );
};

const CrewSlotItemEmpty = ({isFirst}: any) => {
  return (
    <ListItemRow isFirst={isFirst}>
      <Space width={8} />
      <Icon source={Icon.Truck} color={colors.gray.primary} size={12} />
    </ListItemRow>
  );
};

const CrewSlotsList = ({crew}: any) => {
  const {crewSlotsForStartDateV2} = crew;
  const numberOfNeededTrucks = Math.max(
    0,
    crew.numberOfRequiredTrucks - crewSlotsForStartDateV2.length,
  );
  const sortedCrewSlots = _.sortBy(crewSlotsForStartDateV2, ['truck.name', 'slot.index']);

  return (
    <React.Fragment>
      {sortedCrewSlots.map((crewSlot, index) => (
        <CrewSlotItem crewSlot={crewSlot} key={crewSlot.id} isFirst={index === 0} crew={crew} />
      ))}
      {_.range(numberOfNeededTrucks).map((num) => (
        <CrewSlotItemEmpty
          key={`EmptyTruck${num}`}
          isFirst={crewSlotsForStartDateV2.length === 0 && num === 0}
        />
      ))}
    </React.Fragment>
  );
};

const CrewSlotsSection = ({
  crew,
  hasDate,
  editTrucksPopover,
  jobRequiresDateModal,
  isDispatchCloseable,
}: any) => {
  return (
    <React.Fragment>
      <EditButton
        disabled={!isDispatchCloseable}
        onPress={() => {
          if (hasDate) {
            editTrucksPopover.handleToggle();
          } else {
            jobRequiresDateModal.handleOpen();
          }
        }}
        popoverRef={editTrucksPopover.ref}
      >
        Trucks
      </EditButton>
      <Space height={8} />
      <CrewSlotsList crew={crew} />
      <Space height={8} />
    </React.Fragment>
  );
};

const IconTooltip = ({text, isEnabled, children}: any) => {
  if (isEnabled) {
    return (
      <TextTooltip isEnabledMobileToast={false} text={text}>
        <Container>{children}</Container>
      </TextTooltip>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

const JobUserItem = ({jobUser, isFirst, refetch}: any) => {
  const {viewer} = useAppContext();
  const {navigator} = useNavigationDOM();
  const organizationSettingsNotification = Organization.getNotifications(jobUser.user.organization);

  return (
    <ListItemRow isFirst={isFirst}>
      <ListItemContent>
        <Space width={8} />
        <IconTooltip
          isEnabled={jobUser.isOnTrip}
          text={'A trip driver can only be removed by editing the trip.'}
        >
          <Icon
            source={jobUser.isOnTrip ? Icon.Lock : Icon.User}
            color={colors.gray.primary}
            size={12}
          />
        </IconTooltip>
        <TextTooltip isEnabledMobileToast={false} text={JobUser.getTooltipStatus(jobUser)}>
          <JobUserStatusDotContainer>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <JobUserStatusDot color={jobUser.statusColor} />
          </JobUserStatusDotContainer>
        </TextTooltip>
        <ListItemText>
          {jobUser.user.fullName}
          {jobUser.isOnTrip && (
            <React.Fragment>
              <Space width={5} />
              <LinkButton
                onPress={() =>
                  navigator.push(`/dispatch/long-distance/trips/${jobUser.trip.uuid}/shipments`)
                }
              >
                <LinkText>{`(trip driver)`}</LinkText>
              </LinkButton>
            </React.Fragment>
          )}
        </ListItemText>
        <Space width={8} />
        <JobUserMoverPosition jobUser={jobUser} refetch={refetch} />
        <Space width={8} />
      </ListItemContent>
      <StaffCommunicationButton
        user={jobUser.user}
        viewerRole={viewer?.role}
        refetch={refetch}
        isDisabled={!(organizationSettingsNotification as any).allowEmployeeText}
        icon={Icon.CommentSms}
        iconSize={16}
        iconColor={colors.gray.secondary}
      />
      <Space width={8} />
    </ListItemRow>
  );
};

const JobUserItemEmpty = ({isFirst}: any) => {
  return (
    <ListItemRow isFirst={isFirst}>
      <Space width={8} />
      <Icon source={Icon.User} color={colors.gray.primary} size={12} />
    </ListItemRow>
  );
};

const JobUsersList = ({crew, refetch}: any) => {
  const numberOfNeededMovers = Math.max(0, crew.numberOfRequiredMovers - crew.jobUsers.length);
  const sortedJobUsers = _.sortBy(crew.jobUsers, [`user.fullName`]);

  return (
    <React.Fragment>
      {sortedJobUsers.map((jobUser, index) => (
        <JobUserItem key={jobUser.id} isFirst={index === 0} jobUser={jobUser} refetch={refetch} />
      ))}
      {_.range(numberOfNeededMovers).map((num) => (
        <JobUserItemEmpty
          key={`EmptyMover${num}`}
          isFirst={crew.jobUsers.length === 0 && num === 0}
        />
      ))}
    </React.Fragment>
  );
};

const JobUsersSection = ({
  crew,
  hasDate,
  editMoversPopover,
  jobRequiresDateModal,
  isDispatchCloseable,
  refetch,
}: any) => {
  return (
    <React.Fragment>
      <EditButton
        disabled={!isDispatchCloseable}
        onPress={() => {
          if (hasDate) {
            editMoversPopover.handleToggle();
          } else {
            jobRequiresDateModal.handleOpen();
          }
        }}
        popoverRef={editMoversPopover.ref}
      >
        Movers
      </EditButton>
      <Space height={8} />
      <JobUsersList crew={crew} refetch={refetch} />
      <Space height={8} />
    </React.Fragment>
  );
};

const CrewDispatchSection = ({crew, refetch, showTrucks, showMovers}: any) => {
  const responsive = useResponsive();
  const editMoversPopover = usePopover();
  const editTrucksPopover = usePopover();
  const jobJobUsersScheduleModal = useModal();
  const jobRequiresDateModal = useModal();
  const hasDate = !!_.get(crew, 'job.day.value') || !!_.get(crew, 'job.startDate');
  const [isDispatchCloseable, setIsDispatchCloseable] = useState(true);

  return (
    <Container {...responsive}>
      {showTrucks && (
        <CrewSlotsSection
          crew={crew}
          hasDate={hasDate}
          editTrucksPopover={editTrucksPopover}
          jobRequiresDateModal={jobRequiresDateModal}
          isDispatchCloseable={isDispatchCloseable}
        />
      )}
      {showMovers && (
        <JobUsersSection
          crew={crew}
          hasDate={hasDate}
          editMoversPopover={editMoversPopover}
          jobRequiresDateModal={jobRequiresDateModal}
          isDispatchCloseable={isDispatchCloseable}
          refetch={refetch}
        />
      )}
      <CrewUsersAndDriversPopover
        isOpen={editMoversPopover.isOpen}
        key={`${editMoversPopover.isOpen}-MOVERS`}
        handleOpen={editMoversPopover.handleOpen}
        handleClose={editMoversPopover.handleClose}
        popoverRef={editMoversPopover.ref}
        crew={crew}
        placement={Popover.Positions.RightStart}
        refetch={refetch}
        offset={[0, 4]}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        isDispatchCloseable={isDispatchCloseable}
        setIsDispatchCloseable={setIsDispatchCloseable}
      />
      <CrewTrucksPopover
        isOpen={editTrucksPopover.isOpen}
        key={`${editTrucksPopover.isOpen}-TRUCKS`}
        handleOpen={editTrucksPopover.handleOpen}
        handleClose={editTrucksPopover.handleClose}
        popoverRef={editTrucksPopover.ref}
        crewId={crew.id}
        offset={[0, 4]}
        refetch={refetch}
        placement={Popover.Positions.RightStart}
        isDispatchCloseable={isDispatchCloseable}
        setIsDispatchCloseable={setIsDispatchCloseable}
      />
      <JobJobUsersScheduleModal
        isOpen={jobJobUsersScheduleModal.isOpen}
        handleClose={() => {
          refetch();
          jobJobUsersScheduleModal.handleClose();
        }}
        jobUuid={crew.job.uuid}
      />
      <JobRequiresDateModal
        job={crew.job}
        isOpen={jobRequiresDateModal.isOpen}
        handleClose={jobRequiresDateModal.handleClose}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewDispatchSection.fragment = gql`
  ${CrewUsersAndDriversPopover.fragment}
  ${JobRequiresDateModal.fragment}
  ${JobUser.getMoverPositionsKey.fragment}
  ${JobUser.getTooltipStatus.fragment}
  ${JobUserMoverPosition.fragment}
  ${StaffCommunicationButton.fragment}
  ${Organization.getNotifications.fragment}

  fragment CrewDispatchSection on Crew {
    id
    numberOfRequiredTrucks
    numberOfRequiredMovers
    crewSlotsForStartDateV2 {
      id
      driverId
      truckId
      truck {
        id
        name
        size
      }
      slot {
        id
        index
      }
      driver {
        id
        fullName
      }
    }
    jobUsers {
      id
      statusColor
      isOnTrip
      userId
      user {
        id
        fullName
        organization {
          id
          ...Organization_getNotifications
        }
        ...StaffCommunicationButton
      }
      trip {
        id
        uuid
      }
      ...JobUser_getMoverPositionsKey
      ...JobUserMoverPosition
      ...JobUser_getTooltipStatus
    }
    job {
      id
      uuid
      startDate
      day {
        id
        value
      }
      trips {
        id
        uuid
        driverId
        truckIds
      }
      ...JobRequiresDateModal
    }
    ...CrewUsersAndDriversPopover
  }
`;

export default CrewDispatchSection;
