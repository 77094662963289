// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useCallback, useState} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const PayEngineLoaderContainer = Styled.View`
  position: absolute;
  width: 100%;
`;

const PayEngineLoader = () => {
  return (
    <PayEngineLoaderContainer>
      <SkeletonLoader height={50} />
      <Space height={8} />
      <Row>
        <SkeletonLoader height={50} style={{flex: 1}} />
        <Space width={8} />
        <SkeletonLoader height={50} style={{flex: 1}} />
        <Space width={8} />
        <SkeletonLoader height={50} style={{flex: 1}} />
      </Row>
    </PayEngineLoaderContainer>
  );
};

const ClientCreditCardInformationFields = ({client, setCreditCardClient}: any) => {
  const [isFormLoaded, setIsFormLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  return (
    <Container>
      <FieldInput.LabelText isResponsive isRequired>
        Card Information
      </FieldInput.LabelText>
      <Space height={4} />
      <Container>
        <PayEngineCreditCardInput
          mode={client.organization.mode}
          setCreditCardClient={setCreditCardClient}
          onLoad={onLoad}
          // NOTE(dan) It looks like the inner components have a margin of 4, so
          // the -4 margin is to offset that.
          styleWeb={{margin: -4, height: null}}
        />
        {!isFormLoaded && <PayEngineLoader />}
      </Container>
    </Container>
  );
};

ClientCreditCardInformationFields.fragment = gql`
  fragment ClientCreditCardInformationFields on Client {
    id
    organization {
      id
      mode
    }
  }
`;

export default ClientCreditCardInformationFields;
