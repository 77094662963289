// Libraries
import React from 'react';

// Supermove
import {Emoji, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components
import DateFieldComponent from '@shared/design/components/Field/DateField';
import ProjectFollowUpDateForm from '@shared/modules/Project/forms/ProjectFollowUpDateForm';
import useUpdateProjectFollowUpDateMutation from '@shared/modules/Project/hooks/useUpdateProjectFollowUpDateMutation';
import JobOperationsStatusBadge from 'modules/Job/components/JobOperationsStatusBadge';
import JobTestBadge from 'modules/Job/components/JobTestBadge';
import ProjectSalesStatusBadge from 'modules/Project/components/ProjectSalesStatusBadge';

const Container = Styled.View`
  align-self: stretch;
  position: relative;
  z-index: ${(props) => 1000 - (props as any).sectionIndex};
`;

const PageContainer = Styled.View`
  padding-vertical: 8px;
  flex-direction: row;
  position: relative;
`;

const NameRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectName = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
  margin-top: 10px;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const JobName = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const OrganizationName = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const ProjectTypeName = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const Badges = Styled.View`
  align-items: flex-start;
  margin-top: 10px;
`;

const Background = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
  background-color: ${colors.gray.background};
  padding-horizontal: 12px;
  padding-vertical: 8px;
  border-radius: 5px;
  border: 1px solid ${colors.gray.background};
  box-sizing: border-box;
  border-radius: 4px;
`;

const Label = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
  padding-vertical: 4px;
`;

const FollowUpDatePicker = ({project, index}: any) => {
  const projectFollowUpDateForm = ProjectFollowUpDateForm.edit(project);
  const {form, handleSubmit} = useUpdateProjectFollowUpDateMutation({
    projectFollowUpDateForm,
    onSuccess: () => {},
    onError: () => {},
  });

  return (
    <PageContainer>
      <Label>Follow Up On</Label>
      <Space width={8} />
      <DateFieldComponent.Small
        form={form}
        index={index}
        field={`projectFollowUpDateForm.followUpDate`}
        onChangeDate={(name: any, followUpDate: any) => {
          form.setFieldValue(name, followUpDate);
          setTimeout(() => handleSubmit(), 0);
        }}
        isPortaled
      />
    </PageContainer>
  );
};

// TODO(peter): Add the new sales status badge
const ProjectInfoStatusSection = ({job, sectionIndex}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Background sectionIndex={sectionIndex}>
      <ProjectTypeName color={job.project.projectType.color}>
        {job.project.projectType.name}
      </ProjectTypeName>
      <Space height={2} />
      <NameRow>
        {job.hasPacking && <Emoji component={EmojiText} name={'package'} />}
        <ProjectName>{Project.getDisplayText(job.project, job.name)}</ProjectName>
      </NameRow>
      {job.project.organization.features.isEnabledSalesModule && (
        <Badges>
          <ProjectSalesStatusBadge project={job.project} />
        </Badges>
      )}
      {job.project.organization.features.isEnabledProjectDetailsFollowUp && (
        <FollowUpDatePicker project={job.project} index={1} />
      )}
    </Background>
  );
};

// TODO(peter): Add the new operations status badge
const JobInfoStatusSection = ({job, updatedAt, sectionIndex}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Background sectionIndex={sectionIndex}>
      <OrganizationName>{job.organization.name}</OrganizationName>
      <Space height={2} />
      <JobName>{Job.getFullName(job)}</JobName>
      {job.jobType.kind !== 'ESTIMATE' && (
        <Badges>
          <JobOperationsStatusBadge job={job} />
        </Badges>
      )}
      {!!updatedAt && <Subtitle>{`Last synced at: ${Datetime.toDisplayTime(updatedAt)}`}</Subtitle>}
    </Background>
  );
};

type OwnJobStatusSectionProps = {
  job: any;
  updatedAt?: any;
  sectionIndex?: number;
};

// @ts-expect-error TS(2456): Type alias 'JobStatusSectionProps' circularly refe... Remove this comment to see the full error message
type JobStatusSectionProps = OwnJobStatusSectionProps & typeof JobStatusSection.defaultProps;

// @ts-expect-error TS(7022): 'JobStatusSection' implicitly has type 'any' becau... Remove this comment to see the full error message
const JobStatusSection = ({job, updatedAt, sectionIndex}: JobStatusSectionProps) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container responsive={responsive} sectionIndex={sectionIndex}>
      {job.isTest && (
        <React.Fragment>
          <JobTestBadge />
          <Space height={8} />
        </React.Fragment>
      )}
      <ProjectInfoStatusSection job={job} sectionIndex={sectionIndex} />
      <Space height={8} />
      <JobInfoStatusSection job={job} updatedAt={updatedAt} sectionIndex={sectionIndex + 1} />
    </Container>
  );
};

JobStatusSection.defaultProps = {
  updatedAt: null,
  sectionIndex: 0,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobStatusSection.fragment = gql`
  ${Job.getFullName.fragment}
  ${Project.getDisplayText.fragment}
  ${JobOperationsStatusBadge.fragment}
  ${ProjectSalesStatusBadge.fragment}

  fragment JobStatusSection on Job {
    id
    isTest
    displayName
    hasPacking
    jobType {
      id
      kind
    }
    organization {
      id
      name
    }
    project {
      id
      followUpDate
      organization {
        id
        features {
          isEnabledSalesModule: isEnabled(feature: "SALES_MODULE")
          isEnabledProjectDetailsFollowUp: isEnabled(feature: "PROJECT_DETAILS_FOLLOW_UP")
        }
      }
      projectType {
        id
        name
        color
      }
      ...Project_getDisplayText
      ...ProjectSalesStatusBadge
    }
    ...Job_getFullName
    ...JobOperationsStatusBadge
  }
`;

export default JobStatusSection;
