// Libraries
import React from 'react';

// Components
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

import {ReportMoveTimeRangesChart, ReportMoveTimeRangesList} from 'modules/ReportMove/components';

const Container = Styled.View`
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
`;

type OwnProps = {
  reportMove: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ReportMoveTimeRangesReference.defaultProps;

// @ts-expect-error TS(7022): 'ReportMoveTimeRangesReference' implicitly has typ... Remove this comment to see the full error message
const ReportMoveTimeRangesReference = ({reportMove}: Props) => (
  <Container>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={0}>
      <Title>Work Timesheet (for reference)</Title>
    </Row>
    <RowSpace />
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={1}>
      <ReportMoveTimeRangesChart timeRanges={reportMove.timeRanges} />
    </Row>
    <RowSpace />
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Row index={2}>
      <ReportMoveTimeRangesList timeRanges={reportMove.timeRanges} />
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveTimeRangesReference.fragment = gql`
  fragment ReportMoveTimeRangesReference on ReportMove {
    id
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

ReportMoveTimeRangesReference.defaultProps = {};

export default ReportMoveTimeRangesReference;
