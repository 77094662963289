// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useState, useNavigationDOM} from '@supermove/hooks';
import {Invoice} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Drawer from '@shared/design/components/Drawer';
import FieldValue from '@shared/design/components/Field/FieldValue';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import InvoiceActions from 'modules/Accounting/components/InvoiceActions';
import InvoiceBillingActionButtons from 'modules/Accounting/components/InvoiceBillingActionButtons';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectBills from 'modules/Project/V2/Show/components/ProjectBills';
import InvoiceExportStatusWithDate from 'modules/Storage/components/InvoiceExportStatusWithDate';

const Row = Styled.View`
  flex-direction: row;
`;

const ExportStatusContainer = Styled.View`
  flex: 1;
`;

const FooterContainer = Styled.View`
  padding: 16px;
`;

const DesktopSubheading = Styled.Text`
  ${Typography.Subheading};
`;

const getDisplayDate = (date: any) =>
  date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : null;

const ActionMenuButton = ({onPress}: any) => {
  return (
    <Button
      onPress={onPress}
      iconRight={Icon.ChevronDown}
      text={'Actions'}
      isResponsive
      isWidthOfContainer
    />
  );
};

const EmailErrorCallout = () => {
  const responsive = useResponsive();

  return (
    <ErrorCallout>
      <React.Fragment>
        <ErrorCallout.Text>{'Invoice failed to send.  '}</ErrorCallout.Text>
        <a href={Invoice.EMAIL_ERROR_HELP_LINK} target={'_blank'}>
          <ErrorCallout.LinkText responsive={responsive}>View help article</ErrorCallout.LinkText>
        </a>
      </React.Fragment>
    </ErrorCallout>
  );
};

const InvoiceSummaryInformation = ({invoice}: any) => {
  const {date, dueDate, paymentTerm} = invoice;
  const invoiceExport = invoice.invoiceExport[0];
  return (
    <React.Fragment>
      <Row>
        <FieldValue
          label={'Invoice Date'}
          value={getDisplayDate(date)}
          empty={'None'}
          isResponsive
          style={{flex: 1}}
        />
        <FieldValue
          label={'Due Date'}
          value={getDisplayDate(dueDate)}
          empty={'None'}
          isResponsive
          style={{flex: 1}}
        />
      </Row>
      <Space height={16} />
      <Row>
        <FieldValue
          label={'Payment Terms'}
          value={InvoicePaymentTerm.getDisplayName(paymentTerm)}
          empty={'None'}
          isResponsive
          style={{flex: 1}}
        />
        <ExportStatusContainer>
          <FieldValue label={'Export Status'} isResponsive empty={invoiceExport ? null : 'None'} />
          {invoiceExport && <InvoiceExportStatusWithDate invoice={invoice} showDate />}
        </ExportStatusContainer>
      </Row>
    </React.Fragment>
  );
};

const ViewInvoiceDrawerContent = ({invoice, refetch, navigator, isDesktop}: any) => {
  const descriptionField = 'description';
  const getTruncatedKey = ({billItemId}: any) =>
    `BILL_ITEM_${billItemId}_${descriptionField.toUpperCase()}`;
  const [truncated, setTruncated] = useState({});
  const [enabledToggle, setEnabledToggle] = useState({});
  const [isExpandAllBills, setIsExpandAllBills] = useState(true);

  return (
    <Drawer.Body>
      {invoice.hasEmailSendError && (
        <React.Fragment>
          <EmailErrorCallout />
          <Space height={16} />
        </React.Fragment>
      )}
      {isDesktop && (
        <React.Fragment>
          <Row>
            <DesktopSubheading>{`Invoice ${invoice.identifier}`}</DesktopSubheading>
            <Space style={{flex: 1}} />
            <InvoiceActions
              key={`INVOICE_ACTION-${invoice.id}`}
              invoice={invoice}
              navigator={navigator}
              refetch={refetch}
              isTertiaryButton
            />
          </Row>
          <Space height={8} />
        </React.Fragment>
      )}
      <InvoiceSummaryInformation invoice={invoice} />
      <Space height={16} />
      <Line />
      <Space height={16} />
      <ProjectBills
        project={invoice.project}
        refetch={refetch}
        descriptionField={descriptionField}
        getTruncatedKey={getTruncatedKey}
        truncated={truncated}
        setTruncated={setTruncated}
        enabledToggle={enabledToggle}
        setEnabledToggle={setEnabledToggle}
        isExpandAllBills={isExpandAllBills}
        setIsExpandAllBills={setIsExpandAllBills}
        isEnabledPaymentActions={isDesktop}
      />
    </Drawer.Body>
  );
};

const ViewInvoiceDrawerV1 = ({invoiceUuid, invoiceIdentifier, isOpen, handleClose}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const {data, loading, refetch} = useQuery(ViewInvoiceDrawerV1.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {invoiceUuid},
  });

  return (
    <Drawer
      shouldCloseOnClickOutside={false}
      // @ts-expect-error TS(2322): Type 'number | null' is not assignable to type 'nu... Remove this comment to see the full error message
      width={responsive.desktop ? 800 : null}
      isOpen={isOpen}
      handleClose={handleClose}
    >
      {responsive.desktop ? (
        // @ts-expect-error TS(2741): Property 'isResponsive' is missing in type '{ head... Remove this comment to see the full error message
        <Drawer.Header headerText={`View Invoice`} handleClose={handleClose} isClosable />
      ) : (
        <Drawer.HeaderMobile
          headerText={`Invoice ${invoiceIdentifier}`}
          handleClose={handleClose}
        />
      )}
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <React.Fragment>
              <ViewInvoiceDrawerContent
                invoice={data.invoice}
                refetch={refetch}
                isDesktop={responsive.desktop}
                navigator={navigator}
              />
              {!responsive.desktop && (
                <React.Fragment>
                  <Line />
                  <FooterContainer>
                    <InvoiceBillingActionButtons
                      invoice={data.invoice}
                      refetch={refetch}
                      isVisibleInvoiceActions
                      ActionMenuButtonComponent={ActionMenuButton}
                    />
                  </FooterContainer>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewInvoiceDrawerV1.query = gql`
  ${InvoiceBillingActionButtons.fragment}
  ${InvoiceExportStatusWithDate.fragment}
  ${ProjectBills.fragment}
  ${InvoiceActions.fragment}

  query ListProjectsPageContent($invoiceUuid: String!) {
    ${gql.query}
    invoice: invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      uuid
      date
      identifier
      dueDate
      paymentTerm
      hasEmailSendError
      project {
        id
        ...ProjectBills
      }
      invoiceExport {
        id
      }
      ...InvoiceBillingActionButtons
      ...InvoiceActions
      ...InvoiceExportStatusWithDate
    }
  }
  `;

export default ViewInvoiceDrawerV1;
