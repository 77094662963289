// Libraries
import React from 'react';

// Supermove
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import SignConfirmationDocumentMutation from 'modules/Customer/Project/Confirmation/Document/components/SignConfirmationDocumentMutation';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SignProjectConfirmationDocumentForm.defaultProps;

// @ts-expect-error TS(7022): 'SignProjectConfirmationDocumentForm' implicitly h... Remove this comment to see the full error message
const SignProjectConfirmationDocumentForm = ({project, onSuccess, onError, children}: Props) => (
  <Form
    initialValues={{
      isFinished: false,
      isSignatureValid: false,
      // Mutation fields.
      confirmationId: project.confirmation.id,
      date: Datetime.toDate(Datetime.today),
      image: null,
    }}
    onSubmit={() => {}}
  >
    {({values, submitForm, setMutationErrors, ...form}: any) => (
      <SignConfirmationDocumentMutation
        values={values}
        onSuccess={() => form.setFieldValue('isFinished', true)}
        onError={onError}
        setMutationErrors={setMutationErrors}
        submitForm={submitForm}
      >
        {({loading, handleSubmit}: any) =>
          children({
            ...form,
            loading,
            values,
            handleSubmit,
          })
        }
      </SignConfirmationDocumentMutation>
    )}
  </Form>
);

SignProjectConfirmationDocumentForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SignProjectConfirmationDocumentForm.fragment = gql`
  fragment SignProjectConfirmationDocumentForm on Project {
    id
    confirmation {
      id
      isComplete
      nextStep {
        kind
        value
      }
    }
  }
`;

export default SignProjectConfirmationDocumentForm;
