// Libararies
import React from 'react';

// Supermove
import {ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState} from '@supermove/hooks';

// App
import Sheet from '@shared/design/components/Sheet';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import useUpdateValuesMutation from '@shared/modules/Billing/hooks/useUpdateValuesMutation';
import EditBillingValuesModal from 'modules/Project/Billing/components/EditBillingValuesModal';
import ProjectOverviewContentMobile from 'modules/Project/V2/Show/components/ProjectOverviewContentMobile';

const ProjectOverviewSheet = ({
  project,
  isOpen,
  handleClose,
  refetch,
  refetchAndReset,
  urlFilters,
}: any) => {
  const [shouldCloseOverviewSheetOnClickOutside, setShouldCloseOverviewSheetOnClickOutside] =
    useState(false);

  const editBillingValuesModal = useModal({name: 'Edit Billing Values Modal'});
  const updateValuesForm = UpdateValuesForm.edit(project);
  const updateValuesMutation = useUpdateValuesMutation({
    updateValuesForm,
    onSuccess: () => {
      editBillingValuesModal.handleClose();
      refetch();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    <React.Fragment>
      <Sheet
        headerText={`Project ${project.identifier}`}
        isOpen={isOpen}
        shouldCloseOnClickOutside={shouldCloseOverviewSheetOnClickOutside}
        handleClose={handleClose}
      >
        <ScrollView
          contentContainerStyle={{
            paddingHorizontal: 16,
            paddingBottom: 16,
          }}
        >
          <ProjectOverviewContentMobile
            project={project}
            urlFilters={urlFilters}
            refetchAndReset={refetchAndReset}
            setShouldCloseOverviewSheetOnClickOutside={setShouldCloseOverviewSheetOnClickOutside}
            handleClose={handleClose}
            editBillingValuesModal={editBillingValuesModal}
          />
        </ScrollView>
      </Sheet>
      <EditBillingValuesModal
        key={editBillingValuesModal.key}
        isOpen={editBillingValuesModal.isOpen}
        handleClose={editBillingValuesModal.handleClose}
        updateValuesForm={updateValuesMutation.form}
        handleSubmit={updateValuesMutation.handleSubmit}
        projectUuid={project.uuid}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectOverviewSheet.fragment = gql`
  ${ProjectOverviewContentMobile.fragment}
  ${UpdateValuesForm.edit.fragment}

  fragment ProjectOverviewSheet on Project {
    id
    ...ProjectOverviewContentMobile
    ...UpdateValuesForm_edit
  }
`;

export default ProjectOverviewSheet;
