// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {InventoryLibrary} from '@supermove/models';

// App
import InventoryLibraryObjectKind from '@shared/modules/Inventory/enums/InventoryLibraryObjectKind';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import CartonAndItemsContent from 'modules/Organization/Settings/Inventory/components/CartonAndItemsContent';
import ImportInventoryLibraryItemTypesModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryItemTypesModal';
import InventoryLibrarySettingsPage from 'modules/Organization/Settings/Inventory/components/InventoryLibrarySettingsPage';

const getCsvActions = ({importInventoryLibraryCartonsModal, inventoryLibrary}: any) => {
  return [
    {
      text: 'Import via CSV',
      onPress: importInventoryLibraryCartonsModal.handleOpen,
    },
    {
      text: 'Export via CSV',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.CARTONS,
        isTemplate: false,
      }),
    },
    {
      text: 'Download CSV template',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.CARTONS,
        isTemplate: true,
      }),
    },
  ];
};

const ItemsPageContent = ({viewerId, inventoryLibrary, refetch}: any) => {
  const importInventoryLibraryCartonsModal = useModal({
    name: 'Import Inventory Library Cartons Modal',
  });
  const csvActions = getCsvActions({importInventoryLibraryCartonsModal, inventoryLibrary});
  return (
    <React.Fragment>
      <CartonAndItemsContent
        inventoryLibrary={inventoryLibrary}
        itemTypeKind={ItemTypeKind.CARTON}
        itemTypes={inventoryLibrary.cartonItemTypes}
        csvActions={csvActions}
        title={'Cartons'}
        emptyStateText={`Click “Edit” to create a carton and it'll appear here.`}
        refetch={refetch}
      />
      <ImportInventoryLibraryItemTypesModal
        key={importInventoryLibraryCartonsModal.key}
        viewerId={viewerId}
        organizationId={inventoryLibrary.organizationId}
        inventoryLibraryId={inventoryLibrary.id}
        itemTypeKind={ItemTypeKind.CARTON}
        isOpen={importInventoryLibraryCartonsModal.isOpen}
        handleClose={importInventoryLibraryCartonsModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const InventoryLibraryCartonsPage = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(InventoryLibraryCartonsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid: params.libraryUuid,
    },
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <InventoryLibrarySettingsPage
      isLoading={loading}
      inventoryLibraryUuid={params.libraryUuid}
      inventoryLibraryName={data ? data.inventoryLibrary.name : ''}
      inventoryLibraryKind={data ? data.inventoryLibrary.kind : ''}
    >
      {data && (
        <ItemsPageContent
          key={refreshKey}
          inventoryLibrary={data.inventoryLibrary}
          viewerId={data.viewer.id}
          refetch={refetchWithKeyUpdate}
        />
      )}
    </InventoryLibrarySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryLibraryCartonsPage.query = gql`
  ${CartonAndItemsContent.fragment}
  ${InventoryLibrary.handleFileDownload.fragment}

  query InventoryLibraryCartonsPage($inventoryLibraryUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      kind
      name
      organizationId
      cartonItemTypes {
        id
        ...CartonAndItemsContent_ItemType
      }
      ...CartonAndItemsContent_InventoryLibrary
      ...InventoryLibrary_handleFileDownload
    }
  }
`;

export default InventoryLibraryCartonsPage;
