// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, LocationInput, PhoneInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import ClientForm from '@shared/modules/Client/forms/ClientForm';
import ClientSearchDropdownInput from 'modules/Client/components/ClientSearchDropdownInput';
import useClientFieldsClientSearch from 'modules/Client/hooks/useClientFieldsClientSearch';

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const CheckboxText = Styled.Text`
  ${Typography.Body}
`;

const getDerivedClientFromForm = ({form, field}: any) => {
  const clientForm = _.get(form.values, `${field}`);
  // Copies the shape of the data that we expect to see from the query
  return {
    id: '',
    name: clientForm.name,
    primaryContact: {
      fullName: clientForm.primaryContactForm.names.join(' '),
      firstNameV2: clientForm.primaryContactForm.names[0],
      lastNameV2: clientForm.primaryContactForm.names[1],
      phoneNumber: clientForm.primaryContactForm.phoneNumber,
      email: clientForm.primaryContactForm.email,
    },
  };
};

const getClientOptions = ({form, field, clients, getOptionLabel}: any) => {
  const newClient = getDerivedClientFromForm({form, field});
  const clientOptions = [...clients, newClient].map((client) => {
    return {
      client,
      label: getOptionLabel(client),
      value: client.id,
    };
  });
  return clientOptions;
};

const handleCheckDuplicatePhoneNumber = ({form, field, clients, isFocusedField}: any) => {
  const phoneNumber = _.get(form.values, `${field}.primaryContactForm.phoneNumber`);
  const isDuplicatePhoneNumber = _.get(form.values, `${field}.isDuplicatePhoneNumber`);
  const isMatchingPhoneNumberFound = !!_.find(clients, (client) => {
    return client.primaryContact.phoneNumber === phoneNumber;
  });
  // We block unnecessarily setting the form value in order to prevent rerenders.
  if (isMatchingPhoneNumberFound !== isDuplicatePhoneNumber) {
    // The duplicate phone number flag can be set to true even if the phone number
    // input is not focused. This handles the case for when new client information
    // is pre-loaded onto the create project page, eg. when converting a job request
    // into a project.
    if (isFocusedField || isMatchingPhoneNumberFound) {
      form.setFieldValue(`${field}.isDuplicatePhoneNumber`, isMatchingPhoneNumberFound);
    }
  }
};

const handleOnLocation = ({
  form,
  field,
  address,
  city,
  zipCode,
  latitude,
  longitude,
  state,
  country,
}: any) => {
  const locationForm = _.get(form.values, field);
  form.setFieldValue(field, {
    ...locationForm,
    address,
    city,
    zipCode,
    latitude,
    longitude,
    state,
    country,
  });
};

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const ResetClientButton = ({form, field, organizationId}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon source={Icon.Times} size={16} color={colors.blue.interactive} />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(
            field,
            ClientForm.toForm(ClientForm.new({organizationId, isOkayDuplicatePhoneNumber: true})),
          );
        }}
      >
        <ButtonText>Reset client information</ButtonText>
      </Button>
    </Row>
  );
};

const CompanyNameToggle = ({form, field}: any) => {
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isShowingName ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isShowingName`, !isShowingName);
          form.setFieldValue(`${field}.name`, '');
        }}
      >
        <ButtonText>{isShowingName ? 'Remove company name' : 'Add company name'}</ButtonText>
      </Button>
    </Row>
  );
};

const ClientActionButton = ({
  form,
  field,
  organization,
  canSearchClients,
  hasSelectedClient,
  isShowingBillingClient,
}: any) => {
  const isShowingClientActionButton =
    !_.get(form.values, `${field.split('.')[0]}.isSameBillingClient`) || !isShowingBillingClient;
  if (canSearchClients && hasSelectedClient && isShowingClientActionButton) {
    return (
      <React.Fragment>
        {isShowingBillingClient && <Space width={16} />}
        <ResetClientButton form={form} field={field} organizationId={organization.id} />
      </React.Fragment>
    );
  } else if (isShowingClientActionButton) {
    return <CompanyNameToggle form={form} field={field} />;
  }
  return null;
};

const HeaderRow = ({
  form,
  field,
  organization,
  canSearchClients,
  hasSelectedClient,
  disabled,
  headerLabel,
  isShowingBillingClient,
}: any) => {
  const responsive = useResponsive();
  const parentField = field.split('.')[0];
  const sameAsClient = _.get(form.values, `${parentField}.isSameBillingClient`);
  const [isInitiallySameAsClient] = useState(sameAsClient);
  const [initialClientForm] = useState(_.get(form.values, field));
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={0} {...responsive}>
        <Modal.BlockHeader>{`${
          headerLabel || isShowingBillingClient ? 'Billing Information' : 'Client Information'
        }`}</Modal.BlockHeader>
        {responsive.mobile ? <FieldSpace /> : <Space style={{flex: 1}} />}
        {!disabled && (
          <ClientActionButton
            form={form}
            field={field}
            organization={organization}
            canSearchClients={canSearchClients}
            hasSelectedClient={hasSelectedClient}
            isShowingBillingClient={isShowingBillingClient}
          />
        )}
      </Row>
      {isShowingBillingClient && (
        <React.Fragment>
          <FieldSpace />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Row index={1} {...responsive}>
            <Checkbox
              isChecked={sameAsClient}
              handleToggle={(checked) => {
                const parentField = field.split('.')[0];
                form.setFieldValue(`${parentField}.isSameBillingClient`, checked);
                if (!checked) {
                  if (!isInitiallySameAsClient) {
                    form.setFieldValue(field, initialClientForm);
                  } else {
                    form.setFieldValue(
                      field,
                      ClientForm.toForm(
                        ClientForm.new({
                          organizationId: organization.id,
                          isOkayDuplicatePhoneNumber: true,
                        }),
                      ),
                    );
                  }
                }
              }}
              childrenLeft
            >
              <CheckboxText>Same as Client</CheckboxText>
              <Space width={8} />
            </Checkbox>
          </Row>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const CompanyNameRow = ({
  index,
  form,
  field,
  clients,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row {...responsive} index={index}>
      <ClientSearchDropdownInput
        index={0}
        options={getClientOptions({
          form,
          field,
          clients,
          getOptionLabel: (client: any) => client.name,
        })}
        form={form}
        field={field}
        name={'name'}
        label={'Company Name'}
        placeholder={'Company name'}
        loading={loading}
        disabled={disabled}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={(clientName: any) => {
          handleChangeSearch(clientName);
          form.setFieldValue(`${field}.name`, clientName);
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const ContactNameRow = ({
  index,
  form,
  field,
  clients,
  organization,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
}: any) => {
  const responsive = useResponsive();
  const {shouldShowSingleNameCustomerField} = form.values;
  const contactField = `${field}.primaryContactForm`;
  const contactFirstName = _.get(form.values, `${contactField}.names.0`);
  const contactLastName = _.get(form.values, `${contactField}.names.1`);
  const isLastNameRequired = organization.features.isEnabledProjectCustomerLastNameRequired;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row {...responsive} index={index} data-test-id='form-client-name-row'>
      <ClientSearchDropdownInput
        index={0}
        options={getClientOptions({
          form,
          field,
          clients,
          getOptionLabel: (client: any) => client.primaryContact.firstNameV2,
        })}
        form={form}
        field={field}
        name={`primaryContactForm.names.0`}
        label={shouldShowSingleNameCustomerField ? 'Contact Name' : 'Contact First Name'}
        placeholder={shouldShowSingleNameCustomerField ? 'Contact name' : 'Contact first name'}
        loading={loading}
        disabled={disabled}
        isRequired={!contactFirstName}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={(firstName: any) => {
          handleChangeSearch(firstName);
          form.setFieldValue(`${field}.primaryContactForm.names.0`, firstName);
        }}
        style={{flex: 1}}
      />
      {!shouldShowSingleNameCustomerField && (
        <React.Fragment>
          {/* @ts-expect-error TS(2559): Type '{ desktop: boolean; tablet: boolean; mobile:... Remove this comment to see the full error message */}
          <FieldSpace {...responsive} />
          <ClientSearchDropdownInput
            index={1}
            options={getClientOptions({
              form,
              field,
              clients,
              getOptionLabel: (client: any) => client.primaryContact.lastNameV2,
            })}
            form={form}
            field={field}
            name={`primaryContactForm.names.1`}
            loading={loading}
            disabled={disabled}
            isRequired={isLastNameRequired && !contactLastName}
            label={'Contact Last Name'}
            placeholder={'Contact last name'}
            canSearchClients={canSearchClients}
            searchQuery={searchQuery}
            handleChangeSearch={(lastName: any) => {
              handleChangeSearch(lastName);
              form.setFieldValue(`${field}.primaryContactForm.names.1`, lastName);
            }}
            style={{flex: 1}}
          />
        </React.Fragment>
      )}
    </Row>
  );
};

const ContactInfoRow = ({
  index,
  form,
  field,
  clients,
  organization,
  loading,
  disabled,
  searchQuery,
  handleChangeSearch,
  canSearchClients,
  emailAddressRequired,
}: any) => {
  const responsive = useResponsive();
  const contactField = `${field}.primaryContactForm`;
  const isPhoneNumberRequired = organization.features.isEnabledProjectCustomerPhoneNumberRequired;
  const hasPhoneNumber = !!_.get(form.values, `${contactField}.phoneNumber`);
  const isDuplicatePhoneNumber = _.get(form.values, `${field}.isDuplicatePhoneNumber`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row {...responsive} index={index}>
      <ClientSearchDropdownInput
        options={getClientOptions({
          form,
          field,
          clients,
          getOptionLabel: (client: any) => Phone.display(client.primaryContact.phoneNumber),
        })}
        form={form}
        field={field}
        component={PhoneInput}
        name={`primaryContactForm.phoneNumber`}
        label={'Phone Number'}
        placeholder={'(000) 000-0000'}
        loading={loading}
        disabled={disabled}
        required={isPhoneNumberRequired && !hasPhoneNumber}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={(phoneNumber: any) => {
          const numbersOnly = Phone.strip(phoneNumber);
          handleChangeSearch(numbersOnly);
          form.setFieldValue(`${field}.primaryContactForm.phoneNumber`, numbersOnly);
        }}
        handleValidation={(isFocusedField: any) =>
          handleCheckDuplicatePhoneNumber({form, field, clients, isFocusedField})
        }
        hasWarning={!disabled && hasPhoneNumber && isDuplicatePhoneNumber}
        warningMessage={'Another client is using this phone number'}
        style={{flex: 1}}
      />
      {/* @ts-expect-error TS(2559): Type '{ desktop: boolean; tablet: boolean; mobile:... Remove this comment to see the full error message */}
      <FieldSpace {...responsive} />
      <ClientSearchDropdownInput
        index={1}
        options={getClientOptions({
          form,
          field,
          clients,
          getOptionLabel: (client: any) => client.primaryContact.email,
        })}
        form={form}
        field={field}
        name={`primaryContactForm.email`}
        loading={loading}
        disabled={disabled}
        required={emailAddressRequired}
        label={'Email Address'}
        placeholder={'Email address'}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={(email: any) => {
          const trimmedEmail = email.trim();
          handleChangeSearch(trimmedEmail);
          form.setFieldValue(`${field}.primaryContactForm.email`, trimmedEmail);
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const AddressRow = ({form, field, index, disabled}: any) => {
  const locationField = `${field}.primaryLocationForm`;
  return (
    <FieldInput
      {...form}
      index={index}
      component={LocationInput}
      name={`${locationField}.address`}
      label={'Address'}
      input={{
        placeholder: 'Type to search',
        setFieldValue: form.setFieldValue,
        setFieldError: form.setFieldError,
        setFieldTouched: form.setFieldTouched,
        disabled,
        onLocation: ({address, city, zipCode, latitude, longitude, state, country}: any) =>
          handleOnLocation({
            form,
            field: locationField,
            address,
            city,
            zipCode,
            latitude,
            longitude,
            state,
            country,
          }),
        style: {
          color: disabled ? colors.gray.secondary : colors.gray.primary,
          backgroundColor: disabled ? colors.gray.disabled : colors.white,
        },
      }}
      style={{flex: 1}}
    />
  );
};

const ClientFieldsContent = ({
  organization,
  disabled,
  field,
  form,
  hasSelectedClient,
  canSearchClients,
  emailAddressRequired,
}: any) => {
  const {clients, isSearching, searchQuery, handleChangeSearch} = useClientFieldsClientSearch({
    pagination: {page: 1, resultsPerPage: 50},
    useClientFieldsClientSearchFragment,
  });
  const isShowingName = _.get(form.values, `${field}.isShowingName`);
  const disableClientInputs = disabled || (canSearchClients && hasSelectedClient);
  return (
    <React.Fragment>
      {isShowingName && (
        <React.Fragment>
          <FieldSpace />
          <CompanyNameRow
            index={0}
            form={form}
            field={field}
            clients={clients}
            loading={isSearching}
            disabled={disableClientInputs}
            canSearchClients={canSearchClients}
            searchQuery={searchQuery}
            handleChangeSearch={handleChangeSearch}
          />
        </React.Fragment>
      )}
      <FieldSpace />
      <ContactNameRow
        index={1}
        form={form}
        field={field}
        clients={clients}
        organization={organization}
        loading={isSearching}
        disabled={disableClientInputs}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={handleChangeSearch}
      />
      <FieldSpace />
      <ContactInfoRow
        index={2}
        form={form}
        field={field}
        clients={clients}
        organization={organization}
        loading={isSearching}
        disabled={disableClientInputs}
        canSearchClients={canSearchClients}
        searchQuery={searchQuery}
        handleChangeSearch={handleChangeSearch}
        emailAddressRequired={emailAddressRequired}
      />
      <FieldSpace />
      <AddressRow
        index={3}
        form={form}
        field={field}
        clients={clients}
        disabled={disableClientInputs}
      />
    </React.Fragment>
  );
};

const ClientFields = ({
  organization,
  disabled,
  field,
  form,
  isSearchEnabled,
  loading,
  headerLabel,
  emailAddressRequired,
  isShowingBillingClient,
}: any) => {
  const hasSelectedClient = !!_.get(form.values, `${field}.clientId`);
  const canSearchClients =
    isSearchEnabled && _.get(organization, 'features.isEnabledClientSearchOnCreateProject');
  const parentField = field.split('.')[0];
  const sameAsClient = _.get(form.values, `${parentField}.isSameBillingClient`);
  const displayClientFields = !isShowingBillingClient || !sameAsClient;
  return (
    <React.Fragment>
      <HeaderRow
        form={form}
        field={field}
        headerLabel={headerLabel}
        organization={organization}
        canSearchClients={canSearchClients}
        hasSelectedClient={hasSelectedClient}
        disabled={disabled}
        isShowingBillingClient={isShowingBillingClient}
      />
      {displayClientFields && (
        <ClientFieldsContent
          organization={organization}
          disabled={disabled}
          field={field}
          form={form}
          hasSelectedClient={hasSelectedClient}
          canSearchClients={canSearchClients}
          emailAddressRequired={emailAddressRequired}
        />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClientFields.fragment = gql`
  fragment ClientFields on Organization {
    id
    features {
      isEnabledClientSearchOnCreateProject: isEnabled(feature: "CLIENT_SEARCH_ON_CREATE_PROJECT")
      isEnabledProjectCustomerLastNameRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_LAST_NAME_REQUIRED"
      )
      isEnabledProjectCustomerPhoneNumberRequired: isEnabled(
        feature: "PROJECT_CUSTOMER_PHONE_NUMBER_REQUIRED"
      )
    }
  }
`;

const useClientFieldsClientSearchFragment = gql`
  ${ClientForm.edit.fragment}
  fragment useClientFieldsClientSearchFragment on Client {
    id
    name
    primaryContact {
      id
      names
      fullName
      firstNameV2
      lastNameV2
      phoneNumber
      email
    }
    ...ClientForm_edit
  }
`;

export default ClientFields;
