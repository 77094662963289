// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {JobReportMoveDocument} from 'modules/Document/ReportMove/components';
import {ViewDocumentModal} from 'modules/Document/components';

const PreviewReportMoveReceiptModal = ({job, trigger}: any) => (
  <ViewDocumentModal title={'Move Receipt'} trigger={trigger}>
    <JobReportMoveDocument job={job} />
  </ViewDocumentModal>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
PreviewReportMoveReceiptModal.fragment = gql`
  ${JobReportMoveDocument.fragment}

  fragment PreviewReportMoveReceiptModal on Job {
    id
    ...JobReportMoveDocument
  }
`;

export default PreviewReportMoveReceiptModal;
