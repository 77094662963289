// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState, useEffect} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const CollapsibleContainer = Styled.View`
  border-color: ${colors.gray.border};
`;

const CollapsedRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 8px;
`;

const CollapseBodyContainer = Styled.View`
  border-color: ${colors.gray.border};
  border-top-width: ${({
    // @ts-expect-error TS(2339): Property 'isCollapsed' does not exist on type 'The... Remove this comment to see the full error message
    isCollapsed,
  }) => (isCollapsed ? '0px' : '1px')};
  z-index: -1;
`;

const SmallSectionHeader = Styled.Text`
  ${Typography.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

type OwnProps = {
  title?: string;
  defaultCollapsed?: boolean;
  style?: any;
  handlePress?: (...args: any[]) => any;
  isCollapsedOverride?: boolean;
  headerColor?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CollapsibleSection.defaultProps;

// @ts-expect-error TS(7022): 'CollapsibleSection' implicitly has type 'any' bec... Remove this comment to see the full error message
const CollapsibleSection = ({
  title,
  children,
  defaultCollapsed,
  style,
  handlePress,
  isCollapsedOverride,
  headerColor,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(
    isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed,
  );
  useEffect(() => {
    setIsCollapsed(isCollapsedOverride !== null ? isCollapsedOverride : defaultCollapsed);
  }, [isCollapsedOverride, defaultCollapsed]);

  return (
    <CollapsibleContainer style={style}>
      <CollapsedRow
        onPress={() => {
          if (isCollapsedOverride === null) {
            setIsCollapsed(!isCollapsed);
          }
          handlePress(!isCollapsed);
        }}
      >
        <SmallSectionHeader color={headerColor}>{title}</SmallSectionHeader>
        <Space style={{flex: 1}} />
        <Icon
          source={isCollapsed ? Icon.ChevronRight : Icon.ChevronDown}
          color={headerColor || colors.black}
          size={12}
        />
      </CollapsedRow>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <CollapseBodyContainer isCollapsed={isCollapsed}>
        {!isCollapsed && !_.isEmpty(children) && {...children}}
      </CollapseBodyContainer>
    </CollapsibleContainer>
  );
};

CollapsibleSection.defaultProps = {
  title: '',
  defaultCollapsed: false,
  style: null,
  handlePress: () => {},
  isCollapsedOverride: null,
  headerColor: null,
};

export default CollapsibleSection;
