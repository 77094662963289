// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Link = Styled.Touchable`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const ProjectTypeDriverInventorySettingsPanelEdit = ({form, field, projectType}: any) => {
  return (
    <Row>
      <FieldInput
        {...form}
        name={`${field}.inventoryLibraryId`}
        component={DropdownInput}
        isRequired
        label={'Default Driver Inventory Library'}
        input={{
          isPortaled: true,
          options: _.map(projectType.organization.driverInventoryLibraries, (inventoryLibrary) => {
            return {
              label: inventoryLibrary.name,
              value: _.toNumber(inventoryLibrary.id),
            };
          }),
          placeholder: 'Select the default driver inventory library',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const ProjectTypeDriverInventorySettingsPanel = ({projectType}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <Row>
      <Column>
        <LabelText>Default Driver Inventory Library</LabelText>
        <Space height={8} />
        {projectType.defaultDriverInventoryLibrary ? (
          <Link
            onPress={() =>
              navigator.push(
                `/settings/inventory/libraries/${projectType.defaultDriverInventoryLibrary.uuid}`,
              )
            }
          >
            <Row style={{alignItems: 'center'}}>
              <Icon
                source={Icon.ExternalLinkAlt}
                size={Icon.Sizes.Small}
                color={colors.blue.interactive}
              />
              <Space width={8} />
              <LinkText>{projectType.defaultDriverInventoryLibrary.name}</LinkText>
            </Row>
          </Link>
        ) : (
          <EditPanel.Text style={{color: colors.gray.tertiary}}>
            Select the default driver inventory library
          </EditPanel.Text>
        )}
      </Column>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDriverInventorySettingsPanel.fragment = gql`
  fragment ProjectTypeDriverInventorySettingsPanel on ProjectType {
    id
    defaultDriverInventoryLibrary {
      id
      name
      uuid
    }
    organization {
      id
      driverInventoryLibraries {
        id
        name
      }
    }
  }
`;

ProjectTypeDriverInventorySettingsPanel.Edit = ProjectTypeDriverInventorySettingsPanelEdit;

export default ProjectTypeDriverInventorySettingsPanel;
