// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import JobAdditionalFields from 'modules/Job/components/JobAdditionalFields';
import JobBillingFields from 'modules/Job/components/JobBillingFields';
import JobInternalFields from 'modules/Job/components/JobInternalFields';
import JobLocationsFields from 'modules/Job/components/JobLocationsFields';
import JobScheduleFields from 'modules/Job/components/JobScheduleFields';

const Container = Styled.View`
  padding-horizontal: 30px;
  z-index: 100;
`;

const Space = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 30 : 40)}px;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

type OwnProps = {
  jobIndex: number;
  isUpdate?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobFields.defaultProps;

// @ts-expect-error TS(7022): 'JobFields' implicitly has type 'any' because it d... Remove this comment to see the full error message
const JobFields = ({
  field,
  organization,
  form,
  jobIndex,
  isUpdate,
  shouldResetJobDateOnJobTypeChange,
  jobFormField,
  warehouseLocation,
  mapRef,
}: Props) => {
  const responsive = useResponsive();
  const jobField = `${field}.jobForms.${jobIndex}`;
  return (
    <React.Fragment>
      <Container>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section index={0}>
          <JobScheduleFields
            index={0}
            field={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
            shouldResetJobDateOnJobTypeChange={shouldResetJobDateOnJobTypeChange}
            jobFormField={jobFormField}
          />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Space {...responsive} />
          <JobLocationsFields
            index={1}
            field={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
            warehouseLocation={warehouseLocation}
            mapRef={mapRef}
          />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Space {...responsive} />
          <JobBillingFields
            isUpdate={isUpdate}
            index={2}
            field={field}
            jobField={jobField}
            form={form}
            responsive={responsive}
            organization={organization}
          />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Space {...responsive} />
        </Section>
        <JobAdditionalFields
          index={1}
          field={jobField}
          form={form}
          responsive={responsive}
          organization={organization}
        />
      </Container>
      <JobInternalFields
        index={2}
        field={field}
        jobField={jobField}
        form={form}
        organization={organization}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

JobFields.defaultProps = {
  isUpdate: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobFields.fragment = gql`
  ${JobAdditionalFields.fragment}
  ${JobBillingFields.fragment}
  ${JobInternalFields.fragment}
  ${JobLocationsFields.fragment}
  ${JobScheduleFields.fragment}

  fragment JobFields on Organization {
    id
    ...JobAdditionalFields
    ...JobBillingFields
    ...JobInternalFields
    ...JobLocationsFields
    ...JobScheduleFields
  }
`;

export default JobFields;
