/* eslint-disable react-hooks/rules-of-hooks */

import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Popover, Checkbox, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useSidebar,
  useModal,
  usePopover,
  useNavigationDOM,
  useState,
  useHover,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, pluralize} from '@supermove/utils';

// App
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import Table from '@shared/design/components/TableV2Deprecated';
import useDeleteCollectionMutation from '@shared/modules/Storage/hooks/useDeleteCollectionMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CreateMoveProjectModal from 'modules/Job/V2/Move/components/CreateMoveProjectModal';
import CreateProjectSuccessModal from 'modules/Project/components/CreateProjectSuccessModal';
import ContainerSidebarLink from 'modules/Storage/Containers/ContainerSidebarLink';
import CreateCollectionModal from 'modules/Storage/components/CreateCollectionModal';
import StorageAssignContainerSidebar from 'modules/Storage/components/StorageAssignContainerSidebar';
import UpdateCollectionModal from 'modules/Storage/components/UpdateCollectionModal';

const CheckboxContainer = Styled.View`
  flex: 1;
`;

const SectionContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const TableHeaderContainer = Styled.View`
  flex-direction: row;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Label2}
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Label2}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const VerticalLine = Styled.View`
  width: 1px;
  height: 36px;
  background-color: ${colors.gray.border};
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const IconButton = Styled.ButtonV2`
`;

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const getCollectionsTableColumnDefinitions = ({
  selectedCollections,
  setSelectedCollections,
  storageAssignContainerSidebar,
  refetch,
  viewer,
}: any) => {
  return [
    {
      width: 64,
      headerContent: () => {
        return null;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        const isSelected = selectedCollections.includes(collection.id);
        return (
          <CheckboxContainer style={{alignItems: 'flex-end'}}>
            <Checkbox
              size={16}
              iconSize={12}
              checked={isSelected}
              onChange={() => {
                const newSelectedCollections = _.xor(selectedCollections, [collection.id]);
                setSelectedCollections(newSelectedCollections);
              }}
              color={colors.blue.interactive}
              borderColor={isSelected ? colors.blue.interactive : colors.gray.secondary}
            />
          </CheckboxContainer>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Collection Name</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        return <Text numberOfLines={1}>{collection.name}</Text>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Date In</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        const date = collection.startDate;
        return (
          <Text>
            {date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : '-'}
          </Text>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Date Out</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        const date = collection.endDate;
        return (
          <Text>
            {date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : '-'}
          </Text>
        );
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <HeaderText>Weight</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        const estimatedWeight = _.get(collection, 'activeCollectionVersion.estimatedWeight');
        return <Text>{estimatedWeight ? pluralize('lb', estimatedWeight, true) : '-'}</Text>;
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Container Name</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => {
        const container = _.get(collection, 'activeCollectionVersion.container');
        return (
          <React.Fragment>
            {container ? (
              <ContainerSidebarLink container={container} refetch={refetch} />
            ) : (
              <TertiaryButton
                onPress={() => {
                  const isSelected = selectedCollections.includes(collection.id);
                  if (!isSelected) {
                    setSelectedCollections([...selectedCollections, collection.id]);
                  }
                  storageAssignContainerSidebar.handleOpen();
                }}
              >
                <TertiaryButton.Text
                  style={{textDecorationLine: 'underline', color: colors.blue.interactive}}
                >
                  Assign to container
                </TertiaryButton.Text>
              </TertiaryButton>
            )}
          </React.Fragment>
        );
      },
    },
    // Actions
    {
      flex: 2,
      headerContent: () => {
        return <HeaderText>Actions</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'collection' implicitly has an 'an... Remove this comment to see the full error message
      cellContent: ({item: collection}) => (
        <CollectionActions collection={collection} refetch={refetch} />
      ),
    },
  ];
};

const DeleteCollectionModal = ({collection, isOpen, handleClose, refetch}: any) => {
  const {handleSubmit} = useDeleteCollectionMutation({
    collectionForm: {collectionId: collection.id},
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Remove "${collection.name}?"`}
      subtitle={
        'This will remove the collection from the container or shipment it is assigned to. Past invoices will not be affected.'
      }
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Remove'}
    />
  );
};

const CollectionActionsPopover = ({collectionActionsPopover, deleteCollectionModal}: any) => {
  const {isHovered, ref} = useHover();
  const handleOpenDeleteCollectionModal = () => {
    deleteCollectionModal.handleOpen();
    collectionActionsPopover.handleClose();
  };

  return (
    <Popover
      placement={Popover.Positions.RightStart}
      isOpen={collectionActionsPopover.isOpen}
      handleOpen={collectionActionsPopover.handleOpen}
      handleClose={collectionActionsPopover.handleClose}
      reference={collectionActionsPopover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={160}>
        <Space height={8} />
        <MenuOptionButton onPress={handleOpenDeleteCollectionModal} isHovered={isHovered} ref={ref}>
          <MenuItemText>Delete</MenuItemText>
        </MenuOptionButton>
        <Space height={8} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const CollectionActions = ({collection, refetch}: any) => {
  const updateCollectionModal = useModal({name: 'Edit Collection Modal', enableTracking: true});
  const collectionActionsPopover = usePopover();
  const deleteCollectionModal = useModal({name: 'Delete Collection Modal'});

  return (
    <React.Fragment>
      <Row>
        <IconButton onPress={updateCollectionModal.handleOpen}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
        </IconButton>
        <Space width={25} />
        <Popover.Content innerRef={collectionActionsPopover.ref}>
          <IconButton onPress={collectionActionsPopover.handleToggle}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </IconButton>
        </Popover.Content>
      </Row>
      <UpdateCollectionModal
        isOpen={updateCollectionModal.isOpen}
        handleClose={updateCollectionModal.handleClose}
        refetch={refetch}
        collection={collection}
      />
      <CollectionActionsPopover
        key={`COLLECTION_ACTIONS_POPOVER-${collectionActionsPopover.isOpen}`}
        collectionActionsPopover={collectionActionsPopover}
        deleteCollectionModal={deleteCollectionModal}
      />
      <DeleteCollectionModal
        key={deleteCollectionModal.key}
        isOpen={deleteCollectionModal.isOpen}
        handleClose={deleteCollectionModal.handleClose}
        collection={collection}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const TableHeader = ({
  project,
  viewerId,
  refetch,
  selectedCollections,
  storageAssignContainerSidebar,
}: any) => {
  const {params, navigator} = useNavigationDOM();
  const createCollectionModal = useModal({name: 'Create Collection Modal', enableTracking: true});
  const createMoveProjectForStorageProjectModal = useModal({
    name: 'Create Move Project For Storage Project Modal',
    enableTracking: true,
  });
  const createMoveProjectSuccessModal = useModal({name: 'Create Move Project Success Modal'});
  const collectionActions = [
    {
      text: 'Create collection',
      onPress: () => {
        createCollectionModal.handleOpen();
      },
    },
    {
      text: 'Assign to container',
      isDisabled: !selectedCollections.length,
      onPress: () => {
        storageAssignContainerSidebar.handleOpen();
      },
    },
    {
      text: 'Create outgoing move',
      isDisabled: !selectedCollections.length,
      onPress: createMoveProjectForStorageProjectModal.handleOpen,
    },
  ];
  return (
    <TableHeaderContainer>
      <Space style={{flex: 1}} />
      <Row>
        <SelectedItemsText
          color={selectedCollections.length ? colors.blue.interactive : colors.gray.tertiary}
        >{`${_.size(selectedCollections)} ${pluralize(
          'collections',
          _.size(selectedCollections),
        )} selected`}</SelectedItemsText>
        <Space width={12} />
        <VerticalLine />
        <Space width={12} />
        <DropdownButton
          text={'Actions'}
          actions={collectionActions}
          menuWidth={200}
          menuPosition={DropdownButton.MENU_POSITION.RIGHT}
        />
      </Row>
      <CreateMoveProjectModal
        key={createMoveProjectForStorageProjectModal.key}
        isOpen={createMoveProjectForStorageProjectModal.isOpen}
        handleClose={createMoveProjectForStorageProjectModal.handleClose}
        refetch={refetch}
        project={project}
        viewerId={viewerId}
        collections={selectedCollections.map((id: any) => _.find(project.collections, {id}))}
        isDisabledClientFields
        createProjectSuccessModal={createMoveProjectSuccessModal}
      />
      <CreateProjectSuccessModal
        key={params.new}
        projectUuid={params.new}
        isOpen={createMoveProjectSuccessModal.isOpen}
        handleClose={createMoveProjectSuccessModal.handleClose}
        title={'Move project created!'}
        backButtonText={'Back to storage project'}
        handleViewProject={() => navigator.push(`/projects/${params.new}`)}
      />
      <CreateCollectionModal
        isOpen={createCollectionModal.isOpen}
        handleClose={createCollectionModal.handleClose}
        project={project}
        refetch={refetch}
      />
    </TableHeaderContainer>
  );
};

// TODO(cooper): This component will be repurposed to display Items soon
const CollectionsPanel = ({project, viewer, refetch}: any) => {
  const [selectedCollections, setSelectedCollections] = useState([]);
  const storageAssignContainerSidebar = useSidebar({
    name: 'Storage Assign Container Sidebar',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SectionContainer index={0}>
        <TableHeader
          refetch={refetch}
          project={project}
          selectedCollections={selectedCollections}
          storageAssignContainerSidebar={storageAssignContainerSidebar}
          viewerId={viewer.id}
        />
      </SectionContainer>
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SectionContainer index={1}>
        <Table
          columnDefinitions={getCollectionsTableColumnDefinitions({
            selectedCollections,
            setSelectedCollections,
            storageAssignContainerSidebar,
            refetch,
          })}
          emptyStateText='No collections'
          items={project.collections}
          itemKey={'id'}
          loading={false}
        />
      </SectionContainer>
      <StorageAssignContainerSidebar
        key={storageAssignContainerSidebar.key}
        isOpen={storageAssignContainerSidebar.isOpen}
        handleClose={() => {
          setSelectedCollections([]);
          storageAssignContainerSidebar.handleClose();
        }}
        selectedCollections={selectedCollections}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CollectionsPanel.fragment = gql`
  ${CreateMoveProjectModal.fragment}
  ${CreateCollectionModal.fragment}
  ${UpdateCollectionModal.fragment}
  ${ContainerSidebarLink.fragment}

  fragment CollectionsPanel on Project {
    id
    collections {
      id
      name
      startDate
      endDate
      activeCollectionVersion {
        id
        estimatedWeight
        container {
          id
          name
          ...ContainerSidebarLink
        }
      }
      ...UpdateCollectionModal
    }
    ...CreateMoveProjectModal
    ...CreateCollectionModal
  }
`;

export default CollectionsPanel;
