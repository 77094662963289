// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import JobTimeline from 'modules/Job/Show/components/JobTimeline';

type OwnProps = {
  job: any;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobTimelineBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobTimelineBlock' implicitly has type 'any' becau... Remove this comment to see the full error message
const JobTimelineBlock = ({job, refetch}: Props) => {
  return <JobTimeline showEventHistory={false} job={job} refetch={refetch} />;
};

JobTimelineBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimelineBlock.fragment = gql`
  ${JobTimeline.fragment}

  fragment JobTimelineBlock on Job {
    id
    ...JobTimeline
  }
`;

export default JobTimelineBlock;
