// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {BillingLibraryModel, UserModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SidebarPage from 'modules/App/components/SidebarPage';
import AddBillRuleTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/AddBillRuleTypeDrawer';
import BillRuleTypesTable from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypesTable';
import BillRuleTypesTableV1 from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypesTableV1';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading}
;`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.orange.status};
`;

const BillingLibraryBillRulesContent = ({
  billingLibrary,
  refetch,
  userId,
}: {
  billingLibrary: BillingLibraryModel;
  refetch: () => void;
  userId: string;
}) => {
  const responsive = useResponsive();
  const addBillRuleTypeDrawer = useModal({name: 'Add Bill Rule Type Drawer'});

  return (
    <ScrollView
      style={{backgroundColor: colors.gray.background}}
      contentContainerStyle={{paddingHorizontal: responsive.desktop ? 24 : 12}}
    >
      <Container style={{maxWidth: 1400}}>
        <Space height={24} />
        <Row>
          <PageHeading responsive={responsive}>Bill Rules</PageHeading>
          <Space style={{flex: 1, minWidth: 16}} />
          <Button
            isResponsive
            iconLeft={Icon.Plus}
            text={'Add Bill Rule'}
            onPress={addBillRuleTypeDrawer.handleOpen}
          />
        </Row>
        <Space height={12} />
        <BodyText responsive={responsive}>
          {`Manage bill rules, like hour minimums and maximums. Items highlighted in `}
          <LabelText responsive={responsive}>orange</LabelText>
          {` will auto-calculate based on a formula.`}
        </BodyText>
        <Space height={24} />
        <BillRuleTypesTable billingLibrary={billingLibrary} refetch={refetch} userId={userId} />
        <Space height={36} />
        <AddBillRuleTypeDrawer
          key={addBillRuleTypeDrawer.key}
          isOpen={addBillRuleTypeDrawer.isOpen}
          handleClose={addBillRuleTypeDrawer.handleClose}
          billingLibraryUuid={billingLibrary.uuid}
          userId={userId}
          refetch={refetch}
        />
      </Container>
    </ScrollView>
  );
};

// /////////////////////////////////////////////////////////////////////
// TODO(jholston): Remove this block with isEnabledConditionalBillItems
// /////////////////////////////////////////////////////////////////////
const ContentContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding-top: 12px;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 12)}px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
;`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const BillingLibraryBillRulesContentV1 = ({
  billingLibrary,
  refetch,
  userId,
}: {
  billingLibrary: BillingLibraryModel;
  refetch: () => void;
  userId: string;
}) => {
  const responsive = useResponsive();
  return (
    <ContentContainer responsive={responsive}>
      <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
        <ScrollView>
          <Space height={24} />
          <HeaderText>Add Bill Rules</HeaderText>
          <Space height={8} />
          <Text>
            {'Add new bill rules here. Supermove will automatically calculate'}
            <Text style={{color: colors.orange.status}}> orange highlighted items </Text>
            {'based on saved formulas.'}
          </Text>
          <Space height={24} />
          <BillRuleTypesTableV1 billingLibrary={billingLibrary} refetch={refetch} userId={userId} />
          <Space height={36} />
        </ScrollView>
      </ScrollView>
    </ContentContainer>
  );
};
// /////////////////////////////////////////////////////////////////////
// END
// /////////////////////////////////////////////////////////////////////

const BillingLibraryBillRulesPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryBillRulesPage.query}
      variables={{billingLibraryUuid: params.billingLibraryUuid}}
      fetchPolicy={'cache-and-network'}
    >
      {({
        data,
        refetch,
      }: {
        data: {
          billingLibrary: BillingLibraryModel;
          viewer: UserModel;
        };
        refetch: () => void;
      }) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            {data.billingLibrary.organization.features.isEnabledConditionalBillItems ? (
              <BillingLibraryBillRulesContent
                billingLibrary={data.billingLibrary}
                refetch={refetch}
                userId={data.viewer.id}
              />
            ) : (
              <BillingLibraryBillRulesContentV1
                billingLibrary={data.billingLibrary}
                refetch={refetch}
                userId={data.viewer.id}
              />
            )}
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillRulesPage.query = gql`
  ${BillingLibraryPageHeader.fragment}
  ${BillRuleTypesTable.fragment}
  ${BillRuleTypesTableV1.fragment}
  query BillingLibraryBillRulesPage($billingLibraryUuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      uuid
      name
      organizationId
      organization {
        id
        features {
          isEnabledConditionalBillItems: isEnabled(feature: "CONDITIONAL_BILL_ITEMS")
        }
      }
      billRuleTypes {
        id
        name
        description
        updatedBy {
          id
          fullName
        }
      }
      ...BillingLibraryPageHeader
      ...BillRuleTypesTable
      ...BillRuleTypesTableV1
    }
  }
`;

export default BillingLibraryBillRulesPage;
