// Libraries
import React from 'react';

// Supermove
import {Loading, Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useInlineFormMutation, useQuery, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillForm from '@shared/modules/Billing/forms/BillForm';
import Field from 'modules/App/components/Field';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const FieldsContainer = Styled.View`
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const ActivityIndicator = Styled.Loading`
`;

const EditBillHeader = () => {
  return (
    <React.Fragment>
      <TitleText>Edit Bill</TitleText>
      <Space height={16} />
      <InstructionText>{`Please enter a title and description for this bill.`}</InstructionText>
      <Space height={20} />
    </React.Fragment>
  );
};

const EditBillFields = ({form}: any) => {
  return (
    <FieldsContainer>
      <Field
        {...form}
        name={`billForm.title`}
        label={'Title'}
        input={{
          placeholder: 'Enter a title for this bill',
          required: !form.values.billForm.title,
        }}
      />
      <Space height={12} />
      <Field
        {...form}
        label={'Description (Optional)'}
        name={`billForm.description`}
        input={{placeholder: 'Enter a description for this bill'}}
      />
    </FieldsContainer>
  );
};

const EditBillFooter = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <Actions>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        // @ts-expect-error TS(2769): No overload matches this call.
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Confirm</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const BillingProjectEditBillModalContent = ({handleClose, isOpen, refetch, bill}: any) => {
  const billForm = BillForm.edit(bill);
  const {form, submitting, handleSubmit} = useInlineFormMutation({
    name: 'billForm',
    form: billForm,
    toForm: BillForm.toForm,
    toMutation: BillForm.toMutation,
    mutation: gql`
      mutation useUpdateBillTitleAndDescriptionMutation_BillingProjectEditBillModal($billForm: BillForm!) {
        response: updateBillTitleAndDescription(billForm: $billForm) {
          ${gql.errors}
          bill {
            id
            title
            description
          }
        }
      }
    `,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log({error});
    },
  });

  const responsive = useResponsive();

  return (
    <ScreenContainer pointerEvents={'box-none'}>
      <ModalContainer {...responsive}>
        <EditBillHeader />
        <Space height={4} />
        <EditBillFields form={form} project={bill.project} />
        <Space height={20} />
        <EditBillFooter
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      </ModalContainer>
    </ScreenContainer>
  );
};

const BillingProjectEditBillModal = ({handleClose, isOpen, refetch, billUuid}: any) => {
  const {loading, data} = useQuery(BillingProjectEditBillModal.query, {
    skip: !isOpen,
    variables: {
      billUuid,
    },
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <Loading loading={loading}>
        {() =>
          data ? (
            <BillingProjectEditBillModalContent
              handleClose={handleClose}
              isOpen={isOpen}
              refetch={refetch}
              bill={data.bill}
            />
          ) : null
        }
      </Loading>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectEditBillModal.query = gql`
  ${BillForm.edit.fragment}
  ${Job.getFullName.fragment}

  query BillingProjectEditBillModal($billUuid: String!) {
    bill(uuid: $billUuid) {
      id
      title
      project {
        id
        activeJobs {
          id
          day {
            id
            value
          }
          ...Job_getFullName
        }
      }
      ...BillForm_edit
    }
  }
`;

export default BillingProjectEditBillModal;
