// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
`;

const Item = Styled.H6`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.desktop ? 'flex: 1;' : '')}
  width: 150px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  text-align: right;
`;

const SalesPayableUserListHeader = () => {
  const responsive = useResponsive();

  return (
    <Container>
      <Line />
      <Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Item vars={responsive} style={{width: 200, textAlign: 'left'}}>
          Name
        </Item>
        <Item># Jobs</Item>
        <Item>Labor Revenue</Item>
        <Item>Earnings</Item>
        <Item>Additional</Item>
      </Row>
    </Container>
  );
};

export default SalesPayableUserListHeader;
