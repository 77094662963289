// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

type OwnProps = {
  form: any;
  isUpdate?: boolean;
  isSubmitting: boolean;
  handleSubmit: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TruckFormFields.defaultProps;

// @ts-expect-error TS(7022): 'TruckFormFields' implicitly has type 'any' becaus... Remove this comment to see the full error message
const TruckFormFields = ({
  form,
  isUpdate,
  isSubmitting,
  handleSubmit,
  handleClose,
  responsive,
}: Props) => (
  <React.Fragment>
    <FieldInput
      {...form}
      name={'name'}
      label={'Name'}
      input={{
        autoFocus: true,
        placeholder: 'Enter truck name (ex. Truck #1)',
        required: !_.get(form.values, 'name'),
      }}
    />
    <RowSpace {...responsive} />
    <FieldInput
      {...form}
      name={'size'}
      label={'Size'}
      input={{
        placeholder: 'Enter truck size (ex. 16 ft)',
        required: !_.get(form.values, 'size'),
      }}
    />
    <RowSpace {...responsive} />
    <FieldInput
      {...form}
      name={'notes'}
      label={'Notes'}
      input={{
        placeholder: 'Add Notes',
      }}
    />
    <RowSpace {...responsive} />
    <SmallModal.Footer>
      <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
      <SmallModal.Button
        color={colors.blue.interactive}
        isSubmitting={isSubmitting}
        onPress={handleSubmit}
      >
        {isUpdate ? 'Save' : 'Add'}
      </SmallModal.Button>
    </SmallModal.Footer>
  </React.Fragment>
);

TruckFormFields.defaultProps = {
  isUpdate: false,
};

export default TruckFormFields;
