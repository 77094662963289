// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useCompleteCustomerTaskForm = ({customerTask, stepKind, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      isFinished: false,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCompleteCustomerTaskForm.mutation,
    variables: {
      confirmationId: customerTask.id,
      stepKind,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCompleteCustomerTaskForm.mutation = gql`
  mutation useCompleteCustomerTaskForm(
    $confirmationId: Int!,
    $stepKind: String!,
  ) {
    response: completeCustomerTask(
      confirmationId: $confirmationId,
      stepKind: $stepKind,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default useCompleteCustomerTaskForm;
