// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import MediumModal from '@shared/design/components/Modal/MediumModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import InventoryLibraryKind from '@shared/modules/Inventory/enums/InventoryLibraryKind';
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';
import useDeleteInventoryLibraryMutation from '@shared/modules/Inventory/hooks/useDeleteInventoryLibraryMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ReassignProjectTypeFields from 'modules/Organization/Settings/Inventory/components/ReassignProjectTypeFields';

const ContentWithProjectTypes = ({
  form,
  defaultProjectTypeInventorySettingsForms,
  organization,
  inventoryLibraryForm,
}: any) => {
  const inventoryLibraryKind = _.get(form.values, 'inventoryLibraryForm.kind');
  const inventoryLibraries =
    inventoryLibraryKind === InventoryLibraryKind.DRIVER_INVENTORY
      ? organization.driverInventoryLibraries
      : organization.surveyInventoryLibraries;
  const inventoryLibraryOptions = inventoryLibraries.reduce(
    (options: any, inventoryLibrary: any) => {
      if (inventoryLibrary.id !== inventoryLibraryForm.inventoryLibraryId) {
        options.push({
          label: inventoryLibrary.name,
          value: inventoryLibrary.id,
        });
      }
      return options;
    },
    [],
  );
  return (
    <React.Fragment>
      <MediumModal.Text>{`The library you want to remove has ${pluralize(
        'project type',
        defaultProjectTypeInventorySettingsForms.length,
        true,
      )} assigned to it.  Please replace the default ${InventoryLibraryKind.getDisplayKind(
        inventoryLibraryKind,
      )} library for each project type.`}</MediumModal.Text>
      <Space height={16} />
      <ReassignProjectTypeFields
        form={form}
        field={'inventoryLibraryForm.defaultProjectTypeInventorySettingsForms'}
        defaultProjectTypeInventorySettingsForms={defaultProjectTypeInventorySettingsForms}
        dropdownField={'inventoryLibraryId'}
        dropdownPlaceholder={`Select default ${InventoryLibraryKind.getDisplayKind(
          inventoryLibraryKind,
        )} library`}
        dropdownOptions={inventoryLibraryOptions}
      />
    </React.Fragment>
  );
};

const DeleteInventoryLibraryModalContent = ({
  deleteInventoryLibraryModal,
  inventoryLibrary,
  refetch,
}: any) => {
  const inventoryLibraryForm = InventoryLibraryForm.edit(inventoryLibrary);
  const {form, submitting, handleSubmit} = useDeleteInventoryLibraryMutation({
    inventoryLibraryForm,
    onSuccess: () => {
      refetch();
      deleteInventoryLibraryModal.handleClose();
    },
    onError: () => {},
  });
  const defaultProjectTypeInventorySettingsForms = _.get(
    form.values,
    'inventoryLibraryForm.defaultProjectTypeInventorySettingsForms',
    [],
  );
  const title = `Remove ${_.get(form.values, 'inventoryLibraryForm.name', 'Inventory Library')}?`;
  const subtitleText =
    inventoryLibrary.kind === InventoryLibraryKind.DRIVER_INVENTORY ? 'inventories' : 'surveys';
  return _.isEmpty(defaultProjectTypeInventorySettingsForms) ? (
    <DeleteModal
      key={deleteInventoryLibraryModal.key}
      isOpen={deleteInventoryLibraryModal.isOpen}
      title={title}
      subtitle={`Past ${subtitleText} will not be affected.`}
      handleClose={deleteInventoryLibraryModal.handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  ) : (
    <MediumModal
      key={deleteInventoryLibraryModal.key}
      isOpen={deleteInventoryLibraryModal.isOpen}
      title={title}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Reassign and Remove'}
      handleSecondaryAction={deleteInventoryLibraryModal.handleClose}
      secondaryActionText={'Cancel'}
      isScrollable
      primaryActionColor={colors.red.warning}
      style={{maxHeight: '520px'}}
    >
      <MediumModal.Block>
        <ContentWithProjectTypes
          form={form}
          defaultProjectTypeInventorySettingsForms={defaultProjectTypeInventorySettingsForms}
          organization={inventoryLibrary.organization}
          inventoryLibraryForm={inventoryLibraryForm}
        />
      </MediumModal.Block>
    </MediumModal>
  );
};

const DeleteInventoryLibraryModal = ({
  deleteInventoryLibraryModal,
  inventoryLibraryUuid,
  refetch,
}: any) => {
  const {data, loading} = useQuery(DeleteInventoryLibraryModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid,
    },
    skip: !deleteInventoryLibraryModal.isOpen,
  });
  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() =>
        data ? (
          <DeleteInventoryLibraryModalContent
            inventoryLibrary={data.inventoryLibrary}
            deleteInventoryLibraryModal={deleteInventoryLibraryModal}
            refetch={refetch}
          />
        ) : null
      }
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteInventoryLibraryModal.query = gql`
  ${InventoryLibraryForm.edit.fragment}
  query DeleteInventoryLibraryModal($inventoryLibraryUuid: String!) {
    ${gql.query}
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      kind
      organization {
        id
        driverInventoryLibraries {
          id
          name
        }
        surveyInventoryLibraries {
          id
          name
        }
      }
      ...InventoryLibraryForm_edit
    }
  }
`;

export default DeleteInventoryLibraryModal;
