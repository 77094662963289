// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useDebouncedCallback, usePopover, useNavigationDOM} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import SearchBar from '@shared/design/components/SearchBar';
import WorkflowRunFiltersButton from 'modules/Organization/Settings/Workflow/components/WorkflowRunFiltersButton';

const FiltersRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const getFilteredUrlFromParams = (params: any) => {
  const {workflowUuid, ...filters} = params;
  const variables = {...filters, page: '1'};
  return URL.getUrlFromVariables(`/settings/automations/${workflowUuid}/runs`, variables);
};

const handleUpdateParam = ({navigator, params, paramKey, paramValue}: any) => {
  navigator.replace(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}));
};

const SearchInput = () => {
  const {navigator, params} = useNavigationDOM();
  const handleUpdateQuery = useDebouncedCallback((text) => {
    handleUpdateParam({navigator, params, paramKey: 'query', paramValue: text});
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder='Search by project identifier'
      style={{width: '436px'}}
      defaultValue={params.query}
    />
  );
};

const WorkflowRunsFilters = () => {
  const {navigator, params} = useNavigationDOM();
  const workflowRunFiltersPopover = usePopover();

  return (
    <FiltersRow>
      <SearchInput />
      <Space width={10} />
      <WorkflowRunFiltersButton
        popover={workflowRunFiltersPopover}
        handleUpdateParam={({paramKey, paramValue}: any) => {
          handleUpdateParam({navigator, params, paramKey, paramValue});
        }}
        handleClearFilters={(updatedParams: any) => {
          navigator.replace(getFilteredUrlFromParams(updatedParams));
        }}
      />
    </FiltersRow>
  );
};

export default WorkflowRunsFilters;
