// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationPaymentFeesForm, {
  OrganizationPaymentFeesFormToFormType,
} from '@shared/modules/Organization/forms/OrganizationPaymentFeesForm';
import BillingAdjustmentsModal from 'modules/Organization/Settings/Company/components/BillingAdjustmentsModal';

const EditBillingAdjustmentModal = ({
  isOpen,
  handleClose,
  form,
  field,
  index,
  organization,
}: {
  isOpen: boolean;
  handleClose: () => void;
  form: Form<{organizationPaymentFeesForm: OrganizationPaymentFeesFormToFormType}>;
  field: string;
  index: number;
  organization: OrganizationModel;
}) => {
  const organizationPaymentFeesForm = OrganizationPaymentFeesForm.edit(organization);
  return (
    <BillingAdjustmentsModal
      title={'Edit Billing Adjustment'}
      isOpen={isOpen}
      handleClose={handleClose}
      organizationPaymentFeesForm={organizationPaymentFeesForm}
      form={form}
      field={field}
      index={index}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillingAdjustmentModal.fragment = gql`
  ${OrganizationPaymentFeesForm.edit.fragment}
  fragment EditBillingAdjustmentModal on Organization {
    id
    ...OrganizationPaymentFeesForm_edit
  }
`;

export default EditBillingAdjustmentModal;
