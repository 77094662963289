// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowBuilderOptionCard from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderOptionCard';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const ErrorMessage = Styled.Text`
  ${Typography.Micro}
  margin-top: 4px;
  color: ${colors.red.warning};
`;

const MethodLabel = Styled.Text``;

const InvokeWebhookSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
  label,
  description,
}: any) => {
  const field = `${workflowStepsField}.${workflowStepIndex}.invokeWebhookActionForm`;
  const selectedMethod = _.get(form.values, `${field}.method`);
  const methodError = _.get(form.errors, `${field}.method`);
  const urlError = _.get(form.errors, `${field}.url`);
  const headerError = _.get(form.errors, `${field}.headers`);
  const isDoneDisabled =
    !_.get(form.values, `${field}.method`) ||
    !_.get(form.values, `${field}.url`) ||
    !_.get(form.values, `${field}.headers`);
  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <MethodLabel>HTTP Method</MethodLabel>
      <Space height={16} />
      <WorkflowBuilderOptionCard.GroupedListContainer>
        {['GET', 'POST', 'PUT'].map((method, index) => (
          <WorkflowBuilderOptionCard.Tripled
            key={method}
            isTextCentered
            isSelected={method === selectedMethod}
            index={index}
            text={method}
            onPress={() => {
              form.setFieldValue(`${field}.method`, method);
            }}
          />
        ))}
      </WorkflowBuilderOptionCard.GroupedListContainer>
      <Space height={16} />
      <WorkflowBuilderOptionCard.GroupedListContainer>
        {['PATCH', 'DELETE'].map((method, index) => (
          <WorkflowBuilderOptionCard.Tripled
            key={index}
            isTextCentered
            isSelected={method === selectedMethod}
            index={index}
            text={method}
            onPress={() => {
              form.setFieldValue(`${field}.method`, method);
            }}
          />
        ))}
      </WorkflowBuilderOptionCard.GroupedListContainer>
      {methodError && <ErrorMessage>{methodError}</ErrorMessage>}
      <Space height={16} />

      <FieldInput
        {...form}
        index={2}
        label={'URL'}
        name={`${field}.url`}
        touched={urlError}
        errors={urlError}
        input={{
          required: !_.get(form.values, `${field}.url`),
          placeholder: 'Enter URL',
        }}
      />
      <ErrorMessage>
        {urlError && <FieldInput.CaptionText hasErrors>{urlError}</FieldInput.CaptionText>}
      </ErrorMessage>
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        label={'Headers'}
        name={`${field}.headers`}
        touched={headerError}
        errors={headerError}
        input={{
          required: !_.get(form.values, `${field}.headers`),
          placeholder: '{}',
          multiline: true,
          style: {
            height: 300,
            textAlignVertical: 'top',
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      />
      <ErrorMessage>
        {headerError && <FieldInput.CaptionText hasErrors>{headerError}</FieldInput.CaptionText>}
      </ErrorMessage>
    </WorkflowStepSlide>
  );
};

const InvokeWebhookDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
  label,
}: any) => {
  const field = `${workflowStepsField}.${workflowStepIndex}.invokeWebhookActionForm`;
  const method = _.get(form.values, `${field}.method`);
  const url = _.get(form.values, `${field}.url`);
  const description = `${method} Webhook to ${url}`;
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={description}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepInvokeWebhook = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => {
    const workflowStepActionKind = _.get(
      form.values,
      `${workflowStepsField}[${workflowStepIndex}].workflowStepActionKind`,
    );
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const {label, description} = WorkflowStepActionKind.ATTRIBUTES[workflowStepActionKind];
    return [
      <InvokeWebhookSlide
        key={workflowStepActionKind}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        workflow={workflow}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
        label={label}
        description={description}
      />,
      <InvokeWebhookDisplaySlide
        key={'DISPLAY'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        workflow={workflow}
        isPreview={isPreview}
        label={label}
      />,
    ];
  },
};

export default WorkflowStepInvokeWebhook;
