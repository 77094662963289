// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import GenerateCrewDocumentsForJobForm from '@shared/modules/Job/forms/GenerateCrewDocumentsForJobForm';
import useGenerateCrewDocumentsForJobMutation from '@shared/modules/Job/hooks/useGenerateCrewDocumentsForJobMutation';

const GenerateDocumentsForJobModal = ({jobId, project, isOpen, handleClose, onSuccess}: any) => {
  const generatedDocumentsToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Crew documents generated!',
  });
  const generateCrewDocumentsForJobForm = GenerateCrewDocumentsForJobForm.new({jobId});
  const {form, submitting, handleSubmit} = useGenerateCrewDocumentsForJobMutation({
    generateCrewDocumentsForJobForm,
    onSuccess: () => {
      generatedDocumentsToast.handleToast();
      onSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>Generate Crew Documents</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>Select a job to generate crew documents for.</SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        index={0}
        name={'generateCrewDocumentsForJobForm.jobId'}
        component={DropdownInput}
        style={{flex: 1}}
        input={{
          isSingleOptionSelected: true,
          isPortaled: true,
          options: _.map(project.activeJobs, (job) => ({
            label: `${job.jobType.name} ${job.identifier}`,
            value: job.id,
          })),
          style: {flex: 1},
          setFieldValue: form.setFieldValue,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          iconLeft={Icon.Check}
          color={colors.blue.interactive}
          isSubmitting={submitting}
          onPress={handleSubmit}
        >
          Generate
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GenerateDocumentsForJobModal.fragment = gql`
  fragment GenerateDocumentsForJobModal on Project {
    id
    activeJobs {
      id
      identifier
      jobType {
        id
        name
      }
    }
  }
`;

export default GenerateDocumentsForJobModal;
