// Libraries
import React from 'react';

// Supermove
import {Sidebar} from '@supermove/components';

// App
import ProjectComments from 'modules/Project/components/ProjectComments';

const ProjectCommentsSidebar = ({isOpen, handleClose, projectUuid}: any) => {
  return (
    <Sidebar.Content isOpen={isOpen} position={'right'} onClose={() => {}}>
      <ProjectComments isOpen={isOpen} handleClose={handleClose} projectUuid={projectUuid} />
    </Sidebar.Content>
  );
};

export default ProjectCommentsSidebar;
