// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';

// App

import JobBlockKind from '@shared/modules/Job/enums/JobBlockKind';
import Line from 'modules/App/components/Line';
import JobInfoBlock from 'modules/Job/Show/components/JobInfoBlock';
import JobProjectBlock from 'modules/Job/Show/components/JobProjectBlock';
import JobTimelineBlock from 'modules/Job/Show/components/JobTimelineBlock';
import JobAdditionalItemsBlock from 'modules/Job/V2/Move/components/JobAdditionalItemsBlock';
import JobAttachmentsBlock from 'modules/Job/V2/Move/components/JobAttachmentsBlock';
import JobClientBlock from 'modules/Job/V2/Move/components/JobClientBlock';
import JobCommercialMaterialsAndEquipmentsBlock from 'modules/Job/V2/Move/components/JobCommercialMaterialsAndEquipmentsBlock';
import JobDispatchBlock from 'modules/Job/V2/Move/components/JobDispatchBlock';
import JobInventoryBlock from 'modules/Job/V2/Move/components/JobInventoryBlock';
import JobLocationsBlock from 'modules/Job/V2/Move/components/JobLocationsBlock';
import JobMoveBlock from 'modules/Job/V2/Move/components/JobMoveBlock';
import JobProjectAccountingBlock from 'modules/Job/V2/Move/components/JobProjectAccountingBlock';
import JobProjectBillingBlock from 'modules/Job/V2/Move/components/JobProjectBillingBlock';
import JobProjectClaimsBlock from 'modules/Job/V2/Move/components/JobProjectClaimsBlock';
import JobProjectCostsBlock from 'modules/Job/V2/Move/components/JobProjectCostsBlock';
import JobProjectDatesBlock from 'modules/Job/V2/Move/components/JobProjectDatesBlock';
import JobProjectDocumentsBlock from 'modules/Job/V2/Move/components/JobProjectDocumentsBlock';
import JobReportBlock from 'modules/Job/V2/Move/components/JobReportBlock';
import JobValuationCoverageBlock from 'modules/Job/V2/Move/components/JobValuationCoverageBlock';
import JobCommentsBlock from 'modules/Job/components/JobCommentsBlock';
import JobInternal from 'modules/Job/components/JobInternal';
import JobNotesBlock from 'modules/Job/components/JobNotesBlock';
import JobProjectTasksBlock from 'modules/Job/components/JobProjectTasksBlock';
import JobStatusSection from 'modules/Job/components/JobStatusSection';

const getShowJobBlock = ({block, job}: any) => {
  const {features, settings} = job.organization;
  switch (block.kind) {
    // Left Panel
    case JobBlockKind.MANAGER_JOB_ADDITIONAL:
      return !_.isEmpty(Job.getAdditionalItems(job));
    case JobBlockKind.MANAGER_JOB_STATUS:
    case JobBlockKind.MANAGER_JOB_CUSTOMER:
    case JobBlockKind.MANAGER_JOB_MOVE:
    case JobBlockKind.MANAGER_JOB_INTERNAL:
    case JobBlockKind.MANAGER_JOB_BILLING_CLIENT:
    case JobBlockKind.MANAGER_JOB_INVENTORY:
      return true;

    case JobBlockKind.MANAGER_JOB_PROJECT_DATES:
      return features.isEnabledAutomaticProjectDates && Project.getIsLongDistance(job.project);

    // Center Panel
    case JobBlockKind.MANAGER_JOB_REPORT:
      return Job.getIsMove(job);
    case JobBlockKind.MANAGER_JOB_PROJECT_COSTS:
      // TODO: remove from job block config
      return false;
    case JobBlockKind.MANAGER_JOB_PROJECT_BILLING:
      // TODO: remove from job block config
      return false;
    case JobBlockKind.MANAGER_JOB_DISPATCH:
      return (
        job.hasJobStepDispatchTrucks ||
        (Job.getIsBeforeMove(job) && Job.getIsMove(job)) ||
        Job.getIsEstimate(job)
      );
    case JobBlockKind.MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS:
      return settings.isEquipmentAndMaterialsEnabled;
    case JobBlockKind.MANAGER_JOB_VALUATION_COVERAGE:
      // TODO: remove from job block config
      return false;
    case JobBlockKind.MANAGER_JOB_PROJECT:
    case JobBlockKind.MANAGER_JOB_INFO:
    case JobBlockKind.MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK:
    case JobBlockKind.MANAGER_JOB_PROJECT_CLAIMS:
    case JobBlockKind.MANAGER_JOB_PROJECT_DOCUMENTS:
    case JobBlockKind.MANAGER_JOB_LOCATIONS:
    case JobBlockKind.MANAGER_JOB_ATTACHMENTS:
      return true;

    // Right Panel
    case JobBlockKind.MANAGER_JOB_TASKS:
    case JobBlockKind.MANAGER_JOB_TIMELINE:
    case JobBlockKind.MANAGER_JOB_NOTES:
    case JobBlockKind.MANAGER_JOB_COMMENTS:
    default:
      return true;
  }
};

const JobBlockMobileSpacing = ({block}: any) => {
  switch (block.kind) {
    case JobBlockKind.MANAGER_JOB_STATUS:
    case JobBlockKind.MANAGER_JOB_PROJECT:
    case JobBlockKind.MANAGER_JOB_INFO:
    case JobBlockKind.MANAGER_JOB_REPORT:
    case JobBlockKind.MANAGER_JOB_INVENTORY:
    case JobBlockKind.MANAGER_JOB_PROJECT_DOCUMENTS:
    case JobBlockKind.MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK:
    case JobBlockKind.MANAGER_JOB_PROJECT_COSTS:
    case JobBlockKind.MANAGER_JOB_PROJECT_BILLING:
    case JobBlockKind.MANAGER_JOB_PROJECT_CLAIMS:
    case JobBlockKind.MANAGER_JOB_DISPATCH:
    case JobBlockKind.MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS:
    case JobBlockKind.MANAGER_JOB_VALUATION_COVERAGE:
    case JobBlockKind.MANAGER_JOB_ATTACHMENTS:
      return <Space height={28} />;
    case JobBlockKind.MANAGER_JOB_TASKS:
    case JobBlockKind.MANAGER_JOB_TIMELINE:
    case JobBlockKind.MANAGER_JOB_NOTES:
    case JobBlockKind.MANAGER_JOB_COMMENTS:
    case JobBlockKind.MANAGER_JOB_PROJECT_DATES:
    case JobBlockKind.MANAGER_JOB_CUSTOMER:
    case JobBlockKind.MANAGER_JOB_BILLING_CLIENT:
    case JobBlockKind.MANAGER_JOB_MOVE:
    case JobBlockKind.MANAGER_JOB_ADDITIONAL:
      return <Space height={4} />;
    case JobBlockKind.MANAGER_JOB_INTERNAL:
      return <Space height={16} />;
    case JobBlockKind.MANAGER_JOB_LOCATIONS:
    default:
      return null;
  }
};

const SpaceWithHorizontalLine = ({height, isBottomSpace}: any) => {
  return (
    <React.Fragment>
      <Space height={height} />
      {isBottomSpace && <Line />}
    </React.Fragment>
  );
};

const JobBlockDesktopSpacing = ({block, isBottomSpace}: any) => {
  switch (block.kind) {
    // Left Panel
    case JobBlockKind.MANAGER_JOB_STATUS:
    case JobBlockKind.MANAGER_JOB_CUSTOMER:
    case JobBlockKind.MANAGER_JOB_BILLING_CLIENT:
    case JobBlockKind.MANAGER_JOB_PROJECT_DATES:
    case JobBlockKind.MANAGER_JOB_MOVE:
    case JobBlockKind.MANAGER_JOB_ADDITIONAL:
    case JobBlockKind.MANAGER_JOB_INTERNAL:
      return <Space height={4} />;

    // Center Panel
    case JobBlockKind.MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS:
    case JobBlockKind.MANAGER_JOB_PROJECT_DOCUMENTS:
    case JobBlockKind.MANAGER_JOB_REPORT:
    case JobBlockKind.MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK:
    case JobBlockKind.MANAGER_JOB_PROJECT_CLAIMS:
    case JobBlockKind.MANAGER_JOB_DISPATCH:
    case JobBlockKind.MANAGER_JOB_LOCATIONS:
      return isBottomSpace ? null : <Space height={32} />;
    case JobBlockKind.MANAGER_JOB_PROJECT:
    case JobBlockKind.MANAGER_JOB_INFO:
    case JobBlockKind.MANAGER_JOB_INVENTORY:
    case JobBlockKind.MANAGER_JOB_PROJECT_COSTS:
    case JobBlockKind.MANAGER_JOB_PROJECT_BILLING:
    case JobBlockKind.MANAGER_JOB_VALUATION_COVERAGE:
    case JobBlockKind.MANAGER_JOB_ATTACHMENTS:
      return <SpaceWithHorizontalLine height={32} isBottomSpace={isBottomSpace} />;
    // Right Panel
    case JobBlockKind.MANAGER_JOB_TASKS:
      return <Space height={10} />;
    case JobBlockKind.MANAGER_JOB_TIMELINE:
    case JobBlockKind.MANAGER_JOB_NOTES:
    case JobBlockKind.MANAGER_JOB_COMMENTS:
    default:
      return <Space height={4} />;
  }
};

const JobBlockContent = ({index, block, job, viewer, refetch}: any) => {
  switch (block.kind) {
    // Left Panel
    case JobBlockKind.MANAGER_JOB_STATUS:
      return <JobStatusSection job={job} sectionIndex={index} />;
    case JobBlockKind.MANAGER_JOB_CUSTOMER:
      return <JobClientBlock sectionIndex={index} job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_BILLING_CLIENT:
      return (
        <JobClientBlock sectionIndex={index} job={job} refetch={refetch} isShowingBillingClient />
      );
    case JobBlockKind.MANAGER_JOB_PROJECT_DATES:
      return <JobProjectDatesBlock sectionIndex={index} job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_MOVE:
      return <JobMoveBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_ADDITIONAL:
      return <JobAdditionalItemsBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_INTERNAL:
      return <JobInternal job={job} />;

    // Center Panel
    case JobBlockKind.MANAGER_JOB_PROJECT:
      return <JobProjectBlock job={job} />;
    case JobBlockKind.MANAGER_JOB_INFO:
      return <JobInfoBlock job={job} />;
    case JobBlockKind.MANAGER_JOB_REPORT:
      return (
        <JobReportBlock
          sectionIndex={index}
          job={job}
          viewer={viewer}
          refetch={refetch}
          showHeader
        />
      );
    case JobBlockKind.MANAGER_JOB_INVENTORY:
      return <JobInventoryBlock sectionIndex={index} job={job} viewer={viewer} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_PROJECT_DOCUMENTS:
      return <JobProjectDocumentsBlock job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_PROJECT_ACCOUNTING_BLOCK:
      return (
        <JobProjectAccountingBlock index={index} job={job} viewer={viewer} refetch={refetch} />
      );
    case JobBlockKind.MANAGER_JOB_PROJECT_COSTS:
      return <JobProjectCostsBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_PROJECT_BILLING:
      return <JobProjectBillingBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_PROJECT_CLAIMS:
      return <JobProjectClaimsBlock sectionIndex={index} job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_DISPATCH:
      return <JobDispatchBlock sectionIndex={index} job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_COMMERCIAL_EQUIPMENT_AND_MATERIALS:
      return (
        <JobCommercialMaterialsAndEquipmentsBlock
          sectionIndex={index}
          job={job}
          viewer={viewer}
          refetch={refetch}
        />
      );
    case JobBlockKind.MANAGER_JOB_VALUATION_COVERAGE:
      return <JobValuationCoverageBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_LOCATIONS:
      return <JobLocationsBlock sectionIndex={index} job={job} />;
    case JobBlockKind.MANAGER_JOB_ATTACHMENTS:
      return <JobAttachmentsBlock job={job} refetch={refetch} />;

    // Right Panel
    case JobBlockKind.MANAGER_JOB_TASKS:
      return <JobProjectTasksBlock job={job} />;
    case JobBlockKind.MANAGER_JOB_TIMELINE:
      return <JobTimelineBlock job={job} refetch={refetch} />;
    case JobBlockKind.MANAGER_JOB_NOTES:
      return <JobNotesBlock job={job} />;
    case JobBlockKind.MANAGER_JOB_COMMENTS:
      return <JobCommentsBlock job={job} />;

    default:
      return null;
  }
};

const JobPageBlockSpacing = ({responsive, block, isBottomSpace}: any) => {
  return (
    <React.Fragment>
      {responsive.mobile ? (
        <JobBlockMobileSpacing block={block} />
      ) : (
        <JobBlockDesktopSpacing block={block} isBottomSpace={isBottomSpace} />
      )}
    </React.Fragment>
  );
};

const JobPageBlock = ({index, block, job, viewer, refetch}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {getShowJobBlock({block, job}) && (
        <React.Fragment>
          {index !== 0 && <JobPageBlockSpacing responsive={responsive} block={block} />}
          <JobBlockContent
            index={index}
            block={block}
            job={job}
            viewer={viewer}
            refetch={refetch}
          />
          <JobPageBlockSpacing responsive={responsive} block={block} isBottomSpace />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobPageBlock.fragment = gql`
  ${Job.getAdditionalItems.fragment}
  ${Job.getIsBeforeMove.fragment}
  ${Job.getIsDuringMove.fragment}
  ${Job.getIsEstimatedRange.fragment}
  ${Job.getIsMove.fragment}
  ${Job.getIsEstimate.fragment}

  ${JobStatusSection.fragment}
  ${JobClientBlock.fragment}
  ${JobProjectDatesBlock.fragment}
  ${JobMoveBlock.fragment}
  ${JobAdditionalItemsBlock.fragment}
  ${JobInternal.fragment}

  ${JobProjectBlock.fragment}
  ${JobInfoBlock.fragment}
  ${JobReportBlock.fragment}
  ${JobInventoryBlock.fragment}
  ${JobProjectDocumentsBlock.fragment}
  ${JobProjectCostsBlock.fragment}
  ${JobProjectBillingBlock.fragment}
  ${JobProjectClaimsBlock.fragment}
  ${JobCommercialMaterialsAndEquipmentsBlock.fragment}
  ${JobProjectAccountingBlock.fragment}
  ${JobValuationCoverageBlock.fragment}
  ${JobDispatchBlock.fragment}
  ${JobLocationsBlock.fragment}
  ${JobAttachmentsBlock.fragment}

  ${JobProjectTasksBlock.fragment}
  ${JobTimelineBlock.fragment}
  ${JobNotesBlock.fragment}
  ${JobCommentsBlock.fragment}

  ${Project.getIsLongDistance.fragment}

  fragment JobPageBlock_Block on Block {
    kind
  }

  fragment JobPageBlock_Viewer on User {
    id
    ...JobReportBlock_Viewer
    ...JobCommercialMaterialsAndEquipmentsBlock_Viewer
  }

  fragment JobPageBlock_Job on Job {
    id
    hasJobStepSalesBilling: hasJobStep(kind: "SALES_BILLING")
    hasJobStepDispatchBilling: hasJobStep(kind: "DISPATCH_BILLING")
    hasJobStepReportBilling: hasJobStep(kind: "REPORT_BILLING")
    hasJobStepDispatchTrucks: hasJobStep(kind: "DISPATCH_TRUCKS")
    project {
      id
      ...Project_getIsLongDistance
    }
    organization {
      id
      features {
        isEnabledAutomaticProjectDates: isEnabled(feature: "AUTOMATIC_PROJECT_DATES")
      }
      settings {
        id
        isEquipmentAndMaterialsEnabled
      }
    }
    ...Job_getAdditionalItems
    ...Job_getIsBeforeMove
    ...Job_getIsDuringMove
    ...Job_getIsEstimatedRange
    ...Job_getIsMove
    ...Job_getIsEstimate

    ...JobStatusSection
    ...JobClientBlock
    ...JobProjectDatesBlock
    ...JobMoveBlock
    ...JobAdditionalItemsBlock
    ...JobInternal

    ...JobProjectBlock
    ...JobInfoBlock
    ...JobReportBlock_Job
    ...JobInventoryBlock
    ...JobProjectDocumentsBlock
    ...JobProjectCostsBlock
    ...JobProjectBillingBlock
    ...JobProjectClaimsBlock
    ...JobCommercialMaterialsAndEquipmentsBlock_Job
    ...JobValuationCoverageBlock
    ...JobDispatchBlock
    ...JobLocationsBlock
    ...JobAttachmentsBlock
    ...JobProjectAccountingBlock

    ...JobProjectTasksBlock
    ...JobTimelineBlock
    ...JobCommentsBlock
    ...JobNotesBlock
  }
`;

export default JobPageBlock;
