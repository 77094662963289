// Libraries
import React from 'react';

// Supermove
import {Loading, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {fontWeight, colors, typography} from '@supermove/styles';

// App
import {Navigation} from 'modules/App/components';
import EditJobUsersForm from 'modules/JobUser/components/EditJobUsersForm';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  max-width: 1040px;
  width: ${(props) => ((props as any).mobile ? '100%' : '90%')};
  height: ${(props) => ((props as any).mobile ? '100%' : '90%')};
  background-color: ${colors.white};
`;

const Container = Styled.View`
  flex: 1;
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const HeaderMainSection = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderBorderSection = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => ((props as any).mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? typography.h6() : '')}
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Footer = Styled.View`
  padding: 10px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.gray.tertiary};
`;

const Button = Styled.Button`
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

// Modal to assign/unassign members to a Job.
const JobJobUsersScheduleModal = ({jobUuid, isOpen, handleClose}: any) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(JobJobUsersScheduleModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {uuid: jobUuid},
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper {...responsive}>
          <Loading loading={loading || !data}>
            {() => {
              return (
                <Container {...responsive}>
                  <Header>
                    <HeaderBorderSection {...responsive}>
                      <Navigation.CloseButton responsive={responsive} onPress={handleClose} />
                    </HeaderBorderSection>
                    <HeaderMainSection>
                      {/* @ts-expect-error TS(2769): No overload matches this call. */}
                      <Title vars={responsive}>{`Select Movers: ${Job.getFullName(
                        data.job,
                      )}`}</Title>
                    </HeaderMainSection>
                    <HeaderBorderSection />
                  </Header>
                  <EditJobUsersForm job={data.job} />
                  <Footer>
                    <Button onPress={handleClose}>
                      <Text>Done</Text>
                    </Button>
                  </Footer>
                </Container>
              );
            }}
          </Loading>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobJobUsersScheduleModal.query = gql`
  ${Job.getFullName.fragment}
  ${EditJobUsersForm.fragment}

  query JobJobUsersScheduleModal($uuid: String!) {
    job(uuid: $uuid) {
      id
      ...Job_getFullName
      ...EditJobUsersForm
    }
  }
`;

export default JobJobUsersScheduleModal;
