// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled, Space, Icon} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

const MobileTableHeaderLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const MobileTableHeaderText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const MobileTableLabel = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const MobileTableText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const MobileTableContainer = Styled.View`
  border: 1px solid ${colors.gray.border};
  border-radius: 4px;
  overflow: hidden;
`;

const MobileTableItem = Styled.ButtonV2`
`;

const MobileTableItemContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'backgroundColor' does not exist on type ... Remove this comment to see the full error message
    backgroundColor,
  }) => backgroundColor || colors.white};
`;

const ExpandableRowButton = Styled.ButtonV2`
  flex-direction: row;
`;

const Column = Styled.View`
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width || '100%'};
  flex-wrap: wrap;
`;

const Line = Styled.View`
  height: 2px;
  width: 100%;
  background-color: ${colors.gray.border};
`;

const IconContainer = Styled.View`
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CapacityCalendarMobileTable = ({
  items,
  columnDefinitions,
  emptyStateText,
  itemKey,
  navigator,
}: any) => {
  const {
    PRIMARY: primaryColumnDefinition,
    HEADER: headerColumnDefinitions,
    COLLAPSED: collapsedColumnDefinitions,
  } = columnDefinitions;
  const [expandedRows, setExpandedRows] = useState(new Set());
  const toggleRow = (itemKey: any) => {
    setExpandedRows((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemKey)) {
        newSet.delete(itemKey);
      } else {
        newSet.add(itemKey);
      }
      return newSet;
    });
  };
  return (
    <MobileTableContainer>
      <FlatList
        data={items}
        keyExtractor={(job: any, index: any) => _.get(job, itemKey, index)}
        renderItem={({item, index}: any) => {
          const key = _.get(item, itemKey, index);
          const isExpanded = expandedRows.has(key);
          return (
            <MobileTableItem onPress={() => navigator.push(`/jobs/${item.uuid}`)}>
              <MobileTableItemContainer>
                <ExpandableRowButton onPress={() => toggleRow(key)}>
                  <IconContainer>
                    <Icon
                      source={isExpanded ? Icon.AngleUp : Icon.AngleDown}
                      size={16}
                      color={colors.gray.secondary}
                    />
                  </IconContainer>
                  <Space width={4} />
                  {primaryColumnDefinition.cellContent({item})}
                </ExpandableRowButton>
                {headerColumnDefinitions.map((column: any) => (
                  <React.Fragment>
                    <Space height={4} />
                    {column.cellContent({item})}
                  </React.Fragment>
                ))}
              </MobileTableItemContainer>
              {isExpanded && (
                <MobileTableItemContainer
                  // @ts-expect-error TS(2769): No overload matches this call.
                  backgroundColor={colors.gray.background}
                  style={{borderTopWidth: 1, borderColor: colors.gray.border}}
                >
                  {collapsedColumnDefinitions.map((column: any, index: any) => (
                    <React.Fragment>
                      <Row style={{alignItems: 'flex-start'}}>
                        <Column width={120}>{column.headerContent({item})}</Column>
                        <Space width={24} />
                        <Column style={{flex: 1}}>{column.cellContent({item})}</Column>
                      </Row>
                      {index < collapsedColumnDefinitions.length - 1 && <Space height={4} />}
                    </React.Fragment>
                  ))}
                </MobileTableItemContainer>
              )}
            </MobileTableItem>
          );
        }}
        ItemSeparatorComponent={Line}
        ListEmptyComponent={() => (
          <MobileTableItemContainer style={{alignItems: 'center', justifyContent: 'center'}}>
            <MobileTableText>{emptyStateText}</MobileTableText>
          </MobileTableItemContainer>
        )}
        initialNumToRender={10}
        contentContainerStyle={{
          backgroundColor: colors.white,
        }}
      />
    </MobileTableContainer>
  );
};

CapacityCalendarMobileTable.HeaderLabel = MobileTableHeaderLabel;
CapacityCalendarMobileTable.HeaderText = MobileTableHeaderText;
CapacityCalendarMobileTable.Label = MobileTableLabel;
CapacityCalendarMobileTable.Text = MobileTableText;

export default CapacityCalendarMobileTable;
