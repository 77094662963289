// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import Table from '@shared/design/components/TableV2Deprecated';
import ProjectDateKind from '@shared/modules/Project/enums/ProjectDateKind';
import CreateTripWithShipmentsModal from 'modules/Dispatch/LongDistance/components/CreateTripWithShipmentsModal';
import EditShipmentStatusDropdown from 'modules/Dispatch/LongDistance/components/EditShipmentStatusDropdown';
import ShipmentsTableDatePicker from 'modules/Dispatch/Shipment/components/ShipmentsTableDatePicker';

const HeaderText = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const Container = Styled.View`
  flex: 1;
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkButtonText = Styled.Text`
  ${Typography.Link2}
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const handleSelectAll = ({setSelectedShipments, isSelectedAllShipments, shipments}: any) => {
  if (isSelectedAllShipments) {
    return setSelectedShipments({});
  }
  const selectedShipments = _.reduce(
    shipments,
    (result, shipment) => ({...result, [shipment.id]: shipment}),
    {},
  );
  return setSelectedShipments(selectedShipments);
};

const getShipmentsTableColumnDefinitions = ({
  shipments,
  setSelectedShipments,
  selectedShipments,
  totalWeight,
  totalVolume,
  refetchTabData,
  refetchTableData,
  isCheckboxEnabled,
  isTripNameEnabled,
  navigator,
}: any) => {
  const columnDefinitions = [
    // Checkbox
    {
      isEnabled: isCheckboxEnabled,
      width: 64,
      headerContent: () => {
        const isSelectedAllShipments =
          !_.isEmpty(shipments) && shipments.length === _.keys(selectedShipments).length;
        return (
          <BulkEditCheckbox
            isChecked={isSelectedAllShipments}
            onPress={() =>
              handleSelectAll({
                setSelectedShipments,
                isSelectedAllShipments,
                shipments,
              })
            }
            style={{paddingRight: 8}}
          />
        );
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        const isSelected = _.has(selectedShipments, shipment.id);
        return (
          <Container>
            <BulkEditCheckbox
              isChecked={isSelected}
              onPress={() => {
                let copySelectedShipments = _.cloneDeep(selectedShipments);
                if (isSelected) {
                  copySelectedShipments = _.omit(copySelectedShipments, shipment.id);
                } else {
                  copySelectedShipments[shipment.id] = shipment;
                }
                setSelectedShipments(copySelectedShipments);
              }}
              style={{paddingVertical: 8, paddingRight: 8}}
            />
          </Container>
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Status
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Status</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return (
          <EditShipmentStatusDropdown
            key={shipment.status}
            shipment={shipment}
            onSuccess={() => {
              refetchTableData();
              refetchTabData();
            }}
          />
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Customer
    {
      isEnabled: true,
      flex: 2,
      headerContent: () => {
        return <HeaderText>Customer</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return (
          <Container style={{width: 'fit-content'}}>
            <LinkButton
              style={{width: 'fit-content'}}
              onPress={() => {
                navigator.push(`/clients/${shipment.project.client.uuid}`);
              }}
            >
              <LinkButtonText>{shipment.project.client.name}</LinkButtonText>
            </LinkButton>
          </Container>
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Trip
    {
      isEnabled: isTripNameEnabled,
      flex: 2,
      headerContent: () => {
        return <HeaderText>Trip Name</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        const tripName = _.get(shipment, 'trip.name', 'None');
        const hasTrip = Boolean(shipment.trip);
        const tripLink = hasTrip && `/dispatch/long-distance/trips/${shipment.trip.uuid}/shipments`;
        return (
          <Container style={{width: 'fit-content'}}>
            {hasTrip ? (
              <LinkButton style={{width: 'fit-content'}} onPress={() => navigator.push(tripLink)}>
                <LinkButtonText>{tripName}</LinkButtonText>
              </LinkButton>
            ) : (
              <Text>None</Text>
            )}
          </Container>
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Weight
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Weight</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return <Text>{shipment.project.weight} lbs</Text>;
      },
      footerContent: () => {
        return <Text>{totalWeight} lbs</Text>;
      },
    },
    // Volume
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Volume</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return <Text>{shipment.project.volume} ft³</Text>;
      },
      footerContent: () => {
        return <Text>{totalVolume} ft³</Text>;
      },
    },
    // Pack Dates
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Pack Date(s)</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return (
          <ProjectDateColumn projectDateKind={ProjectDateKind.PACK} project={shipment.project} />
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Load Dates
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Load Date(s)</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return (
          <ProjectDateColumn projectDateKind={ProjectDateKind.LOAD} project={shipment.project} />
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Delivery Dates
    {
      isEnabled: true,
      flex: 1,
      headerContent: () => {
        return <HeaderText>Delivery Date(s)</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        return (
          <ProjectDateColumn
            projectDateKind={ProjectDateKind.DELIVERY}
            project={shipment.project}
          />
        );
      },
      footerContent: () => {
        return null;
      },
    },
    // Origin
    {
      isEnabled: true,
      flex: 2,
      headerContent: () => {
        return <HeaderText>Origin</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        const {startLocation} = shipment.project;
        return <Text>{Location.getDisplayCityStateZip(startLocation, '-')}</Text>;
      },
      footerContent: () => {
        return null;
      },
    },
    // Destination
    {
      isEnabled: true,
      flex: 2,
      headerContent: () => {
        return <HeaderText>Destination</HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'shipment' implicitly has an 'any'... Remove this comment to see the full error message
      cellContent: ({item: shipment}) => {
        const {endLocation} = shipment.project;
        return <Text>{Location.getDisplayCityStateZip(endLocation, '-')}</Text>;
      },
      footerContent: () => {
        return null;
      },
    },
  ];

  return _.filter(columnDefinitions, (column) => column.isEnabled);
};

const ProjectDateColumn = ({projectDateKind, project}: any) => {
  const {isEnabledCreateProjectDispatchLocks} = project.organization.features;
  const excludeDates = isEnabledCreateProjectDispatchLocks
    ? project.projectType.futureSalesLockedProjectTypeDays.map(
        (projectTypeDay: any) => projectTypeDay.day.date,
      )
    : [];
  return (
    <ShipmentsTableDatePicker
      project={project}
      projectDateKind={projectDateKind}
      excludeDates={excludeDates}
    />
  );
};

const BulkEditCheckbox = ({isChecked, onPress, style}: any) => {
  return <Checkbox isChecked={isChecked} handleToggle={onPress} style={style} />;
};

type OwnShipmentsTableProps = {
  shipments?: any[];
  createTripWithShipmentsModal?: any;
  refetchTabData?: (...args: any[]) => any;
  refetchTableData?: (...args: any[]) => any;
  selectedShipments?: any;
  setSelectedShipments?: (...args: any[]) => any;
  isCheckboxEnabled?: boolean;
  isTripNameEnabled?: boolean;
  hasFooter?: boolean;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'ShipmentsTableProps' circularly refere... Remove this comment to see the full error message
type ShipmentsTableProps = OwnShipmentsTableProps & typeof ShipmentsTable.defaultProps;

// @ts-expect-error TS(7022): 'ShipmentsTable' implicitly has type 'any' because... Remove this comment to see the full error message
const ShipmentsTable = ({
  shipments,
  createTripWithShipmentsModal,
  refetchTabData,
  refetchTableData,
  selectedShipments,
  setSelectedShipments,
  isCheckboxEnabled,
  isTripNameEnabled,
  hasFooter,
  loading,
  style,
}: ShipmentsTableProps) => {
  const {navigator} = useNavigationDOM();
  const totalWeight = _.sum(shipments.map((shipment: any) => shipment.project.weight));
  const totalVolume = _.sum(shipments.map((shipment: any) => shipment.project.volume));

  return (
    <React.Fragment>
      <Table.FixedHeaderScroll
        columnDefinitions={getShipmentsTableColumnDefinitions({
          shipments,
          selectedShipments,
          setSelectedShipments,
          totalWeight,
          totalVolume,
          refetchTabData,
          refetchTableData,
          isCheckboxEnabled,
          isTripNameEnabled,
          navigator,
        })}
        emptyStateText='No shipments'
        items={shipments}
        itemKey={'id'}
        hasFooter={hasFooter}
        loading={loading}
        isDense
        style={style}
        scrollViewContentContainerStyle={{paddingBottom: 340}}
      />
      <CreateTripWithShipmentsModal
        isOpen={createTripWithShipmentsModal.isOpen}
        handleClose={createTripWithShipmentsModal.handleClose}
        shipments={_.values(selectedShipments)}
        onSuccess={() => {
          setSelectedShipments({});
          refetchTabData();
          refetchTableData();
        }}
      />
    </React.Fragment>
  );
};

ShipmentsTable.defaultProps = {
  shipments: [],
  createTripWithShipmentsModal: {isOpen: false},
  refetchTabData: () => {},
  refetchTableData: () => {},
  selectedShipments: {},
  setSelectedShipments: () => {},
  isCheckboxEnabled: true,
  isTripNameEnabled: true,
  hasFooter: false,
  style: {minWidth: 1300},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShipmentsTable.fragment = gql`
  ${CreateTripWithShipmentsModal.fragment}
  ${EditShipmentStatusDropdown.fragment}
  ${Location.getDisplayCityStateZip.fragment}
  ${ShipmentsTableDatePicker.fragment}

  fragment ShipmentsTable on Shipment {
    id
    project {
      id
      weight
      volume
      client {
        id
        uuid
        name
      }
      startLocation {
        id
        ...Location_getDisplayCityStateZip
      }
      endLocation {
        id
        ...Location_getDisplayCityStateZip
      }
      ...ShipmentsTableDatePicker
      organization {
        id
        features {
          isEnabledCreateProjectDispatchLocks: isEnabled(feature: "CREATE_PROJECT_DISPATCH_LOCKS")
        }
      }
      projectType {
        id
        futureSalesLockedProjectTypeDays {
          id
          day {
            id
            date
          }
        }
      }
    }
    trip {
      id
      uuid
      name
    }
    ...CreateTripWithShipmentsModal
    ...EditShipmentStatusDropdown
  }
`;

export default ShipmentsTable;
