// Libraries
import React from 'react';

// App
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import WarehouseSettingsPageContent from 'modules/Organization/Settings/Storage/Warehouses/WarehouseSettingsPageContent';

const OrganizationSettingsCompanyWarehousesPage = () => {
  return (
    // @ts-expect-error TS(2739): Type '{ children: Element; hasNoChildrenStyle: tru... Remove this comment to see the full error message
    <CompanySettingsPage hasNoChildrenStyle>
      <WarehouseSettingsPageContent isCompanySetting />
    </CompanySettingsPage>
  );
};

export default OrganizationSettingsCompanyWarehousesPage;
