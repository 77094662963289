// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Switch} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {ScheduleCellV2} from 'modules/App/Schedule/components';

import UpdateUserDayByDateMutation from './UpdateUserDayByDateMutation';
import UpdateUserDayFailureModal from './UpdateUserDayFailureModal';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-top-width: 0px;
`;

const Left = Styled.View`
  flex: 1;
  padding-left: 10px;
`;

const DateText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Group = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

const Availability = Styled.H7`
  color: ${colors.gray.primary};
`;

const Right = Styled.View`
`;

const Touchable = Styled.Touchable`
  height: 100%;
`;

const getCellColor = ({scheduleDay}: any) => {
  if (!scheduleDay.userDay) {
    return colors.green.status;
  } else {
    switch (scheduleDay.userDay.scheduleStatus) {
      case 'AVAILABLE':
        return colors.green.status;
      case 'NOT_AVAILABLE':
        return colors.Pink600;
      default:
        return colors.gray.border;
    }
  }
};

const getScheduleStatus = ({scheduleDay}: any) => {
  if (!scheduleDay.userDay) {
    return 'AVAILABLE';
  } else {
    return scheduleDay.userDay.scheduleStatus;
  }
};

const getScheduleStatusDisplay = ({scheduleDay}: any) => {
  switch (getScheduleStatus({scheduleDay})) {
    case 'AVAILABLE':
      return 'Available';
    case 'NOT_AVAILABLE':
      return 'Not Available';
    default:
      return '';
  }
};

const getToggledScheduleStatus = ({scheduleDay}: any) => {
  if (!scheduleDay.userDay) {
    return 'NOT_AVAILABLE';
  }
  switch (scheduleDay.userDay.scheduleStatus) {
    case 'AVAILABLE':
      return 'NOT_AVAILABLE';
    case 'NOT_AVAILABLE':
    default:
      return 'AVAILABLE';
  }
};

const isSwitchEnabled = ({scheduleDay}: any) => {
  return (
    _.get(scheduleDay, 'userDay.scheduleStatus', 'AVAILABLE') === 'NOT_AVAILABLE' ||
    scheduleDay.jobUsers.length === 0
  );
};

const ScheduleUserDayItem = ({scheduleDay, refetch, viewer, moverSetScheduleMinDaysAhead}: any) => {
  const {navigator} = useNavigationDOM();
  const {date} = scheduleDay;
  const datetime = Datetime.fromDate(date);
  const {today} = Datetime;
  const firstDateAllowedToSet = today.add(moverSetScheduleMinDaysAhead, 'day');
  const canSetSchedule = firstDateAllowedToSet <= datetime;

  return (
    <Container>
      <UpdateUserDayByDateMutation
        date={date}
        scheduleStatus={getToggledScheduleStatus({scheduleDay})}
        userId={viewer.id}
        onSuccess={refetch}
      >
        {({loading, handleSubmit}: any) => (
          <React.Fragment>
            <Left>
              <DateText>{Datetime.convertToDisplayDate(scheduleDay.date, 'dddd (M/D)')}</DateText>
              <Group>
                {!canSetSchedule ? null : isSwitchEnabled({scheduleDay}) ? (
                  <React.Fragment>
                    <Switch
                      // @ts-expect-error TS(2322): Type '{ isOn: boolean; onTintColor: "#008633"; onC... Remove this comment to see the full error message
                      isOn={getScheduleStatus({scheduleDay}) === 'AVAILABLE'}
                      onTintColor={colors.green.status}
                      onChange={handleSubmit}
                    />
                    <Space width={10} />
                  </React.Fragment>
                ) : (
                  <UpdateUserDayFailureModal
                    scheduleDay={scheduleDay}
                    trigger={({handleOpen}: any) => (
                      <React.Fragment>
                        <Switch
                          // @ts-expect-error TS(2322): Type '{ isOn: boolean; onTintColor: "#008633"; onC... Remove this comment to see the full error message
                          isOn={getScheduleStatus({scheduleDay}) === 'AVAILABLE'}
                          onTintColor={colors.green.status}
                          onChange={handleOpen}
                        />
                        <Space width={10} />
                      </React.Fragment>
                    )}
                  />
                )}
                <Availability>{getScheduleStatusDisplay({scheduleDay})}</Availability>
              </Group>
            </Left>
            <Right>
              <Touchable
                activeOpacity={1}
                onPress={() => {
                  if (scheduleDay.jobUsers.length > 0) {
                    navigator.push(`/1/schedule/${scheduleDay.date}`);
                  }
                }}
              >
                <ScheduleCellV2
                  loading={loading}
                  width={140}
                  jobUsers={scheduleDay.jobUsers}
                  styleColor={getCellColor({scheduleDay})}
                  styleCursor={scheduleDay.jobUsers.length > 0 ? 'pointer' : 'default'}
                  styleOpacity={1.0}
                />
              </Touchable>
            </Right>
          </React.Fragment>
        )}
      </UpdateUserDayByDateMutation>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ScheduleUserDayItem.fragment = gql`
  ${ScheduleCellV2.fragment}

  fragment ScheduleUserDayItem on EmployeeScheduleDayV2 {
    date
    userDay {
      id
      userId
      dayId
      scheduleStatus
    }
    jobUsers {
      id
      ...ScheduleCellV2
    }
  }
`;

export default ScheduleUserDayItem;
