// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Link = Styled.Touchable`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const ProjectTypeInventorySettingsPanelEdit = ({form, field, projectType}: any) => {
  return (
    <React.Fragment>
      <Row>
        <FieldInput
          {...form}
          name={`${field}.surveyMethodId`}
          component={DropdownInput}
          isRequired
          label={'Default Survey Method'}
          input={{
            isPortaled: true,
            options: _.map(projectType.organization.surveyMethods, (surveyMethod) => {
              return {
                label: surveyMethod.name,
                value: _.toNumber(surveyMethod.id),
              };
            }),
            placeholder: 'Select the default survey method',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
          }}
          style={{flex: 1}}
        />
        <Space width={24} />
        <FieldInput
          {...form}
          name={`${field}.inventoryLibraryId`}
          component={DropdownInput}
          isRequired
          label={'Default Inventory Library'}
          input={{
            isPortaled: true,
            options: _.map(
              projectType.organization.surveyInventoryLibraries,
              (inventoryLibrary) => {
                return {
                  label: inventoryLibrary.name,
                  value: _.toNumber(inventoryLibrary.id),
                };
              },
            ),
            placeholder: 'Select the default inventory library',
            setFieldValue: form.setFieldValue,
            style: {width: '100%'},
          }}
          style={{flex: 1}}
        />
      </Row>
    </React.Fragment>
  );
};

const ProjectTypeInventorySettingsPanel = ({projectType}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <Row>
      <Column>
        <LabelText>Default Survey Method</LabelText>
        <Space height={8} />
        <EditPanel.Text
          style={{color: projectType.defaultSurveyMethod ? colors.black : colors.gray.tertiary}}
        >
          {projectType.defaultSurveyMethod?.name || 'Select the default survey method'}
        </EditPanel.Text>
      </Column>
      <Space width={24} />
      <Column>
        <LabelText>Default Inventory Library</LabelText>
        <Space height={8} />
        {projectType.defaultInventoryLibrary ? (
          <Link
            onPress={() =>
              navigator.push(
                `/settings/inventory/libraries/${projectType.defaultInventoryLibrary.uuid}`,
              )
            }
          >
            <Row style={{alignItems: 'center'}}>
              <Icon
                source={Icon.ExternalLinkAlt}
                size={Icon.Sizes.Small}
                color={colors.blue.interactive}
              />
              <Space width={8} />
              <LinkText>{projectType.defaultInventoryLibrary.name}</LinkText>
            </Row>
          </Link>
        ) : (
          <EditPanel.Text style={{color: colors.gray.tertiary}}>
            Select the default inventory library
          </EditPanel.Text>
        )}
      </Column>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeInventorySettingsPanel.fragment = gql`
  fragment ProjectTypeInventorySettingsPanel on ProjectType {
    id
    defaultSurveyMethod {
      id
      name
    }
    defaultInventoryLibrary {
      id
      name
      uuid
    }
    organization {
      id
      surveyMethods {
        id
        name
      }
      surveyInventoryLibraries {
        id
        name
      }
    }
  }
`;

ProjectTypeInventorySettingsPanel.Edit = ProjectTypeInventorySettingsPanelEdit;

export default ProjectTypeInventorySettingsPanel;
