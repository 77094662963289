// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import {useResponsive, Form} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {ItemFormV2PartialToFormType} from '@shared/modules/Inventory/forms/ItemFormV2';
import InventoryStepper from 'modules/Inventory/Edit/components/InventoryStepper';

const Column = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
`;

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`;

const computeWeight = (volume: number, defaultDensityFactor: number) =>
  volume === 0 ? 0 : _.round(volume * defaultDensityFactor, 2);
const computeVolume = (weight: number, defaultDensityFactor: number) =>
  weight === 0 ? 0 : _.round(weight / defaultDensityFactor, 2);

const NameInput = ({
  form,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'name',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
      }}
      name={'name'}
      label={'Item Name'}
    />
  );
};

const VolumeInput = ({
  form,
  autoFocus,
  defaultDensityFactor,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
  defaultDensityFactor: number;
}) => {
  const {isDerivedWeight} = form.values;
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={(name, value) => {
        const numberValue = Inventory.getFloatValue(value);
        // getFloatValue blocks trailing decimals, so we allow it so that floats can be entered
        form.setFieldValue(name, value.endsWith('.') ? value : numberValue);
        if (isDerivedWeight) {
          form.setFieldValue('weight', computeWeight(numberValue, defaultDensityFactor));
        }
      }}
      input={{
        autoFocus: autoFocus === 'volume',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
      }}
      name={'volume'}
      label={'Volume (cu ft)'}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting volume removes a trailing decimal
        form.setFieldValue('volume', Inventory.getFloatValue(text));
      }}
    />
  );
};

const WeightInput = ({
  form,
  autoFocus,
  defaultDensityFactor,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
  defaultDensityFactor: number;
}) => {
  const {isDerivedWeight, volume} = form.values;
  return (
    <FieldInput
      {...form}
      isResponsive
      name={'weight'}
      label={'Weight (lbs)'}
      LabelIconComponent={() => (
        <Row style={{flex: 1, justifyContent: 'flex-end'}}>
          <IconButton
            onPress={() => {
              if (!isDerivedWeight) {
                form.setFieldValue('weight', computeWeight(volume, defaultDensityFactor));
              }
              form.setFieldValue('isDerivedWeight', !isDerivedWeight);
            }}
          >
            <Icon
              source={isDerivedWeight ? Icon.Link : Icon.LinkSlash}
              size={16}
              color={colors.blue.interactive}
            />
          </IconButton>
        </Row>
      )}
      style={{flex: 1}}
      setFieldValue={(name, value) => {
        const numberValue = Inventory.getFloatValue(value);
        // getFloatValue blocks trailing decimals, so we allow it so that floats can be entered
        form.setFieldValue(name, value.endsWith('.') ? value : numberValue);
        if (isDerivedWeight) {
          form.setFieldValue('volume', computeVolume(numberValue, defaultDensityFactor));
        }
      }}
      input={{
        autoFocus: autoFocus === 'weight',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
      }}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting weight removes a trailing decimal
        form.setFieldValue('weight', Inventory.getFloatValue(text));
      }}
    />
  );
};

const PriceInput = ({
  form,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      component={CurrencyInput}
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'price',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
        setFieldValue: form.setFieldValue,
        setFieldTouched: form.setFieldTouched,
        component: FieldInput.TextInput,
      }}
      name={'price'}
      label={'Price ($)'}
    />
  );
};

const QuantityInput = ({
  form,
  style,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  style: React.CSSProperties;
  autoFocus?: string;
}) => {
  const responsive = useResponsive();
  const {take, takeCount, leaveCount} = form.values;
  const quantityField = take ? 'takeCount' : 'leaveCount';
  const quantity = take ? takeCount : leaveCount;
  const handleQuantityChange = (newQuantity: number) => (newQuantity < 0 ? 0 : newQuantity);

  return (
    <Column style={style}>
      <FieldInput.LabelText isResponsive>Quantity</FieldInput.LabelText>
      <Space height={responsive.desktop ? 4 : 8} />
      <InventoryStepper.Stepper
        value={quantity}
        handleDecrease={() => form.setFieldValue(quantityField, handleQuantityChange(quantity - 1))}
        handleIncrease={() => form.setFieldValue(quantityField, handleQuantityChange(quantity + 1))}
        style={{
          borderColor: colors.gray.tertiary,
        }}
        height={responsive.desktop ? 36 : 48}
      >
        <FieldInput
          {...form}
          isResponsive
          style={{flex: 1}}
          setFieldValue={(name, value) => {
            const quantity = Inventory.getFloatValue(value);
            // getFloatValue blocks trailing decimals, so we allow it so that floats can be entered
            form.setFieldValue(name, value.endsWith('.') ? value : quantity);
          }}
          input={{
            autoFocus: autoFocus === 'quantity',
            onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
            style: {borderWidth: 0, textAlign: 'center'},
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              const text = event.target.value;
              // Formatting quantity removes a trailing decimal
              form.setFieldValue(quantityField, Inventory.getFloatValue(text));
            },
          }}
          name={quantityField}
        />
      </InventoryStepper.Stepper>
    </Column>
  );
};

const PackTimeInput = ({
  form,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'packTime',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
      }}
      name={'packTime'}
      label={'Pack Time (min)'}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting volume removes a trailing decimal
        form.setFieldValue('packTime', _.round(Inventory.getFloatValue(text), 2));
      }}
    />
  );
};

const UnpackTimeInput = ({
  form,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'unpackTime',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
      }}
      name={'unpackTime'}
      label={'Unpack Time (min)'}
      handleBlur={(event) => {
        const text = event.target.value;
        // Formatting volume removes a trailing decimal
        form.setFieldValue('unpackTime', _.round(Inventory.getFloatValue(text), 2));
      }}
    />
  );
};

const NotesInput = ({
  form,
  autoFocus,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
}) => {
  return (
    <FieldInput
      {...form}
      isResponsive
      style={{flex: 1}}
      setFieldValue={form.setFieldValue}
      input={{
        autoFocus: autoFocus === 'notes',
        onFocus: (event: React.FocusEvent<HTMLInputElement>) => event.target.select(),
        multiline: true,
        style: {height: 72, paddingTop: 8},
        placeholder: 'Enter item notes',
      }}
      name={'notes'}
      label={'Item Notes'}
    />
  );
};

const TakeLeaveInput = ({form}: {form: Form<ItemFormV2PartialToFormType>}) => {
  return (
    <Checkbox
      isResponsive
      isChecked={form.values.take}
      label={'Take'}
      hint={'When unchecked, this item will be marked as “Leave”.'}
      handleToggle={(isChecked) => {
        form.setFieldValue('take', isChecked);
        if (isChecked) {
          form.setFieldValue('takeCount', form.values.leaveCount);
          form.setFieldValue('leaveCount', 0);
        } else {
          form.setFieldValue('leaveCount', form.values.takeCount);
          form.setFieldValue('takeCount', 0);
        }
      }}
    />
  );
};

const EditInventoryItemInputFields = ({
  form,
  autoFocus,
  defaultDensityFactor,
  isEnabledSurveysTimeAdditives,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  autoFocus?: string;
  defaultDensityFactor: number;
  isEnabledSurveysTimeAdditives: boolean;
}) => {
  return (
    <React.Fragment>
      <NameInput form={form} autoFocus={autoFocus} />
      <Space height={16} />
      <Row>
        <VolumeInput
          form={form}
          autoFocus={autoFocus}
          defaultDensityFactor={defaultDensityFactor}
        />
        <Space width={16} />
        <WeightInput
          form={form}
          autoFocus={autoFocus}
          defaultDensityFactor={defaultDensityFactor}
        />
      </Row>
      <Space height={16} />
      <Row>
        <PriceInput form={form} autoFocus={autoFocus} />
        <Space width={16} />
        <QuantityInput form={form} style={{flex: 1}} autoFocus={autoFocus} />
      </Row>
      {isEnabledSurveysTimeAdditives && (
        <React.Fragment>
          <Space height={16} />
          <Row>
            <PackTimeInput form={form} autoFocus={autoFocus} />
            <Space width={16} />
            <UnpackTimeInput form={form} autoFocus={autoFocus} />
          </Row>
        </React.Fragment>
      )}
      <Space height={16} />
      <NotesInput form={form} autoFocus={autoFocus} />
      <Space height={16} />
      <TakeLeaveInput form={form} />
    </React.Fragment>
  );
};

export default EditInventoryItemInputFields;
