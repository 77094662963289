// Libraries
import React from 'react';

// Supermove
import {Form} from '@supermove/components';

// Components
import SupplyFormFields from './SupplyFormFields';
import UpdateSuppliesMutation from './UpdateSuppliesMutation';

type OwnProps = {
  index: number;
  organizationSuppliesForm: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateSupplyForm.defaultProps;

// @ts-expect-error TS(7022): 'UpdateSupplyForm' implicitly has type 'any' becau... Remove this comment to see the full error message
const UpdateSupplyForm = ({index, organizationSuppliesForm, onSuccess, onError}: Props) => (
  <Form
    initialValues={{
      organizationSuppliesForm,
    }}
    onSubmit={(values: any) => {}}
  >
    {({submitForm, setMutationErrors, ...form}: any) => (
      <UpdateSuppliesMutation
        organizationSuppliesForm={form.values.organizationSuppliesForm}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}: any) => (
          <SupplyFormFields
            field={`organizationSuppliesForm.suppliesForm.supplyForms.${index}`}
            form={form}
            isUpdate
            loading={loading}
            handleSubmit={handleSubmit}
          />
        )}
      </UpdateSuppliesMutation>
    )}
  </Form>
);

UpdateSupplyForm.defaultProps = {
  onError: () => {},
};

export default UpdateSupplyForm;
