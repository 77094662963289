// Libraries
import React from 'react';

// Supermove
import {Form, Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';
import DocumentFlowFields, {
  DocumentFlowFieldsFragmentType,
} from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowFields';
import DocumentFlowPageHeader from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowPageHeader';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const FieldsContainer = Styled.View`
  max-width: 800px;
`;

const DocumentFlowPage = ({
  form,
  field,
  submitting,
  handleSubmit,
}: {
  form: Form<UserFlowFormToFormType>;
  field: string;
  submitting: boolean;
  handleSubmit: () => void;
}) => {
  const {loading, data} = useQuery<CreateDocumentFlowPageQueryType>(CreateDocumentFlowPageQuery, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => (
        <Container>
          <DocumentFlowPageHeader form={form} field={field} handleSave={handleSubmit} />
          <ScrollView contentContainerStyle={{padding: 24}}>
            <FieldsContainer>
              <DocumentFlowFields
                form={form}
                field={field}
                organization={data!.viewer.viewingOrganization}
              />
            </FieldsContainer>
          </ScrollView>
        </Container>
      )}
    </Loading>
  );
};

type CreateDocumentFlowPageQueryType = {
  viewer: {
    viewingOrganization: OrganizationModel & DocumentFlowFieldsFragmentType;
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const CreateDocumentFlowPageQuery = gql`
  ${DocumentFlowFields.fragment}
  query DocumentFlowPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...DocumentFlowFields
      }
    }
  }
`;

export default DocumentFlowPage;
