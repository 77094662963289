// Libraries
import React from 'react';

// App
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useCreateFormulaMutation from '@shared/modules/Billing/hooks/useCreateFormulaMutation';
import FormulaEditorModal from 'modules/Organization/Settings/Billing/Formulas/components/FormulaEditorModal';

const CreateFormulaModal = ({organizationId, userId, isOpen, handleClose, refetch}: any) => {
  const formulaForm = FormulaForm.new({organizationId, userId});
  const {form, handleSubmit, submitting} = useCreateFormulaMutation({
    formulaForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <FormulaEditorModal
      organizationId={organizationId}
      title={'Create Billing Formula'}
      form={form}
      field={'formulaForm'}
      handleSubmit={handleSubmit}
      submitting={submitting}
      isOpen={isOpen}
      handleClose={handleClose}
    />
  );
};

export default CreateFormulaModal;
