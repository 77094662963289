// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';

const JobCancelModal = ({jobId, handleClose, refetch, isOpen, onSuccess}: any) => {
  const form = useForm({initialValues: {}});
  const {handleSubmit, submitting} = useFormMutation({
    form,
    mutation: JobCancelModal.mutation,
    variables: {jobId},
    onSuccess: () => {
      handleClose();
      onSuccess && onSuccess();
      refetch && refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Cancel Job'}
      subtitle={'This job will be removed from the project.'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
      deleteButtonText={'Confirm'}
      cancelButtonText={'Go back'}
    />
  );
};

JobCancelModal.mutation = gql`
  mutation JobCancelModal($jobId: Int!) {
    response: cancelJob(jobId: $jobId) {
      ${gql.errors}
      job {
        id
        primaryStatus
        calendarPrimaryStatus
      }
    }
  }
`;

export default JobCancelModal;
