// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import Line from 'modules/App/components/Line';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemLabel = Styled.Text`
  ${Typography.Responsive.Label}
`;

const ItemText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const EmptyText = Styled.Text`
  ${Typography.Responsive.Micro}
  font-style: italic;
`;

const getJobEquipmentTableColumns = ({job}: any) => {
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  return [
    {
      flex: 5,
      headerContent: () => {
        return <Table.HeaderText>Equipment Name</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
      cellContent: ({item: equipment}) => {
        return <Table.CellText>{equipment.name}</Table.CellText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText># Requested</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
      cellContent: ({item: equipment}) => {
        return <Table.CellText>{equipment.quantityRequested}</Table.CellText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Left on Site</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
      cellContent: ({item: equipment}) => {
        return <Table.CellText>{equipment.quantityDelivered}</Table.CellText>;
      },
    },
    {
      flex: 1,
      headerContent: () => {
        return <Table.HeaderText>Price</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'equipment' implicitly has an 'any... Remove this comment to see the full error message
      cellContent: ({item: equipment}) => {
        return <Table.CellText>{Currency.display(equipment.price)}</Table.CellText>;
      },
      isHidden: !showPrice,
    },
  ];
};

const EquipmentListItem = ({equipment, responsive, showPrice}: any) => {
  return (
    <React.Fragment>
      <Row>
        <ItemLabel responsive={responsive}>{equipment.name}</ItemLabel>
        <Space style={{flex: 1}} />
        {showPrice && (
          <ItemText responsive={responsive}>{Currency.display(equipment.price)}</ItemText>
        )}
      </Row>
      <Space height={8} />
      <ItemText responsive={responsive}># Requested: {equipment.quantityRequested}</ItemText>
      <Space height={8} />
      <ItemText responsive={responsive}># Left on Site: {equipment.quantityDelivered}</ItemText>
    </React.Fragment>
  );
};

const EquipmentList = ({job, responsive}: any) => {
  const {commercialEquipments} = job.commercialCatalog;
  const showPrice = job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;

  if (_.isEmpty(commercialEquipments)) {
    return <EmptyText responsive={responsive}>No equipment added.</EmptyText>;
  }

  return (
    <React.Fragment>
      {commercialEquipments.map((equipment: any) => (
        <React.Fragment key={equipment.id}>
          <Line />
          <Space height={8} />
          <EquipmentListItem equipment={equipment} responsive={responsive} showPrice={showPrice} />
          <Space height={8} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

const JobEquipmentList = ({job}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Table
          columnDefinitions={getJobEquipmentTableColumns({job})}
          items={job.commercialCatalog.commercialEquipments}
          emptyStateText={'Equipment you add will be shown here.'}
          isDense
        />
      ) : (
        <EquipmentList job={job} responsive={responsive} />
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEquipmentList.fragment = gql`
  fragment JobEquipmentList on Job {
    id
    organization {
      id
      features {
        isEnabledShowEquipmentAndMaterialsPriceOnDocuments: isEnabled(
          feature: "SHOW_EQUIPMENT_AND_MATERIALS_PRICE_ON_DOCUMENTS"
        )
      }
    }
    commercialCatalog {
      id
      commercialEquipments {
        id
        name
        quantityDelivered
        quantityRequested
        price
      }
    }
  }
`;

export default JobEquipmentList;
