// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import LongDistanceDispatchTripCard from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripCard';

const ViewTripContentContainer = Styled.View`
  background-color: ${colors.gray.background}
  height: 100%;
`;

const BlankViewContainer = Styled.View`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const BlankViewContainerText = Styled.Text`
  ${Typography.Label1};
  color: ${colors.gray.tertiary};
`;

const LongDistanceDispatchTripsList = ({statuses}: any) => {
  const {loading, data} = useQuery(LongDistanceDispatchTripsList.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      statuses,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        // We sort the trip based on status to get all 'In Transit' on top
        const sortedTrips = data.viewer.tripsForUserByStatus.sort(
          (firstTrip: any, secondTrip: any) =>
            TripStatus.getStatusOption(firstTrip.status).label <
            TripStatus.getStatusOption(secondTrip.status).label
              ? -1
              : 1,
        );

        return (
          <ViewTripContentContainer>
            <Space height={12} />
            {_.isEmpty(sortedTrips) ? (
              <BlankViewContainer>
                <BlankViewContainerText>{'There are no trips'}</BlankViewContainerText>
              </BlankViewContainer>
            ) : (
              sortedTrips.map((trip: any, index: any) => {
                return (
                  <React.Fragment key={index}>
                    <LongDistanceDispatchTripCard trip={trip} isListItem />
                    <Space height={4} />
                  </React.Fragment>
                );
              })
            )}
            <Space height={12} />
          </ViewTripContentContainer>
        );
      }}
    </Loading>
  );
};

LongDistanceDispatchTripsList.query = gql`
  ${LongDistanceDispatchTripCard.fragment}
  query LongDistanceDispatchTripsList($statuses: [String]!) {
    ${gql.query}
    viewer {
      id
      tripsForUserByStatus(statuses: $statuses) {
        id
        ...LongDistanceDispatchTripCard
      }
    }
  }
`;

export default LongDistanceDispatchTripsList;
