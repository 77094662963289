// Libraries
import React from 'react';

// Supermove
import {Space, Popover, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const MenuItemTouchable = Styled.Touchable`
  background-color: ${(props) => ((props as any).isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
  padding: 4px 12px;
`;

const ActionMenuItem = ({children, onPress, handleClose}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuItemTouchable
        onPress={() => {
          onPress();
          handleClose();
        }}
        // @ts-expect-error TS(2769): No overload matches this call.
        isHighlighted={isHovered}
        ref={ref}
        activeOpacity={0.8}
      >
        <MenuItemText>{children}</MenuItemText>
      </MenuItemTouchable>
    </React.Fragment>
  );
};

const ProjectCommentActionsPopoverContent = ({
  setDeleteModalOpen,
  setEditCommentOpen,
  handleClose,
}: any) => {
  return (
    <ResponsivePopover.StaticContainer width={150} maxHeight={480}>
      <Space height={12} />
      <ActionMenuItem handleClose={handleClose} onPress={setEditCommentOpen}>
        Edit Comment
      </ActionMenuItem>
      <Space height={4} />
      <ActionMenuItem handleClose={handleClose} onPress={setDeleteModalOpen}>
        Delete Comment
      </ActionMenuItem>
      <Space height={12} />
    </ResponsivePopover.StaticContainer>
  );
};

const ProjectCommentActionsPopover = ({
  popover,
  placement,
  setDeleteModalOpen,
  setEditCommentOpen,
}: any) => {
  return (
    <Popover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ProjectCommentActionsPopoverContent
        setDeleteModalOpen={setDeleteModalOpen}
        setEditCommentOpen={setEditCommentOpen}
        handleClose={popover.handleClose}
      />
    </Popover>
  );
};

export default ProjectCommentActionsPopover;
