// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import TwoChoiceModal from 'modules/App/Modal/components/TwoChoiceModal';

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SendReportMoveSuccessModal.defaultProps;

// @ts-expect-error TS(7022): 'SendReportMoveSuccessModal' implicitly has type '... Remove this comment to see the full error message
const SendReportMoveSuccessModal = ({job, isOpen, onClose}: Props) => (
  <TwoChoiceModal
    emojiName={'thumbsup'}
    title={'Move Receipt Sent'}
    subtitle={`We successfully sent a move receipt.`}
    textButtonLeft={'Go Home'}
    textButtonRight={'View Move'}
    onPressLeft={({navigator}: any) => navigator.push('/')}
    onPressRight={({navigator}: any) => {
      navigator.push('/');
      navigator.push(`/jobs/${job.uuid}`);
    }}
    isOpen={isOpen}
    onClose={onClose}
  />
);

SendReportMoveSuccessModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendReportMoveSuccessModal.fragment = gql`
  fragment SendReportMoveSuccessModal on Job {
    id
    uuid
  }
`;

export default SendReportMoveSuccessModal;
