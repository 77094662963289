// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App

import ShipmentStatus from '@shared/modules/Dispatch/enums/ShipmentStatus';
import ShipmentStatusForm from '@shared/modules/Dispatch/forms/ShipmentStatusForm';
import useUpdateShipmentStatusMutation from '@shared/modules/Dispatch/hooks/useUpdateShipmentStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

type OwnProps = {
  shipment: any;
  onSuccess?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditShipmentStatusDropdown.defaultProps;

// @ts-expect-error TS(7022): 'EditShipmentStatusDropdown' implicitly has type '... Remove this comment to see the full error message
const EditShipmentStatusDropdown = ({shipment, onSuccess}: Props) => {
  const shipmentStatusForm = ShipmentStatusForm.edit(shipment);
  const {form, handleSubmit} = useUpdateShipmentStatusMutation({
    shipmentStatusForm,
    onSuccess: ({shipment}: any) => {
      onSuccess(shipment);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <StatusDropdown
      options={ShipmentStatus.OPTIONS}
      initialValue={form.values.shipmentStatusForm.status}
      onSelect={(option: any) => {
        form.setFieldValue('shipmentStatusForm.status', option.value);
        setTimeout(() => handleSubmit(), 0);
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditShipmentStatusDropdown.fragment = gql`
  ${ShipmentStatusForm.edit.fragment}

  fragment EditShipmentStatusDropdown on Shipment {
    id
    ...ShipmentStatusForm_edit
  }
`;

EditShipmentStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditShipmentStatusDropdown;
