// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const Overlay = Styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(47, 54, 64, 0.4);
`;

const Photo = Styled.Image`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SplitPhoto.defaultProps;

// @ts-expect-error TS(7022): 'SplitPhoto' implicitly has type 'any' because it ... Remove this comment to see the full error message
const SplitPhoto = ({source}: Props) => (
  <Container>
    <Content>
      <Photo resizeMode={'cover'} source={source} />
      <Overlay />
    </Content>
  </Container>
);

SplitPhoto.defaultProps = {};

export default SplitPhoto;
