// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Task} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
`;

type OwnProps = {
  task: any;
  size?: number;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof TaskDueIcon.defaultProps;

// @ts-expect-error TS(7022): 'TaskDueIcon' implicitly has type 'any' because it... Remove this comment to see the full error message
const TaskDueIcon = ({task, size}: Props) => {
  if (!task.isCompleted) {
    if (Task.getIsPastDue(task)) {
      return (
        <TextTooltip isEnabledMobileToast={false} text={'Overdue'}>
          <Container>
            <Icon
              source={Icon.ExclamationTriangle}
              size={size}
              color={colors.red.warning}
              style={{marginRight: 6}}
            />
          </Container>
        </TextTooltip>
      );
    }
    if (Task.getIsDueLaterToday(task)) {
      return (
        <TextTooltip isEnabledMobileToast={false} text={'Due soon'}>
          <Container>
            <Icon
              source={Icon.ExclamationTriangle}
              size={size}
              color={colors.orange.status}
              style={{marginRight: 6}}
            />
          </Container>
        </TextTooltip>
      );
    }
  }
  return null;
};

TaskDueIcon.defaultProps = {
  size: 11,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskDueIcon.fragment = gql`
  ${Task.getIsDueLaterToday.fragment}
  ${Task.getIsPastDue.fragment}
  fragment TaskDueIcon on Task {
    id
    ...Task_getIsDueLaterToday
    ...Task_getIsPastDue
  }
`;

export default TaskDueIcon;
