// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import UpdateTruckForm from 'modules/Organization/Settings/Dispatch/Trucks/components/UpdateTruckForm';

type OwnProps = {
  truck: any;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateTruckModal.defaultProps;

// @ts-expect-error TS(7022): 'UpdateTruckModal' implicitly has type 'any' becau... Remove this comment to see the full error message
const UpdateTruckModal = ({truck, isOpen, onClose, refetch}: Props) => {
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>{`Update Truck`}</SmallModal.BoldText>
      <Space height={16} />
      <SmallModal.Text>{`Update your truck details.`}</SmallModal.Text>
      <Space height={16} />
      <UpdateTruckForm handleClose={onClose} refetch={refetch} truck={truck} />
    </SmallModal>
  );
};

UpdateTruckModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTruckModal.fragment = gql`
  ${UpdateTruckForm.fragment}
  fragment UpdateTruckModal on Truck {
    id
    ...UpdateTruckForm
  }
`;

export default UpdateTruckModal;
