// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Container = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const Header = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (_.get(vars, 'hasErrors') ? colors.red.warning : colors.gray.primary)};
`;

const Action = Styled.Touchable`
`;

const ActionText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'ThemeP... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.secondary : colors.gray.primary)};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type 'ThemeP... Remove this comment to see the full error message
    disabled,
  }) => (disabled ? colors.gray.disabled : colors.white)};
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.warning};
`;

type OwnProps = {
  action?: any;
  actionText?: string;
  handleAction?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Field.defaultProps;

// @ts-expect-error TS(7022): 'Field' implicitly has type 'any' because it does ... Remove this comment to see the full error message
const Field = ({
  index = 0,
  actionText,
  name,
  label,
  action,
  touched,
  errors,
  values,
  component: InputComponent = TextInput,
  input = {},
  handleAction,
  handleBlur,
  handleChange,
  style,
}: Props) => {
  const isTouched = !!_.get(touched, name);
  const errorMessage = _.get(errors, name);
  const hasErrors = isTouched && errorMessage;
  const hasHeader = !_.isNil(label) || action || (actionText && handleAction);

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index} style={style}>
      {hasHeader && (
        <React.Fragment>
          <Header>
            {!_.isNil(label) && (
              <React.Fragment>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <Label vars={{hasErrors}} numberOfLines={1}>
                  {label}
                </Label>
              </React.Fragment>
            )}
            {action}
            {!!actionText && !!handleAction && (
              <Action onPress={handleAction}>
                <ActionText>{actionText}</ActionText>
              </Action>
            )}
          </Header>
          <Space height={4} />
        </React.Fragment>
      )}
      <InputComponent
        {...input}
        name={name}
        value={_.get(values, name)}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {hasErrors && (
        <React.Fragment>
          <Space height={4} />
          <ValidationError>{errorMessage}</ValidationError>
        </React.Fragment>
      )}
    </Container>
  );
};

Field.Label = Label;
Field.TextInput = TextInput;

Field.defaultProps = {
  action: null,
  actionText: null,
  handleAction: null,
};

export default Field;
