// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import TextTooltip from '@shared/design/components/TextTooltip';
import Toast from '@shared/design/components/Toast';

const Text = Styled.Text`
  ${Typography.Body}
`;

const IconContainer = Styled.View`
`;

const CheckboxRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const NewVariableSectionModal = ({
  isOpen,
  handleClose,
  parentForm,
  sectionFormsField,
  form,
  field,
}: any) => {
  const newVariableSectionToast = useToast({
    ToastComponent: Toast,
    message: 'New section added',
  });
  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.HeaderText>{'New Section'}</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>
        {'Create a new section group which will be visible on the bottom of the list.'}
      </SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Section Name'}
        input={{
          placeholder: 'Enter name',
          required: true,
        }}
      />
      <Space height={16} />
      <CheckboxRow>
        <Checkbox
          isChecked={_.get(form.values, `${field}.isDefault`)}
          handleToggle={() => {
            const isDefault = _.get(form.values, `${field}.isDefault`);
            form.setFieldValue(`${field}.isDefault`, !isDefault);
          }}
          childrenRight
        >
          <Space width={8} />
          <Text>Set as default?</Text>
        </Checkbox>
        <Space width={8} />
        <TextTooltip
          isEnabledMobileToast={false}
          text={'Any new variable(s) added will initially be grouped in this section'}
        >
          <IconContainer>
            <Icon color={colors.gray.tertiary} size={Icon.Sizes.Medium} source={Icon.InfoCircle} />
          </IconContainer>
        </TextTooltip>
      </CheckboxRow>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          isDisabled={!_.get(form.values, `${field}.name`)}
          onPress={() => {
            const existingSectionForms = _.get(parentForm.values, sectionFormsField);
            const newSectionForm = _.get(form.values, field);
            const isNewSectionDefault = _.get(newSectionForm, 'isDefault');
            // if the new section is default, we need to ensure no other section is default
            if (isNewSectionDefault) {
              const sectionForms = _.map(existingSectionForms, (sectionForm) => ({
                ...sectionForm,
                isDefault: false,
              }));
              parentForm.setFieldValue(sectionFormsField, [...sectionForms, newSectionForm]);
            } else {
              parentForm.setFieldValue(sectionFormsField, [
                ...existingSectionForms,
                newSectionForm,
              ]);
            }
            newVariableSectionToast.handleToast();
            handleClose();
          }}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default NewVariableSectionModal;
