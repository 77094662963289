// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Pageview, PageContext} from '@supermove/analytics';

// App
import {
  EmployeeLogin,
  RedirectIfAuthenticated,
  RedirectIfNotAuthenticated,
  TrackViewer,
} from 'core/Authentication';
import Page from 'core/Page';

const getPath = ({context, props}: any) => {
  // On the server, path comes from context, otherwise we get it from the `location`.
  return _.get(context, 'serverPath') || _.get(props, 'location.pathname');
};

// A gate is a function that takes in props and calls `next` if it should render the next gate.
export const compose = (context = {}, ...gates: any[]) => {
  // Inject context into the inner-most function.
  const initial = ({next, ...props}: any) => next({context, ...props});
  const combine = (next: any, gate: any) => {
    return (props: any) => gate({...props, context, next});
  };

  // Returns a function that takes in the final props and renders each `gate` in order.
  return _.reduceRight(gates, combine, initial);
};

export const page =
  (name: any) =>
  ({next, context, ...props}: any) => {
    // Determines if this page should be server rendered.
    const {ssr} = context;
    const path = getPath({context, props});
    const tab = _.get(props, 'match.params.tab');

    return (
      <PageContext.Provider value={{pageName: name, appNamespace: context.appNamespace}}>
        <Pageview name={name} path={path} tab={tab}>
          <TrackViewer skip={ssr}>{() => next(props)}</TrackViewer>
        </Pageview>
      </PageContext.Provider>
    );
  };

// For redirecting already logged in users
// if they visit /login or /register.
export const redirectIfAuthenticated =
  ({roles}: any) =>
  ({next, ...props}: any) => {
    return (
      <RedirectIfAuthenticated roles={roles} redirect={'/'}>
        {() => next(props)}
      </RedirectIfAuthenticated>
    );
  };

// For redirecting not logged in users
// to the /login page.
export const redirectIfNotAuthenticated =
  ({roles}: any) =>
  ({next, ...props}: any) => {
    return (
      <RedirectIfNotAuthenticated roles={roles} redirect={'/login'}>
        {() => next(props)}
      </RedirectIfNotAuthenticated>
    );
  };

export const employeeLogin =
  () =>
  ({next, context, ...props}: any) => {
    return (
      <PageContext.Provider value={{appNamespace: context.appNamespace}}>
        <EmployeeLogin {...props}>{() => next(props)}</EmployeeLogin>;
      </PageContext.Provider>
    );
  };

export const isAdmin =
  () =>
  ({next, ...props}: any) => {
    // TODO(mark): Implement logic to check if is an admin or not.
    return next(props);
  };

// The prop pageContext comes from Gatsby createPage calls.
export const view =
  (Component: any, meta = {}) =>
  ({context, ...props}: any) => {
    const path = getPath({context, props});

    return (
      <Page.Provider value={{path}}>
        <Page {...meta}>
          <Component key={path} context={context} {...props} />
        </Page>
      </Page.Provider>
    );
  };
