// Libraries
import React from 'react';

// App
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {HTML} from '@supermove/utils';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  setMutationErrors: (...args: any[]) => any;
  submitForm: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SendCustomerReportMoveReceiptMutation.defaultProps;

// Components

// @ts-expect-error TS(7022): 'SendCustomerReportMoveReceiptMutation' implicitly... Remove this comment to see the full error message
const SendCustomerReportMoveReceiptMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => {
  return (
    <Mutation
      // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
      variables={{
        jobId: values.jobId,
        body: HTML.fixRichTextEditor(values.body),
        subject: values.subject,
        attachmentIds: values.attachmentIds,
      }}
      mutation={SendCustomerReportMoveReceiptMutation.mutation}
      onCompleted={({response: {job, errors}}: any) => {
        submitForm();
        setMutationErrors(errors);

        if (!errors) {
          onSuccess(job);
        } else if (onError) {
          onError(errors);
        }
      }}
    >
      {(handleSubmit: any, {loading, error}: any) =>
        children({
          loading,
          handleSubmit,
        })
      }
    </Mutation>
  );
};

SendCustomerReportMoveReceiptMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendCustomerReportMoveReceiptMutation.mutation = gql`
  mutation SendCustomerReportMoveReceiptMutation(
    $jobId: Int!,
    $body: String!,
    $subject: String!,
    $attachmentIds: [String]!,
  ) {
    response: sendCustomerReportMoveReceipt(
      jobId: $jobId,
      body: $body,
      subject: $subject,
      attachmentIds: $attachmentIds,
    ) {
      ${gql.errors}
      job {
        id
        uuid
      }
    }
  }
`;

export default SendCustomerReportMoveReceiptMutation;
