// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSheet} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ProjectStatusBadge from '@shared/modules/Project/components/ProjectStatusBadge';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ProjectHeaderActions from 'modules/Project/V2/Show/components/ProjectHeaderActions';
import ProjectOverviewSheet from 'modules/Project/V2/Show/components/ProjectOverviewSheet';

const ProjectHeaderMobileContainer = Styled.ButtonV2`
  padding-horizontal: 16px;
  padding-vertical: 8px;
  border-bottom-width: 2px;
  border-bottom-color: ${colors.gray.border};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectIdText = Styled.Text`
  ${Typography.Mobile.Subheading}
`;

const ProjectNameText = Styled.Text`
  ${Typography.Mobile.MicroLabel}
`;

const LoadingComponent = () => {
  return (
    <ProjectHeaderMobileContainer>
      <SkeletonLoader isFullWidth height={SkeletonLoader.HEIGHT.Heading2Text} />
      <Space height={4} />
      <SkeletonLoader width={160} height={SkeletonLoader.HEIGHT.Text} />
    </ProjectHeaderMobileContainer>
  );
};

const ProjectHeaderMobile = ({project, refetch, refetchAndReset, urlFilters}: any) => {
  const projectOverviewSheet = useSheet({name: 'Project Overview Sheet', enableTracking: true});

  return (
    <React.Fragment>
      <ProjectHeaderMobileContainer onPress={projectOverviewSheet.handleOpen}>
        <Row style={{justifyContent: 'space-between'}}>
          <Row>
            <ProjectStatusBadge project={project} />
            <Space width={8} />
            <ProjectIdText>{`Project ${project.identifier}`}</ProjectIdText>
            <Space width={8} />
            <Icon source={Icon.InfoCircle} size={16} color={colors.gray.secondary} />
          </Row>
          <ProjectHeaderActions project={project} refetch={refetch} />
        </Row>
        <Space height={4} />
        <ProjectNameText>{project.name || project.client.name}</ProjectNameText>
      </ProjectHeaderMobileContainer>
      <ProjectOverviewSheet
        project={project}
        isOpen={projectOverviewSheet.isOpen}
        handleClose={projectOverviewSheet.handleClose}
        refetch={refetch}
        refetchAndReset={refetchAndReset}
        urlFilters={urlFilters}
      />
    </React.Fragment>
  );
};

ProjectHeaderMobile.LoadingComponent = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectHeaderMobile.fragment = gql`
  ${ProjectStatusBadge.fragment}
  ${ProjectHeaderActions.fragment}
  ${ProjectOverviewSheet.fragment}

  fragment ProjectHeaderMobile on Project {
    id
    identifier
    name
    client {
      id
      name
    }
    ...ProjectStatusBadge
    ...ProjectHeaderActions
    ...ProjectOverviewSheet
  }
`;

export default ProjectHeaderMobile;
