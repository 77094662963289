// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import ListProjectsPageContentV1 from 'modules/Project/List/components/ListProjectsPageContentV1';
import MoveProjectsTabContent from 'modules/Project/List/components/MoveProjectsTabContent';

const PageContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const LoadingComponentContainer = Styled.View`
  padding-vertical: 16px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 24 : 16)}px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LoadingComponent = () => {
  const responsive = useResponsive();

  if (responsive.desktop) {
    return (
      <LoadingComponentContainer responsive={responsive}>
        <Row>
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} style={{flex: 1}} />
          <Space width={16} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Button} width={100} />
        </Row>
        <Space height={16} />
        <SkeletonLoader height={400} isFullWidth />
      </LoadingComponentContainer>
    );
  }

  return (
    <LoadingComponentContainer responsive={responsive}>
      <Row>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonMobile} style={{flex: 1}} />
        <Space width={16} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonMobile} width={80} />
      </Row>
      <Space height={16} />
      <SkeletonLoader height={120} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={120} isFullWidth />
      <Space height={16} />
      <SkeletonLoader height={120} isFullWidth />
    </LoadingComponentContainer>
  );
};

const MoveProjectsTab = () => {
  const {loading, data} = useQuery(MoveProjectsTab.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <PageContainer>
      <Loading loading={loading} as={LoadingComponent}>
        {() => {
          return (
            <React.Fragment>
              {data.viewer.viewingOrganization.features.isEnabledResponsiveProjectsList ? (
                <MoveProjectsTabContent
                  organization={data.viewer.viewingOrganization}
                  PageLoadingComponent={LoadingComponent}
                />
              ) : (
                <ListProjectsPageContentV1
                  organization={data.viewer.viewingOrganization}
                  jobRequestsCount={data.jobRequestCounter.new}
                />
              )}
            </React.Fragment>
          );
        }}
      </Loading>
    </PageContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectsTab.query = gql`
  ${ListProjectsPageContentV1.fragment}
  ${MoveProjectsTabContent.fragment}

  query MoveProjectsTab {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        features {
          isEnabledResponsiveProjectsList: isEnabled(feature: "RESPONSIVE_PROJECTS_LIST")
        }
        ...ListProjectsPageContentV1
        ...MoveProjectsTabContent
      }
    }
    jobRequestCounter {
      new
    }
  }
`;

export default MoveProjectsTab;
