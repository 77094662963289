// Libraries
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useState} from '@supermove/hooks';

// App
import Sheet from '@shared/design/components/Sheet';
import NewTaskFields from 'modules/TaskManagement/Tasks/components/NewTaskFields';

const LoadingContainer = Styled.View`
  flex: 1;
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );
};

const NewTaskSheet = ({isOpen, handleClose, projectUuid, refetch}: any) => {
  const {data, loading} = useQuery(NewTaskSheet.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid: projectUuid || ''},
    skip: !isOpen,
  });
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <Sheet isOpen={isOpen} headerText={'New Task'} handleClose={handleClose} isFixedHeight>
      <Loading loading={loading || !data} as={LoadingComponent}>
        {() => (
          <ScrollView>
            <NewTaskFields
              key={isOpen}
              organization={data.viewer.viewingOrganization}
              project={data.project}
              handleClose={handleClose}
              refetch={refetch}
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
            />
          </ScrollView>
        )}
      </Loading>
    </Sheet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
NewTaskSheet.query = gql`
  ${NewTaskFields.fragment}
  query NewTaskSheet($projectUuid: String!) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...NewTaskFields_Organization
      }
    }
    project(uuid: $projectUuid) {
      id
      ...NewTaskFields_Project
    }
  }
`;

export default NewTaskSheet;
