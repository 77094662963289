/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import UserRole from '@shared/modules/User/enums/UserRole';
import Field from 'modules/App/components/Field';

const getBookedByOptions = ({filteredUsers}: any) => {
  return filteredUsers.map((user: any) => ({
    label: user.fullName,
    value: user.id,
  }));
};

const SalespersonDropdown = ({organization, form, index}: any) => {
  return (
    <Field
      {...form}
      component={DropdownInput}
      name={'reportViewFiltersForm.salespersonId'}
      label={'Salesperson'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        options: getBookedByOptions({filteredUsers: organization.filteredUsers}),
        placeholder: 'Salesperson',
        setFieldValue: form.setFieldValue,
        style: {
          width: 200,
        },
      }}
    />
  );
};

SalespersonDropdown.fragment = gql`
  fragment SalespersonDropdown on Organization {
    id
    filteredUsers: filteredUsersV2(roles: [${UserRole.getGraphqlArgs(
      // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
      UserRole.OFFICE_ROLES,
    )}]) {
      id
      fullName
    }
  }
`;

export default SalespersonDropdown;
