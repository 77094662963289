// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import UpdateMultibranchAdminViewingOrganizationForm from '@shared/modules/User/forms/UpdateMultibranchAdminViewingOrganizationForm';
import useUpdateMultibranchAdminViewingOrganizationMutation from '@shared/modules/User/hooks/useUpdateMultibranchAdminViewingOrganizationMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Label};
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color || colors.black};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PrimaryLabelContainer = Styled.View`
  background-color: ${colors.gray.border};
  padding: 4px 8px;
`;

const PrimaryLabel = Styled.Text`
  ${Typography.Micro}
`;

const OrganizationButton = Styled.Touchable`
`;

const GlobalHeaderMultibranchOrganizationSwitcherModal = ({isOpen, handleClose}: any) => {
  const {data, loading} = useQuery(GlobalHeaderMultibranchOrganizationSwitcherModal.query, {
    fetchPolicy: 'cache-and-network',
  });

  const updateMultibranchAdminViewingOrganizationForm =
    UpdateMultibranchAdminViewingOrganizationForm.new({
      userId: data?.viewer?.id,
    });

  const {form, handleSubmit} = useUpdateMultibranchAdminViewingOrganizationMutation({
    updateMultibranchAdminViewingOrganizationForm,
    onSuccess: handleClose,
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const handleSubmitChangeOrganizations = async (organizationId: any) => {
    await form.setFieldValue(
      'updateMultibranchAdminViewingOrganizationForm.viewingOrganizationId',
      organizationId,
    );
    handleSubmit();
  };

  return (
    <Modal
      isOpen={isOpen}
      width={Modal.WIDTH.SMALL}
      handlePressOutside={handleClose}
      style={{maxHeight: 600}}
    >
      <Modal.Header>
        <Modal.HeaderText>Change Branch</Modal.HeaderText>
      </Modal.Header>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          // Sort organizations by primary first, then alphabetically
          const sortedOrganizations = _.orderBy(
            data.viewer.viewingOrganization.company.organizations,
            ['isPrimary', 'name'],
            ['desc', 'asc'],
          );
          return (
            <Modal.Body isScrollable>
              {sortedOrganizations.map((organization, index) => {
                const isCurrentOrganization =
                  organization.id === data.viewer.viewingOrganization.id;
                const isFirstOrganization = index === 0;
                return (
                  <React.Fragment key={index}>
                    <OrganizationButton
                      onPress={() => handleSubmitChangeOrganizations(organization.id)}
                    >
                      {!isFirstOrganization && <Space height={16} />}
                      <Row>
                        <Row>
                          {/* @ts-expect-error TS(2769): No overload matches this call. */}
                          <Label color={isCurrentOrganization ? colors.blue.interactive : null}>
                            {organization.name}
                          </Label>
                          {organization.isPrimary && (
                            <React.Fragment>
                              <Space width={8} />
                              <PrimaryLabelContainer>
                                <PrimaryLabel>Main</PrimaryLabel>
                              </PrimaryLabelContainer>
                            </React.Fragment>
                          )}
                        </Row>
                        <Container>
                          {isCurrentOrganization && (
                            <React.Fragment>
                              <Icon
                                source={Icon.CheckCircle}
                                color={colors.blue.interactive}
                                size={16}
                              />
                            </React.Fragment>
                          )}
                        </Container>
                      </Row>
                      <Space height={16} />
                    </OrganizationButton>
                    <Line />
                  </React.Fragment>
                );
              })}
            </Modal.Body>
          );
        }}
      </Loading>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
GlobalHeaderMultibranchOrganizationSwitcherModal.query = gql`
  query GlobalHeaderMultibranchOrganizationSwitcher {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        company {
          id
          organizations {
            id
            name
            slug
            isPrimary
          }
        }
      }
    }
  }
`;

export default GlobalHeaderMultibranchOrganizationSwitcherModal;
