// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Invoice} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import FieldValue from '@shared/design/components/Field/FieldValue';
import InvoicePaymentTerm from '@shared/modules/Billing/enums/InvoicePaymentTerm';
import InvoiceExportStatusWithDate from 'modules/Storage/components/InvoiceExportStatusWithDate';

const FieldValueContainer = Styled.View`
  flex: 1;
`;

const BorderedContainer = Styled.View`
  border-radius: 4px;
  border-color: ${colors.gray.border};
  border-width: 1px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getDisplayDate = (date: any) =>
  date ? Datetime.convertToDisplayDate(date, Datetime.DISPLAY_SHORT_DATE) : null;

const InvoiceSummary = ({invoice}: any) => {
  const {date, dueDate, paymentTerm, hasEmailSendError} = invoice;
  return (
    <React.Fragment>
      {hasEmailSendError && (
        <React.Fragment>
          <ErrorCallout>
            <ErrorCallout.Text color={colors.red.warning}>
              {'Invoice failed to send. '}
              <a href={Invoice.EMAIL_ERROR_HELP_LINK} target={'_blank'}>
                <ErrorCallout.LinkText color={colors.red.warning}>
                  {'View help article'}
                </ErrorCallout.LinkText>
              </a>
              {'.'}
            </ErrorCallout.Text>
          </ErrorCallout>
          <Space height={16} />
        </React.Fragment>
      )}
      <BorderedContainer>
        <Row style={{padding: 12}}>
          <FieldValueContainer>
            <FieldValue
              label={'Invoice Date'}
              value={getDisplayDate(date)}
              empty={'None'}
              numberOfLines={2}
              isResponsive
            />
          </FieldValueContainer>
          <FieldValueContainer>
            <FieldValue
              label={'Due Date'}
              value={getDisplayDate(dueDate)}
              empty={'None'}
              numberOfLines={2}
              isResponsive
            />
          </FieldValueContainer>
          <FieldValueContainer>
            <FieldValue
              label={'Payment Terms'}
              value={InvoicePaymentTerm.getDisplayName(paymentTerm)}
              empty={'None'}
              numberOfLines={2}
              isResponsive
            />
          </FieldValueContainer>
          <FieldValueContainer>
            <React.Fragment>
              <FieldValue.LabelText>Export Status</FieldValue.LabelText>
              <InvoiceExportStatusWithDate invoice={invoice} showDate />
            </React.Fragment>
          </FieldValueContainer>
        </Row>
      </BorderedContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceSummary.fragment = gql`
  ${InvoiceExportStatusWithDate.fragment}
  fragment InvoiceSummary on Invoice {
    id
    date
    dueDate
    paymentTerm
    hasEmailSendError
    ...InvoiceExportStatusWithDate
  }
`;

export default InvoiceSummary;
