// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import TripStatusForm from '@shared/modules/Dispatch/forms/TripStatusForm';
import useUpdateTripStatusMutation from '@shared/modules/Dispatch/hooks/useUpdateTripStatusMutation';
import StatusDropdown from 'modules/Dispatch/LongDistance/components/StatusDropdown';

type OwnProps = {
  trip: any;
  onSuccess?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof EditTripStatusDropdown.defaultProps;

// @ts-expect-error TS(7022): 'EditTripStatusDropdown' implicitly has type 'any'... Remove this comment to see the full error message
const EditTripStatusDropdown = ({trip, onSuccess, size}: Props) => {
  const tripStatusForm = TripStatusForm.edit(trip);
  const {form, handleSubmit} = useUpdateTripStatusMutation({
    tripStatusForm,
    onSuccess: ({trip}: any) => {
      onSuccess(trip);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <StatusDropdown
      options={TripStatus.OPTIONS}
      initialValue={form.values.tripStatusForm.status}
      onSelect={(option: any) => {
        form.setFieldValue('tripStatusForm.status', option.value);
        setTimeout(() => handleSubmit(), 0);
      }}
      size={size}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTripStatusDropdown.fragment = gql`
  ${TripStatusForm.edit.fragment}

  fragment EditTripStatusDropdown on Trip {
    id
    ...TripStatusForm_edit
  }
`;

EditTripStatusDropdown.defaultProps = {
  onSuccess: () => {},
};

export default EditTripStatusDropdown;
