// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation useUpdateJobRequestInternalNotesForm(
    $jobRequestId: Int!,
    $internalNotes: String!,
  ) {
    response: updateJobRequestInternalNotes(
      jobRequestId: $jobRequestId,
      internalNotes: $internalNotes,
    ) {
      ${gql.errors}
      jobRequest {
        id
        internalNotes
      }
    }
  }
`;

const useUpdateJobRequestInternalNotesForm = ({jobRequest, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      internalNotes: jobRequest.internalNotes || '',
    },
  });
  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables: {
      jobRequestId: jobRequest.id,
      internalNotes: form.values.internalNotes,
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateJobRequestInternalNotesForm.fragment = gql`
  fragment useUpdateJobRequestInternalNotesForm on JobRequest {
    id
    internalNotes
  }
`;

export default useUpdateJobRequestInternalNotesForm;
