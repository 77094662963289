// Libraries
import React from 'react';

// Supermove
import {Emoji, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H5`
  flex: 1;
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Description = Styled.H7`
  color: ${colors.gray.secondary};
`;

const JobName = Styled.H6`
  margin-top: 5px;
  color: ${colors.blue.interactive};
  text-decoration: underline;
  text-decoration-color: ${colors.blue.interactive};
`;

const Text = Styled.H6`
  margin-top: 5px;
`;

const ReportMoveJobInfo = ({job}: any) => (
  <Container>
    <Row>
      <Emoji component={EmojiText} name={'truck'} />
      <Name>Move Details</Name>
    </Row>
    <Description>
      {job
        ? 'This report is connected to a move on Supermove.'
        : 'This report is not connected to a move on Supermove.'}
    </Description>
    {job && (
      <React.Fragment>
        <a href={`/jobs/${job.uuid}`} style={{flex: 1}} target={'_blank'}>
          <JobName>{Job.getFullName(job)}</JobName>
        </a>
        <Text>{Job.getDisplayDate(job)}</Text>
      </React.Fragment>
    )}
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveJobInfo.fragment = gql`
  ${Job.getDisplayDate.fragment}
  ${Job.getFullName.fragment}

  fragment ReportMoveJobInfo on Job {
    id
    uuid
    ...Job_getDisplayDate
    ...Job_getFullName
  }
`;

export default ReportMoveJobInfo;
