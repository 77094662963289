// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const ViewCell = ({label, placeholder = 'Select default item', value}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const ProjectTypeViewCell = ({label, value, enabledForAll}: any) => {
  return (
    <Row>
      <Column>
        <EditPanel.LabelText>{label}</EditPanel.LabelText>
        <Space height={8} />
        {enabledForAll ? (
          <Row>
            <EditPanel.Text>All project types</EditPanel.Text>
          </Row>
        ) : (
          value.map(({name, color}: any, index: any) => (
            <Row key={index}>
              <EditPanel.Text style={{color}}>•</EditPanel.Text>
              <Space width={7} />
              <EditPanel.Text>{name}</EditPanel.Text>
            </Row>
          ))
        )}
      </Column>
    </Row>
  );
};

const BatchKickoffHourDropdown = ({form, label, options, isDisabled = false}: any) => {
  return (
    <FieldInput
      {...form}
      label={'Daily export time'}
      name={`codatIntegrationForm.batchKickoffHour`}
      isRequired
      component={DropdownInput}
      input={{
        isPortaled: true,
        disabled: isDisabled,
        options,
        placeholder: 'Select hour',
        setFieldValue: form.setFieldValue,
        style: {
          flex: 1,
        },
      }}
      style={{
        flex: 1,
      }}
    />
  );
};

const CodatAutomaticExportSettingsPanelEdit = ({form, codatIntegration}: any) => {
  const {organization} = codatIntegration;
  const {timezone} = organization;

  const enqueueForAllProjectTypesForInvoiceFinalizedField =
    'codatIntegrationForm.isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes';
  const isEnqueueForAllProjectTypesForInvoiceFinalizedEnabled = _.get(
    form.values,
    enqueueForAllProjectTypesForInvoiceFinalizedField,
  );

  const projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField =
    'codatIntegrationForm.projectTypeIdsToEnqueueForExportUponInvoiceFinalized';

  const isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabledField =
    'codatIntegrationForm.isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled';
  const isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled = _.get(
    form.values,
    isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabledField,
  );

  const enqueueForAllProjectTypesForPaymentRecordedField =
    'codatIntegrationForm.isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes';
  const isEnqueueForAllProjectTypesForPaymentRecordedEnabled = _.get(
    form.values,
    enqueueForAllProjectTypesForPaymentRecordedField,
  );

  const projectTypeIdsToEnqueueForExportUponPaymentRecordedField =
    'codatIntegrationForm.projectTypeIdsToEnqueueForExportUponPaymentRecorded';

  const isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabledField =
    'codatIntegrationForm.isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled';
  const isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled = _.get(
    form.values,
    isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabledField,
  );

  const isAutomaticExportEnabledField = 'codatIntegrationForm.isAutomaticExportEnabled';
  const isAutomaticExportEnabled = _.get(form.values, isAutomaticExportEnabledField);

  return (
    <>
      {organization.features.isEnabledAccountingSettingsV2 && (
        <>
          {/* Project Types to Export on Invoice Finalized Setting */}
          <Row>
            <SwitchField
              form={form}
              field={isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabledField}
              onChangeValue={(switchValue) => {
                if (!switchValue) {
                  form.setFieldValue(projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField, []);
                }
              }}
            />
            <Space width={8} />
            <EditPanel.LabelText>
              {'Automatically Queue for Export When an Invoice is Finalized'}
            </EditPanel.LabelText>
          </Row>
          {isProjectTypeIdsToEnqueueForExportUponInvoiceFinalizedEnabled && (
            <>
              <Space height={12} />
              <Row>
                <Column>
                  <FieldInput
                    {...form}
                    label={'Project Types'}
                    name={projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField}
                    isRequired
                    component={MultiDropdownCheckboxInput}
                    input={{
                      // HACK(cooper): There is some hacky stuff going on to achieve this select all UX
                      onOptionAdded: (addedValue: any) => {
                        if (addedValue === CodatIntegrationForm.ALL_PROJECT_TYPES) {
                          // Case where ALL is not selected and it becomes selected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForInvoiceFinalizedField,
                            true,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField,
                            [CodatIntegrationForm.ALL_PROJECT_TYPES],
                          );
                        } else if (
                          addedValue !== CodatIntegrationForm.ALL_PROJECT_TYPES &&
                          isEnqueueForAllProjectTypesForInvoiceFinalizedEnabled
                        ) {
                          // Case where ALL is selected and another project type becomes "deselected"
                          // Note that in this case the option is actually selected but the user thinks it's been deselected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForInvoiceFinalizedField,
                            false,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField,
                            codatIntegration.organization.projectTypes.reduce(
                              (selectedProjectTypes: any, projectType: any) => {
                                if (projectType.id !== addedValue) {
                                  return [...selectedProjectTypes, _.toString(projectType.id)];
                                }
                                return selectedProjectTypes;
                              },
                              [],
                            ),
                          );
                        }
                      },
                      onOptionRemoved: (removedValue: any) => {
                        if (removedValue === CodatIntegrationForm.ALL_PROJECT_TYPES) {
                          // Case where ALL is selected and it becomes deselected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForInvoiceFinalizedField,
                            false,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponInvoiceFinalizedField,
                            [],
                          );
                        }
                      },
                      overrideCheckboxes: _.get(
                        form.values,
                        enqueueForAllProjectTypesForInvoiceFinalizedField,
                      ),
                      options: [
                        {value: CodatIntegrationForm.ALL_PROJECT_TYPES, label: 'All Project Types'},
                        ...codatIntegration.organization.projectTypes.map(({id, name}: any) => ({
                          value: id,
                          label: name,
                        })),
                      ],
                      usePills: true,
                      placeholder: 'Select project type(s)',
                      closeMenuOnSelect: false,
                      isSearchable: true,
                      isPortaled: true,
                      setFieldValue: form.setFieldValue,
                      style: {flex: 1},
                    }}
                  />
                </Column>
              </Row>
            </>
          )}
          <Space height={24} />
          {/* Project Types to Export on Payment Recorded Setting */}
          <Row>
            <SwitchField
              form={form}
              field={isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabledField}
              onChangeValue={(switchValue) => {
                if (!switchValue) {
                  form.setFieldValue(projectTypeIdsToEnqueueForExportUponPaymentRecordedField, []);
                }
              }}
            />
            <Space width={8} />
            <EditPanel.LabelText>
              {'Automatically Queue for Export When Payment is Recorded'}
            </EditPanel.LabelText>
          </Row>
          {isProjectTypeIdsToEnqueueForExportUponPaymentRecordedEnabled && (
            <>
              <Space height={12} />
              <Row>
                <Column>
                  <FieldInput
                    {...form}
                    label={'Project Types'}
                    name={projectTypeIdsToEnqueueForExportUponPaymentRecordedField}
                    isRequired
                    component={MultiDropdownCheckboxInput}
                    input={{
                      // HACK(cooper): There is some hacky stuff going on to achieve this select all UX
                      onOptionAdded: (addedValue: any) => {
                        if (addedValue === CodatIntegrationForm.ALL_PROJECT_TYPES) {
                          // Case where ALL is not selected and it becomes selected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForPaymentRecordedField,
                            true,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponPaymentRecordedField,
                            [CodatIntegrationForm.ALL_PROJECT_TYPES],
                          );
                        } else if (
                          addedValue !== CodatIntegrationForm.ALL_PROJECT_TYPES &&
                          isEnqueueForAllProjectTypesForPaymentRecordedEnabled
                        ) {
                          // Case where ALL is selected and another project type becomes "deselected"
                          // Note that in this case the option is actually selected but the user thinks it's been deselected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForPaymentRecordedField,
                            false,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponPaymentRecordedField,
                            codatIntegration.organization.projectTypes.reduce(
                              (selectedProjectTypes: any, projectType: any) => {
                                if (projectType.id !== addedValue) {
                                  return [...selectedProjectTypes, _.toString(projectType.id)];
                                }
                                return selectedProjectTypes;
                              },
                              [],
                            ),
                          );
                        }
                      },
                      onOptionRemoved: (removedValue: any) => {
                        if (removedValue === CodatIntegrationForm.ALL_PROJECT_TYPES) {
                          // Case where ALL is selected and it becomes deselected
                          form.setFieldValue(
                            enqueueForAllProjectTypesForPaymentRecordedField,
                            false,
                          );
                          form.setFieldValue(
                            projectTypeIdsToEnqueueForExportUponPaymentRecordedField,
                            [],
                          );
                        }
                      },
                      overrideCheckboxes: _.get(
                        form.values,
                        enqueueForAllProjectTypesForPaymentRecordedField,
                      ),
                      options: [
                        {value: CodatIntegrationForm.ALL_PROJECT_TYPES, label: 'All Project Types'},
                        ...codatIntegration.organization.projectTypes.map(({id, name}: any) => ({
                          value: id,
                          label: name,
                        })),
                      ],
                      usePills: true,
                      placeholder: 'Select project type(s)',
                      closeMenuOnSelect: false,
                      isSearchable: true,
                      isPortaled: true,
                      setFieldValue: form.setFieldValue,
                      style: {flex: 1},
                    }}
                  />
                </Column>
              </Row>
            </>
          )}
          <Space height={24} />
        </>
      )}
      {/* Daily Export Setting */}
      <Row>
        <SwitchField
          form={form}
          field={isAutomaticExportEnabledField}
          onChangeValue={(switchValue) => {
            if (!switchValue) {
              form.setFieldValue('codatIntegrationForm.batchKickoffHour', null);
            }
          }}
        />
        <Space width={8} />
        <EditPanel.LabelText>{'Enable Automatic Daily Exports to Quickbooks'}</EditPanel.LabelText>
      </Row>
      {isAutomaticExportEnabled && (
        <>
          <Space height={12} />
          <Row>
            <Column>
              <BatchKickoffHourDropdown
                form={form}
                options={Datetime.getHourDropdownOptions(timezone)}
              />
            </Column>
            <Space style={{flex: 1}} />
          </Row>
        </>
      )}
    </>
  );
};

const CodatAutomaticExportSettingsPanel = ({codatIntegration}: any) => {
  const {
    isAutomaticExportEnabled,
    batchKickoffHour,
    isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes,
    projectTypesToEnqueueForExportUponInvoiceFinalized,
    isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes,
    projectTypesToEnqueueForExportUponPaymentRecorded,
    organization: {
      features: {isEnabledAccountingSettingsV2},
    },
  } = codatIntegration;
  const {timezone} = codatIntegration.organization;
  const prettyHour =
    batchKickoffHour && Datetime.getFriendlyHourString(batchKickoffHour, timezone, true);

  return (
    <React.Fragment>
      {isEnabledAccountingSettingsV2 && (
        <>
          {/* Project Types to Export on Invoice Finalized Setting */}
          <Row>
            <Column>
              <ViewCell
                label={'Automatically Queue for Export When an Invoice is Finalized'}
                value={
                  isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes ||
                  !!projectTypesToEnqueueForExportUponInvoiceFinalized.length
                    ? 'Yes'
                    : 'No'
                }
              />
            </Column>
            {(isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes ||
              !!projectTypesToEnqueueForExportUponInvoiceFinalized.length) && (
              <Column>
                <ProjectTypeViewCell
                  label={'Project Types'}
                  value={projectTypesToEnqueueForExportUponInvoiceFinalized}
                  enabledForAll={isEnabledEnqueueForExportUponInvoiceFinalizedForAllProjectTypes}
                />
              </Column>
            )}
          </Row>
          <Space height={24} />
          {/* Project Types to Export on Payment Recorded Setting */}
          <Row>
            <Column>
              <ViewCell
                label={'Automatically Queue for Export When Payment is Recorded'}
                value={
                  isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes ||
                  !!projectTypesToEnqueueForExportUponPaymentRecorded.length
                    ? 'Yes'
                    : 'No'
                }
              />
            </Column>
            {(isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes ||
              !!projectTypesToEnqueueForExportUponPaymentRecorded.length) && (
              <Column>
                <ProjectTypeViewCell
                  label={'Project Types'}
                  value={projectTypesToEnqueueForExportUponPaymentRecorded}
                  enabledForAll={isEnabledEnqueueForExportUponPaymentRecordedForAllProjectTypes}
                />
              </Column>
            )}
          </Row>
          <Space height={24} />
        </>
      )}
      {/* Daily Export Setting */}
      <Row>
        <Column>
          <ViewCell
            label={'Enable Automatic Daily Exports to Quickbooks'}
            value={isAutomaticExportEnabled ? 'Yes' : 'No'}
          />
        </Column>
        {isAutomaticExportEnabled && (
          <Column>
            <ViewCell
              label={'Export Time'}
              value={prettyHour}
              placeholder={'Select Batch Kickoff Hour'}
            />
          </Column>
        )}
      </Row>
    </React.Fragment>
  );
};

CodatAutomaticExportSettingsPanel.Loading = () => {
  return (
    <React.Fragment>
      <Row>
        <Column>
          <EditPanel.LabelText>
            {'Automatically Queue for Export When an Invoice is Finalized'}
          </EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={256} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>
            {'Automatically Queue for Export When Payment is Recorded'}
          </EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={256} />
        </Column>
      </Row>
      <Space height={24} />
      <Row>
        <Column>
          <EditPanel.LabelText>
            {'Enable Automatic Daily Exports to Quickbooks'}
          </EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={256} />
        </Column>
        <Column>
          <EditPanel.LabelText>{'Export Time'}</EditPanel.LabelText>
          <Space height={8} />
          <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={88} />
        </Column>
      </Row>
      <Space height={24} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CodatAutomaticExportSettingsPanel.fragment = gql`
  fragment CodatAutomaticExportSettingsPanel on CodatIntegration {
    id
    isAutomaticExportEnabled
    batchKickoffHour
    projectTypesToEnqueueForExportUponInvoiceFinalized {
      id
      name
      color
    }
    projectTypesToEnqueueForExportUponPaymentRecorded {
      id
      name
      color
    }
    organization {
      id
      timezone
      projectTypes {
        id
        name
      }
      features {
        isEnabledAccountingSettingsV2: isEnabled(feature: "ACCOUNTING_SETTINGS_V2")
      }
    }
  }
`;

CodatAutomaticExportSettingsPanel.Edit = CodatAutomaticExportSettingsPanelEdit;

export default CodatAutomaticExportSettingsPanel;
