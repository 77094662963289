/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

const Container = Styled.View`
`;

const TimeRangesContainer = Styled.View`
  flex-direction: row;
`;

const Item = Styled.View`
  flex: ${(props) => (props as any).length || 0};
`;

const Content = Styled.View`
  justify-content: center;
  height: 40px;
  background-color: ${(props) => (props as any).color};
`;

const Name = Styled.H7`
  margin-horizontal: 10px;
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Times = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const StartTime = Styled.H8`
  flex: 1;
  margin-top: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const EndTime = Styled.H8`
  margin-top: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const TooltipText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(300)}
`;

const TimeRangeItem = ({length, timeRange}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Item length={length}>
    <Tooltip
      placement={'bottom'}
      mouseEnterDelay={0.0}
      mouseLeaveDelay={0.0}
      // @ts-expect-error TS(2769): No overload matches this call.
      overlay={() => <TooltipText>{TimeRange.getTooltipText(timeRange)}</TooltipText>}
    >
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Content color={TimeRange.getColor(timeRange)}>
        <Name numberOfLines={1}>{timeRange.name}</Name>
      </Content>
    </Tooltip>
  </Item>
);

const RemainingTime = ({length}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Item length={length}>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Content color={colors.gray.border} />
  </Item>
);

const EmptyView = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: ${colors.gray.border};
`;

const EmptyText = Styled.H6`
  color: ${colors.gray.secondary};
`;

const getStartTimeText = ({timeRanges}: any) => {
  if (timeRanges.length === 0) {
    return '';
  }
  return Datetime.convertToDisplayTime(timeRanges[0].start, Datetime.FORM_TIME);
};

const getEndTimeText = ({timeRanges}: any) => {
  if (timeRanges.length === 0) {
    return '';
  }
  const timeRangeEnd = timeRanges[timeRanges.length - 1].end;
  if (timeRangeEnd) {
    return Datetime.convertToDisplayTime(timeRanges[timeRanges.length - 1].end, Datetime.FORM_TIME);
  }
  return 'Move In Progress';
};

const TimeRangesChart = ({hasConfirmedTimes, timeRanges}: any) => {
  return (
    <Container>
      <TimeRangesContainer>
        {timeRanges.map((timeRange: any, index: any) => (
          <TimeRangeItem
            key={index}
            length={TimeRange.getMinutes(timeRange)}
            timeRange={timeRange}
          />
        ))}
        {timeRanges.length === 0 ? (
          <EmptyView>
            <EmptyText>No times entered.</EmptyText>
          </EmptyView>
        ) : (
          <RemainingTime
            length={TimeRange.getEstimatedRemainingMinutes(timeRanges, {hasConfirmedTimes})}
          />
        )}
      </TimeRangesContainer>
      <Times>
        <StartTime>{getStartTimeText({timeRanges})}</StartTime>
        <EndTime>{getEndTimeText({timeRanges})}</EndTime>
      </Times>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TimeRangesChart.fragment = gql`
  fragment TimeRangesChart on TimeRange {
    name
    kind
    start
    end
  }
`;

export default TimeRangesChart;
