// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App

import DisplayValuesForm from '@shared/modules/Billing/forms/DisplayValuesForm';
import ValueFields from 'modules/Project/Billing/components/ValueFields';

const EmptyStateText = Styled.Text`
  ${Typography.Responsive.Body}
  text-align: center;
`;

const BillingValueFields = ({
  orderedValues,
  updateValuesObject,
  isDisabledValuesHidden,
  onBlur,
  numColumns,
}: any) => {
  const responsive = useResponsive();
  const displayValuesForm = DisplayValuesForm.edit(orderedValues);
  // Typically the below useForm call would be called in the useMutation function
  // Since we aren't going to be sending this data for a mutation we can do that logic here
  const form = useForm({
    initialValues: {
      displayValuesForm: DisplayValuesForm.toForm(displayValuesForm),
    },
  });
  // Empty State
  const field = 'displayValuesForm.valueForms';
  const visibleValues = isDisabledValuesHidden
    ? _.get(form.values, field).filter((valueForm: any) => !valueForm.isDisabled)
    : orderedValues;
  if (visibleValues.length === 0) {
    return <EmptyStateText responsive={responsive}>No variables to display.</EmptyStateText>;
  }
  return (
    <ValueFields
      field={field}
      form={form}
      handleBlur={(valueForm: any) => {
        updateValuesObject[valueForm.valueId] = valueForm;
        onBlur && setTimeout(() => onBlur(), 0);
      }}
      isDisabledValuesHidden={isDisabledValuesHidden}
      numColumns={numColumns}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingValueFields.fragment = gql`
  ${DisplayValuesForm.edit.fragment}
  fragment BillingValueFields on Value {
    id
    ...DisplayValuesForm_edit
  }
`;

export default BillingValueFields;
