// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H7`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {
  email?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ResetPasswordModal.defaultProps;

// @ts-expect-error TS(7022): 'ResetPasswordModal' implicitly has type 'any' bec... Remove this comment to see the full error message
const ResetPasswordModal = ({email, trigger}: Props) => {
  const {navigator} = useNavigationDOM();

  return (
    <Modal shouldCloseOnEsc={false} shouldCloseOnOverlayClick={false} trigger={trigger}>
      {() => (
        <Container pointerEvents={'box-none'}>
          <Wrapper width={350}>
            <Circle>
              <Emoji component={EmojiText} name={'email'} />
            </Circle>
            <Content>
              <Title>Email Sent</Title>
              <Subtitle>
                {`An email has been sent to ${email} with a link to reset your password.`}
              </Subtitle>
              <Actions>
                <Button onPress={() => navigator.push(`/login`)}>
                  <Text>Return to Login</Text>
                </Button>
              </Actions>
            </Content>
          </Wrapper>
        </Container>
      )}
    </Modal>
  );
};

ResetPasswordModal.defaultProps = {
  email: '',
};

export default ResetPasswordModal;
