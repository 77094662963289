// Libraries
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App

import RoomForm from '@shared/modules/Inventory/forms/RoomForm';
import useCreateRoomMutation from '@shared/modules/Inventory/hooks/useCreateRoomMutation';
import Modal from 'components/Modal';
import CreateCustomRoomModal from 'modules/Room/components/CreateCustomRoomModal';

const Container = Styled.View`
  padding-top: 20px;
  height: 400px;
`;

const Item = Styled.Touchable`
  padding-horizontal: 20px;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const LoadingContent = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Indicator = Styled.Loading`
`;

const RoomTypeItem = ({disabled, roomType, onSelect}: any) => {
  return (
    <Item disabled={disabled} onPress={() => onSelect(roomType)}>
      <Name>{roomType.name}</Name>
    </Item>
  );
};

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const CreateRoomModal = ({isOpen, inventory, handleClose, onSuccess}: any) => {
  const roomForm = RoomForm.new({inventoryId: inventory.id});
  const {form, submitting, handleSubmit} = useCreateRoomMutation({
    roomForm,
    onSuccess: ({room}: any) => {
      handleClose();
      onSuccess({room});
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  const {
    isOpen: customIsOpen,
    handleOpen: customHandleOpen,
    handleClose: customHandleClose,
  } = useModal();

  const {project} = inventory;
  const {roomTypes} = project.projectType.defaultInventoryLibrary;

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        title={'Add New Room'}
        subtitle={'Add a room to start collecting inventory.'}
        onClose={handleClose}
      >
        <Container>
          <Line />
          {submitting ? (
            <LoadingContent>
              <Indicator size={'large'} color={colors.gray.secondary} />
            </LoadingContent>
          ) : (
            <FlatList
              data={roomTypes}
              keyExtractor={(roomType: any) => roomType.id}
              renderItem={({item: roomType, index}: any) => (
                <React.Fragment>
                  <RoomTypeItem
                    disabled={submitting}
                    roomType={roomType}
                    onSelect={(roomType: any) => {
                      // Update the form and submit the roomTypeId and name.
                      handleSubmit({
                        variables: {
                          roomForm: RoomForm.toMutation({
                            ...form.values.roomForm,
                            roomTypeId: roomType.id,
                            name: roomType.name,
                          }),
                        },
                      });
                    }}
                  />
                  <Line />
                </React.Fragment>
              )}
              ListFooterComponent={() => (
                <Item
                  disabled={submitting}
                  onPress={() => {
                    handleClose();
                    customHandleOpen();
                  }}
                >
                  <Name>Custom Room</Name>
                </Item>
              )}
            />
          )}
        </Container>
      </Modal>
      <CreateCustomRoomModal
        isOpen={customIsOpen}
        inventory={inventory}
        handleClose={customHandleClose}
        onSuccess={onSuccess}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateRoomModal.fragment = gql`
  ${CreateCustomRoomModal.fragment}

  fragment CreateRoomModal on Inventory {
    id
    uuid
    project {
      id
      projectType {
        id
        defaultInventoryLibrary {
          id
          roomTypes {
            id
            name
          }
        }
      }
      organization {
        id
        roomTypes {
          id
          name
        }
      }
    }
    allRooms {
      id
      roomTypeId
    }
    ...CreateCustomRoomModal
  }
`;

export default CreateRoomModal;
