// Supermove
import {EmailTemplateForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const useCreateEmailTemplateForm = ({emailTemplateForm, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      emailTemplateForm: EmailTemplateForm.toForm(emailTemplateForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateEmailTemplateForm.mutation,
    variables: {
      emailTemplateForm: EmailTemplateForm.toMutation(form.values.emailTemplateForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useCreateEmailTemplateForm.mutation = gql`
  mutation CreateEmailTemplateMutation(
    $emailTemplateForm: EmailTemplateForm!,
  ) {
    response: createEmailTemplate(
      emailTemplateForm: $emailTemplateForm,
    ) {
      ${gql.errors}
      emailTemplate {
        id
        kind
        subject
        body
      }
    }
  }
`;

export default useCreateEmailTemplateForm;
