// Libraries
import React from 'react';

// App
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';

type OwnProps = {
  quitEditingModal: any;
  handleCloseParent: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof QuitEditingWidgetModal.defaultProps;

// @ts-expect-error TS(7022): 'QuitEditingWidgetModal' implicitly has type 'any'... Remove this comment to see the full error message
const QuitEditingWidgetModal = ({quitEditingModal, handleCloseParent}: Props) => {
  return (
    <MessageModal
      isOpen={quitEditingModal.isOpen}
      title={'Quit editing?'}
      // @ts-expect-error TS(2322): Type '{ isOpen: any; title: string; message: strin... Remove this comment to see the full error message
      message={`Your changes will not be saved.`}
      handlePressOutside={quitEditingModal.handleClose}
      handlePrimaryAction={quitEditingModal.handleClose}
      handleSecondaryAction={() => {
        handleCloseParent();
        quitEditingModal.handleClose();
      }}
      primaryActionText={'Keep Editing'}
      secondaryActionText={'Quit Without Saving'}
    />
  );
};

QuitEditingWidgetModal.defaultProps = {};

export default QuitEditingWidgetModal;
