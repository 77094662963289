// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Link} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useHover, useNavigationDOM, useResponsive, useSheet} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App

import IconButtonDelete from '@shared/design/components/IconButton/IconButtonDelete';
import IconButtonEdit from '@shared/design/components/IconButton/IconButtonEdit';
import ProjectAttachmentSheet from 'modules/Project/Attachments/ProjectAttachmentSheet';
import AttachmentsFileDropzone from 'modules/Project/Attachments/components/AttachmentsFileDropzone';
import DeleteAttachmentModal from 'modules/Storage/components/DeleteAttachmentModal';

const GridContainer = Styled.View`
  overflow: hidden;
  width: 100%;
`;

// width: calc() is neccesary here since it's a nested flex with negative margin
// otherwise the parent container will flex ignoring the negative margin
const AttachmentContainer = Styled.View`
  width: calc(100% + 6px);
  margin-horizontal: -3px;
  margin-bottom: -12px;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(150px, 1fr));
`;

const GridItemContainer = Styled.ButtonV2`
  height: 130px;
  flex-direction: column;
  border: 1px solid ${({
    // @ts-expect-error TS(2339): Property 'isHovered' does not exist on type 'Theme... Remove this comment to see the full error message
    isHovered,
  }) => (isHovered ? colors.blue.interactive : colors.gray.border)};
  border-radius: 4px;
  overflow: hidden;
  margin-horizontal: 3px;
  margin-bottom: 12px;
`;

const PreviewImageContainer = Styled.View`
  padding: 12px;
  height: 96px;
  border-bottom-color: ${colors.gray.border};
  border-bottom-style: solid;
  border-bottom-width: 1px;
  background-image: ${({
    // @ts-expect-error TS(2339): Property 'fileUrl' does not exist on type 'ThemePr... Remove this comment to see the full error message
    fileUrl,
  }) => `url(${fileUrl})`};
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-origin: content-box;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const PreviewNonImageText = Styled.Text`
  ${Typography.Responsive.Label}
  position: absolute;
`;

const FilenameContainer = Styled.View`
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-horizontal: 8px;
`;

const FilenameText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.primary};
`;

const ActionButtonsContainer = Styled.View`
  flex-direction: row;
  display: ${(props) => ((props as any).isHovered ? 'flex' : 'none')};
`;

const ActionButtons = ({attachment, refetch, isHovered}: any) => {
  const deleteJobPageAttachmentModal = useModal({name: 'Delete Job Page Attachment Modal'});
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ActionButtonsContainer isHovered={isHovered}>
        <Link
          to={`/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=edit`}
        >
          <IconButtonEdit />
        </Link>
        <Space width={8} />
        <IconButtonDelete onPress={deleteJobPageAttachmentModal.handleOpen} />
      </ActionButtonsContainer>
      <DeleteAttachmentModal
        refetch={refetch}
        deleteAttachmentModal={deleteJobPageAttachmentModal}
        attachment={attachment}
      />
    </React.Fragment>
  );
};

const PreviewImage = ({attachment, refetch, isHovered, responsive}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <PreviewImageContainer fileUrl={attachment.file.isImage ? attachment.file.downloadUrl : ''}>
      {!attachment.file.isImage && (
        <PreviewNonImageText responsive={responsive} numberOfLines={1}>
          {attachment.file.filetype}
        </PreviewNonImageText>
      )}
      {responsive.desktop && (
        <ActionButtons attachment={attachment} refetch={refetch} isHovered={isHovered} />
      )}
    </PreviewImageContainer>
  );
};

const AttachmentGridItem = ({attachment, refetch}: any) => {
  const {isHovered, ref} = useHover();
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();
  const projectAttachmentSheet = useSheet({name: 'Project Attachment Sheet'});
  return (
    <React.Fragment>
      <GridItemContainer
        ref={ref}
        isHovered={isHovered}
        onPress={() => {
          if (responsive.desktop) {
            navigator.push(
              `/projects/${attachment.project.uuid}/attachments/${attachment.uuid}?status=view`,
            );
          } else {
            projectAttachmentSheet.handleOpen();
          }
        }}
      >
        <PreviewImage
          attachment={attachment}
          refetch={refetch}
          isHovered={isHovered}
          responsive={responsive}
        />
        <FilenameContainer>
          <FilenameText responsive={responsive} numberOfLines={1}>
            {attachment.file.name}
          </FilenameText>
        </FilenameContainer>
      </GridItemContainer>
      <ProjectAttachmentSheet
        key={projectAttachmentSheet.key}
        isOpen={projectAttachmentSheet.isOpen}
        handleClose={projectAttachmentSheet.handleClose}
        attachment={attachment}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const AttachmentGrid = ({attachments, refetch}: any) => {
  return (
    <GridContainer>
      <AttachmentContainer>
        {attachments.map((attachment: any, index: any) => {
          return <AttachmentGridItem refetch={refetch} key={index} attachment={attachment} />;
        })}
      </AttachmentContainer>
    </GridContainer>
  );
};

const AttachmentsGridView = ({isDragActive, attachments, refetch, isWidget}: any) => {
  const responsive = useResponsive();
  const isSmallParentContainer = isWidget || responsive.mobile;

  if (isDragActive || _.isEmpty(attachments)) {
    return (
      <AttachmentsFileDropzone
        attachments={attachments}
        isTableHeaderVisible={!isSmallParentContainer}
        isDragActive={isDragActive}
      />
    );
  }
  return <AttachmentGrid attachments={attachments} refetch={refetch} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentsGridView.fragment = gql`
  ${DeleteAttachmentModal.fragment}
  ${ProjectAttachmentSheet.fragment}

  fragment AttachmentsGridView on Attachment {
    id
    uuid
    project {
      id
      uuid
    }
    file {
      id
      name
      filetype
      isImage
      downloadUrl
    }
    ...DeleteAttachmentModal
    ...ProjectAttachmentSheet
  }
`;

export default AttachmentsGridView;
