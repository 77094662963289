// Libararies
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import TripForm from '@shared/modules/Dispatch/forms/TripForm';
import useCreateTripMutation from '@shared/modules/Dispatch/hooks/useCreateTripMutation';
import CreateTripSuccessModal from 'modules/Dispatch/LongDistance/components/CreateTripSuccessModal';
import TripModalContent from 'modules/Dispatch/LongDistance/components/TripModalContent';

const DuplicateTripModalContent = ({handleClose, trip}: any) => {
  const createTripSuccessModal = useModal({name: 'Create Trip Success Modal'});
  const tripForm = TripForm.duplicate(trip);
  const field = 'tripForm';
  const {form, handleSubmit, submitting} = useCreateTripMutation({
    tripForm,
    onSuccess: ({trip}: any) => {
      form.setFieldValue(`${field}.tripUuid`, trip.uuid);
      createTripSuccessModal.handleOpen();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{`Duplicate ${trip.name}`}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <TripModalContent form={form} field={field} organization={trip.organization} />
      </Modal.Body>
      <Modal.Footer>
        <Modal.Button onPress={handleClose} isDisabled={submitting}>
          {'Cancel'}
        </Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={handleSubmit}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Save'}
        </Modal.Button>
      </Modal.Footer>
      <CreateTripSuccessModal
        form={form}
        field={field}
        isOpen={createTripSuccessModal.isOpen}
        handleClose={handleClose}
      />
    </React.Fragment>
  );
};

const DuplicateTripModal = ({isOpen, handleClose, trip}: any) => {
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <DuplicateTripModalContent handleClose={handleClose} trip={trip} />
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DuplicateTripModal.fragment = gql`
  ${TripModalContent.fragment}
  ${TripForm.duplicate.fragment}

  fragment DuplicateTripModal on Trip {
    id
    name
    organization {
      id
      ...TripModalContent_Organization
    }
    ...TripForm_duplicate
  }
`;

export default DuplicateTripModal;
