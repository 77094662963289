// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import InventoryNotFound from 'modules/Customer/Project/Inventory/InventoryNotFound';
import InventoryDocument from 'modules/Customer/Project/Quote/components/InventoryDocument';
import PrintDocumentPage from 'modules/Document/Print/components/PrintDocumentPage';

const CustomerProjectInventoryPrint = () => {
  const {
    params: {uuid},
  } = useNavigationDOM();

  return (
    <PrintDocumentPage query={CustomerProjectInventoryPrint.query} variables={{uuid}}>
      {({data}: any) =>
        data.project ? <InventoryDocument project={data.project} /> : <InventoryNotFound />
      }
    </PrintDocumentPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerProjectInventoryPrint.query = gql`
  ${InventoryDocument.fragment}

  query CustomerProjectInventoryPrint($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      ...InventoryDocument
    }
  }
`;

export default CustomerProjectInventoryPrint;
