// Libraries
import React from 'react';

// Supermove
import {Icon, Link, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

import TextTooltip from '@shared/design/components/TextTooltip';

const NotificationDot = Styled.View`
  position: ${({
    // @ts-expect-error TS(2339): Property 'isExpanded' does not exist on type 'Them... Remove this comment to see the full error message
    isExpanded,
  }) => (isExpanded ? 'static' : 'absolute')};
  top: 4px;
  right: 4px;
  width: 8px;
  height: 8px;
  background-color: ${colors.red.warning};
  border-radius: 4px;
`;

const View = Styled.View``;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ItemText = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isSelected || vars.isHovered ? Typography.Label : Typography.Body)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const NotificationText = Styled.Text`
  ${Typography.MicroLabel}
  color: ${colors.red.warning};
`;

const SidebarItemContainer = Styled.View<{isSelected: boolean; isHovered: boolean}>`
  width: 100%;
  padding: 12px;
  align-items: center;
  justify-content: flex-start;
  border-width: 1px;
  border-color: ${({isSelected}) => (isSelected ? colors.gray.border : colors.transparent)};
  border-radius: 4px;
  background-color: ${({isHovered, isSelected}) => (isHovered || isSelected ? colors.gray.background : colors.transparent)};
  flex-direction: row;
`;

const getColor = ({isHovered, isSelected}: any) =>
  isSelected ? colors.blue.interactive : isHovered ? colors.gray.primary : colors.gray.tertiary;

const TooltipWrapper = ({name, isExpanded, responsive, children}: any) => {
  if (isExpanded || !responsive.desktop) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <TextTooltip text={name} placement={'right'} isEnabledMobileToast={false}>
      <View>{children}</View>
    </TextTooltip>
  );
};

const LinkWrapper = ({to, isExternal, isNewTab, onPress, handleClose, children}: any) => {
  const target = isNewTab ? '_blank' : '_self';
  const onClick = () => {
    if (!isNewTab) {
      handleClose && handleClose();
    }
    onPress && onPress();
  };

  return (
    <React.Fragment>
      {isExternal ? (
        <a href={to} target={target} onClick={onClick}>
          {children}
        </a>
      ) : (
        <Link to={to} target={target} onClick={onClick}>
          {children}
        </Link>
      )}
    </React.Fragment>
  );
};

const SidebarItemButton = ({
  innerRef,
  isSelected,
  isHovered,
  isExpanded,
  name,
  to,
  isExternal,
  isNewTab,
  onPress,
  handleClose,
  children,
}: any) => {
  const responsive = useResponsive();
  return (
    <TooltipWrapper name={name} isExpanded={isExpanded} responsive={responsive}>
      <LinkWrapper
        to={to}
        isExternal={isExternal}
        isNewTab={isNewTab}
        onPress={onPress}
        handleClose={handleClose}
      >
        <SidebarItemContainer ref={innerRef} isSelected={isSelected} isHovered={isHovered}>
          {children}
        </SidebarItemContainer>
      </LinkWrapper>
    </TooltipWrapper>
  );
};

type OwnGlobalNavigationSidebarItemProps = {
  notificationCount: number;
  name: string;
  isSelected: boolean;
  isExpanded?: boolean;
  source: string | string[];
  to?: string;
  isExternal?: boolean;
  isNewTab?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'GlobalNavigationSidebarItemProps' circ... Remove this comment to see the full error message
type GlobalNavigationSidebarItemProps = OwnGlobalNavigationSidebarItemProps &
  typeof GlobalNavigationSidebarItem.defaultProps;

// @ts-expect-error TS(7022): 'GlobalNavigationSidebarItem' implicitly has type ... Remove this comment to see the full error message
const GlobalNavigationSidebarItem = ({
  notificationCount,
  name,
  isSelected,
  source,
  to,
  isExternal,
  isNewTab,
  onPress,
  isExpanded,
  handleClose,
}: GlobalNavigationSidebarItemProps) => {
  const {isHovered, ref} = useHover();
  const responsive = useResponsive();
  const hasNotifications = notificationCount !== 0;
  const showExpanded = !responsive.desktop || isExpanded;
  return (
    <SidebarItemButton
      innerRef={ref}
      to={to}
      isExternal={isExternal}
      isNewTab={isNewTab}
      onPress={onPress}
      handleClose={handleClose}
      isSelected={isSelected}
      isHovered={isHovered}
      isExpanded={isExpanded}
      name={name}
    >
      {hasNotifications && !showExpanded && <NotificationDot />}
      <Icon
        source={source}
        color={getColor({isHovered, isSelected})}
        size={20}
        style={{height: 20, width: 20}}
      />
      {showExpanded && (
        <Row style={{flex: 1}}>
          <Space width={8} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <ItemText vars={{isSelected, isHovered}} color={getColor({isHovered, isSelected})}>
            {name}
          </ItemText>
          <Space style={{flex: 1}} />
          {hasNotifications && (
            <Row>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <NotificationDot isExpanded={showExpanded} />
              <Space width={4} />
              <NotificationText>
                {notificationCount > 99 ? '99+' : notificationCount}
              </NotificationText>
            </Row>
          )}
        </Row>
      )}
    </SidebarItemButton>
  );
};

GlobalNavigationSidebarItem.defaultProps = {
  to: '',
  isExternal: false,
  isExpanded: true,
  isNewTab: false,
};

export default GlobalNavigationSidebarItem;
