// Libraries
import React from 'react';

// Components
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency, Percent} from '@supermove/utils';

import CreateEmployeeMutation from './CreateEmployeeMutation';
import EmployeeFormFields from './EmployeeFormFields';

type OwnProps = {
  organization: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateEmployeeForm.defaultProps;

// @ts-expect-error TS(7022): 'CreateEmployeeForm' implicitly has type 'any' bec... Remove this comment to see the full error message
const CreateEmployeeForm = ({organization, onSuccess, onError}: Props) => (
  <Form
    initialValues={{
      organizationId: organization.id,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      payrollIdentifier: '',
      tabletPin: '',
      position: '',
      branchCode: '',
      billingHourlyRate: Currency.toForm(0),
      compensationPercentage: Percent.toForm(0),
      isDriver: false,
      features: {VIEW_CUSTOMER_INFO: false},
      moverPositionIds: [],
      primaryMoverPositionId: null,
    }}
    onSubmit={(values: any) => {}}
  >
    {({submitForm, setMutationErrors, ...form}: any) => (
      <CreateEmployeeMutation
        {...form}
        onSuccess={onSuccess}
        submitForm={submitForm}
        setMutationErrors={setMutationErrors}
      >
        {({loading, handleSubmit}: any) => (
          <EmployeeFormFields
            form={form}
            loading={loading}
            organization={organization}
            handleSubmit={handleSubmit}
          />
        )}
      </CreateEmployeeMutation>
    )}
  </Form>
);

CreateEmployeeForm.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmployeeForm.fragment = gql`
  ${EmployeeFormFields.fragment}

  fragment CreateEmployeeForm on Organization {
    id
    ...EmployeeFormFields
  }
`;

export default CreateEmployeeForm;
