// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useNavigationDOM,
  usePopover,
  useQuery,
  useState,
  useToast,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime, pluralize, titleize} from '@supermove/utils';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import InventoryLibraryKind from '@shared/modules/Inventory/enums/InventoryLibraryKind';
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';
import useSetDefaultOrganizationInventoryLibraryMutation from '@shared/modules/Inventory/hooks/useSetDefaultOrganizationInventoryLibraryMutation';
import CreateInventoryLibraryModal from 'modules/Organization/Settings/Inventory/components/CreateInventoryLibraryModal';
import DeleteDefaultInventoryLibraryModal from 'modules/Organization/Settings/Inventory/components/DeleteDefaultInventoryLibraryModal';
import DeleteInventoryLibraryModal from 'modules/Organization/Settings/Inventory/components/DeleteInventoryLibraryModal';
import InventorySettingsPage from 'modules/Organization/Settings/Inventory/components/InventorySettingsPage';
import UpdateInventoryLibraryModal from 'modules/Organization/Settings/Inventory/components/UpdateInventoryLibraryModal';

const Container = Styled.View`
`;

const InventoryContainer = Styled.View`
  flex-direction: column;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
`;

const TableContainer = Styled.View`
  width: 884px;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const SectionTitle = Styled.Text`
  ${Typography.PageHeading}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const DefaultBadge = Styled.View`
  padding: 2px 8px;
  border-radius: 8px;
  background-color: ${colors.gray.border};
  width: fit-content;
`;

const MicroText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const EditInventoryLibraryButton = Styled.ButtonV2`
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getIsDefault = ({organization, inventoryLibrary, inventoryLibraryKind}: any) => {
  const getDefaultInventoryLibraryId = () => {
    switch (inventoryLibraryKind) {
      case InventoryLibraryKind.DRIVER_INVENTORY:
        return _.toString(organization.defaultDriverInventoryLibraryId);
      case InventoryLibraryKind.SURVEY:
      default:
        return _.toString(organization.defaultInventoryLibraryId);
    }
  };

  return getDefaultInventoryLibraryId() === _.toString(inventoryLibrary.id);
};

const getLibraryName = (inventoryLibraryKind: any) => {
  switch (inventoryLibraryKind) {
    case InventoryLibraryKind.DRIVER_INVENTORY:
      return 'driver inventory';
    case InventoryLibraryKind.SURVEY:
      return 'survey inventory';
    default:
      return 'inventory';
  }
};

const getInventoryLibraries = ({organization, inventoryLibraryKind}: any) => {
  switch (inventoryLibraryKind) {
    case InventoryLibraryKind.DRIVER_INVENTORY:
      return organization.driverInventoryLibraries;
    case InventoryLibraryKind.SURVEY:
      return organization.surveyInventoryLibraries;
    default:
      return null;
  }
};

const getInventoryLibraryColumnDefinitions = ({
  organization,
  inventoryLibraryKind,
  navigator,
  refetch,
}: any) => {
  return [
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderText>Name</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'inventoryLibrary' implicitly has ... Remove this comment to see the full error message
      cellContent: ({item: inventoryLibrary}) => {
        return (
          <Row style={{flex: 1}}>
            <LinkButton
              // this forces the trunction but doesn't fill the space like flex: 1 would
              // flex would add space before the default badge
              style={{display: 'contents'}}
              onPress={() =>
                navigator.push(`/settings/inventory/libraries/${inventoryLibrary.uuid}`)
              }
            >
              <LinkText numberOfLines={1}>{inventoryLibrary.name}</LinkText>
            </LinkButton>
            <Space width={12} />
            {getIsDefault({organization, inventoryLibrary, inventoryLibraryKind}) && (
              <DefaultBadge>
                <MicroText>Default</MicroText>
              </DefaultBadge>
            )}
          </Row>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return (
          <Row style={{alignItems: 'center'}}>
            <TableHeaderText>Project Types</TableHeaderText>
            <Space width={8} />
            <TextTooltip
              isEnabledMobileToast={false}
              placement={'top'}
              style={{textAlign: 'center'}}
              text={`Hover to show full list of project types`}
            >
              <IconContainer>
                <Icon
                  color={colors.gray.tertiary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          </Row>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'inventoryLibrary' implicitly has ... Remove this comment to see the full error message
      cellContent: ({item: inventoryLibrary}) => {
        const projectTypeNames = _.join(
          inventoryLibrary.projectTypes.map((projectType: any) => projectType.name).sort(),
          ', ',
        );
        return _.isEmpty(inventoryLibrary.projectTypes) ? (
          <Text>None</Text>
        ) : (
          <TextTooltip
            isEnabledMobileToast={false}
            placement={'top'}
            style={{textAlign: 'center', maxWidth: '300px'}}
            text={projectTypeNames}
          >
            <Text>{pluralize('project type', inventoryLibrary.projectTypes.length, true)}</Text>
          </TextTooltip>
        );
      },
    },
    {
      flex: 2,
      headerContent: () => {
        return <TableHeaderText>Last Updated</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'inventoryLibrary' implicitly has ... Remove this comment to see the full error message
      cellContent: ({item: inventoryLibrary}) => {
        return (
          <Column>
            <Text>{Datetime.convertToDisplayDatetime(inventoryLibrary.updatedAt)}</Text>
            {inventoryLibrary.updatedBy ? (
              <MicroText>By {inventoryLibrary.updatedBy.fullName}</MicroText>
            ) : null}
          </Column>
        );
      },
    },
    {
      width: 80,
      headerContent: () => {
        return null;
      },
      // @ts-expect-error TS(7031): Binding element 'inventoryLibrary' implicitly has ... Remove this comment to see the full error message
      cellContent: ({item: inventoryLibrary}) => {
        return (
          <InventoryLibraryActions
            inventoryLibrary={inventoryLibrary}
            refetch={refetch}
            inventoryLibraryKind={inventoryLibraryKind}
            isDefaultInventoryLibrary={getIsDefault({
              organization,
              inventoryLibrary,
              inventoryLibraryKind,
            })}
          />
        );
      },
    },
  ];
};

const InventoryLibraryActions = ({inventoryLibrary, refetch, isDefaultInventoryLibrary}: any) => {
  const updateInventoryLibraryModal = useModal({name: 'Update Inventory Library Modal'});
  const deleteDefaultInventoryLibraryModal = useModal({
    name: 'Delete Default Inventory Library Modal',
  });
  const deleteInventoryLibraryModal = useModal({name: 'Delete Inventory Library Modal'});
  const inventoryLibraryActionsPopover = usePopover();
  const errorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error setting default library',
  });
  const {handleSubmit} = useSetDefaultOrganizationInventoryLibraryMutation({
    inventoryLibraryForm: InventoryLibraryForm.edit(inventoryLibrary),
    onSuccess: refetch,
    onError: () => errorToast.handleToast(),
  });

  return (
    <React.Fragment>
      <Row>
        <EditInventoryLibraryButton onPress={updateInventoryLibraryModal.handleOpen}>
          <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
        </EditInventoryLibraryButton>
        <Space width={12} />
        <Popover.Content innerRef={inventoryLibraryActionsPopover.ref}>
          <MoreActionsButton onPress={inventoryLibraryActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      </Row>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={inventoryLibraryActionsPopover.isOpen}
        handleOpen={inventoryLibraryActionsPopover.handleOpen}
        handleClose={inventoryLibraryActionsPopover.handleClose}
        reference={inventoryLibraryActionsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          handleClose={inventoryLibraryActionsPopover.handleClose}
          actions={
            isDefaultInventoryLibrary
              ? [
                  {
                    text: 'Remove',
                    onPress: deleteDefaultInventoryLibraryModal.handleOpen,
                  },
                ]
              : [
                  {
                    text: 'Set as default',
                    onPress: handleSubmit,
                  },
                  {
                    text: 'Remove',
                    onPress: deleteInventoryLibraryModal.handleOpen,
                  },
                ]
          }
        />
      </Popover>
      <UpdateInventoryLibraryModal
        updateInventoryLibraryModal={updateInventoryLibraryModal}
        inventoryLibrary={inventoryLibrary}
        refetch={refetch}
      />
      <DeleteDefaultInventoryLibraryModal
        deleteDefaultInventoryLibraryModal={deleteDefaultInventoryLibraryModal}
        inventoryLibraryUuid={inventoryLibrary.uuid}
        refetch={refetch}
      />
      <DeleteInventoryLibraryModal
        deleteInventoryLibraryModal={deleteInventoryLibraryModal}
        inventoryLibraryUuid={inventoryLibrary.uuid}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const InventoryKindLibrariesContent = ({
  organization,
  inventoryLibraryKind,
  createInventoryLibraryModal,
  refetch,
}: any) => {
  const {navigator} = useNavigationDOM();
  const libraryName = getLibraryName(inventoryLibraryKind);
  const columnDefinitions = getInventoryLibraryColumnDefinitions({
    organization,
    inventoryLibraryKind,
    navigator,
    refetch,
  });
  const inventoryLibraries = getInventoryLibraries({
    organization,
    inventoryLibraryKind: inventoryLibraryKind || InventoryLibraryKind.SURVEY,
  });
  return (
    <Container>
      <SectionTitle>{`${titleize(libraryName)} Libraries`}</SectionTitle>
      <Space height={8} />
      <DescriptionText>{`Create and manage your rooms, categories, cartons and items in each ${InventoryLibraryKind.getDisplayKind(
        inventoryLibraryKind,
      )} library.`}</DescriptionText>
      <Space height={24} />
      <Button
        text={`Create ${titleize(libraryName)} Library`}
        iconLeft={Icon.Plus}
        onPress={createInventoryLibraryModal.handleOpen}
      />
      <Space height={24} />
      <TableContainer>
        <Table
          columnDefinitions={columnDefinitions}
          emptyStateText={`No ${libraryName} libraries to display`}
          items={inventoryLibraries}
          itemKey={'id'}
          isDense
        />
      </TableContainer>
      <CreateInventoryLibraryModal
        createInventoryLibraryModal={createInventoryLibraryModal}
        organizationId={organization.id}
        inventoryLibraryKind={inventoryLibraryKind}
      />
    </Container>
  );
};

const InventoryLibrariesContent = ({organization, refetch}: any) => {
  const createSurveyInventoryLibraryModal = useModal({
    name: 'Create Survey Inventory Library Modal',
  });
  const createDriverInventoryLibraryModal = useModal({
    name: 'Create Driver Inventory Library Modal',
  });
  return (
    <ScrollView horizontal>
      <InventoryContainer>
        {organization.settings.isCrewInventoryEnabled ? (
          <>
            <InventoryKindLibrariesContent
              organization={organization}
              inventoryLibraryKind={InventoryLibraryKind.SURVEY}
              createInventoryLibraryModal={createSurveyInventoryLibraryModal}
              refetch={refetch}
            />
            <Space height={40} />
            <InventoryKindLibrariesContent
              organization={organization}
              inventoryLibraryKind={InventoryLibraryKind.DRIVER_INVENTORY}
              createInventoryLibraryModal={createDriverInventoryLibraryModal}
              refetch={refetch}
            />
          </>
        ) : (
          <InventoryKindLibrariesContent
            organization={organization}
            createInventoryLibraryModal={createSurveyInventoryLibraryModal}
            refetch={refetch}
          />
        )}
        <Space height={72} />
      </InventoryContainer>
    </ScrollView>
  );
};

const OrganizationSettingsInventoryLibrariesSettings = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {loading, data, refetch} = useQuery(OrganizationSettingsInventoryLibrariesSettings.query, {
    fetchPolicy: 'cache-and-network',
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <InventorySettingsPage>
      <Loading loading={loading}>
        {() => (
          <InventoryLibrariesContent
            key={refreshKey}
            organization={data.viewer.viewingOrganization}
            refetch={refetchWithKeyUpdate}
          />
        )}
      </Loading>
    </InventorySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsInventoryLibrariesSettings.query = gql`
  ${InventoryLibraryForm.edit.fragment}
  ${UpdateInventoryLibraryModal.fragment}
  query OrganizationSettingsInventoryLibrariesSettings {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        defaultDriverInventoryLibraryId
        defaultInventoryLibraryId
        driverInventoryLibraries {
          id
          name
          uuid
          projectTypes {
            id
            name
          }
          updatedAt
          updatedBy {
            id
            fullName
          }
          ...InventoryLibraryForm_edit
          ...UpdateInventoryLibraryModal
        }
        surveyInventoryLibraries {
          id
          name
          uuid
          projectTypes {
            id
            name
          }
          updatedAt
          updatedBy {
            id
            fullName
          }
          ...InventoryLibraryForm_edit
          ...UpdateInventoryLibraryModal
        }
        settings {
          id
          isCrewInventoryEnabled
        }
      }
    }
  }
`;

export default OrganizationSettingsInventoryLibrariesSettings;
