// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {useToast, Form, useForm} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {Currency} from '@supermove/utils';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import {
  ItemFormV2Type,
  ItemFormV2PartialToFormType,
} from '@shared/modules/Inventory/forms/ItemFormV2';
import EditInventoryItemInputFields from 'modules/Inventory/Edit/components/EditInventoryItemInputFields';

const validateForm = ({form}: {form: Form<ItemFormV2PartialToFormType>}) => {
  if (form.values.name === '') {
    form.setErrors({name: 'Please enter a name'});
    return true;
  }
  return false;
};

type HandleSubmitPropTypes = {updatedValues: ItemFormV2Type; field: string; index?: number};

const handleSave = ({
  form,
  handleSubmit,
  inventoryRoomsForm,
  roomItemsFormIndex,
}: {
  form: Form<ItemFormV2PartialToFormType>;
  handleSubmit: ({updatedValues, field, index}: HandleSubmitPropTypes) => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
}) => {
  const errors = validateForm({form});
  if (errors) return;

  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms`;
  const {price, take, takeCount, leaveCount, index, volume, weight, packTime, unpackTime} =
    form.values;
  const quantity = take ? takeCount : leaveCount;
  const updatedValues = {
    ...form.values,
    isDirty: true,
    price: Currency.toMutation(price),
    isDeleted: quantity === 0,
    // Formatting with Inventory.getFloatValue removes trailing decimals
    volume: Inventory.getFloatValue(volume),
    weight: Inventory.getFloatValue(weight),
    packTime: Inventory.getFloatValue(packTime),
    unpackTime: Inventory.getFloatValue(unpackTime),
  };

  InventoryRoomsForm.setDirtyForms({
    inventoryRoomsForm,
    roomItemsFormIndex,
    itemIndex: index,
  });

  handleSubmit({updatedValues, field, index});
};

const EditInventoryItemDrawer = ({
  isOpen,
  handleClose,
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  defaultDensityFactor,
  isEnabledSurveysTimeAdditives,
}: {
  isOpen: boolean;
  handleClose: () => void;
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  itemForm: ItemFormV2Type;
  defaultDensityFactor: number;
  isEnabledSurveysTimeAdditives: boolean;
}) => {
  const itemUpdatedToast = useToast({
    ToastComponent: SuccessToast,
    message: `Item updated!`,
  });

  // We clone the form and make edits there so that all the changes are made at once
  const clonedItemForm = _.cloneDeep(itemForm);

  // ItemFormV2 does not toForm currency values, so we need to do that here
  const form = useForm({
    initialValues: {...clonedItemForm, price: Currency.toForm(clonedItemForm.price)},
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      width={DrawerWithAction.WIDTH.MEDIUM}
      headerText={`Edit ${clonedItemForm.name}`}
      handleSubmit={() =>
        handleSave({
          form,
          handleSubmit: ({updatedValues, field, index}: HandleSubmitPropTypes) => {
            inventoryRoomsForm.setFieldValue(`${field}.${index}`, updatedValues);

            itemUpdatedToast.handleToast({message: `${updatedValues.name} updated!`});
            handleClose();
          },
          inventoryRoomsForm,
          roomItemsFormIndex,
        })
      }
      primaryActionText={'Save'}
      isClosable
    >
      <EditInventoryItemInputFields
        form={form}
        defaultDensityFactor={defaultDensityFactor}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
      />
    </DrawerWithAction>
  );
};

export default EditInventoryItemDrawer;
