// Libararies
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import TripStatusForm from '@shared/modules/Dispatch/forms/TripStatusForm';
import useUpdateTripStatusMutation from '@shared/modules/Dispatch/hooks/useUpdateTripStatusMutation';

const CancelTripModal = ({tripId, isOpen, handleClose, refetch}: any) => {
  const tripStatusForm = TripStatusForm.new({tripId, status: TripStatus.CANCELLED});
  const {handleSubmit, submitting} = useUpdateTripStatusMutation({
    tripStatusForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <ActionModal
      title={`Cancel this trip?`}
      icon={Icon.Ban}
      message={`This will remove all shipments from this trip. Would you like to continue?`}
      isOpen={isOpen}
      // @ts-expect-error TS(2322): Type '{ title: string; icon: "ban"; message: strin... Remove this comment to see the full error message
      handleClose={handleClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={handleClose}
      primaryActionText={'Yes, cancel this trip'}
      secondaryActionText={'No, go back'}
      isSubmitting={submitting}
      color={colors.red.warning}
    />
  );
};

export default CancelTripModal;
