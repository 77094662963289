// Libraries
import React from 'react';

// App
import {Form} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {TimeRange} from '@supermove/models';

// Components
import UpdateReportMoveTimeRangesMutation from './UpdateReportMoveTimeRangesMutation';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateReportMoveTimeRangesForm.defaultProps;

// @ts-expect-error TS(7022): 'UpdateReportMoveTimeRangesForm' implicitly has ty... Remove this comment to see the full error message
const UpdateReportMoveTimeRangesForm = ({reportMove, onSuccess, onError, children}: Props) => (
  <Form
    initialValues={{
      reportMoveId: reportMove.id,
      timeRangeInputs: reportMove.timeRanges.map((timeRange: any) => TimeRange.toForm(timeRange)),
    }}
    onSubmit={(values: any) => {}}
  >
    {({submitForm, setMutationErrors, ...form}: any) => (
      <UpdateReportMoveTimeRangesMutation
        reportMoveId={reportMove.id}
        timeRangeInputs={form.values.timeRangeInputs.map((timeRangeInput: any) =>
          TimeRange.toMutation(timeRangeInput),
        )}
        onSuccess={onSuccess}
        setMutationErrors={setMutationErrors}
        submitForm={submitForm}
      >
        {({loading, handleSubmit}: any) =>
          children({
            ...form,
            loading,
            handleSubmit,
          })
        }
      </UpdateReportMoveTimeRangesMutation>
    )}
  </Form>
);

UpdateReportMoveTimeRangesForm.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveTimeRangesForm.fragment = gql`
  fragment UpdateReportMoveTimeRangesForm on ReportMove {
    id
    uuid
    timeRanges {
      name
      kind
      start
      end
    }
  }
`;

export default UpdateReportMoveTimeRangesForm;
