/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors, fontWeight} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  padding-top: 40px;
  padding-horizontal: 50px;
`;

const Title = Styled.H2`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const NotFoundPage = ({title, subtitle}: any) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export default NotFoundPage;
