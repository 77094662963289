// Libraries
import React from 'react';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteAttachmentForm from '@shared/modules/File/forms/DeleteAttachmentForm';
import useDeleteAttachmentForm from '@shared/modules/File/hooks/useDeleteAttachmentForm';

type OwnProps = {
  attachmentId: number;
  handleClose: (...args: any[]) => any;
  isOpen: boolean;
  onSuccess: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeleteAttachmentModal.defaultProps;

// @ts-expect-error TS(7022): 'DeleteAttachmentModal' implicitly has type 'any' ... Remove this comment to see the full error message
const DeleteAttachmentModal = ({isOpen, attachmentId, handleClose, onSuccess}: Props) => {
  const {submitting, handleSubmit} = useDeleteAttachmentForm({
    deleteAttachmentForm: DeleteAttachmentForm.new({
      attachmentId,
    }),
    onSuccess: () => {
      onSuccess();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to delete this attachment?'}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

DeleteAttachmentModal.defaultProps = {};

export default DeleteAttachmentModal;
