// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Circle = Styled.View`
  width: 15px;
  height: 15px;
  border-radius: 8px;
  background-color: ${(props) => (props as any).color};
`;

const Info = Styled.View`
  flex: 1;
  margin-top: 1px;
  margin-left: 10px;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Timestamp = Styled.H7`
  color: ${colors.gray.primary};
`;

const JobEventItem = ({jobEvent}: any) => (
  <Container>
    <Row>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Circle color={colors.green.status} />
      <Info>
        <Name>{jobEvent.name}</Name>
        <Timestamp>
          {Datetime.convertToDisplayDatetime(jobEvent.timestamp, 'ddd, MMM D, h:mm A')}
        </Timestamp>
      </Info>
    </Row>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
JobEventItem.fragment = gql`
  fragment JobEventItem on JobEvent {
    id
    name
    timestamp
  }
`;

export default JobEventItem;
