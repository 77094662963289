// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import CreateMoverPositionForm from '@shared/modules/User/forms/CreateMoverPositionForm';
import useCreateMoverPositionMutation from '@shared/modules/User/hooks/useCreateMoverPositionMutation';
import MoverPositionModalFields from 'modules/Organization/Settings/Users/List/components/MoverPositionModalFields';

const CreateMoverPositionModal = ({handleClose, isOpen, refetch, organizationId}: any) => {
  const createMoverPositionForm = CreateMoverPositionForm.new({organizationId});

  const {form, submitting, handleSubmit} = useCreateMoverPositionMutation({
    createMoverPositionForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.HeaderText>{'Create Position'}</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>{'Fill in the details to create this position.'}</SmallModal.Text>
      <Space height={16} />
      <MoverPositionModalFields form={form} field={'createMoverPositionForm'} />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CreateMoverPositionModal;
