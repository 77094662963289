// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DragAndDropList, Icon, Space, Styled} from '@supermove/components';
import {useDragAndDrop, useModal, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {List} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ToggleBadge from 'modules/App/components/ToggleBadge';
import DeleteProjectLocationModal from 'modules/Project/V2/Show/components/DeleteProjectLocationModal';

const handleReorder = ({form, field, fromIndex, toIndex, locationForms}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const reorderedLocationForms = List.move({list: locationForms, fromIndex, toIndex});
  setFieldValue(`${field}.locationForms`, reorderedLocationForms);
};

const Row = Styled.View`
  flex-direction: row;
`;

const ResponsiveContainer = Styled.View`
  flex: 1;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'row' : 'column')};
  align-items: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'center' : 'flex-start')};
`;

const ItemContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
  padding-horizontal: 16px;
  border-width: 1px;
  border-radius: 8px;
  border-color: ${colors.gray.border}
`;

const ItemText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const ArrowsColumn = Styled.View`
  justify-content: center;
`;

const StartEndBadge = ({isStart, isEnd, isStops}: any) => {
  return (
    <React.Fragment>
      {isStart && (
        <ToggleBadge label={isStops ? 'Pick-up' : 'Origin'} color={ToggleBadge.COLORS.BLUE} isOn />
      )}
      {isStart && isEnd && <Space width={8} />}
      {isEnd && (
        <ToggleBadge
          label={isStops ? 'Drop-off' : 'Destination'}
          color={ToggleBadge.COLORS.GREEN}
          isOn
        />
      )}
    </React.Fragment>
  );
};

const LocationReorderItem = ({
  form,
  field,
  locationForm,
  handleRemoveLocation,
  projectId,
  isStops,
  responsive,
}: any) => {
  const deleteLocationModal = useModal({name: 'Delete Project Location Modal'});
  const isNew = !locationForm.locationId;

  const isStart = isStops
    ? locationForm.kind === LocationKind.PICK_UP
    : _.get(form.values, `${field}.originLocationId`) === locationForm.locationId;
  const isEnd = isStops
    ? locationForm.kind === LocationKind.DROP_OFF
    : _.get(form.values, `${field}.destinationLocationId`) === locationForm.locationId;

  return (
    <ItemContainer>
      <ResponsiveContainer responsive={responsive}>
        <ItemText responsive={responsive}>
          {LocationForm.getDisplayAddressCityStateZip(locationForm)}
        </ItemText>
        {(isStart || isEnd) && (
          <React.Fragment>
            <Space width={8} height={8} />
            <Row>
              <StartEndBadge isStart={isStart} isEnd={isEnd} isStops={isStops} />
            </Row>
          </React.Fragment>
        )}
      </ResponsiveContainer>
      <Space width={12} />
      <TertiaryButton
        onPress={() =>
          isNew || isStops ? handleRemoveLocation() : deleteLocationModal.handleOpen()
        }
      >
        <Icon source={Icon.Trash} size={16} color={colors.red.warning} />
      </TertiaryButton>
      {!isNew && (
        <DeleteProjectLocationModal
          projectId={projectId}
          locationId={locationForm.locationId}
          isOpen={deleteLocationModal.isOpen}
          handleClose={deleteLocationModal.handleClose}
          handleDelete={() => {
            deleteLocationModal.handleClose();
            handleRemoveLocation();
          }}
        />
      )}
    </ItemContainer>
  );
};

const LocationsDragAndDropList = ({
  form,
  field,
  handleRemoveLocation,
  projectId,
  locationForms,
  isStops,
  responsive,
}: any) => {
  const {isReordering, handleReorderStart, handleReorderEnd} = useDragAndDrop();

  return (
    <DragAndDropList
      spaceBetweenItems={8}
      isReordering={isReordering}
      onReorder={({fromIndex, toIndex}) => {
        handleReorderStart();
        handleReorder({
          form,
          field,
          fromIndex,
          toIndex,
          locationForms,
        });
        handleReorderEnd();
      }}
    >
      {locationForms.map((locationForm: any, index: any) => {
        return (
          <LocationReorderItem
            key={index}
            form={form}
            field={field}
            locationForm={locationForm}
            handleRemoveLocation={() => handleRemoveLocation({form, field, index})}
            projectId={projectId}
            isStops={isStops}
            responsive={responsive}
          />
        );
      })}
    </DragAndDropList>
  );
};

const ArrowButtons = ({form, field, index, locationForms}: any) => {
  const isFirst = index === 0;
  const isLast = index === locationForms.length - 1;
  return (
    <ArrowsColumn>
      <TertiaryButton
        style={{height: 32, width: 32}}
        onPress={() =>
          handleReorder({form, field, fromIndex: index, toIndex: index - 1, locationForms})
        }
        isDisabled={isFirst}
      >
        <Icon
          source={Icon.ArrowUp}
          color={isFirst ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
      <Space height={8} />
      <TertiaryButton
        style={{height: 32, width: 32}}
        onPress={() =>
          handleReorder({form, field, fromIndex: index, toIndex: index + 1, locationForms})
        }
        isDisabled={isLast}
      >
        <Icon
          source={Icon.ArrowDown}
          color={isLast ? colors.gray.disabled : colors.blue.interactive}
          size={16}
        />
      </TertiaryButton>
    </ArrowsColumn>
  );
};

const LocationsArrowButtonsList = ({
  form,
  field,
  handleRemoveLocation,
  projectId,
  locationForms,
  isStops,
  responsive,
}: any) => {
  return (
    <React.Fragment>
      {locationForms.map((locationForm: any, index: any) => {
        return (
          <React.Fragment key={index}>
            {index > 0 && <Space height={16} />}
            <Row style={{minHeight: 72}}>
              <ArrowButtons form={form} field={field} index={index} locationForms={locationForms} />
              <Space width={8} />
              <LocationReorderItem
                form={form}
                field={field}
                locationForm={locationForm}
                handleRemoveLocation={() => handleRemoveLocation({form, field, index})}
                projectId={projectId}
                isStops={isStops}
                responsive={responsive}
              />
            </Row>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const EditLocationsListReorderList = ({form, field, handleRemoveLocation, isStops}: any) => {
  const responsive = useResponsive();
  const projectId = _.get(form.values, `${field}.projectId`);
  const locationForms = _.get(form.values, `${field}.locationForms`);

  if (responsive.desktop) {
    return (
      <LocationsDragAndDropList
        form={form}
        field={field}
        handleRemoveLocation={handleRemoveLocation}
        projectId={projectId}
        locationForms={locationForms}
        isStops={isStops}
        responsive={responsive}
      />
    );
  }
  return (
    <LocationsArrowButtonsList
      form={form}
      field={field}
      handleRemoveLocation={handleRemoveLocation}
      projectId={projectId}
      locationForms={locationForms}
      isStops={isStops}
      responsive={responsive}
    />
  );
};

export default EditLocationsListReorderList;
