// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {useHover, usePopover} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import LocationKind from '@shared/modules/Location/enums/LocationKind';

const KindOptionButton = Styled.Touchable`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  margin-vertical: 1px;
`;

const KindOptionText = Styled.Text`
  ${Typography.Body4}
  padding-vertical: 4px;
  padding-horizontal: 8px;
  color: ${colors.gray.primary};
`;

const KindOption = ({kind, onSelect}: any) => {
  const hover = useHover();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <KindOptionButton ref={hover.ref} isHovered={hover.isHovered} onPress={() => onSelect(kind)}>
      <KindOptionText>{LocationKind.display(kind)}</KindOptionText>
    </KindOptionButton>
  );
};

const KindPopoverContainer = Styled.View`
  width: 100px;
  border-radius: 4px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const LocationKindPopover = ({popover, form, field}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <KindPopoverContainer>
        <Space height={7} />
        {LocationKind.VALUES.map((kind, index) => {
          return (
            <KindOption
              key={index}
              kind={kind}
              onSelect={(kind: any) => {
                form.setFieldValue(field, kind);
                popover.handleClose();
              }}
            />
          );
        })}
        <Space height={7} />
      </KindPopoverContainer>
    </Popover>
  );
};

const KindButton = Styled.Touchable`
  flex-direction: row;
  padding-vertical: 4px;
  padding-horizontal: 10px;
  background-color: ${colors.gray.background};
  border-radius: 20px;
`;

const KindText = Styled.Text`
  ${Typography.Label3};
  color: ${colors.gray.primary};
`;

const LocationKindDropdown = ({form, field}: any) => {
  const popover = usePopover();
  const kind = _.get(form.values, field);

  return (
    <React.Fragment>
      <Popover.Content innerRef={popover.ref}>
        <KindButton onPress={popover.handleToggle}>
          <KindText>{LocationKind.display(kind)}</KindText>
          <Space width={4} />
          <Icon source={Icon.CaretDown} size={13} color={colors.gray.primary} />
        </KindButton>
      </Popover.Content>
      <LocationKindPopover key={popover.isOpen} popover={popover} form={form} field={field} />
    </React.Fragment>
  );
};

export default LocationKindDropdown;
