// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useToast,
  useHandleMutationAlertErrors,
  useDrawer,
  usePopover,
  useModal,
  useNavigationDOM,
} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SwitchField from '@shared/design/components/Field/SwitchField';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ToggleWorkflowIsActiveForm from '@shared/modules/Workflow/ToggleWorkflowIsActiveForm';
import useToggleWorkflowIsActiveMutation from '@shared/modules/Workflow/hooks/useToggleWorkflowIsActiveMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import DeleteWorkflowModal from 'modules/Organization/Settings/Workflow/components/DeleteWorkflowModal';
import DuplicateWorkflowModal from 'modules/Organization/Settings/Workflow/components/DuplicateWorkflowModal';
import UpdateWorkflowDrawer from 'modules/Organization/Settings/Workflow/components/UpdateWorkflowDrawer';

const Container = Styled.View`
  flex: 1;
`;

const IconButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const DescriptionText = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.secondary};
`;

const ToggleSwitch = ({workflow}: any) => {
  const toggleWorkflowIsActiveSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Automation Status Updated',
  });

  // @ts-expect-error TS(2322): Type '{ ({ message, actionText, handleAction, hand... Remove this comment to see the full error message
  const alertHandler = useHandleMutationAlertErrors({ToastComponent: ErrorToast});

  const toggleWorkflowIsActiveForm = ToggleWorkflowIsActiveForm.new({
    workflowId: workflow.id,
    isActive: workflow.isActive,
  });

  const {form, handleSubmit} = useToggleWorkflowIsActiveMutation({
    toggleWorkflowIsActiveForm,
    onSuccess: () => {
      toggleWorkflowIsActiveSuccessToast.handleToast();
    },
    onError: (errors: any) => {
      form.setFieldValue(
        'toggleWorkflowIsActiveForm.isActive',
        toggleWorkflowIsActiveForm.isActive,
      );
      console.log({errors});
      alertHandler.handleMutationAlertErrors({errors});
    },
  });
  return (
    <SwitchField
      form={form}
      field={'toggleWorkflowIsActiveForm.isActive'}
      // @ts-expect-error TS(2322): Type '(options?: MutationFunctionOptions<MutationR... Remove this comment to see the full error message
      onChangeValue={handleSubmit}
    />
  );
};

const WorkflowActions = ({workflow, refetch}: any) => {
  const updateWorkflowDrawer = useDrawer({name: 'Update Workflow Drawer'});
  return (
    <React.Fragment>
      <IconButton onPress={updateWorkflowDrawer.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <WorkflowActionsPopover refetch={refetch} workflow={workflow} />
      <UpdateWorkflowDrawer
        key={updateWorkflowDrawer.key}
        isOpen={updateWorkflowDrawer.isOpen}
        handleClose={updateWorkflowDrawer.handleClose}
        workflow={workflow}
        onSuccess={() => {
          updateWorkflowDrawer.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const WorkflowActionsPopover = ({refetch, workflow}: any) => {
  const {navigator} = useNavigationDOM();
  const workflowActionsPopover = usePopover();
  const deleteWorkflowModal = useModal({name: 'Delete Workflow Modal'});
  const duplicateWorkflowModal = useModal({name: 'Duplicate Workflow Modal'});

  return (
    <React.Fragment>
      <Popover.Content innerRef={workflowActionsPopover.ref}>
        <IconButton onPress={workflowActionsPopover.handleOpen}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </IconButton>
      </Popover.Content>
      <ActionMenuPopover
        popover={workflowActionsPopover}
        placement={Popover.Positions.BottomStart}
        offset={[-150, 0]}
        width={200}
      >
        <ActionMenuPopover.MenuItem
          onPress={() => navigator.push(`/settings/automations/${workflow.uuid}/runs`)}
        >
          View Automation Runs
        </ActionMenuPopover.MenuItem>
        <ActionMenuPopover.MenuItem
          onPress={() => {
            deleteWorkflowModal.handleOpen();
            workflowActionsPopover.handleClose();
          }}
        >
          Delete Automation
        </ActionMenuPopover.MenuItem>
        <ActionMenuPopover.MenuItem
          onPress={() => {
            duplicateWorkflowModal.handleOpen();
            workflowActionsPopover.handleClose();
          }}
        >
          Duplicate Automation
        </ActionMenuPopover.MenuItem>
      </ActionMenuPopover>
      <DeleteWorkflowModal
        key={deleteWorkflowModal.key}
        isOpen={deleteWorkflowModal.isOpen}
        workflow={workflow}
        handleClose={deleteWorkflowModal.handleClose}
        onSuccess={() => {
          deleteWorkflowModal.handleClose();
          refetch();
        }}
      />
      <DuplicateWorkflowModal
        key={`Duplicate_${duplicateWorkflowModal.key}`}
        isOpen={duplicateWorkflowModal.isOpen}
        workflow={workflow}
        handleClose={duplicateWorkflowModal.handleClose}
        onSuccess={() => {
          duplicateWorkflowModal.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const getWorkflowsTableColumnDefinitions = ({refetch}: any) => [
  {
    width: 48,
    headerContent: () => {
      return null;
    },
    // @ts-expect-error TS(7031): Binding element 'workflow' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: workflow}) => {
      return <ToggleSwitch workflow={workflow} key={workflow.id} />;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Name</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflow' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: workflow}) => {
      return <Table.CellText numberOfLines={2}>{workflow.name}</Table.CellText>;
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <Table.HeaderText>Description</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflow' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: workflow}) => {
      return (
        <TextTooltip
          isEnabledMobileToast={false}
          text={workflow.description}
          numberOfLines={10}
          style={{maxWidth: 500}}
        >
          <Table.CellText numberOfLines={2}>{workflow.description || '-'}</Table.CellText>
        </TextTooltip>
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <Table.HeaderText>Last Updated</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflow' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: workflow}) => {
      return (
        <Container>
          <Table.CellText>{Datetime.convertToDisplayDatetime(workflow.updatedAt)}</Table.CellText>
          <Space height={4} />
          <DescriptionText>By: {workflow.updatedBy.fullName}</DescriptionText>
        </Container>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <Table.HeaderText>Actions</Table.HeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'workflow' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: workflow}) => {
      return <WorkflowActions workflow={workflow} refetch={refetch} />;
    },
  },
];

const WorkflowsTable = ({workflows, refetch}: any) => {
  const {
    navigator,
    params: {workflowUuid},
  } = useNavigationDOM();
  return (
    <React.Fragment>
      <Container>
        <Table.FixedHeaderScroll
          columnDefinitions={getWorkflowsTableColumnDefinitions({
            refetch,
          })}
          emptyStateText='No automations'
          items={workflows}
          isDense
          onRowPress={(workflow: any) =>
            navigator.replace(`/settings/automations/${workflow.uuid}`)
          }
          isClickable
          activeRowIndex={_.findIndex(
            workflows,
            (workflow) => (workflow as any).uuid === workflowUuid,
          )}
          scrollViewStyle={{paddingBottom: 60}}
        />
        <Space height={32} />
      </Container>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WorkflowsTable.fragment = gql`
  ${UpdateWorkflowDrawer.fragment}
  ${DeleteWorkflowModal.fragment}
  ${DuplicateWorkflowModal.fragment}

  fragment WorkflowsTable on Workflow {
    id
    name
    description
    uuid
    isActive
    kind
    updatedAt
    updatedBy {
      id
      fullName
    }
    owner {
      id
      fullName
    }
    ...UpdateWorkflowDrawer
    ...DeleteWorkflowModal
    ...DuplicateWorkflowModal
  }
`;

export default WorkflowsTable;
