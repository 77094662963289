/**
 * TODO(herrick): Replace this file with ProjectJobAdditionalInfoBlock to consolidate
 * logic once create/update project have been migrated.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// App
import {CheckboxField} from 'modules/App/components';

import JobAdditionalItemsSection from './JobAdditionalItemsSection';

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
`;

const Title = Styled.H4`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const getFieldName = ({field, name}: any) => {
  return field ? `${field}.${name}` : name;
};

const hasFields = ({form, organization}: any) => {
  if (!_.isEmpty(form.values.jobFormAdditionalItems)) {
    return true;
  }
  if (organization.features.isEnabledJobHasPackingField) {
    return true;
  }
  return false;
};

const JobAdditionalFields = ({disabled, index, field, form, responsive, organization}: any) => {
  if (!hasFields({form, organization})) {
    return null;
  }

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section index={index}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Title vars={responsive}>Additional Information</Title>
      <JobAdditionalItemsSection
        disabled={disabled}
        field={field}
        form={form}
        responsive={responsive}
      />
      {organization.features.isEnabledJobHasPackingField && (
        <Row {...responsive} index={1}>
          <CheckboxField
            {...form}
            name={getFieldName({field, name: 'hasPacking'})}
            label={'Requires Packing?'}
            input={{
              disabled,
            }}
            style={{
              marginTop: 5,
              marginBottom: 10,
            }}
          />
        </Row>
      )}
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobAdditionalFields.fragment = gql`
  fragment JobAdditionalFields on Organization {
    id
    features {
      isEnabledJobHasPackingField: isEnabled(feature: "JOB_HAS_PACKING_FIELD")
    }
  }
`;

export default JobAdditionalFields;
