/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useState} from '@supermove/hooks';
import {CostCategory} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';
import CostChangesFoundModal from 'modules/Cost/components/CostChangesFoundModal';

const HeavyLine = Styled.View`
  border-bottom-width: 4px;
  border-color: ${colors.gray.border};
`;

const Container = Styled.View`
`;

const CostSectionButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 16px;
  padding-vertical: 6px;
  margin-vertical: 6px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : 'transparent')}
`;

const CostCategoryText = Styled.Text`
  ${Typography.Label3}
`;

const UserRoleText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.tertiary};
`;

const CompensationUserButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: flex-start;
  padding-horizontal: 16px;
  padding-vertical: 8px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : 'transparent')}
`;

const CompensationUserText = Styled.Text`
  ${Typography.Label4}
`;

const CompensationUserPositionText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const IndicatorLine = Styled.View`
  flex: 1;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 4px;
  border-left-width: 4px;
  border-color: ${colors.gray.border};
`;

const CompensationUser = ({
  hasChange,
  handleSubmit,
  cost,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const costChangesFoundModal = useModal({name: 'Cost Changes Found Modal'});
  const isSelected = cost.id === currentCostId;
  return (
    <CompensationUserButton
      isSelected={isSelected}
      onPress={() => {
        if (hasChange) {
          return costChangesFoundModal.handleOpen();
        }
        return setCurrentCostId(cost.id);
      }}
    >
      <CompensationUserText>{cost.displayName}</CompensationUserText>
      <Space style={{flex: 1}} />
      <CompensationUserPositionText>{cost.moverPosition.name}</CompensationUserPositionText>
      {isSelected && <IndicatorLine />}
      <CostChangesFoundModal
        isOpen={costChangesFoundModal.isOpen}
        handleClose={costChangesFoundModal.handleClose}
        name={currentCostDisplayName}
        handleDecline={() => {
          setCurrentCostId(cost.id);
          costChangesFoundModal.handleClose();
        }}
        handleAccept={async () => {
          const {data} = await handleSubmit();
          if (!data.response.errors) {
            setCurrentCostId(cost.id);
          }
          costChangesFoundModal.handleClose();
        }}
      />
    </CompensationUserButton>
  );
};

const CompensationCostsByRole = ({
  hasChange,
  handleSubmit,
  costs,
  role,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const sortedCosts = _.sortBy(costs, ['displayName']);
  return (
    <React.Fragment>
      <CostSectionButton onPress={() => setIsExpanded(!isExpanded)}>
        <UserRoleText>{pluralize(UserRole.getDisplayRole(role), costs.length, true)}</UserRoleText>
        <Space width={6} />
        <Icon
          source={isExpanded ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.gray.tertiary}
          size={10}
        />
      </CostSectionButton>
      {isExpanded &&
        sortedCosts.map((cost, index) => {
          return (
            <CompensationUser
              key={index}
              hasChange={hasChange}
              handleSubmit={handleSubmit}
              cost={cost}
              currentCostDisplayName={currentCostDisplayName}
              currentCostId={currentCostId}
              setCurrentCostId={setCurrentCostId}
            />
          );
        })}
    </React.Fragment>
  );
};

const CompensationCostsList = ({
  hasChange,
  handleSubmit,
  costs,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const costsByRole = _.groupBy(costs, (cost) => _.get(cost, 'moverPosition.role'));
  const displayUserRoles = [UserRole.SALESPERSON, UserRole.CONTRACTOR, UserRole.EMPLOYEE];
  return (
    <React.Fragment>
      {displayUserRoles.map((role, index) => {
        const roleCosts = _.get(costsByRole, role);
        if (roleCosts) {
          return (
            <React.Fragment key={index}>
              <Line />
              <CompensationCostsByRole
                hasChange={hasChange}
                handleSubmit={handleSubmit}
                costs={roleCosts}
                role={role}
                currentCostDisplayName={currentCostDisplayName}
                currentCostId={currentCostId}
                setCurrentCostId={setCurrentCostId}
              />
            </React.Fragment>
          );
        }
        return null;
      })}
      <Space height={8} />
    </React.Fragment>
  );
};

const CostCategoryRow = ({
  hasChange,
  handleSubmit,
  costCategory,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  const isCompensation = costCategory.kind === CostCategoryKind.COMPENSATION;
  const firstCostId = _.get(costCategory, 'costs.0.id');
  const isSelectedCategory = !isCompensation && firstCostId === currentCostId;
  const costChangesFoundModal = useModal({name: 'Confirm Save Cost Changes Modal'});
  const showFirstCostOfCostCategory = () => setCurrentCostId(firstCostId);
  return (
    <React.Fragment>
      <CostSectionButton
        disabled={isCompensation}
        isSelected={isSelectedCategory}
        onPress={() => {
          if (hasChange) {
            return costChangesFoundModal.handleOpen();
          }
          return showFirstCostOfCostCategory();
        }}
      >
        <CostCategoryText>
          {CostCategory.getDisplayKind(costCategory, {isShortened: true})}
        </CostCategoryText>
        <Space style={{flex: 1}} />
        {!isCompensation && (
          <Icon source={Icon.ChevronRight} color={colors.gray.primary} size={14} />
        )}
        {isSelectedCategory && <IndicatorLine />}
      </CostSectionButton>
      {isCompensation && (
        <CompensationCostsList
          hasChange={hasChange}
          handleSubmit={handleSubmit}
          costs={costCategory.costs}
          currentCostDisplayName={currentCostDisplayName}
          currentCostId={currentCostId}
          setCurrentCostId={setCurrentCostId}
        />
      )}
      <HeavyLine />
      <CostChangesFoundModal
        isOpen={costChangesFoundModal.isOpen}
        handleClose={costChangesFoundModal.handleClose}
        name={currentCostDisplayName}
        handleDecline={() => {
          showFirstCostOfCostCategory();
          costChangesFoundModal.handleClose();
        }}
        handleAccept={async () => {
          const {data} = await handleSubmit();
          if (!data.response.errors) {
            showFirstCostOfCostCategory();
          }
          costChangesFoundModal.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const EditCostCostItemsModalCosts = ({
  hasChange,
  handleSubmit,
  aggregateCost,
  currentCostDisplayName,
  currentCostId,
  setCurrentCostId,
}: any) => {
  return (
    <Container>
      {aggregateCost.costCategories.map((costCategory: any, index: any) => {
        return (
          <CostCategoryRow
            key={index}
            hasChange={hasChange}
            handleSubmit={handleSubmit}
            costCategory={costCategory}
            currentCostDisplayName={currentCostDisplayName}
            currentCostId={currentCostId}
            setCurrentCostId={setCurrentCostId}
          />
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCostCostItemsModalCosts.fragment = gql`
  ${CostCategory.getDisplayKind.fragment}

  fragment EditCostCostItemsModalCosts on AggregateCost {
    totalCost
    costCategories {
      costs {
        id
        displayName
        moverPosition {
          id
          role
          name
        }
      }
      ...CostCategory_getDisplayKind
    }
  }
`;

export default EditCostCostItemsModalCosts;
