// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UnassignTruckFromJobMutation.defaultProps;

// @ts-expect-error TS(7022): 'UnassignTruckFromJobMutation' implicitly has type... Remove this comment to see the full error message
const UnassignTruckFromJobMutation = ({jobTruckId, onSuccess, onError, children}: Props) => {
  return (
    <Mutation
      // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
      variables={{
        jobTruckId,
      }}
      mutation={UnassignTruckFromJobMutation.mutation}
      onCompleted={({response: {jobTruck, errors}}: any) => {
        if (!errors) {
          onSuccess(jobTruck);
        } else if (onError) {
          onError(errors);
        }
      }}
      refetchQueries={['PrepareJobPage']}
      awaitRefetchQueries
    >
      {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
    </Mutation>
  );
};

UnassignTruckFromJobMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UnassignTruckFromJobMutation.mutation = gql`
  mutation UnassignTruckFromJobMutation(
    $jobTruckId: Int!,
  ) {
    response: unassignTruckFromJob(
      jobTruckId: $jobTruckId,
    ) {
      ${gql.errors}
      jobTruck {
        truckId
        jobId
      }
    }
  }
`;

export default UnassignTruckFromJobMutation;
