// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Currency, Percent} from '@supermove/utils';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const BLOCK_TITLE = 'Summary';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getFieldValueContainerStyle = ({responsive}: any) => {
  return {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: `calc(100% * (1 / ${responsive.desktop ? 4 : 2}))`,
  };
};

const SkeletonBody = () => {
  const responsive = useResponsive();
  const fieldValueContainerStyle = getFieldValueContainerStyle({responsive});

  return (
    <Row>
      <Container style={fieldValueContainerStyle}>
        <FieldValue.SkeletonLoader label={'Revenue Total'} width={60} style={{flex: 1}} />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue.SkeletonLoader label={'Cost Total'} width={60} style={{flex: 1}} />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue.SkeletonLoader label={'Net Profit'} width={60} style={{flex: 1}} />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue.SkeletonLoader label={'% Profit'} width={60} style={{flex: 1}} />
        <Space height={16} />
      </Container>
    </Row>
  );
};

const SkeletonComponent = () => {
  return <ActionPanel BodyComponent={SkeletonBody} title={BLOCK_TITLE} style={{width: '100%'}} />;
};

const AccountingSummaryFields = ({project}: any) => {
  const percentProfit = project.totalProfit / project.totalRevenue;
  const responsive = useResponsive();
  const fieldValueContainerStyle = getFieldValueContainerStyle({responsive});

  return (
    <Row style={{flexWrap: 'wrap'}}>
      <Container style={fieldValueContainerStyle}>
        <FieldValue
          label={'Revenue Total'}
          value={Currency.display(project.totalRevenue)}
          style={{flex: 1}}
          isResponsive
        />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue
          label={'Cost Total'}
          value={Currency.display(project.totalCost)}
          style={{flex: 1}}
          isResponsive
        />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue
          label={'Net Profit'}
          value={Currency.display(project.totalProfit)}
          style={{flex: 1}}
          isResponsive
        />
        <Space height={16} />
      </Container>
      <Container style={fieldValueContainerStyle}>
        <FieldValue
          label={'% Profit'}
          value={Percent.display(_.round(percentProfit, 2))}
          style={{flex: 1}}
          isResponsive
        />
        <Space height={16} />
      </Container>
    </Row>
  );
};

const ProjectAccountingSummaryBlockContent = ({project}: any) => {
  return (
    <ActionPanel
      BodyComponent={AccountingSummaryFields}
      bodyComponentProps={{project}}
      title={BLOCK_TITLE}
      style={{width: '100%'}}
    />
  );
};

const ProjectAccountingSummaryBlockContentMobile = ({project}: any) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader title={`Summary`} />
      <AccountingSummaryFields project={project} />
    </React.Fragment>
  );
};

const ProjectAccountingSummaryBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectAccountingSummaryBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data}: any) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectAccountingSummaryBlockContent project={project} />
            ) : (
              <ProjectAccountingSummaryBlockContentMobile project={project} />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAccountingSummaryBlock.listener = gql`
  fragment ProjectAccountingSummaryBlock_listener on Project {
    id
    totalRevenue
    totalCost
    totalProfit
  }
`;

ProjectAccountingSummaryBlock.fragment = gql`
  ${ProjectAccountingSummaryBlock.listener}
  fragment ProjectAccountingSummaryBlock on Project {
    id
    uuid
    ...ProjectAccountingSummaryBlock_listener
  }
`;

ProjectAccountingSummaryBlock.query = gql`
  ${ProjectAccountingSummaryBlock.listener}
  query ProjectAccountingSummaryBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      totalRevenue
      totalCost
      totalProfit
      ...ProjectAccountingSummaryBlock_listener
    }
  }
`;

export default ProjectAccountingSummaryBlock;
