// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Drawer,
  Icon,
  Loading,
  MultiDropdownInput,
  ScrollView,
  SectionList,
  Space,
  Styled,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MessageModal from '@shared/design/components/Modal/SmallModal/MessageModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import JobTypeConfig from '@shared/modules/Job/enums/JobTypeConfigs';
import ApplyJobTypeConfigsToJobTypesForm from '@shared/modules/Job/forms/ApplyJobTypeConfigsToJobTypesForm';
import useApplyJobTypeConfigsToJobTypesMutation from '@shared/modules/Job/hooks/useApplyJobTypeConfigsToJobTypesMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '100%' : '424px')};
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 16px;
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
  padding-horizontal: 16px;
  padding-vertical: 12px;
  background-color: ${colors.white};
`;

const FooterRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const CloseButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
  color: ${colors.gray.primary};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const TableBodyText = Styled.Text`
  ${Typography.Body}
`;

const BoldText = Styled.Text`
  ${Typography.Label}
`;

const SelectedText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const WarningText = Styled.Text`
  ${Typography.Body}
  color: ${colors.orange.status};
`;

const HeaderRow = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  padding-horizontal: 16px;
  background-color: ${colors.gray.background};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const Row = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
  padding-horizontal: 32px;
  background-color: ${colors.white};
  border-bottom-width: 1px;
  border-bottom-color: ${colors.gray.border};
`;

const Header = ({headerText, handleClose}: any) => {
  return (
    <HeaderContainer>
      <HeaderText>{headerText}</HeaderText>
      <CloseButton onPress={handleClose}>
        <Icon source={Icon.Times} size={20} color={colors.gray.primary} />
      </CloseButton>
    </HeaderContainer>
  );
};

const Footer = ({isDisabled, handleSubmit, handleClose, jobTypeIdsToUpdate}: any) => {
  const SELECTED_JOB_TYPE_LIMIT = 40;
  const countOfSelectedJobTypes = jobTypeIdsToUpdate ? jobTypeIdsToUpdate.length : 0;
  const isMaximumJobTypesSelected = countOfSelectedJobTypes > SELECTED_JOB_TYPE_LIMIT;
  return (
    <FooterContainer>
      {isMaximumJobTypesSelected ? (
        <FooterRow>
          <Icon color={colors.orange.status} size={12} source={Icon.ExclamationCircle} />
          <Space width={6} />
          <WarningText>{`Too many selected (Limit ${SELECTED_JOB_TYPE_LIMIT})`}</WarningText>
        </FooterRow>
      ) : (
        <SelectedText>{`${countOfSelectedJobTypes} selected`}</SelectedText>
      )}
      <FooterRow>
        <Button
          color={colors.white}
          textColor={colors.gray.secondary}
          onPress={handleClose}
          text={'Cancel'}
        />
        <Space width={8} />
        <Button
          onPress={handleSubmit}
          text={'Submit'}
          isDisabled={isDisabled || isMaximumJobTypesSelected}
        />
      </FooterRow>
    </FooterContainer>
  );
};

const handleJobTypeToggle = ({jobType, form}: any) => {
  const currentIds = _.get(form.values, 'applyJobTypeConfigsToJobTypesForm.jobTypeIdsToUpdate');
  const updatedIds = _.xor(currentIds, [jobType.id]);
  form.setFieldValue('applyJobTypeConfigsToJobTypesForm.jobTypeIdsToUpdate', updatedIds);
};

const getSections = ({projectTypes, jobTypeId}: any) => {
  return _.orderBy(projectTypes, [(projectType) => projectType.name.toLowerCase()]).map(
    (projectType) => {
      return {
        projectTypeId: projectType.id,
        data: _.orderBy(
          _.filter(projectType.jobTypes, (jobType) => jobType.id !== jobTypeId),
          [(jobType) => jobType.name.toLowerCase()],
        ),
        projectTypeName: projectType.name,
      };
    },
  );
};

const DrawerContent = ({
  handleClose,
  refetch,
  jobType,
  projectTypes,
  defaultJobTypeConfigs = [],
}: any) => {
  const responsive = useResponsive();
  const applyJobTypeConfigsToJobTypesForm = ApplyJobTypeConfigsToJobTypesForm.new({
    jobTypeId: jobType.id,
    defaultJobTypeConfigs,
  });

  const {form, handleSubmit, submitting} = useApplyJobTypeConfigsToJobTypesMutation({
    applyJobTypeConfigsToJobTypesForm,
    onSuccess: () => {
      applyConfigConfirmationModal.handleClose();
      handleClose();
      refetch();
      applyConfigSuccessToast.handleToast();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const jobTypeIdsToUpdate = _.get(
    form.values,
    'applyJobTypeConfigsToJobTypesForm.jobTypeIdsToUpdate',
  );
  const jobTypeConfigs = _.get(form.values, 'applyJobTypeConfigsToJobTypesForm.jobTypeConfigs');
  const applyConfigConfirmationModal = useModal({name: 'Copy Config Confirmation Modal'});
  const applyConfigSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: `Success! ${jobTypeIdsToUpdate.length} Job Types Updated 🎉`,
  });
  return (
    <Container responsive={responsive}>
      <Header headerText={`Copy Job Type Configs`} handleClose={handleClose} />
      <BodyContainer>
        <ScrollView contentContainerStyle={{padding: 16}}>
          <FieldInput.LabelText>{`Copying job type config from...`}</FieldInput.LabelText>
          <Space height={8} />
          <BoldText>{`${jobType.projectType.name}: ${jobType.name}`}</BoldText>
          <Space height={24} />
          <FieldInput
            {...form}
            component={MultiDropdownInput}
            name={`applyJobTypeConfigsToJobTypesForm.jobTypeConfigs`}
            label={'Which config options do you want to copy?'}
            input={{
              options: JobTypeConfig.getDropdownOptions,
              placeholder: 'Select configs...',
              setFieldValue: form.setFieldValue,
              style: {
                flex: 1,
              },
            }}
          />
          <Space height={24} />
          <FieldInput.LabelText>{`Which job types do you want to copy to?`}</FieldInput.LabelText>
          <Space height={8} />
          <SectionList
            refreshing={false}
            onRefresh={refetch}
            sections={getSections({projectTypes, jobTypeId: jobType.id})}
            keyExtractor={(jobType: any) => jobType.id}
            // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
            renderItem={({item: jobType}) => (
              <Row onPress={() => handleJobTypeToggle({jobType, form})}>
                <Checkbox
                  isChecked={_.includes(jobTypeIdsToUpdate, jobType.id)}
                  // @ts-expect-error TS(2322): Type '{ children: Element[]; isChecked: boolean; s... Remove this comment to see the full error message
                  size={16}
                  handleToggle={() => handleJobTypeToggle({jobType, form})}
                  childrenRight
                >
                  <Space width={8} />
                  <TableBodyText>{jobType.name}</TableBodyText>
                </Checkbox>
              </Row>
            )}
            renderSectionHeader={({section}: any) => {
              const field = 'applyJobTypeConfigsToJobTypesForm.jobTypeIdsToUpdate';
              const allProjectTypeJobTypeIds = section.data.map((jobType: any) => jobType.id);
              const selectedJobTypeIds = _.get(form.values, field);
              const isAllProjectTypeJobTypesSelected =
                _.difference(allProjectTypeJobTypeIds, selectedJobTypeIds).length === 0;

              const handleHeaderRowSelected = ({
                form,
                isAllProjectTypeJobTypesSelected,
                selectedJobTypeIds,
                allProjectTypeJobTypeIds,
              }: any) => {
                if (isAllProjectTypeJobTypesSelected) {
                  form.setFieldValue(
                    field,
                    _.difference(selectedJobTypeIds, allProjectTypeJobTypeIds),
                  );
                } else {
                  form.setFieldValue(field, _.union(selectedJobTypeIds, allProjectTypeJobTypeIds));
                }
              };

              return (
                <HeaderRow
                  onPress={() =>
                    handleHeaderRowSelected({
                      form,
                      isAllProjectTypeJobTypesSelected,
                      selectedJobTypeIds,
                      allProjectTypeJobTypeIds,
                    })
                  }
                >
                  <Checkbox
                    isChecked={isAllProjectTypeJobTypesSelected}
                    // @ts-expect-error TS(2322): Type '{ children: Element[]; isChecked: boolean; s... Remove this comment to see the full error message
                    size={16}
                    handleToggle={() =>
                      handleHeaderRowSelected({
                        form,
                        isAllProjectTypeJobTypesSelected,
                        selectedJobTypeIds,
                        allProjectTypeJobTypeIds,
                      })
                    }
                    childrenRight
                  >
                    <Space width={8} />
                    <TableHeaderText>{section.projectTypeName}</TableHeaderText>
                  </Checkbox>
                </HeaderRow>
              );
            }}
            ListEmptyComponent={() => <TableBodyText>No Job Types</TableBodyText>}
            style={{
              borderWidth: 1,
              borderColor: colors.gray.border,
              borderRadius: 4,
            }}
          />
          <Space height={32} />
        </ScrollView>
      </BodyContainer>
      <Footer
        jobTypeIdsToUpdate={jobTypeIdsToUpdate}
        isDisabled={_.isEmpty(jobTypeIdsToUpdate) || _.isEmpty(jobTypeConfigs)}
        handleSubmit={applyConfigConfirmationModal.handleOpen}
        handleClose={handleClose}
      />
      <MessageModal
        key={applyConfigConfirmationModal.key}
        title={'Copy Job Type Config?'}
        // @ts-expect-error TS(2322): Type '{ key: string; title: string; message: strin... Remove this comment to see the full error message
        message={`This change will override selected config for ${jobTypeIdsToUpdate.length} job types.`}
        isOpen={applyConfigConfirmationModal.isOpen}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={applyConfigConfirmationModal.handleClose}
        handlePressOutside={applyConfigConfirmationModal.handleClose}
        isSubmitting={submitting}
        isDisabled={submitting}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
      />
    </Container>
  );
};

type OwnApplyJobTypeConfigDrawerProps = {
  jobType: any;
  refetch?: (...args: any[]) => any;
  defaultJobTypeConfigs?: any[];
  isOpen?: boolean;
  handleClose?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'ApplyJobTypeConfigDrawerProps' circula... Remove this comment to see the full error message
type ApplyJobTypeConfigDrawerProps = OwnApplyJobTypeConfigDrawerProps &
  typeof ApplyJobTypeConfigDrawer.defaultProps;

// @ts-expect-error TS(7022): 'ApplyJobTypeConfigDrawer' implicitly has type 'an... Remove this comment to see the full error message
const ApplyJobTypeConfigDrawer = ({
  isOpen,
  handleClose,
  jobType,
  refetch,
  defaultJobTypeConfigs,
}: ApplyJobTypeConfigDrawerProps) => {
  const {data, loading} = useQuery(ApplyJobTypeConfigDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      category: jobType.projectType.category,
    },
  });

  return (
    <Drawer isOpen={isOpen} onClose={handleClose}>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <DrawerContent
            handleClose={handleClose}
            jobType={jobType}
            projectTypes={data.viewer.viewingOrganization.moveProjectTypes}
            refetch={refetch}
            defaultJobTypeConfigs={defaultJobTypeConfigs}
          />
        )}
      </Loading>
    </Drawer>
  );
};

ApplyJobTypeConfigDrawer.defaultProps = {
  refetch: () => {},
  defaultJobTypeConfigs: [],
  isOpen: false,
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ApplyJobTypeConfigDrawer.fragment = gql`
  fragment ApplyJobTypeConfigDrawer on JobType {
    id
    name
    projectType {
      id
      name
      category
    }
  }
`;

ApplyJobTypeConfigDrawer.query = gql`
    query ApplyJobTypeConfigDrawer($category: String!) {
      ${gql.query}
      viewer {
        id
        viewingOrganization {
          id
          moveProjectTypes: projectTypesForCategory(category: $category) {
            id
            name
            jobTypes {
              id
              name
            }
          }
        }
      }
    }
  `;

export default ApplyJobTypeConfigDrawer;
