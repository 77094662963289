/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSearch} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import CrewUsersAndDriversSchedulesList from 'modules/Dispatch/Crew/components/CrewUsersAndDriversSchedulesList';
import CrewUsersAndDriversSelections from 'modules/Dispatch/Crew/components/CrewUsersAndDriversSelections';

const SearchInputContainer = Styled.View`
  height: 33px;
`;

const ScheduleSummariesSearchInput = Styled.TextInput.H7`
  border-width: 0px;
  background-color: ${colors.gray.background};
  margin-horizontal: 16px;
  border-radius: 4px;
  padding-left: 28px;
  height: 32px;
`;

const CrewUsersAndDrivers = ({
  crew,
  form,
  selectedUsers,
  userSchedules,
  setIsClosable,
  handleAddUserId,
  handleRemoveUserId,
}: any) => {
  const userSchedulesSorted = _.sortBy(userSchedules, (userSchedule) =>
    _.get(userSchedule, 'user.fullName'),
  );
  // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  const {results: searchResults, setQuery} = useSearch({
    initialQuery: '',
    items: userSchedulesSorted,
    // Fuze is really bad at fuzzy searching with long strings, we will use a regex include search with this prop
    options: {keys: ['user.fullName', 'user.position'], useExtendedSearch: true},
  });

  return (
    <React.Fragment>
      <CrewUsersAndDriversSelections
        crew={crew}
        form={form}
        selectedUsers={selectedUsers}
        setIsClosable={setIsClosable}
        handleRemoveUserId={handleRemoveUserId}
      />
      <Space height={8} />
      <SearchInputContainer>
        <ScheduleSummariesSearchInput
          placeholder={`Search for a name or position`}
          placeholderTextColor={colors.gray.tertiary}
          // Adding a ' is to enable regex search for Fuze in useQuery
          // https://www.fusejs.io/api/options.html#distance
          onChangeText={(text: any) => setQuery(text ? `'${text}` : '')}
        />
        <Icon
          source={Icon.Search}
          size={12}
          color={colors.gray.tertiary}
          style={{position: 'absolute', left: 12, top: 10}}
        />
      </SearchInputContainer>
      <CrewUsersAndDriversSchedulesList
        crew={crew}
        selectedUsers={selectedUsers}
        userSchedules={searchResults}
        handleAddUserId={handleAddUserId}
        handleRemoveUserId={handleRemoveUserId}
      />
    </React.Fragment>
  );
};

const CrewUsersAndDriversContent = ({
  crew,
  employeeSchedules,
  form,
  selectedUsers,
  setIsClosable,
  handleAddUserId,
  handleRemoveUserId,
}: any) => {
  return (
    <CrewUsersAndDrivers
      crew={crew}
      form={form}
      selectedUsers={selectedUsers}
      userSchedules={employeeSchedules.schedules}
      setIsClosable={setIsClosable}
      handleAddUserId={handleAddUserId}
      handleRemoveUserId={handleRemoveUserId}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUsersAndDriversContent.fragment = gql`
  ${CrewUsersAndDriversSchedulesList.fragment}
  ${CrewUsersAndDriversSelections.fragment}

  fragment CrewUsersAndDriversContent on Crew {
    id
    organization {
      id
      allEmployees {
        id
        ...CrewUsersAndDriversSelections_Users
      }
    }
    ...CrewUsersAndDriversSelections_Crew
    ...CrewUsersAndDriversSchedulesList_Crew
  }

  fragment CrewUsersAndDriversContent_EmployeeSchedule on EmployeeSchedule {
    user {
      id
      fullName
      hasDriverMoverPosition
      position
    }
    ...CrewUsersAndDriversSchedulesList_EmployeeSchedule
  }
`;

export default CrewUsersAndDriversContent;
