// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useSearch, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectEventLogCard from 'modules/Project/V2/Show/components/widgets/ProjectEventLogCard';
import ProjectEventLogFilters from 'modules/Project/V2/Show/components/widgets/ProjectEventLogFilters';
import ProjectWidgetContainer from 'modules/Project/V2/Show/components/widgets/ProjectWidgetContainer';

const Row = Styled.View`
  flex-direction: row;
`;

const TimeAgoHeader = Styled.Text`
  ${Typography.Responsive.Subheading}
  color: ${colors.gray.secondary};
`;

const INITIAL_FILTERS = {
  fromDate: '',
  toDate: '',
  creatorIds: [],
  jobIds: [],
  isJobsOnly: false,
};

const displayWhen = (date: any) => {
  if (Datetime.isToday(date)) {
    return 'Today';
  } else if (Datetime.isYesterday(date)) {
    return 'Yesterday';
  } else {
    return Datetime.convertToDisplayDate(date);
  }
};

const getGroupedEvents = ({jobEvents}: any) => {
  const timeAgoToEvents = _.groupBy(jobEvents, (jobEvent) => displayWhen(jobEvent.date));

  const groupedEvents = Object.keys(timeAgoToEvents).reduce(
    // @ts-expect-error TS(2769): No overload matches this call.
    (result, key) => [...result, {timeAgo: key, events: timeAgoToEvents[key]}],
    [],
  );

  return _.orderBy(groupedEvents, (group) => _.get(group, 'events.0.date'), ['desc']);
};

const getIsExpandedAll = ({jobEvents, expandedEvents}: any) => {
  const isExpandedCount = Object.keys(expandedEvents).filter(
    (eventId) => expandedEvents[eventId],
  ).length;
  const expandableEventsCount = jobEvents.filter((event: any) => event.description).length;
  return isExpandedCount >= expandableEventsCount;
};

const handleExpandAllEvents = ({jobEvents, setExpandedEvents}: any) => {
  const allExpanded = jobEvents.reduce(
    (result: any, event: any) => (event.description ? {...result, [event.id]: true} : result),
    {},
  );
  setExpandedEvents(allExpanded);
};

const ProjectEventLogWidgetHeader = ({
  project,
  jobEvents,
  search,
  setSearch,
  filters,
  setFilters,
  loading,
  expandedEvents,
  setExpandedEvents,
}: any) => {
  const isExpandedAll = getIsExpandedAll({jobEvents, expandedEvents});
  const responsive = useResponsive();

  return (
    <ProjectWidgetContainer.Header>
      <Row>
        <SearchBar
          valueOverride={search}
          onChangeText={(input) => {
            if (!isExpandedAll) {
              handleExpandAllEvents({jobEvents, setExpandedEvents});
            }
            setSearch(input);
          }}
          containerStyle={{flex: 1}}
          style={{flex: 1, minWidth: 0}}
          isLoading={loading}
          isClearable
          isResponsive
        />
        <Space width={12} />
        <SecondaryButton
          onPress={() => {
            if (isExpandedAll) {
              setExpandedEvents({});
            } else {
              handleExpandAllEvents({jobEvents, setExpandedEvents});
            }
          }}
          isResponsive
        >
          <Icon
            source={isExpandedAll ? Icon.ArrowsToLine : Icon.ArrowsFromLine}
            size={responsive.desktop ? 14 : 16}
            color={colors.blue.interactive}
          />
        </SecondaryButton>
        <Space width={12} />
        <ProjectEventLogFilters
          projectUuid={project.uuid}
          filters={filters}
          setFilters={setFilters}
        />
      </Row>
    </ProjectWidgetContainer.Header>
  );
};

const EventsGroup = ({eventsGroup, expandedEvents, setExpandedEvents, urlFilters}: any) => {
  const {timeAgo, events} = eventsGroup;
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <TimeAgoHeader responsive={responsive}>{timeAgo}</TimeAgoHeader>
      <Space height={16} />
      {_.orderBy(events, 'timestamp', ['desc']).map((jobEvent, index) => {
        return (
          <ProjectEventLogCard
            key={jobEvent.id}
            jobEvent={jobEvent}
            isLast={index === events.length - 1}
            expandedEvents={expandedEvents}
            setExpandedEvents={setExpandedEvents}
            urlFilters={urlFilters}
          />
        );
      })}
    </React.Fragment>
  );
};

const ProjectEventLogWidgetBody = ({
  jobEvents,
  expandedEvents,
  setExpandedEvents,
  urlFilters,
}: any) => {
  const groupedEvents = getGroupedEvents({jobEvents});

  return (
    <ProjectWidgetContainer.Body>
      {groupedEvents.map((eventsGroup) => {
        return (
          <EventsGroup
            key={(eventsGroup as any).timeAgo}
            eventsGroup={eventsGroup}
            expandedEvents={expandedEvents}
            setExpandedEvents={setExpandedEvents}
            urlFilters={urlFilters}
          />
        );
      })}
    </ProjectWidgetContainer.Body>
  );
};

const ProjectEventLogWidget = ({projectUuid, urlFilters}: any) => {
  const [expandedEvents, setExpandedEvents] = useState({});
  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const {loading, data} = useQuery(ProjectEventLogWidget.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
      fromDate: filters.fromDate,
      toDate: filters.toDate,
      creatorIds: filters.creatorIds,
      jobIds: filters.jobIds,
      isJobsOnly: filters.isJobsOnly,
    },
  });

  const {
    results: filteredEvents,
    query,
    setQuery,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: data?.project.jobEvents || [],
    options: {keys: ['name', 'description'], threshold: 0.4},
  });

  return (
    <ProjectWidgetContainer>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          return (
            <React.Fragment>
              <ProjectEventLogWidgetHeader
                project={data.project}
                jobEvents={filteredEvents}
                search={query}
                setSearch={setQuery}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                expandedEvents={expandedEvents}
                setExpandedEvents={setExpandedEvents}
              />
              {!_.isEmpty(filteredEvents) ? (
                <ProjectEventLogWidgetBody
                  jobEvents={filteredEvents}
                  expandedEvents={expandedEvents}
                  setExpandedEvents={setExpandedEvents}
                  urlFilters={urlFilters}
                />
              ) : (
                <EmptyState.ContentContainer>
                  <EmptyState
                    title={'No results.'}
                    message={
                      'No results match your selected filters.\nClear all filters and try again.'
                    }
                    primaryActionIcon={Icon.Trash}
                    primaryActionText={'Clear Filters'}
                    handlePrimaryAction={() => {
                      setFilters(INITIAL_FILTERS);
                      setQuery('');
                    }}
                  />
                </EmptyState.ContentContainer>
              )}
            </React.Fragment>
          );
        }}
      </Loading>
    </ProjectWidgetContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectEventLogWidget.query = gql`
  ${ProjectEventLogCard.fragment}

  query ProjectEventLogWidget(
    $projectUuid: String!,
    $fromDate: String,
    $toDate: String,
    $creatorIds: [Int],
    $jobIds: [Int],
    $isJobsOnly: Boolean,
  ) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      uuid
      jobEvents: filteredJobEvents(
        fromDate: $fromDate,
        toDate: $toDate,
        creatorIds: $creatorIds,
        jobIds: $jobIds,
        isJobsOnly: $isJobsOnly,
      ) {
        id
        date
        timestamp
        ...ProjectEventLogCard
      }
    }
  }
`;

export default ProjectEventLogWidget;
