// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import ConfirmationStepForm from '@shared/modules/Proposal/forms/ConfirmationStepForm';
import useAddConfirmationStepMutation from '@shared/modules/Proposal/hooks/useAddConfirmationStepMutation';
import QuoteStepModalFields from 'modules/Organization/Settings/ProjectTypes/components/QuoteStepModalFields';

const CreateQuoteStepModal = ({
  isOpen,
  handleClose,
  projectType,
  confirmationStepsId,
  refetch,
}: any) => {
  const confirmationStepForm = ConfirmationStepForm.new({
    confirmationStepsId,
    kind: ConfirmationStepKind.QUOTE_STEP_DOCUMENT,
    name: ConfirmationStepKind.getLabel(ConfirmationStepKind.QUOTE_STEP_DOCUMENT),
  });
  // Set the default kind since only one option right now
  const {form, handleSubmit, submitting} = useAddConfirmationStepMutation({
    confirmationStepForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => {
      console.log(error);
    },
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Create Quote Step'}</SmallModal.HeaderText>
      <Space height={16} />
      <QuoteStepModalFields form={form} projectType={projectType} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={false} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateQuoteStepModal.fragment = gql`
  ${QuoteStepModalFields.fragment}

  fragment createQuoteStepModal on ProjectType {
    id
    ...QuoteStepModalFields
  }
`;
export default CreateQuoteStepModal;
