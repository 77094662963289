// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import {DayPage} from 'modules/Day/components';
import EstimatesDayJobsList from 'modules/Job/Estimate/components/EstimatesDayJobsList';

const Wrapper = Styled.View`
  flex: 1;
  align-items: center;
`;

const Container = Styled.View`
  flex: 1;
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
`;

const EstimateDayPage = ({
  match: {
    params: {date},
  },
}: any) => {
  return (
    <DayPage
      variables={{date}}
      query={EstimateDayPage.query}
      title={({responsive}: any) => {
        if (responsive.mobile) {
          return Datetime.convertToDisplayDate(date, 'dddd, MMMM Do');
        } else {
          return Datetime.convertToDisplayDate(date, 'dddd, MMMM Do, YYYY');
        }
      }}
      subtitle={() => 'View jobs'}
      side={({responsive}: any) => (responsive.mobile ? 70 : 120)}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({responsive, data, refetch}: any) => (
        <Wrapper>
          <Container {...responsive}>
            <EstimatesDayJobsList
              date={date}
              calendarDay={data.estimatesCalendarDay}
              hasTitleDay={false}
              refetch={refetch}
            />
          </Container>
        </Wrapper>
      )}
    </DayPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EstimateDayPage.query = gql`
  ${EstimatesDayJobsList.fragment}

  query EstimateDayPage($date: String!) {
    ${gql.query}
    viewer {
      id
    }
    estimatesCalendarDay(date: $date) {
      ...EstimatesDayJobsList
    }
  }
`;

export default EstimateDayPage;
