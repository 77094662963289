// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import SendRemindersToSetScheduleModal from './SendRemindersToSetScheduleModal';

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: 10px;
`;

const Text = Styled.H8`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const SendReminderToSetScheduleButton = ({employee}: any) => (
  <SendRemindersToSetScheduleModal
    employee={employee}
    trigger={({handleOpen}: any) => (
      <Button onPress={handleOpen}>
        <Text>Send Text</Text>
      </Button>
    )}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SendReminderToSetScheduleButton.fragment = gql`
  ${SendRemindersToSetScheduleModal.fragment}

  fragment SendReminderToSetScheduleButton on User {
    id
    ...SendRemindersToSetScheduleModal
  }
`;

export default SendReminderToSetScheduleButton;
