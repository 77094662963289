// Libraries
import React from 'react';

// App
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useScrollView, useState} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// Components
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import CreateAndSendConfirmationForProjectForm from '@shared/modules/Proposal/forms/CreateAndSendConfirmationForProjectForm';
import useCreateAndSendConfirmationForProjectMutation from '@shared/modules/Proposal/hooks/useCreateAndSendConfirmationForProjectMutation';
import ConfirmationProjectFields from 'modules/Project/Confirmation/components/ConfirmationProjectFields';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-top: ${(props) => ((props as any).mobile ? 20 : 40)}px;
  padding-bottom: 100px;
`;

const Title = Styled.Text`
  ${Typography.PageHeading}
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Footer = Styled.View`
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  padding-vertical: ${(props) => ((props as any).mobile ? 10 : 15)};
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.Text`
  ${Typography.Label}
  color: ${colors.white};
`;

const BoldText = Styled.Text`
  ${Typography.Label}
`;

const getWarningText = ({project, isQuote}: any) => {
  const kind = isQuote ? 'quote' : 'confirmation';
  if (project.hasMultipleDays) {
    return `This is a multi-day ${kind}.`;
  } else if (project.hasMultipleCrewsPerDay) {
    return `This ${kind} has multiple jobs on the same day.`;
  } else {
    return '';
  }
};

const WarningSection = ({project, isQuote}: any) => {
  const warningText = getWarningText({project, isQuote});
  if (warningText === '') {
    return null;
  } else {
    return (
      <Subtitle>
        <Space height={16} />
        <BoldText>{warningText}</BoldText>
        {` This ${
          isQuote ? 'quote' : 'confirmation'
        } will include information about all the days in ${Project.getProjectAndActiveJobsText(
          project,
        )}.`}
      </Subtitle>
    );
  }
};

const ProjectQuoteAndConfirmationPageContent = ({
  kind,
  viewerId,
  project,
  onSuccess,
  pageTitle,
  pageSubtitle,
  emailTemplate,
}: any) => {
  const scrollView = useScrollView();
  const isQuote = kind === ConfirmationStepsKind.QUOTE;
  const responsive = useResponsive();
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const createAndSendConfirmationForProjectForm = CreateAndSendConfirmationForProjectForm.new({
    viewerId,
    project,
    emailTemplate,
    kind,
  });
  const {form, submitting, handleSubmit} = useCreateAndSendConfirmationForProjectMutation({
    createAndSendConfirmationForProjectForm,
    onSuccess,
    onError: (errors: any) => {
      scrollView.handleScrollToTop({animated: true});
      console.log({errors});
    },
  });

  return (
    <Wrapper>
      <ScrollView ref={scrollView.ref} style={{flex: 1}}>
        <Container>
          <Content {...responsive}>
            <Title>{pageTitle}</Title>
            <Space height={16} />
            <Row>
              <Subtitle>{pageSubtitle}</Subtitle>
            </Row>
            <Row>
              <WarningSection project={project} isQuote={isQuote} />
            </Row>
            <Space height={40} />
            <ConfirmationProjectFields
              form={form}
              field={'createAndSendConfirmationForProjectForm'}
              viewerId={viewerId}
              project={project}
              isQuote={isQuote}
              defaultEmailTemplateForm={createAndSendConfirmationForProjectForm}
              setIsGeneratingPreview={setIsGeneratingPreview}
            />
          </Content>
        </Container>
      </ScrollView>
      <Footer {...responsive}>
        <Button disabled={isGeneratingPreview} loading={submitting} onPress={handleSubmit}>
          <Row style={{alignItems: 'center'}}>
            <Icon source={Icon.Check} color={colors.white} size={Icon.Sizes.Small} />
            <Space width={8} />
            <Text>{`Send ${isQuote ? 'Quote' : 'Confirmation'}`}</Text>
          </Row>
        </Button>
      </Footer>
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectQuoteAndConfirmationPageContent.fragment = gql`
  ${ConfirmationProjectFields.fragment}
  ${CreateAndSendConfirmationForProjectForm.new.fragment}
  ${Project.getProjectAndActiveJobsText.fragment}

  fragment ProjectQuoteAndConfirmationPageContent_Project on Project {
    id
    hasMultipleDays
    hasMultipleCrewsPerDay
    ...ConfirmationProjectFields
    ...CreateAndSendConfirmationForProjectForm_new_Project
    ...Project_getProjectAndActiveJobsText
  }
  fragment ProjectQuoteAndConfirmationPageContent_EmailTemplate on EmailTemplate {
    id
    ...CreateAndSendConfirmationForProjectForm_new_EmailTemplate
  }
`;

export default ProjectQuoteAndConfirmationPageContent;
