// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';

// App
import PrintDocumentSkeletonLoader from '@shared/modules/Document/components/PrintDocumentSkeletonLoader';
import PrintDocumentV2PdfWrapper from '@shared/modules/Document/components/PrintDocumentV2PdfWrapper';
import DocumentV2Pdf from 'modules/Document/DocumentV2/DocumentV2Pdf';

const SharedDocumentPage = () => {
  const {params, navigator} = useNavigationDOM();
  const {loading, data} = useQuery(SharedDocumentPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      shareUuid: params.shareUuid,
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <Loading loading={loading} as={() => <PrintDocumentSkeletonLoader showPrint />}>
      {() => {
        if (!data.sharedDocument.job.project.isComplete) {
          navigator.replace(`/share/documents/${params.shareUuid}/live`);
          return null;
        }

        return (
          <PrintDocumentV2PdfWrapper documentUuid={data.sharedDocument.uuid}>
            <DocumentV2Pdf document={data.sharedDocument} />
          </PrintDocumentV2PdfWrapper>
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SharedDocumentPage.query = gql`
  ${DocumentV2Pdf.fragment}
  query SharedDocumentPage($shareUuid: String!) {
    ${gql.query}
    sharedDocument(shareUuid: $shareUuid) {
      id
      uuid
      job {
        id
        project {
          id
          isComplete
        }
      }
      ...DocumentV2Pdf
    }
  }
`;

export default SharedDocumentPage;
