// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled, LocationInput, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import CreateContainerTypeModal from 'modules/Organization/Settings/Storage/Containers/components/CreateContainerTypeModal';

const Row = Styled.View`
  flex-direction: row;
`;

const InputTitleLabel = Styled.Text`
  ${Typography.Body3};
`;

const getHandleChangeLocation = ({form, field}: any) => {
  return ({address, city, zipCode, latitude, longitude, state, country}: any) => {
    const locationForm = _.get(form.values, field);
    form.setFieldValue(field, {
      ...locationForm,
      address,
      city,
      zipCode,
      latitude,
      longitude,
      state,
      country,
    });
  };
};

const WarehouseCoordinatesSection = ({form, field}: any) => {
  return (
    <React.Fragment>
      <InputTitleLabel>Warehouse Coordinates</InputTitleLabel>
      <Space height={2} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Row index={2}>
        <FieldInput
          {...form}
          name={`${field}.warehouseCoordinateX`}
          style={{flex: 1}}
          input={{
            placeholder: 'X',
          }}
        />
        <Space height={12} width={12} />
        <FieldInput
          {...form}
          name={`${field}.warehouseCoordinateY`}
          style={{flex: 1}}
          input={{
            placeholder: 'Y',
          }}
        />
        <Space height={12} width={12} />
        <FieldInput
          {...form}
          name={`${field}.warehouseCoordinateZ`}
          style={{flex: 1}}
          input={{
            placeholder: 'Z',
          }}
        />
        <Space height={12} width={12} />
      </Row>
    </React.Fragment>
  );
};

const ContainerModalContent = ({form, field, organization, viewer, refetch}: any) => {
  const createContainerTypeModal = useModal({name: 'Create Container Type Modal'});

  return (
    <React.Fragment>
      <React.Fragment>
        <Row>
          <InputTitleLabel>Container Type</InputTitleLabel>
          <Space style={{flex: 1}} />
          <TertiaryButton
            onPress={createContainerTypeModal.handleOpen}
            iconLeft={Icon.Plus}
            text={'Create New'}
          />
          <CreateContainerTypeModal
            key={createContainerTypeModal.key} // Ensures values are empty when modal is opened
            isOpen={createContainerTypeModal.isOpen}
            handleClose={createContainerTypeModal.handleClose}
            organizationId={organization.company.primaryOrganization.id}
            userId={viewer.id}
            refetch={refetch}
          />
        </Row>
        <Space height={2} />
        <FieldInput
          {...form}
          index={0}
          component={DropdownInput}
          name={`${field}.containerTypeId`}
          input={{
            placeholder: 'Select container type',
            required: !_.get(form.values, 'containerForm.containerTypeId'),
            options: organization.companySettings.containerTypes.map((containerType: any) => ({
              value: containerType.id,
              label: containerType.name,
            })),
            setFieldValue: form.setFieldValue,
            style: {flex: 1},
            isClearable: true,
          }}
        />
      </React.Fragment>
      <Space height={12} width={12} />
      <FieldInput
        {...form}
        index={1}
        label={'Container ID'}
        name={`${field}.name`}
        input={{
          required: !_.get(form.values, 'containerForm.name'),
          placeholder: 'Enter container name',
        }}
      />
      <Space height={12} width={12} />
      <React.Fragment>
        <Row>
          <InputTitleLabel>Where is this container?</InputTitleLabel>
          <Space style={{flex: 1}} />
          <SwitchField form={form} field={`${field}.isCustomLocation`} labelRight={'Custom'} />
        </Row>
        <Space height={2} />
        {_.get(form, 'values.containerForm.isCustomLocation') ? (
          <FieldInput
            {...form}
            index={3}
            component={LocationInput}
            name={`${field}.customLocationForm.address`}
            input={{
              required: !_.get(form.values, 'containerForm.customLocationForm.address'),
              placeholder: 'Enter custom address',
              setFieldValue: (_name: any, value: any) => {
                form.setFieldValue(`${field}.customLocationForm.address`, value);
                form.setFieldValue(`${field}.warehouseId`, null);
              },
              setFieldError: form.setFieldError,
              setFieldTouched: form.setFieldTouched,
              onLocation: getHandleChangeLocation({form, field: `${field}.customLocationForm`}),
            }}
          />
        ) : (
          <React.Fragment>
            <FieldInput
              {...form}
              index={3}
              component={DropdownInput}
              name={`${field}.warehouseId`}
              input={{
                placeholder: 'Choose warehouse',
                required: !_.get(form.values, 'containerForm.warehouseId'),
                options: organization.companySettings.warehouses.map((warehouse: any) => ({
                  value: warehouse.id,
                  label: warehouse.name,
                  description: warehouse.organization.name,
                })),
                setFieldValue: (_name: any, value: any) => {
                  form.setFieldValue(`${field}.warehouseId`, value);
                  form.setFieldValue(`${field}.customLocationForm.address`, null);
                },
                style: {flex: 1},
                isClearable: true,
                components: {
                  Option: DropdownInput.OptionWithDescription,
                },
              }}
            />
            <Space height={12} width={12} />
            <WarehouseCoordinatesSection form={form} field={field} />
          </React.Fragment>
        )}
      </React.Fragment>
      <Space height={12} width={12} />
      <FieldInput
        {...form}
        index={6}
        label={'Container Notes'}
        name={`${field}.notes`}
        style={{flex: 1}}
        input={{
          style: {height: 120, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter any notes about this container',
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ContainerModalContent.fragment = gql`
  fragment ContainerModalContent on Organization {
    id
    company {
      id
      primaryOrganization {
        id
      }
    }
    companySettings {
      warehouses {
        id
        name
        organization {
          id
          name
        }
      }
      containerTypes {
        id
        name
      }
    }
  }
`;

export default ContainerModalContent;
