// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Form, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {
  ProjectTypeModel,
  EmailTemplateModel,
  DocumentTemplateModel,
  OrganizationModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInputComponent from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Switch from '@shared/design/components/Switch';
import ProjectTypeCategory from '@shared/modules/Project/enums/ProjectTypeCategory';
import {
  UserFlowExpirationUnitType,
  convertUserFlowExpirationUnitTypeToLabel,
} from '@shared/modules/UserFlows/enums/UserFlowExpirationUnitType';
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';
import DocumentFlowSteps from 'modules/Organization/Settings/DocumentFlows/DocumentFlow/components/DocumentFlowSteps';

export type DocumentFlowFieldsFragmentType = {
  id: number;
  projectTypes: ProjectTypeModel[];
  sortedEmailTemplates: EmailTemplateModel[];
  documentTemplates: DocumentTemplateModel[];
};

const Column = Styled.View``;
const Row = Styled.View`
    flex-direction: row;
`;

const toEmailTemplateOptions = (emailTemplates: EmailTemplateModel[]) => {
  return emailTemplates.map((emailTemplate) => ({
    label: emailTemplate.name,
    value: emailTemplate.id,
  }));
};

const toProjectTypeOptions = (projectTypes: ProjectTypeModel[]) => {
  return projectTypes.map((projectType) => ({
    label: projectType.name,
    value: projectType.id,
  }));
};

const toUserFlowExpirationUnitTypeOptions = () => {
  return Object.values(UserFlowExpirationUnitType).map((unitType) => ({
    label: convertUserFlowExpirationUnitTypeToLabel(unitType),
    value: unitType,
  }));
};

interface DocumentFlowFieldsProps {
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
  organization: OrganizationModel;
}

const DocumentFlowSettings = ({
  form,
  field,
  organization: {projectTypesForCategory, sortedEmailTemplates},
}: DocumentFlowFieldsProps) => {
  const projectTypeIds = _.get(form.values, `${field}.projectTypeIds`) as string[];
  const isExpirationEnabled = !!_.get(form.values, `${field}.expirationUnitType`);

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={`${field}.name`}
        label={'Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        name={`${field}.description`}
        label={'Description'}
        isResponsive
        input={{
          placeholder: 'Enter description',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        name={`${field}.defaultEmailTemplateId`}
        errorName={`${field}.defaultEmailTemplateId`}
        label={'Default Email Template'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          options: toEmailTemplateOptions(sortedEmailTemplates),
          placeholder: `Select default email template`,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          noOptionsMessage: '',
        }}
      />
      <Space height={16} />
      <Switch
        isOn={isExpirationEnabled}
        onChange={() =>
          form.setFieldValue(
            `${field}.expirationUnitType`,
            isExpirationEnabled ? undefined : UserFlowExpirationUnitType.DAYS,
          )
        }
        labelRight={'Set Expiration'}
        hint={
          'When enabled, the flow link will expire after a specified timeframe. You can override this when preparing this flow.'
        }
        isResponsive
      />
      {isExpirationEnabled && (
        <React.Fragment>
          <Space height={8} />
          <Row>
            <Space width={32} />
            <FieldInput
              {...form}
              index={3}
              name={`${field}.expirationValue`}
              label={'Expiration Timeframe'}
              style={{flex: 1}}
              isResponsive
              isRequired
              isNumeric
              input={{
                placeholder: 'Enter number',
                keyboardType: 'numeric',
              }}
            />
            <Space width={8} />
            <FieldInput
              {...form}
              index={4}
              name={`${field}.expirationUnitType`}
              label={''}
              isResponsive
              component={DropdownInput}
              input={{
                options: toUserFlowExpirationUnitTypeOptions(),
                value: _.get(form.values, `${field}.expirationUnitType`),
                style: {width: '104px'},
                setFieldValue: form.setFieldValue,
                placeholder: 'Select a project',
                isPortaled: true,
              }}
            />
          </Row>
        </React.Fragment>
      )}
      <Space height={16} />
      <Switch
        isOn={_.get(form.values, `${field}.shouldIncludePdfs`)}
        onChange={() =>
          form.setFieldValue(
            `${field}.shouldIncludePdfs`,
            !_.get(form.values, `${field}.shouldIncludePdfs`),
          )
        }
        labelRight={'Attach Documents as PDFs'}
        hint={
          'When enabled, all documents in this flow will attach as PDFs to the email sent to the customer.'
        }
        isResponsive
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={5}
        label={'Project Types'}
        isResponsive
        isRequired
        component={MultiDropdownCheckboxInputComponent}
        name={`${field}.projectTypeIds`}
        input={{
          value: projectTypeIds,
          options: toProjectTypeOptions(projectTypesForCategory),
          setFieldValue: form.setFieldValue,
          isEnabledSelectAll: true,
          placeholder: `Select project types`,
          style: {flex: 1},
          noOptionsMessage: '',
          isResponsive: true,
          isPortaled: true,
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={_.get(form.values, `${field}.isDefault`)}
        handleToggle={() =>
          form.setFieldValue(`${field}.isDefault`, !_.get(form.values, `${field}.isDefault`))
        }
        label={'Assign to all new project types'}
        hint={
          'When selected, this document flow will apply to all new project types created in the future.'
        }
        isResponsive
      />
    </React.Fragment>
  );
};

const CaptionText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.red.warning};
`;

const DocumentFlowFields = ({form, field, organization}: DocumentFlowFieldsProps) => {
  const responsive = useResponsive();

  const userFlowId = _.get(form.values, `${field}.userFlowId`);
  const stepsField = `${field}.userFlowStepForms`;
  const stepsErrorMessage = _.get(form.errors, stepsField);
  return (
    <Column>
      <ActionPanel
        index={0}
        title={'Settings'}
        BodyComponent={DocumentFlowSettings}
        bodyComponentProps={{form, field, organization}}
        bodyStyle={{maxWidth: '440px'}}
      />
      <Space height={24} />
      <React.Fragment>
        <ActionPanel
          index={1}
          title={'Documents'}
          style={stepsErrorMessage ? {borderColor: colors.red.warning} : {}}
          BodyComponent={DocumentFlowSteps}
          bodyComponentProps={{
            userFlowId,
            form,
            field: stepsField,
            organization,
          }}
          bodyStyle={{maxWidth: '488px'}}
        />
        {stepsErrorMessage && (
          <React.Fragment>
            <Space height={8} />
            <Row>
              <Icon
                source={Icon.Ban}
                size={12}
                color={colors.red.warning}
                style={{alignSelf: 'baseline'}}
              />
              <Space width={4} />
              <CaptionText responsive={responsive}>{stepsErrorMessage}</CaptionText>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    </Column>
  );
};

DocumentFlowFields.fragment = gql`
  ${DocumentFlowSteps.fragment}
  fragment DocumentFlowFields on Organization {
    id
    projectTypesForCategory(category: "${ProjectTypeCategory.MOVE}") {
      id
      name
    }
    sortedEmailTemplates {
      id
      name
    }
    ...DocumentFlowSteps
  }
`;

export default DocumentFlowFields;
