// Libraries
import React from 'react';

// Components
import {Lifecycle, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

const ViewJobMutation = ({jobId, userId, children}: any) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      jobId,
      userId,
    }}
    mutation={ViewJobMutation.mutation}
    onCompleted={({response: {jobUser, errors}}: any) => {
      // NOTE(mark): Nothing to do when this succeeds or fails, just continue.
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => (
      <Lifecycle onMount={() => handleSubmit()}>{children}</Lifecycle>
    )}
  </Mutation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewJobMutation.mutation = gql`
  mutation ViewJobMutation(
    $jobId: Int!,
    $userId: Int!,
  ) {
    response: viewJob(
      jobId: $jobId,
      userId: $userId,
    ) {
      ${gql.errors}
      jobUser {
        id
        jobId
        userId
      }
    }
  }
`;

export default ViewJobMutation;
