/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Modal, Space, Styled, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useQuery} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import BillTipForm from '@shared/modules/Billing/forms/BillTipForm';
import useCreateBillTipMutation from '@shared/modules/Billing/hooks/useCreateBillTipMutation';
import Field from 'modules/App/components/Field';
import BillTipInput from 'modules/Project/Billing/BillTip/components/BillTipInput';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? 320 : 392)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const InstructionText = Styled.H6`
  color: ${colors.black};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const TextInput = Styled.TextInput.H7`
`;

const ActivityIndicator = Styled.Loading`
`;

const Header = () => {
  return (
    <React.Fragment>
      <TitleText>Add Tip</TitleText>
      <Space height={20} />
      <InstructionText>
        {`Please enter a tip amount to be paid out to the movers. ` +
          `This tip will be added to the grand total.`}
      </InstructionText>
    </React.Fragment>
  );
};

const TipAmountInput = ({form}: any) => {
  return (
    <Field
      {...form}
      name={'billTipForm.amount'}
      label={'Tip Amount'}
      component={CurrencyInput}
      input={{
        component: TextInput,
        placeholder: 'Enter a tip amount',
        setFieldValue: form.setFieldValue,
        setFieldTouched: form.setFieldTouched,
      }}
    />
  );
};

const TipNameInput = ({form}: any) => {
  return (
    <Field
      {...form}
      name={'billTipForm.name'}
      label={'Tip Name'}
      component={TextInput}
      input={{
        placeholder: 'Enter a tip name',
      }}
    />
  );
};

const Footer = ({handleClose, handleSubmit, submitting}: any) => {
  return (
    <Actions>
      <Button color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </Button>
      <Space width={8} />
      <Button
        color={submitting ? colors.gray.border : colors.blue.interactive}
        onPress={handleSubmit}
        disabled={submitting}
      >
        {submitting ? (
          <ActivityIndicator color={colors.white} size={'small'} />
        ) : (
          <ButtonText color={colors.white}>Confirm</ButtonText>
        )}
      </Button>
    </Actions>
  );
};

const AddBillTipModal = ({handleClose, isOpen, refetch, billId, projectUuid}: any) => {
  const responsive = useResponsive();
  // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
  const billTipForm = BillTipForm.new({billId, name: '', amount: ''});

  const {data, loading} = useQuery(AddBillTipModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });

  const {form, submitting, handleSubmit} = useCreateBillTipMutation({
    billTipForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <Header />
          <Loading loading={loading || !data}>
            {() => (
              <React.Fragment>
                <Space height={16} />
                {data.project.organization.features.isEnabledJobLevelTips && (
                  <React.Fragment>
                    <BillTipInput form={form} field={'billTipForm'} project={data.project} />
                    <Space height={16} />
                  </React.Fragment>
                )}
                <TipNameInput form={form} />
                <Space height={16} />
                <TipAmountInput form={form} />
                <Space height={16} />
                <Footer
                  handleClose={handleClose}
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                />
              </React.Fragment>
            )}
          </Loading>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};
// --------------------------------------------------
// Data
// --------------------------------------------------
AddBillTipModal.query = gql`
  ${BillTipInput.fragment}

  query AddBillTipModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
        features {
          isEnabledJobLevelTips: isEnabled(feature: "JOB_LEVEL_TIPS")
        }
      }
      ...BillTipInput
    }
  }
`;

export default AddBillTipModal;
