/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useNavigationDOM, usePopover, useSidebar} from '@supermove/hooks';
import {Job, Project, JobUser} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import Line from 'modules/App/components/Line';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';
import JobDispatchPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchPopover';
import CELL_STYLE from 'modules/Dispatch/Calendar/Day/constants/CELL_STYLE';
import RIGHT_PANEL_QUERIES from 'modules/Dispatch/Calendar/Day/constants/RIGHT_PANEL_QUERIES';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import JobArrivalTime from 'modules/Job/JobCard/components/JobArrivalTime';
import JobCrewCounts from 'modules/Job/JobCard/components/JobCrewCounts';
import JobDispatchNotes from 'modules/Job/JobCard/components/JobDispatchNotes';
import JobDispatchStatus from 'modules/Job/JobCard/components/JobDispatchStatus';
import JobDistance from 'modules/Job/JobCard/components/JobDistance';
import JobEstimatedRangeBadge from 'modules/Job/JobCard/components/JobEstimatedRangeBadge';
import JobInventoryVolume from 'modules/Job/JobCard/components/JobInventoryVolume';
import JobInventoryWeight from 'modules/Job/JobCard/components/JobInventoryWeight';
import JobLocations from 'modules/Job/JobCard/components/JobLocations';
import JobNameBadge from 'modules/Job/JobCard/components/JobNameBadge';
import JobProjectComments from 'modules/Job/JobCard/components/JobProjectComments';
import CreateCrewsForJobPopoverContent from 'modules/Job/V2/Move/components/CreateCrewsForJobPopoverContent';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import JobCancelModal from 'modules/Job/components/JobCancelModal';

const BoldText = Styled.H8`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
  line-height: 14;
`;

const Container = Styled.View`
`;

const JobContentWrapper = Styled.ButtonV2`
  flex: 1;
  padding-vertical: 1px;
  overflow: hidden;
`;

const CardBackground = Styled.View`
  background-color: ${colors.white};
  height: ${({
    // @ts-expect-error TS(2339): Property 'height' does not exist on type 'ThemePro... Remove this comment to see the full error message
    height,
  }) => height};
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  margin-right: 1px;
`;

const JobInfo = Styled.View`
  position: relative;
  flex: ${(props) => (props as any).numberOfHours};
  flex-direction: row;
  height: ${(props) => (props as any).height};
  background-color: ${(props) => ((props as any).isPending ? colors.white : colors.alpha((props as any).color, 0.03))};
  border-top-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-right-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-bottom-width: ${(props) => ((props as any).isPending ? '1px' : '0px')};
  border-color: ${(props) => (props as any).color}
  border-style: dashed;
  border-radius: 4px;
  align-items: center;
`;

const JobLeftStrip = Styled.View`
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: ${(props) => (props as any).color};
  width: 4px;
  align-self: stretch;
`;

const JobInfoRow = Styled.View`
  margin-horizontal: 4px;
  flex-direction: row;
  align-items: center;
`;

const TopContentContainer = Styled.View`
`;

const AddLaborSourceButton = Styled.Button`
  height: 16px;
  padding-horizontal: 6px;
  border-radius: 2px;
  box-shadow: none;
`;

const CheckBackground = Styled.View`
  background-color: ${colors.green.status};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`;

const JobInfoShadowCover = Styled.View`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${colors.black};
  opacity: 0.2;
`;

const JobCardExpansionContainer = Styled.View`
  position: absolute;
  top: 0px;
  left: -30px;
  width: 30px;
  height: 42px;
  flex: 1;
`;

const GoToJobIconButton = Styled.ButtonV2`
  height: 26px;
  width: 26px;
  border-radius: 14px;
  justify-content: center;
  align-items: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isVisible' does not exist on type 'Theme... Remove this comment to see the full error message
    isVisible,
  }) => (isVisible ? colors.alpha(colors.gray.primary, 0.9) : 'transparent')}
`;

const getCardHeight = ({isSlot, isSecondary, job}: any) => {
  if (!isSlot) {
    return null;
  }
  return CELL_STYLE.getCardHeight({
    isPrimary: !isSecondary,
  });
};

const getCardWidth = ({
  numberOfHours,
  jobCardHover,
  jobDispatchPopover,
  jobDispatchNotesPopover,
  isExpanded,
}: any) => {
  // This function allows small job cards, job cards expanding less than 8 hours, to expand
  // to the width of 8 hours on hover. This is to allow users to quickly be able to see the
  // information that gets cut off when job cards on the dispatch calendar are small.
  if (
    numberOfHours < 8 &&
    isExpanded &&
    (jobCardHover.isHovered || jobDispatchPopover.isOpen || jobDispatchNotesPopover.isOpen)
  ) {
    return 8;
  }
  return numberOfHours;
};

const JobTitleRow = ({job}: any) => {
  const {params} = useNavigationDOM();
  return (
    <JobInfoRow>
      <JobDispatchStatus job={job} />
      <Space width={4} />
      {Job.hasEstimatedRangeJobCounter(job) && (
        <React.Fragment>
          <JobEstimatedRangeBadge job={job} date={params.date} />
          <Space width={4} />
        </React.Fragment>
      )}
      <JobNameBadge job={job} />
      <Space width={4} />
      <Space style={{flexShrink: 1}}>
        <BoldText numberOfLines={1}>{job.project.client.name}</BoldText>
      </Space>
      <Space width={4} />
      <JobArrivalTime job={job} />
    </JobInfoRow>
  );
};

const JobDispatchNotesRow = ({job, jobDispatchNotesPopover}: any) => {
  return (
    <JobInfoRow>
      <JobDispatchNotes job={job} popover={jobDispatchNotesPopover} numberOfLines={1} />
    </JobInfoRow>
  );
};

const JobLogisticsRow = ({job}: any) => {
  return (
    <JobInfoRow>
      <JobInventoryWeight job={job} />
      <Space width={8} />
      <JobInventoryVolume job={job} />
      <Space width={8} />
      <JobDistance job={job} />
      <Space width={8} />
      <JobLocations job={job} direction={'row'} />
    </JobInfoRow>
  );
};

const AssignButton = ({isSlot, isFullyAssigned, isAssignable, assignCrewPopover}: any) => {
  if (isSlot && isFullyAssigned) {
    return (
      <CheckBackground>
        <Icon color={colors.white} size={Icon.Sizes.ExtraSmall} source={Icon.Check} />
      </CheckBackground>
    );
  }
  if (isAssignable) {
    return (
      <Popover.Content innerRef={assignCrewPopover.ref}>
        <AddLaborSourceButton
          onPress={assignCrewPopover.handleToggle}
          selected={assignCrewPopover.isOpen}
        >
          <BoldText color={colors.white}>Assign</BoldText>
        </AddLaborSourceButton>
      </Popover.Content>
    );
  }
  return null;
};

const JobCrewInformationRow = ({
  job,
  crewUsersCount,
  crewTrucksCount,
  isSlot,
  isAssignable,
  isFullyAssigned,
  assignCrewPopover,
  getTooltipText,
}: any) => {
  return (
    <JobInfoRow>
      <JobCrewCounts
        job={job}
        crewUsersCount={crewUsersCount}
        crewTrucksCount={crewTrucksCount}
        getTooltipText={getTooltipText}
        showNamesWithStatuses
        showTruckCountInline
      />
      <Space width={8} />
      <Space style={{flex: 1}} />
    </JobInfoRow>
  );
};

const JobProjectCommentsRow = ({
  job,
  isSlot,
  isFullyAssigned,
  isAssignable,
  assignCrewPopover,
}: any) => {
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  return (
    <React.Fragment>
      <Line />
      <Space height={12} />
      <JobInfoRow>
        <JobProjectComments job={job} sidebar={projectCommentsSidebar} />
        <Space style={{flex: 1}} />
        <AssignButton
          isSlot={isSlot}
          isFullyAssigned={isFullyAssigned}
          isAssignable={isAssignable}
          assignCrewPopover={assignCrewPopover}
        />
      </JobInfoRow>
      <Space height={2} />
    </React.Fragment>
  );
};

const JobInfoContent = ({
  numberOfHours,
  isSlot,
  isAssignable,
  isFullyAssigned,
  organizationId,
  crewId,
  job,
  crewUsersCount,
  crewTrucksCount,
  canAssignOrganization,
  isSecondary,
  refetch,
}: any) => {
  const {isExpanded} = useDayCalendarDispatchViewContext();
  const {navigator} = useNavigationDOM();
  const projectColor = job.project.projectType.color;
  const jobDispatchPopover = usePopover();
  const jobDispatchNotesPopover = usePopover();
  const jobJobUsersScheduleModal = useModal();
  const assignCrewPopover = usePopover();
  const jobCancelModal = useModal();
  const jobCardHover = useHover();
  const jobCardExpansionHover = useHover();
  const goToJobPage = () => navigator.push(`/jobs/${job.uuid}`);

  const handleOpenModal = jobJobUsersScheduleModal.handleOpen;
  jobJobUsersScheduleModal.handleOpen = () => {
    handleOpenModal();
    // TODO(dan) Close popover until popovers are able to hide behind modals.
    jobDispatchPopover.handleClose();
  };
  const projectStatus = Project.getSalesStatus(job.project);
  const showExpansion = isSlot && (jobCardHover.isHovered || jobCardExpansionHover.isHovered);
  const cardHeight = getCardHeight({isSlot, isSecondary, job});
  const cardWidth = getCardWidth({
    numberOfHours,
    jobCardHover,
    jobDispatchPopover,
    jobDispatchNotesPopover,
    isExpanded,
  });

  return (
    <React.Fragment>
      <CardBackground
        style={isSlot ? {flex: cardWidth} : null}
        ref={jobCardHover.ref}
        height={cardHeight}
      >
        <Popover.Content innerRef={jobDispatchPopover.ref}>
          <JobInfo
            // @ts-expect-error TS(2769): No overload matches this call.
            numberOfHours={numberOfHours}
            color={projectColor}
            height={cardHeight}
            isPending={projectStatus === 'LEAD' || projectStatus === 'HOLD'}
          >
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <JobLeftStrip color={projectColor} />
            <JobContentWrapper onPress={jobDispatchPopover.handleOpen}>
              <TopContentContainer>
                <Space height={12} />
                <JobTitleRow job={job} />
                {!isSecondary && (
                  <React.Fragment>
                    <Space height={4} />
                    <JobLogisticsRow job={job} />
                    <Space height={4} />
                    <JobDispatchNotesRow
                      job={job}
                      jobDispatchNotesPopover={jobDispatchNotesPopover}
                    />
                  </React.Fragment>
                )}
                <Space height={4} />
              </TopContentContainer>
              <ScrollView horizontal>
                <Container>
                  <JobCrewInformationRow
                    job={job}
                    crewUsersCount={crewUsersCount}
                    crewTrucksCount={crewTrucksCount}
                    isSlot={isSlot}
                    isAssignable={isAssignable}
                    isFullyAssigned={isFullyAssigned}
                    assignCrewPopover={assignCrewPopover}
                    getTooltipText={JobUser.getTooltipStatus}
                  />
                  <Space height={12} />
                </Container>
              </ScrollView>
              {!isSecondary && (
                <JobProjectCommentsRow
                  job={job}
                  isSlot={isSlot}
                  isFullyAssigned={isFullyAssigned}
                  isAssignable={isAssignable}
                  assignCrewPopover={assignCrewPopover}
                />
              )}
              <Space height={12} />
            </JobContentWrapper>
            {isSecondary && <JobInfoShadowCover />}
          </JobInfo>
        </Popover.Content>
        <JobCardExpansionContainer ref={jobCardExpansionHover.ref}>
          <GoToJobIconButton isVisible={showExpansion} onPress={goToJobPage}>
            <Icon
              source={Icon.ExternalLinkAlt}
              size={13}
              color={showExpansion ? colors.white : 'transparent'}
            />
          </GoToJobIconButton>
        </JobCardExpansionContainer>
      </CardBackground>
      <JobDispatchPopover
        key={jobDispatchPopover.isOpen}
        job={job}
        crewId={crewId}
        organizationId={organizationId}
        isSlot={isSlot}
        isOpen={jobDispatchPopover.isOpen}
        handleOpen={jobDispatchPopover.handleOpen}
        handleClose={jobDispatchPopover.handleClose}
        popoverRef={jobDispatchPopover.ref}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        jobCancelModal={jobCancelModal}
        refetch={refetch}
      />
      <JobJobUsersScheduleModal
        jobUuid={job.uuid}
        isOpen={jobJobUsersScheduleModal.isOpen}
        handleClose={() => {
          refetch();
          jobJobUsersScheduleModal.handleClose();
        }}
      />
      <JobCancelModal
        jobId={job.id}
        isOpen={jobCancelModal.isOpen}
        handleClose={() => {
          refetch();
          jobCancelModal.handleClose();
        }}
      />
      {job.organization.hasMultipleOrganizations && canAssignOrganization ? (
        <ResponsivePopover
          key={`ASSIGN_CREW_POPOVER-${assignCrewPopover.isOpen}`}
          placement={Popover.Positions.Auto}
          isOpen={assignCrewPopover.isOpen}
          handleOpen={assignCrewPopover.handleOpen}
          handleClose={assignCrewPopover.handleClose}
          reference={assignCrewPopover.ref}
          offset={[0, 4]}
        >
          <ResponsivePopover.Container width={320}>
            <CreateCrewsForJobPopoverContent
              job={job}
              handleClose={assignCrewPopover.handleClose}
              refetch={refetch}
            />
          </ResponsivePopover.Container>
        </ResponsivePopover>
      ) : (
        <CrewTrucksPopover
          isOpen={assignCrewPopover.isOpen}
          handleOpen={assignCrewPopover.handleOpen}
          handleClose={assignCrewPopover.handleClose}
          popoverRef={assignCrewPopover.ref}
          crewId={crewId}
          refetch={refetch}
          placement={Popover.Positions.BottomEnd}
          refetchQueries={RIGHT_PANEL_QUERIES}
        />
      )}
      <JobDispatchNotesPopover
        job={job}
        popover={jobDispatchNotesPopover}
        placement={Popover.Positions.Left}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobInfoContent.fragment = gql`
  ${CreateCrewsForJobPopoverContent.fragment}
  ${JobArrivalTime.fragment}
  ${JobCrewCounts.fragment}
  ${JobEstimatedRangeBadge.fragment}
  ${JobDispatchNotes.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${JobDispatchPopover.fragment}
  ${JobDispatchStatus.fragment}
  ${JobDistance.fragment}
  ${JobInventoryVolume.fragment}
  ${JobInventoryWeight.fragment}
  ${JobLocations.fragment}
  ${JobNameBadge.fragment}
  ${JobProjectComments.fragment}
  ${Job.hasEstimatedRangeJobCounter.fragment}
  ${Project.getSalesStatus.fragment}

  fragment JobInfoContent on Job {
    id
    uuid
    organization {
      id
      hasMultipleOrganizations
    }
    project {
      id
      projectType {
        id
        color
      }
      client {
        id
        name
      }
      ...Project_getSalesStatus
    }
    ...CreateCrewsForJobPopoverContent
    ...JobArrivalTime
    ...JobCrewCounts
    ...JobEstimatedRangeBadge
    ...JobDispatchNotes
    ...JobDispatchNotesPopover
    ...JobDispatchPopover
    ...JobDispatchStatus
    ...JobDistance
    ...JobInventoryVolume
    ...JobInventoryWeight
    ...JobLocations
    ...JobNameBadge
    ...JobProjectComments
    ...Job_hasEstimatedRangeJobCounter
  }
`;

export default JobInfoContent;
