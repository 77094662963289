// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteCommercialEquipmentTypeMutation from '@shared/modules/CommercialCatalog/hooks/useDeleteCommercialEquipmentTypeMutation';

const DeleteCommercialEquipmentTypeModal = ({
  isOpen,
  commercialEquipmentType,
  handleClose,
  refetch,
}: any) => {
  const {submitting, handleSubmit} = useDeleteCommercialEquipmentTypeMutation({
    commercialEquipmentTypeId: commercialEquipmentType.id,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${commercialEquipmentType.name}?`}
      subtitle={`Are you sure you would like to delete ${commercialEquipmentType.name}?`}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteCommercialEquipmentTypeModal.fragment = gql`
  fragment DeleteCommercialEquipmentTypeModal on CommercialEquipmentType {
    id
    name
  }
`;

export default DeleteCommercialEquipmentTypeModal;
