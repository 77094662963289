// Libraries
import _ from 'lodash';
import React from 'react';

// Components
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

import SalesPayableItemItem from './SalesPayableItemItem';
import SalesPayableItemsListHeader from './SalesPayableItemsListHeader';

const Cell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const deletePayableItem = ({index: deleteIndex, form}: any) => {
  const {
    payableUserDayForm: {payableItemForms},
  } = form.values;

  form.setFieldValue(
    'payableUserDayForm.payableItemForms',
    _.filter(payableItemForms, (payableItemForm, index) => index !== deleteIndex),
  );
};

const SalesPayableItemsList = ({userId, payableItems, payableItemForms, form}: any) => (
  <FlatList
    initialNumToRender={20}
    data={payableItems}
    extraData={form}
    keyExtractor={(payableItem: any, index: any) => String(index)}
    renderItem={({item: payableItem, index}: any) => (
      <SalesPayableItemItem
        isFirst={index === 0}
        isLast={index === payableItems.length - 1}
        index={index}
        payableItem={payableItem}
        form={form}
        onDelete={() => deletePayableItem({index, form})}
      />
    )}
    CellRendererComponent={Cell}
    ListHeaderComponent={() => <SalesPayableItemsListHeader />}
  />
);

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableItemsList.fragment = gql`
  ${SalesPayableItemItem.fragment}

  fragment SalesPayableItemsList on PayableItem {
    ...SalesPayableItemItem
  }
`;

export default SalesPayableItemsList;
