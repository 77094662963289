// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Link, Icon, Popover, PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  usePopover,
  useNavigationDOM,
  useResponsive,
  useDrawer,
  useModal,
  ResponsiveType,
} from '@supermove/hooks';
import {Project, Tag, JobModel, ProjectModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency, Datetime, List, Phone, pluralize, titleize} from '@supermove/utils';

// App
import DateFieldComponent from '@shared/design/components/Field/DateField';
import IconButton from '@shared/design/components/IconButton';
import Table from '@shared/design/components/Table';
import ProjectStatus, {ProjectStatusType} from '@shared/modules/Project/enums/ProjectStatus';
import ProjectFollowUpDateForm from '@shared/modules/Project/forms/ProjectFollowUpDateForm';
import useUpdateProjectFollowUpDateMutation from '@shared/modules/Project/hooks/useUpdateProjectFollowUpDateMutation';
import SendEmailForProjectModal from 'modules/Email/Project/components/SendEmailForProjectModal';
import ProjectCommentsButton from 'modules/Project/components/ProjectCommentsButton';
import ProjectSalesNotesPopover from 'modules/Project/components/ProjectSalesNotesPopover';
import TagEmojiButton from 'modules/Tag/components/TagEmojiButton';
import UpsertProjectTagModal from 'modules/Tag/components/UpsertProjectTagModal';

const Container = Styled.View`
  background-color: ${colors.white}
`;

const FollowUpdateDateContainer = Styled.View`
  flex: 1;
`;

const PhoneNumberButton = Styled.ButtonV2`
`;

const getDisplayLaborSource = ({
  project,
  isFilteringCancelledProjects,
}: {
  project: ProjectModel;
  isFilteringCancelledProjects?: boolean;
}) => {
  const jobs = isFilteringCancelledProjects ? project.jobs : project.activeJobs;
  const crewOrganizationNames = _.uniq(
    _.flatten(
      jobs.map((job: JobModel) => {
        return job.crews.map((crew: any) => {
          return crew.organization.name;
        });
      }),
    ),
  );
  return crewOrganizationNames.join(', ');
};

const ContactActionIcons = ({project}: {project: ProjectModel}) => {
  const {phoneNumber, email} = project.client.primaryContact;
  const sendEmailForProjectModal = useModal({name: 'Send Email From Projects List Modal'});

  return (
    <React.Fragment>
      <Link to={`/clients/${project.client.uuid}`}>
        <IconButton source={'user'} isSecondary isHoverDistinct tooltip={'Go to client profile.'} />
      </Link>
      <Space width={8} />
      <PhoneNumberButton>
        <a href={phoneNumber ? Phone.createUrl(phoneNumber) : undefined}>
          <IconButton
            source={'phone-alt'}
            isSecondary
            isDisabled={!phoneNumber}
            isDisplayOnly
            isHoverDistinct
            tooltip={
              phoneNumber
                ? `Call ${Phone.display(phoneNumber)}`
                : 'No phone number available for this contact.'
            }
            isEnabledMobileToastTooltip
          />
        </a>
      </PhoneNumberButton>
      <Space width={8} />
      <PreventPropagation>
        <IconButton
          source={'envelope'}
          isSecondary
          onPress={sendEmailForProjectModal.handleOpen}
          isDisabled={!email}
          isHoverDistinct
          tooltip={email ? `Email ${email}` : 'No email available for this contact.'}
          isEnabledMobileToastTooltip
        />
      </PreventPropagation>
      <PreventPropagation>
        <SendEmailForProjectModal
          key={sendEmailForProjectModal.key}
          project={project}
          isOpen={sendEmailForProjectModal.isOpen}
          handleClose={sendEmailForProjectModal.handleClose}
          emailTemplateUuid={null}
        />
      </PreventPropagation>
    </React.Fragment>
  );
};

const PROJECT_IDENTIFIER = {
  headerLabel: 'Project ID',
  cellText: (project: ProjectModel) => `Project ${project.identifier}`,
  cellTextLink: (project: ProjectModel) => `/projects/${project.uuid}`,
};
const CLIENT_CONTACT_ACTIONS = {
  headerLabel: 'Contact',
  cellComponent: (project: ProjectModel) => <ContactActionIcons project={project} />,
};
const getProjectsListColumns = ({
  refetch,
  projectStatus,
  isEnabledProjectDetailsFollowUp,
  isFilteredByFollowUpDate,
  isEnabledMovesListMultiBranchSupport,
  isPrimaryOrganization,
  isFilteringCancelledProjects,
  responsive,
}: {
  refetch: () => void;
  projectStatus: ProjectStatusType;
  isEnabledProjectDetailsFollowUp: boolean;
  isFilteredByFollowUpDate: boolean;
  isEnabledMovesListMultiBranchSupport: boolean;
  isPrimaryOrganization?: boolean;
  isFilteringCancelledProjects?: boolean;
  responsive: ResponsiveType;
}) => {
  return [
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Tags',
      cellComponent: (project: ProjectModel) => {
        return <ProjectTagButton project={project} refetch={refetch} />;
      },
      secondary: {
        headerLabel: 'Sales Status',
        cellText: (project: ProjectModel) => titleize(project.salesStatus.replace('_', ' ')),
        isHidden: ProjectStatus.typeGuard(projectStatus, {
          isProjectStatuses: [ProjectStatus.BOOKED, ProjectStatus.COMPLETED],
        }),
      },
      mobileOptions: {
        rank: 0,
      },
    },
    {
      flex: 1,
      minWidth: 88,
      isHidden: projectStatus !== ProjectStatus.LEAD,
      headerLabel: 'Created At',
      cellText: (project: ProjectModel) =>
        Datetime.convertToDisplayDatetime(project.createdAt, Datetime.DISPLAY_SHORT_DATE),
      secondary: {
        cellText: (project: ProjectModel) =>
          Datetime.convertToDisplayDatetime(project.createdAt, Datetime.DISPLAY_TIME),
      },
      mobileOptions: {
        rank: 1,
      },
    },
    {
      flex: 1,
      minWidth: 88,
      isHidden: !isEnabledProjectDetailsFollowUp,
      headerLabel: 'Follow Up Date',
      cellComponent: (project: ProjectModel) => {
        return (
          <PreventPropagation>
            <FollowUpDatePicker
              project={project}
              refetch={refetch}
              isFilteredByFollowUpDate={isFilteredByFollowUpDate}
            />
          </PreventPropagation>
        );
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Project Name',
      cellTextLink: (project: ProjectModel) => `/projects/${project.uuid}`,
      cellText: (project: ProjectModel) =>
        responsive.desktop ? project.name : Project.getDisplayText(project),
      ...(responsive.desktop
        ? {
            secondary: PROJECT_IDENTIFIER,
          }
        : {}),
      mobileOptions: {
        isInHeader: true,
        rank: 0,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Client Name',
      cellText: (project: ProjectModel) => project.client.name,
      ...(responsive.desktop
        ? {
            secondary: CLIENT_CONTACT_ACTIONS,
          }
        : {}),
      mobileOptions: {
        isInHeader: true,
        rank: 1,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Project Type',
      cellText: (project: ProjectModel) => project.projectType.name,
      cellIcon: () => Icon.Circle,
      cellIconColor: (project: ProjectModel) => project.projectType.color,
      cellIconSize: () => 8,
      secondary: {
        headerLabel: 'Labor Source',
        cellText: (project: ProjectModel) =>
          getDisplayLaborSource({project, isFilteringCancelledProjects}),
        isHidden: !isEnabledMovesListMultiBranchSupport || !isPrimaryOrganization,
      },
      mobileOptions: {
        isInHeader: true,
        rank: 3,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Dates',
      numberOfLines: 2,
      cellText: (project: ProjectModel) => {
        const {startDate, endDate} = project;
        if (!startDate || !endDate) {
          return null;
        }

        const displayStartDate = Datetime.convertToDisplayDate(
          startDate,
          Datetime.DISPLAY_SHORT_DATE,
        );
        const displayEndDate = Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);

        if (startDate === endDate) {
          return displayStartDate;
        } else {
          return `${displayStartDate} - ${displayEndDate}`;
        }
      },
      ...(responsive.desktop ? {secondary: {headerLabel: '(Start - End)'}} : {}),
      mobileOptions: {
        isInHeader: true,
        rank: 2,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Project Size',
      cellText: (project: ProjectModel) => project.size,
      secondary: {
        headerLabel: 'Inventory Type',
        cellText: (project: ProjectModel) => project.inventory.surveyMethod?.name,
      },
    },
    {
      flex: 1,
      minWidth: 88,
      headerLabel: 'Price',
      cellText: (project: ProjectModel) =>
        project.totalRevenue ? Currency.format({value: project.totalRevenue}) : null,
      secondary: {
        headerLabel: 'Weight',
        cellText: (project: ProjectModel) =>
          project.weight ? pluralize('lb', project.weight, true) : null,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Salesperson',
      cellText: (project: ProjectModel) => project.bookedBy?.fullName,
      secondary: {
        headerLabel: 'Coordinator',
        cellText: (project: ProjectModel) => project.coordinatedBy?.fullName,
      },
    },
    {
      flex: 1,
      minWidth: 102,
      headerLabel: 'Referral Source',
      cellText: (project: ProjectModel) => project.referralSource,
      secondary: {
        headerLabel: 'Referral Details',
        cellText: (project: ProjectModel) => project.referralDetails,
      },
    },
    {
      flex: 1,
      minWidth: 88,
      headerLabel: 'Sales Notes',
      cellComponent: (project: ProjectModel) => {
        return <SalesNotesButton project={project} />;
      },
      secondary: {
        headerLabel: 'Comments',
        cellComponent: (project: ProjectModel) => {
          return <ProjectsListCommentsButton project={project} />;
        },
      },
    },
    ...List.insertIf(!responsive.desktop, {
      ...CLIENT_CONTACT_ACTIONS,
      mobileOptions: {
        rank: 4,
      },
    }),
    ...List.insertIf(!responsive.desktop, {
      ...PROJECT_IDENTIFIER,
      mobileOptions: {
        rank: 3,
      },
    }),
  ];
};

const ProjectTagButton = ({project, refetch}: {project: ProjectModel; refetch: () => void}) => {
  const upsertProjectTagModal = useModal({name: 'Upsert Project Tag Modal', enableTracking: true});
  const tags = Tag.getProjectTagAndTagAssignment(project);
  const tagIds = tags.map((tag: any) => tag.id);

  return (
    <PreventPropagation isMobileDisabled>
      <TagEmojiButton
        tags={tags}
        numberOfVisibleSelections={4}
        upsertTagModal={upsertProjectTagModal}
      />
      <UpsertProjectTagModal
        key={upsertProjectTagModal.key}
        tagIds={tagIds}
        projectId={project.id}
        projectUuid={project.uuid}
        refetch={refetch}
        isOpen={upsertProjectTagModal.isOpen}
        handleClose={upsertProjectTagModal.handleClose}
      />
    </PreventPropagation>
  );
};

const SalesNotesButton = ({project}: {project: ProjectModel}) => {
  const salesNotesPopover = usePopover();
  return (
    <PreventPropagation isMobileDisabled>
      <Popover.Content innerRef={salesNotesPopover.ref}>
        <IconButton
          source={'note-sticky'}
          isSecondary
          onPress={salesNotesPopover.handleToggle}
          isHoverDistinct
        />
      </Popover.Content>
      <ProjectSalesNotesPopover
        project={project}
        popover={salesNotesPopover}
        placement={Popover.Positions.Left}
      />
    </PreventPropagation>
  );
};

const ProjectsListCommentsButton = ({project}: {project: ProjectModel}) => {
  const projectCommentsDrawer = useDrawer({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  return (
    <PreventPropagation>
      <ProjectCommentsButton project={project} sidebar={projectCommentsDrawer} style={{}} />
    </PreventPropagation>
  );
};

const FollowUpDatePicker = ({
  project,
  refetch,
  isFilteredByFollowUpDate,
}: {
  project: ProjectModel;
  refetch: () => void;
  isFilteredByFollowUpDate: boolean;
}) => {
  const projectFollowUpDateForm = ProjectFollowUpDateForm.edit(project);
  const {form, handleSubmit} = useUpdateProjectFollowUpDateMutation({
    projectFollowUpDateForm,
    onSuccess: () => {
      if (isFilteredByFollowUpDate) {
        refetch();
      }
    },
    onError: () => {},
  });

  return (
    <FollowUpdateDateContainer>
      <DateFieldComponent.Small
        form={form}
        field={`projectFollowUpDateForm.followUpDate`}
        onChangeDate={(name: string, followUpDate: string) => {
          form.setFieldValue(name, followUpDate);
          setTimeout(() => handleSubmit(), 0);
        }}
        isPortaled
      />
    </FollowUpdateDateContainer>
  );
};

const MoveProjectsTable = ({
  refetch,
  projects,
  projectStatus,
  hasError,
  isEnabledProjectDetailsFollowUp,
  isFilteredByFollowUpDate,
  isEnabledMovesListMultiBranchSupport,
  isPrimaryOrganization,
  isFilteringCancelledProjects,
  isLoading,
}: {
  refetch: () => void;
  projects: ProjectModel[];
  projectStatus: ProjectStatusType;
  hasError: boolean;
  isEnabledProjectDetailsFollowUp: boolean;
  isFilteredByFollowUpDate: boolean;
  isEnabledMovesListMultiBranchSupport: boolean;
  isPrimaryOrganization?: boolean;
  isFilteringCancelledProjects?: boolean;
  isLoading?: boolean;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  return (
    <Container data-test-id='projects-list'>
      <Table
        key={navigator.location.pathname}
        columnDefinitions={getProjectsListColumns({
          refetch,
          projectStatus,
          isEnabledProjectDetailsFollowUp,
          isFilteredByFollowUpDate,
          isEnabledMovesListMultiBranchSupport,
          isPrimaryOrganization,
          isFilteringCancelledProjects,
          responsive,
        })}
        items={projects as any}
        emptyStateText={'No projects to display'}
        isLoading={isLoading}
        onRowPress={(project) => navigator.push(`/projects/${project.uuid}`)}
        hasError={hasError}
        minWidth={1300}
        horizontalScrollViewStyle={{paddingLeft: 16, paddingRight: 16}}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectsTable.fragment = gql`
  ${Project.getDisplayText.fragment}
  ${ProjectSalesNotesPopover.fragment}
  ${ProjectCommentsButton.fragment}
  ${Tag.getProjectTagAndTagAssignment.fragment}
  ${SendEmailForProjectModal.fragment}
  ${ProjectFollowUpDateForm.edit.fragment}

  fragment MoveProjectsTable on Project {
    id
    uuid
    name
    identifier
    createdAt
    salesStatus
    startDate
    endDate
    weight
    size
    totalRevenue
    referralSource
    referralDetails
    client {
      id
      uuid
      name
      primaryContact {
        id
        phoneNumber
        email
      }
    }
    bookedBy {
      id
      fullName
    }
    coordinatedBy {
      id
      fullName
    }
    projectType {
      id
      name
      color
    }
    inventory {
      id
      surveyMethod {
        id
        name
      }
    }
    activeJobs {
      id
      crews {
        id
        organization {
          id
          name
        }
      }
    }
    jobs {
      id
      crews {
        id
        organization {
          id
          name
        }
      }
    }
    ...Tag_getProjectTagAndTagAssignment
    ...ProjectSalesNotesPopover
    ...ProjectCommentsButton
    ...SendEmailForProjectModal
    ...Project_getDisplayText
    ...ProjectFollowUpDateForm_edit
  }
`;

export default MoveProjectsTable;
