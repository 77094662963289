// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// These are effectively the text color
const BLUE = colors.blue.interactive;
const GREEN = colors.green.status;

// Padding reduced by 1 because of border-width
const Button = Styled.ButtonV2`
  padding-vertical: 3px
  padding-horizontal: 7px;
  border-radius: 4px;
  border-width: 1px;
`;

const Text = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const getButtonStyle = ({color, isOn}: any) => {
  if (isOn) {
    return {
      backgroundColor: colors.getBackgroundColor(color),
      borderColor: colors.getBackgroundColor(color),
    };
  }

  return {
    backgroundColor: colors.gray.background,
    borderColor: colors.gray.border,
  };
};

const getTextColor = ({color, isOn}: any) => {
  if (isOn) {
    return color;
  }

  return colors.gray.secondary;
};

type OwnToggleBadgeProps = {
  label: string;
  color: string;
  isOn?: boolean;
  onPress?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'ToggleBadgeProps' circularly reference... Remove this comment to see the full error message
type ToggleBadgeProps = OwnToggleBadgeProps & typeof ToggleBadge.defaultProps;

// @ts-expect-error TS(7022): 'ToggleBadge' implicitly has type 'any' because it... Remove this comment to see the full error message
const ToggleBadge = ({label, color, isOn, onPress}: ToggleBadgeProps) => {
  const responsive = useResponsive();
  return (
    <Button style={getButtonStyle({color, isOn})} onPress={onPress} disabled={!onPress}>
      <Text responsive={responsive} color={getTextColor({color, isOn})}>
        {label}
      </Text>
    </Button>
  );
};

ToggleBadge.COLORS = {
  BLUE,
  GREEN,
};

ToggleBadge.defaultProps = {
  isOn: false,
  onPress: null,
};

export default ToggleBadge;
