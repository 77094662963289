// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  align-self: stretch;
`;

const Section = Styled.View`
`;

const Location = Styled.View`
`;

const Label = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const BoldText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Value = Styled.H6`
  color: ${colors.gray.primary};
`;

const SmallText = Styled.H7`
  color: ${colors.gray.primary};
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CommercialHeadingInformationNorCal.defaultProps;

// @ts-expect-error TS(7022): 'CommercialHeadingInformationNorCal' implicitly ha... Remove this comment to see the full error message
const CommercialHeadingInformationNorCal = ({job}: Props) => (
  <Container>
    <Section>
      <Label>B\L</Label>
      <BoldText>{job.name || `${job.project.identifier} ${job.identifier}`}</BoldText>
      <Value>{Datetime.convertToDisplayDate(job.day.value)}</Value>
      <Value>{Datetime.convertToDisplayTime(job.startTime1)}</Value>
      <SmallText style={{marginTop: 10}}>{`Shipper: ${job.project.name}`}</SmallText>
      <SmallText>{`Salesperson: ${job.bookedBy?.firstName ?? 'None'}`}</SmallText>
    </Section>
    <Section style={{marginTop: 10}}>
      <Label>Locations</Label>
      {job.locations.map((location: any, index: any) => (
        <Location key={location.id} style={{marginTop: 10}}>
          <SmallText>{`#${index + 1}: ${location.address}`}</SmallText>
          <SmallText>
            {`Contact: ${location.customer ? location.customer.firstName : 'None'}`}
          </SmallText>
          <SmallText>{`Instructions: ${location.notes || 'None'}`}</SmallText>
        </Location>
      ))}
    </Section>
  </Container>
);

CommercialHeadingInformationNorCal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialHeadingInformationNorCal.fragment = gql`
  fragment CommercialHeadingInformationNorCal on Job {
    id
    name
    startTime1
    identifier
    bookedBy {
      id
      firstName
    }
    day {
      id
      value
    }
    locations {
      id
      address
      notes
      customer {
        id
        firstName
      }
    }
    project {
      id
      identifier
      name
    }
  }
`;

export default CommercialHeadingInformationNorCal;
