// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Button = Styled.ButtonV2`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isHovered' does not exist on type 'Theme... Remove this comment to see the full error message
    isHovered,
  }) => (isHovered ? colors.gray.border : 'transparent')};
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isHovered' does not exist on type 'Theme... Remove this comment to see the full error message
    isHovered,
  }) => (isHovered ? colors.gray.background : 'transparent')};
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'hasTags' does not exist on type 'ThemePr... Remove this comment to see the full error message
    hasTags,
  }) => (hasTags ? 2 : 4)}px;
  padding-horizontal: 4px;
  margin-left: -6px;
`;

const EmojiContainer = Styled.View`
  background-color: ${colors.gray.background};
  height: 20px;
  width: 20px;
  border-radius: 10px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  align-items: center;
  justify-content: center;
  margin-right: ${({
    // @ts-expect-error TS(2339): Property 'isLast' does not exist on type 'ThemePro... Remove this comment to see the full error message
    isLast,
  }) => (isLast ? 0 : -8)}px;
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TruncateText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const getHoverText = ({tags}: any) => {
  if (_.isEmpty(tags)) {
    return '';
  }

  const hoverText = tags.map((tag: any) => `${tag.emoji} ${tag.name}`).join('\n');

  return hoverText;
};

const getIsLastEmoji = ({tags, index, numberOfVisibleSelections}: any) => {
  return tags.length <= numberOfVisibleSelections
    ? index === tags.length - 1
    : index === numberOfVisibleSelections - 1;
};

type OwnTagEmojiButtonProps = {
  tags: any[];
  numberOfVisibleSelections?: number;
  upsertTagModal: any;
};

// @ts-expect-error TS(2456): Type alias 'TagEmojiButtonProps' circularly refere... Remove this comment to see the full error message
type TagEmojiButtonProps = OwnTagEmojiButtonProps & typeof TagEmojiButton.defaultProps;

// TODO(Kevin): create a new Tag model and use that instead
// Fetch tags from tagAssignmentBy* and passed into component
// With a combined list -> see ProjectTags
// @ts-expect-error TS(7022): 'TagEmojiButton' implicitly has type 'any' because... Remove this comment to see the full error message
const TagEmojiButton = ({tags, numberOfVisibleSelections, upsertTagModal}: TagEmojiButtonProps) => {
  const {ref, isHovered} = useHover();
  const responsive = useResponsive();
  const hasTags = _.some(tags);

  return (
    <Button
      onPress={upsertTagModal.handleOpen}
      ref={ref}
      isHovered={isHovered && responsive.desktop}
      hasTags={hasTags}
    >
      {hasTags ? (
        <TextTooltip isEnabledMobileToast={false} text={getHoverText({tags})}>
          <Container>
            {tags.map((tag: any, index: any) => {
              if (index >= numberOfVisibleSelections) {
                return null;
              }
              return (
                <EmojiContainer
                  // @ts-expect-error TS(2769): No overload matches this call.
                  index={index}
                  key={index}
                  isLast={getIsLastEmoji({tags, index, numberOfVisibleSelections})}
                >
                  <BodyText responsive={responsive}>{tag.emoji}</BodyText>
                </EmojiContainer>
              );
            })}
            {tags.length > numberOfVisibleSelections && (
              <React.Fragment>
                <Space width={4} />
                <TruncateText
                  responsive={responsive}
                  color={isHovered ? colors.blue.hover : colors.gray.secondary}
                >{`+${tags.length - numberOfVisibleSelections}`}</TruncateText>
              </React.Fragment>
            )}
          </Container>
        </TextTooltip>
      ) : (
        <Icon
          source={Icon.Plus}
          color={isHovered ? colors.blue.hover : colors.blue.interactive}
          size={16}
        />
      )}
    </Button>
  );
};

TagEmojiButton.defaultProps = {
  numberOfVisibleSelections: 2,
};

export default TagEmojiButton;
