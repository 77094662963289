// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import DeleteWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/DeleteWarehouseModal';
import EditWarehouseModal from 'modules/Organization/Settings/Storage/Warehouses/components/EditWarehouseModal';

const MenuOptionButton = Styled.ButtonV2`
  background-color: ${(props) => ((props as any).isHovered ? colors.hover : 'transparent')};
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
`;

const ActionMenuItem = ({onPress, children, submitting}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <MenuOptionButton
        onPress={onPress}
        isHovered={isHovered}
        ref={ref}
        activeOpacity={0.8}
        disabled={submitting}
      >
        <MenuItemText>{children}</MenuItemText>
      </MenuOptionButton>
      <Space height={2} />
    </React.Fragment>
  );
};

const WarehouseActionsPopover = ({
  warehouseActionsPopover,
  deleteWarehouseModal,
  warehouse,
  userId,
  refetch,
}: any) => {
  const handleOpenDeleteWarehouseModal = () => {
    deleteWarehouseModal.handleOpen();
    warehouseActionsPopover.handleClose();
  };
  return (
    <Popover
      placement={Popover.Positions.LeftStart}
      isOpen={warehouseActionsPopover.isOpen}
      handleOpen={warehouseActionsPopover.handleOpen}
      handleClose={warehouseActionsPopover.handleClose}
      reference={warehouseActionsPopover.ref}
    >
      <ResponsivePopover.StaticContainer width={200}>
        <Space height={12} />
        <ActionMenuItem onPress={handleOpenDeleteWarehouseModal}>Remove warehouse</ActionMenuItem>
        <DeleteWarehouseModal
          deleteWarehouseModal={deleteWarehouseModal}
          handleClose={deleteWarehouseModal.handleClose}
          warehouse={warehouse}
          userId={userId}
          refetch={refetch}
        />
        <Space height={12} />
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const WarehouseActionsButton = ({organization, warehouse, userId, refetch}: any) => {
  const editWarehouseModal = useModal({name: 'EditWarehouseModal'});
  const warehouseActionsPopover = usePopover();
  const deleteWarehouseModal = useModal({name: 'DeleteWarehouseModal'});

  return (
    <React.Fragment>
      <IconButton onPress={editWarehouseModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <Popover.Content innerRef={warehouseActionsPopover.ref}>
        <IconButton onPress={warehouseActionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={warehouseActionsPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
            size={16}
          />
        </IconButton>
      </Popover.Content>
      <WarehouseActionsPopover
        key={`WAREHOUSE_ACTIONS_POPOVER-${warehouseActionsPopover.isOpen}`}
        warehouseActionsPopover={warehouseActionsPopover}
        deleteWarehouseModal={deleteWarehouseModal}
        warehouse={warehouse}
        userId={userId}
        refetch={refetch}
      />
      <EditWarehouseModal
        key={editWarehouseModal.key}
        isOpen={editWarehouseModal.isOpen}
        handleClose={editWarehouseModal.handleClose}
        organization={organization}
        warehouse={warehouse}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WarehouseActionsButton.fragment = gql`
  ${DeleteWarehouseModal.fragment}
  ${EditWarehouseModal.fragment}

  fragment WarehouseActionsButton on Warehouse {
    id
    name
    organizationId
    ...DeleteWarehouseModal
  }

  fragment WarehouseActionsButton_Organization on Organization {
    id
    ...EditWarehouseModal
  }
`;

export default WarehouseActionsButton;
