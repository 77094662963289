// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Text = Styled.H6`
  margin-right: 5px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => vars.color};
`;

const Touchable = Styled.Touchable`
`;

type OwnProps = {
  isFinal: boolean;
  onPress?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FinalLock.defaultProps;

// @ts-expect-error TS(7022): 'FinalLock' implicitly has type 'any' because it d... Remove this comment to see the full error message
const FinalLock = ({isFinal, onPress}: Props) => {
  const responsive = useResponsive();

  return (
    <Container>
      {isFinal ? (
        <Touchable
          onPress={onPress}
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Text vars={{color: colors.green.status}}>
            {responsive.mobile ? `Unlock to edit` : `Press the lock to make changes`}
          </Text>
          <Icon
            color={colors.green.status}
            size={Icon.Sizes.Large}
            source={Icon.Lock}
            style={{marginBottom: 5}}
          />
        </Touchable>
      ) : (
        <React.Fragment>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Text vars={{color: colors.red.warning}}>
            {responsive.mobile ? `Changes allowed` : `Changes are allowed`}
          </Text>
          <Icon
            color={colors.red.warning}
            size={Icon.Sizes.Large}
            source={Icon.Unlock}
            style={{marginBottom: 5}}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

FinalLock.defaultProps = {
  onPress: null,
};

export default FinalLock;
