// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useResponsive, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import CrewDispatchSection from 'modules/Job/V2/Move/components/CrewDispatchSection';

const Container = Styled.View`
`;

const CrewContainer = Styled.View`
  background-color: ${(props) => ((props as any).isHighlighted ? colors.gray.background : 'transparent')};
`;

const ListItemHeader = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 8px;
`;

const Title = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const CountText = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const PrimaryBadgeContainer = Styled.View`
  background-color: ${colors.orange.status};
  padding-horizontal: 8px;
  padding-top: 3px;
  padding-bottom: 2px;
  border-radius: 20px;
`;

const PrimaryBadgeText = Styled.H8`
  color: ${colors.white};
  ${fontWeight(700)}
`;

const ExpandButton = Styled.Button`
  background-color: ${colors.gray.border};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  align-items: center;
  box-shadow: none;
`;

const PrimaryBadge = () => {
  return (
    <PrimaryBadgeContainer>
      <PrimaryBadgeText>Primary</PrimaryBadgeText>
    </PrimaryBadgeContainer>
  );
};

const CrewHeader = ({crew, isExpanded, setIsExpanded}: any) => {
  return (
    <ListItemHeader>
      <Title>{crew.organization.name}</Title>
      <Space width={8} />
      <Icon source={Icon.Truck} size={13} color={colors.gray.primary} />
      <Space width={4} />
      <CountText>{`${crew.numberOfAssignedTrucks}/${crew.numberOfRequiredTrucks}`}</CountText>
      <Space width={8} />
      <Icon source={Icon.User} size={13} color={colors.gray.primary} style={{marginTop: -1}} />
      <Space width={4} />
      <CountText>{`${crew.jobUsers.length}/${crew.numberOfRequiredMovers}`}</CountText>
      <Space width={8} />
      {crew.isPrimary && (
        <React.Fragment>
          <PrimaryBadge />
          <Space width={8} />
        </React.Fragment>
      )}
      <ExpandButton onPress={() => setIsExpanded(!isExpanded)}>
        <Icon
          source={isExpanded ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.gray.primary}
          size={11}
        />
      </ExpandButton>
    </ListItemHeader>
  );
};

const CrewListItem = ({crew, showTrucks, showMovers, refetch}: any) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const {ref, isHovered} = useHover();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <CrewContainer isHighlighted={isHovered} ref={ref}>
      <CrewHeader crew={crew} isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
      {isExpanded && (
        <CrewDispatchSection
          crew={crew}
          showTrucks={showTrucks}
          showMovers={showMovers}
          refetch={refetch}
        />
      )}
    </CrewContainer>
  );
};

const JobDispatchCrewsList = ({job, viewer, showTrucks, showMovers, refetch}: any) => {
  const responsive = useResponsive();

  // Only the primary organization shows all crews
  const crews = viewer.viewingOrganization.isPrimary
    ? job.crews
    : job.crews.filter((crew: any) => crew.organization.id === viewer.viewingOrganization.id);

  const crewsSorted = _.sortBy(
    crews,
    (crew) => !crew.isPrimary,
    (crew) => crew.organization.name,
  );

  return (
    <Container {...responsive}>
      {crewsSorted.map((crew) => {
        return (
          <CrewListItem
            crew={crew}
            refetch={refetch}
            showTrucks={showTrucks}
            showMovers={showMovers}
            key={crew.id}
          />
        );
      })}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchCrewsList.fragment = gql`
  ${CrewDispatchSection.fragment}

  fragment JobDispatchCrewsList on Job {
    id
    crews {
      id
      isPrimary
      numberOfAssignedTrucks
      numberOfRequiredTrucks
      numberOfRequiredMovers
      jobUsers {
        id
      }
      organization {
        id
        name
      }
      ...CrewDispatchSection
    }
  }

  fragment JobDispatchCrewsList_Viewer on User {
    id
    viewingOrganization {
      id
      isPrimary
    }
  }
`;

export default JobDispatchCrewsList;
