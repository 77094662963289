// Libraries
import React from 'react';

// Components
import AssignUserToJobMutation from './AssignUserToJobMutation';

type OwnProps = {
  job: any;
  user: any;
  onSuccess: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AssignUserToJobButton.defaultProps;

// @ts-expect-error TS(7022): 'AssignUserToJobButton' implicitly has type 'any' ... Remove this comment to see the full error message
const AssignUserToJobButton = ({children, job, user, onSuccess}: Props) => (
  <AssignUserToJobMutation job={job} user={user} onSuccess={onSuccess}>
    {({loading, handleSubmit, ...props}: any) => children({loading, handleSubmit, ...props})}
  </AssignUserToJobMutation>
);

AssignUserToJobButton.defaultProps = {};

export default AssignUserToJobButton;
