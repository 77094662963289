/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

// App
import ReportMoveMoveUsersTipList from './ReportMoveMoveUsersTipList';

const Container = Styled.View`
`;

const Section = Styled.View`
`;

const SectionSpace = Styled.View`
  margin-top: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.H6`
  ${fontWeight(700)}
`;

const Value = Styled.H6`
  ${fontWeight(700)}
`;

type OwnProps = {
  reportMove: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ReportMoveMoveUsersTip.defaultProps;

// @ts-expect-error TS(7022): 'ReportMoveMoveUsersTip' implicitly has type 'any'... Remove this comment to see the full error message
const ReportMoveMoveUsersTip = ({reportMove}: Props) => (
  <Container>
    <Section>
      <Row>
        <Title>Crew Member</Title>
        <Value>Tip</Value>
      </Row>
    </Section>
    <SectionSpace />
    <Section>
      <ReportMoveMoveUsersTipList moveUsers={reportMove.moveUsers} />
    </Section>
  </Container>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportMoveMoveUsersTip.fragment = gql`
  ${ReportMoveMoveUsersTipList.fragment}

  fragment ReportMoveMoveUsersTip on ReportMove {
    id
    moveUsers {
      ...ReportMoveMoveUsersTipList
    }
  }
`;

ReportMoveMoveUsersTip.defaultProps = {};

export default ReportMoveMoveUsersTip;
