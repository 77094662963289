// Libraries
import React from 'react';

// Supermove
import {SignatureInput, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  max-width: 100%;
  width: 350px;
  height: 175px;
  border-width: 2px;
  border-style: dashed;
  border-color: ${colors.gray.border};
`;

type OwnProps = {
  innerRef: any;
  onEnd: (...args: any[]) => any;
  onSave: (...args: any[]) => any;
  style?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SignatureCapture.defaultProps;

// @ts-expect-error TS(7022): 'SignatureCapture' implicitly has type 'any' becau... Remove this comment to see the full error message
const SignatureCapture = ({innerRef, onEnd, onSave, style}: Props) => (
  <Container style={style}>
    {/* @ts-expect-error TS(2741): Property 'isEnabledAutoSave' is missing in type '{... Remove this comment to see the full error message */}
    <SignatureInput ref={innerRef} color={colors.black} onEnd={onEnd} onSave={onSave} />
  </Container>
);

SignatureCapture.defaultProps = {
  style: {},
};

export default SignatureCapture;
