// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Popover, Space, Styled} from '@supermove/components';
import {Datetime} from '@supermove/utils';

// App
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import OrganizationJobCardSample from 'modules/Dispatch/Calendar/Day/components/OrganizationJobCardSample';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const getLocationText = ({locations}: any) => {
  if (locations.length === 0) {
    return 'No Location';
  } else if (locations.length === 1) {
    return `${locations[0].city} - `;
  } else {
    const cities = locations.map((location: any) => location.city);
    return cities.join(' - ');
  }
};

const getJobEndTimeEstimate = ({jobForm}: any) => {
  if (!jobForm.startTime1 || !jobForm.estimateHours1) {
    return 'TBD';
  } else {
    const startTime = jobForm.startTime2 || jobForm.startTime1;
    const estimateMinutes = (jobForm.estimateHours2 || jobForm.estimateHours1) * 60;
    const endTime = Datetime.fromTime(startTime).add(estimateMinutes, 'minute');
    return Datetime.toDisplayTime(endTime);
  }
};

const ProjectJobDispatchPreviewPopover = ({popover, jobForm}: any) => {
  const {
    kind,
    identifier,
    customerForm,
    startTime1,
    dispatchNotes,
    locationDistances,
    locationForms,
    numberOfTrucks,
    crewSize,
  } = jobForm;
  return (
    <ResponsivePopover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.Container width={416}>
        <ContentContainer>
          <Space height={16} />
          <OrganizationJobCardSample
            jobType={kind === 'ESTIMATE' ? 'Survey' : 'Move'}
            jobIdentifier={identifier}
            customerName={_.get(customerForm, 'names.0')}
            startTime={startTime1 || 'TBD'}
            endTime={getJobEndTimeEstimate({jobForm})}
            dispatchNotes={dispatchNotes || 'Click to add a note'}
            weight={'N/A'}
            mileage={String(_.round(_.sum(locationDistances), 0))}
            locations={getLocationText({locations: locationForms})}
            jobTrucksCount={`0/${numberOfTrucks || '0'}`}
            jobUsersCount={`0/${crewSize || '0'}`}
            jobUsersNames={''}
          />
          <Space height={16} />
        </ContentContainer>
      </ResponsivePopover.Container>
    </ResponsivePopover>
  );
};

export default ProjectJobDispatchPreviewPopover;
