/*
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Icon,
  Loading,
  Popover,
  RadioInput,
  ScrollView,
  Space,
  Styled,
  Tooltip,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover, useQuery} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App

import AssignDriverToSlotForm from '@shared/modules/Dispatch/forms/AssignDriverToSlotForm';
import useAssignDriverToSlotMutation from '@shared/modules/Dispatch/hooks/useAssignDriverToSlotMutation';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const DriverRowContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => ((props as any).backgroundColor ? (props as any).backgroundColor : 'transparent')};
`;

const SlotButton = Styled.ButtonV2`
  height: 20px;
  width: 75px;
  padding-left: 6px;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props as any).color};
  border: ${(props) => ((props as any).driver ? `1px solid ${colors.gray.border}` : 'none')};
  border-radius: 4px;
`;

const SlotButtonText = Styled.H8`
  ${(props) => ((props as any).driver ? fontWeight(500) : fontWeight(700))}
  color: ${(props) => ((props as any).selected ? colors.white : (props as any).driver ? colors.black : colors.gray.primary)};
  overflow: hidden;
`;

const ContainerHeader = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 16px;
`;

const Title = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.black};
`;

const ContainerFooter = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const CancelTouchable = Styled.ButtonV2`
  background-color: transparent;
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const GrayText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.tertiary};
`;

const WhiteText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const CancelTimesTouchable = Styled.ButtonV2`
`;

const AssignButton = Styled.LoadingButton`
  background-color: ${colors.blue.interactive};
  padding-vertical: 8px;
  padding-horizontal: 16px;
`;

const DriverName = Styled.H7`
  color: ${(props) => ((props as any).disabled ? colors.gray.tertiary : colors.black)};
  ${fontWeight(500)}
`;

const DriverLabel = Styled.View`
  margin-horizontal: 6px;
  padding-horizontal: 4px;
  background-color: ${(props) => ((props as any).disabled ? colors.gray.border : colors.orange.accent)};
  border-radius: 2px;
`;

const DriverText = Styled.H8`
  color: ${(props) => ((props as any).disabled ? colors.gray.tertiary : colors.orange.status)};
`;

const Indicator = Styled.Loading`
`;

const TooltipText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(500)}
`;

const DriverRow = ({form, driver, driverIdsWithSlots}: any) => {
  const disabled = driverIdsWithSlots.includes(driver.id);
  const isSelected = form.values.assignDriverToSlotForm.driverId === driver.id;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <DriverRowContainer backgroundColor={isSelected ? colors.blue.interactive : null}>
      <Space width={10} />
      {/* @ts-expect-error TS(2739): Type '{ children: Element; disabled: any; isSelect... Remove this comment to see the full error message */}
      <RadioInput
        disabled={disabled}
        isSelected={isSelected}
        color={colors.blue.accent}
        circleColor={colors.blue.interactive}
        onSelect={() => {
          if (isSelected) {
            form.setFieldValue(`assignDriverToSlotForm.driverId`, null);
          } else {
            form.setFieldValue(`assignDriverToSlotForm.driverId`, driver.id);
          }
        }}
      >
        <Row>
          <DriverName>{driver.fullName}</DriverName>
          {driver.moverPositions.map((moverPosition: any) => (
            <React.Fragment key={moverPosition.id}>
              <DriverLabel
                key={moverPosition.id}
                // @ts-expect-error TS(2769): No overload matches this call.
                color={moverPosition.isDriver ? colors.orange.accent : colors.blue.accent}
              >
                <DriverText
                  color={moverPosition.isDriver ? colors.orange.status : colors.gray.primary}
                >
                  {moverPosition.name}
                </DriverText>
              </DriverLabel>
              <Space width={4} />
            </React.Fragment>
          ))}
        </Row>
      </RadioInput>
    </DriverRowContainer>
  );
};

const DriverSlotPopoverMutationContent = ({
  driverIdsWithSlots,
  activeDrivers,
  refetch,
  slotId,
  handleClose,
  selectedDriverId,
}: any) => {
  const assignDriverToSlotForm = AssignDriverToSlotForm.new({slotId, driverId: selectedDriverId});
  const {form, submitting, handleSubmit} = useAssignDriverToSlotMutation({
    assignDriverToSlotForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  return (
    <React.Fragment>
      <Space height={16} />
      <ContainerHeader>
        <Title>Assign Driver</Title>
        <CancelTimesTouchable onPress={handleClose}>
          <Icon color={colors.black} size={20} source={Icon.Times} />
          <Space width={4} />
        </CancelTimesTouchable>
      </ContainerHeader>
      <Space height={12} />
      <Line />
      <ScrollView>
        {activeDrivers.map((driver: any) => (
          <React.Fragment key={`${driver.id}-fragment`}>
            <DriverRow form={form} driver={driver} driverIdsWithSlots={driverIdsWithSlots} />
            <Space height={2} />
          </React.Fragment>
        ))}
      </ScrollView>
      <Space height={12} />
      <ContainerFooter>
        <CancelTouchable onPress={handleClose}>
          <GrayText>Cancel</GrayText>
        </CancelTouchable>
        <AssignButton loading={submitting} onPress={handleSubmit}>
          <WhiteText>Assign</WhiteText>
        </AssignButton>
        <Space width={16} />
      </ContainerFooter>
      <Space height={12} />
    </React.Fragment>
  );
};

const DriverSlotPopoverContent = ({
  refetch,
  isOpen,
  slotId,
  handleClose,
  selectedDriverId,
}: any) => {
  const {data} = useQuery(DriverSlotPopoverContent.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      slotId,
    },
  });
  return (
    <Loading as={Indicator} loading={!data}>
      {() => {
        const driverIdsWithSlots = _.compact(
          data.slot.day.slots
            .map((slot: any) => slot.driverId)
            .filter((driverId: any) => driverId !== data.slot.driverId),
        ).map((driverId) => (driverId as any).toString());

        const {activeDrivers} = data.slot.organization;
        return (
          <DriverSlotPopoverMutationContent
            slotId={slotId}
            refetch={refetch}
            driverIdsWithSlots={driverIdsWithSlots}
            activeDrivers={activeDrivers}
            handleClose={handleClose}
            selectedDriverId={data.slot.driverId ? data.slot.driverId.toString() : null}
          />
        );
      }}
    </Loading>
  );
};

const getButtonColor = ({isSelected, hasDriver, isTripSlot}: any) => {
  if (isTripSlot) {
    return colors.gray.disabled;
  }
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (hasDriver) {
    return colors.white;
  }
  return colors.gray.border;
};

const ButtonContent = ({handleToggle, isOpen, isHovered, driver, hoverRef, isTripSlot}: any) => {
  const hasDriver = !!driver;

  return (
    <SlotButton
      onPress={handleToggle}
      selected={isOpen}
      isHovered={isHovered}
      driver={driver}
      ref={hoverRef}
      disabled={isTripSlot}
      color={getButtonColor({isSelected: isOpen, hasDriver, isTripSlot})}
    >
      {hasDriver || isTripSlot ? (
        <Space width={2} />
      ) : (
        <React.Fragment>
          <Space width={2} />
          <Icon color={isOpen ? colors.white : colors.gray.primary} size={8} source={Icon.Plus} />
          <Space width={4} />
        </React.Fragment>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SlotButtonText numberOfLines={1} selected={isOpen} driver={driver}>
        {hasDriver ? driver.fullName : !isTripSlot && 'Driver'}
      </SlotButtonText>
      <Space width={1} />
    </SlotButton>
  );
};

const DriverSlotButton = ({slot, isTripSlot, refetch}: any) => {
  const {driver} = slot;
  const {ref, isOpen, handleOpen, handleClose, handleToggle} = usePopover();
  const {isHovered, ref: hoverRef} = useHover();
  const hasDriver = !!driver;

  return (
    <React.Fragment>
      <Popover.Content innerRef={ref}>
        {hasDriver ? (
          <Tooltip
            placement={'bottom'}
            overlay={() => <TooltipText>{driver.fullName}</TooltipText>}
            mouseEnterDelay={0.0}
            mouseLeaveDelay={0.0}
            visible={isHovered}
          >
            <ButtonContent
              slot={slot}
              handleToggle={handleToggle}
              isOpen={isOpen}
              isHovered={isHovered}
              driver={driver}
              hoverRef={hoverRef}
              isTripSlot={isTripSlot}
            />
          </Tooltip>
        ) : (
          <ButtonContent
            slot={slot}
            handleToggle={handleToggle}
            isOpen={isOpen}
            isHovered={isHovered}
            hoverRef={hoverRef}
          />
        )}
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={isOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        reference={ref}
        offset={[0, 4]}
      >
        <ResponsivePopover.Container width={280} maxHeight={320}>
          <DriverSlotPopoverContent
            refetch={refetch}
            isOpen={isOpen}
            slotId={slot.id}
            handleClose={handleClose}
          />
        </ResponsivePopover.Container>
      </Popover>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DriverSlotButton.fragment = gql`
  fragment DriverSlotButton_Slot on Slot {
    id
    organizationId
    driver {
      id
      fullName
    }
  }
`;

DriverSlotPopoverContent.query = gql`
  query DriverPopoverContent($slotId: Int!) {
    ${gql.query}
    slot(slotId: $slotId) {
      id
      driverId
      organization {
        id
        activeDrivers {
          id
          fullName
          moverPositions {
            id
            name
            isDriver
          }
        }
      }
      day {
        id
        slots {
          id
          driverId
        }
      }
    }
  }
`;

export default DriverSlotButton;
