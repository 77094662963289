// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useRef, useState, useEffect} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useUpdateProjectMutation from '@shared/modules/Project/hooks/useUpdateProjectMutation';
import {PageLoadingIndicator, Panel, SplitPage, Navigation} from 'modules/App/components';
import {JobMap} from 'modules/Job/components';
import JobFields from 'modules/Job/components/JobFields';
import {ProjectFieldsForJob} from 'modules/Project/components';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H3`
  margin-top: 20px;
  margin-horizontal: 30px;
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const ExtraSpace = Styled.View`
  height: 200px;
`;

const Footer = Styled.View`
  padding: 10px;
  background-color: ${colors.white};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.blue.accent};
`;

const SaveButton = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const SaveText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Section = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

/**
 * Gets the index for the job in a project object.
 *
 * @param {string} uuid The UUID of the job
 * @param {object} project The project object
 */
const getIndexForJobInProject = ({uuid, project}: any) => {
  return Job.sortJobsBySequence(project.activeJobs).findIndex((job) => (job as any).uuid === uuid);
};

const getIsLocationLoading = ({moveLocations}: any) => {
  return _.some(moveLocations, (moveLocation) => moveLocation.isLocationLoading);
};

const EditJobProjectPageContent = ({navigator, job, jobIndex}: any) => {
  const scrollView = useRef();
  const mapRef = useRef();
  const projectForm = ProjectForm.editV2(job.project);
  const {form, submitting, handleSubmit} = useUpdateProjectMutation({
    project: job.project,
    projectForm,
    onSuccess: () => navigator.goBack(),
    onError: () => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      scrollView.current.scrollTo({y: 0});
    },
  });

  const jobFormField = `projectForm.jobForms.${jobIndex}`;
  const {warehouseLocation} = job.project.owningOrganization;
  const moveLocations = JobForm.getLocations(_.get(form.values, jobFormField));
  const boundLocations = warehouseLocation ? [warehouseLocation, ...moveLocations] : moveLocations;
  const [isHandlingSubmit, setIsHandlingSubmit] = useState(false);

  // Code here is in place to make sure we don't submit the form until all of the location data is loaded
  useEffect(() => {
    const isLocationLoading = getIsLocationLoading({moveLocations});
    if (!isLocationLoading && isHandlingSubmit) {
      handleSubmit();
      setIsHandlingSubmit(false);
    }
  }, [isHandlingSubmit, form, handleSubmit, moveLocations]);

  return (
    <SplitPage
      split={
        <Panel>
          <JobMap
            locations={moveLocations}
            boundLocations={boundLocations}
            warehouseLocation={warehouseLocation}
            mapRef={mapRef}
          />
        </Panel>
      }
    >
      <Container>
        <ScrollView ref={scrollView} style={{flex: 1}}>
          <Title>{`Edit Job: ${job.identifier}`}</Title>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={0}>
            <ProjectFieldsForJob
              isUpdate
              field={'projectForm'}
              form={form}
              organization={job.project.organization}
              disabled={submitting}
              shouldResetJobDateOnProjectTypeChange
              jobFormField={jobFormField}
            />
          </Section>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section index={1}>
            <JobFields
              isUpdate
              field={'projectForm'}
              form={form}
              organization={job.project.organization}
              jobIndex={jobIndex}
              shouldResetJobDateOnJobTypeChange
              jobFormField={jobFormField}
              warehouseLocation={warehouseLocation}
              mapRef={mapRef}
            />
          </Section>
          <ExtraSpace />
        </ScrollView>
        <Footer>
          <SaveButton
            loading={submitting}
            onPress={() => {
              setIsHandlingSubmit(true);
            }}
          >
            <SaveText>Save</SaveText>
          </SaveButton>
        </Footer>
      </Container>
    </SplitPage>
  );
};

const EditJobProjectPage = () => {
  const {navigator, params} = useNavigationDOM();
  const {uuid} = params;
  const {loading, data} = useQuery(EditJobProjectPage.query, {
    fetchPolicy: 'network-only', // Network only so we are never editing stale data
    variables: {uuid},
  });

  return (
    <React.Fragment>
      <Navigation
        side={({responsive}: any) => (responsive.mobile ? 70 : 120)}
        title={loading ? 'Loading...' : Project.getDisplayText(data.job.project)}
        subtitle={loading ? null : `Edit move: ${data.job.identifier}`}
        left={() => <Navigation.CloseButton onPress={navigator.goBack} />}
      />
      <Loading as={PageLoadingIndicator} loading={loading}>
        {() => (
          <EditJobProjectPageContent
            navigator={navigator}
            job={data.job}
            jobIndex={getIndexForJobInProject({uuid, project: data.job.project})}
          />
        )}
      </Loading>
    </React.Fragment>
  );
};

// --------------------------------------------------
// PropTypes
// --------------------------------------------------
EditJobProjectPage.propTypes = {};

EditJobProjectPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobProjectPage.query = gql`
  ${JobFields.fragment}
  ${Project.getDisplayText.fragment}
  ${ProjectForm.editV2.fragment}
  ${ProjectFieldsForJob.fragment}
  ${Job.getLocations.fragment}
  ${useUpdateProjectMutation.fragment}

  query EditJobProjectPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      identifier
      name
      uuid
      project {
        id
        uuid
        number
        activeJobs {
          id
          uuid
        }
        owningOrganization {
          id
          warehouseLocation {
            latitude
            longitude
          }
        }
        organization {
          id
          ...JobFields
          ...ProjectFieldsForJob
        }
        ...ProjectForm_editV2
        ...Project_getDisplayText
        ...useUpdateProjectMutation
      }
      ...Job_getLocations
    }
  }
`;

export default EditJobProjectPage;
