// Libraries
import _ from 'lodash';
import qs from 'qs';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import SidebarPage from 'modules/App/components/SidebarPage';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import ReportsCalendar from 'modules/Calendar/Reports/components/ReportsCalendar';
import ReportsNavigation from 'modules/Report/components/ReportsNavigation';

const Container = Styled.View`
  flex: 1;
`;

const getSearchParams = ({location}: any) => {
  const search = _.replace(location.search, '?', '');
  return qs.parse(search);
};

const ReportsCalendarPage = () => (
  <SidebarPage selected={'reports'} query={ReportsCalendarPage.query}>
    {({responsive, navigator, data}: any) => (
      <Container data-test-id='reports-page'>
        <StandardOfficeHeader title={'Reports'} />
        <Container {...responsive}>
          <ReportsNavigation />
          <ReportsCalendar
            searchParams={getSearchParams({location: navigator.location})}
            viewer={data.viewer}
          />
        </Container>
      </Container>
    )}
  </SidebarPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ReportsCalendarPage.query = gql`
  ${ReportsCalendar.fragment}

  query ReportsCalendarPage {
    ${gql.query}
    viewer {
      id
      firstName
      ...ReportsCalendar
    }
  }
`;

export default ReportsCalendarPage;
