// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Task} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepDropdown from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepDropdown';
import WorkflowStepEditTaskBlock from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepEditTaskBlock';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const SectionLoadingContainer = Styled.View`
  align-items: center;
  justify-content: center;
  height: 170px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  margin-top: 8px;
`;

const AddTaskPropertyButtonContainer = Styled.ButtonV2`
  align-self: center;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.border};
`;

const Indicator = Styled.Loading`
`;

const handleAddTaskProperty = ({form, field}: any) => {
  const propertiesToUpdate = _.get(form.values, `${field}.propertiesToUpdate`);
  form.setFieldValue(`${field}.propertiesToUpdate`, [...propertiesToUpdate, '']);
};

const SectionLoadingIndicator = () => (
  <SectionLoadingContainer>
    <Indicator size={'small'} color={colors.gray.secondary} />
  </SectionLoadingContainer>
);

const AddTaskPropertyButton = ({form, field}: any) => {
  return (
    <AddTaskPropertyButtonContainer onPress={() => handleAddTaskProperty({form, field})}>
      <Icon source={Icon.Plus} size={12} color={colors.gray.secondary} />
    </AddTaskPropertyButtonContainer>
  );
};

const UpdateTaskPropertyFields = ({form, field, organizationId}: any) => {
  const {data, loading} = useQuery(UpdateTaskPropertyFields.query, {
    variables: {organizationId},
  });

  const propertiesToUpdate = _.get(form.values, `${field}.propertiesToUpdate`);

  return (
    <Loading loading={loading} as={SectionLoadingIndicator}>
      {() => {
        return (
          <React.Fragment>
            {propertiesToUpdate.map((property: any, index: any) => {
              const isFirstBlock = index === 0;
              const isLastBlock = propertiesToUpdate.length === index + 1;
              const isMaximumBlockCount =
                propertiesToUpdate.length ===
                Task.WORKFLOW_BUILDER_TASK_PROPERTY_DROPDOWN_OPTIONS.length;
              return (
                <React.Fragment key={index}>
                  <Space height={isFirstBlock ? 16 : 8} />
                  <WorkflowStepEditTaskBlock
                    property={property}
                    form={form}
                    field={field}
                    organization={data.organization}
                    index={index}
                  />
                  {isLastBlock && !isMaximumBlockCount && (
                    <React.Fragment>
                      <Space height={6} />
                      <AddTaskPropertyButton form={form} field={field} />
                    </React.Fragment>
                  )}
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      }}
    </Loading>
  );
};

const EditTaskSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  handleSubmitValidateWorkflow,
  organizationId,
}: any) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {label, description, actionForm} = WorkflowStepActionKind.ATTRIBUTES[actionKind];
  const field = `${workflowStepsField}.${workflowStepIndex}.${actionForm}`;
  const isDoneDisabled = !_.get(form.values, `${field}.taskIdReferencePath`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <WorkflowStepDropdown
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        accessPropertySuffix={'.id'}
        isFormula={false}
      />
      {actionKind === WorkflowStepActionKind.UPDATE_TASK && (
        <UpdateTaskPropertyFields form={form} field={field} organizationId={organizationId} />
      )}
    </WorkflowStepSlide>
  );
};

const CompleteTaskDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
}: any) => {
  const actionKind = _.get(
    form.values,
    `${workflowStepsField}.${workflowStepIndex}.workflowStepActionKind`,
  );
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {label} = WorkflowStepActionKind.ATTRIBUTES[actionKind];

  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={label}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepEditTask = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    organizationId,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => [
    <EditTaskSlide
      key={'SELECT_TASK'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
      organizationId={organizationId}
    />,
    <CompleteTaskDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      isPreview={isPreview}
    />,
  ],
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTaskPropertyFields.query = gql`
  ${WorkflowStepEditTaskBlock.fragment}
  query UpdateTaskPropertyFields($organizationId: Int!) {
    ${gql.query}
    organization(organizationId: $organizationId) {
      id
      ...WorkflowStepEditTaskBlock
    }
  }
`;

export default WorkflowStepEditTask;
