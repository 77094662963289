// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useState, useTextInput} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import EditBillLineItemCells from 'modules/Project/Billing/components/EditBillLineItemCells';

const Row = Styled.View`
  flex-direction: row;
`;

const KindLabelContainer = Styled.View`
  padding-horizontal: 8px;
  padding-top: ${(props) => ((props as any).index > 0 ? 7 : 6)}px;
`;

const DescriptionButton = Styled.Touchable`
  position: absolute;
  top: 7px;
  right: 8px;
  flex-direction: row;
  align-items: center;
`;

const swapBillItems = (form: any, field: any, index1: any, index2: any) => {
  const billItemForms = _.get(form.values, field);
  [billItemForms[index1], billItemForms[index2]] = [billItemForms[index2], billItemForms[index1]];
  form.setFieldValue(field, billItemForms);
};

const removeBillItem = (form: any, field: any, index: any, billFormField: any) => {
  const deletedBillItemFormsField = `${billFormField}.deletedBillItemForms`;
  const billItemForms = _.get(form.values, field);
  const removedBillItemForm = _.get(form.values, `${field}.${index}`);
  const updatedBillItemForms = _.remove(billItemForms, (form, i) => i !== index);
  const deletedBillItemForms = [
    ..._.get(form.values, deletedBillItemFormsField),
    removedBillItemForm,
  ];
  form.setFieldValue(field, updatedBillItemForms);
  form.setFieldValue(deletedBillItemFormsField, deletedBillItemForms);
};

const MoveItemColumn = ({form, field, index}: any) => {
  const billModiferFormsCount = _.get(form.values, field).length;
  const isFirstItem = index === 0;
  const isLastItem = index === billModiferFormsCount - 1;
  const handleMoveItemUp = () => {
    swapBillItems(form, field, index, index - 1);
  };
  const handleMoveItemDown = () => {
    swapBillItems(form, field, index, index + 1);
  };

  return (
    <EditBillLineItemCells.IconCell>
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        onPress={handleMoveItemUp}
        disabled={isFirstItem}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
      <Space height={2} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        onPress={handleMoveItemDown}
        disabled={isLastItem}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const NameColumn = ({
  form,
  field,
  billItemForm,
  index,
  handleShowDescription,
  isShowingDescription,
}: any) => {
  const nameField = `${field}.${index}.name`;
  const disabled = !!_.get(form.values, `${field}.${index}.nameFormulaId`);
  const hasError = _.get(form.errors, nameField);
  const handleChangeText = (text: any) => {
    form.setFieldValue(nameField, text);
  };
  return (
    <EditBillLineItemCells.LeftDataCell
      isLarge
      hasBorder
      hasError={hasError}
      isFirstItem={index === 0}
      disabled={disabled}
      // @ts-expect-error TS(2769): No overload matches this call.
      style={{width: null, flex: 1}}
    >
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <KindLabelContainer index={index}>
        <EditBillLineItemCells.CellCaption>
          {BillItemTypeCategory.getDisplayCategory(billItemForm.category, billItemForm)}
        </EditBillLineItemCells.CellCaption>
      </KindLabelContainer>
      <EditBillLineItemCells.NameInput
        value={billItemForm.name}
        handleChangeText={handleChangeText}
        disabled={disabled}
      />
      <DescriptionButton activeOpacity={0.8} onPress={handleShowDescription}>
        <EditBillLineItemCells.CellButtonText>Description</EditBillLineItemCells.CellButtonText>
        <Space width={4} />
        <Icon
          source={isShowingDescription ? Icon.ChevronUp : Icon.ChevronDown}
          color={colors.blue.interactive}
          size={11}
        />
      </DescriptionButton>
    </EditBillLineItemCells.LeftDataCell>
  );
};

const QuantityColumn = ({index}: any) => {
  return (
    <EditBillLineItemCells.DataCell
      // @ts-expect-error TS(2769): No overload matches this call.
      pad
      hasBorder
      isFirstItem={index === 0}
      color={colors.gray.border}
    />
  );
};

const AmountPriceInput = ({form, field, billItemForm, index, disableEditPrices}: any) => {
  const [value, setValue] = useState(billItemForm.amount);
  const [isFocused, setIsFocused] = useState(false);
  const amountField = `${field}.${index}.amount`;
  const disabled = disableEditPrices || !!billItemForm.amountFormulaId;
  const hasError = _.get(form.errors, amountField);
  const handleChangeText = (text: any) => {
    setValue(text);
    form.setFieldValue(amountField, Currency.toForm(Currency.convertToCents(text)));
  };
  return (
    <EditBillLineItemCells.RightDataCell disabled={disabled} hasBorder isFirstItem={index === 0}>
      {isFocused ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}} />
      ) : !isFocused && !_.isEmpty(billItemForm.amount) ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {billItemForm.amount}
        </EditBillLineItemCells.CellText>
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          <EditBillLineItemCells.CellCaption>Price</EditBillLineItemCells.CellCaption>
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setValue(billItemForm.amount);
          setIsFocused(false);
        }}
        onChangeText={handleChangeText}
        disabled={disabled}
        value={value}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const PercentPriceInput = ({form, field, billItemForm, index}: any) => {
  const [value, setValue] = useState(billItemForm.percentage);
  const [isFocused, setIsFocused] = useState(false);
  const percentField = `${field}.${index}.percentage`;
  const disabled = !!billItemForm.amountFormulaId;
  const hasError = _.get(form.errors, percentField);
  const handleChangeText = (text: any) => {
    setValue(text);
    form.setFieldValue(percentField, Percent.toForm(Percent.toFloat(text)));
  };

  return (
    <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={index === 0} disabled={disabled}>
      {isFocused ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}} />
      ) : !isFocused && !_.isEmpty(billItemForm.percentage) ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          {billItemForm.percentage}
        </EditBillLineItemCells.CellText>
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <EditBillLineItemCells.CellText vars={{hasPad: true}}>
          <EditBillLineItemCells.CellCaption>Percentage</EditBillLineItemCells.CellCaption>
        </EditBillLineItemCells.CellText>
      )}
      <EditBillLineItemCells.CellInput
        vars={{isFocused, hasError}}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setValue(billItemForm.percentage);
          setIsFocused(false);
        }}
        onChangeText={handleChangeText}
        value={value}
        disabled={disabled}
      />
    </EditBillLineItemCells.RightDataCell>
  );
};

const PriceColumn = ({form, field, billItemForm, index, disableEditPrices}: any) => {
  if (BillItemForm.getIsAmount(billItemForm)) {
    return (
      <AmountPriceInput
        form={form}
        field={field}
        billItemForm={billItemForm}
        index={index}
        disableEditPrices={disableEditPrices}
      />
    );
  }
  if (BillItemForm.getIsPercentage(billItemForm)) {
    return (
      <PercentPriceInput form={form} field={field} billItemForm={billItemForm} index={index} />
    );
  }
  return <EditBillLineItemCells.RightDataCell hasBorder isFirstItem={index === 0} />;
};

const DeleteItemColumn = ({form, field, index, billFormField}: any) => {
  const handleRemoveItem = () => {
    removeBillItem(form, field, index, billFormField);
  };
  return (
    <EditBillLineItemCells.IconCell>
      <Space height={3} />
      <EditBillLineItemCells.IconCircle
        activeOpacity={0.8}
        // @ts-expect-error TS(2769): No overload matches this call.
        color={colors.gray.tertiary}
        onPress={handleRemoveItem}
      >
        <Icon source={Icon.Trash} size={12} color={colors.gray.tertiary} />
      </EditBillLineItemCells.IconCircle>
    </EditBillLineItemCells.IconCell>
  );
};

const InvoiceBillBillItemsPostSubtotalListItem = ({
  form,
  field,
  billFormField,
  billItemForm,
  index,
  disableEditPrices,
}: any) => {
  const descriptionInput = useTextInput();
  const [isShowingDescription, setIsShowingDescription] = useState(!!billItemForm.description);
  const [inputHeight, setInputHeight] = useState();
  const [isUpdatingDescription, setIsUpdatingDescription] = useState(false);
  const handleUpdateForm = (text: any) => {
    form.setFieldValue(`${field}.${index}.description`, text);
  };
  const handleOnFocus = () => {
    setIsUpdatingDescription(true);
  };
  const handleOnBlur = () => {
    setIsUpdatingDescription(false);
  };

  return (
    <React.Fragment>
      <Row style={{width: '100%'}}>
        <MoveItemColumn form={form} field={field} index={index} />
        <Space width={2} />
        <NameColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          handleShowDescription={() => {
            setIsShowingDescription(true);
            descriptionInput.handleFocus();
          }}
          isShowingDescription={isShowingDescription}
        />
        <QuantityColumn index={index} />
        <PriceColumn
          form={form}
          field={field}
          billItemForm={billItemForm}
          index={index}
          disableEditPrices={disableEditPrices}
        />
        <Space width={2} />
        <DeleteItemColumn form={form} field={field} index={index} billFormField={billFormField} />
      </Row>
      {isShowingDescription && (
        <Row style={{width: '100%'}}>
          <EditBillLineItemCells.FullWidthCell
            // @ts-expect-error TS(2769): No overload matches this call.
            style={{width: null, flex: 1, marginLeft: 22, marginRight: 22}}
          >
            <EditBillLineItemCells.DescriptionInputLabel>
              Description
            </EditBillLineItemCells.DescriptionInputLabel>
            <EditBillLineItemCells.DescriptionInput
              ref={descriptionInput.ref}
              value={billItemForm.description}
              onChangeText={handleUpdateForm}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              onContentSizeChange={(event: any) =>
                setInputHeight(event.nativeEvent.contentSize.height)
              }
              height={inputHeight}
              vars={{isUpdating: isUpdatingDescription}}
              multiline
            />
          </EditBillLineItemCells.FullWidthCell>
        </Row>
      )}
    </React.Fragment>
  );
};

export default InvoiceBillBillItemsPostSubtotalListItem;
