// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';
import useUpdateTruckMutation from '@shared/modules/Dispatch/hooks/useUpdateTruckMutation';

import TruckFormFields from './TruckFormFields';

type OwnProps = {
  truck: any;
  handleClose: (...args: any[]) => any;
  refetch: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateTruckForm.defaultProps;

// @ts-expect-error TS(7022): 'UpdateTruckForm' implicitly has type 'any' becaus... Remove this comment to see the full error message
const UpdateTruckForm = ({truck, handleClose, refetch}: Props) => {
  const responsive = useResponsive();
  const truckForm = TruckForm.edit(truck);
  const {form, submitting, handleSubmit} = useUpdateTruckMutation({
    truckForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });
  return (
    <TruckFormFields
      responsive={responsive}
      isUpdate
      form={form}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};

UpdateTruckForm.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateTruckForm.fragment = gql`
  ${TruckForm.edit.fragment}
  fragment UpdateTruckForm on Truck {
    id
    ...TruckForm_edit
  }
`;

export default UpdateTruckForm;
