// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useQuery, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';

const Container = Styled.View`
  width: 100%;
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const OptionContainer = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  padding-vertical: 5px;
  padding-horizontal: 12px;
  margin-top: ${({
    // @ts-expect-error TS(2339): Property 'isFirstOption' does not exist on type 'T... Remove this comment to see the full error message
    isFirstOption,
  }) => (isFirstOption ? 4 : 2)};
`;

const OptionText = Styled.Text`
  ${Typography.Body}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  padding-vertical: 1px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getActionColor = ({isSelected, isFocused}: any) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isFocused) {
    return colors.hover;
  }
  return 'transparent';
};

const IntegrationCustomerDropdownOption = ({data, isFocused, isSelected, innerProps}: any) => {
  return (
    <OptionContainer {...innerProps} color={getActionColor({isSelected, isFocused})}>
      <Row>
        <OptionText numberOfLines={1} color={isSelected ? colors.white : colors.gray.primary}>
          {data.label}
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
      </Row>
      <Row>
        <OptionText color={isSelected ? colors.blue.accent : colors.gray.secondary}>
          {data.phone ? Phone.display(data.phone) : 'No phone number'}
        </OptionText>
        <Space style={{flex: 1, minWidth: 4}} />
        <OptionText
          numberOfLines={1}
          color={isSelected ? colors.blue.accent : colors.gray.secondary}
        >
          {data.email}
        </OptionText>
      </Row>
    </OptionContainer>
  );
};

const AccountingCustomerFieldContent = ({
  form,
  field,
  fieldIndex,
  organization,
  handleChangeSearch,
  integrationCustomers,
  loading,
  searchQuery,
}: any) => {
  const externalIntegrationSearchQuery = _.get(
    form.values,
    `${field}.externalIntegrationSearchQuery`,
  );

  const externalIntegrationCustomerId = `${field}.externalIntegrationCustomerId`;

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={fieldIndex}>
      <FieldInput
        {...form}
        component={DropdownInput}
        name={externalIntegrationCustomerId}
        label={'Accounting Customer'}
        tooltip={
          organization.activeCodatIntegration
            ? `Select the accounting customer from ${organization.activeCodatIntegration.sourceKindDisplayName} that this client will export to.`
            : null
        }
        input={{
          options: !_.isEmpty(integrationCustomers)
            ? integrationCustomers.map((externalIntegrationCustomer: any) => ({
                label: externalIntegrationCustomer.name,
                value: _.toNumber(externalIntegrationCustomer.id),
                email: externalIntegrationCustomer.email,
                phone: externalIntegrationCustomer.phone,
              }))
            : [],
          isClearable: true,
          isLoading: loading,
          isDisabled: loading,
          placeholder: 'Type to add or search',
          setFieldValue: form.setFieldValue,
          onInputChange: (text: any) => {
            if (text) {
              handleChangeSearch(text);
            }
          },
          onChangeValue: (customerId: any) => {
            if (customerId) {
              form.setFieldValue(`${field}.externalIntegrationSearchQuery`, searchQuery);
            }
            handleChangeSearch(searchQuery);
          },
          onBlur: () => {
            handleChangeSearch(externalIntegrationSearchQuery);
          },
          components: {
            Option: (props: any) => <IntegrationCustomerDropdownOption {...props} />,
          },
          additionalSearchKeys: ['email', 'phone'],
          style: {
            flex: 1,
          },
        }}
        style={{width: '100%'}}
      />
    </Container>
  );
};

const getInitialSearchQuery = ({form, field}: any) => {
  const externalIntegrationSearchQuery = _.get(
    form.values,
    `${field}.externalIntegrationSearchQuery`,
  );
  return externalIntegrationSearchQuery || '';
};

const AccountingCustomersQuery = ({form, field, children}: any) => {
  const [searchQuery, setSearchQuery] = useState(getInitialSearchQuery({form, field}));
  const handleChangeSearch = useDebouncedCallback((text) => setSearchQuery(text), 250);

  const {data, loading} = useQuery(AccountingCustomerField.query, {
    fetchPolicy: 'cache-and-network',
    skip: !searchQuery,
    variables: {
      searchQuery,
      pagination: {page: 1, resultsPerPage: 50},
    },
  });

  const integrationCustomers = _.get(
    data,
    'viewer.viewingOrganization.activeCodatIntegration.externalIntegrationCustomersPaginatedList.externalIntegrationCustomers',
    [],
  );

  return children({searchQuery, handleChangeSearch, loading, integrationCustomers});
};

const AccountingCustomerField = ({form, field, fieldIndex, organization}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={fieldIndex}>
      <AccountingCustomersQuery form={form} field={field}>
        {({searchQuery, handleChangeSearch, loading, integrationCustomers}: any) => (
          <AccountingCustomerFieldContent
            integrationCustomers={integrationCustomers}
            form={form}
            field={field}
            organization={organization}
            fieldIndex={fieldIndex}
            handleChangeSearch={handleChangeSearch}
            loading={loading}
            searchQuery={searchQuery}
          />
        )}
      </AccountingCustomersQuery>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingCustomerField.query = gql`
  query useAccountingCustomerFieldsSearch($pagination: PaginationInput!, $searchQuery: String) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        activeCodatIntegration {
          id
          externalIntegrationCustomersPaginatedList(
            pagination: $pagination
            searchQuery: $searchQuery,
          ) {
            externalIntegrationCustomers: results {
              id
              name
              email
              phone
            }
          }
        }
      }
    }
  }
`;

export default AccountingCustomerField;
