// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';
import useCreateStorageProjectMutation from '@shared/modules/Project/Storage/hooks/useCreateStorageProjectMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import StorageProjectModalFields from 'modules/Job/V2/Storage/components/StorageProjectModalFields';

const CreateStorageProjectFromMoveProjectModalContent = ({
  handleClose,
  refetch,
  project,
  isDisabledClientFields,
  createProjectSuccessModal,
  handleSuccess,
  viewingOrganization,
}: any) => {
  const {navigator} = useNavigationDOM();
  const storageProjectForm = StorageProjectForm.newFromMoveProject(project, {
    collectionIds: [],
  });
  const {form, submitting, handleSubmit} = useCreateStorageProjectMutation({
    storageProjectForm,
    onSuccess: ({project}: any) => {
      if (handleSuccess) {
        return handleSuccess(project);
      }
      navigator.replace(`${navigator.location.pathname}?new=${project.uuid}`);
      createProjectSuccessModal.handleOpen();
      handleClose();
      refetch();
    },
    // TODO(dan) Update error handling
    onError: (error: any) => console.log({error}),
  });

  const {isEnabledStorageMultibranchAssignProject} = project.organization.features;

  return (
    <React.Fragment>
      <Modal.Header style={{paddingHorizontal: 24, paddingVertical: 16}}>
        <Modal.HeaderText>{'Create Storage Project'}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body isScrollable>
        <StorageProjectModalFields
          form={form}
          field={'storageProjectForm'}
          organization={project.organization}
          isDisabledClientFields={isDisabledClientFields}
          isFixedBranch={!isEnabledStorageMultibranchAssignProject}
          isViewerOrgPrimaryMultibranch={viewingOrganization.isPrimaryMultibranch}
          isDisabledInventoryInformation
        />
      </Modal.Body>
      <Modal.Footer style={{paddingHorizontal: 16, paddingVertical: 12}}>
        <Modal.Button onPress={handleClose}>{'Cancel'}</Modal.Button>
        <Space width={8} />
        <Modal.Button
          onPress={() => {
            if (_.get(form.values, `storageProjectForm.isSameBillingClient`)) {
              // Set billing client to same value as client
              form.setFieldValue(
                `storageProjectForm.billingClientForm`,
                _.get(form.values, `storageProjectForm.clientForm`),
              );
            }
            setTimeout(() => handleSubmit(), 0);
          }}
          isSubmitting={submitting}
          color={colors.blue.interactive}
        >
          {'Create'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const CreateStorageProjectFromMoveProjectModal = ({
  isOpen,
  handleClose,
  projectUuid,
  isDisabledClientFields,
  createProjectSuccessModal,
  handleSuccess,
}: any) => {
  const {loading, data, refetch} = useQuery(CreateStorageProjectFromMoveProjectModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });

  return (
    <Modal.Responsive isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => (
          <CreateStorageProjectFromMoveProjectModalContent
            handleClose={handleClose}
            refetch={refetch}
            project={data.project}
            viewingOrganization={data.viewer.viewingOrganization}
            isDisabledClientFields={isDisabledClientFields}
            createProjectSuccessModal={createProjectSuccessModal}
            handleSuccess={handleSuccess}
          />
        )}
      </Loading>
    </Modal.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateStorageProjectFromMoveProjectModal.query = gql`
  ${StorageProjectForm.newFromMoveProject.fragment}
  ${StorageProjectModalFields.fragment}
  ${StorageProjectModalFields.organizationFragment}

  query CreateStorageProjectFromMoveProjectModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      collections {
        id
        ...StorageProjectModalFields
      }
      organization {
        id
        features {
          isEnabledStorageMultibranchAssignProject: isEnabled(
            feature: "STORAGE_MULTIBRANCH_ASSIGN_PROJECT"
          )
        }
        ...StorageProjectModalFields_organizationFragment
      }
      ...StorageProjectForm_newFromMoveProject
    }
    viewer {
      id
      viewingOrganization {
        id
        isPrimaryMultibranch
      }
    }
  }
`;

export default CreateStorageProjectFromMoveProjectModal;
