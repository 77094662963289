// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Organization} from '@supermove/models';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';

const getRelatedOrganizationsOfficeStaffOptions = ({organization, noneOptionLabel}: any) => {
  const relatedOrganizationsOfficeStaffOptions = _.flatten(
    organization.salesStaffOrganizations.map((organization: any) => {
      return Organization.getSalespersonOptionsV2(organization);
    }),
  );

  const sortedOrgs = _.sortBy(relatedOrganizationsOfficeStaffOptions, ['label']);
  if (noneOptionLabel && organization.features.isEnabledAllowEmptyBookedBy) {
    sortedOrgs.unshift({value: null, label: noneOptionLabel, description: 'All branches'});
  }
  return sortedOrgs;
};

const getSalespersonOptions = ({
  organization,
  isEnabledShowIntegrationUser,
  noneOptionLabel,
}: any) => {
  if (isEnabledShowIntegrationUser) {
    return getRelatedOrganizationsOfficeStaffOptions({organization, noneOptionLabel});
  }
  const greaterOfficeOptions =
    Organization.getGreaterOrganizationOfficeStaffDropdownOptions(organization);
  if (noneOptionLabel && organization.features.isEnabledAllowEmptyBookedBy) {
    greaterOfficeOptions.unshift({
      value: null,
      label: noneOptionLabel,
      description: 'All branches',
    });
  }
  return greaterOfficeOptions;
};

const RelatedOrganizationsStaffDropdown = ({
  organizationSlug,
  isEnabledShowIntegrationUser,
  form,
  field,
  label,
  placeholder,
  isMultiSelect,
  isDisabled,

  // Adds the current required pattern with label decorators
  isRequired,

  noneOptionLabel,

  // Adds the deprecated required pattern for the yellow input
  required,

  style,
  inputStyle,
  index,
  isResponsive,
  isPortaled,
}: any) => {
  const {data, loading} = useQuery(RelatedOrganizationsStaffDropdown.query, {
    variables: {
      organizationSlug,
      isEnabledShowIntegrationUser,
    },
  });

  return (
    <FieldInput
      {...form}
      component={isMultiSelect ? MultiDropdownCheckboxInput : DropdownInput}
      name={field}
      label={label}
      index={index}
      style={style}
      isRequired={isRequired}
      isResponsive={isResponsive}
      input={{
        isPortaled,
        disabled: isDisabled,
        isClearable: true,
        required,
        setFieldValue: form.setFieldValue,
        isLoading: loading,
        placeholder,
        options: loading
          ? []
          : getSalespersonOptions({
              organization: data.organization,
              isEnabledShowIntegrationUser,
              noneOptionLabel,
            }),
        style: inputStyle || {flex: 1},
        showDescriptionInOption: true,
        usePills: true,
        isResponsive: true,
      }}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RelatedOrganizationsStaffDropdown.query = gql`
  ${Organization.getGreaterOrganizationOfficeStaffDropdownOptions.fragment}
  ${Organization.getSalespersonOptionsV2.fragment}
  query RelatedOrganizationsStaffDropdown(
    $organizationSlug: String!
    $isEnabledShowIntegrationUser: Boolean!
  ) {
    organization(slug: $organizationSlug) {
      id
      features {
        isEnabledAllowEmptyBookedBy: isEnabled(feature: "ALLOW_EMPTY_BOOKED_BY")
      }
      salesStaffOrganizations {
        id
        ...Organization_getSalespersonOptionsV2 @include(if: $isEnabledShowIntegrationUser)
      }
      ...Organization_getGreaterOrganizationOfficeStaffDropdownOptions
        @skip(if: $isEnabledShowIntegrationUser)
    }
  }
`;

export default RelatedOrganizationsStaffDropdown;
