// Libraries
import React from 'react';

// Supermove
import {Query} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';

type OwnProps = {
  fetchPolicy?: string;
  pollInterval?: number;
  skip?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Page.defaultProps;

// @ts-expect-error TS(7022): 'Page' implicitly has type 'any' because it does n... Remove this comment to see the full error message
const Page = ({fetchPolicy, pollInterval, query, skip, variables, children}: Props) => {
  const responsive = useResponsive();
  const {navigator, params} = useNavigationDOM();

  return (
    <Query
      fetchPolicy={fetchPolicy}
      pollInterval={pollInterval}
      query={query}
      skip={skip}
      variables={variables}
    >
      {({loading, data, refetch, startPolling, stopPolling}: any) =>
        children({
          responsive,
          navigator,
          params,
          loading,
          data,
          refetch,
          startPolling,
          stopPolling,
        })
      }
    </Query>
  );
};

Page.defaultProps = {
  fetchPolicy: 'cache-and-network',
  pollInterval: 0,
  skip: false,
};

export default Page;
