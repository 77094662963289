// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// Components
import {FieldValue} from 'modules/App/components';

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}px;
`;

const Title = Styled.H5`
  margin-bottom: 10px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Column = Styled.View`
  ${(props) => ((props as any).mobile ? 'width: 100%;' : 'flex: 1;')}
`;

const RowSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 15)}px;
`;

const CustomerSection = ({index, customer, responsive}: any) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Section index={index}>
    {/* @ts-expect-error TS(2769): No overload matches this call. */}
    <Title vars={responsive}>Customer Information</Title>
    <Row {...responsive}>
      <Column {...responsive}>
        <FieldValue
          label={'Name'}
          value={User.getFullName(customer)}
          style={{
            marginTop: 3,
          }}
        />
        <RowSpace {...responsive} />
        <FieldValue
          label={'Phone Number'}
          value={Phone.display(customer.phoneNumber)}
          style={{
            marginTop: 3,
          }}
        />
        <RowSpace {...responsive} />
        <FieldValue
          label={'Email'}
          value={customer.email}
          style={{
            marginTop: 3,
          }}
        />
      </Column>
    </Row>
  </Section>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerSection.fragment = gql`
  fragment CustomerSection on User {
    id
    firstName
    lastName
    phoneNumber
    email
  }
`;

export default CustomerSection;
