const TALL = 142;
const SHORT = 54;
const WIDTH = 50;

const getCardHeight = ({isPrimary}: any) => {
  if (!isPrimary) {
    return SHORT;
  }
  return TALL;
};

const CELL_STYLE = {
  TALL,
  SHORT,
  WIDTH,
  getCardHeight,
};

export default CELL_STYLE;
