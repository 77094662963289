// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, Location, Organization, Project, User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// Components
import DocumentHeaderOrganization from 'modules/Document/components/DocumentHeaderOrganization';
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

const Section = Styled.View<{sectionIndex: number}>`
  align-self: stretch;
  padding-horizontal: 20px;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionTitle = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.primary};
`;

const SectionHeader = ({children}: any) => {
  return (
    <React.Fragment>
      <SectionTitle>{children}</SectionTitle>
      <Space height={4} />
    </React.Fragment>
  );
};

const getArrivalText = ({job}: any) => {
  if (job.startTime1) {
    return Job.getDisplayArrivalWindow(job);
  } else {
    return 'Arrival time to be determined';
  }
};

const SectionSpace = () => {
  return <Space height={20} />;
};

const CustomerSection = ({sectionIndex, project}: any) => {
  const phone = Phone.display(project.customer.phoneNumber);
  const {email} = project.customer;

  return (
    <Section sectionIndex={sectionIndex}>
      <SectionHeader>Contact Information</SectionHeader>
      <Text>{User.getFullName(project.customer)}</Text>
      <Text>{phone}</Text>
      <Text>{email}</Text>
    </Section>
  );
};

const ProjectSection = ({sectionIndex, project}: any) => {
  return (
    <Section sectionIndex={sectionIndex}>
      <SectionHeader>Project Information</SectionHeader>
      <Text>Project Name: {Project.getName(project)}</Text>
      <Text>Project Type: {project.projectType.name}</Text>
      {!!project.size && <Text>Project Size: {project.size}</Text>}
      {!!project.description && (
        <React.Fragment>
          <Text>Project Description:</Text>
          <Text>{project.description}</Text>
        </React.Fragment>
      )}
    </Section>
  );
};

const LocationJobSection = ({job}: any) => {
  const numberOfLocations = job.locations.length;

  return (
    <React.Fragment>
      <Text>Job Type: {job.jobType.name}</Text>
      <Text>Job Name: {job.name}</Text>
      <Text>Job Date: {`${Job.getDisplayDate(job, 'MM/DD/YY')}`}</Text>
      <Text>Arrival Time: {getArrivalText({job})}</Text>
      {job.locations.map((location: any, index: any) => (
        <Text key={index}>
          {`${Location.getLocationType({index, total: numberOfLocations})}: ` +
            `${Location.getDisplayLocation(location)}`}
        </Text>
      ))}
      <Space height={8} />
    </React.Fragment>
  );
};

const MoveInformationSection = ({sectionIndex, project}: any) => {
  const jobs = Job.sortJobsBySequence(project.activeJobs).filter(
    (job) => (job as any).kind !== 'ESTIMATE',
  );

  return (
    <Section sectionIndex={sectionIndex}>
      <SectionHeader>Move Information</SectionHeader>
      <Space height={8} />
      {jobs.map((job) => (
        <LocationJobSection job={job} key={(job as any).id} />
      ))}
    </Section>
  );
};

const BillSection = ({project, sectionIndex}: any) => {
  return (
    <React.Fragment>
      <Section sectionIndex={sectionIndex}>
        <SectionHeader>Billing Information</SectionHeader>
        <React.Fragment>
          <Space height={12} />
          <BillingProjectBillsListV1
            isDocument
            isProposal
            showBillRules
            showEmptyTotals={false}
            showQuantity={project.organization.features.isEnabledConfirmationShowQuantity}
            showTotal={project.organization.features.isEnabledConfirmationShowTotal}
            showGrandTotal={project.organization.features.isEnabledConfirmationShowGrandTotal}
            project={project}
            aggregateBill={project.activeJobsAggregateBill}
          />
          {project.activeJobsAggregateBill.hasPayments && (
            <React.Fragment>
              <Space height={12} />
              <BillingProjectPaymentsList aggregateBill={project.activeJobsAggregateBill} />
            </React.Fragment>
          )}
        </React.Fragment>
      </Section>
    </React.Fragment>
  );
};

const AdditionalSection = ({sectionIndex, project}: any) => {
  const sections = Organization.getQuoteLetterSections(project.organization);

  if (sections.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      <SectionSpace />
      <Section sectionIndex={sectionIndex}>
        <SectionHeader>Additional Information</SectionHeader>
        <Space height={8} />
        {sections.map(({title, body}, index) => (
          <Text key={index}>{body}</Text>
        ))}
      </Section>
    </React.Fragment>
  );
};

const CustomerTaskProjectQuoteBody = ({project}: any) => {
  return (
    <React.Fragment>
      <CustomerSection sectionIndex={4} project={project} />
      <SectionSpace />
      <ProjectSection sectionIndex={5} project={project} />
      <SectionSpace />
      <MoveInformationSection sectionIndex={6} project={project} />
      <SectionSpace />
      <BillSection sectionIndex={7} project={project} />
      <AdditionalSection sectionIndex={8} project={project} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerTaskProjectQuoteBody.fragment = gql`
  ${Job.getDisplayArrivalWindow.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.sortJobsBySequence.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Project.getName.fragment}
  ${DocumentHeaderOrganization.fragment}
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}

  fragment CustomerTaskProjectQuoteBody on Project {
    id
    description
    size
    activeJobsAggregateBill {
      hasPayments
      ...BillingProjectBillsListV1_AggregateBill
      ...BillingProjectPaymentsList_AggregateBill
    }
    projectType {
      id
      name
    }
    customer {
      id
      firstName
      lastName
      phoneNumber
      email
    }
    organization {
      id
      slug
      features {
        isEnabledConfirmationShowQuantity: isEnabled(feature: "CONFIRMATION_SHOW_QUANTITY")
        isEnabledConfirmationShowTotal: isEnabled(feature: "CONFIRMATION_SHOW_TOTAL")
        isEnabledConfirmationShowGrandTotal: isEnabled(feature: "CONFIRMATION_SHOW_GRAND_TOTAL")
      }
      ...DocumentHeaderOrganization
    }
    activeJobs {
      id
      crewSize
      kind
      name
      startTime1
      jobType {
        id
        name
      }
      day {
        id
        value
      }
      locations {
        id
        latitude
        longitude
        ...Location_getDisplayLocation
      }
      ...Job_getDisplayArrivalWindow
      ...Job_getDisplayDate
      ...Job_sortJobsBySequence
    }
    ...BillingProjectBillsListV1
    ...Project_getName
  }
`;

export default CustomerTaskProjectQuoteBody;
