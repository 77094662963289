/*
 * Component - v2.1.0
 ************
 DEPRECATED IN FAVOR OF SHARED DeleteModal DESIGN SYSTEM COMPONENT
 ************
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const DeleteModal = ({handleConfirm, handleClose, isOpen, title, subtitle}: any) => {
  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen}>
      <SmallModal.HeaderIcon source={Icon.Trash} color={colors.red.warning} label={title} />
      <Space height={8} />
      <SmallModal.Text>{subtitle}</SmallModal.Text>
      <Space height={32} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button color={colors.red.warning} onPress={handleConfirm}>
          Delete
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default DeleteModal;
