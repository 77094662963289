// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import StorageProjectForm from '@shared/modules/Project/Storage/forms/StorageProjectForm';
import useCancelStorageProjectMutation from '@shared/modules/Project/hooks/useCancelStorageProjectMutation';

const CancelStorageProjectModal = ({project, isOpen, handleClose, refetch}: any) => {
  const storageProjectForm = StorageProjectForm.cancel(project);
  const {handleSubmit} = useCancelStorageProjectMutation({
    storageProjectForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to cancel this project?'}
      deleteButtonText={'Cancel'}
      cancelButtonText={'Go Back'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CancelStorageProjectModal.fragment = gql`
  ${StorageProjectForm.cancel.fragment}

  fragment CancelStorageProjectModal on Project {
    id
    ...StorageProjectForm_cancel
  }
`;

export default CancelStorageProjectModal;
