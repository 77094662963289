// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Currency, Json, Percent} from '@supermove/utils';

const prepareFeatures = ({features}: any) => {
  return Json.stringify(Json.toUpperCaseKeys(Json.toSnakeCaseKeys(features)));
};

type OwnCreateEmployeeMutationProps = {
  values: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  setMutationErrors: (...args: any[]) => any;
  submitForm: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'CreateEmployeeMutationProps' circularl... Remove this comment to see the full error message
type CreateEmployeeMutationProps = OwnCreateEmployeeMutationProps &
  typeof CreateEmployeeMutation.defaultProps;

// @ts-expect-error TS(7022): 'CreateEmployeeMutation' implicitly has type 'any'... Remove this comment to see the full error message
const CreateEmployeeMutation = ({
  values,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: CreateEmployeeMutationProps) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      organizationId: values.organizationId,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      payrollIdentifier: values.payrollIdentifier,
      tabletPin: values.tabletPin,
      position: values.position,
      branchCode: values.branchCode,
      billingHourlyRate: Currency.toMutation(values.billingHourlyRate),
      compensationPercentage: Percent.toMutation(values.compensationPercentage),
      isDriver: values.isDriver,
      features: prepareFeatures({features: values.features}),
      moverPositionIds: values.moverPositionIds,
      primaryMoverPositionId: values.primaryMoverPositionId,
    }}
    mutation={CreateEmployeeMutation.mutation}
    onCompleted={({response: {user, errors}}: any) => {
      submitForm();
      setMutationErrors(errors);

      if (user) {
        onSuccess(user);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

CreateEmployeeMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateEmployeeMutation.mutation = gql`
  mutation CreateEmployeeMutation(
    $organizationId: Int!,
    $firstName: String!,
    $lastName: String!,
    $phoneNumber: String!,
    $payrollIdentifier: String!,
    $tabletPin: String!,
    $position: String!,
    $branchCode: String!,
    $billingHourlyRate: Int!,
    $compensationPercentage: Float!,
    $isDriver: Boolean!,
    $features: JSONString!,
    $moverPositionIds: [Int],
    $primaryMoverPositionId: Int,
  ) {
    response: createEmployee(
      organizationId: $organizationId,
      firstName: $firstName,
      lastName: $lastName,
      phoneNumber: $phoneNumber,
      payrollIdentifier: $payrollIdentifier,
      tabletPin: $tabletPin,
      position: $position,
      branchCode: $branchCode,
      billingHourlyRate: $billingHourlyRate,
      compensationPercentage: $compensationPercentage,
      isDriver: $isDriver,
      features: $features,
      moverPositionIds: $moverPositionIds,
      primaryMoverPositionId: $primaryMoverPositionId,
    ) {
      ${gql.errors}
      user {
        id
        organizationId
        firstName
        lastName
        phoneNumber
        payrollIdentifier
        tabletPin
        billingHourlyRate
        compensationPercentage
        position
        isDriver
        features
      }
    }
  }
`;

export default CreateEmployeeMutation;
