// Libraries
import _ from 'lodash';

// Supremove
import {gql} from '@supermove/graphql';
import {UserFlowModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App

export type DeleteUserFlowFormToFormType = {
  userFlowId: string | undefined;
};

const edit = withFragment(
  (userFlow: UserFlowModel) => {
    return {
      userFlowId: userFlow.id,
    };
  },
  gql`
    fragment DeleteUserFlowForm_edit on UserFlow {
      id
    }
  `,
);

const toForm = (userFlow: ReturnType<typeof edit>) => {
  return {
    userFlowId: _.toNumber(userFlow.userFlowId),
  };
};

const toMutation = (userFlowForm: ReturnType<typeof toForm>) => {
  return {
    userFlowId: userFlowForm.userFlowId,
  };
};

const DeleteUserFlowForm = {
  edit,
  toForm,
  toMutation,
};

export default DeleteUserFlowForm;
