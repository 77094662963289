// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Lifecycle, ScrollView, Space, Styled, ZoomView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import useCompleteCustomerTaskForm from 'modules/Customer/Job/Quote/logic/useCompleteCustomerTaskForm';
import ProjectPage from 'modules/Customer/Project/components/ProjectPage';
import CustomerTaskProjectQuoteUnacceptedContent from 'modules/Project/Confirmation/components/CustomerTaskProjectQuoteUnacceptedContent';

const Wrapper = Styled.View`
  flex: 1;
`;

// We use the same width on mobile and desktop to make sure the user scrolls horizontally and
// sees the entire page.
const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '722px')};
  align-self: center;
`;

const Content = Styled.View`
  align-self: stretch;
  padding-bottom: 90px;
`;

const Section = Styled.View`
  align-self: stretch;
  padding-horizontal: 20px;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 30px;
`;

const Text = Styled.H6`
  margin-bottom: 10px;
  color: ${colors.gray.secondary};
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Title = Styled.H3`
  ${fontWeight(900)}
  color: ${colors.gray.primary};
`;

const DocumentContainer = Styled.View`
  margin: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const ButtonContent = ({form}: any) => {
  if (form.loading) {
    return null;
  }

  if (form.values.isFinished) {
    return <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />;
  }

  return <ButtonText>Request Availability</ButtonText>;
};

const CUSTOMER_TASK_STEP_KIND = ConfirmationStepKind.QUOTE_STEP_ACCEPT_QUOTE;

const isCorrectPage = ({project}: any) => {
  return _.get(project, 'quote.nextStep.kind') === CUSTOMER_TASK_STEP_KIND;
};

const onMountOrUpdate = ({navigator, project}: any) => {
  if (!isCorrectPage({project})) {
    navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/quote`);
  }
};

// If the next step of the confirmation is not to sign document,
// redirect the customer to the show confirmation page.
const Redirect = ({children, navigator, project}: any) => (
  <Lifecycle onMount={() => onMountOrUpdate({navigator, project})}>
    {isCorrectPage({project}) ? children : null}
  </Lifecycle>
);

const Footer = ({project, navigator}: any) => {
  const {form, submitting, handleSubmit} = useCompleteCustomerTaskForm({
    customerTask: project.quote,
    stepKind: CUSTOMER_TASK_STEP_KIND,
    onSuccess: () => form.setFieldValue('isFinished', true),
    onError: (errors: any) => console.log({errors}),
  });
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Section sectionIndex={0}>
      <Text>{`Press the button below to let us know you are interested.`}</Text>
      <Button
        loading={submitting}
        // @ts-expect-error TS(2769): No overload matches this call.
        onPress={handleSubmit}
        onSuccess={() =>
          navigator.push(`/0/${project.organization.slug}/projects/${project.uuid}/quote`)
        }
        success={form.values.isFinished}
      >
        <ButtonContent form={form} />
      </Button>
    </Section>
  );
};

const InfoQuoteCustomerProjectPageContent = ({project, navigator}: any) => {
  const responsive = useResponsive();
  const scale = responsive.mobile ? 0.5 : 1;

  if (project.isCancelled) {
    return (
      <React.Fragment>
        <Space height={20} />
        <Title>This quote has expired</Title>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <DocumentContainer>
        <ZoomView
          defaultScale={scale}
          defaultPositionX={0}
          defaultPositionY={0}
          minScale={scale}
          centerContent={false}
          wrapperClass={responsive.mobile ? 'react-zoom-mobile-document' : ''}
        >
          <CustomerTaskProjectQuoteUnacceptedContent project={project} />
        </ZoomView>
      </DocumentContainer>
      <SectionSpace />
      <Footer project={project} navigator={navigator} />
    </React.Fragment>
  );
};

const InfoQuoteCustomerProjectPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => (
  <ProjectPage variables={{uuid}} query={InfoQuoteCustomerProjectPage.query}>
    {({responsive, navigator, data}: any) => (
      <Redirect navigator={navigator} project={data.project}>
        <Wrapper>
          <ScrollView style={{flex: 1}}>
            <Container {...responsive}>
              <Content>
                <InfoQuoteCustomerProjectPageContent project={data.project} navigator={navigator} />
              </Content>
            </Container>
          </ScrollView>
        </Wrapper>
      </Redirect>
    )}
  </ProjectPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
InfoQuoteCustomerProjectPage.query = gql`
  ${CustomerTaskProjectQuoteUnacceptedContent.fragment}
  ${ProjectPage.fragment}

  query InfoQuoteCustomerProjectPage($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      isCancelled
      quote {
        id
        isComplete
        nextStep {
          kind
          value
        }
      }
      organization {
        id
        slug
      }
      ...CustomerTaskProjectQuoteUnacceptedContent
      ...ProjectPage
    }
  }
`;

export default InfoQuoteCustomerProjectPage;
