// Libraries
import React from 'react';

// Supermove
import {Form, Mutation, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Storage} from '@supermove/sdk';
import {fontWeight, colors} from '@supermove/styles';

// Components
import SetPasswordModal from './SetPasswordModal';

const Field = Styled.View`
  margin-top: 30px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  letter-spacing: 1;
  text-transform: uppercase;
  margin-bottom: 3px;
`;

const Input = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
  max-width: 600px;
`;

const Logo = Styled.H1`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H3`
  margin-top: 30px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SetPasswordForm.defaultProps;

// @ts-expect-error TS(7022): 'SetPasswordForm' implicitly has type 'any' becaus... Remove this comment to see the full error message
const SetPasswordForm = ({user, responsive}: Props) => (
  <Form
    initialValues={{
      password: '',
      userId: user.id,
      resetPasswordToken: user.resetPasswordToken,
    }}
    onSubmit={(values: any) => {}}
  >
    {({values, errors, handleChange, setMutationErrors, submitForm, ...form}: any) => (
      <SetPasswordModal
        trigger={({handleOpen}: any) => (
          <Mutation
            // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
            variables={{
              userId: values.userId,
              password: values.password,
              resetPasswordToken: values.resetPasswordToken,
            }}
            mutation={SetPasswordForm.mutation}
            onCompleted={async ({setPasswordForUser: {token, user, errors}}: any) => {
              submitForm();
              setMutationErrors(errors);
              if (token) {
                await Storage.setItem('token', token);
                await Storage.setItem('userId', user.id);
                handleOpen();
              }
            }}
          >
            {(handleSubmit: any, {loading, error}: any) => (
              <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
                <Content {...responsive}>
                  <Logo>Supermove</Logo>
                  <Title>Set Password</Title>
                  <Subtitle>Please enter your new password.</Subtitle>
                  <Field>
                    <Label>New Password</Label>
                    <Input
                      autoFocus
                      secureTextEntry
                      name={'password'}
                      placeholder={'New Password'}
                      value={values.password}
                      onChange={handleChange}
                      onSubmitEditing={handleSubmit}
                    />
                    {errors.password && <ValidationError>{errors.password}</ValidationError>}
                  </Field>
                  <Actions>
                    <SubmitButton loading={loading} onPress={handleSubmit}>
                      <SubmitText>Set new password</SubmitText>
                    </SubmitButton>
                  </Actions>
                </Content>
              </ScrollView>
            )}
          </Mutation>
        )}
      />
    )}
  </Form>
);

SetPasswordForm.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SetPasswordForm.mutation = gql`
  mutation SetPasswordPage(
    $userId: Int!,
    $password: String!,
    $resetPasswordToken: String!,
  ) {
    setPasswordForUser(
      userId: $userId,
      password: $password,
      resetPasswordToken: $resetPasswordToken,
    ) {
      ${gql.errors}
      token
      user {
        id
      }
    }
  }
`;

export default SetPasswordForm;
