// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import Pill from 'components/Pill';

type OwnProps = {
  projectTypeDay: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProjectTypeDayEstimateItem.defaultProps;

// @ts-expect-error TS(7022): 'ProjectTypeDayEstimateItem' implicitly has type '... Remove this comment to see the full error message
const ProjectTypeDayEstimateItem = ({projectTypeDay, isMultiBranch}: Props) => {
  return (
    <Pill
      color={projectTypeDay.projectType.color}
      count={projectTypeDay.estimatesCount}
      text={
        isMultiBranch
          ? `${projectTypeDay.projectType.name} (${projectTypeDay.projectType.organization.name})`
          : projectTypeDay.projectType.name
      }
    />
  );
};

ProjectTypeDayEstimateItem.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeDayEstimateItem.fragment = gql`
  fragment ProjectTypeDayEstimateItem on ProjectTypeDay {
    id
    estimatesCount
    projectType {
      id
      color
      name
      organization {
        id
        name
      }
    }
  }
`;

export default ProjectTypeDayEstimateItem;
