// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import Sheet from '@shared/design/components/Sheet';
import Line from 'modules/App/components/Line';
import CustomerEmailThreadEmails from 'modules/Communication/components/CustomerEmailThreadEmails';
import CustomerEmailThreadItem from 'modules/Email/components/CustomerEmailThreadItem';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  overflow: auto;
`;

const LinkButton = Styled.ButtonV2`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const EmptyStateContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TitleText = Styled.Text`
  ${Typography.Responsive.Heading2}
  color: ${colors.gray.secondary};
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.tertiary};
`;

const NoEmail = ({handleMissingContactInfo}: any) => {
  const responsive = useResponsive();

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>Send an email</TitleText>
      <Space height={16} />
      <Row>
        <LinkButton onPress={handleMissingContactInfo}>
          <LinkText responsive={responsive}>Add your customer's email</LinkText>
        </LinkButton>
        <Text responsive={responsive}> to send emails.</Text>
      </Row>
    </EmptyStateContainer>
  );
};

const NoEmailThread = ({handleComposeEmail, emailTemplates}: any) => {
  const responsive = useResponsive();

  return (
    <EmptyStateContainer>
      <TitleText responsive={responsive}>No emails</TitleText>
      <Space height={16} />
      <Text responsive={responsive}>Any emails you send will appear here.</Text>
      {handleComposeEmail && (
        <React.Fragment>
          <Space height={16} />
          <DropdownButton
            iconLeft={Icon.Plus}
            text={'Compose Email'}
            actions={emailTemplates.map((emailTemplate: any) => ({
              text: `Compose ${emailTemplate.name}`,
              onPress: () => handleComposeEmail({emailTemplateUuid: emailTemplate.uuid}),
            }))}
            menuWidth={296}
            menuPosition={DropdownButton.MENU_POSITION.CENTER}
            ButtonComponent={Button}
            actionButtonContainerStyle={{height: 244, overflowY: 'auto'}}
          />
        </React.Fragment>
      )}
    </EmptyStateContainer>
  );
};

const EmailThreadSheet = ({selectedThread, setSelectedThread}: any) => {
  return (
    <Sheet
      isOpen={!!selectedThread}
      handleClose={() => setSelectedThread(null)}
      headerText={'View Email'}
      isFixedHeight
    >
      {selectedThread && (
        <React.Fragment>
          <Line />
          <CustomerEmailThreadEmails
            selectedThread={selectedThread}
            setSelectedThread={setSelectedThread}
          />
        </React.Fragment>
      )}
    </Sheet>
  );
};

const EmailThreadsListContent = ({
  emailThreads,
  customer,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  handleComposeEmail,
}: any) => {
  const responsive = useResponsive();

  if (!customer.email) {
    return <NoEmail handleMissingContactInfo={handleMissingContactInfo} />;
  }

  if (_.isEmpty(emailThreads)) {
    return (
      <NoEmailThread
        handleComposeEmail={handleComposeEmail}
        emailTemplates={customer.organization.sortedEmailTemplates}
      />
    );
  }

  if (selectedThread && responsive.desktop) {
    return (
      <Container>
        <CustomerEmailThreadEmails
          selectedThread={selectedThread}
          setSelectedThread={setSelectedThread}
        />
      </Container>
    );
  }

  return (
    <Container>
      {emailThreads.map((emailThread: any, index: any) => (
        <CustomerEmailThreadItem
          key={index}
          emailThread={emailThread}
          setSelectedThread={setSelectedThread}
          isVisibleDateTime
        />
      ))}
      {!responsive.desktop && (
        <EmailThreadSheet selectedThread={selectedThread} setSelectedThread={setSelectedThread} />
      )}
    </Container>
  );
};

const EmailThreadsList = ({
  emailThreads,
  customer,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  handleComposeEmail,
}: any) => {
  return (
    <EmailThreadsListContent
      emailThreads={emailThreads}
      customer={customer}
      handleMissingContactInfo={handleMissingContactInfo}
      selectedThread={selectedThread}
      setSelectedThread={setSelectedThread}
      handleComposeEmail={handleComposeEmail}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreadsList.fragment = gql`
  ${CustomerEmailThreadEmails.fragment}
  ${CustomerEmailThreadItem.fragment}

  fragment EmailThreadsList_Thread on Thread {
    id
    ...CustomerEmailThreadEmails
    ...CustomerEmailThreadItem
  }
  fragment EmailThreadsList_User on User {
    id
    email
    organization {
      id
      sortedEmailTemplates {
        id
        name
        uuid
      }
    }
  }
`;

export default EmailThreadsList;
