// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {BillItemType} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import SidebarPage from 'modules/App/components/SidebarPage';
import AddBillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/AddBillItemTypeDrawer';
import BillItemTypeActions from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeActions';
import BillingLibraryBillItemTypesTreeNavigation from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryBillItemTypesTreeNavigation';
import BillingLibraryPageHeader from 'modules/Organization/Settings/BillingLibraries/components/BillingLibraryPageHeader';

const Container = Styled.View`
`;

const SectionContainer = Styled.View`
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? '100%' : '940px')};
`;

const TableLabelRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RowContainer = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const BodyContainer = Styled.View`
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? '24px' : '12px')};
  backgroundColor: ${colors.gray.background};
  flex: 1;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Responsive.Heading1}
;`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const DescriptionText = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TableCellTextTag = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.hasFormula ? Typography.Label2 : Typography.Body3)}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars = {},
  }) => (vars.hasFormula ? colors.orange.status : colors.gray.primary)};
`;

const IconContainer = Styled.View`
  width: 16px;
  padding-top: 2px;
`;

const PerPositionRatesCell = ({billItemType}: any) => {
  return (
    <React.Fragment>
      {billItemType.isParent && (
        <TextTooltip
          isEnabledMobileToast={false}
          placement={'top'}
          text={`This fee item has position rates`}
        >
          <IconContainer>
            <Icon color={colors.blue.interactive} size={Icon.Sizes.Small} source={Icon.Users} />
          </IconContainer>
        </TextTooltip>
      )}
    </React.Fragment>
  );
};

const TableCellText = ({children, vars, style, tooltipContent}: any) => {
  const tableCellImplContent = (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TableCellTextTag vars={vars} style={style}>
      {children}
    </TableCellTextTag>
  );
  if (tooltipContent) {
    return (
      <React.Fragment>
        <TextTooltip isEnabledMobileToast={false} text={tooltipContent}>
          {tableCellImplContent}
        </TextTooltip>
      </React.Fragment>
    );
  }
  return tableCellImplContent;
};

const CheckCell = ({isChecked}: any) => (
  <React.Fragment>
    {isChecked && (
      <IconContainer style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Check} />
      </IconContainer>
    )}
  </React.Fragment>
);

const getAccountingItemTextWithChildrenBillItemType = ({billItemType}: any) => {
  const billItemTypesName = [];

  if (billItemType.externalInvoiceItem) {
    billItemTypesName.push(billItemType.externalInvoiceItem.name);
  }

  billItemType.childBillItemTypes.forEach((childBillItemType: any) => {
    if (childBillItemType.externalInvoiceItem) {
      billItemTypesName.push(childBillItemType.externalInvoiceItem.name);
    }
  });

  return billItemTypesName.join(', ');
};

const getSuppliesColumnDefinitions = ({refetch, userId, responsive, organization}: any) => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Item Name</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasNameFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderNameFormulaString(billItemType)}
      >
        {billItemType.name}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText />,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => <PerPositionRatesCell billItemType={billItemType} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Value</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasAmountFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderAmountFormulaString(billItemType)}
      >
        {BillItemType.getDisplayValue(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Quantity</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasQuantityFormula(billItemType),
        }}
      >
        {BillItemType.getDisplayQuantity(billItemType, {
          isEnabledTbdBillItems: organization.features.isEnabledTbdBillItems,
        })}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Taxable</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => <CheckCell isChecked={billItemType.isTaxable} />,
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText>{billItemType.description}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Accounting Item</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText>{getAccountingItemTextWithChildrenBillItemType({billItemType})}</TableCellText>
    ),
  },
  {
    flex: 0.7,
    headerContent: () => <Table.HeaderText>Last Updated</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <Container>
        <TableCellText>{BillItemType.getDisplayUpdatedAt(billItemType)}</TableCellText>
        {!!billItemType.updatedBy && (
          <React.Fragment>
            <Space height={4} />
            <DescriptionText responsive={responsive}>
              {billItemType.updatedBy.fullName}
            </DescriptionText>
          </React.Fragment>
        )}
      </Container>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText />,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <BillItemTypeActions
        key={billItemType.id}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
      />
    ),
  },
];

const getFeesAndDiscountsColumnDefinitions = ({refetch, userId, responsive, organization}: any) => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Item Name</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasNameFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderNameFormulaString(billItemType)}
      >
        {billItemType.name}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText />,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => <PerPositionRatesCell billItemType={billItemType} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Value</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasAmountFormula(billItemType),
        }}
        tooltipContent={BillItemType.renderAmountFormulaString(billItemType)}
      >
        {BillItemType.getDisplayValue(billItemType)}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Quantity</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText
        vars={{
          hasFormula: BillItemType.hasQuantityFormula(billItemType),
        }}
      >
        {BillItemType.getDisplayQuantity(billItemType, {
          isEnabledTbdBillItems: organization.features.isEnabledTbdBillItems,
        })}
      </TableCellText>
    ),
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>Taxable</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => <CheckCell isChecked={billItemType.isTaxable} />,
  },
  {
    flex: 0.5,
    headerContent: () => <Table.HeaderText>After/Before</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText>{BillItemType.getDisplayBillStage(billItemType)}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Description</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText>{billItemType.description}</TableCellText>
    ),
  },
  {
    flex: 2,
    headerContent: () => <Table.HeaderText>Accounting Item</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <TableCellText>{getAccountingItemTextWithChildrenBillItemType({billItemType})}</TableCellText>
    ),
  },
  {
    flex: 0.7,
    headerContent: () => <Table.HeaderText>Last Updated</Table.HeaderText>,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <Container>
        <TableCellText>{BillItemType.getDisplayUpdatedAt(billItemType)}</TableCellText>
        {!!billItemType.updatedBy && (
          <React.Fragment>
            <Space height={4} />
            <DescriptionText responsive={responsive}>
              {billItemType.updatedBy.fullName}
            </DescriptionText>
          </React.Fragment>
        )}
      </Container>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText />,
    // @ts-expect-error TS(7031): Binding element 'billItemType' implicitly has an '... Remove this comment to see the full error message
    cellContent: ({item: billItemType}) => (
      <BillItemTypeActions
        key={billItemType.id}
        billItemType={billItemType}
        refetch={refetch}
        userId={userId}
      />
    ),
  },
];

const BillItemTypeSection = ({
  buttonText,
  headerText,
  columnDefinitions,
  category,
  billingLibrary,
  refetch,
  userId,
}: any) => {
  const responsive = useResponsive();
  const drawer = useDrawer({
    name: `${buttonText} Drawer`,
    // @ts-expect-error TS(2345): Argument of type '{ name: string; useTracking: boo... Remove this comment to see the full error message
    useTracking: true,
  });
  // (TODO) Kevin: This can be removed once FF is fully turned on
  const items = _.filter(
    billingLibrary.billItemTypesByCategory,
    (billItemType) => billItemType.category === category && !billItemType.isChild,
  );
  return (
    <SectionContainer responsive={responsive}>
      <TableLabelRow>
        <Container>
          <TableHeaderText responsive={responsive}>{headerText}</TableHeaderText>
          <React.Fragment>
            <Space height={8} />
            <Text responsive={responsive} style={{color: colors.gray.secondary}}>
              {'Supermove will automatically calculate'}
              <LabelText responsive={responsive} style={{color: colors.orange.status}}>
                {' '}
                orange highlighted items{' '}
              </LabelText>
              {'based on saved formulas.'}
            </Text>
          </React.Fragment>
        </Container>
        <Space style={{flex: 1}} />
        <Button iconLeft={Icon.Plus} onPress={drawer.handleOpen} text={buttonText} />
      </TableLabelRow>
      <Space height={16} />
      <Table
        columnDefinitions={columnDefinitions}
        items={items}
        emptyStateText={'No items to display'}
        isDense
      />
      <AddBillItemTypeDrawer
        key={drawer.key}
        isOpen={drawer.isOpen}
        handleClose={drawer.handleClose}
        billingLibrary={billingLibrary}
        category={category}
        refetch={refetch}
        userId={userId}
      />
    </SectionContainer>
  );
};

const BillingLibraryBillItemTypesContent = ({
  billingLibrary,
  refetch,
  userId,
  params,
  responsive,
}: any) => {
  return (
    <RowContainer responsive={responsive}>
      <BillingLibraryBillItemTypesTreeNavigation billingLibraryUuid={billingLibrary.uuid} />
      <BodyContainer responsive={responsive}>
        <ScrollView>
          <Space height={24} />
          {getTreeNavigationRenderItems({
            key: params.category,
            refetch,
            userId,
            billingLibrary,
            organization: billingLibrary.organization,
          })}
          <Space height={36} />
        </ScrollView>
      </BodyContainer>
    </RowContainer>
  );
};

const getTreeNavigationRenderItems = ({
  key,
  refetch,
  userId,
  billingLibrary,
  organization,
}: any) => {
  switch (key) {
    case 'discounts':
      return (
        <BillItemTypeSection
          buttonText={'Add Discount'}
          headerText={'Discounts'}
          columnDefinitions={getFeesAndDiscountsColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.DISCOUNTS}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
    case 'supplies':
      return (
        <BillItemTypeSection
          buttonText={'Add Supply'}
          headerText={'Supplies'}
          columnDefinitions={getSuppliesColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.SUPPLIES}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
    case 'fees':
    default:
      return (
        <BillItemTypeSection
          buttonText={'Add Fee'}
          headerText={'Fees'}
          columnDefinitions={getFeesAndDiscountsColumnDefinitions({
            refetch,
            userId,
            organization,
          })}
          category={BillItemTypeCategory.FEES}
          billingLibrary={billingLibrary}
          refetch={refetch}
          userId={userId}
        />
      );
  }
};

const getCategoryListFromPath = ({params}: any) => {
  switch (params.category) {
    case 'fees':
      return [BillItemTypeCategory.FEES];
    case 'discounts':
      return [BillItemTypeCategory.DISCOUNTS];
    case 'supplies':
      return [BillItemTypeCategory.SUPPLIES];
    default:
      return null;
  }
};

const BillingLibraryBillItemTypesPage = () => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <SidebarPage
      selected={'settings'}
      query={BillingLibraryBillItemTypesPage.query}
      variables={{
        billingLibraryUuid: params.billingLibraryUuid,
        categories: getCategoryListFromPath({params}),
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({data, refetch}: any) => {
        return (
          <Container style={{flex: 1}}>
            <BillingLibraryPageHeader billingLibrary={data.billingLibrary} />
            <BillingLibraryBillItemTypesContent
              billingLibrary={data.billingLibrary}
              refetch={refetch}
              userId={data.viewer.id}
              params={params}
              responsive={responsive}
            />
          </Container>
        );
      }}
    </SidebarPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillItemTypesPage.query = gql`
  ${AddBillItemTypeDrawer.fragment}
  ${BillingLibraryPageHeader.fragment}
  ${BillingLibraryBillItemTypesTreeNavigation.fragment}
  ${BillItemTypeActions.fragment}
  ${BillItemType.getDisplayBillStage.fragment}
  ${BillItemType.getDisplayQuantity.fragment}
  ${BillItemType.getDisplayValue.fragment}
  ${BillItemType.getDisplayUpdatedAt.fragment}
  ${BillItemType.hasAmountFormula.fragment}
  ${BillItemType.hasNameFormula.fragment}
  ${BillItemType.hasQuantityFormula.fragment}
  ${BillItemType.renderAmountFormulaString.fragment}
  ${BillItemType.renderNameFormulaString.fragment}

  query BillingLibraryBillItemTypesPage($billingLibraryUuid: String!, $categories: [String]) {
    ${gql.query}
    viewer {
      id
    }
    billingLibrary(billingLibraryUuid: $billingLibraryUuid) {
      id
      name
      organization {
        id
        features {
          isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        }
      }
      billItemTypesByCategory(categories: $categories) {
        id
        category
        name
        kind
        description
        isChild
        isParent
        isTaxable
        updatedBy {
          id
          fullName
        }
        externalInvoiceItem {
          id
          name
        }
        childBillItemTypes {
          id
          externalInvoiceItem {
            id
            name
          }
        }
        ...BillItemTypeActions
        ...BillItemType_getDisplayBillStage
        ...BillItemType_getDisplayValue
        ...BillItemType_getDisplayQuantity
        ...BillItemType_getDisplayUpdatedAt
        ...BillItemType_hasAmountFormula
        ...BillItemType_hasNameFormula
        ...BillItemType_hasQuantityFormula
        ...BillItemType_renderAmountFormulaString
        ...BillItemType_renderNameFormulaString
      }
      ...BillingLibraryBillItemTypesTreeNavigation
      ...AddBillItemTypeDrawer
      ...BillingLibraryPageHeader
    }
  }
`;

export default BillingLibraryBillItemTypesPage;
