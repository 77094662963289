// Supermove
import {Currency} from '@supermove/utils';

interface BeginPaymentFormType {
  billId: string;
  customerId: string;
  amount: number;
  name: string;
  description: string;
  method: string;
  paymentMethodId: string | null;
  paymentFeeAmount: number;
  // Private
  subtotal: number;
}

const _new = ({
  billId,
  customerId,
  amount,
  name,
}: {
  billId: string;
  customerId: string;
  amount: number;
  name: string;
}): BeginPaymentFormType => ({
  billId,
  customerId,
  amount,
  name,
  description: '',
  method: '',
  paymentMethodId: null,
  paymentFeeAmount: 0,
  // Private
  subtotal: amount || 0,
});

export interface BeginPaymentFormToFormType {
  billId: string;
  customerId: string;
  amount: string;
  name: string;
  description: string;
  method: string;
  paymentMethodId: string | null;
  paymentFeeAmount: string;
  // Private
  subtotal: string;
}

const toForm = ({
  billId,
  customerId,
  name,
  description,
  amount,
  method,
  paymentMethodId,
  paymentFeeAmount,
  // Private
  subtotal,
}: BeginPaymentFormType): BeginPaymentFormToFormType => ({
  billId,
  customerId,
  amount: Currency.toForm(amount),
  name,
  description,
  method,
  paymentMethodId,
  paymentFeeAmount: Currency.toForm(paymentFeeAmount),
  // Private
  subtotal: Currency.toForm(subtotal),
});

const toMutation = ({
  billId,
  customerId,
  name,
  description,
  amount,
  method,
  paymentMethodId,
  paymentFeeAmount,
}: BeginPaymentFormToFormType) => ({
  billId,
  customerId,
  amount: Currency.toMutation(amount),
  name,
  description,
  method,
  paymentMethodId,
  paymentFeeAmount: Currency.toMutation(paymentFeeAmount),
});

const BeginPaymentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default BeginPaymentForm;
