// Libraries
import React from 'react';

// Supermove
import {useResponsive} from '@supermove/hooks';

// App
import TruckForm from '@shared/modules/Dispatch/forms/TruckForm';
import useCreateTruckMutation from '@shared/modules/Dispatch/hooks/useCreateTruckMutation';

import TruckFormFields from './TruckFormFields';

type OwnProps = {
  handleClose: (...args: any[]) => any;
  refetch: (...args: any[]) => any;
  viewer: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CreateTruckForm.defaultProps;

// @ts-expect-error TS(7022): 'CreateTruckForm' implicitly has type 'any' becaus... Remove this comment to see the full error message
const CreateTruckForm = ({handleClose, refetch, viewer}: Props) => {
  const responsive = useResponsive();
  const truckForm = TruckForm.new(viewer.viewingOrganization.id);
  const {form, submitting, handleSubmit} = useCreateTruckMutation({
    truckForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: () => {},
  });
  return (
    <TruckFormFields
      responsive={responsive}
      form={form}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
    />
  );
};

CreateTruckForm.defaultProps = {};

export default CreateTruckForm;
