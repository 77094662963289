// Libararies
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useDebouncedCallback, useNavigationDOM, useResponsive, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import OrganizationSettingsDocumentFlowsFilter from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFilter';
import {OrganizationSettingsDocumentFlowsUrlFilters} from 'modules/Organization/Settings/DocumentFlows/components/OrganizationSettingsDocumentFlowsFiltersTypes';

const PageHeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Column = Styled.View`
`;

const OrganizationSettingsDocumentFlowsPageHeader = ({
  urlFilters,
}: {
  urlFilters: OrganizationSettingsDocumentFlowsUrlFilters;
}) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const [searchQuery, setSearchQuery] = useState(urlFilters.get('searchQuery'));

  const debouncedFilterUpdate = useDebouncedCallback(
    (query: string) => urlFilters.handleUpdate({searchQuery: query}),
    300,
  );
  const handleSearch = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    debouncedFilterUpdate(searchQuery);
  };

  return (
    <Column>
      <Row>
        <PageHeaderText responsive={responsive}>Document Flows</PageHeaderText>
        <Button
          isResponsive
          iconLeft={Icon.Plus}
          text={'Create Document Flow'}
          onPress={() => {
            navigator.push('/settings/projects/document-flows/create');
          }}
        />
      </Row>
      <Space height={24} />
      <Row>
        <SearchBar
          containerStyle={{flex: 1}}
          style={{width: '100%'}}
          defaultValue={''}
          placeholder='Search by flow name, description, project types'
          onChangeText={handleSearch}
          valueOverride={searchQuery}
          isClearable
          isResponsive
        />
        <Space width={8} />
        <OrganizationSettingsDocumentFlowsFilter
          urlFilters={urlFilters}
          handleApply={urlFilters.handleUpdate}
        />
      </Row>
    </Column>
  );
};
export default OrganizationSettingsDocumentFlowsPageHeader;
