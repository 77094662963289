// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 48px;
  background-color: ${colors.black};
  align-items: center;
`;

const MobileText = Styled.Text`
  ${Typography.Subheading}
  color: ${colors.white};
`;

const CloseButton = ({onPress}: any) => {
  return (
    <TertiaryButton
      onPress={onPress}
      style={{
        paddingHorizontal: 8,
        paddingVertical: 8,
      }}
    >
      <Icon source={Icon.Xmark} size={22} color={colors.white} />
    </TertiaryButton>
  );
};

const MobileHeader = ({handleClose, title, children}: any) => {
  const responsive = useResponsive();

  return (
    <HeaderContainer {...responsive}>
      {handleClose && (
        <React.Fragment>
          <Space width={16} />
          <CloseButton onPress={handleClose} />
        </React.Fragment>
      )}
      {title && (
        <React.Fragment>
          <Space width={8} />
          <MobileText>{title}</MobileText>
        </React.Fragment>
      )}
      {children}
    </HeaderContainer>
  );
};

export default MobileHeader;
