// Libraries
import React from 'react';

// Supermove
import {Emoji, Loading, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import JobEventsList from './JobEventsList';

const Container = Styled.View`
  flex: 1;
  padding-horizontal: ${(props) => ((props as any).mobile ? 0 : 40)}px;
  padding-top: ${(props) => ((props as any).mobile ? 30 : 40)}px;
  padding-bottom: ${(props) => ((props as any).mobile ? 0 : 40)}px;
`;

const Wrapper = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  flex: 1;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Events = Styled.View`
  flex: 1;
  margin-top: 20px;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  margin-vertical: 20px;
  margin-horizontal: 30px;
`;

const Button = Styled.LoadingButton`
  flex: 1;
  align-items: center;
  height: 40px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const JobTimelineV2AllJobEventsModalContent = ({job, onClose}: any) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive} pointerEvents={'box-none'}>
      <Wrapper>
        <Circle>
          <Emoji component={EmojiText} name={'clock1'} />
        </Circle>
        <Content>
          <Title>Full Event History</Title>
          <Subtitle>View all events that have happened for this job.</Subtitle>
          <Events>
            <JobEventsList jobEvents={job.jobTimelineV2.allJobEvents} />
          </Events>
          <Actions>
            <Button color={colors.blue.interactive} onPress={onClose}>
              <Text>Close</Text>
            </Button>
          </Actions>
        </Content>
      </Wrapper>
    </Container>
  );
};

type OwnJobTimelineV2AllJobEventsModalProps = {};

// @ts-expect-error TS(2456): Type alias 'JobTimelineV2AllJobEventsModalProps' c... Remove this comment to see the full error message
type JobTimelineV2AllJobEventsModalProps = OwnJobTimelineV2AllJobEventsModalProps &
  typeof JobTimelineV2AllJobEventsModal.defaultProps;

// @ts-expect-error TS(7022): 'JobTimelineV2AllJobEventsModal' implicitly has ty... Remove this comment to see the full error message
const JobTimelineV2AllJobEventsModal = ({
  isOpen,
  jobUuid,
  onClose,
}: JobTimelineV2AllJobEventsModalProps) => {
  const {loading, data} = useQuery(JobTimelineV2AllJobEventsModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      uuid: jobUuid,
    },
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Loading loading={loading}>
        {() =>
          data ? <JobTimelineV2AllJobEventsModalContent job={data.job} onClose={onClose} /> : null
        }
      </Loading>
    </Modal.Content>
  );
};

JobTimelineV2AllJobEventsModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimelineV2AllJobEventsModal.query = gql`
  ${JobEventsList.fragment}

  query JobTimelineV2AllJobEventsModal($uuid: String!) {
    job(uuid: $uuid) {
      id
      jobTimelineV2 {
        allJobEvents {
          id
          ...JobEventsList
        }
      }
    }
  }
`;

export default JobTimelineV2AllJobEventsModal;
