// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Icon, ScrollView, Space, Styled, Tabs} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {
  useState,
  useEffect,
  useMountEffect,
  useResponsive,
  useTabs,
  usePopover,
} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {Animation, colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import LargeModal from '@shared/design/components/Modal/LargeModal';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import VariableSectionsCollapsedStates from '@shared/modules/Variable/enums/VariableSectionsCollapsedStates';
import Line from 'modules/App/components/Line';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import JobBillingValuesSection from 'modules/Project/Billing/components/JobBillingValuesSection';
import ProjectBillingValuesSection from 'modules/Project/Billing/components/ProjectBillingValuesSection';

const Container = Styled.View`
  z-index: ${(props) => 1000 - (props as any).index};
`;

const ModalContentContainer = Styled.View`
  flex: 1;
`;

const BillingVariablesBlockContainer = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const BillingValueGroupTitle = Styled.Text`
  ${Typography.Subheading}
  color: ${colors.gray.secondary};
`;

const SkeletonContainer = Styled.View`
  flex: 1;
  overflow: hidden;
`;

const Skeleton = Styled.View`
  ${Animation.pulse}
  height: ${({
    // @ts-expect-error TS(2339): Property 'height' does not exist on type 'ThemePro... Remove this comment to see the full error message
    height,
  }) => height};
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width};
  border-radius: 4px;
  background-color: ${colors.gray.disabled};
`;

const LoadingComponent = () => {
  return (
    <SkeletonContainer>
      <Skeleton height={'40px'} width={'200px'} />
      <Space height={24} />
      <Skeleton height={'400px'} width={'100%'} />
      <Space height={24} />
      <Skeleton height={'30px'} width={'150px'} />
      <Space height={16} />
      <Skeleton height={'400px'} width={'100%'} />
    </SkeletonContainer>
  );
};

const getInitialProjectTypeSectionsState = ({project, responsive}: any) => {
  const sections: any = [];
  project.projectType.projectTypeVariableSections.forEach((projectTypeVariableSection: any) => {
    sections.push({
      sectionIdentifier: `${project.id}${projectTypeVariableSection.id}`,
      isCollapsed: !responsive.desktop,
    });
  });
  return sections;
};

const getInitialJobTypeSectionsState = ({project}: any) => {
  const sections: any = [];
  project.activeJobs.forEach((job: any) => {
    job.jobType.jobTypeVariableSections.forEach((jobTypeVariableSection: any) => {
      sections.push({
        sectionIdentifier: `${job.id}${jobTypeVariableSection.id}`,
        isCollapsed: true,
      });
    });
  });
  return sections;
};

const isAllSectionsCollapsedOrExpanded = ({
  isCollapsedJobTypeSections,
  isCollapsedProjectTypeSections,
}: any) => {
  let numCollapsed = 0;
  let numExpanded = 0;
  isCollapsedJobTypeSections.forEach((section: any) => {
    if (section.isCollapsed) {
      numCollapsed += 1;
    } else {
      numExpanded += 1;
    }
  });
  isCollapsedProjectTypeSections.forEach((section: any) => {
    if (section.isCollapsed) {
      numCollapsed += 1;
    } else {
      numExpanded += 1;
    }
  });
  if (numCollapsed === 0) {
    return VariableSectionsCollapsedStates.IS_ALL_EXPANDED;
  }
  if (numExpanded === 0) {
    return VariableSectionsCollapsedStates.IS_ALL_COLLAPSED;
  }
  return VariableSectionsCollapsedStates.IS_EXPANDED_AND_COLLAPSED;
};

const toggleIsCollapsedAllSections = ({
  isCollapsedJobTypeSections,
  setIsCollapsedJobTypeSections,
  setIsCollapsedProjectTypeSections,
  isCollapsedProjectTypeSections,
  isCollapsedAll,
  setIsCollapsedAll,
}: any) => {
  const updatedIsCollapsedJobTypeSections = _.clone(isCollapsedJobTypeSections);
  const updatedIsCollapsedProjectTypeSections = _.clone(isCollapsedProjectTypeSections);
  updatedIsCollapsedJobTypeSections.forEach((jobTypeSection: any) => {
    jobTypeSection.isCollapsed = !isCollapsedAll;
  });
  updatedIsCollapsedProjectTypeSections.forEach((projectTypeSection: any) => {
    projectTypeSection.isCollapsed = !isCollapsedAll;
  });

  setIsCollapsedJobTypeSections(updatedIsCollapsedJobTypeSections);
  setIsCollapsedProjectTypeSections(updatedIsCollapsedProjectTypeSections);
  setIsCollapsedAll(!isCollapsedAll);
};

const getJobLabel = ({job}: any) => {
  return `${job.fullName}: ${Job.getDisplayDate(job, Datetime.DISPLAY_MONTH_DAY, 'TBD')}`;
};

const BillingValuesGroup = ({
  title,
  variableSections,
  projectUuid,
  jobUuid,
  isCollapsedSections,
  setIsCollapsedSections,
  index,
  jobId,
  projectId,
  updateValuesObject,
  isDisabledValuesHidden,
}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index}>
      {title && (
        <React.Fragment>
          <Row>
            <BillingValueGroupTitle>{title}</BillingValueGroupTitle>
          </Row>
          <Space height={16} />
        </React.Fragment>
      )}
      <Row>
        {jobUuid && (
          <JobBillingValuesSection
            variableSections={variableSections}
            jobUuid={jobUuid}
            isCollapsedSections={isCollapsedSections}
            setIsCollapsedSections={setIsCollapsedSections}
            index={index}
            jobId={jobId}
            updateValuesObject={updateValuesObject}
            isDisabledValuesHidden={isDisabledValuesHidden}
          />
        )}
        {projectUuid && (
          <ProjectBillingValuesSection
            variableSections={variableSections}
            projectUuid={projectUuid}
            isCollapsedSections={isCollapsedSections}
            setIsCollapsedSections={setIsCollapsedSections}
            index={index}
            projectId={projectId}
            updateValuesObject={updateValuesObject}
            isDisabledValuesHidden={isDisabledValuesHidden}
          />
        )}
      </Row>
    </Container>
  );
};

const ProjectValuesGroup = ({
  project,
  projectTypeVariableSections,
  updateValuesObject,
  isCollapsedProjectTypeSections,
  setIsCollapsedProjectTypeSections,
  responsive,
  isDisabledValuesHidden,
}: any) => {
  return (
    <BillingValuesGroup
      index={0}
      projectId={project.id}
      projectUuid={project.uuid}
      title={responsive.desktop ? Project.getName(project) : null}
      variableSections={projectTypeVariableSections}
      updateValuesObject={updateValuesObject}
      isCollapsedSections={isCollapsedProjectTypeSections}
      setIsCollapsedSections={setIsCollapsedProjectTypeSections}
      isDisabledValuesHidden={isDisabledValuesHidden}
    />
  );
};

const JobValuesGroup = ({
  index,
  job,
  updateValuesObject,
  isCollapsedJobTypeSections,
  setIsCollapsedJobTypeSections,
  responsive,
  isDisabledValuesHidden,
}: any) => {
  return (
    <BillingValuesGroup
      index={index}
      jobId={job.id}
      jobUuid={job.uuid}
      title={responsive.desktop ? getJobLabel({job}) : null}
      variableSections={job.jobType.jobTypeVariableSections}
      updateValuesObject={updateValuesObject}
      isCollapsedSections={isCollapsedJobTypeSections}
      setIsCollapsedSections={setIsCollapsedJobTypeSections}
      isDisabledValuesHidden={isDisabledValuesHidden}
    />
  );
};

const Tab = ({tab, isSelected, handlePress}: any) => {
  return (
    <Tabs.TabContainer
      isSelected={isSelected}
      onPress={handlePress}
      style={{borderBottomWidth: 0, paddingTop: 12, paddingBottom: 12}}
    >
      <Space width={24} />
      <Tabs.TabText style={{color: isSelected ? colors.blue.interactive : colors.gray.tertiary}}>
        {tab.label}
      </Tabs.TabText>
      <Space width={24} />
      {isSelected && <Tabs.TabUnderline />}
    </Tabs.TabContainer>
  );
};

const BillingVariablesBlock = ({
  project,
  updateValuesObject,
  isCollapsedJobTypeSections,
  setIsCollapsedJobTypeSections,
  isCollapsedProjectTypeSections,
  setIsCollapsedProjectTypeSections,
  setIsCollapsedAll,
  responsive,
  isDisabledValuesHidden,
}: any) => {
  const {projectTypeVariableSections} = project.projectType;
  const {activeJobs} = project;
  const tabs = useTabs({initialIndex: 0});

  useMountEffect(() => {
    setIsCollapsedProjectTypeSections(getInitialProjectTypeSectionsState({project, responsive}));
    setIsCollapsedJobTypeSections(getInitialJobTypeSectionsState({project}));
  });

  // Listen when sections are expanded and collapsed to update the collapse all status
  useEffect(() => {
    const allCollapsedStatus = isAllSectionsCollapsedOrExpanded({
      isCollapsedJobTypeSections,
      isCollapsedProjectTypeSections,
    });
    switch (allCollapsedStatus) {
      case VariableSectionsCollapsedStates.IS_ALL_EXPANDED:
        setIsCollapsedAll(false);
        break;
      case VariableSectionsCollapsedStates.IS_ALL_COLLAPSED:
        setIsCollapsedAll(true);
        break;
      default:
        break;
    }
  }, [isCollapsedProjectTypeSections, isCollapsedJobTypeSections, setIsCollapsedAll]);

  return (
    <BillingVariablesBlockContainer>
      {responsive.desktop ? (
        <ScrollView>
          <ProjectValuesGroup
            project={project}
            projectTypeVariableSections={projectTypeVariableSections}
            updateValuesObject={updateValuesObject}
            isCollapsedProjectTypeSections={isCollapsedProjectTypeSections}
            setIsCollapsedProjectTypeSections={setIsCollapsedProjectTypeSections}
            responsive={responsive}
            isDisabledValuesHidden={isDisabledValuesHidden}
          />
          <Space height={40} />
          {activeJobs.map((job: any, jobIndex: any) => {
            return (
              <React.Fragment key={job.id}>
                <JobValuesGroup
                  index={jobIndex + 1}
                  job={job}
                  updateValuesObject={updateValuesObject}
                  isCollapsedJobTypeSections={isCollapsedJobTypeSections}
                  setIsCollapsedJobTypeSections={setIsCollapsedJobTypeSections}
                  responsive={responsive}
                  isDisabledValuesHidden={isDisabledValuesHidden}
                />
                <Space height={40} />
              </React.Fragment>
            );
          })}
        </ScrollView>
      ) : (
        <Tabs
          tabs={tabs}
          // @ts-expect-error TS(2322): Type '{ children: any[]; tabs: { selectedIndex: nu... Remove this comment to see the full error message
          initialIndex={0}
          TabComponent={Tab}
          fullWidthBarStyle={{height: 1}}
          style={{flex: 1}}
          showsHorizontalScrollIndicator={false}
        >
          {/* @ts-expect-error TS(2322): Type '{ children: Element; key: number; tab: { lab... Remove this comment to see the full error message */}
          <ScrollView key={0} tab={{label: Project.getName(project)}}>
            <ProjectValuesGroup
              project={project}
              projectTypeVariableSections={projectTypeVariableSections}
              updateValuesObject={updateValuesObject}
              isCollapsedProjectTypeSections={isCollapsedProjectTypeSections}
              setIsCollapsedProjectTypeSections={setIsCollapsedProjectTypeSections}
              responsive={responsive}
              isDisabledValuesHidden={isDisabledValuesHidden}
            />
          </ScrollView>
          {activeJobs.map((job: any, jobIndex: any) => {
            const displayIndex = jobIndex + 1;
            return (
              // @ts-expect-error TS(2322): Type '{ children: Element; key: any; tab: { label:... Remove this comment to see the full error message
              <ScrollView key={displayIndex} tab={{label: getJobLabel({job})}}>
                <JobValuesGroup
                  index={displayIndex}
                  job={job}
                  updateValuesObject={updateValuesObject}
                  isCollapsedJobTypeSections={isCollapsedJobTypeSections}
                  setIsCollapsedJobTypeSections={setIsCollapsedJobTypeSections}
                  responsive={responsive}
                  isDisabledValuesHidden={isDisabledValuesHidden}
                />
              </ScrollView>
            );
          })}
        </Tabs>
      )}
    </BillingVariablesBlockContainer>
  );
};

const HeaderActions = ({
  actionsPopover,
  isDisabledValuesHidden,
  toggleIsDisabledValuesHidden,
}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {responsive.desktop && <Space width={16} />}
      <ActionMenuPopover
        popover={actionsPopover}
        width={200}
        sheetLabel={'Actions'}
        actions={[
          {
            text: `${isDisabledValuesHidden ? 'Show' : 'Hide'} system variables`,
            onPress: () => toggleIsDisabledValuesHidden(),
          },
        ]}
      >
        <TertiaryButton onPress={actionsPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
        </TertiaryButton>
      </ActionMenuPopover>
    </React.Fragment>
  );
};

const DesktopHeader = ({
  isCollapsedAll,
  handleExpandCollapse,
  actionsPopover,
  isDisabledValuesHidden,
  toggleIsDisabledValuesHidden,
}: any) => {
  return (
    <LargeModal.Header>
      <LargeModal.HeaderText>Edit Billing Values</LargeModal.HeaderText>
      <Space style={{flex: 1}} />
      <TertiaryButton
        text={isCollapsedAll ? 'Expand All' : 'Collapse All'}
        iconLeft={isCollapsedAll ? Icon.ArrowsFromLine : Icon.ArrowsToLine}
        onPress={handleExpandCollapse}
        iconSize={12}
      />
      <HeaderActions
        actionsPopover={actionsPopover}
        isDisabledValuesHidden={isDisabledValuesHidden}
        toggleIsDisabledValuesHidden={toggleIsDisabledValuesHidden}
      />
    </LargeModal.Header>
  );
};

const MobileHeader = ({
  responsive,
  handleClose,
  actionsPopover,
  isDisabledValuesHidden,
  toggleIsDisabledValuesHidden,
}: any) => {
  return (
    <LargeModal.Header isResponsive>
      <Space style={{flex: 1}}>
        <HeaderActions
          actionsPopover={actionsPopover}
          isDisabledValuesHidden={isDisabledValuesHidden}
          toggleIsDisabledValuesHidden={toggleIsDisabledValuesHidden}
        />
      </Space>
      <LargeModal.HeaderText responsive={responsive}>Edit Billing Values</LargeModal.HeaderText>
      <Space style={{flex: 1, alignItems: 'flex-end'}}>
        <TertiaryButton onPress={handleClose} isHitSlop>
          <Icon source={Icon.Xmark} size={24} color={colors.gray.primary} />
        </TertiaryButton>
      </Space>
    </LargeModal.Header>
  );
};

const EditBillingValuesContent = ({
  isOpen,
  handleClose,
  projectUuid,
  updateValuesForm,
  handleSubmit,
  updateValuesObject,
}: any) => {
  const responsive = useResponsive();
  const actionsPopover = usePopover();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {loading, data} = useQuery(EditBillingValuesModal.query, {
    fetchPolicy: 'network-only',
    variables: {
      projectUuid,
    },
    skip: !isOpen,
  });
  const isLoading = loading || !data;
  const [isCollapsedAll, setIsCollapsedAll] = useState(true);
  const [isCollapsedProjectTypeSections, setIsCollapsedProjectTypeSections] = useState([]);
  const [isCollapsedJobTypeSections, setIsCollapsedJobTypeSections] = useState([]);
  const [isDisabledValuesHidden, setIsDisabledValuesHidden] = useState(true);

  return (
    <LargeModal
      isResponsive
      isScrollable={false}
      isOpen={isOpen}
      // @ts-expect-error TS(2322): Type '{ children: Element; isResponsive: true; isS... Remove this comment to see the full error message
      handleClose={handleClose}
      HeaderComponent={() =>
        responsive.desktop ? (
          <DesktopHeader
            isCollapsedAll={isCollapsedAll}
            handleExpandCollapse={() => {
              toggleIsCollapsedAllSections({
                isCollapsedJobTypeSections,
                setIsCollapsedJobTypeSections,
                isCollapsedProjectTypeSections,
                setIsCollapsedProjectTypeSections,
                isCollapsedAll,
                setIsCollapsedAll,
              });
            }}
            actionsPopover={actionsPopover}
            isDisabledValuesHidden={isDisabledValuesHidden}
            toggleIsDisabledValuesHidden={() => setIsDisabledValuesHidden(!isDisabledValuesHidden)}
          />
        ) : (
          <MobileHeader
            responsive={responsive}
            handleClose={handleClose}
            actionsPopover={actionsPopover}
            isDisabledValuesHidden={isDisabledValuesHidden}
            toggleIsDisabledValuesHidden={() => setIsDisabledValuesHidden(!isDisabledValuesHidden)}
          />
        )
      }
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={responsive.desktop ? handleClose : null}
      bodyStyle={{flex: 1, ...(responsive.desktop ? {} : {padding: 0})}}
      style={{height: '100%'}}
      handlePrimaryAction={() =>
        UpdateValuesForm.handleSubmit({
          form: updateValuesForm,
          field: 'updateValuesForm',
          updateValuesObject,
          setIsSubmitting,
          handleSubmit,
        })
      }
      isSubmitting={isSubmitting}
    >
      <ModalContentContainer>
        <Loading
          loading={isLoading}
          as={
            responsive.desktop
              ? LoadingComponent
              : () => (
                  <React.Fragment>
                    <Space height={12} />
                    {/* @ts-expect-error TS(2769): No overload matches this call. */}
                    <Container index={0} style={{paddingHorizontal: 16}}>
                      <SkeletonLoader height={SkeletonLoader.HEIGHT.SubheadingText} />
                    </Container>
                    <Space height={12} />
                    <Line />
                    <ProjectBillingValuesSection.SkeletonLoaderMobile isFirst />
                    <ProjectBillingValuesSection.SkeletonLoaderMobile />
                    <ProjectBillingValuesSection.SkeletonLoaderMobile />
                  </React.Fragment>
                )
          }
        >
          {() => {
            return (
              <BillingVariablesBlock
                project={data.project}
                updateValuesObject={updateValuesObject}
                isCollapsedJobTypeSections={isCollapsedJobTypeSections}
                setIsCollapsedJobTypeSections={setIsCollapsedJobTypeSections}
                isCollapsedProjectTypeSections={isCollapsedProjectTypeSections}
                setIsCollapsedProjectTypeSections={setIsCollapsedProjectTypeSections}
                setIsCollapsedAll={setIsCollapsedAll}
                responsive={responsive}
                isDisabledValuesHidden={isDisabledValuesHidden}
              />
            );
          }}
        </Loading>
      </ModalContentContainer>
    </LargeModal>
  );
};

const EditBillingValuesModal = ({
  isOpen,
  handleClose,
  projectUuid,
  updateValuesForm,
  handleSubmit,
}: any) => {
  // updateValuesObject is defined outside of EditBillingValuesContent
  // so that state updates do not reset the object.
  const updateValuesObject = {};
  return (
    <EditBillingValuesContent
      isOpen={isOpen}
      handleClose={handleClose}
      projectUuid={projectUuid}
      updateValuesForm={updateValuesForm}
      handleSubmit={handleSubmit}
      updateValuesObject={updateValuesObject}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillingValuesModal.query = gql`
  ${JobBillingValuesSection.fragment}
  ${Job.getDisplayDate.fragment}
  ${ProjectBillingValuesSection.fragment}
  ${Project.getName.fragment}

  query EditBillingValuesModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid){
      id
      uuid
      activeJobs{
        id
        uuid
        fullName
        jobType{
          id
          jobTypeVariableSections{
            id
            ...JobBillingValuesSection
          }
        }
        ...Job_getDisplayDate
      }
      projectType{
        id
        projectTypeVariableSections{
          id
          ...ProjectBillingValuesSection
        }
      }
      ...Project_getName
    }
  }
`;

// We utilize React.memo here because we're keeping track of updated variables
// with a plain javascript object. Without this, when the job page re-polls to
// refresh the job data, it resets the updateValuesObject, which causes us to
// lose all the variable value updates that the user has made. This is not easily
// visible because the inputs don't reset since they only write to the object,
// but when pressing save, we find that the older updates do not get saved.
const areEqual = (prevProps: any, nextProps: any) => {
  return prevProps.isOpen === nextProps.isOpen;
};

export default React.memo(EditBillingValuesModal, areEqual);
