import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Popover, Icon, Link} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal, usePopover, useState, usePagination} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {Typography, colors} from '@supermove/styles';
import {Datetime, titleize} from '@supermove/utils';

// Shared
import Button from '@shared/design/components/Button';
import Modal from '@shared/design/components/Modal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import CodatBatchExportForm from '@shared/modules/Integration/forms/CodatBatchExportForm';
import useBatchExportToCodatMutation from '@shared/modules/Integration/hooks/useBatchExportToCodatMutation';
import useKillBatchMutation from '@shared/modules/Integration/hooks/useKillBatchMutation';
import BatchStatus from '@shared/modules/Organization/enums/BatchStatus';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import SettingsPage from 'modules/Organization/Settings/components/SettingsPage';

import {RequeueInvoicesModal} from './OrganizationSettingsCompanyIntegrationsAccountingExportsBatchPage';

const LinkStyle = {
  fontFamily: 'Avenir, sans-serif',
  color: colors.blue.interactive,
  fontWeight: 500,
  fontSize: 14,
  lineHeight: '20px',
  textDecorationLine: 'underline',
  textDecorationColor: colors.blue.interactive,
  cursor: 'pointer',
};

const ActionsCell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const LinkWrapper = Styled.ButtonV2`
  flex: 1;
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const Row = Styled.View`
  justify-content: space-between;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'isMobile' does not exist on type 'ThemeP... Remove this comment to see the full error message
    isMobile,
  }) => (isMobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const getItem = (batchOperation: any) => {
  if (batchOperation.invoiceExport) {
    return `Invoice "${batchOperation.invoiceExport.invoice.identifier}"`;
  }
  if (batchOperation.paymentExport) {
    return `Payment "${batchOperation.paymentExport.payment.name}" (for Invoice ${batchOperation.paymentExport.payment.invoice.identifier})`;
  }
  if (batchOperation.clientExport) {
    return `Client "${batchOperation.clientExport.client.name}"`;
  }
  return 'Unknown';
};

const getItemLink = (batchOperation: any) => {
  if (batchOperation.invoiceExport) {
    return `/invoices/${batchOperation.invoiceExport.invoice.uuid}/view`;
  }
  if (batchOperation.paymentExport) {
    return `/invoices/${batchOperation.paymentExport.payment.invoice.uuid}/view`;
  }
  if (batchOperation.clientExport) {
    return `/clients/${batchOperation.clientExport.client.uuid}`;
  }
  return '';
};

const TableContainer = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.PageHeading}
`;

const Column = Styled.View``;

const Description = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
`;

const getAccountingExportsColumnDefinitions = ({navigator, refetch, params}: any) => [
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Batch ID</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>
        <Link
          style={LinkStyle}
          to={`/settings/company/accounting/${params.uuid}/exports/${item.uuid}`}
        >{`Batch ${item.id}`}</Link>
      </Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Status</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>{titleize(item.status.replace('_', ' '))}</Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Start Time</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>
        {item.startTime ? `${Datetime.convertToDisplayDatetime(item.startTime)}` : '-'}
      </Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>End Time</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>
        {item.endTime ? `${Datetime.convertToDisplayDatetime(item.endTime)}` : '-'}
      </Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Progress</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>
        {item.codatBatchOperationCount
          ? `${item.completedCodatBatchOperationCount}/${item.codatBatchOperationCount}`
          : '-'}
      </Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText># Successful</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>{item.successfulCodatBatchOperationCount}</Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText># Errored</Table.HeaderText>,
    cellContent: ({item, index}: any) => (
      <Table.CellText>{item.failedCodatBatchOperationCount}</Table.CellText>
    ),
  },
  {
    flex: 1,
    headerContent: () => <Table.HeaderText>Actions</Table.HeaderText>,
    cellContent: ({item, index}: any) =>
      BatchStatus.isResyncable(item.status) ||
      BatchStatus.isInProgressOrAboutToStart(item.status) ? (
        <BatchActions batch={item} refetch={refetch} navigator={navigator} params={params} />
      ) : null,
  },
];

const BatchActionsMenu = ({popover, killBatchModal, requeueInvoicesModal, batch, uuid}: any) => {
  const {status} = batch;
  const menuItems = [];
  menuItems.push(
    <ActionMenuPopover.MenuItem>
      <Link to={`/settings/company/accounting/${uuid}/exports/${batch.uuid}`}>View Batch</Link>
    </ActionMenuPopover.MenuItem>,
  );

  if (BatchStatus.isInProgressOrAboutToStart(status)) {
    menuItems.push(
      <ActionMenuPopover.MenuItem
        onPress={() => {
          killBatchModal.handleOpen();
          popover.handleClose();
        }}
      >
        {`${status === BatchStatus.PENDING ? 'Cancel' : 'Stop'} Batch`}
      </ActionMenuPopover.MenuItem>,
    );
  }
  if (BatchStatus.isResyncable(status)) {
    if (batch.failedInvoices.length || BatchStatus.isIncomplete(status)) {
      menuItems.push(
        <ActionMenuPopover.MenuItem
          onPress={() => {
            requeueInvoicesModal.handleOpen();
            popover.handleClose();
          }}
        >
          {`Requeue Failed Invoices`}
        </ActionMenuPopover.MenuItem>,
      );
    }
  }
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      {menuItems}
    </ActionMenuPopover>
  );
};

const KillBatchModal = ({killBatchModal, batch, refetch}: any) => {
  const {handleSubmit, submitting} = useKillBatchMutation({
    killBatchForm: {batchId: batch.id},
    onSuccess: () => {
      killBatchModal.handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  const actionName = batch.status === BatchStatus.PENDING ? 'Cancel' : 'Stop';
  const pendingDescription = "This batch hasn't started yet; all actions will be cancelled.";
  const inProgressDescription =
    'Any actions in this batch that have already finished cannot be undone. The remaining actions will be cancelled.';

  return (
    <DeleteModal
      isOpen={killBatchModal.isOpen}
      title={`Are you sure you want to ${actionName.toLocaleLowerCase()} this batch?`}
      subtitle={batch.status === BatchStatus.PENDING ? pendingDescription : inProgressDescription}
      handleClose={killBatchModal.handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={`${actionName} Batch`}
      isSubmitting={submitting}
    />
  );
};
const BatchActions = ({refetch, batch, navigator, params}: any) => {
  const killBatchModal = useModal({name: 'KillBatchModal'});
  const requeueAllFailedInvoicesModal = useModal({name: 'requeueAllFailedInvoicesModal'});
  const moverActionsPopover = usePopover();
  return (
    <React.Fragment>
      <ActionsCell>
        <Popover.Content innerRef={moverActionsPopover.ref}>
          <MoreActionsButton onPress={moverActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      </ActionsCell>
      <BatchActionsMenu
        popover={moverActionsPopover}
        killBatchModal={killBatchModal}
        requeueInvoicesModal={requeueAllFailedInvoicesModal}
        batch={batch}
        uuid={params.uuid}
      />
      <KillBatchModal
        killBatchModal={killBatchModal}
        handleClose={killBatchModal.handleClose}
        batch={batch}
        refetch={refetch}
      />
      <RequeueInvoicesModal
        requeueInvoicesModal={requeueAllFailedInvoicesModal}
        invoices={batch.failedInvoices}
        refetch={refetch}
        navigator={navigator}
      />
    </React.Fragment>
  );
};

const AccountingExportsTable = ({items, navigator, refetch, loading, params}: any) => {
  return (
    <Table
      columnDefinitions={getAccountingExportsColumnDefinitions({navigator, refetch, params})}
      emptyStateText='No accounting exports to display'
      items={items}
      itemKey='batchId'
      isDense
      loading={loading}
    />
  );
};

const getTitle = ({hasInProgressCodatExportBatch, hasAtLeastOneItemToExport}: any) => {
  if (hasInProgressCodatExportBatch) {
    return 'Batch Export Already In Progress';
  }
  if (hasAtLeastOneItemToExport) {
    return 'Start Batch Export';
  }
  return 'Nothing to Export';
};

const getDescription = ({
  inProgressCodatExportBatch,
  hasAtLeastOneItemToExport,
  pendingCodatExportBatch,
}: any) => {
  if (inProgressCodatExportBatch) {
    return `There can only be one batch export in progress at a time. Please wait for the current batch export to complete before starting a new one. Current progress: ${inProgressCodatExportBatch?.completedCodatBatchOperationCount}/${inProgressCodatExportBatch?.codatBatchOperationCount}`;
  }
  if (hasAtLeastOneItemToExport) {
    return `Click the button below to start a batch export of ${pendingCodatExportBatch?.codatBatchOperations.length} items:`;
  }
  return 'Please export at least one item before exporting a batch.';
};

const StartBatchExportModalContent = ({
  errors,
  handleCancel,
  handleSubmit,
  inProgressCodatExportBatch,
  navigator,
  pendingCodatExportBatch,
  submitting,
}: any) => {
  const hasInProgressCodatExportBatch = Boolean(inProgressCodatExportBatch);
  const hasAtLeastOneItemToExport = pendingCodatExportBatch?.codatBatchOperations.length > 0;
  const canExport = hasInProgressCodatExportBatch && hasAtLeastOneItemToExport;
  const title = getTitle({hasInProgressCodatExportBatch, hasAtLeastOneItemToExport});
  const description = getDescription({
    inProgressCodatExportBatch,
    hasAtLeastOneItemToExport,
    pendingCodatExportBatch,
  });
  return (
    <React.Fragment>
      <Modal.Header>
        <Modal.HeaderText>{title}</Modal.HeaderText>
      </Modal.Header>
      <Modal.Body>
        <Modal.Text>{description}</Modal.Text>
        <ScrollView style={{maxHeight: 200}}>
          {(pendingCodatExportBatch?.codatBatchOperations || []).map((item: any) => (
            <Modal.Text>
              <LinkWrapper onPress={() => navigator.push(getItemLink(item))}>
                <LinkText color={colors.blue.interactive}>{getItem(item)}</LinkText>
              </LinkWrapper>
            </Modal.Text>
          ))}
        </ScrollView>
      </Modal.Body>
      <Modal.Footer>
        <React.Fragment>
          {(errors || []).map((error: any) => (
            <Modal.Text style={{color: colors.red.warning}}>{error.message}</Modal.Text>
          ))}
        </React.Fragment>
        <Modal.Button onPress={handleCancel}>{'Cancel'}</Modal.Button>
        <Modal.Button
          onPress={canExport ? handleCancel : handleSubmit}
          isDisabled={submitting}
          color={colors.blue.interactive}
        >
          {canExport ? 'Go Back' : 'Start Batch Export'}
        </Modal.Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const StartBatchExportModal = ({
  isOpen,
  handleClose,
  inProgressCodatExportBatch,
  pendingCodatExportBatch,
  codatIntegration,
  navigator,
  refetch,
}: any) => {
  const [errors, setErrors] = useState([]);
  const {handleSubmit, submitting} = useBatchExportToCodatMutation({
    codatBatchExportForm: CodatBatchExportForm.new({
      codatIntegrationId: codatIntegration?.id || '',
    }),
    onSuccess: () => {
      setErrors([]);
      handleClose();
      refetch();
    },
    onError: (errors: any) => setErrors(errors),
  });
  return (
    <Modal isOpen={isOpen} width={Modal.WIDTH.MEDIUM} style={{maxHeight: '100%'}}>
      <StartBatchExportModalContent
        inProgressCodatExportBatch={inProgressCodatExportBatch}
        pendingCodatExportBatch={pendingCodatExportBatch}
        handleSubmit={handleSubmit}
        handleCancel={handleClose}
        navigator={navigator}
        submitting={submitting}
        errors={errors}
      />
    </Modal>
  );
};

const ConnectionStatusBadge = ({connectionStatus, isLoading}: any) => {
  const icon = connectionStatus === 'ONLINE' ? Icon.CheckCircle : Icon.TimesCircle;
  const iconColor = connectionStatus === 'ONLINE' ? colors.green.status : colors.red.warning;
  const statusText = connectionStatus === 'ONLINE' ? 'Online' : 'Offline';
  return (
    <React.Fragment>
      <Description>Accounting Software Connection Status:</Description>
      <Space width={8} />
      {isLoading ? (
        <SkeletonLoader width={64} height={8} />
      ) : (
        <React.Fragment>
          <Icon source={icon} size={16} color={iconColor} />
          <Space width={8} />
          <Description
            style={{
              color: iconColor,
            }}
          >
            {statusText}
          </Description>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export const AccountingExportsContent = ({navigator, params}: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const {uuid} = params;
  const {loading, data, refetch} = useQuery(AccountingExportsContent.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid,
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(
      data,
      'viewer.viewingOrganization.codatExportBatchesPaginatedList.paginationMetadata',
    ),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  const {viewingOrganization} = data?.viewer || {};
  const {codatIntegrationByUuid} = viewingOrganization || {};
  const {connectionStatus} = codatIntegrationByUuid || {};
  const {codatExportBatchesPaginatedList, inProgressCodatExportBatch, pendingCodatExportBatch} =
    viewingOrganization || {};
  const startBatchExportModal = useModal({name: 'Start Batch Export Modal'});
  const hasInProgressCodatExportBatch = Boolean(inProgressCodatExportBatch);
  const hasAtLeastOneItemToExport = pendingCodatExportBatch?.codatBatchOperations.length > 0;
  const isOffline = connectionStatus === 'OFFLINE';
  const canExport = !hasInProgressCodatExportBatch && hasAtLeastOneItemToExport && !isOffline;

  return (
    <React.Fragment>
      <Row
        style={{
          width: '1200px',
        }}
      >
        <Column style={{maxWidth: 600}}>
          <Title>Batch Exports</Title>
          <Space height={12} />
          <Description>{`Export your invoices, customers, and payments in batches.`}</Description>
        </Column>
        <Column
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
          }}
        >
          {canExport ? (
            <ButtonContainer>
              <Button
                iconLeft={Icon.Upload}
                text={'Start Batch Export'}
                onPress={startBatchExportModal.handleOpen}
                isDisabled={!canExport}
              />
            </ButtonContainer>
          ) : (
            <TextTooltip
              isEnabledMobileToast={false}
              placement='top'
              text={
                isOffline
                  ? 'Please ensure your accounting software is online and connected to Supermove before proceeding.'
                  : hasInProgressCodatExportBatch
                    ? `There is already a batch export in progress for this integration. Current progress: ${inProgressCodatExportBatch?.completedCodatBatchOperationCount}/${inProgressCodatExportBatch?.codatBatchOperationCount}`
                    : 'Please queue at least one item before exporting the batch.'
              }
            >
              <ButtonContainer>
                <Button
                  iconLeft={Icon.Upload}
                  text={'Start Batch Export'}
                  onPress={startBatchExportModal.handleOpen}
                  isDisabled={!canExport}
                />
              </ButtonContainer>
            </TextTooltip>
          )}
          <Space height={12} />
          <Row
            style={{
              alignItems: 'center',
            }}
          >
            <ConnectionStatusBadge connectionStatus={connectionStatus} isLoading={loading} />
          </Row>
          {codatIntegrationByUuid?.codatLastSync ? (
            <React.Fragment>
              <Space height={8} />
              <Row>
                <Description>
                  {`(last checked ${Datetime.timeAgo(codatIntegrationByUuid?.codatLastSync)})`}
                </Description>
              </Row>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Space height={8} />
              <Row>
                <SkeletonLoader width={100} height={8} />
              </Row>
            </React.Fragment>
          )}
        </Column>
      </Row>
      <Space height={20} />
      <ScrollView horizontal>
        <TableContainer style={{width: '1200px'}}>
          <AccountingExportsTable
            items={codatExportBatchesPaginatedList?.codatBatches || []}
            navigator={navigator}
            refetch={refetch}
            loading={loading}
            params={params}
          />
          <Space height={20} />
          <PaginationBar pagination={pagination} />
        </TableContainer>
      </ScrollView>
      <StartBatchExportModal
        isOpen={startBatchExportModal.isOpen}
        handleClose={startBatchExportModal.handleClose}
        key={startBatchExportModal.key}
        inProgressCodatExportBatch={inProgressCodatExportBatch}
        pendingCodatExportBatch={pendingCodatExportBatch}
        codatIntegration={viewingOrganization?.codatIntegrationByUuid}
        navigator={navigator}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyIntegrationsAccountingExportsPage = () => {
  const {navigator, params} = useNavigationDOM();
  return (
    <SettingsPage
      breadcrumbs={[
        {name: 'Settings', onPress: () => navigator.push('/settings')},
        {
          name: 'Company Settings',
          onPress: () => navigator.push('/settings/company/business-info'),
        },
        {name: 'Accounting', onPress: () => navigator.push('/settings/company/accounting')},
        {name: 'Accounting Exports'},
      ]}
      tabs={[
        {
          label: 'General',
          url: `/settings/company/accounting/${params.uuid}/general`,
        },
        {
          label: 'Import',
          url: `/settings/company/accounting/${params.uuid}/imports`,
        },
        {
          label: 'Logs',
          url: `/settings/company/accounting/${params.uuid}/logs`,
        },
        {
          label: 'Exports',
          url: `/settings/company/accounting/${params.uuid}/exports`,
        },
      ]}
    >
      <AccountingExportsContent navigator={navigator} params={params} />
    </SettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountingExportsContent.query = gql`
  ${usePagination.fragment}
  query AccountingExportsContent($pagination: PaginationInput!, $uuid: String!) {
    viewer {
      id
      viewingOrganization {
        id
        pendingCodatExportBatch(uuid: $uuid) {
          id
          codatBatchOperations {
            id
            invoiceExport {
              id
              invoice {
                id
                identifier
                uuid
              }
            }
            paymentExport {
              id
              payment {
                id
                name
                invoice {
                  id
                  uuid
                  identifier
                }
              }
            }
            clientExport {
              id
              client {
                id
                uuid
                name
              }
            }
          }
        }
        inProgressCodatExportBatch(uuid: $uuid) {
          id
          completedCodatBatchOperationCount
          codatBatchOperationCount
          codatBatchOperations {
            id
          }
        }
        codatIntegrationByUuid(uuid: $uuid) {
          id
          connectionStatus
          codatLastSync
        }
        codatExportBatchesPaginatedList(pagination: $pagination, uuid: $uuid) {
          paginationMetadata {
            currentPage
            totalResults
            ...usePagination
          }
          codatBatches: results {
            id

            completedCodatBatchOperationCount
            endTime
            failedCodatBatchOperationCount
            startTime
            status
            successfulCodatBatchOperationCount
            codatBatchOperationCount
            uuid
            codatBatchOperations {
              id
              invoiceExport {
                id
                invoice {
                  id
                  identifier
                  uuid
                }
              }
              paymentExport {
                id
                payment {
                  id
                  name
                  invoice {
                    id
                    uuid
                    identifier
                  }
                }
              }
              clientExport {
                id
                client {
                  id
                  uuid
                  name
                }
              }
            }
            failedInvoices {
              id
              uuid
              identifier
            }
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsCompanyIntegrationsAccountingExportsPage;
