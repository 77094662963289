// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, ScrollViewType, UrlFiltersType} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import Line from '@shared/design/components/Line';
import MobileCapacityCalendarHeader from 'modules/Calendar/Capacity/components/MobileCapacityCalendarHeader';
import MobileCapacityCalendarMonthDays from 'modules/Calendar/Capacity/components/MobileCapacityCalendarMonthDays';
import MobileCapacityCalendarMonthNavigator from 'modules/Calendar/Capacity/components/MobileCapacityCalendarMonthNavigator';

const MonthNavigatorContainer = Styled.View`
  align-items: center;
`;

const MobileCapacityCalendarMonth = ({
  organization,
  urlFilters,
  scrollView,
}: {
  organization: OrganizationModel;
  urlFilters: UrlFiltersType;
  scrollView: ScrollViewType;
}) => {
  const [todayPositionY, setTodayPositionY] = useState(0);

  return (
    <React.Fragment>
      <MobileCapacityCalendarHeader
        organization={organization}
        urlFilters={urlFilters}
        todayPositionY={todayPositionY}
        scrollView={scrollView}
      />
      <Line />
      <MobileCapacityCalendarMonthDays
        organization={organization}
        todayPositionY={todayPositionY}
        setTodayPositionY={setTodayPositionY}
        urlFilters={urlFilters}
        scrollView={scrollView}
      />
      <Line />
      <MonthNavigatorContainer>
        <Space height={16} />
        <MobileCapacityCalendarMonthNavigator urlFilters={urlFilters} scrollView={scrollView} />
        <Space height={24} />
      </MonthNavigatorContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileCapacityCalendarMonth.fragment = gql`
  ${MobileCapacityCalendarHeader.fragment}
  ${MobileCapacityCalendarMonthDays.fragment}

  fragment MobileCapacityCalendarMonth on Organization {
    id
    ...MobileCapacityCalendarHeader
    ...MobileCapacityCalendarMonthDays
  }
`;

export default MobileCapacityCalendarMonth;
