// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import JobTypeFeature from '@supermove/models/src/JobTypeFeature';
import {Typography, colors} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import JobTypeConfigs from '@shared/modules/Job/enums/JobTypeConfigs';
import JobTypeFeatureForm from '@shared/modules/Job/forms/JobTypeFeatureForm';
import useUpsertJobTypeFeatureMutation from '@shared/modules/Job/hooks/useUpsertJobTypeFeatureMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import SwitchField from 'modules/App/components/SwitchField';
import ApplyJobTypeConfigPopoverButton from 'modules/Organization/Settings/JobTypes/components/ApplyJobTypeConfigPopoverButton';
import JobTypeSettingsPageHeader from 'modules/Organization/Settings/JobTypes/components/JobTypeSettingsPageHeader';
import JobTypeTimesheetCategorySection from 'modules/Organization/Settings/JobTypes/components/JobTypeTimesheetCategorySection';

const Text = Styled.Text`
  ${Typography.Body3}
`;

const HeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label2}
;`;

const ActionContainer = Styled.View`
  align-items: center;
`;

const TableContainer = Styled.View`
  width: 1150px;
`;

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  padding-left: 24px;
  background-color: ${colors.gray.background};
`;

const ButtonRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 1150px;
`;

const ToggleSwitch = ({jobTypeFeature}: any) => {
  const jobTypeFeatureForm = JobTypeFeatureForm.edit(jobTypeFeature);

  const jobFeatureIsEnabledField = 'jobTypeFeatureForm.isEnabled';
  const jobFeatureIdField = 'jobTypeFeatureForm.jobTypeFeatureId';

  const {form, handleSubmit} = useUpsertJobTypeFeatureMutation({
    jobTypeFeatureForm,
    onSuccess: ({jobTypeFeature}: any) => {
      form.setFieldValue(jobFeatureIdField, jobTypeFeature.id);
      form.setFieldValue(jobFeatureIsEnabledField, jobTypeFeature.isEnabled);
    },
    onError: () => form.setFieldValue(jobFeatureIsEnabledField, !(form.values as any).isEnabled),
  });

  return (
    <SwitchField
      {...form}
      index={1}
      name={jobFeatureIsEnabledField}
      onChangeValue={() => {
        handleSubmit();
      }}
    />
  );
};

const JobTypeFeatureSectionActions = ({jobTypeFeature}: any) => {
  return (
    <ActionContainer>
      <ToggleSwitch jobTypeFeature={jobTypeFeature} />
    </ActionContainer>
  );
};

const getJobTypeFeatureColumnDefinition = () => [
  {
    width: 300,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobTypeFeature' implicitly has an... Remove this comment to see the full error message
    cellContent: ({item: jobTypeFeature}) => {
      return <Text numberOfLines={2}>{jobTypeFeature.name}</Text>;
    },
  },
  {
    width: 500,
    headerContent: () => {
      return <TableHeaderText>Description</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobTypeFeature' implicitly has an... Remove this comment to see the full error message
    cellContent: ({item: jobTypeFeature}) => {
      return <Text numberOfLines={3}>{jobTypeFeature.description}</Text>;
    },
  },
  {
    width: 200,
    headerContent: () => {
      return <TableHeaderText>Application</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobTypeFeature' implicitly has an... Remove this comment to see the full error message
    cellContent: ({item: jobTypeFeature}) => {
      return (
        <Text numberOfLines={1}>{JobTypeFeature.getCategoryDisplayValue(jobTypeFeature)}</Text>
      );
    },
  },
  {
    width: 150,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobTypeFeature' implicitly has an... Remove this comment to see the full error message
    cellContent: ({item: jobTypeFeature}) => {
      return <JobTypeFeatureSectionActions jobTypeFeature={jobTypeFeature} />;
    },
  },
];

const JobTypeGeneralFeatureTable = ({jobTypeFeatures}: any) => (
  <TableContainer>
    <Table
      columnDefinitions={getJobTypeFeatureColumnDefinition()}
      emptyStateText='No features to display'
      items={jobTypeFeatures}
      isDense
    />
  </TableContainer>
);

const JobTypeFeatureCategorySection = ({title, description, jobTypeFeatures}: any) => {
  return (
    <React.Fragment>
      <HeaderText>{title}</HeaderText>
      <Space height={12} />
      <Text>{description}</Text>
      <Space height={16} />
      <JobTypeGeneralFeatureTable jobTypeFeatures={jobTypeFeatures} />
    </React.Fragment>
  );
};

const JobTypeGeneralSection = ({jobTypeFeatures, jobType, refetch}: any) => {
  const isEnabledTimesheetsV2 = jobType.projectType.features.timesheetsV2;
  return (
    <React.Fragment>
      <ButtonRow>
        <ApplyJobTypeConfigPopoverButton
          jobType={jobType}
          refetch={refetch}
          defaultJobTypeConfigs={[JobTypeConfigs.JOB_TYPE_FEATURES.value]}
        />
      </ButtonRow>
      <JobTypeFeatureCategorySection
        title={'Office App'}
        description={'All settings enabled in this section will be applied to the Office App.'}
        jobTypeFeatures={jobTypeFeatures.filter(
          (jobTypeFeature: any) => jobTypeFeature.category === 'MANAGER',
        )}
      />
      <Space height={48} />
      <JobTypeFeatureCategorySection
        title={'Crew App'}
        description={'All settings enabled in this section will be applied to the Crew App.'}
        jobTypeFeatures={jobTypeFeatures.filter((jobTypeFeature: any) => {
          if (jobTypeFeature.kind === 'CREW_PER_MOVER_TIMESHEET' && isEnabledTimesheetsV2) {
            return false;
          }
          return jobTypeFeature.category === 'CREW';
        })}
      />
      <Space height={isEnabledTimesheetsV2 ? 24 : 48} />
      {isEnabledTimesheetsV2 && (
        <React.Fragment>
          <JobTypeTimesheetCategorySection
            jobTypeFeature={_.find(jobTypeFeatures, {kind: 'CREW_PER_MOVER_TIMESHEET'})}
            refetch={refetch}
          />
          <Space height={48} />
        </React.Fragment>
      )}
      <JobTypeFeatureCategorySection
        title={'Customer App'}
        description={'All settings enabled in this section will be applied to the Customer App.'}
        jobTypeFeatures={jobTypeFeatures.filter(
          (jobTypeFeature: any) => jobTypeFeature.category === 'CUSTOMER',
        )}
      />
      <Space height={48} />
    </React.Fragment>
  );
};

// NOTE(kevin): The job feature table displays existing jobTypeFeature
// as well as organization level job feature that do not yet exist as a jobTypeFeature.
const transformJobFeaturesWithOrganizationJobFeatures = ({
  baseJobFeatures,
  jobTypeFeatures,
  jobTypeId,
  organization,
}: any) => {
  // Specify the custom order for CREW job features
  const crewOrder = [
    'CREW_ALLOW_SKIP_DOCUMENTS',
    'CREW_PRE_MOVE_SKIP_PAYMENT',
    'CREW_EDIT_BILLING_AND_PAYMENTS',
    'CREW_PER_MOVER_TIMESHEET',
    'CREW_POST_MOVE_BILLING_AUTOMATIC_INVOICE',
    'CREW_HIDE_BILLING',
    'CREW_SET_START_TIME_ON_ARRIVAL',
    'CREW_SET_START_TIME_ON_LEAVE_WAREHOUSE',
    'CREW_PRE_MOVE_EDIT_BILL',
  ];

  // Sort the transformed result by category then name on return
  return _.sortBy(
    baseJobFeatures
      // We merge the organization job features with job type feature
      .reduce((mergedJobTypeFeatures: any, orgJobFeatureItem: any) => {
        const hiddenJobTypeFeatureKinds = [
          'CREW_ADDITIONAL_BILLING',
          'CREW_DURING_MOVE_BILLING',
          'MANAGER_ASSIGN_CREW_LARGE_CREW',
          'MANAGER_CONFIRMATION',
          'MANAGER_QUOTE',
        ];
        // Remove deprecated Job Type Features from the FE, we can't do this on the backend
        // as we can't remove a kind from config if there are existing job features with the kind
        if (_.includes(hiddenJobTypeFeatureKinds, orgJobFeatureItem.kind)) {
          return mergedJobTypeFeatures;
        }

        // Find job type feature exist for given organization job features
        const jobTypeFeature = _.find(jobTypeFeatures, {
          kind: orgJobFeatureItem.kind,
        });

        if (jobTypeFeature) {
          return [
            ...mergedJobTypeFeatures,
            {
              ...jobTypeFeature,
              orderIndex:
                orgJobFeatureItem.category === 'CREW'
                  ? _.indexOf(crewOrder, orgJobFeatureItem.kind)
                  : null,
            },
          ];
        } else {
          return [
            ...mergedJobTypeFeatures,
            {
              kind: orgJobFeatureItem.kind,
              name: orgJobFeatureItem.name,
              description: orgJobFeatureItem.description,
              category: orgJobFeatureItem.category,
              isEnabled: false,
              jobTypeId,
              organizationId: organization.id,
              // If the job feature is a CREW feature, we need to sort it by the custom order
              orderIndex:
                orgJobFeatureItem.category === 'CREW'
                  ? _.indexOf(crewOrder, orgJobFeatureItem.kind)
                  : null,
            },
          ];
        }
      }, []),
    ['orderIndex', 'category', 'name'],
  );
};
const JobTypeGeneralSettingsContent = ({jobTypeUuid}: any) => {
  const {loading, data, refetch} = useQuery(JobTypeGeneralSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobTypeUuid,
    },
  });

  if (loading) {
    return <PageLoadingIndicator />;
  }

  const {organization, jobTypeFeatures} = data.jobTypeByUuid;

  return (
    <PageContainer>
      <JobTypeSettingsPageHeader jobType={data.jobTypeByUuid} />
      <ContentContainer>
        <Space height={24} />
        <ScrollView horizontal contentContainerStyle={{flexGrow: 1}}>
          <ScrollView>
            <JobTypeGeneralSection
              jobTypeFeatures={transformJobFeaturesWithOrganizationJobFeatures({
                baseJobFeatures: organization.jobFeatures,
                jobTypeFeatures,
                jobTypeId: data.jobTypeByUuid.id,
                organization,
              })}
              jobType={data.jobTypeByUuid}
              refetch={refetch}
            />
          </ScrollView>
        </ScrollView>
        <Space height={48} />
      </ContentContainer>
    </PageContainer>
  );
};

const JobTypeGeneralSettingsPage = () => {
  const {params} = useNavigationDOM();
  return (
    <SidebarPageV2 selected={'settings'}>
      <JobTypeGeneralSettingsContent jobTypeUuid={params.jobTypeUuid} />
    </SidebarPageV2>
  );
};

JobTypeGeneralSettingsPage.query = gql`
  ${ApplyJobTypeConfigPopoverButton.fragment}
  ${JobTypeFeature.getCategoryDisplayValue.fragment}
  ${JobTypeSettingsPageHeader.fragment}
  ${JobTypeTimesheetCategorySection.fragment}

  query JobTypeGeneralSettingsPage(
    $jobTypeUuid: String!,
  ) {
    ${gql.query}
    jobTypeByUuid(jobTypeUuid: $jobTypeUuid) {
      id
      name
      organization {
        id
        jobFeatures {
          kind
          name
          description
          category
        }
      }
      projectType {
        id
        features {
          timesheetsV2
        }
      }
      jobTypeFeatures {
        id
        kind
        name
        description
        isEnabled
        jobTypeId
        organizationId
        ...JobTypeFeature_getCategoryDisplayValue
        ...JobTypeTimesheetCategorySection
      }
      ...ApplyJobTypeConfigPopoverButton
      ...JobTypeSettingsPageHeader
    }
  }
`;

export default JobTypeGeneralSettingsPage;
