// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight} from '@supermove/styles';

// App
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

const Container = Styled.View`
  display: block;
  width: 100%;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
`;

type OwnProps = {
  job: any;
  showBillRules?: boolean;
  showPayments?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentBillingInformation.defaultProps;

// @ts-expect-error TS(7022): 'DocumentBillingInformation' implicitly has type '... Remove this comment to see the full error message
const DocumentBillingInformation = ({showBillRules, showPayments, job}: Props) => {
  return (
    <Container>
      <Title>Billing Items</Title>
      <React.Fragment>
        <BillingProjectBillsListV1
          isDocument
          showBillRules={showBillRules}
          project={job.project}
          aggregateBill={job.project.currentAggregateBill}
        />
        {showPayments && (
          <BillingProjectPaymentsList aggregateBill={job.project.currentAggregateBill} />
        )}
      </React.Fragment>
    </Container>
  );
};

DocumentBillingInformation.defaultProps = {
  showBillRules: false,
  showPayments: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentBillingInformation.fragment = gql`
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}

  fragment DocumentBillingInformation on Job {
    id
    project {
      id
      currentAggregateBill {
        ...BillingProjectBillsListV1_AggregateBill
        ...BillingProjectPaymentsList_AggregateBill
      }
      ...BillingProjectBillsListV1
    }
    currentBill {
      id
      hasBillRulesForCustomer
      hasPayments
    }
  }
`;

export default DocumentBillingInformation;
