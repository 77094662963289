// Libraries
import React from 'react';

// Supermove
import {Popover, ScrollView, Space, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const PopoverContentContainer = Styled.View`
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width}px;
  border-radius: 4px;
  position: absolute;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const MenuItemButton = Styled.ButtonV2`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isHighlighted' does not exist on type 'T... Remove this comment to see the full error message
    isHighlighted,
  }) => (isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 8px;
  padding-horizontal: 16px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

type OwnMenuItemProps = {
  onPress: (...args: any[]) => any;
  color?: string;
};

// @ts-expect-error TS(2456): Type alias 'MenuItemProps' circularly references i... Remove this comment to see the full error message
type MenuItemProps = OwnMenuItemProps & typeof MenuItem.defaultProps;

// @ts-expect-error TS(7022): 'MenuItem' implicitly has type 'any' because it do... Remove this comment to see the full error message
const MenuItem = ({onPress, color, disabled, children}: MenuItemProps) => {
  const {isHovered, ref} = useHover();
  return (
    <MenuItemButton onPress={onPress} isHighlighted={isHovered} ref={ref} disabled={disabled}>
      <MenuItemText color={color}>{children}</MenuItemText>
    </MenuItemButton>
  );
};

type OwnActionMenuPopoverProps = {
  popover: any;
  handleOpen?: (...args: any[]) => any;
  handleClose?: (...args: any[]) => any;
  placement?: string;
  offset?: number[];
  width?: number;
  scrollStyle?: any;
};

// @ts-expect-error TS(2456): Type alias 'ActionMenuPopoverProps' circularly ref... Remove this comment to see the full error message
type ActionMenuPopoverProps = OwnActionMenuPopoverProps & typeof ActionMenuPopover.defaultProps;

// @ts-expect-error TS(7022): 'ActionMenuPopover' implicitly has type 'any' beca... Remove this comment to see the full error message
const ActionMenuPopover = ({
  popover,
  handleOpen,
  handleClose,
  placement,
  width,
  offset,
  scrollStyle,
  children,
}: ActionMenuPopoverProps) => {
  return (
    <Popover
      placement={placement}
      isOpen={popover.isOpen}
      handleOpen={handleOpen || popover.handleOpen}
      handleClose={handleClose || popover.handleClose}
      reference={popover.ref}
      offset={offset}
    >
      <PopoverContentContainer width={width}>
        <ScrollView style={scrollStyle}>
          <Space height={8} />
          {children}
          <Space height={8} />
        </ScrollView>
      </PopoverContentContainer>
    </Popover>
  );
};

ActionMenuPopover.MenuItem = MenuItem;

ActionMenuPopover.defaultProps = {
  handleOpen: null,
  handleClose: null,
  placement: Popover.Positions.Auto,
  offset: [0, 4],
  width: 120,
  scrollStyle: {},
};

MenuItem.defaultProps = {
  color: colors.gray.primary,
};

export default ActionMenuPopover;
