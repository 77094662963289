// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import PreviewBillItemForm from '@shared/modules/Billing/forms/PreviewBillItemForm';
import UpdateBillItemAndValuesForm from '@shared/modules/Billing/forms/UpdateBillItemAndValuesForm';
import UpdateValuesForm from '@shared/modules/Billing/forms/UpdateValuesForm';
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import usePreviewBillItemMutation from '@shared/modules/Billing/hooks/usePreviewBillItemMutation';
import useUpdateBillItemAndValuesMutation from '@shared/modules/Billing/hooks/useUpdateBillItemAndValuesMutation';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import BillingValueFields from 'modules/Project/Billing/components/BillingValueFields';
import EditBillItemFields from 'modules/Project/Billing/components/EditBillItemFields';

const Line = Styled.View`
  background-color: ${colors.gray.border};
  height: 1px;
  width: 100%;
`;

const Container = Styled.View`
`;

const TotalContainer = Styled.View`
  min-height: 60px;
  justify-content: center;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 24 : 0)}}
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const SubHeading = Styled.Text`
  ${Typography.Responsive.Subheading}
`;

const TotalText = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'isLabel' does not exist on type 'ThemePr... Remove this comment to see the full error message
    isLabel,
  }) => (isLabel ? Typography.Responsive.Label : Typography.Responsive.Body)}
  ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => (color ? `color: ${color};` : '')}
`;

const SkeletonField = () => (
  <Container>
    <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.Text} />
    <Space height={8} />
    <SkeletonLoader isFullWidth height={28} />
  </Container>
);

const LoadingComponent = () => {
  const responsive = useResponsive();
  return (
    <Container style={{flex: 1}}>
      <Container style={{padding: responsive.desktop ? 24 : 0}}>
        <SkeletonField />
        <Space height={16} />
        <SkeletonField />
        <Space height={16} />
        <SkeletonField />
        <Space height={24} />
        <Line />
        <Space height={24} />
        <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.SubheadingText} />
        <Space height={16} />
        <SkeletonLoader isFullWidth height={50} />
      </Container>
      <Space style={{flex: 1}} />
      <Line />
      <TotalContainer responsive={responsive}>
        <Space height={16} />
        <Row>
          <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.SubheadingText} />
          <Space style={{flex: 1}} />
          <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.SubheadingText} />
        </Row>
        <Space height={16} />
      </TotalContainer>
    </Container>
  );
};

const generatePreview = ({updateValuesObject, values, previewBillItemMutation}: any) => {
  const updatedValueIds = _.keys(updateValuesObject);
  const updatedValueForms = _.values(updateValuesObject);
  const missingValues = values.filter(({id}: any) => !updatedValueIds.includes(_.toString(id)));
  const missingValueForms = missingValues.map((value: any) =>
    ValueForm.toForm(ValueForm.edit(value)),
  );
  const valueForms = [...updatedValueForms, ...missingValueForms];
  previewBillItemMutation.form.setFieldValue('previewBillItemForm.valueForms', valueForms);
  setTimeout(() => previewBillItemMutation.handleSubmit(), 0);
};

const VariableActions = ({
  variableActionsPopover,
  isDisabledValuesHidden,
  toggleIsDisabledValuesHidden,
}: any) => {
  return (
    <ActionMenuPopover
      popover={variableActionsPopover}
      width={200}
      sheetLabel={'Actions'}
      actions={[
        {
          text: `${isDisabledValuesHidden ? 'Show' : 'Hide'} system variables`,
          onPress: () => toggleIsDisabledValuesHidden(),
        },
      ]}
    >
      <TertiaryButton onPress={variableActionsPopover.handleToggle}>
        <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
      </TertiaryButton>
    </ActionMenuPopover>
  );
};

const TotalRow = ({
  responsive,
  title,
  min,
  max,
  isLoading,
  showTbd,
  isLabel,
  valueOverride,
  color,
}: any) => {
  return (
    <Row>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <TotalText isLabel={isLabel} responsive={responsive} color={color}>
        {title}
      </TotalText>
      <Space style={{flex: 1, minWidth: 16}} />
      {isLoading ? (
        <SkeletonLoader width={100} height={SkeletonLoader.HEIGHT.Text} />
      ) : (
        // @ts-expect-error TS(2769): No overload matches this call.
        <TotalText isLabel={isLabel} responsive={responsive} color={color}>
          {/* @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message */}
          {valueOverride || (showTbd ? 'TBD' : Currency.formatRange({min, max}))}
        </TotalText>
      )}
    </Row>
  );
};

const Totals = ({
  responsive,
  minSubtotal,
  maxSubtotal,
  showTbd,
  minTaxTotal,
  maxTaxTotal,
  minTotal,
  maxTotal,
  isLoading,
  isTaxable,
  salesTaxRate,
}: any) => {
  return (
    <TotalContainer responsive={responsive}>
      <Space height={14} />
      <TotalRow
        responsive={responsive}
        title={'Subtotal'}
        min={minSubtotal}
        max={maxSubtotal}
        isLoading={isLoading}
        showTbd={showTbd}
        isLabel
        color={colors.gray.secondary}
      />
      <Space height={6} />
      <TotalRow
        responsive={responsive}
        title={`Sales Tax (${Percent.display(salesTaxRate)})`}
        min={minTaxTotal}
        max={maxTaxTotal}
        isLoading={isLoading}
        showTbd={showTbd}
        valueOverride={!isTaxable ? 'N/A' : null}
        color={colors.gray.secondary}
      />
      <Space height={6} />
      <TotalRow
        responsive={responsive}
        title={'Total'}
        min={minTotal}
        max={maxTotal}
        isLoading={isLoading}
        showTbd={showTbd}
        isLabel
      />
      <Space height={14} />
    </TotalContainer>
  );
};

const EditBillItemAndValuesDrawerFields = ({
  organizationId,
  values,
  form,
  field,
  updateValuesObject,
  isEnabledTbdBillItems,
  salesTaxRate,
  nameFormula,
  amountFormula,
  minQuantityFormula,
  maxQuantityFormula,
}: any) => {
  const responsive = useResponsive();
  const variableActionsPopover = usePopover();
  const [isDisabledValuesHidden, setIsDisabledValuesHidden] = useState(true);
  const billItemFormField = `${field}.billItemForm`;
  const billItemForm = _.get(form.values, billItemFormField);
  const {minSubtotal, maxSubtotal, showTbd, minTaxTotal, maxTaxTotal, minTotal, maxTotal} =
    BillItemForm.getSubtotals(billItemForm, {salesTaxRate});
  const {hasNameFormula, hasQuantityFormula, hasAmountFormula} =
    BillItemForm.getHasFormulas(billItemForm);
  const previewBillItemForm = PreviewBillItemForm.editFromBillItemForm(billItemForm, {
    organizationId,
    valueForms: _.values(updateValuesObject),
  });
  const previewBillItemMutation = usePreviewBillItemMutation({
    previewBillItemForm,
    // @ts-expect-error TS(7031): Binding element 'billItemPreview' implicitly has a... Remove this comment to see the full error message
    onSuccess: ({billItem: billItemPreview}) => {
      if (hasNameFormula) {
        form.setFieldValue(`${billItemFormField}.name`, billItemPreview.name);
      }
      if (hasAmountFormula) {
        if (isEnabledTbdBillItems) {
          form.setFieldValue(
            `${billItemFormField}.amount`,
            billItemPreview.amount === null ? '' : Currency.toForm(billItemPreview.amount),
          );
        } else {
          form.setFieldValue(
            `${billItemFormField}.amount`,
            Currency.toForm(billItemPreview.amount),
          );
        }
      }
      if (hasQuantityFormula) {
        if (isEnabledTbdBillItems) {
          form.setFieldValue(
            `${billItemFormField}.minQuantity`,
            billItemPreview.minQuantity === null ? '' : String(billItemPreview.minQuantity),
          );
          form.setFieldValue(
            `${billItemFormField}.maxQuantity`,
            billItemPreview.maxQuantity === null ? '' : String(billItemPreview.maxQuantity),
          );
        } else {
          form.setFieldValue(
            `${billItemFormField}.minQuantity`,
            billItemPreview.minQuantity ? String(billItemPreview.minQuantity) : '',
          );
          form.setFieldValue(
            `${billItemFormField}.maxQuantity`,
            billItemPreview.maxQuantity ? String(billItemPreview.maxQuantity) : '',
          );
        }
      }
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <React.Fragment>
      <ScrollView
        style={{flex: 1}}
        contentContainerStyle={{paddingHorizontal: responsive.desktop ? 24 : 0}}
      >
        <Space height={24} />
        <EditBillItemFields
          form={form}
          field={billItemFormField}
          previewBillItemMutation={previewBillItemMutation}
          nameFormula={nameFormula}
          amountFormula={amountFormula}
          minQuantityFormula={minQuantityFormula}
          maxQuantityFormula={maxQuantityFormula}
        />
        <Space height={24} />
        <Line />
        <Space height={24} />
        <Row>
          <SubHeading responsive={responsive}>Variables</SubHeading>
          <Space style={{flex: 1}} />
          <VariableActions
            variableActionsPopover={variableActionsPopover}
            isDisabledValuesHidden={isDisabledValuesHidden}
            toggleIsDisabledValuesHidden={() => setIsDisabledValuesHidden(!isDisabledValuesHidden)}
          />
        </Row>
        <Space height={16} />
        <WarningCallout
          text={'Changes to these values may result in updates to other bill items.'}
        />
        <Space height={16} />
        <BillingValueFields
          orderedValues={values}
          updateValuesObject={updateValuesObject}
          onBlur={() => generatePreview({updateValuesObject, values, previewBillItemMutation})}
          numColumns={2}
          isDisabledValuesHidden={isDisabledValuesHidden}
        />
        <Space height={24} />
      </ScrollView>
      <Line />
      <Totals
        responsive={responsive}
        minSubtotal={minSubtotal}
        maxSubtotal={maxSubtotal}
        showTbd={showTbd}
        minTaxTotal={minTaxTotal}
        maxTaxTotal={maxTaxTotal}
        minTotal={minTotal}
        maxTotal={maxTotal}
        isLoading={previewBillItemMutation.submitting}
        isTaxable={_.get(form.values, `${billItemFormField}.isTaxable`)}
        salesTaxRate={salesTaxRate}
      />
    </React.Fragment>
  );
};

const EditBillItemAndValuesDrawerLogic = ({
  organizationId,
  bill,
  nameFormula,
  amountFormula,
  minQuantityFormula,
  maxQuantityFormula,
  form,
  field,
  updateValuesObject,
  salesTaxRate,
}: any) => {
  const {project, job} = bill || {};
  const {values: projectValues} = project || {};
  const {values: jobValues} = job || {};
  const values = [...(projectValues || []), ...(jobValues || [])];
  const variableIdsUsedInFormulas = [
    ...(nameFormula?.variablesUsedInFormula || []),
    ...(amountFormula?.variablesUsedInFormula || []),
    ...(minQuantityFormula?.variablesUsedInFormula || []),
    ...(maxQuantityFormula?.variablesUsedInFormula || []),
  ].map(({id}) => id);
  const filteredValues = _.filter(values, ({variableId}) =>
    variableIdsUsedInFormulas.includes(_.toString(variableId)),
  );
  return (
    <EditBillItemAndValuesDrawerFields
      organizationId={organizationId}
      values={filteredValues}
      form={form}
      field={field}
      updateValuesObject={updateValuesObject}
      isEnabledTbdBillItems={bill.organization?.features.isEnabledTbdBillItems}
      salesTaxRate={salesTaxRate}
      nameFormula={nameFormula}
      amountFormula={amountFormula}
      minQuantityFormula={minQuantityFormula}
      maxQuantityFormula={maxQuantityFormula}
    />
  );
};

const EditBillItemAndValuesDrawerContent = ({
  isOpen,
  handleClose,
  bill,
  billItemForm,
  refetch,
  updateValuesObject,
  salesTaxRate,
}: any) => {
  const updateBillItemandValuesSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Changes saved.',
  });
  const updateValuesForm = UpdateValuesForm.edit(bill.project);
  const updateBillItemAndValuesForm = UpdateBillItemAndValuesForm.new({
    billItemForm,
    updateValuesForm,
  });
  const {form, handleSubmit} = useUpdateBillItemAndValuesMutation({
    updateBillItemAndValuesForm,
    onSuccess: () => {
      handleClose();
      refetch();
      updateBillItemandValuesSuccessToast.handleToast();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const {nameFormulaId, amountFormulaId, minQuantityFormulaId, maxQuantityFormulaId} = billItemForm;

  // We query using the underlying formulas and filter values on the frontend since we don't have an existing
  // bill item when creating a new one. The bill item form has the formula ids regardless.
  const {loading, data} = useQuery(EditBillItemAndValuesDrawer.query, {
    fetchPolicy: 'network-only',
    variables: {
      nameFormulaId,
      amountFormulaId,
      minQuantityFormulaId,
      maxQuantityFormulaId,
      billUuid: bill.uuid,
    },
    skip: !isOpen,
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Bill Item'}
      primaryActionText={'Save'}
      shouldCloseOnClickOutside={false}
      handleSubmit={() =>
        // UpdateValuesForm.handleSubmit prepares the updateValuesForm for submit, ensuring that all onBlur handlers have run
        // The handleSubmit we're providing calls useUpdateBillItemAndValuesMutation.handleSubmit
        requestAnimationFrame(() => {
          UpdateValuesForm.handleSubmit({
            form,
            field: 'updateBillItemAndValuesForm.updateValuesForm',
            updateValuesObject,
            setIsSubmitting,
            handleSubmit,
          });
        })
      }
      isDisabled={loading}
      isSubmitting={isSubmitting}
      bodyStyle={{
        flex: 1,
        padding: 0,
        overflowY: 'hidden',
      }}
    >
      <Loading loading={loading} as={LoadingComponent}>
        {() => {
          return (
            <EditBillItemAndValuesDrawerLogic
              organizationId={bill.organizationId}
              data={data}
              bill={data?.bill}
              nameFormula={data?.nameFormula}
              amountFormula={data?.amountFormula}
              minQuantityFormula={data?.minQuantityFormula}
              maxQuantityFormula={data?.maxQuantityFormula}
              form={form}
              field={'updateBillItemAndValuesForm'}
              updateValuesObject={updateValuesObject}
              salesTaxRate={salesTaxRate}
            />
          );
        }}
      </Loading>
    </DrawerWithAction>
  );
};
const EditBillItemAndValuesDrawer = ({isOpen, handleClose, bill, billItemForm, refetch}: any) => {
  // updateValuesObject is defined outside of EditBillItemAndValuesDrawerContent
  // so that state updates do not reset the object.
  const updateValuesObject = {};
  return (
    <EditBillItemAndValuesDrawerContent
      isOpen={isOpen}
      handleClose={handleClose}
      bill={bill}
      billItemForm={billItemForm}
      refetch={refetch}
      updateValuesObject={updateValuesObject}
      salesTaxRate={bill.project.salesTaxRate}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemAndValuesDrawer.fragment = gql`
  ${UpdateValuesForm.edit.fragment}
  fragment EditBillItemAndValuesDrawer on Bill {
    id
    uuid
    organizationId
    project {
      id
      salesTaxRate
      ...UpdateValuesForm_edit
    }
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
      }
    }
  }
`;

EditBillItemAndValuesDrawer.query = gql`
  ${BillingValueFields.fragment}
  ${EditBillItemFields.fragment}
  ${ValueForm.edit.fragment}
  query EditBillItemAndValuesDrawer($nameFormulaId: Int, $amountFormulaId: Int, $minQuantityFormulaId: Int, $maxQuantityFormulaId: Int, $billUuid: String!){
    ${gql.query}
    nameFormula: formula(formulaId: $nameFormulaId) {
      id
      variablesUsedInFormula {
        id
      }
      ...EditBillItemFields_Formula
    }
    amountFormula: formula(formulaId: $amountFormulaId) {
      id
      variablesUsedInFormula {
        id
      }
      ...EditBillItemFields_Formula
    }
    minQuantityFormula: formula(formulaId: $minQuantityFormulaId) {
      id
      variablesUsedInFormula {
        id
      }
      ...EditBillItemFields_Formula
    }
    maxQuantityFormula: formula(formulaId: $maxQuantityFormulaId) {
      id
      variablesUsedInFormula {
        id
      }
      ...EditBillItemFields_Formula
    }
    bill(uuid: $billUuid) {
      id
      project {
        id
        values {
          id
          ...BillingValueFields
          ...ValueForm_edit
        }
      }
      job {
        id
        values {
          id
          ...BillingValueFields
          ...ValueForm_edit
        }
      }
    }
  }
`;

export default EditBillItemAndValuesDrawer;
