// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';

const DocumentFieldInput = ({projectType, form, index}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Document'}
        index={index}
        name={'confirmationStepForm.value'}
        component={DropdownInput}
        isRequired
        input={{
          options: _.map(
            _.orderBy(
              _.filter(
                projectType.organization.documentTemplates,
                (documentTemplate) => documentTemplate.activeOrMostRecentDocumentTemplateVersion,
              ),
              (documentTemplate) => _.toLower(documentTemplate.name),
              ['asc'],
            ),
            (documentTemplate) => {
              return {
                label: documentTemplate.name,
                value: documentTemplate.identifier,
              };
            },
          ),
          placeholder: 'Select document',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          onChangeValue: (value: any, documentTemplate: any) => {
            form.setFieldValue('confirmationStepForm.value', value);
            form.setFieldValue('confirmationStepForm.valueTwo', documentTemplate.label);
          },
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={form.values.confirmationStepForm.showByDefault}
        handleToggle={(isChecked) => {
          form.setFieldValue('confirmationStepForm.showByDefault', isChecked);
        }}
        label={'Include by default'}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const QuoteStepModalFields = ({form, projectType}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Step'}
        index={0}
        name={'confirmationStepForm.kind'}
        component={DropdownInput}
        isRequired
        input={{
          options: ConfirmationStepKind.getDropdownOptions({
            kinds: ConfirmationStepKind.QUOTE_STEPS,
          }),
          placeholder: 'Select step',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          onChangeValue: (value: any, quoteStep: any) => {
            form.setFieldValue('confirmationStepForm.kind', value);
            form.setFieldValue('confirmationStepForm.value', '');
            form.setFieldValue('confirmationStepForm.valueTwo', '');
            form.setFieldValue('confirmationStepForm.name', quoteStep.label);
          },
        }}
      />
      <Space height={16} />
      {form.values.confirmationStepForm.kind === ConfirmationStepKind.QUOTE_STEP_DOCUMENT && (
        <DocumentFieldInput form={form} projectType={projectType} index={1} />
      )}
      <FieldInput
        {...form}
        label={'Button Text'}
        index={2}
        name={'confirmationStepForm.actionText'}
        isRequired
        input={{
          placeholder: 'Select step',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
QuoteStepModalFields.fragment = gql`
  fragment QuoteStepModalFields on ProjectType {
    id
    organization {
      id
      documentTemplates: documentTemplatesByCategory(categories: ["JOB"]) {
        id
        name
        identifier
        activeOrMostRecentDocumentTemplateVersion {
          id
        }
      }
    }
  }
`;

export default QuoteStepModalFields;
