// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import ReferralSourceForm from '@shared/modules/Company/forms/ReferralSourceForm';
import useUpdateOrganizationReferralSourcesMutation from '@shared/modules/Company/hooks/useUpdateOrganizationReferralSourcesMutation';

const RemoveReferralTypeModal = ({
  removeReferralTypeModal,
  refetch,
  referral,
  referralSources,
  organizationId,
}: any) => {
  const referralSourceForms = [
    ...referralSources
      .filter((referralSource: any) => referralSource.name !== referral.name)
      .map((referralSource: any) => ReferralSourceForm.edit(referralSource)),
  ];

  const {handleSubmit} = useUpdateOrganizationReferralSourcesMutation({
    organizationReferralSourcesForm: {
      organizationId,
      referralSourceForms,
    },
    onSuccess: () => {
      refetch();
      removeReferralTypeModal.handleClose();
    },
    onError: (error: any) => console.log(error),
  });

  return (
    <DeleteModal
      handleDelete={handleSubmit}
      handleClose={removeReferralTypeModal.handleClose}
      isOpen={removeReferralTypeModal.isOpen}
      title={`Remove ${referral.name}?`}
      subtitle={'Removing will not impact any existing projects connected to this referral source.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RemoveReferralTypeModal.fragment = gql`
  fragment RemoveReferralTypeModal on Organization {
    id
    settings {
      id
      referralSources {
        name
      }
    }
  }
`;

export default RemoveReferralTypeModal;
