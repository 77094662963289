// Libraries
import React from 'react';

// Supermove
import {DropdownInput, Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import SlotTypeForm from '@shared/modules/Dispatch/forms/SlotTypeForm';
import useUpdateSlotTypeMutation from '@shared/modules/Dispatch/hooks/useUpdateSlotTypeMutation';
import {PageLoadingIndicator} from 'modules/App/components';

const Indicator = () => {
  return (
    <React.Fragment>
      <Space height={48} />
      <PageLoadingIndicator />
      <Space height={48} />
    </React.Fragment>
  );
};

const UpdateSlotTypeModalContent = ({slotType, organization, handleClose, refetch}: any) => {
  const slotTypeForm = SlotTypeForm.edit(slotType);
  const {form, submitting, handleSubmit} = useUpdateSlotTypeMutation({
    slotTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  const allTruckOptions = [...organization.activeTrucks, ...organization.overflowTrucks].map(
    ({id, name}) => {
      return {label: name, value: parseInt(id, 10)};
    },
  );
  const activeDriverOptions = organization.activeDrivers.map(({id, fullName}: any) => {
    return {label: fullName, value: parseInt(id, 10)};
  });
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={0}
        name={'displayIndex'}
        label={'Slot Name'}
        input={{
          disabled: true,
          placeholder: `Slot ${slotType.index}`,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={1}
        component={DropdownInput}
        name={'slotTypeForm.truckId'}
        label={'Truck'}
        input={{
          autoFocus: true,
          isSearchable: true,
          isClearable: true,
          placeholder: 'Assign default truck for this slot',
          options: allTruckOptions,
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        component={DropdownInput}
        name={'slotTypeForm.driverId'}
        label={'Driver'}
        input={{
          isSearchable: true,
          isClearable: true,
          placeholder: 'Assign default driver for this slot',
          options: activeDriverOptions,
          setFieldValue: form.setFieldValue,
          style: {
            flex: 1,
          },
        }}
        style={{flex: 1}}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={4} />
        <SmallModal.Button
          color={colors.blue.interactive}
          isSubmitting={submitting}
          onPress={handleSubmit}
        >
          Save
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const UpdateSlotTypeModal = ({slotTypeId, isOpen, handleClose, refetch}: any) => {
  const {loading, data} = useQuery(UpdateSlotTypeModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      id: slotTypeId,
    },
  });

  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <React.Fragment>
        <SmallModal.BoldText>Update Slot</SmallModal.BoldText>
        <Space height={16} />
        <SmallModal.Text>
          Add slot to the dispatch calendar and assign a default truck or driver.
        </SmallModal.Text>
        <Space height={16} />
        <Loading loading={loading} as={Indicator}>
          {() =>
            data ? (
              <UpdateSlotTypeModalContent
                slotType={data.slotType}
                organization={data.slotType.organization}
                handleClose={handleClose}
                refetch={refetch}
              />
            ) : null
          }
        </Loading>
      </React.Fragment>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSlotTypeModal.query = gql`
  ${SlotTypeForm.edit.fragment}

  query UpdateSlotTypeModal($id: Int!) {
    ${gql.query}
    slotType(id: $id) {
      id
      organization {
        id
        activeTrucks {
          id
          name
        }
        overflowTrucks {
          id
          name
        }
        activeDrivers {
          id
          fullName
        }
      }
      ...SlotTypeForm_edit
    }
  }
`;

export default UpdateSlotTypeModal;
