// Libraries
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Project} from '@supermove/models';
import {colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha((props as any).color, 0.15)};
`;

const Text = Styled.H7`
  margin-left: 8px;
  color: ${colors.gray.primary};
`;

type OwnProps = {
  project: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ProjectSalesStatusBadge.defaultProps;

// @ts-expect-error TS(7022): 'ProjectSalesStatusBadge' implicitly has type 'any... Remove this comment to see the full error message
const ProjectSalesStatusBadge = ({project}: Props) => {
  const salesStatus = Project.getSalesStatus(project);
  const salesStatusText = Project.getSalesStatusText(project);
  const color = Project.getSalesStatusColor(salesStatus);
  const icon = Project.getSalesStatusIcon(salesStatus);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container color={color}>
      <Icon
        color={color}
        size={Icon.Sizes.Large}
        // @ts-expect-error TS(2322): Type '"" | "calendar-check" | "trophy"' is not ass... Remove this comment to see the full error message
        source={icon}
        style={{
          width: 20,
          marginBottom: 2,
        }}
      />
      <Text>{salesStatusText}</Text>
    </Container>
  );
};

ProjectSalesStatusBadge.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectSalesStatusBadge.fragment = gql`
  ${Project.getSalesStatus.fragment}
  ${Project.getSalesStatusText.fragment}

  fragment ProjectSalesStatusBadge on Project {
    id
    ...Project_getSalesStatus
    ...Project_getSalesStatusText
  }
`;

export default ProjectSalesStatusBadge;
