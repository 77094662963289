// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';

// App
import Checkbox from '@shared/design/components/Checkbox';

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const getBulkActionCheckboxColumnDefinition = ({
  isEdit,
  form,
  field,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return {
    width: 50,
    headerContent: () => {
      if (!isEdit) {
        return null;
      }
      const numberOfTotalForms = _.size(_.get(form.values, field, []));
      const isAllSelected = _.size(bulkSelectedFormIndices) === numberOfTotalForms;
      return (
        <CenteredCell>
          <Checkbox
            isChecked={isAllSelected}
            handleToggle={() => {
              if (isAllSelected) {
                setBulkSelectedFormIndices([]);
              } else {
                setBulkSelectedFormIndices(_.range(0, numberOfTotalForms));
              }
            }}
          />
        </CenteredCell>
      );
    },
    viewCellContent: () => {},
    editCellContent: ({rowIndex}: any) => {
      const isSelected = _.includes(bulkSelectedFormIndices, rowIndex);
      return (
        <CenteredCell>
          <Checkbox
            isChecked={isSelected}
            handleToggle={() => {
              setBulkSelectedFormIndices(_.xor(bulkSelectedFormIndices, [rowIndex]));
            }}
          />
        </CenteredCell>
      );
    },
    footerContent: () => {},
  };
};

export default getBulkActionCheckboxColumnDefinition;
