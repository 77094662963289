// Libraries
import React from 'react';

// Supermove
import {Styled, Space, Icon} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import InputIsRequiredCheckBoxV1 from 'modules/Organization/Settings/Document/components/InputIsRequiredCheckBoxV1';

const TextInput = Styled.TextInput`
  ${Typography.Body3}
  border-width: 1px;
  border-color: ${colors.gray.border};
  width: 100%;
  padding-vertical: 9px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex-direction: column;
  flex: 1;
`;

const DeleteFieldColumn = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-left: 16px;
`;

const addFieldInput = ({setDraftDocumentContentJson, documentItemIndex}: any) => {
  setDraftDocumentContentJson((prevState: any) => {
    prevState.documentItems[documentItemIndex].input.metadata.fields.push({
      label: '',
      description: '',
      is_hidden: false,
    });
    prevState.documentItems[documentItemIndex].input.value.values.push('');
    return {
      ...prevState,
    };
  });
};

const removeFieldInput = ({setDraftDocumentContentJson, documentItemIndex, fieldIndex}: any) => {
  setDraftDocumentContentJson((prevState: any) => {
    prevState.documentItems[documentItemIndex].input.metadata.fields.splice(fieldIndex, 1);
    prevState.documentItems[documentItemIndex].input.value.values.splice(fieldIndex, 1);
    return {
      ...prevState,
    };
  });
};

const InputTextInputEditorV1 = ({
  documentItem,
  documentItemIndex,
  setDraftDocumentContentJson,
}: any) => {
  const {fields} = documentItem.input.metadata;
  return (
    <React.Fragment>
      <InputIsRequiredCheckBoxV1
        documentItem={documentItem}
        documentItemIndex={documentItemIndex}
        setDraftDocumentContentJson={setDraftDocumentContentJson}
      />
      <Space height={24} />
      {fields.map((field: any, index: any) => (
        <React.Fragment key={index}>
          <Row>
            <Column>
              <TextInput
                autoFocus
                placeholder={'Enter input title'}
                value={field.label}
                multiline
                onChangeText={(text: any) => {
                  setDraftDocumentContentJson((prevState: any) => {
                    prevState.documentItems[documentItemIndex].input.metadata.fields[index].label =
                      text;
                    return {
                      ...prevState,
                    };
                  });
                }}
              />
              <Space height={8} />
              <TextInput
                placeholder={'Enter input description'}
                value={field.description}
                multiline
                onChangeText={(text: any) => {
                  setDraftDocumentContentJson((prevState: any) => {
                    prevState.documentItems[documentItemIndex].input.metadata.fields[
                      index
                    ].description = text;
                    return {
                      ...prevState,
                    };
                  });
                }}
              />
            </Column>
            {fields.length > 1 && (
              <DeleteFieldColumn>
                <TertiaryButton
                  iconLeft={Icon.Trash}
                  iconSize={12}
                  textColor={colors.gray.secondary}
                  onPress={() =>
                    removeFieldInput({
                      setDraftDocumentContentJson,
                      documentItemIndex,
                      fieldIndex: index,
                    })
                  }
                />
              </DeleteFieldColumn>
            )}
          </Row>
          <Space height={32} />
        </React.Fragment>
      ))}
      <TertiaryButton
        iconLeft={Icon.Plus}
        text={'Add Item'}
        onPress={() => addFieldInput({setDraftDocumentContentJson, documentItemIndex})}
      />
    </React.Fragment>
  );
};

export default InputTextInputEditorV1;
