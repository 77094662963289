// Libraries
import React from 'react';

// Supermove
import {Modal, Styled} from '@supermove/components';

// App

import WebhookForm from '@shared/modules/Integration/forms/WebhookForm';
import useCreateWebhookMutation from '@shared/modules/Integration/hooks/useCreateWebhookMutation';

import OrganizationWebhookModalContent from './OrganizationWebhookModalContent';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const CreateOrganizationWebhookModal = ({organizationId, isOpen, handleClose, refetch}: any) => {
  const webhookForm = WebhookForm.new({organizationId});
  const {form, submitting, handleSubmit} = useCreateWebhookMutation({
    webhookForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <OrganizationWebhookModalContent
          titleText='Create Webhook'
          subTitleText='Add details for this webhook.'
          form={form}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      </Overlay>
    </Modal.Content>
  );
};

export default CreateOrganizationWebhookModal;
