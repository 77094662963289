/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {DateInput} from '@supermove/components';

// App
import Field from 'modules/App/components/Field';

const StartDateFilter = ({form, index}: any) => {
  return (
    <Field
      {...form}
      index={index}
      component={DateInput}
      name={'reportViewFiltersForm.startDate'}
      label={'Start Date'}
      style={{
        zIndex: 100 - index,
      }}
      input={{
        placeholder: 'Start date',
        setFieldValue: form.setFieldValue,
        style: {
          width: 200,
        },
      }}
    />
  );
};

export default StartDateFilter;
