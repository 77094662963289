// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

const Content = Styled.View`
  width: 100%;
  max-width: 400px;
  padding-top: 40px;
  padding-left: 8px;
  padding-right: 8px;
`;

const Title = Styled.Text`
  ${Typography.Heading6}
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
`;

const Logo = Styled.Text`
  ${Typography.Heading3}
  background-color: ${colors.black};
  color: ${colors.white};
  font-weight: 900;
  text-align: center;
  padding-vertical: 12px;
  padding-horizontal: 24px;
  width: 100%;
  height: 62px;
`;

const ExpiredInvalidTokenView = ({responsive}: any) => (
  <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
    <Content {...responsive}>
      <Logo>Supermove</Logo>
      <Space height={24} />
      <Title>This invitation has expired.</Title>
      <Space height={8} />
      <Subtitle>Please reach out to your organization for assistance.</Subtitle>
    </Content>
  </ScrollView>
);

export default ExpiredInvalidTokenView;
