// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job, Project, Truck} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

import TimesheetBillableEntryKind from '@shared/modules/Timesheet/enums/TimesheetBillableEntryKind';
import {JobUsersList} from 'modules/App/Job/components';
import JobTimetable from 'modules/App/components/JobTimetable';
import TimeRangesChart from 'modules/App/components/TimeRangesChart';
import JobStatusBadge from 'modules/Job/components/JobStatusBadge';
import JobTimesheetBillableEntries from 'modules/Project/V2/Show/Blocks/Job/components/JobTimesheetBillableEntries';

import DispatchJobDrivingStatusBadge from './DispatchJobDrivingStatusBadge';

const Container = Styled.View`
  padding-vertical: 5px;
  background-color: ${colors.white};
`;

const Touchable = Styled.Touchable`
  padding-vertical: 5px;
  padding-horizontal: 10px;
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  align-items: ${(props) => ((props as any).mobile ? 'flex-start' : 'center')};
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'space-between')};
`;

const EtaRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Line = Styled.View`
  height: 1px;
  margin-vertical: 5px;
  background-color: ${colors.gray.border};
`;

const LineBig = Styled.View`
  height: 1px;
  margin-vertical: 10px;
  background-color: ${colors.gray.border};
`;

const LeftInfo = Styled.View`
  ${(props) => ((props as any).mobile ? 'width: 100%' : 'flex: 1')};
  overflow: hidden;
`;

const RightInfo = Styled.View`
  ${(props) => ((props as any).mobile ? '' : 'flex: 1;')};
  align-items: flex-end;
  overflow: hidden;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
`;

const ProjectText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const Training = Styled.H7`
  margin-left: 5px;
  ${fontWeight(700)}
  color: ${colors.purple.hover};
`;

const StartTime = Styled.H7`
`;

const TruckText = Styled.H7`
  margin-bottom: 2px;
`;

const getTruckName = ({job}: any) => {
  if (job.jobTrucks && job.jobTrucks.length) {
    return job.jobTrucks.map((jobTruck: any) => Truck.getFullName(jobTruck.truck)).join(', ');
  } else {
    return 'Not assigned yet';
  }
};

const DispatchJobItem = ({job}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const isEnabledTimesheetsV2 = !!job.project.projectType.features.timesheetsV2;

  return (
    <Container>
      <Touchable onPress={() => navigator.push(`/jobs/${job.uuid}`)}>
        <Row {...responsive}>
          <LeftInfo {...responsive}>
            <ProjectText>{Project.getDisplayText(job.project, job.name)}</ProjectText>
            <Name numberOfLines={1}>
              {`${Job.getDisplayText(job)}`}
              {job.isTest && <Training>(Training)</Training>}
            </Name>
            <StartTime numberOfLines={1}>
              <DispatchJobDrivingStatusBadge job={job} style={{marginRight: 5}} />
              {!!job.startTime1 && Datetime.convertToDisplayTime(job.startTime1)}
              {!!job.startTime1 && !!job.moveSize && ', '}
              {!!job.moveSize && `${job.moveSize}`}
            </StartTime>
            <EtaRow>
              <ProjectText>{Job.getEstimatedArrivalText(job)}</ProjectText>
            </EtaRow>
            <TruckText numberOfLines={1}>{`Trucks: ${getTruckName({job})}`}</TruckText>
          </LeftInfo>
          {!responsive.mobile && (
            <RightInfo {...responsive}>
              <JobStatusBadge job={job} />
            </RightInfo>
          )}
        </Row>
        {job.primaryStatus !== 'CANCELLED' && (
          <React.Fragment>
            <Line />
            <Row {...responsive}>
              <LeftInfo {...responsive}>
                <JobUsersList isCompact={job.primaryStatus === 'COMPLETE'} job={job} />
              </LeftInfo>
            </Row>
          </React.Fragment>
        )}
        {responsive.mobile && (
          <React.Fragment>
            <LineBig />
            <Row {...responsive}>
              <JobStatusBadge job={job} />
            </Row>
          </React.Fragment>
        )}
        <Line />
        {isEnabledTimesheetsV2 && (
          <React.Fragment>
            <JobTimetable.Label>{`Job Timesheet${
              job.timesheetBillableEntryKind === TimesheetBillableEntryKind.POSITION ? 's' : ''
            }`}</JobTimetable.Label>
            <JobTimesheetBillableEntries job={job} responsive={responsive} isViewOnly isCondensed />
            <Space height={16} />
          </React.Fragment>
        )}
        <JobTimetable job={job} hideWorkTimesheet={isEnabledTimesheetsV2} />
      </Touchable>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchJobItem.fragment = gql`
  ${DispatchJobDrivingStatusBadge.fragment}
  ${TimeRangesChart.fragment}
  ${JobStatusBadge.fragment}
  ${JobUsersList.fragment}
  ${JobTimetable.fragment}
  ${Job.getDisplayText.fragment}
  ${Job.getEstimatedArrivalText.fragment}
  ${JobTimesheetBillableEntries.fragment}
  ${Project.getDisplayText.fragment}

  fragment DispatchJobItem on Job {
    id
    uuid
    isTest
    displayName
    primaryStatus: calendarPrimaryStatus
    moveSize
    name
    totalDistance
    startTime1
    startTime2
    timesheetBillableEntryKind
    jobTrucks {
      id
      truckId
      jobId
      truck {
        id
        name
        size
      }
    }
    customer {
      id
      aliasName
      firstName
      lastName
    }
    bookedBy {
      id
      firstName
      lastName
    }
    move {
      id
      hasConfirmedTimes
      timeRanges {
        ...TimeRangesChart
      }
    }
    jobEventsTimesheet {
      timeRanges {
        ...TimeRangesChart
      }
    }
    organization {
      id
      features {
        isEnabledAutomatedTimesheet: isEnabled(feature: "AUTOMATED_TIMESHEET")
      }
    }
    project {
      id
      projectType {
        id
        features {
          timesheetsV2
        }
      }
      ...Project_getDisplayText
    }
    ...JobTimetable
    ...DispatchJobDrivingStatusBadge
    ...JobStatusBadge
    ...JobUsersList
    ...Job_getDisplayText
    ...Job_getEstimatedArrivalText
    ...JobTimesheetBillableEntries
  }
`;

export default DispatchJobItem;
