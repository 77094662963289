// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, LocationInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';

const FieldsContainer = Styled.View`
  z-index: 100;
`;

const getHandleChangeLocation = ({form, field}: any) => {
  return ({address, city, zipCode, latitude, longitude, state, country}: any) => {
    const locationForm = _.get(form.values, field);
    form.setFieldValue(field, {
      ...locationForm,
      address,
      city,
      zipCode,
      latitude,
      longitude,
      state,
      country,
    });
  };
};

const WarehouseFields = ({form, organization, isCreate}: any) => {
  const {isEnabledMultipleWarehouse} = organization.features;

  // Sort organizations by primary first, then alphabetically
  const sortedOrganizations = _.orderBy(
    organization.company.organizations,
    ['isPrimary', 'name'],
    ['desc', 'asc'],
  );

  const organizationOptions = sortedOrganizations
    .filter((sortedOrganization) => {
      if (!organization.isPrimary) {
        return organization.id === sortedOrganization.id;
      }
      return true;
    })
    .map((sortedOrganization) => ({
      label: sortedOrganization.name,
      value: _.parseInt(sortedOrganization.id),
    }));

  const isDefault = _.get(form.values, `warehouseForm.isDefault`);

  return (
    <FieldsContainer>
      {isEnabledMultipleWarehouse && organization.isPrimaryMultibranch && (
        <React.Fragment>
          <FieldInput
            {...form}
            index={0}
            component={DropdownInput}
            name={'warehouseForm.organizationId'}
            label={'Branch'}
            isRequired
            input={{
              disabled: !isCreate,
              style: {width: '100%'},
              options: organizationOptions,
              placeholder: 'Select a branch',
              setFieldValue: form.setFieldValue,
            }}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <FieldInput
        {...form}
        index={1}
        name={'warehouseForm.name'}
        label={'Warehouse Name'}
        isRequired
        input={{
          autoFocus: true,
          placeholder: 'Enter a name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={2}
        component={LocationInput}
        name={'warehouseForm.locationForm.address'}
        label={'Address'}
        isRequired={!_.get(form.values, 'warehouseForm.location')}
        input={{
          setFieldValue: form.setFieldValue,
          setFieldError: form.setFieldError,
          setFieldTouched: form.setFieldTouched,
          onLocation: getHandleChangeLocation({form, field: `warehouseForm.locationForm`}),
          placeholder: 'Enter an address',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={3}
        name={'warehouseForm.notes'}
        label={'Warehouse Notes'}
        input={{
          style: {height: 120, paddingTop: 8},
          multiline: true,
          placeholder: 'Enter warehouse notes',
        }}
      />
      {isEnabledMultipleWarehouse && (
        <React.Fragment>
          <Space height={16} />
          <Checkbox
            label={'Set as default for this branch'}
            hint={
              'When checked, this warehouse will be automatically selected when creating a move project for this branch.'
            }
            isChecked={isDefault}
            handleToggle={() => form.setFieldValue(`warehouseForm.isDefault`, !isDefault)}
          />
        </React.Fragment>
      )}
    </FieldsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
WarehouseFields.fragment = gql`
  fragment WarehouseFields on Organization {
    id
    isPrimary
    isPrimaryMultibranch
    company {
      id
      organizations {
        id
        name
      }
    }
    features {
      isEnabledMultipleWarehouse: isEnabled(feature: "MULTIPLE_WAREHOUSE")
    }
  }
`;

export default WarehouseFields;
