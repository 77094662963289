// Libraries
import React from 'react';

const COLUMN_MIN_WIDTH = 180;

export const BookingCalendarContext = React.createContext(null);

const BookingCalendarContextProvider = ({children}: any) => {
  const store = {
    columnMinWidth: COLUMN_MIN_WIDTH,
    pageMinWidth: COLUMN_MIN_WIDTH * 7,
  };

  return (
    // @ts-expect-error TS(2322): Type '{ columnMinWidth: number; pageMinWidth: numb... Remove this comment to see the full error message
    <BookingCalendarContext.Provider value={store}>{children}</BookingCalendarContext.Provider>
  );
};

export default BookingCalendarContextProvider;
