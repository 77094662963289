// Libraries
import React from 'react';

// Supermove
import {Linkify, Space, Styled, Popover, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, usePopover, useResponsive, useState, useModal} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CommentForm from '@shared/modules/Comment/forms/CommentForm';
import useUpdateCommentMutation from '@shared/modules/Comment/hooks/useUpdateCommentMutation';
import ProjectCommentActionsPopover from 'modules/Project/components/ProjectCommentActionsPopover';
import ProjectCommentDeleteModal from 'modules/Project/components/ProjectCommentDeleteModal';
import ProjectCommentInput from 'modules/Project/components/ProjectCommentInput';

const InputContainer = Styled.View`
  background-color: ${colors.white};
`;

const DividerContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Container = Styled.View`
  padding: 16px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.gray.border};
`;

const CommentHeaderRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Subject = Styled.Text`
  ${Typography.Responsive.Label}
`;

const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;

const CommentMetaData = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const Button = Styled.ButtonV2`
`;

const CommentLink = ({href, text, key, responsive}: any) => {
  return (
    <a href={href} target={'_blank'} key={key}>
      <LinkText responsive={responsive}>{text}</LinkText>
    </a>
  );
};

const ProjectCommentActionButton = ({setDeleteModalOpen, setEditCommentOpen}: any) => {
  const projectCommentActionsPopover = usePopover();
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Popover.Content innerRef={projectCommentActionsPopover.ref}>
        <Button onPress={projectCommentActionsPopover.handleToggle}>
          <Icon
            source={Icon.EllipsisV}
            color={colors.gray.secondary}
            size={responsive.desktop ? 16 : 20}
          />
        </Button>
      </Popover.Content>
      <ProjectCommentActionsPopover
        key={projectCommentActionsPopover.key}
        popover={projectCommentActionsPopover}
        placement={Popover.Positions.BottomStart}
        setDeleteModalOpen={setDeleteModalOpen}
        setEditCommentOpen={setEditCommentOpen}
      />
    </React.Fragment>
  );
};

const ProjectCommentsListItem = ({index, comment, viewer, refetch, handleCommentUpdated}: any) => {
  const responsive = useResponsive();
  const [editCommentOpen, setEditCommentOpen] = useState(false);
  const projectCommentDeleteModal = useModal({name: 'Project Comment Delete Modal'});
  const commentForm = CommentForm.edit(comment);
  const {form, handleSubmit} = useUpdateCommentMutation({
    commentForm,
    onSuccess: () => {
      refetch();
      setEditCommentOpen(false);
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  useEffect(() => {
    handleCommentUpdated({commentId: comment.id, isEditingComment: form.dirty});
  }, [comment.id, form, handleCommentUpdated]);

  const hasCommentMetadata = comment.user.fullName !== '' || comment.createdAt !== '';
  return (
    <React.Fragment>
      {index !== 0 && (
        <DividerContainer>
          <Line />
        </DividerContainer>
      )}
      {editCommentOpen ? (
        <InputContainer>
          <ProjectCommentInput form={form} handleSubmit={handleSubmit} />
        </InputContainer>
      ) : (
        <Container>
          <CommentHeaderRow>
            <Subject responsive={responsive}>{comment.subject}</Subject>
            {viewer.id === comment.user.id && (
              <ProjectCommentActionButton
                comment={comment}
                setDeleteModalOpen={projectCommentDeleteModal.handleOpen}
                setEditCommentOpen={() => setEditCommentOpen(true)}
              />
            )}
          </CommentHeaderRow>
          {comment.body !== '' && (
            <React.Fragment>
              <Space height={4} />
              <Linkify
                component={(href, text, key) => (
                  <CommentLink href={href} text={text} key={key} responsive={responsive} />
                )}
              >
                <BodyText responsive={responsive}>{comment.body}</BodyText>
              </Linkify>
            </React.Fragment>
          )}
          {hasCommentMetadata && (
            <React.Fragment>
              <Space height={4} />
              <CommentMetaData responsive={responsive}>
                {comment.user.fullName},{' '}
                {Datetime.convertToDisplayDatetime(comment.createdAt, Datetime.DISPLAY_DATETIME)}
              </CommentMetaData>
            </React.Fragment>
          )}
        </Container>
      )}
      <ProjectCommentDeleteModal
        key={projectCommentDeleteModal.key}
        comment={comment}
        deleteModal={projectCommentDeleteModal}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

ProjectCommentsListItem.fragment = gql`
  ${CommentForm.edit.fragment}
  fragment ProjectCommentsListItem on Comment {
    id
    subject
    body
    createdAt
    user {
      id
      fullName
    }
    ...CommentForm_edit
  }
`;

export default ProjectCommentsListItem;
