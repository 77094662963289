// Supermove
import {ReportMoveUserForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

const MUTATION = gql`
  mutation UpdateReportMoveMoveUsersMutation(
    $reportMoveId: Int!,
    $moveUserInputs: [ReportMoveUserInput]!,
  ) {
    response: updateReportMoveMoveUsers(
      reportMoveId: $reportMoveId,
      moveUserInputs: $moveUserInputs,
    ) {
      ${gql.errors}
      reportMove {
        id
      }
    }
  }
`;

const useUpdateReportMoveMoveUsersForm = ({reportMove, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      reportMoveId: reportMove.id,
      moveUserInputs: reportMove.moveUsers.map((moveUser: any) => {
        return ReportMoveUserForm.toForm(ReportMoveUserForm.edit(moveUser));
      }),
    },
  });

  const variables = {
    reportMoveId: form.values.reportMoveId,
    moveUserInputs: form.values.moveUserInputs.map((moveUserForm: any) => {
      return ReportMoveUserForm.toMutation(moveUserForm);
    }),
  };

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: MUTATION,
    variables,
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useUpdateReportMoveMoveUsersForm.fragment = gql`
  ${ReportMoveUserForm.edit.fragment}

  fragment useUpdateReportMoveMoveUsersForm on ReportMove {
    id
    uuid
    moveUsers {
      user {
        id
        firstName
        lastName
      }
      ...ReportMoveUserForm_edit
    }
  }
`;

export default useUpdateReportMoveMoveUsersForm;
