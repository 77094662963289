// Libraries
import React from 'react';

// Supermove
import {Loading, Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive, useScrollView, useTabs} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import WarningCallout from '@shared/design/components/Callout/WarningCallout';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useUpdateProjectMutation from '@shared/modules/Project/hooks/useUpdateProjectMutation';
import SubmitButton from 'modules/App/components/SubmitButton';
import ProjectJobsContent from 'modules/Project/Update/components/ProjectJobsContent';
import ProjectJobsHeader from 'modules/Project/Update/components/ProjectJobsHeader';

const Actions = Styled.View`
  height: 68px;
  padding-horizontal: 24px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top-width: 1px;
  border-top-color: ${colors.gray.border};
`;

const ButtonText = Styled.H7`
  color: ${(props) => (props as any).color};
  ${fontWeight(700)}
`;

const FooterButton = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 120px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color};
`;

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props as any).backgroundColor};
`;

const ModalContainer = Styled.View`
  flex: 1;
  width: ${(props) => ((props as any).mobile ? '100%' : '800px')};
  background-color: ${colors.white};
  border-radius: ${(props) => ((props as any).mobile ? '0px' : '16px')};
`;

const CalloutContainer = Styled.View`
  padding-horizontal: 24px;
`;

const ActivityIndicator = Styled.Loading`
  flex: 1;
`;

const ActivityContainer = () => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ModalContainer backgroundColor={colors.white}>
      <ActivityIndicator />
    </ModalContainer>
  );
};

const ProjectJobsModalFooter = ({handleClose, handleSubmit, submitting, form}: any) => {
  return (
    <Actions>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FooterButton color={colors.white} onPress={handleClose} disabled={submitting}>
        <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
      </FooterButton>
      <Space width={8} />
      <SubmitButton handleSubmit={handleSubmit} form={form} />
    </Actions>
  );
};

const ProjectModalContent = ({viewerId, project, handleClose, refetch}: any) => {
  const responsive = useResponsive();
  const projectForm = ProjectForm.editV2WithNewJob(project, {creatorId: viewerId});
  const {ref: scrollViewRef, handleScrollTo} = useScrollView();
  const {form, handleSubmit, submitting} = useUpdateProjectMutation({
    project,
    projectForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
      handleScrollTo({y: 0});
    },
  });
  const isAtMaxJobs = ProjectForm.getIsAtMaxJobs(form.values.projectForm, {project});
  const {jobForms} = form.values.projectForm;
  const tabs = useTabs({initialIndex: jobForms.length - 1});

  return (
    <ModalContainer {...responsive}>
      <Space height={22} />
      <ProjectJobsHeader
        form={form}
        viewerId={viewerId}
        project={project}
        setSelectedIndex={tabs.setSelectedIndex}
        isAtMaxJobs={isAtMaxJobs}
      />
      {isAtMaxJobs && (
        <React.Fragment>
          <Space height={12} />
          <CalloutContainer>
            <WarningCallout text={'This project has reached the maximum number of jobs allowed.'} />
          </CalloutContainer>
        </React.Fragment>
      )}
      <Space height={24} />
      <ProjectJobsContent
        form={form}
        project={project}
        scrollViewRef={scrollViewRef}
        tabs={tabs}
        isAtMaxJobs={isAtMaxJobs}
      />
      <ProjectJobsModalFooter
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        form={form}
      />
    </ModalContainer>
  );
};

const AddProjectJobsModal = ({isOpen, handleClose, projectUuid, refetch}: any) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(AddProjectJobsModal.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {
      projectUuid,
    },
  });

  return (
    <Modal.Content onClose={() => {}} isOpen={isOpen}>
      <Container>
        {!responsive.mobile && <Space height={36} />}
        <Loading loading={loading} as={ActivityContainer}>
          {() => {
            return data ? (
              <ProjectModalContent
                viewerId={data.viewer.id}
                project={data.project}
                handleClose={handleClose}
                refetch={refetch}
              />
            ) : null;
          }}
        </Loading>
        {!responsive.mobile && <Space height={36} />}
      </Container>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddProjectJobsModal.query = gql`
  ${useUpdateProjectMutation.fragment}
  ${ProjectForm.editV2WithNewJob.fragment}
  ${ProjectForm.getIsAtMaxJobs.fragment}
  ${ProjectJobsContent.fragment}
  ${ProjectJobsHeader.fragment}

  query AddProjectJobsModal($projectUuid: String!) {
    viewer {
      id
    }
    project(uuid: $projectUuid) {
      id
      ...useUpdateProjectMutation
      ...ProjectForm_editV2WithNewJob
      ...ProjectForm_getIsAtMaxJobs
      ...ProjectJobsContent
      ...ProjectJobsHeader
    }
  }
`;

export default AddProjectJobsModal;
