// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useResponsive, useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table from '@shared/design/components/TableV2Deprecated';

const Row = Styled.View`
  flex-direction: row;
`;

const Column = Styled.View`
  flex: 1;
`;

const TableContainer = Styled.View`
  border-color: ${colors.gray.border};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`;

const LegendText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary};
`;

const Legend = () => {
  return (
    <React.Fragment>
      <Row>
        <Icon source={Icon.StarExclamation} color={colors.blue.interactive} size={16} />
        <Space width={8} />
        <LegendText>{`Indicates that we have automatically updated the job type`}</LegendText>
      </Row>
      <Space height={8} />
      <Row>
        <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={16} />
        <Space width={8} />
        <LegendText>{`Indicates that we could not find a matching job type`}</LegendText>
      </Row>
    </React.Fragment>
  );
};

const JobTypeDetails = ({updateJobTypeForJobForm, responsive}: any) => {
  return (
    <React.Fragment>
      <Table.CellText responsive={responsive}>
        {_.get(updateJobTypeForJobForm, 'jobName')}
      </Table.CellText>
      <Table.CellText responsive={responsive} style={{color: colors.gray.secondary}}>
        {_.get(updateJobTypeForJobForm, 'jobDisplayDate')}
      </Table.CellText>
    </React.Fragment>
  );
};

const JobTypeMatchedIcon = ({isMatched}: any) => {
  return (
    <React.Fragment>
      {isMatched ? (
        <Icon source={Icon.StarExclamation} color={colors.blue.interactive} size={16} />
      ) : (
        <Icon source={Icon.ExclamationTriangle} color={colors.orange.status} size={16} />
      )}
      <Space width={8} />
    </React.Fragment>
  );
};

const getColumnDefinitions = ({
  form,
  field,
  jobTypeDropdownOptions,
  isProjectTypeUpdated,
  manuallySelectedJobTypesOnJob,
  setManuallySelectedJobTypesOnJob,
  responsive,
}: any) => [
  {
    isHidden: !responsive.desktop,
    flex: 1,
    headerContent: () => {
      return (
        <Column>
          <Table.HeaderText>Current Job Type</Table.HeaderText>
          <Table.HeaderSubtext>{`Date(s)`}</Table.HeaderSubtext>
        </Column>
      );
    },
    // @ts-expect-error TS(7031): Binding element 'updateJobTypeForJobForm' implicit... Remove this comment to see the full error message
    cellContent: ({item: updateJobTypeForJobForm}) => {
      return (
        <Column>
          <JobTypeDetails updateJobTypeForJobForm={updateJobTypeForJobForm} />
        </Column>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return responsive.desktop ? <Table.HeaderText>New Job Type</Table.HeaderText> : null;
    },
    cellContent: ({item: updateJobTypeForJobForm, rowIndex}: any) => {
      const jobId = _.get(updateJobTypeForJobForm, 'jobId');
      const showIsMatchedIcon =
        isProjectTypeUpdated && !_.includes(manuallySelectedJobTypesOnJob, jobId);
      return (
        <Column>
          {!responsive.desktop && (
            <React.Fragment>
              <Row style={{justifyContent: 'space-between'}}>
                <JobTypeDetails
                  updateJobTypeForJobForm={updateJobTypeForJobForm}
                  responsive={responsive}
                />
              </Row>
              <Space height={16} />
            </React.Fragment>
          )}
          <Row style={{flex: 1, alignItems: 'center'}}>
            {showIsMatchedIcon && (
              <JobTypeMatchedIcon isMatched={_.get(updateJobTypeForJobForm, 'isMatched')} />
            )}
            <FieldInput
              {...form}
              isRequired
              name={`${field}.${rowIndex}.jobTypeId`}
              component={DropdownInput}
              isResponsive
              input={{
                options: jobTypeDropdownOptions,
                isPortaled: true,
                placeholder: 'Select a job type',
                setFieldValue: form.setFieldValue,
                onChangeValue: () => {
                  setManuallySelectedJobTypesOnJob([...manuallySelectedJobTypesOnJob, jobId]);
                  form.setFieldValue(`${field}.${rowIndex}.isMatched`, false);
                },
                style: {flex: 1},
              }}
              style={{flex: 1}}
            />
          </Row>
        </Column>
      );
    },
  },
];

const getJobTypeDropdownOptions = ({organization, selectedProjectTypeId}: any) => {
  return organization.jobTypes
    .filter(
      (jobType: any) => _.toString(jobType.projectTypeId) === _.toString(selectedProjectTypeId),
    )
    .map((jobType: any) => ({
      label: jobType.name,
      value: jobType.id,
    }));
};

const EditJobTypesContent = ({project, form, field}: any) => {
  const [manuallySelectedJobTypesOnJob, setManuallySelectedJobTypesOnJob] = useState([]);
  const {projectType, organization} = project;
  const selectedProjectTypeId = _.get(form.values, `${field}.projectTypeId`);
  const isProjectTypeUpdated = projectType.id !== selectedProjectTypeId;
  const selectedProjectTypeName = _.get(form.values, `${field}.projectTypeName`);
  const updateJobTypeForJobFormsField = `${field}.updateJobTypeForJobForms`;
  const updateJobTypeForJobForms = _.get(form.values, updateJobTypeForJobFormsField);
  const jobTypeDropdownOptions = getJobTypeDropdownOptions({organization, selectedProjectTypeId});
  const responsive = useResponsive();

  useEffect(() => {
    setManuallySelectedJobTypesOnJob([]);
  }, [selectedProjectTypeId]);

  return (
    <React.Fragment>
      {isProjectTypeUpdated && (
        <React.Fragment>
          <Callout
            text={`We've selected new ${selectedProjectTypeName} job types most similar to your ${projectType.name} job types. If we couldn't find any close matches, nothing has been selected. Please double-check before saving.`}
          />
          <Space height={16} />
          <Legend />
          <Space height={16} />
        </React.Fragment>
      )}
      <TableContainer style={{borderTopWidth: responsive.desktop ? 0 : 1}}>
        <Table
          columnDefinitions={getColumnDefinitions({
            form,
            field: updateJobTypeForJobFormsField,
            jobTypeDropdownOptions,
            isProjectTypeUpdated,
            manuallySelectedJobTypesOnJob,
            setManuallySelectedJobTypesOnJob,
            responsive,
          })}
          emptyStateText='No job types.'
          items={updateJobTypeForJobForms}
          itemKey={'id'}
          isEnabledHeader={responsive.desktop}
          rowStyle={{paddingHorizontal: responsive.desktop ? 0 : 4}}
        />
      </TableContainer>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobTypesContent.fragment = gql`
  fragment EditJobTypesContent on Project {
    id
    projectType {
      id
      name
    }
    organization {
      id
      jobTypes {
        id
        name
        projectTypeId
      }
    }
  }
`;

export default EditJobTypesContent;
