// Libraries
import PropTypes from 'prop-types';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors, typography} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.theme === 'light' ? colors.white : colors.gray.primary)};
  z-index: 100;
`;

const Center = Styled.View`
  flex: 1;
  align-items: center;
  margin-horizontal: 10px;
`;

const Title = Styled.H5`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? typography.h6() : '')}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.theme === 'light' ? colors.gray.primary : colors.white)};
`;

const Subtitle = Styled.H7`
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? typography.h8() : '')}
  ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.mobile ? 'font-size: 13px' : '')}
  margin-top: -2px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.theme === 'light' ? colors.gray.primary : colors.white)};
`;

const Side = Styled.View`
  width: ${(props) => (props as any).width}px;
`;

type OwnNavigationProps = {
  theme?: 'light' | 'dark';
  side?: (...args: any[]) => any;
  left?: (...args: any[]) => any;
  right?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'NavigationProps' circularly references... Remove this comment to see the full error message
type NavigationProps = OwnNavigationProps & typeof Navigation.defaultProps;

// @ts-expect-error TS(7022): 'Navigation' implicitly has type 'any' because it ... Remove this comment to see the full error message
const Navigation = ({theme, title, subtitle, side, left, right}: NavigationProps) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container vars={{theme}}>
      <Side width={side({responsive})}>{left()}</Side>
      <Center>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Title vars={{...responsive, theme}} numberOfLines={1}>
          {title}
        </Title>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Subtitle vars={{...responsive, theme}} numberOfLines={1}>
          {subtitle}
        </Subtitle>
      </Center>
      <Side width={side({responsive})}>{right()}</Side>
    </Container>
  );
};

Navigation.defaultProps = {
  theme: 'light',
  side: () => 0,
  left: () => null,
  right: () => null,
};

const Touchable = Styled.Touchable`
`;

const CloseIcon = Styled.Image`
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 20 : 30)}px;
  height: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 20 : 30)}px;
  margin: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? 10 : 15)}px;
`;

const CloseButton = ({theme, onPress}: any) => {
  const responsive = useResponsive();

  return (
    <Touchable onPress={onPress}>
      <CloseIcon
        source={
          theme === 'light'
            ? require('./assets/close-dark.png')
            : require('./assets/close-light.png')
        }
        responsive={responsive}
      />
    </Touchable>
  );
};

Navigation.CloseButton = CloseButton;

Navigation.CloseButton.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
};

Navigation.CloseButton.defaultProps = {
  theme: 'light',
};

Navigation.Button = Styled.Button`
  height: 40px;
  width: ${(props) => (props as any).width}px;
  ${(props) => (props as any).style};
`;

Navigation.ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

export default Navigation;
