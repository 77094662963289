// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import SwitchField from '@shared/design/components/Field/SwitchField';
import ProjectTypeColorDropdown from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeColorDropdown';

const getBillingLibraryOptions = ({organization}: any) => {
  return organization.billingLibraries.map((billingLibrary: any) => {
    return {
      label: billingLibrary.name,
      value: billingLibrary.id,
    };
  });
};

const getBillTypeOptions = ({form, organization}: any) => {
  const selectedBillingLibraryId = form.values.projectTypeForm.billingLibraryId;
  const {billTypes} = _.find(
    organization.billingLibraries,
    (billingLibrary) => billingLibrary.id === selectedBillingLibraryId,
  );

  return billTypes.map((billType: any) => ({
    label: billType.name,
    value: _.toNumber(billType.id),
  }));
};

const ProjectTypeModalFields = ({form, organization, isStorageSettingsPage}: any) => {
  const nameField = `projectTypeForm.name`;
  const colorField = `projectTypeForm.color`;
  const billingLibraryField = `projectTypeForm.billingLibraryId`;
  const billTypesField = `projectTypeForm.billTypeIds`;
  const isLongDistanceField = `projectTypeForm.isLongDistance`;
  return (
    <React.Fragment>
      <FieldInput.Memoized
        {...form}
        label={'Name'}
        index={0}
        name={nameField}
        input={{
          autoFocus: true,
          required: !_.get(form.values, nameField),
          placeholder: 'Enter project type name',
          disabled: !organization.isOwnerOfSettings,
        }}
      />
      <Space height={20} />
      <ProjectTypeColorDropdown
        form={form}
        field={colorField}
        index={1}
        label={'Color'}
        placeholder={'Select project type color'}
        disabled={!organization.isOwnerOfSettings}
      />
      <Space height={20} />
      <FieldInput
        {...form}
        label={'Billing Library'}
        name={billingLibraryField}
        component={DropdownInput}
        index={2}
        input={{
          required: !_.get(form.values, billingLibraryField),
          options: getBillingLibraryOptions({organization}),
          placeholder: 'Select billing library',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      {!!form.values.projectTypeForm.billingLibraryId && !isStorageSettingsPage && (
        <React.Fragment>
          <Space height={20} />
          <FieldInput
            {...form}
            label={'Default Bill Template(s)'}
            name={billTypesField}
            component={MultiDropdownCheckboxInput}
            index={3}
            input={{
              options: getBillTypeOptions({form, organization}),
              placeholder: `Select billing template(s)`,
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
          />
        </React.Fragment>
      )}
      {!isStorageSettingsPage && (
        <React.Fragment>
          <Space height={20} />
          <SwitchField
            form={form}
            field={isLongDistanceField}
            index={4}
            labelRight={'This is a long distance project type.'}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectTypeModalFields.fragment = gql`
  fragment ProjectTypeModalFields on Organization {
    id
    isOwnerOfSettings
    billingLibraries {
      id
      name
      billTypes {
        id
        name
      }
    }
  }
`;
export default ProjectTypeModalFields;
