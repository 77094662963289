// Libraries
import React from 'react';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ConfirmSyncInventoryBillModal.defaultProps;

// @ts-expect-error TS(7022): 'ConfirmSyncInventoryBillModal' implicitly has typ... Remove this comment to see the full error message
const ConfirmSyncInventoryBillModal = ({isOpen, handleClose, handleSubmit, submitting}: Props) => {
  return (
    <CautionModal
      isOpen={isOpen}
      title={'Regenerate Bill?'}
      message={`This action will regenerate the packing bill and any changes you made to that bill will be removed. This action cannot be undone.`}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      isSubmitting={submitting}
    />
  );
};

ConfirmSyncInventoryBillModal.defaultProps = {};

export default ConfirmSyncInventoryBillModal;
