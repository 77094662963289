// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App

import MediumModal from '@shared/design/components/Modal/MediumModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteSurveyMethodMutation from '@shared/modules/Inventory/hooks/useDeleteSurveyMethodMutation';
import ReassignProjectTypeFields from 'modules/Organization/Settings/Inventory/components/ReassignProjectTypeFields';

const ContentWithProjectTypes = ({
  form,
  defaultProjectTypeInventorySettingsForms,
  organization,
  surveyMethodForm,
}: any) => {
  const surveyMethodOptions = organization.surveyMethods.reduce(
    (options: any, surveyMethod: any) => {
      if (surveyMethod.id !== surveyMethodForm.surveyMethodId) {
        options.push({
          label: surveyMethod.name,
          value: surveyMethod.id,
        });
      }
      return options;
    },
    [],
  );
  return (
    <React.Fragment>
      <MediumModal.Text>{`The survey method you want to remove has ${pluralize(
        'project type',
        defaultProjectTypeInventorySettingsForms.length,
        true,
      )} assigned to it. Please replace the default survey method for each project type.`}</MediumModal.Text>
      <Space height={16} />
      <ReassignProjectTypeFields
        form={form}
        field={'surveyMethodForm.defaultProjectTypeInventorySettingsForms'}
        defaultProjectTypeInventorySettingsForms={defaultProjectTypeInventorySettingsForms}
        dropdownField={'surveyMethodId'}
        dropdownPlaceholder={'Select default survey method'}
        dropdownOptions={surveyMethodOptions}
      />
    </React.Fragment>
  );
};

const DeleteSurveyMethodModal = ({
  deleteSurveyMethodModal,
  surveyMethodForm,
  refetch,
  organization,
}: any) => {
  const {form, submitting, handleSubmit} = useDeleteSurveyMethodMutation({
    surveyMethodForm,
    onSuccess: () => {
      refetch();
      deleteSurveyMethodModal.handleClose();
    },
    onError: () => {},
  });
  const defaultProjectTypeInventorySettingsForms = _.get(
    form.values,
    'surveyMethodForm.defaultProjectTypeInventorySettingsForms',
    [],
  );
  const title = `Remove ${_.get(surveyMethodForm, 'name', 'Survey Method')}?`;
  return _.isEmpty(defaultProjectTypeInventorySettingsForms) ? (
    <DeleteModal
      key={deleteSurveyMethodModal.key}
      isOpen={deleteSurveyMethodModal.isOpen}
      title={title}
      subtitle={'Past surveys will not be affected.'}
      handleClose={deleteSurveyMethodModal.handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  ) : (
    <MediumModal
      key={deleteSurveyMethodModal.key}
      isOpen={deleteSurveyMethodModal.isOpen}
      title={title}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Reassign and Remove'}
      handleSecondaryAction={deleteSurveyMethodModal.handleClose}
      secondaryActionText={'Cancel'}
      isScrollable
      primaryActionColor={colors.red.warning}
      style={{maxHeight: '520px'}}
    >
      <MediumModal.Block>
        <ContentWithProjectTypes
          form={form}
          defaultProjectTypeInventorySettingsForms={defaultProjectTypeInventorySettingsForms}
          organization={organization}
          surveyMethodForm={surveyMethodForm}
        />
      </MediumModal.Block>
    </MediumModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteSurveyMethodModal.fragment = gql`
  fragment DeleteSurveyMethodModal on Organization {
    id
    surveyMethods {
      id
      name
    }
  }
`;

export default DeleteSurveyMethodModal;
