// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import RelatedOrganizationsStaffDropdown from 'modules/Organization/components/RelatedOrganizationsStaffDropdown';
import SingleOrganizationStaffDropdown from 'modules/Organization/components/SingleOrganizationStaffDropdown';

type OwnProps = {
  organization: any;
  form: any;
  field: string;
  label: string;
  placeholder: string;
  isMultiSelect?: boolean;
  style?: any;
  inputStyle?: any;
  index?: number;
  isSalesperson?: boolean;
  isCoordinator?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  required?: boolean;
  isResponsive?: boolean;
  isPortaled?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof OrganizationStaffDropdown.defaultProps;

// @ts-expect-error TS(7022): 'OrganizationStaffDropdown' implicitly has type 'a... Remove this comment to see the full error message
const OrganizationStaffDropdown = ({
  organization,
  form,
  field,
  label,
  placeholder,
  isMultiSelect,
  style,
  inputStyle,
  index,
  isSalesperson,
  isCoordinator,
  noneOptionLabel,
  isDisabled,
  isRequired, // Current required flag
  required, // Deprecated required flag
  isResponsive,
  isPortaled,
}: Props) => {
  return (
    <React.Fragment>
      {(isSalesperson || isCoordinator) &&
      organization.features.isEnabledShowSalespersonsFromAllOrganizations ? (
        <RelatedOrganizationsStaffDropdown
          isDisabled={isDisabled}
          isRequired={isRequired}
          required={required}
          organizationSlug={organization.slug}
          isEnabledShowIntegrationUser={
            (isCoordinator && organization.features.isEnabledShowIntegrationCoordinator) ||
            (isSalesperson && organization.features.isEnabledShowIntegrationSalesperson)
          }
          isSalesperson={isSalesperson}
          form={form}
          field={field}
          label={label}
          noneOptionLabel={noneOptionLabel}
          placeholder={placeholder}
          isMultiSelect={isMultiSelect}
          style={style}
          inputStyle={inputStyle}
          index={index}
          isResponsive={isResponsive}
          isPortaled={isPortaled}
        />
      ) : (
        <SingleOrganizationStaffDropdown
          isDisabled={isDisabled}
          isRequired={isRequired}
          required={required}
          organizationSlug={organization.slug}
          isEnabledShowIntegrationUser={
            (isCoordinator && organization.features.isEnabledShowIntegrationCoordinator) ||
            (isSalesperson && organization.features.isEnabledShowIntegrationSalesperson)
          }
          isSalesperson={isSalesperson}
          form={form}
          field={field}
          label={label}
          noneOptionLabel={noneOptionLabel}
          placeholder={placeholder}
          isMultiSelect={isMultiSelect}
          style={style}
          inputStyle={inputStyle}
          index={index}
          isResponsive={isResponsive}
          isPortaled={isPortaled}
        />
      )}
    </React.Fragment>
  );
};

OrganizationStaffDropdown.defaultProps = {
  isMultiSelect: false,
  style: null,
  inputStyle: null,
  index: null,
  isSalesperson: false,
  isCoordinator: false,
  isDisabled: false,
  isRequired: false,
  required: false,
  isResponsive: false,
  isPortaled: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationStaffDropdown.fragment = gql`
  fragment OrganizationStaffDropdown on Organization {
    id
    slug
    features {
      isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
        feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
      )
      isEnabledShowIntegrationCoordinator: isEnabled(feature: "SHOW_INTEGRATION_COORDINATOR")
      isEnabledShowIntegrationSalesperson: isEnabled(feature: "SHOW_INTEGRATION_SALESPERSON")
    }
  }
`;

export default OrganizationStaffDropdown;
