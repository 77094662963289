/**
 * Component - v2.0.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Modal, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import BillingLibraryForm from '@shared/modules/Billing/forms/BillingLibraryForm';
import useUpdateBillingLibraryMutation from '@shared/modules/Billing/hooks/useUpdateBillingLibraryMutation';
import Field from 'modules/App/components/Field';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ContentContainer = Styled.View`
  width: 400px;
  border-radius: 16px;
  background-color: ${colors.white};
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const FooterButton = Styled.ButtonV2`
  width: 104px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const EditBillingLibraryModal = ({billingLibrary, isOpen, handleClose, refetch}: any) => {
  const billingLibraryForm = BillingLibraryForm.edit(billingLibrary);
  const {form, submitting, handleSubmit} = useUpdateBillingLibraryMutation({
    billingLibraryForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={handleClose}>
      <Overlay pointerEvents={'box-none'}>
        <ContentContainer>
          <Space height={24} />
          <TitleText>Edit Billing Library</TitleText>
          <Space height={20} />
          <Text>{`Update the billing library name.`}</Text>
          <Space height={20} />
          <Field
            {...form}
            label={'Billing Library Name'}
            name={'billingLibraryForm.name'}
            input={{
              required: !_.get(form.values, 'billingLibraryForm.name'),
              placeholder: 'Enter a billing library name',
            }}
          />
          <Space height={36} />
          <FooterRow>
            <FooterButton color={colors.white} onPress={handleClose}>
              <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
            </FooterButton>
            <FooterButton
              color={colors.blue.interactive}
              onPress={handleSubmit}
              disabled={submitting}
            >
              <FooterButtonText color={colors.white}>Save</FooterButtonText>
            </FooterButton>
          </FooterRow>
          <Space height={24} />
        </ContentContainer>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillingLibraryModal.fragment = gql`
  ${BillingLibraryForm.edit.fragment}
  fragment EditBillingLibraryModal on BillingLibrary {
    id
    ...BillingLibraryForm_edit
  }
`;

export default EditBillingLibraryModal;
