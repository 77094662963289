// Libraries
import React from 'react';

// Supermove
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {Clipboard} from '@supermove/sdk';
import {fontWeight, colors} from '@supermove/styles';
import {makeUrl} from '@supermove/utils';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 20px;
`;

const Button = Styled.Button`
  flex: 1;
  align-items: center;
  height: 30px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  margin-right: 10px;
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobShareModal.defaultProps;

// @ts-expect-error TS(7022): 'JobShareModal' implicitly has type 'any' because ... Remove this comment to see the full error message
const JobShareModal = ({job, trigger}: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Modal trigger={trigger}>
      {({handleClose}: any) => (
        <Container pointerEvents={'box-none'}>
          <Wrapper width={350}>
            <Circle>
              <Emoji component={EmojiText} name={'link'} />
            </Circle>
            <Content>
              <Title>Job Details Link</Title>
              <Subtitle>
                {`Open or copy the link to view details for ${Job.getFullName(job)}.`}
              </Subtitle>
              <Actions>
                <a href={makeUrl(`/1/jobs/${job.uuid}/view`)} style={{flex: 1}} target={'_blank'}>
                  <Button>
                    <Text>Open</Text>
                  </Button>
                </a>
                <ButtonSpace />
                <Button
                  onPress={() => {
                    Clipboard.setString(makeUrl(`/1/jobs/${job.uuid}/view`));
                    setIsCopied(true);
                  }}
                >
                  <Text>{isCopied ? 'Copied' : 'Copy'}</Text>
                </Button>
              </Actions>
            </Content>
          </Wrapper>
        </Container>
      )}
    </Modal>
  );
};

JobShareModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobShareModal.fragment = gql`
  ${Job.getFullName.fragment}

  fragment JobShareModal on Job {
    id
    uuid
    ...Job_getFullName
  }
`;

export default JobShareModal;
