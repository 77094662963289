// Libraries
import React from 'react';

// App
import {Icon, Link, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

import UserRole from '@shared/modules/User/enums/UserRole';

// Components
import ManagerSearch from './ManagerSearch';
import SidebarSuperAdmin from './Sidebar/SidebarSuperAdmin';
import UpdateSuperAdminOrganizationModal from './UpdateSuperAdminOrganizationModal';

const Container = Styled.View`
  ${(props) => ((props as any).mobile ? 'height: 60px;' : 'width: 200px;')}
  background-color: ${colors.black};
`;

const Wrapper = Styled.View`
  flex: 1;
  flex-direction: ${(props) => ((props as any).mobile ? 'row' : 'column')};
`;

const Content = Styled.View`
  flex: 1;
  flex-direction: ${(props) => ((props as any).mobile ? 'row' : 'column')};
  ${(props) => ((props as any).mobile ? 'align-items: center;' : '')}
`;

const Logo = Styled.View`
  margin: 15px;
`;

const LogoText = Styled.Text`
  font-family: 'Avenir';
  font-size: 30px;
  line-height: 48px;
  ${fontWeight(700)}
  color: ${colors.white};
  letter-spacing: -2;
  text-align: center;
`;

const User = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'row' : 'column')};
  ${(props) => ((props as any).mobile ? 'align-items: center;' : '')}
  margin-horizontal: 5px;
  ${(props) => ((props as any).mobile ? '' : 'margin-bottom: 15px;')}
`;

const Touchable = Styled.Touchable`
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Search = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
`;

type OwnProps = {
  viewer?: any;
  onSidebarOpen?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof PageHeader.defaultProps;

// @ts-expect-error TS(7022): 'PageHeader' implicitly has type 'any' because it ... Remove this comment to see the full error message
const PageHeader = ({viewer, onSidebarOpen, HeaderComponent}: Props) => {
  const responsive = useResponsive();

  return (
    <Container {...responsive}>
      <Wrapper {...responsive}>
        <Content {...responsive}>
          {HeaderComponent ? (
            <HeaderComponent />
          ) : (
            <Logo>
              <Link to={'/'}>
                {/* @ts-expect-error TS(2769): No overload matches this call. */}
                <LogoText vars={responsive}>Supermove</LogoText>
              </Link>
            </Logo>
          )}
        </Content>
        <User {...responsive}>
          {['super'].includes(viewer.role) && (
            <UpdateSuperAdminOrganizationModal
              trigger={({handleOpen}: any) => (
                <Touchable onPress={handleOpen} style={{width: 40}}>
                  <SidebarSuperAdmin responsive={responsive} user={viewer} />
                </Touchable>
              )}
            />
          )}
          {UserRole.MANAGER_ROLES_PLUS_SUPER.includes(viewer.role) && (
            <ManagerSearch
              organization={viewer.viewingOrganization}
              trigger={({handleOpen}: any) => (
                <Touchable onPress={handleOpen} style={{width: 40}}>
                  <Search {...responsive}>
                    <Icon color={colors.white} size={Icon.Sizes.ExtraLarge} source={Icon.Search} />
                  </Search>
                </Touchable>
              )}
            />
          )}
          <Touchable onPress={onSidebarOpen} style={{width: 40}}>
            <Icon color={colors.white} size={Icon.Sizes.ExtraLarge} source={Icon.Bars} />
          </Touchable>
        </User>
      </Wrapper>
    </Container>
  );
};

PageHeader.defaultProps = {
  viewer: {},
  onSidebarOpen: () => 0,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PageHeader.fragment = gql`
  fragment PageHeader on User {
    id
    role
  }
`;

export default PageHeader;
