// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

const Container = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Item = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 3px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const Text = Styled.H8`
  font-size: 10px;
  min-height 15px;
`;

const formatTimeRanges = ({timeRanges}: any) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getColumnNames = ({job}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(job, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).endName : (timeRange as any).startName;
  });
};

const getJobUserTimes = ({jobUser}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(jobUser, 'timesheet.timeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).end : (timeRange as any).start;
  });
};

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, ['user.firstName', 'user.lastName']);
};

const TableHeader = ({names, job}: any) => (
  <Header>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 60}}>
        <Text>Branch / IC Code</Text>
      </Cell>
    )}
    <Cell style={{width: 100}}>
      <Text>Name</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 100}}>
        <Text>Position</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text>Labor Source</Text>
        )}
      </Cell>
    )}
    {names.map((name: any, index: any) => (
      <Cell key={index} style={{width: 50}}>
        <Text>{name}</Text>
      </Cell>
    ))}
    <Cell style={{width: 50}}>
      <Text>Travel Hours</Text>
    </Cell>
    <Cell style={{width: 50}}>
      <Text>Total Hours</Text>
    </Cell>
  </Header>
);

const JobUserItem = ({jobUser, job}: any) => (
  <Item>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 60}}>
        <Text>{jobUser.branchCode}</Text>
      </Cell>
    )}
    <Cell style={{width: 100}}>
      <Text>{User.getFullName(jobUser.user)}</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 100}}>
        <Text numberOfLines={1}>{jobUser.position}</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text numberOfLines={1}>{jobUser.user.organization.name}</Text>
        )}
      </Cell>
    )}
    {getJobUserTimes({jobUser}).map((time, index) => (
      <Cell key={`${jobUser.id}-${index}`} style={{width: 50}}>
        <Text numberOfLines={1}>{time ? Datetime.convertToDisplayTime(time) : 'N/A'}</Text>
      </Cell>
    ))}
    <Cell style={{width: 50}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</Text>
    </Cell>
    <Cell style={{width: 50}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</Text>
    </Cell>
  </Item>
);

const ReportMoveUserItem = ({reportMoveUser, job}: any) => {
  const {timeRanges} = reportMoveUser.timesheet;
  const lastTimeRangeEnd = _.get(timeRanges, `${timeRanges.length - 1}.end`);

  return (
    <Item>
      {job.organization.features.isEnabledJobUserShowBranchCode && (
        <Cell style={{width: 60}}>
          <Text>{reportMoveUser.branchCode}</Text>
        </Cell>
      )}
      <Cell style={{width: 100}}>
        <Text>{User.getFullName(reportMoveUser.user)}</Text>
      </Cell>
      {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
        <Cell style={{width: 100}}>
          <Text numberOfLines={1}>{reportMoveUser.position}</Text>
          {job.organization.features.isEnabledJobUserShowOrganizationName && (
            <Text numberOfLines={1}>{reportMoveUser.user.organization.name}</Text>
          )}
        </Cell>
      )}
      {timeRanges.map((timeRange: any, index: any) => (
        <Cell key={`${reportMoveUser.userId}-${index}`} style={{width: 50}}>
          <Text numberOfLines={1}>
            {timeRange.start ? Datetime.convertToDisplayTime(timeRange.start) : 'N/A'}
          </Text>
        </Cell>
      ))}
      <Cell style={{width: 50}}>
        <Text numberOfLines={1}>
          {lastTimeRangeEnd ? Datetime.convertToDisplayTime(lastTimeRangeEnd) : 'N/A'}
        </Text>
      </Cell>
      <Cell style={{width: 50}}>
        <Text numberOfLines={1}>{Duration.toHours(reportMoveUser.timesheet.totalTimeDrive)}</Text>
      </Cell>
      <Cell style={{width: 50}}>
        <Text numberOfLines={1}>
          {Duration.toHours(reportMoveUser.timesheet.totalTimeWithoutBreak)}
        </Text>
      </Cell>
    </Item>
  );
};

type OwnCommercialMoveTimesheetTableProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'CommercialMoveTimesheetTableProps' cir... Remove this comment to see the full error message
type CommercialMoveTimesheetTableProps = OwnCommercialMoveTimesheetTableProps &
  typeof CommercialMoveTimesheetTable.defaultProps;

// @ts-expect-error TS(7022): 'CommercialMoveTimesheetTable' implicitly has type... Remove this comment to see the full error message
const CommercialMoveTimesheetTable = ({job}: CommercialMoveTimesheetTableProps) => (
  <Container>
    <TableHeader names={getColumnNames({job})} job={job} />
    {job.reportMove ? (
      <FlatList
        data={job.reportMove.moveUsers}
        keyExtractor={(moveUser: any) => String(moveUser.userId)}
        // @ts-expect-error TS(7031): Binding element 'moveUser' implicitly has an 'any'... Remove this comment to see the full error message
        renderItem={({item: moveUser}) => (
          <ReportMoveUserItem reportMoveUser={moveUser} job={job} />
        )}
      />
    ) : (
      <FlatList
        data={sortJobUsers({jobUsers: job.confirmedJobUsers})}
        keyExtractor={(jobUser: any) => jobUser.id}
        // @ts-expect-error TS(7031): Binding element 'jobUser' implicitly has an 'any' ... Remove this comment to see the full error message
        renderItem={({item: jobUser}) => <JobUserItem jobUser={jobUser} job={job} />}
      />
    )}
  </Container>
);

CommercialMoveTimesheetTable.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialMoveTimesheetTable.fragment = gql`
  fragment CommercialMoveTimesheetTable on Job {
    id
    hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
      kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
    )
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    confirmedJobUsers {
      id
      position
      branchCode
      timesheet {
        timeRanges {
          name
          start
          end
          kind
          startName
          endName
          date
          timestamp
          length
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
    reportMove {
      id
      moveUsers {
        userId
        position
        branchCode
        timesheet {
          timeRanges {
            name
            start
            end
            kind
            startName
            endName
          }
          totalTimeDrive
          totalTimeWithoutBreak
        }
        user {
          id
          firstName
          lastName
          organization {
            id
            name
          }
        }
      }
    }
  }
`;

export default CommercialMoveTimesheetTable;
