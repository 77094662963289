// Libraries
import React from 'react';

// Supermove
import {Hover, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

const Option = Styled.View`
  flex: 1;
`;

const Text = Styled.H6`
  padding-vertical: 5px;
  ${fontWeight(700)}
  border-bottom-color: ${colors.gray.primary};
  border-bottom-style: solid;
`;

const Touchable = Styled.Touchable`
  align-items: center;
  width: 100%;
`;

const Item = ({isSelected, count, name, url}: any) => {
  const {navigator} = useNavigationDOM();

  return (
    <Option>
      <Hover>
        {({isHovered}: any) => (
          <Touchable onPress={() => navigator.replace(`/jobs/requests/${url}`)}>
            <Text
              style={{
                color: isSelected
                  ? colors.gray.primary
                  : isHovered
                    ? colors.blue.interactive
                    : colors.gray.tertiary,
                borderBottomWidth: isSelected ? '2px' : '0px',
              }}
            >
              {`${name} (${count})`}
            </Text>
          </Touchable>
        )}
      </Hover>
    </Option>
  );
};

type OwnJobRequestsHeaderProps = {
  kind: string;
};

// @ts-expect-error TS(2456): Type alias 'JobRequestsHeaderProps' circularly ref... Remove this comment to see the full error message
type JobRequestsHeaderProps = OwnJobRequestsHeaderProps & typeof JobRequestsHeader.defaultProps;

// @ts-expect-error TS(7022): 'JobRequestsHeader' implicitly has type 'any' beca... Remove this comment to see the full error message
const JobRequestsHeader = ({jobRequestCounter, kind}: JobRequestsHeaderProps) => {
  return (
    <Container>
      <Item isSelected={kind === 'NEW'} count={jobRequestCounter.new} name={'New'} url={'new'} />
      <Item
        isSelected={kind === 'PENDING'}
        count={jobRequestCounter.pending}
        name={'Pending'}
        url={'pending'}
      />
      <Item
        isSelected={kind === 'CANCELLED'}
        count={jobRequestCounter.cancelled}
        name={'Deleted'}
        url={'cancelled'}
      />
    </Container>
  );
};

JobRequestsHeader.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestsHeader.fragment = gql`
  fragment JobRequestsHeader on JobRequestCounter {
    cancelled
    new
    pending
  }
`;

export default JobRequestsHeader;
