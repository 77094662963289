// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ProjectTypeModalFields from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeModalFields';

const Text = Styled.Text`
  ${Typography.Body3}
`;

const LinkText = Styled.Text`
  ${Typography.Link2}
`;

const Link = Styled.ButtonV2`
`;

const LoadingBody = () => {
  return (
    <React.Fragment>
      <Space height={16} />
      <PageLoadingIndicator />
      <Space height={16} />
    </React.Fragment>
  );
};

const CreateProjectTypeModalContent = ({form, organization, isStorageSettingsPage}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <Text>
        {`Select a billing library for this project type. ` +
          `You can also create a new billing library via the `}
        <Link onPress={() => navigator.push('/settings/billing/billing-libraries')}>
          <LinkText>Billing Libraries Settings</LinkText>
        </Link>
        {`.`}
      </Text>
      <Space height={16} />
      <ProjectTypeModalFields
        form={form}
        organization={organization}
        isStorageSettingsPage={isStorageSettingsPage}
      />
    </React.Fragment>
  );
};

const CreateProjectTypeModal = ({
  isOpen,
  handleClose,
  organizationId,
  form,
  handleSubmit,
  submitting,
  isStorageSettingsPage,
}: any) => {
  const {loading, data} = useQuery(CreateProjectTypeModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      organizationId,
    },
    skip: !isOpen,
  });
  return (
    <SmallModal isOpen={isOpen} style={{overflow: 'visible'}}>
      <SmallModal.HeaderText>{'Create Project Type'}</SmallModal.HeaderText>
      <Space height={16} />
      <Loading as={LoadingBody} loading={loading || !data}>
        {() => (
          <CreateProjectTypeModalContent
            form={form}
            organization={data.organization}
            isStorageSettingsPage={isStorageSettingsPage}
          />
        )}
      </Loading>
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          isDisabled={loading}
          isSubmitting={submitting}
          onPress={handleSubmit}
          color={colors.blue.interactive}
        >
          Create
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

CreateProjectTypeModal.query = gql`
  ${ProjectTypeModalFields.fragment}
  query CreateProjectTypeModal($organizationId: Int!) {
    ${gql.query}
    organization(organizationId: $organizationId) {
      id  
      ...ProjectTypeModalFields
    }
  }
`;

export default CreateProjectTypeModal;
