/**
 * DEPRECATED(mark): Once all organizations stop using DocumentTemplate.Kind.BILL, we can remove
 * this page.
 */

// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintDocumentPage} from 'modules/Document/Print/components';
import {JobBillDocument} from 'modules/Document/components';

const CustomerBillJobPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => (
  <PrintDocumentPage query={CustomerBillJobPage.query} variables={{uuid}}>
    {({data}: any) => <JobBillDocument job={data.job} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerBillJobPage.query = gql`
  ${JobBillDocument.fragment}

  query CustomerBillJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobBillDocument
    }
  }
`;

export default CustomerBillJobPage;
