// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, LocationInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useSearch, useState} from '@supermove/hooks';
import {Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import ResponsiveTextInput from 'modules/App/components/ResponsiveTextInput';
import ProjectLocationJobsCallout from 'modules/Project/V2/Edit/components/ProjectLocationJobsCallout';

const Column = Styled.View`
`;

const ResponsiveContainer = Styled.View`
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'row' : 'column')};
`;

const IndexWrapper = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const AdditionalDetailsText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${colors.blue.interactive};
`;

const getLocationManualOptions = ({locations}: any) => {
  return locations.map((location: any) => {
    return {
      description: location.name || location.address,
      location,
    };
  });
};

const getWarehouseOptions = ({project}: any) => {
  return project.organization.warehouses.map((warehouse: any) => {
    return {
      description: `${warehouse.name} - ${warehouse.location.address}`,
      location: warehouse.location,
      warehouse,
    };
  });
};

const handleWarehouseLocation = ({form, field, location, warehouse}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const prevLocationForm = _.get(form.values, field);
  const locationForm = LocationForm.toForm(LocationForm.copy(location));
  setFieldValue(field, {
    ...locationForm,
    name: warehouse.name,
    locationId: prevLocationForm ? prevLocationForm.locationId : null,
  });
};

const handleManualLocation = ({form, field, location}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const prevLocationForm = _.get(form.values, field);
  const locationForm = LocationForm.toForm(LocationForm.copy(location));
  setFieldValue(field, {...locationForm, locationId: prevLocationForm.locationId});
};

const handleOnLocation = ({form, field, location}: any) => {
  const setFieldValue = form.customSetFieldValue || form.setFieldValue;
  const {address, city, state, zipCode, country, latitude, longitude} = location;
  const locationForm = _.get(form.values, field);
  setFieldValue(field, {
    ...locationForm,
    address,
    city,
    state,
    zipCode,
    country,
    latitude,
    longitude,
  });
};

const RowSpace = () => {
  return <Space height={16} />;
};

const FieldRow = ({index, responsive, children}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <IndexWrapper index={index}>
      <ResponsiveContainer responsive={responsive}>{children}</ResponsiveContainer>
    </IndexWrapper>
  );
};

const AddressInput = ({form, field, project, isDisabled, isWarehouse}: any) => {
  const {isEnabledGooglePlacesCenterLocation} = project.organization.features;

  // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  const {setQuery, results: locations} = useSearch({
    initialQuery: '',
    items: project.locations,
    options: {keys: ['name', 'address', 'zipCode', 'unit', 'floorNumber', 'notes'], threshold: 0.4},
  });

  return (
    <FieldInput
      {...form}
      index={0}
      component={LocationInput}
      name={`${field}.address`}
      label={'Address'}
      isRequired
      input={{
        disabled: isDisabled,
        setFieldValue: form.customSetFieldValue || form.setFieldValue,
        setFieldError: form.setFieldError,
        setFieldTouched: form.setFieldTouched,
        onChangeText: setQuery,
        placeholder: 'Enter address',
        suggestionsLabel: 'Custom',
        suggestionsPlaceholder: 'Start typing to create a new stop',
        manualOptionsLabel: 'Project Locations',
        manualOptions: getLocationManualOptions({locations}),
        warehouseOptionsLabel: 'Warehouses (Internal)',
        warehouseOptions: isWarehouse ? getWarehouseOptions({project}) : null,
        handlePressManualOption: ({location}: any) => handleManualLocation({location, form, field}),
        handlePressWarehouseOption: ({location, warehouse}: any) =>
          handleWarehouseLocation({location, warehouse, form, field}),
        onLocation: (location: any) => handleOnLocation({location, form, field}),
        initialLocation: isEnabledGooglePlacesCenterLocation
          ? project.owningOrganization.warehouseLocation
          : null,
      }}
      style={{flex: 1}}
      isResponsive
    />
  );
};

const AddressField = ({form, field, index, project, responsive, isDisabled, isWarehouse}: any) => {
  return (
    <FieldRow index={index} responsive={responsive}>
      <AddressInput
        form={form}
        field={field}
        project={project}
        isDisabled={isDisabled}
        isWarehouse={isWarehouse}
      />
      <Space width={16} height={16} />
      <FieldInput.Memoized
        {...form}
        index={1}
        name={`${field}.zipCode`}
        label={'Zip Code'}
        input={{disabled: true}}
        style={responsive.desktop ? {width: 120} : {flex: 1}}
        isResponsive
      />
    </FieldRow>
  );
};

const AdditionalDetailsButton = ({isExpanded, setIsExpanded, responsive}: any) => {
  const width = responsive.desktop ? 12 : 14;
  return (
    <TertiaryButton onPress={() => setIsExpanded(!isExpanded)} isResponsive>
      <Icon
        source={isExpanded ? Icon.ChevronDown : Icon.ChevronRight}
        size={width}
        style={{width}}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <AdditionalDetailsText responsive={responsive}>Additional Details</AdditionalDetailsText>
    </TertiaryButton>
  );
};

const NotesInput = ({form, field, index, isDisabled}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <IndexWrapper index={index}>
      <FieldInput.LabelText isResponsive>Location Notes</FieldInput.LabelText>
      <Space height={4} />
      <ResponsiveTextInput.Memoized
        form={form}
        field={`${field}.notes`}
        minHeight={80}
        disabled={isDisabled}
        input={{
          style: {
            paddingLeft: 12,
            paddingRight: 12,
          },
          placeholder: 'Enter notes such as parking details, access information, or gate codes',
        }}
        isResponsive
      />
    </IndexWrapper>
  );
};

const NameAndType = ({form, field, index, project, isDisabled, responsive}: any) => {
  return (
    <FieldRow index={index} responsive={responsive}>
      <FieldInput.Memoized
        {...form}
        name={`${field}.name`}
        label={'Location Name'}
        input={{
          disabled: isDisabled,
          placeholder: 'Enter location name',
        }}
        style={{flex: 1}}
        isResponsive
      />
      <Space width={16} height={16} />
      <FieldInput
        {...form}
        name={`${field}.buildingType`}
        label={'Location Type'}
        component={DropdownInput}
        style={{flex: 1}}
        input={{
          isDisabled,
          options: Organization.getLocationTypeDropdownOptions(project.organization),
          placeholder: 'Select type',
          style: {flex: 1},
          setFieldValue: form.setFieldValue,
        }}
        isResponsive
      />
    </FieldRow>
  );
};

const UnitAndFloor = ({form, field, index, isDisabled, responsive}: any) => {
  return (
    <FieldRow index={index} responsive={responsive}>
      <FieldInput.Memoized
        {...form}
        name={`${field}.unit`}
        label={'Unit / Suite #'}
        input={{
          disabled: isDisabled,
          placeholder: 'Enter unit #',
        }}
        style={{flex: 1}}
        isResponsive
      />
      <Space width={16} height={16} />
      <FieldInput.Memoized
        {...form}
        name={`${field}.floorNumber`}
        label={'Floor #'}
        input={{
          disabled: isDisabled,
          placeholder: 'Enter floor #',
        }}
        style={{flex: 1}}
        isResponsive
      />
    </FieldRow>
  );
};

const StairsAndWalk = ({form, field, index, isDisabled, responsive}: any) => {
  const hasElevator = _.get(form.values, `${field}.hasElevator`);
  const hasLongWalk = _.get(form.values, `${field}.hasLongWalk`);
  return (
    <FieldRow index={index} responsive={responsive}>
      <FieldInput.Memoized
        {...form}
        index={index}
        name={`${field}.stairCount`}
        label={'Flights of Stairs'}
        input={{
          disabled: isDisabled,
          placeholder: 'Enter # of flights',
        }}
        style={{flex: 1}}
        isResponsive
      />
      <Space width={16} />
      <Column style={{flex: 1}}>
        <Space height={responsive.desktop ? 18 : 16} />
        <Checkbox
          isDisabled={isDisabled}
          isChecked={hasElevator}
          style={{alignItems: 'center'}}
          label={'Elevator'}
          handleToggle={() => form.setFieldValue(`${field}.hasElevator`, !hasElevator)}
          isMobile={!responsive.desktop}
        />
        <Space height={responsive.desktop ? 4 : 12} />
        <Checkbox
          isDisabled={isDisabled}
          isChecked={hasLongWalk}
          label={'Long Walk'}
          handleToggle={() => form.setFieldValue(`${field}.hasLongWalk`, !hasLongWalk)}
          isMobile={!responsive.desktop}
        />
      </Column>
    </FieldRow>
  );
};

const AdditionalDetails = ({form, field, project, isStops, responsive}: any) => {
  const isExisting = !!_.get(form.values, `${field}.locationId`);
  const isCopy = !!_.get(form.values, `${field}.copyOfLocationId`);
  const isDisabled = (isExisting || isCopy) && isStops;

  return (
    <React.Fragment>
      <RowSpace />
      <NotesInput form={form} field={field} index={0} isDisabled={isDisabled} />
      <RowSpace />
      <NameAndType
        form={form}
        field={field}
        index={1}
        project={project}
        isDisabled={isDisabled}
        responsive={responsive}
      />
      <RowSpace />
      <UnitAndFloor
        form={form}
        field={field}
        index={2}
        isDisabled={isDisabled}
        responsive={responsive}
      />
      <RowSpace />
      <StairsAndWalk
        form={form}
        field={field}
        index={3}
        isDisabled={isDisabled}
        responsive={responsive}
      />
    </React.Fragment>
  );
};

const EditProjectLocationFields = ({
  form,
  field,
  project,
  autoFocus,
  isStops,
  showJobsCallout,
  isDisabled,
  isWarehouse,
}: any) => {
  const responsive = useResponsive();
  const [isExpanded, setIsExpanded] = useState(autoFocus);
  const location = _.find(project.locations, ['id', _.get(form.values, `${field}.locationId`)]);

  return (
    <React.Fragment>
      {showJobsCallout && location && (
        <ProjectLocationJobsCallout location={location} marginBottom={16} />
      )}
      <AddressField
        form={form}
        field={field}
        index={0}
        project={project}
        responsive={responsive}
        isDisabled={isDisabled}
        isWarehouse={isWarehouse}
      />
      <RowSpace />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <IndexWrapper index={1}>
        {!isWarehouse && (
          <AdditionalDetailsButton
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            responsive={responsive}
          />
        )}
        {isExpanded && (
          <AdditionalDetails
            form={form}
            field={field}
            project={project}
            isStops={isStops}
            responsive={responsive}
            isDisabled={isDisabled}
          />
        )}
      </IndexWrapper>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectLocationFields.fragment = gql`
  ${LocationForm.copy.fragment}
  ${Organization.getLocationTypeDropdownOptions.fragment}
  ${ProjectLocationJobsCallout.fragment}

  fragment EditProjectLocationFields on Project {
    id
    organization {
      id
      features {
        isEnabledGooglePlacesCenterLocation: isEnabled(feature: "GOOGLE_PLACES_CENTER_LOCATION")
      }
      warehouses {
        id
        name
        location {
          id
          address
          ...LocationForm_copy
        }
      }
      ...Organization_getLocationTypeDropdownOptions
    }
    owningOrganization {
      id
      warehouseLocation {
        latitude
        longitude
      }
    }
    locations {
      id
      name
      address
      city
      state
      zipCode
      country
      latitude
      longitude
      unit
      floorNumber
      notes
      ...LocationForm_copy
      ...ProjectLocationJobsCallout
    }
  }
`;

export default EditProjectLocationFields;
