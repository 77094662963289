// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import FieldValue from '@shared/design/components/Field/FieldValue';

const Container = Styled.View`
`;

const Section = Styled.View`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label1}
`;

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowSpace = Styled.View`
  margin-top: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '8px' : '16px')};
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobNotesBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobNotesBlock' implicitly has type 'any' because ... Remove this comment to see the full error message
const JobNotesBlock = ({job}: Props) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Section>
        <Space height={12} />
        <Title>Notes</Title>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row index={0} {...responsive}>
          <FieldValue empty={'None'} label={'Crew Notes'} value={job.additionalNotes} />
        </Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <RowSpace {...responsive} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row index={2} {...responsive}>
          <FieldValue empty={'None'} label={'Dispatch Notes'} value={job.dispatchNotes} />
        </Row>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <RowSpace {...responsive} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Row index={1} {...responsive}>
          <FieldValue empty={'None'} label={'Office Notes'} value={job.officeNotes} />
        </Row>
        <Space height={16} />
      </Section>
    </Container>
  );
};

JobNotesBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNotesBlock.fragment = gql`
  fragment JobNotesBlock on Job {
    id
    additionalNotes
    officeNotes
    dispatchNotes
  }
`;

export default JobNotesBlock;
