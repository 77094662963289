// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import TextTooltip from '@shared/design/components/TextTooltip';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.secondary}
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const TOOLTIP_TEXT = 'Applies to surveyed items without a custom weight.';

const ViewCell = ({label, placeholder, value}: any) => {
  return (
    <Row>
      <Column>
        <Row style={{alignItems: 'center'}}>
          <LabelText>{label}</LabelText>
          <Space width={10} />
          <TextTooltip
            isEnabledMobileToast={false}
            placement={'top'}
            style={{textAlign: 'center'}}
            text={TOOLTIP_TEXT}
          >
            <IconContainer>
              <Icon
                color={colors.gray.secondary}
                size={Icon.Sizes.Medium}
                source={Icon.InfoCircle}
              />
            </IconContainer>
          </TextTooltip>
        </Row>
        <Space height={8} />
        <EditPanel.Text style={{color: value ? colors.black : colors.gray.tertiary}}>
          {value || placeholder}
        </EditPanel.Text>
      </Column>
    </Row>
  );
};

const MeasurementsPanelEdit = ({form}: any) => {
  return (
    <React.Fragment>
      <Row>
        <FieldInput
          {...form}
          label={`Default Density Factor`}
          name={'organizationInventorySettingsForm.defaultDensityFactor'}
          isRequired
          LabelIconComponent={Icon.InfoCircle}
          tooltip={TOOLTIP_TEXT}
        />
      </Row>
    </React.Fragment>
  );
};

const MeasurementsPanel = ({organization}: any) => {
  return (
    <React.Fragment>
      <ViewCell
        label={'Default Density Factor'}
        placeholder={'Add default density'}
        value={`${organization.settings.defaultDensityFactor} lbs/cu ft`}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MeasurementsPanel.fragment = gql`
  fragment MeasurementsPanel on Organization {
    id
    settings {
      id
      defaultDensityFactor
    }
  }
`;

MeasurementsPanel.Edit = MeasurementsPanelEdit;

export default MeasurementsPanel;
