// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {ModalPage, Navigation} from 'modules/App/components';

import UserDayNotFoundPage from './UserDayNotFoundPage';

type OwnProps = {
  onClose?: (...args: any[]) => any;
  side?: (...args: any[]) => any;
  title?: (...args: any[]) => any;
  subtitle?: (...args: any[]) => any;
  right?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UserDayPage.defaultProps;

// @ts-expect-error TS(7022): 'UserDayPage' implicitly has type 'any' because it... Remove this comment to see the full error message
const UserDayPage = ({
  side,
  title,
  subtitle,
  right,
  variables,
  query,
  onClose,
  children,
}: Props) => (
  <ModalPage
    variables={variables}
    query={query}
    navigation={({responsive, loading, data, navigator}: any) => (
      <Navigation
        side={side}
        title={title({responsive, loading, data})}
        subtitle={subtitle({responsive, loading, data})}
        left={() =>
          onClose ? (
            <Navigation.CloseButton onPress={() => onClose({loading, data, navigator})} />
          ) : null
        }
        right={() => right({loading, data, navigator})}
      />
    )}
  >
    {({data, ...props}: any) =>
      data.userDay ? children({...props, data}) : <UserDayNotFoundPage />
    }
  </ModalPage>
);

UserDayPage.defaultProps = {
  onClose: undefined,
  side: () => 60,
  title: () => '',
  subtitle: () => '',
  right: () => null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UserDayPage.fragment = gql`
  fragment UserDayPage on UserDay {
    id
  }
`;

export default UserDayPage;
