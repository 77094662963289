// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Datetime} from '@supermove/utils';

// App
import TimeRangesChart from 'modules/App/components/TimeRangesChart';

const Text = Styled.H7`
  margin-top: 5px;
`;

const getTimesheetText = ({timeRanges}: any) => {
  return timeRanges
    .map((timeRange: any) => {
      const start = Datetime.convertToDisplayTime(timeRange.start, Datetime.FORM_TIME);
      if (timeRange.end) {
        const end = Datetime.convertToDisplayTime(timeRange.end, Datetime.FORM_TIME);
        return `${timeRange.name} (${start} - ${end})`;
      }
      return `${timeRange.name} (${start}, In Progress)`;
    })
    .join(', ');
};

type OwnDocumentMoveTimesChartProps = {};

// @ts-expect-error TS(2456): Type alias 'DocumentMoveTimesChartProps' circularl... Remove this comment to see the full error message
type DocumentMoveTimesChartProps = OwnDocumentMoveTimesChartProps &
  typeof DocumentMoveTimesChart.defaultProps;

// @ts-expect-error TS(7022): 'DocumentMoveTimesChart' implicitly has type 'any'... Remove this comment to see the full error message
const DocumentMoveTimesChart = ({hasConfirmedTimes, timeRanges}: DocumentMoveTimesChartProps) => {
  return (
    <React.Fragment>
      <TimeRangesChart hasConfirmedTimes={hasConfirmedTimes} timeRanges={timeRanges} />
      <Text>{getTimesheetText({timeRanges})}</Text>
    </React.Fragment>
  );
};

DocumentMoveTimesChart.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentMoveTimesChart.fragment = gql`
  ${TimeRangesChart.fragment}

  fragment DocumentMoveTimesChart on TimeRange {
    name
    kind
    start
    end
    ...TimeRangesChart
  }
`;

export default DocumentMoveTimesChart;
