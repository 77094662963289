// Libararies
import React from 'react';

// Supermove
import {Styled, Space, Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useModal, useHover} from '@supermove/hooks';
import {Job, JobUser} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import CrewTrucksPopover from 'modules/Dispatch/Crew/components/CrewTrucksPopover';
import CrewUsersAndDriversPopover from 'modules/Dispatch/Crew/components/CrewUsersAndDriversPopover';
import CreateCrewsForJobButton from 'modules/Job/V2/Move/components/CreateCrewsForJobButton';
import CreateCrewsForJobPopoverContent from 'modules/Job/V2/Move/components/CreateCrewsForJobPopoverContent';
import JobJobUsersScheduleModal from 'modules/Job/V2/Move/components/JobJobUsersScheduleModal';
import ViewLaborSourcesPopoverContent from 'modules/Job/V2/Move/components/ViewLaborSourcesPopoverContent';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FullLengthRow = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.gray.background};
  border-radius: 4px;
  border: 1px solid ${colors.gray.border};
  padding: 8px;
  margin: 8px;
`;

const ContainerWithBorder = Styled.View<{isHovered: boolean; isModalOpen: boolean}>`
  display: flex;
  border: 1px solid ${({isHovered, isModalOpen}) =>
    isHovered || isModalOpen ? colors.blue.interactive : colors.gray.border};
  border-radius: 4px;
  padding-horizontal: 8px;
`;

const DispatchCountText = Styled.Text<{isModalOpen?: boolean}>`
  ${Typography.Micro}
  color: ${({isModalOpen}) => (isModalOpen ? colors.blue.interactive : colors.gray.primary)};
`;

const RowWrap = Styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
`;

const FlexContainer = Styled.View<{flex: number}>`
  flex: ${({flex}) => flex};
`;

const DispatchLaborSourcePopover = ({popover, job, viewer, refetch}: any) => {
  const isContractor = viewer.viewingOrganization.id !== job.project.organization.id;
  return (
    <Popover
      placement={Popover.Positions.BottomEnd}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <ResponsivePopover.StaticContainer width={320} maxHeight={700}>
        {isContractor ? (
          <ViewLaborSourcesPopoverContent job={job} viewer={viewer} />
        ) : (
          <CreateCrewsForJobPopoverContent
            job={job}
            handleClose={popover.handleClose}
            refetch={refetch}
            shouldRunUpsertCostsAndBillingEngineAsync
          />
        )}
      </ResponsivePopover.StaticContainer>
    </Popover>
  );
};

const DispatchLaborSourceEditButton = ({job, viewer, refetch}: any) => {
  const dispatchLaborSourcePopover = usePopover();

  return (
    <React.Fragment>
      <Popover.Content innerRef={dispatchLaborSourcePopover.ref}>
        <TertiaryButton onPress={dispatchLaborSourcePopover.handleOpen}>
          <Icon source={Icon.Pen} size={12} color={colors.blue.interactive} />
        </TertiaryButton>
      </Popover.Content>
      <DispatchLaborSourcePopover
        popover={dispatchLaborSourcePopover}
        job={job}
        viewer={viewer}
        refetch={() => refetch({shouldFetchJobsListTable: true})}
      />
    </React.Fragment>
  );
};

const DispatchTrucksButton = ({crew, refetch, job}: any) => {
  const editTrucksPopover = usePopover();
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <Row ref={ref}>
        <Popover.Content innerRef={editTrucksPopover.ref}>
          <TertiaryButton onPress={editTrucksPopover.handleOpen}>
            <ContainerWithBorder isHovered={isHovered} isModalOpen={editTrucksPopover.isOpen}>
              <Row>
                <Icon
                  source={Icon.Truck}
                  color={editTrucksPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
                  size={10}
                />
                <Space width={6} />
                <DispatchCountText isModalOpen={editTrucksPopover.isOpen}>
                  {Job.getDispatchJobTrucksCount(job)}
                </DispatchCountText>
              </Row>
            </ContainerWithBorder>
          </TertiaryButton>
        </Popover.Content>
      </Row>
      <CrewTrucksPopover
        isOpen={editTrucksPopover.isOpen}
        handleOpen={editTrucksPopover.handleOpen}
        handleClose={editTrucksPopover.handleClose}
        popoverRef={editTrucksPopover.ref}
        crewId={crew.id}
        placement={Popover.Positions.RightStart}
        offset={[0, 4]}
        shouldRunUpsertCostsAndBillingEngineAsync
        refetch={() => {
          refetch();
          // When a new truck gets saved, it will force this popover to close when the
          // data refetches and relocates the job card to a different slot. Instead of
          // waiting for the delayed update, we close the modal right after the refetch.
          editTrucksPopover.handleClose();
        }}
      />
    </React.Fragment>
  );
};

const DispatchMoversButton = ({crew, refetch, job}: any) => {
  const editMoversPopover = usePopover();
  const jobJobUsersScheduleModal = useModal();
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      <Row ref={ref}>
        <Popover.Content innerRef={editMoversPopover.ref}>
          <TertiaryButton onPress={editMoversPopover.handleOpen}>
            <ContainerWithBorder isHovered={isHovered} isModalOpen={editMoversPopover.isOpen}>
              <Row>
                <Icon
                  source={Icon.User}
                  color={editMoversPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
                  size={12}
                />
                <Space width={6} />
                <DispatchCountText isModalOpen={editMoversPopover.isOpen}>
                  {Job.getDispatchJobUsersCount(job)}
                </DispatchCountText>
              </Row>
            </ContainerWithBorder>
          </TertiaryButton>
        </Popover.Content>
      </Row>
      <CrewUsersAndDriversPopover
        isOpen={editMoversPopover.isOpen}
        key={`${editMoversPopover.isOpen}-MOVERS`}
        handleOpen={editMoversPopover.handleOpen}
        handleClose={editMoversPopover.handleClose}
        popoverRef={editMoversPopover.ref}
        crew={crew}
        placement={Popover.Positions.Right}
        refetch={refetch}
        jobJobUsersScheduleModal={jobJobUsersScheduleModal}
        offset={[-32, 4]}
        shouldRunUpsertCostsAndBillingEngineAsync
      />
      <JobJobUsersScheduleModal
        jobUuid={job.uuid}
        isOpen={jobJobUsersScheduleModal.isOpen}
        handleClose={() => {
          jobJobUsersScheduleModal.handleClose();
          refetch();
        }}
      />
    </React.Fragment>
  );
};

const DispatchTrucksContent = ({crew, refetch, job}: any) => {
  const activeJobTrucksForOrganization = job.jobTrucks.filter(
    (activeJobTruck: any) => activeJobTruck.truck.organizationId === crew.organizationId,
  );
  return (
    <Row>
      <DispatchTrucksButton crew={crew} refetch={refetch} job={job} />
      <RowWrap>
        {activeJobTrucksForOrganization &&
          activeJobTrucksForOrganization.map((jobTruck: any) => (
            <React.Fragment key={jobTruck.id}>
              <Space width={4} />
              <DispatchCountText>{jobTruck.truck.name}</DispatchCountText>
            </React.Fragment>
          ))}
      </RowWrap>
    </Row>
  );
};

const DispatchMoversContent = ({crew, refetch, job}: any) => {
  const assignedJobUsersForOrganization = job.assignedJobUsers.filter(
    (assignedJobUser: any) => assignedJobUser.user.organizationId === crew.organizationId,
  );
  return (
    <Row>
      <DispatchMoversButton crew={crew} refetch={refetch} job={job} />
      <RowWrap>
        {assignedJobUsersForOrganization.map((assignedJobUser: any, index: any) => (
          <React.Fragment key={assignedJobUser.id}>
            <TextTooltip
              isEnabledMobileToast={false}
              text={JobUser.getTooltipStatus(assignedJobUser)}
            >
              <Row>
                <Space width={4} />
                <Icon source={Icon.Circle} color={assignedJobUser.statusColor} size={6} />
                <Space width={4} />
                <DispatchCountText>
                  {JobUser.getFirstNameAndLastInitial(assignedJobUser)}
                </DispatchCountText>
              </Row>
            </TextTooltip>
          </React.Fragment>
        ))}
      </RowWrap>
    </Row>
  );
};

const DispatchLaborSourceList = ({crews, job, viewer, refetch}: any) => {
  const canViewAndEditOtherOrgs =
    viewer.viewingOrganization.hasMultipleOrganizations && viewer.viewingOrganization.isPrimary;
  if (!canViewAndEditOtherOrgs) {
    crews = crews.filter((crew: any) => crew.organization.id === viewer.viewingOrganization.id);
  }
  return (
    <Container>
      {crews.map((crew: any, index: any) => {
        const isLastItem = index === crews.length - 1;
        return (
          <React.Fragment key={index}>
            <Row>
              {canViewAndEditOtherOrgs && (
                <FlexContainer flex={1.5}>
                  <Row>
                    <Table.CellText>{crew.organization.name}</Table.CellText>
                    <Space width={8} />
                    <DispatchLaborSourceEditButton job={job} viewer={viewer} refetch={refetch} />
                  </Row>
                </FlexContainer>
              )}
              <FlexContainer flex={1.5}>
                <DispatchTrucksContent crew={crew} refetch={refetch} job={job} />
              </FlexContainer>
              <FlexContainer flex={3}>
                <DispatchMoversContent crew={crew} refetch={refetch} job={job} />
              </FlexContainer>
            </Row>
            {!isLastItem && <Space height={14} />}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const CapacityCalendarTableDispatch = ({isVisible, viewer, refetch, item: job}: any) => {
  if (!isVisible) {
    return null;
  }
  return (
    <FullLengthRow>
      {job.crews.length > 0 ? (
        <DispatchLaborSourceList crews={job.crews} job={job} viewer={viewer} refetch={refetch} />
      ) : (
        <CreateCrewsForJobButton
          job={job}
          viewer={viewer}
          refetch={() => refetch({shouldFetchJobsListTable: true})}
          isButtonTertiary
        />
      )}
    </FullLengthRow>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarTableDispatch.fragment = gql`
  ${Job.getDispatchJobTrucksCount.fragment}
  ${Job.getDispatchJobUsersCount.fragment}
  ${CreateCrewsForJobButton.fragment}
  ${CrewUsersAndDriversPopover.fragment}
  ${JobUser.getTooltipStatus.fragment}
  ${JobUser.getFirstNameAndLastInitial.fragment}

  fragment CapacityCalendarTableDispatch_Job on Job {
    id
    uuid
    jobTrucks {
      id
      truck {
        id
        name
        organizationId
      }
    }
    assignedJobUsers {
      id
      statusColor
      user {
        id
        position
        organizationId
        fullName
      }
      ...JobUser_getTooltipStatus
      ...JobUser_getFirstNameAndLastInitial
    }
    project {
      id
      organization {
        id
      }
    }
    crews {
      id
      organizationId
      organization {
        id
        name
      }
      ...CrewUsersAndDriversPopover
    }
    ...Job_getDispatchJobTrucksCount
    ...Job_getDispatchJobUsersCount
    ...CreateCrewsForJobButton_Job
  }

  fragment CapacityCalendarTableDispatch_User on User {
    id
    viewingOrganization {
      id
      hasMultipleOrganizations
      name
      isPrimary
    }
    ...CreateCrewsForJobButton_Viewer
  }
`;

export default CapacityCalendarTableDispatch;
