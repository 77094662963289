// Libraries
import React from 'react';

// Supermove
import {
  Icon,
  Popover,
  PreventPropagation,
  Link,
  Space,
  Styled,
  ScrollView,
} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useNavigationDOM, useSidebar, useModal} from '@supermove/hooks';
import {Inventory, Tag} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency, Datetime, pluralize, titleize} from '@supermove/utils';

// App
import DateFieldComponent from '@shared/design/components/Field/DateField';
import PhoneNumber from '@shared/design/components/PhoneNumber';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import ProjectFollowUpDateForm from '@shared/modules/Project/forms/ProjectFollowUpDateForm';
import useUpdateProjectFollowUpDateMutation from '@shared/modules/Project/hooks/useUpdateProjectFollowUpDateMutation';
import ProjectCommentsButton from 'modules/Project/components/ProjectCommentsButton';
import ProjectSalesNotesPopover from 'modules/Project/components/ProjectSalesNotesPopover';
import TagEmojiButton from 'modules/Tag/components/TagEmojiButton';
import UpsertProjectTagModal from 'modules/Tag/components/UpsertProjectTagModal';

const Container = Styled.View`
  background-color: ${colors.white}
`;

const CellContainer = Styled.View`
  flex: 1;
`;

const ProjectTypeColorIndicator = Styled.Text`
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 8px;
  padding-left: 8px;
`;

const LinkWrapper = Styled.ButtonV2`
`;

const ButtonContainer = Styled.Touchable`
`;

/**
 * Define columns, including:
 * title, key, flex ratio, and functions
 * for extracting primary and secondary data for each cell
 */
const getProjectsListColumns = ({
  status,
  refetch,
  isEnabledProjectDetailsFollowUp,
  isFilteredByFollowUpDate,
  isEnabledMovesListMultiBranchSupport,
  isPrimaryOrganization,
  isFilteringCancelledProjects,
  isEnabledProjectTag,
}: any) => {
  return [
    {
      flex: 3,
      isHidden: status === 'completed' || status === 'booked',
      headerContent: () => {
        return <Table.HeaderText>Sales Status</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <Table.CellText data-test-id='projects-list-sales-status'>
            {titleize(project.salesStatus.replace('_', ' '))}
          </Table.CellText>
        );
      },
    },
    // TODO(Kevin): Move this somewhere, mocks is deviating from the current
    // product, placing it here for now
    {
      flex: 1,
      minWidth: 80,
      isHidden: !isEnabledProjectTag,
      headerContent: () => {
        return <Table.HeaderText>Tags</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <PreventPropagation>
            <ProjectTagButton project={project} refetch={refetch} />
          </PreventPropagation>
        );
      },
    },
    {
      flex: 2,
      minWidth: 90,
      headerContent: () => {
        return <Table.HeaderText>Created At</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <Table.CellText>
              {Datetime.convertToDisplayDatetime(project.createdAt, Datetime.DISPLAY_SHORT_DATE)}
            </Table.CellText>
            <Table.CellSubtext numberOfLines={1}>
              {Datetime.convertToDisplayDatetime(project.createdAt, Datetime.DISPLAY_TIME)}
            </Table.CellSubtext>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      isHidden: !isEnabledProjectDetailsFollowUp,
      minWidth: 110,
      headerContent: () => {
        return <Table.HeaderText>Follow Up Date</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <FollowUpDatePicker
              project={project}
              refetch={refetch}
              isFilteredByFollowUpDate={isFilteredByFollowUpDate}
            />
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 110,
      headerContent: () => {
        return <Table.HeaderText>Client Name</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        const hasSeparateContactName =
          project.client.name !== project.client.primaryContact.fullName;

        return (
          <CellContainer>
            <LinkWrapper>
              <Link to={`/clients/${project.client.uuid}`}>
                <Table.CellLink numberOfLines={1}>{project.client.name}</Table.CellLink>
              </Link>
            </LinkWrapper>
            <Table.CellText numberOfLines={1}>
              {hasSeparateContactName ? project.client.primaryContact.fullName : ''}
            </Table.CellText>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 110,
      headerContent: () => {
        return <Table.HeaderText>Contact</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            {project.client.primaryContact.phoneNumber ? (
              <PhoneNumber isClickable phoneNumber={project.client.primaryContact.phoneNumber} />
            ) : (
              <Table.CellText numberOfLines={1}>--</Table.CellText>
            )}
            <Table.CellSubtext numberOfLines={1}>
              {project.client.primaryContact.email ? project.client.primaryContact.email : ''}
            </Table.CellSubtext>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 130,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Move Project Type</Table.HeaderText>
            <Table.HeaderSubtext>Inventory Type</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <Table.CellText numberOfLines={1}>
              <ProjectTypeColorIndicator color={project.projectType.color}>
                •
              </ProjectTypeColorIndicator>
              <Space width={7} />
              {project.projectType.name}
            </Table.CellText>
            <Table.CellSubtext numberOfLines={1}>
              {project.inventory && Inventory.getDisplayKind(project.inventory)}
            </Table.CellSubtext>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 100,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Price</Table.HeaderText>
            <Table.HeaderSubtext>Weight</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <Table.CellText numberOfLines={1}>
              {project.totalRevenue ? Currency.format({value: project.totalRevenue}) : '--'}
            </Table.CellText>
            <Table.CellSubtext numberOfLines={1}>
              {project.weight ? pluralize('lb', project.weight, true) : '--'}
            </Table.CellSubtext>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 110,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Date</Table.HeaderText>
            <Table.HeaderSubtext>(Start - End)</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        const {startDate, endDate} = project;
        if (!startDate || !endDate) {
          return <Table.CellText>--</Table.CellText>;
        }

        const displayStartDate = Datetime.convertToDisplayDate(
          startDate,
          Datetime.DISPLAY_SHORT_DATE,
        );
        const displayEndDate = Datetime.convertToDisplayDate(endDate, Datetime.DISPLAY_SHORT_DATE);

        if (startDate === endDate) {
          return <Table.CellText>{displayStartDate}</Table.CellText>;
        } else {
          return <Table.CellText>{`${displayStartDate} - ${displayEndDate}`}</Table.CellText>;
        }
      },
    },
    {
      flex: 3,
      minWidth: 110,
      isHidden: !(isEnabledMovesListMultiBranchSupport && isPrimaryOrganization),
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Labor Source</Table.HeaderText>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        const jobs = isFilteringCancelledProjects ? project.jobs : project.activeJobs;
        const laborSources = jobs
          .map((job: any) => {
            return job.crews.map((crew: any) => {
              return crew.organization.name;
            });
          })
          // flattens array of arrays to a single array of strings
          // i.e. [[], ['bluxomemovers'], ['subcontractor']] => ['bluxomemovers', 'subcontractor']
          .reduce((organizationNames: any, organizationName: any) => {
            return organizationNames.concat(organizationName);
          }, [])
          // remove duplicate organization names (same organization can be on multiple jobs)
          .filter((value: any, index: any, self: any) => self.indexOf(value) === index)
          .join(', ');

        if (laborSources === '') {
          return <Table.CellSubtext>{`None`}</Table.CellSubtext>;
        }
        return <Table.CellText>{laborSources}</Table.CellText>;
      },
    },
    {
      flex: 3,
      minWidth: 110,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Salesperson</Table.HeaderText>
            <Table.HeaderSubtext>Coordinator</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return (
          <CellContainer>
            <Table.CellText numberOfLines={1}>
              {project.bookedBy ? project.bookedBy.fullName : '--'}
            </Table.CellText>
            <Table.CellSubtext numberOfLines={1}>
              {project.coordinatedBy ? project.coordinatedBy.fullName : '--'}
            </Table.CellSubtext>
          </CellContainer>
        );
      },
    },
    {
      flex: 3,
      minWidth: 110,
      headerContent: () => {
        return (
          <CellContainer>
            <Table.HeaderText>Referral Source</Table.HeaderText>
            <Table.HeaderSubtext>Referral Details</Table.HeaderSubtext>
          </CellContainer>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        const {referralSource, referralDetails} = project;
        const hasReferralSource = !!referralSource;
        const hasReferralDetails = !!referralDetails;
        const isTooltipVisible = hasReferralSource || hasReferralDetails;
        const tooltipText = `${referralSource || ''}${
          hasReferralSource && hasReferralDetails ? '\n\n' : ''
        }${referralDetails || ''}`;
        return (
          <TextTooltip isEnabledMobileToast={false} text={isTooltipVisible ? tooltipText : null}>
            <CellContainer>
              <Table.CellText numberOfLines={1}>{referralSource || '--'}</Table.CellText>
              <Table.CellSubtext numberOfLines={1}>{referralDetails || '--'}</Table.CellSubtext>
            </CellContainer>
          </TextTooltip>
        );
      },
    },
    {
      flex: 1,
      minWidth: 70,
      headerContent: () => {
        return <Table.HeaderText>Sales Notes</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return <SalesNotesButton project={project} />;
      },
    },
    {
      flex: 4,
      minWidth: 110,
      headerContent: () => {
        return <Table.HeaderText>Comments</Table.HeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'project' implicitly has an 'any' ... Remove this comment to see the full error message
      cellContent: ({item: project}) => {
        return <ProjectsListCommentsButton project={project} />;
      },
    },
  ];
};

const ProjectTagButton = ({project, refetch}: any) => {
  const upsertProjectTagModal = useModal({name: 'Upsert Project Tag Modal', enableTracking: true});
  const tags = Tag.getProjectTagAndTagAssignment(project);
  const tagIds = tags.map((tag: any) => tag.id);

  return (
    <React.Fragment>
      <TagEmojiButton
        tags={tags}
        numberOfVisibleSelections={3}
        upsertTagModal={upsertProjectTagModal}
      />
      <UpsertProjectTagModal
        key={`${upsertProjectTagModal.key}_${project.id}`}
        tagIds={tagIds}
        projectId={project.id}
        projectUuid={project.uuid}
        refetch={refetch}
        isOpen={upsertProjectTagModal.isOpen}
        handleClose={upsertProjectTagModal.handleClose}
      />
    </React.Fragment>
  );
};

const SalesNotesButton = ({project}: any) => {
  const salesNotesPopover = usePopover();
  return (
    <React.Fragment>
      <Popover.Content innerRef={salesNotesPopover.ref}>
        <Button onPress={salesNotesPopover.handleToggle}>
          <Icon
            source={Icon.StickyNote}
            color={salesNotesPopover.isOpen ? colors.blue.interactive : colors.gray.secondary}
            size={16}
          />
        </Button>
      </Popover.Content>
      <ProjectSalesNotesPopover
        project={project}
        popover={salesNotesPopover}
        placement={Popover.Positions.Left}
      />
    </React.Fragment>
  );
};

const ProjectsListCommentsButton = ({project}: any) => {
  const projectCommentsSidebar = useSidebar({
    name: 'ProjectCommentsSidebar',
    enableTracking: true,
  });
  return (
    <Table.PreventPropagationContainer>
      <ProjectCommentsButton
        project={project}
        style={{paddingVertical: 8}}
        sidebar={projectCommentsSidebar}
      />
    </Table.PreventPropagationContainer>
  );
};

const FollowUpDatePicker = ({project, refetch, isFilteredByFollowUpDate}: any) => {
  const projectFollowUpDateForm = ProjectFollowUpDateForm.edit(project);
  const {form, handleSubmit} = useUpdateProjectFollowUpDateMutation({
    projectFollowUpDateForm,
    onSuccess: () => {
      if (isFilteredByFollowUpDate) {
        refetch();
      }
    },
    onError: () => {},
  });

  return (
    <ButtonContainer>
      <DateFieldComponent.Small
        form={form}
        field={`projectFollowUpDateForm.followUpDate`}
        onChangeDate={(name: any, followUpDate: any) => {
          form.setFieldValue(name, followUpDate);
          setTimeout(() => handleSubmit(), 0);
        }}
        isPortaled
      />
    </ButtonContainer>
  );
};

type OwnProjectsListProps = {
  loading?: boolean;
  projects: any[];
};

// @ts-expect-error TS(2456): Type alias 'ProjectsListProps' circularly referenc... Remove this comment to see the full error message
type ProjectsListProps = OwnProjectsListProps & typeof ProjectsList.defaultProps;

// @ts-expect-error TS(7022): 'ProjectsList' implicitly has type 'any' because i... Remove this comment to see the full error message
const ProjectsList = ({
  status,
  loading,
  refetch,
  projects,
  hasError,
  isEnabledProjectDetailsFollowUp,
  isFilteredByFollowUpDate,
  isEnabledMovesListMultiBranchSupport,
  isPrimaryOrganization,
  isFilteringCancelledProjects,
  isEnabledProjectTag,
}: ProjectsListProps) => {
  const {navigator} = useNavigationDOM();

  return (
    <Container data-test-id='projects-list'>
      <ScrollView
        horizontal
        contentContainerStyle={{
          width: '100%',
        }}
      >
        <React.Fragment>
          <Table.FixedHeaderScroll
            columnDefinitions={getProjectsListColumns({
              status,
              refetch,
              isEnabledProjectDetailsFollowUp,
              isFilteredByFollowUpDate,
              isEnabledMovesListMultiBranchSupport,
              isPrimaryOrganization,
              isFilteringCancelledProjects,
              isEnabledProjectTag,
            })}
            items={projects}
            emptyStateText={'There are no projects that match these filters'}
            loading={loading}
            isClickable
            onRowPress={(project: any) => navigator.push(`/projects/${project.uuid}`)}
            hasError={hasError}
            rowStyle={{overflowY: 'visible', overflowX: 'visible'}}
            style={{width: '100%', minWidth: 1300}}
          />
        </React.Fragment>
      </ScrollView>
    </Container>
  );
};

ProjectsList.defaultProps = {
  loading: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectsList.fragment = gql`
  ${Inventory.getDisplayKind.fragment}
  ${ProjectSalesNotesPopover.fragment}
  ${ProjectCommentsButton.fragment}
  ${Tag.getProjectTagAndTagAssignment.fragment}

  fragment ProjectsList on Project {
    id
    uuid
    createdAt
    followUpDate
    status
    salesStatus
    startDate
    endDate
    weight
    totalRevenue
    referralSource
    referralDetails
    client {
      id
      uuid
      name
      primaryContact {
        id
        fullName
        phoneNumber
        email
      }
    }
    bookedBy {
      id
      fullName
    }
    coordinatedBy {
      id
      fullName
    }
    projectType {
      id
      name
      color
    }
    inventory {
      id
      ...Inventory_getDisplayKind
    }
    activeJobs {
      id
      crews {
        id
        organization {
          id
          name
        }
      }
    }
    jobs {
      id
      crews {
        id
        organization {
          id
          name
        }
      }
    }
    ...Tag_getProjectTagAndTagAssignment
    ...ProjectSalesNotesPopover
    ...ProjectCommentsButton
  }
`;

export default ProjectsList;
