// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import JobDateForm from '@shared/modules/Job/forms/JobDateForm';
import useAddJobDateMutation from '@shared/modules/Job/hooks/useAddJobDateMutation';
import JobDateFields from 'modules/Job/components/JobDateFields';

const DateFieldContainer = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const JobMissingDateModal = ({
  job,
  projectTypeId,
  isOpen,
  handleClose,
  onSuccess,
  messageExtension = '',
}: any) => {
  const {form, submitting, handleSubmit} = useAddJobDateMutation({
    jobDateForm: JobDateForm.edit(job),
    onSuccess: () => {
      handleClose();
      onSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <ActionModal
      icon={Icon.ExclamationTriangle}
      color={colors.orange.status}
      title={'Missing date.'}
      message={`Please enter a date for ${Job.getFullName(job)}${messageExtension}.`}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      primaryActionText={'Save'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      isSubmitting={submitting}
    >
      <Space height={12} />
      <DateFieldContainer>
        <JobDateFields
          form={form}
          field={'jobDateForm'}
          label={'Date'}
          organization={job.organization}
          projectTypeId={projectTypeId}
          isResponsive
        />
      </DateFieldContainer>
      <Space height={8} />
    </ActionModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobMissingDateModal.fragment = gql`
  ${JobDateFields.fragment}
  ${Job.getFullName.fragment}
  ${JobDateForm.edit.fragment}

  fragment JobMissingDateModal on Job {
    id
    organization {
      id
      ...JobDateFields
    }
    ...Job_getFullName
    ...JobDateForm_edit
  }
`;

export default JobMissingDateModal;
