// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

// Components

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: ${colors.blue.accent};
`;

const Panel = ({children}: any) => <Container>{children}</Container>;

export default Panel;
