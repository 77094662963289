// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, FileDragInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState, useQuery, useModal, usePagination, useForm} from '@supermove/hooks';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import AttachmentForm from '@shared/modules/File/forms/AttachmentForm';
import BulkAttachmentsForm from '@shared/modules/File/forms/BulkAttachmentsForm';
import AttachmentViewModes from '@shared/modules/Project/enums/AttachmentViewModes';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import AttachmentsFilters from 'modules/Project/Attachments/components/AttachmentsFilters';
import AttachmentsGridView from 'modules/Project/Attachments/components/AttachmentsGridView';
import AttachmentsListView from 'modules/Project/Attachments/components/AttachmentsListView';
import UploadAttachmentsModalV2 from 'modules/Storage/components/UploadAttachmentsModalV2';

const ContentContainer = Styled.View`
`;

const TableContainer = Styled.View`
  width: 100%;
  min-height: 50;
  flex: 1;
`;

const AttachmentsPanelContent = ({projectUuid, containerStyle, isWidget}: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedView, setSelectedView] = useState(AttachmentViewModes.GRID);

  const uploadAttachmentsModal = useModal({name: 'Upload Attachments Modal'});
  const bulkAttachmentsForm = useForm({
    initialValues: {
      bulkAttachmentsForm: BulkAttachmentsForm.toForm({attachmentForms: []}),
    },
  });

  const {data, loading, refetch} = useQuery(AttachmentsPanel.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectUuid,
      pagination: {
        page: currentPage,
        resultsPerPage: 20,
      },
      searchQuery,
    },
  });

  const pagination = usePagination({
    currentPage,
    paginationMetadata: _.get(data, 'project.paginatedList.paginationMetadata'),
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });

  // Only show page loading indicator on initial render
  if (loading && !data) {
    return <PageLoadingIndicator />;
  }

  return (
    <ContentContainer style={containerStyle}>
      <AttachmentsFilters
        project={data.project}
        uploadAttachmentsModal={uploadAttachmentsModal}
        bulkAttachmentsForm={bulkAttachmentsForm}
        setSearchQuery={setSearchQuery}
        setSelectedView={setSelectedView}
        selectedView={selectedView}
        refetch={refetch}
        isLoading={loading && searchQuery !== ''}
        isWidget={isWidget}
      />
      <ScrollView contentContainerStyle={{minHeight: '100%'}}>
        <ScrollView horizontal contentContainerStyle={{flex: 1}}>
          <TableContainer>
            <FileDragInput
              style={{flex: 1}}
              disableClick={!_.isEmpty(data.project.paginatedList.attachments)}
              onFilesChange={(files) => {
                // Put each file in an AttachmentForm
                const attachmentForms = files.map((file) => {
                  return AttachmentForm.toForm({
                    organizationId: data.project.organization.id,
                    projectId: data.project.id,
                    mimetype: file.type,
                    filename: file.name,
                    // @ts-expect-error TS(2322): Type '"PROJECT_ATTACHMENT"' is not assignable to t... Remove this comment to see the full error message
                    attachmentCategoryKinds: ['PROJECT_ATTACHMENT'],
                    description: '',
                    identifier: '',
                    file,
                  });
                });
                bulkAttachmentsForm.setFieldValue(
                  `bulkAttachmentsForm.attachmentForms`,
                  attachmentForms,
                );
                uploadAttachmentsModal.handleOpen();
              }}
            >
              {({isDragActive}) => {
                if (selectedView === AttachmentViewModes.GRID) {
                  return (
                    <AttachmentsGridView
                      isDragActive={isDragActive}
                      attachments={data.project.paginatedList.attachments}
                      refetch={refetch}
                      isWidget={isWidget}
                    />
                  );
                } else {
                  return (
                    <AttachmentsListView
                      isDragActive={isDragActive}
                      attachments={data.project.paginatedList.attachments}
                      refetch={refetch}
                      isWidget={isWidget}
                    />
                  );
                }
              }}
            </FileDragInput>
          </TableContainer>
        </ScrollView>
      </ScrollView>
      <Space height={16} />
      <PaginationBar pagination={pagination} />
      <Space height={16} />
      <UploadAttachmentsModalV2
        key={uploadAttachmentsModal.key} // Ensures values are empty when modal is opened
        isOpen={uploadAttachmentsModal.isOpen}
        handleClose={uploadAttachmentsModal.handleClose}
        bulkAttachmentsForm={bulkAttachmentsForm}
        refetch={refetch}
        projectUuid={projectUuid}
      />
    </ContentContainer>
  );
};

type OwnAttachmentsPanelProps = {
  project: any;
  containerStyle?: any;
  isWidget?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'AttachmentsPanelProps' circularly refe... Remove this comment to see the full error message
type AttachmentsPanelProps = OwnAttachmentsPanelProps & typeof AttachmentsPanel.defaultProps;

// @ts-expect-error TS(7022): 'AttachmentsPanel' implicitly has type 'any' becau... Remove this comment to see the full error message
const AttachmentsPanel = ({project, containerStyle, isWidget}: AttachmentsPanelProps) => {
  // Since the claims block can also add and remove attachments, we listen for
  // the number of attachments for claims and refresh this content when it changes.
  return (
    <AttachmentsPanelContent
      key={project.claimAttachmentsHash}
      projectUuid={project.uuid}
      containerStyle={containerStyle}
      isWidget={isWidget}
    />
  );
};

AttachmentsPanel.defaultProps = {
  containerStyle: {},
  isWidget: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AttachmentsPanel.listener = gql`
  fragment AttachmentsPanel_listener on Project {
    id
    claimAttachmentsHash
  }
`;

AttachmentsPanel.fragment = gql`
  ${AttachmentsPanel.listener}
  fragment AttachmentsPanel on Project {
    id
    ...AttachmentsPanel_listener
  }
`;

AttachmentsPanel.query = gql`
  ${AttachmentsPanel.listener}
  ${AttachmentsFilters.fragment}
  ${usePagination.fragment}
  ${AttachmentsListView.fragment}
  ${AttachmentsGridView.fragment}

  query AttachmentsPanel(
    $projectUuid: String!
    $searchQuery: String
    $pagination: PaginationInput!
  ) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      organization {
        id
      }
      paginatedList: filteredAttachmentsPaginatedList(
        searchQuery: $searchQuery
        pagination: $pagination
      ) {
        attachments: results {
          id
          ...AttachmentsListView
          ...AttachmentsGridView
        }
        paginationMetadata {
          ...usePagination
        }
      }
      ...AttachmentsPanel_listener
      ...AttachmentsFilters
    }
  }
`;

export default AttachmentsPanel;
