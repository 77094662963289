// Libraries
import React from 'react';

// Supermove
import {Emoji, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {FinalLock, ReportMoveNotification} from 'modules/ReportMove/Show/components';
import {
  FinalizeReportMoveMutation,
  ReportMovePage,
  ReportMoveJobInfo,
  UnfinalizeReportMoveModal,
} from 'modules/ReportMove/components';
import ReportMoveInfoV1 from 'modules/ReportMove/components/ReportMoveInfoV1';

const Wrapper = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  align-items: center;
  width: 100%;
  padding-horizontal: 20px;
`;

const Content = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '800px')};
  padding-top: 60px;
  padding-bottom: 180px;
`;

const MiniRow = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Left = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
`;

const Subtitle = Styled.H6`
  margin-top: 10px;
  line-height: 24px;
`;

const Row = Styled.View`
  margin-top: 40px;
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const RowHeader = Styled.View`
  margin-bottom: 20px;
`;

const Line = Styled.View`
  margin-top: 40px;
  height: 10px;
  background-color: ${colors.gray.border};
`;

const Footer = Styled.View`
  flex-direction: row;
  align-self: center;
  width: ${(props) => ((props as any).mobile ? '100%' : '800px')};
  padding-vertical: ${(props) => ((props as any).mobile ? 10 : 15)};
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  background-color: ${colors.white};
`;

const Button = Styled.LoadingButton`
  flex: 1;
  height: 40px;
`;

const Text = Styled.H7`
  margin-right: 5px;
  ${fontWeight(700)}
  color: ${colors.white};
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const ShowReportMovePageContent = ({uuid, reportMove, navigator, responsive, refetch}: any) => {
  return (
    <Wrapper>
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Container>
          <Content {...responsive}>
            <ReportMoveNotification reportMove={reportMove} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Row index={0}>
              <RowHeader>
                <MiniRow>
                  <Left>
                    <Emoji component={EmojiText} name={'book'} />
                    <Title>View move report</Title>
                  </Left>
                  {reportMove.isFinal ? (
                    <UnfinalizeReportMoveModal
                      reportMove={reportMove}
                      onSuccess={() => {
                        navigator.push(`/move-reports/${reportMove.uuid}/timesheet/edit`);
                      }}
                      refetch={refetch}
                      trigger={({handleOpen}: any) => <FinalLock isFinal onPress={handleOpen} />}
                    />
                  ) : (
                    <FinalLock isFinal={false} />
                  )}
                </MiniRow>
                <Subtitle>
                  {reportMove.isFinal
                    ? 'This report is final and is locked to prevent further changes.'
                    : 'This report is not final and requires your attention. You may ' +
                      'make changes by pressing the "Edit" button at the bottom of the screen.'}
                </Subtitle>
              </RowHeader>
              <ReportMoveJobInfo job={reportMove.job} />
            </Row>
            <Line />
            <Space height={40} />
            <ReportMoveInfoV1 reportMove={reportMove} />
          </Content>
        </Container>
      </ScrollView>
      {!reportMove.isFinal && (
        <Footer {...responsive}>
          <FinalizeReportMoveMutation reportMoveId={reportMove.id}>
            {({loading, handleSubmit}: any) => (
              <Button
                disabled={!reportMove.isFinalizable}
                loading={loading}
                color={colors.green.status}
                onPress={() => {
                  handleSubmit();
                }}
              >
                <Text>Finalize</Text>
                <Icon color={colors.white} size={Icon.Sizes.Medium} source={Icon.Check} />
              </Button>
            )}
          </FinalizeReportMoveMutation>
          <ButtonSpace />
          <Button onPress={() => navigator.push(`/move-reports/${uuid}/timesheet/edit`)}>
            <Text>Edit</Text>
            <Icon color={colors.white} size={Icon.Sizes.Medium} source={Icon.Pen} />
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
};

const ShowReportMovePage = ({
  match: {
    params: {uuid},
  },
}: any) => (
  <ReportMovePage
    variables={{uuid}}
    query={ShowReportMovePage.query}
    title={() => 'View Move Report'}
    subtitle={() => ''}
    onClose={({navigator}: any) => navigator.goBack()}
  >
    {({responsive, navigator, data, refetch}: any) => (
      <ShowReportMovePageContent
        uuid={uuid}
        reportMove={data.reportMove}
        responsive={responsive}
        navigator={navigator}
        refetch={refetch}
      />
    )}
  </ReportMovePage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowReportMovePage.query = gql`
  ${ReportMoveInfoV1.fragment}
  ${ReportMoveJobInfo.fragment}
  ${ReportMoveNotification.fragment}
  ${ReportMovePage.fragment}
  ${UnfinalizeReportMoveModal.fragment}

  query ShowReportMovePage($uuid: String!) {
    ${gql.query}
    reportMove(uuid: $uuid) {
      id
      uuid
      isFinal
      isFinalizable
      job {
        id
        uuid
        project {
          id
        }
        ...ReportMoveJobInfo
      }
      ...ReportMoveInfoV1
      ...ReportMoveNotification
      ...ReportMovePage
      ...UnfinalizeReportMoveModal
    }
  }
`;

export default ShowReportMovePage;
