// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import SalesUpdatePayableUserDayModal from './SalesUpdatePayableUserDayModal';

const Container = Styled.View`
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Row = Styled.View`
  flex-direction: row;
  padding-vertical: 20px;
  padding-horizontal: 20px;
`;

const Touchable = Styled.Touchable`
  flex: 1;
`;

const Link = Styled.H6`
  width: 150px;
  color: ${colors.blue.interactive};
  text-decoration-line: underline;
`;

const Text = Styled.H6`
  width: 150px;
  color: ${colors.gray.primary};
  text-align: right;
`;

const SalesPayableUserDayItem = ({isFirst, isLast, index, payableUserDay, refetch}: any) => {
  const {isOpen, handleOpen, handleClose} = useModal({onClose: refetch});

  return (
    <React.Fragment>
      <Container>
        <Line />
        <Row>
          <Touchable onPress={handleOpen}>
            <Link>{Datetime.convertToDisplayDate(payableUserDay.day.value, 'dddd (M/D)')}</Link>
          </Touchable>
          <Text>{payableUserDay.jobsCount}</Text>
          <Text>{Currency.display(payableUserDay.amount)}</Text>
          <Text>{Currency.display(payableUserDay.total)}</Text>
          <Text>{Currency.display(payableUserDay.additionalTotal)}</Text>
        </Row>
        {isLast && <Line />}
      </Container>
      <SalesUpdatePayableUserDayModal
        isOpen={isOpen}
        payableUserDay={payableUserDay}
        onClose={handleClose}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableUserDayItem.fragment = gql`
  ${SalesUpdatePayableUserDayModal.fragment}

  fragment SalesPayableUserDayItem on PayableUserDay {
    jobsCount
    amount
    total
    additionalTotal
    day {
      id
      value
    }
    ...SalesUpdatePayableUserDayModal
  }
`;

export default SalesPayableUserDayItem;
