const PERCENT = 'PERCENT' as const;
const RATE = 'RATE' as const;
const NO_FEE = 'NO_FEE' as const;

export type PaymentFeeKindType = typeof PERCENT | typeof RATE | typeof NO_FEE;

const PaymentFeeKind = {
  PERCENT,
  RATE,
  NO_FEE,
};

export default PaymentFeeKind;
