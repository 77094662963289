const selfClosingTags = ['br', 'hr', 'img', 'input', 'meta', 'link'];

export const prettifyHtmlOnce = (html: string) => {
  // When code was previously from a WYSIWYG editor, it will return a single line of HTML.
  // We want to convert that to a prettified form,
  // but once the user has gone to the HTML editor don't want to override changes.
  //   If the user goes back to WYSIWYG editor and changes again, it will be one line of HTML again and re-prettified.
  const lineCount = html.split('\n').length;
  if (lineCount > 1) {
    return html;
  }
  const tab = '\t';
  let result = '';
  let indent = '';

  const getTagName = (token: string): string => {
    const match = token.match(/^<\/?([^\s/>]+)/);
    return match ? match[1].toLowerCase() : '';
  };

  // Split the HTML into tags and text content, ignoring existing whitespace
  const tokens = html.match(/<\/?[^>]+>|[^<>]+/g);

  tokens?.forEach((token) => {
    const isTag = token.startsWith('<');
    const isOpeningTag = /^<[^/!][^>]*>$/.test(token.trim());
    const isClosingTag = /^<\/[^>]+>$/.test(token.trim());
    const tagName = getTagName(token);

    if (isClosingTag) {
      // Decrease indent for closing tags
      indent = indent.substring(tab.length);
    }

    // Normalize text tokens
    if (!isTag) {
      // Replace multiple whitespace characters with a single space
      token = token.replace(/\s+/g, ' ').trim();
      // If the text content is empty after trimming, skip adding it
      if (token === '') {
        return;
      }
    }

    // Add the token with current indentation
    result += `${indent}${token.trim()}\r\n`;

    if (isOpeningTag && !selfClosingTags.includes(tagName)) {
      // Increase indent for non-self-closing opening tags
      indent += tab;
    }
  });

  return result.trim();
};
