/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

const SystemMessageContainer = Styled.View`
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isWarning ? colors.orange.accent : colors.red.accent)};
  border-radius: 4px;
  margin-horizontal: 12px;
  padding-horizontal: 12px;
  padding-vertical: 8px;
`;

const SystemMessageText = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'MaybeRespo... Remove this comment to see the full error message
    vars,
  }) => (vars.isWarning ? colors.orange.status : colors.red.warning)};
`;

type OwnProps = {
  containerStyle?: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SystemMessage.defaultProps;

// @ts-expect-error TS(7022): 'SystemMessage' implicitly has type 'any' because ... Remove this comment to see the full error message
const SystemMessage = ({children, isWarning, containerStyle, isResponsive}: Props) => {
  const responsive = useResponsive();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SystemMessageContainer vars={{isWarning}} style={containerStyle}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <SystemMessageText vars={{isWarning}} responsive={isResponsive ? responsive : null}>
        {children}
      </SystemMessageText>
    </SystemMessageContainer>
  );
};

SystemMessage.defaultProps = {
  containerStyle: {},
};

SystemMessage.Container = SystemMessageContainer;
SystemMessage.Text = SystemMessageText;

export default SystemMessage;
