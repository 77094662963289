// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer} from '@supermove/hooks';
import {Variable} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import UpdateVariableDrawer from 'modules/Organization/Settings/Billing/Variables/components/UpdateVariableDrawer';

const Container = Styled.View`
  width: 1300px;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading6}
`;

const CellText = Styled.Text`
  ${Typography.Body3}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
`;

const IconButton = Styled.ButtonV2`
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getVariableTableColumnDefinitions = ({refetch, userId, isCustomVariable}: any) => [
  {
    flex: 4,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return <CellText>{variable.name}</CellText>;
    },
  },
  {
    flex: 4,
    headerContent: () => {
      return <TableHeaderText>Description</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return <CellText>{variable.description}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Category</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return <CellText>{`${Variable.getDisplayKind(variable.kind)} Type`}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Format</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return <CellText>{Variable.getDisplayFormat(variable.format)}</CellText>;
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Last Updated</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return (
        <Container>
          <CellText>{Variable.getDisplayUpdatedAt(variable)}</CellText>
          {!!variable.updatedBy && (
            <React.Fragment>
              <Space height={4} />
              <DescriptionText>{variable.updatedBy.fullName}</DescriptionText>
            </React.Fragment>
          )}
        </Container>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => <TableHeaderText>Actions</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'variable' implicitly has an 'any'... Remove this comment to see the full error message
    cellContent: ({item: variable}) => {
      return <Actions variable={variable} refetch={refetch} userId={userId} />;
    },
  },
];

const Actions = ({variable, refetch, userId}: any) => {
  const updateVariableDrawer = useDrawer({name: 'Update Variable Drawer'});
  const {isSystemVariable} = variable;
  return isSystemVariable ? (
    <Row>
      <TextTooltip
        isEnabledMobileToast={false}
        text={'This is a system variable and cannot be edited'}
      >
        <IconButton disabled>
          <Icon source={Icon.Pen} color={colors.gray.disabled} size={16} />
        </IconButton>
      </TextTooltip>
    </Row>
  ) : (
    <Row>
      <IconButton onPress={updateVariableDrawer.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <UpdateVariableDrawer
        key={updateVariableDrawer.key}
        variable={variable}
        refetch={refetch}
        isOpen={updateVariableDrawer.isOpen}
        handleClose={updateVariableDrawer.handleClose}
        userId={userId}
      />
    </Row>
  );
};

const BillingVariablesTable = ({title, variables, refetch, userId}: any) => {
  const sortedVariables = _.orderBy(
    variables,
    [(variable) => variable.name.toLowerCase()],
    ['asc'],
  );
  return (
    <ScrollView horizontal>
      <Container>
        <HeaderText>{title}</HeaderText>
        <Space height={16} />
        <Table
          columnDefinitions={getVariableTableColumnDefinitions({refetch, userId})}
          items={sortedVariables}
          emptyStateText={'There are no variables'}
          isDense
          // @ts-expect-error TS(2322): Type '{ columnDefinitions: { flex: number; headerC... Remove this comment to see the full error message
          refetch={refetch}
          rowStyle={{display: 'flex'}}
        />
      </Container>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingVariablesTable.fragment = gql`
  ${UpdateVariableDrawer.fragment}
  fragment BillingVariablesTable on Variable {
    id
    name
    description
    kind
    format
    updatedAt
    createdById
    updatedBy {
      id
      fullName
    }
    isDerived
    ...UpdateVariableDrawer
  }
`;

export default BillingVariablesTable;
