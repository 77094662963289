import SupplyForm from './SupplyForm';

const SuppliesForm = {
  toForm: ({supplyForms}: any) => ({
    supplyForms: supplyForms.map((supply: any) => SupplyForm.toForm(supply)),
  }),

  toMutation: ({supplyForms}: any) => ({
    supplyForms: supplyForms.map((supply: any) => SupplyForm.toMutation(supply)),
  }),
};

export default SuppliesForm;
