// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useUpdateProjectTypeMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeMutation';
import ProjectTypeFields from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeFields';

const EditProjectTypeDrawerContent = ({
  isOpen,
  projectType,
  isStorageSettingsPage,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  projectType: ProjectTypeModel;
  isStorageSettingsPage: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const createProjectTypeSuccessToast = useToast({
    ToastComponent: SuccessToast,
  });
  const projectTypeForm = ProjectTypeForm.edit(projectType);
  const {form, submitting, handleSubmit} = useUpdateProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
      createProjectTypeSuccessToast.handleToast({
        message: `Project type updated!`,
      });
    },
    onError: (errors: unknown) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      shouldCloseOnClickOutside={false}
      headerText={'Edit Project Type'}
      primaryActionText={'Save'}
      handleSubmit={handleSubmit}
      isSubmitting={submitting}
    >
      <ProjectTypeFields
        form={form}
        organization={projectType.organization}
        isStorageSettingsPage={isStorageSettingsPage}
      />
    </DrawerWithAction>
  );
};

const EditProjectTypeDrawer = ({
  isOpen,
  projectTypeId,
  isStorageSettingsPage,
  handleClose,
  refetch,
}: {
  isOpen: boolean;
  projectTypeId: string;
  isStorageSettingsPage: boolean;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const {loading, data} = useQuery<{projectType: ProjectTypeModel}>(EditProjectTypeDrawerQuery, {
    variables: {projectTypeId: _.toNumber(projectTypeId)},
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });

  if (!data) return null;

  return (
    <EditProjectTypeDrawerContent
      isOpen={isOpen}
      projectType={data.projectType}
      isStorageSettingsPage={isStorageSettingsPage}
      handleClose={handleClose}
      refetch={refetch}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const EditProjectTypeDrawerQuery = gql`
  ${ProjectTypeFields.fragment}
  ${ProjectTypeForm.edit.fragment}
  query EditProjectTypeDrawer($projectTypeId: Int!) {
    ${gql.query}
    projectType(projectTypeId: $projectTypeId) {
      id
      organization {
        id
        ...ProjectTypeFields
      }
      ...ProjectTypeForm_edit
    }
  }
`;

export default EditProjectTypeDrawer;
