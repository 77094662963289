// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMemo, useState, useToast} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TextMessageSystemKind from '@shared/modules/Sms/enums/TextMessageSystemKind';
import SendTextMessageToTeamForm from '@shared/modules/Sms/forms/SendTextMessageToTeamForm';
import useSendTextMessageToTeamMutation from '@shared/modules/Sms/hooks/useSendTextMessageToTeamMutation';

const Container = Styled.View`
  padding: 24px;
`;

const ModalBodyContainer = Styled.View`
  max-height: 300px;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${colors.gray.border};
  padding-horizontal: 16px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Body = Styled.Text`
  ${Typography.Body}
`;

const Heading = Styled.Text`
  ${Typography.Heading2}
`;

const TextTemplateDropdownContainer = Styled.View`
  height: 52px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-top: 16px;
`;

const IconCircleButton = Styled.ButtonV2`
  height: 28px;
  width: 28px;
  border-radius: 14px;
  align-items: center;
  background-color: ${colors.gray.border};
  justify-content: center;
`;

const IconButton = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
`;

const TeamNameContainer = Styled.View`
  background-color: ${colors.gray.border};
  border-radius: 16px;
  padding-vertical: 4px;
  padding-horizontal: 8px;
`;

const SendTeamSMSModalBodyTextTemplateDropdown = ({form, team, setIsUsingTemplate}: any) => {
  const textMessageTemplateMap = Object.fromEntries(
    team.organization.textMessageTemplatesByCategory.map((textMessageTemplate: any) => [
      textMessageTemplate.id,
      textMessageTemplate.body,
    ]),
  );

  const sortedCustomTemplates = useMemo(() => {
    return team.organization.textMessageTemplatesByCategory
      .filter((textMessageTemplate: any) => {
        return textMessageTemplate.systemTemplateKind === TextMessageSystemKind.CUSTOM;
      })
      .map((textMessageTemplate: any) => {
        return {
          label: textMessageTemplate.name,
          value: _.toNumber(textMessageTemplate.id),
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }, [team.organization.textMessageTemplatesByCategory]);

  return (
    <TextTemplateDropdownContainer>
      <Row>
        <FieldInput
          {...form}
          index={0}
          name={'sendTextMessageToTeamForm.textMessageTemplateId'}
          component={DropdownInput}
          input={{
            isPortaled: true,
            options: sortedCustomTemplates,
            placeholder: 'Select an SMS template',
            setFieldValue: form.setFieldValue,
            onChangeValue: (value: any) => {
              // When value is selected, we need to look in to the text message
              // template id -> body object to find the string
              const headerBody = _.get(form.values, 'sendTextMessageToTeamForm.headerBody');
              form.setFieldValue(
                'sendTextMessageToTeamForm.body',
                headerBody + textMessageTemplateMap[value],
              );
            },
            style: {width: '420px'},
          }}
        />
        <Space style={{flex: 1}} />
        <IconCircleButton
          onPress={() => {
            form.setFieldValue('sendTextMessageToTeamForm.textMessageTemplateId', null);
            setIsUsingTemplate(false);
          }}
        >
          <Icon source={Icon.Times} size={14} color={colors.gray.tertiary} />
        </IconCircleButton>
      </Row>
    </TextTemplateDropdownContainer>
  );
};

const SendTeamSMSModalBody = ({
  form,
  handleSubmit,
  team,
  setIsUsingTemplate,
  isUsingTemplate,
}: any) => {
  const field = 'sendTextMessageToTeamForm.body';

  return (
    <ModalBodyContainer>
      {isUsingTemplate && (
        <React.Fragment>
          <Space height={16} />
          <SendTeamSMSModalBodyTextTemplateDropdown
            form={form}
            team={team}
            handleSubmit={handleSubmit}
            organization={team.organization}
            setIsUsingTemplate={setIsUsingTemplate}
          />
        </React.Fragment>
      )}
      <FieldInput.Memoized
        {...form}
        index={1}
        name={field}
        input={{
          placeholder: 'Send a Message...',
          multiline: true,
          style: {
            fontWeight: '500',
            fontSize: '14px',
            color: colors.gray.primary,
            backgroundColor: colors.white,
            paddingLeft: 0,
            paddingRight: 0,
            borderWidth: 0,
            borderRadius: 0,
            paddingVertical: 18,
            height: 180,
          },
        }}
      />
    </ModalBodyContainer>
  );
};

const SendTeamSMSModal = ({sendTeamSMSModal, team, viewer}: any) => {
  const [isUsingTemplate, setIsUsingTemplate] = useState(false);

  const sendTextMessageToTeamForm = SendTextMessageToTeamForm.new({
    team,
    sender: viewer,
  });

  const sendTeamMessageToast = useToast({
    ToastComponent: SuccessToast,
    message: `Message sent to ${team.name}.`,
  });

  const {form, handleSubmit, submitting} = useSendTextMessageToTeamMutation({
    sendTextMessageToTeamForm,
    onSuccess: () => {
      sendTeamSMSModal.handleClose();
      sendTeamMessageToast.handleToast();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <Modal isOpen={sendTeamSMSModal.isOpen} width={Modal.WIDTH.MEDIUM} style={{maxHeight: '500px'}}>
      <Container>
        <Row>
          <Heading>Team Message</Heading>
          <Space style={{flex: 1}} />
          <IconButton onPress={sendTeamSMSModal.handleClose} backgroundColor={colors.gray.border}>
            <Icon source={Icon.Times} size={24} color={colors.gray.tertiary} />
          </IconButton>
        </Row>
        <Space height={20} />
        <Row>
          <Body>To: </Body>
          <TeamNameContainer>
            <Body>{team.name}</Body>
          </TeamNameContainer>
        </Row>
        <Space height={16} />
        <SendTeamSMSModalBody
          form={form}
          handleSubmit={handleSubmit}
          team={team}
          sendTeamSMSModal={sendTeamSMSModal}
          viewer={viewer}
          setIsUsingTemplate={setIsUsingTemplate}
          isUsingTemplate={isUsingTemplate}
        />
        <Space height={16} />
        <FooterContainer>
          <TertiaryButton
            text={'Use Template'}
            onPress={() => {
              setIsUsingTemplate(!isUsingTemplate);
            }}
          />
          <Space width={16} />
          <Modal.Button
            isSubmitting={submitting}
            onPress={handleSubmit}
            color={colors.blue.interactive}
          >
            Send
          </Modal.Button>
        </FooterContainer>
      </Container>
    </Modal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendTeamSMSModal.fragment = gql`
  ${SendTextMessageToTeamForm.new.fragment}

  fragment SendTeamSMSModal on Team {
    id
    name
    organization {
      id
      textMessageTemplatesByCategory(categories: ["MOVER", "OFFICE", "OTHER"]) {
        id
        name
        body
        systemTemplateKind
      }
    }
    ...SendTextMessageToTeamForm_new
  }

  fragment SendTeamSMSModal_Viewer on User {
    id
    fullName
    ...SendTextMessageToTeamForm_Viewer
  }
`;

export default SendTeamSMSModal;
