// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {Location} from '@supermove/utils';

// Components
import {MapMarker} from 'modules/App/Map/components';
import MapView from 'modules/App/components/MapView';

const getTruckLocations = ({jobs}: any) => {
  // Remove completed jobs.
  const filteredJobs = _.filter(jobs, (job) => job.primaryStatus !== 'COMPLETE');
  const positions = _.flatten(
    _.map(filteredJobs, (job) => _.get(job, 'jobTrucks.0.latestPosition')),
  );
  const validPositions = _.filter(positions, Boolean);
  return validPositions;
};

const getLocations = ({jobs, organization}: any) => {
  if (organization.warehouseLocation) {
    return [organization.warehouseLocation, ...getTruckLocations({jobs})];
  } else {
    return getTruckLocations({jobs});
  }
};

type OwnDispatchJobsMapProps = {
  jobs: any[];
};

// @ts-expect-error TS(2456): Type alias 'DispatchJobsMapProps' circularly refer... Remove this comment to see the full error message
type DispatchJobsMapProps = OwnDispatchJobsMapProps & typeof DispatchJobsMap.defaultProps;

// @ts-expect-error TS(7022): 'DispatchJobsMap' implicitly has type 'any' becaus... Remove this comment to see the full error message
const DispatchJobsMap = ({jobs, organization}: DispatchJobsMapProps) => (
  <MapView
    mapTypeControl={false}
    locations={getLocations({jobs, organization})}
    shouldResetBounds={false}
    renderMapAnnotations={(props: any) => (
      <React.Fragment>
        {!!organization.warehouseLocation && (
          <MapMarker
            key={0}
            kind={MapMarker.WAREHOUSE}
            name={'Warehouse'}
            position={Location.create(organization.warehouseLocation)}
            {...props}
          />
        )}
        {getTruckLocations({jobs}).map((point, index) => (
          <MapMarker
            key={index + 1}
            kind={MapMarker.TRUCK}
            name={'Truck'}
            position={Location.create(point)}
            {...props}
          />
        ))}
      </React.Fragment>
    )}
  />
);

DispatchJobsMap.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchJobsMap.fragment = gql`
  fragment DispatchJobsMap_Job on Job {
    id
    primaryStatus: calendarPrimaryStatus
    jobTrucks {
      id
      latestPosition {
        id
        latitude
        longitude
      }
    }
  }
  fragment DispatchJobsMap_Organization on Organization {
    id
    warehouseLocation {
      latitude
      longitude
    }
  }
`;

export default DispatchJobsMap;
