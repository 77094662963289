// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';
import useUpdateCommercialMaterialMutation from '@shared/modules/CommercialCatalog/hooks/useUpdateCommercialMaterialMutation';

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const UpdateCommercialMaterialModal = ({isOpen, commercialMaterial, handleClose, refetch}: any) => {
  const commercialMaterialForm = CommercialMaterialForm.edit(commercialMaterial);
  const {form, submitting, handleSubmit} = useUpdateCommercialMaterialMutation({
    commercialMaterialForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>Edit Material</SmallModal.HeaderText>
      <Space height={12} />
      <SmallModal.Text>Update the material name to add and press submit.</SmallModal.Text>
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.name'}
        label={'Material Name'}
        input={{
          required: !form.values.commercialMaterialForm.name,
          autoFocus: true,
          placeholder: 'Enter a material name',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={20} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.quantityRequested'}
        label={'Quantity Requested'}
        input={{
          placeholder: 'Enter quantity requested',
          keyboardType: 'phone-pad',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={20} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.quantityDelivered'}
        label={'Left on Site'}
        input={{
          placeholder: 'Enter quantity left on site',
          keyboardType: 'phone-pad',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={20} />
      <FieldInput
        {...form}
        name={'commercialMaterialForm.price'}
        label={'Price'}
        component={CurrencyInput}
        input={{
          component: TextInput,
          placeholder: 'Enter the price',
          keyboardType: 'phone-pad',
          setFieldTouched: form.setFieldTouched,
          setFieldValue: form.setFieldValue,
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          onPress={handleSubmit}
          color={colors.blue.interactive}
          isSubmitting={submitting}
        >
          Submit
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateCommercialMaterialModal.fragment = gql`
  ${CommercialMaterialForm.edit.fragment}

  fragment UpdateCommercialMaterialModal on CommercialMaterial {
    id
    ...CommercialMaterialForm_edit
  }
`;

export default UpdateCommercialMaterialModal;
