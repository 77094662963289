// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

const Container = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Item = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const Text = Styled.H7`
`;

const formatTimeRanges = ({timeRanges}: any) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getColumnNames = ({reportMove}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(reportMove, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).endName : (timeRange as any).startName;
  });
};

const TableHeader = ({names, job, organization}: any) => (
  <Header>
    {organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 80}}>
        <Text>Branch / IC Code</Text>
      </Cell>
    )}
    <Cell style={{width: 120}}>
      <Text>Name</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 120}}>
        <Text>Position</Text>
        {organization.features.isEnabledJobUserShowOrganizationName && <Text>Labor Source</Text>}
      </Cell>
    )}
    {names.map((name: any, index: any) => (
      <Cell key={index} style={{width: 80}}>
        <Text>{name}</Text>
      </Cell>
    ))}
    <Cell style={{width: 80}}>
      <Text>Travel Hours</Text>
    </Cell>
    <Cell style={{width: 80}}>
      <Text>Total Hours</Text>
    </Cell>
  </Header>
);

const ReportMoveUserItem = ({reportMoveUser, job, organization}: any) => {
  const {timeRanges} = reportMoveUser.timesheet;
  const lastTimeRangeEnd = _.get(timeRanges, `${timeRanges.length - 1}.end`);
  return (
    <Item>
      {organization.features.isEnabledJobUserShowBranchCode && (
        <Cell style={{width: 80}}>
          <Text>{reportMoveUser.branchCode}</Text>
        </Cell>
      )}
      <Cell style={{width: 120}}>
        <Text>{User.getFullName(reportMoveUser.user)}</Text>
      </Cell>
      {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
        <Cell style={{width: 120}}>
          <Text numberOfLines={1}>{reportMoveUser.positions.join(', ')}</Text>
          {organization.features.isEnabledJobUserShowOrganizationName && (
            <Text numberOfLines={1}>{reportMoveUser.user.organization.name}</Text>
          )}
        </Cell>
      )}
      {timeRanges.map((timeRange: any, index: any) => (
        <Cell key={`${reportMoveUser.userId}-${index}`} style={{width: 80}}>
          <Text numberOfLines={1}>
            {timeRange.start ? Datetime.convertToDisplayTime(timeRange.start) : 'N/A'}
          </Text>
        </Cell>
      ))}
      <Cell style={{width: 80}}>
        <Text numberOfLines={1}>
          {lastTimeRangeEnd ? Datetime.convertToDisplayTime(lastTimeRangeEnd) : 'N/A'}
        </Text>
      </Cell>
      <Cell style={{width: 80}}>
        <Text numberOfLines={1}>{Duration.toHours(reportMoveUser.timesheet.totalTimeDrive)}</Text>
      </Cell>
      <Cell style={{width: 80}}>
        <Text numberOfLines={1}>
          {Duration.toHours(reportMoveUser.timesheet.totalTimeWithoutBreak)}
        </Text>
      </Cell>
    </Item>
  );
};

type OwnCommercialReportMoveTimesheetTableProps = {
  reportMove: any;
};

// @ts-expect-error TS(2456): Type alias 'CommercialReportMoveTimesheetTableProp... Remove this comment to see the full error message
type CommercialReportMoveTimesheetTableProps = OwnCommercialReportMoveTimesheetTableProps &
  typeof CommercialReportMoveTimesheetTable.defaultProps;

// @ts-expect-error TS(7022): 'CommercialReportMoveTimesheetTable' implicitly ha... Remove this comment to see the full error message
const CommercialReportMoveTimesheetTable = ({
  reportMove,
}: CommercialReportMoveTimesheetTableProps) => (
  <Container>
    <TableHeader
      names={getColumnNames({reportMove})}
      job={reportMove.job}
      organization={reportMove.organization}
    />
    {reportMove.moveUsers.map((moveUser: any, index: any) => (
      <ReportMoveUserItem
        key={index}
        reportMoveUser={moveUser}
        job={reportMove.job}
        organization={reportMove.organization}
      />
    ))}
  </Container>
);

CommercialReportMoveTimesheetTable.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialReportMoveTimesheetTable.fragment = gql`
  fragment CommercialReportMoveTimesheetTable on ReportMove {
    id
    job {
      id
      hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
        kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
      )
    }
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    moveUsers {
      positions
      branchCode
      timesheet {
        timeRanges {
          name
          start
          end
          kind
          startName
          endName
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
`;

export default CommercialReportMoveTimesheetTable;
