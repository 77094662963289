// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Query, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useSearch, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import SupermoveProductKind from '@shared/modules/SupermoveProduct/enums/SupermoveProductKind';

import UpdateSuperAdminOrganizationMutation from './UpdateSuperAdminOrganizationMutation';

const Wrapper = Styled.View`
  ${(props) => ((props as any).mobile ? 'flex: 1' : 'height: 500px')};
`;

const Indicator = Styled.Loading`
  margin-top: 20px;
`;

const Container = Styled.View`
  flex: 1;
`;

const SearchWrapper = Styled.View`
  padding: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.blue.accent};
`;

const SearchInput = Styled.TextInput.H7`
  padding: 10px;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-vertical: 10px;
  padding-horizontal: 10px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.gray.tertiary};
`;

const Left = Styled.View`
  flex: 1;
  justify-content: center;
  height: 100%;
  margin-right: 5px;
`;

const Name = Styled.H7`
  ${fontWeight(500)}
`;

const Slug = Styled.H8`
`;

const Button = Styled.LoadingButton`
  width: 80px;
`;

const ButtonText = Styled.H7`
  color: ${colors.white};
  ${fontWeight(700)}
  text-align: center;
`;

const Option = ({selectedOrganizationId, organization, handlePress}: any) => (
  <Row>
    <Left>
      <Name numberOfLines={1}>{organization.name}</Name>
      <Slug numberOfLines={1}>{organization.slug}</Slug>
    </Left>
    <Button
      disabled={selectedOrganizationId && selectedOrganizationId !== organization.id}
      loading={selectedOrganizationId === organization.id}
      onPress={() => handlePress({organization})}
    >
      {selectedOrganizationId !== organization.id && (
        <ButtonText numberOfLines={1}>Select</ButtonText>
      )}
    </Button>
  </Row>
);

const SearchItems = ({organizations, handleSubmit}: any) => {
  const sortedOrganizations = _.sortBy(organizations, ['slug']);
  const {
    query,
    results: filteredOrganizations,
    setQuery,
  } = useSearch({
    initialQuery: '',
    items: sortedOrganizations,
    options: {keys: ['slug', 'name']},
    limit: 20,
  });
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  return (
    <Container>
      <SearchWrapper>
        <SearchInput
          autoFocus
          placeholder={'Search for an organization by name...'}
          value={query}
          onChangeText={(text: any) => {
            setQuery(text);
          }}
          onSubmitEditing={() => {
            // Handles keyboard enter for first search result
            const selectedOrganization = filteredOrganizations[0];
            setSelectedOrganizationId(selectedOrganization.id);
            handleSubmit({
              variables: {
                organizationId: selectedOrganization.id,
              },
            });
          }}
        />
      </SearchWrapper>
      <FlatList
        data={filteredOrganizations}
        keyExtractor={(organization: any) => organization.id}
        // @ts-expect-error TS(7031): Binding element 'organization' implicitly has an '... Remove this comment to see the full error message
        renderItem={({item: organization}) => (
          <Option
            selectedOrganizationId={selectedOrganizationId}
            organization={organization}
            handlePress={() => {
              setSelectedOrganizationId(organization.id);
              handleSubmit({
                variables: {
                  organizationId: organization.id,
                },
              });
            }}
          />
        )}
      />
    </Container>
  );
};

const SearchForm = ({organizations}: any) => {
  return (
    <UpdateSuperAdminOrganizationMutation
      onSuccess={() => {
        window.location.href = '/';
      }}
    >
      {({handleSubmit}: any) => {
        return <SearchItems organizations={organizations} handleSubmit={handleSubmit} />;
      }}
    </UpdateSuperAdminOrganizationMutation>
  );
};

const UpdateSuperAdminOrganizationForm = () => {
  const responsive = useResponsive();

  return (
    <Query
      fetchPolicy={'cache-and-network'}
      query={UpdateSuperAdminOrganizationForm.query}
      variables={{
        supermoveProductKind: SupermoveProductKind.OFFICE,
      }}
    >
      {({loading, data}: any) => (
        <Wrapper {...responsive}>
          {loading ? (
            <Indicator size={'large'} color={colors.gray.secondary} />
          ) : (
            <SearchForm organizations={data.activeOrganizations} />
          )}
        </Wrapper>
      )}
    </Query>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuperAdminOrganizationForm.query = gql`
  query UpdateSuperAdminOrganizationForm($supermoveProductKind: String!) {
    ${gql.query}
    activeOrganizations: activeOrganizationsForProduct(kind: $supermoveProductKind) {
      id
      name
      slug
    }
  }
`;

export default UpdateSuperAdminOrganizationForm;
