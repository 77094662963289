// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';
import {Json} from '@supermove/utils';

// Components
import JobUserStatusBadge from 'modules/App/Job/components/JobUserStatusBadge';

const Wrapper = Styled.View`
  justify-content: center;
  align-items: center;
  margin-right: ${(props) => ((props as any).isLast ? 0 : 5)}px;
`;

const Touchable = Styled.Touchable`
`;

const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.secondary};
  border-radius: 5px;
  max-width: 120px;
`;

const Section = Styled.View`
  align-items: center;
  width: 100%;
`;

const Name = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  padding-horizontal: 20px;
`;

const Position = Styled.H8`
  color: ${colors.gray.primary};
  padding-horizontal: 12px;
`;

const Badge = Styled.View`
  position: absolute;
  bottom: 0px;
  right: 2px;
`;

const shouldShowBadge = ({jobUser}: any) => {
  const notificationSettings = _.get(jobUser, 'user.organization.settings.notifications', '{}');
  const json = Json.toCamelCaseKeys(Json.parse(notificationSettings));
  return (json as any).allowEmployeeText;
};

const UserAvatar = ({jobUser}: any) => (
  <Container>
    <Section>
      <Name numberOfLines={1}>{jobUser.user.firstName}</Name>
    </Section>
    <Section>
      <Position numberOfLines={1}>{User.getPosition(jobUser.user)}</Position>
      {shouldShowBadge({jobUser}) && (
        <Badge>
          <JobUserStatusBadge jobUser={jobUser} />
        </Badge>
      )}
    </Section>
  </Container>
);

type OwnJobUserAvatarProps = {
  isLast?: boolean;
  jobUser: any;
  onPress?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'JobUserAvatarProps' circularly referen... Remove this comment to see the full error message
type JobUserAvatarProps = OwnJobUserAvatarProps & typeof JobUserAvatar.defaultProps;

// @ts-expect-error TS(7022): 'JobUserAvatar' implicitly has type 'any' because ... Remove this comment to see the full error message
const JobUserAvatar = ({isLast, jobUser, onPress}: JobUserAvatarProps) => {
  return onPress ? (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper isLast={isLast}>
      <Touchable onPress={() => onPress(jobUser)}>
        <UserAvatar jobUser={jobUser} />
      </Touchable>
    </Wrapper>
  ) : (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper isLast={isLast}>
      <UserAvatar jobUser={jobUser} />
    </Wrapper>
  );
};

JobUserAvatar.defaultProps = {
  isLast: false,
  onPress: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserAvatar.fragment = gql`
  ${JobUserStatusBadge.fragment}

  fragment JobUserAvatar on JobUser {
    id
    jobId
    userId
    user {
      id
      firstName
      position
      organization {
        id
        settings {
          id
          notifications
        }
      }
    }
    ...JobUserStatusBadge
  }
`;

export default JobUserAvatar;
