// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App

import SmallModal from '@shared/design/components/Modal/SmallModal';

type OwnProps = {
  confirmText?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MessageModal.defaultProps;

// @ts-expect-error TS(7022): 'MessageModal' implicitly has type 'any' because i... Remove this comment to see the full error message
const MessageModal = ({
  handleConfirm,
  handleClose,
  isOpen,
  submitting,
  message,
  confirmText,
}: Props) => {
  return (
    <SmallModal handlePressOutside={handleClose} isOpen={isOpen}>
      <SmallModal.BoldText>{message}</SmallModal.BoldText>
      <Space height={36} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          color={colors.blue.interactive}
          onPress={handleConfirm}
          isSubmitting={submitting}
        >
          {confirmText}
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

MessageModal.defaultProps = {
  confirmText: 'Confirm',
};

export default MessageModal;
