// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {CommercialCatalogModel} from '@supermove/models';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import SyncAllCommercialCatalogBillsForm from '@shared/modules/CommercialCatalog/forms/SyncAllCommercialCatalogBillsForm';
import useSyncAllCommercialCatalogBillsMutation from '@shared/modules/CommercialCatalog/hooks/useSyncAllCommercialCatalogBillsMutation';

const ConfirmSyncCommercialCatalogBillModal = ({
  isOpen,
  onClose,
  commercialCatalog,
  refetch,
}: {
  isOpen: boolean;
  onClose: () => void;
  commercialCatalog: CommercialCatalogModel;
  refetch: () => void;
}) => {
  const syncAllCommercialCatalogBillsForm = SyncAllCommercialCatalogBillsForm.edit(
    commercialCatalog.project,
  );
  const {submitting, handleSubmit} = useSyncAllCommercialCatalogBillsMutation({
    syncAllCommercialCatalogBillsForm,
    onSuccess: () => {
      onClose();
      refetch();
    },
    onError: () => console.log('syncAllCommercialCatalogBillsMutation error'),
  });

  return (
    <CautionModal
      title={'Regenerate bills?'}
      message={
        `This will regenerate all equipment and material bills ` +
        `and remove any changes that have been made to them. This action ` +
        `cannot be undone.`
      }
      isOpen={isOpen}
      handlePressOutside={onClose}
      handlePrimaryAction={handleSubmit}
      handleSecondaryAction={onClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      isMobileSheet
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmSyncCommercialCatalogBillModal.fragment = gql`
  ${SyncAllCommercialCatalogBillsForm.edit.fragment}

  fragment ConfirmSyncCommercialCatalogBillModal on CommercialCatalog {
    id
    project {
      id
      ...SyncAllCommercialCatalogBillsForm_edit
    }
  }
`;

export default ConfirmSyncCommercialCatalogBillModal;
