// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useQuery, useRef, useState} from '@supermove/hooks';
import {InventoryLibrary} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import CategoryKind from '@shared/modules/Inventory/enums/CategoryKind';
import InventoryLibraryObjectKind from '@shared/modules/Inventory/enums/InventoryLibraryObjectKind';
import CategoriesForm from '@shared/modules/Inventory/forms/CategoriesForm';
import CategoryForm from '@shared/modules/Inventory/forms/CategoryForm';
import useUpsertCategoriesMutation from '@shared/modules/Inventory/hooks/useUpsertCategoriesMutation';
import ImportInventoryLibraryCategoriesModal from 'modules/Organization/Settings/Inventory/components/ImportInventoryLibraryCategoriesModal';
import InventoryLibraryBulkUpdateContents from 'modules/Organization/Settings/Inventory/components/InventoryLibraryBulkUpdateContents';
import InventoryLibrarySettingsPage from 'modules/Organization/Settings/Inventory/components/InventoryLibrarySettingsPage';
import getBulkActionCheckboxColumnDefinition from 'modules/Organization/Settings/Inventory/helpers/getBulkActionCheckboxColumnDefinition';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const IconContainer = Styled.View`
  width: 16px;
`;

const getCsvActions = ({importInventoryLibraryCategoriesModal, inventoryLibrary}: any) => {
  return [
    {
      text: 'Import via CSV',
      onPress: importInventoryLibraryCategoriesModal.handleOpen,
    },
    {
      text: 'Export via CSV',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.CATEGORIES,
        isTemplate: false,
      }),
    },
    {
      text: 'Download CSV template',
      onPress: InventoryLibrary.handleFileDownload({
        inventoryLibrary,
        kind: InventoryLibraryObjectKind.CATEGORIES,
        isTemplate: true,
      }),
    },
  ];
};

const setFormIsDirty = ({form, field, rowIndex, isDirty = true}: any) => {
  form.setFieldValue(`${field}.${rowIndex}.isDirty`, isDirty);
};

const getColumnDefinitions = ({
  isEdit,
  inventoryLibrary,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return [
    getBulkActionCheckboxColumnDefinition({
      isEdit,
      form,
      field,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    {
      flex: 5,
      headerContent: () => {
        return <TableHeaderText>Category Name</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'category' implicitly has an 'any'... Remove this comment to see the full error message
      viewCellContent: ({item: category}) => {
        return <Text>{category.name}</Text>;
      },
      editCellContent: ({rowIndex}: any) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.name`}
            input={{
              autoFocus: rowIndex === _.size(_.get(form.values, field, [])) - 1,
              disabled: _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              placeholder: 'Enter category name',
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
            }}
            style={{flex: 1}}
            handleChange={() => setFormIsDirty({form, field, rowIndex})}
          />
        );
      },
      footerContent: () => {
        return (
          <TertiaryButton
            text={'Add Category'}
            iconLeft={Icon.Plus}
            onPress={() => {
              const existingCategoryForms = form.values.categoriesForm.categoryForms;
              form.setFieldValue('categoriesForm.categoryForms', [
                ...existingCategoryForms,
                CategoryForm.new({
                  organizationId: inventoryLibrary.organizationId,
                  inventoryLibraryId: inventoryLibrary.id,
                }),
              ]);
              setTimeout(() => scrollToBottom(), 0);
            }}
          />
        );
      },
    },
    {
      flex: 4,
      headerContent: () => {
        return <TableHeaderText>Type</TableHeaderText>;
      },
      // @ts-expect-error TS(7031): Binding element 'category' implicitly has an 'any'... Remove this comment to see the full error message
      viewCellContent: ({item: category}) => {
        return <Text>{CategoryKind.getLabel(category.kind)}</Text>;
      },
      editCellContent: ({rowIndex}: any) => {
        return (
          <FieldInput.Memoized
            {...form}
            // since the field is memoized, we add a key to trigger an update on isDeleted
            key={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
            name={`${field}.${rowIndex}.kind`}
            component={DropdownInput}
            input={{
              // kind cannot be changed on existing categories
              disabled:
                _.get(form.values, `${field}.${rowIndex}.categoryId`) ||
                _.get(form.values, `${field}.${rowIndex}.isDeleted`),
              isPortaled: true,
              options: CategoryKind.dropdownOptions,
              placeholder: 'Select category kind',
              setFieldValue: form.setFieldValue,
              style: {width: '100%'},
              onChangeValue: () => setFormIsDirty({form, field, rowIndex}),
            }}
            style={{flex: 1}}
          />
        );
      },
      footerContent: () => {},
    },
    {
      flex: 1,
      headerContent: () => {
        return (
          <Row style={{justifyContent: 'center', width: '100%'}}>
            <TableHeaderText>Shared</TableHeaderText>
            <Space width={8} />
            <TextTooltip
              isEnabledMobileToast={false}
              placement={'top'}
              style={{textAlign: 'center', width: '220px'}}
              text={
                `Check if you want this category to be accessible across all rooms. ` +
                `Uncheck if you want this category to only be accessible in select rooms.`
              }
            >
              <IconContainer>
                <Icon
                  color={colors.gray.secondary}
                  size={Icon.Sizes.Medium}
                  source={Icon.InfoCircle}
                />
              </IconContainer>
            </TextTooltip>
          </Row>
        );
      },
      // @ts-expect-error TS(7031): Binding element 'category' implicitly has an 'any'... Remove this comment to see the full error message
      viewCellContent: ({item: category}) => {
        return (
          <CenteredCell>
            {category.isShared && (
              <IconContainer>
                <Icon color={colors.gray.primary} size={Icon.Sizes.Medium} source={Icon.Check} />
              </IconContainer>
            )}
          </CenteredCell>
        );
      },
      editCellContent: ({rowIndex}: any) => {
        const isSharedField = `${field}.${rowIndex}.isShared`;
        return (
          <CenteredCell>
            <Checkbox
              isDisabled={_.get(form.values, `${field}.${rowIndex}.isDeleted`)}
              isChecked={_.get(form.values, isSharedField)}
              handleToggle={(value) => {
                form.setFieldValue(isSharedField, value);
                setFormIsDirty({form, field, rowIndex});
              }}
            />
          </CenteredCell>
        );
      },
      footerContent: () => {},
    },
    {
      flex: 1,
      headerContent: () => {},
      viewCellContent: () => {},
      editCellContent: ({rowIndex}: any) => {
        const isDeleted = _.get(form.values, `${field}.${rowIndex}.isDeleted`);
        return (
          <CenteredCell>
            <TertiaryButton
              text={isDeleted ? 'Restore' : 'Remove'}
              onPress={() => {
                form.setFieldValue(`${field}.${rowIndex}.isDeleted`, !isDeleted);
                setFormIsDirty({form, field, rowIndex});
              }}
              textColor={isDeleted ? colors.blue.interactive : colors.red.warning}
            />
          </CenteredCell>
        );
      },
      footerContent: () => {},
    },
  ];
};

const getViewColumnDefinitions = () => {
  // these dependencies are not needed for the viewCellContent
  return _.map(
    getColumnDefinitions({
      isEdit: false,
      inventoryLibrary: null,
      form: null,
      field: '',
      scrollToBottom: () => {},
      bulkSelectedFormIndices: [],
      setBulkSelectedFormIndices: () => {},
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.viewCellContent,
    }),
  );
};

const getEditColumnDefinitions = ({
  inventoryLibrary,
  form,
  field,
  scrollToBottom,
  bulkSelectedFormIndices,
  setBulkSelectedFormIndices,
}: any) => {
  return _.map(
    getColumnDefinitions({
      isEdit: true,
      inventoryLibrary,
      form,
      field,
      scrollToBottom,
      bulkSelectedFormIndices,
      setBulkSelectedFormIndices,
    }),
    (columnDefinition) => ({
      ...columnDefinition,
      cellContent: columnDefinition.editCellContent,
    }),
  );
};

const scrollToBottom = ({virtualizedListRef, itemCount}: any) => {
  virtualizedListRef.current.scrollToItem(itemCount);
};

const CategoriesPageContent = ({inventoryLibrary, viewerId, title, refetch}: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [bulkSelectedFormIndices, setBulkSelectedFormIndices] = useState([]);
  const importInventoryLibraryCategoriesModal = useModal({
    name: 'Import Inventory Library Categories Modal',
  });
  const virtualizedListRef = useRef(null);

  // View
  const [searchTerm, setSearchTerm] = useState('');
  const viewColumnDefinitions = getViewColumnDefinitions();
  const csvActions = getCsvActions({importInventoryLibraryCategoriesModal, inventoryLibrary});
  const filteredCategories = _.filter(inventoryLibrary.categories, (category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Edit
  const categoriesForm = CategoriesForm.edit(inventoryLibrary);
  const {form, handleSubmit, submitting} = useUpsertCategoriesMutation({
    categoriesForm,
    onSuccess: () => {
      form.resetForm();
      refetch();
      setIsEdit(false);
    },
    onError: (errors: any) => console.log(errors),
  });
  const field = 'categoriesForm.categoryForms';
  const editColumnDefinitions = getEditColumnDefinitions({
    inventoryLibrary,
    form,
    field,
    scrollToBottom: () =>
      scrollToBottom({virtualizedListRef, itemCount: _.get(form.values, field).length}),
    bulkSelectedFormIndices,
    setBulkSelectedFormIndices,
  });

  // FEATURE FLAG REMOVAL: SURVEYS_TIME_ADDITIVES
  const {isEnabledSurveysTimeAdditives} = inventoryLibrary.organization.features;

  return (
    <React.Fragment>
      <InventoryLibraryBulkUpdateContents
        ViewComponent={InventoryLibraryBulkUpdateContents.ViewTable}
        viewComponentProps={{
          columnDefinitions: viewColumnDefinitions,
          tableItems: filteredCategories,
          emptyStateText: `Click “Edit” to create a category and it'll appear here.`,
        }}
        EditComponent={InventoryLibraryBulkUpdateContents.EditTable}
        editComponentProps={{
          form,
          field,
          itemKey: 'categoryId',
          columnDefinitions: editColumnDefinitions,
          virtualizedListRef,
        }}
        editHeaderProps={{
          field,
          bulkSelectedFormIndices,
          setBulkSelectedFormIndices,
        }}
        isEdit={isEdit}
        setIsEdit={(isEdit: any) => {
          setIsEdit(isEdit);
          setSearchTerm('');
        }}
        title={title}
        csvActions={csvActions}
        setSearchTerm={setSearchTerm}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
      />
      <ImportInventoryLibraryCategoriesModal
        key={importInventoryLibraryCategoriesModal.key}
        viewerId={viewerId}
        organizationId={inventoryLibrary.organizationId}
        inventoryLibraryId={inventoryLibrary.id}
        isOpen={importInventoryLibraryCategoriesModal.isOpen}
        handleClose={importInventoryLibraryCategoriesModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const InventoryLibraryCategoriesPage = () => {
  const [refreshKey, setRefreshKey] = useState(0);
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(InventoryLibraryCategoriesPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid: params.libraryUuid,
    },
  });
  const refetchWithKeyUpdate = () => {
    refetch();
    setRefreshKey(refreshKey + 1);
  };
  return (
    <InventoryLibrarySettingsPage
      isLoading={loading}
      inventoryLibraryUuid={params.libraryUuid}
      inventoryLibraryName={data ? data.inventoryLibrary.name : ''}
      inventoryLibraryKind={data ? data.inventoryLibrary.kind : ''}
    >
      {data && (
        <CategoriesPageContent
          key={refreshKey}
          inventoryLibrary={data.inventoryLibrary}
          viewerId={data.viewer.id}
          title={'Categories'}
          refetch={refetchWithKeyUpdate}
        />
      )}
    </InventoryLibrarySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryLibraryCategoriesPage.query = gql`
  ${CategoriesForm.edit.fragment}
  ${InventoryLibrary.handleFileDownload.fragment}
  ${InventoryLibraryBulkUpdateContents.fragment}

  query InventoryLibraryCategoriesPage($inventoryLibraryUuid: String!) {
    ${gql.query}
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      name
      kind
      organizationId
      organization {
        id
        ...InventoryLibraryBulkUpdateContents
      }
      categories {
        id
        name
        kind
        isShared
      }
      ...CategoriesForm_edit
      ...InventoryLibrary_handleFileDownload
    }
    viewer {
      id
    }
  }
`;

export default InventoryLibraryCategoriesPage;
