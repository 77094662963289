// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import JobValueFields from 'modules/Project/Billing/components/JobValueFields';
import TwoColumnValueFields from 'modules/Project/Billing/components/TwoColumnValueFields';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const VariablesContent = ({index, form, jobFormField, isEdit}: any) => {
  const hasVisibleValueForms = !_.isEmpty(
    _.filter(
      _.get(form.values, `${jobFormField}.valueForms`),
      (valueForm) => valueForm.isVisibleForCreateProject,
    ),
  );
  return (
    <React.Fragment>
      {!hasVisibleValueForms && <EmptyStateText>No variables to display</EmptyStateText>}
      <JobValueFields
        index={index}
        jobTypeField={`${jobFormField}.jobTypeId`}
        valueFormsField={`${jobFormField}.valueForms`}
        form={form}
        isEdit={isEdit}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}: any) => (
          <React.Fragment>
            <TwoColumnValueFields
              outerIndex={index}
              form={form}
              valueFormsField={valueFormsField}
              valueForms={valueForms}
              startingValueFormIndex={startingValueFormIndex}
            />
            <Space height={8} />
          </React.Fragment>
        )}
      </JobValueFields>
    </React.Fragment>
  );
};

const ProjectJobVariablesInfoBlock = ({index, form, jobField, isEdit}: any) => {
  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Variables</SectionTitle>
          <EmptySpace />
        </React.Fragment>
      }
    >
      <VariablesContent index={index} form={form} jobFormField={jobField} isEdit={isEdit} />
    </ProjectJobSection>
  );
};

export default ProjectJobVariablesInfoBlock;
