// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useForm, useModal, useResponsive, useSheet} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ButtonGroup from '@shared/design/components/Button/ButtonGroup';
import DropdownSheet from '@shared/design/components/DropdownInput/components/DropdownSheet';
import FieldInput from '@shared/design/components/Field/FieldInput';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SheetWithFooter from '@shared/design/components/Sheet/SheetWithFooter';
import ItemTypeKind from '@shared/modules/Inventory/enums/ItemTypeKind';
import InventoryRoomsForm from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import RoomItemsForm from '@shared/modules/Inventory/forms/RoomItemsForm';

const RoomHeaderContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.gray.background};
  border-color: ${colors.gray.border};
  border-bottom-width: 1px;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '16' : '32')}px;
  padding-vertical: 12px;
`;

const IconButton = Styled.ButtonV2`
  padding-left: 16px;
  padding-right: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '8' : '0')}px;
  padding-vertical: 8px;
`;

const getFilterOptions = ({itemCount, cartonCount}: any) => {
  return [
    {
      value: ItemTypeKind.ITEM,
      label: 'Items',
      count: _.toString(itemCount),
    },
    {
      value: ItemTypeKind.CARTON,
      label: 'Cartons',
      count: _.toString(cartonCount),
    },
  ];
};

const MobileInventoryRoomHeader = ({
  roomItemsForm,
  inventoryRoomsForm,
  selectedRoomIndex,
  itemTypeKindFilters,
  setItemTypeKindFilters,
}: any) => {
  const responsive = useResponsive();
  const roomActionsSheet = useSheet({name: 'Room Actions Sheet'});
  const editRoomSheet = useSheet({name: 'Edit Room Sheet'});
  const deleteRoomModal = useModal({name: 'Delete Room Modal'});
  const clonedRoomForm = _.cloneDeep(roomItemsForm);
  const form = useForm({initialValues: clonedRoomForm});
  const roomItemsFormInfo = RoomItemsForm.getInfo(roomItemsForm);
  return (
    <RoomHeaderContainer responsive={responsive}>
      <ButtonGroup
        containerStyle={{width: 'fit-content'}}
        options={getFilterOptions(roomItemsFormInfo)}
        selectedOptionValues={itemTypeKindFilters}
        handleOptionPress={(option) => {
          setItemTypeKindFilters(_.xor(itemTypeKindFilters, [option]));
        }}
        showCounts
      />
      <Space style={{flex: 1, minWidth: 12}} />
      <IconButton
        responsive={responsive}
        onPress={roomActionsSheet.handleOpen}
        hitSlop={{right: 16}}
      >
        <Icon source={Icon.EllipsisV} size={16} color={colors.gray.secondary} />
      </IconButton>
      <DropdownSheet
        key={roomActionsSheet.key}
        isOpen={roomActionsSheet.isOpen}
        handleClose={roomActionsSheet.handleClose}
        headerText={'Actions'}
        isSearchable={false}
        options={DropdownSheet.formatActionsForDropdownSheet({
          actions: [
            {
              text: 'Edit room name',
              onPress: editRoomSheet.handleOpen,
            },
            {
              text: 'Delete room',
              onPress: deleteRoomModal.handleOpen,
              color: colors.red.warning,
            },
          ],
          handleClose: roomActionsSheet.handleClose,
        })}
      />
      <SheetWithFooter
        headerText={`Edit ${roomItemsForm.name}`}
        isOpen={editRoomSheet.isOpen}
        handleClose={editRoomSheet.handleClose}
        primaryActionText={'Save'}
        handlePrimaryAction={() => {
          if (form.values.name === '') {
            form.setErrors({name: 'Please enter a name'});
            return;
          }
          const field = `inventoryRoomsForm.roomItemsForms.${selectedRoomIndex}`;
          inventoryRoomsForm.setFieldValue(field, {...form.values, isDirty: true});
          editRoomSheet.handleClose();
        }}
        isFixedFooter
      >
        <FieldInput
          {...form}
          isResponsive
          setFieldValue={form.setFieldValue}
          input={{autoFocus: true}}
          name={'name'}
          label={'Room Name'}
        />
      </SheetWithFooter>
      <DeleteModal
        key={deleteRoomModal.key}
        isOpen={deleteRoomModal.isOpen}
        title={`Delete ${roomItemsForm.name}?`}
        subtitle={
          'This room and its items will be deleted from this survey. This cannot be undone.'
        }
        handleClose={deleteRoomModal.handleClose}
        handleDelete={() => {
          InventoryRoomsForm.handleDeleteRoom({
            inventoryRoomsForm,
            index: selectedRoomIndex,
            resetToSummary: true,
          });
          deleteRoomModal.handleClose();
        }}
      />
    </RoomHeaderContainer>
  );
};

export default MobileInventoryRoomHeader;
