/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

const Section = Styled.View`
`;

const Table = Styled.View`
`;

const TableHeader = Styled.View`
  flex-direction: row;
`;

const TableCell = Styled.View`
  width: 120px;
  padding: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const TableCellHeaderText = Styled.H8`
  ${fontWeight(700)}
`;

const TableCellText = Styled.H8`
`;

const TableRow = Styled.View`
  flex-direction: row;
`;

const ValuationDeclarationTable = ({job}: any) => {
  const {valuationCoverageTable} = job.jobType.valuationCoverageConfig;

  return (
    <Section>
      <Table>
        {valuationCoverageTable.map((row: any, rowIndex: any) => {
          if (rowIndex === 0) {
            return (
              <TableHeader key={rowIndex}>
                {row.map((column: any, columnIndex: any) => (
                  <TableCell key={columnIndex}>
                    <TableCellHeaderText>{column}</TableCellHeaderText>
                  </TableCell>
                ))}
              </TableHeader>
            );
          }

          return (
            <TableRow key={rowIndex}>
              {row.map((column: any, columnIndex: any) => (
                <TableCell key={columnIndex}>
                  <TableCellText>{column}</TableCellText>
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </Table>
    </Section>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ValuationDeclarationTable.fragment = gql`
  fragment ValuationDeclarationTable on Job {
    id
    jobType {
      id
      valuationCoverageConfig {
        valuationCoverageTable
      }
    }
  }
`;

export default ValuationDeclarationTable;
