// Libraries
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {useInlineFormMutation} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import UserFlowForm, {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';

const useDuplicateUserFlowMutation = ({
  userFlowForm,
  onSuccess,
}: {
  userFlowForm: UserFlowFormToFormType;
  onSuccess: () => void;
}) => {
  return useInlineFormMutation({
    name: 'userFlowForm',
    form: userFlowForm,
    toForm: UserFlowForm.toForm,
    toMutation: UserFlowForm.toMutation,
    mutation: gql`
        mutation useDuplicateUserFlowMutation($userFlowForm: UserFlowForm!) {
          response: duplicateUserFlow(userFlowForm: $userFlowForm) {
            ${gql.errors}
            userFlow {
              id
            }
          }
        }
      `,
    onSuccess,
  });
};

const OrganizationSettingsDocumentFlowDuplicateModalContent = ({
  userFlow,
  isOpen,
  handleClose,
  onDuplicated,
}: {
  userFlow: UserFlowModel;
  isOpen: boolean;
  handleClose: () => void;
  onDuplicated: () => void;
}) => {
  const {form, handleSubmit, submitting} = useDuplicateUserFlowMutation({
    userFlowForm: UserFlowForm.edit(userFlow),
    onSuccess: () => {
      onDuplicated();
      handleClose();
    },
  });

  return (
    <CautionModal
      isOpen={isOpen}
      title={`Duplicate this flow?`}
      isSubmitting={submitting}
      primaryActionText={`Duplicate`}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={`Cancel`}
      handleSecondaryAction={handleClose}
    />
  );
};

interface ManageDashboardCategoryDuplicateModalProps {
  userFlow: UserFlowModel;
  isOpen: boolean;
  handleClose: () => void;
  onDuplicated: () => void;
}

const OrganizationSettingsDocumentFlowDuplicateModal = ({
  userFlow,
  isOpen,
  handleClose,
  onDuplicated,
}: ManageDashboardCategoryDuplicateModalProps) => {
  const {data, loading} = useQuery<{userFlow: UserFlowModel}>(
    OrganizationSettingsDocumentFlowDuplicateModalQuery,
    {
      variables: {uuid: userFlow.uuid},
      skip: !isOpen,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <OrganizationSettingsDocumentFlowDuplicateModalContent
      userFlow={data.userFlow}
      isOpen={isOpen}
      handleClose={handleClose}
      onDuplicated={onDuplicated}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const OrganizationSettingsDocumentFlowDuplicateModalQuery = gql`
  ${UserFlowForm.edit.fragment}
  query OrganizationSettingsDocumentFlowDuplicateModalQuery($uuid: String!) {
    ${gql.query}
    userFlow(uuid: $uuid) {
      id
      ...UserFlowForm_edit
    }
  }
`;

export default OrganizationSettingsDocumentFlowDuplicateModal;
