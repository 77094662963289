// Libararies
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useDrawer} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ProjectTasks from 'modules/Job/components/ProjectTasks';

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
`;

const ProjectTasksWidget = ({projectUuid, urlFilters}: any) => {
  const newTaskDrawer = useDrawer({
    name: 'New Task Drawer - Project Page',
    enableTracking: true,
  });

  return (
    <Container>
      <ProjectTasks
        projectUuid={projectUuid}
        newTaskDrawer={newTaskDrawer}
        isWidget
        onCloseUrlTask={() => urlFilters.handleUpdate({taskUuid: null})}
      />
    </Container>
  );
};

export default ProjectTasksWidget;
