// Libraries
import React from 'react';

import useDeleteUserMutation from '@shared/modules/User/hooks/useDeleteUserMutation';
import DeleteModal from 'modules/App/components/DeleteModal';

const DeleteUserModal = ({isOpen, handleClose, userId, refetch}: any) => {
  const {handleSubmit} = useDeleteUserMutation({
    userId,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={'Are you sure you want to delete this user?'}
      subtitle={
        "This user will be removed from all existing records on any completed or scheduled jobs. If you want to keep all records intact, mark the user inactive. You can't undo this action."
      }
      handleClose={handleClose}
      handleConfirm={handleSubmit}
    />
  );
};

export default DeleteUserModal;
