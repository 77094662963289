// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import JobProjectNavigation from './JobProjectNavigation';

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobNavigation.defaultProps;

// @ts-expect-error TS(7022): 'JobNavigation' implicitly has type 'any' because ... Remove this comment to see the full error message
const JobNavigation = ({job, viewer, handleOpenSidebar, refetch}: Props) => {
  return (
    <JobProjectNavigation
      job={job}
      viewer={viewer}
      handleOpenSidebar={handleOpenSidebar}
      refetch={refetch}
    />
  );
};

JobNavigation.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobNavigation.fragment = gql`
  ${JobProjectNavigation.fragment}

  fragment JobNavigation_Viewer on User {
    id
    ...JobProjectNavigation_Viewer
  }

  fragment JobNavigation_Job on Job {
    id
    ...JobProjectNavigation_Job
  }
`;

export default JobNavigation;
