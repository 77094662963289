// Libararies
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useModal, useToast, useDrawer} from '@supermove/hooks';
import {Invoice} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import SendInvoiceToCodatForm from '@shared/modules/Billing/forms/SendInvoiceToCodatForm';
import useSendInvoiceMutation from '@shared/modules/Billing/hooks/useSendInvoiceMutation';
import useSendInvoiceToCodatMutation from '@shared/modules/Billing/hooks/useSendInvoiceToCodatMutation';
import InvoiceChargeCreditCardDrawer from 'modules/Accounting/components/InvoiceChargeCreditCardDrawer';
import useAppContext from 'modules/App/context/useAppContext';
import ClientCreditCardsDrawer from 'modules/Client/components/ClientCreditCardsDrawer';
import CancelInvoiceModal from 'modules/Storage/components/CancelInvoiceModal';
import ConfirmInvoiceEditModal from 'modules/Storage/components/ConfirmInvoiceEditModal';
import FinalizeInvoiceModal from 'modules/Storage/components/FinalizeInvoiceModal';
import RecordInvoicePaymentDrawer from 'modules/Storage/components/RecordInvoicePaymentDrawer';
import UnfinalizeInvoiceModal from 'modules/Storage/components/UnfinalizeInvoiceModal';
import UpdateInvoiceDrawer from 'modules/Storage/components/UpdateInvoiceDrawer';

const IconButton = Styled.ButtonV2`
  width: 16px;
  height: 16px;
  align-items: center;
`;

const InvoiceActionsPopoverButton = ({
  invoiceActionsPopover,
  invoice,
  navigator,
  refetch,
  cancelInvoiceModal,
  finalizeInvoiceModal,
  unfinalizeInvoiceModal,
  recordPaymentModal,
  handleResendInvoiceSubmit,
  handleSendInvoiceToCodatSubmit,
  updateInvoiceDrawer,
  confirmInvoiceEditModal,
  chargeCreditCardDrawer,
  manageCreditCardsDrawer,
  isTertiaryButton,
}: any) => {
  const {viewer} = useAppContext();
  const {isAuthorizedAccountingActions} = viewer || {};

  const handleEditStorageInvoice = () =>
    navigator.push(`/storage/projects/${invoice.project.uuid}/invoices/${invoice.uuid}`);

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={invoiceActionsPopover}
        placement={ActionMenuPopover.Position.LeftStart}
        width={240}
        actions={Invoice.getInvoiceActions(
          invoice,
          {
            handleEditStorageInvoice,
            handleSendInvoiceToCodatSubmit,
            handleResendInvoiceSubmit,
            recordPaymentModal,
            chargeCreditCardDrawer,
            manageCreditCardsDrawer,
            cancelInvoiceModal,
            finalizeInvoiceModal,
            unfinalizeInvoiceModal,
            updateInvoiceDrawer,
            confirmInvoiceEditModal,
            navigator,
            isAuthorizedAccountingActions,
          },
          ['INVOICE', 'PAYMENTS', 'ADMIN'],
        ).map((section) => ({
          ...section,
          actions: section.actions.map((action) => ({...action, text: action.label})),
        }))}
      >
        {isTertiaryButton ? (
          <TertiaryButton
            text={`Actions`}
            iconRight={Icon.ChevronDown}
            onPress={invoiceActionsPopover.handleOpen}
            isLarge
          />
        ) : (
          <IconButton
            onPress={invoiceActionsPopover.handleOpen}
            disabled={invoice.status === InvoiceStatus.CANCELLED}
          >
            <Icon source={Icon.EllipsisV} color={colors.gray.tertiary} size={16} />
          </IconButton>
        )}
      </ActionMenuPopover>
      <FinalizeInvoiceModal
        invoiceId={invoice.id}
        isOpen={finalizeInvoiceModal.isOpen}
        handleClose={finalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <UnfinalizeInvoiceModal
        invoiceId={invoice.id}
        isOpen={unfinalizeInvoiceModal.isOpen}
        handleClose={unfinalizeInvoiceModal.handleClose}
        refetch={refetch}
      />
      <CancelInvoiceModal
        invoiceId={invoice.id}
        invoiceIdentifier={invoice.identifier}
        isOpen={cancelInvoiceModal.isOpen}
        handleClose={cancelInvoiceModal.handleClose}
        refetch={refetch}
      />
      <RecordInvoicePaymentDrawer
        key={recordPaymentModal.key}
        isOpen={recordPaymentModal.isOpen}
        handleClose={recordPaymentModal.handleClose}
        refetch={refetch}
        invoiceUuid={invoice.uuid}
      />
      <UpdateInvoiceDrawer
        key={updateInvoiceDrawer.key}
        invoiceUuid={invoice.uuid}
        isOpen={updateInvoiceDrawer.isOpen}
        handleClose={updateInvoiceDrawer.handleClose}
        refetch={refetch}
      />
      <ConfirmInvoiceEditModal
        key={confirmInvoiceEditModal.key}
        invoice={invoice}
        handleAction={handleEditStorageInvoice}
        isOpen={confirmInvoiceEditModal.isOpen}
        handleClose={confirmInvoiceEditModal.handleClose}
      />
      <InvoiceChargeCreditCardDrawer
        key={chargeCreditCardDrawer.key}
        isOpen={chargeCreditCardDrawer.isOpen}
        handleClose={chargeCreditCardDrawer.handleClose}
        invoiceUuid={invoice.uuid}
        refetch={refetch}
      />
      <ClientCreditCardsDrawer
        key={manageCreditCardsDrawer.key}
        isOpen={manageCreditCardsDrawer.isOpen}
        clientId={invoice.project.billingClientId}
        projectId={invoice.project.id}
        handleClose={manageCreditCardsDrawer.handleClose}
      />
    </React.Fragment>
  );
};

type OwnInvoiceActionsProps = {
  refetch?: (...args: any[]) => any;
  isTertiaryButton?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'InvoiceActionsProps' circularly refere... Remove this comment to see the full error message
type InvoiceActionsProps = OwnInvoiceActionsProps & typeof InvoiceActions.defaultProps;

// @ts-expect-error TS(7022): 'InvoiceActions' implicitly has type 'any' because... Remove this comment to see the full error message
const InvoiceActions = ({invoice, navigator, refetch, isTertiaryButton}: InvoiceActionsProps) => {
  const invoiceActionsPopover = usePopover();
  const cancelInvoiceModal = useModal({name: 'Cancel Invoice Modal', enableTracking: true});
  const recordPaymentModal = useModal({name: 'Record Payment Modal', enableTracking: true});
  const finalizeInvoiceModal = useModal({name: 'Finalize Invoice Modal', enableTracking: true});
  const unfinalizeInvoiceModal = useModal({name: 'Unfinalize Invoice Modal', enableTracking: true});
  const updateInvoiceDrawer = useDrawer({name: 'Update Invoice Drawer', enableTracking: true});
  const chargeCreditCardDrawer = useDrawer({
    name: 'Charge Credit Card Drawer',
    enableTracking: true,
  });
  const manageCreditCardsDrawer = useDrawer({
    name: 'Manage Credit Cards Drawer',
    enableTracking: true,
  });
  const confirmInvoiceEditModal = useModal({
    name: 'Confirm Invoice Edit Modal',
    enableTracking: true,
  });
  const invoiceForm = InvoiceForm.idOnly({invoiceId: invoice.id});
  const sendInvoiceToCodatForm = SendInvoiceToCodatForm.edit(invoice);

  // Name spacing Toast and Mutation since this is a list of actions
  const successResendInvoiceToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${invoice.identifier} sent!`,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const sendInvoiceToCodatToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice ${invoice.identifier} has been queued for export. It will be exported in the next batch.`,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const {handleSubmit: handleResendInvoiceSubmit} = useSendInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      successResendInvoiceToast.handleToast();
    },
    onError: (errors: any) => {
      console.log(errors);
    },
  });

  const {handleSubmit: handleSendInvoiceToCodatSubmit} = useSendInvoiceToCodatMutation({
    sendInvoiceToCodatForm,
    onSuccess: () => {
      refetch();
      sendInvoiceToCodatToast.handleToast();
    },
    onError: (errors: any) => {
      refetch();
      console.log(errors);
    },
  });

  return (
    <InvoiceActionsPopoverButton
      invoiceActionsPopover={invoiceActionsPopover}
      invoice={invoice}
      navigator={navigator}
      refetch={refetch}
      cancelInvoiceModal={cancelInvoiceModal}
      finalizeInvoiceModal={finalizeInvoiceModal}
      unfinalizeInvoiceModal={unfinalizeInvoiceModal}
      recordPaymentModal={recordPaymentModal}
      handleResendInvoiceSubmit={handleResendInvoiceSubmit}
      handleSendInvoiceToCodatSubmit={handleSendInvoiceToCodatSubmit}
      updateInvoiceDrawer={updateInvoiceDrawer}
      confirmInvoiceEditModal={confirmInvoiceEditModal}
      chargeCreditCardDrawer={chargeCreditCardDrawer}
      manageCreditCardsDrawer={manageCreditCardsDrawer}
      isTertiaryButton={isTertiaryButton}
    />
  );
};

InvoiceActions.defaultProps = {
  refetch: () => {},
  isTertiaryButton: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceActions.fragment = gql`
  ${Invoice.getInvoiceActions.fragment}

  fragment InvoiceActions on Invoice {
    id
    uuid
    status
    identifier
    project {
      id
      uuid
      billingClientId
    }
    ...Invoice_getInvoiceActions
  }
`;

export default InvoiceActions;
