// Libraries
import React from 'react';

// Supermove
import {SingleDatePicker} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {Datetime, URL} from '@supermove/utils';

// App
import CapacityCalendarSettingHelpers from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSettingHelpers';

const getColorsForCapacityCalendarDays = ({capacityCalendar}: any) => {
  const dayColors = {};
  capacityCalendar.capacityCalendarDays.forEach((capacityCalendarDay: any) => {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    dayColors[capacityCalendarDay.date] =
      CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor({
        capacityCalendar,
        capacityCalendarDay,
      });
  });
  return dayColors;
};

const getFilteredUrlFromParams = (params: any, baseUrl: any) => {
  return URL.getUrlFromVariables(baseUrl, params);
};

const handleUpdateParam = ({baseUrl, navigator, params, paramKey, paramValue}: any) => {
  navigator.replace(getFilteredUrlFromParams({...params, [paramKey]: paramValue || ''}, baseUrl));
};

const CapacityCalendarDayPickerBlock = ({selectedDate, setSelectedDate, organization}: any) => {
  const {navigator, params} = useNavigationDOM();
  const {location} = navigator;
  const [viewingMonth, setViewingMonth] = useState(Datetime.toMutationMonth(selectedDate));

  const handleViewingMonthChange = (date: any) => {
    setViewingMonth(Datetime.toMutationMonth(date));
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
    handleUpdateParam({
      baseUrl: location.pathname,
      navigator,
      params,
      paramKey: 'date',
      paramValue: date,
    });
  };

  const {data, loading} = useQuery(CapacityCalendarDayPickerBlock.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      month: viewingMonth,
      slugs: params.slugs
        ? params.slugs
        : organization.isPrimary
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
      shouldFetchAdditionalDaysBeforeAndAfterMonth: false,
    },
  });

  return (
    <SingleDatePicker
      selectedDate={selectedDate}
      handleDateChange={handleDateChange}
      handleViewingMonthChange={handleViewingMonthChange}
      viewingMonth={viewingMonth}
      dayColors={
        data &&
        getColorsForCapacityCalendarDays({
          capacityCalendar: data.capacityCalendar,
        })
      }
      loading={loading}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarDayPickerBlock.query = gql`
  ${CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor.fragment}
  query CapacityCalendarDayPickerBlock($month: String!, $shouldFetchAdditionalDaysBeforeAndAfterMonth: Boolean, $slugs: [String!]!) {
    ${gql.query}
    capacityCalendar(month: $month, shouldFetchAdditionalDaysBeforeAndAfterMonth: $shouldFetchAdditionalDaysBeforeAndAfterMonth, slugs: $slugs) {
      ...CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor
	  }
  }
`;

CapacityCalendarDayPickerBlock.fragment = gql`
  fragment CapacityCalendarDayPickerBlock on Organization {
    id
    slug
    isPrimary
  }
`;

export default CapacityCalendarDayPickerBlock;
