// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove

// Components
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

import {Navigation} from 'modules/App/components';
import {getSidebarItems} from 'modules/App/components/Sidebar/data/SidebarData';

import LogOutModal from '../LogOutModal';
import SidebarAvatar from '../SidebarAvatar';

import SidebarItemV2 from './SidebarItemV2';

const Wrapper = Styled.View`
  flex: 1;
  justify-content: space-between;
  width: 240px;
`;

const Container = Styled.View`
  flex: 1;
  align-items: flex-end;
`;

const Items = Styled.View`
  align-items: flex-end;
`;

const Item = Styled.View`
  width: 100%;
`;

const User = Styled.View`
  width: 100%;
`;

const Touchable = Styled.Touchable`
`;

type OwnProps = {
  selectedItem: string;
  notificationCounts?: any;
  viewer: any;
  onSidebarClose: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SidebarItems.defaultProps;

// @ts-expect-error TS(7022): 'SidebarItems' implicitly has type 'any' because i... Remove this comment to see the full error message
const SidebarItems = ({selectedItem, notificationCounts, viewer, onSidebarClose}: Props) => {
  const responsive = useResponsive();

  return (
    <Wrapper>
      <Container>
        <Navigation.CloseButton responsive={responsive} onPress={onSidebarClose} />
        <ScrollView style={{alignSelf: 'stretch'}}>
          <Items>
            {/* @ts-expect-error TS(2345): Argument of type '{ viewer: any; responsive: Respo... Remove this comment to see the full error message */}
            {getSidebarItems({viewer, responsive}).map(({key, to, name, source}) => (
              <Item key={key} {...responsive}>
                <SidebarItemV2
                  isSelected={key === selectedItem}
                  count={_.get(notificationCounts, key, 0)}
                  to={to}
                  name={name}
                  source={source}
                  responsive={responsive}
                />
              </Item>
            ))}
          </Items>
        </ScrollView>
      </Container>
      <User>
        {viewer.viewingOrganization && (
          <LogOutModal
            trigger={({handleOpen}: any) => (
              <Touchable onPress={handleOpen}>
                <SidebarAvatar size={40} user={viewer} />
              </Touchable>
            )}
          />
        )}
      </User>
    </Wrapper>
  );
};

SidebarItems.defaultProps = {
  notificationCounts: {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SidebarItems.fragment = gql`
  ${SidebarAvatar.fragment}
  ${getSidebarItems.fragment}

  fragment SidebarItems_viewer on User {
    id
    ...SidebarAvatar
    ...User_getSidebarItems
  }
`;

export default SidebarItems;
