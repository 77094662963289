// Libraries
import _ from 'lodash';

// Supermove
import {ToggleBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import Toast from '@shared/design/components/Toast';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useUpdateTaskIsCompletedMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskIsCompletedMutation';

const getSuccessMessage = () => {
  return _.sample([
    'Task completed! Great job!',
    'Task completed! Keep up the great work!',
    "Really?! Another task completed?! You're awesome!",
    'Wow! Keep crushing those tasks!',
  ]);
};

const useToggleTaskIsCompleted = ({task, onSuccess}: any) => {
  const taskCompletedToast = useToast({
    ToastComponent: SuccessToast,
    message: getSuccessMessage(),
  });
  const taskMarkedIncompleteToast = useToast({
    ToastComponent: Toast,
    message: 'Task marked incomplete.',
  });
  const taskCompletedErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Error updating task.',
  });

  const toggleBooleanPropertyForm = ToggleBooleanPropertyForm.new({
    id: task.id,
    booleanValue: task.isCompleted,
  });

  const {form, handleSubmit, submitting} = useUpdateTaskIsCompletedMutation({
    toggleBooleanPropertyForm,
    onSuccess: ({task}: any) => {
      if (task.isCompleted) {
        taskCompletedToast.handleToast();
      } else {
        taskMarkedIncompleteToast.handleToast();
      }
      form.setFieldValue('toggleBooleanPropertyForm.booleanValue', task.isCompleted);
      onSuccess && onSuccess();
    },
    onError: (errors: any) => {
      taskCompletedErrorToast.handleToast();
      console.log({errors});
    },
  });

  return {
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useToggleTaskIsCompleted.fragment = gql`
  fragment useToggleTaskIsCompleted on Task {
    id
    isCompleted
  }
`;

export default useToggleTaskIsCompleted;
