// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import ReorderBillsForm from '@shared/modules/Billing/forms/ReorderBillsForm';
import useReorderBillsMutation from '@shared/modules/Billing/hooks/useReorderBillsMutation';
import ProjectBillTipsList from 'modules/Project/Billing/BillTip/components/ProjectBillTipsList';
import BillPaymentFeesList from 'modules/Project/Billing/components/BillPaymentFeesList';
import BillTotalTip from 'modules/Project/Billing/components/BillTotalTip';
import BillsListItem from 'modules/Project/Billing/components/BillsListItem';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LabelContainer = Styled.View`
  border-top-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '1px' : '0px')};
  border-left-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '1px' : '0px')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-color: ${colors.gray.border};
  padding-left: ${(props) => ((props as any).isMobile ? '0px' : '12px')};
  flex-direction: row;
  justify-content: ${(props) => (props as any).justify};
  align-items: center;
`;

const AmountContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '12px' : '0px')};
  border-top-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '1px' : '0px')};
  border-bottom-width: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isEditable ? '1px' : '0px')};
  flex: ${(props) => (props as any).flex};
  border-color: ${colors.gray.border};
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
  padding-vertical: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'MaybeRespo... Remove this comment to see the full error message
    vars,
  }) => (vars && vars.isEditable ? '12px' : '0px')};
`;

const RightSideBorder = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-vertical: 12px;
  padding-right: 12px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: ${colors.gray.border};
`;

const IconContainer = Styled.View`
  justify-content: center;
  align-items: center;
`;

const getSortedBills = ({bills, orderedBillForms}: any) => {
  const sortedBills: any = [];

  orderedBillForms.forEach((billForm: any) => {
    const matchingBill = _.find(bills, (bill) => String(bill.id) === String(billForm.billId));
    if (matchingBill) {
      sortedBills.push(matchingBill);
    }
  });

  return sortedBills;
};

const TotalLabel = ({isEditable, isProposal}: any) => {
  const responsive = useResponsive();

  return (
    <LabelContainer
      style={{backgroundColor: colors.white}}
      // @ts-expect-error TS(2769): No overload matches this call.
      vars={{isEditable}}
      justify={'flex-end'}
    >
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <LabelText vars={{isEditable}} responsive={responsive}>
        {isProposal ? 'Estimated Total' : 'Grand Total'}
      </LabelText>
    </LabelContainer>
  );
};

const GrandTotal = ({
  isEditable,
  isProposal,
  isTotalAvailable,
  minTotal,
  maxTotal,
  isEnabledTbdBillItems,
}: any) => {
  const responsive = useResponsive();

  return (
    <Row style={responsive.mobile ? {flex: 1} : {width: 400, alignSelf: 'flex-end'}}>
      {responsive.mobile ? (
        <TotalLabel isEditable={isEditable} isProposal={isProposal} />
      ) : (
        <TotalLabel isEditable={isEditable} isProposal={isProposal} />
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <AmountContainer style={{backgroundColor: colors.white}} vars={{isEditable}} flex={3}>
        <LabelText responsive={responsive}>
          {/* @ts-expect-error TS(2345): Argument of type '{ min: any; max: any; }' is not ... Remove this comment to see the full error message */}
          {isTotalAvailable ? Currency.formatRange({min: minTotal, max: maxTotal}) : 'TBD'}
        </LabelText>
        {isEnabledTbdBillItems && isEditable && !isTotalAvailable && responsive.desktop && (
          <Row>
            <Space width={8} />
            <TextTooltip
              isEnabledMobileToast={false}
              text={`If any bill items don't have a price or quantity, the totals will show as "TBD" and won't be shown to the customer.`}
            >
              <IconContainer>
                <Icon source={Icon.QuestionCircle} size={12} color={colors.gray.secondary} />
              </IconContainer>
            </TextTooltip>
          </Row>
        )}
      </AmountContainer>
      {isEditable && <RightSideBorder />}
    </Row>
  );
};

type OwnBillingProjectBillsListProps = {
  showEmptyTotals?: boolean;
  showQuantity?: boolean;
  showTotal?: boolean;
  showGrandTotal?: boolean;
  isProposal?: boolean;
  descriptionField?: string;
  getTruncatedKey?: (...args: any[]) => any;
  truncated?: any;
  setTruncated?: (...args: any[]) => any;
  enabledToggle?: any;
  setEnabledToggle?: (...args: any[]) => any;
  aggregateBill: any;
};

// @ts-expect-error TS(2456): Type alias 'BillingProjectBillsListProps' circular... Remove this comment to see the full error message
type BillingProjectBillsListProps = OwnBillingProjectBillsListProps &
  typeof BillingProjectBillsList.defaultProps;

// @ts-expect-error TS(7022): 'BillingProjectBillsList' implicitly has type 'any... Remove this comment to see the full error message
const BillingProjectBillsList = ({
  isDocument,
  isEditable,
  isReordering,
  isProposal,
  isCollapsed,
  showEmptyTotals,
  showQuantity,
  showTotal,
  showGrandTotal,
  showBillRules,
  project,
  refetch,
  pageRefetch,
  descriptionField,
  getTruncatedKey,
  truncated,
  setTruncated,
  enabledToggle,
  setEnabledToggle,
  aggregateBill,
}: BillingProjectBillsListProps) => {
  const responsive = useResponsive();
  const {isTotalAvailable, minTotal, maxTotal, bills, primaryBill} = aggregateBill;
  const reorderBillsForm = ReorderBillsForm.edit(bills);
  const {form, handleSubmit} = useReorderBillsMutation({
    reorderBillsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (error: any) => {
      console.log({error});
    },
  });

  const isGrandTotalVisible =
    (isEditable || showGrandTotal) && (showEmptyTotals || isTotalAvailable);

  return (
    <Container>
      {getSortedBills({bills, orderedBillForms: form.values.reorderBillsForm.reorderBillForms}).map(
        (bill: any, index: any) => {
          return (
            <React.Fragment key={`${index}_BILL`}>
              <BillsListItem
                bill={bill}
                index={index}
                refetch={refetch}
                pageRefetch={pageRefetch}
                isDocument={isDocument}
                isEditable={isEditable}
                isReordering={isReordering}
                isCollapsed={isCollapsed}
                reorderBillsForm={form}
                handleSubmit={handleSubmit}
                showQuantity={showQuantity}
                showTotal={showTotal}
                showBillRules={showBillRules}
                showEmptyTotals={showEmptyTotals}
                descriptionField={descriptionField}
                getTruncatedKey={getTruncatedKey}
                truncated={truncated}
                setTruncated={setTruncated}
                enabledToggle={enabledToggle}
                setEnabledToggle={setEnabledToggle}
                isEnabledConditionalBillItems={
                  project.organization.features.isEnabledConditionalBillItems
                }
              />
              <Space height={isEditable ? 8 : 12} />
            </React.Fragment>
          );
        },
      )}
      {isGrandTotalVisible && (
        <React.Fragment>
          <Space height={8} />
          {(primaryBill.hasBillTips || isEditable) && (
            <React.Fragment>
              {project.projectType.features.timesheetsV2 ? (
                <React.Fragment>
                  <BillTotalTip
                    bill={primaryBill}
                    projectUuid={project.uuid}
                    isEditable={isEditable}
                    refetch={refetch}
                  />
                  <Space height={16} />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <ProjectBillTipsList
                    project={project}
                    isEditable={isEditable}
                    primaryBill={primaryBill}
                    refetch={refetch}
                  />
                  <Space height={responsive.desktop ? 8 : 16} />
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {primaryBill.hasPaymentFees && (
            <React.Fragment>
              <BillPaymentFeesList bill={primaryBill} isEditable={isEditable} />
              <Space height={16} />
            </React.Fragment>
          )}
          <GrandTotal
            isEditable={isEditable}
            isProposal={isProposal}
            isTotalAvailable={isTotalAvailable}
            minTotal={minTotal}
            maxTotal={maxTotal}
            isEnabledTbdBillItems={project.organization.features.isEnabledTbdBillItems}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

BillingProjectBillsList.defaultProps = {
  showEmptyTotals: true,
  showQuantity: true,
  showTotal: true,
  showGrandTotal: true,
  isProposal: false,
  descriptionField: undefined,
  getTruncatedKey: undefined,
  truncated: undefined,
  setTruncated: undefined,
  enabledToggle: undefined,
  setEnabledToggle: undefined,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingProjectBillsList.fragment = gql`
  ${BillTotalTip.fragment}
  ${BillsListItem.fragment}
  ${BillPaymentFeesList.fragment}
  ${ProjectBillTipsList.fragment}
  ${ReorderBillsForm.edit.fragment}

  fragment BillingProjectBillsList_AggregateBill on AggregateBill {
    minTotal
    maxTotal
    isTotalAvailable
    primaryBill {
      id
      tip
      total
      hasBillTips
      hasPaymentFees
      ...BillTotalTip
      ...BillPaymentFeesList
      ...ProjectBillTipsList
    }
    bills {
      id
      ...BillsListItem
      ...ReorderBillsForm_edit
    }
  }

  fragment BillingProjectBillsList on Project {
    id
    uuid
    organization {
      id
      features {
        isEnabledTbdBillItems: isEnabled(feature: "TBD_BILL_ITEMS")
        isEnabledConditionalBillItems: isEnabled(feature: "CONDITIONAL_BILL_ITEMS")
      }
    }
    projectType {
      id
      features {
        timesheetsV2
      }
    }
    ...ProjectBillTipsList_Project
  }
`;

export default BillingProjectBillsList;
