export default {
  JOB_TYPES: 'JOB_TYPES',
  BILLING: 'BILLING',
  PAYMENTS: 'PAYMENTS',
  ACCOUNTING: 'ACCOUNTING',
  VARIABLES: 'VARIABLES',
  VALUATION_COVERAGE: 'VALUATION_COVERAGE',
  QUOTES_AND_CONFIRMATIONS: 'QUOTES_AND_CONFIRMATIONS',
  DOCUMENT_FLOWS: 'DOCUMENT_FLOWS',
  SURVEYS: 'SURVEYS',
  DRIVER_INVENTORY: 'DRIVER_INVENTORY',
};
