// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import TaskTemplateForm from '@shared/modules/TaskManagement/forms/TaskTemplateForm';
import useUpdateTaskTemplateMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskTemplateMutation';
import TaskTemplateDrawerFields from 'modules/TaskManagement/TaskTemplates/components/TaskTemplateDrawerFields';

const EditTaskTemplateDrawer = ({
  isOpen,
  handleClose,
  taskTemplate,
  organization,
  refetch,
}: any) => {
  const taskTemplateForm = TaskTemplateForm.edit(taskTemplate);
  const successToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Successfully updated task template!',
  });

  const {form, handleSubmit, submitting} = useUpdateTaskTemplateMutation({
    taskTemplateForm,
    onSuccess: () => {
      handleClose();
      successToast.handleToast();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      headerText={'Edit Task Template'}
      primaryActionText={'Save'}
      secondaryActionText={'Cancel'}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
    >
      <TaskTemplateDrawerFields
        form={form}
        field={'taskTemplateForm'}
        organization={organization}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTaskTemplateDrawer.fragment = gql`
  ${TaskTemplateForm.edit.fragment}
  ${TaskTemplateDrawerFields.fragment}
  fragment EditTaskTemplateDrawer on TaskTemplate {
    id
    ...TaskTemplateForm_edit
  }
  fragment EditTaskTemplateDrawer_Organization on Organization {
    id
    ...TaskTemplateDrawerFields
  }
`;

export default EditTaskTemplateDrawer;
