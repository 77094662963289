// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Icon, Styled, Space, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  usePopover,
  useQuery,
  useModal,
  useDebouncedCallback,
} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import SearchBar from '@shared/design/components/SearchBar';
import Tabs from '@shared/design/components/Tabs';
import TripStatus from '@shared/modules/Dispatch/enums/TripStatus';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import TripFiltersButton from 'modules/Dispatch/LongDistance/Trip/components/TripFiltersButton';
import LongDistanceDispatchTripsTable from 'modules/Dispatch/LongDistance/components/LongDistanceDispatchTripsTable';

const Container = Styled.View`
  flex: 1;
  padding-horizontal: 16px;
`;

const LeftContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const RightContainer = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const FiltersContainers = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ActionsButton = Styled.ButtonV2`
  background-color: ${colors.blue.interactive};
  padding: 8px 16px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
`;

const ActionsButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const TabsContainer = Styled.View`
`;

const TripsTabActionMenu = ({popover, createTripWithShipmentsModal}: any) => {
  return (
    <ActionMenuPopover
      popover={popover}
      placement={Popover.Positions.LeftEnd}
      width={200}
      offset={[4, 101]}
    >
      <ActionMenuPopover.MenuItem
        onPress={() => {
          createTripWithShipmentsModal.handleOpen();
          popover.handleClose();
        }}
      >
        Create Trip
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const handleTabClicked = ({tabValue, navigator}: any) => {
  navigator.replace(`/dispatch/long-distance/trips/${tabValue.toLowerCase()}?page=1`);
};

const getTabIndex = ({tabDefinitions, tabValue}: any) => {
  return _.findIndex(
    tabDefinitions,
    (definition) => (definition as any).value.toLowerCase() === tabValue.toLowerCase(),
  );
};

const getTabDefinitions = ({organization}: any) => {
  const {tripCountsByStatus} = organization;
  return [
    {
      label: TripStatus.getStatusOption(TripStatus.ACTIVE).label,
      count: tripCountsByStatus.activeCount,
      value: TripStatus.ACTIVE,
    },
    {
      label: TripStatus.getStatusOption(TripStatus.PENDING).label,
      count: tripCountsByStatus.pendingCount,
      value: TripStatus.PENDING,
    },
    {
      label: TripStatus.getStatusOption(TripStatus.PLANNED).label,
      count: tripCountsByStatus.plannedCount,
      value: TripStatus.PLANNED,
    },
    {
      label: TripStatus.getStatusOption(TripStatus.IN_TRANSIT).label,
      count: tripCountsByStatus.inTransitCount,
      value: TripStatus.IN_TRANSIT,
    },
    {
      label: TripStatus.getStatusOption(TripStatus.COMPLETED).label,
      count: tripCountsByStatus.completedCount,
      value: TripStatus.COMPLETED,
    },
    {
      label: TripStatus.getStatusOption(TripStatus.CANCELLED).label,
      count: tripCountsByStatus.cancelledCount,
      value: TripStatus.CANCELLED,
    },
  ];
};

const TabHeader = ({organization, activeTabValue, navigator}: any) => {
  const tabDefinitions = getTabDefinitions({organization});
  const activeTabIndex = getTabIndex({tabDefinitions, tabValue: activeTabValue});
  return (
    <TabsContainer>
      <Tabs
        tabs={tabDefinitions}
        handlePressTab={({value}) => handleTabClicked({tabValue: value, navigator})}
        activeTabIndex={activeTabIndex}
        TabComponent={Tabs.SecondaryTab}
      />
    </TabsContainer>
  );
};

const LongDistanceDispatchTripsTabContent = () => {
  const {params, navigator} = useNavigationDOM();
  const actionsPopover = usePopover();
  const {
    data,
    loading,
    refetch: refetchTabData,
  } = useQuery(LongDistanceDispatchTripsTabContent.query, {
    fetchPolicy: 'cache-and-network',
  });
  const createTripWithShipmentsModal = useModal({
    name: 'Create Trip Modal',
    enableTracking: true,
  });
  const handleChangeSearch = useDebouncedCallback((text) => {
    navigator.replace(`/dispatch/long-distance/trips/${params.subTab}?page=1&query=${text}`);
  }, 500);

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => (
        <Container>
          <Space height={16} />
          <FiltersContainers>
            <LeftContainer>
              <SearchBar
                placeholder='Search by trip details'
                onChangeText={handleChangeSearch}
                style={{width: '348px'}}
                defaultValue={params.query}
              />
              <Space width={8} />
              <TripFiltersButton style={{height: 36}} />
            </LeftContainer>
            <RightContainer>
              <Popover.Content innerRef={actionsPopover.ref}>
                <ActionsButton onPress={actionsPopover.handleToggle}>
                  <ActionsButtonText>Actions</ActionsButtonText>
                  <Space width={9} />
                  <Icon color={colors.white} source={Icon.ChevronDown} size={10} />
                </ActionsButton>
              </Popover.Content>
              <TripsTabActionMenu
                popover={actionsPopover}
                createTripWithShipmentsModal={createTripWithShipmentsModal}
              />
            </RightContainer>
          </FiltersContainers>
          <Space height={16} />
          <TabHeader
            organization={data.viewer.viewingOrganization}
            activeTabValue={params.subTab}
            navigator={navigator}
          />
          <LongDistanceDispatchTripsTable
            createTripWithShipmentsModal={createTripWithShipmentsModal}
            refetchTabData={refetchTabData}
          />
        </Container>
      )}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
LongDistanceDispatchTripsTabContent.query = gql`
  query LongDistanceDispatchTripsTabContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        tripCountsByStatus{
          activeCount
          pendingCount
          plannedCount
          inTransitCount
          completedCount
          cancelledCount
        }
      }
    }
  }
`;

export default LongDistanceDispatchTripsTabContent;
