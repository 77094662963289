import {loadPayengine} from 'payengine';
import React from 'react';

import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

import Button from '@shared/design/components/Button';
import Panel from '@shared/design/components/Panel';
import {PayEngineConfig} from '@shared/modules/Payment/components/PayEngineConfig';
import useCreatePayEngineMerchantMutation from '@shared/modules/Payment/hooks/useCreatePayEngineMerchantMutation';
import useEnablePayEngineGatewayMutation from '@shared/modules/Payment/hooks/useEnablePayEngineGatewayMutation';
import AuthorizeDotNetLogo from 'modules/Organization/Settings/Company/assets/AuthorizeDotNetLogo';

const Image = Styled.Image`
  width: 128px;
  height: 38px;
`;

const PanelRow = Styled.View`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center
`;

const PanelRowElement = Styled.View`
`;

const TextContainer = Styled.View`
  width: 450px;
`;

const MessageText = Styled.Text`
  ${Typography.Body3}
  margin-top: 10px;
  color: ${({
    // @ts-expect-error TS(2339): Property 'vars' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    vars,
  }) => (vars.isError ? colors.red.warning : colors.green.status)};
`;

// TODO(atsu): Put this into somewhere reusable, we do this a lot for payments-related mutations
const throwIfError = (response: any) => {
  if (response.errors) {
    throw response.errors;
  }
  if (response.data?.response?.errors) {
    throw response.data.response.errors;
  }
  if (!response.data?.response) {
    throw new Error('Received empty GraphQL response');
  }
};

const AuthorizeDotNetPanel = ({organization}: any) => {
  const [payEngine, setPayEngine] = useState();
  useEffect(() => {
    // Since `sdk` is a function, we need to use the callback form of setState
    loadPayengine(PayEngineConfig.get(organization.mode)).then((sdk) => setPayEngine((_) => sdk));
  }, [organization.mode]);

  const [message, setMessage] = useState({isError: false, text: null});
  const [isConnected, setIsConnected] = useState(
    organization.payengineMerchant?.gateway === 'authorize.net',
  );
  const [gatewayConfiguration, setGatewayConfiguration] = useState(
    JSON.parse(organization.payengineMerchant?.gatewayConfiguration ?? '{}'),
  );
  const [merchantIdState, setMerchantIdState] = useState(
    organization.payengineMerchant?.merchantId,
  );

  const createMerchantMutation = useCreatePayEngineMerchantMutation({
    organizationId: organization.id,
  });
  const mutation = useEnablePayEngineGatewayMutation({});

  const onPress = async () => {
    try {
      let merchantId = merchantIdState;
      if (!merchantId) {
        const createMerchantResponse = await createMerchantMutation.handleSubmit();
        throwIfError(createMerchantResponse);
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        merchantId = createMerchantResponse.data.response.payengineMerchant.merchantId;
        setMerchantIdState(merchantId);
        console.log(`Created PayEngine merchant ${merchantId} for organization ${organization.id}`);
      }

      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const authResponse = await payEngine.connectWithAuthorizeNet(merchantId);
      console.log(`Received auth response for Authorize.Net: `, authResponse);

      const response = await mutation.handleSubmit({
        variables: {
          form: {
            organizationId: organization.id,
            gatewayUuid: authResponse.id,
          },
        },
      });
      throwIfError(response);

      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'null'.
      setMessage({isError: false, text: 'Successfully connected your account with Supermove.'});
      setIsConnected(true);
      setGatewayConfiguration(authResponse.configuration);
    } catch (exception) {
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'null'.
      setMessage({isError: true, text: 'Something went wrong.'});
      console.error(`Error connecting Authorize.Net account:`, exception);
    }
  };

  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header>
        <Panel.HeaderText>Authorize.Net</Panel.HeaderText>
      </Panel.Header>
      <Panel.Body>
        <PanelRow>
          <PanelRowElement>
            <Image resizeMode={'contain'} source={{uri: AuthorizeDotNetLogo}} />
          </PanelRowElement>
          {!!message.text && (
            <PanelRowElement>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <MessageText vars={{isError: message.isError}}>{message.text}</MessageText>
            </PanelRowElement>
          )}
        </PanelRow>

        <Space height={16} />

        {isConnected ? (
          <PanelRow>
            <PanelRowElement>
              <Panel.LabelText>Account ID</Panel.LabelText>
              <Space height={8} />
              {/* TODO(atsu): This is just a placeholder, PayEngine team is working on adding the account name */}
              <Panel.Text>{gatewayConfiguration.access_token}</Panel.Text>
            </PanelRowElement>
            <PanelRowElement>
              <Button onPress={onPress} text={'Update Account'} />
            </PanelRowElement>
          </PanelRow>
        ) : (
          <PanelRow>
            <PanelRowElement>
              <TextContainer>
                <Panel.Text>
                  Simplify payment processing by connecting your Authorize.Net account with
                  Supermove.
                </Panel.Text>
              </TextContainer>
            </PanelRowElement>
            <PanelRowElement>
              <Button onPress={onPress} text={'Connect to Authorize.Net'} />
            </PanelRowElement>
          </PanelRow>
        )}
      </Panel.Body>
    </Panel>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AuthorizeDotNetPanel.fragment = gql`
  fragment AuthorizeDotNetPanel on Organization {
    id
    mode
    payengineMerchant {
      id
      merchantId
      gateway
      gatewayConfiguration
    }
  }
`;

export default AuthorizeDotNetPanel;
