// Libraries
import {loadPayengine} from 'payengine';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useCallback, useQuery} from '@supermove/hooks';
import {useNavigationDOM} from '@supermove/navigation';
import {colors, Typography} from '@supermove/styles';

// App
import {PayEngineConfig} from '@shared/modules/Payment/components/PayEngineConfig';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';

const PageContainer = Styled.View`
  flex: 1;
`;

const HeaderContainer = Styled.View`
  padding: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
  flex-direction: row;
  align-items: center;
  height: 84px;
  width: 100%;
`;

const BackButton = Styled.ButtonV2`
`;

const HeaderText = Styled.Text`
  ${Typography.Heading1};
`;

const PaymentsOnboardingContent = ({payengineMerchant, mode}: any) => {
  useEffect(() => {
    loadPayengine(PayEngineConfig.get(mode));
  }, [mode]);

  const {navigator} = useNavigationDOM();
  const onSubmit = useCallback(
    (node) => {
      if (node === null) {
        return;
      }

      node.addEventListener('ready', () => {
        // See API docs on merchant details for full list of fields:
        // https://docs.payengine.co/payengine-api-reference/onboarding/update-a-merchant
        node.populateData({
          business_type: {
            mcc_sic: '4214', // Merchant Category Code
          },
          business_details: {
            accepted_transaction_types: ['CC_DC'], // Default to only credit cards, not ACH
          },
        });
      });

      // Event fires when the embedded form is submitted. See:
      //   https://docs.payengine.co/payengine-developer-docs/merchant-onboarding/embedded-onboarding-ui#3.-events
      node.addEventListener('submitted', (submittedEvent: any) => {
        navigator.push('/settings/company/payments');
      });
    },
    [navigator],
  );

  // <pay-engine/> is a Web Component provided by PayEngine. See:
  //   https://docs.payengine.co/payengine-developer-docs/getting-started-1/5.-frontend-frameworks
  return (
    <ScrollView style={{flex: 1, backgroundColor: colors.gray.background}}>
      {/* @ts-expect-error TS(2339): Property 'pay-engine' does not exist on type 'JSX.... Remove this comment to see the full error message */}
      <pay-engine
        ref={onSubmit}
        type={'boarding'}
        merchant-id={payengineMerchant.merchantId}
        hash={payengineMerchant.hmac}
      />
    </ScrollView>
  );
};

const OrganizationSettingsCompanyPaymentsOnboardingPage = () => {
  const {navigator} = useNavigationDOM();
  const {loading, data} = useQuery(OrganizationSettingsCompanyPaymentsOnboardingPage.query, {
    // Onboarding button on /settings/company/payments _may_ create a merchant, so
    // 'network-only' is used to avoid using empty cached values
    fetchPolicy: 'network-only',
  });

  return (
    <SidebarPageV2 selected={'settings'}>
      <PageContainer>
        <HeaderContainer>
          <BackButton onPress={() => navigator.push('/settings/company/payments')}>
            <Icon source={Icon.ChevronLeft} color={colors.black} size={24} />
          </BackButton>
          <Space width={16} />
          <HeaderText>Supermove Payments Onboarding</HeaderText>
        </HeaderContainer>
        <Loading loading={loading}>
          {() => (
            <PaymentsOnboardingContent
              payengineMerchant={data.viewer.viewingOrganization.payengineMerchant}
              mode={data.viewer.viewingOrganization.mode}
            />
          )}
        </Loading>
      </PageContainer>
    </SidebarPageV2>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyPaymentsOnboardingPage.query = gql`
  query OrganizationSettingsCompanyPaymentsOnboardingPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        mode
        payengineMerchant {
          id
          merchantId
          hmac
        }
      }
    }
  } 
`;

export default OrganizationSettingsCompanyPaymentsOnboardingPage;
