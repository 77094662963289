// Libararies
import React from 'react';

// Supermove
import {Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal, usePopover, useResponsive, useSheet} from '@supermove/hooks';

// App
import ActionMenu from '@shared/design/components/ActionMenu';
import DocumentTemplateViewer from 'modules/Job/V2/Move/components/DocumentTemplateViewer';
import GenerateDocumentsForJobModal from 'modules/Job/V2/Move/components/GenerateDocumentsForJobModal';

type OwnProps = {
  project: any;
  refetch?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof AddDocumentsPopover.defaultProps;

// @ts-expect-error TS(7022): 'AddDocumentsPopover' implicitly has type 'any' be... Remove this comment to see the full error message
const AddDocumentsPopover = ({project, refetch, children}: Props) => {
  const responsive = useResponsive();
  const generateCustomDocumentsDrawer = useDrawer({name: 'Generate Custom Documents Drawer'});
  const generateCustomDocumentsSheet = useSheet({name: 'Generate Custom Documents Sheet'});
  const generateCrewDocumentsModal = useModal({name: 'Generate Crew Documents Modal'});
  const overlayHandler = responsive.desktop
    ? generateCustomDocumentsDrawer
    : generateCustomDocumentsSheet;
  const addDocumentsPopover = usePopover({name: 'Add Documents Popover'});
  return (
    <React.Fragment>
      <Popover.Content innerRef={addDocumentsPopover.ref}>
        {children({handleToggle: addDocumentsPopover.handleToggle})}
      </Popover.Content>
      <Popover
        placement={Popover.Positions.BottomEnd}
        isOpen={addDocumentsPopover.isOpen}
        handleOpen={addDocumentsPopover.handleOpen}
        handleClose={addDocumentsPopover.handleClose}
        reference={addDocumentsPopover.ref}
        offset={[0, 4]}
      >
        <ActionMenu
          key={addDocumentsPopover.key}
          handleClose={addDocumentsPopover.handleClose}
          actions={[
            {
              text: 'Add new document',
              onPress: overlayHandler.handleOpen,
            },
            {
              text: 'Generate crew documents',
              onPress: generateCrewDocumentsModal.handleOpen,
            },
          ]}
          width={200}
        />
      </Popover>
      <GenerateDocumentsForJobModal
        key={generateCrewDocumentsModal.key}
        isOpen={generateCrewDocumentsModal.isOpen}
        handleClose={generateCrewDocumentsModal.handleClose}
        project={project}
        onSuccess={() => {
          generateCrewDocumentsModal.handleClose();
          refetch();
        }}
      />
      <DocumentTemplateViewer
        key={overlayHandler.key}
        documentTemplateViewer={overlayHandler}
        project={project}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

AddDocumentsPopover.defaultProps = {
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AddDocumentsPopover.fragment = gql`
  ${DocumentTemplateViewer.fragment}
  ${GenerateDocumentsForJobModal.fragment}
  fragment AddDocumentsPopover on Project {
    id
    ...DocumentTemplateViewer
    ...GenerateDocumentsForJobModal
  }
`;

export default AddDocumentsPopover;
