// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, usePopover} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DayForm from '@shared/modules/Scheduling/forms/DayForm';
import useUpdateDayMutation from '@shared/modules/Scheduling/hooks/useUpdateDayMutation';
import AddDayNotesPopover from 'modules/App/Day/components/AddDayNotesPopover';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const DayNotesPressableContainer = Styled.ButtonV2`
  width: 100%;
`;

const DayNotesText = Styled.Text`
  ${Typography.Body5}
`;

const BookingCalendarWeekDayNotes = ({calendarDay, refetch}: any) => {
  const {ref, isHovered} = useHover();
  const addDayNotesPopover = usePopover();
  const dayForm = DayForm.edit(calendarDay.day);
  const {form, handleSubmit, submitting} = useUpdateDayMutation({
    dayForm,
    onSuccess: () => {
      addDayNotesPopover.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <React.Fragment>
      <DayNotesPressableContainer ref={ref} onPress={addDayNotesPopover.handleToggle}>
        <Popover.Content innerRef={addDayNotesPopover.ref}>
          <Row style={{paddingHorizontal: 10, alignItems: 'flex-start'}}>
            <Icon source={Icon.CalendarDay} size={10} color={colors.gray.primary} />
            <Space width={8} />
            <DayNotesText
              style={
                isHovered || addDayNotesPopover.isOpen ? {textDecorationLine: 'underline'} : null
              }
              numberOfLines={4}
            >
              {calendarDay.day.notes || 'Add day notes'}
            </DayNotesText>
          </Row>
        </Popover.Content>
      </DayNotesPressableContainer>
      <AddDayNotesPopover
        key={addDayNotesPopover.isOpen}
        popover={addDayNotesPopover}
        width={400}
        form={form}
        handleSubmit={handleSubmit}
        submitting={submitting}
        organization={calendarDay.organization}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendarWeekDayNotes.fragment = gql`
  ${DayForm.edit.fragment}
  ${AddDayNotesPopover.fragment}

  fragment BookingCalendarWeekDayNotes on CalendarDayV4 {
    day {
      id
      notes
      organization {
        id
        ...AddDayNotesPopover
      }
      ...DayForm_edit
    }
  }
`;

export default BookingCalendarWeekDayNotes;
