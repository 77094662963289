// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Project} from '@supermove/models';

// App

import SuccessModal from '@shared/design/components/Modal/SmallModal/SuccessModal';

const CreateProjectSuccessModal = ({
  projectUuid,
  isOpen,
  handleClose,
  handleViewProject,
  title,
  backButtonText,
}: any) => {
  const {data} = useQuery(CreateProjectSuccessModal.query, {
    fetchPolicy: 'cache-and-network',
    skip: !projectUuid,
    variables: {
      projectUuid,
    },
  });

  if (!data) {
    return null;
  }

  return (
    <SuccessModal
      title={title}
      subtitle={`You've successfully created ${Project.getName(data.project)}`}
      isOpen={isOpen}
      handlePrimaryAction={handleViewProject}
      handleSecondaryAction={handleClose}
      primaryActionText={'View Project'}
      secondaryActionText={backButtonText || 'Go Back'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateProjectSuccessModal.query = gql`
  ${Project.getName.fragment}
  query CreateProjectSuccessModal($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...Project_getName
    }
  }
`;

export default CreateProjectSuccessModal;
