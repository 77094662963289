// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Space} from '@supermove/components';
import TextInput from '@supermove/components/src/Styled/TextInput';
import {gql} from '@supermove/graphql';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';

const DocumentFieldInput = ({projectType, form, index}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Document'}
        index={index}
        name={'confirmationStepForm.value'}
        component={DropdownInput}
        isRequired
        input={{
          options: _.map(
            _.orderBy(
              _.filter(
                projectType.organization.documentTemplates,
                (documentTemplate) => documentTemplate.activeOrMostRecentDocumentTemplateVersion,
              ),
              (documentTemplate) => _.toLower(documentTemplate.name),
              ['asc'],
            ),
            (documentTemplate) => {
              return {
                label: documentTemplate.name,
                value: documentTemplate.identifier,
              };
            },
          ),
          placeholder: 'Select document',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          onChangeValue: (value: any, documentTemplate: any) => {
            form.setFieldValue('confirmationStepForm.value', value);
            form.setFieldValue('confirmationStepForm.valueTwo', documentTemplate.label);
          },
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const CancellationPolicyFieldInputs = ({form, index}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={CurrencyInput}
        label={'Cancellation Fee'}
        index={index}
        name={'confirmationStepForm.value'}
        isRequired
        input={{
          autoFocus: true,
          placeholder: 'Enter cancellation fee',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          component: TextInput,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Cancellation Window'}
        index={index + 1}
        name={'confirmationStepForm.valueTwo'}
        isRequired
        input={{
          placeholder: 'Enter cancellation window in days',
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const MakeDepositFieldInput = ({form, index}: any) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={CurrencyInput}
        label={'Default Deposit'}
        index={index}
        name={'confirmationStepForm.value'}
        input={{
          autoFocus: true,
          placeholder: 'Enter default deposit',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
          component: TextInput,
        }}
        isRequired
      />
      <Space height={16} />
    </React.Fragment>
  );
};

const AdditionalFields = ({form, projectType, index}: any) => {
  const confirmationStepKind = form.values.confirmationStepForm.kind;
  switch (confirmationStepKind) {
    case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
      return <DocumentFieldInput form={form} projectType={projectType} index={index} />;
    case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
      return <CancellationPolicyFieldInputs form={form} index={1} />;
    case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
      return <MakeDepositFieldInput form={form} index={1} />;
    default:
      return null;
  }
};

const getDropdownOptions = ({hasPaymentProcessing}: any) => {
  if (hasPaymentProcessing) {
    return ConfirmationStepKind.getDropdownOptions({
      kinds: ConfirmationStepKind.CONFIRMATION_STEPS,
    });
  } else
    return ConfirmationStepKind.getDropdownOptions({
      kinds: [ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT],
    });
};

const ConfirmationStepModalFields = ({form, projectType}: any) => {
  const hasPaymentProcessing = projectType.organization?.payengineMerchant?.isProcessingEnabled;
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        label={'Step'}
        index={0}
        name={'confirmationStepForm.kind'}
        component={DropdownInput}
        isRequired
        input={{
          options: getDropdownOptions({hasPaymentProcessing}),
          placeholder: 'Select step',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
          onChangeValue: (value: any, confirmationStep: any) => {
            form.setFieldValue('confirmationStepForm.kind', value);
            if (
              _.includes(
                [
                  ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT,
                  ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD,
                ],
                value,
              )
            ) {
              form.setFieldValue('confirmationStepForm.value', '0');
            } else {
              form.setFieldValue('confirmationStepForm.value', '');
            }
            form.setFieldValue('confirmationStepForm.valueTwo', '');
            form.setFieldValue('confirmationStepForm.name', confirmationStep.label);
          },
        }}
      />
      <Space height={16} />
      <AdditionalFields form={form} projectType={projectType} index={1} />
      <Checkbox
        isChecked={form.values.confirmationStepForm.showByDefault}
        handleToggle={(isChecked) => {
          form.setFieldValue('confirmationStepForm.showByDefault', isChecked);
        }}
        label={'Include by default'}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        label={'Button Text'}
        index={3}
        name={'confirmationStepForm.actionText'}
        isRequired
        input={{
          placeholder: 'Select step',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={16} />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationStepModalFields.fragment = gql`
  fragment ConfirmationStepModalFields on ProjectType {
    id
    organization {
      id
      payengineMerchant {
        id
        isProcessingEnabled
      }
      documentTemplates: documentTemplatesByCategory(categories: ["JOB"]) {
        id
        name
        identifier
        activeOrMostRecentDocumentTemplateVersion {
          id
        }
      }
    }
  }
`;

export default ConfirmationStepModalFields;
