// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  CapacityCalendarDayModel,
  CapacityCalendarModel,
  CapacityCalendarSlotMetricModel,
} from '@supermove/models';

// App
import SkeletonLoader from '@shared/design/components/SkeletonLoader';
import CapacityCalendarSettingHelpers from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSettingHelpers';
import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';
import DaySlotMetric from 'modules/Calendar/Capacity/components/DaySlotMetric';

const TopLeftMetricContainer = Styled.View`
  position: absolute;
  top: 8;
  left: 12;
`;

const SecondaryMetricContainer = Styled.View`
  position: absolute;
  top: 30;
  right: 0;
  padding-right: 8px;
`;

const BottomMetricContainer = Styled.View`
  position: absolute;
  bottom: 8;
  left: 12;
  width: 90%;
`;

const MetricsContainer = Styled.View<{backgroundColor: string}>`
  flex: 1;
  height: 100%;
  background-color: ${({backgroundColor}) => backgroundColor};
`;

const TopLeftMetric = ({
  slotMetric,
  capacityCalendarDay,
  capacityCalendar,
}: {
  slotMetric?: CapacityCalendarSlotMetricModel;
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
}) => {
  return (
    <TopLeftMetricContainer>
      <DaySlotMetric
        metric={slotMetric?.metric}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
      />
    </TopLeftMetricContainer>
  );
};

const SecondaryMetric = ({
  slotMetric,
  capacityCalendarDay,
  capacityCalendar,
}: {
  slotMetric?: CapacityCalendarSlotMetricModel;
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
}) => {
  return (
    <SecondaryMetricContainer>
      <DaySlotMetric
        metric={slotMetric?.metric}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
        isSecondaryMetric
      />
    </SecondaryMetricContainer>
  );
};

const BottomMetric = ({
  capacityCalendarDay,
  capacityCalendar,
  shouldHideDayNotes,
}: {
  capacityCalendarDay: CapacityCalendarDayModel;
  capacityCalendar: CapacityCalendarModel;
  shouldHideDayNotes?: boolean;
}) => {
  return (
    <BottomMetricContainer style={{bottom: 8}}>
      <DaySlotMetric
        metric={CapacityCalendarSlotMetricKind.DAY_NOTES}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
        shouldHideDayNotes={shouldHideDayNotes}
      />
    </BottomMetricContainer>
  );
};

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <TopLeftMetricContainer>
        <SkeletonLoader height={26} width={50} />
        <Space height={6} />
        <SkeletonLoader height={15} width={90} />
      </TopLeftMetricContainer>
      <BottomMetricContainer>
        <SkeletonLoader height={15} isFullWidth />
      </BottomMetricContainer>
    </React.Fragment>
  );
};

const CapacityCalendarDaySlotMetrics = ({
  capacityCalendar,
  cellDate,
  shouldHideDayNotes,
  isLoading,
}: {
  capacityCalendar: CapacityCalendarModel;
  cellDate: string;
  shouldHideDayNotes?: boolean;
  isLoading?: boolean;
}) => {
  if (isLoading) {
    return <LoadingComponent />;
  }

  const {capacityCalendarDays} = capacityCalendar;
  // capacityCalendarDay is guaranteed because the date picker is limited
  // to the days showing on the capacity calendar
  const capacityCalendarDay = capacityCalendarDays.find((day) => day.date === cellDate)!;

  return (
    <MetricsContainer
      backgroundColor={
        CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor({
          capacityCalendar,
          capacityCalendarDay,
        }).backgroundColor
      }
    >
      <TopLeftMetric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
        slotMetric={CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex(capacityCalendar, {
          slotPriorityIndex: 1,
        })}
      />
      <SecondaryMetric
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
        slotMetric={CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex(capacityCalendar, {
          slotPriorityIndex: 2,
        })}
      />
      <BottomMetric
        shouldHideDayNotes={shouldHideDayNotes}
        capacityCalendarDay={capacityCalendarDay}
        capacityCalendar={capacityCalendar}
      />
    </MetricsContainer>
  );
};

CapacityCalendarDaySlotMetrics.SkeletonLoader = LoadingComponent;

// --------------------------------------------------
// Data
// --------------------------------------------------
CapacityCalendarDaySlotMetrics.fragment = gql`
  ${CapacityCalendarSettingHelpers.getCapacityCalendarBackgroundColor.fragment}
  ${CapacityCalendarSettingHelpers.getMetricBySlotPriorityIndex.fragment}
  ${DaySlotMetric.fragment}

  fragment CapacityCalendarDaySlotMetrics on CapacityCalendar {
    capacityCalendarDays {
      date
    }
    ...CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor
    ...CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex
    ...DaySlotMetric
  }
`;

export default CapacityCalendarDaySlotMetrics;
