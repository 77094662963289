// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import CartonCategoriesList from '@shared/modules/Inventory/Summary/components/CartonCategoriesList';
import InventoryRoomsList from '@shared/modules/Inventory/Summary/components/InventoryRoomsList';
import InventorySection from '@shared/modules/Inventory/Summary/components/InventorySection';
import InventoryHeader from 'modules/Job/Inventory/components/InventoryHeader';
import InventorySidebar from 'modules/Job/Inventory/components/InventorySidebar';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  flex: 1;
  align-self: center;
  max-width: 1120px;
  width: 100%;
  padding-horizontal: 10px;
`;

const PageBody = Styled.View`
  flex: 1;
  flex-direction: row;
`;

const DashedLine = Styled.View`
  border-top-width: 1px;
  border-color: ${colors.gray.border};
  border-style: dashed;
  flex: 1;
`;

const SummaryContent = ({inventory}: any) => {
  return (
    <ScrollView style={{flex: 1}}>
      <Space height={16} />
      <InventorySection icon={Icon.BoxOpen} title={'Carton summary'}>
        <CartonCategoriesList inventory={inventory} showWeight showVolume />
      </InventorySection>
      <Space height={16} />
      <DashedLine />
      <Space height={16} />
      <InventorySection icon={Icon.Archive} title={'Room summary (Cartons included)'}>
        <InventoryRoomsList inventory={inventory} />
      </InventorySection>
      <Space height={32} />
    </ScrollView>
  );
};

const SummaryInventoryJobPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(SummaryInventoryJobPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid: params.uuid,
      jobUuid: params.jobUuid,
    },
  });

  return (
    <Container>
      <Loading loading={loading}>
        {() => (
          <Content>
            <InventoryHeader inventory={data.inventory} title={Job.getFullName(data.job)} />
            <PageBody>
              <InventorySidebar inventory={data.inventory} />
              <Space width={20} />
              <SummaryContent inventory={data.inventory} />
            </PageBody>
          </Content>
        )}
      </Loading>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SummaryInventoryJobPage.query = gql`
  ${InventoryHeader.fragment}
  ${InventorySidebar.fragment}
  ${Job.getFullName.fragment}
  ${CartonCategoriesList.fragment}
  ${InventoryRoomsList.fragment}

  query SummaryInventoryJobPage($uuid: String!, $jobUuid: String!) {
    ${gql.query}

    inventory(uuid: $uuid) {
      id
      ...InventoryHeader
      ...InventorySidebar
      ...CartonCategoriesList
      ...InventoryRoomsList
    }

    job(uuid: $jobUuid) {
      id
      ...Job_getFullName
    }
  }
`;

export default SummaryInventoryJobPage;
