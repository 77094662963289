/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useModal, useHover, usePopover} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import SendJobDetailsToSpecificUsersModal from 'modules/Day/Jobs/components/SendJobDetailsToSpecificUsersModal';
import EditJobDispatchInfoPopover from 'modules/Dispatch/Calendar/Day/components/EditJobDispatchInfoPopover';
import JobDispatchNotesPopover from 'modules/Dispatch/Calendar/Day/components/JobDispatchNotesPopover';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-right: -6px;
`;

const NotesButtonContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const NotesButtonTouchable = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const NotesText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const NotesIconBackground = Styled.View`
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${(props) => ((props as any).isHighlighted ? colors.hover : 'transparent')};
  align-items: center;
  justify-content: center;
`;

const IconContainer = Styled.Touchable`
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${(props) => ((props as any).isHighlighted ? colors.hover : 'transparent')};
  align-items: center;
  justify-content: center;
`;

const CommentIconContainer = Styled.Touchable`
  width: 28px;
  height: 28px;
  border-radius: 14px;
  background-color: ${(props) => ((props as any).isHighlighted ? colors.hover : 'transparent')};
  align-items: center;
  justify-content: center;
  padding-top: 2px;
`;

const MenuPopoverContainer = Styled.View`
  width: 180px;
  border-radius: 6px;
  position: absolute;
  margin-left: -9px;
  margin-top: -5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
`;

const MenuItemTouchable = Styled.Touchable`
  background-color: ${(props) => ((props as any).isHighlighted ? colors.hover : 'transparent')};
`;

const MenuItemText = Styled.H7`
  padding-vertical: 4px;
  padding-horizontal: 12px;
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const NotesButton = ({job, dispatchNotesPopover, setIsClosable, refetch}: any) => {
  const {isHovered, ref} = useHover();
  const handlePress = () => {
    setIsClosable(false);
    dispatchNotesPopover.handleToggle();
  };

  return (
    <NotesButtonContainer>
      <Popover.Content innerRef={dispatchNotesPopover.ref}>
        <NotesButtonTouchable ref={ref} onPress={handlePress}>
          <NotesText>Notes</NotesText>
          <Space width={4} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <NotesIconBackground isHighlighted={isHovered || dispatchNotesPopover.isOpen}>
            <Icon source={Icon.CaretDown} color={colors.gray.primary} size={14} />
          </NotesIconBackground>
        </NotesButtonTouchable>
      </Popover.Content>
      <JobDispatchNotesPopover
        job={job}
        popover={dispatchNotesPopover}
        setIsClosable={setIsClosable}
        placement={Popover.Positions.BottomEnd}
        refetch={refetch}
      />
    </NotesButtonContainer>
  );
};

const ActionMenuItem = ({onPress, children}: any) => {
  const {isHovered, ref} = useHover();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <MenuItemTouchable onPress={onPress} isHighlighted={isHovered} ref={ref} activeOpacity={0.8}>
        <MenuItemText>{children}</MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const PopoverActionsMenuPopover = ({
  job,
  isOpen,
  handleOpen,
  handleClose,
  handleCloseAll,
  popoverRef,
  jobCancelModal,
  setIsClosable,
}: any) => {
  const {navigator} = useNavigationDOM();
  const handleGoToJobEdit = () => navigator.push(`/jobs/${job.uuid}/edit`);
  const handleOpenJobDeleteModal = () => {
    jobCancelModal.handleOpen();
    handleCloseAll();
  };

  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={isOpen}
      handleOpen={handleOpen}
      handleClose={handleClose}
      reference={popoverRef}
      onComponentDidHide={() => setIsClosable(true)}
      offset={[0, 4]}
    >
      <MenuPopoverContainer>
        <Space height={12} />
        <ActionMenuItem onPress={handleGoToJobEdit}>Edit Job</ActionMenuItem>
        <ActionMenuItem onPress={handleOpenJobDeleteModal}>Cancel Job</ActionMenuItem>
        <Space height={10} />
      </MenuPopoverContainer>
    </Popover>
  );
};

const ActionIcon = ({icon, onPress, tooltip, color, isOpen}: any) => {
  const {isHovered, ref} = useHover();
  if (tooltip) {
    return (
      <TextTooltip text={tooltip} isEnabledMobileToast={false}>
        <IconContainer
          ref={ref}
          // @ts-expect-error TS(2769): No overload matches this call.
          isHighlighted={isHovered || isOpen}
          onPress={onPress}
          activeOpacity={0.8}
        >
          <Icon source={icon} size={15} color={color} />
        </IconContainer>
      </TextTooltip>
    );
  }
  return (
    <IconContainer
      ref={ref}
      // @ts-expect-error TS(2769): No overload matches this call.
      isHighlighted={isHovered || isOpen}
      onPress={onPress}
      activeOpacity={0.8}
    >
      <Icon source={icon} size={15} color={color} />
    </IconContainer>
  );
};

const CommentIcon = ({onPress}: any) => {
  const {isHovered, ref} = useHover();
  return (
    <TextTooltip text={'Send text to movers'} isEnabledMobileToast={false}>
      <CommentIconContainer
        ref={ref}
        // @ts-expect-error TS(2769): No overload matches this call.
        isHighlighted={isHovered}
        onPress={onPress}
        activeOpacity={0.8}
      >
        <Icon source={Icon.CommentAlt} size={15} color={colors.gray.secondary} />
      </CommentIconContainer>
    </TextTooltip>
  );
};

const JobDispatchPopoverActions = ({
  job,
  crewId,
  handleClose,
  jobCancelModal,
  setIsClosable,
  refetch,
}: any) => {
  const {navigator} = useNavigationDOM();
  const goToJobPage = () => navigator.push(`/jobs/${job.uuid}`);
  const popoverActionsMenuPopover = usePopover();
  const dispatchNotesPopover = usePopover();
  const editJobDispatchInfoPopover = usePopover();
  const sendTextMessageModal = useModal();

  return (
    <Container>
      <NotesButton
        job={job}
        dispatchNotesPopover={dispatchNotesPopover}
        refetch={refetch}
        setIsClosable={setIsClosable}
      />
      <Popover.Content innerRef={editJobDispatchInfoPopover.ref}>
        <ActionIcon
          onPress={() => {
            setIsClosable(false);
            setTimeout(() => editJobDispatchInfoPopover.handleToggle(), 0);
          }}
          icon={Icon.Edit}
          tooltip={'Edit dispatch details'}
          color={colors.gray.secondary}
          isOpen={editJobDispatchInfoPopover.isOpen}
        />
      </Popover.Content>
      <Space width={4} />
      <CommentIcon
        onPress={() => {
          setIsClosable(false);
          sendTextMessageModal.handleOpen();
        }}
      />
      <Space width={4} />
      <ActionIcon
        onPress={goToJobPage}
        icon={Icon.ExternalLinkAlt}
        tooltip={'Go to job page'}
        color={colors.gray.secondary}
      />
      <Space width={4} />
      <Popover.Content innerRef={popoverActionsMenuPopover.ref}>
        <ActionIcon
          icon={Icon.EllipsisV}
          onPress={() => {
            setIsClosable(false);
            setTimeout(() => popoverActionsMenuPopover.handleToggle(), 0);
          }}
          color={colors.gray.secondary}
          isOpen={popoverActionsMenuPopover.isOpen}
        />
      </Popover.Content>
      <Space width={4} />
      <EditJobDispatchInfoPopover
        key={editJobDispatchInfoPopover.isOpen}
        job={job}
        crewId={crewId}
        popover={editJobDispatchInfoPopover}
        setIsClosable={setIsClosable}
        refetch={refetch}
      />
      <PopoverActionsMenuPopover
        job={job}
        isOpen={popoverActionsMenuPopover.isOpen}
        handleOpen={popoverActionsMenuPopover.handleOpen}
        handleClose={popoverActionsMenuPopover.handleClose}
        handleCloseAll={handleClose}
        popoverRef={popoverActionsMenuPopover.ref}
        jobCancelModal={jobCancelModal}
        setIsClosable={setIsClosable}
      />
      <SendJobDetailsToSpecificUsersModal
        job={job}
        isOpen={sendTextMessageModal.isOpen}
        onClose={() => {
          refetch();
          setIsClosable(true);
          sendTextMessageModal.handleClose();
        }}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobDispatchPopoverActions.fragment = gql`
  ${EditJobDispatchInfoPopover.fragment}
  ${JobDispatchNotesPopover.fragment}
  ${SendJobDetailsToSpecificUsersModal.fragment}

  fragment JobDispatchPopoverActions on Job {
    id
    uuid
    ...EditJobDispatchInfoPopover
    ...JobDispatchNotesPopover
    ...SendJobDetailsToSpecificUsersModal
  }
`;

export default JobDispatchPopoverActions;
