// Libraries
import React from 'react';

// Supermove
import {DateInput, Icon, Loading, Modal, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import CompleteEstimatedRangeJobForm from '@shared/modules/Job/forms/CompleteEstimatedRangeJobForm';
import useCompleteDateRangeJobMutation from '@shared/modules/Job/hooks/useCompleteDateRangeJobMutation';
import Field from 'modules/App/components/Field';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const ScreenContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: ${(props) => ((props as any).mobile ? 320 : 400)}px;
  background-color: ${colors.white};
  border-radius: 16px;
  padding: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
  color: ${colors.gray.primary};
`;

const LabelText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.tertiary};
`;

const FieldContainer = Styled.View`
  width: 352px;
`;

const BodyText = Styled.Text`
  ${Typography.Body2}
  color: ${colors.gray.primary};
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const CompleteEstimatedRangeJobModalContent = ({job, handleClose, refetch}: any) => {
  const completeEstimatedRangeJobForm = CompleteEstimatedRangeJobForm.edit(job);
  const {form, submitting, handleSubmit} = useCompleteDateRangeJobMutation({
    completeEstimatedRangeJobForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <React.Fragment>
      <TitleText>{`Do you want to mark ${job.shortName} complete?`}</TitleText>
      <Space height={20} />
      <LabelText>Completed Date</LabelText>
      <Space height={4} />
      <FieldContainer style={{zIndex: 9999}}>
        <Field
          {...form}
          component={DateInput}
          name={'completeEstimatedRangeJobForm.endDate'}
          input={{
            placeholder: 'Select a date',
            setFieldValue: form.setFieldValue,
            style: {width: '100%', backgroundColor: 'transparent'},
          }}
        />
        <Icon
          source={Icon.ChevronDown}
          color={colors.gray.secondary}
          size={12}
          style={{position: 'absolute', right: 16, top: 16}}
        />
      </FieldContainer>
      <Space height={20} />
      <BodyText>
        {`This action will update the job's end date and ` +
          `remove it from future days on the calendar. You ` +
          `cannot undo this action.`}
      </BodyText>
      <Space height={20} />
      <Actions>
        <QuaternaryButton text={'Cancel'} onPress={handleClose} isDisabled={submitting} />
        <Space width={16} />
        <Button
          text={'Confirm'}
          onPress={handleSubmit}
          isDisabled={!form.values.completeEstimatedRangeJobForm.endDate}
          isSubmitting={submitting}
        />
      </Actions>
    </React.Fragment>
  );
};

const CompleteEstimatedRangeJobModal = ({jobUuid, handleClose, isOpen, refetchParent}: any) => {
  const responsive = useResponsive();
  const {loading, data, refetch} = useQuery(CompleteEstimatedRangeJobModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      jobUuid,
    },
    skip: !isOpen,
  });

  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer pointerEvents={'box-none'}>
        <ModalContainer {...responsive}>
          <Loading loading={loading || !data} as={PageLoadingIndicator}>
            {() => (
              <CompleteEstimatedRangeJobModalContent
                job={data.job}
                handleClose={handleClose}
                refetch={() => {
                  refetch();
                  refetchParent();
                }}
              />
            )}
          </Loading>
        </ModalContainer>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CompleteEstimatedRangeJobModal.query = gql`
  ${CompleteEstimatedRangeJobForm.edit.fragment}
  query CompleteEstimatedRangeJobModal($jobUuid: String!) {
    ${gql.query}
    job(uuid: $jobUuid) {
      id
      shortName
      ...CompleteEstimatedRangeJobForm_edit
    }
  }
`;

export default CompleteEstimatedRangeJobModal;
