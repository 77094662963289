// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Popover, Space, Styled, Tooltip} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useModal,
  useState,
  usePopover,
  useQuery,
  useNavigationDOM,
  usePagination,
  useDebouncedCallback,
  useResponsive,
} from '@supermove/hooks';
import {BillItemType, Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SearchBar from '@shared/design/components/SearchBar';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import FormulaForm from '@shared/modules/Billing/forms/FormulaForm';
import useDeleteFormulaMutation from '@shared/modules/Billing/hooks/useDeleteFormulaMutation';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import CannotDeleteFormulaModal from 'modules/Organization/Settings/Billing/Formulas/components/CannotDeleteFormulaModal';
import CreateFormulaModal from 'modules/Organization/Settings/Billing/Formulas/components/CreateFormulaModal';
import EditFormulaModal from 'modules/Organization/Settings/Billing/Formulas/components/EditFormulaModal';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${colors.white};

`;

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const HeaderRow = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const ContentContainer = Styled.View`
  width: 1000;
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const CreateButton = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  background-color: ${colors.blue.interactive};
  border-radius: 4px;
`;

const CreateButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const TableCellText = Styled.Text`
  ${Typography.Body3}
`;

const DescriptionText = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.secondary};
`;

const IconButton = Styled.ButtonV2`
`;

const Button = Styled.ButtonV2`
`;

const DeleteFormulaModal = ({formula, userId, isOpen, handleClose, refetch}: any) => {
  const formulaForm = FormulaForm.edit(formula, {userId});
  const {handleSubmit, submitting} = useDeleteFormulaMutation({
    formulaForm,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Are you sure you want to delete "${formula.name}"?`}
      subtitle={"You can't undo this action."}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

const Actions = ({formula, userId, refetch}: any) => {
  const actionMenuPopover = usePopover();
  const deleteModal = useModal({name: 'Delete Billing Formula Modal'});
  const editFormulaModal = useModal({name: 'Edit Billing Formula Modal'});
  const cannotDeleteModal = useModal({name: 'Cannot Delete Billing Formula Modal'});
  const cannotDeleteDefaultModal = useModal({name: 'Cannot Delete Default Billing Formula Modal'});
  return (
    <Row>
      <IconButton onPress={editFormulaModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </IconButton>
      <Space width={16} />
      <Popover.Content innerRef={actionMenuPopover.ref}>
        <IconButton onPress={actionMenuPopover.handleToggle}>
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </IconButton>
      </Popover.Content>
      <ActionMenuPopover popover={actionMenuPopover} placement={Popover.Positions.RightStart}>
        <ActionMenuPopover.MenuItem
          onPress={() => {
            actionMenuPopover.handleClose();
            const isDeleteable =
              formula.billItemTypes.length === 0 &&
              formula.billRuleTypes.length === 0 &&
              formula.consumingFormulas.length === 0;
            if (formula.isDefaultFormula) {
              cannotDeleteDefaultModal.handleOpen();
            } else {
              isDeleteable ? deleteModal.handleOpen() : cannotDeleteModal.handleOpen();
            }
          }}
        >
          Delete
        </ActionMenuPopover.MenuItem>
      </ActionMenuPopover>
      <EditFormulaModal
        key={editFormulaModal.key}
        formula={formula}
        userId={userId}
        isOpen={editFormulaModal.isOpen}
        handleClose={editFormulaModal.handleClose}
        refetch={refetch}
      />
      <DeleteFormulaModal
        key={deleteModal.key}
        formula={formula}
        userId={userId}
        isOpen={deleteModal.isOpen}
        handleClose={deleteModal.handleClose}
        refetch={refetch}
      />
      <CannotDeleteFormulaModal
        key={cannotDeleteModal.key}
        formula={formula}
        cannotDeleteModal={cannotDeleteModal}
      />
      <CautionModal
        title={'Cannot delete formula.'}
        message={`This is a default formula. It can be modified, but not deleted.`}
        isOpen={cannotDeleteDefaultModal.isOpen}
        handlePressOutside={cannotDeleteDefaultModal.handleClose}
        handlePrimaryAction={cannotDeleteDefaultModal.handleClose}
        primaryActionText={'Close'}
      />
    </Row>
  );
};

const getFormulasTableColumnDefinitions = ({refetch, userId, navigator}: any) => [
  {
    width: 220,
    headerContent: () => <TableHeaderText>Name/Description</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'formula' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: formula}) => (
      <TextTooltip
        isEnabledMobileToast={false}
        text={`${formula.name}:\n${formula.description}`}
        style={{maxWidth: 280}}
      >
        <Container>
          <Row style={{maxWidth: 200}}>
            <TableCellText numberOfLines={1}>{formula.name}</TableCellText>
          </Row>
          {!!formula.description && (
            <React.Fragment>
              <Space height={4} />
              <Row style={{maxWidth: 200}}>
                <DescriptionText numberOfLines={1}>{formula.description}</DescriptionText>
              </Row>
            </React.Fragment>
          )}
        </Container>
      </TextTooltip>
    ),
  },
  {
    flex: 1,
    headerContent: () => <TableHeaderText>Input</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'formula' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: formula}) => {
      return (
        <TextTooltip
          text={Formula.getFormulaString(formula)}
          numberOfLines={10}
          style={{maxWidth: 500}}
          isEnabledMobileToast={false}
        >
          <TableCellText numberOfLines={2}>{Formula.getFormulaString(formula)}</TableCellText>
        </TextTooltip>
      );
    },
  },
  {
    width: 160,
    headerContent: () => <TableHeaderText>Used In</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'formula' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: formula}) => {
      const {billRuleTypes} = formula;
      const billItemTypes = formula.billItemTypes.filter(
        (billItemType: any) => !billItemType.isParent,
      );
      const billItemTypesContent = billItemTypes.length ? (
        <Tooltip
          overlay={
            <>
              {billItemTypes.map((billItemType: any) => (
                <Row>
                  <Button
                    onPress={() =>
                      navigator.pushNewTab(
                        `/settings/billing/billing-libraries/${billItemType.billingLibrary.uuid}/items/fees`,
                      )
                    }
                  >
                    <Text>{`${BillItemType.getDisplayName(billItemType)} (${
                      billItemType.billingLibrary.name
                    })`}</Text>
                  </Button>
                </Row>
              ))}
            </>
          }
        >
          <TableCellText>{`${billItemTypes.length} ${pluralize(
            'Bill Item',
            billItemTypes.length,
          )}`}</TableCellText>
        </Tooltip>
      ) : null;
      const billRuleTypesContent = billRuleTypes.length ? (
        <Tooltip
          overlay={
            <>
              {billRuleTypes.map((billRuleType: any) => (
                <Row>
                  <Button
                    onPress={() =>
                      navigator.pushNewTab(
                        `/settings/billing/billing-libraries/${billRuleType.billingLibrary.uuid}/items/fees`,
                      )
                    }
                  >
                    <Text>{`${billRuleType.name} (${billRuleType.billingLibrary.name})`}</Text>
                  </Button>
                </Row>
              ))}
            </>
          }
        >
          <TableCellText>{`${billRuleTypes.length} ${pluralize(
            'Bill Rule',
            billRuleTypes.length,
          )}`}</TableCellText>
        </Tooltip>
      ) : null;
      return (
        <Container>
          {billItemTypesContent}
          {billRuleTypesContent}
        </Container>
      );
    },
  },
  {
    width: 160,
    headerContent: () => <TableHeaderText>Last Updated</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'formula' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: formula}) => (
      <Container>
        <TableCellText>{Formula.getDisplayUpdatedAt(formula)}</TableCellText>
        {!!formula.updatedBy && (
          <React.Fragment>
            <Space height={4} />
            <DescriptionText>{formula.updatedBy.fullName}</DescriptionText>
          </React.Fragment>
        )}
      </Container>
    ),
  },
  {
    width: 100,
    headerContent: () => <TableHeaderText style={{paddingLeft: 2}}>Actions</TableHeaderText>,
    // @ts-expect-error TS(7031): Binding element 'formula' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: formula}) => (
      <Actions formula={formula} userId={userId} refetch={refetch} />
    ),
  },
];

const OrganizationSettingsBillingFormulasPageContent = ({
  organization,
  userId,
  refetch,
  pagination,
  search,
  setSearch,
}: any) => {
  const responsive = useResponsive();
  const createFormulaModal = useModal({name: 'Create Formula Modal'});
  const {formulasPaginatedList} = organization;
  const {formulas} = formulasPaginatedList;
  const {navigator} = useNavigationDOM();
  return (
    <ContentContainer>
      <HeaderText responsive={responsive}>Billing Formulas</HeaderText>
      <Space height={12} />
      <HeaderRow>
        <SearchBar
          onChangeText={setSearch}
          placeholder='Search by formula name'
          style={{width: '500px'}}
          defaultValue={search}
        />
        <CreateButton onPress={createFormulaModal.handleOpen}>
          <Icon source={Icon.Plus} color={colors.white} size={12} />
          <Space width={6} />
          <CreateButtonText>Create Billing Formula</CreateButtonText>
        </CreateButton>
      </HeaderRow>
      <Space height={16} />
      <Table
        columnDefinitions={getFormulasTableColumnDefinitions({refetch, userId, navigator})}
        items={formulas}
        emptyStateText={'No formulas found'}
        isDense
        headerStyle={{backgroundColor: colors.gray.background}}
        isClickable={false}
      />
      <Space height={20} />
      <PaginationBar pagination={pagination} />
      <Space height={36} />
      <CreateFormulaModal
        key={createFormulaModal.key}
        organizationId={organization.id}
        userId={userId}
        isOpen={createFormulaModal.isOpen}
        handleClose={createFormulaModal.handleClose}
        refetch={refetch}
      />
    </ContentContainer>
  );
};

const OrganizationSettingsBillingFormulasPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const {loading, data, refetch} = useQuery(OrganizationSettingsBillingFormulasPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pagination: {
        page: currentPage,
        resultsPerPage: 50,
      },
      search,
    },
  });
  const handleSearch = useDebouncedCallback((query) => {
    setSearch(query);
    setCurrentPage(1);
  }, 300);
  const pagination = usePagination({
    currentPage,
    paginationMetadata:
      data?.viewer?.viewingOrganization?.formulasPaginatedList?.paginationMetadata || {},
    onChangePage: (page) => {
      setCurrentPage(page);
    },
  });
  return (
    <OrganizationSettingsBillingSettingsPage>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <OrganizationSettingsBillingFormulasPageContent
            organization={data.viewer.viewingOrganization}
            userId={data.viewer.id}
            refetch={refetch}
            pagination={pagination}
            search={search}
            setSearch={handleSearch}
          />
        )}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingFormulasPage.query = gql`
  ${EditFormulaModal.fragment}
  ${Formula.getFormulaString.fragment}
  ${Formula.getDisplayUpdatedAt.fragment}
  ${FormulaForm.edit.fragment}
  ${CannotDeleteFormulaModal.fragment}
  ${BillItemType.getDisplayName.fragment}
  ${usePagination.fragment}
  query OrganizationSettingsBillingFormulasPage(
    $pagination: PaginationInput!,
    $search: String!
  ) {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        formulasPaginatedList(
          pagination: $pagination,
          search: $search
        ) {
          formulas: results {
          id
          name
          description
          isDefaultFormula
          updatedBy {
            id
            fullName
          }
          billItemTypes {
            id
            name
            isParent
            billingLibrary {
              id
              uuid
              name
            }
            ...BillItemType_getDisplayName
          }
          billRuleTypes {
            id
            name
            billingLibrary {
              id
              uuid
              name
            }
          }
          consumingFormulas {
            id
            name
          }
          ...CannotDeleteFormulaModal
          ...EditFormulaModal
          ...Formula_getDisplayUpdatedAt
          ...Formula_getFormulaString
          ...FormulaForm_edit
          }
          paginationMetadata {
            totalResults
            ...usePagination
          }
        }
      }
    }
  }
`;

export default OrganizationSettingsBillingFormulasPage;
