// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import useDeleteEmailTemplateForm from 'modules/Organization/Settings/Email/logic/useDeleteEmailTemplateForm';

type OwnProps = {
  isOpen: boolean;
  emailTemplate: any;
  handleClose?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DeleteEmailTemplateModal.defaultProps;

// @ts-expect-error TS(7022): 'DeleteEmailTemplateModal' implicitly has type 'an... Remove this comment to see the full error message
const DeleteEmailTemplateModal = ({isOpen, emailTemplate, handleClose, refetch}: Props) => {
  const {submitting, handleSubmit} = useDeleteEmailTemplateForm({
    emailTemplate,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DeleteModal
      isOpen={isOpen}
      title={`Delete ${emailTemplate.name}?`}
      subtitle={'Are you sure you want to delete this email template?'}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      isSubmitting={submitting}
    />
  );
};

DeleteEmailTemplateModal.defaultProps = {
  handleClose: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteEmailTemplateModal.fragment = gql`
  ${useDeleteEmailTemplateForm.fragment}
  fragment DeleteEmailTemplateModal on EmailTemplate {
    id
    uuid
    name
    ...useDeleteEmailTemplateForm
  }
`;

export default DeleteEmailTemplateModal;
