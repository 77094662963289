/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useMountEffect, useNavigationDOM} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import CrewInfoContent from 'modules/Dispatch/Calendar/Day/components/CrewInfoContent';
import CELL_STYLE from 'modules/Dispatch/Calendar/Day/constants/CELL_STYLE';
import useDayCalendarDispatchOrganizationsContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchOrganizationsContext';
import useDayCalendarDispatchViewContext from 'modules/Dispatch/Calendar/Day/context/useDayCalendarDispatchViewContext';

const NUMBER_OF_HOURS = 24;

const OrganizationsContainer = Styled.View`
`;

const OrganizationSlotsContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index}
  min-width: ${NUMBER_OF_HOURS * CELL_STYLE.WIDTH}px;
`;

const SlotsContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 1000 - index};
  flex-direction: row;
  height: ${({
    // @ts-expect-error TS(2339): Property 'height' does not exist on type 'ThemePro... Remove this comment to see the full error message
    height,
  }) => `${height}px`};
`;

const SlotsTimesHeader = Styled.View`
  flex-direction: row;
  border: 1px;
  border-left-width: 0px;
  border-top-right-radius: 10px;
  border-color: ${colors.gray.border};
  background-color: ${colors.gray.background};
`;

const SlotsTimestampTouchableWrapper = Styled.Touchable`
  flex: 1;
`;

const SlotsTimestampText = Styled.H8`
  ${fontWeight(500)}
  color: ${colors.gray.secondary}
  padding-vertical: 4px;
`;

const SlotContent = Styled.View`
  z-index: -1;
  flex: ${NUMBER_OF_HOURS}; // This represents the number of hour boxes to fill up.
`;

const EmptySlotContent = Styled.View`
  position: absolute;
  flex-direction: row;
  width: 100%;
  height: 100%;
  border-bottom-width: ${(props) => ((props as any).isThick ? 3 : 1)}px;
  border-color: ${colors.gray.border}
`;

const BlankBox = Styled.View`
  background-color: ${colors.white}
  border-right-width: 1px;
  border-color: ${colors.gray.border}
  flex: 1;
`;

const FillerHours = Styled.View`
  flex: ${(props) => (props as any).hours};
`;

const TimeBarContainer = Styled.View`
  position: absolute;
  height: 100%;
  top: ${({
    // @ts-expect-error TS(2339): Property 'marginTop' does not exist on type 'Theme... Remove this comment to see the full error message
    marginTop,
  }) => `${marginTop}px`};
  left: ${(props) => (props as any).position};
  z-index: 10000;
`;

const TimeBarCircle = Styled.View`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  margin-left: -3px;
  margin-top: -3px;
  background-color: ${colors.Pink600};
`;

const TimeBarLine = Styled.View`
  flex: 1;
  border-left-width: 2px;
  border-color: ${colors.Pink600};
  margin-bottom: ${({
    // @ts-expect-error TS(2339): Property 'marginBottom' does not exist on type 'Th... Remove this comment to see the full error message
    marginBottom,
  }) => `${marginBottom}px`};
`;

const getNumberOfConsecutiveClosedOrgsFromTop = ({organizations}: any) => {
  let count = 0;
  for (let i = 0; i < organizations.length; i += 1) {
    const org = organizations[i];
    if (org.isOpen) {
      return count;
    }
    count += 1;
  }
  return count;
};

const getNumberOfConsecutiveClosedOrgsFromBottom = ({organizations}: any) => {
  let count = 0;
  for (let i = organizations.length - 1; i >= 0; i -= 1) {
    const org = organizations[i];
    if (org.isOpen) {
      return count;
    }
    count += 1;
  }
  return count;
};

const getTimeBarMarginTop = ({organizations}: any) => {
  const defaultMarginTop = 56;
  const numberOfClosedOrgsFromTop = getNumberOfConsecutiveClosedOrgsFromTop({organizations});
  const spaceBetweenOrgs = 40;
  return defaultMarginTop + numberOfClosedOrgsFromTop * spaceBetweenOrgs;
};

const getTimeBarMarginBottom = ({organizations}: any) => {
  const defaultMarginBottom = 72;
  const marginTop = getTimeBarMarginTop({organizations});
  const numberOfClosedOrgsFromBottom = getNumberOfConsecutiveClosedOrgsFromBottom({organizations});
  const spaceBetweenOrgs = 40;
  return defaultMarginBottom + marginTop + numberOfClosedOrgsFromBottom * spaceBetweenOrgs;
};

const getTimestamps = () => {
  return _.range(0, NUMBER_OF_HOURS).map((num) => {
    // Datetimes are mutable, so we need to create a new one each time;
    const startDate = Datetime.fromDate('1970-01-01', 'YYYY-MM-DD');
    return startDate.add(num, 'hour').format('h A');
  });
};

/*
 *
 * Return the filler hours on the left/right and number of hours.
 * If we don't have a start time, the crew component will take up the whole slot.
 * If we have a start time but no end time, then we start at the start time but span the remaining slot.
 * If we have both a start time and an end time, then we return the specific proper hours.
 *
 */

const getNumberOfHours = ({job}: any) => {
  if (!job.startTime1) {
    return [0, NUMBER_OF_HOURS, 0];
  }
  const crewStartTime = Datetime.fromTime(job.startTime1);
  const leftBoxHours = crewStartTime.hours() + crewStartTime.minutes() / 60;
  if (!job.endTimeEstimate || job.isEndTimeEstimateLaterDate) {
    // Fill the job from the start time to the end of the day
    return [leftBoxHours, NUMBER_OF_HOURS - leftBoxHours, 0];
  }
  const crewEndTime = Datetime.fromTime(job.endTimeEstimate);
  // we use minutes / 60 to get the decimal value of the hours
  const numberOfHours = crewEndTime.diff(crewStartTime, 'minutes') / 60;
  const rightBoxHours = NUMBER_OF_HOURS - leftBoxHours - numberOfHours;

  return [leftBoxHours, numberOfHours, rightBoxHours];
};

const getTimeBarPosition = () => {
  const hours = Datetime.now.hours();
  const minutes = Datetime.now.minutes();
  return (hours + minutes / 60) * CELL_STYLE.WIDTH;
};

const Timestamp = ({timestamp, handleScrollTo, index}: any) => {
  const scrollToPosition = index * CELL_STYLE.WIDTH;
  return (
    <SlotsTimestampTouchableWrapper
      onPress={() => handleScrollTo({x: scrollToPosition})}
      activeOpacity={0.8}
    >
      <SlotsTimestampText>{timestamp}</SlotsTimestampText>
    </SlotsTimestampTouchableWrapper>
  );
};

const TimeBar = () => {
  const timeBarPosition = getTimeBarPosition();
  const {organizations} = useDayCalendarDispatchOrganizationsContext();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <TimeBarContainer position={timeBarPosition} marginTop={getTimeBarMarginTop({organizations})}>
      <TimeBarCircle />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <TimeBarLine marginBottom={getTimeBarMarginBottom({organizations})} />
    </TimeBarContainer>
  );
};

const DesignatedCrewContent = ({isFirst, isLast, isPrimary, crew, refetch}: any) => {
  // There are 24 boxes, and we're using flex to generate the length.
  // We assume flex 1 represents an hour.
  // First, we can get the length of the job via the endTime - startTime. e.g. 9am - 10am job.
  // Then we need to get the length of the box to the start job;
  // Finally, we can get the rightBoxLength from subtracting the prior two from 24 boxes left.
  const [leftBoxHours, numberOfHours, rightBoxHours] = getNumberOfHours({job: crew.job});
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <EmptySlotContent pointerEvents={'box-none'} isThick={isLast}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FillerHours hours={leftBoxHours} pointerEvents={'box-none'} />
      <CrewInfoContent
        isSlot
        isFirst={isFirst}
        isLast={isLast}
        isSecondary={!isPrimary}
        crew={crew}
        numberOfHours={numberOfHours}
        refetch={refetch}
      />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FillerHours hours={rightBoxHours} pointerEvents={'box-none'} />
    </EmptySlotContent>
  );
};

const CrewSlotRow = ({children, hasPrimaryCrewSlot, isLast, index}: any) => {
  const cardHeight = CELL_STYLE.getCardHeight({
    isPrimary: hasPrimaryCrewSlot,
  });
  const offset = isLast ? 3 : 1;

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <SlotsContainer height={cardHeight + offset} index={index}>
      <SlotContent>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <EmptySlotContent isThick={isLast}>
          {_.range(0, NUMBER_OF_HOURS).map((num) => (
            <BlankBox key={num} />
          ))}
        </EmptySlotContent>
        {children}
      </SlotContent>
    </SlotsContainer>
  );
};

const CondensedCrewSlots = ({crewSlots, refetch, index}: any) => {
  const hasPrimaryCrewSlot = _.find(crewSlots, 'isPrimary');
  return (
    <CrewSlotRow hasPrimaryCrewSlot={hasPrimaryCrewSlot} isLast index={index}>
      {crewSlots.map((crewSlot: any, index: any) => (
        <DesignatedCrewContent
          key={crewSlot.id}
          isFirst={index === 0}
          isLast={index === crewSlots.length - 1}
          crew={crewSlot.crew}
          refetch={refetch}
          isPrimary={crewSlot.isPrimary}
        />
      ))}
    </CrewSlotRow>
  );
};

const ExpandedCrewSlots = ({crewSlots, refetch, slotIndex}: any) => {
  // We use the index to properly set the z-index for each row. We want the hover tooltip content
  // on the above row to show on top of job cards below it. However, since a slot can have many
  // crewSlots, and each crewSlot also needs to increment the index to properly differentiate its
  // own z-index, we start by multiplying the slotIndex by 10 to account for any added increments
  // that the previous slot did when handling its crewSlots.
  const indexForZIndex = slotIndex * 10;
  return (
    <React.Fragment>
      {crewSlots.length > 0 ? (
        <React.Fragment>
          {crewSlots.map((crewSlot: any, crewSlotIndex: any) => {
            return (
              <CrewSlotRow
                key={crewSlot.id}
                hasPrimaryCrewSlot={crewSlot.isPrimary}
                isLast={crewSlotIndex === crewSlots.length - 1}
                index={indexForZIndex + crewSlotIndex}
              >
                <DesignatedCrewContent
                  isFirst={crewSlotIndex === 0}
                  isLast={crewSlotIndex === crewSlots.length - 1}
                  isPrimary={crewSlot.isPrimary}
                  crew={crewSlot.crew}
                  refetch={refetch}
                />
              </CrewSlotRow>
            );
          })}
        </React.Fragment>
      ) : (
        <CrewSlotRow isLast index={indexForZIndex} />
      )}
    </React.Fragment>
  );
};

const OrganizationSlot = ({slot, refetch, index}: any) => {
  const {isExpanded} = useDayCalendarDispatchViewContext();
  const activeCrewSlots = _.sortBy(slot.activeCrewSlots, [
    (crewSlot) => {
      const datetime = Datetime.fromTime(crewSlot.crew.job.startTime1 || '0000');
      return datetime.hours() * 60 + datetime.minutes();
    },
  ]);
  return (
    <React.Fragment>
      {isExpanded ? (
        <ExpandedCrewSlots crewSlots={activeCrewSlots} refetch={refetch} slotIndex={index} />
      ) : (
        <CondensedCrewSlots crewSlots={activeCrewSlots} refetch={refetch} index={index} />
      )}
    </React.Fragment>
  );
};

/*
 * For each organization there is a time header and individual slots.
 * We utilize flex to handle an initial box with fixed width and 24 flex boxes to wrap the
 * remaining space for the time header and the slots.
 */
const OrganizationSlots = ({organizationSlotsSummary, handleScrollTo, refetch, index}: any) => {
  const timestamps = getTimestamps();
  const slots = _.sortBy(organizationSlotsSummary.slots, ['index']);

  const {organizations} = useDayCalendarDispatchOrganizationsContext();
  const isOpen = _.get(organizations, `${index}.isOpen`);

  return (
    <React.Fragment>
      {index === 0 && <Space height={32} />}
      {isOpen ? (
        // @ts-expect-error TS(2769): No overload matches this call.
        <OrganizationSlotsContainer index={index}>
          <SlotsTimesHeader>
            {timestamps.map((timestamp, index) => (
              <Timestamp
                key={timestamp}
                timestamp={timestamp}
                handleScrollTo={handleScrollTo}
                index={index}
              />
            ))}
          </SlotsTimesHeader>
          {slots.map((slot, index) => (
            <OrganizationSlot key={slot.id} index={index} slot={slot} refetch={refetch} />
          ))}
          <Space height={72} />
        </OrganizationSlotsContainer>
      ) : (
        <Space height={40} />
      )}
    </React.Fragment>
  );
};

const OrganizationSlotsCrews = ({
  organizationSlotsSummaries,
  refetch,
  scrollViewRef,
  handleScrollTo,
}: any) => {
  const {params} = useNavigationDOM();
  const scrollViewStartPosition = 7 * CELL_STYLE.WIDTH;
  const isShowingToday = params.date === Datetime.convertToDate(Datetime.today);

  useMountEffect(() => {
    handleScrollTo({x: scrollViewStartPosition, animated: false});
  });

  const {organizations} = useDayCalendarDispatchOrganizationsContext();
  const hasOpenOrganization = !!_.find(organizations, (org) => (org as any).isOpen);

  return (
    <ScrollView nestedScrollEnabled horizontal ref={scrollViewRef}>
      <OrganizationsContainer>
        {organizationSlotsSummaries.map((organizationSlotsSummary: any, index: any) => (
          <OrganizationSlots
            key={organizationSlotsSummary.organization.id}
            organizationSlotsSummary={organizationSlotsSummary}
            handleScrollTo={handleScrollTo}
            refetch={refetch}
            index={index}
          />
        ))}
        {isShowingToday && hasOpenOrganization && <TimeBar />}
      </OrganizationsContainer>
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

OrganizationSlotsCrews.fragment = gql`
  ${CrewInfoContent.fragment}

  fragment OrganizationSlotsCrews on DispatchCalendarDay {
    organizationSlotsSummaries {
      organization {
        id
      }
      slots {
        id
        index
        activeCrewSlots {
          id
          isPrimary
          crew {
            id
            job {
              id
              startTime1
              endTimeEstimate
              isEndTimeEstimateLaterDate
            }
            ...CrewInfoContent
          }
        }
      }
    }
  }
`;

export default OrganizationSlotsCrews;
