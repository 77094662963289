// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateReportMoveMoveUsersMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateReportMoveMoveUsersMutation' implicitly has... Remove this comment to see the full error message
const UpdateReportMoveMoveUsersMutation = ({
  reportMoveId,
  moveUserInputs,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
    variables={{
      reportMoveId,
      moveUserInputs,
    }}
    mutation={UpdateReportMoveMoveUsersMutation.mutation}
    onCompleted={({response: {reportMove, errors}}: any) => {
      submitForm();
      setMutationErrors(errors);

      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateReportMoveMoveUsersMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateReportMoveMoveUsersMutation.mutation = gql`
  mutation UpdateReportMoveMoveUsersMutation(
    $reportMoveId: Int!,
    $moveUserInputs: [ReportMoveUserInput]!,
  ) {
    response: updateReportMoveMoveUsers(
      reportMoveId: $reportMoveId,
      moveUserInputs: $moveUserInputs,
    ) {
      ${gql.errors}
      reportMove {
        id
        uuid
        timePayable
        moveUsers {
          userId
          timePayableAdjustment
          tip
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default UpdateReportMoveMoveUsersMutation;
