// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateJobUserMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateJobUserMutation' implicitly has type 'any' ... Remove this comment to see the full error message
const UpdateJobUserMutation = ({jobId, userId, status, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      jobId,
      userId,
      status,
    }}
    mutation={UpdateJobUserMutation.mutation}
    onCompleted={({response: {jobUser, errors}}: any) => {
      if (jobUser) {
        onSuccess(jobUser);
      } else {
        onError(errors);
      }
    }}
    refetchQueries={['DetailsJobPage']}
    awaitRefetchQueries
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateJobUserMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateJobUserMutation.fragment = gql`
  fragment UpdateJobUserMutation on JobUser {
    id
    jobId
    userId
  }
`;

UpdateJobUserMutation.mutation = gql`
  mutation UpdateJobUserMutation(
    $jobId: Int!,
    $userId: Int!,
    $status: String!,
  ) {
    response: updateJobUser(
      jobId: $jobId,
      userId: $userId,
      status: $status,
    ) {
      ${gql.errors}
      jobUser {
        id
        jobId
        userId
      }
    }
  }
`;

export default UpdateJobUserMutation;
