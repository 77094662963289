// Libraries
import React from 'react';

// Supermove
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';

// App
import Modal from '@shared/design/components/Modal';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import EditStorageProjectModalContent from 'modules/Job/V2/Storage/components/EditStorageProjectModalContent';

const EditStorageProjectModal = ({isOpen, handleClose, project, invoiceForm, refetch}: any) => {
  const {data, loading} = useQuery(EditStorageProjectModal.query, {
    variables: {
      uuid: project.uuid,
      skip: !isOpen,
    },
  });

  return (
    <Modal.Responsive isOpen={isOpen} width={Modal.WIDTH.LARGE} style={{flex: 1}}>
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          return (
            <EditStorageProjectModalContent
              handleClose={handleClose}
              project={data.project}
              invoiceFOrm={invoiceForm}
              refetch={refetch}
              isViewerOrgPrimaryMultibranch={data.viewer.viewingOrganization.isPrimaryMultibranch}
            />
          );
        }}
      </Loading>
    </Modal.Responsive>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditStorageProjectModal.query = gql`
  ${EditStorageProjectModalContent.fragment}

  query EditStorageProjectModal($uuid : String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      ...EditStorageProjectModalContent
    }
    viewer {
      id
      viewingOrganization {
        id
        isPrimaryMultibranch
      }
    }
  }
`;

EditStorageProjectModal.fragment = gql`
  fragment EditStorageProjectModal on Project {
    id
    uuid
  }
`;

export default EditStorageProjectModal;
