// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import IconButton from '@shared/design/components/IconButton';
import BillRuleTypeForm from '@shared/modules/Billing/forms/BillRuleTypeForm';
import useUpdateBillRuleTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillRuleTypeMutation';
import BillRuleTypeFields from 'modules/Organization/Settings/BillingLibraries/components/BillRuleTypeFields';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  align-items: center;
  flex-direction: row;
  ${({
    // @ts-expect-error TS(2339): Property 'isRightAligned' does not exist on type '... Remove this comment to see the full error message
    isRightAligned,
  }) => isRightAligned && 'justify-content: flex-end;'}
  ${({
    // @ts-expect-error TS(2339): Property 'isCenterAligned' does not exist on type ... Remove this comment to see the full error message
    isCenterAligned,
  }) => isCenterAligned && 'justify-content: center;'}
  ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex && `flex: ${flex};`}
  ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width && `width: ${width}px;`}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SecondaryText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const EditBillTemplateBillRuleTypeDrawer = ({
  isOpen,
  handleClose,
  form,
  field,
  billingLibrary,
}: any) => {
  const billRuleTypeForm = _.get(form.values, field);
  const {
    form: updateBillRuleTypeForm,
    submitting,
    handleSubmit,
  } = useUpdateBillRuleTypeMutation({
    billRuleTypeForm,
    onSuccess: () => {
      const updatedBillRuleTypeForm = updateBillRuleTypeForm.values.billRuleTypeForm;
      const {value, valueFormulaId, kind} = updatedBillRuleTypeForm;
      form.setFieldValue(field, {
        ...updatedBillRuleTypeForm,
        value: BillRuleTypeForm.getMutationValue({valueFormulaId, kind, value}),
        stringValue: BillRuleTypeForm.getIsString({kind}) ? value : '',
      });
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });
  return (
    <DrawerWithAction
      isOpen={isOpen}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={'Edit Bill Rule'}
      shouldCloseOnClickOutside={false}
    >
      <BillRuleTypeFields
        // @ts-expect-error TS(2322): Type 'FormikFormType<{ billRuleTypeForm: BillRuleT... Remove this comment to see the full error message
        form={updateBillRuleTypeForm}
        field={'billRuleTypeForm'}
        billingLibrary={billingLibrary}
      />
    </DrawerWithAction>
  );
};

const BillingLibraryBillRuleTypesRow = ({form, field, billingLibrary, handleDelete}: any) => {
  const responsive = useResponsive();
  const billRuleTypeForm = _.get(form.values, field);
  const editBillTemplateBillRuleTypeDrawer = useDrawer({
    name: 'Edit Bill Template Bill Rule Type Drawer',
  });
  const conditionalBillItem =
    billRuleTypeForm.billItemTypeId &&
    _.find(
      billingLibrary.billItemTypes,
      (billItemType) => _.toString(billItemType.id) === _.toString(billRuleTypeForm.billItemTypeId),
    );
  return (
    <Container>
      <Row>
        <Container
          style={{
            paddingVertical: 4,
            paddingHorizontal: 8,
            backgroundColor: colors.gray.background,
          }}
        >
          <Row>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell flex={3}>
              <Text responsive={responsive}>{billRuleTypeForm.name}</Text>
            </Cell>
            <Space width={24} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell width={144} isRightAligned>
              <SecondaryText responsive={responsive} style={{textAlign: 'right'}}>
                {conditionalBillItem
                  ? conditionalBillItem.name
                  : BillRuleTypeForm.getDisplayValue(billRuleTypeForm)}
              </SecondaryText>
            </Cell>
            <Space width={24} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell width={120} isCenterAligned>
              <Text responsive={responsive}>
                {billRuleTypeForm.isVisibleToCustomer ? 'Yes' : 'No'}
              </Text>
            </Cell>
          </Row>
          {!!billRuleTypeForm.description && (
            <React.Fragment>
              <Space height={4} />
              <SecondaryText responsive={responsive}>{billRuleTypeForm.description}</SecondaryText>
            </React.Fragment>
          )}
        </Container>
        <Space width={24} />
        <Cell width={56}>
          <IconButton source={Icon.Pen} onPress={editBillTemplateBillRuleTypeDrawer.handleOpen} />
          <Space width={8} />
          <IconButton isDestructive source={Icon.Trash} onPress={handleDelete} />
        </Cell>
      </Row>
      <EditBillTemplateBillRuleTypeDrawer
        key={editBillTemplateBillRuleTypeDrawer.key}
        form={form}
        field={field}
        isOpen={editBillTemplateBillRuleTypeDrawer.isOpen}
        handleClose={editBillTemplateBillRuleTypeDrawer.handleClose}
        billingLibrary={billingLibrary}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillRuleTypesRow.fragment = gql`
  ${BillRuleTypeFields.fragment}
  fragment BillingLibraryBillRuleTypesRow_BillingLibrary on BillingLibrary {
    id
    billItemTypes {
      id
      name
    }
    ...BillRuleTypeFields
  }
`;

export default BillingLibraryBillRuleTypesRow;
