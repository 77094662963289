// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';

// App
import SwitchField from '@shared/design/components/Field/SwitchField';
import Panel from '@shared/design/components/Panel';
import OrganizationMoverNotificationSettingsForm from '@shared/modules/Organization/forms/OrganizationMoverNotificationSettingsForm';
import useUpdateOrganizationMoverNotificationSettingsMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationMoverNotificationSettingsMutation';

const LabelText = Styled.Text`
  ${Typography.Label2};
`;

const PermissionsContent = ({organization, refetch}: any) => {
  const organizationMoverNotificationSettingsForm =
    OrganizationMoverNotificationSettingsForm.edit(organization);
  const {form, handleSubmit} = useUpdateOrganizationMoverNotificationSettingsMutation({
    organizationMoverNotificationSettingsForm,
    onSuccess: () => {
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const allowEmployeeTextField = 'organizationMoverNotificationSettingsForm.allowEmployeeText';
  const sendReminderSetScheduleField =
    'organizationMoverNotificationSettingsForm.sendReminderSetSchedule';
  const allowMoverAcceptDeclineJobField =
    'organizationMoverNotificationSettingsForm.allowMoverAcceptDeclineJob';

  return (
    <Panel width={Panel.WIDTH.DEFAULT}>
      <Panel.Header>
        <Panel.HeaderText>SMS Permissions</Panel.HeaderText>
      </Panel.Header>
      <Panel.Body>
        <LabelText>Text Messaging to Movers</LabelText>
        <Space height={8} />
        <Panel.Text style={{color: colors.gray.secondary}}>
          Enabling SMS will allow you to communicate with your Movers via text message, while
          granting them access to the Mover app.
        </Panel.Text>
        <Space height={16} />
        <SwitchField
          index={0}
          form={form}
          field={allowEmployeeTextField}
          labelRight={`Enable text messaging to Movers`}
          onChangeValue={(value) => {
            // These fields are turned off and disabled if texting for employees is disabled
            if (!value) {
              form.setFieldValue(sendReminderSetScheduleField, false);
              form.setFieldValue(allowMoverAcceptDeclineJobField, false);
            }
            setTimeout(() => handleSubmit(), 0);
          }}
        />
        <Space height={32} />
        <LabelText>Reminders</LabelText>
        <Space height={8} />
        <SwitchField
          index={1}
          form={form}
          field={sendReminderSetScheduleField}
          labelRight={`Send weekly reminder text to Movers to set their schedule`}
          disabled={!_.get(form.values, allowEmployeeTextField)}
          onChangeValue={() => {
            setTimeout(() => handleSubmit(), 0);
          }}
        />
        <Space height={24} />
        <LabelText>Job Permissions</LabelText>
        <Space height={8} />
        <SwitchField
          index={2}
          form={form}
          field={allowMoverAcceptDeclineJobField}
          labelRight={`Allow Movers to accept or decline jobs`}
          disabled={!_.get(form.values, allowEmployeeTextField)}
          onChangeValue={() => {
            setTimeout(() => handleSubmit(), 0);
          }}
        />
      </Panel.Body>
    </Panel>
  );
};

const OrganizationSettingsStaffPermissionsContent = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsStaffPermissionsContent.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <Loading loading={loading}>
      {() => {
        return (
          <PermissionsContent organization={data.viewer.viewingOrganization} refetch={refetch} />
        );
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsStaffPermissionsContent.query = gql`
  ${OrganizationMoverNotificationSettingsForm.edit.fragment}
  query OrganizationSettingsStaffPermissionsContent {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...OrganizationMoverNotificationSettingsForm_edit
      }
    }
  }
`;

export default OrganizationSettingsStaffPermissionsContent;
