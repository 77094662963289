// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {
  Drawer,
  Icon,
  Loading,
  ScrollView,
  Space,
  Styled,
  ToastContainer,
} from '@supermove/components';
import {ToggleBooleanPropertyForm} from '@supermove/forms';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useNavigationDOM,
  useQuery,
  useResponsive,
  useState,
  useToast,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {URL} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import DropdownButton from '@shared/design/components/Button/DropdownButton';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Toast from '@shared/design/components/Toast';
import TaskForm from '@shared/modules/TaskManagement/forms/TaskForm';
import useUpdateTaskIsArchivedMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskIsArchivedMutation';
import useUpdateTaskMutation from '@shared/modules/TaskManagement/hooks/useUpdateTaskMutation';
import Line from 'modules/App/components/Line';
import TaskDrawerFields from 'modules/TaskManagement/Tasks/components/TaskDrawerFields';
import TaskDrawerTabs from 'modules/TaskManagement/Tasks/components/TaskDrawerTabs';
import useToggleTaskIsCompleted from 'modules/TaskManagement/Tasks/hooks/useToggleTaskIsCompleted';

const IconButton = Styled.ButtonV2`
  padding-horizontal: 8px;
  padding-vertical: 2px;
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.mobile ? '100%' : '424px')};
`;

const IndexedContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index}
`;

const LoadingContainer = Styled.View`
  flex: 1;
  justify-content: center;
`;

const LoadingIndicator = Styled.Loading`
`;

const HeaderContainer = Styled.View`
  flex-direction: row;
  height: 72px;
  align-items: center;
`;

const HeaderText = Styled.Text`
  ${Typography.Heading2}
`;

const TaskFieldsContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const FooterContainer = Styled.View`
  padding-top: 20px;
  padding-bottom: 24px;
  padding-horizontal: 24px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const getActions = ({linkCopiedToast, taskLink, handleArchive}: any) => {
  return [
    {
      text: 'Archive task',
      onPress: handleArchive,
    },
    {
      text: 'Copy link',
      onPress: () => {
        /* global navigator */
        navigator.clipboard.writeText(taskLink);
        linkCopiedToast.handleToast();
      },
    },
  ];
};

const getTaskLink = ({task, navigator, params}: any) => {
  const {pathname} = navigator.location;

  // Params for correctly navigating project page v2
  const {section, block, jobUuid, widget} = params;

  return URL.getLinkFromVariables(pathname, {taskUuid: task.uuid, section, block, jobUuid, widget});
};

const CompleteButton = ({task, handleSubmit, submitting}: any) => {
  return (
    <React.Fragment>
      {task.isCompleted ? (
        <Button
          text={'Completed'}
          color={colors.green.status}
          style={{height: 28}}
          onPress={handleSubmit}
          isSubmitting={submitting}
        />
      ) : (
        <SecondaryButton
          text={'Mark as Complete'}
          style={{height: 28}}
          onPress={handleSubmit}
          isSubmitting={submitting}
        />
      )}
    </React.Fragment>
  );
};

const ActionsButton = ({onPress}: any) => {
  return (
    <IconButton onPress={onPress}>
      <Icon source={Icon.EllipsisV} size={14} color={colors.gray.primary} />
    </IconButton>
  );
};

const Header = ({task, handleClose, refetch}: any) => {
  const linkCopiedToast = useToast({
    ToastComponent: Toast,
    message: 'Link copied to clipboard',
  });
  const taskArchivedToast = useToast({
    ToastComponent: Toast,
    message: 'Task archived',
  });

  const toggleBooleanPropertyForm = ToggleBooleanPropertyForm.new({
    id: task.id,
    booleanValue: task.isArchived,
  });
  const {handleSubmit: handleArchive} = useUpdateTaskIsArchivedMutation({
    toggleBooleanPropertyForm,
    onSuccess: () => {
      taskArchivedToast.handleToast();
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  const {handleSubmit, submitting} = useToggleTaskIsCompleted({
    task,
    onSuccess: refetch,
  });

  const {navigator, params} = useNavigationDOM();
  const taskLink = getTaskLink({task, navigator, params});

  return (
    <HeaderContainer>
      <Space width={24} />
      <HeaderText>Task Viewer</HeaderText>
      {task.isArchived && (
        <React.Fragment>
          <Space width={16} />
          <Icon source={Icon.Archive} size={16} color={colors.gray.secondary} />
        </React.Fragment>
      )}
      <Space style={{flex: 1}} />
      <CompleteButton
        key={task.isCompleted}
        task={task}
        handleSubmit={handleSubmit}
        submitting={submitting}
      />
      <Space width={7} />
      {/* @ts-expect-error TS(2741): Property 'text' is missing in type '{ actions: { t... Remove this comment to see the full error message */}
      <DropdownButton
        actions={getActions({linkCopiedToast, taskLink, handleArchive})}
        ButtonComponent={ActionsButton}
        isVisibleArrow={false}
        menuPosition={DropdownButton.MENU_POSITION.RIGHT}
        menuWidth={144}
      />
      <Space width={16} />
      <ToastContainer />
    </HeaderContainer>
  );
};

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <LoadingIndicator />
    </LoadingContainer>
  );
};

const Footer = ({handleClose, handleSubmit, submitting, isEnabledSave}: any) => {
  return (
    <FooterContainer>
      <QuaternaryButton text={'Cancel'} width={96} onPress={handleClose} isDisabled={submitting} />
      <Space width={16} />
      <Button
        text={'Save'}
        width={96}
        onPress={handleSubmit}
        isSubmitting={submitting}
        isDisabled={!isEnabledSave}
      />
    </FooterContainer>
  );
};

const EditTaskDrawerContent = ({
  organization,
  task,
  handleClose,
  refetch,
  hasChanges,
  setHasChanges,
}: any) => {
  const taskForm = TaskForm.edit(task);
  const {form, handleSubmit, submitting} = useUpdateTaskMutation({
    taskForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });

  useEffect(() => {
    if (form.dirty !== hasChanges) {
      setHasChanges(form.dirty);
    }
  }, [form.dirty, hasChanges, setHasChanges]);

  const field = 'taskForm';
  const hasComment = !!_.get(form.values, `${field}.comment`);
  const isEnabledEdit = !task.isCompleted;
  const isEnabledSave = hasChanges || hasComment;

  return (
    <React.Fragment>
      <TaskFieldsContainer>
        <ScrollView contentContainerStyle={{paddingHorizontal: 24}}>
          <Space height={16} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IndexedContainer index={0}>
            <TaskDrawerFields
              form={form}
              field={field}
              organization={organization}
              isDisabled={!isEnabledEdit}
            />
          </IndexedContainer>
          <Space height={24} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <IndexedContainer index={1}>
            <TaskDrawerTabs task={task} form={form} field={field} />
          </IndexedContainer>
          <Space height={16} />
        </ScrollView>
      </TaskFieldsContainer>
      <Line />
      <Footer
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        isEnabledSave={isEnabledSave}
      />
    </React.Fragment>
  );
};

const EditTaskDrawer = ({isOpen, handleClose, task, refetch}: any) => {
  const responsive = useResponsive();
  const {data, loading} = useQuery(EditTaskDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
  });
  const [hasChanges, setHasChanges] = useState(false);

  return (
    <Drawer isOpen={isOpen} onClose={handleClose} shouldCloseOnClickOutside={!hasChanges}>
      <Container responsive={responsive}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IndexedContainer index={0}>
          <Header task={task} handleClose={handleClose} refetch={refetch} />
        </IndexedContainer>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IndexedContainer index={1} style={{flex: 1}}>
          <Line />
          <Loading loading={loading || !data} as={LoadingComponent}>
            {() => (
              <EditTaskDrawerContent
                organization={data.viewer.viewingOrganization}
                task={task}
                handleClose={handleClose}
                refetch={refetch}
                hasChanges={hasChanges}
                setHasChanges={setHasChanges}
              />
            )}
          </Loading>
        </IndexedContainer>
      </Container>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditTaskDrawer.query = gql`
  ${TaskDrawerFields.fragment}
  query EditTaskDrawer {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...TaskDrawerFields
      }
    }
  }
`;

EditTaskDrawer.fragment = gql`
  ${useToggleTaskIsCompleted.fragment}
  ${TaskDrawerTabs.fragment}
  ${TaskForm.edit.fragment}
  fragment EditTaskDrawer on Task {
    id
    uuid
    name
    isCompleted
    ...useToggleTaskIsCompleted
    ...TaskDrawerTabs
    ...TaskForm_edit
  }
`;

export default EditTaskDrawer;
