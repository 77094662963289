// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Job, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// Components
import {JobStatusBadge} from 'modules/Job/components';

const Container = Styled.View`
  flex-direction: row;
  margin-bottom: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 3px;
`;

const Touchable = Styled.ButtonV2`
  flex: 1;
  padding: 12px;
`;

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  align-items: ${(props) => ((props as any).mobile ? 'flex-start' : 'center')};
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'space-between')};
`;

const Info = Styled.View`
  ${(props) => ((props as any).mobile ? 'align-self: stretch;' : '')}
`;

const Line = Styled.View`
  margin-vertical: 10px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const ProjectInformationRow = Styled.View`
  flex-direction: row;
`;

const ProjectTypeDot = Styled.View`
  margin-top: 5px;
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color}
`;

const ProjectInfomationText = Styled.Text`
  ${Typography.Micro}
`;

const JobName = Styled.Text`
  ${Typography.Subheading}
`;

const Training = Styled.Text`
  ${Typography.Subheading}
  margin-left: 5px;
  color: ${colors.purple.hover};
`;

const JobTypeName = Styled.Text`
  ${Typography.Label}
  color: ${colors.gray.secondary};
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const ProjectInformation = ({job}: any) => {
  return (
    <ProjectInformationRow>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ProjectTypeDot color={job.project.projectType.color} />
      <Space width={6} />
      <ProjectInfomationText>{`${job.project.projectType.name} / ${Project.getDisplayText(
        job.project,
        job.name,
      )}`}</ProjectInfomationText>
    </ProjectInformationRow>
  );
};

const SearchJobItem = ({job, handleClose}: any) => {
  const {navigator} = useNavigationDOM();
  const responsive = useResponsive();

  return (
    <Container>
      <Touchable
        onPress={() => {
          navigator.push(`/jobs/${job.uuid}`);
          handleClose();
        }}
      >
        <ProjectInformation job={job} />
        <Row {...responsive}>
          <JobName>
            {Job.getFullName(job)}
            {job.isTest && <Training>(Training)</Training>}
          </JobName>
        </Row>
        <Row {...responsive}>
          <Info {...responsive}>
            <JobTypeName>{job.jobType.name}</JobTypeName>
            <Text numberOfLines={1}>{job.customer.fullName}</Text>
            <Text numberOfLines={1}>{Phone.display(job.customer.phoneNumber)}</Text>
            <Text numberOfLines={1}>{job.customer.email}</Text>
            <Text numberOfLines={1}>{Job.getDisplayDate(job)}</Text>
          </Info>
          {!responsive.mobile && <JobStatusBadge job={job} />}
        </Row>
        {responsive.mobile && (
          <React.Fragment>
            <Line />
            <Row {...responsive}>
              <JobStatusBadge job={job} />
            </Row>
          </React.Fragment>
        )}
      </Touchable>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SearchJobItem.fragment = gql`
  ${Job.getDisplayDate.fragment}
  ${Job.getFullName.fragment}
  ${Project.getDisplayText.fragment}
  ${JobStatusBadge.fragment}

  fragment SearchJobItem on Job {
    id
    uuid
    isTest
    name
    moveSize
    totalDistance
    startTime1
    startTime2
    day {
      id
      value
    }
    customer {
      id
      fullName
      phoneNumber
      email
    }
    jobType {
      id
      name
    }
    project {
      id
      projectType {
        id
        color
        name
      }
      ...Project_getDisplayText
    }
    ...Job_getDisplayDate
    ...Job_getFullName
    ...JobStatusBadge
  }
`;

export default SearchJobItem;
