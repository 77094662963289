// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import ProjectBlockKind from '@shared/modules/Project/enums/ProjectBlockKind';
import EditJobInformationFields from 'modules/Project/V2/Edit/components/EditJobInformationFields';
import EditProjectBlockWrapper from 'modules/Project/V2/Edit/components/EditProjectBlockWrapper';
import MobileEditJobBlock from 'modules/Project/V2/Edit/components/MobileEditJobBlock';

const EditJobInformation = ({form, field, project, isDisabled}: any) => {
  return (
    <EditJobInformationFields field={field} form={form} project={project} isDisabled={isDisabled} />
  );
};

const EditJobInformationBlockContent = ({form, field, project, isDisabled}: any) => {
  const responsive = useResponsive();
  const title = ProjectBlockKind.getDisplay(ProjectBlockKind.Job.JOB_INFORMATION);

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <ActionPanel
          BodyComponent={EditJobInformation}
          bodyComponentProps={{form, field, project, isDisabled}}
          ActionButtonComponent={() => null}
          // @ts-expect-error TS(2322): Type '{ BodyComponent: ({ form, field, project, is... Remove this comment to see the full error message
          actionBodyComponent={() => null}
          title={title}
          style={{width: '100%'}}
        />
      ) : (
        <MobileEditJobBlock headerText={title} responsive={responsive}>
          <EditJobInformation field={field} form={form} project={project} isDisabled={isDisabled} />
        </MobileEditJobBlock>
      )}
    </React.Fragment>
  );
};

const EditJobInformationBlock = ({
  form,
  field,
  index,
  handleSetPositionY,
  project,
  isDisabled,
}: any) => {
  return (
    <EditProjectBlockWrapper index={index} handleSetPositionY={handleSetPositionY}>
      <EditJobInformationBlockContent
        form={form}
        field={field}
        project={project}
        isDisabled={isDisabled}
      />
    </EditProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditJobInformationBlock.fragment = gql`
  ${EditJobInformationFields.fragment}
  fragment EditJobInformationBlock on Project {
    id
    ...EditJobInformationFields
  }
`;

export default EditJobInformationBlock;
