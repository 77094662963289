// Supermove
import {gql} from '@supermove/graphql';
import {InvoiceModel} from '@supermove/models';
import {Currency, withFragment} from '@supermove/utils';

export interface RecordPaymentFormType {
  invoiceId: string;
  name: string;
  amount: number;
  method: string;
  paymentMethodId?: string;
  description: string;
  paymentFeeAmount: number;
  // Private
  subtotal: number;
}

const _new = withFragment(
  ({name, invoice}: {name: string; invoice: InvoiceModel}): RecordPaymentFormType => ({
    invoiceId: invoice.id,
    name: name || '',
    amount: invoice.remainingBalance,
    method: '',
    paymentMethodId: undefined,
    description: '',
    paymentFeeAmount: 0,
    // Private
    subtotal: invoice.remainingBalance || 0,
  }),
  gql`
    fragment RecordPaymentForm_new on Invoice {
      id
      remainingBalance
    }
  `,
);

export interface RecordPaymentFormToFormType {
  invoiceId: string;
  name: string;
  amount: string;
  method: string;
  paymentMethodId?: string;
  description: string;
  paymentFeeAmount: string;
  // Private
  subtotal: string;
}

const toForm = ({
  invoiceId,
  name,
  amount,
  method,
  paymentMethodId,
  description,
  paymentFeeAmount,
  // Private
  subtotal,
}: RecordPaymentFormType): RecordPaymentFormToFormType => ({
  invoiceId,
  name,
  amount: Currency.toForm(amount),
  method,
  paymentMethodId,
  description,
  paymentFeeAmount: Currency.toForm(paymentFeeAmount),
  // Private
  subtotal: Currency.toForm(subtotal),
});

const toMutation = ({
  invoiceId,
  name,
  amount,
  method,
  paymentMethodId,
  description,
  paymentFeeAmount,
}: RecordPaymentFormToFormType) => ({
  invoiceId,
  name,
  amount: Currency.toMutation(amount),
  method,
  paymentMethodId,
  description,
  paymentFeeAmount: Currency.toMutation(paymentFeeAmount),
});

const RecordPaymentForm = {
  new: _new,
  toForm,
  toMutation,
};

export default RecordPaymentForm;
