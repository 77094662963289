// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, Styled, Space} from '@supermove/components';
import {usePopover, useState} from '@supermove/hooks';
import {Typography} from '@supermove/styles';

// App
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const StatusDropdownButton = Styled.ButtonV2`
  padding: 4px 8px;
  border-radius: 4px;
`;

const StatusDropdownText = Styled.Text`
  ${({
    // @ts-expect-error TS(2339): Property 'size' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    size,
  }) => (size === 'LARGE' ? Typography.Label3 : Typography.Label4)}
`;

const SIZE = {
  SMALL: 'SMALL',
  LARGE: 'LARGE',
};

const StatusMenuPopover = ({popover, options, onSelect}: any) => {
  const visibleOptions = options.filter((option: any) => !option.isHidden);
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.LeftEnd} offset={[4, 0]}>
      {visibleOptions.map((option: any) => {
        return (
          <ActionMenuPopover.MenuItem
            key={option.value}
            onPress={() => {
              onSelect(option);
              popover.handleClose();
            }}
          >
            {option.label}
          </ActionMenuPopover.MenuItem>
        );
      })}
    </ActionMenuPopover>
  );
};

const StatusDropdown = ({options, initialValue, onSelect, size}: any) => {
  const actionsPopover = usePopover();
  const [selectedStatus, setSelectedStatus] = useState(
    _.find(options, (option) => option.value === initialValue),
  );

  const handleOnSelect = (option: any) => {
    setSelectedStatus(option);
    onSelect(option);
  };

  // Filter out options that is currently selected
  options = _.filter(options, (option) => option.value !== initialValue);

  return (
    <React.Fragment>
      <Popover.Content innerRef={actionsPopover.ref}>
        <StatusDropdownButton
          style={{backgroundColor: selectedStatus.backgroundColor}}
          onPress={actionsPopover.handleToggle}
          disabled={selectedStatus.isHidden}
        >
          <Container>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <StatusDropdownText style={{color: selectedStatus.textColor}} size={size}>
              {selectedStatus.label}
            </StatusDropdownText>
            {!selectedStatus.isHidden && (
              <React.Fragment>
                <Space width={5} />
                <Icon color={selectedStatus.textColor} source={Icon.ChevronDown} size={7} />
              </React.Fragment>
            )}
          </Container>
        </StatusDropdownButton>
      </Popover.Content>
      <StatusMenuPopover options={options} popover={actionsPopover} onSelect={handleOnSelect} />
    </React.Fragment>
  );
};

StatusDropdown.SIZE = SIZE;

export default StatusDropdown;
