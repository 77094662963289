// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery} from '@supermove/hooks';
import {Formula} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemUnit from '@shared/modules/Billing/enums/BillItemUnit';
import BillStage from '@shared/modules/Billing/enums/BillStage';
import TextInputWithDropdown from 'modules/App/components/TextInputWithDropdown';

const Row = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
  flex-direction: row;
`;

const DropdownSelectedBadgeContainer = Styled.View`
  padding-vertical: 2px;
  padding-horizontal: 4px;
  background-color: ${colors.alpha(colors.orange.status, 0.1)}
  border-radius: 16px;
  border-color: ${colors.orange.status}
  border-width: 1px;
`;

const DropdownSelectedBadgeText = Styled.Text`
  ${Typography.Label4}
  color: ${colors.orange.status};
`;

const handleBlurCurrency = ({form, field}: any) => {
  const currency = _.get(form.values, field);
  if (currency) {
    form.setFieldValue(field, Currency.toForm(Currency.convertToCents(currency)));
  }
};

const handleBlurPercent = ({form, field}: any) => {
  const percent = _.get(form.values, field);
  if (percent) {
    form.setFieldValue(field, Percent.toForm(Percent.toFloat(percent)));
  }
};

const DropdownSelectedBadge = () => {
  return (
    <DropdownSelectedBadgeContainer>
      <DropdownSelectedBadgeText>Formula</DropdownSelectedBadgeText>
    </DropdownSelectedBadgeContainer>
  );
};

const PriceField = ({form, field, fieldIndex, formulaOptions}: any) => {
  return (
    <TextInputWithDropdown
      form={form}
      index={fieldIndex}
      textValueField={`${field}.amount`}
      clearTextValue={''}
      dropdownValueField={`${field}.amountFormulaId`}
      label={'Price'}
      dropdownOptions={formulaOptions}
      placeholder={'Formula or custom price'}
      noOptionsMessage={'No formula options'}
      onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
      DropdownSelectedBadge={DropdownSelectedBadge}
    />
  );
};

const ValueField = ({form, field, fieldIndex, formulaOptions}: any) => {
  const kind = _.get(form.values, `${field}.kind`);
  const isKindAmount = kind === BillItemTypeKind.AMOUNT;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={fieldIndex}>
      {isKindAmount ? (
        <TextInputWithDropdown
          form={form}
          textValueField={`${field}.amount`}
          clearTextValue={''}
          dropdownValueField={`${field}.amountFormulaId`}
          label={'Value'}
          dropdownOptions={formulaOptions}
          placeholder={'Formula or custom value'}
          noOptionsMessage={'No formula options'}
          onBlur={() => handleBlurCurrency({form, field: `${field}.amount`})}
          DropdownSelectedBadge={DropdownSelectedBadge}
        />
      ) : (
        <TextInputWithDropdown
          form={form}
          textValueField={`${field}.percentage`}
          clearTextValue={''}
          dropdownValueField={`${field}.amountFormulaId`}
          label={'Value'}
          dropdownOptions={formulaOptions}
          placeholder={'Formula or custom percent'}
          noOptionsMessage={'No formula options'}
          onBlur={() => handleBlurPercent({form, field: `${field}.percentage`})}
          DropdownSelectedBadge={DropdownSelectedBadge}
        />
      )}
      <Space width={8} />
      <FieldInput
        {...form}
        label={'Unit'}
        name={`${field}.unit`}
        component={DropdownInput}
        input={{
          disabled: true,
          options: [
            {label: '$', value: BillItemUnit.DOLLAR},
            {label: '%', value: BillItemUnit.PERCENT},
            {label: '/ hour', value: BillItemUnit.HOUR},
          ],
          style: {width: 116},
        }}
      />
    </Row>
  );
};

const QuantityField = ({form, field, fieldIndex, formulaOptions}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={fieldIndex}>
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.minQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.minQuantityFormulaId`}
        label={'Min Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
      <Space width={8} />
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.maxQuantity`}
        clearTextValue={''}
        dropdownValueField={`${field}.maxQuantityFormulaId`}
        label={'Max Quantity'}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or quantity'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
      />
    </Row>
  );
};

const EditBillItemTypeModalFields = ({form, field}: any) => {
  const billItemTypeForm = _.get(form.values, field);
  const isCategorySupplies = billItemTypeForm.category === BillItemTypeCategory.SUPPLIES;
  const {data} = useQuery(EditBillItemTypeModalFields.query, {
    fetchPolicy: 'cache-and-network',
  });

  const formulas = _.get(data, 'viewer.viewingOrganization.formulas', []);
  const formulaOptions = Formula.getFormulaDropdownOptions(formulas);
  return (
    <React.Fragment>
      <TextInputWithDropdown
        form={form}
        textValueField={`${field}.name`}
        dropdownValueField={`${field}.nameFormulaId`}
        label={'Name'}
        index={0}
        dropdownOptions={formulaOptions}
        placeholder={'Formula or custom name'}
        noOptionsMessage={'No formula options'}
        DropdownSelectedBadge={DropdownSelectedBadge}
        isRequired
      />
      <Space height={20} />
      {billItemTypeForm.billStage === BillStage.PRE_SUBTOTAL && (
        <React.Fragment>
          <QuantityField form={form} field={field} fieldIndex={1} formulaOptions={formulaOptions} />
          <Space height={20} />
        </React.Fragment>
      )}
      {isCategorySupplies ? (
        <PriceField form={form} field={field} fieldIndex={2} formulaOptions={formulaOptions} />
      ) : (
        <React.Fragment>
          <ValueField form={form} field={field} fieldIndex={3} formulaOptions={formulaOptions} />
          <Space height={20} />
          <FieldInput.LabelText>Before / After Subtotal</FieldInput.LabelText>
          <Space height={4} />
          <FieldInput.TextInput
            disabled
            value={BillStage.getDisplayName(billItemTypeForm.billStage)}
          />
        </React.Fragment>
      )}
      <Space height={20} />
      <FieldInput
        {...form}
        index={4}
        label={'Description (Optional)'}
        name={`${field}.description`}
        input={{placeholder: 'Enter a description'}}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditBillItemTypeModalFields.query = gql`
  ${Formula.getFormulaDropdownOptions.fragment}
  query EditBillItemTypeModalFields {
    viewer {
      id
      viewingOrganization {
        id
        formulas {
          id
          identifier
          ...Formula_getFormulaDropdownOptions
        }
      }
    }
  }
`;

export default EditBillItemTypeModalFields;
