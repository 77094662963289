// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App

import DocumentV2ByCategory from '@shared/modules/Document/components/DocumentV2ByCategory';

const CustomerDocumentV2 = ({document, isPrintable, isEditable, form, field}: any) => {
  return (
    <React.Fragment>
      <DocumentV2ByCategory
        document={document}
        isPrintable={isPrintable}
        isEditable={isEditable}
        form={form}
        field={field}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerDocumentV2.fragment = gql`
  ${DocumentV2ByCategory.fragment}

  fragment CustomerDocumentV2 on Document {
    id
    ...DocumentV2ByCategory
  }
`;

export default CustomerDocumentV2;
