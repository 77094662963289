/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

const Text = Styled.H7`
  align-self: center;
  text-align: center;
  word-break: break-all;
`;

const Image = Styled.Image`
  height: inherit;
  width: inherit;
`;

const FileImage = ({file}: any) => {
  // TODO(dan): Once all files support thumbnails we can remove the switch case
  switch (file.mimetype) {
    case 'image/png':
    case 'image/jpeg':
      return (
        <Image
          resizeMode={'cover'}
          source={{uri: file.downloadUrl}}
          accessibilityLabel={file.name}
        />
      );
    case 'video/mp4':
    case 'video/wav':
    case 'video/quicktime':
      return (
        <React.Fragment>
          {file.thumbnailFile ? (
            <Image
              resizeMode={'cover'}
              source={{uri: file.thumbnailFile.downloadUrl}}
              accessibilityLabel={file.name}
            />
          ) : (
            <Text>{file.name}</Text>
          )}
        </React.Fragment>
      );
    default:
      return <Text>{file.name}</Text>;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FileImage.fragment = gql`
  fragment FileImage on File {
    id
    name
    mimetype
    downloadUrl
    thumbnailFile {
      id
      downloadUrl
    }
  }
`;

export default FileImage;
