// Libraries
import React from 'react';

// Components
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import useResetMoveMutation from '@shared/modules/Job/hooks/useResetMoveMutation';

const ResetJobModal = ({moveId, isOpen, handleClose, refetch}: any) => {
  const {submitting, handleSubmit} = useResetMoveMutation({
    moveId,
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  return (
    <CautionModal
      isOpen={isOpen}
      title={'Reset Job?'}
      message={`Resetting this move will change this job's status back to “Booked”, allowing the crew to start over. This cannot be undone.`}
      primaryActionText={'Confirm'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={handleClose}
      handlePressOutside={handleClose}
      isSubmitting={submitting}
    />
  );
};

export default ResetJobModal;
