// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useNavigationDOM, useRef} from '@supermove/hooks';
import {Project} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import PayEnginePaymentsSection from 'modules/Customer/Invoice/Payment/components/PayEnginePaymentsSection';
import InvoiceCustomerDocumentPage from 'modules/Customer/Invoice/components/InvoiceCustomerDocumentPage';
import InvoiceNotFoundPage from 'modules/Customer/Invoice/components/InvoiceNotFoundPage';

const FooterContainer = Styled.View`
`;

const handlePress = ({footerRef, scrollView}: any) => {
  if (footerRef.current && scrollView) {
    footerRef.current.measure((fx: any, fy: any, width: any, height: any, px: any, py: any) => {
      scrollView.handleScrollTo({
        y: fy,
        animated: true,
      });
    });
  }
};

const Header = ({scrollView, footerRef, isEnabledCreditCards}: any) => {
  return (
    <React.Fragment>
      <Space height={60} />
      {isEnabledCreditCards ? (
        <React.Fragment>
          <InvoiceCustomerDocumentPage.PageTitle>
            Review and Pay Your Invoice
          </InvoiceCustomerDocumentPage.PageTitle>
          <Space height={8} />
          <InvoiceCustomerDocumentPage.Subtitle>
            Please review your invoice and make your payment below.
          </InvoiceCustomerDocumentPage.Subtitle>
          <Space height={8} />
          <TertiaryButton
            text={'Jump to Payment'}
            onPress={() => handlePress({footerRef, scrollView})}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <InvoiceCustomerDocumentPage.PageTitle>
            Review Your Invoice
          </InvoiceCustomerDocumentPage.PageTitle>
          <Space height={8} />
          <InvoiceCustomerDocumentPage.Subtitle>
            Please review your invoice below.
          </InvoiceCustomerDocumentPage.Subtitle>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Footer = ({invoice, refetch, footerRef, isEnabledCreditCards}: any) => {
  return (
    <FooterContainer ref={footerRef}>
      {isEnabledCreditCards && <PayEnginePaymentsSection invoice={invoice} refetch={refetch} />}
    </FooterContainer>
  );
};

const ReviewInvoicePageContent = ({invoice, refetch}: any) => {
  const footerRef = useRef(null);
  const isEnabledCreditCards = Project.getIsEnabledCreditCards(invoice.project);

  return (
    <InvoiceCustomerDocumentPage
      invoice={invoice}
      documentTitle={'Invoice'}
      HeaderComponent={Header}
      headerComponentProps={{footerRef, isEnabledCreditCards}}
      FooterComponent={Footer}
      footerComponentProps={{invoice, refetch, footerRef, isEnabledCreditCards}}
      getIsCorrectPage={({invoice}: any) => {
        // If the balance is zero we can skip the review page and to go the success page
        return invoice.remainingBalance > 0;
      }}
      redirectRoute={`/0/${invoice.organization.slug}/invoices/${invoice.uuid}/success`}
    />
  );
};

const ReviewInvoicePage = () => {
  const {params} = useNavigationDOM();
  const {loading, data, refetch} = useQuery(ReviewInvoicePage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceUuid: params.invoiceUuid,
    },
  });

  return (
    <Loading loading={loading} as={PageLoadingIndicator}>
      {() => {
        if (!data.invoiceByUuid) {
          return <InvoiceNotFoundPage />;
        }
        return <ReviewInvoicePageContent invoice={data.invoiceByUuid} refetch={refetch} />;
      }}
    </Loading>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ReviewInvoicePage.query = gql`
  ${PayEnginePaymentsSection.fragment}
  ${InvoiceCustomerDocumentPage.fragment}
  ${Project.getIsEnabledCreditCards.fragment}

  query ReviewInvoicePage($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      uuid
      remainingBalance
      organization {
        id
        slug
      }
      project {
        id
        ...Project_getIsEnabledCreditCards
      }
      ...PayEnginePaymentsSection
      ...InvoiceCustomerDocumentPage
    }
  }
`;

export default ReviewInvoicePage;
