// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, Styled, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useModal, useQuery} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App

import BulkAttachmentsForm from '@shared/modules/File/forms/BulkAttachmentsForm';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import UploadAttachmentsModalV2 from 'modules/Storage/components/UploadAttachmentsModalV2';

const Container = Styled.View`
`;

const AttachmentTitleText = Styled.H7`
  color: ${colors.gray.secondary};
`;

const AttachmentSubtitleText = Styled.H7`
  color: ${colors.blue.interactive};
`;

const BlackText = Styled.H7`
  color: ${colors.black};
`;

const AttachmentRow = Styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

const ButtonSpace = Styled.View`
  width: 10px;
`;

const DeleteButton = Styled.Button`
  height: 25px;
  width: 25px;
  margin: 5px;
  padding-horizontal: 12px;
  background-color: ${colors.Pink600};
`;

const AddButton = Styled.Button`
  height: 25px;
  width: 25px;
  margin: 5px;
  padding-horizontal: 12px;
`;

const Button = Styled.Button`
  align-self: flex-start;
  flex-direction: row;
  justify-content: space-between;
  width: ${(props) => ((props as any).mobile ? '100%' : 'fit-content')};
  height: 40px;
  padding-horizontal: 10px;
  margin-top: ${(props) => ((props as any).mobile && !(props as any).isFirst ? '10px' : '0px')};
  margin-right: ${(props) => ((props as any).mobile ? '0px' : '10px')};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const AttachedAttachments = ({form, attachedAttachmentIds, allAttachments, fieldName}: any) => {
  const removeAttachmentId = ({attachmentIds, attachmentId}: any) => {
    return attachmentIds.filter((id: any) => {
      return id !== attachmentId;
    });
  };

  const getAttachmentNameForId = ({attachmentId, allAttachments}: any) => {
    const attachment = _.find(
      allAttachments,
      (currAttachment) => currAttachment.id === attachmentId,
    );
    return attachment ? attachment.file.name : 'Unknown';
  };

  return (
    <React.Fragment>
      {attachedAttachmentIds.map((attachmentId: any) => {
        return (
          <AttachmentRow key={attachmentId}>
            <DeleteButton
              onPress={() => {
                form.setFieldValue(
                  fieldName,
                  removeAttachmentId({
                    attachmentIds: attachedAttachmentIds,
                    attachmentId,
                  }),
                );
              }}
            >
              <Icon color={colors.white} size={Icon.Sizes.Small} source={Icon.Trash} />
            </DeleteButton>
            <ButtonSpace />
            <BlackText>{getAttachmentNameForId({attachmentId, allAttachments})}</BlackText>
          </AttachmentRow>
        );
      })}
    </React.Fragment>
  );
};

const UnattachedAttachments = ({form, attachments, attachedAttachmentIds, fieldName}: any) => {
  return (
    <React.Fragment>
      {attachments.map((attachment: any) => {
        return (
          <AttachmentRow key={attachment.id}>
            <AddButton
              onPress={() => {
                form.setFieldValue(fieldName, [...attachedAttachmentIds, attachment.id]);
              }}
            >
              <Icon color={colors.white} size={Icon.Sizes.Small} source={Icon.Plus} />
            </AddButton>
            <ButtonSpace />
            <BlackText>{attachment.file.name}</BlackText>
          </AttachmentRow>
        );
      })}
    </React.Fragment>
  );
};

const EmailAttachmentsSelectorContent = ({form, name, project, refetch}: any) => {
  const uploadAttachmentModalV2 = useModal({name: 'Upload Attachments Modal V2'});
  const bulkAttachmentsForm = useForm({
    initialValues: {
      bulkAttachmentsForm: BulkAttachmentsForm.toForm({attachmentForms: []}),
    },
  });

  const isAttached = ({attachment, attachedAttachmentIds}: any) =>
    attachedAttachmentIds.includes(attachment.id);

  const fieldName = name ? `${name}.attachmentIds` : 'attachmentIds';
  const projectAttachments = project.filteredAttachments;
  const libraryAttachments = project.organization.emailTemplateAttachments;
  const allAttachments = _.union(projectAttachments, libraryAttachments);
  const attachedAttachmentIds = _.get(form.values, fieldName);
  const availableProjectAttachments = projectAttachments.filter(
    (attachment: any) => !isAttached({attachment, attachedAttachmentIds}),
  );
  const availableLibraryAttachments = libraryAttachments.filter(
    (attachment: any) => !isAttached({attachment, attachedAttachmentIds}),
  );

  return (
    <Container>
      <Space height={10} />
      <AttachmentTitleText>{`Attachments (${attachedAttachmentIds.length})`}</AttachmentTitleText>
      <AttachedAttachments
        form={form}
        attachedAttachmentIds={attachedAttachmentIds}
        allAttachments={allAttachments}
        fieldName={fieldName}
      />
      <Space height={10} />
      <AttachmentTitleText>Available Attachments</AttachmentTitleText>
      <Space height={5} />
      <AttachmentSubtitleText>From Library</AttachmentSubtitleText>
      <UnattachedAttachments
        form={form}
        attachments={availableLibraryAttachments}
        attachedAttachmentIds={attachedAttachmentIds}
        fieldName={fieldName}
      />
      <Space height={10} />
      <AttachmentSubtitleText>From Project</AttachmentSubtitleText>
      <UnattachedAttachments
        form={form}
        attachments={availableProjectAttachments}
        attachedAttachmentIds={attachedAttachmentIds}
        fieldName={fieldName}
      />
      <Space height={10} />
      <Button onPress={uploadAttachmentModalV2.handleOpen}>
        <ButtonText>Upload a New Attachment</ButtonText>
      </Button>
      <UploadAttachmentsModalV2
        key={uploadAttachmentModalV2.key}
        isOpen={uploadAttachmentModalV2.isOpen}
        handleClose={uploadAttachmentModalV2.handleClose}
        bulkAttachmentsForm={bulkAttachmentsForm}
        refetch={refetch}
        projectUuid={project.uuid}
      />
    </Container>
  );
};

type OwnEmailAttachmentsSelectorProps = {
  form: any;
  project: any;
  name?: string;
};

// @ts-expect-error TS(2456): Type alias 'EmailAttachmentsSelectorProps' circula... Remove this comment to see the full error message
type EmailAttachmentsSelectorProps = OwnEmailAttachmentsSelectorProps &
  typeof EmailAttachmentsSelector.defaultProps;

// @ts-expect-error TS(7022): 'EmailAttachmentsSelector' implicitly has type 'an... Remove this comment to see the full error message
const EmailAttachmentsSelector = ({form, project, name}: EmailAttachmentsSelectorProps) => {
  const {loading, data, refetch} = useQuery(EmailAttachmentsSelector.query, {
    fetchPolicy: 'network-only',
    variables: {
      uuid: project.uuid,
    },
  });

  return (
    <Loading alwaysUpdate loading={loading} as={PageLoadingIndicator}>
      {() => (
        <EmailAttachmentsSelectorContent
          form={form}
          name={name}
          project={data.project}
          refetch={refetch}
        />
      )}
    </Loading>
  );
};

EmailAttachmentsSelector.defaultProps = {
  name: null,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentsSelector.query = gql`
  query EmailAttachmentsSelector($uuid: String!) {
    ${gql.query}
    project(uuid: $uuid) {
      id
      uuid
      organization {
        id
        emailTemplateAttachments {
          id
          file {
            id
            name
          }
        }
      }
      filteredAttachments: filteredAttachments(attachmentCategoryKinds: []) {
        id
        file {
          id
          name
        }
      }
    }
  }
`;

EmailAttachmentsSelector.fragment = gql`
  fragment EmailAttachmentsSelector on Project {
    id
    uuid
  }
`;

export default EmailAttachmentsSelector;
