// Libraries
import React from 'react';

// Supermove
import {Icon, Loading, Modal, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useQuery, useResponsive, useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CostCategoryKind from '@shared/modules/Billing/enums/CostCategoryKind';
import CostForm from '@shared/modules/Billing/forms/CostForm';
import useUpdateCostMutation from '@shared/modules/Billing/hooks/useUpdateCostMutation';
import Line from 'modules/App/components/Line';
import CostChangesFoundModal from 'modules/Cost/components/CostChangesFoundModal';
import EditCostCostItemsModalCostSectionsV2 from 'modules/Cost/components/EditCostCostItemsModalCostSectionsV2';
import EditCostCostItemsModalCostsV2 from 'modules/Cost/components/EditCostCostItemsModalCostsV2';

const Row = Styled.View`
  flex-direction: row;
`;

const VerticalLine = Styled.View`
  height: 100%;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
`;

const ScreenContainer = Styled.View`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = Styled.View`
  width: 1000px;
  height: ${({
    // @ts-expect-error TS(2339): Property 'mobile' does not exist on type 'ThemePro... Remove this comment to see the full error message
    mobile,
  }) => (mobile ? '90%' : '720px')};
  border-radius: 16px;
  background-color: ${colors.white};
`;

const HeaderContainer = Styled.View`
  height: ${(props) => (props as any).height};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-horizontal: 24px;
`;

const BigHeaderText = Styled.Text`
  ${Typography.Heading1}
`;

const BodyContainer = Styled.View`
  flex: 1;
`;

const CostsListColumnContainer = Styled.View`
  width: ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width};
`;

const CostSectionsListColumnContainer = Styled.View`
  flex: 1;
  padding-left: 16px;
  padding-right: 24px;
`;

const FooterContainer = Styled.View`
  height: 72px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-horizontal: 30px;
`;

const ActivityIndicator = Styled.Loading`
  flex: 1;
`;

const handleCloseModal = ({form, handleClose, costChangesFoundModal}: any) => {
  if (form.dirty) {
    return costChangesFoundModal.handleOpen();
  }
  return handleClose();
};

const ActivityContainer = () => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <ModalContainer backgroundColor={colors.white} {...responsive}>
      <ActivityIndicator />
    </ModalContainer>
  );
};

const EditCostCostItemsModalHeader = ({handleClose}: any) => {
  return (
    <React.Fragment>
      <HeaderContainer height={'76px'}>
        <BigHeaderText>Edit Cost Items</BigHeaderText>
        <TertiaryButton onPress={handleClose}>
          <Icon source={Icon.Times} color={colors.gray.secondary} size={20} />
        </TertiaryButton>
      </HeaderContainer>
    </React.Fragment>
  );
};

const EditCostCostItemsModalBody = ({
  form,
  field,
  handleSubmit,
  cost,
  aggregateCost,
  setCurrentCostId,
  isViewingAllBillItems,
  setIsViewingAllBillItems,
}: any) => {
  const totalEmployeeCompensation = aggregateCost.costCategories.find(
    (costCategory: any) => costCategory.kind === CostCategoryKind.COMPENSATION,
  ).totalCost;

  return (
    <BodyContainer>
      <Row style={{flex: 1}}>
        <CostsListColumnContainer width={'300px'}>
          <ScrollView style={{flex: 1}}>
            <EditCostCostItemsModalCostsV2
              hasChange={form.dirty}
              handleSubmit={handleSubmit}
              aggregateCost={aggregateCost}
              currentCostDisplayName={cost.displayName}
              currentCostId={cost.id}
              setCurrentCostId={setCurrentCostId}
            />
          </ScrollView>
        </CostsListColumnContainer>
        <VerticalLine />
        <ScrollView
          style={{
            backgroundColor: colors.gray.background,
          }}
        >
          <CostSectionsListColumnContainer>
            <EditCostCostItemsModalCostSectionsV2
              form={form}
              field={field}
              totalEmployeeCompensation={totalEmployeeCompensation}
              cost={cost}
              isViewingAllBillItems={isViewingAllBillItems}
              setIsViewingAllBillItems={setIsViewingAllBillItems}
            />
          </CostSectionsListColumnContainer>
        </ScrollView>
      </Row>
    </BodyContainer>
  );
};

const EditCostCostItemsModalFooter = ({
  form,
  handleClose,
  handleSubmit,
  submitting,
  costChangesFoundModal,
}: any) => {
  return (
    <FooterContainer>
      <QuaternaryButton
        onPress={() => handleCloseModal({form, handleClose, costChangesFoundModal})}
        isDisabled={submitting}
        text={'Cancel'}
        textColor={colors.blue.interactive}
      />
      <Space width={16} />
      <Button
        text={'Save'}
        isSubmitting={submitting}
        onPress={async () => {
          const {data} = await handleSubmit();
          if (!data.response.errors) {
            handleClose();
          }
        }}
      />
    </FooterContainer>
  );
};

const EditCostCostItemsModalModalContent = ({
  cost,
  aggregateCost,
  setCurrentCostId,
  isViewingAllBillItems,
  setIsViewingAllBillItems,
  handleClose,
  refetch,
}: any) => {
  const costForm = CostForm.edit(cost, {
    weight: cost.project.weight,
    totalDistance: cost.project.totalDistance,
    projectRevenue: cost.project.totalRevenue,
  });
  const {form, handleSubmit, submitting} = useUpdateCostMutation({
    costForm,
    onSuccess: () => refetch(),
    onError: (errors: any) => console.log({errors}),
  });
  const costChangesFoundModal = useModal({name: 'Cost Changes Found Modal'});
  const responsive = useResponsive();

  return (
    <ModalContainer {...responsive}>
      <EditCostCostItemsModalHeader
        organization={cost.project.organization}
        handleClose={() => handleCloseModal({form, handleClose, costChangesFoundModal})}
      />
      <Line />
      <EditCostCostItemsModalBody
        form={form}
        field={'costForm'}
        handleSubmit={handleSubmit}
        cost={cost}
        aggregateCost={aggregateCost}
        setCurrentCostId={setCurrentCostId}
        isViewingAllBillItems={isViewingAllBillItems}
        setIsViewingAllBillItems={setIsViewingAllBillItems}
      />
      <Line />
      <EditCostCostItemsModalFooter
        form={form}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        submitting={submitting}
        costChangesFoundModal={costChangesFoundModal}
      />
      <CostChangesFoundModal
        isOpen={costChangesFoundModal.isOpen}
        handleClose={costChangesFoundModal.handleClose}
        name={cost.displayName}
        handleDecline={handleClose}
        handleAccept={async () => {
          const {data} = await handleSubmit();
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          if (!data.response.errors) {
            handleClose();
          }
          costChangesFoundModal.handleClose();
        }}
      />
    </ModalContainer>
  );
};

const EditCostCostItemsModalV2 = ({isOpen, handleClose, costId, aggregateCost, refetch}: any) => {
  const [currentCostId, setCurrentCostId] = useState(costId);
  const [isViewingAllBillItems, setIsViewingAllBillItems] = useState(false);
  const {loading, data} = useQuery(EditCostCostItemsModalV2.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen || !costId,
    variables: {
      costId: currentCostId,
    },
  });
  return (
    <Modal.Content onClose={handleClose} isOpen={isOpen}>
      <ScreenContainer>
        <Loading loading={loading} as={ActivityContainer}>
          {() => {
            return data ? (
              <EditCostCostItemsModalModalContent
                key={currentCostId}
                cost={data.cost}
                aggregateCost={aggregateCost}
                setCurrentCostId={setCurrentCostId}
                isViewingAllBillItems={isViewingAllBillItems}
                setIsViewingAllBillItems={setIsViewingAllBillItems}
                handleClose={handleClose}
                refetch={refetch}
              />
            ) : null;
          }}
        </Loading>
      </ScreenContainer>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditCostCostItemsModalV2.query = gql`
  ${CostForm.edit.fragment}
  ${EditCostCostItemsModalCostSectionsV2.fragment}

  query EditCostCostItemsModalV2($costId: Int!) {
    cost(costId: $costId) {
      id
      displayName
      project {
        id
        weight
        totalDistance
        totalRevenue
      }
      ...CostForm_edit
      ...EditCostCostItemsModalCostSectionsV2
    }
  }
`;

EditCostCostItemsModalV2.fragment = gql`
  ${EditCostCostItemsModalCostsV2.fragment}

  fragment EditCostCostItemsModalV2 on AggregateCost {
    ...EditCostCostItemsModalCostsV2
  }
`;

export default EditCostCostItemsModalV2;
