/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Icon, LocationInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Job, Organization} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import LocationKind from '@shared/modules/Location/enums/LocationKind';
import LocationForm from '@shared/modules/Location/forms/LocationForm';
import CheckboxField from 'modules/App/components/CheckboxField';
import JobMap from 'modules/Job/components/JobMap';
import LocationKindDropdown from 'modules/Job/components/LocationKindDropdown';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const Container = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const SectionTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const FieldGroupContainer = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const FieldSpace = Styled.View`
  margin-top: ${(props) => ((props as any).mobile ? 10 : 0)}px;
  margin-left: ${(props) => ((props as any).mobile ? 0 : 8)}px;
`;

const LocationTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const Checkboxes = Styled.View`
  justify-content: flex-end;
`;

const ActionText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const ActionContainer = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  background-color: ${colors.white};
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Action = Styled.Touchable`
`;

const ActionSpace = Styled.View`
  width: 10px;
`;

const AddLocation = Styled.View`
  justify-content: center;
  width: 100%;
  height: 40px;
`;

const AddLocationLine = Styled.View`
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const InsertWrapper = Styled.View`
  width: 100px;
  z-index: 1;
`;

const InsertLink = Styled.Touchable`
  padding-vertical: 5px;
  padding-horizontal: 10px;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  border-radius: 20px;
`;

const InsertLinkText = Styled.H7`
  color: ${colors.blue.interactive};
  ${fontWeight(700)}
`;

const STAIR_DESCRIPTION_OPTIONS = [
  {label: 'No Stairs', value: 'No Stairs'},
  {label: '1-10 Stairs', value: '1-10 Stairs'},
  {label: '11-20 Stairs', value: '11-20 Stairs'},
  {label: '21-30 Stairs', value: '21-30 Stairs'},
  {label: '31-40 Stairs', value: '31-40 Stairs'},
  {label: '41-50 Stairs', value: '41-50 Stairs'},
  {label: '51+ Stairs', value: '51+ Stairs'},
  {label: 'Unknown', value: 'Unknown'},
];

const decrementIndexLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const beforeIndex = selectedIndex - 1;
  const beforeLocationForm = locationForms[beforeIndex];
  const selectedLocationForm = locationForms[selectedIndex];
  const newLocationForms = locationForms
    .slice(0, beforeIndex)
    .concat([selectedLocationForm, beforeLocationForm])
    .concat(locationForms.slice(selectedIndex + 1));
  form.setFieldValue(field, newLocationForms);
};

const incrementIndexLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const afterIndex = selectedIndex + 1;
  const afterLocationForm = locationForms[afterIndex];
  const selectedLocationForm = locationForms[selectedIndex];
  const newLocationForms = locationForms
    .slice(0, selectedIndex)
    .concat([afterLocationForm, selectedLocationForm])
    .concat(locationForms.slice(afterIndex + 1));
  form.setFieldValue(field, newLocationForms);
};

const deleteLocationForm = ({index: selectedIndex, field, form, locationForms}: any) => {
  const newLocationForms = locationForms.filter((locationForm: any, index: any) => {
    return index !== selectedIndex;
  });

  form.setFieldValue(field, newLocationForms);
};

const insertLocationForm = ({
  isFirst,
  isLast,
  index,
  locationFormsField,
  form,
  locationForms,
}: any) => {
  // Inserts a new LocationForm AFTER index.
  const kind = isLast ? LocationKind.DROP_OFF : LocationKind.PICK_UP;
  const newLocationForm = LocationForm.toForm(LocationForm.new({kind}));
  const firstLocationForms = _.slice(locationForms, 0, index + 1); // Include `index`.
  const lastLocationForms = _.slice(locationForms, index + 1, locationForms.length);
  const newLocationForms = [...firstLocationForms, newLocationForm, ...lastLocationForms];

  form.setFieldValue(locationFormsField, newLocationForms);
};

const AddLocationSpace = ({onAddLocation}: any) => {
  const responsive = useResponsive();

  return (
    <AddLocation {...responsive}>
      <InsertWrapper>
        <InsertLink onPress={onAddLocation}>
          <InsertLinkText>+ Location</InsertLinkText>
        </InsertLink>
      </InsertWrapper>
      <AddLocationLine />
    </AddLocation>
  );
};

const LocationActions = ({index, field, form, locationForms}: any) => (
  <Actions>
    <Action
      disabled={index === 0}
      onPress={() =>
        decrementIndexLocationForm({
          index,
          field,
          form,
          locationForms,
        })
      }
    >
      <Icon
        color={index === 0 ? colors.blue.accent : colors.gray.secondary}
        size={Icon.Sizes.Large}
        source={Icon.ArrowUp}
      />
    </Action>
    <ActionSpace />
    <Action
      disabled={index === locationForms.length - 1}
      onPress={() =>
        incrementIndexLocationForm({
          index,
          field,
          form,
          locationForms,
        })
      }
    >
      <Icon
        color={index === locationForms.length - 1 ? colors.blue.accent : colors.gray.secondary}
        size={Icon.Sizes.Large}
        source={Icon.ArrowDown}
      />
    </Action>
    <ActionSpace />
    <Action
      onPress={() =>
        deleteLocationForm({
          index,
          field,
          form,
          locationForms,
        })
      }
    >
      <Icon color={colors.red.warning} size={Icon.Sizes.Large} source={Icon.Trash} />
    </Action>
  </Actions>
);

const LocationFormSection = ({
  index,
  form,
  locationField,
  locationFormsField,
  locationForms,
  project,
  onLocation,
  isBuildingTypeRequired,
}: any) => {
  const responsive = useResponsive();
  const locationName = _.get(form, `values.${locationField}.name`);
  const locationAddress = _.get(form, `values.${locationField}.address`);
  const isNameViewable = _.get(form, `values.${locationField}.isNameViewable`);
  const isExtraInfoVisible = _.get(form, `values.${locationField}.isExtraInfoVisible`);
  const {isEnabledGooglePlacesCenterLocation} = project.organization.features;
  const buildingTypeDropdownOptions = Organization.getLocationTypeDropdownOptions(
    project.organization,
  );

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container index={index}>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={0} {...responsive}>
        <LocationTitle>{`${index + 1} of ${locationForms.length}`}</LocationTitle>
        <Space width={8} />
        <LocationKindDropdown field={`${locationField}.kind`} form={form} />
        <EmptySpace />
        {locationForms.length > 1 && (
          <LocationActions
            index={index}
            field={locationFormsField}
            form={form}
            locationForms={locationForms}
          />
        )}
      </FieldGroupContainer>
      <Space height={8} />
      {isNameViewable && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <FieldGroupContainer index={1} {...responsive}>
          <FieldInput.Memoized
            {...form}
            actionText={
              locationName ? null : (
                <React.Fragment>
                  <Icon
                    color={colors.blue.interactive}
                    size={Icon.Sizes.Small}
                    source={Icon.Trash}
                  />
                  <Space width={4} />
                  {'Clear'}
                </React.Fragment>
              )
            }
            name={`${locationField}.name`}
            label={'Name'}
            input={{
              placeholder: 'Enter custom name',
              flex: 1,
            }}
            style={{
              flex: 1,
            }}
            handleAction={() => {
              form.setFieldValue(`${locationField}.name`, '');
              form.setFieldValue(`${locationField}.isNameViewable`, false);
            }}
          />
        </FieldGroupContainer>
      )}
      <Space height={8} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={2} style={{flex: 1}} {...responsive}>
        <FieldInput.Memoized
          {...form}
          component={LocationInput}
          actionText={isNameViewable ? null : '+ Custom name'}
          name={`${locationField}.address`}
          label={'Address'}
          input={{
            required: !locationAddress,
            placeholder: 'Enter address',
            setFieldValue: form.setFieldValue,
            setFieldError: form.setFieldError,
            setFieldTouched: form.setFieldTouched,
            onLocation,
            initialLocation: isEnabledGooglePlacesCenterLocation
              ? project.owningOrganization.warehouseLocation
              : null,
            style: {
              flex: 1,
            },
          }}
          style={{
            flex: 2,
          }}
          handleAction={() => form.setFieldValue(`${locationField}.isNameViewable`, true)}
        />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          name={`${locationField}.zipCode`}
          label={'Zip Code'}
          input={{
            // Always disable the zipCode fields.
            disabled: true,
            placeholder: 'Zip Code',
          }}
          style={{
            flex: 1,
          }}
        />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <FieldSpace {...responsive} />
        <FieldInput.Memoized
          {...form}
          index={3}
          component={DropdownInput}
          actionText={isExtraInfoVisible ? null : '+ Additional Information'}
          name={`${locationField}.buildingType`}
          label={'Location Type'}
          input={{
            required:
              !_.get(form.values, `${locationField}.buildingType`) && isBuildingTypeRequired,
            options: buildingTypeDropdownOptions,
            placeholder: 'Select the location type',
            isSearchable: true,
            setFieldValue: form.setFieldValue,
            style: {
              flex: 1,
            },
          }}
          style={{
            flex: 2,
          }}
          handleAction={() => form.setFieldValue(`${locationField}.isExtraInfoVisible`, true)}
        />
      </FieldGroupContainer>
      {isExtraInfoVisible && (
        <React.Fragment>
          <Space height={8} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <FieldGroupContainer index={5} style={{justifyContent: 'flex-end'}} {...responsive}>
            <ActionContainer
              onPress={() => {
                form.setFieldValue(`${locationField}.unit`, null);
                form.setFieldValue(`${locationField}.floorNumber`, null);
                form.setFieldValue(`${locationField}.stairDescription`, null);
                form.setFieldValue(`${locationField}.hasElevator`, false);
                form.setFieldValue(`${locationField}.hasLongWalk`, false);
                form.setFieldValue(`${locationField}.notes`, null);
                form.setFieldValue(`${locationField}.isExtraInfoVisible`, false);
              }}
            >
              <Icon color={colors.blue.interactive} size={Icon.Sizes.Small} source={Icon.Trash} />
              <Space width={4} />
              <ActionText>Clear</ActionText>
            </ActionContainer>
          </FieldGroupContainer>
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <FieldGroupContainer index={6} {...responsive}>
            <FieldInput.Memoized
              {...form}
              name={`${locationField}.unit`}
              label={'Unit #'}
              input={{
                placeholder: 'Unit #',
                style: {
                  flex: 1,
                },
              }}
              style={{
                flex: 1,
              }}
            />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              name={`${locationField}.floorNumber`}
              label={'Floor #'}
              input={{
                placeholder: 'Floor #',
                style: {
                  flex: 1,
                },
              }}
              style={{
                flex: 1,
              }}
            />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <FieldSpace {...responsive} />
            <FieldInput.Memoized
              {...form}
              component={DropdownInput}
              name={`${locationField}.stairDescription`}
              label={'# of Stairs'}
              input={{
                isPortaled: true,
                options: STAIR_DESCRIPTION_OPTIONS,
                placeholder: '# of Stairs',
                isSearchable: true,
                setFieldValue: form.setFieldValue,
                style: {
                  flex: 1,
                },
              }}
              style={{
                flex: 1,
              }}
            />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <FieldSpace {...responsive} />
            <Checkboxes style={{flex: 1}}>
              <CheckboxField {...form} name={`${locationField}.hasElevator`} label={'Elevator?'} />
              <CheckboxField {...form} name={`${locationField}.hasLongWalk`} label={'Long Walk?'} />
            </Checkboxes>
            <EmptySpace style={{flex: 2}} />
          </FieldGroupContainer>
          <Space height={8} />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <FieldGroupContainer index={6} {...responsive}>
            <FieldInput.Memoized
              {...form}
              name={`${locationField}.notes`}
              label={Job.FIELDS.LOCATION_NOTES}
              input={{
                placeholder:
                  'Enter notes such as parking details, access information, or gate codes',
                multiline: true,
                numberOfLines: 4,
                style: {
                  paddingVertical: 9,
                  minHeight: 56,
                },
              }}
              style={{
                width: '100%',
              }}
            />
          </FieldGroupContainer>
        </React.Fragment>
      )}
    </Container>
  );
};

const ProjectJobLocationsInfoBlockFields = ({form, jobField, project}: any) => {
  const locationForms = _.get(form, `values.${jobField}.locationForms`);
  const locationsCount = locationForms.length;

  return (
    <Container>
      {locationForms.map((locationForm: any, index: any) => {
        const locationField = `${jobField}.locationForms.${index}`;
        const locationFormsField = `${jobField}.locationForms`;
        const locationForms = _.get(form.values, locationFormsField);

        return (
          <React.Fragment key={index}>
            <LocationFormSection
              key={`locationForm.${index}`}
              locationForms={locationForms}
              form={form}
              index={index}
              locationField={locationField}
              locationFormsField={locationFormsField}
              onLocation={({address, city, zipCode, latitude, longitude, state, country}: any) => {
                const locationForm = _.get(form, `values.${locationField}`);
                form.setFieldValue(locationField, {
                  ...locationForm,
                  address,
                  city,
                  zipCode,
                  latitude,
                  longitude,
                  state,
                  country,
                });
              }}
              project={project}
              isBuildingTypeRequired={false}
            />
            <JobMap
              isHidden
              locations={locationForms}
              onRouteUpdate={({distances}: any) => {
                form.setFieldValue(`${jobField}.locationDistances`, distances);
              }}
            />
            <Space height={8} />
            {(locationsCount === 1 || index < locationsCount - 1) && (
              <React.Fragment>
                <AddLocationSpace
                  locationsCount={locationsCount}
                  onAddLocation={() => {
                    insertLocationForm({
                      isFirst: index === 0,
                      isLast: index === locationsCount - 1,
                      index,
                      locationFormsField,
                      form,
                      locationForms,
                    });
                  }}
                />
                <Space height={8} />
              </React.Fragment>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

const ProjectJobLocationsInfoBlock = ({index, form, jobField, project}: any) => {
  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Locations</SectionTitle>
          <EmptySpace />
          <Space width={8} />
        </React.Fragment>
      }
    >
      <ProjectJobLocationsInfoBlockFields form={form} jobField={jobField} project={project} />
    </ProjectJobSection>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobLocationsInfoBlock.fragment = gql`
  ${Organization.getLocationTypeDropdownOptions.fragment}

  fragment ProjectJobLocationsInfoBlock on Project {
    id
    organization {
      id
      features {
        isEnabledGooglePlacesCenterLocation: isEnabled(feature: "GOOGLE_PLACES_CENTER_LOCATION")
      }
      ...Organization_getLocationTypeDropdownOptions
    }
    owningOrganization {
      id
      warehouseLocation {
        latitude
        longitude
      }
    }
  }
`;

export default ProjectJobLocationsInfoBlock;
