// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px;
  background-color: ${colors.gray.background};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const DateText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

type OwnProps = {
  date: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof WeekHeader.defaultProps;

// @ts-expect-error TS(7022): 'WeekHeader' implicitly has type 'any' because it ... Remove this comment to see the full error message
const WeekHeader = ({date}: Props) => (
  <Container>
    <DateText>{`Week of ${Datetime.convertToDisplayDate(date, 'M/D/YY')}`}</DateText>
  </Container>
);

WeekHeader.defaultProps = {};

export default WeekHeader;
