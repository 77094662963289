/**
 * Component - v2.1.0
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App

import useBookingFiltersForm from '@shared/modules/Booking/hooks/useBookingFiltersForm';
import CreateJobButton from 'modules/App/components/CreateJobButton';
import StandardOfficeHeader from 'modules/App/components/StandardOfficeHeader';
import BookingCalendarDay from 'modules/Calendar/Booking/components/BookingCalendarDay';
import BookingCalendarWeek from 'modules/Calendar/Booking/components/BookingCalendarWeek';
import BookingCalendarWeekV1 from 'modules/Calendar/Booking/components/BookingCalendarWeekV1';
import BookingFiltersSidebar from 'modules/Calendar/Booking/components/BookingFiltersSidebar';
import BookingCalendarContextProvider from 'modules/Calendar/Booking/context/BookingCalendarContextProvider';
import useBookingCalendarContext from 'modules/Calendar/Booking/context/useBookingCalendarContext';
import CalendarHeader from 'modules/Calendar/components/CalendarHeader';

const Container = Styled.View`
  flex: 1;
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const Button = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding-horizontal: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  box-shadow: none;
`;

const ButtonText = Styled.H7`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
  letter-spacing: 0.5;
`;

const Calendar = ({date, type, form, organization}: any) => {
  if (type === 'DAY') {
    return <BookingCalendarDay date={date} form={form} />;
  }
  if (!organization.features.isEnabledBookingCalendarV2) {
    return <BookingCalendarWeekV1 date={date} form={form} />;
  }
  return <BookingCalendarWeek date={date} form={form} />;
};

const getSelectedOrganizationSlug = ({viewer, organizationSlug}: any) => {
  const {organizations} = viewer.viewingOrganization.company;
  const viewerOrganization = _.find(organizations, {slug: viewer.viewingOrganization.slug});
  // If no organization slug is provided, return the viewer organization
  if (!organizationSlug) {
    return viewerOrganization.slug;
  }
  const selectedOrganization =
    _.find(organizations, {slug: organizationSlug}) || viewer.viewingOrganization;
  return selectedOrganization.slug;
};

const isValidOrganizationSlug = ({viewer, organizationSlug}: any) => {
  const {organizations} = viewer.viewingOrganization.company;
  return _.find(organizations, {slug: organizationSlug});
};

const BookingCalendarContent = ({
  searchParams,
  viewer,
  form,
  bookingFiltersSidebar,
  isHeaderHidden,
}: any) => {
  const {isHovered: filtersIsHovered, ref: filtersRef} = useHover();
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  const date = Datetime.toMutationDate(
    searchParams.date || Datetime.toMutationDate(Datetime.today),
  );
  const type = responsive.mobile ? 'DAY' : searchParams.mode || 'WEEK';
  const {pageMinWidth} = useBookingCalendarContext();

  const selectedOrganizationSlug = getSelectedOrganizationSlug({
    viewer,
    organizationSlug: searchParams.organization,
  });

  if (
    !searchParams.organization ||
    !isValidOrganizationSlug({viewer, organizationSlug: searchParams.organization})
  ) {
    navigator.replace(
      `/booking/calendar?date=${date}&organization=${viewer.viewingOrganization.slug}`,
    );
  }

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container style={{minWidth: responsive.mobile ? null : pageMinWidth}}>
      {isHeaderHidden ? null : <StandardOfficeHeader title={'Booking'} />}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={0}>
        <CalendarHeader
          mode={'BOOKING'}
          queryDate={date}
          queryType={type}
          selectedOrganizationSlug={selectedOrganizationSlug}
          organization={viewer.viewingOrganization}
          form={form}
          right={
            <Actions>
              {viewer.viewingOrganization.hasMultipleJobTypes && (
                <React.Fragment>
                  {viewer.viewingOrganization.canCreateJobs && (
                    <React.Fragment>
                      <Space width={8} />
                      <CreateJobButton />
                    </React.Fragment>
                  )}
                  <Space width={8} />
                  <Button
                    color={colors.white}
                    onPress={() => navigator.push(`/settings/staff/schedules?date=${date}`)}
                  >
                    <ButtonText>Movers Schedule</ButtonText>
                  </Button>
                  <Space width={8} />
                  <Button
                    ref={filtersRef}
                    color={filtersIsHovered ? colors.hover : colors.white}
                    onPress={bookingFiltersSidebar.handleOpen}
                  >
                    <Icon
                      color={colors.gray.primary}
                      size={Icon.Sizes.Medium}
                      source={Icon.Sliders}
                    />
                    {!responsive.mobile && <ButtonText style={{marginLeft: 5}}>Filters</ButtonText>}
                  </Button>
                </React.Fragment>
              )}
            </Actions>
          }
        />
      </Section>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section index={1} style={{flex: 1}} data-test-id='booking-calendar-container'>
        <Calendar date={date} type={type} form={form} organization={viewer.viewingOrganization} />
      </Section>
    </Container>
  );
};

const BookingCalendar = ({searchParams, viewer, isHeaderHidden}: any) => {
  const bookingFiltersSidebar = useModal();

  const selectedOrganizationSlug = getSelectedOrganizationSlug({
    viewer,
    organizationSlug: searchParams.organization,
  });
  // Filters form for selecting which jobs to show / hide.
  const form = useBookingFiltersForm({
    viewerOrganization: viewer.viewingOrganization,
    selectedOrganizationSlug,
  });

  return (
    <BookingFiltersSidebar
      isOpen={bookingFiltersSidebar.isOpen}
      form={form}
      onOpen={bookingFiltersSidebar.handleOpen}
      onClose={bookingFiltersSidebar.handleClose}
      queryDate={searchParams.date}
      selectedOrganizationSlug={selectedOrganizationSlug}
      organization={viewer.viewingOrganization}
    >
      <BookingCalendarContextProvider>
        <BookingCalendarContent
          isHeaderHidden={isHeaderHidden}
          searchParams={searchParams}
          viewer={viewer}
          form={form}
          bookingFiltersSidebar={bookingFiltersSidebar}
        />
      </BookingCalendarContextProvider>
    </BookingFiltersSidebar>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BookingCalendar.fragment = gql`
  ${useBookingFiltersForm.fragment}
  ${CalendarHeader.fragment}
  ${BookingFiltersSidebar.fragment}

  fragment BookingCalendar on User {
    id
    viewingOrganization {
      id
      slug
      canCreateJobs
      hasMultipleJobTypes
      features {
        isEnabledBookingCalendarV2: isEnabled(feature: "BOOKING_CALENDAR_V2")
      }
      ...useBookingFiltersForm
      ...CalendarHeader
      ...BookingFiltersSidebar
    }
  }
`;

export default BookingCalendar;
