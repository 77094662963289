// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, Space, Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useState,
  useEffect,
  useNavigationDOM,
  useResponsive,
  useQuery,
  usePagination,
  useRef,
  useModal,
  useToast,
} from '@supermove/hooks';
import {URL} from '@supermove/utils';

// App
import PaginationBar from '@shared/design/components/Pagination/PaginationBar';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InvoiceStatus from '@shared/modules/Billing/enums/InvoiceStatus';
import useFilteredInvoicesReducer, {
  getDefaultFiltersForOrganization,
} from '@shared/modules/Billing/hooks/useFilteredInvoicesReducer';
import useSendBulkInvoicesToCodatMutation from '@shared/modules/Billing/hooks/useSendBulkInvoicesToCodatMutation';
import InvoiceList from 'modules/Accounting/components/InvoiceList';
import ListInvoicesPageFilters from 'modules/Accounting/components/ListInvoicesPageFilters';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import FinalizeBulkInvoicesModal from 'modules/Storage/components/FinalizeBulkInvoicesModal';
import UnfinalizeBulkInvoicesModal from 'modules/Storage/components/UnfinalizeBulkInvoicesModal';

const Container = Styled.View`
  flex: 1;
  margin-horizontal: 24px;
`;

const Content = Styled.View`
  width: 100%;
`;

const Section = Styled.View`
  align-self: stretch;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const BASE_ROUTE = '/accounting/invoices';

const getVariablesFromParams = (
  params: any,
  initialFiltersForOrganization: any,
  organization: any,
  projectTypeCategory: any,
) => {
  const {statuses, isTraining, ...rest} = params;
  // Redirect to DRAFT if no status is specified in URL
  const urlStatuses = statuses || [InvoiceStatus.DRAFT];
  if (!params.pagination || !params.statuses || !params.slugs) {
    // Only use default isTraining filter when page is initially rendered
    return {
      statuses: urlStatuses,
      pagination: PaginationBar.DEFAULT_PAGINATION,
      isTraining: initialFiltersForOrganization.isTraining,
      slugs:
        organization.isPrimary &&
        (organization.features.isEnabledMultibranchFilterView ||
          organization.features.isEnabledMultibranchAccounting)
          ? ['ALL_ORGANIZATIONS']
          : [organization.slug],
      ...rest,
    };
  }
  return {
    statuses: urlStatuses,
    pagination: PaginationBar.DEFAULT_PAGINATION,
    isTraining: isTraining === 'true' ? true : isTraining === 'false' ? false : null,
    ...rest,
  };
};

const getHideServiceType = (organization: any, filters: any) => {
  if (!organization.settings.isStorageEnabled) {
    return true;
  }

  return filters.projectTypeCategories.length === 1;
};

const ListInvoicesPageContent = ({organization, projectTypeCategory}: any) => {
  const {params, navigator} = useNavigationDOM();
  const ref = useRef();
  const responsive = useResponsive();
  const baseRoute = `${BASE_ROUTE}/${projectTypeCategory}`;
  const finalizeBulkInvoicesModal = useModal({
    name: 'Finalize Bulk Invoices Modal',
    enableTracking: true,
  });
  const unfinalizeBulkInvoicesModal = useModal({
    name: 'Unfinalize Bulk Invoices Modal',
    enableTracking: true,
  });

  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const {visibleCalendarStatuses} = organization.settings;
  const initialFiltersForOrganization = getDefaultFiltersForOrganization(visibleCalendarStatuses, [
    _.upperCase(projectTypeCategory),
  ]);

  const {filters, dispatch, actions} = useFilteredInvoicesReducer(
    {
      ...getVariablesFromParams(
        {
          projectTypeCategories: [_.upperCase(projectTypeCategory)],
          ...params,
        },
        initialFiltersForOrganization,
        organization,
        _.upperCase(projectTypeCategory),
      ),
    },
    visibleCalendarStatuses,
  );

  useEffect(() => {
    // If required params are not set in the route on mount,
    // reload the page with the correct variables
    if (!params.pagination || !params.statuses) {
      navigator.replace(URL.getUrlFromVariables(baseRoute, filters));
      return;
    }

    if (!ref.current) {
      // Set the initial ref to avoid unnecessary URL updates
      ref.current = filters;
      return;
    }

    // Only push new URL when variables have changed
    if (!_.isEqual(ref.current, filters)) {
      ref.current = filters;
      const url = URL.getUrlFromVariables(baseRoute, filters);
      navigator.replace(url);

      // Also reset the selectedInvoices when filters change
      setSelectedInvoices([]);
    }
  }, [navigator, ref, filters, baseRoute, params, setSelectedInvoices]);

  const {loading, data, refetch, error} = useQuery(ListInvoicesPageContent.query, {
    fetchPolicy: 'cache-and-network',
    variables: filters,
    skip: !params.pagination || !params.statuses,
  });

  const sendBulkInvoicesToCodatToast = useToast({
    ToastComponent: SuccessToast,
    message: `Invoice(s) queued for export. They will be exported in the next batch.`,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const {handleSubmit: handleSubmitBulkSendInvoicesToCodat} = useSendBulkInvoicesToCodatMutation({
    invoiceIds: selectedInvoices,
    onSuccess: () => {
      refetch();
      setSelectedInvoices([]);
      sendBulkInvoicesToCodatToast.handleToast();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const pagination = usePagination({
    currentPage: _.toNumber(filters.pagination.page),
    paginationMetadata: _.get(data, 'paginatedList.paginationMetadata'),
    onChangePage: (page) => dispatch({type: actions.SET_PAGINATION, payload: page}),
  });

  return (
    <React.Fragment>
      <Container>
        <Space height={16} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section sectionIndex={0}>
          <Content {...responsive}>
            <ListInvoicesPageFilters
              filters={filters}
              dispatch={dispatch}
              actions={actions}
              organization={organization}
              selectedInvoices={selectedInvoices}
              projectTypeCategory={_.upperCase(projectTypeCategory)}
              handleFinalizePress={finalizeBulkInvoicesModal.handleOpen}
              handleUnfinalizePress={unfinalizeBulkInvoicesModal.handleOpen}
              handleSubmitBulkSendInvoicesToCodat={handleSubmitBulkSendInvoicesToCodat}
              setSelectedInvoices={setSelectedInvoices}
              invoices={data ? data.paginatedList.invoices : []}
              params={params}
            />
          </Content>
        </Section>
        <Loading loading={!data} as={PageLoadingIndicator}>
          {() => (
            // @ts-expect-error TS(2769): No overload matches this call.
            <Section sectionIndex={1} style={{flex: 1}}>
              <InvoiceList
                loading={loading}
                refetch={refetch}
                invoices={data ? data.paginatedList.invoices : []}
                hasError={!!error}
                hideStatus
                hideServiceType={getHideServiceType(organization, filters)}
                showBranchName={
                  organization.isPrimaryMultibranch &&
                  (organization.features.isEnabledMultibranchFilterView ||
                    organization.features.isEnabledMultibranchAccounting)
                }
                viewingOrganization={data.viewer.viewingOrganization}
                showCheckboxes
                selectedInvoices={selectedInvoices}
                setSelectedInvoices={setSelectedInvoices}
                isErrorTab={filters.hasEmailSendError}
                isFixedHeaderScroll
              />
            </Section>
          )}
        </Loading>
        <Space height={40} />
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section sectionIndex={2}>
          <PaginationBar pagination={pagination} />
        </Section>
        <Space height={70} />
      </Container>
      <FinalizeBulkInvoicesModal
        invoiceIds={selectedInvoices}
        isOpen={finalizeBulkInvoicesModal.isOpen}
        handleClose={finalizeBulkInvoicesModal.handleClose}
        resetSelectedInvoices={() => setSelectedInvoices([])}
        refetch={refetch}
      />
      <UnfinalizeBulkInvoicesModal
        invoiceIds={selectedInvoices}
        isOpen={unfinalizeBulkInvoicesModal.isOpen}
        handleClose={unfinalizeBulkInvoicesModal.handleClose}
        resetSelectedInvoices={() => setSelectedInvoices([])}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListInvoicesPageContent.query = gql`
  ${ListInvoicesPageFilters.invoiceFragment}
  ${InvoiceList.fragment}
  ${usePagination.fragment}

  query ListInvoicesPageContent(
    $pagination: PaginationInput!,
    $statuses: [String],
    $projectIds: [Int],
    $projectTypeIds: [Int],
    $projectTagIds: [Int],
    $projectTypeCategories: [String],
    $dateFrom: String,
    $dateTo: String,
    $dueDateFrom: String,
    $dueDateTo: String,
    $invoicePayments: [String],
    $exportStatuses: [String],
    $projectStatuses: [String],
    $slugs: [String],
    $isTraining: Boolean,
    $hasEmailSendError: Boolean,
    $searchQuery: String,
  ) {
    ${gql.query}
    paginatedList: filteredInvoicesPaginatedList(
      pagination: $pagination,
      statuses: $statuses,
      projectIds: $projectIds,
      projectTypeIds: $projectTypeIds,
      slugs: $slugs,
      projectTagIds: $projectTagIds,
      projectTypeCategories: $projectTypeCategories,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      dueDateFrom: $dueDateFrom,
      dueDateTo: $dueDateTo,
      invoicePayments: $invoicePayments,
      exportStatuses: $exportStatuses,
      projectStatuses: $projectStatuses,
      isTraining: $isTraining,
      hasEmailSendError: $hasEmailSendError,
      searchQuery: $searchQuery,
    ) {
      invoices: results {
        id
        ...ListInvoicesPageFilters_invoiceFragment
        ...InvoiceList
      }
      paginationMetadata {
        ...usePagination
      }
    }
    viewer {
      id
      ...InvoiceList_Viewer
    }
  }
`;

ListInvoicesPageContent.fragment = gql`
  ${ListInvoicesPageFilters.organizationFragment}

  fragment ListInvoicesPageContent on Organization {
    id
    slug
    isPrimary
    isPrimaryMultibranch
    features {
      isEnabledMultibranchFilterView: isEnabled(feature: "MULTIBRANCH_FILTER_VIEW")
      isEnabledMultibranchAccounting: isEnabled(feature: "MULTIBRANCH_ACCOUNTING")
    }
    settings {
      id
      isStorageEnabled
      visibleCalendarStatuses
    }
    ...ListInvoicesPageFilters_organizationFragment
  }
`;

export default ListInvoicesPageContent;
