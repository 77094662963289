// Libraries
import React from 'react';

// Supermove
import {RichTextInput, RichTextInputV1, Space, Styled} from '@supermove/components';
import {Form, FormValues, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import Field from 'modules/App/components/Field';

const Container = Styled.View`
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const EmailContentSection = ({
  field,
  form,
  isEnabledEmailRichTextEditorV2,
}: {
  field: string;
  form: Form<FormValues>;
  isEnabledEmailRichTextEditorV2: boolean;
}) => {
  const responsive = useResponsive();

  return (
    <Container>
      <Label>Subject</Label>
      <Space height={2} />
      <Field
        {...form}
        name={`${field}.subject`}
        input={{
          placeholder: 'Enter subject',
          style: {
            width: '100%',
          },
        }}
      />
      <Space height={12} />
      {isEnabledEmailRichTextEditorV2 ? (
        <Field
          {...form}
          name={`${field}.body`}
          component={RichTextInput}
          input={{
            placeholder: 'Enter body',
            setFieldValue: form.setFieldValue,
            style: {
              height: 360,
              paddingVertical: 9,
            },
          }}
          style={{
            height: responsive.mobile ? 390 : 366,
          }}
        />
      ) : (
        <Field
          {...form}
          name={`${field}.body`}
          component={RichTextInputV1}
          input={{
            multiline: true,
            placeholder: 'Enter body',
            setFieldValue: form.setFieldValue,
            style: {
              width: '100%',
              height: 360,
              paddingVertical: 9,
            },
          }}
          style={{
            height: responsive.mobile ? 390 : 366,
          }}
        />
      )}
    </Container>
  );
};

export default EmailContentSection;
