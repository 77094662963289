/*
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import BillTipActionsButton from 'modules/Project/Billing/BillTip/components/BillTipActionsButton';

const Row = Styled.View`
  flex-direction: row;
`;

const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;

const EmptySpace = Styled.View`
  flex: 6;
`;

const BillTipsContainer = Styled.View`
  flex: 7;
  border-width: ${({
    // @ts-expect-error TS(2339): Property 'isEditable' does not exist on type 'Them... Remove this comment to see the full error message
    isEditable,
  }) => (isEditable ? '1px' : '0px')};
  border-color: ${colors.gray.border};
  border-radius: ${({
    // @ts-expect-error TS(2339): Property 'isEditable' does not exist on type 'Them... Remove this comment to see the full error message
    isEditable,
  }) => (isEditable ? '8px' : '0px')};
  background-color: ${colors.white};
`;

const NameContainer = Styled.View`
  flex: 1;
  padding-top: ${(props) => ((props as any).isEditable && (props as any).isFirst ? '8px' : '0px')};
  padding-bottom: ${(props) => ((props as any).isEditable ? '8px' : '0px')};
  padding-left: ${(props) => (!(props as any).isEditable ? '0px' : '12px')};
  flex-direction: row;
  align-items: center;
`;

const NameRow = Styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: ${(props) => ((props as any).mobile ? 'flex-start' : 'flex-end')};
`;

const AmountContainer = Styled.View`
  justify-content: center;
  align-items: flex-end;
  padding-top: ${(props) => ((props as any).isEditable && (props as any).isFirst ? '8px' : '0px')};
  padding-bottom: ${(props) => ((props as any).isEditable ? '8px' : '0px')};
  padding-horizontal: ${(props) => ((props as any).isEditable ? '12px' : '0px')};
`;

const BillTipName = ({isEditable, billTip, refetch, isFirst, billId, project}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <NameContainer isEditable={isEditable} isFirst={isFirst} {...responsive}>
      {isEditable ? (
        <BillTipActionsButton
          billTip={billTip}
          billId={billId}
          refetch={refetch}
          project={project}
        />
      ) : (
        <Space />
      )}
      <NameRow {...responsive}>
        <Space width={8} />
        <LabelText responsive={responsive}>Tip</LabelText>
        {!!billTip.name && (
          <React.Fragment>
            <Space width={4} />
            <LabelText numberOfLines={1} responsive={responsive}>{`(${billTip.name}`}</LabelText>
            <LabelText responsive={responsive}>{`)`}</LabelText>
          </React.Fragment>
        )}
      </NameRow>
    </NameContainer>
  );
};

const BillTipAmount = ({billTip, isEditable, isFirst}: any) => {
  const responsive = useResponsive();

  return (
    <AmountContainer
      // @ts-expect-error TS(2769): No overload matches this call.
      isEditable={isEditable}
      isFirst={isFirst}
      style={!responsive.mobile && {flex: 1}}
    >
      {!!billTip && (
        <LabelText responsive={responsive}>{Currency.format({value: billTip.amount})}</LabelText>
      )}
    </AmountContainer>
  );
};

const BillTip = ({billTip, isEditable, isFirst, refetch, project}: any) => {
  return (
    <Row>
      <BillTipName
        billTip={billTip}
        isEditable={isEditable}
        isFirst={isFirst}
        refetch={refetch}
        project={project}
      />
      <BillTipAmount billTip={billTip} isEditable={isEditable} isFirst={isFirst} />
    </Row>
  );
};

const EmptyBillTip = ({isEditable, primaryBill, refetch, project}: any) => {
  const responsive = useResponsive();
  return (
    <Row>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <NameContainer isEditable={isEditable} isFirst {...responsive}>
        <BillTipActionsButton billId={primaryBill.id} refetch={refetch} project={project} />
        <NameRow {...responsive}>
          <Space width={8} />
          <LabelText responsive={responsive}>Tip</LabelText>
        </NameRow>
      </NameContainer>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <AmountContainer isEditable={isEditable} isFirst style={!responsive.mobile && {flex: 1}} />
    </Row>
  );
};

const ProjectBillTipsList = ({primaryBill, isEditable, refetch, project}: any) => {
  const {billTips} = primaryBill;
  const responsive = useResponsive();
  return (
    <Row>
      {!responsive.mobile && <EmptySpace />}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <BillTipsContainer isEditable={isEditable}>
        {billTips.length === 0 ? (
          isEditable ? (
            <EmptyBillTip
              project={project}
              isEditable={isEditable}
              primaryBill={primaryBill}
              refetch={refetch}
            />
          ) : null
        ) : (
          billTips.map((billTip: any, index: any) => {
            const isFirst = index === 0;
            return (
              <React.Fragment key={billTip.id}>
                {!isEditable && !isFirst && <Space height={8} />}
                <BillTip
                  billTip={billTip}
                  isEditable={isEditable}
                  project={project}
                  isFirst={isFirst}
                  refetch={refetch}
                />
              </React.Fragment>
            );
          })
        )}
      </BillTipsContainer>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectBillTipsList.fragment = gql`
  ${BillTipActionsButton.fragment}

  fragment ProjectBillTipsList on Bill {
    id
    billTips {
      id
      name
      amount
      ...BillTipActionsButton
    }
  }

  fragment ProjectBillTipsList_Project on Project {
    id
    ...BillTipActionsButton_Project
  }
`;

export default ProjectBillTipsList;
