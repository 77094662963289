// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Emoji, Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation, useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import PayEngineCreditCardInput from '@shared/modules/Payment/components/PayEngineCreditCardInput';
import useSavePayEngineCreditCard from '@shared/modules/Payment/hooks/useSavePayEngineCreditCard';
import UpdateConfirmationCardMutation from 'modules/Customer/Project/Confirmation/Card/components/UpdateConfirmationCardMutation';
import ConfirmationStepHeader from 'modules/Customer/Project/Confirmation/components/ConfirmationStepHeader';

const Container = Styled.View`
  width: ${(props) => ((props as any).mobile ? '100%' : '500px')};
  padding-horizontal: ${(props) => ((props as any).mobile ? 20 : 0)}px;
`;

const Row = Styled.View`
  margin-top: 30px;
  width: 100%;
`;

const CardInputWrapper = Styled.View`
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.tertiary};
  border-radius: 3px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Content = Styled.View`
  align-self: stretch;
  padding-top: 60px;
  padding-bottom: 90px;
`;

const SubtitleBold = Styled.H6`
  ${fontWeight(700)}
  line-height: 24px;
`;

const Section = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const EmojiText = Styled.H4`
  margin-right: 5px;
`;

const Name = Styled.H6`
  ${fontWeight(700)}
`;

const Footer = Styled.View`
  width: 100%;
  margin-top: 30px;
`;

const Button = Styled.StatefulButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const getSubtitleText = ({confirmation}: any) => {
  const {nextStep: step} = confirmation;
  const feeWindow = step.valueTwo ? step.valueTwo : '3 days';
  const cancellationFee = step.value
    ? Currency.display(step.value, {shouldHideCentsIfZero: true})
    : Currency.display(20000, {shouldHideCentsIfZero: true});
  return [
    `Should you need to cancel your move, we request that you let us know ` +
      `at least ${feeWindow} before your scheduled time. This gives us ` +
      `the opportunity to serve other customers. Your card will not be charged unless you ` +
      `cancel within ${feeWindow} of your scheduled time. If you cancel within ` +
      `${feeWindow} of your scheduled time, there will be ` +
      `a cancellation fee of ${cancellationFee}.\n\nPlease leave us your credit card info. ` +
      `We will securely save this info and it will only be used in the event of a ` +
      `cancellation. `,
    <SubtitleBold>{`You will not be charged anything at this time.`}</SubtitleBold>,
  ];
};

const ButtonContent = ({isLoading, isSuccess, confirmation}: any) => {
  if (isLoading) {
    return null;
  }
  if (isSuccess) {
    return <Icon color={colors.white} size={Icon.Sizes.Large} source={Icon.Check} />;
  }
  const actionText = _.get(confirmation, 'nextStep.actionText', '');
  if (actionText) {
    return <Text>{actionText}</Text>;
  }
  return <Text>Continue</Text>;
};

type OwnCardConfirmationProjectFormPayEngineProps = {
  project: any;
  onSuccess: (...args: any[]) => any;
  beforeSubmit?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'CardConfirmationProjectFormPayEnginePr... Remove this comment to see the full error message
type CardConfirmationProjectFormPayEngineProps = OwnCardConfirmationProjectFormPayEngineProps &
  typeof CardConfirmationProjectFormPayEngine.defaultProps;

// @ts-expect-error TS(7022): 'CardConfirmationProjectFormPayEngine' implicitly ... Remove this comment to see the full error message
const CardConfirmationProjectFormPayEngine = ({
  project,
  onSuccess,
  beforeSubmit,
}: CardConfirmationProjectFormPayEngineProps) => {
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [isFormLoaded, setIsFormLoaded] = React.useState(false);
  const onLoad = React.useCallback(() => {
    setIsFormLoaded(true);
  }, [setIsFormLoaded]);

  // @ts-expect-error TS(2345): Argument of type '{ clientId: any; onSuccess: (res... Remove this comment to see the full error message
  const savePayEngineCreditCard = useSavePayEngineCreditCard({
    clientId: project.billingClient.id,
    onSuccess: (response) => {
      setErrorMessage(null);
    },
    onError: (error) => {
      console.error(`Error executing SavePayengineCreditCardMutation: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Could not save credit card."' i... Remove this comment to see the full error message
      setErrorMessage(`Could not save credit card.`);
    },
    onTokenizeError: (error) => {
      console.error(`Error tokenizing credit card: `, error);
      // @ts-expect-error TS(2345): Argument of type '"Invalid credit card information... Remove this comment to see the full error message
      setErrorMessage(`Invalid credit card information.`);
    },
  });

  const updateConfirmationCardForm = useForm({
    initialValues: {
      payengineCreditCardId: undefined,
    },
  });
  const updateConfirmationCardMutation = useFormMutation({
    form: updateConfirmationCardForm,
    mutation: UpdateConfirmationCardMutation.mutation,
    variables: {
      confirmationId: project.confirmation.id,
      payengineCreditCardId: updateConfirmationCardForm.values.payengineCreditCardId,
    },
    onSuccess: (response) => {
      setIsSuccess(true);
      setErrorMessage(null);
    },
    onError: (errors) => {
      console.error(`Error executing UpdateConfirmationCardMutation: `, errors);
      // @ts-expect-error TS(2345): Argument of type '"Could not save credit card."' i... Remove this comment to see the full error message
      setErrorMessage(`Could not save credit card.`);
    },
  });

  const isLoading = savePayEngineCreditCard.submitting || updateConfirmationCardMutation.submitting;

  const onPress = async () => {
    try {
      // Skip tokenization & SavePayengineCreditCardMutation if it already succeeded. We shouldn't
      // re-tokenize the card if only updateConfirmationCardMutation fails, since that will result
      // in duplicate saved cards for this client.
      if (!updateConfirmationCardForm.values.payengineCreditCardId) {
        const saveCardResponse = await savePayEngineCreditCard.handleSubmit();
        const cardId = saveCardResponse?.id;
        if ((saveCardResponse as any)?.errors || !cardId) {
          return;
        }
        updateConfirmationCardForm.setFieldValue('payengineCreditCardId', cardId);
      }

      const response = await updateConfirmationCardMutation.handleSubmit();
      const errors = response?.errors || response?.data?.response?.errors;
      if (!response || errors) {
        return;
      }

      onSuccess();
    } catch (error) {
      console.error('Unhandled error: ', error);
      // @ts-expect-error TS(2345): Argument of type '"Something went wrong."' is not ... Remove this comment to see the full error message
      setErrorMessage('Something went wrong.');
    }
  };

  const responsive = useResponsive();
  return (
    <Content>
      <ConfirmationStepHeader
        title={'Review our cancellation policy'}
        subtitle={getSubtitleText({confirmation: project.confirmation})}
        confirmation={project.confirmation}
      />
      <Row>
        <Section>
          <Emoji component={EmojiText} name={'credit_card'} />
          <Name>Credit card information</Name>
        </Section>
        <Container {...responsive}>
          <CardInputWrapper>
            <PayEngineCreditCardInput
              setCreditCardClient={savePayEngineCreditCard.setCreditCardClient}
              mode={project.organization.mode}
              onLoad={onLoad}
            />
          </CardInputWrapper>
          {errorMessage && <ValidationError>{errorMessage}</ValidationError>}
        </Container>
      </Row>
      <Footer {...responsive}>
        <Button
          disabled={!isFormLoaded}
          loading={isLoading}
          success={isSuccess}
          onPress={() => beforeSubmit(onPress)}
        >
          <ButtonContent
            isLoading={isLoading}
            isSuccess={isSuccess}
            confirmation={project.confirmation}
          />
        </Button>
      </Footer>
    </Content>
  );
};

CardConfirmationProjectFormPayEngine.defaultProps = {
  beforeSubmit: (handleSubmit: any) => handleSubmit(),
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CardConfirmationProjectFormPayEngine.fragment = gql`
  ${ConfirmationStepHeader.fragment}

  fragment CardConfirmationProjectFormPayEngine on Project {
    id
    uuid
    confirmation {
      id
      nextStep {
        kind
        value
        valueTwo
        actionText
      }
      ...ConfirmationStepHeader
    }
    billingClient {
      id
    }
    customer {
      id
      firstName
      email
      phoneNumber
    }
    organization {
      id
      slug
      mode
    }
  }
`;

export default CardConfirmationProjectFormPayEngine;
