/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job, Location, Project, User} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import BillingProjectBillsListV1 from 'modules/Project/Billing/components/BillingProjectBillsListV1';
import BillingProjectPaymentsList from 'modules/Project/Billing/components/BillingProjectPaymentsList';

const Container = Styled.View`
  width: 100%;
`;

const GeneralInformationContainer = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

const Column = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.primary};
`;

const Text = Styled.Text`
  ${Typography.Body4}
  color: ${colors.gray.primary};
`;

const GeneralInformationSection = ({project}: any) => {
  const phone = Phone.display(project.customer.phoneNumber);
  const {email} = project.customer;

  return (
    <GeneralInformationContainer>
      <Title>Contact Information</Title>
      <Space height={4} />
      <Text>{User.getFullName(project.customer)}</Text>
      <Text>{phone}</Text>
      <Text>{email}</Text>
      <Space height={20} />
      <Title>Project Information</Title>
      <Space height={4} />
      <Text>Project Name: {Project.getName(project)}</Text>
      <Text>Project Type: {project.projectType.name}</Text>
      {!!project.size && <Text>Project Size: {project.size}</Text>}
      {!!project.description && (
        <React.Fragment>
          <Text>Project Description:</Text>
          <Text>{project.description}</Text>
        </React.Fragment>
      )}
      <Space height={20} />
      <Title>Move Information</Title>
      <Space height={8} />
      {Job.sortJobsBySequence(project.activeMoveJobs)
        .filter((job) => (job as any).kind !== 'ESTIMATE')
        .map((job) => (
          <LocationJobSection job={job} key={(job as any).id} />
        ))}
    </GeneralInformationContainer>
  );
};

const LocationJobSection = ({job}: any) => {
  const numberOfLocations = job.locations.length;
  const getArrivalText = ({job}: any) => {
    if (job.startTime1) {
      return Job.getDisplayArrivalWindow(job);
    } else {
      return 'Arrival time to be determined';
    }
  };

  return (
    <React.Fragment>
      <Text>Job Type: {job.jobType.name}</Text>
      <Text>Job Name: {job.name}</Text>
      <Text>Job Date: {`${Job.getDisplayDate(job, 'MM/DD/YY')}`}</Text>
      <Text>Arrival Time: {getArrivalText({job})}</Text>
      {job.locations.map((location: any, index: any) => (
        <Text key={index}>
          {`${Location.getLocationType({index, total: numberOfLocations})}: ` +
            `${Location.getDisplayLocation(location)}`}
        </Text>
      ))}
      <Space height={16} />
    </React.Fragment>
  );
};

const BillSection = ({project, sectionIndex}: any) => {
  return (
    <Row>
      <Column>
        <Title>Billing Information</Title>
        <Space height={20} />
        <BillingProjectBillsListV1
          isDocument
          isProposal
          showBillRules
          showQuantity={project.organization.features.isEnabledConfirmationShowQuantity}
          showTotal={project.organization.features.isEnabledConfirmationShowTotal}
          showGrandTotal={project.organization.features.isEnabledConfirmationShowGrandTotal}
          showEmptyTotals={false}
          project={project}
          aggregateBill={project.activeJobsAggregateBill}
        />
        {project.activeJobsAggregateBill.hasPayments && (
          <React.Fragment>
            <Space height={10} />
            <BillingProjectPaymentsList aggregateBill={project.activeJobsAggregateBill} />
          </React.Fragment>
        )}
      </Column>
    </Row>
  );
};

type OwnConfirmationDocumentProjectInfoProps = {
  project: any;
};

// @ts-expect-error TS(2456): Type alias 'ConfirmationDocumentProjectInfoProps' ... Remove this comment to see the full error message
type ConfirmationDocumentProjectInfoProps = OwnConfirmationDocumentProjectInfoProps &
  typeof ConfirmationDocumentProjectInfo.defaultProps;

// @ts-expect-error TS(7022): 'ConfirmationDocumentProjectInfo' implicitly has t... Remove this comment to see the full error message
const ConfirmationDocumentProjectInfo = ({project}: ConfirmationDocumentProjectInfoProps) => {
  return (
    <Container>
      <GeneralInformationSection project={project} />
      <Space height={20} />
      <BillSection sectionIndex={6} project={project} />
    </Container>
  );
};

ConfirmationDocumentProjectInfo.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConfirmationDocumentProjectInfo.fragment = gql`
  ${Job.getDisplayArrivalWindow.fragment}
  ${Job.getDisplayDate.fragment}
  ${Job.sortJobsBySequence.fragment}
  ${Location.getDisplayLocation.fragment}
  ${Project.getName.fragment}
  ${BillingProjectBillsListV1.fragment}
  ${BillingProjectPaymentsList.fragment}

  fragment ConfirmationDocumentProjectInfo on Project {
    id
    size
    description
    activeJobsAggregateBill {
      hasPayments
      ...BillingProjectBillsListV1_AggregateBill
      ...BillingProjectPaymentsList_AggregateBill
    }
    projectType {
      id
      name
    }
    customer {
      id
      aliasName
      firstName
      lastName
      phoneNumber
      email
    }
    organization {
      id
      slug
      name
      businessName
      features {
        isEnabledConfirmationShowQuantity: isEnabled(feature: "CONFIRMATION_SHOW_QUANTITY")
        isEnabledConfirmationShowTotal: isEnabled(feature: "CONFIRMATION_SHOW_TOTAL")
        isEnabledConfirmationShowGrandTotal: isEnabled(feature: "CONFIRMATION_SHOW_GRAND_TOTAL")
      }
    }
    activeMoveJobs {
      id
      kind
      name
      moveSize
      jobType {
        id
        name
      }
      day {
        id
        value
      }
      locations {
        id
        ...Location_getDisplayLocation
      }
      ...Job_getDisplayArrivalWindow
      ...Job_getDisplayDate
      ...Job_sortJobsBySequence
    }
    ...BillingProjectBillsListV1
    ...Project_getName
  }
`;

export default ConfirmationDocumentProjectInfo;
