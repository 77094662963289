// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {ScrollView, Styled, Space, Loading, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useEffect,
  useQuery,
  useState,
  Form,
  ResponsiveType,
  useResponsive,
  DrawerType,
} from '@supermove/hooks';
import {
  DocumentItemSectionType,
  DocumentItemType,
  DocumentTemplateVersionModel,
} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Json} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Callout from '@shared/design/components/Callout';
import Checkbox from '@shared/design/components/Checkbox';
import Drawer from '@shared/design/components/Drawer';
import Table from '@shared/design/components/Table';
import {ColumnDefinitionType} from '@shared/design/components/Table/components/TableBuilder';
import DocumentItemsList from '@shared/modules/Document/components/DocumentItemsList';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';
import {DocumentContentJsonFormType} from '@shared/modules/Document/forms/DocumentTemplateVersionForm';
import JobTypeStepDocumentTemplateForm, {
  JobTypeStepDocumentTemplateApiType,
} from '@shared/modules/Job/forms/JobTypeStepDocumentTemplateForm';
import useUpdateJobTypeStepDocumentTemplateMutation, {
  JobTypeStepDocumentTemplateFormWrapperType,
} from '@shared/modules/Job/hooks/useUpdateJobTypeStepDocumentTemplateMutation';
import Line from 'components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const PageContainer = Styled.View`
  flex: 1;
`;

const ContentContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const ColumContainer = Styled.View`
  flex: 1;
`;

const ColumContentContainer = Styled.View`
  flex: 1;
  padding: 24px;
  `;

const HeaderContainer = Styled.View`
  background-color: ${colors.white};
  padding-horizontal: 24px;
  padding-vertical: 24px;
  border-bottom-width: 1px;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const VerticalLine = Styled.View`
  position: absolute;
  right: 0;
  height: 100%;
  border-right-width: 1px;
  border-color: ${colors.gray.border};
  z-index: 1;
`;

const DocumentItemsListContainer = Styled.View`
  background-color: ${colors.white};
  padding: 32px;
`;

const ButtonContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;
  background-color: ${colors.white};
`;

const DocumentPreviewContentContainer = Styled.View`
  width: 800px;
  padding-horizontal: 24px;
`;

const getSectionColumns = ({
  hasNonReadonlySections,
  readonlyUuids,
  onToggleAll,
  onPressItem,
}: {
  hasNonReadonlySections: boolean;
  readonlyUuids: string[];
  onToggleAll: () => void;
  onPressItem: (itemUuid: string) => void;
}): ColumnDefinitionType<DocumentItemSectionType>[] => [
  {
    flex: 1,
    headerComponent: () => {
      return (
        <Checkbox
          isChecked={hasNonReadonlySections}
          handleToggle={onToggleAll}
          checkboxIcon={readonlyUuids.length > 0 && hasNonReadonlySections ? 'minus' : undefined}
        />
      );
    },
    cellComponent: (item) => {
      return (
        <Checkbox
          isChecked={!readonlyUuids.includes(item.uuid)}
          handleToggle={() => onPressItem(item.uuid)}
        />
      );
    },
  },
  {
    flex: 3,
    headerLabel: 'Section Name',
    cellText: (item) => item.name,
  },
];

const getSections = (documentItems?: DocumentItemType[]) => {
  return (documentItems?.filter((item) => item.itemKind === DocumentItemKindV2.SECTION) ??
    []) as DocumentItemSectionType[];
};

const DocumentPreview = ({
  hoveredSectionIndex,
  documentContentJson,
  documentTemplateVersion,
  responsive,
  isEnabledRichTextEditorV2,
}: {
  hoveredSectionIndex?: number;
  documentContentJson?: DocumentContentJsonFormType;
  documentTemplateVersion: DocumentTemplateVersionModel;
  responsive: ResponsiveType;
  isEnabledRichTextEditorV2: boolean;
}) => {
  return (
    <ColumContainer style={{flex: 3}}>
      <HeaderContainer>
        <HeaderText responsive={responsive}>Preview: {documentTemplateVersion.name}</HeaderText>
      </HeaderContainer>
      <VerticalLine />
      <ScrollView horizontal contentContainerStyle={{flex: 1}}>
        <DocumentPreviewContentContainer>
          <ScrollView
            showsVerticalScrollIndicator={false}
            contentContainerStyle={{marginVertical: 24}}
          >
            {documentContentJson ? (
              <DocumentItemsListContainer>
                <DocumentItemsList
                  documentItems={documentContentJson.documentItems}
                  isPreview
                  isEditable={false}
                  highlightSectionIndex={hoveredSectionIndex}
                  isEnabledRichTextEditorV2={isEnabledRichTextEditorV2}
                  isEnabledDocumentPerPositionTimesheetTotals={
                    documentTemplateVersion.organization.features
                      .isEnabledDocumentPerPositionTimesheetTotals
                  }
                  isEnabledDocumentPerPositionTimesheetTimes={
                    documentTemplateVersion.organization.features
                      .isEnabledDocumentPerPositionTimesheetTimes
                  }
                />
              </DocumentItemsListContainer>
            ) : null}
          </ScrollView>
        </DocumentPreviewContentContainer>
      </ScrollView>
    </ColumContainer>
  );
};

const onToggleItem = (uuid: string, form: Form<JobTypeStepDocumentTemplateFormWrapperType>) => {
  const readonlySectionUuids =
    form.values.jobTypeStepDocumentTemplateForm.readonlySectionUuids ?? [];
  const newReadonlySectionUuids = _.xor(readonlySectionUuids, [uuid]);
  form.setFieldValue(
    'jobTypeStepDocumentTemplateForm.readonlySectionUuids',
    newReadonlySectionUuids,
  );
};

const DocumentSectionsSelectCheckboxes = ({
  hoveredSectionIndex,
  setHoveredSectionIndex,
  documentContentJson,
  form,
}: {
  hoveredSectionIndex?: number;
  setHoveredSectionIndex: (index?: number) => void;
  documentContentJson?: DocumentContentJsonFormType;
  form: Form<JobTypeStepDocumentTemplateFormWrapperType>;
}) => {
  const sections = getSections(documentContentJson?.documentItems);
  const hasNonReadonlySections =
    (form.values.jobTypeStepDocumentTemplateForm.readonlySectionUuids?.length ?? 0) <
    sections.length;
  const readonlyUuids = form.values.jobTypeStepDocumentTemplateForm.readonlySectionUuids ?? [];

  if (!sections.length) {
    return null;
  }

  return (
    <Table
      columnDefinitions={getSectionColumns({
        hasNonReadonlySections,
        readonlyUuids,
        onToggleAll: () => {
          form.setFieldValue(
            'jobTypeStepDocumentTemplateForm.readonlySectionUuids',
            hasNonReadonlySections ? sections.map((section) => section.uuid) : [],
          );
        },
        onPressItem: (uuid) => onToggleItem(uuid, form),
      })}
      onRowPress={(item) => onToggleItem(item.uuid, form)}
      onRowHover={(_, index) => setHoveredSectionIndex(index)}
      activeRowIndex={hoveredSectionIndex}
      items={sections}
      itemKey={'uuid'}
      hasBorder
    />
  );
};

const InfoBox = () => {
  return (
    <Callout
      text={
        'When selected, customers can complete sections with inputs in the Crew App. Deselect to keep them read-only, even if there are inputs.'
      }
    />
  );
};

const SelectDocumentSections = ({
  documentContentJson,
  form,
  hoveredSectionIndex,
  setHoveredSectionIndex,
  handleSubmit,
  isSubmitting,
  onPressCancel,
  responsive,
}: {
  hoveredSectionIndex?: number;
  setHoveredSectionIndex: (item?: number) => void;
  documentContentJson?: DocumentContentJsonFormType;
  form: Form<JobTypeStepDocumentTemplateFormWrapperType>;
  handleSubmit: () => void;
  isSubmitting?: boolean;
  onPressCancel: () => void;
  responsive: ResponsiveType;
}) => {
  return (
    <ColumContainer style={{flex: 2}}>
      <ScrollView>
        <HeaderContainer>
          <HeaderText responsive={responsive}>Select Document Sections</HeaderText>
        </HeaderContainer>
        <ColumContentContainer>
          <InfoBox />
          <Space height={16} />
          <DocumentSectionsSelectCheckboxes
            documentContentJson={documentContentJson}
            form={form}
            hoveredSectionIndex={hoveredSectionIndex}
            setHoveredSectionIndex={setHoveredSectionIndex}
          />
        </ColumContentContainer>
      </ScrollView>
      <Line />
      <ButtonContainer>
        <TertiaryButton text='Cancel' textColor={colors.gray.secondary} onPress={onPressCancel} />
        <Space width={16} />
        <Button
          iconLeft={Icon.Check}
          text='Save'
          width={120}
          onPress={handleSubmit}
          isSubmitting={isSubmitting}
        />
      </ButtonContainer>
    </ColumContainer>
  );
};

const JobTypeStepsSelectDocumentsSectionContent = ({
  documentTemplateVersion,
  jobTypeStepDocumentTemplate,
  handleClose,
  refetch,
}: {
  documentTemplateVersion: DocumentTemplateVersionModel;
  jobTypeStepDocumentTemplate: JobTypeStepDocumentTemplateApiType;
  handleClose: () => void;
  refetch: () => void;
}) => {
  const [hoveredSectionIndex, setHoveredSectionIndex] = useState<number | undefined>();
  const [documentContentJson, setDocumentContentJson] = useState<DocumentContentJsonFormType>();
  const responsive = useResponsive();

  const {form, submitting, handleSubmit} = useUpdateJobTypeStepDocumentTemplateMutation({
    jobTypeStepDocumentTemplateForm: JobTypeStepDocumentTemplateForm.edit(
      jobTypeStepDocumentTemplate,
    ),
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors) => console.log({errors}),
  });

  useEffect(() => {
    setDocumentContentJson(
      Json.toObject(documentTemplateVersion.documentContentJson) as DocumentContentJsonFormType,
    );
  }, [documentTemplateVersion]);

  return (
    <PageContainer>
      <ContentContainer>
        <DocumentPreview
          hoveredSectionIndex={hoveredSectionIndex}
          documentContentJson={documentContentJson}
          documentTemplateVersion={documentTemplateVersion}
          responsive={responsive}
          isEnabledRichTextEditorV2={
            documentTemplateVersion.organization.features.isEnabledRichTextEditorV2
          }
        />
        <SelectDocumentSections
          hoveredSectionIndex={hoveredSectionIndex}
          setHoveredSectionIndex={setHoveredSectionIndex}
          documentContentJson={documentContentJson}
          form={form}
          handleSubmit={handleSubmit}
          isSubmitting={submitting}
          onPressCancel={handleClose}
          responsive={responsive}
        />
        <Space height={48} />
      </ContentContainer>
    </PageContainer>
  );
};

interface JobTypeStepsSelectDocumentSectionDrawerProps {
  documentTemplateVersionUuid?: string;
  documentTemplateUuid?: string;
  jobTypeUuid: string;
  jobTypeStepKind?: string;
  drawerHandler: DrawerType;
  refetch: () => void;
}

const JobTypeStepsSelectDocumentSectionDrawer = ({
  drawerHandler,
  documentTemplateUuid,
  documentTemplateVersionUuid,
  jobTypeUuid,
  jobTypeStepKind,
  refetch,
}: JobTypeStepsSelectDocumentSectionDrawerProps) => {
  const showDrawer = drawerHandler.isOpen && !!documentTemplateUuid;
  const {loading, data} = useQuery(JobTypeStepsSelectDocumentSectionDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !showDrawer,
    variables: {
      documentTemplateVersionUuid,
      documentTemplateUuid,
      jobTypeUuid,
      step: jobTypeStepKind,
    },
  });

  return (
    <Drawer
      isOpen={showDrawer}
      handleClose={drawerHandler.handleClose}
      width={window.innerWidth * 0.8}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          if (!data) {
            return null;
          }
          return (
            <JobTypeStepsSelectDocumentsSectionContent
              documentTemplateVersion={data.documentTemplateVersion}
              jobTypeStepDocumentTemplate={
                data.documentTemplate.jobTypeStepDocumentTemplateByJobTypeAndStep
              }
              handleClose={drawerHandler.handleClose}
              refetch={refetch}
            />
          );
        }}
      </Loading>
    </Drawer>
  );
};

JobTypeStepsSelectDocumentSectionDrawer.query = gql`
query JobTypeStepsSelectDocumentSectionDrawer(
  $documentTemplateVersionUuid: String!,
  $documentTemplateUuid: String!,
  $jobTypeUuid: String!
  $step: String!
) {
    ${gql.query}
    documentTemplateVersion(uuid: $documentTemplateVersionUuid) {
      id
      uuid
      name
      documentContentJson
      documentTemplateId
      organization {
        id
        features {
          isEnabledRichTextEditorV2: isEnabled(feature: "RICH_TEXT_EDITOR_V2")
          isEnabledDocumentPerPositionTimesheetTotals: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TOTALS")
          isEnabledDocumentPerPositionTimesheetTimes: isEnabled(feature: "DOCUMENT_PER_POSITION_TIMESHEET_TIMES")
        }
      }
    }
    documentTemplate(uuid: $documentTemplateUuid) {
      id
      jobTypeStepDocumentTemplateByJobTypeAndStep(jobTypeUuid: $jobTypeUuid, step: $step) {
        id
        index
        jobTypeStepId
        organizationId
        documentTemplateId
        readonlySectionUuids
      }
    }
}
`;

export default JobTypeStepsSelectDocumentSectionDrawer;
