// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
  max-width: 600px;
`;

const Logo = Styled.H1`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H3`
  margin-top: 30px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

type OwnProps = {};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof InvalidResetPasswordTokenView.defaultProps;

// @ts-expect-error TS(7022): 'InvalidResetPasswordTokenView' implicitly has typ... Remove this comment to see the full error message
const InvalidResetPasswordTokenView = ({responsive}: Props) => (
  <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
    <Content {...responsive}>
      <Logo>Supermove</Logo>
      <Title>Reset Password</Title>
      <Subtitle>The password link is expired, please request another.</Subtitle>
    </Content>
  </ScrollView>
);

InvalidResetPasswordTokenView.defaultProps = {};

export default InvalidResetPasswordTokenView;
