// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {BillModel, PaymentFeeModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Datetime} from '@supermove/utils';

// App
import TextTooltip from '@shared/design/components/TextTooltip';

const Row = Styled.View`
  flex-direction: row;
`;

const Text = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Container = Styled.View<{isEditable?: boolean}>`
  padding: ${({isEditable}) => (isEditable ? 12 : 0)}px;
  border-radius: ${({isEditable}) => (isEditable ? 8 : 0)}px;
  border-width: ${({isEditable}) => (isEditable ? 1 : 0)}px;
  border-color: ${colors.gray.border};
  background-color: ${colors.white};
`;

const PaymentFee = ({paymentFee}: {paymentFee: PaymentFeeModel}) => {
  const responsive = useResponsive();
  const isDiscount = paymentFee.amount < 0;
  const paymentText = `Payment of ${Currency.format({value: paymentFee.payment.totalAmount})} on ${Datetime.convertToDisplayDatetime(
    paymentFee.payment.createdAt,
    Datetime.DISPLAY_DATETIME,
  )}`;
  return (
    <Row>
      <TextTooltip isEnabledMobileToast={false} text={paymentText} placement={'top'}>
        <Text responsive={responsive} numberOfLines={1}>
          {paymentFee.displayName}
        </Text>
      </TextTooltip>
      <Space flex={1} />
      <Text
        responsive={responsive}
        style={{color: isDiscount ? colors.green.status : colors.gray.primary}}
      >
        {Currency.format({value: paymentFee.amount, shouldHideCentsIfZero: false})}
      </Text>
    </Row>
  );
};

const BillPaymentFeesList = ({bill, isEditable}: {bill: BillModel; isEditable: boolean}) => {
  const responsive = useResponsive();
  return (
    <Container
      isEditable={isEditable}
      style={responsive.mobile ? null : {width: 400, alignSelf: 'flex-end'}}
    >
      {bill.paymentFees.map((paymentFee, index) => (
        <React.Fragment key={paymentFee.id}>
          {index > 0 && <Space height={8} />}
          <PaymentFee paymentFee={paymentFee} />
        </React.Fragment>
      ))}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillPaymentFeesList.fragment = gql`
  fragment BillPaymentFeesList on Bill {
    id
    paymentFees {
      id
      displayName
      amount
      payment {
        id
        totalAmount
        createdAt
      }
    }
  }
`;

export default BillPaymentFeesList;
