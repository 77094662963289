// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useForm} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';
import {Float} from '@supermove/utils';

// App
import Modal from 'components/Modal';

const Container = Styled.View`
  padding: 20px;
`;

const Field = Styled.View`
  flex-direction: row;
  align-items: center;
  padding-vertical: 10px;
`;

const Name = Styled.H7`
  flex: 1;
  color: ${colors.gray.primary};
  text-transform: uppercase;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Actions = Styled.View`
  align-items: stretch;
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 10px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const CounterTouchable = Styled.Touchable`
`;

const CounterButton = ({disabled, count, onPress, children}: any) => {
  return (
    <CounterTouchable disabled={disabled} onPress={onPress}>
      <Value style={{width: 40}}>{children}</Value>
    </CounterTouchable>
  );
};

const Value = Styled.View`
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props as any).color};
  border-width: 1px;
  border-color: ${colors.gray.border};
`;

const TextInput = Styled.TextInput.H7`
`;

const ActivityIndicator = Styled.Loading`
`;

const CounterInput = ({name, count, itemForm, form}: any) => {
  const disabled = count === 0;
  const onDecrement = () => form.setFieldValue(name, Float.toForm(count - 1));
  const onIncrement = () => form.setFieldValue(name, Float.toForm(count + 1));

  return (
    <Row>
      <CounterButton disabled={disabled} onPress={onDecrement}>
        <Icon
          color={disabled ? colors.gray.tertiary : colors.gray.primary}
          size={16}
          source={Icon.Minus}
        />
      </CounterButton>
      <TextInput
        keyboardType={'phone-pad'}
        value={_.get(form, `values.${name}`)}
        placeholder={'Count'}
        onChangeText={(value: any) => form.setFieldValue(name, value)}
        style={{
          width: 80,
          height: 40,
          borderRadius: 0,
          borderColor: colors.blue.accent,
          textAlign: 'center',
        }}
      />
      <CounterButton onPress={onIncrement}>
        <Icon color={colors.gray.primary} size={16} source={Icon.Plus} />
      </CounterButton>
    </Row>
  );
};

const CreateCustomItemButton = ({handleOpen, handleClose}: any) => {
  return (
    <Button
      color={colors.alpha(colors.blue.accentDark, 0.1)}
      onPress={() => {
        handleClose();
        handleOpen();
      }}
    >
      <Text color={colors.blue.accentDark}>Create Custom Version</Text>
    </Button>
  );
};

const UpdateItemModal = ({
  canCreateItem,
  isOpen,
  index,
  field,
  itemForm,
  form: parentForm,
  inventory,
  handleClose,
  handleOpenCreateItemModal,
}: any) => {
  const form = useForm({
    initialValues: {
      itemForm,
    },
    onSubmit: ({itemForm: newItemForm}) => {
      const {itemForms} = parentForm.values.collectionForm;
      const newItemForms = itemForms.map((itemForm: any, itemFormIndex: any) => {
        return itemFormIndex === index ? newItemForm : itemForm;
      });

      // Add the new ItemForm to the collection and submit the parent form.
      parentForm.setFieldValue('collectionForm.itemForms', newItemForms);
      parentForm.setFieldValue('collectionForm.hasChanges', true);
      handleClose();
    },
  });

  const takeCount = Float.toFloat(form.values.itemForm.takeCount);
  const leaveCount = Float.toFloat(form.values.itemForm.leaveCount);
  const {isSubmitting} = form;

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} title={itemForm.name} subtitle={''} onClose={handleClose}>
        <Container>
          <Field>
            <Name>Going</Name>
            <CounterInput
              name={`itemForm.takeCount`}
              count={takeCount}
              itemForm={itemForm}
              form={form}
            />
          </Field>
          <Field>
            <Name>Not Going</Name>
            <CounterInput
              name={`itemForm.leaveCount`}
              count={leaveCount}
              itemForm={itemForm}
              form={form}
            />
          </Field>
          <Space height={20} />
          <Actions>
            <Button
              color={colors.blue.accentDark}
              onPress={() => {
                form.handleSubmit();
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <ActivityIndicator size={'small'} color={colors.white} />
              ) : (
                <Text color={colors.white}>Update</Text>
              )}
            </Button>
            {canCreateItem && (
              <React.Fragment>
                <Space height={10} />
                <CreateCustomItemButton
                  handleOpen={handleOpenCreateItemModal}
                  handleClose={handleClose}
                />
              </React.Fragment>
            )}
          </Actions>
        </Container>
      </Modal>
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateItemModal.fragment = gql`
  fragment UpdateItemModal on Inventory {
    id
  }
`;

export default UpdateItemModal;
