// Libraries
import React from 'react';

// Supermove
import {Loading, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useToggle, useInlineFormMutation} from '@supermove/hooks';

// App
import EditPanel from '@shared/design/components/Panel/EditPanel';
import CompanyTabletSettingsForm from '@shared/modules/Company/forms/CompanyTabletSettingsForm';
import useUpdateCompanyTabletSettingsMutation from '@shared/modules/Organization/hooks/useUpdateCompanyTabletSettingsMutation';
import SettingsForm from '@shared/modules/Settings/forms/SettingsForm';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';
import CrewVisibleJobsPanel from 'modules/Organization/Settings/Company/components/CrewVisibleJobsPanel';
import CrewVisibleJobsPanelEdit from 'modules/Organization/Settings/Company/components/CrewVisibleJobsPanelEdit';
import TabletLoginsPanel from 'modules/Organization/Settings/Company/components/TabletLoginsPanel';

const CrewLogins = ({index, organization}: any) => {
  const editTabletLoginsToggle = useToggle();
  const companyTabletSettingsForm = CompanyTabletSettingsForm.edit(organization);
  const {form, handleSubmit, submitting} = useUpdateCompanyTabletSettingsMutation({
    companyTabletSettingsForm,
    onSuccess: editTabletLoginsToggle.handleToggleOff,
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      bodyComponentProps={{organization}}
      editBodyComponentProps={{form}}
      BodyComponent={TabletLoginsPanel}
      EditBodyComponent={TabletLoginsPanel.Edit}
      title={'Logins'}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={editTabletLoginsToggle.isOn}
      handleEdit={editTabletLoginsToggle.handleToggleOn}
      handleClose={editTabletLoginsToggle.handleToggleOff}
    />
  );
};

const CrewVisibleJobs = ({index, organization, viewer, refetch}: any) => {
  const isCrewVisibleJobsEditable = organization.isPrimary || organization.isFranchise;
  const editCrewVisibleJobsToggle = useToggle();
  const settingsForm = SettingsForm.edit(organization.settings, {updatedById: viewer.id});
  const {form, handleSubmit, submitting} = useInlineFormMutation({
    name: 'settingsForm',
    form: settingsForm,
    toForm: SettingsForm.toForm,
    toMutation: SettingsForm.toMutation,
    mutation: gql`
      mutation useUpdateSettingsCrewVisibleJobsMutation($settingsForm: SettingsForm!) {
        response: updateSettingsCrewVisibleJobs(settingsForm: $settingsForm) {
          ${gql.errors}
          settings {
            id
            crewVisibleJobsByProjectStatus
            crewVisibleJobsForMover
          }
        }
      }
    `,
    onSuccess: () => {
      refetch();
      editCrewVisibleJobsToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });

  return (
    <EditPanel
      index={index}
      title={'Crew Viewing Permissions'}
      headerSubtitle={'Set which jobs the movers can see on the Crew App.'}
      isEditButtonVisible={isCrewVisibleJobsEditable}
      BodyComponent={CrewVisibleJobsPanel}
      bodyComponentProps={{organization, isCrewVisibleJobsEditable}}
      EditBodyComponent={CrewVisibleJobsPanelEdit}
      editBodyComponentProps={{form}}
      isEditing={editCrewVisibleJobsToggle.isOn}
      handleEdit={editCrewVisibleJobsToggle.handleToggleOn}
      handleClose={editCrewVisibleJobsToggle.handleToggleOff}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
    />
  );
};

const CompanyCrewAppContent = ({organization, viewer, refetch}: any) => {
  return (
    <React.Fragment>
      <CrewLogins index={0} organization={organization} />
      <Space height={24} />
      <CrewVisibleJobs index={1} organization={organization} viewer={viewer} refetch={refetch} />
    </React.Fragment>
  );
};

const OrganizationSettingsCompanyCrewAppPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyCrewAppPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Crew App'}
      description={'Manage the Crew App for your crews, branches, and contractors.'}
      isResponsive
    >
      <Loading loading={loading}>
        {() => (
          <CompanyCrewAppContent
            organization={data.viewer.viewingOrganization}
            viewer={data.viewer}
            refetch={refetch}
          />
        )}
      </Loading>
    </CompanySettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

OrganizationSettingsCompanyCrewAppPage.query = gql`
  ${CompanyTabletSettingsForm.edit.fragment}
  ${SettingsForm.edit.fragment}
  ${CrewVisibleJobsPanel.fragment}
  ${CrewVisibleJobsPanelEdit.fragment}

  query OrganizationSettingsCompanyCrewAppPage {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        ...CompanyTabletSettingsForm_edit
        ...CrewVisibleJobsPanel
        ...CrewVisibleJobsPanelEdit
        settings {
          id
          ...SettingsForm_edit
        }
      }
    }
  }
`;

export default OrganizationSettingsCompanyCrewAppPage;
