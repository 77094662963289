// Libraries
import React from 'react';

// Supermove
import {PreventPropagation, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useSheet, UrlFiltersType} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import IconButton from '@shared/design/components/IconButton';
import Sheet from '@shared/design/components/Sheet';
import CapacityCalendarDayPickerBlock from 'modules/Calendar/Capacity/components/CapacityCalendarDayPickerBlock';

const DayText = Styled.Text`
  ${Typography.Responsive.Subheading};
`;

const DatePickerContainer = Styled.View`
  align-items: center;
  background-color: ${colors.white};
  flex: 1;
`;

const MobileCapacityCalendarDayNavigator = ({
  urlFilters,
  organization,
}: {
  urlFilters: UrlFiltersType;
  organization: OrganizationModel;
}) => {
  const responsive = useResponsive();
  const date = urlFilters.get('date') || Datetime.today;
  const datePickerSheet = useSheet({name: 'Capacity Calendar Date Selector Sheet'});

  return (
    <TertiaryButton onPress={datePickerSheet.handleOpen} isResponsive>
      <DayText responsive={responsive}>
        {Datetime.convertToDisplayDate(date, 'ddd, MMMM Do')}
      </DayText>
      <Space width={8} />
      <IconButton source={IconButton.SOURCE.AngleDown} isSecondary isDisplayOnly />
      <PreventPropagation>
        <Sheet
          height={'400px'}
          isOpen={datePickerSheet.isOpen}
          handleClose={datePickerSheet.handleClose}
          headerContainerStyle={{
            backgroundColor: colors.white,
            paddingHorizontal: 32,
            paddingBottom: 0,
          }}
          HeaderLeftComponent={() => {
            return (
              <TertiaryButton
                text={'Today'}
                onPress={() => {
                  urlFilters.handleUpdate({date: undefined});
                  datePickerSheet.handleClose();
                }}
              />
            );
          }}
        >
          <DatePickerContainer>
            <CapacityCalendarDayPickerBlock
              selectedDate={date}
              setSelectedDate={(date: string) => {
                urlFilters.handleUpdate({date});
                datePickerSheet.handleClose();
              }}
              organization={organization}
            />
          </DatePickerContainer>
        </Sheet>
      </PreventPropagation>
    </TertiaryButton>
  );
};

MobileCapacityCalendarDayNavigator.fragment = gql`
  ${CapacityCalendarDayPickerBlock.fragment}

  fragment MobileCapacityCalendarDayNavigator on Organization {
    id
    ...CapacityCalendarDayPickerBlock
  }
`;

export default MobileCapacityCalendarDayNavigator;
