/* eslint-disable react-hooks/exhaustive-deps */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useEffect, useModal, useQuery, useResponsive, useState, useToast} from '@supermove/hooks';
import {PayengineCreditCard, PaymentMethod} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import RadioButton from '@shared/design/components/RadioButton';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import BeginPaymentV3Form from '@shared/modules/Payment/forms/BeginPaymentV3Form';
import PayengineCreditCardForm from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import {transactionContactMessage} from '@shared/modules/Payment/hooks/payengineErrors';
import useChargePayEngineCreditCardV2 from '@shared/modules/Payment/hooks/useChargePayEngineCreditCardV2';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import InvoiceCreditCardsRadioButtonList from 'modules/Accounting/components/InvoiceCreditCardsRadioButtonList';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ClientCreditCardFields from 'modules/Client/components/ClientCreditCardFields';
import ClientCreditCardInformationFields from 'modules/Client/components/ClientCreditCardInformationFields';
import PaymentTotals from 'modules/Payment/components/PaymentTotals';

const PaymentAmountOptions = {
  REMAINING_BALANCE: 'REMAINING_BALANCE',
  CUSTOM_AMOUNT: 'CUSTOM_AMOUNT',
};

const Container = Styled.View`
  width: 100%;
`;

const AddCardButton = Styled.ButtonV2`
  flex-direction: row;
  padding-vertical: 16px;
  padding-horizontal: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'isSelected' does not exist on type 'Them... Remove this comment to see the full error message
    isSelected,
  }) => (isSelected ? colors.blue.accent : 'transparent')};
`;

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const AddCardText = Styled.Text`
  ${Typography.Responsive.Body}
`;

const AddCardOption = ({
  client,
  form,
  field,
  creditCard,
  setCreditCard,
  errorMessage,
  setErrorMessage,
  setCreditCardClient,
  isDisabled,
}: any) => {
  const responsive = useResponsive();
  const isAddingCard = !creditCard.id;

  useEffect(() => {
    if (isAddingCard) {
      form.setFieldValue(`${field}.saveToClientId`, client.id);
    } else {
      form.setFieldValue(`${field}.saveToClientId`, null);
    }
  }, [isAddingCard]);

  return (
    <AddCardButton
      isSelected={isAddingCard}
      onPress={() => {
        setCreditCard({});
        setErrorMessage('');
      }}
      disabled={isAddingCard || isDisabled}
    >
      <RadioButton isOn={isAddingCard} />
      <Space width={8} />
      <Column>
        <AddCardText responsive={responsive}>{`Add a new card`}</AddCardText>
        {isAddingCard && (
          <React.Fragment>
            {errorMessage && (
              <React.Fragment>
                <Space height={16} />
                <ErrorCallout text={errorMessage} />
              </React.Fragment>
            )}
            <Space height={16} />
            <ClientCreditCardFields
              client={client}
              form={form}
              field={`${field}.payengineCreditCardForm`}
              setCreditCardClient={setCreditCardClient}
            />
          </React.Fragment>
        )}
      </Column>
    </AddCardButton>
  );
};

const handleUpdateTotals = ({form, field, paymentMethod, amount}: any) => {
  form.setFieldValue(`${field}.subtotal`, Currency.toForm(amount));
  const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(paymentMethod, {amount});
  form.setFieldValue(`${field}.paymentFeeAmount`, Currency.toForm(paymentFeeAmount));
  form.setFieldValue(`${field}.amount`, Currency.toForm(paymentFeeAmount + amount));
};

const ChargeCreditCardFields = ({
  invoice,
  form,
  field,
  creditCard,
  setCreditCard,
  errorMessage,
  setErrorMessage,
  setCreditCardClient,
  isDisabled,
  paymentMethod,
}: any) => {
  const [hasAmountWarning, setHasAmountWarning] = useState(false);
  const [selectedPaymentAmount, setSelectedPaymentAmount] = useState(
    PaymentAmountOptions.REMAINING_BALANCE,
  );
  const client = invoice.project.billingClient;
  const paymentField = `${field}.beginPaymentV3Form`;

  return (
    <Container>
      <FieldInput
        {...form}
        label={'Payment Name'}
        name={`${paymentField}.name`}
        isRequired
        isResponsive
        input={{placeholder: `Enter a payment name`}}
      />
      <Space height={16} />
      <FieldInput.LabelText isResponsive isRequired>
        Payment Amount
      </FieldInput.LabelText>
      <FieldInput.Spacer height={FieldInput.SIZE.SMALL} />
      <RadioButton
        isOn={selectedPaymentAmount === PaymentAmountOptions.REMAINING_BALANCE}
        label={`Pay remaining balance in full: ${Currency.display(invoice.remainingBalance)}`}
        onPress={() => {
          handleUpdateTotals({
            form,
            field: paymentField,
            paymentMethod,
            amount: invoice.remainingBalance,
          });
          setSelectedPaymentAmount(PaymentAmountOptions.REMAINING_BALANCE);
        }}
      />
      <Space height={8} />
      <RadioButton
        isOn={selectedPaymentAmount === PaymentAmountOptions.CUSTOM_AMOUNT}
        label={'Pay other amount'}
        onPress={() => {
          form.setFieldValue(`${paymentField}.subtotal`, '');
          form.setFieldValue(`${paymentField}.paymentFeeAmount`, '');
          form.setFieldValue(`${paymentField}.amount`, '');
          setSelectedPaymentAmount(PaymentAmountOptions.CUSTOM_AMOUNT);
        }}
      />
      {selectedPaymentAmount === PaymentAmountOptions.CUSTOM_AMOUNT && (
        <React.Fragment>
          <Space height={8} />
          <Row>
            <Space width={24} />
            <FieldInput
              {...form}
              component={CurrencyInput}
              name={`${paymentField}.subtotal`}
              isResponsive
              hasWarning={hasAmountWarning}
              warningMessage={'Amount exceeds remaining balance'}
              handleBlur={(e) => {
                const amount = e.currentTarget.value;
                const amountInCents = Currency.convertToCents(amount);
                const isAboveRemainingBalance = amount && amountInCents > invoice.remainingBalance;
                setHasAmountWarning(isAboveRemainingBalance);
                handleUpdateTotals({
                  form,
                  field: paymentField,
                  paymentMethod,
                  amount: amountInCents,
                });
              }}
              input={{
                component: FieldInput.TextInput,
                setFieldValue: form.setFieldValue,
                setFieldTouched: form.setFieldTouched,
                placeholder: `Enter a payment amount`,
              }}
              style={{flex: 1}}
            />
          </Row>
        </React.Fragment>
      )}
      <Space height={16} />
      <FieldInput.LabelText isResponsive isRequired>
        Payment Method
      </FieldInput.LabelText>
      <Space height={4} />
      <InvoiceCreditCardsRadioButtonList
        invoice={invoice}
        handleSelectCard={(card: any) => {
          form.setFieldValue(`${field}.saveToClientId`, null);
          form.setFieldValue(
            `${field}.payengineCreditCardForm`,
            PayengineCreditCardForm.toForm(PayengineCreditCardForm.edit(card)),
          );
          setCreditCard(card);
          setCreditCardClient(PayengineCreditCard.getCreditCardClient(card));
          setErrorMessage('');
        }}
        selectedCardId={creditCard.id}
        isDisabled={isDisabled}
        errorMessage={errorMessage}
      />
      <AddCardOption
        client={client}
        form={form}
        field={field}
        creditCard={creditCard}
        setCreditCard={setCreditCard}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        setCreditCardClient={setCreditCardClient}
        isDisabled={isDisabled}
      />
    </Container>
  );
};

const InvoiceChargeCreditCardDrawerContent = ({invoice, onSuccess, handleClose}: any) => {
  const client = invoice.project.billingClient;
  const defaultCard = client.creditCards.find((card: any) => card.isDefault);

  const [creditCard, setCreditCard] = useState(defaultCard || {});
  const [errorMessage, setErrorMessage] = useState('');

  const {payenginePaymentMethod: paymentMethod} = invoice.project.organization;
  const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(paymentMethod, {
    amount: invoice.remainingBalance,
  });
  const beginPaymentV3Form = BeginPaymentV3Form.forInvoice(invoice, {
    method: PaymentMethodKind.PAYENGINE_CREDIT_CARD,
    paymentMethodId: paymentMethod.id,
    paymentFeeAmount,
    amount: invoice.remainingBalance > 0 ? invoice.remainingBalance + paymentFeeAmount : 0,
  });
  const {form, handleSubmit, submitting, setCreditCardClient} = useChargePayEngineCreditCardV2({
    // @ts-expect-error TS(2741): Property 'jobId' is missing in type '{ invoiceId: ... Remove this comment to see the full error message
    beginPaymentV3Form,
    createCardResponse: {
      token: creditCard.token,
    },
    onSuccess,
    handleErrorMessage: (message) => {
      setErrorMessage(`${message} ${transactionContactMessage(invoice.project.organization.name)}`);
    },
    handleTokenizeErrorMessage: setErrorMessage,
    creditCard: defaultCard,
  });
  const confirmPaymentModal = useModal({name: 'Confirm Payment Modal'});
  const field = 'chargePayengineCreditCardForm';
  const possiblyHandleSubmit = () => {
    const paymentAmountInCents = Currency.convertToCents(
      _.get(form.values, `${field}.beginPaymentV3Form.amount`),
    );
    if (paymentAmountInCents > invoice.remainingBalance + paymentFeeAmount) {
      confirmPaymentModal.handleOpen();
    } else {
      handleSubmit();
    }
  };

  return (
    <React.Fragment>
      <Drawer.Body>
        <ChargeCreditCardFields
          invoice={invoice}
          form={form}
          field={field}
          creditCard={creditCard}
          setCreditCard={setCreditCard}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setCreditCardClient={setCreditCardClient}
          isDisabled={submitting}
          paymentMethod={paymentMethod}
        />
      </Drawer.Body>
      <PaymentTotals
        subtotal={_.get(form.values, `${field}.beginPaymentV3Form.subtotal`)}
        paymentFeeAmount={_.get(form.values, `${field}.beginPaymentV3Form.paymentFeeAmount`)}
        total={_.get(form.values, `${field}.beginPaymentV3Form.amount`)}
        paymentMethod={paymentMethod}
      />
      <Drawer.Footer
        isSubmitting={submitting}
        primaryAction={possiblyHandleSubmit}
        secondaryAction={handleClose}
        primaryActionText={'Charge'}
        isResponsive
      />
      <WarningModal
        key={confirmPaymentModal.key}
        isOpen={confirmPaymentModal.isOpen}
        title={'Proceed with payment?'}
        message={`The amount exceeds the remaining balance of ${Currency.display(invoice.remainingBalance)}.`}
        handlePrimaryAction={handleSubmit}
        handleSecondaryAction={confirmPaymentModal.handleClose}
        primaryActionText={'Confirm'}
        secondaryActionText={'Cancel'}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

const InvoiceChargeCreditCardDrawer = ({invoiceUuid, isOpen, handleClose, refetch}: any) => {
  const {loading, data} = useQuery(InvoiceChargeCreditCardDrawer.query, {
    fetchPolicy: 'network-only',
    skip: !isOpen,
    variables: {invoiceUuid},
  });

  const chargeCreditCardSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Card charged successfully',
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  return (
    <Drawer isOpen={isOpen} width={Drawer.WIDTH.DEFAULT}>
      <Drawer.Header headerText={'Charge Credit Card'} handleClose={handleClose} isResponsive />
      <Loading loading={loading || !data} as={PageLoadingIndicator}>
        {() => {
          const handleSuccess = () => {
            chargeCreditCardSuccessToast.handleToast();
            handleClose();
            refetch();
          };
          return (
            <InvoiceChargeCreditCardDrawerContent
              invoice={data.invoiceByUuid}
              onSuccess={handleSuccess}
              handleClose={handleClose}
            />
          );
        }}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceChargeCreditCardDrawer.query = gql`
  ${BeginPaymentV3Form.forInvoice.fragment}
  ${ClientCreditCardInformationFields.fragment}
  ${InvoiceCreditCardsRadioButtonList.fragment}
  ${PayengineCreditCard.getCreditCardClient.fragment}
  ${PayengineCreditCardForm.edit.fragment}
  ${useChargePayEngineCreditCardV2.fragment}
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  ${PaymentTotals.fragment}

  query InvoiceChargeCreditCardDrawer($invoiceUuid: String!) {
    ${gql.query}
    invoiceByUuid(invoiceUuid: $invoiceUuid) {
      id
      remainingBalance
      project {
        id
        billingClient {
          id
          creditCards {
            id
            token
            isDefault
            ...PayengineCreditCardForm_edit
            ...PayengineCreditCard_getCreditCardClient
            ...useChargePayEngineCreditCardV2
          }
          ...ClientCreditCardInformationFields
        }
        organization {
          id
          name
          payenginePaymentMethod {
            id
            ...PaymentTotals
            ...PaymentMethod_computePaymentFeeAmount
          }
        }
      }
      ...BeginPaymentV3Form_forInvoice
      ...InvoiceCreditCardsRadioButtonList
    }
  }
`;

export default InvoiceChargeCreditCardDrawer;
