// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, Styled} from '@supermove/components';
import {useState} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WebhookKind from '@shared/modules/Integration/enums/WebhookKind';

const ContentContainer = Styled.View`
  width: 400px;
  border-radius: 16px;
  background-color: ${colors.white};
  padding-horizontal: 24px;
`;

const TitleText = Styled.Text`
  ${Typography.Heading6}
`;

const Text = Styled.Text`
  ${Typography.Body2}
`;

const FooterRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const FooterButton = Styled.ButtonV2`
  width: 104px;
  height: 40px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const FooterButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const handleSave = ({
  form,
  handleSubmit,
  setHeaderError,
  setNameError,
  setKindError,
  setUrlError,
}: any) => {
  // Must validate errors on the frontend as well as the backend because
  // sending invalid headers that are invalid json breaks all backend errors
  // and only having header validation on the frontend will not give backend errors
  const {name, kind, url, headers} = form.values.webhookForm;
  const nameValid = validateName({name, setNameError});
  const kindValid = validateKind({kind, setKindError});
  const urlValid = validateUrl({url, setUrlError});
  const headersValid = validateHeaders({headers, setHeaderError});
  nameValid && kindValid && urlValid && headersValid && handleSubmit();
};

const validateName = ({name, setNameError}: any) => {
  if (!name) {
    setNameError({'webhookForm.name': 'Name is required'});
    return false;
  }
  setNameError(null);
  return true;
};

const validateKind = ({kind, setKindError}: any) => {
  if (!kind) {
    setKindError({'webhookForm.kind': 'Event is required'});
    return false;
  }
  setKindError(null);
  return true;
};

const validateUrl = ({url, setUrlError}: any) => {
  if (!url) {
    setUrlError({'webhookForm.url': 'Url is required'});
    return false;
  }
  try {
    // eslint-disable-next-line
    const testUrl = new URL(url); // Disabling linter error. new URL is used to just validate if the URL is valid
  } catch (exception) {
    setUrlError({'webhookForm.url': 'Invalid Url format.'});
    return false;
  }
  setUrlError(null);
  return true;
};

const validateHeaders = ({headers, setHeaderError}: any) => {
  if (!headers) {
    setHeaderError({'webhookForm.headers': 'Headers are required'});
    return false;
  }
  try {
    JSON.parse(headers);
    setHeaderError(null);
  } catch (exception) {
    setHeaderError({'webhookForm.headers': 'Invalid json format.'});
    return false;
  }
  return true;
};

const OrganizationWebhookModalContent = ({
  form,
  handleClose,
  handleSubmit,
  submitting,
  titleText,
  subTitleText,
}: any) => {
  const [headerError, setHeaderError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [kindError, setKindError] = useState(null);
  const [urlError, setUrlError] = useState(null);
  return (
    <ContentContainer>
      <Space height={24} />
      <TitleText>{titleText}</TitleText>
      <Space height={20} />
      <Text>{subTitleText}</Text>
      <Space height={20} />
      <FieldInput
        {...form}
        index={0}
        label={'Name'}
        name={'webhookForm.name'}
        touched={nameError}
        errors={nameError}
        input={{
          required: !_.get(form.values, 'webhookForm.name'),
          placeholder: 'Enter Name',
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        index={1}
        label={'Description'}
        name={'webhookForm.description'}
        input={{
          placeholder: 'Enter Description',
          multiline: true,
          style: {
            height: 100,
            paddingTop: 10,
            paddingBottom: 10,
            textAlignVertical: 'top',
          },
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        index={2}
        label={'Event'}
        name={'webhookForm.kind'}
        touched={kindError}
        errors={kindError}
        component={DropdownInput}
        input={{
          required: !_.get(form.values, 'webhookForm.kind'),
          options: WebhookKind.DROPDOWN_OPTIONS,
          placeholder: 'Select an event',
          setFieldValue: form.setFieldValue,
          style: {width: '100%'},
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        index={3}
        label={'URL'}
        name={'webhookForm.url'}
        touched={urlError}
        errors={urlError}
        input={{
          required: !_.get(form.values, 'webhookForm.url'),
          placeholder: 'Enter URL',
        }}
      />
      <Space height={12} />
      <FieldInput
        {...form}
        index={4}
        label={'Headers'}
        name={'webhookForm.headers'}
        touched={headerError}
        errors={headerError}
        input={{
          required: !_.get(form.values, 'webhookForm.headers'),
          placeholder: '{}',
          multiline: true,
          style: {
            height: 300,
            textAlignVertical: 'top',
            paddingTop: 10,
            paddingBottom: 10,
          },
        }}
      />
      <Space height={20} />
      <FooterRow>
        <FooterButton color={colors.white} onPress={handleClose} disabled={submitting}>
          <FooterButtonText color={colors.gray.secondary}>Cancel</FooterButtonText>
        </FooterButton>
        <FooterButton
          onPress={() =>
            handleSave({
              form,
              handleSubmit,
              setHeaderError,
              setKindError,
              setNameError,
              setUrlError,
            })
          }
          color={colors.blue.interactive}
          disabled={submitting}
        >
          <FooterButtonText color={colors.white}>Save</FooterButtonText>
        </FooterButton>
      </FooterRow>
      <Space height={24} />
    </ContentContainer>
  );
};

export default OrganizationWebhookModalContent;
