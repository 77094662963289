// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {DropdownInput, Icon, TimeInput, Styled} from '@supermove/components';
import {TimeRange} from '@supermove/models';
import {colors} from '@supermove/styles';

// Components
import {Field} from 'modules/App/components';

const Container = Styled.View`
  flex-direction: row;
  align-items: flex-start;
  padding-vertical: 10px;
`;

const Line = Styled.View`
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const Left = Styled.View`
  align-self: stretch;
  justify-content: center;
`;

const Circle = Styled.View`
  margin-right: 10px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: ${(props) => (props as any).color};
`;

const TimeInputs = Styled.View`
  flex: 1;
  flex-direction: row;
  padding-horizontal: 10px;
`;

const Input = Styled.View`
  flex: 1;
`;

const TimeSpace = Styled.View`
  width: 10px;
`;

const TextInput = Styled.TextInput.H7`
`;

const Button = Styled.Button`
  align-self: stretch;
  align-items: center;
  width: 40px;
`;

const KIND_OPTIONS = [
  {label: 'Work time', value: 'WORK'},
  {label: 'Drive time', value: 'DRIVE'},
  {label: 'Break time', value: 'BREAK'},
  {label: 'Wait time', value: 'WAIT'},
];

const UpdateTimeRangeItem = ({isFirst, form, index}: any) => (
  <Container>
    {isFirst && <Line />}
    <Left>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Circle color={TimeRange.getColor(_.get(form.values, `timeRangeInputs.${index}`))} />
    </Left>
    <Field
      {...form}
      component={DropdownInput}
      name={`timeRangeInputs.${index}.kind`}
      input={{
        options: KIND_OPTIONS,
        placeholder: 'Select',
        setFieldValue: (_field: any, kind: any) => {
          form.setFieldValue(`timeRangeInputs.${index}.kind`, kind);
          form.setFieldValue(`timeRangeInputs.${index}.name`, TimeRange.getName({kind}));
        },
        style: {
          width: 150,
        },
      }}
    />
    <TimeInputs>
      <Input>
        <Field
          {...form}
          component={TimeInput}
          name={`timeRangeInputs.${index}.start`}
          input={{
            disabled: index > 0 && form.values.timeRangeInputs.length > 0,
            component: TextInput,
            placeholder: 'Start Time',
            setFieldValue: form.setFieldValue,
          }}
        />
      </Input>
      <TimeSpace />
      <Input>
        <Field
          {...form}
          component={TimeInput}
          name={`timeRangeInputs.${index}.end`}
          input={{
            component: TextInput,
            placeholder: 'End Time',
            setFieldValue: (name: any, value: any) => {
              form.setFieldValue(name, value);
              if (index < form.values.timeRangeInputs.length - 1) {
                form.setFieldValue(`timeRangeInputs.${index + 1}.start`, value);
              }
            },
          }}
        />
      </Input>
    </TimeInputs>
    <Button
      color={colors.blue.accent}
      onPress={() => {
        const newTimeRangeInputs = form.values.timeRangeInputs
          .slice(0, index)
          .concat(form.values.timeRangeInputs.slice(index + 1));
        if (index > 0 && index < newTimeRangeInputs.length) {
          newTimeRangeInputs[index].start = newTimeRangeInputs[index - 1].end;
        }
        form.setFieldValue('timeRangeInputs', newTimeRangeInputs);
      }}
    >
      <Icon color={colors.blue.interactive} size={Icon.Sizes.Large} source={Icon.Trash} />
    </Button>
    <Line />
  </Container>
);

export default UpdateTimeRangeItem;
