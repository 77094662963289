/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {RadioInput, Space, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Index = Styled.H7`
  color: ${colors.gray.primary};
`;

const Label = Styled.H7`
  flex: 1;
  color: ${colors.gray.primary};
`;

const Content = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Input = Styled.TextInput.H7`
  width: 500px;
`;

const ValidationError = Styled.H7`
  margin-top: 3px;
  color: ${colors.red.warning};
`;

const DocumentAdditionalInfoRadioButtonWithTextInput = ({
  isFirst,
  isLast,
  name,
  index,
  item,
  value,
  errors,
  setFieldValue,
  setFieldTouched,
}: any) => {
  const [label, radioValue, textValue] = value;
  const {options, inputs} = item;
  const shouldShowTextInput = !!radioValue && !!_.get(inputs, _.camelCase(radioValue));
  const radioError = _.get(errors, `${name}.1`);
  const textError = _.get(errors, `${name}.2`);

  return (
    <Container>
      <Row>
        <Index>{`${index + 1}.`}</Index>
        <Space width={5} />
        <Label>{label}</Label>
        <Content>
          {options.map((option: any, optionIndex: any) => (
            <React.Fragment key={optionIndex}>
              <Space width={10} />
              <RadioInput
                isSelected={option === radioValue}
                color={colors.blue.interactive}
                label={option}
                value={option}
                onSelect={(value) => setFieldValue(`${name}.1`, value)}
              />
            </React.Fragment>
          ))}
        </Content>
      </Row>
      {!!radioError && <ValidationError>{radioError}</ValidationError>}
      {shouldShowTextInput && (
        <React.Fragment>
          <Space height={5} />
          <Input
            name={`${name}.2`}
            placeholder={'Enter text'}
            value={textValue}
            onChangeText={(text: any) => setFieldValue(`${name}.2`, text)}
            onBlur={() => setFieldTouched(`${name}.2`, true)}
          />
        </React.Fragment>
      )}
      {!!textError && <ValidationError>{textError}</ValidationError>}
    </Container>
  );
};

export default DocumentAdditionalInfoRadioButtonWithTextInput;
