/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors, Typography} from '@supermove/styles';

// App
import JobTimetable from 'modules/App/components/JobTimetable';
import CommercialJobUsersTimesheetTable from 'modules/Job/Commercial/Timesheet/components/CommercialJobUsersTimesheetTable';

const Container = Styled.View`
  z-index: 1;
`;

const TitleRow = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = Styled.Text`
  ${Typography.Heading1}
`;

const Section = Styled.View`
  align-items: flex-start;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionHeader = Styled.View`
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Label = Styled.H7`
  margin-bottom: 5px;
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const TitleAndBadge = Styled.View`
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 10px;
`;

const JobTimetableContainer = Styled.View`
  align-items: stretch;
  min-width: 100%;
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof CommercialJobReportIncomplete.defaultProps;

// @ts-expect-error TS(7022): 'CommercialJobReportIncomplete' implicitly has typ... Remove this comment to see the full error message
const CommercialJobReportIncomplete = ({job, showHeader}: Props) => {
  return (
    <Container>
      {showHeader && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={0}>
          <TitleRow>
            <TitleAndBadge>
              <Title>Report Information</Title>
            </TitleAndBadge>
          </TitleRow>
        </Section>
      )}
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <SectionHeader>
          <Label>Work Timesheet</Label>
        </SectionHeader>
        <ScrollView
          horizontal
          contentContainerStyle={{
            minWidth: '100%',
          }}
          style={{
            alignSelf: 'stretch',
          }}
        >
          <CommercialJobUsersTimesheetTable job={job} />
        </ScrollView>
      </Section>
      {job.jobTimetable.timesheets.length > 0 && (
        <React.Fragment>
          <SectionSpace />
          <Section
            // @ts-expect-error TS(2769): No overload matches this call.
            sectionIndex={3}
            style={{
              paddingHorizontal: 0,
            }}
          >
            <ScrollView
              horizontal
              contentContainerStyle={{
                minWidth: '100%',
              }}
              style={{
                alignSelf: 'stretch',
              }}
            >
              <JobTimetableContainer>
                <JobTimetable job={job} hideWorkTimesheet />
              </JobTimetableContainer>
            </ScrollView>
          </Section>
        </React.Fragment>
      )}
    </Container>
  );
};

CommercialJobReportIncomplete.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialJobReportIncomplete.fragment = gql`
  ${CommercialJobUsersTimesheetTable.fragment}
  ${JobTimetable.fragment}

  fragment CommercialJobReportIncomplete on Job {
    id
    uuid
    jobTimetable {
      timesheets {
        name
      }
    }
    ...CommercialJobUsersTimesheetTable
    ...JobTimetable
  }
`;

export default CommercialJobReportIncomplete;
