// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';
import {ProjectType} from '@supermove/models';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import MediumModal from '@shared/design/components/Modal/MediumModal';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Switch from '@shared/design/components/Switch';
import ProjectTypeFeatureForm from '@shared/modules/Project/forms/ProjectTypeFeatureForm';
import useUpdateProjectTypeFeatureMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeFeatureMutation';

const EditJobSettingsModal = ({projectType, editJobSettingsModal, refetch}: any) => {
  const {form, submitting, handleSubmit} = useUpdateProjectTypeFeatureMutation({
    projectTypeFeatureForm: ProjectTypeFeatureForm.new({
      projectTypeId: projectType.id,
      feature: ProjectType.FEATURES.AUTOMATICALLY_FINALIZE_JOBS,
      isEnabled: projectType.features.automaticallyFinalizeJobs,
    }),
    onSuccess: () => {
      editJobSettingsModal.handleClose();
      refetch();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const field = 'projectTypeFeatureForm.isEnabled';
  const isEnabled = _.get(form.values, field);

  return (
    <MediumModal
      title={'Job Settings'}
      description={'These settings will apply to all jobs within this project type.'}
      isOpen={editJobSettingsModal.isOpen}
      // @ts-expect-error TS(2322): Type '{ children: Element; title: string; descript... Remove this comment to see the full error message
      primaryActionIcon={Icon.Check}
      primaryActionText={'Save'}
      handlePrimaryAction={handleSubmit}
      secondaryActionText={'Cancel'}
      handleSecondaryAction={editJobSettingsModal.handleClose}
      isSubmitting={submitting}
    >
      <Switch
        labelRight={'Automatically Finalize Jobs'}
        isOn={isEnabled}
        onChange={() => form.setFieldValue(field, !isEnabled)}
        hint={
          'When enabled, all jobs within this project type will be automatically finalized when a job is ' +
          'completed manually or on the crew tablet. When disabled, jobs must be manually finalized.'
        }
      />
    </MediumModal>
  );
};

const ProjectTypeJobSettingsPanel = ({projectType, width, refetch}: any) => {
  const editJobSettingsModal = useModal({name: 'Edit Job Settings Modal'});

  return (
    <React.Fragment>
      <ActionPanel
        title={'Job Settings'}
        headerSubtitle={'These settings will apply to all jobs within this project type.'}
        width={width}
        BodyComponent={() => {
          return (
            <FieldValue
              label={'Automatically Finalize Jobs'}
              value={projectType.features.automaticallyFinalizeJobs ? 'Yes' : 'No'}
            />
          );
        }}
        ActionButtonComponent={TertiaryButton}
        actionButtonComponentProps={{
          iconLeft: Icon.Pen,
          text: 'Edit',
          onPress: editJobSettingsModal.handleOpen,
        }}
        // @ts-expect-error TS(2322): Type '{ title: string; headerSubtitle: string; wid... Remove this comment to see the full error message
        secondaryButtonT
      />
      <EditJobSettingsModal
        projectType={projectType}
        editJobSettingsModal={editJobSettingsModal}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

ProjectTypeJobSettingsPanel.fragment = gql`
  fragment ProjectTypeJobSettingsPanel on ProjectType {
    id
    features {
      automaticallyFinalizeJobs
    }
  }
`;

export default ProjectTypeJobSettingsPanel;
