// Supermove
import {gql} from '@supermove/graphql';
import {
  CapacityCalendarDayModel,
  CapacityCalendarModel,
  CapacityCalendarSlotMetricModel,
} from '@supermove/models';
import {colors} from '@supermove/styles';
import {withFragment} from '@supermove/utils';

import CapacityCalendarSlotMetricKind from '@shared/modules/CapacityCalendarSetting/enums/CapacityCalendarSlotMetricKind';

const getMetricBySlotPriorityIndex = withFragment(
  (capacityCalendar: CapacityCalendarModel, {slotPriorityIndex}: {slotPriorityIndex: number}) => {
    return capacityCalendar.capacityCalendarSlotMetrics.find(
      (metric) => metric.slotPriorityIndex === slotPriorityIndex,
    );
  },
  gql`
    fragment CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex on CapacityCalendar {
      capacityCalendarSlotMetrics {
        id
        metric
        slotPriorityIndex
      }
    }
  `,
);

const getPrimaryCapacityMetricPercentage = withFragment(
  ({
    capacityCalendarDay,
    slotMetric,
  }: {
    capacityCalendarDay: CapacityCalendarDayModel;
    slotMetric?: CapacityCalendarSlotMetricModel;
  }) => {
    switch (slotMetric?.metric) {
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION:
        return (
          capacityCalendarDay.totalJobPositionCount / capacityCalendarDay.availablePositionCount
        );
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE_BY_POSITION_TRUCK_FALLBACK:
        return capacityCalendarDay.totalTruckCount / capacityCalendarDay.availablePositionCount;
      case CapacityCalendarSlotMetricKind.TOTAL_TRUCKS_AVAILABLE:
        return capacityCalendarDay.totalTruckCount / capacityCalendarDay.availableTruckCount;
      case CapacityCalendarSlotMetricKind.TOTAL_CREW_AVAILABLE:
        return (
          capacityCalendarDay.totalAllJobUserCount / capacityCalendarDay.availableAllMoverCount
        );
      default:
        return null;
    }
  },
  gql`
    fragment CapacityCalendarSettingHelpers_getPrimaryCapacityMetricPercentage on CapacityCalendar {
      capacityCalendarDays {
        date
        totalJobPositionCount
        availablePositionCount
        dayNotes
        totalTruckCount
        availableTruckCount
        totalAllJobUserCount
        availableAllMoverCount
      }
    }
  `,
);

const getCapacityCalendarBackgroundColor = withFragment(
  ({
    capacityCalendar,
    capacityCalendarDay,
  }: {
    capacityCalendar: CapacityCalendarModel;
    capacityCalendarDay: CapacityCalendarDayModel;
  }) => {
    const primaryCapacityMetricPercentage = getPrimaryCapacityMetricPercentage({
      capacityCalendarDay,
      slotMetric: getMetricBySlotPriorityIndex(capacityCalendar, {
        slotPriorityIndex: 1,
      }),
    });
    if ((primaryCapacityMetricPercentage as any) < 0.7) {
      return {
        backgroundColor: colors.white,
        textColor: colors.gray.secondary,
        statusBar: colors.transparent,
      };
    } else if ((primaryCapacityMetricPercentage as any) < 0.9) {
      return {
        backgroundColor: colors.orange.accent,
        textColor: colors.orange.status,
        statusBar: colors.orange.hover,
      };
    } else {
      return {
        backgroundColor: colors.red.accent,
        textColor: colors.red.warning,
        statusBar: colors.red.warning,
      };
    }
  },
  gql`
    ${getPrimaryCapacityMetricPercentage.fragment}
    ${getMetricBySlotPriorityIndex.fragment}
    fragment CapacityCalendarSettingHelpers_getCapacityCalendarBackgroundColor on CapacityCalendar {
      ...CapacityCalendarSettingHelpers_getPrimaryCapacityMetricPercentage
      ...CapacityCalendarSettingHelpers_getMetricBySlotPriorityIndex
    }
  `,
);

const CapacityCalendarSettingHelpers = {
  getCapacityCalendarBackgroundColor,
  getPrimaryCapacityMetricPercentage,
  getMetricBySlotPriorityIndex,
};

export default CapacityCalendarSettingHelpers;
