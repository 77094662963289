// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Loading, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useResponsive,
  useQuery,
  useScrollView,
  useEffect,
  useDebouncedCallback,
  useState,
  useTextInput,
  useModal,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import EmptyState from '@shared/design/components/EmptyState';
import SearchBar from '@shared/design/components/SearchBar';
import CommentForm from '@shared/modules/Comment/forms/CommentForm';
import useCreateCommentMutation from '@shared/modules/Comment/hooks/useCreateCommentMutation';
import Line from 'modules/App/components/Line';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import QuitEditingWidgetModal from 'modules/Project/V2/Show/components/widgets/QuitEditingWidgetModal';
import ProjectCommentInput from 'modules/Project/components/ProjectCommentInput';
import ProjectCommentsListItem from 'modules/Project/components/ProjectCommentsListItem';

const Container = Styled.View<{responsive: any; isFullPage: boolean; isWidget: boolean}>`
  flex: 1;
  background-color: ${colors.white};
  width: ${({responsive, isFullPage, isWidget}) =>
    responsive.mobile || isFullPage || isWidget ? '100%' : '500px'};
`;

const HeaderContainer = Styled.View`
  padding-horizontal: 20px;
  background-color: ${colors.gray.background};
`;

const Button = Styled.ButtonV2`
`;

const Row = Styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const TitleText = Styled.Text`
  ${Typography.Label1}
`;

const BodyContainer = Styled.View`
  flex: 1;
  background-color: ${colors.gray.background};
`;

const FooterContainer = Styled.View`
`;

const SearchResultsText = Styled.Text`
  ${Typography.Responsive.Body};
  color: ${colors.gray.secondary};
`;

const Header = ({officeThread, handleClose, isFullPage, setIsFullPage}: any) => {
  const responsive = useResponsive();
  return (
    <Row style={{justifyContent: 'flex-start'}}>
      <TitleText>{pluralize('Comment', officeThread.commentsCount, true)}</TitleText>
      <Space style={{flex: 1}} />
      {responsive.desktop && (
        <Button onPress={() => setIsFullPage(!isFullPage)}>
          <Icon
            source={isFullPage ? Icon.Compress : Icon.Expand}
            size={16}
            color={colors.gray.secondary}
          />
        </Button>
      )}
      <Space width={12} />
      <Button onPress={handleClose}>
        <Icon source={Icon.Times} size={20} color={colors.gray.secondary} />
      </Button>
    </Row>
  );
};

const CommentsSearch = ({officeThread, handleChangeQuery, searchQueryExists, searchInput}: any) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Space height={16} />
      <SearchBar
        inputRef={searchInput.ref}
        onChangeText={(text) => handleChangeQuery(text)}
        placeholder={'Search'}
        containerStyle={{flex: 1}}
        style={{width: '100%'}}
        defaultValue={''}
        isClearable
        isResponsive
      />
      <Space height={16} />
      {searchQueryExists && (
        <React.Fragment>
          <Row>
            <SearchResultsText responsive={responsive}>
              {pluralize('result', officeThread.comments.length, true)} found
            </SearchResultsText>
          </Row>
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Body = ({officeThread, viewer, commentScrollView, refetch, setIsEditingComment}: any) => {
  const {commentsCount} = officeThread;
  if (!commentsCount) {
    return (
      <EmptyState.ContentContainer>
        <EmptyState
          title={'No Comments'}
          message={'Use the fields below to add comments\nto this project.'}
        />
      </EmptyState.ContentContainer>
    );
  }

  return (
    <BodyCommentList
      viewer={viewer}
      commentScrollView={commentScrollView}
      officeThread={officeThread}
      refetch={refetch}
      setIsEditingComment={setIsEditingComment}
    />
  );
};

const BodyCommentList = ({
  officeThread,
  viewer,
  commentScrollView,
  refetch,
  setIsEditingComment,
}: any) => {
  const [dirtyCommentIds, setDirtyCommentIds] = useState([]);
  const {comments} = officeThread;

  useEffect(() => {
    commentScrollView.handleScrollToEnd({animated: true});
  }, [commentScrollView]);

  return (
    <BodyContainer>
      <ScrollView ref={commentScrollView.ref}>
        {comments.map((comment: any, index: any) => (
          <ProjectCommentsListItem
            // ensures commentForm is reset if subject or body is changed
            key={`${comment.id}_${comment.subject}_${comment.body}`}
            comment={comment}
            viewer={viewer}
            refetch={refetch}
            index={index}
            // We keep track of all comments being edited
            // dirtyCommentIds should reflect form.dirty for all individual comments
            handleCommentUpdated={({commentId, isEditingComment}: any) => {
              const existingDirtyCommentIds = [...dirtyCommentIds];
              if (isEditingComment) {
                // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                if (!existingDirtyCommentIds.includes(commentId)) {
                  // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
                  setDirtyCommentIds([...existingDirtyCommentIds, commentId]);
                }
              } else {
                // @ts-expect-error TS(2345): Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                const indexOfComment = existingDirtyCommentIds.indexOf(commentId);
                if (indexOfComment !== -1) {
                  setDirtyCommentIds([
                    ...existingDirtyCommentIds.slice(0, indexOfComment),
                    ...existingDirtyCommentIds.slice(indexOfComment + 1),
                  ]);
                }
              }
              // isEditingComment should be true when editing any comment
              setIsEditingComment(!_.isEmpty(dirtyCommentIds));
            }}
          />
        ))}
      </ScrollView>
    </BodyContainer>
  );
};

const Footer = ({commentForm, handleSubmit}: any) => (
  <React.Fragment>
    <Line />
    <FooterContainer>
      <ProjectCommentInput form={commentForm} handleSubmit={handleSubmit} />
    </FooterContainer>
  </React.Fragment>
);

const ProjectCommentsContent = ({
  handleClose,
  viewer,
  officeThread,
  refetch,
  searchQuery,
  setSearchQuery,
  isWidget,
  isFullPage,
  setIsFullPage,
  setIsClosable,
}: any) => {
  const [isEditingComment, setIsEditingComment] = useState(false);
  const searchInput = useTextInput();
  const commentScrollView = useScrollView();
  const handleChangeQuery = useDebouncedCallback((query) => setSearchQuery(query), 250);

  const commentForm = CommentForm.new({
    threadId: officeThread.id,
    userId: viewer.id,
    subject: '',
    body: '',
  });

  const resetForm = () => {
    form.setFieldValue('commentForm.body', '');
    form.setFieldValue('commentForm.subject', '');
  };

  const {form, handleSubmit} = useCreateCommentMutation({
    commentForm,
    onSuccess: () => {
      setSearchQuery('');
      searchInput.handleClear();
      refetch();
      resetForm();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });
  useEffect(() => {
    setIsClosable(!(isEditingComment || form.dirty));
  }, [form, isEditingComment, setIsClosable]);

  return (
    <React.Fragment>
      <HeaderContainer>
        {!isWidget && (
          <React.Fragment>
            <Space height={16} />
            <Header
              officeThread={officeThread}
              handleClose={handleClose}
              isFullPage={isFullPage}
              setIsFullPage={setIsFullPage}
            />
          </React.Fragment>
        )}
        <CommentsSearch
          officeThread={officeThread}
          handleChangeQuery={handleChangeQuery}
          searchQueryExists={!!searchQuery}
          searchInput={searchInput}
        />
      </HeaderContainer>
      <Line />
      <Body
        officeThread={officeThread}
        viewer={viewer}
        commentScrollView={commentScrollView}
        refetch={refetch}
        setIsEditingComment={setIsEditingComment}
      />
      <Footer commentForm={form} handleSubmit={handleSubmit} />
    </React.Fragment>
  );
};

type OwnProjectCommentsProps = {
  projectUuid: string;
  isOpen?: boolean;
  handleClose?: (...args: any[]) => any;
  isWidget?: boolean;
  setIsParentClosable?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'ProjectCommentsProps' circularly refer... Remove this comment to see the full error message
type ProjectCommentsProps = OwnProjectCommentsProps & typeof ProjectComments.defaultProps;

// @ts-expect-error TS(7022): 'ProjectComments' implicitly has type 'any' becaus... Remove this comment to see the full error message
const ProjectComments = ({
  isOpen,
  handleClose,
  projectUuid,
  setIsParentClosable,
  isWidget,
}: ProjectCommentsProps) => {
  const responsive = useResponsive();
  const [searchQuery, setSearchQuery] = useState('');
  const [isFullPage, setIsFullPage] = useState(false);
  const [isClosable, setIsClosable] = useState(true);
  const quitEditingModal = useModal({name: 'Quit Editing Modal'});

  const {loading, data, refetch} = useQuery(ProjectComments.query, {
    fetchPolicy: 'cache-and-network',
    variables: {projectUuid, searchQuery},
    skip: isWidget ? false : !isOpen,
  });

  return (
    <Container
      responsive={responsive}
      isFullPage={!responsive.desktop || isFullPage}
      isWidget={isWidget}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => {
          if (!data) {
            return null;
          }

          return (
            <ProjectCommentsContent
              isOpen={isOpen}
              handleClose={() => {
                isClosable ? handleClose() : quitEditingModal.handleOpen();
              }}
              viewer={data.viewer}
              officeThread={data.project.officeThread}
              refetch={refetch}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              isWidget={isWidget}
              isFullPage={isFullPage}
              setIsFullPage={setIsFullPage}
              setIsClosable={(value: any) => {
                setIsClosable(value);
                setIsParentClosable(value);
              }}
            />
          );
        }}
      </Loading>
      <QuitEditingWidgetModal
        key={quitEditingModal.key}
        quitEditingModal={quitEditingModal}
        handleCloseParent={handleClose}
      />
    </Container>
  );
};

ProjectComments.defaultProps = {
  isOpen: false,
  handleClose: () => {},
  isWidget: false,
  setIsParentClosable: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectComments.query = gql`
  ${ProjectCommentsListItem.fragment}

  query ProjectComments(
    $projectUuid: String!,
    $searchQuery: String,
  ) {
    ${gql.query}
    viewer {
      id
    }
    project(uuid: $projectUuid) {
      id
      officeThread {
        id
        commentsCount
        comments: filteredComments(searchQuery: $searchQuery) {
          id
          ...ProjectCommentsListItem
        }
      }
    }
  }
`;

export default ProjectComments;
