// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MapView as SupermoveMapView, Styled} from '@supermove/components';
import {useResponsive, useState} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';
import {Location as LocationUtils} from '@supermove/utils';

// App

// Components
import {MapMarker} from 'modules/App/Map/components';
import MapView from 'modules/App/components/MapView';

const Container = Styled.View`
  flex: 1;
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  right: 60px;
  bottom: 25px;
  z-index: 1;
`;

const Button = Styled.Button`
  height: 40px;
  padding-horizontal: ${(props) => ((props as any).mobile ? 10 : 15)}px;
  margin-left: ${(props) => ((props as any).isFirst ? 0 : 10)}px;
  background-color: ${(props) => ((props as any).selected ? colors.blue.interactive : colors.white)};
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => ((props as any).disabled ? colors.gray.tertiary : (props as any).selected ? colors.white : colors.gray.primary)};
`;

const getInitialCenter = ({warehouseLocation}: any) => {
  return warehouseLocation
    ? {
        lat: warehouseLocation.latitude,
        lng: warehouseLocation.longitude,
      }
    : {
        lat: 37.7749,
        lng: -122.4194,
      };
};

const getStreetView = ({selectedIndex, points}: any) => {
  return _.get(points, selectedIndex);
};

const isValid = (location: any) => Boolean(LocationUtils.create(location));

const ROUTE_INDEX = -1;

type OwnJobMapProps = {
  isCustomer?: boolean;
  isHidden?: boolean;
  locations: any[];
  boundLocations?: {
    latitude?: number;
    longitude?: number;
  }[];
  onRouteUpdate?: (...args: any[]) => any;
  renderMapAnnotations?: (...args: any[]) => any;
  warehouseLocation?: {
    latitude?: number;
    longitude?: number;
  };
};

// @ts-expect-error TS(2456): Type alias 'JobMapProps' circularly references its... Remove this comment to see the full error message
type JobMapProps = OwnJobMapProps & typeof JobMap.defaultProps;

// @ts-expect-error TS(7022): 'JobMap' implicitly has type 'any' because it does... Remove this comment to see the full error message
const JobMap = ({
  isCustomer,
  isHidden,
  renderMapAnnotations,
  boundLocations,
  locations,
  warehouseLocation,
  onRouteUpdate,
  mapRef,
  onReady,
}: JobMapProps) => {
  const responsive = useResponsive();
  const [selectedIndex, setSelectedIndex] = useState(ROUTE_INDEX);

  // We memoize locations (LocationUtils.create) so a lat/lon pair always render the same object
  // to avoid re-rending the map markers.
  const points = locations.map((location: any) => LocationUtils.create(location)).filter(Boolean);

  const warehousePosition = warehouseLocation ? LocationUtils.create(warehouseLocation) : null;

  const hasExtraControls = !isCustomer && !isHidden;

  return (
    <Container
      style={{
        visibility: isHidden ? 'hidden' : 'visible',
      }}
    >
      <MapView
        hasExtraControls={hasExtraControls}
        initialCenter={getInitialCenter({warehouseLocation})}
        locations={[...locations, ...boundLocations]}
        directionLocations={locations}
        onRouteUpdate={onRouteUpdate}
        onReady={(mapProps: any, map: any) => {
          if (mapRef) {
            mapRef.current = map;
          }
          if (onReady) {
            onReady(map);
          }
        }}
        renderMapAnnotations={({route, ...props}: any) => (
          <React.Fragment>
            {points.map((point: any, index: any) => (
              <MapMarker
                key={index}
                kind={MapMarker.DEFAULT}
                name={`Stop ${index + 1}`}
                label={MapMarker.createMarkerLabel(index + 1)}
                position={point}
                {...props}
              />
            ))}
            {renderMapAnnotations(props)}
            {route && route.length > 0 && (
              <SupermoveMapView.Polyline
                path={route}
                strokeColor={colors.gray.primary}
                strokeWeight={5}
                {...props}
              />
            )}
            {warehousePosition && (
              <MapMarker kind={MapMarker.WAREHOUSE} position={warehousePosition} {...props} />
            )}
          </React.Fragment>
        )}
        streetViewPoint={getStreetView({selectedIndex, points})}
      />
      {hasExtraControls && (
        <Actions>
          <Button
            isFirst
            key={'route'}
            selected={selectedIndex === ROUTE_INDEX}
            onPress={() => setSelectedIndex(ROUTE_INDEX)}
            {...responsive}
          >
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <ButtonText selected={selectedIndex === ROUTE_INDEX}>Route</ButtonText>
          </Button>
          {locations.map((location: any, index: any) => (
            <Button
              key={index}
              disabled={!isValid(location)}
              selected={selectedIndex === index}
              onPress={() => setSelectedIndex(index)}
              {...responsive}
            >
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <ButtonText disabled={!isValid(location)} selected={selectedIndex === index}>
                {responsive.mobile ? `#${index + 1}` : `Stop #${index + 1}`}
              </ButtonText>
            </Button>
          ))}
        </Actions>
      )}
    </Container>
  );
};

JobMap.defaultProps = {
  isCustomer: false,
  isHidden: false,
  boundLocations: [],
  onRouteUpdate: () => {},
  renderMapAnnotations: () => null,
  warehouseLocation: null,
};

export default JobMap;
