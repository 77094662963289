// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography} from '@supermove/styles';

// App
import JobTimetable from 'modules/App/components/JobTimetable';

const Container = Styled.View`
`;

const Title = Styled.Text`
  ${Typography.Heading1}
  margin-bottom: 5px;
`;

type OwnProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobTimetableBlock.defaultProps;

// @ts-expect-error TS(7022): 'JobTimetableBlock' implicitly has type 'any' beca... Remove this comment to see the full error message
const JobTimetableBlock = ({job, showHeader}: Props) => {
  return (
    <Container>
      {showHeader && <Title>Timesheets</Title>}
      <JobTimetable job={job} />
    </Container>
  );
};

JobTimetableBlock.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTimetableBlock.fragment = gql`
  ${JobTimetable.fragment}

  fragment JobTimetableBlock on Job {
    id
    ...JobTimetable
  }
`;

export default JobTimetableBlock;
