// Libraries
import React from 'react';

// App
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// Components
const Container = Styled.View`
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${(props) => (props as any).color};
  border-radius: 8px;
`;

const getColor = ({jobUser}: any) => {
  switch (jobUser.status) {
    case 'CONFIRMED':
      return colors.green.status;
    case 'REMOVED':
      return colors.Pink600;
    case 'VIEWED':
      return colors.red.warning;
    default:
      return colors.gray.tertiary;
  }
};

const getSource = ({jobUser}: any) => {
  switch (jobUser.status) {
    case 'CONFIRMED':
      return Icon.Check;
    case 'REMOVED':
      return Icon.Times;
    case 'VIEWED':
      return Icon.Eye;
    default:
      return Icon.Question;
  }
};

type OwnJobUserStatusBadgeProps = {
  jobUser: any;
};

// @ts-expect-error TS(2456): Type alias 'JobUserStatusBadgeProps' circularly re... Remove this comment to see the full error message
type JobUserStatusBadgeProps = OwnJobUserStatusBadgeProps & typeof JobUserStatusBadge.defaultProps;

// @ts-expect-error TS(7022): 'JobUserStatusBadge' implicitly has type 'any' bec... Remove this comment to see the full error message
const JobUserStatusBadge = ({jobUser}: JobUserStatusBadgeProps) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container color={getColor({jobUser})}>
    <Icon color={colors.white} size={Icon.Sizes.ExtraSmall} source={getSource({jobUser})} />
  </Container>
);

JobUserStatusBadge.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobUserStatusBadge.fragment = gql`
  fragment JobUserStatusBadge on JobUser {
    id
    status
  }
`;

export default JobUserStatusBadge;
