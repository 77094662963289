// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';
import {BillItemType, Organization} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency, Percent} from '@supermove/utils';

// App
import IconButton from '@shared/design/components/IconButton';
import BillItemTypeCategory from '@shared/modules/Billing/enums/BillItemTypeCategory';
import BillItemTypeKind from '@shared/modules/Billing/enums/BillItemTypeKind';
import BillItemForm from '@shared/modules/Billing/forms/BillItemForm';
import useUpdateBillItemTypeMutation from '@shared/modules/Billing/hooks/useUpdateBillItemTypeMutation';
import UserRole from '@shared/modules/User/enums/UserRole';
import BillItemTypeDrawer from 'modules/Organization/Settings/BillingLibraries/components/BillItemTypeDrawer';
import EditBillItemTypeFields from 'modules/Organization/Settings/BillingLibraries/components/EditBillItemTypeFields';

const Container = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const Cell = Styled.View`
  align-items: center;
  flex-direction: row;
  ${({
    // @ts-expect-error TS(2339): Property 'isRightAligned' does not exist on type '... Remove this comment to see the full error message
    isRightAligned,
  }) => isRightAligned && 'justify-content: flex-end;'}
  ${({
    // @ts-expect-error TS(2339): Property 'flex' does not exist on type 'ThemeProps... Remove this comment to see the full error message
    flex,
  }) => flex && `flex: ${flex};`}
  ${({
    // @ts-expect-error TS(2339): Property 'width' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    width,
  }) => width && `width: ${width}px;`}
`;

const Text = Styled.Text`
  ${Typography.Responsive.Body}
`;

const SecondaryText = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const getDisplayAmount = ({billItemTypeForm, isEnabledTbdBillItems}: any) => {
  if (billItemTypeForm.kind === BillItemTypeKind.AMOUNT) {
    return isEnabledTbdBillItems && _.isNil(billItemTypeForm.amount)
      ? 'TBD'
      : Currency.display(billItemTypeForm.amount);
  }
  return isEnabledTbdBillItems && _.isNil(billItemTypeForm.percentage)
    ? 'TBD'
    : Percent.display(billItemTypeForm.percentage);
};

const EditBillTemplateBillItemTypeDrawer = ({
  isOpen,
  handleClose,
  form,
  field,
  organization,
}: any) => {
  const billItemTypeForm = _.get(form.values, field);
  const {category} = billItemTypeForm;
  const {
    form: updateBillItemTypeForm,
    submitting,
    handleSubmit,
  } = useUpdateBillItemTypeMutation({
    billItemTypeForm,
    onSuccess: () => {
      const updatedBillItemTypeForm = updateBillItemTypeForm.values.billItemTypeForm;
      form.setFieldValue(field, {
        ...updatedBillItemTypeForm,
        amount: Currency.convertToCents(updatedBillItemTypeForm.amount),
      });
      handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  return (
    <BillItemTypeDrawer
      isOpen={isOpen}
      isLoading={false}
      isSubmitting={submitting}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      headerText={BillItemTypeCategory.getDisplayCategory(category)}
    >
      <EditBillItemTypeFields
        form={updateBillItemTypeForm}
        field={'billItemTypeForm'}
        category={category}
        formulas={organization.formulas}
        moverPositions={
          organization.features.isEnabledMoverPositionMultibranch
            ? Organization.getCompanySettingsMoverPositionsByRole({
                organization,
                role: UserRole.EMPLOYEE,
              })
            : organization.moverPositions
        }
        organization={organization}
        showGlobalEditWarning
      />
    </BillItemTypeDrawer>
  );
};

const BillingLibraryBillItemTypesRow = ({
  form,
  field,
  isEnabledTbdBillItems,
  organization,
  handleDelete,
}: any) => {
  const billItemTypeForm = _.get(form.values, field);
  const responsive = useResponsive();
  const editBillTemplateBillItemTypeDrawer = useDrawer({
    name: 'Edit Bill Template Bill Item Type Drawer',
  });
  return (
    <Container>
      <Row>
        <Container>
          <Row>
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell flex={3}>
              <Text responsive={responsive}>{BillItemType.getDisplayName(billItemTypeForm)}</Text>
            </Cell>
            <Space width={24} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell flex={1} isRightAligned>
              <SecondaryText responsive={responsive}>
                {BillItemForm.getEstimateQuantity(billItemTypeForm, {isEnabledTbdBillItems})}
              </SecondaryText>
            </Cell>
            <Space width={24} />
            {/* @ts-expect-error TS(2769): No overload matches this call. */}
            <Cell flex={1} isRightAligned>
              <SecondaryText responsive={responsive}>
                {getDisplayAmount({billItemTypeForm, isEnabledTbdBillItems})}
              </SecondaryText>
            </Cell>
          </Row>
          {!!billItemTypeForm.description && (
            <React.Fragment>
              <Space height={4} />
              <SecondaryText responsive={responsive}>{billItemTypeForm.description}</SecondaryText>
            </React.Fragment>
          )}
        </Container>
        <Space width={24} />
        <Cell width={56}>
          <IconButton source={Icon.Pen} onPress={editBillTemplateBillItemTypeDrawer.handleOpen} />
          <Space width={8} />
          <IconButton isDestructive source={Icon.Trash} onPress={handleDelete} />
        </Cell>
      </Row>
      <EditBillTemplateBillItemTypeDrawer
        key={editBillTemplateBillItemTypeDrawer.key}
        form={form}
        field={field}
        isOpen={editBillTemplateBillItemTypeDrawer.isOpen}
        handleClose={editBillTemplateBillItemTypeDrawer.handleClose}
        organization={organization}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
BillingLibraryBillItemTypesRow.fragment = gql`
  ${BillItemType.getDisplayName.fragment}
  ${EditBillItemTypeFields.fragment}
  ${Organization.getCompanySettingsMoverPositionsByRole.fragment}

  fragment BillingLibraryBillItemTypesRow on BillItemType {
    id
    name
    ...BillItemType_getDisplayName
  }

  fragment BillingLibraryBillItemTypesRow_Organization on Organization {
    id
    features {
      isEnabledMoverPositionMultibranch: isEnabled(feature: "MOVER_POSITION_MULTIBRANCH")
    }
    formulas {
      id
      ...EditBillItemTypeFields_Formula
    }
    moverPositions {
      id
      ...EditBillItemTypeFields_MoverPosition
    }
    ...EditBillItemTypeFields_Organization
    ...Organization_getCompanySettingsMoverPositionsByRole
  }
`;

export default BillingLibraryBillItemTypesRow;
