// Libraries
import React from 'react';

// Supermove
import {Modal, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {User} from '@supermove/models';
import {colors, fontWeight} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import {Navigation} from 'modules/App/components';
import {useUpdatePayableUserDayForm} from 'modules/Payroll/Crew/PayableUser/Show/logic';

import CrewPayableUserDayFields from './CrewPayableUserDayFields';

const Overlay = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Wrapper = Styled.View`
  height: ${(props) => ((props as any).mobile ? '100%' : '600px')};
  width: ${(props) => ((props as any).mobile ? '100%' : '600px')};
`;

const Container = Styled.View`
  flex: 1;
  background-color: ${colors.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const Header = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 60px;
`;

const HeaderCenter = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const HeaderSide = Styled.View`
  align-items: center;
  justify-content: center;
  width: ${(props) => ((props as any).mobile ? 40 : 60)}px;
`;

const Title = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding-vertical: 10px;
  padding-horizontal: 20px;
  border-top-width: 1px;
  border-top-color: ${colors.blue.accent};
`;

const Button = Styled.LoadingButton`
  padding-horizontal: 15px;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const CrewUpdatePayableUserDayModal = ({isOpen, payableUserDay, onClose}: any) => {
  const responsive = useResponsive();
  const {form, submitting, handleSubmit} = useUpdatePayableUserDayForm({
    payableUserDay,
    onSuccess: () => onClose(),
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <Modal.Content isOpen={isOpen} onClose={onClose}>
      <Overlay pointerEvents={'box-none'}>
        <Wrapper {...responsive}>
          <Container>
            <Header>
              <HeaderSide {...responsive}>
                <Navigation.CloseButton responsive={responsive} onPress={onClose} />
              </HeaderSide>
              <HeaderCenter>
                <Title>{User.getFullName(payableUserDay.user)}</Title>
                <Subtitle>
                  {Datetime.convertToDisplayDate(payableUserDay.day.value, 'dddd (M/D)')}
                </Subtitle>
              </HeaderCenter>
              <HeaderSide />
            </Header>
            <ScrollView style={{flex: 1}}>
              <CrewPayableUserDayFields
                payableUserDay={payableUserDay}
                payableUserDayForm={form.values.payableUserDayForm}
                form={form}
              />
            </ScrollView>
            <Actions>
              <Button loading={submitting} onPress={handleSubmit}>
                <Text>Submit</Text>
              </Button>
            </Actions>
          </Container>
        </Wrapper>
      </Overlay>
    </Modal.Content>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewUpdatePayableUserDayModal.fragment = gql`
  ${useUpdatePayableUserDayForm.fragment}
  ${CrewPayableUserDayFields.fragment}

  fragment CrewUpdatePayableUserDayModal on PayableUserDay {
    id
    day {
      id
      value
    }
    user {
      id
      firstName
      lastName
    }
    ...useUpdatePayableUserDayForm
    ...CrewPayableUserDayFields
  }
`;

export default CrewUpdatePayableUserDayModal;
