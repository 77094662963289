/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useSearch, useQuery} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import AllOrganizationsCrewsList from 'modules/Dispatch/Calendar/Day/RightPanel/components/AllOrganizationsCrewsList';
import CrewInfoContent from 'modules/Dispatch/Calendar/Day/components/CrewInfoContent';
import JobInfoContent from 'modules/Dispatch/Calendar/Day/components/JobInfoContent';

const BorderedContainer = Styled.View`
  flex: 1;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${colors.gray.border};
`;

const ItemContainer = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index}
  padding-vertical: 2px;
  padding-horizontal: 8px;
`;

const SearchBarInput = Styled.TextInput.H7`
  border-width: 0;
  background-color: ${colors.alpha(colors.gray.border, 0.5)};
  color: ${colors.black}
  padding-left: 28px;
`;

const SearchContainer = Styled.View`
  margin: 8px;
`;

const SearchIconContainer = Styled.View`
  position: absolute;
  top: 14px;
  left: 8px;
`;

const Indicator = Styled.Loading`
  padding: 20px;
`;

const SearchCrewsList = ({crews, refetchCalendar, canAssignOrganization}: any) => {
  const {
    query,
    results: filteredCrews,
    setQuery,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: crews,
    options: {keys: ['job.identifier', 'job.customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <SearchBarInput
          placeholder={'Customer name, job name'}
          placeholderTextColor={colors.gray.secondary}
          onChangeText={(text: any) => setQuery(text)}
          value={query}
        />
        <SearchIconContainer>
          <Icon color={colors.gray.secondary} size={Icon.Sizes.Small} source={Icon.Search} />
        </SearchIconContainer>
      </SearchContainer>
      <ScrollView>
        {filteredCrews.map((crew, index) => {
          return (
            <ItemContainer key={(crew as any).id} index={index}>
              <CrewInfoContent
                crew={crew}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchCrewsList.fragment = gql`
  ${CrewInfoContent.fragment}

  fragment SearchCrewsList on Crew {
    id
    job {
      id
      identifier
      customer {
        id
        fullName
      }
    }
    ...CrewInfoContent
  }
`;

const SearchJobsList = ({jobs, refetchCalendar, canAssignOrganization}: any) => {
  const {
    query,
    results: filteredJobs,
    setQuery,
    // @ts-expect-error TS(2345): Argument of type '{ initialQuery: string; items: a... Remove this comment to see the full error message
  } = useSearch({
    initialQuery: '',
    items: jobs,
    options: {keys: ['identifier', 'customer.fullName']},
  });

  return (
    <React.Fragment>
      <SearchContainer>
        <SearchBarInput
          placeholder={'Customer name, job name'}
          placeholderTextColor={colors.gray.secondary}
          onChangeText={(text: any) => setQuery(text)}
          value={query}
        />
        <SearchIconContainer>
          <Icon color={colors.gray.secondary} size={Icon.Sizes.Small} source={Icon.Search} />
        </SearchIconContainer>
      </SearchContainer>
      <ScrollView>
        {filteredJobs.map((job, index) => {
          return (
            <ItemContainer key={(job as any).id} index={index}>
              <JobInfoContent
                job={job}
                crewUsersCount={Job.getDispatchJobUsersCount(job)}
                isAssignable
                refetch={refetchCalendar}
                canAssignOrganization={canAssignOrganization}
              />
            </ItemContainer>
          );
        })}
      </ScrollView>
    </React.Fragment>
  );
};

SearchJobsList.fragment = gql`
  ${Job.getDispatchJobUsersCount.fragment}
  ${Job.getDispatchJobUsersNames.fragment}
  ${JobInfoContent.fragment}

  fragment SearchJobsList on Job {
    id
    identifier
    customer {
      id
      fullName
    }
    ...Job_getDispatchJobUsersCount
    ...Job_getDispatchJobUsersNames
    ...JobInfoContent
  }
`;

const AllJobsList = ({viewerDayId, dayIds, refetchCalendar, canAssignOrganization}: any) => {
  const {loading, data, refetch} = useQuery(AllJobsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds},
  });

  if (loading) {
    return <Indicator />;
  }

  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <SearchJobsList
        jobs={data.dispatchCalendarDay.allJobs}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

AllJobsList.query = gql`
  ${SearchJobsList.fragment}

  query AllJobsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      allJobs {
        id
        ...SearchJobsList
      }
    }
  }
`;

const UnassignedJobsList = ({viewerDayId, dayIds, refetchCalendar, canAssignOrganization}: any) => {
  const {loading, data, refetch} = useQuery(UnassignedJobsList.query, {
    fetchPolicy: 'network-only',
    variables: {viewerDayId, dayIds},
  });

  if (loading) {
    return <Indicator />;
  }

  const refetchBoth = () => {
    refetchCalendar();
    refetch();
  };

  return (
    <BorderedContainer>
      <SearchJobsList
        jobs={data.dispatchCalendarDay.unassignedJobs}
        refetchCalendar={refetchBoth}
        canAssignOrganization={canAssignOrganization}
      />
    </BorderedContainer>
  );
};

UnassignedJobsList.query = gql`
  ${SearchJobsList.fragment}

  query UnassignedJobsList(
    $viewerDayId: Int!,
    $dayIds: [Int]!,
  ) {
    ${gql.query}
    dispatchCalendarDay(viewerDayId: $viewerDayId, dayIds: $dayIds) {
      unassignedJobs {
        id
        ...SearchJobsList
      }
    }
  }
`;

const MultipleOrganizationJobsLists = ({
  dispatchJobsSummaryKind,
  viewerDayId,
  dayIds,
  organizationId,
  refetchCalendar,
  canAssignOrganization,
}: any) => {
  switch (dispatchJobsSummaryKind) {
    case 'ALL':
      return (
        <AllJobsList
          viewerDayId={viewerDayId}
          dayIds={dayIds}
          refetchCalendar={refetchCalendar}
          canAssignOrganization={canAssignOrganization}
        />
      );
    case 'UNASSIGNED':
      return (
        <UnassignedJobsList
          viewerDayId={viewerDayId}
          dayIds={dayIds}
          refetchCalendar={refetchCalendar}
          canAssignOrganization={canAssignOrganization}
        />
      );
    case 'CONTRACTORS':
      return (
        <AllOrganizationsCrewsList
          viewerDayId={viewerDayId}
          dayIds={dayIds}
          organizationId={organizationId}
          refetchCalendar={refetchCalendar}
          canAssignOrganization={canAssignOrganization}
        />
      );
    default:
      return null;
  }
};

export default MultipleOrganizationJobsLists;
