// Libraries
import React from 'react';

// Supermove
import {Form, Link, Mutation, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// Components
import {ResetPasswordModal} from 'modules/Authentication/Password/Reset/components';

const Content = Styled.View`
  width: 100%;
  max-width: 600px;
  padding-top: 40px;
  padding-horizontal: ${(props) => ((props as any).desktop ? 100 : 50)}px;
  max-width: 600px;
`;

const Logo = Styled.H1`
  margin-top: 40px;
  ${fontWeight(700)}
  color: ${colors.gray.primary};
  letter-spacing: -2;
`;

const Title = Styled.H3`
  margin-top: 30px;
  ${fontWeight(500)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  margin-top: 5px;
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const Field = Styled.View`
  margin-top: 30px;
`;

const Label = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
  margin-bottom: 3px;
`;

const Input = Styled.TextInput.H7`
  margin-top: 3px;
`;

const ValidationError = Styled.H7`
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const Actions = Styled.View`
  flex-direction: row;
  margin-vertical: 25px;
`;

const SubmitButton = Styled.LoadingButton`
  height: 40px;
  padding-horizontal: 20px;
`;

const SubmitText = Styled.H5`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Login = Styled.H6`
  color: ${colors.blue.interactive};
  margin: auto;
`;

const ResetPasswordPage = () => {
  const responsive = useResponsive();

  return (
    <Form
      initialValues={{
        email: '',
      }}
      onSubmit={(values: any) => {}}
    >
      {({values, errors, handleChange, setMutationErrors, submitForm, ...form}: any) => (
        <ResetPasswordModal
          email={values.email}
          trigger={({handleOpen}: any) => (
            <Mutation
              // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
              variables={{
                email: values.email,
              }}
              mutation={ResetPasswordPage.mutation}
              onCompleted={({resetPasswordForUser: {user, errors}}: any) => {
                submitForm();
                setMutationErrors(errors);
                if (user) {
                  handleOpen();
                }
              }}
            >
              {(handleSubmit: any, {loading, error}: any) => (
                <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
                  <Content {...responsive}>
                    <Logo>Supermove</Logo>
                    <Title>Reset Password</Title>
                    <Subtitle>
                      Please enter your email address and we'll send you a link to reset your
                      password.
                    </Subtitle>
                    <Field>
                      <Label>Email</Label>
                      <Input
                        autoFocus
                        name={'email'}
                        placeholder={'Email'}
                        value={values.email}
                        onChange={handleChange}
                        onSubmitEditing={handleSubmit}
                      />
                      {errors.email && <ValidationError>{errors.email}</ValidationError>}
                    </Field>
                    <Actions>
                      <SubmitButton loading={loading} onPress={handleSubmit}>
                        <SubmitText>Send Email</SubmitText>
                      </SubmitButton>
                    </Actions>
                    <Link to={'/login'}>
                      <Login>Return to Log In</Login>
                    </Link>
                  </Content>
                </ScrollView>
              )}
            </Mutation>
          )}
        />
      )}
    </Form>
  );
};

// --------------------------------------------------
// Props
// --------------------------------------------------
ResetPasswordPage.propTypes = {};

ResetPasswordPage.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
ResetPasswordPage.mutation = gql`
  mutation ResetPasswordPage(
    $email: String!,
  ) {
    resetPasswordForUser(
      email: $email,
    ) {
      ${gql.errors}
      user {
        id
      }
    }
  }
`;

export default ResetPasswordPage;
