// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import LocationTypeForm from '@shared/modules/Organization/forms/LocationTypeForm';
import useUpdateOrganizationLocationTypesMutation from '@shared/modules/Organization/hooks/useUpdateOrganizationLocationTypesMutation';

const CreateLocationTypeModal = ({
  isOpen,
  handleClose,
  locationTypes,
  organizationId,
  refetch,
}: any) => {
  const locationTypeForms = [
    ...locationTypes.map((locationType: any) => LocationTypeForm.edit(locationType)),
    LocationTypeForm.new(),
  ];
  const {form, submitting, handleSubmit} = useUpdateOrganizationLocationTypesMutation({
    organizationLocationTypesForm: {
      organizationId,
      locationTypeForms,
    },
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (error: any) => console.log(error),
  });

  const nameField = `organizationLocationTypesForm.locationTypeForms.${locationTypes.length}.name`;

  return (
    <SmallModal isOpen={isOpen}>
      <SmallModal.BoldText>{`Add Location Type`}</SmallModal.BoldText>
      <Space height={16} />
      <SmallModal.Text>
        {`This will appear in the 'Location Type' dropdown when creating a new project.`}
      </SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        name={nameField}
        label={'Name'}
        input={{
          autoFocus: true,
          required: !_.get(form.values, nameField),
          placeholder: 'Enter location type name',
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button
          color={colors.blue.interactive}
          isSubmitting={submitting}
          onPress={handleSubmit}
        >
          Add
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CreateLocationTypeModal;
