// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// App
import ItemForm from '@shared/modules/Inventory/forms/ItemForm';
import CreateItemModal from 'modules/Item/components/CreateItemModal';

const CreateCustomItemModal = ({isOpen, collectionId, inventory, form, handleClose}: any) => {
  const itemForm = ItemForm.new({
    collectionId,
    name: '',
    takeCount: 0,
    leaveCount: 0,
    weight: 0,
    volume: 0,
    price: 0,
    isDerivedWeight: true,
    densityFactor: inventory.densityFactor,
  });

  return (
    <CreateItemModal
      isOpen={isOpen}
      name={'Add Custom Item'}
      itemForm={itemForm}
      form={form}
      inventory={inventory}
      handleClose={handleClose}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCustomItemModal.fragment = gql`
  ${CreateItemModal.fragment}

  fragment CreateCustomItemModal on Inventory {
    id
    densityFactor
    ...CreateItemModal
  }
`;

export default CreateCustomItemModal;
