// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {EmailTemplate} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import EmailTemplateRecipientForm from '@shared/modules/Email/forms/EmailTemplateRecipientForm';
import Field from 'modules/App/components/Field';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const Button = Styled.ButtonV2`
`;

const CcButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const OptionAddText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const ErrorText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.warning};
`;

const getRecipientIsOrganizationVariable = (value: any) => {
  return !!_.get(EmailTemplate.RECIPIENT_VALUES, value);
};

const getRecipientIsUserId = (value: any) => {
  return !!Number(value);
};

const getEmailTemplateRecipientForm = (value: any) => {
  if (getRecipientIsOrganizationVariable(value)) {
    return EmailTemplateRecipientForm.new({role: value});
  }
  if (getRecipientIsUserId(value)) {
    return EmailTemplateRecipientForm.new({userId: value});
  }
  return EmailTemplateRecipientForm.new({email: value});
};

const getIsNewCustomOption = ({option, searchInputValue}: any) => {
  return searchInputValue === option.label && searchInputValue === option.value;
};

const getOptions = ({recipientOptions, searchInputValue}: any) => {
  const searchInputValueFirstChar = searchInputValue[0];
  const isSearchingForVariable = searchInputValueFirstChar === '{';
  const hasExactSearchMatch = !!_.find(
    recipientOptions,
    (option) => _.toLower(option.label) === _.toLower(searchInputValue),
  );
  if (!searchInputValue || isSearchingForVariable || hasExactSearchMatch) {
    return recipientOptions;
  }
  const customRecipientOption = {label: searchInputValue, value: searchInputValue};
  return [customRecipientOption, ...recipientOptions];
};

const handleUpdateRecipients = ({
  values,
  recipientOptions,
  form,
  field,
  recipientFormsField,
}: any) => {
  const recipientForms = values.map((value: any) => {
    const isAddingNewRecipient = !_.find(
      recipientOptions,
      (option) => _.toLower(value) === _.toLower(option.value),
    );
    if (isAddingNewRecipient) {
      const currentCustomOptions = _.get(form.values, `${field}.customRecipientOptions`);
      form.setFieldValue(`${field}.customRecipientOptions`, [
        ...currentCustomOptions,
        {label: value, value},
      ]);
    }
    return getEmailTemplateRecipientForm(value);
  });
  form.setFieldValue(`${field}.${recipientFormsField}`, recipientForms);
};

const RecipientOption = ({searchInputValue, option, disabled, children}: any) => {
  return (
    <Row>
      {getIsNewCustomOption({option, searchInputValue}) && (
        <React.Fragment>
          <Icon source={Icon.Plus} size={10} color={colors.blue.interactive} />
          <Space width={4} />
          <OptionAddText>Add</OptionAddText>
          <Space width={8} />
        </React.Fragment>
      )}
      <OptionText color={disabled ? colors.gray.tertiary : colors.gray.primary}>
        {children}
      </OptionText>
    </Row>
  );
};

const RecipientsField = ({form, field, recipientType, recipientOptions}: any) => {
  const searchInputValue = _.get(form.values, `${field}.searchInputValue`);
  const recipientValuesField = `${recipientType}EmailTemplateRecipientValues`;
  const recipientFormsField = `${recipientType}EmailTemplateRecipientForms`;
  const recipientErrors = _.get(form.errors, `${field}.${recipientFormsField}`);

  return (
    <React.Fragment>
      <Field
        {...form}
        component={MultiDropdownInput}
        name={`${field}.${recipientValuesField}`}
        input={{
          options: getOptions({recipientOptions, searchInputValue}),
          placeholder: 'Enter recipients',
          setFieldValue: form.setFieldValue,
          isSearchable: true,
          isOptionDisabled: (option: any) => option.disabled,
          onChangeValue: (values: any) => {
            handleUpdateRecipients({values, recipientOptions, form, field, recipientFormsField});
          },
          onInputChange: (value: any) => {
            form.setFieldValue(`${field}.searchInputValue`, value);
          },
          components: {
            Option: (props: any) => {
              return (
                <MultiDropdownInput.Option
                  {...props}
                  optionComponentProps={{searchInputValue}}
                  OptionComponent={RecipientOption}
                />
              );
            },
          },
          style: {
            width: '100%',
            borderColor: recipientErrors ? colors.red.warning : colors.gray.tertiary,
          },
        }}
        style={{
          width: '100%',
        }}
      />
      {!!recipientErrors && (
        <React.Fragment>
          <Space height={4} />
          {/* TODO(dan) Once backend is updated, show message from backend for input */}
          <ErrorText>
            {_.get(
              _.filter(recipientErrors, (error) => !!error),
              '0.email',
              'Invalid email found.',
            )}
          </ErrorText>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const OrganizationEmailTemplateRecipients = ({field, form, organization}: any) => {
  const {isShowingCc, isShowingBcc} = _.get(form.values, field);
  const variableOptions = EmailTemplate.getRecipientDropdownOptions({organization});

  const userOptions = organization.activeOfficeUsers.map((user: any) => {
    return {
      label: `${user.fullName} <${user.email ? user.email : 'NO EMAIL'}>`,
      value: user.id,
      disabled: !user.email,
    };
  });
  const customOptions = _.get(form.values, `${field}.customRecipientOptions`);
  // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
  const recipientOptions = [...variableOptions, ...userOptions, ...customOptions];
  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={0}>
        <Row>
          <Label>Recipients</Label>
          <Space style={{flex: 1}} />
          {!isShowingCc && (
            <Button onPress={() => form.setFieldValue(`${field}.isShowingCc`, true)}>
              <CcButtonText>Cc</CcButtonText>
            </Button>
          )}
          {!isShowingBcc && (
            <React.Fragment>
              <Space width={12} />
              <Button onPress={() => form.setFieldValue(`${field}.isShowingBcc`, true)}>
                <CcButtonText>Bcc</CcButtonText>
              </Button>
            </React.Fragment>
          )}
        </Row>
        <Space height={2} />
        <RecipientsField
          form={form}
          field={field}
          recipientType={'to'}
          recipientOptions={recipientOptions}
        />
        <Space height={12} />
      </Section>
      {isShowingCc && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={1}>
          <Label>Cc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'cc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
      {isShowingBcc && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <Section sectionIndex={2}>
          <Label>Bcc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'bcc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationEmailTemplateRecipients.fragment = gql`
  ${EmailTemplate.getRecipientDropdownOptions.fragment}
  fragment OrganizationEmailTemplateRecipients on Organization {
    id
    activeOfficeUsers {
      id
      fullName
      email
    }
    ...EmailTemplate_getRecipientDropdownOptions
  }
`;

export default OrganizationEmailTemplateRecipients;
