// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import Modal from '@shared/design/components/Modal';
import ProjectSizeInput from 'modules/Project/components/ProjectSizeInput';
import ProjectTypeDropdown from 'modules/Project/components/ProjectTypeDropdown';

const Row = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const Button = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
`;

const ButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const FieldSpace = () => {
  return <Space height={12} width={8} />;
};

const ProjectNameToggle = ({form, field}: any) => {
  const isProjectNameVisible = _.get(form.values, `${field}.isProjectNameVisible`);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} style={{alignItems: 'center'}}>
      <Icon
        source={isProjectNameVisible ? Icon.Trash : Icon.Plus}
        size={14}
        color={colors.blue.interactive}
      />
      <Space width={8} />
      <Button
        onPress={() => {
          form.setFieldValue(`${field}.isProjectNameVisible`, !isProjectNameVisible);
          form.setFieldValue(`${field}.name`, '');
        }}
      >
        <ButtonText>{isProjectNameVisible ? 'Remove project name' : 'Add project name'}</ButtonText>
      </Button>
    </Row>
  );
};

const HeaderRow = ({form, field}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={0} {...responsive}>
      <Modal.BlockHeader>Project Information</Modal.BlockHeader>
      {responsive.mobile ? <FieldSpace /> : <Space style={{flex: 1}} />}
      <ProjectNameToggle form={form} field={field} />
    </Row>
  );
};

const ProjectNameRow = ({form, field, index}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={index}>
      <FieldInput.Memoized
        {...form}
        name={`${field}.name`}
        label={'Project Name'}
        input={{
          placeholder: 'Enter project name',
        }}
        style={{flex: 1}}
      />
    </Row>
  );
};

const TypeAndSizeRow = ({form, field, index, organization}: any) => {
  const responsive = useResponsive();
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Row index={index} {...responsive}>
      <ProjectTypeDropdown
        organization={organization}
        field={field}
        form={form}
        shouldResetJobDateOnProjectTypeChange
        jobFormField={`${field}.jobForms.0`}
        style={{flex: 1}}
      />
      <FieldSpace />
      <ProjectSizeInput organization={organization} field={field} form={form} style={{flex: 1}} />
    </Row>
  );
};

const MoveProjectProjectFields = ({form, field, organization}: any) => {
  const isProjectNameVisible = _.get(form.values, `${field}.isProjectNameVisible`);
  return (
    <React.Fragment>
      <HeaderRow form={form} field={field} />
      {isProjectNameVisible && (
        <React.Fragment>
          <FieldSpace />
          <ProjectNameRow form={form} field={field} index={0} />
        </React.Fragment>
      )}
      <FieldSpace />
      <TypeAndSizeRow form={form} field={field} index={1} organization={organization} />
      <FieldSpace />
      <FieldInput.Memoized
        {...form}
        name={`${field}.description`}
        label={'Project Description (shown to customer)'}
        index={2}
        input={{
          placeholder: 'Enter a description for the project. This will be shown to the customer.',
          style: {minHeight: 76, paddingTop: 8},
          multiline: true,
        }}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MoveProjectProjectFields.fragment = gql`
  ${ProjectSizeInput.fragment}
  ${ProjectTypeDropdown.fragment}
  fragment MoveProjectProjectFields on Organization {
    id
    ...ProjectSizeInput
    ...ProjectTypeDropdown
  }
`;

export default MoveProjectProjectFields;
