// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useDebouncedCallback,
  useModal,
  useNavigationDOM,
  useUrlFilters,
  useResponsive,
} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import BulkEditClaimStatusModal from 'modules/Claim/components/BulkEditClaimStatusModal';
import ClaimFiltersButton from 'modules/Claim/components/ClaimFiltersButton';

const FiltersContainer = Styled.View`
  flex: 1;
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'row' : 'column')};
  align-items: center;
  padding-horizontal: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 0 : 16)};
`;

const SearchContainer = Styled.View`
  flex-direction: row;
  align-items: center;
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'auto' : '100%')}
`;

const BulkEditContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'auto' : '100%')}
`;

const SelectedItemsText = Styled.Text`
  ${Typography.Responsive.Label}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'MaybeResp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const SearchInput = ({urlFilters, handleResetSelections}: any) => {
  const {params} = useNavigationDOM();
  const responsive = useResponsive();
  const handleUpdateQuery = useDebouncedCallback((text) => {
    urlFilters.handleUpdate({query: text});
    handleResetSelections();
  }, 500);
  return (
    <SearchBar
      onChangeText={handleUpdateQuery}
      placeholder='Search by project identifier, client name, or claim identifier'
      style={{width: responsive.desktop ? '436px' : '100%'}}
      containerStyle={{flex: 1}}
      defaultValue={params.query}
      isResponsive
    />
  );
};

const ListClaimsFilters = ({organization, selectedClaims, handleResetSelections, refetch}: any) => {
  const responsive = useResponsive();
  const editClaimStatusModal = useModal({
    name: 'Edit Claim Status Modal',
  });
  const urlFilters = useUrlFilters({
    getRoute: () => '/claims',
    filtersForUpdate: {page: '1'},
    onUpdate: handleResetSelections,
    filterKeys: [
      // @ts-expect-error TS(2322): Type '"query"' is not assignable to type '"page"'.
      'query',
      // @ts-expect-error TS(2322): Type '"claimTypeIds"' is not assignable to type '"... Remove this comment to see the full error message
      'claimTypeIds',
      // @ts-expect-error TS(2322): Type '"claimStatusId"' is not assignable to type '... Remove this comment to see the full error message
      'claimStatusId',
      // @ts-expect-error TS(2322): Type '"projectTypeIds"' is not assignable to type ... Remove this comment to see the full error message
      'projectTypeIds',
      // @ts-expect-error TS(2322): Type '"projectTagIds"' is not assignable to type '... Remove this comment to see the full error message
      'projectTagIds',
      // @ts-expect-error TS(2322): Type '"projectStatuses"' is not assignable to type... Remove this comment to see the full error message
      'projectStatuses',
      // @ts-expect-error TS(2322): Type '"minAmount"' is not assignable to type '"pag... Remove this comment to see the full error message
      'minAmount',
      // @ts-expect-error TS(2322): Type '"maxAmount"' is not assignable to type '"pag... Remove this comment to see the full error message
      'maxAmount',
      // @ts-expect-error TS(2322): Type '"slugs"' is not assignable to type '"page"'.
      'slugs',
    ],
  });

  return (
    <React.Fragment>
      <FiltersContainer responsive={responsive}>
        <SearchContainer responsive={responsive}>
          <SearchInput urlFilters={urlFilters} handleResetSelections={handleResetSelections} />
          <Space width={16} />
          <ClaimFiltersButton urlFilters={urlFilters} organization={organization} />
        </SearchContainer>
        {responsive.desktop ? <Space flex={1} /> : <Space height={16} />}
        <BulkEditContainer responsive={responsive}>
          <SelectedItemsText
            color={selectedClaims.length ? colors.blue.interactive : colors.gray.tertiary}
            responsive={responsive}
          >{`${pluralize('claim', _.size(selectedClaims), true)} selected`}</SelectedItemsText>
          {responsive.desktop ? <Space width={16} /> : <Space flex={1} />}
          <Button
            text={'Edit Claim Status'}
            isDisabled={_.size(selectedClaims) === 0}
            onPress={editClaimStatusModal.handleOpen}
            isResponsive
          />
        </BulkEditContainer>
      </FiltersContainer>
      <BulkEditClaimStatusModal
        key={editClaimStatusModal.key}
        isOpen={editClaimStatusModal.isOpen}
        handleClose={editClaimStatusModal.handleClose}
        selectedClaims={selectedClaims}
        handleResetSelections={handleResetSelections}
        organization={organization}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ListClaimsFilters.fragment = gql`
  ${BulkEditClaimStatusModal.fragment}
  ${ClaimFiltersButton.fragment}
  fragment ListClaimsFilters on Organization {
    id
    ...BulkEditClaimStatusModal
    ...ClaimFiltersButton
  }
`;

export default ListClaimsFilters;
