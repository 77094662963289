// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import DelayUnitType from '@shared/modules/Workflow/enums/DelayUnitType';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowBuilderOptionCard from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderOptionCard';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const ErrorMessage = Styled.Text`
  ${Typography.Micro}
  margin-top: 4px;
  color: ${colors.red.warning};
`;

const DelaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  field,
  handleSubmitValidateWorkflow,
}: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {label, description} = WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.DELAY];
  const selectedDelayUnitType = _.get(form.values, `${field}.delayUnitType`);
  const delayUnits = _.get(form.values, `${field}.delayUnits`);
  const isDoneDisabled = !delayUnits || !selectedDelayUnitType;
  const delayUnitTypeError = _.get(form.errors, `${field}.delayUnitType`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        name={`${field}.delayUnits`}
        label={'Time to delay'}
        isRequired
        input={{
          placeholder: 'Enter value',
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <WorkflowBuilderOptionCard.GroupedListContainer>
        {DelayUnitType.VALUES.map((delayUnitType, index) => (
          <WorkflowBuilderOptionCard.Tripled
            key={index}
            isTextCentered
            isSelected={delayUnitType === selectedDelayUnitType}
            index={index}
            text={DelayUnitType.getDisplayValue({value: delayUnitType, isPlural: true})}
            onPress={() => {
              form.setFieldValue(`${field}.delayUnitType`, delayUnitType);
            }}
          />
        ))}
        {delayUnitTypeError && <ErrorMessage>{delayUnitTypeError}</ErrorMessage>}
      </WorkflowBuilderOptionCard.GroupedListContainer>
    </WorkflowStepSlide>
  );
};

const DelayDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  field,
  isPreview,
}: any) => {
  const selectedDelayUnitType = _.get(form.values, `${field}.delayUnitType`);
  const delayUnits = _.get(form.values, `${field}.delayUnits`);
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={
        selectedDelayUnitType &&
        `Delay ${pluralize(
          DelayUnitType.getDisplayValue({value: selectedDelayUnitType, isPlural: false}),
          _.round(delayUnits, 2),
          true,
        )}`
      }
      isPreview={isPreview}
    />
  );
};

const WorkflowStepDelay = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => {
    const field = `${workflowStepsField}.${workflowStepIndex}.delayWorkflowActionForm`;
    return [
      <DelaySlide
        key={'CREATE'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
      />,
      <DelayDisplaySlide
        key={'DISPLAY'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        isPreview={isPreview}
      />,
    ];
  },
};

export default WorkflowStepDelay;
