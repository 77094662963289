// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';

// App
import Sheet from '@shared/design/components/Sheet';
import AttachmentPageContent from 'modules/Project/Attachments/components/AttachmentPageContent';

const ProjectAttachmentSheet = ({isOpen, handleClose, attachment, refetch}: any) => {
  return (
    <Sheet
      headerText={attachment.file.name}
      isOpen={isOpen}
      handleClose={handleClose}
      isFixedHeight
    >
      <AttachmentPageContent
        attachment={attachment}
        refetch={refetch}
        projectUuid={attachment.project.uuid}
        onSuccess={handleClose}
      />
    </Sheet>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectAttachmentSheet.fragment = gql`
  ${AttachmentPageContent.fragment}
  fragment ProjectAttachmentSheet on Attachment {
    id
    file {
      id
      name
    }
    project {
      id
      uuid
    }
    ...AttachmentPageContent
  }
`;

export default ProjectAttachmentSheet;
