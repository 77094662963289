// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Lifecycle, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useRef, useState} from '@supermove/hooks';
import {colors, fontWeight} from '@supermove/styles';

// App
import JobForm from '@shared/modules/Job/forms/JobForm';
import SourceKind from '@shared/modules/Project/enums/SourceKind';
import ProjectForm from '@shared/modules/Project/forms/ProjectForm';
import useCreateProjectMutation from '@shared/modules/Project/hooks/useCreateProjectMutation';
import {NewJobSuccessModal} from 'modules/App/Job/components';
import {Panel, SplitPage} from 'modules/App/components';
import {JobMap} from 'modules/Job/components';
import JobFields from 'modules/Job/components/JobFields';
import {JobRequestPage} from 'modules/JobRequest/components';
import {ProjectFieldsForJob} from 'modules/Project/components';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.H3`
  margin-top: 20px;
  margin-horizontal: 30px;
  ${fontWeight(900)}
  color: ${colors.gray.primary};
  letter-spacing: -0.5;
`;

const Content = Styled.View`
`;

const ExtraSpace = Styled.View`
  height: 200px;
`;

const Footer = Styled.View`
  padding: 10px;
  background-color: ${colors.white};
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${colors.blue.accent};
`;

const Button = Styled.LoadingButton`
  height: 40px;
  align-self: stretch;
`;

const Text = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const Section = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

// If the request already has a move (it was converted before),
// redirect the manager to the show move page.
const Redirect = ({children, jobRequest, navigator}: any) => (
  <Lifecycle
    onMount={() => {
      if (jobRequest.job) {
        navigator.replace(`/jobs/${jobRequest.job.uuid}`);
      }
    }}
  >
    {!jobRequest.job ? children : null}
  </Lifecycle>
);

const ConvertJobRequestPageContent = ({jobRequest, viewer}: any) => {
  const [job, setJob] = useState(null);
  const {isOpen, handleOpen, handleClose} = useModal();
  const scrollView = useRef();
  const mapRef = useRef();

  // Create the projectForm and attach handlers to the hook.
  const projectForm = ProjectForm.newFromJobRequest(jobRequest, {
    viewerId: viewer.id,
    sourceId: jobRequest.organization.source.id,
  });
  const {form, submitting, handleSubmit} = useCreateProjectMutation({
    projectForm,
    organization: jobRequest.organization,

    // Navigate to the job page on success. We assume there is always one job created
    // when the project is created and thus why [0] works.
    onSuccess: ({project}: any) => {
      setJob(project.jobs[0]);
      handleOpen();
    },
    onError: () => {
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      scrollView.current.scrollTo({y: 0});
    },
  });

  const {warehouseLocation} = jobRequest.organization;
  const moveLocations = JobForm.getLocations(_.get(form, 'values.projectForm.jobForms.0'));
  const boundLocations = warehouseLocation ? [warehouseLocation, ...moveLocations] : moveLocations;

  return (
    <React.Fragment>
      <SplitPage
        split={
          <Panel>
            <JobMap
              locations={moveLocations}
              boundLocations={boundLocations}
              warehouseLocation={warehouseLocation}
              mapRef={mapRef}
            />
          </Panel>
        }
      >
        <Container>
          <ScrollView ref={scrollView} style={{flex: 1}}>
            <Title>{`Convert Request to Job`}</Title>
            <Content>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Section index={0}>
                <ProjectFieldsForJob
                  field={'projectForm'}
                  jobField={'projectForm.jobForms.0'}
                  form={form}
                  organization={jobRequest.organization}
                  disabled={submitting}
                />
              </Section>
              {/* @ts-expect-error TS(2769): No overload matches this call. */}
              <Section index={1}>
                <JobFields
                  field={'projectForm'}
                  form={form}
                  organization={jobRequest.organization}
                  jobIndex={0}
                  shouldResetJobDateOnJobTypeChange
                  jobFormField={'projectForm.jobForms.0'}
                  warehouseLocation={warehouseLocation}
                  mapRef={mapRef}
                />
              </Section>
              <ExtraSpace />
            </Content>
          </ScrollView>
          <Footer>
            <Button loading={submitting} onPress={handleSubmit}>
              {!submitting && <Text>{`Convert to Job`}</Text>}
            </Button>
          </Footer>
        </Container>
      </SplitPage>
      <NewJobSuccessModal.Content isOpen={isOpen} job={job} onClose={handleClose} />
    </React.Fragment>
  );
};

const ConvertJobRequestPage = () => {
  const {params} = useNavigationDOM();
  const {uuid} = params;

  return (
    <JobRequestPage
      title={() => 'Convert Move Request'}
      subtitle={() => `Fill in details`}
      variables={{uuid, sourceKind: SourceKind.CUSTOMER_JOB_REQUEST_FORM}}
      query={ConvertJobRequestPage.query}
      onClose={({navigator}: any) => navigator.goBack()}
    >
      {({navigator, data}: any) => (
        <Redirect navigator={navigator} jobRequest={data.jobRequest}>
          <ConvertJobRequestPageContent jobRequest={data.jobRequest} viewer={data.viewer} />
        </Redirect>
      )}
    </JobRequestPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ConvertJobRequestPage.query = gql`
  ${useCreateProjectMutation.fragment}
  ${ProjectForm.newFromJobRequest.fragment}
  ${JobFields.fragment}
  ${JobRequestPage.fragment}
  ${ProjectFieldsForJob.fragment}

  query ConvertJobRequestPage($uuid: String!, $sourceKind: String!) {
    ${gql.query}
    viewer {
      id
    }
    jobRequest(uuid: $uuid) {
      id
      uuid
      job {
        id
        uuid
      }
      organization {
        id
        source(sourceKind: $sourceKind) {
          id
        }
        warehouseLocation {
          latitude
          longitude
        }
        ...useCreateProjectMutation
        ...JobFields
        ...ProjectFieldsForJob
      }
      ...ProjectForm_newFromJobRequest
      ...JobRequestPage
    }
  }
`;

export default ConvertJobRequestPage;
