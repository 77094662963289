// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table, {TableComponents} from '@shared/design/components/Table';
import PasscodeCell from 'modules/Organization/Settings/Company/components/PasscodeCell';

const sortBySlug = (a: any, b: any) => a.slug.localeCompare(b.slug);

// --------------------------------------------------
// Column Definitions
// --------------------------------------------------

const Row = Styled.View`
  flex-direction: row;
`;

const getViewColumnDefinitions = () => {
  return [
    {
      flex: 1,
      headerLabel: 'Name',
      cellText: (organization: any) => organization.name,
    },
    {
      flex: 1,
      headerLabel: 'Tablet Login',
      cellText: (organization: any) => organization.slug,
    },
    {
      flex: 1,
      headerLabel: 'Passcode',
      cellComponent: (organization: any) => {
        return <PasscodeCell passcode={organization.pin} />;
      },
    },
  ];
};

const getEditColumnDefinitions = ({form, responsive}: any) => {
  return [
    {
      flex: 1,
      headerLabel: 'Name',
      cellText: (organizationFormItem: any) => organizationFormItem.name,
    },
    {
      flex: 1,
      headerLabel: 'Tablet Login',
      cellText: (organizationFormItem: any) => organizationFormItem.slug,
    },
    {
      flex: 1,
      headerComponent: () => {
        return (
          <Row>
            <TableComponents.HeaderLabel
              responsive={responsive}
              style={{color: colors.red.warning}}
            >
              *
            </TableComponents.HeaderLabel>
            <TableComponents.HeaderLabel responsive={responsive}>
              Passcode
            </TableComponents.HeaderLabel>
            <TableComponents.HeaderLabel
              responsive={responsive}
              style={{
                color: colors.gray.tertiary,
                fontStyle: 'italic',
              }}
            >
              {` –– required`}
            </TableComponents.HeaderLabel>
          </Row>
        );
      },

      cellComponent: (organizationFormItem: any, rowIndex: any) => {
        return (
          <FieldInput
            {...form}
            value={organizationFormItem.pin}
            name={`companyTabletSettingsForm.organizationTabletSettingsForms.${rowIndex}.pin`}
            showErrorMessage={false}
            input={{
              placeholder: `Enter a passcode`,
            }}
          />
        );
      },
    },
  ];
};

// --------------------------------------------------
// TabletLoginsPanel
// --------------------------------------------------

const TabletLoginsPanel = ({organization}: any) => {
  const organizations = organization.isPrimary
    ? organization.company.organizations
    : [organization];

  return (
    <Table
      items={organizations.sort(sortBySlug)}
      itemKey={'id'}
      columnDefinitions={getViewColumnDefinitions()}
      // @ts-expect-error TS(2322): Type '{ items: any; itemKey: string; columnDefinit... Remove this comment to see the full error message
      isDense
    />
  );
};

// --------------------------------------------------
// TabletLoginsPanelEdit
// --------------------------------------------------

const hasPasscodeError = ({form}: any) => {
  return (
    !_.isEmpty(form.errors) &&
    form.errors.companyTabletSettingsForm.organizationTabletSettingsForms.some(
      (organizationTabletSettingsForm: any) =>
        organizationTabletSettingsForm &&
        // It seems Object.hasOwn() has to be used here instead of Object.hasOwnProperty() since
        // using the latter was returning false. One difference between Object.hasOwn() and
        // Object.hasOwnProperty() is that Object.hasOwn() works for objects created using
        // Object.create(null) (source: Mozilla). The error object in the form object is constructed
        // in setFormServerErrors() using lodash, which presumably uses Object.create(null) to
        // create the object.
        // @ts-expect-error TS(2550): Property 'hasOwn' does not exist on type 'ObjectCo... Remove this comment to see the full error message
        Object.hasOwn(organizationTabletSettingsForm, 'pin'),
    )
  );
};

const TabletLoginsPanelEdit = ({form}: any) => {
  const {organizationTabletSettingsForms} = form.values.companyTabletSettingsForm;
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {hasPasscodeError({form}) && (
        <React.Fragment>
          <ErrorCallout text={'Passcodes must be at least 4 characters long.'} />
          <Space height={16} />
        </React.Fragment>
      )}
      <Table
        items={organizationTabletSettingsForms.sort(sortBySlug)}
        itemKey={'organizationId'}
        columnDefinitions={getEditColumnDefinitions({form, responsive})}
      />
    </React.Fragment>
  );
};

TabletLoginsPanel.Edit = TabletLoginsPanelEdit;

export default TabletLoginsPanel;
