// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import EditProjectInternalInformationDrawer from 'modules/Project/V2/Show/Blocks/components/EditProjectInternalInformationDrawer';
import MobileProjectBlockHeader from 'modules/Project/V2/Show/Blocks/components/MobileProjectBlockHeader';
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';

const ResponsiveContainer = Styled.View`
  flex-direction: ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => (responsive.desktop ? 'row' : 'column')};
`;

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const FieldValueWrapper = Styled.View`
  ${({
    // @ts-expect-error TS(2339): Property 'responsive' does not exist on type 'Them... Remove this comment to see the full error message
    responsive,
  }) => responsive.desktop && 'flex: 1;'}
`;

const SkeletonField = ({label}: any) => {
  return (
    <Column style={{flex: 1}}>
      <FieldValue.LabelText>{label}</FieldValue.LabelText>
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={180} />
    </Column>
  );
};

const SkeletonBody = () => {
  return (
    <Column>
      <Row style={{flex: 1}}>
        <SkeletonField label={'Salesperson'} />
        <SkeletonField label={'Additional Salespersons'} />
        <SkeletonField label={'Coordinator'} />
      </Row>
      <Space height={16} />
      <Row style={{flex: 1}}>
        <SkeletonField label={'How did you hear about us?'} />
        <SkeletonField label={'Referral Details'} />
        <SkeletonField label={'Created By'} />
      </Row>
    </Column>
  );
};

const SkeletonComponent = () => {
  return (
    <ActionPanel
      BodyComponent={SkeletonBody}
      ActionButtonComponent={() => (
        <SkeletonLoader height={SkeletonLoader.HEIGHT.ButtonSmall} width={72} />
      )}
      title={'Internal Information'}
      style={{width: '100%'}}
    />
  );
};

const InternalField = ({value, label}: any) => {
  const responsive = useResponsive();

  return (
    <FieldValueWrapper responsive={responsive}>
      <FieldValue value={value} label={label} empty={'None'} isResponsive />
    </FieldValueWrapper>
  );
};

const InternalInformationFields = ({project}: any) => {
  const {isEnabledAdditionalSalespersons} = project.organization.features;
  const responsive = useResponsive();

  return (
    <Column>
      <ResponsiveContainer responsive={responsive}>
        <InternalField label={'Salesperson'} value={project.bookedBy?.fullName} />
        <Space width={16} height={16} />
        {isEnabledAdditionalSalespersons ? (
          <React.Fragment>
            <InternalField
              label={
                project.organization.features.isEnabledProjectManagersField
                  ? 'Project Managers'
                  : 'Additional Salespersons'
              }
              value={project.additionalSalespersons
                .map((salesperson: any) => salesperson.fullName)
                .join(', ')}
              empty={'None'}
            />
            <Space width={16} height={16} />
            <InternalField label={'Coordinator'} value={project.coordinatedBy?.fullName} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <InternalField label={'Coordinator'} value={project.coordinatedBy?.fullName} />
            {responsive.desktop && (
              <React.Fragment>
                <Space width={16} />
                <Column style={{flex: 1}} />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </ResponsiveContainer>
      <Space height={16} />
      <ResponsiveContainer responsive={responsive}>
        <InternalField label={'How did you hear about us?'} value={project.referralSource} />
        <Space width={16} height={16} />
        <InternalField label={'Referral Details'} value={project.referralDetails} />
        <Space width={16} height={16} />
        <InternalField label={'Created By'} value={project.creator?.fullName} />
      </ResponsiveContainer>
    </Column>
  );
};

const EditInternalInformationButton = ({project, refetch}: any) => {
  const responsive = useResponsive();
  const editInternalInformationDrawer = useDrawer({
    name: 'Edit Project Internal Information Drawer',
  });

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <Button
          text={'Edit'}
          iconLeft={Icon.Pen}
          onPress={editInternalInformationDrawer.handleOpen}
          isSmall
        />
      ) : (
        <TertiaryButton
          text={`Edit`}
          onPress={editInternalInformationDrawer.handleOpen}
          isResponsive
          isHitSlop
        />
      )}
      <EditProjectInternalInformationDrawer
        key={editInternalInformationDrawer.key}
        project={project}
        isOpen={editInternalInformationDrawer.isOpen}
        handleClose={editInternalInformationDrawer.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

const ProjectInternalBlockContent = ({project, refetch}: any) => {
  return (
    <ActionPanel
      BodyComponent={InternalInformationFields}
      bodyComponentProps={{project}}
      ActionButtonComponent={EditInternalInformationButton}
      actionButtonComponentProps={{project, refetch}}
      title={'Internal Information'}
      style={{width: '100%'}}
    />
  );
};

const ProjectInternalBlockContentMobile = ({project, refetch}: any) => {
  return (
    <React.Fragment>
      <MobileProjectBlockHeader
        title={`Internal Information`}
        ActionsComponent={EditInternalInformationButton}
        actionsComponentProps={{project, refetch}}
      />
      <InternalInformationFields project={project} />
    </React.Fragment>
  );
};

const ProjectInternalBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  index,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectInternalBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data, refetch}: any) => {
        const {project} = data;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectInternalBlockContent project={project} refetch={refetch} />
            ) : (
              <ProjectInternalBlockContentMobile project={project} refetch={refetch} />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectInternalBlock.fragment = gql`
  fragment ProjectInternalBlock on Project {
    id
    uuid
  }
`;

ProjectInternalBlock.query = gql`
  ${EditProjectInternalInformationDrawer.fragment}

  query ProjectInternalBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      referralSource
      referralDetails
      bookedBy {
        id
        fullName
      }
      additionalSalespersons {
        id
        fullName
      }
      coordinatedBy {
        id
        fullName
      }
      creator {
        id
        fullName
      }
      organization {
        id
        features {
          isEnabledAdditionalSalespersons: isEnabled(feature: "ADDITIONAL_SALESPERSON_IDS_INPUT")
          isEnabledProjectManagersField: isEnabled(feature: "PROJECT_MANAGERS_FIELD")
        }
      }
      ...EditProjectInternalInformationDrawer
    }
  }
`;

export default ProjectInternalBlock;
