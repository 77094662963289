// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Form, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {DocumentTemplateModel, OrganizationModel} from '@supermove/models';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import {UserFlowFormToFormType} from '@shared/modules/UserFlows/forms/UserFlowForm';

export type DocumentFlowStepFieldsFragmentType = {
  id: number;
  documentTemplates: DocumentTemplateModel[];
};

const toDocumentTemplateOptions = (documentTemplates: DocumentTemplateModel[]) => {
  return documentTemplates.map((documentTemplate) => ({
    label: documentTemplate.name,
    value: documentTemplate.id,
    description: documentTemplate.description,
  }));
};

interface DocumentFlowStepFieldsProps {
  form: Form<{userFlowForm: UserFlowFormToFormType}>;
  field: string;
  organization: OrganizationModel;
}

const DocumentFlowStepFields = ({form, field, organization}: DocumentFlowStepFieldsProps) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        index={2}
        name={`${field}.documentTemplateId`}
        errorName={`${field}.documentTemplateId`}
        label={'Document'}
        isResponsive
        isRequired
        component={DropdownInput}
        input={{
          options: toDocumentTemplateOptions(organization.documentTemplates),
          showDescriptionInOption: true,
          placeholder: `Select document`,
          setFieldValue: (name: string, value: string | null) => {
            if (value) {
              form.setFieldValue(`${field}.documentTemplateId`, value);
              form.setFieldValue(
                `${field}.documentTemplate`,
                organization.documentTemplates.find((dt) => dt.id === value),
              );
            }
          },
          isPortaled: true,
          style: {flex: 1},
          noOptionsMessage: () => 'No documents found',
          isPortalled: true,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        index={0}
        name={`${field}.buttonText`}
        label={'Button Text'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter button text',
        }}
      />
      <Space height={16} />
      <Checkbox
        isChecked={_.get(form.values, `${field}.isDefault`)}
        handleToggle={() =>
          form.setFieldValue(`${field}.isDefault`, !_.get(form.values, `${field}.isDefault`))
        }
        label={'Include document by default'}
        isResponsive
      />
    </React.Fragment>
  );
};

DocumentFlowStepFields.fragment = gql`
  fragment DocumentFlowStepFields on Organization {
    id
    documentTemplates {
      id
      name
      description
    }
  }
`;

export default DocumentFlowStepFields;
