// Libararies
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ActionMenuPopover from 'modules/App/components/ActionMenuPopover';
import Line from 'modules/App/components/Line';
import CancelTripModal from 'modules/Dispatch/LongDistance/components/CancelTripModal';
import DuplicateTripModal from 'modules/Dispatch/LongDistance/components/DuplicateTripModal';
import EditTripModal from 'modules/Dispatch/LongDistance/components/EditTripModal';
import EditTripStatusDropdown from 'modules/Dispatch/LongDistance/components/EditTripStatusDropdown';

const Row = Styled.View`
  flex-direction: row;
`;

const TripLogisticsContainer = Styled.View`
  width: 240px;
`;

const BlockContainer = Styled.View`
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 4px;
`;

const SectionContainer = Styled.View`
  padding-vertical: 12px;
  padding-horizontal: 16px;
`;

const SectionHeader = Styled.Text`
  ${Typography.Label3}
`;

const TripNameText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.gray.secondary};
`;

const TripActionsMenu = ({popover, editTripModal, duplicateTripModal, cancelTripModal}: any) => {
  return (
    <ActionMenuPopover popover={popover} placement={Popover.Positions.BottomStart} width={208}>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          editTripModal.handleOpen();
          popover.handleClose();
        }}
      >
        Edit Trip
      </ActionMenuPopover.MenuItem>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          duplicateTripModal.handleOpen();
          popover.handleClose();
        }}
      >
        Duplicate Trip
      </ActionMenuPopover.MenuItem>
      <ActionMenuPopover.MenuItem
        onPress={() => {
          cancelTripModal.handleOpen();
          popover.handleClose();
        }}
        color={colors.red.warning}
      >
        Cancel Trip
      </ActionMenuPopover.MenuItem>
    </ActionMenuPopover>
  );
};

const TripProfile = ({trip, refetch}: any) => {
  return (
    <BlockContainer>
      <SectionContainer>
        <SectionHeader style={{color: colors.blue.interactive}}>Long Distance Trip</SectionHeader>
        <Space height={4} />
        <TripNameText>{trip.name}</TripNameText>
        <Space height={8} />
        <Row>
          <EditTripStatusDropdown
            key={trip.status}
            trip={trip}
            onSuccess={refetch}
            size={'LARGE'}
          />
        </Row>
      </SectionContainer>
    </BlockContainer>
  );
};

const TripDriver = ({trip}: any) => {
  return (
    <BlockContainer>
      <SectionContainer>
        <SectionHeader>Driver Information</SectionHeader>
      </SectionContainer>
      <Line />
      <SectionContainer>
        <FieldValue label={'Driver Name'} value={_.get(trip.driver, 'fullName')} empty={'-'} />
        <Space height={12} />
        <FieldValue
          label={'Phone Number'}
          value={Phone.display(_.get(trip.driver, 'phoneNumber'))}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue label={'Email Address'} value={_.get(trip.driver, 'email')} empty={'-'} />
        <Space height={12} />
        <FieldValue label={'Driver Notes'} value={trip.driverNotes} empty={'-'} />
      </SectionContainer>
    </BlockContainer>
  );
};

const TripTrucks = ({trip}: any) => {
  const truckNames = trip.tripSegments[0].trucks.map((truck: any) => truck.name);
  return (
    <BlockContainer>
      <SectionContainer>
        <SectionHeader>Truck Information</SectionHeader>
      </SectionContainer>
      <Line />
      <SectionContainer>
        <FieldValue label={'Trucks'} value={truckNames.join(', ')} empty={'-'} />
      </SectionContainer>
    </BlockContainer>
  );
};

const TripDetails = ({trip}: any) => {
  return (
    <BlockContainer>
      <SectionContainer>
        <SectionHeader>Trip Details</SectionHeader>
      </SectionContainer>
      <Line />
      <SectionContainer>
        <FieldValue
          label={'Origin'}
          value={trip.startLocation && Location.getDisplayLocation(trip.startLocation)}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue
          label={'Destination'}
          value={trip.endLocation && Location.getDisplayLocation(trip.endLocation)}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue
          label={'Start Date'}
          value={trip.startDate && Datetime.convertToDisplayDate(trip.startDate, 'M/D/YY')}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue
          label={'End Date'}
          value={trip.endDate && Datetime.convertToDisplayDate(trip.endDate, 'M/D/YY')}
          empty={'-'}
        />
        <Space height={12} />
        <FieldValue label={'Total Weight'} value={`${trip.totalWeight} lbs`} />
      </SectionContainer>
    </BlockContainer>
  );
};

const TripInternalInformation = ({trip}: any) => {
  return (
    <BlockContainer>
      <SectionContainer>
        <SectionHeader>Internal Information</SectionHeader>
      </SectionContainer>
      <Line />
      <SectionContainer>
        <FieldValue label={'Office Notes'} value={trip.officeNotes} empty={'-'} />
      </SectionContainer>
    </BlockContainer>
  );
};

const TripLogisticsColumn = ({trip, refetch}: any) => {
  const tripActionsPopover = usePopover();
  const cancelTripModal = useModal({name: 'Cancel Trip Modal'});
  const duplicateTripModal = useModal({name: 'Duplicate Trip Modal', enableTracking: true});
  const editTripModal = useModal({name: 'Edit Trip Modal', enableTracking: true});
  return (
    <TripLogisticsContainer>
      <ScrollView style={{paddingHorizontal: 16}}>
        <Space height={16} />
        <Popover.Content innerRef={tripActionsPopover.ref}>
          <Button
            isWidthOfContainer
            text={'Actions'}
            iconRight={Icon.ChevronDown}
            onPress={tripActionsPopover.handleToggle}
          />
        </Popover.Content>
        <Space height={12} />
        <TripProfile trip={trip} refetch={refetch} />
        <Space height={12} />
        <TripDriver trip={trip} />
        <Space height={12} />
        <TripTrucks trip={trip} />
        <Space height={12} />
        <TripDetails trip={trip} />
        <Space height={12} />
        <TripInternalInformation trip={trip} />
        <Space height={16} />
      </ScrollView>
      <TripActionsMenu
        popover={tripActionsPopover}
        editTripModal={editTripModal}
        duplicateTripModal={duplicateTripModal}
        cancelTripModal={cancelTripModal}
      />
      <EditTripModal
        isOpen={editTripModal.isOpen}
        handleClose={editTripModal.handleClose}
        trip={trip}
        refetch={refetch}
      />
      <DuplicateTripModal
        isOpen={duplicateTripModal.isOpen}
        handleClose={duplicateTripModal.handleClose}
        trip={trip}
      />
      <CancelTripModal
        isOpen={cancelTripModal.isOpen}
        handleClose={cancelTripModal.handleClose}
        tripId={trip.id}
        refetch={refetch}
      />
    </TripLogisticsContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TripLogisticsColumn.fragment = gql`
  ${DuplicateTripModal.fragment}
  ${EditTripModal.fragment}
  ${EditTripStatusDropdown.fragment}
  ${Location.getDisplayLocation.fragment}

  fragment TripLogisticsColumn on Trip {
    id
    name
    status
    startDate
    endDate
    totalWeight
    officeNotes
    driverNotes
    driver {
      id
      fullName
      phoneNumber
      email
    }
    startLocation {
      id
      ...Location_getDisplayLocation
    }
    endLocation {
      id
      ...Location_getDisplayLocation
    }
    tripSegments {
      id
      trucks {
        id
        name
      }
    }
    ...DuplicateTripModal
    ...EditTripModal
    ...EditTripStatusDropdown
  }
`;

export default TripLogisticsColumn;
