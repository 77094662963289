// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {colors, Typography} from '@supermove/styles';

// App
import Modal from '@shared/design/components/Modal';
import JobValueFields from 'modules/Project/Billing/components/JobValueFields';
import ProjectValueFields from 'modules/Project/Billing/components/ProjectValueFields';
import TwoColumnValueFields from 'modules/Project/Billing/components/TwoColumnValueFields';

const EmptyStateText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const BillingValuesFields = ({form, field, isEdit, isShowTitle}: any) => {
  const hasVisibleValueForms = !_.isEmpty(
    _.filter(
      _.get(form.values, `${field}.valueForms`),
      (valueForm) => valueForm.isVisibleForCreateProject,
    ),
  );
  const jobFormField = `${field}.jobForms.0`;
  const jobForm = _.get(form.values, jobFormField);
  return (
    <React.Fragment>
      {isShowTitle && <Modal.BlockHeader>Variables</Modal.BlockHeader>}
      <Space height={12} />
      {!hasVisibleValueForms && <EmptyStateText>No variables to display</EmptyStateText>}
      <ProjectValueFields
        index={0}
        projectTypeField={`${field}.projectTypeId`}
        valueFormsField={`${field}.valueForms`}
        form={form}
        isEdit={isEdit}
      >
        {({index, valueForms, valueFormsField, form, startingValueFormIndex}: any) => (
          <React.Fragment>
            <TwoColumnValueFields
              outerIndex={index}
              form={form}
              valueFormsField={valueFormsField}
              valueForms={valueForms}
              startingValueFormIndex={startingValueFormIndex}
            />
            <Space height={8} />
          </React.Fragment>
        )}
      </ProjectValueFields>
      {jobForm && (
        // StorageProjectForm does not support jobs at this time.
        // This will ensure job valueForms only display on move project.
        <JobValueFields
          index={1}
          jobTypeField={`${jobFormField}.jobTypeId`}
          valueFormsField={`${jobFormField}.valueForms`}
          form={form}
        >
          {({index, valueForms, valueFormsField, form, startingValueFormIndex}: any) => (
            <React.Fragment>
              <TwoColumnValueFields
                outerIndex={index}
                form={form}
                valueFormsField={valueFormsField}
                valueForms={valueForms}
                startingValueFormIndex={startingValueFormIndex}
              />
              <Space height={8} />
            </React.Fragment>
          )}
        </JobValueFields>
      )}
    </React.Fragment>
  );
};

export default BillingValuesFields;
