// Libraries
import React from 'react';

// Supermove
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

// Components
import OrganizationSuppliesForm from '../forms/OrganizationSuppliesForm';

type OwnProps = {
  organizationSuppliesForm: any;
  onSuccess: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateSuppliesMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateSuppliesMutation' implicitly has type 'any'... Remove this comment to see the full error message
const UpdateSuppliesMutation = ({
  organizationSuppliesForm,
  onSuccess,
  onError,
  setMutationErrors,
  submitForm,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      organizationSuppliesForm: OrganizationSuppliesForm.toMutation(organizationSuppliesForm),
    }}
    mutation={UpdateSuppliesMutation.mutation}
    onCompleted={({response: {organization, errors}}: any) => {
      if (submitForm) {
        submitForm();
      }

      if (setMutationErrors) {
        setMutationErrors(errors);
      }

      if (!errors) {
        onSuccess(organization);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) =>
      children({
        loading,
        handleSubmit,
      })
    }
  </Mutation>
);

UpdateSuppliesMutation.defaultProps = {
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateSuppliesMutation.mutation = gql`
  mutation UpdateSuppliesMutation($organizationSuppliesForm: OrganizationSuppliesForm!) {
    response: updateOrganizationSupplies(
      organizationSuppliesForm: $organizationSuppliesForm
    ) {
      ${gql.errors}
      organization {
        id
        settings {
          id
          supplies
        }
      }
    }
  }
`;

export default UpdateSuppliesMutation;
