// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDebouncedCallback, useNavigationDOM, useQuery, useState} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Drawer from '@shared/design/components/Drawer';
import EmptyState from '@shared/design/components/EmptyState';
import FieldInput from '@shared/design/components/Field/FieldInput';
import GlobalSearchItem from 'modules/App/Global/components/GlobalSearchItem';
import MobileHeader from 'modules/App/Global/components/MobileHeader';
import Line from 'modules/App/components/Line';
import SearchJobItem from 'modules/App/components/ManagerSearch/components/SearchJobItem';
import useAppContext from 'modules/App/context/useAppContext';

const Container = Styled.View`
  flex: 1;
`;

const SearchInputContainer = Styled.View`
`;

const ClearButton = Styled.ButtonV2`
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 8px;
`;

const SearchButton = Styled.ButtonV2`
`;

const LoadingComponent = () => {
  return (
    <React.Fragment>
      <GlobalSearchItem.LoadingComponent />
      <Line />
      <GlobalSearchItem.LoadingComponent />
      <Line />
      <GlobalSearchItem.LoadingComponent />
    </React.Fragment>
  );
};

const JobResults = ({jobs, handleClose}: any) => {
  const {viewer} = useAppContext();
  const {navigator} = useNavigationDOM();

  return (
    <React.Fragment>
      {jobs.map((job: any, index: any) => {
        return (
          <React.Fragment key={job.id}>
            {index > 0 && <Line />}
            <SearchButton
              onPress={() => {
                navigator.push(`/jobs/${job.uuid}`);
                handleClose();
              }}
            >
              {viewer?.viewingOrganization.features.isEnabledGlobalSearchV2 ? (
                <GlobalSearchItem key={job.id} job={job} />
              ) : (
                <SearchJobItem key={job.id} job={job} handleClose={handleClose} />
              )}
            </SearchButton>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const NoResults = () => {
  return (
    <EmptyState.ContentContainer>
      <EmptyState
        icon={Icon.Search}
        title={'No results found.'}
        message={'Your input returned no results.\nChange your search and try again.'}
      />
    </EmptyState.ContentContainer>
  );
};

const MobileSearchDrawerContent = ({handleClose}: any) => {
  const {viewer} = useAppContext();
  const [input, setInput] = useState('');
  const [search, setSearch] = useState(input);

  const handleUpdateSearch = useDebouncedCallback((text) => setSearch(text), 500);

  const {data, loading} = useQuery(MobileSearchDrawer.query, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: viewer?.viewingOrganization.id,
      searchQuery: search,
    },
    skip: !viewer,
  });

  return (
    <Container>
      <MobileHeader handleClose={handleClose} title={'Search'} />
      <SearchInputContainer>
        <FieldInput.TextInput
          style={{borderWidth: 0, paddingLeft: 40, height: 48}}
          value={input}
          onChangeText={(text: any) => {
            setInput(text);
            handleUpdateSearch(text);
          }}
        />
        <Icon
          source={Icon.MagnifyingGlass}
          size={16}
          color={colors.gray.tertiary}
          style={{position: 'absolute', top: 16, left: 16}}
        />
        <ClearButton
          onPress={() => {
            setInput('');
            setSearch('');
          }}
        >
          <Icon source={Icon.Xmark} size={16} color={colors.gray.secondary} />
        </ClearButton>
      </SearchInputContainer>
      <Line />
      {search && loading ? (
        <LoadingComponent />
      ) : search && data && _.isEmpty(data.jobs) ? (
        <NoResults />
      ) : (
        <ScrollView>
          <JobResults jobs={data?.jobs || []} handleClose={handleClose} />
        </ScrollView>
      )}
    </Container>
  );
};

const MobileSearchDrawer = ({isOpen, handleClose}: any) => {
  return (
    <Drawer isOpen={isOpen} handleClose={handleClose} shouldCloseOnClickOutside={false}>
      <MobileSearchDrawerContent isOpen={isOpen} handleClose={handleClose} />
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MobileSearchDrawer.query = gql`
  ${GlobalSearchItem.fragment}
  ${SearchJobItem.fragment}

  query MobileSearchDrawer(
    $organizationId: Int!,
    $searchQuery: String!,
  ) {
    ${gql.query}
    jobs: searchJobsByQuery(
      organizationId: $organizationId,
      searchQuery: $searchQuery,
    ) {
      id
      uuid
      ...GlobalSearchItem
      ...SearchJobItem
    }
  }
`;

export default MobileSearchDrawer;
