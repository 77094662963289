// Libraries
import React from 'react';

// App
import {Icon, Modal, Space, Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 16px;
  padding-horizontal: 16px;
`;

const IconContainer = Styled.View`
  height: 48px;
  width: 48px;
  border-radius: 24px;
  background-color: ${colors.alpha(colors.red.warning, 0.1)};
  align-items: center;
  justify-content: center;
`;

const MainText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const SubText = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
`;

const Actions = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

const Button = Styled.ButtonV2`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100px;
  border-radius: 4px;
  background-color: ${(props) => (props as any).color}
`;

const ButtonText = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const ProjectJobDeleteModal = ({onPress, handleClose, isOpen}: any) => (
  <Modal.Content onClose={handleClose} isOpen={isOpen}>
    <Container pointerEvents={'box-none'}>
      <Wrapper width={392}>
        <Space height={24} />
        <IconContainer>
          <Icon color={colors.red.warning} size={22} source={Icon.Trash} />
        </IconContainer>
        <Space height={24} />
        <MainText>Are you sure you want to delete this job?</MainText>
        <Space height={8} />
        <SubText>You can't undo this action.</SubText>
        <Space height={24} />
        <Actions>
          <Button color={colors.white} onPress={handleClose}>
            <ButtonText color={colors.gray.secondary}>Cancel</ButtonText>
          </Button>
          <Space width={8} />
          <Button
            color={colors.red.warning}
            onPress={() => {
              onPress();
              handleClose();
            }}
          >
            <ButtonText color={colors.white}>Delete</ButtonText>
          </Button>
          <Space width={8} />
        </Actions>
        <Space height={24} />
      </Wrapper>
    </Container>
  </Modal.Content>
);

export default ProjectJobDeleteModal;
