// Libraries
import React from 'react';

// Supermove
import {Styled, Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import DocumentItemBlockByCategory from '@shared/modules/Document/components/DocumentItemBlockByCategory';

const DocumentItemBlockContainer = Styled.ButtonV2`

  border-color: ${({
    // @ts-expect-error TS(2339): Property 'isFocused' does not exist on type 'Theme... Remove this comment to see the full error message
    isFocused,
  }) => (isFocused ? colors.blue.interactive : colors.white)};
  border-width: ${({
    // @ts-expect-error TS(2339): Property 'isFocused' does not exist on type 'Theme... Remove this comment to see the full error message
    isFocused,
  }) => (isFocused ? '2px' : '0px')};
  border-radius: ${({
    // @ts-expect-error TS(2339): Property 'isFocused' does not exist on type 'Theme... Remove this comment to see the full error message
    isFocused,
  }) => (isFocused ? '4px' : '0px')};
`;

const JobDocumentContentRendererV1 = ({
  documentContentJson,
  isPreview,
  isEditable,
  job,
  documentEditorSelectedIndex,
  setSelectedDocumentItem,
}: any) => {
  return (
    <React.Fragment>
      <Space height={12} />
      {documentContentJson.documentItems.map((item: any, index: any) => {
        const isSelected =
          documentEditorSelectedIndex != null && documentEditorSelectedIndex === index;
        return (
          <React.Fragment key={index}>
            <DocumentItemBlockContainer
              key={index}
              disabled={!setSelectedDocumentItem}
              isFocused={isSelected}
              onPress={() => {
                if (setSelectedDocumentItem) {
                  if (isSelected) {
                    setSelectedDocumentItem({});
                  } else {
                    setSelectedDocumentItem({index, documentItem: item});
                  }
                }
              }}
            >
              <DocumentItemBlockByCategory
                key={index}
                index={index}
                item={item}
                isPreview={isPreview}
                isEditable={isEditable}
                // @ts-expect-error this field doesn't exist, but the component will be deleted soon anyway
                isFocused={documentEditorSelectedIndex && documentEditorSelectedIndex === index}
                job={job}
                project={job?.project || null}
              />
              <Space height={8} />
            </DocumentItemBlockContainer>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export default JobDocumentContentRendererV1;
