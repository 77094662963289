// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, ScrollView, Styled, Space, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useNavigationDOM,
  useQuery,
  useModal,
  usePopover,
  useResponsive,
  useState,
  useDrawer,
} from '@supermove/hooks';
import {Job} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import Switch from '@shared/design/components/Switch';
import Table from '@shared/design/components/TableV2Deprecated';
import TextTooltip from '@shared/design/components/TextTooltip';
import JobTypeForm from '@shared/modules/Job/forms/JobTypeForm';
import useUpdateJobTypeIndexMutation from '@shared/modules/Job/hooks/useUpdateJobTypeIndexMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';
import SidebarPageV2 from 'modules/App/components/SidebarPageV2';
import ApplyJobTypeConfigDrawer from 'modules/Organization/Settings/JobTypes/components/ApplyJobTypeConfigDrawer';
import CreateJobTypeModal from 'modules/Organization/Settings/JobTypes/components/CreateJobTypeModal';
import EditJobTypeModal from 'modules/Organization/Settings/JobTypes/components/EditJobTypeModal';
import RemoveJobTypeModal from 'modules/Organization/Settings/JobTypes/components/RemoveJobTypeModal';
import ProjectTypeJobSettingsPanel from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeJobSettingsPanel';
import ProjectTypeSettingsPageHeader from 'modules/Organization/Settings/ProjectTypes/components/ProjectTypeSettingsPageHeader';

const PageContainer = Styled.View`
  flex: 1;
`;

const Text = Styled.Text`
  ${Typography.Body3}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label3}
;`;

const EditJobTypeButton = Styled.ButtonV2`
`;

const KindHeaderText = Styled.Text`
  ${Typography.Heading4}
`;

const KindDescriptionText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.secondary};
`;

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link2}
`;

const JobTypeTableContainer = Styled.View`
`;

const CellColumn = Styled.View`
  flex-direction: column;
`;

const NameText = Styled.Text`
  ${Typography.Body5}
  color: ${colors.gray.secondary};
`;

const IconContainer = Styled.View`
  width: 16px;
  padding-top: 2px;
`;

const MoreActionsButton = Styled.ButtonV2`
  padding-horizontal: 6px;
`;

const MenuItemTouchable = Styled.ButtonV2`
`;

const MenuItemText = Styled.Text`
  ${Typography.Body3}
  padding-vertical: 4px;
  padding-horizontal: 12px;
`;

const TableControlsRow = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const ReorderButtonsColumnContainer = Styled.View`
  width: 16px;
  height: 36px;
  justify-content: center;
`;

const ReorderButtonIconCircle = Styled.Touchable`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border-width: 1px;
  border-color: ${(props) => ((props as any).color ? (props as any).color : (props as any).disabled ? colors.gray.border : colors.blue.interactive)};
  justify-content: center;
  align-items: center;
`;

const getDisplayJobTypeBillTypes = ({jobType, projectType, navigator}: any) => {
  const {billTypes} = jobType;
  if (_.isEmpty(billTypes)) {
    return <Text style={{color: colors.gray.tertiary}}>None selected</Text>;
  }

  return (
    <Text>
      {billTypes.map((billType: any, index: any) => (
        <React.Fragment key={billType.id}>
          <LinkButton
            onPress={() =>
              navigator.push(
                `/settings/billing/billing-libraries/${projectType.billingLibrary.uuid}/bill-templates/${billType.uuid}`,
              )
            }
          >
            <LinkText>
              {billType.name}
              {index !== billTypes.length - 1 ? ', ' : ''}
            </LinkText>
          </LinkButton>
        </React.Fragment>
      ))}
    </Text>
  );
};

const getDisplayProjectDateSettings = ({jobType}: any) => {
  const {setsPackDates, setsLoadDates, setsDeliveryDates} = jobType;
  const projectDateSettings = [
    ...(setsPackDates ? ['Pack Date(s)'] : []),
    ...(setsLoadDates ? ['Load Date(s)'] : []),
    ...(setsDeliveryDates ? ['Delivery Date(s)'] : []),
  ];
  const displayProjectDateSettings = _.join(projectDateSettings, ', ');

  if (!displayProjectDateSettings) {
    return <Text style={{color: colors.gray.tertiary}}>None selected</Text>;
  }

  return <Text numberOfLines={1}>{displayProjectDateSettings}</Text>;
};

const getJobTypeColumnDefinitions = ({
  projectType,
  projectTypeUuid,
  showProjectDatesColumn,
  refetch,
  itemsCount,
  isReordering,
  projectTypeJobTypes,
  navigator,
}: any) => [
  {
    width: 50,
    headerContent: () => {},
    isHidden: !isReordering,
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType, rowIndex: index}) => {
      const isFirstItem = index === 0;
      const isLastItem = index === itemsCount - 1;
      return (
        <ReorderButtonsColumn
          isFirstItem={isFirstItem}
          isLastItem={isLastItem}
          jobType={jobType}
          index={index}
          refetch={refetch}
        />
      );
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Name</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      return (
        <Text>
          <LinkButton
            onPress={() =>
              navigator.push(
                `/settings/projects/project-types/${projectTypeUuid}/job-types/${jobType.uuid}/general`,
              )
            }
          >
            <LinkText>{jobType.name}</LinkText>
          </LinkButton>
        </Text>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return (
        <React.Fragment>
          <TableHeaderText>Type</TableHeaderText>
          <Space width={6} />
          <TextTooltip
            isEnabledMobileToast={false}
            placement={'top'}
            text={`Determines which calendar your job will appear`}
          >
            <IconContainer>
              <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.InfoCircle} />
            </IconContainer>
          </TextTooltip>
        </React.Fragment>
      );
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      const calendarName = jobType.kind === Job.KIND.ESTIMATE ? 'Survey' : 'Move';
      return <Text>{calendarName}</Text>;
    },
  },
  {
    flex: 3,
    headerContent: () => {
      return <TableHeaderText>Default Bill Templates</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      return getDisplayJobTypeBillTypes({jobType, projectType, navigator});
    },
  },
  {
    flex: 2,
    isHidden: !showProjectDatesColumn,
    headerContent: () => {
      return (
        <React.Fragment>
          <TableHeaderText>Default Project Date Type(s)</TableHeaderText>
          <Space width={6} />
          <TextTooltip
            isEnabledMobileToast={false}
            placement={'top'}
            text={`Sets whether this job type will automatically determine the pack, load, and/or delivery dates for long distance trips. This can be overridden in the job page.`}
            style={{maxWidth: '250px'}}
          >
            <IconContainer>
              <Icon color={colors.gray.tertiary} size={Icon.Sizes.Small} source={Icon.InfoCircle} />
            </IconContainer>
          </TextTooltip>
        </React.Fragment>
      );
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      return getDisplayProjectDateSettings({jobType});
    },
  },
  {
    flex: 2,
    headerContent: () => {
      return <TableHeaderText>Last Updated</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      return (
        <CellColumn>
          <Text>{Datetime.convertToDisplayDatetime(jobType.updatedAt)}</Text>
          {jobType.updatedBy ? <NameText>By {jobType.updatedBy.fullName}</NameText> : null}
        </CellColumn>
      );
    },
  },
  {
    flex: 1,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'jobType' implicitly has an 'any' ... Remove this comment to see the full error message
    cellContent: ({item: jobType}) => {
      return (
        <JobTypeActions
          jobType={jobType}
          refetch={refetch}
          projectTypeJobTypes={projectTypeJobTypes}
        />
      );
    },
  },
];

const ReorderButtonsColumn = ({jobType, index, refetch, isFirstItem, isLastItem}: any) => {
  const jobTypeForm = JobTypeForm.toForm({
    jobTypeId: jobType.id,
    projectTypeId: jobType.projectType.id,
    organizationId: jobType.organization.id,
    ...jobType,
  });

  const {form, handleSubmit} = useUpdateJobTypeIndexMutation({
    jobTypeForm,
    onSuccess: () => {
      refetch();
    },
    onError: () => {},
  });

  const handleMoveItemUp = () => {
    form.setFieldValue('jobTypeForm.index', index - 1);
    setTimeout(() => handleSubmit(), 0);
  };
  const handleMoveItemDown = () => {
    form.setFieldValue('jobTypeForm.index', index + 1);
    setTimeout(() => handleSubmit(), 0);
  };
  return (
    <ReorderButtonsColumnContainer>
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isFirstItem}
        onPress={handleMoveItemUp}
      >
        <Icon
          source={Icon.ArrowUp}
          size={12}
          color={isFirstItem ? colors.gray.border : colors.blue.interactive}
        />
      </ReorderButtonIconCircle>
      <Space height={2} />
      <ReorderButtonIconCircle
        activeOpacity={0.8}
        disabled={isLastItem}
        onPress={handleMoveItemDown}
      >
        <Icon
          source={Icon.ArrowDown}
          size={12}
          color={isLastItem ? colors.gray.border : colors.blue.interactive}
        />
      </ReorderButtonIconCircle>
    </ReorderButtonsColumnContainer>
  );
};

const ActionMenuItem = ({onPress, children}: any) => {
  return (
    <React.Fragment>
      <MenuItemTouchable onPress={onPress} activeOpacity={0.8}>
        <MenuItemText>{children}</MenuItemText>
      </MenuItemTouchable>
      <Space height={2} />
    </React.Fragment>
  );
};

const JobTypeActions = ({jobType, refetch, projectTypeJobTypes}: any) => {
  const applyJobTypeConfigDrawer = useDrawer({name: 'Apply Job Type Config Drawer'});
  const editJobTypeModal = useModal({name: 'Edit Job Type Modal'});
  const jobTypeActionsPopover = usePopover();
  const removeJobTypeModal = useModal({name: 'Remove Job Type Modal'});
  const isEnabledDelete =
    [Job.KIND.ESTIMATE, Job.KIND.MOVE].includes(jobType.kind) &&
    jobType.organization.isOwnerOfSettings;

  return (
    <React.Fragment>
      <EditJobTypeButton onPress={editJobTypeModal.handleOpen}>
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={16} />
      </EditJobTypeButton>
      <Space width={12} />
      {isEnabledDelete && (
        <Popover.Content innerRef={jobTypeActionsPopover.ref}>
          <MoreActionsButton onPress={jobTypeActionsPopover.handleOpen}>
            <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
          </MoreActionsButton>
        </Popover.Content>
      )}
      <Popover
        placement={Popover.Positions.BottomStart}
        isOpen={jobTypeActionsPopover.isOpen}
        handleOpen={jobTypeActionsPopover.handleOpen}
        handleClose={jobTypeActionsPopover.handleClose}
        reference={jobTypeActionsPopover.ref}
        offset={[0, 4]}
      >
        <ResponsivePopover.StaticContainer width={120}>
          <Space height={8} />
          <ActionMenuItem
            onPress={() => {
              applyJobTypeConfigDrawer.handleOpen();
              jobTypeActionsPopover.handleClose();
            }}
          >
            Copy Config
          </ActionMenuItem>
          <Space height={8} />
          <ActionMenuItem
            onPress={() => {
              removeJobTypeModal.handleOpen();
              jobTypeActionsPopover.handleClose();
            }}
          >
            Remove
          </ActionMenuItem>
          <Space height={8} />
        </ResponsivePopover.StaticContainer>
      </Popover>
      <EditJobTypeModal
        key={editJobTypeModal.key}
        isOpen={editJobTypeModal.isOpen}
        handleClose={editJobTypeModal.handleClose}
        jobTypeUuid={jobType.uuid}
        refetch={refetch}
      />
      <ApplyJobTypeConfigDrawer
        key={applyJobTypeConfigDrawer.key}
        isOpen={applyJobTypeConfigDrawer.isOpen}
        handleClose={applyJobTypeConfigDrawer.handleClose}
        jobType={jobType}
        refetch={refetch}
      />
      <RemoveJobTypeModal
        jobType={jobType}
        refetch={refetch}
        removeJobTypeModal={removeJobTypeModal}
      />
    </React.Fragment>
  );
};

const JobTypeTableSection = ({
  projectType,
  projectTypeJobTypes,
  projectTypeUuid,
  showProjectDatesColumn,
  refetch,
  isReordering,
  setIsReordering,
  organization,
}: any) => {
  const {navigator} = useNavigationDOM();
  return (
    <React.Fragment>
      <JobTypeTableContainer>
        {organization.isOwnerOfSettings && (
          <TableControlsRow>
            <Switch isOn={isReordering} onChange={setIsReordering} labelLeft='Reorder?' />
          </TableControlsRow>
        )}
        <Space height={8} />
        <Table
          columnDefinitions={getJobTypeColumnDefinitions({
            projectType,
            projectTypeUuid,
            showProjectDatesColumn,
            refetch,
            itemsCount: projectTypeJobTypes.length,
            isReordering,
            navigator,
          })}
          emptyStateText='No job types to display'
          items={projectTypeJobTypes}
          isDense
          itemKey={'id'}
        />
      </JobTypeTableContainer>
    </React.Fragment>
  );
};

const ProjectTypeJobTypesSettingsContent = ({isReordering, setIsReordering}: any) => {
  const {
    params: {projectTypeUuid},
  } = useNavigationDOM();
  const {data, refetch} = useQuery(ProjectTypeJobTypesSettingsPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      projectTypeUuid,
    },
  });
  const createJobTypeModal = useModal({name: 'CreateJobTypeModal'});

  if (!data) {
    return <PageLoadingIndicator />;
  }

  const projectType = data.projectTypeByUuid;
  const {isEnabledAutomaticProjectDates} = projectType.organization.features;
  const showProjectDatesColumn = isEnabledAutomaticProjectDates && projectType.isLongDistance;
  const panelWidth = showProjectDatesColumn ? 968 : 848;

  return (
    <PageContainer>
      <ProjectTypeSettingsPageHeader projectType={projectType} />
      <ScrollView style={{flex: 1, padding: 24, backgroundColor: colors.gray.background}}>
        <KindHeaderText>Jobs</KindHeaderText>
        <Space height={16} />
        <KindDescriptionText>{`Configure the job settings and job types for the ${projectType.name} project type.`}</KindDescriptionText>
        {projectType.features.timesheetsV2 && (
          <React.Fragment>
            <Space height={24} />
            <ProjectTypeJobSettingsPanel
              projectType={projectType}
              width={panelWidth}
              refetch={refetch}
            />
          </React.Fragment>
        )}
        <Space height={24} />
        <ActionPanel
          title={'Job Types'}
          width={panelWidth}
          BodyComponent={JobTypeTableSection}
          bodyComponentProps={{
            projectType,
            projectTypeJobTypes: projectType.jobTypes,
            projectTypeUuid,
            showProjectDatesColumn,
            refetch,
            isReordering,
            setIsReordering,
            organization: projectType.organization,
          }}
          ActionButtonComponent={projectType.organization.isOwnerOfSettings && TertiaryButton}
          actionButtonComponentProps={{
            iconLeft: Icon.Plus,
            text: 'Create Job Type',
            onPress: createJobTypeModal.handleOpen,
            isHitSlop: true,
          }}
        />
        <Space height={24} />
      </ScrollView>
      <CreateJobTypeModal
        isOpen={createJobTypeModal.isOpen}
        handleClose={createJobTypeModal.handleClose}
        projectType={projectType}
        refetch={refetch}
        key={createJobTypeModal.key}
      />
    </PageContainer>
  );
};

const MobileScroll = ({children}: any) => {
  const responsive = useResponsive();
  if (responsive.desktop) {
    return children;
  }
  return (
    <ScrollView horizontal style={{flex: 1}}>
      {children}
    </ScrollView>
  );
};

const ProjectTypeJobTypesSettingsPage = () => {
  const [isReordering, setIsReordering] = useState(false);

  return (
    <SidebarPageV2 selected={'settings'}>
      <MobileScroll>
        <ProjectTypeJobTypesSettingsContent
          isReordering={isReordering}
          setIsReordering={setIsReordering}
        />
      </MobileScroll>
    </SidebarPageV2>
  );
};

ProjectTypeJobTypesSettingsPage.query = gql`
  ${ProjectTypeSettingsPageHeader.fragment}
  ${ProjectTypeJobSettingsPanel.fragment}
  ${CreateJobTypeModal.fragment}
  ${RemoveJobTypeModal.fragment}
  ${ApplyJobTypeConfigDrawer.fragment}

  query ProjectTypeJobTypesSettingsPage(
    $projectTypeUuid: String!,
  ) {
    ${gql.query}
    projectTypeByUuid(projectTypeUuid: $projectTypeUuid) {
      id
      name
      isLongDistance
      features {
        timesheetsV2
      }
      billingLibrary {
        id
        uuid
      }
      jobTypes {
        id
        uuid
        name
        index
        kind
        updatedAt
        setsPackDates
        setsLoadDates
        setsDeliveryDates
        updatedBy {
          id
          fullName
        }
        billTypes {
          id
          uuid
          name
        }
        organization {
          id
          isOwnerOfSettings
        }
        ...ApplyJobTypeConfigDrawer
        ...RemoveJobTypeModal
      }
      organization {
        id
        isOwnerOfSettings
        features {
          isEnabledAutomaticProjectDates: isEnabled(feature: "AUTOMATIC_PROJECT_DATES")
        }
      }
      ...CreateJobTypeModal
      ...ProjectTypeSettingsPageHeader
      ...ProjectTypeJobSettingsPanel
    }
  }
`;

export default ProjectTypeJobTypesSettingsPage;
