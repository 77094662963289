// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {Location} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize, Location as LocationUtils} from '@supermove/utils';

// App
import ErrorState from '@shared/design/components/EmptyState/ErrorState';
import FieldValue from '@shared/design/components/Field/FieldValue';
import ErrorCatcher from '@shared/modules/Error/components/ErrorCatcher';
import ViewLocationButtons from '@shared/modules/Location/components/ViewLocationButtons';
import SkeletonLoader from 'modules/App/components/SkeletonLoader';
import LocationsListItemHeader from 'modules/Project/V2/Show/Blocks/components/LocationsListItemHeader';

const Column = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
`;

const LocationName = Styled.Text`
  ${Typography.Responsive.Label}
`;

const Information = Styled.Text`
  ${Typography.Responsive.Micro}
  color: ${colors.gray.secondary};
`;

const LocationNumber = Styled.Text`
  ${Typography.Responsive.MicroLabel}
  color: ${colors.gray.secondary};
`;

const LocationInformationContainer = Styled.View`
  padding-horizontal: 8px;
  padding-vertical: 3px;
  border-width: 1px;
  border-color: ${colors.gray.border};
  border-radius: 16px;
  margin-bottom: 8px;
`;

const VerticalLine = Styled.View`
  height: 100%;
  width: 1px;
  background-color: ${colors.gray.border};
`;

const getAdditionalInformationKeys = ({location}: any) => {
  return Location.AdditionalInformation.Values.filter(
    (key) => !!location[key] || location[key] === false,
  );
};

const SkeletonComponent = () => {
  return (
    <React.Fragment>
      <Row>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={100} />
        <Space style={{flex: 1}} />
        <Icon source={Icon.MapMarkedAlt} size={16} color={colors.gray.disabled} />
        <Space width={14} />
        <Icon source={Icon.EllipsisV} size={16} color={colors.gray.disabled} />
        <Space width={6} />
      </Row>
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={160} />
      <Space height={8} />
      <SkeletonLoader height={SkeletonLoader.HEIGHT.Text} width={160} />
      <Space height={8} />
      <Row>
        <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={64} />
        <Space width={12} />
        <VerticalLine />
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={64} />
        <Space width={12} />
        <VerticalLine />
        <Space width={12} />
        <SkeletonLoader height={SkeletonLoader.HEIGHT.MicroText} width={64} />
      </Row>
    </React.Fragment>
  );
};

const LocationAddress = ({location, mapRef, hasStreetView, showZillow = true, responsive}: any) => {
  return (
    <Row style={{alignItems: 'center'}}>
      <FieldValue
        newTab
        link={LocationUtils.createGoogleMapsUrl(location.address)}
        value={Location.getDisplayAddressCityStateZip(location)}
        style={{display: 'block'}}
        isResponsive
      />
      {responsive.desktop && (
        <React.Fragment>
          <Space width={8} />
          <ViewLocationButtons
            location={location}
            map={mapRef.current}
            hasStreetView={hasStreetView}
            showZillow={showZillow}
          />
        </React.Fragment>
      )}
    </Row>
  );
};

const LocationInformation = ({location, informationKey, responsive}: any) => {
  const information = location[informationKey];

  switch (informationKey) {
    case Location.AdditionalInformation.BUILDING_TYPE:
      return <Information responsive={responsive}>{information}</Information>;
    case Location.AdditionalInformation.UNIT:
      return <Information responsive={responsive}>{`Unit ${information}`}</Information>;
    case Location.AdditionalInformation.FLOOR_NUMBER:
      return <Information responsive={responsive}>{`Floor ${information}`}</Information>;
    case Location.AdditionalInformation.STAIR_COUNT:
      return (
        <Information responsive={responsive}>{`${pluralize(
          'flight',
          information,
          true,
        )} of stairs`}</Information>
      );
    case Location.AdditionalInformation.HAS_ELEVATOR:
      return (
        <Row style={{alignItems: 'center'}}>
          <Icon
            source={information ? Icon.Check : Icon.Xmark}
            size={responsive.desktop ? 12 : 14}
            color={colors.gray.secondary}
          />
          <Space width={8} />
          <Information responsive={responsive}>Elevator</Information>
        </Row>
      );
    case Location.AdditionalInformation.HAS_LONG_WALK:
      return (
        <Row style={{alignItems: 'center'}}>
          <Icon
            source={information ? Icon.Check : Icon.Xmark}
            size={responsive.desktop ? 12 : 14}
            color={colors.gray.secondary}
          />
          <Space width={8} />
          <Information responsive={responsive}>Long Walk</Information>
        </Row>
      );
    default:
      return null;
  }
};

const AdditionalInformation = ({location}: any) => {
  const responsive = useResponsive();
  const additionalInformationKeys = getAdditionalInformationKeys({location});
  return (
    <Row style={{flexWrap: 'wrap'}}>
      {additionalInformationKeys.map((informationKey, index) => {
        return (
          <React.Fragment key={informationKey}>
            <Row>
              <LocationInformationContainer responsive={responsive}>
                <LocationInformation
                  location={location}
                  informationKey={informationKey}
                  responsive={responsive}
                />
              </LocationInformationContainer>
              <Space width={8} />
            </Row>
          </React.Fragment>
        );
      })}
    </Row>
  );
};

const WarehouseLocationListItem = ({location, mapRef, hasStreetView}: any) => {
  const responsive = useResponsive();

  return (
    <ErrorCatcher ErrorComponent={() => <ErrorState title={'Location error.'} />}>
      <LocationNumber responsive={responsive}>{`Warehouse`}</LocationNumber>
      <Space height={12} />
      <Column>
        <React.Fragment>
          <LocationName responsive={responsive}>{location.name}</LocationName>
          <Space height={responsive.desktop ? 8 : 4} />
        </React.Fragment>
        <LocationAddress
          location={location}
          mapRef={mapRef}
          hasStreetView={hasStreetView}
          showZillow={false}
          responsive={responsive}
        />
      </Column>
    </ErrorCatcher>
  );
};

const LocationsListItem = ({
  location,
  totalLocations,
  index,
  mapRef,
  hasStreetView,
  LocationActionsComponent,
  locationActionsComponentProps,
  isOrigin,
  isDestination,
  isStops,
}: any) => {
  const responsive = useResponsive();
  return (
    <ErrorCatcher ErrorComponent={() => <ErrorState title={'Location error.'} />}>
      <Column>
        <LocationsListItemHeader
          location={location}
          totalLocations={totalLocations}
          index={index}
          isOrigin={isOrigin}
          isDestination={isDestination}
          isStops={isStops}
        >
          <LocationActionsComponent {...locationActionsComponentProps} />
        </LocationsListItemHeader>
        <Space height={responsive.desktop ? 8 : 12} />
        {!!location.name && (
          <React.Fragment>
            <LocationName responsive={responsive}>{location.name}</LocationName>
            <Space height={responsive.desktop ? 8 : 4} />
          </React.Fragment>
        )}
        <LocationAddress
          location={location}
          mapRef={mapRef}
          hasStreetView={hasStreetView}
          responsive={responsive}
        />
        <Space height={responsive.desktop ? 8 : 12} />
        <AdditionalInformation location={location} />
        {!!location.notes && (
          <React.Fragment>
            <Space height={4} />
            <Information responsive={responsive}>{location.notes}</Information>
          </React.Fragment>
        )}
      </Column>
    </ErrorCatcher>
  );
};

LocationsListItem.SkeletonComponent = SkeletonComponent;
LocationsListItem.WarehouseLocationListItem = WarehouseLocationListItem;

// --------------------------------------------------
// Data
// --------------------------------------------------
LocationsListItem.fragment = gql`
  ${LocationsListItemHeader.fragment}
  ${Location.getDisplayAddressCityStateZip.fragment}
  ${Location.AdditionalInformation.fragment}
  ${ViewLocationButtons.fragment}

  fragment LocationsListItem on Location {
    id
    address
    name
    notes
    ...LocationsListItemHeader
    ...Location_getDisplayAddressCityStateZip
    ...Location_AdditionalInformation
    ...ViewLocationButtons
  }
`;

export default LocationsListItem;
