/* eslint-disable react-hooks/exhaustive-deps */
// TODO(jholston): This should be used for recording any non-transacting payments.
// We should consolidate this with RecordInvoicePaymentDrawer.

// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useEffect, useQuery, useResponsive, useState} from '@supermove/hooks';
import {BillModel, PaymentMethod, PaymentMethodModel, Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import Drawer from '@shared/design/components/Drawer';
import FieldInput from '@shared/design/components/Field/FieldInput';
import BeginPaymentForm, {
  BeginPaymentFormToFormType,
} from '@shared/modules/Payment/forms/BeginPaymentForm';
import useBeginPaymentMutation from '@shared/modules/Payment/hooks/useBeginPaymentMutation';
import useFinalizePaymentMutation from '@shared/modules/Payment/hooks/useFinalizePaymentMutation';
import PaymentMethodKind from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import PaymentTotals from 'modules/Payment/components/PaymentTotals';
import BillingProjectPaymentMethodsDropdown from 'modules/Project/Billing/components/BillingProjectPaymentMethodsDropdown';

const BalanceText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;

const getPaymentAmount = (bill: BillModel) => {
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  if (min > 0) {
    return min;
  }
  if (max > 0) {
    return max;
  }
  return 0;
};

const RemainingBalance = ({bill}: {bill: BillModel}) => {
  const responsive = useResponsive();
  const min = bill.project.activeJobsAggregateBill.minBalance;
  const max = bill.project.activeJobsAggregateBill.maxBalance;
  const color = min > 0 || max > 0 ? colors.red.warning : colors.green.status;
  return (
    <BalanceText responsive={responsive} style={{color}}>
      {`Remaining Balance: ${Currency.formatRange({min, max, shouldHideCentsIfZero: false})}`}
    </BalanceText>
  );
};

const PaymentFields = ({
  form,
  field,
  bill,
  paymentMethod,
}: {
  form: Form<{beginPaymentForm: BeginPaymentFormToFormType}>;
  field: string;
  bill: BillModel;
  paymentMethod?: PaymentMethodModel;
}) => {
  return (
    <React.Fragment>
      <FieldInput
        {...form}
        name={`${field}.name`}
        label={'Payment Name'}
        isResponsive
        isRequired
        input={{
          placeholder: 'Enter a payment name',
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.subtotal`}
        label={'Payment Amount'}
        component={CurrencyInput}
        isResponsive
        isRequired
        handleBlur={(e) => {
          const amount = Currency.convertToCents(e.currentTarget.value);
          form.setFieldValue(`${field}.subtotal`, Currency.toForm(amount));
          const paymentFeeAmount = paymentMethod
            ? PaymentMethod.computePaymentFeeAmount(paymentMethod, {amount})
            : 0;
          form.setFieldValue(`${field}.paymentFeeAmount`, Currency.toForm(paymentFeeAmount));
          form.setFieldValue(`${field}.amount`, Currency.toForm(paymentFeeAmount + amount));
        }}
        input={{
          component: FieldInput.TextInput,
          placeholder: 'Enter a payment amount',
          setFieldValue: form.setFieldValue,
          setFieldTouched: form.setFieldTouched,
        }}
      />
      <Space height={4} />
      <RemainingBalance bill={bill} />
      <Space height={16} />
      <BillingProjectPaymentMethodsDropdown form={form} bill={bill} />
      <Space height={16} />
      <FieldInput
        {...form}
        name={`${field}.description`}
        label={'Payment Description'}
        isResponsive
        isRequired={
          form.values.beginPaymentForm.method === PaymentMethodKind.CHECK &&
          !form.values.beginPaymentForm.description
        }
        input={{
          placeholder: 'Enter a payment description',
          style: {height: 80, paddingTop: 10},
          multiline: true,
        }}
      />
    </React.Fragment>
  );
};

const RecordPaymentDrawerContent = ({
  handleClose,
  refetch,
  bill,
}: {
  handleClose: () => void;
  refetch: () => void;
  bill: BillModel;
}) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodModel | undefined>(undefined);
  const {project} = bill;
  const responsive = useResponsive();
  const beginPaymentForm = BeginPaymentForm.new({
    billId: bill.id,
    customerId: bill.customerId,
    amount: getPaymentAmount(bill),
    name: `Payment for ${Project.getName(project)}`,
  });

  const beginPaymentMutation = useBeginPaymentMutation({
    beginPaymentForm,
    onSuccess: ({payment}: {payment: unknown}) => {
      return payment;
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });

  const finalizePaymentMutation = useFinalizePaymentMutation({
    onSuccess: () => {
      refetch();
      handleClose();
    },
    onError: (errors: unknown) => {
      console.log({errors});
    },
  });

  const submitting = finalizePaymentMutation.submitting || beginPaymentMutation.submitting;
  const handleSubmit = async () => {
    const {data} = await beginPaymentMutation.handleSubmit();
    if (!data?.response.payment) {
      return;
    }
    await finalizePaymentMutation.form.setFieldValue('paymentId', data.response.payment.id);
    finalizePaymentMutation.handleSubmit();
  };

  useEffect(() => {
    const newPaymentMethod = _.find(
      bill.project.organization.paymentMethodsForOffice,
      (paymentMethod) =>
        _.toString(paymentMethod.id) ===
        _.toString(beginPaymentMutation.form.values.beginPaymentForm.paymentMethodId),
    );
    setPaymentMethod(newPaymentMethod);
    if (newPaymentMethod) {
      const subtotal = Currency.convertToCents(
        beginPaymentMutation.form.values.beginPaymentForm.subtotal,
      );
      const paymentFeeAmount = PaymentMethod.computePaymentFeeAmount(newPaymentMethod, {
        amount: subtotal,
      });
      beginPaymentMutation.form.setFieldValue(
        'beginPaymentForm.paymentFeeAmount',
        Currency.toForm(paymentFeeAmount),
      );
      beginPaymentMutation.form.setFieldValue(
        'beginPaymentForm.amount',
        Currency.toForm(paymentFeeAmount + subtotal),
      );
    }
  }, [beginPaymentMutation.form.values.beginPaymentForm.paymentMethodId]);

  return (
    <React.Fragment>
      <Drawer.Body>
        <PaymentFields
          form={beginPaymentMutation.form}
          field={'beginPaymentForm'}
          bill={bill}
          paymentMethod={paymentMethod}
        />
      </Drawer.Body>
      <PaymentTotals
        paymentFeeAmount={beginPaymentMutation.form.values.beginPaymentForm.paymentFeeAmount}
        total={beginPaymentMutation.form.values.beginPaymentForm.amount}
        subtotal={beginPaymentMutation.form.values.beginPaymentForm.subtotal}
        paymentMethod={paymentMethod}
      />
      {responsive.desktop ? (
        <Drawer.Footer
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          secondaryAction={handleClose}
          primaryActionText={'Submit'}
        />
      ) : (
        <Drawer.FooterMobile
          isSubmitting={submitting}
          primaryAction={handleSubmit}
          primaryActionText={'Submit'}
        />
      )}
    </React.Fragment>
  );
};

const RecordPaymentDrawer = ({
  handleClose,
  isOpen,
  refetch,
  billUuid,
}: {
  handleClose: () => void;
  isOpen: boolean;
  refetch: () => void;
  billUuid: string;
}) => {
  const responsive = useResponsive();
  const {loading, data} = useQuery(RecordPaymentDrawer.query, {
    fetchPolicy: 'cache-and-network',
    skip: !isOpen,
    variables: {
      uuid: billUuid,
    },
  });

  return (
    <Drawer isOpen={isOpen} width={Drawer.WIDTH.DEFAULT}>
      <Drawer.Header
        isResponsive
        headerText={`Record Payment`}
        description={
          'Add a payment name and enter an amount. This payment should have happened already.'
        }
        handleClose={handleClose}
        isClosable={!responsive.desktop}
      />
      <Loading loading={loading || !data}>
        {() => (
          <RecordPaymentDrawerContent
            handleClose={handleClose}
            refetch={refetch}
            bill={data.bill}
          />
        )}
      </Loading>
    </Drawer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
RecordPaymentDrawer.query = gql`
  ${BillingProjectPaymentMethodsDropdown.fragment}
  ${PaymentMethod.computePaymentFeeAmount.fragment}
  ${PaymentTotals.fragment}
  ${Project.getName.fragment}

  query RecordPaymentDrawer ($uuid: String!) {
    ${gql.query}
    bill(uuid: $uuid) {
      id
      customerId
      project {
        id
        activeJobsAggregateBill {
          minBalance
          maxBalance
        }
        organization {
          id
          paymentMethodsForOffice {
            id
            ...PaymentMethod_computePaymentFeeAmount
            ...PaymentTotals
          }
        }
        ...Project_getName
      }
      ...BillingProjectPaymentMethodsDropdown
    }
  }
`;

export default RecordPaymentDrawer;
