// Libraries
import React from 'react';

// Supermove
import {ScrollView, Space, Styled} from '@supermove/components';
import {useNavigationDOM, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// Components

import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';
import useUserInviteSignUpMutation from '@shared/modules/User/hooks/useUserInviteSignUpMutation';
import InviteOrganizationUserSignUpFields from 'modules/Authentication/Signup/components/InviteOrganizationUserSignUpFields';

const Content = Styled.View`
  width: 100%;
  max-width: 400px;
  padding-top: 40px;
  padding-left: 8px;
  padding-right: 8px;
`;

const Title = Styled.Text`
  ${Typography.Heading6}
`;

const Subtitle = Styled.Text`
  ${Typography.Body2}
`;

const Actions = Styled.View`
  flex-direction: row;
`;

const SubmitButton = Styled.ButtonV2`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 36px;
  padding-horizontal: 16px;
  padding-vertical: 8px;
  border-radius: 4px;
  width: 100%;
  background-color: ${colors.blue.interactive};
`;

const SubmitText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.white};
`;

const Logo = Styled.Text`
  ${Typography.Heading3}
  background-color: ${colors.black};
  color: ${colors.white};
  font-weight: 900;
  text-align: center;
  padding-vertical: 12px;
  padding-horizontal: 24px;
  width: 100%;
  height: 62px;
`;

const InviteOrganizationUserSignUpPageContent = ({token, decodedToken}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();

  // Pass in token and email
  const inviteUserForm = InviteUserForm.new({
    inviteToken: token,
    email: decodedToken.email,
  });

  const {form, submitting, handleSubmit} = useUserInviteSignUpMutation({
    inviteUserForm,
    onSuccess: () => {
      navigator.replace('/');
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <ScrollView contentContainerStyle={{alignItems: 'center'}} style={{flex: 1}}>
      <Content {...responsive}>
        <Logo>Supermove</Logo>
        <Space height={24} />
        <Title>Create an account</Title>
        <Subtitle>
          You have been invited to join an organization on Supermove. Please create a password below
          to gain access. This link expires after 7 days and can only be used once.
        </Subtitle>
        <Space height={24} />
        <InviteOrganizationUserSignUpFields form={form} />
        <Space height={24} />
        <Actions>
          <SubmitButton loading={submitting} onPress={handleSubmit}>
            <SubmitText>Finish Sign-Up</SubmitText>
          </SubmitButton>
        </Actions>
      </Content>
    </ScrollView>
  );
};

export default InviteOrganizationUserSignUpPageContent;
