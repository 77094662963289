// Libraries
import React from 'react';

// Components
import {Lifecycle, Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof ViewScheduleMutation.defaultProps;

// @ts-expect-error TS(7022): 'ViewScheduleMutation' implicitly has type 'any' b... Remove this comment to see the full error message
const ViewScheduleMutation = ({date, userId, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    variables={{
      date,
      userId,
    }}
    mutation={ViewScheduleMutation.mutation}
    onCompleted={({response: {errors}}: any) => {
      if (!errors) {
        onSuccess();
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => (
      <Lifecycle onMount={() => handleSubmit()}>{children}</Lifecycle>
    )}
  </Mutation>
);

ViewScheduleMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ViewScheduleMutation.mutation = gql`
  mutation ViewScheduleMutation(
    $date: String!,
    $userId: Int!,
  ) {
    response: viewSchedule(
      date: $date,
      userId: $userId,
    ) {
      ${gql.errors}
    }
  }
`;

export default ViewScheduleMutation;
