// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import InvoiceBillBillItemsList from 'modules/Storage/components/InvoiceBillBillItemsList';

const Container = Styled.View`
  border-color: ${colors.gray.border};
  border-radius: 4px;
  border-width: 1px;
  padding: 16px;
`;

const ZIndexContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 500 - index};
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const BodyText = Styled.Text`
  ${Typography.Body3}
`;

const LabelBlackText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.primary}
`;

const LabelGrayText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.tertiary}
`;

const TitleContainer = Styled.View`
  width: 100%;
`;

const SubtotalContainer = Styled.View`
  align-items: flex-end;
`;

const TotalsRow = Styled.View`
  flex-direction: row;
  align-items: flex-end;
`;

const DottedLineTop = Styled.View`
  border-color: ${colors.gray.border};
  border-style: dotted;
  border-top-width: 1px; 
`;

const TotalWithIconRow = Styled.View`
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const IconButton = Styled.ButtonV2`
  width: 24px;
`;

const TotalContainer = Styled.View`
  min-width: 70px;
  align-items: flex-end;
`;

const LoadingButton = Styled.Loading`
`;

const InvoiceBillingFieldsItems = ({
  form,
  bill,
  billFormField,
  viewer,
  index,
  submitting,
  setCurrentBillIndex,
  currentBillIndex,
}: any) => {
  const [isDescription, setIsDescription] = useState(
    !_.isEmpty(_.get(form.values, `${billFormField}.description`)),
  );
  return (
    <Container>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ZIndexContainer index={index}>
        <TitleContainer>
          <Row>
            <BodyText>Bill Title</BodyText>
            <Space style={{flex: 1}} />
            <TertiaryButton
              onPress={() => {
                setIsDescription(!isDescription);
              }}
              iconLeft={isDescription ? Icon.Trash : Icon.Plus}
              text={`${isDescription ? 'Remove' : 'Add'} Description`}
            />
          </Row>
          <Space height={4} />
          <FieldInput
            {...form}
            name={`${billFormField}.title`}
            input={{
              required: !_.get(form.values, `${billFormField}.title`),
              placeholder: 'Enter bill title',
            }}
          />
        </TitleContainer>
      </ZIndexContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ZIndexContainer index={1 + index}>
        {isDescription && (
          <FieldInput
            {...form}
            name={`${billFormField}.description`}
            label={'Bill Description'}
            input={{
              placeholder: 'Enter bill description',
              required: !_.get(form.values, `${billFormField}.description`),
            }}
          />
        )}
      </ZIndexContainer>
      <Space height={12} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ZIndexContainer index={2 + index}>
        <InvoiceBillBillItemsList
          form={form}
          bill={bill}
          billFormField={billFormField}
          userRole={viewer.role}
          index={index}
          setCurrentBillIndex={setCurrentBillIndex}
        />
      </ZIndexContainer>
      <Space height={16} />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ZIndexContainer index={3 + index}>
        <SubtotalContainer>
          <TotalsRow>
            <LabelGrayText>Subtotal</LabelGrayText>
            <Space width={16} />
            <TotalContainer>
              {currentBillIndex === index && submitting ? (
                // @ts-expect-error TS(2769): No overload matches this call.
                <LoadingButton loading={submitting} />
              ) : (
                <BodyText>
                  {Currency.display(
                    _.get(form.values, `invoiceForm.billTotalsPreview[${index}].subtotal`) || 0,
                  )}
                </BodyText>
              )}
            </TotalContainer>
          </TotalsRow>
        </SubtotalContainer>
        <Space height={4} />
        <DottedLineTop />
        <Space height={4} />
        <SubtotalContainer>
          <TotalWithIconRow>
            <IconButton
              onPress={() => {
                if (_.get(form.values, 'invoiceForm.billForms').length > 1) {
                  // prevent index from going to negative when we only have 1 item in list
                  setCurrentBillIndex(index === 0 ? 0 : index - 1);

                  form.setFieldValue(
                    'invoiceForm.billForms',
                    _.filter(_.get(form.values, 'invoiceForm.billForms'), (value, billIndex) => {
                      return billIndex !== index;
                    }),
                  );
                }
              }}
            >
              <Icon source={Icon.Trash} color={colors.gray.secondary} size={16} />
            </IconButton>
            <Space style={{flex: 1}} />
            <LabelBlackText>Total</LabelBlackText>
            <Space width={16} />
            <TotalContainer>
              {currentBillIndex === index && submitting ? (
                // @ts-expect-error TS(2769): No overload matches this call.
                <LoadingButton loading={submitting} />
              ) : (
                <LabelBlackText>
                  {Currency.display(
                    _.get(form.values, `invoiceForm.billTotalsPreview[${index}].total`) || 0,
                  )}
                </LabelBlackText>
              )}
            </TotalContainer>
          </TotalWithIconRow>
        </SubtotalContainer>
      </ZIndexContainer>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InvoiceBillingFieldsItems.fragment = gql`
  ${InvoiceBillBillItemsList.fragment}

  fragment InvoiceBillingFieldsItems on Project {
    id
    currentPrimaryBill {
      id
      isPrimary
      ...InvoiceBillBillItemsList
    }
  }
`;

export default InvoiceBillingFieldsItems;
