// Libraries
import React from 'react';

// App
import {Styled} from '@supermove/components';
import {fontWeight, colors} from '@supermove/styles';

// Components
const Container = Styled.View`
  align-items: center;
  justify-content: center;
  height: ${(props) => (props as any).size}px;
  width: ${(props) => (props as any).size}px;
  border-width: ${(props) => (props as any).borderSize}px;
  border-color: ${(props) => (props as any).borderColor};
  border-radius: ${(props) => (props as any).size / 2}px;
  background-color: ${(props) => (props as any).color};
`;

const Text = Styled.H5`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

type OwnProps = {
  borderColor?: string;
  borderSize?: number;
  color?: string;
  size: number;
  text?: string;
  textColor?: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof Avatar.defaultProps;

// @ts-expect-error TS(7022): 'Avatar' implicitly has type 'any' because it does... Remove this comment to see the full error message
const Avatar = ({borderColor, borderSize, color, size, text, textColor}: Props) => (
  // @ts-expect-error TS(2769): No overload matches this call.
  <Container borderColor={borderColor} borderSize={borderSize} color={color} size={size}>
    <Text color={textColor}>{text}</Text>
  </Container>
);

Avatar.defaultProps = {
  borderColor: colors.blue.interactive,
  borderSize: 0,
  color: colors.blue.interactive,
  text: '',
  textColor: colors.white,
};

export default Avatar;
