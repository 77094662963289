// Libraries
import React from 'react';

// App
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import AddJobTruckItem from './AddJobTruckItem';
import JobTruckItem from './JobTruckItem';

const Container = Styled.View`
  z-index: 1;
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyMessage = Styled.H7`
  font-style: italic;
  color: ${colors.gray.secondary};
`;

type OwnProps = {
  isAddable?: boolean;
  isUnassignable?: boolean;
  job: any;
  refetch?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobTrucksList.defaultProps;

// @ts-expect-error TS(7022): 'JobTrucksList' implicitly has type 'any' because ... Remove this comment to see the full error message
const JobTrucksList = ({
  isAddable, // Whether trucks can be added.
  isUnassignable, // Whether trucks can be unassigned.
  job,
  refetch,
}: Props) => {
  const responsive = useResponsive();

  return (
    <Container>
      <FlatList
        data={job.jobTrucks}
        keyExtractor={(jobTruck: any) => String(jobTruck.id)}
        // @ts-expect-error TS(7031): Binding element 'jobTruck' implicitly has an 'any'... Remove this comment to see the full error message
        renderItem={({item: jobTruck}) => (
          <JobTruckItem isUnassignable={isUnassignable} jobTruck={jobTruck} refetch={refetch} />
        )}
        ListEmptyComponent={() =>
          !isAddable &&
          job.jobTrucks.length <= 0 && (
            <EmptyContainer>
              <EmptyMessage>No trucks</EmptyMessage>
            </EmptyContainer>
          )
        }
      />
      {isAddable && <AddJobTruckItem job={job} responsive={responsive} refetch={refetch} />}
    </Container>
  );
};

JobTrucksList.defaultProps = {
  isAddable: false,
  isUnassignable: false,
  refetch: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobTrucksList.fragment = gql`
  ${AddJobTruckItem.fragment}
  ${JobTruckItem.fragment}

  fragment JobTrucksList on Job {
    id
    crewSize
    hourlyRate
    travelFee
    crewStatus: employeeApprovalStatus {
      error
      status
    }
    jobTrucks {
      id
      ...JobTruckItem
    }
    ...AddJobTruckItem
  }
`;

export default JobTrucksList;
