// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {useCallback, useState} from '@supermove/hooks';

export const DayCalendarDispatchOrganizationsContext = React.createContext(null);

const DayCalendarDispatchOrganizationsContextProvider = ({
  children,
  organizations: contextOrganizations,
}: any) => {
  const [organizations, setOrganizations] = useState(contextOrganizations);
  const isAllOpen = !_.find(organizations, ['isOpen', false]);

  const toggleOrganizationIsOpen = useCallback(
    (id) => {
      setOrganizations(
        organizations.map((org: any) => ({
          ...org,
          isOpen: org.organizationId === id ? !org.isOpen : org.isOpen,
        })),
      );
    },
    [organizations],
  );

  const toggleAllOrganizations = useCallback(() => {
    if (isAllOpen) {
      setOrganizations(
        organizations.map((org: any) => ({
          ...org,
          isOpen: false,
        })),
      );
    } else {
      setOrganizations(
        organizations.map((org: any) => ({
          ...org,
          isOpen: true,
        })),
      );
    }
  }, [isAllOpen, organizations]);

  const store = {
    organizations,
    setOrganizations,
    toggleOrganizationIsOpen,
    toggleAllOrganizations,
    isAllOpen,
  };

  return (
    // @ts-expect-error TS(2322): Type '{ organizations: any; setOrganizations: Reac... Remove this comment to see the full error message
    <DayCalendarDispatchOrganizationsContext.Provider value={store}>
      {children}
    </DayCalendarDispatchOrganizationsContext.Provider>
  );
};

export default DayCalendarDispatchOrganizationsContextProvider;
