// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Space, MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Organization} from '@supermove/models';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextMessageTemplateCategory from '@shared/modules/Sms/enums/TextMessageTemplateCategory';
import TextMessageWorkflowRecipientKind from '@shared/modules/Sms/enums/TextMessageWorkflowRecipientKind';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const getFilteredRecipientKindOptions = (textMessageTemplateCategory: any) => {
  const filterValues: any = [];

  switch (textMessageTemplateCategory) {
    case TextMessageTemplateCategory.CUSTOMER:
      filterValues.push(
        TextMessageWorkflowRecipientKind.PRIMARY_CLIENT,
        TextMessageWorkflowRecipientKind.BILLING_CLIENT,
      );
      break;
    case TextMessageTemplateCategory.MOVER:
      filterValues.push(TextMessageWorkflowRecipientKind.ASSIGNED_MOVERS);
      break;
    case TextMessageTemplateCategory.OFFICE:
      filterValues.push(
        TextMessageWorkflowRecipientKind.COORDINATOR,
        TextMessageWorkflowRecipientKind.SALESPERSON,
        TextMessageWorkflowRecipientKind.SPECIFIC_USERS,
        TextMessageWorkflowRecipientKind.TEAMS,
      );
      break;
    default:
      return [];
  }

  return _.filter(TextMessageWorkflowRecipientKind.DROPDOWN_OPTIONS, (option) =>
    _.includes(filterValues, option.value),
  );
};

const SendSmsFromTemplateSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  handleSubmitValidateWorkflow,
}: any) => {
  const {label, description} =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.SEND_SMS_V2];
  const field = `${workflowStepsField}.${workflowStepIndex}.sendSmsV2FromTemplateActionForm`;
  const isDoneDisabled =
    !_.get(form.values, `${field}.textMessageTemplateId`) ||
    !_.get(form.values, `${field}.recipientKind`) ||
    !_.get(form.values, `${field}.textMessageTemplateCategory`) ||
    (_.get(form.values, `${field}.recipientKind`) ===
      TextMessageWorkflowRecipientKind.SPECIFIC_USERS &&
      _.isEmpty(_.get(form.values, `${field}.recipientUserIds`, []))) ||
    (_.get(form.values, `${field}.recipientKind`) === TextMessageWorkflowRecipientKind.TEAMS &&
      _.isEmpty(_.get(form.values, `${field}.recipientTeamIds`, [])));
  const {organization} = workflow;

  // Form Values
  const textMessageTemplateCategory = _.get(form.values, `${field}.textMessageTemplateCategory`);
  const recipientKind = _.get(form.values, `${field}.recipientKind`);

  const filteredTemplatesBySelectedCategory =
    workflow.organization.textMessageV2TemplatesByCategory.filter((textMessageTemplate: any) => {
      return textMessageTemplate.category === textMessageTemplateCategory;
    });

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <FieldInput.Memoized
        {...form}
        component={DropdownInput}
        name={`${field}.textMessageTemplateCategory`}
        label={'Select Template Category'}
        isRequired
        input={{
          options: TextMessageTemplateCategory.DROPDOWN_OPTIONS,
          placeholder: 'Select Template Category',
          isSearchable: false,
          isPortaled: true,
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(`${field}.textMessageTemplateCategory`, value);
            form.setFieldValue(`${field}.textMessageTemplateId`, null);
          },
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.textMessageTemplateId`}
        label={'Select Template'}
        isRequired
        input={{
          options: filteredTemplatesBySelectedCategory.map((textMessageTemplate: any) => ({
            value: textMessageTemplate.id,
            label: textMessageTemplate.name,
          })),
          placeholder: 'Select SMS template',
          isSearchable: true,
          isPortaled: true,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.recipientKind`}
        label={'Select Recipient Kind'}
        isRequired
        input={{
          options: getFilteredRecipientKindOptions(textMessageTemplateCategory),
          placeholder: 'Select Recipient Kind',
          isSearchable: false,
          isPortaled: true,
          setFieldValue: (name: any, value: any) => {
            form.setFieldValue(`${field}.recipientKind`, value);
            form.setFieldValue(`${field}.recipientUserIds`, []);
            form.setFieldValue(`${field}.recipientTeamIds`, []);
          },
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      {recipientKind === TextMessageWorkflowRecipientKind.SPECIFIC_USERS && (
        <React.Fragment>
          <FieldInput.LabelText isRequired>Select Users</FieldInput.LabelText>
          {/* @ts-expect-error TS(2740): Type '{ options: any; placeholder: string; style: ... Remove this comment to see the full error message */}
          <MultiDropdownInput
            options={Organization.getOfficeStaffDropdownOptions(organization)}
            placeholder={'Select recipients'}
            style={{width: '100%'}}
            value={_.get(form.values, `${field}.recipientUserIds`, [])}
            onChangeValue={(recipientUserIds) => {
              form.setFieldValue(`${field}.recipientUserIds`, recipientUserIds);
            }}
            isSearchable
            isPortaled
          />
        </React.Fragment>
      )}
      {recipientKind === TextMessageWorkflowRecipientKind.TEAMS && (
        <React.Fragment>
          <FieldInput.LabelText isRequired>Select Teams</FieldInput.LabelText>
          {/* @ts-expect-error TS(2740): Type '{ options: any; placeholder: string; style: ... Remove this comment to see the full error message */}
          <MultiDropdownInput
            options={organization.allTeams.map((team: any) => ({
              value: team.id,
              label: team.name,
            }))}
            placeholder={'Select teams'}
            style={{width: '100%'}}
            value={_.get(form.values, `${field}.recipientTeamIds`, [])}
            onChangeValue={(recipientTeamIds) => {
              form.setFieldValue(`${field}.recipientTeamIds`, recipientTeamIds);
            }}
            isSearchable
            isPortaled
          />
        </React.Fragment>
      )}
    </WorkflowStepSlide>
  );
};

const SendSmsFromTemplateDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  workflow,
  isPreview,
}: any) => {
  const field = `${workflowStepsField}.${workflowStepIndex}`;
  const textMessageTemplateId = _.get(
    form.values,
    `${field}.sendSmsV2FromTemplateActionForm.textMessageTemplateId`,
  );
  const smsTemplate = _.find(workflow.organization.textMessageV2TemplatesByCategory, [
    'id',
    textMessageTemplateId,
  ]);
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={smsTemplate ? `Send SMS from template: ${smsTemplate.name} ` : ''}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepSendSmsV2FromTemplate = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => [
    <SendSmsFromTemplateSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <SendSmsFromTemplateDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
  fragment: gql`
    ${Organization.getOfficeStaffDropdownOptions.fragment}
    fragment WorkflowStepSendSmsV2FromTemplate on Workflow {
      id
      organization {
        id
        allTeams {
          id
          name
        }
        textMessageV2TemplatesByCategory: textMessageTemplatesByCategory(
          categories: ["CUSTOMER", "MOVER", "OFFICE"]
        ) {
          id
          name
          category
        }
        ...Organization_getOfficeStaffDropdownOptions
      }
    }
  `,
};

export default WorkflowStepSendSmsV2FromTemplate;
