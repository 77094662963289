// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import SearchBar from '@shared/design/components/SearchBar';
import TextTooltip from '@shared/design/components/TextTooltip';
import Line from 'modules/App/components/Line';
import EmailThreadsList from 'modules/Communication/components/EmailThreadsList';

const EmailActionsContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TooltipContainer = Styled.View`
`;

const SearchResultsText = Styled.Text`
  ${Typography.Responsive.Body};
`;

const EmailActions = ({
  resultsCount,
  handleChangeQuery,
  searchInput,
  searchQuery,
  selectedThread,
  isLoading,
  handleComposeEmail,
  isCustomerEmailProvided,
}: any) => {
  const responsive = useResponsive();
  const showActions = !selectedThread || !responsive.desktop;
  return (
    <React.Fragment>
      {showActions && (
        <EmailActionsContainer>
          <Row>
            <SearchBar
              inputRef={searchInput.ref}
              onChangeText={(text) => handleChangeQuery(text)}
              placeholder={'Search'}
              containerStyle={{flex: 1}}
              style={{width: '100%'}}
              defaultValue={''}
              isLoading={isLoading}
              isClearable
              isResponsive
            />
            {handleComposeEmail && (
              <Row>
                <Space width={responsive.desktop ? 12 : 16} />
                {isCustomerEmailProvided ? (
                  <Button
                    // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                    text={null}
                    iconLeft={Icon.Plus}
                    iconSize={14}
                    onPress={handleComposeEmail}
                    isResponsive
                  />
                ) : (
                  <TextTooltip
                    isEnabledMobileToast={false}
                    text={'Missing Email'}
                    placement={'left'}
                  >
                    <TooltipContainer>
                      <Button
                        isDisabled
                        // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                        text={null}
                        iconLeft={Icon.Plus}
                        iconSize={14}
                        isResponsive
                      />
                    </TooltipContainer>
                  </TextTooltip>
                )}
              </Row>
            )}
          </Row>
          <Space height={4} />
          {!!searchQuery && (
            <React.Fragment>
              <Space height={4} />
              <SearchResultsText responsive={responsive}>
                {pluralize('result', resultsCount, true)} found
              </SearchResultsText>
              <Space height={4} />
            </React.Fragment>
          )}
        </EmailActionsContainer>
      )}
    </React.Fragment>
  );
};

type OwnEmailThreadsProps = {
  emailThreads: any[];
  handleMissingContactInfo: (...args: any[]) => any;
  selectedThread?: any;
  setSelectedThread?: (...args: any[]) => any;
  searchInput?: any;
  searchQuery?: string;
  handleChangeQuery?: (...args: any[]) => any;
  isLoading?: boolean;
  customer: any;
  refetch?: (...args: any[]) => any;
  handleComposeEmail?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'EmailThreadsProps' circularly referenc... Remove this comment to see the full error message
type EmailThreadsProps = OwnEmailThreadsProps & typeof EmailThreads.defaultProps;

// @ts-expect-error TS(7022): 'EmailThreads' implicitly has type 'any' because i... Remove this comment to see the full error message
const EmailThreads = ({
  emailThreads,
  handleMissingContactInfo,
  selectedThread,
  setSelectedThread,
  searchInput,
  searchQuery,
  handleChangeQuery,
  isLoading,
  customer,
  refetch,
  handleComposeEmail,
}: EmailThreadsProps) => {
  return (
    <React.Fragment>
      <EmailActions
        resultsCount={emailThreads.length}
        handleChangeQuery={handleChangeQuery}
        searchInput={searchInput}
        searchQuery={searchQuery}
        selectedThread={selectedThread}
        isLoading={isLoading}
        handleComposeEmail={handleComposeEmail}
        isCustomerEmailProvided={customer.email}
      />
      <Line />
      <EmailThreadsList
        emailThreads={emailThreads}
        customer={customer}
        handleMissingContactInfo={handleMissingContactInfo}
        refetch={refetch}
        selectedThread={selectedThread}
        setSelectedThread={setSelectedThread}
        handleComposeEmail={handleComposeEmail}
      />
    </React.Fragment>
  );
};

EmailThreads.defaultProps = {
  selectedThread: null,
  setSelectedThread: () => {},
  searchInput: null,
  searchQuery: '',
  handleChangeQuery: () => {},
  isLoading: false,
  refetch: () => {},
  handleComposeEmail: undefined,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailThreads.fragment = gql`
  ${EmailThreadsList.fragment}
  fragment EmailThreads_User on User {
    id
    ...EmailThreadsList_User
  }
  fragment EmailThreads_Thread on Thread {
    id
    ...EmailThreadsList_Thread
  }
`;

export default EmailThreads;
