// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useModal} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Drawer from '@shared/design/components/Drawer';
import EditClientModal from 'modules/Client/Show/components/EditClientModal';
import CustomerCommunicationDrawer from 'modules/Communication/components/CustomerCommunicationDrawer';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const ProjectClientSmsButton = ({project, refetch, iconSize, buttonStyle, handlePress}: any) => {
  const customerCommunicationDrawerSms = useDrawer({name: 'Customer Communication Drawer'});
  const editClientModal = useModal({name: 'Edit Client Modal'});
  return (
    <Row>
      <TertiaryButton
        onPress={handlePress || customerCommunicationDrawerSms.handleOpen}
        style={buttonStyle}
      >
        <Icon source={Icon.CommentDots} color={colors.blue.interactive} size={iconSize} />
      </TertiaryButton>
      <CustomerCommunicationDrawer
        key={customerCommunicationDrawerSms.key}
        isOpen={customerCommunicationDrawerSms.isOpen}
        handleClose={customerCommunicationDrawerSms.handleClose}
        client={project.client}
        projectId={project.id}
        handleMissingContactInfo={editClientModal.handleOpen}
        initialTabIndex={0}
        width={Drawer.WIDTH.MEDIUM}
      />
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={project.client.uuid}
        refetch={refetch}
      />
    </Row>
  );
};

const ProjectClientEmailButton = ({project, refetch, iconSize, buttonStyle, handlePress}: any) => {
  const customerCommunicationDrawerEmail = useDrawer({name: 'Customer Communication Drawer'});
  const editClientModal = useModal({name: 'Edit Client Modal'});
  return (
    <Row>
      <TertiaryButton
        onPress={handlePress || customerCommunicationDrawerEmail.handleOpen}
        style={buttonStyle}
      >
        <Icon source={Icon.Envelope} color={colors.blue.interactive} size={iconSize} />
      </TertiaryButton>
      <CustomerCommunicationDrawer
        key={customerCommunicationDrawerEmail.key}
        isOpen={customerCommunicationDrawerEmail.isOpen}
        handleClose={customerCommunicationDrawerEmail.handleClose}
        client={project.client}
        projectId={project.id}
        handleMissingContactInfo={editClientModal.handleOpen}
        initialTabIndex={1}
        width={Drawer.WIDTH.MEDIUM}
      />
      <EditClientModal
        key={editClientModal.key}
        isOpen={editClientModal.isOpen}
        handleClose={editClientModal.handleClose}
        clientUuid={project.client.uuid}
        refetch={refetch}
      />
    </Row>
  );
};

const ProjectClientCommunicationButtons = ({
  project,
  refetch,
  iconSize,
  spaceWidth,
  buttonStyle,
  handlePressSms,
  handlePressEmail,
}: any) => {
  return (
    <Row>
      <ProjectClientSmsButton
        project={project}
        refetch={refetch}
        iconSize={iconSize}
        buttonStyle={buttonStyle}
        handlePress={handlePressSms}
      />
      <Space width={spaceWidth} />
      <ProjectClientEmailButton
        project={project}
        refetch={refetch}
        iconSize={iconSize}
        buttonStyle={buttonStyle}
        handlePress={handlePressEmail}
      />
    </Row>
  );
};

ProjectClientCommunicationButtons.Sms = ProjectClientSmsButton;
ProjectClientCommunicationButtons.Email = ProjectClientEmailButton;

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectClientCommunicationButtons.fragment = gql`
  ${CustomerCommunicationDrawer.fragment}
  fragment ProjectClientCommunicationButtons on Project {
    id
    client {
      id
      uuid
      ...CustomerCommunicationDrawer
    }
  }
`;

export default ProjectClientCommunicationButtons;
