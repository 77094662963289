// Libraries
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {useInlineFormMutation} from '@supermove/hooks';
import {UserFlowModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import DeleteUserFlowForm, {
  DeleteUserFlowFormToFormType,
} from '@shared/modules/UserFlows/forms/DeleteUserFlowForm';

const useDeleteUserFlowMutation = ({
  deleteUserFlowForm,
  onSuccess,
}: {
  deleteUserFlowForm: DeleteUserFlowFormToFormType;
  onSuccess: () => void;
}) => {
  return useInlineFormMutation({
    name: 'deleteUserFlowForm',
    form: deleteUserFlowForm,
    toForm: DeleteUserFlowForm.toForm,
    toMutation: DeleteUserFlowForm.toMutation,
    mutation: gql`
      mutation useDeleteUserFlowMutation($deleteUserFlowForm: DeleteUserFlowForm!) {
        response: deleteUserFlow(deleteUserFlowForm: $deleteUserFlowForm) {
          ${gql.errors}
          userFlow {
            id
          }
        }
      }
    `,
    onSuccess,
  });
};

const OrganizationSettingsDocumentFlowDeleteModalContent = ({
  userFlow,
  isOpen,
  handleClose,
  onDeleted,
}: {
  userFlow: UserFlowModel;
  isOpen: boolean;
  handleClose: () => void;
  onDeleted: () => void;
}) => {
  const {form, handleSubmit, submitting} = useDeleteUserFlowMutation({
    deleteUserFlowForm: DeleteUserFlowForm.edit(userFlow),
    onSuccess: () => {
      onDeleted();
      handleClose();
    },
  });

  const projectTypeUserFlowsCount = userFlow.projectTypeUserFlows.length;

  const subtitle =
    projectTypeUserFlowsCount > 0
      ? `This flow is linked to the following project types:\n ${userFlow.projectTypeUserFlows
          .map((projectTypeUserFlow) => `• ${projectTypeUserFlow.projectType.name}`)
          .join('\n')}`
      : 'This flow is not linked to any project types.';

  return (
    <DeleteModal
      isDisabled={submitting}
      isOpen={isOpen}
      title={`Delete this flow?`}
      subtitle={subtitle}
      isSubmitting={submitting}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

interface OrganizationSettingsDocumentFlowDeleteModalProps {
  userFlow: UserFlowModel;
  isOpen: boolean;
  handleClose: () => void;
  onDeleted: () => void;
}

const OrganizationSettingsDocumentFlowDeleteModal = ({
  userFlow,
  isOpen,
  handleClose,
  onDeleted,
}: OrganizationSettingsDocumentFlowDeleteModalProps) => {
  const {data, loading} = useQuery<{userFlow: UserFlowModel}>(
    OrganizationSettingsDocumentFlowDeleteModalQuery,
    {
      variables: {uuid: userFlow.uuid},
      skip: !isOpen,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <OrganizationSettingsDocumentFlowDeleteModalContent
      userFlow={data.userFlow}
      isOpen={isOpen}
      handleClose={handleClose}
      onDeleted={onDeleted}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const OrganizationSettingsDocumentFlowDeleteModalQuery = gql`
  query OrganizationSettingsDocumentFlowDeleteModalQuery($uuid: String!) {
    userFlow(uuid: $uuid) {
      id
      projectTypeUserFlows {
        id
        projectType {
          id
          name
        }
      }
    }
  }
`;

export default OrganizationSettingsDocumentFlowDeleteModal;
