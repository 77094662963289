// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal} from '@supermove/hooks';

// App
import Table from '@shared/design/components/Table';
import DeleteClaimTypeModal from 'modules/Organization/Settings/Company/components/DeleteClaimTypeModal';
import UpdateClaimTypeModal from 'modules/Organization/Settings/Company/components/UpdateClaimTypeModal';

const ClaimTypesTableContainer = Styled.View`
  max-width: 780px;
`;

const ColorDot = Styled.View`
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const sortClaimTypeByName = (claimTypes: any) => {
  return _.orderBy(
    claimTypes,
    [(claimType) => claimType.name.toLowerCase(), (claimType) => claimType.name],
    ['asc', 'desc'],
  );
};

const getClaimTypesColumnDefinitions = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => [
  {
    minWidth: 50,
    cellComponent: (claimType: any) => {
      // @ts-expect-error TS(2769): No overload matches this call.
      return <ColorDot color={claimType.color} />;
    },
    mobileOptions: {
      rank: 3,
    },
  },
  {
    headerLabel: 'Name',
    flex: 2,
    cellText: (claimType: any) => claimType.name,
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    headerLabel: 'Description',
    flex: 4,
    cellText: (claimType: any) => (claimType.description ? claimType.description : ''),
    numberOfLines: 2,
    mobileOptions: {
      rank: 2,
    },
  },
  {
    headerLabel: 'Actions',
    minWidth: 80,
    isHidden: !organization.isPrimary || !isEnabledClaimsProjectOrganizationMigration,
    actions: (claimType: any) => [
      {
        text: 'Edit',
        onPress: ({handleOpen}: any) => handleOpen(),
        desktopIcon: Icon.Pen,
        actionHook: {
          hook: useModal,
          hookArgument: {
            name: `Edit Claim Type Modal`,
          },
          renderComponent: ({isOpen, handleClose, hookKey}: any) => {
            return (
              <UpdateClaimTypeModal
                key={hookKey}
                isOpen={isOpen}
                handleClose={handleClose}
                claimType={claimType}
                refetch={refetch}
              />
            );
          },
        },
      },
      {
        text: 'Remove',
        onPress: ({handleOpen}: any) => handleOpen(),
        actionHook: {
          hook: useModal,
          hookArgument: {
            name: `Remove Claim Type Modal`,
          },
          renderComponent: ({isOpen, handleClose, hookKey}: any) => {
            return (
              <DeleteClaimTypeModal
                key={hookKey}
                isOpen={isOpen}
                handleClose={handleClose}
                claimType={claimType}
                refetch={refetch}
              />
            );
          },
        },
      },
    ],
  },
];

const ClaimTypesTable = ({
  organization,
  refetch,
  isEnabledClaimsProjectOrganizationMigration,
}: any) => {
  return (
    <ClaimTypesTableContainer>
      <Table
        items={sortClaimTypeByName(organization.companySettings.claimTypes)}
        itemKey={'id'}
        columnDefinitions={getClaimTypesColumnDefinitions({
          organization,
          refetch,
          isEnabledClaimsProjectOrganizationMigration,
        })}
        emptyStateText={`Create a claim type and it'll appear here`}
        hasBorder
      />
    </ClaimTypesTableContainer>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ClaimTypesTable.fragment = gql`
  ${UpdateClaimTypeModal.fragment}
  ${DeleteClaimTypeModal.fragment}
  fragment ClaimTypesTable on Organization {
    id
    companySettings {
      claimTypes {
        id
        name
        color
        description
        ...UpdateClaimTypeModal
        ...DeleteClaimTypeModal
      }
    }
  }
`;

export default ClaimTypesTable;
