// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import OrganizationLogo from '@shared/modules/Organization/components/OrganizationLogo';

const Container = Styled.View`
  width: 100%;
`;

type OwnProps = {
  organization: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof JobRequestOrganizationLogo.defaultProps;

// @ts-expect-error TS(7022): 'JobRequestOrganizationLogo' implicitly has type '... Remove this comment to see the full error message
const JobRequestOrganizationLogo = ({organization}: Props) => {
  const responsive = useResponsive();

  return (
    <Container>
      <OrganizationLogo
        organization={organization}
        style={{
          width: '100%',
          height: responsive.mobile ? '145px' : '215px',
        }}
      />
    </Container>
  );
};

JobRequestOrganizationLogo.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobRequestOrganizationLogo.fragment = gql`
  ${OrganizationLogo.fragment}
  fragment JobRequestOrganizationLogo on Organization {
    id
    ...OrganizationLogo
  }
`;

export default JobRequestOrganizationLogo;
