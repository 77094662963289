/**
 * Component - v2.0.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {colors} from '@supermove/styles';

// App
import SmallModal from '@shared/design/components/Modal/SmallModal';

const CannotDeleteDefaultBillingLibraryModal = ({isOpen, handleClose}: any) => {
  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.BoldText>You cannot delete your default billing library.</SmallModal.BoldText>
      <Space height={20} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={handleClose}>Cancel</SmallModal.Button>
        <SmallModal.Button color={colors.blue.interactive} onPress={handleClose}>
          Got it
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

export default CannotDeleteDefaultBillingLibraryModal;
