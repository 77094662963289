// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DropdownInput, Loading, Space} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {titleize} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import InventoryLibraryKind from '@shared/modules/Inventory/enums/InventoryLibraryKind';
import InventoryLibraryForm from '@shared/modules/Inventory/forms/InventoryLibraryForm';
import useDeleteDefaultInventoryLibraryMutation from '@shared/modules/Inventory/hooks/useDeleteDefaultInventoryLibraryMutation';
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';

const DeleteDefaultSurveyInventoryLibraryModalContent = ({
  deleteDefaultInventoryLibraryModal,
  inventoryLibrary,
  refetch,
}: any) => {
  const inventoryLibraryForm = InventoryLibraryForm.edit(inventoryLibrary);
  const inventoryLibraryDisplayText = InventoryLibraryKind.getDisplayHeader(
    inventoryLibrary.kind,
  ).toLowerCase();
  const {form, submitting, handleSubmit} = useDeleteDefaultInventoryLibraryMutation({
    inventoryLibraryForm,
    onSuccess: () => {
      refetch();
      deleteDefaultInventoryLibraryModal.handleClose();
    },
    onError: (errors: any) => console.log(errors),
  });
  const inventoryLibraries =
    inventoryLibrary.kind === InventoryLibraryKind.DRIVER_INVENTORY
      ? inventoryLibrary.organization.driverInventoryLibraries
      : inventoryLibrary.organization.surveyInventoryLibraries;
  const inventoryLibraryOptions = inventoryLibraries.reduce(
    (options: any, inventoryLibrary: any) => {
      if (inventoryLibrary.id !== inventoryLibraryForm.inventoryLibraryId) {
        options.push({
          label: inventoryLibrary.name,
          value: inventoryLibrary.id,
        });
      }
      return options;
    },
    [],
  );

  return (
    <React.Fragment>
      <SmallModal.HeaderText>{`Remove ${_.get(
        form.values,
        'inventoryLibraryForm.name',
        'Inventory Library',
      )}?`}</SmallModal.HeaderText>
      <Space height={16} />
      <SmallModal.Text>
        {`The library you want to remove is currently the default. ` +
          `Please choose a new default ${InventoryLibraryKind.getDisplayKind(
            inventoryLibrary.kind,
          )} library. Any project types ` +
          `with this library will be replaced by the new default.`}
      </SmallModal.Text>
      <Space height={16} />
      <FieldInput
        {...form}
        isRequired
        name={'inventoryLibraryForm.newDefaultInventoryLibraryId'}
        label={`Default ${titleize(inventoryLibraryDisplayText)} Inventory Library`}
        component={DropdownInput}
        input={{
          options: inventoryLibraryOptions,
          placeholder: `Select default ${inventoryLibraryDisplayText} inventory library`,
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button onPress={deleteDefaultInventoryLibraryModal.handleClose}>
          Cancel
        </SmallModal.Button>
        <SmallModal.Button
          color={colors.red.warning}
          onPress={handleSubmit}
          isSubmitting={submitting}
        >
          Reassign and Remove
        </SmallModal.Button>
      </SmallModal.Footer>
    </React.Fragment>
  );
};

const DeleteDefaultInventoryLibraryModal = ({
  deleteDefaultInventoryLibraryModal,
  inventoryLibraryUuid,
  refetch,
}: any) => {
  const {data, loading} = useQuery(DeleteDefaultInventoryLibraryModal.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inventoryLibraryUuid,
    },
    skip: !deleteDefaultInventoryLibraryModal.isOpen,
  });
  return (
    <SmallModal
      key={deleteDefaultInventoryLibraryModal.key}
      isOpen={deleteDefaultInventoryLibraryModal.isOpen}
      style={{overflow: 'visible'}}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() =>
          data ? (
            <DeleteDefaultSurveyInventoryLibraryModalContent
              inventoryLibrary={data.inventoryLibrary}
              deleteDefaultInventoryLibraryModal={deleteDefaultInventoryLibraryModal}
              refetch={refetch}
            />
          ) : null
        }
      </Loading>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DeleteDefaultInventoryLibraryModal.query = gql`
  ${InventoryLibraryForm.edit.fragment}
  query DeleteDefaultInventoryLibraryModal($inventoryLibraryUuid: String!) {
    ${gql.query}
    inventoryLibrary(uuid: $inventoryLibraryUuid) {
      id
      kind
      organization {
        id
        driverInventoryLibraries {
          id
          name
        }
        surveyInventoryLibraries {
          id
          name
        }
      }
      ...InventoryLibraryForm_edit
    }
  }
`;

export default DeleteDefaultInventoryLibraryModal;
