/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import ProjectJobSection from 'modules/Project/Update/components/ProjectJobSection';

const EmptySpace = Styled.View`
  flex: 1;
`;

const SectionTitle = Styled.H6`
  color: ${colors.gray.primary};
  ${fontWeight(700)}
`;

const FieldGroupContainer = Styled.View`
  flex-direction: ${(props) => ((props as any).mobile ? 'column' : 'row')};
  align-items: ${(props) => ((props as any).mobile ? 'stretch' : 'flex-start')};
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index = 0,
  }) => 100 - index};
`;

const ProjectJobInternalInfoBlockFields = ({form, jobField, organization}: any) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <FieldGroupContainer index={0} {...responsive}>
        <FieldInput.Memoized
          {...form}
          name={`${jobField}.officeNotes`}
          label={'Office Notes'}
          input={{
            multiline: true,
            numberOfLines: 2,
            placeholder: 'Enter any details for the office',
            style: {
              paddingVertical: 9,
              flex: 1,
            },
          }}
          style={{
            flex: 1,
          }}
        />
      </FieldGroupContainer>
    </React.Fragment>
  );
};

const ProjectJobInternalInfoBlock = ({index, form, jobField, organization}: any) => {
  return (
    <ProjectJobSection
      index={index}
      title={
        <React.Fragment>
          <SectionTitle>Internal Information</SectionTitle>
          <EmptySpace />
          <Space width={8} />
        </React.Fragment>
      }
    >
      <ProjectJobInternalInfoBlockFields
        form={form}
        jobField={jobField}
        organization={organization}
      />
    </ProjectJobSection>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProjectJobInternalInfoBlock.fragment = gql`
  fragment ProjectJobInternalInfoBlock on Organization {
    id
  }
`;

export default ProjectJobInternalInfoBlock;
