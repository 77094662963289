// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {DateInput, Icon, Space, Styled, Popover, MultiDropdownInput} from '@supermove/components';
import {useNavigationDOM, usePopover} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowRunStatus from '@shared/modules/Workflow/enums/WorkflowRunStatus';
import ResponsivePopover from 'modules/App/components/ResponsivePopover';

const ContentContainer = Styled.View`
  padding-horizontal: 16px;
`;

const Header = Styled.Text`
  ${Typography.Label}
`;

const BodyText = Styled.Text`
  ${Typography.Body}
  padding-bottom: 12px;
`;

const FiltersRow = Styled.View`
  z-index: ${(props) => 100 - (props as any).index}
  flex-direction: row;
  align-items: flex-end;
`;

const FilterContainer = Styled.View`
  z-index: ${({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    index,
  }) => 100 - index};
`;

const getButtonText = ({params}: any) => {
  const {page, query, fromDate, toDate, workflowUuid, ...countedFilters} = params;
  const activeFilters = _.compact([
    ...(Datetime.isValidDateString(fromDate) ? [fromDate] : []),
    ...(Datetime.isValidDateString(toDate) ? [toDate] : []),
    ..._.values(countedFilters),
  ]);
  return `Filters${_.isEmpty(activeFilters) ? '' : ` (${activeFilters.length})`}`;
};

const getDateValue = ({params, paramKey}: any) => {
  const urlDateValue = params[paramKey];
  const isValidDate = Datetime.isValidDateString(urlDateValue);
  if (isValidDate) {
    return Datetime.fromDate(urlDateValue);
  }
  return '';
};

const StatusFilter = ({index, handleUpdateParam}: any) => {
  const {params} = useNavigationDOM();

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText>Statuses</FieldInput.LabelText>
      <Space height={4} />
      {/* @ts-expect-error TS(2740): Type '{ options: ({ value: string; label: string; ... Remove this comment to see the full error message */}
      <MultiDropdownInput
        options={WorkflowRunStatus.DROPDOWN_OPTIONS}
        placeholder={'Select Statuses'}
        style={{flex: 1}}
        value={params.statuses}
        onChangeValue={(statuses) => {
          handleUpdateParam({
            paramKey: 'statuses',
            paramValue: statuses,
          });
        }}
      />
    </FilterContainer>
  );
};

const DateFilter = ({index, paramKey, label, handleUpdateParam}: any) => {
  const {params} = useNavigationDOM();
  const handleUpdateDate = ({date}: any) =>
    handleUpdateParam({
      paramKey,
      paramValue: date ? Datetime.convertToDate(date) : '',
    });

  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FilterContainer index={index}>
      <FieldInput.LabelText>{label}</FieldInput.LabelText>
      <Space height={4} />
      <DateInput
        placeholder={'MM/DD/YYYY'}
        setFieldValue={() => {}}
        onChangeDate={(date) => handleUpdateDate({date})}
        value={getDateValue({params, paramKey})}
        style={{
          width: 118,
        }}
      />
    </FilterContainer>
  );
};

const DateFilters = ({index, handleUpdateParam}: any) => {
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <FiltersRow index={index}>
      <DateFilter
        index={0}
        paramKey={'fromDate'}
        label={'Dates'}
        handleUpdateParam={handleUpdateParam}
      />
      <Space width={8} />
      <BodyText>-</BodyText>
      <Space width={8} />
      <DateFilter index={1} paramKey={'toDate'} handleUpdateParam={handleUpdateParam} />
    </FiltersRow>
  );
};

const ClearFiltersButton = ({handleClearFilters}: any) => {
  const {params} = useNavigationDOM();
  return (
    <SecondaryButton
      text={'Clear Filters'}
      onPress={() =>
        handleClearFilters({
          ...params,
          fromDate: null,
          toDate: null,
          statuses: null,
        })
      }
    />
  );
};

const WorkflowRunFiltersPopoverContent = ({handleUpdateParam, handleClearFilters}: any) => {
  return (
    <ResponsivePopover.StaticContainer width={288}>
      <ContentContainer>
        <Space height={16} />
        <Header>Filters</Header>
        <Space height={12} />
        <DateFilters index={0} handleUpdateParam={handleUpdateParam} />
        <Space height={12} />
        <StatusFilter index={1} handleUpdateParam={handleUpdateParam} />
        <Space height={12} />
        <ClearFiltersButton handleClearFilters={handleClearFilters} />
        <Space height={16} />
      </ContentContainer>
    </ResponsivePopover.StaticContainer>
  );
};

const WorkflowRunFiltersPopover = ({popover, handleUpdateParam, handleClearFilters}: any) => {
  return (
    <Popover
      placement={Popover.Positions.BottomStart}
      isOpen={popover.isOpen}
      handleOpen={popover.handleOpen}
      handleClose={popover.handleClose}
      reference={popover.ref}
      offset={[0, 4]}
    >
      <WorkflowRunFiltersPopoverContent
        handleUpdateParam={handleUpdateParam}
        handleClearFilters={handleClearFilters}
      />
    </Popover>
  );
};

const WorkflowRunFiltersButton = ({handleUpdateParam, handleClearFilters}: any) => {
  const {params} = useNavigationDOM();
  const taskFilterPopover = usePopover({
    name: 'Workflow Run Filters Popover',
  });

  return (
    <React.Fragment>
      <Popover.Content innerRef={taskFilterPopover.ref}>
        <SecondaryButton
          text={getButtonText({params})}
          onPress={taskFilterPopover.handleOpen}
          iconLeft={Icon.Filter}
        />
      </Popover.Content>
      <WorkflowRunFiltersPopover
        popover={taskFilterPopover}
        handleUpdateParam={handleUpdateParam}
        handleClearFilters={handleClearFilters}
      />
    </React.Fragment>
  );
};

export default WorkflowRunFiltersButton;
