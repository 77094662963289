// Libraries
import React from 'react';

// Supermove
import {CurrencyInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import CommercialMaterialTypeForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialTypeForm';
import useCreateCommercialMaterialTypeMutation from '@shared/modules/CommercialCatalog/hooks/useCreateCommercialMaterialTypeMutation';

const TextInput = Styled.TextInput.H7`
  margin-top: 3px;
`;

const CreateCommercialMaterialTypeModal = ({isOpen, organization, handleClose, refetch}: any) => {
  const commercialMaterialTypeForm = CommercialMaterialTypeForm.new({
    organizationId: organization.id,
  });
  const {form, submitting, handleSubmit} = useCreateCommercialMaterialTypeMutation({
    commercialMaterialTypeForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors: any) => {
      console.log({errors});
    },
  });

  return (
    <SmallModal isOpen={isOpen} handlePressOutside={handleClose}>
      <SmallModal.HeaderText>Add Material</SmallModal.HeaderText>
      <Space height={12} />
      <SmallModal.Text>Enter material details and press submit.</SmallModal.Text>
      <Space height={12} />
      <FieldInput
        {...form}
        name={'commercialMaterialTypeForm.name'}
        label={'Material Name'}
        input={{
          required: !form.values.commercialMaterialTypeForm.name,
          autoFocus: true,
          placeholder: 'Enter material name',
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={16} />
      <FieldInput
        {...form}
        name={'commercialMaterialTypeForm.price'}
        label={'Price'}
        component={CurrencyInput}
        input={{
          component: TextInput,
          placeholder: 'Enter the price',
          keyboardType: 'phone-pad',
          setFieldTouched: form.setFieldTouched,
          setFieldValue: form.setFieldValue,
        }}
        style={{
          flex: 1,
        }}
      />
      <Space height={16} />
      <SmallModal.Footer>
        <SmallModal.Button isDisabled={submitting} onPress={handleClose}>
          Cancel
        </SmallModal.Button>
        <Space width={8} />
        <SmallModal.Button
          isSubmitting={submitting}
          color={colors.blue.interactive}
          onPress={handleSubmit}
        >
          Submit
        </SmallModal.Button>
      </SmallModal.Footer>
    </SmallModal>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateCommercialMaterialTypeModal.fragment = gql`
  fragment CreateCommercialMaterialTypeModal on Organization {
    id
  }
`;

export default CreateCommercialMaterialTypeModal;
