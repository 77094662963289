import {Styled} from '@supermove/components';

const SettingsContent = Styled.View`
  width: 100%;
  margin-bottom: 40px;
  margin-top: ${(props) => ((props as any).mobile ? 40 : 20)}px;
  padding-horizontal: ${(props) => ((props as any).mobile ? 12 : 24)}px;
`;

export default SettingsContent;
