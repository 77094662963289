// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';

// App
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ClaimForm from '@shared/modules/Claim/forms/ClaimForm';
import useCreateClaimMutation from '@shared/modules/Claim/hooks/useCreateClaimMutation';
import ProjectClaimDrawerFields from 'modules/Project/Claim/components/ProjectClaimDrawerFields';

const CreateProjectClaimDrawer = ({project, isOpen, handleClose, onSuccess}: any) => {
  const createClaimSuccessToast = useToast({
    ToastComponent: SuccessToast,
    // @ts-expect-error TS(2345): Argument of type '{ ToastComponent: { ({ message, ... Remove this comment to see the full error message
    isClosable: true,
  });

  const claimForm = ClaimForm.new({organizationId: project.organizationId, projectId: project.id});
  const {form, handleSubmit, submitting} = useCreateClaimMutation({
    claimForm,
    onSuccess: ({claim}: any) => {
      createClaimSuccessToast.handleToast({message: `Claim ${claim.identifier} created`});
      onSuccess();
    },
    onError: (errors: any) => console.log({errors}),
  });

  return (
    <DrawerWithAction
      isOpen={isOpen}
      handleClose={handleClose}
      width={DrawerWithAction.WIDTH.MEDIUM}
      headerText={'Create Claim'}
      handleSubmit={handleSubmit}
      primaryActionText={'Create'}
      isSubmitting={submitting}
      // @ts-expect-error TS(2345): Argument of type 'ClaimFormTypeForm' is not assign... Remove this comment to see the full error message
      isDisabled={ClaimForm.getIsUploadingAttachment(form.values.claimForm)}
      // @ts-expect-error TS(2322): Type '{ children: Element; isOpen: any; handleClos... Remove this comment to see the full error message
      isResponsive
    >
      <ProjectClaimDrawerFields
        form={form}
        field={'claimForm'}
        projectUuid={project.uuid}
        isOpen={isOpen}
      />
    </DrawerWithAction>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreateProjectClaimDrawer.fragment = gql`
  fragment CreateProjectClaimDrawer on Project {
    id
    uuid
    organizationId
  }
`;

export default CreateProjectClaimDrawer;
