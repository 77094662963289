// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Typography, colors} from '@supermove/styles';

const Row = Styled.View`
  flex-direction: row;
`;

const ColorDot = Styled.View`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const getProgressColor = ({completedSteps, totalSteps}: any) => {
  if (completedSteps === '0') {
    return colors.red.warning;
  }
  if (_.toString(completedSteps) === _.toString(totalSteps)) {
    return colors.green.status;
  }
  return colors.yellow.hover;
};

const ProposalProgress = ({proposal}: any) => {
  return (
    <Row style={{alignItems: 'center'}}>
      <ColorDot
        // @ts-expect-error TS(2769): No overload matches this call.
        color={getProgressColor({
          completedSteps: proposal.completedStepCount,
          totalSteps: proposal.stepCount,
        })}
      />
      <Space width={8} />
      <Text>{`${proposal.completedStepCount}/${proposal.stepCount} steps`}</Text>
    </Row>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ProposalProgress.fragment = gql`
  fragment ProposalProgress on Confirmation {
    id
    completedStepCount
    stepCount
  }
`;

export default ProposalProgress;
