// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  reportMoveId: string;
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof FinalizeReportMoveMutation.defaultProps;

// @ts-expect-error TS(7022): 'FinalizeReportMoveMutation' implicitly has type '... Remove this comment to see the full error message
const FinalizeReportMoveMutation = ({reportMoveId, onSuccess, onError, children}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading }:... Remove this comment to see the full error message
    variables={{
      reportMoveId,
    }}
    mutation={FinalizeReportMoveMutation.mutation}
    onCompleted={({response: {reportMove, errors}}: any) => {
      if (!errors) {
        onSuccess(reportMove);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading}: any) => children({loading, handleSubmit})}
  </Mutation>
);

FinalizeReportMoveMutation.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
FinalizeReportMoveMutation.fragment = gql`
  fragment FinalizeReportMoveMutation on ReportMove {
    id
  }
`;

FinalizeReportMoveMutation.mutation = gql`
  mutation FinalizeReportMoveMutation(
    $reportMoveId: Int!,
  ) {
    response: finalizeReportMove(
      reportMoveId: $reportMoveId,
    ) {
      ${gql.errors}
      reportMove {
        id
        isFinal
        isFinalizable
        isSyncableToQuickbooks
        job {
          id
          calendarPrimaryStatus
        }
      }
    }
  }
`;

export default FinalizeReportMoveMutation;
