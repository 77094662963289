// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled, ScrollView} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import DocumentItemsList from '@shared/modules/Document/components/DocumentItemsList';
import {
  DocumentContentJsonType,
  DocumentContentJsonWithSectionsType,
  SelectedDocumentItemType,
  SelectedDocumentItemInSectionType,
  SetSelectedDocumentItemType,
} from '@shared/modules/Document/types/DocumentTemplateVersionEditorTypes';

const PlaceholderContainer = Styled.View`
  flex: 1;
  padding-vertical: 24px;
`;

const PlaceholderDocument = Styled.View`
  background-color: ${colors.white};
  flex: 1;
  border-width: 1px;
  border-color: ${colors.gray.border};
  justify-content: center;
  align-items: center;
`;

const PlaceholderDocumentText = Styled.Text`
  ${Typography.Responsive.Micro};
  color: ${colors.gray.tertiary};
`;

const DocumentTemplateEditorPreview = ({
  documentContentJson,
  selectedDocumentItem,
  setSelectedDocumentItem,
  hoveredSectionIndex,
  isEnabledRichTextEditorV2,
  isEnabledDocumentPerPositionTimesheetTotals,
  isEnabledDocumentPerPositionTimesheetTimes,
}: {
  documentContentJson: DocumentContentJsonType | DocumentContentJsonWithSectionsType;
  selectedDocumentItem: SelectedDocumentItemType | SelectedDocumentItemInSectionType;
  setSelectedDocumentItem: SetSelectedDocumentItemType;
  hoveredSectionIndex?: number;
  isEnabledRichTextEditorV2: boolean;
  isEnabledDocumentPerPositionTimesheetTotals: boolean;
  isEnabledDocumentPerPositionTimesheetTimes: boolean;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {!_.isEmpty(documentContentJson.documentItems) ? (
        <ScrollView
          contentContainerStyle={{
            padding: 32,
            backgroundColor: colors.white,
            borderWidth: 1,
            borderColor: colors.gray.border,
            minHeight: '100%',
          }}
          style={{paddingVertical: 24}}
        >
          <DocumentItemsList
            documentItems={documentContentJson.documentItems}
            isPreview
            isBuilder
            isEditable={false}
            selectedDocumentItem={selectedDocumentItem}
            setSelectedDocumentItem={setSelectedDocumentItem}
            highlightSectionIndex={hoveredSectionIndex}
            isEnabledRichTextEditorV2={isEnabledRichTextEditorV2}
            isEnabledDocumentPerPositionTimesheetTotals={
              isEnabledDocumentPerPositionTimesheetTotals
            }
            isEnabledDocumentPerPositionTimesheetTimes={isEnabledDocumentPerPositionTimesheetTimes}
          />
        </ScrollView>
      ) : (
        <PlaceholderContainer>
          <PlaceholderDocument>
            <PlaceholderDocumentText responsive={responsive}>
              {'Document Preview'}
            </PlaceholderDocumentText>
          </PlaceholderDocument>
        </PlaceholderContainer>
      )}
    </React.Fragment>
  );
};

export default DocumentTemplateEditorPreview;
