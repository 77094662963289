// Libraries
import React from 'react';
import {Redirect} from 'react-router-dom';

// Components
import {Loading} from '@supermove/components';
import {gql} from '@supermove/graphql';

import {Page} from 'modules/App/components';

const getActiveJobUser = ({job, viewer}: any) => {
  return job.activeJobUsers.find((jobUser: any) => String(jobUser.userId) === String(viewer.id));
};

const ShowJobPage = ({
  match: {
    params: {uuid},
  },
}: any) => {
  return (
    <Page variables={{uuid}} query={ShowJobPage.query}>
      {({responsive, navigator, loading, data}: any) => (
        <Loading loading={loading}>
          {() => {
            if (!data.viewer) {
              // Public view of the job.
              return <Redirect to={`/1/jobs/${uuid}/view`} />;
            }

            const jobUser = getActiveJobUser({job: data.job, viewer: data.viewer});

            if (jobUser) {
              // User is on the job and allowed to see the details.
              return <Redirect to={`/1/jobs/${uuid}/details`} />;
            }

            return <Redirect to={'/1/schedule'} />;
          }}
        </Loading>
      )}
    </Page>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
ShowJobPage.query = gql`
  query ShowJobPage($uuid: String!) {
    ${gql.query}
    viewer {
      id
    }
    job(uuid: $uuid) {
      id
      activeJobUsers {
        id
        userId
        status
      }
    }
  }
`;

export default ShowJobPage;
