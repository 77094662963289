// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Popover} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {usePopover, useToast} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import ActionModal from '@shared/design/components/Modal/SmallModal/ActionModal';
import Toast from '@shared/design/components/Toast';
import ConfirmationStepsKind from '@shared/modules/Proposal/enums/ConfirmationStepsKind';
import ConfirmationForm from '@shared/modules/Proposal/forms/ConfirmationForm';
import useExpireConfirmationMutation from '@shared/modules/Proposal/hooks/useExpireConfirmationMutation';

const handleCopyLink = ({project, proposal, copiedUrlToast}: any) => {
  const proposalUrl =
    proposal.kind === ConfirmationStepsKind.QUOTE ? project.quoteUrl : project.confirmationUrl;
  /* global navigator */
  navigator.clipboard.writeText(proposalUrl);
  copiedUrlToast.handleToast();
};

const ExpireConfirmationModal = ({
  proposal,
  expireConfirmationModal,
  handleSubmit,
  isSubmitting,
}: any) => {
  return (
    <ActionModal
      title={'Mark as expired?'}
      message={`This ${ConfirmationStepsKind.getDisplay(
        proposal.kind,
      )} will no longer be active and the customer will not be able to access it. This cannot be undone.`}
      icon={Icon.ExclamationTriangle}
      color={colors.orange.status}
      key={expireConfirmationModal.key}
      isOpen={expireConfirmationModal.isOpen}
      handlePrimaryAction={handleSubmit}
      isSubmitting={isSubmitting}
      handleSecondaryAction={expireConfirmationModal.handleClose}
      primaryActionText={'Confirm'}
      secondaryActionText={'Cancel'}
    />
  );
};

const MarkAsExpiredButton = ({proposal, refetch}: any) => {
  const expireConfirmationModal = usePopover({
    name: 'Expire Confirmation Modal',
    enableTracking: true,
  });
  const expiredProposalToast = useToast({
    ToastComponent: Toast,
    message: `${_.capitalize(proposal.kind)} marked as expired.`,
  });
  const confirmationForm = ConfirmationForm.edit(proposal);
  const {handleSubmit, submitting} = useExpireConfirmationMutation({
    confirmationForm,
    onSuccess: () => {
      refetch();
      expiredProposalToast.handleToast();
      expireConfirmationModal.handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const isDisabled = !proposal.isActive || proposal.isExpired;

  return (
    <React.Fragment>
      <SecondaryButton
        isSmall
        isWidthOfContainer
        isDisabled={isDisabled}
        text={'Mark as Expired'}
        onPress={expireConfirmationModal.handleOpen}
      />
      <ExpireConfirmationModal
        proposal={proposal}
        expireConfirmationModal={expireConfirmationModal}
        handleSubmit={handleSubmit}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

const CopyLinkButton = ({project, proposal}: any) => {
  const copiedUrlToast = useToast({
    ToastComponent: Toast,
    message: `Link to ${ConfirmationStepsKind.getDisplay(proposal.kind)} copied to clipboard.`,
  });
  const isDisabled = !proposal.isActive || proposal.isExpired;

  return (
    <SecondaryButton
      isSmall
      isWidthOfContainer
      isDisabled={isDisabled}
      text={'Copy Link'}
      onPress={() => handleCopyLink({project, proposal, copiedUrlToast})}
    />
  );
};

const ProjectProposalActions = ({project, proposal, refetch}: any) => {
  const projectProposalActionsPopover = usePopover({
    name: 'Project Proposal Actions Popover',
    enableTracking: true,
  });
  const expireConfirmationModal = usePopover({
    name: 'Expire Confirmation Modal',
    enableTracking: true,
  });
  const copiedUrlToast = useToast({
    ToastComponent: Toast,
    message: `Link to ${ConfirmationStepsKind.getDisplay(proposal.kind)} copied to clipboard.`,
  });
  const expiredProposalToast = useToast({
    ToastComponent: Toast,
    message: `${_.capitalize(proposal.kind)} marked as expired.`,
  });
  const confirmationForm = ConfirmationForm.edit(proposal);
  const {handleSubmit, submitting} = useExpireConfirmationMutation({
    confirmationForm,
    onSuccess: () => {
      refetch();
      expiredProposalToast.handleToast();
      expireConfirmationModal.handleClose();
    },
    onError: (errors: any) => console.log({errors}),
  });
  const isDisabled = !proposal.isActive || proposal.isExpired;
  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={projectProposalActionsPopover}
        placement={Popover.Positions.BottomEnd}
        width={200}
        actions={[
          {
            text: 'Copy link to clipboard',
            onPress: () => handleCopyLink({project, proposal, copiedUrlToast}),
          },
          {
            text: 'Mark as expired',
            onPress: expireConfirmationModal.handleOpen,
          },
        ]}
      >
        <TertiaryButton
          isDisabled={isDisabled}
          style={{paddingHorizontal: 4, alignItems: 'center', justifyContent: 'center'}}
          onPress={projectProposalActionsPopover.handleToggle}
          isHitSlop
        >
          <Icon
            source={Icon.EllipsisV}
            color={isDisabled ? colors.gray.disabled : colors.gray.secondary}
            size={Icon.Sizes.Large}
          />
        </TertiaryButton>
      </ActionMenuPopover>
      <ExpireConfirmationModal
        proposal={proposal}
        expireConfirmationModal={expireConfirmationModal}
        handleSubmit={handleSubmit}
        isSubmitting={submitting}
      />
    </React.Fragment>
  );
};

ProjectProposalActions.MarkAsExpiredButton = MarkAsExpiredButton;
ProjectProposalActions.CopyLinkButton = CopyLinkButton;

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectProposalActions.projectFragment = gql`
  fragment ProjectProposalActions_Project on Project {
    id
    confirmationUrl
    quoteUrl
  }
`;

ProjectProposalActions.confirmationFragment = gql`
  ${ConfirmationForm.edit.fragment}

  fragment ProjectProposalActions_Confirmation on Confirmation {
    id
    isActive
    kind
    ...ConfirmationForm_edit
  }
`;

export default ProjectProposalActions;
