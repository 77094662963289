// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {gql, useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {ProjectTypeModel} from '@supermove/models';

// App
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import ProjectTypeForm from '@shared/modules/Project/forms/ProjectTypeForm';
import useUpdateProjectTypeMutation from '@shared/modules/Project/hooks/useUpdateProjectTypeMutation';

const ProjectTypeDocumentFlowDeleteModalContent = ({
  projectType,
  userFlowId,
  isOpen,
  handleClose,
  onDeleted,
}: {
  projectType: ProjectTypeModel;
  userFlowId: string;
  isOpen: boolean;
  handleClose: () => void;
  onDeleted: () => void;
}) => {
  const removeDocumentFlowFromProjectTypeSuccessToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Document flow removed from project type!',
  });

  const projectTypeWithoutUserFlow = {
    ...projectType,
    projectTypeUserFlows: projectType.projectTypeUserFlows.filter(
      (projectTypeUserFlow) => projectTypeUserFlow.userFlowId !== _.toNumber(userFlowId),
    ),
  };
  const projectTypeForm = ProjectTypeForm.edit(projectTypeWithoutUserFlow);

  const {form, submitting, handleSubmit} = useUpdateProjectTypeMutation({
    projectTypeForm,
    onSuccess: () => {
      handleClose();
      onDeleted();
      removeDocumentFlowFromProjectTypeSuccessToast.handleToast();
    },
    onError: (errors: unknown) => console.log({errors}),
  });

  return (
    <DeleteModal
      isDisabled={submitting}
      isOpen={isOpen}
      title={`Delete this flow from project type?`}
      isSubmitting={submitting}
      handleClose={handleClose}
      handleDelete={handleSubmit}
    />
  );
};

interface ProjectTypeDocumentFlowDeleteModalProps {
  projectTypeId: string;
  userFlowId: string;
  isOpen: boolean;
  handleClose: () => void;
  onDeleted: () => void;
}

const ProjectTypeDocumentFlowDeleteModal = ({
  projectTypeId,
  userFlowId,
  isOpen,
  handleClose,
  onDeleted,
}: ProjectTypeDocumentFlowDeleteModalProps) => {
  const {data, loading} = useQuery<{projectType: ProjectTypeModel}>(
    ProjectTypeDocumentFlowDeleteModalQuery,
    {
      variables: {projectTypeId: _.toNumber(projectTypeId)},
      skip: !isOpen,
    },
  );

  if (!data) {
    return null;
  }

  return (
    <ProjectTypeDocumentFlowDeleteModalContent
      isOpen={isOpen}
      projectType={data.projectType}
      userFlowId={userFlowId}
      handleClose={handleClose}
      onDeleted={onDeleted}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
const ProjectTypeDocumentFlowDeleteModalQuery = gql`
  ${ProjectTypeForm.edit.fragment}
  query ProjectTypeDocumentFlowDeleteModalQuery($projectTypeId: Int!) {
    ${gql.query}
    projectType(projectTypeId: $projectTypeId) {
      id
      ...ProjectTypeForm_edit
    }
  }
`;

export default ProjectTypeDocumentFlowDeleteModal;
