// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import ProjectBlockWrapper from 'modules/Project/V2/Show/Blocks/components/ProjectBlockWrapper';
import ProjectClientsBlockContentDesktop from 'modules/Project/V2/Show/Blocks/components/ProjectClientsBlockContentDesktop';
import ProjectClientsBlockContentMobile from 'modules/Project/V2/Show/Blocks/components/ProjectClientsBlockContentMobile';

const ProjectClientsBlock = ({
  project,
  handleSetPositionY,
  layoutKey,
  urlFilters,
  index,
  projectBlockKind,
}: any) => {
  const responsive = useResponsive();

  return (
    <ProjectBlockWrapper
      index={index}
      query={ProjectClientsBlock.query}
      queryVariables={{projectUuid: project.uuid}}
      layoutKey={layoutKey}
      handleSetPositionY={handleSetPositionY}
      SkeletonComponent={ProjectClientsBlockContentDesktop.SkeletonComponent}
      projectBlockKind={projectBlockKind}
    >
      {({data}: any) => {
        const {project} = data;
        const isSameBillingClient = project.client.id === project.billingClient.id;
        return (
          <React.Fragment>
            {responsive.desktop ? (
              <ProjectClientsBlockContentDesktop
                project={project}
                isSameBillingClient={isSameBillingClient}
                urlFilters={urlFilters}
              />
            ) : (
              <ProjectClientsBlockContentMobile
                project={project}
                isSameBillingClient={isSameBillingClient}
                urlFilters={urlFilters}
              />
            )}
          </React.Fragment>
        );
      }}
    </ProjectBlockWrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------

ProjectClientsBlock.fragment = gql`
  fragment ProjectClientsBlock on Project {
    id
    uuid
  }
`;

ProjectClientsBlock.query = gql`
  ${ProjectClientsBlockContentDesktop.fragment}
  ${ProjectClientsBlockContentMobile.fragment}

  query ProjectClientsBlock($projectUuid: String!) {
    ${gql.query}
    project(uuid: $projectUuid) {
      id
      ...ProjectClientsBlockContentDesktop
      ...ProjectClientsBlockContentMobile
    }
  }
`;

export default ProjectClientsBlock;
