// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form, useDrawer, useResponsive, useToggle} from '@supermove/hooks';
import {
  OrganizationModel,
  PaymentMethod,
  PaymentMethodModel,
  ProjectTypeModel,
} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import Callout from '@shared/design/components/Callout';
import EmptyState from '@shared/design/components/EmptyState';
import Panel from '@shared/design/components/Panel';
import ActionPanel from '@shared/design/components/Panel/ActionPanel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Table from '@shared/design/components/Table';
import UpdateOfficePaymentMethodsForm, {
  UpdateOfficePaymentMethodsFormType,
} from '@shared/modules/PaymentMethod/forms/UpdateOfficePaymentMethodsForm';
import useUpdateOfficePaymentMethodsMutation from '@shared/modules/PaymentMethod/hooks/useUpdateOfficePaymentMethodsMutation';
import EditProjectTypePaymentMethodsDrawer from 'modules/Organization/Settings/Company/components/EditProjectTypePaymentMethodsDrawer';
import PaymentMethodBadges from 'modules/Organization/Settings/Company/components/PaymentMethodBadges';
import PaymentMethodOptions from 'modules/Organization/Settings/Company/components/PaymentMethodOptions';
import PaymentMethodsPanel from 'modules/Organization/Settings/Company/components/PaymentMethodsPanel';

const SectionTitle = Styled.Text`
  ${Typography.Responsive.Heading1}
`;

const Description = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const Container = Styled.View`
`;

const ColorDot = Styled.View<{color: string}>`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background-color: ${({color}) => color};
`;

const OfficePaymentMethodsPanelContent = ({
  paymentMethods,
}: {
  paymentMethods: PaymentMethodModel[];
}) => {
  const hasPaymentMethods = paymentMethods.length > 0;
  return hasPaymentMethods ? (
    <PaymentMethodBadges
      paymentMethods={PaymentMethod.getSortedPaymentMethods(paymentMethods) as PaymentMethodModel[]}
    />
  ) : (
    <EmptyState
      title={'No Office App payment methods.'}
      message={
        'Create new payment methods or enable existing payment methods to be used in the Office App.'
      }
    />
  );
};

const OfficePaymentMethodsPanelEditContent = ({
  form,
  organization,
}: {
  form: Form<{updateOfficePaymentMethodsForm: UpdateOfficePaymentMethodsFormType}>;
  organization: OrganizationModel;
}) => {
  return (
    <PaymentMethodOptions
      form={form}
      displayForms={form.values.updateOfficePaymentMethodsForm.paymentMethodForms}
      field={'updateOfficePaymentMethodsForm.paymentMethodForms'}
      fieldToUpdate={'isEnabledForOffice'}
      labelField={'name'}
      organization={organization}
    />
  );
};

const OfficePaymentMethodsPanel = ({
  index,
  viewingOrganization,
  refetch,
}: {
  index: number;
  viewingOrganization: OrganizationModel;
  refetch: () => void;
}) => {
  const officePaymentMethodsToggle = useToggle();
  const updateOfficePaymentMethodsForm = UpdateOfficePaymentMethodsForm.edit(viewingOrganization);
  const {form, handleSubmit, submitting} = useUpdateOfficePaymentMethodsMutation({
    updateOfficePaymentMethodsForm,
    onSuccess: () => {
      refetch();
      officePaymentMethodsToggle.handleToggleOff();
    },
    onError: (errors) => {
      console.log({errors});
    },
  });
  return (
    <EditPanel
      index={index}
      title={'Office App'}
      BodyComponent={OfficePaymentMethodsPanelContent}
      bodyComponentProps={{
        paymentMethods: viewingOrganization.paymentMethods.filter(
          (paymentMethod) => paymentMethod.isEnabledForOffice,
        ),
      }}
      EditBodyComponent={OfficePaymentMethodsPanelEditContent}
      editBodyComponentProps={{form, organization: viewingOrganization}}
      handleSave={handleSubmit}
      isSubmitting={submitting}
      handleCancel={form.handleReset}
      isEditing={officePaymentMethodsToggle.isOn}
      handleEdit={officePaymentMethodsToggle.handleToggleOn}
      handleClose={officePaymentMethodsToggle.handleToggleOff}
    />
  );
};

const CrewPaymentMethodsPanelContent = ({
  projectTypes,
  refetch,
}: {
  projectTypes: ProjectTypeModel[];
  refetch: () => void;
}) => {
  return (
    <Table
      columnDefinitions={[
        {
          flex: 1,
          headerLabel: 'Project Type',
          cellComponent: (projectType) => <ColorDot color={projectType.color} />,
          cellText: (projectType) => projectType.name,
        },
        {
          flex: 2,
          headerLabel: 'Payment Methods',
          cellComponent: (projectType) => {
            return projectType.paymentMethods.length > 0 ? (
              <PaymentMethodBadges
                paymentMethods={
                  PaymentMethod.getSortedPaymentMethods(
                    projectType.paymentMethods,
                  ) as PaymentMethodModel[]
                }
              />
            ) : null;
          },
        },
        {
          headerLabel: 'Actions',
          width: 80,
          cellStyle: {alignItems: 'center'},
          actions: (item) => [
            {
              text: 'Edit',
              onPress: ({handleOpen}) => handleOpen && handleOpen(),
              desktopIcon: Icon.Pen,
              actionHook: {
                hook: useDrawer,
                hookArgument: {name: 'Update Project Type Payment Methods Drawer'},
                renderComponent: ({hookKey, isOpen, handleClose}) => {
                  return (
                    <EditProjectTypePaymentMethodsDrawer
                      key={hookKey}
                      isOpen={isOpen}
                      handleClose={handleClose}
                      // The type is string
                      projectTypeId={item.id as unknown as number}
                      refetch={refetch}
                    />
                  );
                },
              },
            },
          ],
        },
      ]}
      items={projectTypes}
      itemKey={'id'}
      emptyStateText={'No project types'}
      hasBorder
    />
  );
};

const CrewPaymentMethodsPanel = ({
  index,
  viewingOrganization,
  refetch,
}: {
  index: number;
  viewingOrganization: OrganizationModel;
  refetch: () => void;
}) => {
  return (
    <ActionPanel
      index={index}
      title={'Crew App'}
      BodyComponent={CrewPaymentMethodsPanelContent}
      bodyComponentProps={{projectTypes: viewingOrganization.moveProjectTypes, refetch}}
      width={Panel.WIDTH.DEFAULT}
    />
  );
};

const PaymentMethodsSettings = ({
  viewingOrganization,
  refetch,
}: {
  viewingOrganization: OrganizationModel;
  refetch: () => void;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      <Container style={{paddingHorizontal: responsive.desktop ? 0 : 16}}>
        <SectionTitle responsive={responsive}>{`Payment Methods`}</SectionTitle>
        <Space height={16} />
        <Description
          responsive={responsive}
        >{`Configure payment methods and set which ones are enabled for the Office App and Crew App.`}</Description>
        <Space height={16} />
      </Container>
      {viewingOrganization.isOwnerOfSettings ? (
        <React.Fragment>
          <PaymentMethodsPanel
            index={0}
            viewingOrganization={viewingOrganization}
            refetch={refetch}
          />
          <Space height={16} />
          <OfficePaymentMethodsPanel
            index={1}
            viewingOrganization={viewingOrganization}
            refetch={refetch}
          />
          <Space height={16} />
          <CrewPaymentMethodsPanel
            index={2}
            viewingOrganization={viewingOrganization}
            refetch={refetch}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Callout
            text={`Payment methods are set by the company. Please contact the admins at ${viewingOrganization.company.primaryOrganization.name} to make changes.`}
            width={responsive.desktop ? Panel.WIDTH.DEFAULT : undefined}
          />
          <Space height={16} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PaymentMethodsSettings.fragment = gql`
  ${PaymentMethod.getSortedPaymentMethods.fragment}
  ${PaymentMethodBadges.fragment}
  ${PaymentMethodOptions.fragment}
  ${PaymentMethodsPanel.fragment}
  ${UpdateOfficePaymentMethodsForm.edit.fragment}
  fragment PaymentMethodsSettings on Organization {
    id
    isOwnerOfSettings
    paymentMethods {
      id
      isEnabledForOffice
      ...PaymentMethodBadges
      ...PaymentMethod_getSortedPaymentMethods
    }
    moveProjectTypes: projectTypesForCategory(category: "MOVE") {
      id
      color
      name
      paymentMethods {
        id
        name
        ...PaymentMethod_getSortedPaymentMethods
      }
    }
    company {
      id
      primaryOrganization {
        id
        name
      }
    }
    ...PaymentMethodOptions
    ...PaymentMethodsPanel
    ...UpdateOfficePaymentMethodsForm_edit
  }
`;

export default PaymentMethodsSettings;
