// Libraries
import React from 'react';

// Supermove
import {Loading, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useResponsive} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';

// App
import PageLoadingIndicator from 'modules/App/components/PageLoadingIndicator';
import OrganizationSettingsBillingSettingsPage from 'modules/Organization/Settings/Billing/components/OrganizationSettingsBillingSettingsPage';

const Container = Styled.View`
`;

const PageHeading = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;

const PageDescription = Styled.Text`
  ${Typography.Responsive.Body}
  color: ${colors.gray.secondary};
`;

const OrganizationSettingsBillingHourlyRatesPageContent = () => {
  const responsive = useResponsive();
  return (
    <Container>
      <PageHeading responsive={responsive}>Hourly Rates</PageHeading>
      <Space height={16} />
      <PageDescription responsive={responsive}>
        Create and manage hourly rates to be used in formulas.
      </PageDescription>
    </Container>
  );
};

const OrganizationSettingsBillingHourlyRatesPage = () => {
  const {loading} = useQuery(OrganizationSettingsBillingHourlyRatesPage.query, {
    fetchPolicy: 'cache-and-network',
  });
  return (
    <OrganizationSettingsBillingSettingsPage>
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <OrganizationSettingsBillingHourlyRatesPageContent />}
      </Loading>
    </OrganizationSettingsBillingSettingsPage>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsBillingHourlyRatesPage.query = gql`
  query OrganizationSettingsBillingHourlyRatesPage {
    ${gql.query}
    viewer {
      id
    }
  }
`;

export default OrganizationSettingsBillingHourlyRatesPage;
