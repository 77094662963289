// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {Typography} from '@supermove/styles';

const Text = Styled.Text`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  max-width: 100%;
`;

const CustomerChatLink = ({url, color, children}: any) => {
  return (
    <a href={url} target={'_blank'} style={{maxWidth: '100%'}}>
      <Text color={color}>{children}</Text>
    </a>
  );
};

export default CustomerChatLink;
