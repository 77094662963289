// Libraries
import React from 'react';

// App
import {Emoji, Modal, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {fontWeight, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// Components

const Container = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const Wrapper = Styled.View`
  width: ${(props) => (props as any).width}px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
  border-radius: 5px;
`;

const Circle = Styled.View`
  align-self: center;
  align-items: center;
  justify-content: center;
  top: -20px;
  height: 80px;
  width: 80px;
  border-radius: 50px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px rgba(194,194,194,0.5);
`;

const EmojiText = Styled.H1`
  margin-top: 5px;
`;

const Content = Styled.View`
  margin-horizontal: 30px;
`;

const Title = Styled.H5`
  color: ${colors.gray.primary};
  ${fontWeight(500)}
  text-align: center;
`;

const Subtitle = Styled.H6`
  margin-top: 15px;
  color: ${colors.gray.tertiary};
  text-align: center;
`;

const Actions = Styled.View`
  align-items: center;
  margin-vertical: 20px;
`;

const SubmitButton = Styled.Button`
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 100px;
`;

const SubmitText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {
  scheduleDay: any;
  trigger: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateUserDayFailureModal.defaultProps;

// @ts-expect-error TS(7022): 'UpdateUserDayFailureModal' implicitly has type 'a... Remove this comment to see the full error message
const UpdateUserDayFailureModal = ({scheduleDay, trigger}: Props) => (
  <Modal trigger={trigger}>
    {({handleClose}: any) => (
      <Container pointerEvents={'box-none'}>
        <Wrapper width={350}>
          <Circle>
            <Emoji component={EmojiText} name={'warning'} />
          </Circle>
          <Content>
            <Title>Error</Title>
            <Subtitle>
              {`You are already assigned to job(s) on ` +
                `${Datetime.convertToDisplayDate(scheduleDay.date, 'dddd, M/D')}. ` +
                `Please contact your office for help updating this day to not available.`}
            </Subtitle>
            <Actions>
              <SubmitButton onPress={handleClose}>
                <SubmitText>Ok</SubmitText>
              </SubmitButton>
            </Actions>
          </Content>
        </Wrapper>
      </Container>
    )}
  </Modal>
);

UpdateUserDayFailureModal.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateUserDayFailureModal.fragment = gql`
  fragment UpdateUserDayFailureModal on Job {
    id
  }
`;

export default UpdateUserDayFailureModal;
