// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

const Container = Styled.View`
  align-items: center;
  padding-horizontal: 12px;
  padding-vertical: 8px;
  border-radius: 5px;
  background-color: ${(props) => colors.alpha((props as any).color, 0.15)};
`;

const Primary = Styled.H7`
  ${fontWeight(700)}
  color: ${(props) => (props as any).color};
`;

const DispatchJobDrivingStatusBadge = ({job, style}: any) => {
  const color = Job.getDrivingPrimaryStatusColor(job);
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Container color={color} style={style}>
      <Primary color={color}>{Job.getDrivingStatusText(job)}</Primary>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DispatchJobDrivingStatusBadge.fragment = gql`
  ${Job.getDrivingPrimaryStatusColor.fragment}
  ${Job.getDrivingStatusText.fragment}
  fragment DispatchJobDrivingStatusBadge on Job {
    id
    ...Job_getDrivingPrimaryStatusColor
    ...Job_getDrivingStatusText
  }
`;

export default DispatchJobDrivingStatusBadge;
