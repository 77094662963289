// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, useNavigationDOM, useResponsive} from '@supermove/hooks';
import {Project} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import WarningModal from '@shared/design/components/Modal/SmallModal/WarningModal';
import ProjectJobsForm from '@shared/modules/Project/forms/ProjectJobsForm';
import OfficeHeader, {OfficeHeaderBuilder} from 'modules/App/components/OfficeHeader';

const MobileHeaderRow = Styled.View`
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-vertical: 12px;
`;

const MobileHeaderSideColumn = Styled.View`
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const MobileHeaderText = Styled.Text`
  ${Typography.Responsive.Heading2}
`;

const DesktopHeader = ({
  project,
  handleSubmit,
  submitting,
  form,
  field,
  exitEditProjectJobsModal,
  navigator,
}: any) => {
  return (
    <OfficeHeaderBuilder.Content style={{flex: 1, padding: 24}}>
      <TertiaryButton onPress={form.dirty ? exitEditProjectJobsModal.handleOpen : navigator.goBack}>
        <Icon source={Icon.XmarkLarge} size={22} color={colors.gray.primary} />
      </TertiaryButton>
      <Space width={16} />
      <OfficeHeaderBuilder.Title>{`Edit Jobs for ${Project.getName(project)}`}</OfficeHeaderBuilder.Title>
      <Space style={{flex: 1}} />
      <Button
        text={'Save Changes'}
        iconLeft={Icon.Check}
        isSubmitting={submitting}
        onPress={() => {
          const projectForm = _.get(form.values, field);
          form.setFieldValue(field, ProjectJobsForm.handleCleanupForm(projectForm));
          setTimeout(handleSubmit, 0);
        }}
      />
    </OfficeHeaderBuilder.Content>
  );
};

const MobileHeader = ({form, exitEditProjectJobsModal, navigator, responsive, isLoading}: any) => {
  return (
    <MobileHeaderRow>
      <Space width={16} />
      <MobileHeaderSideColumn />
      <Space style={{flex: 1}} />
      <MobileHeaderText responsive={responsive}>{`Edit Jobs`}</MobileHeaderText>
      <Space style={{flex: 1}} />
      <MobileHeaderSideColumn>
        <TertiaryButton
          onPress={
            isLoading || !form.dirty ? navigator.goBack : exitEditProjectJobsModal.handleOpen
          }
          isHitSlop
        >
          <Icon source={Icon.XmarkLarge} size={16} color={colors.gray.primary} />
        </TertiaryButton>
      </MobileHeaderSideColumn>
      <Space width={16} />
    </MobileHeaderRow>
  );
};

const EditProjectJobsHeader = ({
  project,
  handleSubmit,
  submitting,
  form,
  field,
  isLoading,
}: any) => {
  const responsive = useResponsive();
  const {navigator} = useNavigationDOM();
  const exitEditProjectJobsModal = useModal({name: 'Exit Edit Project Jobs Modal'});

  return (
    <OfficeHeader>
      {responsive.desktop ? (
        <DesktopHeader
          project={project}
          handleSubmit={handleSubmit}
          submitting={submitting}
          form={form}
          field={field}
          exitEditProjectJobsModal={exitEditProjectJobsModal}
          navigator={navigator}
        />
      ) : (
        <MobileHeader
          form={form}
          exitEditProjectJobsModal={exitEditProjectJobsModal}
          navigator={navigator}
          responsive={responsive}
          isLoading={isLoading}
        />
      )}
      <WarningModal
        title={'Exit this page?'}
        message={'Any unsaved changes will be deleted.'}
        isOpen={exitEditProjectJobsModal.isOpen}
        primaryActionText={'Exit Page'}
        handlePrimaryAction={navigator.goBack}
        secondaryActionText={'Cancel'}
        handleSecondaryAction={exitEditProjectJobsModal.handleClose}
      />
    </OfficeHeader>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EditProjectJobsHeader.fragment = gql`
  ${Project.getName.fragment}

  fragment EditProjectJobsHeader on Project {
    id
    ...Project_getName
  }
`;

export default EditProjectJobsHeader;
