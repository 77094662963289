// Libraries
import React from 'react';

// Supermove
import {ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Job} from '@supermove/models';
import {fontWeight, colors} from '@supermove/styles';

// App
import {Panel, SplitPage} from 'modules/App/components';
import {CrewJobUsersList} from 'modules/Employee/Job/Crew/components';
import {CrewJobStatusBanner, JobPageWithNavigation, JobTabs} from 'modules/Employee/Job/components';

const Container = Styled.View`
  flex: 1;
`;

const Content = Styled.View`
  margin-horizontal: 20px;
  margin-bottom: 30px;
`;

const Header = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.H4`
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Subtitle = Styled.H6`
  color: ${colors.gray.primary};
`;

const Line = Styled.View`
  margin-vertical: 20px;
  height: 1px;
  background-color: ${colors.blue.accent};
`;

const CrewJobPage = ({
  match: {
    params: {uuid},
  },
}: any) => (
  <JobPageWithNavigation
    variables={{uuid}}
    query={CrewJobPage.query}
    title={({loading, data}: any) => (loading ? 'Loading...' : Job.getFullName(data.job))}
    subtitle={() => 'View crew members'}
    onClose={({navigator}: any) => navigator.goBack()}
  >
    {({responsive, data}: any) => (
      <SplitPage split={<Panel />}>
        <Container>
          <JobTabs selected={'crew'} job={data.job} />
          <ScrollView style={{flex: 1}}>
            <CrewJobStatusBanner job={data.job} />
            <Content {...responsive}>
              <Header>
                <Title>{`${Job.getFullName(data.job)}: Crew`}</Title>
                <Subtitle>View crew members assigned to this job.</Subtitle>
              </Header>
              <Line />
              <CrewJobUsersList job={data.job} />
            </Content>
          </ScrollView>
        </Container>
      </SplitPage>
    )}
  </JobPageWithNavigation>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CrewJobPage.query = gql`
  ${JobPageWithNavigation.fragment}
  ${JobTabs.fragment}
  ${CrewJobStatusBanner.fragment}
  ${CrewJobUsersList.fragment}
  ${Job.getFullName.fragment}

  query CrewJobPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobPageWithNavigation
      ...JobTabs
      ...CrewJobStatusBanner
      ...CrewJobUsersList
      ...Job_getFullName
    }
  }
`;

export default CrewJobPage;
