// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';

// App
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import ClientCreditCardInformationFields from 'modules/Client/components/ClientCreditCardInformationFields';

const Container = Styled.View`
`;

const getIsCurrentDefaultCard = ({client, form, field}: any) => {
  const currentDefaultCard = _.find(client.creditCards, {isDefault: true});
  return (
    currentDefaultCard &&
    _.toString(currentDefaultCard.id) ===
      _.toString(_.get(form.values, `${field}.payengineCreditCardId`))
  );
};

const NicknameField = ({form, field}: any) => {
  return (
    <FieldInput
      {...form}
      name={`${field}.nickname`}
      label={'Card Nickname'}
      isResponsive
      input={{
        placeholder: 'Enter card nickname',
      }}
    />
  );
};

const SetDefaultFieldTooltipWrapper = ({form, field, client, children}: any) => {
  const isFirstCard = _.isEmpty(client.creditCards);

  if (isFirstCard) {
    return (
      <TextTooltip
        isEnabledMobileToast={false}
        placement={'left'}
        text={'The first card added will be set as the default card'}
      >
        {children}
      </TextTooltip>
    );
  }

  if (getIsCurrentDefaultCard({client, form, field})) {
    return (
      <TextTooltip
        isEnabledMobileToast={false}
        placement={'left'}
        text={'Cannot unset the default card'}
      >
        {children}
      </TextTooltip>
    );
  }

  return children;
};

const SetDefaultField = ({form, field, client}: any) => {
  const isFirstCard = _.isEmpty(client.creditCards);
  const isDefault = _.get(form.values, `${field}.isDefault`);
  const isCurrentDefaultCard = getIsCurrentDefaultCard({client, form, field});

  return (
    <SetDefaultFieldTooltipWrapper form={form} field={field} client={client}>
      <Container>
        <Checkbox
          isChecked={isDefault || isFirstCard}
          label={'Set as default card'}
          // @ts-expect-error TS(2322): Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
          hint={isFirstCard ? 'The first card is automatically the default card.' : null}
          handleToggle={() => form.setFieldValue(`${field}.isDefault`, !isDefault)}
          isDisabled={isCurrentDefaultCard || isFirstCard}
          isResponsive
        />
      </Container>
    </SetDefaultFieldTooltipWrapper>
  );
};

const ClientCreditCardFields = ({client, form, field, setCreditCardClient}: any) => {
  return (
    <React.Fragment>
      <NicknameField form={form} field={field} />
      {setCreditCardClient && (
        <React.Fragment>
          <Space height={16} />
          <ClientCreditCardInformationFields
            client={client}
            setCreditCardClient={setCreditCardClient}
          />
        </React.Fragment>
      )}
      <Space height={16} />
      <SetDefaultField form={form} field={field} client={client} />
    </React.Fragment>
  );
};

ClientCreditCardFields.fragment = gql`
  ${ClientCreditCardInformationFields.fragment}

  fragment ClientCreditCardFields on Client {
    id
    creditCards {
      id
      isDefault
    }
    ...ClientCreditCardInformationFields
  }
`;

export default ClientCreditCardFields;
