// Libraries
import React from 'react';

import {gql} from '@supermove/graphql';

// Components
import {PrintDocumentPage} from 'modules/Document/Print/components';
import {JobChangeOrderDocument} from 'modules/Document/components';

const CustomerChangeOrderPage = ({
  match: {
    params: {slug, uuid},
  },
}: any) => (
  <PrintDocumentPage query={CustomerChangeOrderPage.query} variables={{uuid}}>
    {({data}: any) => <JobChangeOrderDocument job={data.job} />}
  </PrintDocumentPage>
);

// --------------------------------------------------
// Data
// --------------------------------------------------
CustomerChangeOrderPage.query = gql`
  ${JobChangeOrderDocument.fragment}

  query CustomerChangeOrderPage($uuid: String!) {
    ${gql.query}
    job(uuid: $uuid) {
      id
      ...JobChangeOrderDocument
    }
  }
`;

export default CustomerChangeOrderPage;
