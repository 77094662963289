// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Checkbox, Styled, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive, useToast} from '@supermove/hooks';
import {JobUser} from '@supermove/models';
import {colors, Typography} from '@supermove/styles';
import {pluralize} from '@supermove/utils';

// App
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import useSendJobDetailsToSpecificJobUsersForm from 'modules/Day/Jobs/logic/useSendJobDetailsToSpecificJobUsersForm';

const Section = Styled.View`
  padding-horizontal: ${(props) => ((props as any).mobile ? 20 : 30)}px;
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const Title = Styled.Text`
  ${Typography.Heading1};
`;

const Subtitle = Styled.Text`
  ${Typography.Body}
`;

const Table = Styled.View`
  width: 100%;
`;

const Row = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Cell = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: ${(props) => ((props as any).small ? '100' : '200')}px;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
`;

const HeaderText = Styled.Text`
  ${Typography.Label}
`;

const Button = Styled.LoadingButton`
  height: 30px;
`;

const ButtonText = Styled.Text`
  ${Typography.Label}
  color: ${colors.white};
`;

const Text = Styled.Text`
  ${Typography.Body}
  color: ${colors.gray.primary};
`;

const getPendingJobUsers = (jobUsers: any) => {
  return jobUsers.filter(
    (jobUser: any) => jobUser.status !== 'REMOVED' && jobUser.status !== 'CONFIRMED',
  );
};

const addIdToJobUserIds = ({id, jobUserIds}: any) => {
  return _.uniq([...jobUserIds, id]);
};

const removeIdFromJobUserIds = ({id, jobUserIds}: any) => {
  return _.filter(jobUserIds, (jobUserId) => jobUserId !== id);
};

const JobUserRow = ({jobUser, isChecked, handleSelect}: any) => {
  return (
    <Row>
      <Cell
        style={{
          width: 40,
        }}
      >
        <Checkbox checked={isChecked} onChange={handleSelect} />
      </Cell>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Cell small>
        <Text>{JobUser.getDisplayStatus(jobUser)}</Text>
      </Cell>
      <Cell>
        <Text>{jobUser.user.firstName}</Text>
      </Cell>
      <Cell>
        <Text>{jobUser.position}</Text>
      </Cell>
    </Row>
  );
};

const SendTextTable = ({form, jobUsers}: any) => {
  const responsive = useResponsive();

  const handleSelectJobUserId = (id: any) => {
    const {jobUserIds} = form.values;
    if (jobUserIds.includes(id)) {
      // Job User with the jobUserId exists, so we remove it.
      form.setFieldValue('jobUserIds', removeIdFromJobUserIds({id, jobUserIds}));
    } else {
      // Job User with the jobUserId does not exist, so we add it.
      form.setFieldValue('jobUserIds', addIdToJobUserIds({id, jobUserIds}));
    }
  };

  return (
    <ScrollView
      horizontal
      contentContainerStyle={{
        minWidth: '100%',
        paddingHorizontal: responsive.mobile ? 20 : 30,
      }}
      style={{
        alignSelf: 'stretch',
      }}
    >
      <Table>
        <Row {...responsive}>
          <Cell
            style={{
              width: 40,
            }}
          />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Cell small>
            <HeaderText>Status</HeaderText>
          </Cell>
          <Cell>
            <HeaderText>Name</HeaderText>
          </Cell>
          <Cell>
            <HeaderText>Position</HeaderText>
          </Cell>
        </Row>
        {jobUsers.map((jobUser: any) => {
          return (
            <JobUserRow
              jobUser={jobUser}
              key={jobUser.id}
              isChecked={form.values.jobUserIds.includes(jobUser.id)}
              handleSelect={() => handleSelectJobUserId(jobUser.id)}
            />
          );
        })}
      </Table>
    </ScrollView>
  );
};

type OwnSendJobDetailsToSpecificUsersModalContentProps = {};

// @ts-expect-error TS(2456): Type alias 'SendJobDetailsToSpecificUsersModalCont... Remove this comment to see the full error message
type SendJobDetailsToSpecificUsersModalContentProps =
  OwnSendJobDetailsToSpecificUsersModalContentProps &
    typeof SendJobDetailsToSpecificUsersModalContent.defaultProps;

// @ts-expect-error TS(7022): 'SendJobDetailsToSpecificUsersModalContent' implic... Remove this comment to see the full error message
const SendJobDetailsToSpecificUsersModalContent = ({
  job,
  onClose,
  viewerId,
}: SendJobDetailsToSpecificUsersModalContentProps) => {
  const responsive = useResponsive();
  const textMessagesSentToast = useToast({
    ToastComponent: SuccessToast,
    message: 'SMS sent!',
  });
  const {form, submitting, handleSubmit} = useSendJobDetailsToSpecificJobUsersForm({
    jobId: job.id,

    // Pending users will be default selected.
    jobUserIds: getPendingJobUsers(job.jobUsers).map((jobUser: any) => jobUser.id),
    creatorId: viewerId || job.creatorId,
    onSuccess: () => {
      onClose();
      textMessagesSentToast.handleToast();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const getSendText = (form: any) => {
    const countSelectedCrew = form.values.jobUserIds.length;
    if (countSelectedCrew === 0) {
      return `No crew selected`;
    } else {
      return `Send to ${pluralize('crew member', countSelectedCrew, true)} `;
    }
  };

  const sortedJobUsers = _.sortBy(job.jobUsers, [`user.fullName`]);

  return (
    <React.Fragment>
      <ScrollView
        contentContainerStyle={{
          paddingBottom: 40,
        }}
      >
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <Section {...responsive} sectionIndex={0}>
          <Title>Send text with job details</Title>
          <Subtitle>Select which crew members you want to text.</Subtitle>
        </Section>
        <SectionSpace />
        <Section
          {...responsive}
          // @ts-expect-error TS(2769): No overload matches this call.
          sectionIndex={1}
          style={{
            paddingHorizontal: 0,
          }}
        >
          <SendTextTable
            form={form}
            jobUsers={JobUser.getJobUsersForJob({job, jobUsers: sortedJobUsers})}
          />
        </Section>
      </ScrollView>
      <Section
        {...responsive}
        // @ts-expect-error TS(2769): No overload matches this call.
        sectionIndex={2}
        style={{
          paddingVertical: 20,
          boxShadow: '0 -2px 2px rgba(0,0,0,0.1)',
        }}
      >
        <Button
          disabled={form.values.jobUserIds.length === 0}
          loading={submitting}
          onPress={handleSubmit}
        >
          <ButtonText>{getSendText(form)}</ButtonText>
        </Button>
      </Section>
    </React.Fragment>
  );
};

SendJobDetailsToSpecificUsersModalContent.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
SendJobDetailsToSpecificUsersModalContent.fragment = gql`
  ${JobUser.getDisplayStatus.fragment}

  fragment SendJobDetailsToSpecificUsersModalContent on Job {
    id
    creatorId
    jobUsers: activeJobUsers {
      id
      status
      position
      user {
        id
        firstName
        fullName
      }
      ...JobUser_getDisplayStatus
    }
  }
`;

export default SendJobDetailsToSpecificUsersModalContent;
