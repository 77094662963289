// Libraries
import React from 'react';

// Supermove
import {Styled, Space, ScrollView} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useHover, useState} from '@supermove/hooks';
import {Typography, colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SearchBar from '@shared/design/components/SearchBar';
import Line from 'modules/App/components/Line';

const HeaderContainer = Styled.View`
  flex-direction: row;
  padding: 12px 16px;
`;

const FooterContainer = Styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 16px;
`;

const HeaderText = Styled.Text`
  ${Typography.Label1}
`;

const Container = Styled.View`
`;

const DriverButton = Styled.ButtonV2<{isHovered: boolean; isSelected: boolean}>`
  padding: 12px;
  background-color: ${({isHovered, isSelected}) => getDriverBlockBackgroundColor({isHovered, isSelected})};
  cursor: pointer;
`;

const DriverText = Styled.Text`
  ${Typography.Body3}
`;

const getDriverBlockBackgroundColor = ({isHovered, isSelected}: any) => {
  if (isSelected) {
    return colors.blue.interactive;
  }
  if (isHovered) {
    return colors.hover;
  }
  return colors.white;
};

const DriverBlock = ({driver, form}: any) => {
  const {ref, isHovered} = useHover();
  const currentSelectedDriverId = form.values.assignDriverAndTrucksToTripForm.driverId;
  return (
    <DriverButton
      ref={ref}
      isHovered={isHovered}
      isSelected={currentSelectedDriverId === driver.id}
      onPress={() => {
        if (currentSelectedDriverId === driver.id) {
          form.setFieldValue('assignDriverAndTrucksToTripForm.driverId', null);
        } else {
          form.setFieldValue('assignDriverAndTrucksToTripForm.driverId', driver.id);
        }
      }}
    >
      <DriverText>{driver.fullName}</DriverText>
    </DriverButton>
  );
};

const Drivers = ({searchTerm, drivers, form}: any) => {
  const filteredDrivers = searchTerm
    ? drivers.filter((driver: any) =>
        driver.fullName.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : drivers;
  return (
    <Container>
      {filteredDrivers.map((driver: any) => (
        <DriverBlock key={driver.id} driver={driver} form={form} />
      ))}
    </Container>
  );
};

const Footer = ({assignDriverAndTrucksPopover, handleSubmit}: any) => {
  return (
    <FooterContainer>
      <QuaternaryButton
        width={80}
        text={'Cancel'}
        onPress={assignDriverAndTrucksPopover.handleClose}
      />
      <Space width={16} />
      <Button width={76} text={'Assign'} onPress={handleSubmit} />
    </FooterContainer>
  );
};

const AssignDriverPopoverContent = ({
  assignDriverAndTrucksPopover,
  form,
  activeDrivers,
  handleSubmit,
}: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Container style={{flex: 1}}>
      <HeaderContainer>
        <HeaderText>Assign Driver to Trip</HeaderText>
      </HeaderContainer>
      <Line />
      <Container style={{padding: 12, flex: 1}}>
        <SearchBar
          style={{width: '100%'}}
          placeholder={'Search by driver name'}
          onChangeText={setSearchTerm}
        />
        <Space height={12} />
        <ScrollView style={{maxHeight: 190}}>
          <Drivers drivers={activeDrivers} form={form} searchTerm={searchTerm} />
        </ScrollView>
      </Container>
      <Footer
        assignDriverAndTrucksPopover={assignDriverAndTrucksPopover}
        handleSubmit={handleSubmit}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AssignDriverPopoverContent.fragment = gql`
  fragment AssignDriverPopoverContent on Organization {
    activeDrivers {
      id
      fullName
    }
  }
`;

export default AssignDriverPopoverContent;
