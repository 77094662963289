// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {MultiDropdownInput} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import MultiDropdownCheckboxInput from '@shared/design/components/Field/MultiDropdownCheckboxInput';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';

const ALL_ORGANIZATIONS = 'ALL_ORGANIZATIONS';

type OwnProps = {
  value?: any[];
  organizations?: any[];
  onChangeValue?: (...args: any[]) => any;
  noBranchOrganizations?: boolean;
  label?: string;
  isResponsive?: boolean;
  placeholder?: string;
  width?: string;
  isPortaled?: boolean;
  index?: number;
  disableAllOption?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof MultiBranchOrganizationField.defaultProps;

// @ts-expect-error TS(7022): 'MultiBranchOrganizationField' implicitly has type... Remove this comment to see the full error message
const MultiBranchOrganizationField = ({
  value,
  organizations,
  onChangeValue,
  noBranchOrganizations,
  label,
  width,
  isPortaled,
  isResponsive,
  index,
  disableAllOption,
  placeholder,
}: Props) => {
  const {params} = useNavigationDOM();

  const filteredOrganizations = organizations.filter((organization: any) => {
    return noBranchOrganizations
      ? ![OrganizationKind.BRANCH, OrganizationKind.CONTRACTOR].includes(organization.kind)
      : true;
  });

  const options = _.sortBy(
    [
      !disableAllOption && {
        label: 'All Branches',
        value: ALL_ORGANIZATIONS,
      },
      ...filteredOrganizations.map((organization: any) => ({
        label: organization.name,
        value: organization.slug,
      })),
    ],
    'label',
    // Filter out null if disableAllOption is selected
  ).filter((option) => !!option);

  return (
    <FieldInput
      style={{width}}
      component={disableAllOption ? MultiDropdownInput : MultiDropdownCheckboxInput}
      label={label}
      index={index}
      isResponsive={isResponsive}
      input={{
        isPortaled,
        value,
        style: {width: '100%'},
        options,
        placeholder: placeholder ?? 'Select branch(es)',
        onChangeValue: (newSlugs: any) => {
          // If all is selected and they select a different org, remove all
          const previousSlugs = params.slugs || [];
          if (previousSlugs.includes(ALL_ORGANIZATIONS)) {
            newSlugs = _.filter(newSlugs, (slug) => slug !== ALL_ORGANIZATIONS);
          }
          // If they select all, remove all other orgs, or if they remove everything default to all
          if (
            (!previousSlugs.includes(ALL_ORGANIZATIONS) && newSlugs.includes(ALL_ORGANIZATIONS)) ||
            newSlugs.length === 0
          ) {
            newSlugs = [ALL_ORGANIZATIONS];
          }
          onChangeValue(newSlugs);
        },
      }}
    />
  );
};

MultiBranchOrganizationField.defaultProps = {
  value: [],
  organizations: [],
  onChangeValue: () => {},
  noBranchOrganizations: false,
  placeholder: undefined,
  label: null,
  isResponsive: false,
  width: '200px',
  isPortaled: true,
  index: 0,
  disableAllOption: false,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
MultiBranchOrganizationField.fragment = gql`
  fragment MultiBranchOrganizationField on Organization {
    id
    company {
      id
      organizations {
        id
        name
        slug
        isPrimary
        kind
      }
    }
  }
`;

export default MultiBranchOrganizationField;
