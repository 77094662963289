// Libraries
import React from 'react';

// App
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const SendVirtualWalkthroughSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  handleSubmitValidateWorkflow,
}: any) => {
  const {label, description} =
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.SEND_VIRTUAL_WALKTHROUGH];

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />
  );
};

const SendVirtualWalkthroughDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  isPreview,
}: any) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={'Send virtual walkthrough SMS'}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepSendEmailFromTemplate = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    workflow,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => [
    <SendVirtualWalkthroughSlide
      key={'CREATE'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    />,
    <SendVirtualWalkthroughDisplaySlide
      key={'DISPLAY'}
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      workflow={workflow}
      isPreview={isPreview}
    />,
  ],
};

export default WorkflowStepSendEmailFromTemplate;
