// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors} from '@supermove/styles';
import {titleize} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Callout from '@shared/design/components/Callout';
import Panel from '@shared/design/components/Panel';
import EditPanel from '@shared/design/components/Panel/EditPanel';
import Switch from '@shared/design/components/Switch';
import ProjectStatus from '@shared/modules/Project/enums/ProjectStatus';

const Column = Styled.View`
  flex: 1;
`;

const Row = Styled.View`
  flex-direction: row;
`;

const getStatusLabel = (status: any) => titleize(status.replace('_', ' '));

const getStatusColor = (status: any) => {
  switch (status) {
    case ProjectStatus.LEAD:
    case ProjectStatus.HOLD:
      return colors.orange.status;
    case ProjectStatus.BOOKED:
    default:
      return colors.blue.interactive;
  }
};

const getStatusIcon = (status: any) => {
  switch (status) {
    case ProjectStatus.LEAD:
      return Icon.Trophy;
    case ProjectStatus.HOLD:
      return Icon.CalendarClock;
    case ProjectStatus.BOOKED:
    default:
      return Icon.CalendarCheck;
  }
};

const CalendarStatusesPanelHeader = ({isEditing, handleEdit, isViewingChildBranch}: any) => (
  <Panel.Header style={{alignItems: 'center'}}>
    <Column>
      <Row>
        <Panel.HeaderText>Statuses</Panel.HeaderText>
        <Space style={{flex: 1, minWidth: 12}} />
        {!isViewingChildBranch && (
          // @ts-expect-error TS(2741): Property 'isDisabled' is missing in type '{ isEdit... Remove this comment to see the full error message
          <EditPanel.EditButton isEditing={isEditing} handleEdit={handleEdit} />
        )}
      </Row>
      <Space height={8} />
      <Panel.HeaderDescription>
        {`Projects with these statuses will be shown on the calendars.`}
      </Panel.HeaderDescription>
    </Column>
  </Panel.Header>
);

const CalendarStatusesPanelEdit = ({form, field}: any) => {
  const statuses = _.get(form.values, field);
  return (
    <React.Fragment>
      {[ProjectStatus.LEAD, ProjectStatus.HOLD, ProjectStatus.BOOKED].map((status, index) => {
        return (
          <React.Fragment key={index}>
            <Switch
              isOn={_.includes(statuses, status)}
              onChange={() => {
                const existingStatuses = [...statuses];
                const updatedStatuses = _.xor(existingStatuses, [status]);
                form.setFieldValue(field, updatedStatuses);
              }}
              labelRight={getStatusLabel(status)}
            />
            <Space height={8} />
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const CalendarStatusesPanel = ({organization, isViewingChildBranch}: any) => {
  return isViewingChildBranch ? (
    <Callout
      text={
        'You are viewing a branch. Please contact the admins at the main branch to make changes.'
      }
    />
  ) : (
    <Row style={{flexWrap: 'wrap'}}>
      {organization.settings.visibleCalendarStatuses.map((status: any, index: any) => (
        <Row key={index}>
          <Badge
            label={getStatusLabel(status)}
            color={getStatusColor(status)}
            iconLeft={getStatusIcon(status)}
            iconSize={12}
          />
          <Space width={8} />
        </Row>
      ))}
    </Row>
  );
};

CalendarStatusesPanel.Edit = CalendarStatusesPanelEdit;
CalendarStatusesPanel.Header = CalendarStatusesPanelHeader;

// --------------------------------------------------
// Data
// --------------------------------------------------
CalendarStatusesPanel.fragment = gql`
  fragment CalendarStatusesPanel on Organization {
    id
    settings {
      id
      visibleCalendarStatuses
    }
  }
`;

export default CalendarStatusesPanel;
