// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Loading, Styled} from '@supermove/components';
import {generated, gql, useQuery} from '@supermove/graphql';
import {useEffect, useModal, useResponsive, useState} from '@supermove/hooks';
import {Dashboard} from '@supermove/models';
import {Typography} from '@supermove/styles';

// App

import ExploDashboard, {DashboardEvent} from '@shared/modules/Reports/components/ExploDashboard';
import UserRole from '@shared/modules/User/enums/UserRole';
import Line from 'modules/App/components/Line';
import useAppContext from 'modules/App/context/useAppContext';
import GlobalDashboardsLibraryPageUpdateDashboardDrawer from 'modules/Report/Library/components/GlobalDashboardsLibraryPageUpdateDashboardDrawer';
import DashboardsPageEditDashboardModal from 'modules/Report/components/DashboardsPageEditDashboardModal';
import DashboardsPageRemoveDashboardModal from 'modules/Report/components/DashboardsPageRemoveDashboardModal';
import DashboardsPageResetDashboardToDefaultModal from 'modules/Report/components/DashboardsPageResetDashboardToDefaultModal';
import DashboardsPageUpdateDashboardVariablesModal from 'modules/Report/components/DashboardsPageUpdateDashboardVariablesModal';
import DashboardsPageViewPanelHeader from 'modules/Report/components/DashboardsPageViewPanelHeader';

const Container = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const HeaderContainer = Styled.View`
  width: 100%;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 16px;
  padding-horizontal: 24px;
`;

const ExploContainer = Styled.View`
  flex: 1;
  width: 100%;
  flex-direction: column;
`;

const NoteText = Styled.Text`
  ${Typography.Responsive.Micro}
  padding-horizontal: 24px;
  padding-vertical: 8px;
`;

type DashboardsPageViewPanelProps = {
  selectedDashboardUuid: string;
  handleRemove: () => void;
};

const DashboardsPageViewPanel = ({
  selectedDashboardUuid,
  handleRemove,
}: DashboardsPageViewPanelProps) => {
  const responsive = useResponsive();

  const editDashboardModal = useModal({name: 'Dashboards Page Edit Dashboard Modal'});
  const removeDashboardModal = useModal({name: 'Dashboards Page Remove Dashboard Modal'});
  const resetToDefaultDashboardModal = useModal({
    name: 'Dashboards Page Reset To Default Dashboard Modal',
  });
  const updateViewDashboardModal = useModal({name: 'Dashboards Page Update View Dashboard Modal'});

  const {data, loading, refetch} = useQuery<generated.DashboardsPageViewPanelQuery>(
    DashboardsPageViewPanel.query,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        uuid: selectedDashboardUuid,
      },
    },
  );

  const {viewer} = useAppContext();
  const isSuperAdminEditingCustom =
    UserRole.SUPER_ADMIN === viewer?.role && !data?.dashboard?.globalDashboardId;

  const [newExploVariables, setNewExploVariables] = useState<Record<string, any>>(
    JSON.parse(data?.dashboard?.exploVariables || '{}'),
  );

  useEffect(() => {
    if (data) {
      setNewExploVariables(JSON.parse(data.dashboard?.exploVariables || '{}'));
    }
  }, [data]);

  const handleExploMessage = (event: DashboardEvent) => {
    if (event.event === 'sendVariableUpdatedEvent') {
      const existingVariables = JSON.parse(data?.dashboard?.exploVariables || '{}');
      setNewExploVariables((prev) => ({
        ...existingVariables,
        ...prev,
        [event.detail.varName]: event.detail.newValue,
      }));
    }
  };

  return (
    <Loading loading={loading || !data}>
      {() =>
        data &&
        data.dashboard && (
          <React.Fragment>
            <Container>
              <HeaderContainer>
                <DashboardsPageViewPanelHeader
                  dashboard={data.dashboard}
                  isEnabledOfficeReportsUpdateAndResetView={
                    data.dashboard.organization?.features
                      ?.isEnabledOfficeReportsUpdateAndResetView ?? false
                  }
                  isNewExploVariables={Dashboard.isVariablesChanged(
                    data.dashboard,
                    newExploVariables,
                  )}
                  handleEdit={editDashboardModal.handleOpen}
                  handleRemove={removeDashboardModal.handleOpen}
                  handleResetToDefault={resetToDefaultDashboardModal.handleOpen}
                  handleUpdateView={updateViewDashboardModal.handleOpen}
                />
              </HeaderContainer>
              <Line />
              {!responsive.desktop && (
                <React.Fragment>
                  <NoteText responsive={responsive}>Tap report to scroll and preview.</NoteText>
                </React.Fragment>
              )}
              <ExploContainer style={responsive.desktop ? {padding: 24} : {}}>
                <ExploDashboard
                  name={data.dashboard.name ?? ''}
                  viewerId={_.toNumber(data.dashboard.organization?.id)}
                  exploDashboardEmbedId={data.dashboard.exploDashboardEmbedId ?? ''}
                  exploUserGroupToken={data.dashboard.organization?.exploUserGroupToken ?? ''}
                  exploVariables={data.dashboard.exploVariables ?? '{}'}
                  style={{
                    flex: 1,
                    height: responsive.desktop ? undefined : 564,
                  }}
                  onMessage={handleExploMessage}
                />
              </ExploContainer>
            </Container>
            <GlobalDashboardsLibraryPageUpdateDashboardDrawer
              isOpen={isSuperAdminEditingCustom && editDashboardModal.isOpen}
              dashboardUuid={selectedDashboardUuid}
              handleClose={editDashboardModal.handleClose}
              refetch={refetch}
            />
            <DashboardsPageEditDashboardModal
              dashboard={data.dashboard}
              isOpen={!isSuperAdminEditingCustom && editDashboardModal.isOpen}
              handleClose={editDashboardModal.handleClose}
              refetch={refetch}
            />
            <DashboardsPageRemoveDashboardModal
              dashboard={data.dashboard}
              isOpen={removeDashboardModal.isOpen}
              handleClose={removeDashboardModal.handleClose}
              handleRemove={handleRemove}
            />
            <DashboardsPageResetDashboardToDefaultModal
              dashboardId={data.dashboard.id}
              isOpen={resetToDefaultDashboardModal.isOpen}
              handleClose={resetToDefaultDashboardModal.handleClose}
              refetch={refetch}
            />
            <DashboardsPageUpdateDashboardVariablesModal
              dashboard={data.dashboard}
              newExploVariables={JSON.stringify(newExploVariables)}
              isOpen={updateViewDashboardModal.isOpen}
              handleClose={updateViewDashboardModal.handleClose}
              refetch={refetch}
            />
          </React.Fragment>
        )
      }
    </Loading>
  );
};

DashboardsPageViewPanel.query = gql`
  ${DashboardsPageViewPanelHeader.fragment}
  ${DashboardsPageEditDashboardModal.fragment}
  ${DashboardsPageRemoveDashboardModal.fragment}
  ${DashboardsPageUpdateDashboardVariablesModal.fragment}
  ${Dashboard.isVariablesChanged.fragment}
  query DashboardsPageViewPanel ($uuid: String!) {
    ${gql.query}
    dashboard(dashboardUuid: $uuid) { 
      id
      name
      globalDashboardId
      exploDashboardEmbedId
      exploVariables
      organization {
        id
        exploUserGroupToken
        features {
          isEnabledOfficeReportsUpdateAndResetView: isEnabled(feature: "OFFICE_REPORTS_UPDATE_AND_RESET_VIEW")
        }
      }
      ...DashboardsPageViewPanelHeaderFragment
      ...DashboardsPageEditDashboardModalFragment
      ...DashboardsPageRemoveDashboardModalFragment
      ...DashboardsPageUpdateDashboardVariablesModal
      ...Dashboard_isVariablesChanged
    }
  }
`;

export default DashboardsPageViewPanel;
