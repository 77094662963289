// Libararies
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {useModal, useState} from '@supermove/hooks';

// App
import InvoiceForm from '@shared/modules/Billing/forms/InvoiceForm';
import useUpdateInvoiceMutation from '@shared/modules/Billing/hooks/useUpdateInvoiceMutation';
import InvoicePageContent from 'modules/Storage/components/InvoicePageContent';

const UpdateInvoicePageContent = ({invoice, viewer}: any) => {
  const invoiceForm = InvoiceForm.edit(invoice);

  const invoiceSuccessModal = useModal({
    name: 'Invoice Success Modal',
    enableTracking: true,
  });

  const {form, submitting, handleSubmit} = useUpdateInvoiceMutation({
    invoiceForm,
    onSuccess: () => {
      invoiceSuccessModal.handleOpen();
    },
    onError: (errors: any) => console.log({errors}),
  });

  const [draftButtonSubmitting, setDraftButtonSubmitting] = useState(true);

  const field = 'invoiceForm';

  return (
    <InvoicePageContent
      form={form}
      field={field}
      project={invoice.project}
      viewer={viewer}
      handleSubmit={handleSubmit}
      invoiceSuccessModal={invoiceSuccessModal}
      invoice={invoice}
      isSubmitting={submitting}
      draftButtonSubmitting={draftButtonSubmitting}
      setDraftButtonSubmitting={setDraftButtonSubmitting}
      isEdit
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateInvoicePageContent.fragment = gql`
  ${InvoicePageContent.fragment}
  ${InvoiceForm.edit.fragment}

  fragment UpdateInvoicePageContent on Invoice {
    id
    project {
      identifier
      ...InvoicePageContent
    }
    identifier
    ...InvoiceForm_edit
  }
`;

export default UpdateInvoicePageContent;
