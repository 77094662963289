// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';
import {Datetime} from '@supermove/utils';

const useSignInventoryConfirmationForm = ({confirmation, onSuccess, onError}: any) => {
  const form = useForm({
    initialValues: {
      isFinished: false,
      isSignatureValid: false,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSignInventoryConfirmationForm.mutation,
    variables: {
      confirmationId: confirmation.id,
      date: Datetime.toFormDate(Datetime.now),
      image: null, // `image` will be submitted by the `handleSubmit` call.
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

useSignInventoryConfirmationForm.mutation = gql`
  mutation SignInventoryConfirmationMutation(
    $confirmationId: Int!,
    $date: String!,
    $image: String!,
  ) {
    response: signInventoryConfirmationDocument(
      confirmationId: $confirmationId,
      date: $date,
      image: $image,
    ) {
      ${gql.errors}
      confirmation {
        id
      }
    }
  }
`;

export default useSignInventoryConfirmationForm;
