// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {User} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Collection, Datetime, Duration} from '@supermove/utils';

const Container = Styled.View`
`;

const Header = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Item = Styled.View`
  flex-direction: row;
  width: 100%;
`;

const Cell = Styled.View`
  width: 100px;
  padding-vertical: 3px;
  padding-horizontal: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: ${colors.gray.border};
  overflow: hidden;
`;

const Text = Styled.H7`
`;

const getJobUserTimes = ({jobUser}: any) => {
  const {timeRanges} = jobUser.timesheet;
  return timeRanges
    .map((timeRange: any) => timeRange.start)
    .concat([timeRanges[timeRanges.length - 1].end]);
};

const sortJobUsers = ({jobUsers}: any) => {
  return _.sortBy(jobUsers, ['user.firstName', 'user.lastName']);
};

const formatTimeRanges = ({timeRanges}: any) => {
  return timeRanges.concat([_.last(timeRanges)]);
};

const getColumnNames = ({job}: any) => {
  const timeRanges = formatTimeRanges({
    timeRanges: _.get(job, 'organization.defaultTimesheetTimeRanges', []),
  });

  return Collection.mapWith(timeRanges, (timeRange, index, {isLast}) => {
    return isLast ? (timeRange as any).endName : (timeRange as any).startName;
  });
};

const TableHeader = ({names, job}: any) => (
  <Header>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 80}}>
        <Text>Branch / IC Code</Text>
      </Cell>
    )}
    <Cell style={{width: 140}}>
      <Text>Name</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 120}}>
        <Text>Position</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text>Labor Source</Text>
        )}
      </Cell>
    )}
    {names.map((name: any, index: any) => (
      <Cell key={index} style={{width: 100}}>
        <Text>{name}</Text>
      </Cell>
    ))}
    <Cell style={{width: 100}}>
      <Text>Travel Hours</Text>
    </Cell>
    <Cell style={{width: 100}}>
      <Text>Total Hours</Text>
    </Cell>
  </Header>
);

const CommercialJobUserItem = ({jobUser, job}: any) => (
  <Item>
    {job.organization.features.isEnabledJobUserShowBranchCode && (
      <Cell style={{width: 80}}>
        <Text>{jobUser.branchCode}</Text>
      </Cell>
    )}
    <Cell style={{width: 140}}>
      <Text>{User.getFullName(jobUser.user)}</Text>
    </Cell>
    {job.hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo && (
      <Cell style={{width: 120}}>
        <Text numberOfLines={1}>{jobUser.position}</Text>
        {job.organization.features.isEnabledJobUserShowOrganizationName && (
          <Text numberOfLines={1}>{jobUser.user.organization.name}</Text>
        )}
      </Cell>
    )}
    {getJobUserTimes({jobUser}).map((time: any, index: any) => (
      <Cell key={`${jobUser.id}-${index}`} style={{width: 100}}>
        <Text numberOfLines={1}>{time ? Datetime.convertToDisplayTime(time) : 'N/A'}</Text>
      </Cell>
    ))}
    <Cell style={{width: 100}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeDrive)}</Text>
    </Cell>
    <Cell style={{width: 100}}>
      <Text numberOfLines={1}>{Duration.toHours(jobUser.timesheet.totalTimeWithoutBreak)}</Text>
    </Cell>
  </Item>
);

type OwnCommercialJobUsersTimesheetTableProps = {
  job: any;
};

// @ts-expect-error TS(2456): Type alias 'CommercialJobUsersTimesheetTableProps'... Remove this comment to see the full error message
type CommercialJobUsersTimesheetTableProps = OwnCommercialJobUsersTimesheetTableProps &
  typeof CommercialJobUsersTimesheetTable.defaultProps;

// @ts-expect-error TS(7022): 'CommercialJobUsersTimesheetTable' implicitly has ... Remove this comment to see the full error message
const CommercialJobUsersTimesheetTable = ({job}: CommercialJobUsersTimesheetTableProps) => {
  return (
    <Container>
      <TableHeader names={getColumnNames({job})} job={job} />
      <FlatList
        data={sortJobUsers({jobUsers: job.confirmedJobUsers})}
        keyExtractor={(jobUser: any) => jobUser.id}
        renderItem={({item: jobUser, index}: any) => (
          <CommercialJobUserItem jobUser={jobUser} job={job} />
        )}
      />
    </Container>
  );
};

CommercialJobUsersTimesheetTable.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommercialJobUsersTimesheetTable.fragment = gql`
  fragment CommercialJobUsersTimesheetTable on Job {
    id
    hasJobFeatureCrewCommercialTimesheetShowAdditionalJobUserInfo: hasJobFeature(
      kind: "CREW_COMMERCIAL_TIMESHEET_SHOW_ADDITIONAL_JOB_USER_INFO"
    )
    organization {
      id
      defaultTimesheetTimeRanges {
        startName
        endName
      }
      features {
        isEnabledJobUserShowBranchCode: isEnabled(feature: "JOB_USER_SHOW_BRANCH_CODE")
        isEnabledJobUserShowOrganizationName: isEnabled(feature: "JOB_USER_SHOW_ORGANIZATION_NAME")
      }
    }
    confirmedJobUsers {
      id
      position
      branchCode
      timesheet {
        timeRanges {
          name
          start
          end
          kind
          startName
          endName
          date
          timestamp
          length
        }
        totalTimeDrive
        totalTimeWithoutBreak
      }
      user {
        id
        firstName
        lastName
        organization {
          id
          name
        }
      }
    }
  }
`;

export default CommercialJobUsersTimesheetTable;
