/**
 * NOTE(mark): This is copied from the crew app with minimal changes.
 */

/**
 * Component - v2.1.0
 */

// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import DocumentAdditionalInfoFields from 'modules/Customer/Document/components/DocumentAdditionalInfoFields';
import DocumentAdditionalInfoValuationCoverageFields from 'modules/Customer/Document/components/DocumentAdditionalInfoValuationCoverageFields';

const Container = Styled.View`
`;

const Actions = Styled.View`
  margin-top: 20px;
`;

const Button = Styled.LoadingButton`
  align-self: stretch;
  height: 40px;
`;

const Text = Styled.H6`
  ${fontWeight(700)}
  color: ${colors.white};
`;

const PrepareDocumentAdditionalInfoFields = ({documentAdditionalInfo, document, form}: any) => {
  switch (documentAdditionalInfo.kind) {
    case 'EQUIPMENT':
    case 'GRID':
    case 'LIST':
    case 'LIST_V2':
    case 'RADIO_BUTTONS':
    case 'RADIO_BUTTONS_WITH_TEXT_INPUTS':
    case 'SECTION_TEXT_AREAS':
    case 'SELECT_MULTIPLE':
      // These kinds are handled by the existing component. We render a save button
      // below each set of fields inline on the form.
      return (
        <Container>
          <DocumentAdditionalInfoFields
            documentAdditionalInfo={documentAdditionalInfo}
            form={form}
          />
          <Actions>
            <Button loading={form.loading} onPress={form.handleSubmit}>
              <Text>Save</Text>
            </Button>
          </Actions>
        </Container>
      );
    case 'VALUATION_COVERAGE':
      return <DocumentAdditionalInfoValuationCoverageFields document={document} form={form} />;
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
PrepareDocumentAdditionalInfoFields.fragment = gql`
  ${DocumentAdditionalInfoFields.fragment}
  ${DocumentAdditionalInfoValuationCoverageFields.fragment}

  fragment PrepareDocumentAdditionalInfoFields_Document on Document {
    id
    ...DocumentAdditionalInfoFields_Document
    ...DocumentAdditionalInfoValuationCoverageFields
  }

  fragment PrepareDocumentAdditionalInfoFields on DocumentAdditionalInfo {
    kind
    ...DocumentAdditionalInfoFields
  }
`;

export default PrepareDocumentAdditionalInfoFields;
