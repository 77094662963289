// Libraries
import React from 'react';

// Components
import {Mutation} from '@supermove/components';
import {gql} from '@supermove/graphql';

type OwnProps = {
  date: string;
  scheduleStatus: string;
  userId: string;
  refetchQueries?: any[];
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof UpdateUserDayByDateMutation.defaultProps;

// @ts-expect-error TS(7022): 'UpdateUserDayByDateMutation' implicitly has type ... Remove this comment to see the full error message
const UpdateUserDayByDateMutation = ({
  date,
  scheduleStatus,
  userId,
  refetchQueries,
  onSuccess,
  onError,
  children,
}: Props) => (
  <Mutation
    // @ts-expect-error TS(2322): Type '{ children: (handleSubmit: any, { loading, e... Remove this comment to see the full error message
    awaitRefetchQueries
    mutation={UpdateUserDayByDateMutation.mutation}
    refetchQueries={refetchQueries}
    variables={{
      date,
      scheduleStatus,
      userId,
    }}
    onCompleted={({response: {userDay, errors}}: any) => {
      if (!errors) {
        onSuccess(userDay);
      } else {
        onError(errors);
      }
    }}
  >
    {(handleSubmit: any, {loading, error}: any) => children({loading, handleSubmit})}
  </Mutation>
);

UpdateUserDayByDateMutation.defaultProps = {
  refetchQueries: [],
  onSuccess: () => {},
  onError: () => {},
};

// --------------------------------------------------
// Data
// --------------------------------------------------
UpdateUserDayByDateMutation.mutation = gql`
  mutation UpdateUserDayByDateMutation(
    $date: String!,
    $scheduleStatus: String!,
    $userId: Int!,
  ) {
    response: updateUserDayByDate(
      date: $date,
      scheduleStatus: $scheduleStatus,
      userId: $userId,
    ) {
      ${gql.errors}
      userDay {
        id
        dayId
        userId
        scheduleStatus
      }
    }
  }
`;

export default UpdateUserDayByDateMutation;
