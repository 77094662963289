// Libraries
import React from 'react';

// Supermove
import {Icon, Link, Styled} from '@supermove/components';
import {useHover} from '@supermove/hooks';
import {fontWeight, colors} from '@supermove/styles';

const Wrapper = Styled.View<{isSelected: boolean; isHovered: boolean}>`
  width: 100%;
  background-color: ${({isSelected, isHovered}) => {
    if (isSelected) {
      return colors.blue.interactive;
    } else if (isHovered) {
      return colors.blue.accentDark;
    } else {
      return colors.transparent;
    }
  }};
`;

const Container = Styled.View`
  align-items: center;
  width: 100%;
  padding-vertical: 15px;
`;

const Content = Styled.View`
  align-items: center;
`;

const Name = Styled.H8`
  margin-top: 5px;
  color: ${colors.white};
  letter-spacing: 0.5px;
  text-align: center;
`;

const Notification = Styled.View`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 16px;
  width: 20px;
  height: 20px;
  background-color: ${colors.red.warning};
  border-radius: 3px;
`;

const NotificationText = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.white};
`;

type OwnProps = {
  count: number;
  name: string;
  selected: boolean;
  source: string;
  responsive: any;
  to: string;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof SidebarItem.defaultProps;

// @ts-expect-error TS(7022): 'SidebarItem' implicitly has type 'any' because it... Remove this comment to see the full error message
const SidebarItem = ({count, name, selected, source, responsive, to}: Props) => {
  const {isHovered, ref} = useHover();
  return (
    <Wrapper ref={ref} isHovered={isHovered} isSelected={selected}>
      <Link to={to} data-test-id={`sidebar-item-${name.toLowerCase()}`}>
        <Container {...responsive}>
          <Content>
            <Icon source={source} color={colors.white} size={20} style={{height: 20, width: 20}} />
            <Name>{name}</Name>
          </Content>
        </Container>
      </Link>
      {count > 0 && (
        <Notification>
          <NotificationText>{count}</NotificationText>
        </Notification>
      )}
    </Wrapper>
  );
};

SidebarItem.defaultProps = {};

export default SidebarItem;
