// Libraries
import React from 'react';

// Supermove
import {Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useModal, usePopover} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import ActionMenuPopover from '@shared/design/components/ActionMenu/ActionMenuPopover';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import CautionModal from '@shared/design/components/Modal/SmallModal/CautionModal';
import ConfirmationModal from '@shared/design/components/Modal/SmallModal/ConfirmationModal';
import DeleteModal from '@shared/design/components/Modal/SmallModal/DeleteModal';
import PayengineCreditCardForm from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import useDeletePayengineCreditCardMutation from '@shared/modules/Payment/hooks/useDeletePayengineCreditCardMutation';
import useUpdatePayengineCreditCardMutation from '@shared/modules/Payment/hooks/useUpdatePayengineCreditCardMutation';

const SetDefaultCardModal = ({creditCard, projectId, isOpen, handleClose, refetch}: any) => {
  const payengineCreditCardForm = PayengineCreditCardForm.edit(creditCard, {projectId});
  const {handleSubmit} = useUpdatePayengineCreditCardMutation({
    payengineCreditCardForm: {...payengineCreditCardForm, isDefault: true},
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <ConfirmationModal
      icon={Icon.CreditCard}
      title={`Set ${creditCard.cardName} as default card`}
      subtitle={
        'By setting this card as the default, any scheduled automatic payments will be charged to this card.'
      }
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleSubmit}
      primaryActionText={'Confirm'}
      handleSecondaryAction={handleClose}
      secondaryActionText={'Cancel'}
    />
  );
};

const CannotDeleteDefaultCardModal = ({isOpen, handleClose}: any) => {
  return (
    <CautionModal
      title={`Cannot delete the default card`}
      message={`This card is the default card. To delete it, assign\nanother card as the client's default.`}
      isOpen={isOpen}
      handlePressOutside={handleClose}
      handlePrimaryAction={handleClose}
      primaryActionText={'Close'}
    />
  );
};

const DeleteCardModal = ({creditCard, projectId, isOpen, handleClose, refetch}: any) => {
  const payengineCreditCardForm = PayengineCreditCardForm.edit(creditCard, {projectId});
  const {handleSubmit, submitting} = useDeletePayengineCreditCardMutation({
    payengineCreditCardForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.log({errors}),
  });

  return (
    <DeleteModal
      title={`Delete ${creditCard.cardName}?`}
      subtitle={`This will delete the card from the client's profile.\nThis cannot be undone.`}
      isOpen={isOpen}
      handleClose={handleClose}
      handleDelete={handleSubmit}
      deleteButtonText={'Confirm'}
      isSubmitting={submitting}
    />
  );
};

const CreditCardActionsPopoverButton = ({creditCard, projectId, refetch}: any) => {
  const actionsMenuPopover = usePopover();
  const setDefaultCardModal = useModal({
    name: 'Set Default Credit Card Modal',
    enableTracking: true,
  });
  const deleteCardModal = useModal({name: 'Delete Credit Card Modal', enableTracking: true});
  const cannotDeleteCardModal = useModal({
    name: 'Cannot Delete Credit Card Modal',
    enableTracking: true,
  });

  return (
    <React.Fragment>
      <ActionMenuPopover
        popover={actionsMenuPopover}
        width={200}
        actions={[
          {
            text: 'Set as default card',
            onPress: setDefaultCardModal.handleOpen,
          },
          {
            text: 'Delete card',
            color: colors.red.warning,
            onPress: creditCard.isDefault
              ? cannotDeleteCardModal.handleOpen
              : deleteCardModal.handleOpen,
          },
        ]}
      >
        <TertiaryButton
          style={{paddingHorizontal: 12, paddingVertical: 4}}
          onPress={actionsMenuPopover.handleToggle}
        >
          <Icon source={Icon.EllipsisV} color={colors.gray.secondary} size={16} />
        </TertiaryButton>
      </ActionMenuPopover>
      <SetDefaultCardModal
        key={setDefaultCardModal.key}
        creditCard={creditCard}
        projectId={projectId}
        isOpen={setDefaultCardModal.isOpen}
        handleClose={setDefaultCardModal.handleClose}
        refetch={refetch}
      />
      <CannotDeleteDefaultCardModal
        key={cannotDeleteCardModal.key}
        isOpen={cannotDeleteCardModal.isOpen}
        handleClose={cannotDeleteCardModal.handleClose}
      />
      <DeleteCardModal
        key={deleteCardModal.key}
        creditCard={creditCard}
        projectId={projectId}
        isOpen={deleteCardModal.isOpen}
        handleClose={deleteCardModal.handleClose}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CreditCardActionsPopoverButton.fragment = gql`
  ${PayengineCreditCardForm.edit.fragment}

  fragment CreditCardActionsPopoverButton on PayengineCreditCard {
    id
    cardName
    brand
    ...PayengineCreditCardForm_edit
  }
`;

export default CreditCardActionsPopoverButton;
