// Libraries
import React from 'react';

// Supermove
import {FlatList, ScrollView, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import SalesPayableUserDayItem from './SalesPayableUserDayItem';
import SalesPayableUserDaysListHeader from './SalesPayableUserDaysListHeader';

const Item = Styled.View`
`;

const EmptyContainer = Styled.View`
  padding: 40px;
  align-items: center;
`;

const EmptyMessage = Styled.H6`
  color: ${colors.gray.secondary};
`;

const SalesPayableUserDaysList = ({payableUserDays, refetch}: any) => {
  const responsive = useResponsive();

  return (
    <ScrollView
      horizontal
      // @ts-expect-error TS(2322): Type '{ width: string | null; }' is not assignable... Remove this comment to see the full error message
      contentContainerStyle={{
        width: responsive.desktop ? '100%' : null,
      }}
    >
      <FlatList
        initialNumToRender={20}
        data={payableUserDays}
        keyExtractor={(payableUserDay: any, index: any) => payableUserDay.id}
        renderItem={({item: payableUserDay, index}: any) => (
          <Item>
            <SalesPayableUserDayItem
              isFirst={index === 0}
              isLast={index === payableUserDays.length - 1}
              index={index}
              payableUserDay={payableUserDay}
              refetch={refetch}
            />
          </Item>
        )}
        ListHeaderComponent={() => <SalesPayableUserDaysListHeader />}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyMessage>No hours worked during these dates.</EmptyMessage>
          </EmptyContainer>
        )}
      />
    </ScrollView>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
SalesPayableUserDaysList.fragment = gql`
  ${SalesPayableUserDayItem.fragment}

  fragment SalesPayableUserDaysList on PayableUserDay {
    id
    ...SalesPayableUserDayItem
  }
`;

export default SalesPayableUserDaysList;
