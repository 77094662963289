// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, DropdownInput} from '@supermove/components';
import {titleize} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import WorkflowFormulaBuilder from '@shared/modules/Workflow/enums/WorkflowFormulaBuilder';
import WorkflowModelDataTypes from '@shared/modules/Workflow/enums/WorkflowModelDataTypes';
import WorkflowModelProperties from '@shared/modules/Workflow/enums/WorkflowModelProperties';
import WorkflowStepActionKind from '@shared/modules/Workflow/enums/WorkflowStepActionKind';
import WorkflowStopIfHelpers from '@shared/modules/Workflow/enums/WorkflowStopIfHelpers';
import WorkflowBuilderOptionCard from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowBuilderOptionCard';
import WorkflowStepDropdown from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepDropdown';
import WorkflowStepSlide from 'modules/Organization/Settings/Workflow/Builder/components/WorkflowStepSlide';

const StringValueField = ({form, field, index}: any) => {
  return (
    <FieldInput.Memoized
      {...form}
      index={index}
      label={'Value'}
      name={`${field}.comparatorValue`}
      input={{
        placeholder: 'Enter value',
      }}
    />
  );
};

const EnumValueField = ({form, field, index}: any) => {
  const propertyName = _.get(form.values, `${field}.propertyName`);
  return (
    <FieldInput
      {...form}
      index={index}
      component={DropdownInput}
      name={`${field}.comparatorValue`}
      label={'Select value'}
      isRequired
      input={{
        options: WorkflowModelProperties.getEnumOptions(propertyName),
        placeholder: 'Select value',
        isSearchable: true,
        isPortaled: true,
        setFieldValue: form.setFieldValue,
        style: {flex: 1},
        menuPlacement: 'auto',
      }}
    />
  );
};

const BooleanValueField = ({form, field}: any) => {
  const selectedBoolean = _.get(form.values, `${field}.comparatorValue`);
  return (
    <React.Fragment>
      <FieldInput.LabelText isRequired>Is equal to</FieldInput.LabelText>
      <Space height={4} />
      <WorkflowBuilderOptionCard.GroupedListContainer>
        {['true', 'false'].map((boolean, index) => (
          <WorkflowBuilderOptionCard.Paired
            key={index}
            isSelected={boolean === selectedBoolean}
            isTextCentered
            index={index}
            text={titleize(boolean)}
            onPress={() => {
              form.setFieldValue(`${field}.comparatorType`, WorkflowFormulaBuilder.IS);
              form.setFieldValue(`${field}.comparatorValue`, boolean);
            }}
          />
        ))}
      </WorkflowBuilderOptionCard.GroupedListContainer>
    </React.Fragment>
  );
};

const ValueFieldForDataType = ({form, field, index, dataType}: any) => {
  switch (dataType) {
    case WorkflowModelDataTypes.STRING:
    case WorkflowModelDataTypes.NUMBER:
      return <StringValueField form={form} field={field} index={index} />;
    case WorkflowModelDataTypes.ENUM:
      return <EnumValueField form={form} field={field} index={index} />;
    case WorkflowModelDataTypes.BOOLEAN:
      return <BooleanValueField form={form} field={field} />;
    case WorkflowModelDataTypes.DATE:
    default:
      return null;
  }
};

const StopIfSlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  field,
  handleSubmitValidateWorkflow,
}: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const {label, description} = WorkflowStepActionKind.ATTRIBUTES[WorkflowStepActionKind.STOP_IF];
  const stepReferenceKind = _.get(form.values, `${field}.stepReferenceKind`);
  const propertyName = _.get(form.values, `${field}.propertyName`);
  const comparatorType = _.get(form.values, `${field}.comparatorType`);
  const comparatorValue = _.get(form.values, `${field}.comparatorValue`);
  const isDoneDisabled = !stepReferenceKind || !propertyName || !comparatorType || !comparatorValue;
  const propertyDataType = _.get(form.values, `${field}.propertyDataType`);

  return (
    <WorkflowStepSlide
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      title={label}
      subtitle={description}
      showDoneButton
      isDoneDisabled={isDoneDisabled}
      handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
    >
      <WorkflowStepDropdown
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        accessPropertySuffix={''}
        isFormula
      />
      <Space height={16} />
      <FieldInput
        {...form}
        component={DropdownInput}
        name={`${field}.propertyName`}
        label={'Select Property'}
        isRequired
        input={{
          options:
            WorkflowStopIfHelpers.getModelPropertyOptionsFormStepReferenceKind(stepReferenceKind),
          placeholder: 'Select Condition',
          isSearchable: true,
          isPortaled: true,
          onChangeValue: (_: any, {dataType}: any) => {
            form.setFieldValue(`${field}.propertyDataType`, dataType);
          },
          setFieldValue: form.setFieldValue,
          style: {flex: 1},
          menuPlacement: 'auto',
        }}
      />
      <Space height={16} />
      {!WorkflowStopIfHelpers.getIsComparatorTypeAssumed(propertyDataType) && (
        <React.Fragment>
          <FieldInput
            {...form}
            component={DropdownInput}
            name={`${field}.comparatorType`}
            label={'Condition'}
            isRequired
            input={{
              options: WorkflowFormulaBuilder.COMPARATOR_TYPE_OPTIONS,
              placeholder: 'Select Condition',
              isSearchable: true,
              isPortaled: true,
              setFieldValue: form.setFieldValue,
              style: {flex: 1},
              menuPlacement: 'auto',
            }}
          />
          <Space height={16} />
        </React.Fragment>
      )}
      <ValueFieldForDataType
        form={form}
        field={field}
        index={workflowStepIndex}
        dataType={propertyDataType}
      />
    </WorkflowStepSlide>
  );
};

const StopIfDisplaySlide = ({
  form,
  workflowStepsField,
  workflowStepIndex,
  field,
  isPreview,
}: any) => {
  return (
    <WorkflowStepSlide.Display
      form={form}
      workflowStepsField={workflowStepsField}
      workflowStepIndex={workflowStepIndex}
      description={'Stop If'}
      isPreview={isPreview}
    />
  );
};

const WorkflowStepStopIf = {
  getSlides: ({
    form,
    workflowStepsField,
    workflowStepIndex,
    handleSubmitValidateWorkflow,
    isPreview,
  }: any) => {
    const field = `${workflowStepsField}.${workflowStepIndex}.stopIfActionForm`;
    return [
      <StopIfSlide
        key={'CREATE'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        handleSubmitValidateWorkflow={handleSubmitValidateWorkflow}
      />,
      <StopIfDisplaySlide
        key={'DISPLAY'}
        form={form}
        workflowStepsField={workflowStepsField}
        workflowStepIndex={workflowStepIndex}
        field={field}
        isPreview={isPreview}
      />,
    ];
  },
};

export default WorkflowStepStopIf;
