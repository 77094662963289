// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, Icon, Space, Styled} from '@supermove/components';
import TextInput from '@supermove/components/src/Styled/TextInput';
import {useState, useHover, useToast, useDrawer, Form} from '@supermove/hooks';
import {Inventory} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import SecondaryButton from '@shared/design/components/Button/SecondaryButton';
import Checkbox from '@shared/design/components/Checkbox';
import FieldInput from '@shared/design/components/Field/FieldInput';
import Table from '@shared/design/components/TableV2Deprecated';
import Toast from '@shared/design/components/Toast';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import SuccessToast from '@shared/design/components/Toast/SuccessToast';
import InventoryRoomsForm, {
  InventoryRoomsFormType,
} from '@shared/modules/Inventory/forms/InventoryRoomsForm';
import {ItemFormV2Type} from '@shared/modules/Inventory/forms/ItemFormV2';
import EditInventoryItemDrawer from 'modules/Inventory/Edit/components/EditInventoryItemDrawer';
import InventoryStepper from 'modules/Inventory/Edit/components/InventoryStepper';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

const DiscreteButton = Styled.ButtonV2`
  border-width: 1px;
  border-radius: 4px;
  border-color: ${(props) => ((props as any).isHovered ? colors.blue.interactive : colors.transparent)};
`;

const IconButton = Styled.ButtonV2`
  display: flex;
`;

const CenteredCell = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const getVirtualizedHeights = ({itemCount, itemHeight, window}: any) => {
  const heightOfItems = itemCount * itemHeight;
  const maxHeight = window.innerHeight - 500;
  return {
    virtualizedItemHeight: itemHeight,
    virtualizedTableHeight: heightOfItems < maxHeight ? heightOfItems : maxHeight,
  };
};

const ItemNameInput = ({inventoryRoomsForm, rowIndex, roomItemsFormIndex}: any) => {
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${rowIndex}`;
  const itemForm = _.get(inventoryRoomsForm.values, field);
  const [itemName, setItemName] = useState(itemForm.name);
  const {ref, isHovered, handleToggleOff} = useHover();
  const isNewCustomItem = itemForm.itemId.includes('NEW') && itemForm.name === 'Custom Item';
  const [isEdit, setIsEdit] = useState(isNewCustomItem);
  const noNameErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: 'Item name required',
  });

  return isEdit ? (
    <FieldInput
      {...inventoryRoomsForm}
      value={itemName}
      handleChange={(event) => {
        const text = event.target.value;
        setItemName(text);
      }}
      style={{flex: 1}}
      setFieldValue={() => {
        // Override the default setFieldValue behavior to do nothing
      }}
      input={{autoFocus: true}}
      handleBlur={(event) => {
        const text = event.target.value;
        if (text === '') {
          noNameErrorToast.handleToast();
          setIsEdit(false);
          handleToggleOff();
          setItemName(itemForm.name);
          return;
        }
        inventoryRoomsForm.setFieldValue(`${field}.name`, text);
        InventoryRoomsForm.setDirtyForms({
          inventoryRoomsForm,
          roomItemsFormIndex,
          itemIndex: rowIndex,
        });
        handleToggleOff();
        setIsEdit(false);
      }}
    />
  ) : (
    <DiscreteButton
      ref={ref}
      isHovered={isHovered}
      style={{height: 36, flex: 1, padding: 8, alignItems: 'flex-start'}}
      onPress={() => setIsEdit(true)}
    >
      <Table.CellText numberOfLines={1}>{itemName}</Table.CellText>
    </DiscreteButton>
  );
};

const PriceInput = ({inventoryRoomsForm, rowIndex, roomItemsFormIndex}: any) => {
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${rowIndex}`;
  const itemForm = _.get(inventoryRoomsForm.values, field);
  // Currency is stored as cents on ItemFormV2, so the toForm conversion occurs on the fly and then back toMutation on save
  const [price, setPrice] = useState(Currency.toForm(itemForm.price));
  const {ref, isHovered, handleToggleOff} = useHover();
  const [isEdit, setIsEdit] = useState(false);

  return isEdit ? (
    <FieldInput
      {...inventoryRoomsForm}
      key={itemForm.uuid}
      value={price}
      style={{flex: 1}}
      component={CurrencyInput}
      input={{
        autoFocus: true,
        setFieldValue: () => {},
        setFieldTouched: inventoryRoomsForm.setFieldTouched,
        component: TextInput,
        onChangeText: (value: any) => {
          setPrice(value || Currency.toForm(0));
        },
      }}
      handleBlur={(event) => {
        const text = event.target.value;
        inventoryRoomsForm.setFieldValue(`${field}.price`, text ? Currency.toMutation(text) : 0);
        InventoryRoomsForm.setDirtyForms({
          inventoryRoomsForm,
          roomItemsFormIndex,
          itemIndex: rowIndex,
        });
        handleToggleOff();
        setIsEdit(false);
      }}
    />
  ) : (
    <DiscreteButton
      ref={ref}
      isHovered={isHovered}
      style={{height: 36, flex: 1, padding: 8, alignItems: 'center'}}
      onPress={() => setIsEdit(true)}
    >
      <Table.CellText numberOfLines={1}>{price}</Table.CellText>
    </DiscreteButton>
  );
};

const TableInput = ({
  inventoryRoomsForm,
  rowIndex,
  roomItemsFormIndex,
  fieldName,
  defaultDensityFactor,
  isNumbersOnly,
  isTextCentered,
}: any) => {
  const {ref, isHovered, handleToggleOff} = useHover();
  const [isEdit, setIsEdit] = useState(false);
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${rowIndex}`;
  const itemForm = _.get(inventoryRoomsForm.values, field);
  const {isDerivedWeight} = itemForm;
  return isEdit ? (
    <FieldInput.Memoized
      {...inventoryRoomsForm}
      key={_.get(inventoryRoomsForm, `values.${field}.isDeleted`)}
      index={rowIndex}
      style={{flex: 1}}
      name={`${field}.${fieldName}`}
      handleChange={() => {}} // Empty function needed here to override the default handleChange behavior which will allow any input
      setFieldValue={(name, text) => {
        inventoryRoomsForm.setFieldValue(`${field}.${fieldName}`, text);
      }}
      input={{
        autoFocus: true,
        disabled: _.get(inventoryRoomsForm, `values.${field}.isDeleted`),
        style: {flex: 1},
      }}
      handleBlur={(e) => {
        if (isNumbersOnly) {
          const numberValue = Inventory.getFloatValue(e.currentTarget.value);
          const isZero = numberValue === 0;
          inventoryRoomsForm.setFieldValue(`${field}.${fieldName}`, numberValue);
          if (fieldName === 'weight' && isDerivedWeight) {
            const volume = isZero ? 0 : _.round(numberValue / defaultDensityFactor, 2);
            inventoryRoomsForm.setFieldValue(`${field}.volume`, volume);
          }
          if (fieldName === 'volume' && isDerivedWeight) {
            const weight = isZero ? 0 : _.round(numberValue * defaultDensityFactor, 2);
            inventoryRoomsForm.setFieldValue(`${field}.weight`, weight);
          }
        }
        setIsEdit(false);
        handleToggleOff();
        // we ensure the form isDirty on handleBlur since autosave could fire while typing a value
        // and reset the dirty state, causing the next mutation to miss this value
        InventoryRoomsForm.setDirtyForms({
          inventoryRoomsForm,
          roomItemsFormIndex,
          itemIndex: rowIndex,
        });
      }}
    />
  ) : (
    <DiscreteButton
      ref={ref}
      isHovered={isHovered}
      style={{
        height: 36,
        flex: 1,
        padding: 8,
        alignItems: isTextCentered ? 'center' : 'flex-start',
      }}
      onPress={() => setIsEdit(true)}
    >
      <Table.CellText numberOfLines={1}>
        {_.get(inventoryRoomsForm.values, `${field}.${fieldName}`)}
      </Table.CellText>
    </DiscreteButton>
  );
};

const QuantityButton = ({
  icon,
  quantity,
  inventoryRoomsForm,
  rowIndex,
  item,
  roomItemsFormIndex,
  field,
}: any) => {
  return (
    <SecondaryButton
      iconLeft={icon}
      onPress={() => {
        InventoryRoomsForm.handleQuantityChange({
          inventoryRoomsForm,
          quantity,
          item,
          roomItemsFormIndex,
          itemIndex: rowIndex,
        });
      }}
      isDisabled={_.get(inventoryRoomsForm, `values.${field}.isDeleted`)}
    />
  );
};

const ItemNameCell = ({inventoryRoomsForm, roomItemsFormIndex}: any) => ({
  headerContent: () => {
    return <Table.HeaderText>Item Name</Table.HeaderText>;
  },
  cellContent: ({item}: any) => {
    const {index} = item;
    return (
      <ItemNameInput
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={roomItemsFormIndex}
        rowIndex={index}
      />
    );
  },
});

const VolumeCell = ({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}: any) => ({
  headerContent: () => {
    return (
      <CenteredCell>
        <Table.HeaderText>Volume (cu ft)</Table.HeaderText>
      </CenteredCell>
    );
  },
  cellContent: ({item}: any) => {
    return (
      <TableInput
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={roomItemsFormIndex}
        rowIndex={item.index}
        fieldName={'volume'}
        defaultDensityFactor={defaultDensityFactor}
        isNumbersOnly
        isTextCentered
      />
    );
  },
});

const WeightCell = ({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}: any) => ({
  headerContent: () => {
    return (
      <CenteredCell>
        <Table.HeaderText>Weight (lb)</Table.HeaderText>
      </CenteredCell>
    );
  },
  cellContent: ({item}: any) => {
    return (
      <TableInput
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={roomItemsFormIndex}
        rowIndex={item.index}
        fieldName={'weight'}
        defaultDensityFactor={defaultDensityFactor}
        isNumbersOnly
        isTextCentered
      />
    );
  },
});

const NotesCell = ({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}: any) => ({
  headerContent: () => {
    return <Table.HeaderText>Notes</Table.HeaderText>;
  },
  cellContent: ({item}: any) => {
    return (
      <TableInput
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={roomItemsFormIndex}
        rowIndex={item.index}
        fieldName={'notes'}
        defaultDensityFactor={defaultDensityFactor}
        isTextCentered={false}
      />
    );
  },
});

const TakeCell = ({inventoryRoomsForm, roomItemsFormIndex}: any) => ({
  headerContent: () => {
    return (
      <CenteredCell>
        <Table.HeaderText>Take</Table.HeaderText>
      </CenteredCell>
    );
  },
  cellContent: ({item}: any) => {
    return (
      <CenteredCell>
        <Checkbox
          isDisabled={item.isDeleted}
          isChecked={item.take}
          handleToggle={(isChecked) => {
            InventoryRoomsForm.handleTakeToggle({
              inventoryRoomsForm,
              isChecked,
              roomItemsFormIndex,
              itemIndex: item.index,
            });
          }}
        />
      </CenteredCell>
    );
  },
});

const DuplicateButton = ({
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  iconSize,
  handleScrollRoomToBottom,
  style,
}: any) => {
  const duplicateItemToast = useToast({
    ToastComponent: SuccessToast,
    message: 'Item added!',
  });
  return (
    <IconButton
      style={style}
      onPress={() => {
        const newItemForm = InventoryRoomsForm.handleDuplicate({
          inventoryRoomsForm,
          roomItemsFormIndex,
          itemForm,
        });
        duplicateItemToast.handleToast({message: `${newItemForm.name} added!`});
        handleScrollRoomToBottom();
      }}
    >
      <Icon source={Icon.Copy} color={colors.blue.interactive} size={iconSize || 16} />
    </IconButton>
  );
};

const DeleteButton = ({
  inventoryRoomsForm,
  isDeleted,
  roomItemsFormIndex,
  itemIndex,
  iconSize,
  style,
}: any) => {
  return (
    <IconButton
      style={style}
      onPress={() => {
        InventoryRoomsForm.handleDeleteToggle({
          inventoryRoomsForm,
          isDeleted: !isDeleted,
          roomItemsFormIndex,
          itemIndex,
        });
      }}
    >
      <Icon source={Icon.Trash} color={colors.red.warning} size={iconSize || 16} />
    </IconButton>
  );
};

const EditButton = ({
  inventoryRoomsForm,
  roomItemsFormIndex,
  itemForm,
  defaultDensityFactor,
  isEnabledSurveysTimeAdditives,
  iconSize,
}: {
  inventoryRoomsForm: Form<InventoryRoomsFormType>;
  roomItemsFormIndex: number;
  itemForm: ItemFormV2Type;
  defaultDensityFactor: number;
  isEnabledSurveysTimeAdditives: boolean;
  iconSize?: number;
}) => {
  const editInventoryItemDrawer = useDrawer({name: 'Edit Inventory Item Drawer'});

  return (
    <React.Fragment>
      <IconButton
        style={{
          height: 24,
          width: 24,
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={editInventoryItemDrawer.handleOpen}
      >
        <Icon source={Icon.Pen} color={colors.blue.interactive} size={iconSize || 16} />
      </IconButton>
      <EditInventoryItemDrawer
        key={editInventoryItemDrawer.key}
        isOpen={editInventoryItemDrawer.isOpen}
        handleClose={editInventoryItemDrawer.handleClose}
        inventoryRoomsForm={inventoryRoomsForm}
        roomItemsFormIndex={roomItemsFormIndex}
        itemForm={itemForm}
        defaultDensityFactor={defaultDensityFactor}
        isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
      />
    </React.Fragment>
  );
};

const handleQuantityChange = ({
  inventoryRoomsForm,
  roomItemsFormIndex,
  field,
  quantity,
  itemForm,
  removeItemToast,
}: any) => {
  const {name, index: itemIndex} = itemForm;
  const newQuantity = quantity < 0 ? 0 : quantity;
  inventoryRoomsForm.setFieldValue(field, newQuantity);
  InventoryRoomsForm.setDirtyForms({
    inventoryRoomsForm,
    roomItemsFormIndex,
    itemIndex,
  });

  if (newQuantity === 0) {
    InventoryRoomsForm.handleDeleteToggle({
      inventoryRoomsForm,
      isDeleted: true,
      roomItemsFormIndex,
      itemIndex,
    });
    removeItemToast.handleToast({message: `${name} removed.`});
  }
};

const QuantityInput = ({itemForm, inventoryRoomsForm, roomItemsFormIndex}: any) => {
  const {index, take, takeCount, leaveCount} = itemForm;
  const quantityField = take ? 'takeCount' : 'leaveCount';
  const quantity = take ? takeCount : leaveCount;
  const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${index}.${quantityField}`;

  const removeItemToast = useToast({
    ToastComponent: Toast,
    message: 'Item removed.',
    actionText: 'Undo',
    handleAction: () => {
      InventoryRoomsForm.handleDeleteToggle({
        inventoryRoomsForm,
        isDeleted: false,
        roomItemsFormIndex,
        itemIndex: index,
      });
      // Reset quantity to 1 if undoing a delete
      inventoryRoomsForm.setFieldValue(field, 1);
    },
  });

  return (
    <InventoryStepper.Stepper
      value={quantity}
      handleDecrease={() => {
        const newQuantity = quantity - 1;
        handleQuantityChange({
          inventoryRoomsForm,
          roomItemsFormIndex,
          field,
          quantity: newQuantity,
          itemForm,
          removeItemToast,
        });
      }}
      handleIncrease={() => {
        const newQuantity = quantity + 1;
        handleQuantityChange({
          inventoryRoomsForm,
          roomItemsFormIndex,
          field,
          quantity: newQuantity,
          itemForm,
          removeItemToast,
        });
      }}
      style={{
        width: 128,
        borderColor: colors.gray.tertiary,
      }}
      height={36}
    >
      <FieldInput
        {...inventoryRoomsForm}
        isResponsive
        style={{flex: 1}}
        setFieldValue={(name, value) => {
          const quantity = Inventory.getFloatValue(value);
          // getFloatValue blocks trailing decimals, so we allow it so that floats can be entered
          inventoryRoomsForm.setFieldValue(name, value.endsWith('.') ? value : quantity);
        }}
        input={{
          style: {borderWidth: 0, textAlign: 'center', padding: 0},
          onBlur: (event: any) => {
            const text = event.target.value;
            // Formatting quantity removes a trailing decimal
            inventoryRoomsForm.setFieldValue(field, Inventory.getFloatValue(text));
            InventoryRoomsForm.setDirtyForms({
              inventoryRoomsForm,
              roomItemsFormIndex,
              itemIndex: index,
            });
          },
        }}
        name={field}
      />
    </InventoryStepper.Stepper>
  );
};

const getColumnDefinitions = ({
  inventoryRoomsForm,
  roomItemsFormIndex,
  defaultDensityFactor,
  isEnabledResponsiveSurveysForDesktop,
  handleScrollRoomToBottom,
  isEnabledSurveysTimeAdditives,
}: any) => {
  if (isEnabledResponsiveSurveysForDesktop) {
    return [
      {
        flex: 2,
        minWidth: 176,
        cellStyle: {paddingRight: 16, paddingLeft: 24},
        ...ItemNameCell({inventoryRoomsForm, roomItemsFormIndex}),
      },
      {
        width: 160,
        cellStyle: {paddingHorizontal: 16},
        headerContent: () => {
          return <Table.HeaderText>Quantity</Table.HeaderText>;
        },
        cellContent: ({item}: any) => {
          return (
            <QuantityInput
              itemForm={item}
              inventoryRoomsForm={inventoryRoomsForm}
              roomItemsFormIndex={roomItemsFormIndex}
            />
          );
        },
      },
      {
        width: 120,
        cellStyle: {paddingHorizontal: 16},
        ...VolumeCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        cellStyle: {paddingHorizontal: 0},
        width: 16,
        headerContent: () => <React.Fragment />,
        cellContent: ({item}: any) => {
          const {index, isDerivedWeight, volume} = item;
          const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${index}`;

          return (
            <IconButton
              style={{
                height: 16,
                width: 16,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onPress={() => {
                if (!isDerivedWeight) {
                  const weight = volume === 0 ? 0 : _.round(volume * defaultDensityFactor, 2);
                  inventoryRoomsForm.setFieldValue(`${field}.weight`, weight);
                }
                inventoryRoomsForm.setFieldValue(`${field}.isDerivedWeight`, !isDerivedWeight);
              }}
            >
              <Icon
                source={isDerivedWeight ? Icon.Link : Icon.LinkSlash}
                color={colors.blue.interactive}
                size={12}
              />
            </IconButton>
          );
        },
      },
      {
        width: 120,
        cellStyle: {paddingHorizontal: 16},
        ...WeightCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        width: 120,
        cellStyle: {paddingHorizontal: 16},
        headerContent: () => (
          <CenteredCell>
            <Table.HeaderText>Price ($)</Table.HeaderText>
          </CenteredCell>
        ),
        cellContent: ({item}: any) => {
          return (
            <PriceInput
              inventoryRoomsForm={inventoryRoomsForm}
              roomItemsFormIndex={roomItemsFormIndex}
              rowIndex={item.index}
            />
          );
        },
      },
      {
        flex: 1,
        minWidth: 100,
        cellStyle: {paddingHorizontal: 16},
        ...NotesCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        width: 64,
        cellStyle: {paddingHorizontal: 16},
        ...TakeCell({inventoryRoomsForm, roomItemsFormIndex}),
      },
      {
        width: isEnabledSurveysTimeAdditives ? 144 : 96,
        cellStyle: {paddingLeft: 16, paddingRight: 24},
        headerContent: () => {},
        cellContent: ({item}: any) => {
          const {isDeleted} = item;
          return (
            <React.Fragment>
              <Row>
                {isEnabledSurveysTimeAdditives && (
                  <React.Fragment>
                    <EditButton
                      inventoryRoomsForm={inventoryRoomsForm}
                      roomItemsFormIndex={roomItemsFormIndex}
                      itemForm={item}
                      defaultDensityFactor={defaultDensityFactor}
                      isEnabledSurveysTimeAdditives={isEnabledSurveysTimeAdditives}
                    />
                    <Space width={8} />
                  </React.Fragment>
                )}
                <DuplicateButton
                  inventoryRoomsForm={inventoryRoomsForm}
                  roomItemsFormIndex={roomItemsFormIndex}
                  itemForm={item}
                  handleScrollRoomToBottom={handleScrollRoomToBottom}
                  style={{
                    height: 24,
                    width: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
                <Space width={8} />
                <DeleteButton
                  inventoryRoomsForm={inventoryRoomsForm}
                  isDeleted={isDeleted}
                  roomItemsFormIndex={roomItemsFormIndex}
                  itemIndex={item.index}
                  style={{
                    height: 24,
                    width: 24,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                />
              </Row>
            </React.Fragment>
          );
        },
      },
    ];
  } else {
    return [
      {
        flex: 3,
        ...ItemNameCell({inventoryRoomsForm, roomItemsFormIndex}),
      },
      {
        flex: 2,
        minWidth: 176,
        headerContent: () => {
          return <Table.HeaderText>Quantity</Table.HeaderText>;
        },
        cellContent: ({item}: any) => {
          const {index} = item;
          const field = `inventoryRoomsForm.roomItemsForms.${roomItemsFormIndex}.itemForms.${index}`;
          return (
            <Row>
              <QuantityButton
                icon={Icon.Minus}
                inventoryRoomsForm={inventoryRoomsForm}
                quantity={-1}
                item={item}
                roomItemsFormIndex={roomItemsFormIndex}
                rowIndex={index}
                field={field}
              />
              <Space width={8} />
              <TableInput
                inventoryRoomsForm={inventoryRoomsForm}
                roomItemsFormIndex={roomItemsFormIndex}
                rowIndex={item.index}
                fieldName={item.take ? 'takeCount' : 'leaveCount'}
                isNumbersOnly
                isTextCentered
              />
              <Space width={8} />
              <QuantityButton
                icon={Icon.Plus}
                inventoryRoomsForm={inventoryRoomsForm}
                quantity={1}
                item={item}
                roomItemsFormIndex={roomItemsFormIndex}
                rowIndex={index}
                field={field}
              />
            </Row>
          );
        },
      },
      {
        flex: 1,
        minWidth: 32,
        ...TakeCell({inventoryRoomsForm, roomItemsFormIndex}),
      },
      {
        flex: 1,
        minWidth: 40,
        ...VolumeCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        flex: 1,
        minWidth: 40,
        ...WeightCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        flex: 3,
        ...NotesCell({inventoryRoomsForm, roomItemsFormIndex, defaultDensityFactor}),
      },
      {
        flex: 1,
        minWidth: 32,
        headerContent: () => {},
        cellContent: ({item}: any) => {
          const {isDeleted} = item;
          return (
            <Table.CellText>
              <DeleteButton
                inventoryRoomsForm={inventoryRoomsForm}
                isDeleted={isDeleted}
                roomItemsFormIndex={roomItemsFormIndex}
                itemIndex={item.index}
                iconSize={Icon.Sizes.Medium}
                style={{
                  paddingHorizontal: 12,
                }}
              />
            </Table.CellText>
          );
        },
      },
    ];
  }
};

const EditRoomSectionTable = ({
  inventoryRoomsForm,
  index,
  defaultDensityFactor,
  virtualizedListRef,
  filteredItemForms,
  isEnabledResponsiveSurveysForDesktop,
  handleScrollRoomToBottom,
  isEnabledSurveysTimeAdditives,
}: any) => {
  const {virtualizedItemHeight, virtualizedTableHeight} = getVirtualizedHeights({
    itemCount: filteredItemForms.length,
    itemHeight: 60,
    window,
  });

  return (
    <Table.Virtualized
      columnDefinitions={getColumnDefinitions({
        inventoryRoomsForm,
        roomItemsFormIndex: index,
        defaultDensityFactor,
        isEnabledResponsiveSurveysForDesktop,
        handleScrollRoomToBottom,
        isEnabledSurveysTimeAdditives,
      })}
      emptyStateText={"Select an item and it'll appear here."}
      items={filteredItemForms}
      virtualizedItemHeight={virtualizedItemHeight}
      virtualizedTableHeight={virtualizedTableHeight}
      itemKey={'itemId'}
      virtualizedListRef={virtualizedListRef}
      // disable the table's default border since it's
      // nested inside the collapsible container
      containerStyle={{borderWidth: '0px'}}
    />
  );
};

export default EditRoomSectionTable;
