// Libraries
import React from 'react';

// Supermove
import {ExternalLink, Icon, ScrollView, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useQuery, useModal} from '@supermove/hooks';
import {colors, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Button from '@shared/design/components/Button';
import Table from '@shared/design/components/TableV2Deprecated';
import CreateAttachmentForm from '@shared/modules/File/forms/CreateAttachmentForm';
import AddAttachmentModal from 'modules/Job/V2/Move/components/AddAttachmentModal';
import DeleteAttachmentModal from 'modules/Job/V2/Move/components/DeleteAttachmentModal';

const Container = Styled.View`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const HeaderText = Styled.Text`
  ${Typography.Subheading}
`;

const TableHeaderText = Styled.Text`
  ${Typography.Label}
;`;

const Text = Styled.Text`
  ${Typography.Body}
`;

const AttachmentsTableContainer = Styled.View`
  width: 625px;
`;

const DeleteAttachmentButton = Styled.ButtonV2`
`;

const ATTACHMENT_CATEGORY_KINDS_DROPDOWN_OPTIONS = [
  {
    label: 'Email Attachment',
    value: 'EMAIL_TEMPLATE_ATTACHMENT',
  },
];

const getAttachmentColumnDefinitions = ({refetch}: any) => [
  {
    width: 350,
    headerContent: () => {
      return <TableHeaderText>Title</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
    cellContent: ({item: attachment}) => {
      return (
        <ExternalLink url={attachment.file.downloadUrl} style={{flex: 1}}>
          <LinkText numberOfLines={2}>{attachment.file.name}</LinkText>
        </ExternalLink>
      );
    },
  },
  {
    width: 150,
    headerContent: () => {
      return <TableHeaderText>Uploaded at</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
    cellContent: ({item: attachment}) => {
      return <Text>{Datetime.convertToDisplayDate(attachment.file.uploadedAt, 'M/D/YY')}</Text>;
    },
  },
  {
    width: 50,
    headerContent: () => {
      return <TableHeaderText>Actions</TableHeaderText>;
    },
    // @ts-expect-error TS(7031): Binding element 'attachment' implicitly has an 'an... Remove this comment to see the full error message
    cellContent: ({item: attachment}) => {
      return <AttachmentActions attachment={attachment} refetch={refetch} />;
    },
  },
];

const AttachmentActions = ({attachment, refetch}: any) => {
  const deleteAttachmentModal = useModal({name: 'Delete Attachment Modal'});

  return (
    <React.Fragment>
      <DeleteAttachmentButton onPress={deleteAttachmentModal.handleOpen}>
        <Icon source={Icon.Trash} color={colors.red.warning} size={16} />
      </DeleteAttachmentButton>
      <DeleteAttachmentModal
        isOpen={deleteAttachmentModal.isOpen}
        attachmentId={Number(attachment.id)}
        handleClose={deleteAttachmentModal.handleClose}
        onSuccess={refetch}
      />
    </React.Fragment>
  );
};

const EmailAttachmentLibraryAttachments = ({emailTemplateAttachments, refetch}: any) => {
  return (
    <ScrollView horizontal>
      <AttachmentsTableContainer>
        <Table
          columnDefinitions={getAttachmentColumnDefinitions({
            refetch,
          })}
          emptyStateText={'No attachments to display here'}
          items={emailTemplateAttachments}
          itemKey={'id'}
        />
      </AttachmentsTableContainer>
    </ScrollView>
  );
};

const EmailAttachmentLibrary = () => {
  const {data, refetch} = useQuery(EmailAttachmentLibrary.query, {
    fetchPolicy: 'cache-and-network',
  });

  const createAttachmentForm = CreateAttachmentForm.new({
    // @ts-expect-error TS(2322): Type '"EMAIL_TEMPLATE_ATTACHMENT"' is not assignab... Remove this comment to see the full error message
    attachmentCategoryKinds: ['EMAIL_TEMPLATE_ATTACHMENT'],
    description: '',
  });

  const addAttachmentModal = useModal({name: 'Add Attachment Modal'});

  if (!data) {
    return null;
  }

  return (
    <Container>
      <Button
        text={'Upload attachment'}
        onPress={addAttachmentModal.handleOpen}
        iconLeft={Icon.Plus}
      />
      <Space height={32} />
      <HeaderText>Email Attachment Library</HeaderText>
      <Space height={12} />
      <EmailAttachmentLibraryAttachments
        emailTemplateAttachments={data.viewer.viewingOrganization.emailTemplateAttachments}
        refetch={refetch}
      />
      <AddAttachmentModal
        key={addAttachmentModal.key}
        isOpen={addAttachmentModal.isOpen}
        handleClose={addAttachmentModal.handleClose}
        viewer={data.viewer}
        refetch={refetch}
        initialCreateAttachmentForm={createAttachmentForm}
        attachmentCategoryKindsDropdownOptions={ATTACHMENT_CATEGORY_KINDS_DROPDOWN_OPTIONS}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailAttachmentLibrary.query = gql`
  ${AddAttachmentModal.fragment}
  query EmailAttachmentLibrary {
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        emailTemplateAttachments {
          id
          file {
            id
            name
            downloadUrl
            uploadedAt
          }
        }
      }
      ...AddAttachmentModal
    }
  }
`;

export default EmailAttachmentLibrary;
