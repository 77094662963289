// Libraries
import React from 'react';

// Supermove
import {Icon, Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import SmallModal from '@shared/design/components/Modal/SmallModal';
import PayengineCreditCardForm from '@shared/modules/Payment/forms/PayengineCreditCardForm';
import useUpdatePayengineCreditCardMutation from '@shared/modules/Payment/hooks/useUpdatePayengineCreditCardMutation';
import ClientCreditCardFields from 'modules/Client/components/ClientCreditCardFields';

const ClientEditCreditCardModalContent = ({
  creditCard,
  client,
  projectId,
  handleClose,
  refetch,
  responsive,
}: any) => {
  const payengineCreditCardForm = PayengineCreditCardForm.edit(creditCard, {projectId});
  const {form, submitting, handleSubmit} = useUpdatePayengineCreditCardMutation({
    payengineCreditCardForm,
    onSuccess: () => {
      handleClose();
      refetch();
    },
    onError: (errors) => console.error({errors}),
  });

  const field = `payengineCreditCardForm`;

  return (
    <React.Fragment>
      <ClientCreditCardFields form={form} field={field} client={client} />
      <Space height={32} />
      {responsive.desktop ? (
        <SmallModal.Footer>
          <QuaternaryButton onPress={handleClose} text={'Cancel'} />
          <Space width={16} />
          <Button
            isSubmitting={submitting}
            onPress={handleSubmit}
            iconLeft={Icon.Check}
            text={'Save'}
          />
        </SmallModal.Footer>
      ) : (
        <Button
          isSubmitting={submitting}
          onPress={handleSubmit}
          iconLeft={Icon.Check}
          text={'Save'}
          isResponsive
          isWidthOfContainer
        />
      )}
    </React.Fragment>
  );
};

const ClientEditCreditCardModal = ({
  creditCard,
  client,
  projectId,
  isOpen,
  handleClose,
  refetch,
}: any) => {
  const responsive = useResponsive();

  return (
    <SmallModal
      isOpen={isOpen}
      handleClose={handleClose}
      isMobileSheet
      sheetLabel={'Edit Credit Card'}
    >
      {responsive.desktop && (
        <React.Fragment>
          <SmallModal.HeaderText>Edit Credit Card</SmallModal.HeaderText>
          <Space height={16} />
        </React.Fragment>
      )}
      <ClientEditCreditCardModalContent
        creditCard={creditCard}
        client={client}
        projectId={projectId}
        handleClose={handleClose}
        refetch={refetch}
        responsive={responsive}
      />
    </SmallModal>
  );
};

ClientEditCreditCardModal.fragment = gql`
  ${ClientCreditCardFields.fragment}
  ${PayengineCreditCardForm.edit.fragment}

  fragment ClientEditCreditCardModal on Client {
    id
    creditCards {
      id
      ...PayengineCreditCardForm_edit
    }
    ...ClientCreditCardFields
  }
`;

export default ClientEditCreditCardModal;
