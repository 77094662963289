// Libraries
import React from 'react';

// Components
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Phone} from '@supermove/utils';

const Container = Styled.View`
  align-items: center;
  border-color: ${colors.gray.border};
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  flex: 1;
  padding-vertical: 16px;
`;

const Title = Styled.Text`
  ${Typography.Label2}
  text-transform: uppercase;
  color: ${colors.gray.tertiary};
`;

const Image = Styled.Image`
`;

const Name = Styled.Text`
  ${Typography.Label3}
`;

const Text = Styled.Text`
  ${Typography.Body5}
`;

type OwnProps = {
  organization: any;
};

// @ts-expect-error TS(2456): Type alias 'Props' circularly references itself.
type Props = OwnProps & typeof DocumentHeaderPreview.defaultProps;

// @ts-expect-error TS(7022): 'DocumentHeaderPreview' implicitly has type 'any' ... Remove this comment to see the full error message
const DocumentHeaderPreview = ({organization}: Props) => {
  return (
    <Container>
      <Title>Document Header Preview</Title>
      <Space height={16} />
      {organization.logoFile && (
        <React.Fragment>
          <Image
            resizeMode={'contain'}
            source={{uri: organization.logoFile.downloadUrl}}
            style={{
              width: 195,
              height: 80,
            }}
          />
          <Space height={12} />
        </React.Fragment>
      )}
      <Name>{organization.businessName}</Name>
      <Space height={8} />
      <Text>{organization.businessLicense}</Text>
      <Space height={4} />
      <Text>{organization.businessAddress}</Text>
      <Space height={4} />
      <Text>{Phone.display(organization.phoneNumber)}</Text>
    </Container>
  );
};

DocumentHeaderPreview.defaultProps = {};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentHeaderPreview.fragment = gql`
  fragment DocumentHeaderPreview on Organization {
    id
    phoneNumber
    logoFile {
      id
      downloadUrl
    }
    businessAddress
    businessName
    businessLicense
  }
`;

export default DocumentHeaderPreview;
